import EditableCell from 'components/table/cell/EditableCell'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { payrollCommentaryHeadings } from 'utils/locales/payrollSummary.en'

export const generateCommentaryHeadings = () => [
  new TableHeading({
    accessor: 'body',
    heading: payrollCommentaryHeadings.comments,
    requiredField: true,
    className: 'u-text--left',
    columnClassName: 'u-text--left',
    editable: true,
    Cell: EditableCell,
    inputType: 'textArea',
    disableSortBy: true,
    charactersPerLine: 110,
  }),
  new TableHeading({
    accessor: 'date',
    heading: payrollCommentaryHeadings.date,
    className: 'u-text--left u-fixed-width--200',
    columnClassName: 'u-text--left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'authorName',
    heading: payrollCommentaryHeadings.submittedBy,
    className: 'u-text--left u-fixed-width--250',
    columnClassName: 'u-text--left ',
    disableSortBy: true,
  }),
]
