import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import PropTypes from 'prop-types'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchVendorCountryServicePivotIfNeeded, fetchVendorCountryServicePivot, removeVendorCountryService } from 'redux/actions/vendorCountryServicePivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import RemoveServiceForm from '../components/RemoveServiceForm'

class RemoveServiceFormContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchVendorsIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchServicesIfNeeded()
  }

  render () {
    return <RemoveServiceForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const vendorId = parseInt(props.match.params.vendorId, 10)

  return {
    fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
    onSubmit: serviceId => dispatch(removeVendorCountryService(vendorId, serviceId)).then(() => {
      props.onSubmit()
      dispatch(fetchVendorCountryServicePivot())
    })
  }
}

const mapStateToProps = (state, props) => {
  const { vendors, services, vendorCountryServicePivot, countryServicePivot } = state

  if (isFetching([vendors, services, vendorCountryServicePivot, countryServicePivot])) return { isFetching: true }

  const { serviceId } = props

  return {
    initialValues: {
      serviceId
    }
  }
}

RemoveServiceFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  countryId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'addServiceForm'
})(RemoveServiceFormContainer))
