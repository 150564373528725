import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import TermsContainer from '../containers/TermsContainer'
import TermsCreateContainer from '../containers/TermsCreateContainer'
import TermsEditContainer from '../containers/TermsEditContainer'
import Modal from 'components/Modal'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      termId: null
    }
  }

  render () {
    return <InnerWrapper>
      <TermsCreateContainer {...this.props} />
      <TermsContainer
        onEdit={(termId) => {
          this.setState({
            termId
          })
          this.refs.editModal.showModal()
        }}
        {...this.props} />

      <Modal
        ref='editModal'
        className='c-modal'
        modalHeading='Edit term'
      >
        <TermsEditContainer
          termId={this.state.termId}
          onEdit={() => this.refs.editModal.hideModal()}
        />
      </Modal>
    </InnerWrapper>
  }
}

export default RouteView
