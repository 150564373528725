import { useFetch } from 'hooks/useFetch'
import { useSelector } from 'react-redux'
import { createFilter } from 'utils/redux/filter'
import { getPayrollInstanceVersionsByInstanceIdSortedDesc } from 'redux/selectors/payrollInstanceVersions'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { formatNumberToCurrency } from 'utils/number'

/**
 * Method that makes calls to payroll instances and payrun versioning
 * @param {Number} payrollInstanceId
 * @returns Object with the version data and the page title
 */

export const getTitleAndVersionData = (payrollInstanceId) => {
  const fetcherOptions = [
    {
      name: 'payrollInstanceVersion',
      params: [
        {
          _computed: {
            filter: (state) => createFilter({ payrollInstance: payrollInstanceId }),
          },
          disableObsoleteFlow: true,
        },
      ],
      forceFetch: true,
    },
    {
      name: 'payrollInstances',
      params: [
        {
          _computed: {
            filter: (state) => createFilter({ id: payrollInstanceId }),
          },
          disableObsoleteFlow: true,
        },
      ],
      forceFetch: true,
    },
  ]

  const fetcherVersionData = useFetch(fetcherOptions)

  const versionData = useSelector((state) => {
    if (fetcherVersionData.isFetching) return []
    return getPayrollInstanceVersionsByInstanceIdSortedDesc(state, { payrollInstanceId })
  })

  const payrollInstanceData = useSelector((state) => {
    if (fetcherVersionData.isFetching) return
    return getPayrollInstanceRef(state, { payrollInstanceId })
  })
  const title = payrollInstanceData?.payrollNameAndPeriod
  return {
    versionData,
    title,
    isFetching: fetcherVersionData.isFetching,
  }
}

export const generateEmptyCellPlaceHolder = (classNames = '') => ({
  displayValue: '',
  classNames,
})

/**
 * @param {String} displayValue - value to be shown in the cell
 * @param {String} classNames - css classes applied to the cell
 * @returns Object that is used as an item in a custom last row on Payslip tables
 */

export const formatCell = (displayValue = '', classNames = '') => {
  return {
    displayValue: formatNumberToCurrency(displayValue, false, 'ENEN', null, 2),
    classNames,
  }
}
/**
 * We are using companyLocalName as the local name if it exists, if not, it should fall back on countryLocalName
 * If the local and global name are the same, we are showing only the global name
 * If they are not the same, we're showing global / local
 * @param {String} companyLocalName
 * @param {String} countryLocalName
 * @param {String} globalName
 * @param {Bool} showElementCode whether to show the element code after the term name, ex "Term Name [elCode]"
 * @param {String} elementCode
 * @returns {String} Formatted string
 */

export const getTermName = ({ companyLocalName = '', countryLocalName = '', globalName = '', showElementCode = false, elementCode = null }) => {
  const localTermName = companyLocalName || countryLocalName
  const globalTermName = globalName
  const term = localTermName === globalTermName || !localTermName ? globalTermName : `${globalTermName} / ${localTermName}`
  if (!showElementCode || !elementCode) return term
  return `${term} [${elementCode}]`
}
