import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'

const ReferralsFormFields = ({ ...props }) => {
  return (
    <Form showSubmitBtn btnText='Submit' {...props}>
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-padding-small'>
          <Field
            name='name'
            label='Referral'
            labelClassName='c-label'
            component={CustomSelectField}
            formGroupClassName='u-margin-none'
            placeholder='Select'
            data-testid='referrals-wrapper'
            options={[{
              value: 'referral-1',
              label: 'Referral 1'
            },
            {
              value: 'referral-2',
              label: 'Referral 2'
            },
            {
              value: 'other',
              label: 'Other'
            }]}
          />
        </div>
      </div>
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-padding-small'>
          <Field
            name='deactivateAccount'
            label='Deactivate account in'
            labelClassName='c-label'
            component={CustomSelectField}
            formGroupClassName='u-margin-none'
            placeholder='Select'
            data-testid='referrals-wrapper'
            options={[{
              value: '3',
              label: '3 Months'
            },
            {
              value: '6',
              label: '6 Months'
            },
            {
              value: 'other',
              label: 'Other'
            }]}
          />
        </div>
      </div>
    </Form>
  )
}

export default ReferralsFormFields
