import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ConfirmationModal from 'components/ConfirmationModal'
import Modal from 'components/Modal'
import DocumentsListContainer from '../containers/DocumentsListContainer'
import DocumentsShareVendorModalFormContainer from '../containers/DocumentsShareVendorModalFormContainer'
import { types } from 'redux/config/documents'
import { getDocumentTypesByTabName, getDefaultTabByCurrentStep, getFilteredDocTabsForByPayrunState } from 'utils/documentTypes'
import { DocumentMessages } from 'utils/helperClasses/documents'
import { find } from 'lodash'
import DocumentFormEditContainer from 'containers/documents/DocumentFormEditContainer'
import SectionHeading from 'components/SectionHeading'
import { updateDocument } from 'redux/actions/document'
import { gtnValidationStatusEnums } from 'utils/enums/gtnValidationEnums'
import { useDispatch } from 'react-redux'

const DocumentsView = (props) => {
  const shareModalRef = useRef()
  const confirmShareModalRef = useRef()
  const editModalRef = useRef()

  const [id, setId] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [documentTenant, setDocumentTenant] = useState(null)
  const [documentName, setDocumentName] = useState(null)
  const [documentTypes, setDocumentTypes] = useState([])
  const [payrollId, setPayrollId] = useState(null)
  const [uploadedPayrollName, setUploadedPayrollName] = useState(null)
  const [isDocUploaded, setIsDocUploaded] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [tabIndexBasedOnDocUploadedType, setTabIndexBasedOnDocUploadedType] = useState(0)

  const dispatch = useDispatch()

  const { onShare, dynamicTypeClass, typeClass, companyName, countryName, currentStep, payrunState, payrollName = null } = props
  const type = dynamicTypeClass ? types[dynamicTypeClass] : types[typeClass]
  const tabs = typeClass ? getFilteredDocTabsForByPayrunState(types[typeClass].documentTypesTabs, payrunState) : null

  const message = ['payrollInstance', 'company', 'payroll'].includes(type.shortValue)
    ? new DocumentMessages(type.shortValue, { company: companyName, country: countryName, payroll: payrollName || uploadedPayrollName }, documentTypes)
      .confirmationMsg
    : type.confirmationMsg

  const showShareModal = () => {
    shareModalRef.current.showModal()
  }

  const getShortValue = (typeClass) => {
    return find(types, (a) => a.value === typeClass).shortValue
  }

  const showConfirmShareModal = (id, documentId, documentTenant, documentName, typeClass, typeId, payrollName, documentTypes) => {
    const shortValue = getShortValue(typeClass)
    props.onShare && props.onShare(shortValue)

    setId(id)
    setDocumentId(documentId)
    setDocumentTenant(documentTenant)
    setDocumentName(documentName)
    setDocumentTypes(documentTypes)
    setPayrollId(shortValue === 'payroll' ? typeId : null)
    setUploadedPayrollName(payrollName)

    confirmShareModalRef.current.showModal()
  }

  const handleOnFileUploaded = (tabIndex) => {
    setIsDocUploaded(true)
    setTabIndexBasedOnDocUploadedType(tabIndex)
  }

  const overrideGtnValidation = (documentId) => dispatch(updateDocument({ gtnValidationStatus: gtnValidationStatusEnums.overwritten }, documentId, true, false))

  const tabIndex = isDocUploaded || isSelected ? tabIndexBasedOnDocUploadedType : getDefaultTabByCurrentStep(currentStep, payrunState)

  return (
    <>
      <ConfirmationModal
        ref={confirmShareModalRef}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => showShareModal()}
        data-testid='share-confirm-modal'
      >
        <p>{type && message}</p>
        <p>{type && type.shareFileMsg}</p>
      </ConfirmationModal>
      <Modal ref={shareModalRef} className='c-modal c-modal--half c-modal--overflow-y' modalHeading={`Share ${documentName}`}>
        <DocumentsShareVendorModalFormContainer
          id={id}
          documentId={documentId}
          documentTenant={documentTenant}
          payrollId={payrollId}
          onShared={() => shareModalRef.current.hideModal()}
          {...props}
          typeClass={type.shortValue}
        />
      </Modal>
      <Modal ref={editModalRef} className='c-modal c-modal--half' modalHeading={`Edit ${documentName}`}>
        <DocumentFormEditContainer
          id={id}
          documentId={documentId}
          documentTenant={documentTenant}
          hasDocumentClassification={typeClass === 'payrollInstance'}
          payrollId={payrollId}
          onSubmitChanges={() => editModalRef.current.hideModal()}
          {...props}
          typeClass={type.shortValue}
        />
      </Modal>
      {tabs && (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndexBasedOnDocUploadedType(index)
            setIsSelected(true)
          }}
        >
          <TabList className='c-tabs__list c-tabs__list-border-none'>
            {tabs.map((tab) => (
              <Tab key={tab.name} className='c-tabs__item' data-testid={`tab-${tab.label}`}>
                <span>{tab.label}</span>
              </Tab>
            ))}
          </TabList>
          {tabs.map((tab) => (
            <TabPanel key={tab.name}>
              <SectionHeading text={tab.documentsTableTitle} fullWidth className='u-padding-top' />
              <DocumentsListContainer
                overrideGtnValidation={(id) => overrideGtnValidation(id)}
                openConfirmShareModal={(id, docId, docTenant, docName, typeClass, typeId, payrollName, documentTypes) =>
                  showConfirmShareModal(id, docId, docTenant, docName, typeClass, typeId, payrollName, documentTypes)
                }
                openShareModal={({ id, documentId, documentTenant, documentName, typeClass = '', payrollId }) => {
                  const shortValue = getShortValue(typeClass)
                  // Check if onShare is set and call it
                  onShare && onShare(shortValue)
                  setId(id)
                  setDocumentId(documentId)
                  setDocumentTenant(documentTenant)
                  setDocumentName(documentName)
                  setPayrollId(payrollId)
                  showShareModal()
                }}
                openEditModal={({ id, documentId, documentTenant, documentName }) => {
                  setId(id)
                  setDocumentId(documentId)
                  setDocumentTenant(documentTenant)
                  setDocumentName(documentName)
                  editModalRef.current.showModal()
                }}
                {...props}
                documentTypes={getDocumentTypesByTabName(tab.name)}
                tab={tab}
                tabIndex={tabIndex}
                onFileUploaded={(tabIndex) => handleOnFileUploaded(tabIndex)}
              />
            </TabPanel>
          ))}
        </Tabs>
      )}
      {!tabs && (
        <DocumentsListContainer
          openConfirmShareModal={(id, docId, docTenant, docName, typeClass, typeId, uploadedPayrollName) =>
            showConfirmShareModal(id, docId, docTenant, docName, typeClass, typeId, uploadedPayrollName)
          }
          openShareModal={({ id, documentId, documentTenant, documentName, typeClass = '', payrollId }) => {
            const shortValue = getShortValue(typeClass)
            // Check if onShare is set and call it
            onShare && onShare(shortValue)
            setId(id)
            setDocumentId(documentId)
            setDocumentTenant(documentTenant)
            setDocumentName(documentName)
            setPayrollId(payrollId)
            showShareModal()
          }}
          openEditModal={({ id, documentId, documentTenant, documentName }) => {
            setId(id)
            setDocumentId(documentId)
            setDocumentTenant(documentTenant)
            setDocumentName(documentName)

            editModalRef.current.showModal()
          }}
          {...props}
        />
      )}
    </>
  )
}

DocumentsView.propTypes = {
  onShare: PropTypes.func,
  dynamicTypeClass: PropTypes.string,
  typeClass: PropTypes.string,
  companyName: PropTypes.string,
  countryName: PropTypes.string,
  payrollName: PropTypes.string,
  currentStep: PropTypes.string,
  payrunState: PropTypes.string,
}

export default DocumentsView
