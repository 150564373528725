const fieldComponentsEnum = {
  INPUT: 'input',
  DROPDOWN: 'dropdown',
  EMAIL: 'email',
  DATE: 'date',
  PHONE: 'phone',
  OBJECT: 'object'
}

export default fieldComponentsEnum
