import React from 'react'
import InnerWrapper from './InnerWrapper'
import Tabs from './Tabs'
import PropTypes from 'prop-types'

export const InnerWrapperTabs = ({ removeWrapper, ...props }) => {
  if (removeWrapper) return <Tabs {...props} />

  return <InnerWrapper {...props}>
    <Tabs {...props} />
  </InnerWrapper>
}

InnerWrapperTabs.propTypes = {
  removeWrapper: PropTypes.bool
}

export default InnerWrapperTabs
