import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'

import { connect } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchTimezonesIfNeeded } from 'redux/actions/timezones'
import { updateVendorUserSSO, updateVendorUserWithAAs } from 'redux/actions/vendorUsers'
import { isCot, isCotOem, isVendorAdmin } from 'redux/selectors/auth'
import { getCountries } from 'redux/selectors/country'
import { getTimezones } from 'redux/selectors/timezones'
import { getVendorUser, getVendorUsersInitialAccessAreas } from 'redux/selectors/vendorUsers'
import { resetPasswordSendEmail } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import VendorUserForm from '../components/VendorUserForm'
import { getVendorById } from 'redux/selectors/vendors'
import { booleanToStringActiveInactive } from 'redux/transformers/booleanTransformer'
import { enableVendorUserSSOStrings } from 'redux/config/ssoAccessStrings'
import { getAccessableExternalParentCompanies } from 'redux/selectors/externalCompanies'
import { buildVendorAccessAreas, getAccessAreasDifference } from '../vendorUserHelper'
import { ROLE_VENDOR, ROLE_VENDOR_ADMIN, vendorRoleTypes } from '../../../../../../../redux/config/auth'

const formName = 'vendorUserCreateForm'

const VendorUserFormEditContainer = (props) => {
  const { dispatch } = props

  const fetchData = async () => {
    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchVendorsIfNeeded())
    dispatch(fetchTimezonesIfNeeded())
  }

  useEffect(() => {
    fetchData()
  }, [dispatch])

  return <VendorUserForm {...props} />
}

VendorUserFormEditContainer.propTypes = {
  onEdit: PropTypes.func,
  dispatch: PropTypes.func,
  vendorUserId: PropTypes.number,
}

// map to state
const mapStateToProps = (state, props) => {
  const { countries, vendors, timezones } = state
  const entities = [countries, vendors, timezones]
  const vendorUser = getVendorUser(state, {
    userId: props.vendorUserId,
  })

  if (isFetching(entities, true)) return { isFetching: true }

  const { vendorId } = props.match.params
  const vendor = getVendorById(state, { vendorId })?.ref
  const isCotUser = isCot(state)
  const { defaultSSOEnabledFor, isVendorSSOEnabled } = vendor
  const isVendorUserActive = vendorUser.hasCredentials
  const showUserSSOField = isVendorSSOEnabled && isVendorUserActive && enableVendorUserSSOStrings.includes(defaultSSOEnabledFor)
  const showUserSSOButton = showUserSSOField && isCot(state)
  const parentCompanies = getAccessableExternalParentCompanies(state)

  const showMode2faFieldOnEdit = isVendorUserActive ? !vendorUser.isSSOEnabled : false

  const initialFormValues = {
    ...vendorUser,
    ...(vendorUser.country ? { country: vendorUser.country.id } : {}),
    // `workflowNotifications` is only a FE field,
    // that represents and results in two BE fields when the data is send back to the BE
    workflowNotifications: JSON.stringify({
      receivesNotifications: vendorUser.receivesNotifications,
      receivesEmailNotifications: vendorUser.receivesEmailNotifications,
    }),
    isSSOEnabled: booleanToStringActiveInactive(vendorUser.isSSOEnabled),
    ...getVendorUsersInitialAccessAreas(state, { vendorUser }),
  }
  const canEditRole = !isCotOem(state)

  return {
    canEditRole,
    vendorRoleTypes,
    isEdit: true,
    isVendorAdmin: isVendorAdmin(state),
    isCot: isCotUser,
    globalCountries: getCountries(state),
    timezones: getTimezones(state),
    hasCredentials: vendorUser.hasCredentials,
    workEmail: vendorUser.workEmail,
    externalCompanies: props?.externalCompanies,
    parentCompanies,
    receivesNotifications: vendorUser.receivesNotifications,
    receivesEmailNotifications: vendorUser.receivesEmailNotifications,
    initialValues: initialFormValues,
    vendorUserId: vendorUser.id,
    isVendorSSOEnabled: vendorUser.isSSOEnabled,
    showUserSSOField,
    showUserSSOButton,
    showMode2faFieldOnEdit,
  }
}

const handleData = (id, state, data) => {
  const { accessAreas, ...formData } = data
  const roleType = data.roleType
  if (roleType === ROLE_VENDOR_ADMIN) {
    return formData
  }

  const vendorUser = getVendorUser(state, {
    userId: id,
  })
  const isDowngradeRole = vendorUser.roleType === ROLE_VENDOR_ADMIN && roleType === ROLE_VENDOR
  const formAccessAreas = buildVendorAccessAreas(accessAreas, roleType)

  if (isDowngradeRole) {
    return {
      ...formData,
      attachAccessAreas: formAccessAreas,
    }
  }

  const initialAccessAreas = buildVendorAccessAreas(getVendorUsersInitialAccessAreas(state, { vendorUser })?.accessAreas)
  const attachAccessAreas = getAccessAreasDifference(formAccessAreas, initialAccessAreas)
  const detachAccessAreas = getAccessAreasDifference(initialAccessAreas, formAccessAreas)

  return {
    ...formData,
    attachAccessAreas,
    detachAccessAreas,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetField: (field, value) => dispatch(change(formName, field, value)),
    onSubmit: (data) => {
      const { workflowNotifications, accessAreas, ...vendorUser } = data
      // `workflowNotifications` value results in 2 backend fields,
      // please refer to the field options for more details
      let sanitized = {
        ...omit(vendorUser, ['isSSOEnabled', 'tenants']),
        accessAreas,
        ...(workflowNotifications && JSON.parse(workflowNotifications)),
        mobilePhone: vendorUser.mobilePhone || null,
      }

      return dispatch(updateVendorUserWithAAs(sanitized, sanitized.id, handleData)).then(() => {
        props.onEdit()
      })
    },
    resetUserPassword: (data) =>
      dispatch(resetPasswordSendEmail(data)).then(() => {
        dispatch(
          showMessage({
            body: 'A password reset link has been sent to the user’s email',
          })
        )
      }),
    handleSSOclick: (data) =>
      dispatch(updateVendorUserSSO(data)).then((res) => {
        if (res?.status) {
          let val = booleanToStringActiveInactive(res.isVendorSSOEnabled)
          dispatch(change(formName, 'isSSOEnabled', val))
        }
        return res
      }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
  })(VendorUserFormEditContainer)
)
