import React from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'
import { getComputeDomVarColor } from 'utils/colors'
import { AppVarColorName } from 'utils/enums/colorsEnum'

const StatusSwitchToggle = (props) => {
  const { input, disabled, label } = props
  const { onChange, value } = input
  return (
    <div className='switch-toggle' data-testid={props['data-testid']}>
      <div className={disabled ? 'u-cursor--not-allowed o-grid ai-center' : 'o-grid ai-center'}>
        <Switch
          title={value ? 'Active' : 'Inactive'}
          onChange={!disabled ? onChange : () => {}}
          checked={props.statusMapping[value]}
          disabled={disabled}
          onColor={getComputeDomVarColor(AppVarColorName.SwitchToggleOn)}
          onHandleColor='#fff'
          offColor='#EDEAF2'
          handleDiameter={13}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={15}
          width={34}
          className='react-switch'
          id='normal-switch'
        />
        {label ? <span className='o-block o-block--left u-text--small u-margin-left-small'>{props.mappedStatusLabels[label]}</span> : null}
      </div>
    </div>
  )
}

StatusSwitchToggle.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  statusMapping: PropTypes.object.isRequired,
  mappedStatusLabels: PropTypes.object.isRequired,
  'data-testid': PropTypes.string,
}

export default StatusSwitchToggle
