/**
 * `fetch` library error messages of Chrome, Firefox and Safari,
 * when the fetch request fails, because of interrupted network
 * @type {[*]}
 */
const networkErrors = ['Failed to fetch', 'Network request failed',
  'NetworkError when attempting to fetch resource.']

/**
 * Check if the passed in message is part of
 * the default network errors messages
 *
 * @credits https://medium.com/@vinhlh/how-to-handle-networkerror-when-using-fetch-ff2663220435
 * @param {String} message
 * @return {Boolean}
 */
export const isNetworkError = message => networkErrors.includes(message)
