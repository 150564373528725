import { createReducer } from 'utils/redux'
import { COUNTRIES_FETCH, COUNTRIES_RECEIVE } from 'routes/Countries/modules/actions'

const actionTypes = {
  fetch: COUNTRIES_FETCH,
  receive: COUNTRIES_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(actionTypes)
