import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { required } from 'utils/validations'
import LeaveFormFields from './LeaveFormFields'
import CustomSelectField from 'components/form/select/CustomSelectField'

const LeaveForm = ({ leaveTypes, ...props }) => (
  <Form showSubmitBtn {...props}>
    <div className='o-layout'>
      <div className='o-layout__item u-1/1 u-1/3@tablet'>
        <Field
          name='companyCountryTerm'
          label='Type of Leave'
          labelClassName='c-label'
          component={CustomSelectField}
          placeholder='Select'
          options={leaveTypes.map(type => {
            const label = type.localName ? type.termName + ' (' + type.localName + ')' : type.termName
            return {
              value: type.id,
              label: label
            }
          })}
          validate={required}
          data-testid='leave-type'
        />
      </div>
      <div className='o-layout__item u-1/1 u-2/3@tablet u-padding-left-none'>
        <LeaveFormFields {...props} />
      </div>
    </div>
  </Form>
)

LeaveForm.propTypes = {
  leaveTypes: PropTypes.array
}

export default LeaveForm
