import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { fetchDataProtectionFieldsIfNeeded } from 'redux/actions/dataProtectionFields'
import DataProtectionFieldsList from 'components/DataProtectionFieldsList'
import { getDataProtectionFieldsGroupedByCategory } from 'redux/selectors/dataProtectionFields'

class GlobalDataProtectionContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(fetchDataProtectionFieldsIfNeeded())
  }

  render () {
    const { onEdit } = this.props
    if (this.props.isFetching) return <div>Loading...</div>

    return <DataProtectionFieldsList
      title='Global Data Protection'
      onClick={dataFieldId => onEdit(dataFieldId)}
      {...this.props} />
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

const mapStateToProps = state => {
  const { dataProtectionFields } = state

  const entities = [ dataProtectionFields ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    groupedFields: getDataProtectionFieldsGroupedByCategory(state)
  }
}

GlobalDataProtectionContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataProtectionContainer)
