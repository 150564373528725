import _ from 'lodash'

/**
 * Remove all null, undefined and empty array values for an object
 *
 * @param {Object} filters
 * @return {Object}
 */
export const sanitizeObject = (filters) =>
  _(filters)
    .omitBy((value) => _.isNil(value) || (_.isArray(value) && _.isEmpty(value)))
    .value()

/**
 * Transform property values to boolean
 *
 * @param {Object} data
 * @param {String} item
 * @return {Object}
 *
 * ex: data: {
 *  isVisible: undefined
 * }
 * mapPropertyValueToBoolean(data, 'isVisible')
 * output: { isVisible: false }
 */
export const mapPropertyValueToBoolean = (data, item) => {
  return { [item]: !!data[item] }
}
