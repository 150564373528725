import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

export default WrappedComponent => {
  class AttachDetach extends React.Component {
    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      /**
       * Mass update of employees by form
       *
       * @param {String} formName
       * @param {Array.<{ id: String }>} employees
       * @param {Boolean} value
       */
      updateEmployees: (formName, employees, value) => {
        const fields = employees.map(u => `id-${u.id}`)

        fields.forEach(field => {
          dispatch(change(formName, field, value))
        })
      }
    }
  }

  return connect(null, mapDispatchToProps)(AttachDetach)
}
