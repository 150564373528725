import React from 'react'
import DataProtectionPolicyContainer from '../containers/DataProtectionPolicyContainer'
import DataProtectionResourcesList from '../components/DataProtectionResourcesList'
import PersonalDataContainer from '../containers/PersonalDataContainer'

class RouteView extends React.Component {
  render () {
    return <div>
      <div className='o-layout'>
        <PersonalDataContainer {...this.props} />
        <div className='o-layout__item u-1/1 u-1/2@tablet u-2/3@desktop'>

          <DataProtectionPolicyContainer {...this.props} />

          <DataProtectionResourcesList {...this.props} />

        </div>
      </div>
    </div>
  }
}

export default RouteView
