import React from 'react'
import PropTypes from 'prop-types'

/**
 * FieldLayout component enhances the layout of form fields by wrapping provided children elements
 * within a div with specific CSS classes to handle responsive design and styling.
 *
 * @param {Object} props - The properties passed to this component.
 * @param {React.ReactNode} props.children - The children elements to be displayed within the layout.
 * @returns {JSX.Element} A wrapper around children elements with responsive and styled layout behavior.
 */
export const FieldLayout = ({ children }) => (
  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>{children}</div>
  </div>
)

FieldLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
