import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { getVendorsTenants } from 'redux/selectors/tenants'
import VendorsList from '../components/VendorsList'
import { selectTenant } from 'redux/actions/tenants'

class VendorsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchVendorsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <VendorsList {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded()),
    selectTenantSubmit: (url, type, id) => {
      // Before resetting everything, go to `/home` route,
      // in order to prevent unrelated api calls.
      // Imagine that you select a company tenant and you're on `/companies` route.
      // After that you change to a vendor tenant.
      // In that case fetch companies requests will be triggered, to the vendor tenant api url,
      // that isn't correct.
      // So before switching the tenant / api url, we go to the `home` neutral route.
      ownProps.history.push(`/home`)

      dispatch(selectTenant({ url, type, id }))
    }
  }
}

const mapStateToProps = (state, props) => {
  const { tenants, vendors } = state

  const entities = [ vendors ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    vendors: getVendorsTenants(state),
    initialValues: {
      url: tenants.url
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsContainer)
