import createSelector from 'utils/createSelector'
import overtimes from 'redux/config/overtimes'
import termSubcategories from 'redux/config/termSubcategories'
import { hasAccess } from 'redux/selectors/auth'
import { makeFilteredIdsByFilter } from './filters'
import { isEmpty } from 'utils/fnkit/typeChecks'

const getFilteredIds = makeFilteredIdsByFilter('companyCountryTermPivot')

const companyId = (state, props) => parseInt(props.companyId)
const categoryName = (state, props) => props.categoryName

/**
 * Extracts the properties from props.
 * @param {Object} _ - Unused parameter representing the state.
 * @param {Object} props - The properties passed to the selector.
 * @returns {Object} The properties passed to the selector.
 */
const getProps = (_, props) => props

export const getCompanyCountryTermsPivotByCompany = createSelector(companyId, ({ CompanyCountryTerm }, companyId) => {
  let companyTerms = []

  CompanyCountryTerm.filter({ company: companyId })
    .toModelArray()
    .map((companyCountryTerm) => {
      companyTerms.push({
        companyCountryTermId: companyCountryTerm.id,
        localName: companyCountryTerm.countryTerm.localName,
        globalName: companyCountryTerm.countryTerm.term.name,
        elementCode: companyCountryTerm.elementCode,
        shouldOnlyUseGlobalName: companyCountryTerm.countryTerm.shouldOnlyUseGlobalName(),
        elements: companyCountryTerm.getFullNameElements(),
        fullName: companyCountryTerm.getFullName(),
        categoryName: companyCountryTerm.countryTerm.term.termSubcategory.termCategory.name,
        validFrom: companyCountryTerm.validFrom,
        validTo: companyCountryTerm.validTo,
        amount: companyCountryTerm.amount,
        format: companyCountryTerm.format,
      })
    })

  return companyTerms
})

export const getCompanyCountryTermsPivotByCompanyAndCategory = createSelector(companyId, categoryName, ({ CompanyCountryTerm }, companyId, categoryName) => {
  return CompanyCountryTerm.filter({ company: companyId })
    .toModelArray()
    .filter((entity) => entity.countryTerm.term.termSubcategory.termCategory.name === categoryName)
    .map((pivot) => ({
      id: pivot.id,
      glExpense: pivot.glExpense,
      glLiability: pivot.glLiability,
      elementCode: pivot.elementCode,
      type: pivot.type,
      countryTermId: pivot.countryTerm.id,
      termName: pivot.countryTerm.term.name,
      localName: pivot.localName,
      shortName: pivot.countryTerm.shortName,
      subCategory: pivot.countryTerm.term.termSubcategory.name,
      editableBy: pivot.editableBy,
      isRecurringEditable: pivot.isRecurringEditable,
      isRecurring: pivot.isRecurring,
      recurringDisabledReason: pivot.recurringDisabledReason,
    }))
    .reverse()
})

export const getCompanyCountryTermsPivotByCompanyAndCategoryForAttendance = createSelector(
  companyId,
  categoryName,
  ({ CompanyCountryTerm }, companyId, categoryName) => {
    return CompanyCountryTerm.filter({ company: companyId })
      .toModelArray()
      .filter((entity) => entity.countryTerm.term.termSubcategory.termCategory.name === categoryName)
      .map((pivot) => ({
        id: pivot.id,
        glExpense: pivot.glExpense,
        glLiability: pivot.glLiability,
        elementCode: pivot.elementCode,
        type: pivot.type,
        countryTermId: pivot.countryTerm.id,
        termName: pivot.countryTerm.term.name,
        localName: pivot.countryTerm.localName,
        shortName: pivot.countryTerm.shortName,
        subCategory: pivot.countryTerm.term.termSubcategory.name,
        editableBy: pivot.editableBy,
        isRecurringEditable: pivot.isRecurringEditable,
        recurringDisabledReason: pivot.recurringDisabledReason,
      }))
      .reverse()
  }
)

export const getFilteredCompanyCountryTerms = createSelector(getFilteredIds, ({ CompanyCountryTerm }, ids) =>
  ids.map((id) => CompanyCountryTerm.withId(id).ref)
)

export const getCompanyCountryTermsPivotCountryTermIdsByCompany = createSelector(companyId, ({ CompanyCountryTerm }, companyId) =>
  CompanyCountryTerm.filter({ company: companyId })
    .toModelArray()
    .map((model) => model.countryTerm.id)
)

// get the fixed company country terms
export const getFixedCompanyTerms = createSelector(companyId, ({ CompanyCountryTerm }, companyId) =>
  getTermsWithData(CompanyCountryTerm.filter({ company: companyId }), ['fixed'])
)

// get the variable company country terms
export const getVariableCompanyTerms = createSelector(companyId, ({ CompanyCountryTerm }, companyId) =>
  getTermsWithData(CompanyCountryTerm.filter({ company: companyId }), ['variable'])
)

export const getRecurringDeductionsByCompany = createSelector(companyId, ({ CompanyCountryTerm }, companyId) =>
  getTermsWithData(CompanyCountryTerm.filter({ company: companyId, isRecurring: true }), [null, 'employee_net_deduction'])
)

/**
 * Get companyCountryTerms in a specific way
 * return [{
 *   companyCountryTermId: int,
 *   term: termRef
 * }]
 */
const getTermsWithData = (companyCountryTerms, types) => {
  let companyTerms = []

  companyCountryTerms.toModelArray().map((companyCountryTerm) => {
    if (types.includes(companyCountryTerm.type)) {
      companyTerms.push({
        companyCountryTermId: companyCountryTerm.id,
        localName: companyCountryTerm.countryTerm.localName,
        globalName: companyCountryTerm.countryTerm.term.name,
        elementCode: companyCountryTerm.elementCode,
        shouldOnlyUseGlobalName: companyCountryTerm.countryTerm.shouldOnlyUseGlobalName(),
        elements: companyCountryTerm.getFullNameElements(),
        fullName: companyCountryTerm.getFullName(),
        categoryName: companyCountryTerm.countryTerm.term?.termSubcategory?.termCategory?.name,
        validFrom: companyCountryTerm.validFrom,
        validTo: companyCountryTerm.validTo,
        amount: companyCountryTerm.amount,
        format: companyCountryTerm.format,
      })
    }
  })

  return companyTerms
}

// get overtimes for current company, mapped
// to their companyCountryTerms
// Note: this normally should be done in the back end
// but it's done here
export const getMappedOvertimeTermsToCCTerms = createSelector(companyId, ({ CompanyCountryTerm }, companyId) => {
  // grab overtime ccts
  let cct = CompanyCountryTerm.filter((ccTerm) => ccTerm.type === 'overtime' && ccTerm.company === companyId)

  // define overtimes
  let normalOvertime = 0
  let overtimeDuringWeekend = 0
  let overtimeDuringHoliday = 0

  // map the overtimes to their respected CCTerm
  cct.toModelArray().map((ccTerm) => {
    if (ccTerm.countryTerm.term.termSubcategory.name === overtimes.weekend) {
      overtimeDuringWeekend = ccTerm.id
    } else if (ccTerm.countryTerm.term.termSubcategory.name === overtimes.normal) {
      normalOvertime = ccTerm.id
    } else if (ccTerm.countryTerm.term.termSubcategory.name === overtimes.holiday) {
      overtimeDuringHoliday = ccTerm.id
    }
  })

  // return the mapped overtimes
  return {
    normal: normalOvertime,
    weekend: overtimeDuringWeekend,
    holiday: overtimeDuringHoliday,
  }
})

// find company time attendance
export const getCompanyAttendance = createSelector(companyId, ({ CompanyCountryTerm }, companyId) => getByType(CompanyCountryTerm, companyId, 'attendance'))

// find company overtime
export const getCompanyOvertime = createSelector(companyId, ({ CompanyCountryTerm }, companyId) => getByType(CompanyCountryTerm, companyId, 'overtime'))

/**
 * Get Company Overtime terms by excluding the following subcategory `termSubcategories.overtimeAdditions`
 *
 * For terms within this subcategory, the Employee can't add values to `EmployeeOvertime`,
 * therefore we should exclude all the terms from this subcategory..
 *
 * Later if needed, we can pass the excluded subcategories as parameter.
 */
export const getCompanyOvertimeByExcludedSubcategory = createSelector(getCompanyOvertime, (session, terms) =>
  terms.filter((term) => term.subCategoryName !== termSubcategories.overtimeAdditions)
)

/**
 * Get CCT by type
 *
 * @param Model CompanyCountryTerm
 * @param int companyId
 * @param string type
 * @return object mixed
 */
const getByType = (CompanyCountryTerm, companyId, type) => {
  return CompanyCountryTerm.all()
    .filter((term) => term.type === type && term.company === companyId)
    .toModelArray()
    .map((entry) => ({
      ...entry.ref,
      shortName: entry.countryTerm.shortName,
      localName: entry.countryTerm.localName,
      termName: entry.countryTerm.term.name,
      subCategoryId: entry.countryTerm.term.termSubcategory.id,
      subCategoryName: entry.countryTerm.term.termSubcategory.name,
    }))
}

/**
 * Get form fields props for Time and Attendance tables
 *
 * We get the props via selector.
 * Otherwise doing it in the form fields directly causes unnecessary fields rerendering.
 * For example passing <Field options={terms.map()} /> will rerender the Field each time the Form is rerendered,
 * because on each Form rerender a new Array is being created and passed down to the `options` prop.
 */
export const getFormFieldProps = createSelector(getCompanyAttendance, hasAccess, (session, terms, hasAccess) => {
  return {
    disabled: !hasAccess(['EMPLOYEETERM_EDIT']),
    options: terms.map((term) => ({
      value: term.id,
      label: term.shortName,
      title: term.termName + ' (' + term.localName + ')',
    })),
  }
})

/**
 * Selector to get company country terms based on categories.
 *
 * @param {Object} props - Properties containing companies and fixedCategories.
 * @param {Array.<number>} props.companies - List of company IDs.
 * @param {Array.<Object>} props.fixedCategories - List of category objects.
 * @param {Object} state - State containing the CompanyCountryTerm model.
 * @param {Object} state.CompanyCountryTerm - ORM model for company country terms.
 * @returns {Object} An object mapping fixed categories to an array of terms.
 */
export const getCompanyCountryTermsBasedOnCategories = createSelector(getProps, ({ CompanyCountryTerm }, { companies, fixedCategories }) => {
  if (isEmpty(companies) || isEmpty(fixedCategories)) {
    return {}
  }
  const categoriesToFiltered = new Set(fixedCategories.map((c) => c.id))
  return CompanyCountryTerm.filter((term) => companies.includes(term.company) && categoriesToFiltered.has(term.categoryRef.id))
    .toRefArray()
    .map((term) => {
      const globalName = term?.termRef.name
      const localName = term?.localName || term?.countryTermRef.localName
      const name = localName === globalName ? globalName : `${globalName} / ${localName}`
      const fixedCategory = fixedCategories.find((c) => c.id === term.categoryRef.id)?.key
      return { id: term.id, name, fixedCategory }
    })
    .reduce((acc, { id, name, fixedCategory }) => {
      if (!fixedCategory) return acc
      acc[fixedCategory] = acc[fixedCategory] || []
      acc[fixedCategory].push({ id, name })
      return acc
    }, {})
})
