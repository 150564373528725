export const MonthNamesEnum = {
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
}

export const MonthValueEnum = {
  1: 'JANUARY',
  2: 'FEBRUARY',
  3: 'MARCH',
  4: 'APRIL',
  5: 'MAY',
  6: 'JUNE',
  7: 'JULY',
  8: 'AUGUST',
  9: 'SEPTEMBER',
  10: 'OCTOBER',
  11: 'NOVEMBER',
  12: 'DECEMBER',
}

export const getMonthNameByIndex = (index) => {
  if (!MonthNamesEnum[MonthValueEnum[index]]) return ''
  return MonthNamesEnum[MonthValueEnum[index]]
}

export const WeekdayNamesEnumShort = {
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun'
}

export const weekWithShortNameDays = [
  WeekdayNamesEnumShort.MONDAY,
  WeekdayNamesEnumShort.TUESDAY,
  WeekdayNamesEnumShort.WEDNESDAY,
  WeekdayNamesEnumShort.THURSDAY,
  WeekdayNamesEnumShort.FRIDAY,
  WeekdayNamesEnumShort.SATURDAY,
  WeekdayNamesEnumShort.SUNDAY,
]
