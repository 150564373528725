import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_DATA_PROTECTION_FIELD_STATE_FETCH = 'COMPANY_DATA_PROTECTION_FIELD_STATE_FETCH'
export const COMPANY_DATA_PROTECTION_FIELD_STATE_RECEIVE = 'COMPANY_DATA_PROTECTION_FIELD_STATE_RECEIVE'
export const COMPANY_DATA_PROTECTION_FIELD_STATE_FILTER = 'COMPANY_DATA_PROTECTION_FIELD_STATE_FILTER'
export const COMPANY_DATA_PROTECTION_FIELD_STATE_FILTERED = 'COMPANY_DATA_PROTECTION_FIELD_STATE_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_DATA_PROTECTION_FIELD_STATE_FETCH,
  receive: COMPANY_DATA_PROTECTION_FIELD_STATE_RECEIVE,
  filter: COMPANY_DATA_PROTECTION_FIELD_STATE_FILTER,
  filtered: COMPANY_DATA_PROTECTION_FIELD_STATE_FILTERED
}
const actionUtility = new ActionUtility(actionTypes, 'companyDataProtectionFieldState', 'companydataprotectionfieldstates', 'CompanyDataProtectionFieldState')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyDataProtectionFieldStatesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyDataProtectionFieldStates = actionUtility.fetchEntities

export const filterCompanyDataProtectionFieldStates = filters => {
  return actionUtility.filterEntity({ filters })
}
