import createSelector from 'utils/createSelector'

/**
 *
 * Get COT users for a vendor tenant
 *
 * @return CotUser
 */
export const getCotUsers = createSelector(
  ({ CotUser }) => {
    return CotUser
      .all()
      .toModelArray()
      .map(user => ({
        ...user.ref,
        ...user.country ? { country: { ...user.country.ref } } : {},
        name: user.getName()
      }))
      .reverse()
  })
