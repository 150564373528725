import { createReducerMeta } from 'utils/redux'
import { VENDORS_FETCH, VENDORS_RECEIVE, VENDOR_UPDATE } from 'redux/actions/vendors'

const actionTypes = {
  fetch: VENDORS_FETCH,
  receive: VENDORS_RECEIVE,
  update: VENDOR_UPDATE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducerMeta(actionTypes)
