import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/events'

class Event extends CRUDModel {}
Event.modelName = 'Event'
Event.actionTypes = actionTypes

Event.fields = {
  id: attr()
}

export default Event
