import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/termSubCategories'

class SubCategory extends CRUDModel {}
SubCategory.modelName = 'SubCategory'
SubCategory.actionTypes = actionTypes

SubCategory.fields = {
  id: attr(),
  name: attr(),
  termCategory: fk('Category', 'subCategories')
}

export default SubCategory
