import React, { memo, useEffect, useState } from 'react'

import { types } from 'redux/config/reports'

import { useReportingSelector } from 'hooks/useReporting'
import { useReportingActions } from 'hooks/useReportingActions'
import { useCategoryFields } from 'hooks/useFields'

import { SchemaForm } from 'utils/fnkit/components/SchemaForm'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { resetSchemaFormFields } from 'utils/fnkit/components/SchemaForm/capabilities'

import { buildReportDesigner, ReportDesignerLayout } from './layout'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { fixedDesignerReportTermCategories } from 'utils/enums/fixedCategoriesField'

import { getReportDesignerSchema } from './schemas'
import { getReportDesignerUISchema } from './uiSchema'
import { getReportResetFieldIds, mapCategoryFieldsDefaultOptions, normalizeReportDesignerFields, reportDesignerCustomValidate } from './fields'
import { ReportDesignerModals } from './modals'
import { toast } from 'react-toastify'
import { GenericFailedToast } from 'components/ToastFailedViews'
import reportOptionsConfig from 'configs/reports'

const formId = 'reportDesignerFormId'

const fieldsMapper = ({ id, name, field }) => ({ label: name, value: `${field || id}` })
const categoryListMapper = (list = [], fields = {}) => list.map((c) => ({ ...c, options: fields[c.value] || [] }))

const Container = memo(({ defaultData, fetchChangedFieldData, isFetchingDependentFields }) => {
  const [form, setForm] = useState({ formData: defaultData || {}, changedField: undefined })
  const [context, setContext] = useState()
  const [isOpenMostReportModal, setIsOpenMostReportModal] = useState()
  const { formData, changedField } = form
  const { payroll, company } = formData

  /*
   * Report Hooks
   */
  const { mostRecentReport, updateMostRecentReport, ...reportingOptionsData } = useReportingSelector({ filters: formData })
  const { createReport, deleteReport, onSaveReport, downloadReport } = useReportingActions()

  /*
   * Category fields hook
   */
  const { categories } = useCategoryFields({
    defaultFields: defaultData.fields,
    selectedCountries: formData.country,
    countries: reportingOptionsData.countries,
    company: formData.company,
    fixedCategories: fixedDesignerReportTermCategories,
    fieldsMapper,
    categoryListMapper,
    defaultOptionsMapper: mapCategoryFieldsDefaultOptions,
  })

  useEffect(() => {
    fetchChangedFieldData({ changedField, filters: formData })
  }, [payroll, company])

  /*
   * Form actions
   */
  const handleFormChange = (form, fieldId = '') => {
    const newChangedField = fieldId.replace(`${formId}_`, '')
    const newForm = {
      formData: form.formData,
      changedField: newChangedField,
    }
    const resetFields = getReportResetFieldIds(newChangedField, form.formData)

    if (resetFields?.fieldIdsToReset) {
      newForm.formData = resetSchemaFormFields(form.formData, resetFields.fieldIdsToReset, resetFields.fieldIdsValueToReset)
    }

    setForm(newForm)
  }

  const handleSubmit = (submitedForm = {}) => {
    createReport(normalizeReportDesignerFields(submitedForm.formData)).catch(() =>
      toast.error(<GenericFailedToast id={reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE} name={reportOptionsConfig.CUSTOM_BUILD_REPORT.LABEL} />)
    )
  }

  const handleDeleteReport = (id) => {
    return deleteReport(id, () => updateMostRecentReport(undefined))
  }

  const updateContext = (updatedContext) => setContext({ ...context, ...updatedContext })

  /*
   * Form props
   */
  const schema = getReportDesignerSchema(reportingOptionsData)
  const uiSchema = getReportDesignerUISchema({ formId, formData, reportingOptionsData, isFetchingDependentFields })
  const initialData = { ...formData, categories: isEmpty(formData.categories) ? categories : formData.categories }

  return (
    <>
      <ReportDesignerModals
        isOpenMostReportModal={isOpenMostReportModal}
        setIsOpenMostReportModal={setIsOpenMostReportModal}
        report={mostRecentReport}
        updateMostRecentReport={updateMostRecentReport}
      />
      <ReportDesignerLayout>
        <SchemaForm
          schema={schema}
          customValidate={reportDesignerCustomValidate}
          uiSchema={uiSchema}
          formData={initialData}
          onChange={handleFormChange}
          onSubmit={handleSubmit}
          formContext={{ ...context, updateContext }}
        />
        {mostRecentReport && (
          <div className='ps-mt-[12]'>
            <MostRecentRunReportItem
              types={types}
              mostRecentRunReport={mostRecentReport}
              handleReportModalOpen={() => setIsOpenMostReportModal(true)}
              onOpenReport={() => setIsOpenMostReportModal(true)}
              onDelete={handleDeleteReport}
              onSaveReport={onSaveReport}
              downloadReport={downloadReport}
            />
          </div>
        )}
      </ReportDesignerLayout>
    </>
  )
})

export const ReportDesigner = (props) => buildReportDesigner(props, Container)
