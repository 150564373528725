import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeOvertime'

class EmployeeOvertime extends CRUDModel {}
EmployeeOvertime.modelName = 'EmployeeOvertime'
EmployeeOvertime.actionTypes = actionTypes

EmployeeOvertime.fields = {
  id: attr(),
  employee: fk('EmployeeSystemUser', 'employeeOvertimes'),
  companyCountryTerm: fk('CompanyCountryTerm', 'employeeOvertimes'),
}

export default EmployeeOvertime
