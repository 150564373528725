import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { addSign } from 'utils/numbers'
import PayslipTable from 'components/table/PayslipTable'

class VendorServicesTree extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      vendorId: null,
      countryTaskId: null,
      vendorCountryServiceId: null,
      taskDeadline: null,
      vendorCountryTaskId: null,
    }
  }

  render () {
    const { rows, heading } = this.props

    return <div>
      <SectionHeading text={heading || 'Services list'} />

      <div className='o-layout'>
        <div className='o-layout__item u-1/1'>

          <PayslipTable
            data={rows.map(row => (
              {
                ...row,
                monthlyDeadline: row.deadlineMonthly && row.deadlineMonthly !== null
                  ? addSign(row.deadlineMonthly)
                  : null,
                fortnightlyDeadline: row.deadlineFortnightly && row.deadlineFortnightly !== null
                  ? addSign(row.deadlineFortnightly)
                  : null,
                weeklyDeadline: row.deadlineWeekly && row.deadlineWeekly !== null ? addSign(row.deadlineWeekly) : null,
                action: <button
                  className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                  title='Edit'
                  onClick={() => this.props.onClick(row)}
                >
                  <span className='icon icon--edit' />
                </button>
              }
            ))}
            headings={[
              { accessor: 'serviceName', Header: 'Name', disableSortBy: true },
              { accessor: 'serviceType', Header: 'Type', disableSortBy: true },
              { accessor: 'processName', Header: 'Processes', disableSortBy: true },
              // { accessor: 'processKeyEvent', Header: 'Key process event', disableSortBy: true },
              { accessor: 'stepName', Header: 'Steps of the process', disableSortBy: true },
              { accessor: 'taskName', Header: 'Actions per step', disableSortBy: true },
              { accessor: 'taskOwner', Header: 'Action owner', disableSortBy: true },
              {
                accessor: 'monthlyDeadline',
                Header: 'Monthly deadline',
                formatExtraData: { isInheritColor: true },
                disableSortBy: true
              },
              {
                accessor: 'fortnightlyDeadline',
                Header: 'Fortnightly deadline',
                formatExtraData: { isInheritColor: true },
                disableSortBy: true
              },
              { accessor: 'weeklyDeadline', Header: 'Weekly deadline', disableSortBy: true },
              { accessor: 'action', Header: ' ', disableSortBy: true, actionCell: true },
            ]}
            wrapperClassName='u-1/1'
            modifierClasses='o-payslip-table--wrap-header-text'
            trClassName='react-bs-table__cell--whitespace--normal'
            tableHeaderClass='react-bs-table__cell--whitespace--normal'
          />
        </div>
      </div>

    </div>
  }
}

VendorServicesTree.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    serviceName: PropTypes.string,
    serviceType: PropTypes.string,
    processName: PropTypes.string,
    processKeyEvent: PropTypes.string,
    stepName: PropTypes.string,
    taskName: PropTypes.string,
    taskOwner: PropTypes.string,
    finishTaskLink: PropTypes.string,
    taskDeadline: PropTypes.number
  })),
  heading: PropTypes.string,
  onClick: PropTypes.func
}

export default VendorServicesTree
