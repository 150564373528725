import React from 'react'
import EmployeeMobileNavItem from './EmployeeMobileNavItem'

export default function EmployeeMobileNav (props) {
  return (
    <nav>
      <ul id='mobile-side-menu'>
        <li className='mobile-side-menu-header u-text--center'>
          <div className='mobile-payslip-logo c-logo c-logo--negative--full o-block' />
          <span>Menu</span>
        </li>
        {props.routes.map(route => <EmployeeMobileNavItem key={route.title} item={route} />)}
      </ul>
    </nav>
  )
}
