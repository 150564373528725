import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { LoginImage } from './LoginImage'

export const ResetPasswordWrapper = ({ children }) => (
  <div className='c-login-wrapper'>
    <LoginImage />
    <div className='c-login__content c-login__content--reset-pass'>
      <div className=''>
        <NavLink to='/' className='c-logo c-logo--full c-logo--full--large o-block u-center' />
      </div>
      {children}
    </div>
  </div>
)

ResetPasswordWrapper.propTypes = {
  children: PropTypes.node,
}

export default ResetPasswordWrapper
