import { graphTypesEnum, reportingGraphNamesEnum } from 'utils/enums/analyticsEnums'
import { ReportingAnalyticsService } from './ReportingAnalyticsService'

export class ReportingAnalyticsPieChartService extends ReportingAnalyticsService {
  /**
   * @param {array} rawData The full data set returned when getting the details from a the Reports API
   * @param {array} otherSheets The otherSheets data returned from the server reporting API
   * @param {object} mappingOptions A series of options that will be used throughout the file.  General contains logic from enums/reports.js
   * @param {object} charts The active charts for a given report.  Data is found from enums/reports.js
   * @param {object} chartLogic Chart logic that will be used throughout the Services.  Logic is currently obtained from the enums/reports.js
   */
  constructor (rawData = [], otherSheets = [], mappingOptions = {}, charts = [], chartLogic = {}) {
    super(rawData, otherSheets, mappingOptions, charts, chartLogic)
    this.filteredDownReportTypes = this.getFilteredChartTypes()
  }

  /**
   * Method to filter out the unrelated report types for a given report
   * The full details come enums/reports.js.  But this service only cares about PIe reports (graphTypesEnum.PIE_CHART)
   * @returns {array} The Pie charts that are activated for this report
   */
  getFilteredChartTypes = () => {
    const filteredCharts = Object.entries(this.chartTypes).filter(([key, value]) => value === graphTypesEnum.PIE_CHART)
    return Object.fromEntries(filteredCharts)
  }

  /**
   * Method to start process the data for this report.
   * It handles setting the data for each current report tile type
   * This includes the Totals count for Workforce, Active ESS, Inactive ESS
   * Will return a string message if no items are active
   * @returns null | string
   */
  processReportData = () => {
    if (Object.keys(this.filteredDownReportTypes).length === 0) return 'No Charts Specified'
    const data = this.options.CHART_LOGIC.OTHER_SHEETS_TOTALS ? this.otherSheetsData : this.chartData.data

    data.forEach(item => {
      if (item.total && item[this.options.CHART_LOGIC.TOTALS_KEY]) {
        if (this.filteredDownReportTypes[reportingGraphNamesEnum.EMPLOYEE_SELF_SERVICE]) {
          this.setTotalData(item)
          this.setEssPieDataActive(item)
          this.setEssPieDataInActive(item)
        }
      }
    })
  }
}
