import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { isShareAllowed } from 'containers/documents/utils/documents'
import { textLabels } from 'utils/locales/common.en'
import { GTNValidationModals } from './GTNValidationModals'
import { DocumentList } from 'components/DocumentList/DocumentList'
import { createDynamicDocumentFilterName } from 'redux/filters/createDocumentFilter'
import { documentTableHeadingTypes } from 'components/DocumentList/documentTableUtils'
import { payrollDocsMapper } from './helpers/payrollDocumentsUtils'
import { DocumentHeaderActions, DocumentsDeleteModalText, PayrollDocumentActionsRow, prepareGTNDocument } from './helpers/documentTemplates'
import { DeleteConfirmationModal } from 'components/ReusableModals/DeleteConfirmationModal'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { useDispatch } from 'react-redux'
import { useDocumentTableActions } from 'hooks/useDocumentActions'

const getOmittedColumns = ({ showImportStatus, showGlStatus, showInputsValidationReportStatus, showGTNColumn, isGtnTab, canCreate }) =>
  [
    !showImportStatus && documentTableHeadingTypes.importStatus,
    !showGlStatus && documentTableHeadingTypes.glStatus,
    !showInputsValidationReportStatus && documentTableHeadingTypes.inputsValidationReportStatus,
    !showGTNColumn && documentTableHeadingTypes.gtnReImport,
    !isGtnTab && documentTableHeadingTypes.gtnValidationStatus,
    !canCreate && documentTableHeadingTypes.action,
    documentTableHeadingTypes.category,
    documentTableHeadingTypes.payrollName,
  ]?.filter(Boolean)

const generatePayrollDocActions = (actionProps) => (data) => {
  const doc = data?.row
  const shouldShowShareButton = isShareAllowed(doc)
  const rowProps = { ...actionProps, shouldShowShareButton, doc }
  return <PayrollDocumentActionsRow {...rowProps} />
}

const PayrollDocumentsTable = (props) => {
  const dispatch = useDispatch()
  const overrideValidationModalRef = useRef()
  const shareConfirmationModalRef = useRef()
  const [document, setDocument] = useState(null)

  const {
    filterNames,
    typeClass,
    typeId,
    typeTenant,
    extraFilters,
    onDelete,
    onDownload,
    onUpload,
    openShareModal,
    openEditModal,
    openReimportGTNModal,
    openReimportGTNWhenPayrunIsUnlockedModal,
    openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
    isCot,
    isKU,
    isVendorUser,
    userSpecialRightAllPayrunDocuments,
    canCreate,
    showGlStatus,
    showInputsValidationReportStatus,
    isGtnTab,
    tabName,
    hasRightsGTNReimportButton,
    isPayrollInstanceLocked,
    isPayrollInstanceOnStepToLockUnlock,
    hasRightsToLockUnlock,
    payrollInstanceIsClosedOrWithExtraSteps,
    isLastGtnMappingReportSuccessful,
    fetchInitialPayrollDocuments,
    overrideGtnValidation,
    refetchDocuments,
  } = props
  const filterName = createDynamicDocumentFilterName([typeId, props?.tab?.name])

  const {
    documentsState,
    historyModalRef,
    deleteModalRef,
    documentLogs,
    onShareButtonClick,
    handleHistoryClick,
    onEditClick,
    handleTrashAction,
    onConfirmDelete,
  } = useDocumentTableActions({ onDelete, openShareModal, openEditModal })

  /*
   *  Conditionals
   */
  const showGTNColumn =
    hasRightsGTNReimportButton &&
    isPayrollInstanceOnStepToLockUnlock &&
    !isLastGtnMappingReportSuccessful &&
    (payrollInstanceIsClosedOrWithExtraSteps ? !isPayrollInstanceLocked : true)

  const omittedColumns = getOmittedColumns({
    showImportStatus: isGtnTab,
    showGlStatus,
    showInputsValidationReportStatus,
    showGTNColumn: isGtnTab && showGTNColumn,
    isGtnTab,
    canCreate,
  })
  /*
   * Effects
   */
  useEffect(() => {
    fetchInitialPayrollDocuments()
  }, [dispatch])

  /*
   * event actions
   */
  const handleShareButtonClick = (doc) => {
    onShareButtonClick({
      ...prepareGTNDocument(doc),
      documentId: doc.documentId,
      typeClass: doc.typeClass,
      ...(doc.typeClass === 'Payslip\\CoreBundle\\Entity\\Payroll' ? { payrollId: doc.extraData.typeObject.id } : null),
    })
  }

  const onOverrideClick = (doc) => {
    setDocument(doc)
    overrideValidationModalRef.current.showModal()
  }

  const onConfirmShare = () => {
    openShareModal({
      id: document.id,
      documentId: document.documentId,
      documentTenant: document.documentTenant,
      documentName: document.name,
      typeClass: document.typeClass,
    })
  }

  const handleEditClick = (doc) => {
    onEditClick({
      ...doc,
      ...prepareGTNDocument(doc),
      documentId: doc.documentId,
    })
  }

  /*
   * Texts info
   */
  const historyHeading = `${documentsState?.documentName} history`
  /*
   * Template declarations
   */
  const headerActions = <DocumentHeaderActions canCreate={canCreate} handleClick={onUpload} />
  const headingOptions = {
    labels: {
      [documentTableHeadingTypes.version]: isGtnTab ? textLabels.gtnVersion : textLabels.version,
    },
  }
  return (
    <>
      <DeleteConfirmationModal
        modalRef={deleteModalRef}
        onConfirmation={onConfirmDelete}
        deleteModalText={<DocumentsDeleteModalText fileName={documentsState.fileName} />}
        highlightedText={documentsState?.fileName}
      />
      <HistoryModal className='c-modal--half c-modal--overflow-y' modalRef={historyModalRef} heading={historyHeading} logs={documentLogs} />
      <GTNValidationModals
        overrideGtnValidation={overrideGtnValidation}
        overrideValidationModalRef={overrideValidationModalRef}
        shareConfirmationModalRef={shareConfirmationModalRef}
        documentId={document?.id}
        onConfirmShare={onConfirmShare}
        onRejectShare={refetchDocuments}
      />
      <DocumentList
        extraFilters={extraFilters}
        typeClass={typeClass}
        typeId={typeId}
        typeTenant={typeTenant}
        filterNames={filterNames}
        headerActions={headerActions}
        headingOptions={headingOptions}
        DocumentActions={generatePayrollDocActions({
          canCreate,
          isCot,
          userSpecialRightAllPayrunDocuments,
          handleShareButtonClick,
          handleHistoryClick,
          handleEditClick,
          handleTrashAction,
        })}
        filterName={filterName}
        tabName={tabName}
        isKU={isKU}
        isVendorUser={isVendorUser}
        omittedColumns={omittedColumns}
        onOverrideClick={onOverrideClick}
        dataMapper={(docsToMap) =>
          payrollDocsMapper(docsToMap, {
            onDownload,
            openReimportGTNModal,
            hasRightsToLockUnlock,
            openReimportGTNWhenPayrunIsUnlockedModal,
            openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
            showGTNColumn,
            isPayrollInstanceLocked,
          })
        }
      />
    </>
  )
}

PayrollDocumentsTable.propTypes = {
  extraFilters: PropTypes.object,
  filterNames: PropTypes.arrayOf(PropTypes.string),
  fetchInitialPayrollDocuments: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onUpload: PropTypes.func,
  openShareModal: PropTypes.func,
  openEditModal: PropTypes.func,
  openReimportGTNModal: PropTypes.func,
  openReimportGTNWhenPayrunIsUnlockedModal: PropTypes.func,
  canCreate: PropTypes.bool,
  isCot: PropTypes.bool,
  isGtnTab: PropTypes.bool,
  showGlStatus: PropTypes.bool,
  showInputsValidationReportStatus: PropTypes.bool,
  hasRightsGTNReimportButton: PropTypes.bool,
  isPayrollInstanceLocked: PropTypes.bool,
  isPayrollInstanceOnStepToLockUnlock: PropTypes.bool,
  openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal: PropTypes.func,
  hasRightsToLockUnlock: PropTypes.bool,
  isLastGtnMappingReportSuccessful: PropTypes.bool,
}

export default PayrollDocumentsTable
