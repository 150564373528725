import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/approvedCompanyDataProtectionFieldsPivot'

class ApprovedCompanyDataProtectionField extends CRUDModel {}
ApprovedCompanyDataProtectionField.modelName = 'ApprovedCompanyDataProtectionField'
ApprovedCompanyDataProtectionField.actionTypes = actionTypes

ApprovedCompanyDataProtectionField.fields = {
  id: attr(),
  countryDataProtectionField: fk('CountryDataProtectionField', 'approvedCompanyDataProtectionFields'),
  companyDataProtectionFieldRevision: fk('CompanyDataProtectionFieldsRevision', 'approvedCompanyDataProtectionFields')
}

export default ApprovedCompanyDataProtectionField
