import React from 'react'
import { Route } from 'react-router-dom'

const GenerateTabbedRoute = (comp, pathName, history) => {
  return (
    <Route
      exact
      key={pathName}
      path={pathName}
      children={({ match, rest }) => (
        <comp.indexRoute.component {...rest} location={location} match={match} history={history} childRoutes={comp.childRoutes} />
      )} />
  )
}
export default GenerateTabbedRoute
