import { createSelector } from 'reselect'
import { steps } from 'redux/selectors/store/steps'
import { tasks } from 'redux/selectors/store/tasks'
import { loadRef } from 'redux/helpers/loadRef'
import { completionURIs } from 'redux/config/tasks'

export const getTasks = createSelector([ tasks, steps ],
  (tasks, steps) => tasks.allIds.map(id => {
    return loadRef({
      ...tasks.byIds[id],
      finishTaskLink: completionURIs.find(mapping => mapping.value === tasks.byIds[id].finishTaskLink).uri
    }, [{
      ref: 'step',
      entity: steps
    }])
  })
    .reverse()
)
