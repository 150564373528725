import createSelector from 'utils/createSelector'
import { uniq } from 'utils/fnkit/array'

import { makeFilteredIdsByFilter } from './filters'
import { getDocTypeLabel } from 'utils/enums/documentTypes'

const getDocumentId = (state, props) => props.documentId
// get filtered ids, by passed in filter
const getFilteredIdsByFilter = makeFilteredIdsByFilter('document')

const getDocSection = (doc) => {
  const docTypes = doc?.documentTypes
  return docTypes ? uniq(docTypes.map((type) => getDocTypeLabel(type))).join(', ') : ''
}

const getDocTypesText = (document, DocumentType) =>
  document.documentTypes ? document.documentTypes.map((type) => DocumentType.withId(type).text).join(', ') : []

export const getFilteredDocuments = createSelector(getFilteredIdsByFilter, ({ Document }, filteredIds) => {
  return filteredIds.map((id) => Document.withId(id).ref)
})

export const getDocumentRef = createSelector(getDocumentId, ({ Document }, id) => Document.withId(id).ref)

export const getFilteredDocumentsWithDocumentTypes = createSelector(getFilteredDocuments, ({ DocumentType }, documents) =>
  documents.map((document) => ({
    ...document,
    documentTypes: getDocTypesText(document, DocumentType),
  }))
)

export const getDocuments = createSelector(
  (state, filterName) => {
    const entity = 'document'
    return state[entity].filters[filterName]?.ids
  },
  ({ Document, DocumentType }, filteredIds = []) => {
    return Document.all()
      .toRefArray()
      .filter((doc) => filteredIds.includes(doc.id))
      .map((doc) => ({
        ...doc,
        documentType: getDocTypesText(doc, DocumentType),
        uploadedOn: doc?.uploadedOn,
        uploadedBy: `${doc.extraData.firstname} ${doc.extraData.surname}`,
        section: getDocSection(doc),
      }))
  }
)

export const getFilteredDocsCount = createSelector(
  (state, filterName) => state['document']?.filters[filterName],
  ({ Document, DocumentType }, filteredDocs) => {
    const filteredIds = filteredDocs?.ids || []
    const documents = Document.all()
      .toRefArray()
      .filter((doc) => filteredIds.includes(doc.id))
      .map((doc) => ({
        ...doc,
        documentType: getDocTypesText(doc, DocumentType),
        uploadedOn: doc?.uploadedOn,
        uploadedBy: `${doc.extraData.firstname} ${doc.extraData.surname}`,
        section: getDocSection(doc),
      }))
      .sort((a, b) => filteredIds.indexOf(a.id) - filteredIds.indexOf(b.id))

    return {
      documents,
      totalCount: filteredDocs?.totalCount,
      params: filteredDocs?.params,
    }
  }
)
