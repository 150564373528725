import { useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { isEmpty } from 'utils/fnkit/typeChecks'

const useEmployeeCustomFields = (dispatchMethod, dispatchMethodArgs, dependency = []) => {
  const [fields, setFields] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(dispatchMethodArgs)) return

    setIsFetching(true)

    dispatch(dispatchMethod(dispatchMethodArgs)).then((resp) => {
      batch(() => {
        setFields(resp)
        setIsFetching(false)
      })
    })
  }, dependency)

  return { fields, isFetchingExtraFields: isFetching }
}

export default useEmployeeCustomFields
