import React from 'react'
import { isFetching } from 'utils/redux/fetching'
import CalendarView from '../components/CalendarView'
import { connect } from 'react-redux'
import { reduxForm, change, getFormValues } from 'redux-form'
import { filterEvents } from 'redux/actions/events'
import { getEvents, getFilteredEvents } from '../selectors/events'

class CalendarContainer extends React.Component {
  render () {
    return <CalendarView {...this.props} />
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { events } = state

  const entities = [ events ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    isFiltering: state.events.isFiltering,
    tasks: state.events.filteredIds ? getFilteredEvents(state) : getEvents(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRangeChange: range => {
      dispatch(change('filterTasks', 'fromDate', range.fromDate))
      dispatch(change('filterTasks', 'toDate', range.toDate))

      dispatch((dispatch, getState) => {
        const { payrollsAndProcesses, ...rest } = getFormValues('filterTasks')(getState())

        dispatch(filterEvents(rest))
      })
    },
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'filterTasks'
})(CalendarContainer))
