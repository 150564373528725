import { mode2faLabels } from 'utils/locales/common.en'

export const mode2faOptions = [
  { value: 'totp', label: mode2faLabels.totp },
  { value: 'sms', label: mode2faLabels.sms },
]

export const mode2faEmailOption = {
  value: 'email',
  label: mode2faLabels.email,
}
