import { ActionUtility } from 'utils/redux'
import { SubmissionError } from 'redux-form'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import { normalizeSyncData, normalizeSyncAllData } from 'redux/transformers/manyToManyTransformer'
import { fetchPayrollEmployeePivot } from 'redux/actions/payrollEmployeePivot'
import { fetchPayrollPaygroupPivot } from 'redux/actions/payrollPaygroupPivot'
import { fetchPaygroups } from 'redux/actions/paygroups'
import forEach from 'lodash/forEach'
import pick from 'lodash/pick'
import { workflowTemplateEnums } from 'utils/enums/payroll'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLLS_FETCH = 'PAYROLLS_FETCH'
export const PAYROLLS_RECEIVE = 'PAYROLLS_RECEIVE'
export const PAYROLL_CREATE = 'PAYROLL_CREATE'
export const PAYROLL_UPDATE = 'PAYROLL_UPDATE'
export const PAYROLL_DELETE = 'PAYROLL_DELETE'
export const PAYROLL_EMPLOYEE_ATTACH = 'PAYROLL_EMPLOYEE_ATTACH'
export const PAYROLL_INVALIDATE = 'PAYROLL_INVALIDATE'
export const PAYROLL_SET_ACTIVE_ID = 'PAYROLL_SET_ACTIVE_ID'
export const PAYROLL_EMPLOYEE_DETACH = 'PAYROLL_EMPLOYEE_DETACH'
export const PAYROLL_PAYGROUP_ATTACH = 'PAYROLL_PAYGROUP_ATTACH'
export const PAYROLL_PAYGROUP_DETACH = 'PAYROLL_PAYGROUP_DETACH'
export const PAYROLL_PIN = 'PAYROLL_PIN'
export const PAYROLL_UNPIN = 'PAYROLL_UNPIN'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLLS_FETCH,
  receive: PAYROLLS_RECEIVE,
  create: PAYROLL_CREATE,
  update: PAYROLL_UPDATE,
  delete: PAYROLL_DELETE,
  attachEmployees: PAYROLL_EMPLOYEE_ATTACH,
  detachPayrollEmployees: PAYROLL_EMPLOYEE_DETACH,
  invalidate: PAYROLL_INVALIDATE,
  setActiveId: PAYROLL_SET_ACTIVE_ID,
  attachPaygroups: PAYROLL_PAYGROUP_ATTACH,
  detachPayrollPaygroups: PAYROLL_PAYGROUP_DETACH,
  pinPayroll: PAYROLL_PIN,
  unpinPayroll: PAYROLL_UNPIN,
}
const actionUtility = new ActionUtility(actionTypes, 'payrolls', 'payrolls', 'Payroll')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrolls = actionUtility.fetchEntities
export const deletePayroll = actionUtility.deleteEntity
export const invalidatePayrolls = actionUtility.invalidate
export const setActivePayroll = actionUtility.setActive

export const createPayroll = (entity, canUseGenerateAutoSavedFilesField) => {
  // Common fields for all Payrolls, no matter what `frequency` is selected
  let normalized = pick(entity, [
    'name',
    'shortName',
    'currency',
    'processOwner',
    'paymentProvider',
    'paymentAccount',
    'status',
    'frequency',
    'company',
    'payrollAlias',
    'payrollExternalId',
    'startDate',
    'endDate',
    'fromDate',
    'toDate',
    'payDate',
    'workflowTemplate',
    'templateTimelines',
    'workingWeek',
  ])

  // Dates fields to be normalized in the expected BE date format, before sending them to the BE API
  let datesFields = ['startDate', 'endDate']

  // According to the selected `frequency` we send different fields to the BE
  if (entity.frequency === 'Once off') {
    normalized.startDate = entity.fromDate
    normalized.endDate = entity.toDate
    normalized = { ...normalized, ...pick(entity, ['reason']) }

    datesFields = [...datesFields, 'fromDate', 'toDate', 'payDate']
  } else if (['Weekly', 'Monthly'].includes(entity.frequency)) {
    normalized = { ...normalized, ...pick(entity, ['payDateDuringPayPeriod']) }
  } else if (entity.frequency === 'Semi-monthly') {
    normalized = { ...normalized, ...pick(entity, ['secondFromDate', 'secondToDate', 'secondPayDate']) }
  } else if (entity.frequency === 'Fortnightly') {
    normalized = { ...normalized, ...pick(entity, ['payDateDuringPayPeriod']) }
  }
  // If there is no specific template selected, on the FE we show the 'Payslip Default' selection, but for the BE we have to send null
  if (entity.workflowTemplate === workflowTemplateEnums.DEFAULT) {
    normalized = { ...normalized, workflowTemplate: null }
  }

  if (canUseGenerateAutoSavedFilesField) {
    normalized = { ...normalized, ...pick(entity, ['generateAutoSavedFiles']) }
  }

  return actionUtility.createEntity(dateTransformer(normalized, datesFields))
}

export const updatePayroll = (entity) => {
  // Transform and set valid end date, if it's passed
  if (entity.hasOwnProperty('endDate')) {
    entity = dateTransformer(entity, ['endDate'])
  }

  if (entity?.payDate instanceof Date) {
    entity = dateTransformer(entity, ['payDate'])
  }
  return actionUtility.updateEntity(entity, entity.payrollId)
}

const attachEmployeesAction = (employeeSystemUsersIds, payrollId) => ({
  type: actionTypes.attachEmployees,
  payload: { payrollId, ...employeeSystemUsersIds },
})

const detachEmployeesAction = (employeeSystemUsersIds, payrollId) => ({
  type: actionTypes.detachPayrollEmployees,
  payload: { payrollId, ...employeeSystemUsersIds },
})

// attach employees to payroll
export const attachEmployees = (entity) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: normalizeSyncAllData(entity, ['prePopulatedData'], { prePopulatedData: `${entity.prePopulatedData}` }, (value) => value),
        id: entity.payrollId,
        childUri: 'employeeusers',
        actionFunc: attachEmployeesAction,
      })
    )
  }
}

/**
 * Attach employee to payroll
 *
 * For more details, why we create a similar function as `attachEmployees`,
 * please refer to `attachEmployeeToPaygroup` documentation.
 *
 * @param {Object} entity
 * @param {Number} entity.payrollId
 * @param {Number} entity.employeeId
 * @param {Object} params - Please refer to `actionUtility`. The parameters are passed down to the utility directly.
 * @returns {function(*, *, {api: *})}
 */
export const attachEmployeeToPayroll = (entity, params) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        ...params,
        entity: { data: { [entity.employeeId]: {} } },
        id: entity.payrollId,
        childUri: 'employeeusers',
        actionFunc: attachEmployeesAction,
      })
    )
  }
}

// remove employees from payroll
export const detachEmployees = (entity) => {
  let paygroupEmployees = {}
  let data = normalizeSyncData(entity, null, (value) => value)

  forEach(entity.paygroupEmployees, (value, key) => {
    if (typeof data['data'][value] !== 'undefined') {
      paygroupEmployees['id-' + value] = ['This employee is participating in the payroll via paygroup. Please remove it from the respected paygroup']
    }
  })

  if (Object.keys(paygroupEmployees).length) {
    throw new SubmissionError(paygroupEmployees)
  }

  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.detachEntities({
        entity: data,
        id: entity.payrollId,
        childUri: 'employeeusers',
        actionFunc: detachEmployeesAction,
      })
    ).then(() => {
      dispatch(fetchPayrollEmployeePivot())
    })
  }
}

const attachPaygroupsAction = (paygroupIds, payrollId) => ({
  type: actionTypes.attachPaygroups,
  payload: { payrollId, ...paygroupIds },
})

export const attachPaygroups = (entity) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: normalizeSyncData(entity),
        id: entity.payrollId,
        childUri: 'paygroups',
        actionFunc: attachPaygroupsAction,
      })
    ).then(() => {
      dispatch(fetchPayrollPaygroupPivot())
      dispatch(fetchPaygroups())
    })
  }
}

const detachPaygroupsAction = (paygroupIds, payrollId) => ({
  type: actionTypes.detachPayrollPaygroups,
  payload: { payrollId, ...paygroupIds },
})

export const detachPaygroups = (entity) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.detachEntities({
        entity: normalizeSyncData(entity),
        id: entity.payrollId,
        childUri: 'paygroups',
        actionFunc: detachPaygroupsAction,
      })
    ).then(() => {
      dispatch(fetchPayrollPaygroupPivot())
      dispatch(fetchPaygroups())
    })
  }
}

const pinPayrollAction = (pined, payrollId) => ({
  type: actionTypes.pinPayroll,
  payload: { payrollId, ...pined },
})

export const pinPayroll = (id, pinStatus, shouldFetch = true, shouldInvalidate = false) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: { pin: pinStatus },
        id: id,
        childUri: 'pin',
        actionFunc: pinPayrollAction,
        shouldFetch: shouldFetch,
        shouldInvalidate: shouldInvalidate,
      })
    )
  }
}
