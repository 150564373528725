import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeCountryPivot'

class EmployeeCountry extends CRUDModel {}
EmployeeCountry.modelName = 'EmployeeCountry'

EmployeeCountry.fields = {
  id: attr(),
  country: fk('Country', 'employeeCountry'),
  employee: fk('EmployeeSystemUser', 'employeeCountry')
}
EmployeeCountry.actionTypes = actionTypes

export default EmployeeCountry
