import { isEmpty } from 'lodash'

export const fetchSummaryNotes =
  (payrollInstanceId) =>
    (dispatch, getState, { api }) =>
      api.fetch(`payrollinstances/${payrollInstanceId}/summarynotes`).then((res) => res)

export const saveSummaryNotes =
  (payrollInstanceId, data = []) =>
    (dispatch, getState, { api }) => {
      const normalizedData = data.map((d) => ({
        itemKey: d.itemKey,
        itemType: d.itemType,
        body: d.body,
      }))
      return api.patch(`payrollinstances/${payrollInstanceId}/summarynotes`, { payload: normalizedData }).then((res) => ({
        hasErrors: !isEmpty(res?.errors),
        data: res,
      }))
    }
