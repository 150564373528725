import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/holidays'

class Holiday extends CRUDModel {}
Holiday.modelName = 'Holiday'

Holiday.fields = {
  id: attr(),
  country: fk('Country', 'holidays'),
}
Holiday.actionTypes = actionTypes

export default Holiday
