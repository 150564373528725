import React, { useRef } from 'react'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import BankAccountContainer from 'containers/BankAccountContainer'
import BankAccountFormCreateContainer from 'containers/BankAccountFormCreateContainer'
import Modal from 'components/Modal'

const RouteView = (props) => {
  const modalCreateBankAccount = useRef(null)

  return <>
    <SectionHeading text='Bank details'>
      <Authorization permissions={['BANKACCOUNT_CREATE']}>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            <div
              onClick={() => modalCreateBankAccount.current.showModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
              title='Add'
              data-testid='bank-account-create'
            >
              <span className='icon icon--plus' />
            </div>
          </div>
        </div>
      </Authorization>
    </SectionHeading>
    <div className='o-layout'>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
        <BankAccountContainer {...props} isCompany />
      </div>
    </div>

    <Modal
      ref={modalCreateBankAccount}
      className='c-modal c-modal--full'
      modalHeading='Add bank account'
    >
      <BankAccountFormCreateContainer
        isCompanyBankCreate
        onSubmit={() => modalCreateBankAccount.current.hideModal()}
        {...props}
      />
    </Modal>
  </>
}

export default RouteView
