import createSelector from 'utils/createSelector'
import _ from 'lodash'

// get company id
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

export const getCountryServicesByCompanyAvailableForAttaching = createSelector(getCompanyId,
  ({ Company, CountryService }, companyId) => {
    // get company
    let company = Company.withId(companyId)

    // get ids of country services already attached to company
    let attachedCountryServices = _.map(company.countryServices.toRefArray(), 'id')

    // return all country services from the company country
    // that aren't assigned to the company
    // and have a vendor who's doing the job
    return CountryService
      .filter(
        countryService =>
          countryService.country === company.ref.country &&
          !_.includes(attachedCountryServices, countryService.id)
      )
      .toModelArray()
      .filter(
        countryService =>
          countryService
            .vendorCountryServices
            .toModelArray()
            .length
      )
      .map(countryService => ({
        ...countryService.ref,
        service: {
          ...countryService.service.ref
        }
      }))
  }
)
