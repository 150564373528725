import { Model } from 'redux-orm'

/**
 * Create a CRUDModel for handling default CRUD reducers
 *
 * CRUDModel has the same concept as utils/redux/reducer-orm.js
 * Unfortunately I didn't manage to reuse it, so creating a base class
 * is a workaround.
 */
export class CRUDModel extends Model {
  static baseHandlers (Model) {
    return {
      [Model.actionTypes.receive]: (Model, { payload: { response, filter, prevFilterState } }) => {
        // Delete the obsoleted Model instances, according to the new data, received via the API
        CRUDModel.deleteObsoletedEntities(Model, response, filter, prevFilterState)

        // Insert the response in our Models and update existing Model instances
        response.data.map(entity => Model.upsert(entity))
      },
      [Model.actionTypes.filtered]: (Model, { payload }) => {
        payload.data.data.map(entity => Model.upsert(entity))
      },
      [Model.actionTypes.reset]: Model => Model.all().delete(),
    }
  }

  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model)
    }
  }

  static reducer (action, Model, session) {
    const handlers = Model.handlers(Model)
    const handler = handlers[action.type]

    handler && handler(Model, action)
  }

  /**
   * Delete the obsoleted Model instances, according to the new data, received via the API
   *
   * Example 1: This is the most common use-case. Imagine we decide to delete an employee.
   * We send an API DELETE request and the employee will deleted on the BE.
   * But on the FE will be still kept, because on DELETE - we don't remove it from local Store,
   * because of a legacy decision.
   * Instead of deleting it locally, we fetch again the employees and find out which employees are obsoleted locally
   * and remove them via this mechanism.
   * Even we refactor and make it better, this obsolete mechanism is required by Example 2.
   *
   * Example 2: Imagine we have 3 employees already fetcher locally.
   * Then someone deletes one of them on their FE client.
   * After that we decide to fetch all the employees again.
   * In that case (without having the below obsolete mechanism or having a real-time communication),
   * our local employees will continue exist, because `Model.upsert(entity)` do create or update only.
   *
   * Example 3: We do a bulk request.
   * Having a list of Bussiness Units and their Action Owners, we can Add or Remove owners in 1 single request.
   * In this case, after the bulk request is completed, we just refetch the pivot table (BUnits <- pivot -> Owners)
   * and the local state becomes up-to-date. Otherwise - we have to determine which pivot records are
   * added and deleted manually.
   *
   * @param {Object} Model - specific Model instance, such as Employee, Company and etc.
   * @param {Object} response - BE response
   * @param {Object|undefined} filter - The filter, by which the response is filtered and returned by the BE
   * @param {Object|undefined}  prevFilterState - Previous filtered meta state of the Store entity, by the same filter
   */
  static deleteObsoletedEntities (Model, response, filter, prevFilterState) {
    // Don't have a filter? It means - we fetch all the Models' instances.
    // Because of this we have to check all the Models for obsoleted instances `Model.all()`.
    if (!filter) {
      Model.all().filter(model => !response.data.find(entity => entity.id === model.id)).delete()
    }

    // Having previous filter ids, we apply the same obsolete flow, but now looking in the filtered ids.
    // For example: if we filter the Employees by `companyId = 1` and have the following response `[1, 2, 3]` initially,
    // and later do the exactly same filtration, but the new response is `[1, 2]`,
    // then we assume that `Employee.withId(3)` is deleted.
    // **Important note**:
    // If we don't trigger the obsolete mechanism, in the case we do a filtration,
    // it's possible the `redux-orm` Models to be out-of-sync with `reducer-meta` filtered ids.
    // For example - let's say we have an Employees list + their Bank Accounts data.
    // Let's say we fetch all the Employees (no filter applied), but because we have many Bank Accounts records,
    // we decide to do a filtration by `companyId`.
    // The selector of getting the Employees with their Bank accounts would be something like:
    // `Employees.toModelArray().map( e => ({ ...e, bank: e.bankAccounts.toRefArray() }) )`
    // All's good! But now we decide to delete an Employee's Bank account.
    // The DELETE request is successful and the next step of our flow is to invalidate all the Bank Accounts. Cool!
    // The invalidation will trigger refetching / refiltering Bank Accounts by `companyId`
    // and the filtered Bank accounts ids will be kept in the Store's entity meta.
    // BUT the `redux-orm` Bank Account Model will still keep the deleted Bank Account.
    if (prevFilterState && prevFilterState.ids) {
      // Find the ids in the previous filtered state,
      // these don't exist in the new filtered response, filtered by the same criteria.
      // Having such nonexistent ids, we assume they are deleted on the BE and obsoleted here on the FE.
      const obsoleted = prevFilterState.ids.filter(prevId => !response.data.find(entity => entity.id === prevId))

      // Remove the obsoleted ids from the local Model
      Model.all().filter(model => obsoleted.includes(model.id)).delete()
    }
  }
}
