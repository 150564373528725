import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'

const MainNavItem = (props) => {
  const [showChildren, setShowChildren] = useState(false)
  const preventNavigationOnClick = (e) => {
    e.preventDefault()
  }

  const { path, isLink = true, name, title, children, sub, onClick, exact = false, isActive, dataTestId = '', isExternal = false } = props
  let menuNavClick = isLink ? onClick : preventNavigationOnClick
  let menuLink = !isExternal ? (
    <NavLink to={isLink ? path : '/home'} onClick={menuNavClick} className='o-block o-block--left' exact={exact} isActive={isActive} data-testid={dataTestId}>
      {!sub ? (
        <span
          className={`nav-icon nav-icon--${name}
            o-block u-float--left u-margin-right-small u-margin-left-tiny`}
        />
      ) : null}
      <span className='nav-label'>{title}</span>
      {children ? <span className='nav-arrow' /> : null}
    </NavLink>
  ) : (
    <a href={path} className='o-block o-block--left'>
      <span
        className={`nav-icon nav-icon--${name}
            o-block u-float--left u-margin-right-small u-margin-left-tiny`}
      />
      <span className='nav-label'>{title}</span>
    </a>
  )

  return (
    <span className={children ? `nav-item has-children ${showChildren ? 'expanded' : ''}` : 'nav-item'}>
      <li
        className={children ? `o-list-bare menu-link has-children` : 'o-list-bare menu-link no-children'}
        onClick={children ? () => setShowChildren(!showChildren) : null}
      >
        {menuLink}
      </li>
      {children && <li className='o-list-bare'>{children}</li>}
    </span>
  )
}

MainNavItem.contextTypes = { router: PropTypes.object }

MainNavItem.propTypes = {
  path: PropTypes.string,
  isLink: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  sub: PropTypes.bool,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  isActive: PropTypes.func,
  dataTestId: PropTypes.string,
}

export default withRouter(MainNavItem)
