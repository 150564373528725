import React from 'react'
import { loginImages } from 'utils/enums/loginImages'
import { randomIntFromInterval } from 'utils/number'

export const LoginImage = () => {
  const rndInt = randomIntFromInterval(0, loginImages.length - 1)
  const image = loginImages[rndInt]
  const imageURL = `url(${(image && image.URL) || loginImages[3].URL})`
  const imageDescription = image.DESCRIPTION

  return <div className='c-login__image' style={{ backgroundImage: imageURL }}>
    <div className='login-img' style={{ backgroundImage: imageURL }} />
    <span className='login-img-description'>{imageDescription}</span>
  </div>
}

export default LoginImage
