import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payfileChanges'
import { baseReducerHandlers } from './payrun'

class PayfileChange extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      ...baseReducerHandlers(Model)
    }
  }
}
PayfileChange.modelName = 'PayfileChange'

PayfileChange.fields = {
  id: attr(),
}
PayfileChange.actionTypes = actionTypes

export default PayfileChange
