import React from 'react'
import { getSubmitButtonOptions } from '@rjsf/utils'
import { buttonActions } from 'utils/locales/common.en'

export const SubmitButtonTemplate = (props) => {
  const { uiSchema } = props
  const { norender, ...options } = getSubmitButtonOptions(uiSchema)
  const { className = '', containerClassName = '', buttonProps = {} } = options

  if (norender) {
    return null
  }

  return (
    <div className={containerClassName}>
      <button className={`c-btn rounded-md ${className}`} type='submit' {...buttonProps}>
        {options?.label ?? buttonActions.save}
      </button>
    </div>
  )
}
