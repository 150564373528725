import React from 'react'
import { FadeModal as Modal } from 'boron-15'

let contentStyle = {
  backgroundColor: '$color-gallery'
}

class MessageModal extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal () {
    this.refs.modal.show()
  }

  hideModal () {
    this.refs.modal.hide()
  }

  render () {
    return <div data-testid='message-modal'>
      <Modal
        contentStyle={contentStyle}
        ref='modal'
        {...this.props}
      >
        <span onClick={this.hideModal} className='icon--ex' data-testid='modal-close' />
        {
          this.props.modalHeading
            ? <div className='o-layout'>
              <div className='o-layout__item u-text--center u-1/1'>
                <span className='u-text--center u-text--huge u-weight--bold'>{this.props.modalHeading}</span>
              </div>
            </div>
            : null
        }
        <div className='o-layout'>
          <div className='o-layout__item u-text--center u-1/1'>
            <p className='u-text--center u-margin-none u-padding' data-testid='modal-body'>{this.props.message}</p>
          </div>
        </div>
      </Modal>
    </div>
  }
}

export default MessageModal
