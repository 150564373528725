import { formValueSelector } from 'redux-form'

export const hasEmployees = (state, formName, employees) => {
  let formValues = formValueSelector(formName)

  let employeeFormFields = employees.map(employee => {
    return formValues(state, `id-${employee.id}`)
  })

  return employeeFormFields.some(employeeField => employeeField)
}
