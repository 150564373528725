import { getParentCompanyById } from '../selectors/parentCompanies'
import { isArray } from 'utils/fnkit/typeChecks'

export const getTenantSSOStatusObject = (state) => {
  const defaultResponse = {
    defaultSSOEnabledFor: null,
    isTenantSSOEnabled: false,
  }
  if (!state.parentCompanies || !isArray(state.parentCompanies.allIds)) return defaultResponse

  const parentCompaniesData = state.parentCompanies.allIds.map((id) => state.parentCompanies?.byIds[id])
  if (parentCompaniesData.length === 0) return defaultResponse

  const tenant = state.tenants?.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }) : parentCompaniesData[0]

  return {
    defaultSSOEnabledFor: tenant.defaultSSOEnabledFor,
    isTenantSSOEnabled: tenant.isTenantSSOEnabled,
  }
}
