import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const ANNUAL_SALARIES_FETCH = 'ANNUAL_SALARIES_FETCH'
export const ANNUAL_SALARIES_RECEIVE = 'ANNUAL_SALARIES_RECEIVE'
export const ANNUAL_SALARIES_INVALIDATE = 'ANNUAL_SALARIES_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: ANNUAL_SALARIES_FETCH,
  receive: ANNUAL_SALARIES_RECEIVE,
  invalidate: ANNUAL_SALARIES_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'annualSalaries', 'annualsalaries', 'AnnualSalary')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchAnnualSalariesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchAnnualSalaries = actionUtility.fetchEntities
export const invalidateAnnualSalaries = actionUtility.invalidate
