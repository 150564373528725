import { ActionUtility } from 'utils/redux'
import { SubmissionError } from 'redux-form'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  reset: CHANGE_PASSWORD
}

// We will be removing this functionality with the advent of SSO
// So no real need now to adjust lint errors
// eslint-disable-next-line no-unused-vars
const actionUtility = new ActionUtility(actionTypes)

// ------------------------------------
// Thunk
// ------------------------------------
export const changePassword = (data) => {
  const hasErrors = (data.password !== data.passwordConfirmation)

  if (hasErrors) {
    throw new SubmissionError({
      passwordConfirmation: 'The passwords do not match'
    })
  }

  return (dispatch, getState, { api }) => {
    dispatch(changePassword)

    return api.post('auth/password/change', { payload: data })
      .then(errorsHandling.handleFormErrors)
  }
}
