import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/tasks'

export const sortASCByPosition = (a, b) => a.position - b.position
// This is a quick solution for sorting by PayrollInstance Step position and Task position.
// It's a quick and dirty, because we rely on the task `alphabetic` representation.
// Each task `alphabeticName` starts with `${stepAlphabeticPosition}.${taskName}`, i.e. `A1.Dummy task, A2.Another one`.
// Getting the first 2 chars, i.e. `A1` we can sort the task correctly.
// However, this sorting should happen on BE. And also on the FE we should change our `query-string` library, in order
// to support nested sorting params. We already have started refactoring the library in another feature.
// Once we're ready and it's a priority - we'll refactor all the usages of `sortASCByAlphabeticName` to use the BE sort.
export const sortASCByAlphabeticName = (a, b) => a.alphabeticName.substring(0, 2) - b.alphabeticName.substring(0, 2)

class Task extends CRUDModel {}
Task.modelName = 'Task'
Task.actionTypes = actionTypes

Task.fields = {
  id: attr(),
  step: fk('Step', 'tasks')
}

export default Task
