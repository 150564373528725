import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchCompanyServicePivotIfNeeded } from 'redux/actions/companyServicePivot'
import { fetchVendorCountryServicePivotIfNeeded } from 'redux/actions/vendorCountryServicePivot'
import { isFetching } from 'utils/redux/fetching'
import { getCountryServicesByCompanyAvailableForAttaching } from '../selectors/countryServices'
import { attachCountryServices } from 'routes/Companies/modules/actions'
import AssignCountryServices from '../components/AssignCountryServices'

class AssignCountryServicesContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchServicesIfNeeded()
    this.props.fetchCompanyServicePivotIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
  }

  render () {
    return <AssignCountryServices {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
    fetchCompanyServicePivotIfNeeded: () => dispatch(fetchCompanyServicePivotIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
    dispatch,
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { countryServicePivot, services, companyServicePivot, vendorCountryServicePivot } = state
  const entities = [countryServicePivot, services, companyServicePivot, vendorCountryServicePivot]

  if (isFetching(entities)) return { isFetching: true }

  // get country services
  let countryServices = getCountryServicesByCompanyAvailableForAttaching(state, {
    companyId: props.companyId
  })

  return {
    countryServices,
    onSuccess: props.onSuccess,
    initialValues: {
      companyId: props.companyId
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { onSuccess } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: data => dispatch(attachCountryServices(data)).then(onSuccess)
  }
}

AssignCountryServicesContainer.propTypes = {
  companyId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'assignCountryServices',
})(AssignCountryServicesContainer))
