import React from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import { createCountry } from 'routes/Countries/modules/actions'
import Fetcher from 'containers/Fetcher'
import CountryForm from '../components/CountryForm'
import { getTimezones } from 'redux/selectors/timezones'
import { getCurrencies } from 'redux/selectors/currencies'
import { getLanguages } from 'redux/selectors/languages'
import countries from 'redux/config/allCountries'
import { invalidateCountryTimezonePivot } from 'redux/actions/countryTimezonePivot'

class CountryFormCreateContainer extends React.Component {
  render () {
    return <CountryForm {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  return {
    currencies: getCurrencies(state),
    timezones: getTimezones(state),
    languages: getLanguages(state),
    allCountries: countries,
    initialValues: { abbreviature: undefined }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onChange: () => {
      dispatch((dispatch, getState) => {
        const selector = formValueSelector('countryCreateForm')
        const country = selector(getState(), 'name')
        const code = countries.find(c => c.name === country)?.code || undefined
        dispatch(change('countryCreateForm', 'abbreviature', code))
      })
    },
    onSubmit: data => {
      return dispatch(createCountry(data)).then(() => {
        props.onCreate()
        dispatch(invalidateCountryTimezonePivot())
      })
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'countryCreateForm',
})(CountryFormCreateContainer))

export default Fetcher(Container, [
  'currencies',
  'languages',
  'timezones',
])
