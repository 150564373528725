import React from 'react'
import PropTypes from 'prop-types'
import TabItem from '../components/TabItem'
import Slider from 'components/Slider'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom'
import generateAllRoutes from 'utils/routeGenerator'

export const Tabs = ({ children, tabs, isCot, ...otherProps }) => {
  let history = useHistory()
  let { path } = useRouteMatch()
  const renderedTabs = tabs.map(tab => {
    return tab.name
      ? <TabItem to={tab.to} key={tab.name} data-testid={tab['data-testid']} {...otherProps}
      >
        {tab.name}
      </TabItem>
      : null
  })

  let routes = []
  if (otherProps.childRoutes) {
    otherProps.childRoutes.forEach(child => {
      routes = generateAllRoutes(child, path, routes, history)
    })
  }

  return <div className='tabs-container'>
    <Slider
      className='c-tabs__list'
      dots={false}
      infinite={false}
      slidesToShow={renderedTabs.length}
      slidesToScroll={3}
      responsive={[
        {
          breakpoint: 1905,
          settings: {
            // Check if currently logged user is COT and limit the number of tabs to be
            // max 5 because of greater length of tabs' text, in other case - max 8
            slidesToShow: isCot
              ? (renderedTabs.length > 10 ? 10 : renderedTabs.length)
              : (renderedTabs.length > 8 ? 8 : renderedTabs.length),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1620,
          settings: {
            // Check if currently logged user is COT and limit the number of tabs to be
            // max 5 because of greater length of tabs' text, in other case - max 8
            slidesToShow: isCot
              ? (renderedTabs.length > 5 ? 5 : renderedTabs.length)
              : (renderedTabs.length > 8 ? 8 : renderedTabs.length),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1370,
          settings: {
            // Check if currently logged user is COT and limit the number of tabs to be
            // max 4 because of greater length of tabs' text, in other case - max 8
            slidesToShow: isCot
              ? (renderedTabs.length > 4 ? 4 : renderedTabs.length)
              : (renderedTabs.length > 8 ? 8 : renderedTabs.length),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1299,
          settings: {
            slidesToShow: (renderedTabs.length > 7) ? 7 : renderedTabs.length,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1160,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]}
      variableWidth>{renderedTabs}
    </Slider>

    <div className='c-tabs__panel'>
      <Switch>
        {[...routes]}
      </Switch>
      { children }
    </div>
  </div>
}

Tabs.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.array,
  isCot: PropTypes.bool
}

export default Tabs
