import React, { memo } from 'react'

import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton } from 'components/buttons'
import { buttonActions } from 'utils/locales/common.en'
import HistoryList from 'components/documents/HistoryList'

export const HistoryModal = memo(({ modalRef, className, heading, logs }) => {
  const onConfirmation = () => modalRef?.current?.hideModal()
  const headingsOverrides = {
    className: 'text-left',
    columnClassName: 'text-left',
  }
  return (
    <BaseConfirmationModal ref={modalRef} className={`c-modal c-modal--overflow-y ${className || ''}`} modalHeading={heading}>
      <HistoryList logs={logs} headingsOverrides={headingsOverrides} />
      <ConfirmButton buttonText={buttonActions.ok} onClick={onConfirmation} />
    </BaseConfirmationModal>
  )
})
