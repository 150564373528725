import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { useSelector } from 'react-redux'
import { isCot, isCotBot, isCotOrEngineerOrAdmin, isKeyUser } from 'redux/selectors/auth'
import { docTypeIDs, docTypes, docTypesOnlyCotBotCanEdit } from 'utils/enums/documentTypes'
import { getCotUser } from 'redux/selectors/cotUsers'
import { intersection } from 'utils/fnkit/array'
import { isArray, isEmpty, isString } from 'utils/fnkit/typeChecks'
import { allowedShareForGTNValidations, disableShareForGTNValidations, disableEditForGTNValidationsCOT } from 'utils/documentTypes'
import { getUser } from 'redux/selectors/employees'
import { invalidateDocuments } from 'redux/actions/document'
import { resetDocumentUsers } from 'redux/actions/documentUsers'
import { invalidateDocumentLogs } from 'redux/actions/documentLogs'
import { invalidatePayrollInstanceTaskOwner } from 'redux/actions/payrollInstanceTaskOwner'

export const excludeForFuturePayruns = ({ docTypes = [], payrunState, allowInputs = false }) => {
  if (payrunState !== payrunStatesEnum.INACTIVE) return null
  if (allowInputs) return docTypes.filter((type) => type !== 'inputs')
  return docTypes
}

/**
 * Utility to check if the logged in user can edit the provided document.
 *
 * Will only be applied if the owner of the document is the logged in user or a COT user is logged in.
 * Currently used for docTypesOnlyCotBotCanEdit list of document types, which should only be editable by the COT_BOT user, not other COT users
 * Beneficiary file and Bank account can be edited by COT if they have special rights for those files.
 * Special right allPayrunDocuments allows the users to edit
 * @param {Object} document object
 * @returns {Boolean}
 */

export const documentsEditableByCotAndSpecialRight = [
  docTypes.BANK_FILE_VALIDATION_REPORT,
  docTypes.PAYSLIPS_VALIDATION_REPORT,
  docTypes.PAYSLIPS_HCM_REPORT,
  docTypes.TAX_DOCS_VALIDATION_REPORT,
  docTypes.TAX_DOCS_HCM_REPORT,
]

export const canLoggedInUserEditDocumentByType = (doc, isOwner = false) => {
  if (isEmpty(doc.type)) return true
  const isCotUser = useSelector((state) => isCot(state))
  const isCotBotUser = useSelector((state) => isCotBot(state))
  const isCotOrEngineerOrAdminUser = useSelector((state) => isCotOrEngineerOrAdmin(state))
  const cotUserSpecialRights = isCotOrEngineerOrAdminUser && useSelector((state) => getCotUser(state, { userId: state.auth.userId }).cotSpecialRight)
  const user = useSelector((state) => isKeyUser(state) && getUser(state, { userId: state.auth.userId }))
  const userSpecialRights = user?.specialRight

  if (docTypesOnlyCotBotCanEdit.some((dt) => doc.type.includes(dt))) return isCotBotUser || userSpecialRights?.allPayrunDocuments
  if (doc.type.includes(docTypes.BENEFICIARY_FILE)) {
    return isCotBotUser || cotUserSpecialRights?.beneficiaryFile || isOwner || userSpecialRights?.allPayrunDocuments
  }
  if (doc.type.includes(docTypes.BANK_FILE)) return isCotOrEngineerOrAdminUser ? cotUserSpecialRights?.bankFile : true
  if (doc.type.split(',').includes(docTypes.DATA_MIGRATION)) return isCotBotUser || cotUserSpecialRights?.dataMigration

  if (documentsEditableByCotAndSpecialRight.some((type) => doc.type.includes(type))) {
    return isCotUser || userSpecialRights?.allPayrunDocuments
  }

  const isGTNdoc = doc.type
    .split(',')
    .map((type) => type.trim())
    .includes(docTypes.GTN)
  if (isGTNdoc && isOwner) return true
  if (isGTNdoc && isCot) return !disableEditForGTNValidationsCOT.includes(doc.gtnValidationStatus)
  return true
}

export const canLoggedInUserDownloadDocumentByType = (docType) => {
  const isCotOrEngineerOrAdminUser = useSelector((state) => isCotOrEngineerOrAdmin(state))
  const isKU = useSelector((state) => isKeyUser(state))
  const isCotBotUser = useSelector((state) => isCotBot(state))
  const cotUserSpecialRights = isCotOrEngineerOrAdminUser && useSelector((state) => getCotUser(state, { userId: state.auth.userId }).cotSpecialRight)

  // COT Users other then CotBot should not be able to download the "Additional Input" document type
  // Beneficiary file can only be downloaded by Key Users, Cot Bot and Cot special rights user
  // Bank files can't be downloaded by COT users, unless they have special rights
  // Bank file validation report is explicitly here to not be triggered by the bank file check due to similar name
  // Data migration can only be downloaded by COT Bot or dataMigration special right Cot user
  if (docType.includes(docTypes.BANK_FILE_VALIDATION_REPORT)) return true
  if (docType.includes(docTypes.ADDITIONAL_INPUTS)) return !isCotOrEngineerOrAdminUser
  if (docType.includes(docTypes.BENEFICIARY_FILE)) {
    return isKU || isCotBotUser || cotUserSpecialRights?.beneficiaryFile
  }
  if (docType.includes(docTypes.BANK_FILE)) {
    return isCotOrEngineerOrAdminUser ? cotUserSpecialRights?.bankFile : true
  }
  if (isString(docType) && docType.split(',').includes(docTypes.DATA_MIGRATION)) {
    return isCotBotUser || cotUserSpecialRights?.dataMigration
  }
  return true
}

/**
 * Utility to check if a certain file can be shared or not
 * Used for:
 * Data migration document type which shouldn't be shareable at any time
 * GTN documents which should be only sharable based on current validation status and ownership if on files table, on upload share is allowed
 *
 * Share can to be disabled in two places:
 * 1. On successful upload there is a share modal
 * 2. On the files table there is a share button
 *
 * Note:
 * On upload the documentTypes is an array of IDs
 * On the payroll instance table the documentTypes is a string
 * On other document tables there is no documentTypes at all
 * This function will cover all cases so we can reuse it and localize the conditions
 */
const docTypeIdsNotAllowedShare = [docTypeIDs.DATA_MIGRATION, docTypeIDs.DATA_MIGRATION_VALIDATION_REPORT]

const docTypesNotAllowedShare = [docTypes.DATA_MIGRATION, docTypes.DATA_MIGRATION_VALIDATION_REPORT]

export const isShareAllowed = (doc, isCotUser = false) => {
  // share modal logic
  if (isArray(doc?.documentTypes)) return isEmpty(intersection([doc.documentTypes, docTypeIdsNotAllowedShare]))

  // files table logic
  if (doc?.documentTypes) {
    const isGTNdoc = doc.documentTypes
      .split(',')
      .map((type) => type.trim())
      .includes(docTypes.GTN)
    const hasGTNValidationStatus = isGTNdoc && doc.gtnValidationStatus
    const canShareGTN =
      hasGTNValidationStatus &&
      (doc.extraData.isOwner || isCotUser || allowedShareForGTNValidations.includes(doc.gtnValidationStatus)) &&
      !disableShareForGTNValidations.includes(doc.gtnValidationStatus)
    const canShare = isEmpty(intersection([doc.documentTypes.split(','), docTypesNotAllowedShare])) && (hasGTNValidationStatus ? canShareGTN : true)

    return canShare
  }
  return true
}

// A collection of invalidations that has to execute after a document is shared
export const afterShareActions = (dispatch, onShared) => {
  onShared()
  dispatch(invalidateDocuments())
  dispatch(resetDocumentUsers())
  dispatch(invalidateDocumentLogs())
  dispatch(invalidatePayrollInstanceTaskOwner())
}
