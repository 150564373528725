import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import AccordionContainer from 'containers/AccordionContainer'
import MobileDetailsView from 'components/MobileDetailsView'
import SectionHeading from 'components/SectionHeading'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'

class TimeAttendanceSummary extends React.Component {
  renderHeadings () {
    // init first row headings
    let headings = [
      {
        accessor: 'payrollName',
        Header: 'Payroll Name',
        headingColClass: 'fixed-width--150',
        disableSortBy: true,
        disableFilters: true
      },
      {
        accessor: 'payPeriodDates',
        Header: 'Pay Period Dates',
        headingColClass: 'fixed-width--150',
        disableSortBy: true,
        disableFilters: true
      },
      {
        accessor: 'payDates',
        Header: 'Pay Date',
        disableSortBy: true,
        disableFilters: true
      },
      {
        accessor: 'details',
        Header: 'Details',
        disableSortBy: true,
        disableFilters: true
      }
    ]

    return headings
  }

  /**
   * Render rows for table
   * @param employee - the employee we're displaying
   * @param payrollInstances - employee's payroll instances
   * @return [data]
   */
  renderRows (employee, payrollInstances) {
    let rows = []
    payrollInstances.forEach(pi => {
      const data = {}
      // fill in data
      const payPeriod = `${moment(pi.fromDate.date).format('DD/MM/YY')} - ${moment(pi.toDate.date).format('DD/MM/YY')}`
      data['payrollName'] = pi.payrollName
      data['payPeriodDates'] = payPeriod
      data['payDates'] = moment(pi.payDate.date).format('DD/MM/YY')
      data['totalOvertime'] = pi.overtimeHours
      data['details'] = <Link
        to={`/employees/${employee.id}/time-attendance/${pi.id}/change-details`}
        className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-small'
      >
        View
      </Link>

      rows.push(data)
    })
    return rows
  }

  renderMobileRows () {
    const { employeePI } = this.props
    let attn = []
    employeePI.map((pi, i) => {
      // add empty line to visually separate payroll instances
      if (i > 0) attn.push({})
      // add payroll name and period
      attn.push(
        { 'Payroll Name': pi.payrollName },
        { 'Payroll Period': `${moment(pi.fromDate.date).format('DD/MM/YY')} - ${moment(pi.toDate.date).format('DD/MM/YY')}` },
        { 'Pay Date': `${moment(pi.payDate.date).format('DD/MM/YY')}` }
      )
    })
    return attn
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <div>
      <div className='u-hide-on-mobile'>
        <SectionHeading text='Summary by Payrun' />
        <PayslipTable
          trClassName='react-bs-table__cell--overflow-ellipsis'
          wrapper='o-table--tiny o-table--striped'
          headings={this.renderHeadings()}
          data={this.renderRows(this.props.employee, this.props.employeePI)}
        />
      </div>
      <div className='u-hide-on-desktop'>
        <AccordionContainer title='Summary by Payrun' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={this.renderMobileRows()} />
        </AccordionContainer>
      </div>
    </div>
  }
}

TimeAttendanceSummary.propTypes = {
  employee: PropTypes.object,
  employeePI: PropTypes.array,
}

export default TimeAttendanceSummary
