import React from 'react'
import { useAsyncDebounce } from 'react-table'

export const PayslipTableSearch = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 300)

  return (
    <div className='row'>
      <div className='col-xs-6 col-sm-6 col-md-6 col-lg-8'>
        <div className='form-group form-group-sm o-payslip-table-search-form'>
          <input
            className='form-control rounded'
            placeholder='Search'
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value)
              onChange(e.target.value)
            }}
          />

          <span className='input-group-btn' />
        </div>
      </div>
      <div className='col-xs-6 col-sm-6 col-md-6 col-lg-4'>
        <div className='btn-group btn-group-sm' role='group' />
      </div>
    </div>
  )
}
