import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_TASK_OWNER_SCOPE_FETCH = 'PAYROLL_INSTANCE_TASK_OWNER_SCOPE_FETCH'
export const PAYROLL_INSTANCE_TASK_OWNER_SCOPE_RECEIVE = 'PAYROLL_INSTANCE_TASK_OWNER_SCOPE_RECEIVE'
export const PAYROLL_INSTANCE_TASK_OWNER_SCOPE_UPDATE = 'PAYROLL_INSTANCE_TASK_OWNER_SCOPE_UPDATE'
export const PAYROLL_INSTANCE_TASK_OWNER_SCOPE_INVALIDATE = 'PAYROLL_INSTANCE_TASK_OWNER_SCOPE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_TASK_OWNER_SCOPE_FETCH,
  receive: PAYROLL_INSTANCE_TASK_OWNER_SCOPE_RECEIVE,
  update: PAYROLL_INSTANCE_TASK_OWNER_SCOPE_UPDATE,
  invalidate: PAYROLL_INSTANCE_TASK_OWNER_SCOPE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes,
  'payrollInstanceTaskOwnerScope', 'payrollinstancetaskownerscopes', 'PayrollInstanceTaskOwnerScope')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceTaskOwnerScopeIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceTaskOwnerScope = actionUtility.fetchEntities
export const createPayrollInstanceTaskOwnerScope = actionUtility.createEntity
export const updatePayrollInstanceTaskOwnerScope = actionUtility.updateEntity
export const invalidatePayrollInstanceTaskOwnerScope = actionUtility.invalidate
