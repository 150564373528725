import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { sortByLabel } from 'utils/strings'

const Filter = (props) => {
  const { companies, companyId, payrolls } = props

  return <>
    <SectionHeading text='Choose Company' />
    <Form {...props}>
      <div className='o-layout'>
        <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='company'
              label='Company *'
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              options={companies.map(company => ({
                value: company.id,
                label: company.name
              })).sort(sortByLabel)}
              data-testid='company-wrapper'
            />
          </div>
        </div>
        {
          companyId &&
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='payroll'
                  label='Payroll *'
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  options={payrolls}
                  data-testid='payroll-wrapper'
                />
              </div>
            </div>
        }
      </div>
    </Form>
  </>
}

Filter.propTypes = {
  // All reporting types
  companies: PropTypes.array,
  companyId: PropTypes.number,
  payrolls: PropTypes.array
}

export default Filter
