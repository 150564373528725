import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/generalLedgerGlobalData'

class GeneralLedgerGlobalData extends CRUDModel {}
GeneralLedgerGlobalData.modelName = 'GeneralLedgerGlobalData'
GeneralLedgerGlobalData.actionTypes = actionTypes

GeneralLedgerGlobalData.fields = {
  id: attr(),
  company: fk('Company', 'generalLedgerGlobalData'),
}

export default GeneralLedgerGlobalData
