import createSelector from 'utils/createSelector'

export const makePayrollInstanceTasksInitials = selector => createSelector(selector,
  (session, tasks) => {
    let formInitials = {}

    tasks.forEach(task => {
      // We've started experimenting with such JSON stringified structures,
      // in order to find an easier way for passing multiple values for fields' names.
      // Also having such structure, it's easier for parsing the String field name and accessing its JSON properties.
      // In this case there's another Gotcha too.
      // If we pass a numeric value for `id`, instead of passing a stringified value,
      // let's say `id = 1000`, then `redux-form` reducer will create 999 empty key:value pairs in the Store.
      // Only the record with `id = 1000` will have the below object value.
      // Because of this, we prefer to pass a stringified value here.
      // About the first gotcha (handling multidimensional field names), you can read more here:
      // @link: https://gitlab.com/dev-labs-bg/payslip-frontend/-/wikis/Gotchas#forms-field-names
      const id = JSON.stringify({
        id: task.id
      })

      formInitials[id] = {
        id: task.id,
        status: task.status,
        isReversible: task.isReversible,
        isEditable: task.isEditable,
        draftableTasks: task.draftableTasks,
        draftableTasksHeading: task.draftableTasksHeading
      }
    })

    return formInitials
  })

export const makeDisabledPayrollInstanceTasks = selector => createSelector(selector,
  (session, tasks) => tasks.filter(task => !task.isEditable))
