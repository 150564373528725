import isEqual from 'lodash/isEqual'

/**
 * Compare two objects and get the difference in a newly created object
 *
 * Example use-case:
 * 1. We have a form and the form has initial form values, i.e. `initialData` param.
 * 2. The form is submitted and all the values will be kept in a new object, i.e. `formData` param.
 * 3. Now we want to have an object, containing only the changed form keys' values,
 * and later on send only them to the BE API.
 *
 * @param {Object} initialData
 * @param {Object} formData
 * @param {Boolean} deepEqual
 */
export const diff = (initialData, formData, deepEqual = false) => Object.keys(formData).reduce((acc, key) => {
  // Is the submitted form data value changed, according to the initial form data value?
  const isDataChanged = deepEqual ? !isEqual(formData[key], initialData[key]) : formData[key] !== initialData[key]

  if (isDataChanged) {
    acc[key] = formData[key]
  }

  return acc
}, {})
