import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/notifications'

class Notification extends CRUDModel {}
Notification.modelName = 'Notification'

Notification.fields = {
  id: attr()
}
Notification.actionTypes = actionTypes

export default Notification
