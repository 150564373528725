import React from 'react'
import PropTypes from 'prop-types'
import PayrollInstanceAccordionContainer from 'containers/PayrollInstanceAccordionContainer'
import Pagination from 'utils/helperClasses/Pagination'
import SectionHeading from './SectionHeading'
import FlagOption from 'components/form/select/FlagOption'
import FlagValue from 'components/form/select/FlagValue'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Select from 'react-select/lib/Select'
import useQuery from 'hooks/useQuery'
import { isUndefined } from 'lodash'

const getExpandedState = (isVendor, idx, query) => {
  const queryIdx = +query.get('idx')
  if (idx === 0 && isVendor) return true
  if (!isUndefined(queryIdx)) {
    return idx === queryIdx
  }
}

const PayrollListView = ({ payrolls = [], selectedCountry, onCountryChange, payrunState, heading, handleSubmit, countries, isVendor = false, ...rest }) => {
  const query = useQuery()
  return (
    <>
      <SectionHeading text={heading} fullWidth>
        {!isVendor ? <Select
          value={selectedCountry}
          name='country'
          component={CustomSelectField}
          optionComponent={FlagOption}
          valueComponent={FlagValue}
          type='text'
          className='c-custom-select--small c-custom-select--min-width--large'
          formGroupClassName='u-margin-none'
          labelClassName='c-label'
          placeholder='Country'
          options={countries}
          onChange={(val) => {
            onCountryChange(val)
          }}
        /> : null
        }
      </SectionHeading>
      {payrolls.map((payroll, idx) => (
        <PayrollInstanceAccordionContainer
          key={payroll.id}
          isVendor={isVendor}
          payroll={payroll}
          payrunState={payrunState}
          accordionIdx={idx}
          isExpanded={getExpandedState(isVendor, idx, query)}
          {...rest}
        />
      ))}
      <div className='u-float--right u-margin-right'>
        <Pagination {...rest} />
      </div>
    </>
  )
}

PayrollListView.propTypes = {
  payrolls: PropTypes.array,
  payrunState: PropTypes.string,
  heading: PropTypes.string,
  handleSubmit: PropTypes.func,
  countries: PropTypes.array,
  isVendor: PropTypes.bool,
  selectedCountry: PropTypes.object,
  onCountryChange: PropTypes.func
}

export default PayrollListView
