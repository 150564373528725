import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { reduxForm } from 'redux-form'
import { selectTenant, disableInvalidator } from 'redux/actions/tenants'
import TenantSelector from '../components/TenantSelector'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { getParentCompaniesTenants, getVendorsTenants } from 'redux/selectors/tenants'
import { purify } from '../utils/router'
import { closeAllToastMessages } from 'redux/actions/toasts'

/**
 * Important note. Chosen tenant is being cached in the Store via `redux-persist`.
 * When we have already selected tenant, then the API calls will use the tenant's api url.
 * So once cached the tenant's api url, all next calls will use this url.
 * Keep in mind that if the BE changes the tenant url, for some reason,
 * this change will not be applied, because we already have a cached value.
 *
 * To fix this, we should stop caching the resource or have a tenant referencey by id,
 * in order to always get the fresh data.
 */
class TenantSelectorContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchParentCompaniesIfNeeded()
    this.props.fetchVendorsIfNeeded()
  }

  render () {
    return <TenantSelector {...this.props} />
  }
}

export const getTenantTypeByUrl = (tenants, url) => {
  return tenants.find(tenant => tenant.url === url).type
}

export const getTenantIdByUrl = (tenants, url) => {
  return tenants.find(tenant => tenant.url === url).id
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
  fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded())
})

const mapStateToProps = state => {
  const { tenants, parentCompanies, vendors } = state

  if (isFetching([parentCompanies, vendors])) return { isFetching: true }

  const parentCompaniesTenants = getParentCompaniesTenants(state)
  const vendorsTenants = getVendorsTenants(state)

  return {
    parentCompaniesTenants,
    vendorsTenants,
    tenants: [
      { name: 'COS', url: null },
      { name: 'Clients', disabled: true },
      ...parentCompaniesTenants,
      { name: 'Vendors', disabled: true },
      ...vendorsTenants
    ],
    tenantsState: tenants,
    initialValues: {
      url: tenants.url
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { parentCompaniesTenants, vendorsTenants } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: ({ url }) => {
      const type = url ? getTenantTypeByUrl([
        ...parentCompaniesTenants, ...vendorsTenants], url) : null

      const id = url ? getTenantIdByUrl([
        ...parentCompaniesTenants, ...vendorsTenants], url) : null

      // @docs: routes/Vendors/routes/Vendor/routes/Clients/routes/Client/components/Invalidator
      dispatch(disableInvalidator())
      dispatch(closeAllToastMessages())
      // Before resetting everything, go to `/pure` route, in order to prevent unrelated api calls.
      // Imagine that you select a company tenant and you're on `/companies` route.
      // After that you change to a vendor tenant.
      // In that case fetch companies requests will be triggered, to the vendor tenant api url, that isn't correct.
      // So before switching the tenant / api url, we go to the `pure` neutral route.
      purify(ownProps.history).then(() => {
        dispatch(selectTenant({ url, type, id }))
        ownProps.history.push(`/home`)
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'tenantSelect',
  destroyOnUnmount: false
})(TenantSelectorContainer))
