import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_DATA_PROTECTION_FIELDS_FETCH = 'COUNTRY_DATA_PROTECTION_FIELDS_FETCH'
export const COUNTRY_DATA_PROTECTION_FIELDS_RECEIVE = 'COUNTRY_DATA_PROTECTION_FIELDS_RECEIVE'
export const COUNTRY_DATA_PROTECTION_FIELDS_UPDATE = 'COUNTRY_DATA_PROTECTION_FIELDS_UPDATE'
export const COUNTRY_DATA_PROTECTION_FIELDS_INVALIDATE = 'COUNTRY_DATA_PROTECTION_FIELDS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_DATA_PROTECTION_FIELDS_FETCH,
  receive: COUNTRY_DATA_PROTECTION_FIELDS_RECEIVE,
  update: COUNTRY_DATA_PROTECTION_FIELDS_UPDATE,
  invalidate: COUNTRY_DATA_PROTECTION_FIELDS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'countryDataProtectionFieldsPivot', 'countrydataprotectionfields', 'CountryDataProtectionField')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryDataProtectionFieldsPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryDataProtectionFieldsPivot = actionUtility.fetchEntities
export const updateCountryDataProtectionFieldsPivot = actionUtility.updateEntity
export const invalidateCountryDataProtectionFieldsPivot = actionUtility.invalidate
