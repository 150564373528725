/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Back from 'components/Back'
import Moment from 'components/Moment'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router'

const FilterName = ({ report }) => {
  return (
    <div>
      {report.categoryName ? (
        <span className='u-text--xtra-huge u-text--minsk'>
          <span className='u-weight--bold'>{report.categoryName}</span>
          <span className='u-text--xtra-huge u-text--minsk'>
            {' '}
            / {report.name}
          </span>
        </span>
      ) : (
        <span className='u-text--xtra-huge u-text--minsk'>
          {report.name}
        </span>
      )}
      <div>
        <span><Moment date={report.createdAt.date} format='DD/MM/YYYY HH:mm' />
          {report.createdAt.timezone ? ` ${report.createdAt.timezone} ` : null}</span>
        <span>{report.subCat}, </span>
        {
          report.filters.fromDate
            ? <Moment format='DD/MM/YYYY'>{report.filters.fromDate}</Moment>
            : 'Any date'
        }
        &nbsp;-&nbsp;
        {
          report.filters.toDate
            ? <Moment format='DD/MM/YYYY'>{report.filters.toDate}</Moment>
            : 'Any date'
        }
      </div>
    </div>
  )
}
export const ReportAnalyticsHeader = (props) => {
  const [showFiltersList, setShowFiltersList] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const { report } = props
  const isPayAndTaxesReport = report.type === 'GlobalPayAndTaxesReport'

  const onBackNavigate = () => {
    history.push(location.state?.from || '/reporting/customize-report')
  }

  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: showFiltersList,
    })
  }
  if (props.hideOnExport) {
    return (
      <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-5/6@desktop u-margin-bottom-small' style={{ height: '60px' }}>
        <FilterName report={report} {...props} />
      </div>
    )
  }
  return (
    <div className='page--reporting-analytics-header'>
      <div className='o-layout o-grid ai-start u-margin-bottom-small'>
        <div className={`u-margin-right ${props.hideOnExport ? 'v-hidden' : ''}`}>
          <Back onBackNavigate={onBackNavigate} />
        </div>
        <div className='o-layout__item u-padding-left-none u-1/1 u-2/3@tablet u-5/6@desktop'>
          <FilterName report={report} />
          <div
            onClick={() => setShowFiltersList(!showFiltersList)}
            className={`u-text--small u-cursor--point`}
          >
            <span className={`u-float--left`}>
              {showFiltersList ? 'Hide filters' : 'Show filters'}
            </span>
            <span className={getArrowClasses()} />
          </div>
        </div>

        <div className={`d-flex d-flex-item--auto jc--flex-end  ${props.hideOnExport ? 'v-hidden' : ''}`}>
          <button
            className='c-btn shadow c-btn--small rounded c-btn--curious u-margin-right-tiny u-margin-bottom-tiny'
            title='Download'
            onClick={(e) => {
              e.preventDefault()
              props.downloadReport(report.id)
            }}
          >
            <span className={`icon icon--download`} />
          </button>
        </div>
      </div>
      <div>
        <div className={`analytics-show-filter o-block o-block--left ${showFiltersList ? 'filter-opened' : ''}`}>
          <div className={`rounded shadow border u-padding-small u-margin-bottom u-text--small`}>
            <span className='u-weight--bold'>Country: </span>
            <span>
              {report.filters.countryNames.length
                ? report.filters.countryNames.join(', ')
                : 'Any'}
            </span>
            <br />
            <span className='u-weight--bold'>Company: </span>
            <span>
              {report.filters.companyNames.length
                ? report.filters.companyNames.join(', ')
                : 'Any'}
            </span>
            <br />
            {!isPayAndTaxesReport && (
              <span>
                <span className='u-weight--bold'>Payroll: </span>
                <span>
                  {report.filters.payrollNames.length
                    ? report.filters.payrollNames.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
            {report.type === 'EmployeeReport' && (
              <span>
                <span className='u-weight--bold'>
                  {' '}
                  Type of Employment/Engagement:{' '}
                </span>
                <span>
                  {report.filters.employmentStatus.length
                    ? report.filters.employmentStatus.join(', ')
                    : 'Any'}
                </span>
                <br />
                <span className='u-weight--bold'>Business Unit: </span>
                <span>
                  {report.filters.businessUnitNames.length
                    ? report.filters.businessUnitNames.join(', ')
                    : 'Any'}
                </span>
                <br />
                <span className='u-weight--bold'>Cost Center: </span>
                <span>
                  {report.filters.costCenterNames.length
                    ? report.filters.costCenterNames.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
            {report.type === 'AuditTrailReport' && (
              <span>
                <span className='u-weight--bold'>Process owner: </span>
                <span>
                  {report.filters.processOwnerNames.length
                    ? report.filters.processOwnerNames.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
            {[
              'PayrollInstanceReport',
              'EmployeeDataChangesReport',
              'VarianceReport',
              'GlobalPayrunManagementReport',
            ].includes(report.type) && (
              <span>
                <span className='u-weight--bold'>Payrun: </span>
                <span>
                  {report.filters.payrollInstanceNames.length
                    ? report.filters.payrollInstanceNames.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
            {(report.type === 'PayrollReport' ||
              report.type === 'PayrollInstanceReport' ||
              report.type === 'GlobalPayrunManagementReport') && (
              <span>
                <span className='u-weight--bold'>Show Only Totals: </span>
                <span>{report.filters.showOnlyTotals ? 'Yes' : 'No'}</span>
                <br />
              </span>
            )}
            {[
              'PayrollInstanceReport',
              'EmployeeDataChangesReport',
              'GlobalPayrunManagementReport',
            ].includes(report.type) && (
              <span>
                <span className='u-weight--bold'>
                  Type of Employment/Engagement:{' '}
                </span>
                <span>
                  {report.filters.employmentStatus.length
                    ? report.filters.employmentStatus.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
            {['PayrollReport', 'GlobalPayAndTaxesReport'].includes(
              report.type
            ) &&
              report.filters.termCategory && (
              <div>
                <span>
                  <span className='u-weight--bold'>Term category: </span>
                  <span>
                    {report.filters.termCategoryNames.length
                      ? report.filters.termCategoryNames.join(', ')
                      : 'Any'}
                  </span>
                  <br />
                  <span className='u-weight--bold'>
                    Elements Subcategory:{' '}
                  </span>
                  <span>
                    {report.filters.termSubCategoryNames.length
                      ? report.filters.termSubCategoryNames.join(', ')
                      : 'Any'}
                  </span>
                  <br />
                  <span className='u-weight--bold'>Elements: </span>
                  <span>
                    {report.filters.termNames.length
                      ? report.filters.termNames.join(', ')
                      : 'Any'}
                  </span>
                </span>
              </div>
            )}
            {[
              'PayrollInstanceReport',
              'PayrollReport',
              'EmployeeDataChangesReport',
              'VarianceReport',
              'GlobalPayrunManagementReport',
            ].includes(report.type) &&
              report.reportingCurrency.length > 0 && (
              <div>
                <span className='u-weight--bold'>Reporting currency: </span>
                <span>{report.reportingCurrency.join(', ')}</span>
              </div>
            )}
            {[
              'PayrollInstanceReport',
              'PayrollReport',
              'EmployeeDataChangesReport',
              'VarianceReport',
            ].includes(report.type) &&
              report.reportingCurrency.length > 0 && (
              <div>
                <span className='u-weight--bold'>Reporting currency: </span>
                <span>{report.reportingCurrency.join(', ')}</span>
              </div>
            )}
            {report.type === 'ChangeLogReport' && (
              <span>
                <span className='u-weight--bold'>Payrun: </span>
                <span>
                  {report.filters.payrollInstanceNames.length
                    ? report.filters.payrollInstanceNames.join(', ')
                    : 'Any'}
                </span>
                <br />
                <span className='u-weight--bold'>
                  Type of Employment/Engagement:{' '}
                </span>
                <span>
                  {report.filters.employmentStatus.length
                    ? report.filters.employmentStatus.join(', ')
                    : 'Any'}
                </span>
                <br />
                <span className='u-weight--bold'>Business Unit: </span>
                <span>
                  {report.filters.businessUnitNames.length
                    ? report.filters.businessUnitNames.join(', ')
                    : 'Any'}
                </span>
                <br />
                <span className='u-weight--bold'>Modified by: </span>
                <span>
                  {report.filters.modifiedBy.length &&
                  report.filters.modifiedBy.filter((i) => i !== '').length
                    ? report.filters.modifiedBy.join(', ')
                    : 'Any'}
                </span>
                <br />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
