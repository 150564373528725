import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
// eslint-disable-next-line max-len
import {
  getPayrollProcessTree,
  getPayrollProcessIdByPayrollId,
  getPayrollProcessTasksOwnersIds,
} from 'routes/Companies/routes/Company/routes/DatesProcesses/routes/Payroll/selectors/payrollServiceORM'
import { getIsSharedDocByUserByDocIdByDocTenant } from 'redux/selectors/documentUsers'
import { getUserWithSchema } from 'redux/selectors/employees'
import PayrollProcessTree from 'components/PayrollProcessTree'
import { isCot, hasAccess } from 'redux/selectors/auth'
import _ from 'lodash'

class PayrollProcessTreeContainer extends React.Component {
  render () {
    return <PayrollProcessTree {...this.props} />
  }
}

PayrollProcessTreeContainer.PropTypes = {
  // Filter the tasks by its `active` flag
  // If omitted - then all the task (active and inactive) will be returned
  // and no filter will be applied
  taskActive: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const {
    typeClass,
    payrollId,
    match: { params },
    documentId,
    documentTenant,
    isShare,
    taskActive,
    shouldIncludeEmptySteps,
  } = props

  const payrollProcessId = params.payrollProcessId ? parseInt(params.payrollProcessId) : getPayrollProcessIdByPayrollId(state, { payrollId })

  let tree = getPayrollProcessTree(state, { payrollProcessId, typeClass, taskActive, shouldIncludeEmptySteps })

  if (isShare && tree.owner) {
    let owner = getUserWithSchema(state, { userId: tree.owner.id })
    tree.owner = {
      ...owner,
      share: getIsSharedDocByUserByDocIdByDocTenant(state, {
        documentId,
        documentTenant,
        userId: owner.id,
        userTenant: owner.schema,
      }),
    }
  }

  // Sort tasks by position
  tree.steps.map((step) => {
    step.tasks = step.tasks.map((task) => {
      // Check if file is shared to user
      let ownerUser = task.owner && task.owner.id ? getUserWithSchema(state, { userId: task.owner.id }) : task.owner
      if (ownerUser) {
        ownerUser = {
          ...ownerUser,
          share: getIsSharedDocByUserByDocIdByDocTenant(state, {
            documentId: documentId,
            documentTenant: documentTenant,
            userId: ownerUser.id,
            userTenant: ownerUser.schema,
          }),
        }
      }
      return {
        ...task,
        owner: task.owner ? { ...ownerUser } : null,
      }
    })
    step.tasks = _.sortBy(step.tasks, ['position'])
  })

  return {
    payrollProcessId,
    tree,
    isCot: isCot(state),
    isEditDisabled: !hasAccess(state)(['PAYROLLTASK_CREATE', 'PAYROLLTASK_EDIT', 'PAYROLLSTEP_CREATE', 'PAYROLLSTEP_EDIT']),
  }
}

const Container = connect(mapStateToProps)(PayrollProcessTreeContainer)

export default Fetcher(
  Fetcher(Container, [
    {
      name: 'employeeSystemUsers',
      params: [
        {
          _computed: {
            filter: (state, { payrollId, match: { params } }) => {
              const payrollProcessId = params.payrollProcessId ? parseInt(params.payrollProcessId) : getPayrollProcessIdByPayrollId(state, { payrollId })
              const processOwners = getPayrollProcessTasksOwnersIds(state, { payrollProcessId })

              return createFilter({ isKeyPerson: 1, id: processOwners })
            },
          },
        },
      ],
    },
  ]),
  [
    'processes',
    'countryProcessPivot',
    'companyProcessPivot',
    'payrolls',
    'payrollService',
    'payrollProcess',
    'payrollStep',
    'payrollTask',
    'parentCompanies',
    'payrollTaskOwners',
  ]
)
