import Fetcher from 'containers/Fetcher'
import React from 'react'
import { batch, connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { createNewEmployeeUserAddress, fetchEmployeeUserAddresses } from 'redux/actions/employeeUserAddresses'
import { createFilter } from 'utils/redux/filter'
import { AddNewEmployeeAddressForm } from '../components/AddNewEmployeeAddressForm'

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: (data) => dispatch(createNewEmployeeUserAddress(data, false, true))
    .then(() => {
      batch(() => {
        dispatch(fetchEmployeeUserAddresses({ filter: createFilter({ employeeUser: props.match.params.employeeId }) }))
        dispatch(fetchEmployeeSystemUsers({ filter: createFilter({ id: props.match.params.employeeId }) }))
        props.onSubmit()
      })
    })
})
const mapStateToProps = (state, props) => {
  const initialVales = {
    employeeUser: props.match.params.employeeId
  }
  return {
    initialValues: {
      ...initialVales,
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'employeeAddressCreate' })(AddNewEmployeeAddressForm))

const AddressFetcher = Fetcher(Container, [])

export default props => {
  return <AddressFetcher {...props} />
}
