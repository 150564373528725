import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyTaskPivot'

class CompanyCountryTask extends CRUDModel {}
CompanyCountryTask.modelName = 'CompanyCountryTask'
CompanyCountryTask.actionTypes = actionTypes

CompanyCountryTask.fields = {
  id: attr(),
  companyStep: fk('CompanyCountryStep', 'companyCountryTasks'),
  countryTask: fk('CountryTask', 'companyCountryTasks'),
  taskOwner: fk('EmployeeSystemUser', 'companyCountryTasks')
}

export default CompanyCountryTask
