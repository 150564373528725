import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from './table/PayslipTable'

export const ServicesTree = ({ rows, heading }) => {
  return <div>
    <SectionHeading text={heading || 'Services list'} className='u-margin-top-large' />
    <PayslipTable
      data={rows.map(row => ({
        id: row.id,
        name: row.serviceName,
        type: row.serviceType,
        processes: row.processName,
        keyProcessEvent: row.processKeyEvent,
        steps: row.stepName,
        actions: row.taskName,
        actionOwner: row.taskOwner,
        actionCompletionLink: row.finishTaskLink
      }))}
      headings={[
        { accessor: 'name', Header: 'Name', disableSortBy: true },
        { accessor: 'type', Header: 'Type', disableSortBy: true },
        { accessor: 'processes', Header: 'Processes', disableSortBy: true },
        { accessor: 'keyProcessEvent', Header: 'Key process event', disableSortBy: true },
        { accessor: 'steps', Header: 'Steps of the process', disableSortBy: true },
        { accessor: 'actions', Header: 'Actions per step', disableSortBy: true },
        { accessor: 'actionOwner', Header: 'Action owner', disableSortBy: true },
        { accessor: 'actionCompletionLink', Header: 'Action Completion Link', disableSortBy: true }
      ]}
      wrapperClassName='u-margin-bottom u-1/1'
      trBodyClassName='o-payslip-table__cell--whitespace--normal'
    />
  </div>
}

ServicesTree.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    serviceName: PropTypes.string,
    serviceType: PropTypes.string,
    processName: PropTypes.string,
    processKeyEvent: PropTypes.string,
    stepName: PropTypes.string,
    taskName: PropTypes.string,
    taskOwner: PropTypes.string,
    finishTaskLink: PropTypes.string
  })).isRequired,
  heading: PropTypes.string
}

export default ServicesTree
