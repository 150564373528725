import React from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { fetchServicesIfNeeded, createService } from 'redux/actions/services'
import ServiceCreate from '../components/ServiceCreate'

class ServicesCreateContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { fetchServicesIfNeeded } = this.props
    fetchServicesIfNeeded()
  }

  render () {
    return <ServiceCreate {...this.props} />
  }
}

const mapDispatchToProps = dispatch => ({
  fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
  onSubmit: data => dispatch(createService(data)).then(() => {
    dispatch(reset('service'))
  })
})

const mapStateToProps = (state) => ({
  types: ['setup', 'operational'],
  isFetching : state.services.isFetching
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesCreateContainer)
