export const LOCALSTORAGE_NAMESPACE = '@payslip'

export const getStorageItem = (key) => {
  let item

  try {
    item = window.localStorage.getItem(`${LOCALSTORAGE_NAMESPACE}/${key}`)
  } catch (e) {
    console.error('Error on getting item from localstorage', e)
    item = undefined
  }

  return item
}

export const setStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(`${LOCALSTORAGE_NAMESPACE}/${key}`, value)
  } catch (e) {
    console.error('Error on saving item from localstorage', e)
  }
}

export const clearStorageByNamepace = (namespace = LOCALSTORAGE_NAMESPACE) => {
  try {
    const keys = Object.keys(window.localStorage)
    for (const key of keys) {
      if (key.startsWith(namespace)) {
        window.localStorage.removeItem(key)
      }
    }
  } catch (e) {
    console.error('Error removing all keys by namespace on localstorage', e)
  }
}

export const removeItemFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(`${LOCALSTORAGE_NAMESPACE}/${key}`)
  } catch (e) {
    console.error('Error removing localstorage item', e)
  }
}
