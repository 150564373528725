export const graphTypesEnum = {
  TILE_COUNT: 'TILE_COUNT',
  LINE_CHART: 'LINE_CHART',
  SIDE_BARCHART: 'SIDE_BARCHART',
  PIE_CHART: 'PIE_CHART',
}

export const totalsCalculations = {
  CALCULATED: 'CALCULATED',
  FLAT: 'FLAT',
}

export const reportingGraphNamesEnum = {
  GLOBAL_WORKFORCE_HEADCOUNT: 'GLOBAL_WORKFORCE_HEADCOUNT',
  GLOBAL_JOINERS_HEADCOUNT: 'GLOBAL_JOINERS_HEADCOUNT',
  GLOBAL_LEAVERS_HEADCOUNT: 'GLOBAL_LEAVERS_HEADCOUNT',
  HEADCOUNT_BY_COUNTRY: 'HEADCOUNT_BY_COUNTRY',
  EMPLOYEE_SELF_SERVICE: 'EMPLOYEE_SELF_SERVICE',
  TOTAL_EMPLOYER_COSTS: 'TOTAL_EMPLOYER_COSTS',
  EMPLOYER_CONTRIBUTION_COSTS: 'EMPLOYER_CONTRIBUTION_COSTS',
  TOTAL_PAY_ELEMENT_COSTS: 'TOTAL_PAY_ELEMENT_COSTS',
  TOTAL_EE_DEDUCTION_COSTS: 'TOTAL_EE_DEDUCTION_COSTS',
  TOTAL_EE_NET_DEDUCTION_COSTS: 'TOTAL_EE_NET_DEDUCTION_COSTS',
  GLOBAL_COSTS: 'GLOBAL_COSTS',
  GLOBAL_COSTS_PER_ELEMENT: 'GLOBAL_COSTS_PER_ELEMENT',
  TOTAL_PAYROLL_ELEMENT_COSTS: 'TOTAL_PAYROLL_ELEMENT_COSTS',
  GLOBAL_WORKFLOW_AUDIT: 'GLOBAL_WORKFLOW_AUDIT',
  WORKFLOW_AUDIT: 'WORKFLOW_AUDIT',
}
