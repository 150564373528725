import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_TASK_OWNER_FETCH = 'PAYROLL_INSTANCE_TASK_OWNER_FETCH'
export const PAYROLL_INSTANCE_TASK_OWNER_RECEIVE = 'PAYROLL_INSTANCE_TASK_OWNER_RECEIVE'
export const PAYROLL_INSTANCE_TASK_OWNER_UPDATE = 'PAYROLL_INSTANCE_TASK_OWNER_UPDATE'
export const PAYROLL_INSTANCE_TASK_OWNER_FILTER = 'PAYROLL_INSTANCE_TASK_OWNER_FILTER'
export const PAYROLL_INSTANCE_TASK_OWNER_FILTERED = 'PAYROLL_INSTANCE_TASK_OWNER_FILTERED'
export const PAYROLL_INSTANCE_TASK_OWNER_INVALIDATE = 'PAYROLL_INSTANCE_TASK_OWNER_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_TASK_OWNER_FETCH,
  receive: PAYROLL_INSTANCE_TASK_OWNER_RECEIVE,
  update: PAYROLL_INSTANCE_TASK_OWNER_UPDATE,
  filter: PAYROLL_INSTANCE_TASK_OWNER_FILTER,
  filtered: PAYROLL_INSTANCE_TASK_OWNER_FILTERED,
  invalidate: PAYROLL_INSTANCE_TASK_OWNER_INVALIDATE
}
const URI = 'payrollinstancetaskowners'
const actionUtility = new ActionUtility(actionTypes,
  'payrollInstanceTaskOwner', URI, 'PayrollInstanceTaskOwner')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceTaskOwnerIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceTaskOwner = actionUtility.fetchEntities
export const invalidatePayrollInstanceTaskOwner = actionUtility.invalidate

export const mergePayrollInstanceTaskOwners = data => {
  return (dispatch, getState, { api }) => {
    let payload = { data: {} }

    Object.keys(data).forEach(key => {
      const { id } = JSON.parse(key)

      payload.data[id] = { status: data[key] }
    })

    // sync to back end
    return api.put(`${URI}/update`, { payload })
      .then(errorsHandling.handlePivotJSONNormalize)
  }
}
