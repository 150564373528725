import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import PropTypes from 'prop-types'
import RequestCodeView from 'components/RequestCodeView'

const emailLabel = `Please provide the authentication code received in your email`
const textMsgLabel = 'Please provide the authentication code received on your mobile phone'

const AuthCodeView = (props) => {
  const { senderService, getNewCode } = props
  const label = senderService === 'email' ? emailLabel : textMsgLabel

  return <div className='c-login__content-user-activation'>
    <div className='o-grid o-grid--center'>
      <Form showSubmitBtn btnText='Send' colorClassBtn='c-btn--curious' btnFullWidth classNames='c-btn--rounded' {...props}>
        <div
          className='o-form-group u-margin-bottom-large'
          data-testid='input-wrapper'
        >
          <Field
            name='authCode'
            label={label}
            placeholder='Authentication code'
            component={InputField}
            type='text'
            autoFocus
            className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
            labelClassName='c-label c-label--forgotten-pass'
          />
        </div>
      </Form>
    </div>
    <RequestCodeView onClick={getNewCode} />
  </div>
}

AuthCodeView.propTypes = {
  getNewCode: PropTypes.func,
  senderService: PropTypes.string
}

export default AuthCodeView
