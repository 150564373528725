import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AccordionContainer from 'containers/AccordionContainer'
import MobileDetailsView from 'components/MobileDetailsView'
import SectionHeading from 'components/SectionHeading'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { extractKeyValuePairFromArray, getFieldsBySection, mapEmployeeFieldsForMobile } from 'utils/employee'
import PayslipTable from 'components/table/PayslipTable'
import { EditableCellDataEmployeeFieldsFormatter } from 'components/table/cell/EditableCellDataEmployeeFieldsFormatter'
import { Authorization } from 'containers/Authorization'
import ProcessingSSOModal from 'components/ProcessingSSOModal'
import { isCot } from 'redux/selectors/auth'
import { useSelector } from 'react-redux'

const btnClasses = 'c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'

function JobOrgView ({
  isFetching,
  employee,
  isEmployeeOnly,
  isPaymentTypeHourly,
  EmployeeStatus,
  inEditMode,
  onUpdateGlobalDataForSave,
  fieldErrors,
  hasCredentials,
  onUserPasswordReset,
  handleSSOclick,
  onValueChange,
  data: { jobProfile, organizationDetails, employmentDetails, employeeSelfService },
  currencies,
  payrolls,
  paygroups,
  permissions,
  history,
  showUserSSOField,
  showUserSSOButton,
  modalState,
  onModalStateChange,
}) {
  const [isSSOSubmitting, setIsSubmitting] = useState(false)
  const [submitErrors, setSubmitErrors] = useState([])
  const submittingSSOModal = useRef()
  const uri = `/companies/${employee.company.id}/roles-rights?action=openEditModal&employeeId=${employee.id}`
  const handleMakeKeyPerson = () => history.push(uri)
  const isUserSSOEnabled = employee.isSSOEnabled
  const isCotUser = useSelector((state) => isCot(state))

  const showMode2faFieldOnEdit = employee.hasCredentials ? !isUserSSOEnabled : false

  useEffect(() => {
    setIsSubmitting(false)
    if (modalState?.showSubmitSSOConfirmModal) {
      return submittingSSOModal.current.showModal()
    }

    window.addEventListener('popstate', () => {
      onModalStateChange({ showSubmitSSOConfirmModal: false })
    })

    return window.removeEventListener('popstate', () => {})
  }, [])

  const defaultNestedColumns = [
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      classNames: 'd-none',
    },
    {
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
      cellClassName: 'w-100 u-border--mischka',
      classNames: 'd-none',
      editable: true,
      Cell: (props) => {
        return <EditableCellDataEmployeeFieldsFormatter permissions={permissions} {...props} />
      },
    },
  ]
  const showUniqueContractorIDField = EmployeeStatus.shouldShowUniqueContractorIdArea
  const getEmployeeJobProfileFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'job-and-org',
    subSection: 'jobProfile',
    isPaymentTypeHourly,
    showUniqueContractorIDField: showUniqueContractorIDField,
  })

  const showInvoicePaymentCurrencyArea = EmployeeStatus.shouldShowInvoicePaymentCurrencyArea
  const getEmployeeOrganizationFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'job-and-org',
    subSection: 'organizationDetails',
    showInvoicePaymentCurrencyArea: showInvoicePaymentCurrencyArea,
  })

  const getEmployeeEmploymentFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'job-and-org',
    subSection: 'employmentDetails',
  })

  const getEmployeeSelfServiceFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({ isUserSSOEnabled, showUserSSOField, isCotUser, showMode2faFieldOnEdit }),
    employee,
    section: 'job-and-org',
    subSection: 'employeeSelfService',
  })

  if (isFetching) return <div>Loading...</div>
  const editableOptions = {
    inGlobalEditMode: inEditMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    fieldErrors: fieldErrors,
    onValueChange: onValueChange,
  }
  return (
    <div data-testid='job-org-view'>
      <ProcessingSSOModal
        isSSOSubmitting={isSSOSubmitting}
        isUserSSOEnabled={isUserSSOEnabled}
        onModalStateChange={onModalStateChange}
        ref={submittingSSOModal}
        isEmployeeUser
        errors={submitErrors}
      />

      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <div className='o-layout'>
          <div className='o-layout__item u-1/1 u-1/3@tablet'>
            <PayslipTable
              data={jobProfile}
              dynamicValues
              editableOptions={editableOptions}
              headings={[
                {
                  accessor: 'name',
                  Header: 'Job Profile',
                  disableSortBy: true,
                  disableFilters: true,
                  classNames: 'u-text--center',
                  isKey: true,
                  columns: defaultNestedColumns,
                },
              ]}
              wrapperClassName='u-margin-bottom u-1/1'
            />
            <div>
              <PayslipTable
                data={employeeSelfService}
                dynamicValues
                editableOptions={editableOptions}
                headings={[
                  {
                    accessor: 'name',
                    Header: 'Employee Self Service',
                    disableSortBy: true,
                    disableFilters: true,
                    classNames: 'u-text--center',
                    isKey: true,
                    columns: defaultNestedColumns,
                  },
                ]}
                wrapperClassName='u-margin-bottom-tiny u-1/1'
              />
              <div className='d-flex'>
                <Authorization permissions={['CONVERT_EMPLOYEE_TO_KEYPERSON']}>
                  <div className='c-panel u-padding-small u-padding-top-none u-padding-left-none'>
                    <div className='u-padding-top-tiny'>
                      <button type='button' onClick={handleMakeKeyPerson} disabled={inEditMode} className={`${btnClasses} ${inEditMode ? 'disabled' : ''}`}>
                        Make Key Person
                      </button>
                    </div>
                  </div>
                </Authorization>
                {hasCredentials && !isUserSSOEnabled && (
                  <div className={`c-panel u-padding-small u-padding-top-none u-padding-left-none`}>
                    <div className='u-padding-top-tiny'>
                      <button
                        type='button'
                        onClick={() => onUserPasswordReset({ email: employee.workEmail })}
                        disabled={inEditMode}
                        className={`${btnClasses} ${inEditMode ? 'disabled' : ''}`}
                        data-testid='reset-user-password'
                      >
                        Reset User Password
                      </button>
                    </div>
                  </div>
                )}
                {showUserSSOButton && (
                  <div className='c-panel u-padding-small u-padding-top-none u-padding-left-none'>
                    <div className='u-padding-top-tiny'>
                      <button
                        type='button'
                        onClick={() => {
                          setIsSubmitting(true)
                          submittingSSOModal.current.showModal()
                          handleSSOclick({
                            userId: employee.id,
                            isUserSSOEnabled: !employee.isSSOEnabled,
                          }).then((res) => {
                            if (res.errors) {
                              setIsSubmitting(false)
                              setSubmitErrors(res.errors._error)
                            }
                          })
                        }}
                        disabled={inEditMode}
                        className={`${btnClasses} ${inEditMode ? 'disabled' : ''}
                         ${isSSOSubmitting ? 'pe-none' : ''} `}
                        data-testid='sso-button'
                      >
                        {`${employee.isSSOEnabled ? 'Disable' : 'Enable'} SSO`}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='o-layout__item u-1/1 u-1/3@tablet'>
            <PayslipTable
              data={organizationDetails}
              dynamicValues
              editableOptions={editableOptions}
              headings={[
                {
                  accessor: 'name',
                  Header: 'Organization',
                  disableSortBy: true,
                  disableFilters: true,
                  classNames: 'u-text--center',
                  isKey: true,
                  columns: defaultNestedColumns,
                },
              ]}
              wrapperClassName='u-margin-bottom u-1/1'
            />
          </div>

          <div className='o-layout__item u-1/1 u-1/3@tablet'>
            <PayslipTable
              data={employmentDetails}
              dynamicValues
              editableOptions={editableOptions}
              headings={[
                {
                  accessor: 'name',
                  Header: 'Employment Details',
                  disableSortBy: true,
                  disableFilters: true,
                  classNames: 'u-text--center',
                  isKey: true,
                  columns: defaultNestedColumns,
                },
              ]}
              wrapperClassName='u-margin-bottom u-1/1'
            />
          </div>
        </div>
      </div>

      {isEmployeeOnly && (
        <div className='u-hide-on-desktop'>
          <SectionHeading text='Job & Org' />
          <AccordionContainer title='Job Profile' titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeJobProfileFields)} />
          </AccordionContainer>
          <AccordionContainer title='Organization' titleColor='minsk' fullWidth rounded>
            <MobileDetailsView
              rows={extractKeyValuePairFromArray(
                mapEmployeeFieldsForMobile({
                  fields: getEmployeeOrganizationFields,
                  currencies,
                  payrolls,
                  paygroups,
                })
              )}
            />
          </AccordionContainer>
          <AccordionContainer title='Employment Details' titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeEmploymentFields)} />
          </AccordionContainer>
          <AccordionContainer title='Employee Self Service' titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeSelfServiceFields)} />
          </AccordionContainer>
        </div>
      )}
    </div>
  )
}

JobOrgView.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number.isRequired,
    employeeId: PropTypes.string,
    position: PropTypes.string,
    countries: PropTypes.array,
    workEmail: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      accessor: PropTypes.string,
    }),
    isSSOEnabled: PropTypes.bool,
  }),
  currencies: PropTypes.array,
  payrolls: PropTypes.array,
  paygroups: PropTypes.array,
  isEmployeeOnly: PropTypes.bool,
  isPaymentTypeHourly: PropTypes.bool,
  isFetching: PropTypes.func,
  EmployeeStatus: PropTypes.object,
  inEditMode: PropTypes.bool,
  onUpdateGlobalDataForSave: PropTypes.func,
  fieldErrors: PropTypes.array,
  hasCredentials: PropTypes.bool,
  onUserPasswordReset: PropTypes.func,
  data: PropTypes.object,
  permissions: PropTypes.array,
  history: PropTypes.object,
  showUserSSOField: PropTypes.bool,
  showUserSSOButton: PropTypes.bool,
  handleSSOclick: PropTypes.func,
  modalState: PropTypes.object,
  onModalStateChange: PropTypes.func,
  onValueChange: PropTypes.func,
}

export default JobOrgView
