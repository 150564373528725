import createSelector from 'utils/createSelector'

const vendorId = (state, props) => parseInt(props.vendorId)

export const getModel = createSelector(vendorId,
  ({ Vendor }, vendorId) => Vendor.withId(vendorId)
)

export const getVendor = createSelector(getModel,
  ({ Vendor }, vendor) => ({
    ...vendor.ref,
    hqCountry: { ...vendor.hqCountry.ref }
  })
)
