import React from 'react'
import PropTypes from 'prop-types'
import { batch, useDispatch, useSelector } from 'react-redux'
import { downloadReport, deleteReport, resetReports } from 'redux/actions/reports'
import { getReportDetailsById } from 'routes/Reporting/selectors/reportData'
import ReportView from '../components/ReportView'
import { createFilter } from 'utils/redux/filter'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import { useFetch } from 'hooks/useFetch'
import Loader from 'components/Loader'
import { formatReportExtraFieldsDetails } from '../utilities'
import { flagAsRead, setLastCreatedJobId } from 'redux/actions/pollingJob'
import { getCompletedReportCreateJobs } from 'redux/selectors/pollingJobs'
import { isCot, isVendor } from 'redux/selectors/auth'
import { toast } from 'react-toastify'

const ReportDetailContainer = (props) => {
  const dispatch = useDispatch()
  const fetchOptions = [
    'countries',
    'companies',
    'payrolls',
    'businessUnits',
    'costCenters',
    'departments',
    {
      name: 'reportData',
      params: [
        {
          _computed: {
            filter: (state, pp) => createFilter({ id: props.reportId || props.match.params.reportId }),
          },
          disableObsoleteFlow: true,
        },
      ],
      forceFetch: true,
      dependants: [{ url: null, responseField: 'dataFileUrl', reduxEnabled: false, parent: 'reportData' }],
    },
  ]
  const fetcherData = useFetch(fetchOptions)

  const shouldUseDefaultApi = useSelector((state) => isCot(state))
  const isVendorUser = useSelector((state) => isVendor(state))
  const inProgressPollingJobs = useSelector((state) => getCompletedReportCreateJobs(state))
  const job = inProgressPollingJobs ? inProgressPollingJobs[0] : null

  const getBaseApiUsage = () => {
    let baseApiUsage = { forceDefaultAPI: shouldUseDefaultApi }
    if (isVendorUser) {
      baseApiUsage = { forceDefaultAPI: false }
    }
    return baseApiUsage
  }

  const deleteSelectedReport = (id) => {
    if (job) {
      batch(() => {
        dispatch(flagAsRead(job.id, job, getBaseApiUsage()))
        toast.dismiss(job.id)
      })
    }
    return dispatch(deleteReport(id))
      .then(() => dispatch(setLastCreatedJobId(null)))
      .then(() => dispatch(resetReports()))
      .then(() => {
        props.history.push('/reporting/customize-report')
        dispatch(
          showMessage({
            body: 'Successfully deleted.',
          })
        )
      })
      .catch((error) => dispatch(showMessage({ body: errorToString(error) })))
  }
  const report = useSelector((state) => {
    if (fetcherData.isFetching && !fetcherData.areDependentsLoaded) return null
    return getReportDetailsById(state, { reportId: props.reportId || props.match.params.reportId })
  })

  if (!fetcherData.areDependentsLoaded) return <Loader />

  const reportsData = fetcherData.dependantData['reportData'].find((items) => items.parent === 'reportData').data ?? []
  const remappedData = formatReportExtraFieldsDetails(reportsData)

  return (
    <ReportView
      downloadReport={(id) => dispatch(downloadReport(id))}
      deleteReport={deleteSelectedReport}
      report={report}
      reportsData={remappedData}
      job={job}
    />
  )
}

ReportDetailContainer.propTypes = {
  reportId: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default ReportDetailContainer
