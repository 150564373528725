import React from 'react'

import Logo from '../Logo'
import { isMultitenancyEnabled } from 'utils/config'
import TenantSelectorContainer from 'containers/TenantSelectorContainer'
import MainNav from 'components/MainNav'
import { addRouteOptions, asideGlobalRoutes } from './routes'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'

const generateRoutes = ({ controlCenterUrl, userHasAccessToPICC, hasAccessToResources }) =>
  [
    addRouteOptions(asideGlobalRoutes.home, { isHome: true }),
    asideGlobalRoutes.countries,
    asideGlobalRoutes.globalTermsSubcategories,
    asideGlobalRoutes.clients,
    asideGlobalRoutes.vendors,
    ...(hasAccessToResources ? [asideGlobalRoutes.resources] : []),
    asideGlobalRoutes.alerts,
  ]?.filter(Boolean)

export const AsideCosOem = (props) => {
  const { history, asideMode, selectAside, controlCenterUrl, userHasAccessToPICC, hasAccessToResources } = props
  const menuItems = generateRoutes({ controlCenterUrl, userHasAccessToPICC, hasAccessToResources })

  return (
    <aside className='o-aside'>
      <Logo />
      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}
      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />
      <MainNav asideMode={asideMode} items={menuItems} {...props} />
    </aside>
  )
}
