import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { completionURIs } from 'redux/config/tasks'
import { tasksIdentifiers, creatableIdentifiers } from 'redux/config/tasksIdentifiers'

const identifiers = creatableIdentifiers.map(i => ({
  value: tasksIdentifiers[i].code,
  label: tasksIdentifiers[i].name
}))

const TaskCreate = (props) => {
  const { processes, steps, owners } = props
  return (
    <div>
      <SectionHeading text='Create action' />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Form showSubmitBtn btnText='Submit' {...props}>
            <div className='o-form-group'>
              <Field
                name='name'
                label='Name'
                component={InputField}
                type='text'
                className='c-input'
                labelClassName='c-label'
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='identifier'
                label='Identifier'
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                clearable={false}
                placeholder='Select'
                options={identifiers}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='finishTaskLink'
                label='Action completion link'
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                clearable={false}
                placeholder='Select'
                options={completionURIs.map(uri => ({
                  value: uri.value,
                  label: uri.uri
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='owner'
                label='Owner'
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder='Select owner'
                options={owners.map(owner => ({
                  value: owner,
                  label: owner
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='process'
                label='Process'
                component={CustomSelectField}
                labelClassName='c-label'
                clearable={false}
                placeholder={processes.length ? 'Select a process' : 'Loading...'}
                options={processes.map(process => ({
                  value: process.id,
                  label: process.name
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='step'
                label='Step'
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder='Select step'
                options={steps.map(step => ({
                  value: step.id,
                  label: step.name
                }))}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'task', // a unique identifier for this form
})(TaskCreate)
