import createSelector from 'utils/createSelector'

export const getResources = createSelector(({ Resource }) => {
  return Resource.all().toRefArray()
})

export const getFilteredResources = createSelector(
  (state, filterName) => {
    const entity = 'resources'
    return state[entity].filters[filterName]?.ids
  },
  ({ Resource }, filteredIds = []) => {
    return filteredIds?.map((id) => Resource.withId(id))
  }
)
