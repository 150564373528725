import React from 'react'
import { useHistory } from 'react-router'

export const Back = ({ onBackNavigate }) => {
  const history = useHistory()
  return <div
    onClick={onBackNavigate || history.goBack}
    data-testid='back-button'
    className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
    title='Back'
  >
    <i className='icon--back' /> Back
  </div>
}

export default Back
