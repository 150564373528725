import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import moment from 'moment'
import Fetcher from 'containers/Fetcher'
import LeaveCreate from '../components/LeaveCreate'
import { getEmployee } from '../../../selectors/employeesORM'
import termCategoriesConfig from 'redux/config/termCategories'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import { fetchEmployeeAttendance } from 'redux/actions/employeeAttendance'
import { getAttendancesPeriodById } from '../selectors/employeeAttendancePeriodORM'
import { getCompanyCountryTermsPivotByCompanyAndCategoryForAttendance } from 'redux/selectors/companyCountryTermPivot'
import { bulkSyncAttendances, fetchEmployeeAttendancePeriods } from 'redux/actions/employeeAttendancePeriods'

const mapDispatchToProps = (dispatch, props) => {
  const employeeId = parseInt(props.match.params.employeeId)

  return {
    onSubmit: data => (
      dispatch(bulkSyncAttendances(data)).then(() => {
        props.onSubmit()
        dispatch(fetchEmployeeAttendancePeriods(employeeId))
        dispatch(fetchEmployeeAttendance())
      })
    )
  }
}

const mapStateToProps = (state, props) => {
  const employee = getEmployee(state, { employeeId: props.match.params.employeeId })

  const leaveTypes = getCompanyCountryTermsPivotByCompanyAndCategoryForAttendance(state, {
    companyId: employee.company,
    categoryName: termCategoriesConfig.timeAttendance
  })

  const attendancePeriod = getAttendancesPeriodById(state, { id: props.id })
  const setToDate = attendancePeriod.estimatedLastDayOfLeave
    ? moment(attendancePeriod.estimatedLastDayOfLeave).toDate()
    : moment(attendancePeriod.toDate).toDate()

  const setActualDayOfLeave = attendancePeriod.actualLastDayOfLeave
    ? moment(attendancePeriod.actualLastDayOfLeave).toDate()
    : null

  const setLastDayOfWork = attendancePeriod.lastDayOfWork
    ? moment(attendancePeriod.lastDayOfWork).toDate()
    : null

  return {
    leaveTypes,
    initialValues: {
      employeeId: employee.id,
      companyCountryTerm: attendancePeriod.companyCountryTerm,
      from: moment(attendancePeriod.fromDate).toDate(),
      to: setToDate,
      actualLastDayOfLeave:setActualDayOfLeave,
      lastDayOfWork: setLastDayOfWork,
      previousFromDate: moment(attendancePeriod.fromDate).toDate(),
      previousToDate: moment(attendancePeriod.toDate).toDate(),
      totalDays: attendancePeriod.totalDays
    },
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'employeeLeaveEdit'
})(LeaveCreate))

export default Fetcher(Container, [
  'terms', 'termCategories', 'termSubCategories', 'countryTermPivot', 'companyCountryTermPivot',
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
