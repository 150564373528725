import loadable from '@loadable/component'
import CoreLayout from 'layouts/PageLayout/PageLayout'

const RouteViewLoadable = loadable(() => import('./components/RouteView'))

export default (store) => ({
  path: 'previous',
  indexRoute: { component: RouteViewLoadable },
  tabbedRoute: true,
  wrappedComponent: CoreLayout,
  authProtected: true,
})
