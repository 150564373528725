import React from 'react'
import Modal from 'components/Modal'
import SectionHeading from 'components/SectionHeading'
import ResourcesUploadForm from 'routes/Resources/components/ResourcesUploadForm'
import { resourcesUploadText } from 'utils/locales/resources.en'

const ResourcesUploadHeader = ({ uploadModalRef, ...rest }) => {
  return (
    <>
      <Modal ref={uploadModalRef} className='c-modal' modalHeading={resourcesUploadText.header}>
        <ResourcesUploadForm {...rest} />
      </Modal>
      <SectionHeading>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <div className='u-float--right'>
            <button
              title='Upload file'
              onClick={() => uploadModalRef.current.showModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            >
              <span className='icon icon--upload' />
            </button>
          </div>
        </div>
      </SectionHeading>
    </>
  )
}

export default ResourcesUploadHeader
