import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import AmountFormat from 'components/AmountFormat'
import EditableCell from 'components/table/cell/EditableCell'
import { batch, useDispatch } from 'react-redux'
import { updateEmployeeSystemUser } from 'redux/actions/employeeSystemUsers'
import { invalidateAnnualSalaries } from 'redux/actions/annualSalaries'
import ConfirmationModal from 'components/ConfirmationModal'
import { confirmMessage } from 'components/payruns/Messages'
import { showMessage } from 'redux/actions/modal'
import { Authorization } from 'containers/Authorization'
import { isString } from 'lodash'

const getPostConfirmationMessage = {
  active: {
    confirmed: 'Annual Salary change will not automatically update the Basic pay /' + ' Salary value in employee’s file, so please update it.',
    rejected:
      'Please note that the updated Annual Salary value will be used in the next payrun. ' +
      'This change will not automatically update the Basic pay / Salary value in employee’s file, so please update it.',
  },
  locked: {
    confirmed: (
      <div>
        <p>{confirmMessage}</p>
        <p>Annual Salary change will not automatically update the Basic pay / Salary value in employee's file, so please update it.</p>
      </div>
    ),
    rejected:
      'Please note that the updated Annual Salary value will be used in the next payrun. ' +
      'This change will not automatically update the Basic pay / Salary value in employee’s file, so please update it.',
  },
}

const InlineButtons = ({ onEditClick, onHistoryClick = () => null, areButtonsDisable = false }) => {
  return (
    <div className='d-flex justify-content-end'>
      <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
        <button
          onClick={onEditClick}
          className={`c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny ${areButtonsDisable && 'disabled'}`}
          title='Edit'
          disabled={areButtonsDisable}
          data-testid='annual-salary-edit'
        >
          <span className='icon icon--edit' />
        </button>
      </Authorization>
      <button
        onClick={onHistoryClick}
        className={`c-btn c-btn--tiny c-btn--curious u-text--tiny ${areButtonsDisable && 'disabled'}`}
        title='History'
        data-testid='annual-salary-history'
        disabled={areButtonsDisable}
      >
        <span className='icon icon--history' />
      </button>
    </div>
  )
}

InlineButtons.propTypes = {
  onEditClick: PropTypes.func,
  onHistoryClick: PropTypes.func,
  areButtonsDisable: PropTypes.bool,
}

const formatSalaryData = (salaryData) => {
  if (salaryData.value) {
    salaryData['annualSalaryValue'] = salaryData.value
  }
  return salaryData
}

const verifyData = (salaryData) => {
  const errors = []
  let error = { effectiveDate: [], annualSalaryValue: [] }
  // If Nothing has change provide both errors
  if (!salaryData.isDirty) {
    error.effectiveDate = 'Effective Date Required'
    error.value = 'Annual Salary Value field is required'
    errors.push(error)
    return errors
  }
  // If the salary hasn't changed through the er
  if (salaryData.isDirty && !salaryData.salaryIsDirty) {
    error.value = 'Annual Salary Value field is required'
    errors.push(error)
  }

  if (!salaryData.effectiveDate) {
    error.effectiveDate = 'Effective Date Required'
    errors.push(error)
  }

  if (!salaryData.value || (salaryData.value && isString(salaryData.value) && salaryData.value.trim() === '')) {
    error.value = 'Annual Salary Value field is required'
    errors.push(error)
  }
  return errors
}

const AnnualSalaryTableView = (props) => {
  const {
    inlineMode,
    setInlineMode,
    disableSalaryInlineButtons,
    onAnnualSalaryHistoryBtnClick,
    showConfirmation,
    hasEmployeeActiveInstances,
    hasEmployeeLockedInstances,
    hideInlineButtons,
  } = props
  const defaultData = { value: props.salary, effectiveDate: null, reason: null, id: props.employeeId }
  const dispatch = useDispatch()
  const confirmationModal = useRef(null)
  const [salaryData, setSalaryDate] = useState(defaultData)
  const [fieldErrors, setFieldErrors] = useState([])

  const revertModifyingState = () => {
    setInlineMode(false)
    setFieldErrors([])
  }

  const getDataForSave = () => formatSalaryData(salaryData)

  const submitData = (payload) => {
    let promise = dispatch(updateEmployeeSystemUser(payload, { shouldFetch: false, shouldInvalidate: true }, true))
    promise = promise.then((resp) => {
      dispatch(invalidateAnnualSalaries())
      revertModifyingState()
    })
    return promise
  }

  const showConfirmMessage = () => {
    confirmationModal.current.showModal()
  }

  const editableOptions = {
    inGlobalEditMode: false,
    inAddMode: inlineMode,
    onUpdateGlobalData: (rowIndex, columnId, value) =>
      setSalaryDate((old) => {
        let hasChangedSalary = {}
        if (columnId === 'value') {
          hasChangedSalary = { salaryIsDirty: true }
        }
        return { ...old, [columnId]: value, isDirty: true, ...hasChangedSalary }
      }),
    onCancelInlineAdd: () => {
      batch(() => {
        setInlineMode(false)
        setSalaryDate(defaultData)
        setFieldErrors([])
      })
    },
    onSaveInline: () => {
      const payload = getDataForSave()
      const errors = verifyData(payload)
      if (errors.length) {
        setFieldErrors(errors)
        return
      }

      let showConfirmApplyToPayroll = payload.annualSalaryValue !== props.salary && showConfirmation
      // Want to show the confirmation message to apply not apply the salary to the current payroll
      // Otherwise we just submit the data
      if (showConfirmApplyToPayroll) {
        showConfirmMessage()
        return
      }
      return submitData(payload)
    },
    fieldErrors: fieldErrors,
  }

  const inlineButtons = { hasInlineSave: true, hasInlineCancel: true }
  const viewColumns = [
    new TableHeading({ accessor: 'title', heading: 'Title', disableSortBy: false, className: 'd-none', columnClassName: 'text-left' }),
    new TableHeading({ accessor: 'value', heading: 'Value', disableSortBy: false, className: 'd-none', columnClassName: 'text-right' }),
    new TableHeading({ accessor: 'buttons', heading: 'Buttons', disableSortBy: false, className: 'd-none', columnClassName: 'text-right u-fixed-width--100' }),
  ]
  const inlineModeHeaders = [
    new TableHeading({ accessor: 'title', heading: 'Title', disableSortBy: false, className: 'd-none', columnClassName: 'u-text-left' }),
    {
      ...new TableHeading({ accessor: 'value', heading: 'Value', disableSortBy: false, className: 'd-none' }),
      Cell: EditableCell,
      placeholder: 'Value',
      setWidth: 250,
      editable: true,
      inputType: 'amount',
    },
    {
      ...new TableHeading({ accessor: 'effectiveDate', heading: 'Effective Date', disableSortBy: false, className: 'd-none' }),
      Cell: EditableCell,
      editable: true,
      placeholder: 'Effective Date',
      inputType: 'date',
    },
    {
      ...new TableHeading({ accessor: 'reason', heading: 'Reason', disableSortBy: false, className: 'd-none' }),
      Cell: EditableCell,
      ...inlineButtons,
      editable: true,
      placeholder: 'Reason',
    },
  ]
  let tableData = [
    {
      title: 'Annual Salary',
      value: <AmountFormat amount={parseFloat(salaryData.value)} />,
      buttons: !hideInlineButtons ? (
        <InlineButtons
          onEditClick={() => setInlineMode(!inlineMode)}
          onHistoryClick={onAnnualSalaryHistoryBtnClick}
          areButtonsDisable={disableSalaryInlineButtons}
        />
      ) : null,
      metaData: {},
    },
  ]

  if (inlineMode) {
    // A bit of a hack for now, to show an error message for the effective date
    // Didn't want to modify the table too much, and this list only has one validation
    // Being the effective date
    tableData = [
      {
        title: 'Annual Salary',
        value: null,
        effectiveDate: null,
        reason: null,
        isNew: true,
        metaData: {
          checkForEmptyValue: { shouldCheck: true, field: 'value' },
          checkForEmptyDate: { shouldCheck: true, field: 'effectiveDate' },
        },
      },
    ]
  }

  return (
    <div id='pay-taxes-annual-salary-table' className='o-layout'>
      <PayslipTable
        wrapperClassName='o-layout__item u-1/1 react-bs-table--overflow-auto'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-pay-and-taxes-salary'
        editableOptions={editableOptions}
        dynamicValues
        headings={[
          {
            Header: 'Annual Salary',
            classNames: 'text-center',
            columns: inlineMode ? inlineModeHeaders : viewColumns,
          },
        ]}
        data={tableData}
      />
      <ConfirmationModal
        ref={confirmationModal}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => {
          const payload = getDataForSave()
          submitData({ ...payload, inherit: true })
          if (showConfirmation) {
            const status = hasEmployeeActiveInstances ? 'active' : hasEmployeeLockedInstances ? 'locked' : null
            status &&
              dispatch(
                showMessage({
                  body: getPostConfirmationMessage[status]['confirmed'],
                })
              )
          }
        }}
        onReject={() => {
          const payload = getDataForSave()
          submitData({ ...payload, inherit: false })
          if (showConfirmation) {
            const status = hasEmployeeActiveInstances ? 'active' : hasEmployeeLockedInstances ? 'locked' : null
            status &&
              dispatch(
                showMessage({
                  body: getPostConfirmationMessage[status]['rejected'],
                })
              )
          }
        }}
      >
        <p>Do you want to apply the change of annual salary for the current payrun?</p>
      </ConfirmationModal>
    </div>
  )
}

AnnualSalaryTableView.propTypes = {
  salary: PropTypes.number,
  onAnnualSalaryHistoryBtnClick: PropTypes.func,
  employeeId: PropTypes.number,
  inlineMode: PropTypes.bool,
  setInlineMode: PropTypes.func,
  disableSalaryInlineButtons: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  hasEmployeeActiveInstances: PropTypes.bool,
  hasEmployeeLockedInstances: PropTypes.bool,
  hideInlineButtons: PropTypes.bool,
}
export default AnnualSalaryTableView
