import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SummaryDisplayCard from '../components/SummaryDisplayCard'
import { useDispatch } from 'react-redux'
import { fetchPayrollInstanceOverview, updatePayrollInstances } from 'redux/actions/payrollInstances'
import Raven from 'raven-js'
import { format } from 'date-fns'
import Loader from 'components/Loader'
import { formatNumberToCurrency } from 'utils/number'
import { payrunStatusesEnum } from 'utils/enums/payrunStatuses'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'

const SummaryCardContainer = (props) => {
  const { syncPayrollInstances } = props

  const [overviewData, setOverviewData] = useState(null)
  const [instanceData, setInstanceData] = useState(null)
  const dispatch = useDispatch()
  const fetchOverviewData = async () => {
    const res = await dispatch(fetchPayrollInstanceOverview(props.payrollInstanceId))
    if (res) {
      setOverviewData(res)
    }
  }

  useEffect(() => {
    fetchOverviewData()
  }, [dispatch, props.payrollInstanceId])

  useEffect(() => {
    if (overviewData && props.payrollInstance) {
      const data = {
        companyCode: overviewData.companyCode,
        countryAbbr: props.payrollInstance.countryAbbr.toLowerCase(),
        country: props.payrollInstance.countryName,
        currency: overviewData.currency,
        currentPayrun: formatNumberToCurrency(props.payrollInstance.userDefinedPayrunValue || 0, false, 'ENEN', null, 2),
        headcount: overviewData.headcount,
        payroll: props.payrollInstance.payrollName,
        payrollPeriod: props.payrollInstance.period,
        payDate: format(new Date(props.payrollInstance.payDate.date), dateFormatEnums.DefaultDateFormatWithoutTime),
        payrunDifference: overviewData.payrunDifference,
        previousPayrun: overviewData.previousPayrun,
      }
      setInstanceData(data)
    }
  }, [overviewData, props.payrollInstance])

  const onUpdatePayrunTotals = async (data) => {
    // BE requires the userDefinedPayrunValue to be a string to be able to handle large numbers
    const remapData = {
      id: props.payrollInstanceId,
      userDefinedPayrunValue: data.userDefinedPayrunValue.toString(),
    }
    await dispatch(updatePayrollInstances(remapData)).then(
      (res) => res,
      (err) => {
        Raven.captureException(err)
        return { hasError: true }
      }
    )
    await syncPayrollInstances()
    await fetchOverviewData()
  }

  // The edit button to update the current payrun totals has to be disabled if the payrun is completed, completed-with-extra-tasks or inactive
  // User should be able to input a custom value for userDefinedPayrunValue for all other payrun statuses
  const isUpdatePayrunTotalsDisabled = [payrunStatusesEnum.COMPLETED, payrunStatusesEnum.COMPLETED_WITH_EXTRA_TASKS, payrunStatusesEnum.INACTIVE].includes(
    props.payrollInstance.status
  )

  if (!instanceData) return <Loader />

  return <SummaryDisplayCard data={instanceData} onUpdatePayrunTotals={onUpdatePayrunTotals} isUpdatePayrunTotalsDisabled={isUpdatePayrunTotalsDisabled} />
}

SummaryCardContainer.propTypes = {
  syncPayrollInstances: PropTypes.func.isRequired,
  payrollInstanceId: PropTypes.string.isRequired,
  payrollInstance: PropTypes.shape({
    countryAbbr: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    userDefinedPayrunValue: PropTypes.number,
    payrollName: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    payDate: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default SummaryCardContainer
