import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/subsidiaries'

class Subsidiary extends CRUDModel {}
Subsidiary.modelName = 'Subsidiary'
Subsidiary.actionTypes = actionTypes

Subsidiary.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'subsidiaries'),
}

export default Subsidiary
