import { useLocation } from 'react-router'

/**
 * Method to generate the pathname to use in place of hardcoded location
 *
 * Retrieve the query param of choice using the following class method
 * @param {string} override Text that you want to override the location pathname with
 * @param {boolean} condition A condition that is required to trigger the override
 * @returns {string} pathname string
 */
export default function usePathName (override = null, condition = false) {
  if (condition && !override) {
    throw Error('You must provide an override if providing a condition for it to show')
  }
  const location = useLocation()
  return condition ? override : location.pathname
}
