import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import Loader from 'components/Loader'
import { isEmpty } from 'utils/fnkit/typeChecks'

const ResourcesList = ({ isFetching, headings, data, pagination, setSort }) => {
  if (isFetching) return <Loader />

  const onPageChange = ({ pageIndex }) => {
    pagination.onPageChange(pageIndex * pagination.limit)
  }

  const onSort = ({ sortBy }) => {
    if (!isEmpty(sortBy)) {
      const [{ id, desc }] = sortBy
      setSort([{ name: id, order: desc ? 'desc' : 'asc' }])
    }
  }

  const options = {
    pageSize: pagination.limit,
    pageIndex: pagination.currentPage,
    pageCount: pagination.totalPages,
    onPageChange,
    onSort,
  }

  return <PayslipTable headings={headings} data={data} options={options} remote pagination remoteSorting />
}

export default ResourcesList
