import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_TERM_PIVOT_FETCH = 'EMPLOYEE_TERM_PIVOT_FETCH'
export const EMPLOTEE_TERM_PIVOT_RECEIVE = 'EMPLOTEE_TERM_PIVOT_RECEIVE'
export const EMPLOTEE_TERM_PIVOT_INVALIDATE = 'EMPLOTEE_TERM_PIVOT_INVALIDATE'
export const EMPLOTEE_TERM_PIVOT_MERGE = 'EMPLOTEE_TERM_PIVOT_MERGE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_TERM_PIVOT_FETCH,
  receive: EMPLOTEE_TERM_PIVOT_RECEIVE,
  invalidate: EMPLOTEE_TERM_PIVOT_INVALIDATE,
  mergeTerms: EMPLOTEE_TERM_PIVOT_MERGE,
}
const actionUtility = new ActionUtility(actionTypes, 'employeeTermPivot', 'employeeterms', 'EmployeeCompanyCountryTerm')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeTermPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeTermPivot = actionUtility.fetchEntities
export const invalidateEmployeeTermPivot = actionUtility.invalidate

const buildTemplateURI = payrollId => `bulkimport?payroll=${payrollId}`

export const downloadEmployeeTermsTemplate = payrollId => (
  actionUtility.downloadEntity({ childUri: buildTemplateURI(payrollId) })
)
export const downloadEmployeeTermsWrongImportedFile = fullUrl => actionUtility.downloadEntity({ fullUrl })
export const importEmployeeTerms = (payrollId, file) => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: buildTemplateURI(payrollId), shouldHandleErrors: false }))
)

export const mergeEmployeeTerms = (employeeId, payload, params = {}) => {
  return (dispatch, getState, { api }) => {
    return api.put(`employeeterms`, { payload })
  }
}
