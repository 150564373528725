import { createReducer } from './reducer'

/**
 * Default reducer handlers for the entities meta data
 *
 * Using this reducer, we will keep entities meta data (as `isFetching`, `didInvalidated`).
 *
 * We create such reducer, because redux-orm doesn't support adding custom Model properties.
 * @ref https://github.com/tommikaikkonen/redux-orm/issues/152
 *
 * It would be better if all entities meta data to be stored in top level reducer, for instance `meta`:
 * ```
 * {
 *   meta: {
 *     companies: { isFetching: true }
 *   }
 * }
 * ```
 *
 * We won't do it in that way, because before using `redux-orm`, we organized our entities with `reducer-entities.js`,
 * and all entities were kept globally in the Store, i.e. :
 *
 * ```
 * // Please check utils/redux/reducer-entities.js`
 * {
 *   companies: {...},
 *   countries: {...}
 * }
 * ```
 * Therefore we'll keep most of `reducer-entities` Store structure (only the meta, without the data) here.
 * Thanks to this, we shouldn't change any components.
 *
 * @param {Object} actionTypes - Action types mapping. We support `fetch` and `receive` action types,
 * therefore we need to provide the real Entity action types values, mapped to the supported ones.
 * @example {
 *  fetch: COUNTRIES_FETCH,
 *  receive: COUNTRIES_RECEIVE,
 *  setActiveId: COUNTRIES_SET_ACTIVE_ID,
 * }
 */
export const getHandlers = actionTypes => {
  const handlers = {
    [actionTypes.fetch]: (state, { payload: { filter = { name: '/' }, requestedAt } = {} } = {}) => ({
      ...state,
      // If we're fetching everything and because of legacy reasons (before we always fetch all items),
      // change the flag to `true`, otherwise we keep the flag per filter below.
      ...filter.name === '/' ? { isFetching: true } : {},
      filters: {
        ...state.filters,
        [filter.name]: {
          ...state.filters[filter.name],
          isFetching: true,
          requestedAt
        },
      }
    }),
    [actionTypes.receive]: (state, { payload: { response, filter = { name: '/', params: {} } } = {} } = {}) => ({
      ...state,
      // If we're receiving everything and because of legacy reasons (before we always fetch and receive all items),
      // change the flags, otherwise we keep the flags per filter below
      ...filter.name === '/' ? {
        isFetching: false,
        didInvalidated: false,
        receivedAt: Date.now()
      } : {},
      filters: {
        ...state.filters,
        [filter.name]: {
          ...state.filters[filter.name],
          isFetching: false,
          didInvalidated: false,
          receivedAt: Date.now(),
          params: filter.params,
          totalCount: response.totalCount,
          flags: response.flags,
          ids: response.data.map(entity => entity.id)
        }
      }
    }),
    [actionTypes.receiveWithErrors]: (state, { payload }) => ({ ...state, errorPayload: payload }),
    [actionTypes.clearErrors]: (state, action) => ({ ...state, errorPayload: null }),
    [actionTypes.filter]: (state, action) => ({ ...state, isFiltering: true }),
    [actionTypes.filtered]: (state, { payload }) => ({
      ...state,
      filteredIds: payload.data.data.map(entity => entity.id),
      filtersLegacy: payload.filters,
      isFetching: false,
      isFiltering: false
    }),
    [actionTypes.resetFilters]: (state, action) => ({ ...state, filteredIds: defaultState.filteredIds }),
    [actionTypes.setActiveId]: (state, { payload }) => ({ ...state, activeId: payload.id }),
    [actionTypes.setLastCreatedId]: (state, { payload }) => ({ ...state, lastCreatedId: payload.id }),
    [actionTypes.setLastRunReportType]: (state, { payload }) => ({ ...state, lastRunReportType: payload.obj }),
    [actionTypes.setShouldPoll]: (state, { payload }) => ({ ...state, shouldPoll: payload.enablePolling }),
    [actionTypes.setStartPolling]: (state, { payload }) => ({ ...state, startPolling: payload.shouldStartPolling }),
    [actionTypes.setPollingDelay]: (state, { payload }) => ({ ...state, delay: payload.pollingDelay }),
    [actionTypes.invalidateSpecificFilter]: (state, { payload }) => {
      const filters = Object.keys(state.filters).reduce((filters, filterName) => {
        if (filterName === payload) {
          filters[filterName].didInvalidated = true
        }
        return filters
      }, { ...state.filters })
      return { ...state, filters }
    },
    [actionTypes.invalidate]: (state, action) => {
      const filters = Object.keys(state.filters).reduce((filters, filterName) => {
        filters[filterName].didInvalidated = true
        return filters
      }, { ...state.filters })

      return { ...state, didInvalidated: true, filters }
    },
    // TODO - reset/delete functionality is already improved/refactored in this PR:
    //  https://gitlab.com/dev-labs-bg/payslip-frontend/-/merge_requests/1149
    //  It's not prioritized and tested yet, and we can't use/merge it.
    //  However, we had a `documentuser` unshare issue,
    //  and we copied the `reset` flow (part of above PR), in order to fix the issue.
    //  The issue is: When you unshare a User and reopen the Share modal, the User is still checked.
    [actionTypes.reset]: () => ({ ...defaultState })
  }

  // If we don't pass all the supported `actionTypes`,
  // then in the handlers we have an `undefined` handler function
  delete handlers.undefined

  return handlers
}

export const defaultState = {
  /**
   * @inheritDoc utils/redux/fetching.js
   */
  isFetching: null,
  isFiltering: null,
  /**
   * The ID of a model,
   * that is being processed at specific moment.
   *
   * @example - Assigning Employees to a Paygroup:
   * Firstly we're on the Paygroup page, where we select a Paygroup,
   * and once we want to assign employees to the already selected Paygroup,
   * then we transition to the Employees page.
   * In that case, located at  Employees page, we have to know to which Paygroup we're assigning employees.
   * Therefore, we keep the selected Paygroup ID in `activeId` meta property.
   *
   * @type {Number}
   */
  activeId: null,
  didInvalidated: false,
  receivedAt: null,
  lastCreatedId: null,
  receiveWithErrors: null,
  /**
   * Keep the ids, that the BE returns when we do filtration
   *
   * Later these ids, are used by the selectors,
   * in order to display only the filtered entities.
   *
   * @type {Array}
   */
  filteredIds: null,
  filters: {},
  filtersLegacy: {}
}

export default (actionTypes, initialState, handlers) => {
  initialState = { ...defaultState, ...initialState }
  handlers = { ...getHandlers(actionTypes), ...handlers }
  return createReducer(initialState, handlers)
}
