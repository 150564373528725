import React from 'react'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import Modal from 'components/Modal'
import LeaveFormEditContainer from '../containers/LeaveFormEditContainer'
import LeaveDeleteContainer from '../containers/LeaveDeleteContainer'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'

class LeavesTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      attendancePeriodId: null
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    // Reset period id, when there aren't any attendances,
    // because it's possible the id to be cached by previously edited period.
    // Here is an example:
    // 1. There are attendances and we render them..
    // 2. We click and edit an instance.
    // 3. On edit we bulk PATCH all attendances, therefore all employee attendances are deleted.
    // 4. The period id would be still in the state.
    // 5. So in that case we would pass a deleted id to `<LeaveFormEditContainer />`,
    // that would result in an error.
    if (!nextProps.attendances.length) {
      this.setState({ attendancePeriodId: null })
    }
  }

  /**
   * Handle modal opening
   * @param modalRef
   */
  handleModalOpen (modalRef, id) {
    modalRef.showModal()
    this.setState({ attendancePeriodId: id })
  }

  renderRows () {
    const { attendances } = this.props
    return attendances.map(({ id, fromDate, toDate, localName, globalName, totalDays }) => {
      return {
        leave: `${moment(fromDate).format('DD/MM/YY')} - ${moment(toDate).format('DD/MM/YY')}`,
        type: <span data-testid='leave-type'>{ globalName } ({ localName })</span>,
        actions: <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
          <div>
            <div
              onClick={() => this.handleModalOpen(this.refs.modalEditLeave, id)}
              data-testid={`leave-edit-${id}`}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny' title='Edit'>
              <span className='icon icon--edit' />
            </div>
            <div
              onClick={() => this.handleModalOpen(this.refs.modalDeleteLeave, id)}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              title='Delete' ><span className='icon icon--trash' />
            </div>
          </div>
        </Authorization>,
        totalDays: totalDays
      }
    })
  }

  render () {
    const { attendancePeriodId } = this.state
    const { hasAccess } = this.props

    const hasAccessToEdit = hasAccess(['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'])

    return <div>
      <PayslipTable
        wrapperClassName='u-1/1'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-time-and-attendance-leaves'
        headings={[
          {
            Header: 'Leave',
            accessor: 'leave',
            disableFilters: true,
            disableSortBy: true
          },
          {
            Header: 'Type',
            accessor: 'type',
            disableFilters: true,
            disableSortBy: true
          },
          {
            Header: 'Total Days',
            accessor: 'totalDays',
            disableFilters: true,
            disableSortBy: true
          },
          {
            Header: hasAccessToEdit ? 'Actions' : '',
            accessor: 'actions',
            actionCell: true,
            disableFilters: true,
            disableSortBy: true
          }
        ]}
        data={this.renderRows()}
      />

      <Modal
        ref='modalEditLeave'
        className='c-modal c-modal--half'
        modalHeading='Edit Personal Leave'
        data-testid='leave-edit-modal'
      >
        { attendancePeriodId
          ? <LeaveFormEditContainer onSubmit={() => this.refs.modalEditLeave.hideModal()} id={this.state.attendancePeriodId} {...this.props} />
          : null }
      </Modal>

      <BaseConfirmationModal
        ref='modalDeleteLeave'
        className='c-modal'
        modalHeading='Confirmation'
      >
        <LeaveDeleteContainer
          attendancePeriodId={this.state.attendancePeriodId}
          onSubmit={() => this.refs.modalDeleteLeave.hideModal()} {...this.props}
          onReject={() => this.refs.modalDeleteLeave.hideModal()} />
      </BaseConfirmationModal>

    </div>
  }
}

LeavesTable.propTypes = {
  attendances: PropTypes.array,
  hasAccess: PropTypes.func
}

export default LeavesTable
