import { sortTypes } from './enums/sort'

/**
 * Sorts two objects by their 'lastModifiedAt' date property.
 * @param {Object} origin - The first object to compare.
 * @param {Object} next - The second object to compare.
 * @returns {number} A negative value if 'next' should appear before 'origin',
 *                    zero if they are equal, and a positive value if 'next' should appear after 'origin'.
 */
export const sortByLastModifiedAt = (origin, next) => {
  const originDate = new Date(origin.lastModifiedAt.date)
  const nextDate = new Date(next.lastModifiedAt.date)
  return nextDate - originDate
}

/**
 * A comparator function for sorting objects based on a specified property.
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {Object} options - Options for sorting.
 * @param {string} options.sortStatus - The sort order, can be 'asc' for ascending or 'desc' for descending.
 * @param {string} options.key - The property key by which the objects should be sorted.
 * @returns {number} - A negative number if 'a' should come before 'b', a positive number if 'a' should come after 'b',
 *                     or zero if 'a' and 'b' are considered equal according to the sorting criteria.
 */
export const sortByProperty = (a, b, { sortStatus, key }) => (sortStatus === sortTypes.asc ? a[key] - b[key] : b[key] - a[key])
