import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import PropTypes from 'prop-types'
import { fetchCompanies } from 'routes/Companies/modules/actions'
import { fetchCountries } from 'routes/Countries/modules/actions'
import { fetchParentCompanies } from 'redux/actions/parentCompanies'
import { fetchVendors } from 'redux/actions/vendors'
import { fetchVendorCountryServicePivot } from 'redux/actions/vendorCountryServicePivot'
import { fetchVendorSwitch } from 'redux/actions/vendorSwitch'
import { fetchCompanyServicePivot } from 'redux/actions/companyServicePivot'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import CompanyList from 'components/companies/CompanyList'
import HeadQuarter from 'components/companies/HeadQuarter'
import { getVendorCompanies, getVendorSpecialStatusCompanies } from 'redux/selectors/vendorCountryServices'
import { logos } from 'utils/config'
import { getSortedAccessibleCompanies } from 'redux/selectors/company'

class CompaniesContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetch()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    const isVendorChanged = this.props.match.params.vendorId !== nextProps.match.params.vendorId
    const isTenantChanged = this.props.match.params.tenantId !== nextProps.match.params.tenantId

    if (isVendorChanged || isTenantChanged) this.props.fetch()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>
    return <div>
      <div className='o-layout u-relative'>
        <div className='o-layout__item u-margin-bottom u-text--center u-1/1'>
          <HeadQuarter {...this.props} />
        </div>
      </div>

      <CompanyList {...this.props} />
    </div>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetch: () => {
      dispatch(fetchCompanies({ forceVendorTenantAPI: true }))
      dispatch(fetchCountries({ forceVendorTenantAPI: true }))
      dispatch(fetchParentCompanies({ forceVendorTenantAPI: true }))
      dispatch(fetchVendors({ forceVendorTenantAPI: true }))
      dispatch(fetchVendorCountryServicePivot({ forceVendorTenantAPI: true }))
      dispatch(fetchCompanyServicePivot({ forceVendorTenantAPI: true }))
      dispatch(fetchVendorSwitch({ forceVendorTenantAPI: true }))
    }
  }
}

const mapStateToProps = (state, props) => {
  const { companies: companiesEntity, countries, parentCompanies,
    vendors, vendorCountryServicePivot, companyServicePivot } = state

  if (isFetching([companiesEntity, countries, parentCompanies,
    vendors, vendorCountryServicePivot, companyServicePivot])) return { isFetching: true }

  const tenant = getParentCompanyById(state, { parentCompanyId: props.match.params.tenantId })
  const logo = logos ? logos[tenant.schema] : null
  const accessibleCompanies = getVendorCompanies(state, { vendorId: props.match.params.vendorId })

  const onboardingCompanies = getVendorSpecialStatusCompanies(state, { vendorId: props.match.params.vendorId, vendorStatus: 'onboarding' })
  const offboardingCompanies = getVendorSpecialStatusCompanies(state, { vendorId: props.match.params.vendorId, vendorStatus : 'offboarding' })

  return {
    companies: getSortedAccessibleCompanies([...accessibleCompanies, ...onboardingCompanies, ...offboardingCompanies]),
    tenantName: tenant.name,
    logo
  }
}

CompaniesContainer.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer)
