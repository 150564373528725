import createSelector from 'utils/createSelector'

/*
 * Helper func to get all needed company props
 */
export const _getCompanyProps = (company) => ({
  accessable: company.extraData.accessable,
  companyId: company.extraData.id,
  country: company.extraData.country,
  tenant: company.extraData.tenant,
  name: company.name,
  id: company.id,
})

export const getAccessableExternalParentCompanies = (state, filterName = '/') => {
  const entity = 'externalCompanies'
  return state[entity].filters[filterName]?.parentCompanies?.filter((c) => c?.extraData?.accessable)
}

export const getExternalCompanies = createSelector(({ ExternalCompany }) => {
  return ExternalCompany.all()
    .toRefArray()
    .map((company) => _getCompanyProps(company))
})

/**
 * Extracts accessible companies for a given vendor.
 * @param {Object[]} ownAccessCompanies - Array of all external companies.
 * @param {Object} vendor - The vendor object.
 * @param {Object} vendor.accessableCompanies - Accessible companies for the vendor.
 * @param {string} vendor.accessableCompanies.tenant - Tenant identifier.
 * @param {string[]} vendor.accessableCompanies[id] - Array of company IDs accessible to the vendor for the specified tenant.
 * @returns {Object[]} Array of accessible companies for the vendor.
 */
export const extractAccessibleCompanies = (ownAccessCompanies = [], vendor = {}) => {
  const accessibleTenants = vendor.accessableCompanies || {}
  return Object.keys(accessibleTenants).reduce((companies, tenant) => {
    const tenantCompanies = accessibleTenants[tenant] || []
    const externalCompanies = tenantCompanies.map((id) =>
      ownAccessCompanies.find((externalCompany) => externalCompany.companyId === id && externalCompany.tenant === tenant)
    )
    return [...companies, ...externalCompanies]
  }, [])
}
