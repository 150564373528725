import createSelector from 'utils/createSelector'
import { _getCompaniesByCountry, getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds } from 'redux/selectors/filters'
import { getPayrollWithPaygroupsById } from 'redux/selectors/payroll'
import _ from 'lodash'

const getPaygroupId = (state, props) => parseInt(props.paygroupId)
const getCountryId = (state, props) => parseInt(props.countryId)
const getCompanyId = (state, props) => parseInt(props.companyId)

export const getPaygroupById = createSelector(getPaygroupId,
  ({ Paygroup }, paygroupId) => {
    const paygroup = Paygroup.withId(paygroupId)
    return {
      ...paygroup.ref,
      paygroupTerms: paygroup.paygroupTerms.toModelArray().map(term => term.ref),
      company: paygroup.company.ref,
    }
  }
)

export const getPaygroups = createSelector(
  ({ Paygroup }) => {
    return Paygroup.all().toModelArray()
      .map(paygroup => {
        return {
          ...paygroup.ref
        }
      })
      .reverse()
  }
)

export const getPaygroupsByCountryThroughCompany = createSelector(getCountryId,
  ({ Country }, countryId) => {
    const companies = _getCompaniesByCountry(Country, countryId)
    const paygroups = companies.map(company => {
      return company.paygroups.toModelArray().map(paygroup => ({
        ...paygroup.ref
      }))
    })

    return _.flatten(paygroups)
  }
)

export const getPaygroupsByCountriesThroughCompany = createSelector(getCompaniesByCountries,
  ({ Company }, companies) => {
    const paygroups = companies.map(companyRef => {
      const company = Company.withId(companyRef.id)
      return company.paygroups.toRefArray().map(paygroup => ({ ...paygroup })).reverse()
    })

    return _.flatten(paygroups)
  }
)

export const getPaygroupsByCompanyAvailableForPayroll = createSelector(getCompanyId, getPayrollWithPaygroupsById,
  ({ Paygroup }, companyId, payroll) => {
    return Paygroup
      .filter(
        paygroup => paygroup.company === companyId &&
        !_.includes(payroll.paygroupIds, paygroup.id) &&
        payroll.frequency === paygroup.frequency &&
        payroll.currency === paygroup.currency
      )
      .toModelArray()
      .map(
        paygroup => ({
          ...paygroup.ref,
        }))
  })

export const getPaygroupsByCompanies = createSelector(getCompaniesIds,
  ({ Paygroup }, companiesIds) => {
    return Paygroup.filter(paygroup => companiesIds.includes(paygroup.company))
      .toRefArray()
      .map(paygroup => ({ ...paygroup }))
  }
)
