import { isEmpty } from 'lodash'

export const totalsTableStateTypes = Object.freeze({
  onFetchData: 'onFetchData',
  onToggleDisbursable: 'onToggleDisbursable',
  onViewBy: 'onViewBy',
  onToggleShowComments: 'onToggleShowComments',
  onToggleEdit: 'onToggleEdit',
  onEditData: 'onEditData',
  onResetState: 'onResetState',
})

const REFRESH_STATE = {
  dataWithoutSubcategories: [],
  data: null,
  summaryNotes: [],
  isAllRowsExpanded: false,
  isEdit: false,
  editableTableState: {},
  tableChangedData: [],
  isDirty: false,
  needsRefetch: false,
}

const RESET_CHANGED_DATA_STATE = {
  isAllRowsExpanded: false,
  isEdit: false,
  editableTableState: {},
  tableChangedData: [],
  isDirty: false,
}

export const getTotalsTableInitialState = ({ viewByOpts }) => ({
  ...REFRESH_STATE,
  isLoading: true,
  disbursableOnly: false,
  viewBy: viewByOpts[0],
})

const getTableChangedData = (state, { rowIndex, columnId, value }) => {
  const notFoundItemdIdex = -1
  const dataToStore = state.tableChangedData
  const originalComment = state.viewBy.value === 'subcategory' ? state.data[rowIndex] : state.dataWithoutSubcategories[rowIndex]

  const savedCommentIndex = dataToStore.findIndex(({ itemKey, itemType }) => itemKey === originalComment?.itemKey && itemType === originalComment?.itemType)
  const originalCommentValue = originalComment?.[columnId] || ''
  const isDirty = originalCommentValue !== value

  if (savedCommentIndex > notFoundItemdIdex) {
    dataToStore[savedCommentIndex][columnId] = value
    dataToStore[savedCommentIndex].isDirty = isDirty
  } else {
    dataToStore.push({
      itemKey: originalComment.itemKey,
      itemType: originalComment.itemType,
      [columnId]: value,
      isDirty,
    })
  }

  return dataToStore
}

export const totalsTableStateReducer = (state, action) => {
  switch (action.type) {
  case totalsTableStateTypes.onFetchData:
    const data = Array.isArray(action.payload?.data) ? action.payload?.data : []
    return {
      ...state,
      ...REFRESH_STATE,
      needsRefetch: false,
      isLoading: false,
      data,
      dataWithoutSubcategories: data?.filter((item) => isEmpty(item?.subcategory)),
      summaryNotes: action.payload?.summaryNotes,
    }
  case totalsTableStateTypes.onToggleDisbursable:
    return { ...state, isLoading: true, data: null, disbursableOnly: !state.disbursableOnly }
  case totalsTableStateTypes.onViewBy:
    return {
      ...state,
      ...RESET_CHANGED_DATA_STATE,
      viewBy: action.payload,
    }
  case totalsTableStateTypes.onToggleShowComments:
    return {
      ...state,
      isAllRowsExpanded: !state.isAllRowsExpanded,
      isEdit: false,
      editableTableState: undefined,
    }
  case totalsTableStateTypes.onToggleEdit:
    const isEdit = !state.isEdit
    return {
      ...state,
      isEdit,
      editableTableState: isEdit
        ? {
          inGlobalEditMode: isEdit,
          fieldErrors: [],
          inAddMode: false,
        }
        : undefined,
    }
  case totalsTableStateTypes.onEditData:
    const tableChangedData = getTableChangedData(state, action.payload)
    return {
      ...state,
      tableChangedData: getTableChangedData(state, action.payload),
      isDirty: !isEmpty(tableChangedData?.filter((note) => note.isDirty)),
    }
  case totalsTableStateTypes.onResetState:
    return {
      ...state,
      ...REFRESH_STATE,
      isLoading: true,
      needsRefetch: true,
    }
  default:
    return state
  }
}
