import { ActionUtility } from 'utils/redux'
import { fetchCountryTermPivot } from 'redux/actions/countryTermPivot'
import { fetchTerms } from 'routes/Terms/modules/actions'
import { fetchCountryServicePivot } from 'redux/actions/countryServicePivot'
import { normalizeSyncData } from 'redux/transformers/manyToManyTransformer'
import { invalidateCountryDataProtectionFieldsPivot } from 'redux/actions/countryDataProtectionFieldsPivot'
import { dateTransformer } from 'redux/transformers/dateTransformer'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRIES_FETCH = 'COUNTRIES_FETCH'
export const COUNTRIES_RECEIVE = 'COUNTRIES_RECEIVE'
export const COUNTRIES_CREATE = 'COUNTRIES_CREATE'
export const COUNTRY_ADD_TERMS = 'COUNTRY_ADD_TERMS'
export const COUNTRY_ADD_SERVICES = 'COUNTRY_ADD_SERVICES'
export const COUNTRY_INVALIDATE = 'COUNTRY_INVALIDATE'
export const COUNTRY_MERGE_DATA_PROTECTION_FIELDS = 'COUNTRY_MERGE_DATA_PROTECTION_FIELDS'
export const COUNTRY_UPDATE_TAX_DATES = 'COUNTRY_UPDATE_TAX_DATES'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRIES_FETCH,
  receive: COUNTRIES_RECEIVE,
  create: COUNTRIES_CREATE,
  attachTerms: COUNTRY_ADD_TERMS,
  invalidate: COUNTRY_INVALIDATE,
  mergeDataFields: COUNTRY_MERGE_DATA_PROTECTION_FIELDS,
  update: COUNTRY_UPDATE_TAX_DATES
}
const actionUtility = new ActionUtility(actionTypes, 'countries', 'countries')

export const fetchCountriesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountries = actionUtility.fetchEntities
export const invalidateCountries = actionUtility.invalidate
export const updateTaxDates = actionUtility.updateEntity

export const createCountry = (entity) => {
  // Set valid dates
  const data = dateTransformer(entity, ['taxStartDate', 'taxEndDate'])

  return (dispatch) => {
    return dispatch(actionUtility.createEntity(data))
  }
}

const addTermsAction = countryTerms => ({
  type: COUNTRY_ADD_TERMS,
  payload: countryTerms
})

// add services action
const addServicesAction = countryServices => ({
  type: COUNTRY_ADD_SERVICES,
  payload: countryServices
})

// add terms to country
export const addTerm = (countryId, termData) => {
  // normalize data here for now
  let data = {}
  data['data'] = {}
  data['data'][termData['term']] = {
    localName: termData['localName'],
    shortName: termData['shortName']
  }

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.attachEntities({
      entity: data,
      id: countryId,
      childUri: 'terms',
      actionFunc: addTermsAction
    })).then(() => {
      dispatch(fetchCountryTermPivot())
      dispatch(fetchTerms())
    })
  }
}

export const addServices = (countryId, serviceIds) => {
  let data = {}
  data['data'] = serviceIds

  return (dispatch, getState, { api }) => {
    dispatch(addServicesAction(data))

    return dispatch(actionUtility.attachEntities({
      entity: data,
      id: countryId,
      childUri: 'services',
      actionFunc: addServicesAction
    })).then(() => {
      dispatch(fetchCountryServicePivot())
    })
  }
}

const mergeDataFieldsAction = entity => ({
  type: actionTypes.mergeDataFields,
  payload: entity
})

/**
 * Send an api request to merge data protection fields to country
 */
export const mergeCountryDataFields = (data, countryId) => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.mergeEntities({
      entity: normalizeSyncData(data, 'isSensitive'),
      id: countryId,
      childUri: 'dataprotectionfields',
      actionFunc: mergeDataFieldsAction
    })).then(() => {
      dispatch(invalidateCountryDataProtectionFieldsPivot())
    })
  }
}
