import createSelector from 'utils/createSelector'
import _ from 'lodash'
import {
  getBusinessUnitsIds,
  getCompaniesIds,
  getCostCentersIds,
  getPaygroupsIds,
  getPayrollInstancesIds,
  getPayrollsIds,
  getProjectsIds,
} from 'redux/selectors/filters'

const getCountryId = (state, props) => parseInt(props.countryId)
const getExternalCompanies = (state, props) => props.companies

// get all countries
export const getCountries = createSelector(({ Country }) => {
  return Country.all()
    .toRefArray()
    .map((country) => ({
      ...country,
    }))
})

export const getCountriesByExternalCompanies = createSelector(getExternalCompanies, ({ Country }, companies) => {
  const countryIds = companies.map((company) => company.country)

  return Country.filter((country) => countryIds.includes(country.id))
    .toRefArray()
    .map((c) => c)
})

/**
 * Get country by abbr
 *
 * @param {Array} countries
 * @param {String} countryAbbr
 * @private
 */
export const _getCountryNameByAbbr = (countries, countryAbbr) => _.find(countries, { code: countryAbbr }).name

export const getCountryRef = createSelector(getCountryId, ({ Country }, countryId) => {
  const country = Country.withId(countryId)

  return {
    ...country.ref,
  }
})

/*
 * Get countries by the authenticated user role
 *
 * If logged user is COT and he operates via a tenant mode,
 * the BE should return all the Countries in the system.
 *
 * If the logged in user is NOT COT,
 * the BE should return all the Countries of the Companies to which the logged in user has access
 *
 */
export const getCountriesByAuth = (state) => getCountriesRefByAccessibleCompanies(state)

// If the logged in user is COT
export const getCountriesRefByCompanies = createSelector(({ Company }) => {
  const companies = Company.all().toModelArray()
  const countries = companies.map((company) => ({ ...company.country.ref }))

  return _.uniqBy(countries, 'id')
})

// If the logged in user is NOT COT
export const getCountriesRefByAccessibleCompanies = createSelector(({ EmployeeSystemUser, Company }) => {
  const companies = Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()

  const countries = companies.map((company) => ({ ...company.country?.ref }))

  return _.uniqBy(countries, 'id')
})

export const getCountriesByCompanies = createSelector(getCompaniesIds, ({ Company }, companiesIds) => {
  const countries = Company.filter((company) => companiesIds.includes(company.id))
    .toModelArray()
    .map((company) => ({ ...company.country?.ref }))

  // Here we get only the unique Countries, because 1 Country has more than one Companies
  return _.uniqBy(countries, 'id')
})

export const getCountryNameById = createSelector(getCountryId, ({ Country }, countryId) => Country.withId(countryId).ref.name)

export const extractCountriesAndCompaniesIds = (models, getCountryId, getCompanyId) => {
  const countriesIds = []
  const companiesIds = []

  models.forEach((model) => {
    countriesIds.push(getCountryId(model))
    companiesIds.push(getCompanyId(model))
  })

  return {
    countriesIds: _.uniq(countriesIds),
    companiesIds: _.uniq(companiesIds),
  }
}

export const getCountriesAndCompaniesIdsByCompanies = createSelector(getCompaniesIds, ({ Company }, companiesIds) => {
  const models = Company.filter((company) => companiesIds.includes(company.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.country.id,
    (model) => model.id
  )
})

export const getCountriesAndCompaniesIdsByBusinessUnits = createSelector(getBusinessUnitsIds, ({ BusinessUnit }, businessUnitsIds) => {
  const models = BusinessUnit.filter((bu) => businessUnitsIds.includes(bu.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.company.country.id,
    (model) => model.company.id
  )
})

export const getCountriesAndCompaniesIdsByCostCenters = createSelector(getCostCentersIds, ({ CostCenter }, costCentersIds) => {
  const models = CostCenter.filter((cs) => costCentersIds.includes(cs.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.company.country.id,
    (model) => model.company.id
  )
})

export const getCountriesAndCompaniesIdsByProjects = createSelector(getProjectsIds, ({ Project }, projectsIds) => {
  const models = Project.filter((project) => projectsIds.includes(project.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.company.country.id,
    (model) => model.company.id
  )
})

export const getCountriesAndCompaniesIdsByPayrolls = createSelector(getPayrollsIds, ({ Payroll }, payrollsIds) => {
  const models = Payroll.filter((payroll) => payrollsIds.includes(payroll.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.company.country.id,
    (model) => model.company.id
  )
})

export const getCountriesAndCompaniesIdsByPaygroups = createSelector(getPaygroupsIds, ({ Paygroup }, paygroupsIds) => {
  const models = Paygroup.filter((paygroup) => paygroupsIds.includes(paygroup.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.company.country.id,
    (model) => model.company.id
  )
})
export const getCountriesAndCompaniesIdsByPayrollInstances = createSelector(getPayrollInstancesIds, ({ PayrollInstance }, payrollInstancesIds) => {
  const models = PayrollInstance.filter((instance) => payrollInstancesIds.includes(instance.id)).toModelArray()

  return extractCountriesAndCompaniesIds(
    models,
    (model) => model.payroll.company.ref.country,
    (model) => model.company
  )
})
