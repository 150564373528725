import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompanyCountryTermPivotIfNeeded } from 'redux/actions/companyCountryTermPivot'
import { fetchCountryTermPivotIfNeeded } from 'redux/actions/countryTermPivot'
import { fetchTermsIfNeeded } from 'routes/Terms/modules/actions'
import { getVariableCompanyTerms } from 'redux/selectors/companyCountryTermPivot'
import VariablePayElements from 'components/VariablePayElements'

class VariablePayElementsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCompanyCountryTermPivotIfNeeded()
    this.props.fetchCountryTermPivotIfNeeded()
    this.props.fetchTermsIfNeeded()
  }

  render () {
    return <VariablePayElements {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyCountryTermPivotIfNeeded: () => dispatch(fetchCompanyCountryTermPivotIfNeeded()),
    fetchCountryTermPivotIfNeeded: () => dispatch(fetchCountryTermPivotIfNeeded()),
    fetchTermsIfNeeded: () => dispatch(fetchTermsIfNeeded()),
  }
}

const mapStateToProps = (state, props) => {
  const { companyCountryTermPivot, countryTermPivot, terms } = state

  if (isFetching([companyCountryTermPivot, countryTermPivot, terms])) return { isFetching: true }

  return {
    variableTerms: getVariableCompanyTerms(state, { companyId: props.companyId }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablePayElementsContainer)
