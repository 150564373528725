import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { getVendorUser } from 'redux/selectors/vendorUsers'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchVendorUsersIfNeeded } from 'redux/actions/vendorUsers'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import PropTypes from 'prop-types'
import { setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { closeAllToastMessages } from 'redux/actions/toasts'

/**
 * When a vendor user is logged in, he is redirected here firstly,
 * in order to map the vendor user to a vendor id.
 * (1 VendorUser belongs to 1 Vendor, 1 Vendor has many VendorUsers).
 *
 * Once we have the vendor id, then we redirect the user to the corresponding vendor routes.
 */
class RedirectContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { id, isFetching, dispatch, history } = this.props

    dispatch(fetchVendorsIfNeeded())
    dispatch(fetchVendorUsersIfNeeded())
    dispatch(fetchCountriesIfNeeded())
    dispatch(setShouldPoll({ enablePolling: false }))
    dispatch(setStartPolling({ shouldStartPolling: false }))
    dispatch(closeAllToastMessages())

    if (!isFetching) history.push(`/vendors/${id}/company-details`)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    const { id, isFetching } = nextProps
    if (!isFetching) nextProps.history.push(`/vendors/${id}/company-details`)
  }

  render () {
    return <div />
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mapStateToProps = state => {
  if (isFetching([state.vendors, state.vendorUsers, state.countries])) return { isFetching: true }
  return {
    id: getVendorUser(state, { userId: state.auth.userId }).vendor
  }
}

RedirectContainer.propTypes = {
  id: PropTypes.number,
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectContainer)
