import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYGROUP_EMPLOYEE_FETCH = 'PAYGROUP_EMPLOYEE_FETCH'
export const PAYGROUP_EMPLOYEE_RECEIVE = 'PAYGROUP_EMPLOYEE_RECEIVE'
export const PAYGROUP_EMPLOYEE_INVALIDATE = 'PAYGROUP_EMPLOYEE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYGROUP_EMPLOYEE_FETCH,
  receive: PAYGROUP_EMPLOYEE_RECEIVE,
  invalidate: PAYGROUP_EMPLOYEE_INVALIDATE
}
const actionUtility = new ActionUtility(
  actionTypes, 'paygroupEmployeePivot', 'paygroupemployees', 'PaygroupEmployee', {}, { fetch: 'PAYGROUPEMPLOYEE_VIEW' }
)

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPaygroupEmployeePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPaygroupEmployeePivot = actionUtility.fetchEntities
export const invalidatePaygroupEmployeePivot = actionUtility.invalidate
