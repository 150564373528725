import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'

export const Steps = ({ steps, isFetching }) => {
  if (isFetching) return (<div>Loading...</div>)
  return <div>
    <SectionHeading text='Steps list' className='u-margin-top-large' />

    {isFetching === true ? 'Loading ...' : null}

    <PayslipTable
      wrapperClassName='u-1/1@tablet u-1/2@desktop'
      headings={[
        {
          accessor: 'name',
          Header: 'Name',
          disableSortBy: true
        },
        {
          accessor: 'processRef',
          Header: 'Process',
          disableSortBy: true
        }
      ]}
      data={steps.map(step => (
        {
          name: step.name,
          processRef: step.processRef.name
        }
      ))}
    />

  </div>
}

export default Steps
