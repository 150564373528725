import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const STEPS_FETCH = 'STEPS_FETCH'
export const STEPS_RECEIVE = 'STEPS_RECEIVE'
export const STEPS_INVALIDATE = 'STEPS_INVALIDATE'
export const STEP_CREATE = 'STEP_CREATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: STEPS_FETCH,
  receive: STEPS_RECEIVE,
  invalidate: STEPS_INVALIDATE,
  create: STEP_CREATE
}
const actionUtility = new ActionUtility(actionTypes, 'steps', 'steps', 'Step')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchStepsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchSteps = actionUtility.fetchEntities
export const invalidateSteps = actionUtility.invalidate
export const createStep = actionUtility.createEntity
