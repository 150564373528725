import React from 'react'
import PropTypes from 'prop-types'

const ReportTypeAvatar = ({ classList, icon, height, width }) => {
  return (
    <div className={classList} style={{ height, width }}>
      <span className={`icon--${icon}`} />
    </div>
  )
}

ReportTypeAvatar.propTypes = {
  classList: PropTypes.string,
  icon: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}
export default ReportTypeAvatar
