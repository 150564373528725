import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { keycloakTokenExpiration } from './enums/keycloakRealms'

function isExpiredCheck (token) {
  // Unix timestamp of token expiration
  const expiration = jwtDecode(token).exp
  return moment(Date.now()) > moment.unix(expiration)
}

export const isExpiredRhssoToken = (kc) => kc?.isTokenExpired(keycloakTokenExpiration.MIN_EXPIRED_VALIDITY)

/**
 * Is JWT expired
 *
 * @param {String} token
 * @returns {boolean}
 */
export function isExpired (token) {
  return isExpiredCheck(token)
}
