import React from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import SwitchToggle from 'components/form/SwitchToggle'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'

class DataFieldForm extends React.Component {
  render () {
    const { isFetching } = this.props

    if (isFetching) return <div>Loading...</div>

    return <Form {...this.props}>
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='name'
            label='Date protection field *'
            labelClassName='c-label u-text--small'
            component={InputField}
            type='text'
            disabled
            disabledReason='You cannot edit data protection field name'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='isSensitive'
            label='Is sensitive data? *'
            component={SwitchToggle}
            labelClassName='c-label u-text--small'
          />
        </div>
      </div>
      <div className='o-layout u-padding'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            type='submit'
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'
          >
            Save
          </button>
        </div>
      </div>
    </Form>
  }
}

DataFieldForm.propTypes = {
  isFetching: PropTypes.bool
}

export default DataFieldForm
