import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const RESOURCE_LOGS_FETCH = 'RESOURCE_LOGS_FETCH'
export const RESOURCE_LOGS_RECEIVE = 'RESOURCE_LOGS_RECEIVE'
export const RESOURCE_LOGS_INVALIDATE = 'RESOURCE_LOGS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: RESOURCE_LOGS_FETCH,
  receive: RESOURCE_LOGS_RECEIVE,
  invalidate: RESOURCE_LOGS_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'resourceLogs', 'resourcelogs', 'ResourceLog')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchResourceLogsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchResourceLogs = actionUtility.fetchEntities
export const invalidateResourceLogs = actionUtility.invalidate
