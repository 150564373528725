import React from 'react'
import { Field } from 'redux-form'
import AmountInputField from 'components/form/AmountInputField'
import PropTypes from 'prop-types'
import FieldDatePicker from './form/FieldDatePicker'

const getTermId = (str, formFieldName) => formFieldName.replace(str, '')
const hasAnAmount = (id, values) => !!values[`fixed-term-${id}`]
const hasValidFromDate = (id, values) => !!values[`valid-from-fixed-term-${id}`]
const hasValidToDate = (id, values) => !!values[`valid-to-fixed-term-${id}`]
const getValidFromDate = (id, values) => values[`valid-from-fixed-term-${id}`]

const valueRequired = (value, values, props, formFieldName) => {
  const termId = getTermId('fixed-term-', formFieldName)
  const hasValidFrom = hasValidFromDate(termId, values)
  const hasValidTo = hasValidToDate(termId, values)
  if ((hasValidFrom || hasValidTo) && !value) {
    return 'This value should not be blank'
  }

  return undefined
}
const validFromDateRequired = (value, values, props, formFieldName) => {
  const termId = getTermId('valid-from-fixed-term-', formFieldName)
  const hasAmount = hasAnAmount(termId, values)
  if (hasAmount && !value) {
    return 'From Date field is required'
  }

  return undefined
}

const validToDateRequired = (value, values, props, formFieldName) => {
  const termId = getTermId('valid-to-fixed-term-', formFieldName)
  const hasAmount = hasAnAmount(termId, values)
  const hasValidFrom = hasValidFromDate(termId, values)
  const fromDate = getValidFromDate(termId, values)
  if (hasAmount && hasValidFrom && value && fromDate > value) {
    return 'The To Date should be the same or after the From Date.'
  }

  return undefined
}

const FixedPayElements = ({
  isFetching,
  fixedTerms,
  disabled,
  disabledReason,
}) => {
  if (isFetching) return <div>Loading...</div>
  return <>
    { fixedTerms.map((term, index) => {
      return <div key={`${term.companyCountryTermId}-${term.fullName}`} className='o-layout o-grid--strech'>
        <div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name={`fixed-term-${term.companyCountryTermId}`}
                label={term.fullName}
                component={AmountInputField}
                type='text'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                disabled={disabled}
                disabledReason={disabledReason}
                data-testid={`term-field-${term.localName.toLowerCase()}`}
                validate={[valueRequired]}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name={`valid-from-fixed-term-${term.companyCountryTermId}`}
                label='From Date'
                component={FieldDatePicker}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={[validFromDateRequired]}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name={`valid-to-fixed-term-${term.companyCountryTermId}`}
                label='To Date'
                component={FieldDatePicker}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={[validToDateRequired]}
              />
            </div>
          </div>
        </div>
      </div>
    }) }
  </>
}

FixedPayElements.propTypes = {
  isFetching: PropTypes.bool,
  fixedTerms: PropTypes.array,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
}

export default FixedPayElements
