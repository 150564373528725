import React from 'react'
import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import Modal from 'components/Modal'
import { createFilter } from '../../../utils/redux/filter'
import { getPayrollInstanceRef } from '../../../redux/selectors/payrollInstance'

class PayrollInstanceModalContainer extends React.Component {
  renderHeading (isFetching, payrollInstance, renderHeading) {
    if (isFetching) return 'Loading...'

    if (!payrollInstance) return ''

    return renderHeading(payrollInstance)
  }

  renderSubHeading (isFetching, payrollInstance, renderSubHeading) {
    if (!renderSubHeading) return

    // Display empty string on isFetching because if we have heading that is fetching
    // it is displaying 'Loading...' and we don't want to duplicate it.
    if (!payrollInstance || isFetching) return ''

    return renderSubHeading(payrollInstance)
  }

  showModal () {
    this.refs.modal.showModal()
  }

  hideModal () {
    this.refs.modal.hideModal()
  }

  render () {
    const { isFetching, payrollInstance, renderHeading, renderSubHeading, ...rest } = this.props

    return <Modal {...rest} ref='modal'
      modalHeading={this.renderHeading(isFetching, payrollInstance, renderHeading)}
      modalSubHeading={this.renderSubHeading(isFetching, payrollInstance, renderSubHeading)}
    />
  }
}

const mapStateToProps = (state, { payrollInstanceId, isFetching: isFetchingProp }) => {
  if (isFetchingProp) return { isFetching: true }

  return {
    payrollInstance: payrollInstanceId ? getPayrollInstanceRef(state, { payrollInstanceId }) : null
  }
}

const Component = connect(mapStateToProps, null, null,
  { forwardRef: true })(PayrollInstanceModalContainer)

export default Fetcher(Component, [
  {
    name: 'payrollInstances',
    params: [{
      _computed: {
        filter: (state, { payrollInstanceId: id }) => createFilter({ id })
      }
    }]
  }],
{ showLoader: false, renderWhileFetching: true })
