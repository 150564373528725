import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/documentLogs'

class DocumentLog extends CRUDModel {}
DocumentLog.modelName = 'DocumentLog'

DocumentLog.fields = {
  id: attr()
}
DocumentLog.actionTypes = actionTypes

export default DocumentLog
