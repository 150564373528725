import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceTask'
import { statuses } from 'redux/config/payrollInstanceTasks'
import _ from 'lodash'

// check if status is in progress
export const isInProgress = status => {
  return status === statuses.inProgress.value
}

// check if status is completed
export const isCompleted = status => {
  return status === statuses.completed.value
}

// check if status is reverted
export const isReverted = status => {
  return status === statuses.reverted.value
}

/**
 * Remove disabled tasks from the passed in `tasks` by `disabledTasks` array
 * and return a new normalized `tasks` object
 *
 * @param {Object} tasks - where the key is JSON.stingified object, containing `id` property
 * @param {Array} disabledTasks - array of the disabled tasks
 * @returns {{}}
 */
export const removeDisabledTasks = (tasks, disabledTasks) => {
  let normalized = {}

  _.forOwn(tasks, (value, key) => {
    const { id } = JSON.parse(key)

    // If the task is part of the `disabledTasks`, then skip it
    if (disabledTasks.find(task => task.id === parseInt(id))) return

    normalized[key] = value
  })

  return normalized
}

export const findCompletedReversionTask = (normalized, initialState) => {
  return Object.keys(normalized).find(key => {
    const initialTask = initialState[key]
    const changedTask = normalized[key]
    return initialTask.status === 'reverted' && changedTask.status === 'completed'
  })
}

/**
 * Are all tasks completed?
 *
 * I.e. the value of all the task is `statuses.completed.value`?
 *
 * @param {Object} formValues
 * @return {boolean}
 */
export const areTasksCompleted = formValues => {
  // Get only the tasks fields, because it's possible another form fields to exists,
  // as `stepId` for example.
  const sanitizedTasks = _.pickBy(formValues, (value, key) => {
    return JSON.parse(key).hasOwnProperty('id')
  })
  // Get all tasks values
  const tasksValues = _.values(sanitizedTasks)
  // Completed tasks only
  const completedTasks = tasksValues.filter(({ status }) => status === statuses.completed.value)

  // If the length of all the tasks and completed tasks is the same,
  // then all the tasks are completed
  return tasksValues.length && (tasksValues.length === completedTasks.length)
}

class PayrollInstanceTask extends CRUDModel {
  isCompleted () {
    return isCompleted(this.status)
  }
}
PayrollInstanceTask.modelName = 'PayrollInstanceTask'
PayrollInstanceTask.actionTypes = actionTypes

PayrollInstanceTask.fields = {
  id: attr(),
  payrollInstanceStep: fk('PayrollInstanceStep', 'payrollInstanceTasks'),
  payrollTask: fk('PayrollTask', 'payrollInstanceTasks'),
  owner: fk('EmployeeSystemUser', 'payrollInstanceTasks'),
  // Here we have two FKs relations to `EmployeeSystemUser`.
  // By BE design the `processOwner` field is derived from other Model (not the current one).
  // But because of FE simplicity for accessing the Process Owner from current model, the BE dynamically add it here.
  // It's not supposed to use the related name from EmployeeSystemUser.
  // Because of this we name it `payrollInstanceTasks2` (it's not possible to have same related names in one model)
  processOwner: fk('EmployeeSystemUser', 'payrollInstanceTasks2'),
}

export default PayrollInstanceTask
