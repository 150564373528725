import React from 'react'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import DataProtectionFieldsListForm from 'components/DataProtectionFieldsListForm'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'

class ApproveFieldsForm extends React.Component {
  render () {
    const { latestApprovedDateAuthor, isApproved, showApproveMsgBtn } = this.props

    return (
      <Form {...this.props}>
        {
          /**  Check if there are any approved fields to show the message and if the last approval is made by an employee or COT.
           **  If latest changes are made by COT, the modifiedBy property will be null **/
          latestApprovedDateAuthor && latestApprovedDateAuthor.hasApprovedFields && (
            <div className='text-center'>
              <p className='u-text--small'>
                Current list of payroll sensitive fields approved on:{' '}
                <span className='u-weight--bold'>
                  {latestApprovedDateAuthor.modifiedAt}
                  {latestApprovedDateAuthor.modifiedBy ? ` by ${latestApprovedDateAuthor.modifiedBy}` : null}
                </span>
              </p>
            </div>
          )
        }
        <DataProtectionFieldsListForm {...this.props} />
        <div className='u-text--center'>
          {showApproveMsgBtn && (
            <Field
              type='checkbox'
              component={CheckboxField}
              className='control__indicator__input'
              label='I approve that the above listed fields are payroll sensitive.'
              name='approve'
            />
          )}
          {isApproved && showApproveMsgBtn && (
            <button type='submit' className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right' title='Approve'>
              Approve
            </button>
          )}
        </div>
      </Form>
    )
  }
}

ApproveFieldsForm.propTypes = {
  latestApprovedDateAuthor: PropTypes.shape({
    hasApprovedFields: PropTypes.bool,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string
  }),
  isApproved: PropTypes.bool,
  showApproveMsgBtn: PropTypes.bool
}

export default ApproveFieldsForm
