import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { isNumber } from 'lodash'
import Fetcher from 'containers/Fetcher'
import { types } from 'redux/config/documents'
import DocumentsViewTable from 'components/documents/DocumentsViewTable'
import PayrollDocumentsTable from 'components/documents/PayrollDocumentsTable'
import { hasAccess, isCot, isCotBot, isCotOrEngineerOrAdmin, isEmployeeOnly, isKeyUser, isVendorInvolved } from 'redux/selectors/auth'
import { createDocsPaginationFilter, createDynamicDocumentFilterName } from 'redux/filters/createDocumentFilter'
import { getDocumentTypes } from 'redux/selectors/documentTypes'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { showMessage, clearMessage } from 'redux/actions/modal'
import { uploadDocument, downloadDocument, fetchDocuments, deleteDocument, updateDocument, invalidateDocuments } from 'redux/actions/document'
import Modal from 'components/Modal'
import { reduxForm, formValueSelector, change, reset, stopAsyncValidation } from 'redux-form'
import DocumentClassificationUploadForm from 'components/documents/DocumentClassificationUploadForm'
import DocumentUploadForm from 'components/documents/DocumentUploadForm'
import { getEmployeeById } from 'routes/Payruns/routes/Instance/selectors/employeesORM'
import { getTabsByUploadedDocumentTypes, getUploadedDocumentTypesObjects, setDocumentTypes } from 'utils/documentTypes'
import { getModalHeading } from 'utils/modal'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import PropTypes from 'prop-types'
import { excludeForFuturePayruns, isShareAllowed } from './utils/documents'
import { getCotUser } from 'redux/selectors/cotUsers'
import { docTypesFilesToExcludeWhenReadOnly } from 'utils/enums/documentTypes'
import { fetchDocumentTypes } from 'redux/actions/documentTypes'
import { getUser } from 'redux/selectors/employees'

const PAYROLL_DOC_FILTER_NAMES = ['typeClass', 'typeId', 'typeTenant', 'documentTypes']
const DOCUMENTS_FILTER_NAMES = ['typeClass', 'typeId', 'typeTenant']

const DocumentListContainer = (props) => {
  const uploadModal = useRef()
  const { hasDocumentClassification, dispatch, title, modalHeading, isPayrollInstance } = props
  const handleModalOpen = () => {
    uploadModal.current.showModal()
  }
  return (
    <>
      <Modal
        ref={uploadModal}
        className='c-modal'
        modalHeading={modalHeading || ''}
        modalSubHeading='Choose document type'
        onHide={() => dispatch(reset('documentUpload'))}
      >
        {hasDocumentClassification ? <DocumentClassificationUploadForm {...props} /> : <DocumentUploadForm {...props} />}
      </Modal>
      {isPayrollInstance ? (
        <PayrollDocumentsTable filterNames={PAYROLL_DOC_FILTER_NAMES} onUpload={() => handleModalOpen()} {...props} />
      ) : (
        <DocumentsViewTable title={title} filterNames={DOCUMENTS_FILTER_NAMES} onUpload={() => handleModalOpen()} {...props} />
      )}
    </>
  )
}

// According to the Document typeClass, we return specific permission naming,
// because some of the roles don't have access to all types sharing.
// Until now - having DOCUMENT_CREATE, you will have access to all documents' types sharing.
// Now, we want to restrict some roles to Employee->Documents sharing.
// Because of this we create specific permissions, according to the `typeClass`.
// Keep in mind that the permission will hide the sharing functionality, but the BE API calls are still allowed.
// We decided this and communicated it with PM - because it's very possible the business logic to be changed,
// and for now - we won't introduce new ACL/Permissions changes on the BE.
const getPermissions = (typeClass) => {
  const permissions = {
    create: 'DOCUMENT_CREATE',
  }

  if (typeClass !== 'employee') return permissions

  // Prefixing permission with `EMPLOYEE`
  return Object.keys(permissions).reduce(
    (accumulator, key) => {
      accumulator[key] = `EMPLOYEE${accumulator[key]}`
      return accumulator
    },
    { ...permissions }
  )
}

const mapStateToProps = (state, props) => {
  let { typeClass, payrollInstanceId, typeId, tab } = props

  const { create } = getPermissions(typeClass)
  const isPayrollInstance = isNumber(payrollInstanceId)
  const isCotUser = isCot(state)
  const isCotBotUser = isCotBot(state)
  const isKU = isKeyUser(state)
  const isVendorUser = isVendorInvolved(state)

  const cotUserSpecialRights = isCotUser && getCotUser(state, { userId: state.auth.userId }).cotSpecialRight
  const user = isKeyUser(state) && getUser(state, { userId: state.auth.userId })
  const userSpecialRightAllPayrunDocuments = user?.specialRight?.allPayrunDocuments

  const selector = formValueSelector('documentUpload')
  const values = selector(state, 'documentType', 'payrollId', 'documentTypes', 'version')
  const regex = new RegExp(`^[1-9]{1}[0-9]*$`)

  // Remove option/s from document types dropdown, when uploading file on payrun level in ER mode
  let documentTypesToExclude = [
    'gtnMappingReport',
    'payslipUploadReport',
    'taxDocumentUploadReport',
    'inputsValidationReport',
    'dataMigrationValidationReport',
    'payslipsValidationReport',
    'payslipsHcmReport',
    'taxDocsValidationReport',
    'taxDocsHcmReport',
  ]
  const extraFilesToExclude = !isCotUser ? docTypesFilesToExcludeWhenReadOnly.upload : []

  // Only CotBot user should be able to upload Additional Inputs document type
  if (!isCotBotUser) documentTypesToExclude.push('additionalInputs', 'beneficiaryFile', 'dataMigration')

  let shouldRunIsCotUserExcludes = isCotUser

  const documentTypesToExcludeForFuturePayruns =
    isPayrollInstance &&
    excludeForFuturePayruns({
      docTypes: ['inputsAutoSaved', 'mipiInputs', 'gtn', 'gtnAutoSaved', 'payslips', 'tax', 'bankFile', 'glMapping', 'glReports', 'bankFileValidationReport'],
      payrunState: props.payrollInstance.status,
    })

  if (documentTypesToExcludeForFuturePayruns) {
    shouldRunIsCotUserExcludes = false
    documentTypesToExclude = documentTypesToExclude.concat(documentTypesToExcludeForFuturePayruns)
  }

  const documentTypes = setDocumentTypes(
    getDocumentTypes(state),
    [...documentTypesToExclude, ...extraFilesToExclude],
    shouldRunIsCotUserExcludes,
    isCotBotUser,
    isCotOrEngineerOrAdmin,
    cotUserSpecialRights
  )
  let documentTitle = 'Document'
  if (props.updateTitleForEmployee) {
    const employee = getEmployeeById(state, { employeeId: props.match.params.employeeId })
    const { employmentStatus } = employee
    const EmployeeStatus = new EmploymentStatusHelper(employmentStatus)
    documentTitle = EmployeeStatus.isContractorChangeTitleStatus ? 'Documents' : 'Tax Documents'
  }
  const tabName = tab?.name
  const isGtnTab = tabName === 'payrollGtnDocTypes' || null
  const isGlTab = tabName === 'payrollGlReportsDocTypes'
  const isInputsTab = tabName === 'payrollInputsDocTypes'

  return {
    extraFilters: {
      documentTypes: props?.documentTypes,
      allDocTypes: props?.allDocTypes,
    },
    title: documentTitle,
    isEmployeeOnly: isEmployeeOnly(state),
    isCot: isCotUser,
    isCotBot: isCotBotUser,
    isKU,
    isVendorUser,
    canCreate: hasAccess(state)([create]),
    userSpecialRightAllPayrunDocuments,
    documentTypes,
    hasAccess: hasAccess(state),
    isPayrollInstance,
    isGtnTab,
    tabName,
    showCategoryPayrollName: !isPayrollInstance,
    showGlStatus: isGlTab,
    showInputsValidationReportStatus: isInputsTab,
    hasDocumentClassification: isPayrollInstance,
    modalHeading: getModalHeading(state, payrollInstanceId, typeClass, typeId),
    isPayroll: values.documentType === 'Payroll',
    showSubmitBtn:
      values.documentType === 'Company' ||
      (values.documentType === 'Payroll' && values.payrollId) ||
      (isNumber(payrollInstanceId) && values.documentTypes && values.documentTypes.length && values.version > 0 && regex.test(values.version)),
  }
}

const dispatchDocFetch = ({ filterName, docFilterNames, dispatch, filters }) => {
  dispatch(
    fetchDocuments({
      filter: createDocsPaginationFilter(filterName, { filters, docFilterNames }),
    })
  )
}

const mapDispatchToProps = (dispatch, props) => {
  const { typeClass, typeId, payrollInstanceId, payrollId = null, onFileUploaded, tabIndex: currentTab } = props
  return {
    fetchInitialDocs: () => {
      const filterName = createDynamicDocumentFilterName([props?.typeId, props?.typeClass])
      dispatchDocFetch({ dispatch, filterName, docFilterNames: DOCUMENTS_FILTER_NAMES, filters: props })
    },
    fetchInitialPayrollDocuments: () => {
      const filterName = createDynamicDocumentFilterName([props?.typeId, props?.tab?.name])
      dispatchDocFetch({ dispatch, filterName, docFilterNames: PAYROLL_DOC_FILTER_NAMES, filters: props })
    },
    uploadFile: (file) => {
      dispatch((dispatch, getState) => {
        const state = getState()
        const { parentCompanies } = state
        const parentCompaniesData = parentCompanies.allIds.map((id) => state.parentCompanies.byIds[id])

        const selector = formValueSelector('documentUpload')
        const values = selector(state, 'documentType', 'payrollId', 'documentTypes', 'version')
        const isCotUser = isCot(state)
        const data = {
          typeClass: types[typeClass].filterName,
          typeId,
          // If we are logged in as COT and we operate in a Tenant mode,
          // then we have to get the already selected tenant for the state. `state.tenants.id`
          // If we don't have a selected tenant, it means we are an Employee logged in a concrete tenant / schema.
          typeTenant: state.tenants.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }).schema : parentCompaniesData[0].schema,
          ...(payrollInstanceId
            ? {
              version: values.version,
              ...Object.keys(values.documentTypes).reduce((acc, key) => {
                acc[`documentTypes[${key}]`] = values.documentTypes[key]
                return acc
              }, {}),
            }
            : {}),
          uploadingMessage: getModalHeading(state, payrollInstanceId, typeClass, typeId, payrollId),
        }
        dispatch(uploadDocument(file, data))
          .then(({ data }) => {
            // The document will be shared to the Process owner automatically on upload,
            // only if the file is uploaded by a Key people and the Process owner notifications `receivesNotifications`
            // are enabled.
            let isProcessOwnerAutoShared = !isCot(state) && data.extraData.processOwnerEnabledNotifications
            if (isShareAllowed(data, isCotUser)) {
              props.openConfirmShareModal(
                data.id,
                data.documentId,
                data.documentTenant,
                data.name,
                data.typeClass,
                data.typeId,
                isProcessOwnerAutoShared,
                data.documentTypes
              )
            }
            dispatch(invalidateDocuments())
            dispatch(fetchDocumentTypes())
            dispatch(reset('documentUpload'))
            dispatch(clearMessage())
            if (typeClass === 'payrollInstance') {
              const allDocumentsTypes = getDocumentTypes(state)
              const documentTypesIndexes = [...data.documentTypes]
              const uploadedDocumentTypesObjects = getUploadedDocumentTypesObjects(documentTypesIndexes, allDocumentsTypes)
              const tabsByUploadedDocumentTypes = getTabsByUploadedDocumentTypes(uploadedDocumentTypesObjects, props.payrunState || null)
              const isUploadedFileTypeIncludedInCurrentTab = tabsByUploadedDocumentTypes.includes(currentTab)
              // If we are in tab `GTN` and upload a file with a several types: `GTN`,
              // `Payslips`, and `Inputs` for example, we should keep the user on the same tab,
              // since one of the types of the uploaded file is same as the current tab.
              // Another case would be, if we are in tab `Inputs` and the user upload a file with
              // types `Payslips` and `GTN`. In this case we should redirect the user to
              // the `Payslips and Tax Documents` tab, because this is the first selected type
              // of the uploaded document and none of the types of the documents matches
              // the current tab type.
              onFileUploaded(isUploadedFileTypeIncludedInCurrentTab ? currentTab : tabsByUploadedDocumentTypes.shift())
            }
          })
          .catch((error) => {
            if (error.errors?.hasOwnProperty('version') || error.errors?.hasOwnProperty('documentTypes')) {
              dispatch(clearMessage())
              dispatch(stopAsyncValidation('documentUpload', error.errors))
              return
            }

            dispatch(showMessage({ body: 'Something went wrong.' }))
          })
      })
    },
    onDelete: (id) => {
      dispatch(deleteDocument(id, false, true))
      dispatch(fetchDocumentTypes())
    },
    onEdit: (data) => dispatch(updateDocument({ description: data.value }, data.id, false, true)),
    onDownload: (id) => dispatch(downloadDocument(id)),
    resetForm: () => dispatch(change('documentUpload', 'payrollId', null)),
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'documentUpload',
  })(DocumentListContainer)
)

const PayrollDocumentsFetcher = Fetcher(Container, ['parentCompanies', 'documentTypes'])

const DocumentsFetcher = Fetcher(Container, ['parentCompanies', 'documentTypes', 'countries'])

DocumentListContainer.propTypes = {
  hasDocumentClassification: PropTypes.bool,
  dispatch: PropTypes.func,
  title: PropTypes.string,
  modalHeading: PropTypes.string,
  isPayrollInstance: PropTypes.bool,
  payrollInstance: PropTypes.object,
}

PayrollDocumentsFetcher.propTypes = {
  typeClass: PropTypes.string,
}

export default (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.typeClass === 'payrollInstance') {
    return <PayrollDocumentsFetcher {...props} />
  }

  return <DocumentsFetcher {...props} />
}
