import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import PayslipTable from 'components/table/PayslipTable'
import { buildDocumentTableHeadings } from './documentTableUtils'
import { useFilteredDocuments } from 'hooks/useDocument'
import FilterButton from 'components/buttons/FilterButton'
import Loader from 'components/Loader'
import { downloadDocument } from 'redux/actions/document'

export const DocumentList = ({
  isVendorDocuments,
  extraFilters,
  filterName,
  filterNames,
  omittedColumns,
  typeId,
  typeClass,
  typeTenant,
  tabName,
  isKU,
  isVendorUser,
  dataMapper,
  DocumentActions,
  headerActions,
  headingOptions,
  onDownload,
  onOverrideClick,
}) => {
  const dispatch = useDispatch()
  const onDefaultDownload = useCallback((id) => dispatch(downloadDocument(id)), [dispatch])
  const headings = buildDocumentTableHeadings(omittedColumns, {
    handleDownloadDocument: onDownload || onDefaultDownload,
    DocumentActions,
    onOverrideClick,
    tabName,
    isKU,
    isVendorUser,
    ...headingOptions,
  })
  const { showFilters, isFetching, documents, tableOptions, totalCount, handleFilterChange } = useFilteredDocuments({
    isVendorDocuments,
    filterName,
    extraFilters,
    filterNames,
    typeId,
    typeClass,
    typeTenant,
    tableHeadings: headings,
  })

  const tableData = dataMapper(documents)

  if (!tableOptions?.pageSize) {
    return <Loader />
  }

  return (
    <div className='u-relative'>
      <div className='u-float--right u-margin-top-neg-large'>
        {headerActions}
        <FilterButton className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left u-relative' onClick={handleFilterChange} />
      </div>
      <PayslipTable
        data={tableData}
        headings={headings}
        options={tableOptions}
        totalCount={totalCount}
        showFilters={showFilters}
        dynamicalues
        pagination
        remote
        remoteSorting
        isFetching={isFetching}
      />
    </div>
  )
}

DocumentList.propTypes = {
  isVendorDocuments: PropTypes.bool,
  extraFilters: PropTypes.object,
  headingOptions: PropTypes.object,
  filterName: PropTypes.string,
  omittedColumns: PropTypes.arrayOf(PropTypes.string),
  typeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeClass: PropTypes.string,
  typeTenant: PropTypes.string,
  dataMapper: PropTypes.func,
  DocumentActions: PropTypes.func,
  headerActions: PropTypes.element,
  onDownload: PropTypes.func,
}
