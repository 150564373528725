import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryTaskPivot'

class CountryTask extends CRUDModel {}
CountryTask.modelName = 'CountryTask'
CountryTask.actionTypes = actionTypes

CountryTask.fields = {
  id: attr(),
  name: attr(),
  task: fk('Task', 'countryTasks'),
  countryStep: fk('CountryStep', 'countryTasks')
}

export default CountryTask
