import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const DOCUMENT_LOGS_FETCH = 'DOCUMENT_LOGS_FETCH'
export const DOCUMENT_LOGS_RECEIVE = 'DOCUMENT_LOGS_RECEIVE'
export const DOCUMENT_LOGS_INVALIDATE = 'DOCUMENT_LOGS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DOCUMENT_LOGS_FETCH,
  receive: DOCUMENT_LOGS_RECEIVE,
  invalidate: DOCUMENT_LOGS_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'documentLogs', 'documentlogs', 'DocumentLog')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDocumentLogsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDocumentLogs = actionUtility.fetchEntities
export const invalidateDocumentLogs = actionUtility.invalidate
