import React from 'react'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import PropTypes from 'prop-types'

const InnerWrapper = ({ vendorId, tenantId, companyId, showDocuments, showExtraPayrunsTab, isVendorRestricted = true, ...props }) => {
  const path = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}/details`

  if (isVendorRestricted) {
    return <InnerWrapperTabs tabs={[
      showDocuments && { name: 'Documents', to: `${path}/payruns/documents`, 'data-testid': 'documents-tab' }]} removeWrapper {...props}
    />
  }

  return <InnerWrapperTabs tabs={[
    { name: 'Pre Payroll Steps', to: `${path}/payruns/active` },
    ...showExtraPayrunsTab ? [{ name: 'Post Payroll Steps', to: `${path}/payruns/extra` }] : [],
    { name: 'Closed Payruns', to: `${path}/payruns/previous`, 'data-testid': 'closedPayrollInstances-tab' },
    { name: 'Future Payruns', to: `${path}/payruns/inactive`, 'data-testid': 'futurePayruns-tab' },
    showDocuments && { name: 'Documents', to: `${path}/payruns/documents`, 'data-testid': 'documents-tab' },
  ]} removeWrapper {...props} />
}

InnerWrapper.propTypes = {
  vendorId: PropTypes.string,
  tenantId: PropTypes.string,
  companyId: PropTypes.string,
  showDocuments: PropTypes.bool,
  showExtraPayrunsTab: PropTypes.bool,
  location: PropTypes.object,
  isVendorRestricted: PropTypes.bool
}

export default InnerWrapper
