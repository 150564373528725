import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryServicePivot'

class CountryService extends CRUDModel {}
CountryService.modelName = 'CountryService'
CountryService.actionTypes = actionTypes

CountryService.fields = {
  id: attr(),
  name: attr(),
  service: fk('Service', 'countryServices'),
  country: fk('Country', 'countryServices')
}

export default CountryService
