import React from 'react'
import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { isCot, isCotButNotCotOem } from 'redux/selectors/auth'
import { getVendor } from 'redux/selectors/vendor'
import { isFetching } from 'utils/redux/fetching'
import CompanyDetailsView from '../components/CompanyDetailsView'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'

class VendorsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(getWhiteLabeledBrandStyles())
  }
  render () {
    return <CompanyDetailsView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})
const mapStateToProps = (state, props) => {
  const { vendors, countries } = state

  if (isFetching([vendors, countries])) return { isFetching: true }

  return {
    vendor: getVendor(state, { vendorId: props.match.params.vendorId }),
    isCot: isCot(state),
    isCotButNotCotOem: isCotButNotCotOem(state),
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(VendorsContainer)

export default Fetcher(Container, ['vendors', 'countries'], { renderWhileFetching: false, showLoader: true })
