import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import FilterButton from 'components/buttons/FilterButton'

const columnFilter = { type: 'TextFilter', delay: 1000, placeholder: 'Filter' }

const TermsTable = ({ isFetching, terms, onEdit }) => {
  const [showFilters, setShowFilters] = useState(false)

  if (isFetching) return <div>Loading...</div>

  return <div>
    <SectionHeading text='Terms list' className='u-margin-top-large'>
      <FilterButton
        className='c-btn c-btn--small float-right c-btn--curious u-padding-left u-padding-right u-margin-bottom-small'
        onClick={() => setShowFilters(!showFilters)} />
    </SectionHeading>
    <PayslipTable
      data={terms.map(term => ({
        ...term,
        category: term.categoryRef.name,
        subcategory: term.termSubcategoryRef.name,
        action: <button
          onClick={() => onEdit(term.id)}
          className='c-btn c-btn--tiny c-btn--curious'>
          <span className='icon icon--edit' />
        </button>
      }))}
      wrapperClassName='table-text-full-length'
      showFilters={showFilters}
      pagination
      options={{ noDataMessage: 'No terms', pageIndex: 0, pageSize: 25, showPageSizeSelector: terms.length > 25 }}
      headings={[
        { accessor: 'id', Header: 'ID', isKey: true, filterOptions: columnFilter },
        { accessor: 'name', Header: 'Name', filterOptions: columnFilter },
        { accessor: 'definition', Header: 'Definition', filterOptions: columnFilter },
        { accessor: 'category', Header: 'Category', filterOptions: columnFilter },
        { accessor: 'subcategory', Header: 'SubCategory', filterOptions: columnFilter },
        { accessor: 'action', Header: 'Action', actionCell: true, disableFilters: true, disableSortBy: true, setWidth: '100px' }
      ]}
    />
  </div>
}

TermsTable.propTypes = {
  isFetching: PropTypes.bool,
  terms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    definition: PropTypes.string,
    categoryRef: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    termSubcategoryRef: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  })),
  onEdit: PropTypes.func.isRequired,
}

export default TermsTable
