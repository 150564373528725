import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { changePassword } from 'redux/actions/changePassword'
import ChangePasswordView from '../components/ChangePasswordView'
import { showMessage } from 'redux/actions/modal'
import { isFetching } from 'utils/redux/fetching'
import { createFilter } from 'utils/redux/filter'
import { tenantTypes } from 'utils/enums/tenantTypes'
import { isCot, isKeyUser, isVendorInvolved, isUser } from 'redux/selectors/auth'
import { getCotUser } from 'redux/selectors/cotUsers'
import { getUser } from 'redux/selectors/employees'
import { getVendorUser } from 'redux/selectors/vendorUsers'
class ChangePasswordContainer extends React.Component {
  render () {
    return <ChangePasswordView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) =>
      dispatch(changePassword(data)).then((response) => {
        if (!response.errors) {
          dispatch(showMessage({ body: 'Your password was changed successfully!' }))
        }
        dispatch(reset('changePassword'))
      }),
  }
}

const mapStateToProps = (state) => {
  const { employeeSystemUsers } = state

  const employeeFilter = createFilter({ id: state.auth.userId })
  const employeeEntityWithFilter = [{ ref: employeeSystemUsers, filter: employeeFilter }]
  if (state.config.tenantType === tenantTypes.TENANT && isFetching(employeeEntityWithFilter)) return { isFetching: true }
  if (state.config.tenantType === tenantTypes.VENDOR && isFetching([state.vendorUsers])) return { isFetching: true }
  if (state.config.tenantType === tenantTypes.COS && isFetching([state.cotUsers])) return { isFetching: true }

  const isUserSSOEnabled = () => {
    const userId = state.auth.userId
    if (isCot(state)) return getCotUser(state, { userId }).isSSOEnabled
    if (isKeyUser(state) || isUser(state)) return getUser(state, { userId }).isSSOEnabled
    if (isVendorInvolved(state)) return getVendorUser(state, { userId }).isSSOEnabled
    return false
  }

  return {
    initialValues: {
      old: null,
      password: null,
      passwordConfirmation: null,
    },
    isUserSSOEnabled: isUserSSOEnabled(),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'changePassword',
  })(ChangePasswordContainer)
)
