const employeeStatusEnum = {
  LOCAL: {
    LABEL: 'Local Employee',
    VALUE: 'Local Employee',
  },
  EXPAT: {
    LABEL: 'Expat',
    VALUE: 'Expat',
  },
  CONTRACTOR: {
    LABEL: 'Contractor',
    VALUE: 'Contractor',
  },
  CONTRACTOR_INDIVIDUAL: {
    LABEL: 'Contractor - Individual',
    VALUE: 'Contractor - Individual',
  },
  CONTRACTOR_COMPANY: {
    LABEL: 'Contractor - Company',
    VALUE: 'Contractor - Company',
  },
  CONTRACTOR_AGENCY: {
    LABEL: 'Contractor - Agency',
    VALUE: 'Contractor - Agency',
  },
  EMPLOYER_OF_RECORD: {
    LABEL: 'Employer of Record',
    VALUE: 'Employer of Record',
  },
  SHORT_TERM_ASSIGNMENT: {
    LABEL: 'Short Term Assignment',
    VALUE: 'Short Term Assignment',
  },
  SHORT_TERM_ASSIGNMENT_HOST: {
    LABEL: 'Short Term Assignment (Host)',
    VALUE: 'Short Term Assignment (Host)',
  },
  SUPPLIER: {
    LABEL: 'Supplier',
    VALUE: 'Supplier',
  },
  NON_EMPLOYEE: {
    LABEL: 'Non-employee',
    VALUE: 'Non-employee',
  },
}

employeeStatusEnum.ALL_EMPLOYEMENT_STATUS = [
  employeeStatusEnum.LOCAL,
  employeeStatusEnum.EXPAT,
  employeeStatusEnum.CONTRACTOR,
  employeeStatusEnum.CONTRACTOR_INDIVIDUAL,
  employeeStatusEnum.CONTRACTOR_COMPANY,
  employeeStatusEnum.CONTRACTOR_AGENCY,
  employeeStatusEnum.EMPLOYER_OF_RECORD,
  employeeStatusEnum.SHORT_TERM_ASSIGNMENT,
  employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST,
  employeeStatusEnum.SUPPLIER,
  employeeStatusEnum.NON_EMPLOYEE,
]

employeeStatusEnum.ALL_IMPORT_LABELS = [
  employeeStatusEnum.LOCAL.LABEL,
  employeeStatusEnum.EXPAT.LABEL,
  employeeStatusEnum.CONTRACTOR.LABEL,
  employeeStatusEnum.CONTRACTOR_INDIVIDUAL.LABEL,
  employeeStatusEnum.CONTRACTOR_COMPANY.LABEL,
  employeeStatusEnum.CONTRACTOR_AGENCY.LABEL,
  employeeStatusEnum.EMPLOYER_OF_RECORD.LABEL,
  employeeStatusEnum.SHORT_TERM_ASSIGNMENT.LABEL,
  employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.LABEL,
  employeeStatusEnum.SUPPLIER.LABEL,
  employeeStatusEnum.NON_EMPLOYEE.LABEL,
]

export default employeeStatusEnum
