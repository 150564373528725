import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EXTERNAL_COMPANIES_FETCH = 'EXTERNAL_COMPANIES_FETCH'
export const EXTERNAL_COMPANIES_RECEIVE = 'EXTERNAL_COMPANIES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EXTERNAL_COMPANIES_FETCH,
  receive: EXTERNAL_COMPANIES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'externalCompanies', 'external_companies', 'ExternalCompany')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchExternalCompaniesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchExternalCompanies = actionUtility.fetchEntities
