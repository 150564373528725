import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_ATTENDANCE_FETCH = 'EMPLOYEE_ATTENDANCE_FETCH'
export const EMPLOYEE_ATTENDANCE_RECEIVE = 'EMPLOYEE_ATTENDANCE_RECEIVE'
export const EMPLOYEE_ATTENDANCE_INVALIDATE = 'EMPLOYEE_ATTENDANCE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_ATTENDANCE_FETCH,
  receive: EMPLOYEE_ATTENDANCE_RECEIVE,
  invalidate: EMPLOYEE_ATTENDANCE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'employeeAttendance', 'employeeattendances', 'EmployeeAttendance')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeAttendanceIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeAttendance = actionUtility.fetchEntities
export const invalidateEmployeeAttendance = actionUtility.invalidate
