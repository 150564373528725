import createSelector from 'utils/createSelector'
import _ from 'lodash'

const getPayrollTaskId = (state, props) => parseInt(props.payrollTaskId)
/*
 * Build data for business unit owners initial values
 */
export const getBusinessUnitsOwnersInitialValues = createSelector(getPayrollTaskId,
  ({ PayrollTask }, payrollTaskId) => {
    // Get payrol task object
    const payrollTask = PayrollTask.withId(payrollTaskId)

    const isMultiOwners = payrollTask.taskableType === 'BusinessUnit'

    // If it's not multi owners type, then we should not build multi initial owners values
    if (!isMultiOwners) return {}

    let scopes = payrollTask.payrollTaskOwners.toModelArray().map(pto => {
      return pto.payrollTaskOwnerScopes.toModelArray().map(scope => ({
        ...scope.ref,
        owner: pto.owner
      }))
    })

    scopes = _.flatten(scopes)

    // Build initial values in right format
    //
    // For example: {
    // 'actionOwnerBu-11': 88,
    // 'actionOwnerBu-12': 89,
    // }
    const initialValues = {}
    scopes.map(scope => {
      initialValues[`actionOwnerBu-${scope.taskableId}`] = scope.owner.id
    })

    return initialValues
  }
)
