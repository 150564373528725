import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { documentTextLabels } from 'utils/locales/document.en'
import { useSelector } from 'react-redux'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { LinkDocumentModal } from './LinkDocumentModal'

export const LinkDocument = ({ payrollInstanceId }) => {
  const documentModalRef = useRef(null)
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId }))
  const heading = `${payrollInstance.companyName}, ${payrollInstance.countryName}, ${payrollInstance.payrollNameAndPeriod}`
  return (
    <>
      <LinkDocumentModal heading={heading} payrollInstanceId={payrollInstanceId} documentModalRef={documentModalRef} />
      <div
        onClick={() => {
          documentModalRef.current.showModal()
        }}
        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny
                    u-margin-top-tiny'
        title={documentTextLabels.link}
        data-testid='link-document'
      >
        <span className='icon icon--plus' />
      </div>
    </>
  )
}

LinkDocument.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
}
