import React from 'react'
import Calendar from './Calendar'
import Loader from 'components/Loader'

export const CalendarView = ({ isFetching, isFiltering, ...rest }) => {
  if (isFetching) return <Loader />
  return <div className={isFiltering ? 'u-half-opacity' : null}>
    <Calendar {...rest} />
  </div>
}

export default CalendarView
