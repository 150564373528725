import React, { useReducer, useState, useEffect, useRef, useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { SummaryAdditionalItems } from '../components/SummaryAdditionalITems'
import { getAdditionalItemsTableHeadings } from '../additionalItemsTableConfig'
import { ADDITIONAL_ITEMS_INITIAL_STATE, additionalItemsStateReducer, additionalItemsStateTypes } from '../utils/additionalItemsStateReducer'
import { AdditionalItemsActions } from '../components/AdditionalItemsActions'
import Loader from 'components/Loader'

import { deleteAdditionalItem, fetchAdditionalItems } from 'redux/actions/additionalItems'
import { createFilter } from 'utils/redux/filter'
import { createAdditionalItemsFilterNameById } from 'redux/filters/additionalItemsFilter'
import { getAdditionalItems } from 'redux/selectors/additionalItems'
import { payrollSummaryAdditionalItems } from 'utils/locales/payrollSummary.en'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { DeleteConfirmationModal } from 'components/ReusableModals/DeleteConfirmationModal'
import { isEmpty } from 'lodash'
import { reusableModalHeadings } from 'utils/locales/modals.en'
import { PayrollSummaryContext, summaryViewToggleIds } from '../PayrollSummaryContext'

export const SummaryAdditionalItemsContainer = ({ payrollInstance }) => {
  const dispatch = useDispatch()
  const payrollInstanceId = payrollInstance.id

  // State for handle the delete and history modal and only update this part of the DOM
  const [clickedRow, setClickedRow] = useState()
  const deleteModalRef = useRef()
  const historyModalRef = useRef()

  const filterName = createAdditionalItemsFilterNameById(payrollInstanceId)
  const [state, dispatchStateChange] = useReducer(additionalItemsStateReducer, ADDITIONAL_ITEMS_INITIAL_STATE)

  const additionalITems = useSelector((state) => getAdditionalItems(state, filterName))

  const { mode, data, needsRefetch, hasItems, tableChangedData, isDirty, isLoading, editableTableState } = state

  const { setGlobalUpdateMode } = useContext(PayrollSummaryContext)

  useEffect(() => {
    setGlobalUpdateMode(summaryViewToggleIds.additionalItems, !!mode)
  }, [mode])

  const fetchData = async () => {
    await dispatch(
      fetchAdditionalItems({
        filter: createFilter(
          {
            payrollInstance: payrollInstanceId,
          },
          filterName
        ),
      })
    )
  }

  useEffect(() => {
    if (needsRefetch) {
      fetchData()
    }
  }, [needsRefetch])

  useEffect(() => {
    if (additionalITems) {
      dispatchStateChange({
        type: additionalItemsStateTypes.onFetchData,
        payload: additionalITems,
      })
    }
  }, [additionalITems])

  if (isLoading) {
    return <Loader />
  }

  const onUpdateGlobalData = (rowIndex, columnId, value) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onEditData,
      payload: { rowIndex, columnId, value: `${value}` },
    })

  const toggleMode = (payload) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onToggleMode,
      payload,
    })

  const handleDelete = (row) => {
    setClickedRow(row)
    deleteModalRef?.current?.showModal()
  }
  const handleHistory = (row) => {
    setClickedRow(row)
    historyModalRef?.current?.showModal()
  }

  const notifyErrors = (payload) =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onHasErrors,
      payload,
    })

  const refreshState = () =>
    dispatchStateChange({
      type: additionalItemsStateTypes.onResetState,
    })

  const deleteConfirmation = async () => {
    await dispatch(deleteAdditionalItem(clickedRow.id))
    refreshState()
  }

  const tableHeadings = getAdditionalItemsTableHeadings({ handleDelete, handleHistory })
  const hasHistory = !isEmpty(clickedRow?.history)
  const historyHeading = hasHistory ? `${clickedRow.item} ${reusableModalHeadings.history}` : payrollSummaryAdditionalItems.historyHeading

  return (
    <div className='rounded border u-padding-small'>
      <HistoryModal modalRef={historyModalRef} heading={historyHeading} logs={clickedRow?.history} />
      <DeleteConfirmationModal modalRef={deleteModalRef} onConfirmation={deleteConfirmation} />
      <AdditionalItemsActions
        payrollInstance={payrollInstance}
        mode={mode}
        isDirty={isDirty}
        hasItems={hasItems}
        toggleMode={toggleMode}
        tableChangedData={tableChangedData}
        notifyErrors={notifyErrors}
        refreshState={refreshState}
      />
      <SummaryAdditionalItems data={data} tableHeadings={tableHeadings} editableTableState={editableTableState} onUpdateGlobalData={onUpdateGlobalData} />
    </div>
  )
}
