import createSelector from 'utils/createSelector'

// get the payroll instance id
const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)

/**
 * Get payroll instance by id with Payroll
 */
export const getPayrollInstanceWithPayroll = createSelector(getPayrollInstanceId, ({ PayrollInstance, Payroll: payrollState }, payrollInstanceId) => {
  const payrollInstance = PayrollInstance.withId(payrollInstanceId)
  const payroll = payrollInstance.payroll ?? payrollState.withId(payrollInstance.payrollId)
  return {
    ...payrollInstance.ref,
    payroll: payroll.ref,
  }
})
