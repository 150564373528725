import { Model, fk, attr } from 'redux-orm'
import { actionTypes, EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME } from 'redux/actions/employeeAttendancePeriods'

/**
 * EmployeeAttendancePeriod is an abstraction over EmployeeAttendance model.
 * There isn't a real back-end entity, that correspondents to this model.
 * We just have an API route, that returns computed employee attendance periods.
 *
 * Thanks to the abstraction, we don't calculate the employee attendance periods on front-end.
 * It's better such a calculations and business related functionality to happen on the back-end.
 */
class EmployeeAttendancePeriod extends Model {
  static handlers (Model) {
    return {
      [Model.actionTypes.receive]: (Model, { payload: { response } }) => {
        response.data.map(entity => {
          Model.upsert({
            // Build id dynamically, because the back-end doesn't return ids for this entity,
            // because of the entity is an abstraction over EmployeeAttendance model.
            // The abstraction calculates the employee periods on the back-end,
            // instead of calculating it here on front-end.
            id: `${entity.employee}-${entity.fromDate}-${entity.toDate}`,
            ...entity
          })
        })
      },
      [EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME]: (Model, { payload }) => {
        payload.params.employees.forEach(employeeId => {
          Model.filter(model => model.employee === employeeId).delete()
        })
      },
    }
  }

  static reducer (action, Model) {
    const handlers = EmployeeAttendancePeriod.handlers(Model)
    const handler = handlers[action.type]

    handler && handler(Model, action)
  }
}
EmployeeAttendancePeriod.modelName = 'EmployeeAttendancePeriod'
EmployeeAttendancePeriod.actionTypes = actionTypes

EmployeeAttendancePeriod.fields = {
  id: attr(),
  employee: fk('EmployeeSystemUser', 'employeeAttendancePeriods'),
  companyCountryTerm: fk('CompanyCountryTerm', 'employeeAttendancePeriods')
}

export default EmployeeAttendancePeriod
