import createSelector from 'utils/createSelector'
const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)

export const getPayrollInstanceVersionsByInstanceIdSortedDesc = createSelector(getPayrollInstanceId, ({ PayrollInstanceVersion }, payrollInstanceId) => (
  PayrollInstanceVersion
    .all()
    .toRefArray()
    .filter(v => v.payrollInstance === payrollInstanceId)
    .sort((a, b) => new Date(b.createdAt.date) - new Date(a.createdAt.date))
))
