import createSelector from 'utils/createSelector'
import _ from 'lodash'
import { employeeCustomFieldsConfig } from 'redux/config/employeeFieldsConfig'
import { sortByEmployeeName } from 'redux/models/payrollInstanceEmployee'
import moment from 'moment'

const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)
const getEmployeeId = (state, props) => parseInt(props.employeeId)

/**
 * Get all employees for the current payroll instance
 */
export const getEmployeesByPayrollInstance = createSelector(getPayrollInstanceId,
  ({ PayrollInstance }, payrollInstanceId) => {
    return PayrollInstance.withId(payrollInstanceId)
      .payrollInstanceEmployees
      .toModelArray()
      .filter(pivot => pivot.employee.isAccessable)
      .sort(sortByEmployeeName)
      .map(getPayrollInstanceEmployee)
  }
)

/**
 * Get an employee by id,
 * with all needed payroll instance data + related models
 */
export const getEmployeeByPayrollInstanceByEmployee = createSelector(getPayrollInstanceId, getEmployeeId,
  ({ PayrollInstance }, payrollInstanceId, employeeId) => {
    const payrollInstanceEmployee = PayrollInstance.withId(payrollInstanceId)
      .payrollInstanceEmployees.filter({ employee: employeeId }).first()

    return getPayrollInstanceEmployee(payrollInstanceEmployee)
  }
)

const getPayrollInstanceEmployee = pivot => {
  const countries = pivot.employee.countries.toRefArray().map(country => country.name)
  const nationalFields = _.flatten(employeeCustomFieldsConfig
    .filter(field => countries.includes(field.country))
    .map(field => field.customFields
      .filter(cf => cf.showInPayrollInstance)
      .map(cf => ({
        name: cf.printableName,
        value: pivot.employee[cf.name],
      }))
    ))

  const annualSalary = pivot.annualSalary ? pivot.annualSalary.ref : null

  return {
    ...pivot.employee.ref,
    annualSalary: annualSalary ? annualSalary.value : null,
    effectiveDate: annualSalary && annualSalary.effectiveDate && pivot.showEffectiveDate ? moment(annualSalary.effectiveDate.date).format('DD/MM/YYYY') : null,
    company: { ...pivot.employee.company.ref },
    countries,
    country: pivot.employee.company.country.name,
    businessUnit: pivot.employee.businessUnit ? { ...pivot.employee.businessUnit.ref } : null,
    paygroup: pivot.paygroup ? { ...pivot.paygroup.ref } : null,
    payrollInstanceEmployeeId: pivot.id,
    grossPay: pivot.grossPay,
    prepayAmount: pivot.prepayAmount,
    paymentAmount: pivot.paymentAmount,
    netIncome: pivot.netIncome,
    totalTaxableIncome: pivot.totalTaxableIncome,
    netPay: pivot.netPay,
    totalEmployerCost: pivot.totalEmployerCost,
    payrollInstanceEmployeeCurrency: pivot.currency ? { ...pivot.currency.ref } : null,
    payrollInstanceEmployeeNotes: pivot.notes,
    nationalFields,
    notes: pivot.notes
  }
}

export const getNationalFieldsByPayrollInstance = createSelector(getEmployeesByPayrollInstance,
  (session, employees) => {
    const fields = _.flatten(employees.map(employee => employee.nationalFields.map(field => field.name)))

    return _.uniq(fields)
  }
)

export const getNationalFieldsByPayrollInstanceByEmployee = createSelector(getEmployeeByPayrollInstanceByEmployee,
  (session, employee) => {
    return employee.nationalFields.map(field => field.name)
  }
)

export const getEmployeeById = createSelector(
  getEmployeeId,
  ({ EmployeeSystemUser }, employeeId) => {
    const employee = EmployeeSystemUser.withId(employeeId)
    return {
      ...employee.ref
    }
  }
)
