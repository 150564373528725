import React from 'react'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import PropTypes from 'prop-types'
import { tenantTypes, resetPasswordMissingPhoneNumberMessage } from 'utils/enums/tenantTypes'

class ResetPasswordPhoneView extends React.Component {
  render () {
    const { mobilePhone, tenantType, history, logout } = this.props
    let message = resetPasswordMissingPhoneNumberMessage.TENANT

    if (tenantType === tenantTypes.COT || tenantType === tenantTypes.VENDOR) {
      message = resetPasswordMissingPhoneNumberMessage.COT_AND_VENDOR
    }

    return <>
      {
        !mobilePhone
          ? (
            <div className='o-layout o-grid o-grid--center'>
              <p className='u-text--normal u-text--center line-height--24 u-margin-top u-margin-bottom-large'>
                {message}
              </p>

              <button
                type='submit'
                className={`c-btn c-btn--full c-btn--submit c-btn--small c-btn--curious c-btn--rounded u-padding-left u-padding-right`}
                tabIndex='-1'
                onClick={() => {
                  logout()
                  history.push('/login')
                }}
              >
                OK
              </button>
            </div>
          )
          : (
            <>
              <div className='o-grid o-grid--center'>
                <Form onSubmit={this.props.onSubmit} btnText='Next' colorClassBtn='c-btn--curious' btnFullWidth {...this.props}>
                  <div className='o-form-group u-margin-top-large u-margin-bottom-small u-padding-bottom-tiny'>
                    <Field
                      name='phoneNumber'
                      label={`We'll send an authentication code to the following phone number: `}
                      placeholder={`${this.props.mobilePhone || ''}`}
                      component={InputField}
                      type='text'
                      autoFocus
                      className='c-input c-input__value-havelock c-input--alt c-input--rounded u-margin-top u-margin-bottom'
                      labelClassName='c-label'
                      disabled
                    />
                  </div>
                  <button
                    type='submit'
                    className={`c-btn c-btn--full c-btn--submit c-btn--small c-btn--curious c-btn--rounded u-padding-left u-padding-right
                    ${!this.props.mobilePhone ? 'disabled' : ''}
                    `}
                    tabIndex='-1'
                    disabled={!this.props.mobilePhone}
                  >
                    Next
                  </button>
                </Form>
              </div>
              <div className='o-layout o-grid o-grid--center'>
                <p className='u-text--small u-text--center u-margin-top'>If the provided phone number is not correct, please contact an administrator.</p>
              </div>
            </>
          )
      }
    </>
  }
}

ResetPasswordPhoneView.propTypes = {
  onSubmit: PropTypes.func,
  mobilePhone: PropTypes.string,
  tenantType: PropTypes.string,
  history: PropTypes.object,
  logout: PropTypes.func,
}

export default ResetPasswordPhoneView
