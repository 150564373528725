import createSelector from 'utils/createSelector'

const getParentCompanyId = (state, props) => parseInt(props.parentCompanyId)
const getVendorId = (state, props) => ({
  vendorId: parseInt(props.vendorId),
  isCot: props.isCot,
})
const getParentCompanyTenants = (state, props) => props

export const getParentCompanies = createSelector(({ ParentCompany }) => {
  return ParentCompany.all()
    .toRefArray()
    .map((company) => ({
      ...company,
    }))
})

export const getParentCompaniesByVendor = createSelector(getVendorId, ({ ParentCompany }, { vendorId, isCot }) => {
  const companies = ParentCompany.all()
    .toRefArray()
    .map((company) => ({
      ...company,
    }))
  if (isCot) {
    return companies.filter((c) => c.vendorParentCompanies?.includes(c.id))
  }
  return companies
})

export const getParentCompanyById = createSelector(getParentCompanyId, ({ ParentCompany }, parentCompanyId) => {
  const parentCompany = ParentCompany.withId(parentCompanyId)
  return {
    ...parentCompany.ref,
  }
})

export const getFilteredParentCompaniesByTenants = createSelector(getParentCompanyTenants, ({ ParentCompany }, { tenants }) =>
  ParentCompany.all()
    .toRefArray()
    .filter((c) => tenants.includes(c.schema))
)
