import React from 'react'
import { getTemplate, getUiOptions } from '@rjsf/utils'

const REQUIRED_FIELD_SYMBOL = '*'

/**
 * Renders a label element with optional required field symbol.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The text to display within the label.
 * @param {boolean} props.required - Whether the label should indicate a required field.
 * @param {string} props.id - The ID for the corresponding form element.
 * @return {JSX.Element|null} The rendered label element, or null if no label text is provided.
 */
const Label = ({ label, required, id }) => {
  if (!label) {
    return null
  }
  const content = required ? `${label} ${REQUIRED_FIELD_SYMBOL}` : label

  return (
    <label className='c-label u-text--small u-text--curious' htmlFor={id}>
      {content}
    </label>
  )
}

/**
 * FieldTemplate is a functional component used in form generation.
 * It provides a template for wrapping form fields and
 * manages their rendering based on properties provided.
 *
 * @param {object} props - The properties object.
 * @param {string} props.id - An unique identifier for the field.
 * @param {string} props.label - The label for the field.
 * @param {ReactNode} props.children - The child elements or field components.
 * @param {ReactNode} props.errors - Error messages associated with the field.
 * @param {ReactNode} props.help - Helpful information or hints for the field.
 * @param {ReactNode} props.description - Description of the field.
 * @param {boolean} props.hidden - Flag to determine if the field should be hidden.
 * @param {boolean} props.required - Flag to indicate if the field is required.
 * @param {boolean} props.displayLabel - Flag to determine if the label should be displayed.
 * @param {object} props.registry - Registry of templates and fields.
 * @param {object} props.uiSchema - UI schema object that defines UI options for the field.
 *
 * @returns {JSX.Element} A JSX element that represents the field with its associated properties and children.
 */
export const FieldTemplate = (props) => {
  const { id, label, children, errors, help, description, hidden, required, displayLabel, registry, uiSchema } = props
  const uiOptions = getUiOptions(uiSchema)
  const WrapIfAdditionalTemplate = getTemplate('WrapIfAdditionalTemplate', registry, uiOptions)
  if (hidden) {
    return <div className='hidden'>{children}</div>
  }
  return (
    <WrapIfAdditionalTemplate {...props}>
      {displayLabel && <Label label={label} required={required} id={id} />}
      {displayLabel && description ? description : null}
      {children}
      {errors}
      {help}
    </WrapIfAdditionalTemplate>
  )
}
