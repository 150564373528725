import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceTaskOwnerScope'

class PayrollInstanceTaskOwnerScope extends CRUDModel {}

PayrollInstanceTaskOwnerScope.modelName = 'PayrollInstanceTaskOwnerScope'
PayrollInstanceTaskOwnerScope.actionTypes = actionTypes

PayrollInstanceTaskOwnerScope.fields = {
  id: attr(),
  payrollInstanceTaskOwner: fk('PayrollInstanceTaskOwner', 'payrollInstanceTaskOwnerScopes')
}

export default PayrollInstanceTaskOwnerScope
