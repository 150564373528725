import React from 'react'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { cookieTabTitle } from 'utils/locales/policyCookies.en'
import { privacyTabTitle } from 'utils/locales/policyPrivacy.en'

const InnerWrapper = ({ ...props }) => {
  return (
    <InnerWrapperTabs
      tabs={[
        { name: privacyTabTitle, to: '/privacysecurity/privacy' },
        { name: cookieTabTitle, to: '/privacysecurity/cookie' },
      ]}
      {...props}
    />
  )
}

export default InnerWrapper
