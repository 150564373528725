import { createReducer } from 'utils/redux/reducer'
import { TENANT_SELECT } from '../actions/tenants'

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer({
  url: null,
  type: null,
  id: null
}, {
  [TENANT_SELECT]: (state, { payload: { url, type, id } }) => ({ ...state, url, type, id })
})
