export const payrollReconciliationFilterMapping = {
  elementName: 'element',
  previous: 'previousZero',
  v1: 'v1Zero',
  v2: 'v2Zero',
}

export const rolesAndRightsFilterMapping = {
  name: 'fullname',
  firstname: 'firstname',
  surname: 'surname',
  status: 'hasCredentials',
  base: 'location',
  role: 'roleType',
  accessArea: 'inclusiveAccessableCompanies',
  businessUnits: 'accessableBusinessUnits',
  position: 'position',
  id: 'id',
}

export const rolesAndRightsPartialSearchFieldEnabled = {
  name: 'fullname',
  position: 'position',
}

export const shareModalsFilterMapping = {
  name: 'fullname',
  roleTypeName: 'roleType',
}

export const shareModalsSortMapping = {
  name: 'firstname',
  roleTypeName: 'roleType',
}
