import React from 'react'
import PropTypes from 'prop-types'

class Option extends React.Component {
  constructor (props) {
    super(props)

    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
  }

  handleMouseDown (event) {
    if (this.props.option.disabled) return

    event.preventDefault()
    event.stopPropagation()
    this.props.onSelect(this.props.option, event)
  }

  handleMouseEnter (event) {
    if (this.props.option.disabled) return

    this.props.onFocus(this.props.option, event)
  }

  handleMouseMove (event) {
    if (this.props.option.disabled) return
    if (this.props.isFocused) return

    this.props.onFocus(this.props.option, event)
  }

  getTitle () {
    const { option: { label, disabled, disabledReason } } = this.props

    if (disabled && disabledReason) return disabledReason

    return label
  }

  render () {
    const { children } = this.props

    return (
      <div onMouseDown={this.handleMouseDown} onMouseEnter={this.handleMouseEnter} onMouseMove={this.handleMouseMove}
        className={this.props.className}
        title={this.getTitle()}>{children}</div>
    )
  }
}

Option.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired,
}

export default Option
