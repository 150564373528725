import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/referrals'

class Referral extends CRUDModel {}
Referral.modelName = 'Referral'
Referral.actionTypes = actionTypes

Referral.fields = {
  id: attr(),
  name: attr(),
  company: fk('ParentCompany', 'referrals'),
}

export default Referral
