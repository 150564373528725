import _ from 'lodash'

/**
 * Transform amount fields, in order to match the BE specifics.
 *
 * @param {Object} entity - The data object
 * @param {Array} fields - The data's fields names as String values, these are amounts and have to be transformed.
 * @param {String} subField - If one of the above fields' values is object, then we have to specify it's name.
 * @return {Object} entity
 */
export const amountsTransformer = (entity, fields, subField = '') => {
  let normalized = _.cloneDeep(entity)

  fields.forEach((field) => {
    if (!normalized.hasOwnProperty(field) || (subField && !normalized[field].hasOwnProperty(subField))) return null

    if (normalized[field] && normalized[field].hasOwnProperty(subField)) {
      normalized[field][subField] = amountTransformer(normalized[field][subField])
    } else {
      normalized[field] = amountTransformer(normalized[field])
    }
  })

  return normalized
}

/**
 * Transform amount property so it
 * could be sent correctly to the back end
 *
 * @param amount - the amount you're sending
 * @return String
 */
export const amountTransformer = (amount) => {
  if (amount === '' || amount === null) return null
  return amount.toString().replace(/,/g, '')
}

/**
 * Normalize amount number to send it to back end and take into account the float format
 * @param {*} number - the ammount number  you're sending to BE
 * @returns
 */
export const normalizeNumber = (number) => parseFloat(amountTransformer(number)).toString()
