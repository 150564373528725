import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactAvatar from 'components/ReactAvatar'

export const Employee = ({ employee, children }) => {
  const personalPath = `/employees/${employee.id}/personal`

  return <div className='c-panel__holder o-layout__item u-margin-bottom-huge
    u-relative u-1/2 u-1/4@tablet u-1/6@desktop'>
    <Link to={personalPath}>
      <div className='c-panel c-panel--clickable o-block o-block--center
        u-bg--white u-padding-small u-padding-bottom u-relative border-1-valhalla rounded-md hover--shadow-md u-word-break-all'>
        <div className='centered-image o-media__img o-media__img--circle employee-avatar'>
          <ReactAvatar firstName={employee.firstname} lastName={employee.surname} color='#291547' />
        </div>
        <h3 className='u-text--curious u-text--normal u-padding-top
          u-margin-bottom-tiny'>{employee.firstname} {employee.surname}</h3>
        <div className='u-text--normal'>{ employee.company ? employee.company.name : null }</div>
        <div className='u-text--normal u-padding-bottom-small'>
          {employee.businessUnit ? employee.businessUnit.name : null}
        </div>
      </div>
    </Link>
    <div className='o-form-group__errors--abs'>
      { children }
    </div>
  </div>
}

// component prop types
Employee.propTypes = {
  children: PropTypes.element,
  employee: PropTypes.shape({
    firstname: PropTypes.string,
    surname: PropTypes.string,
    company: PropTypes.object.isRequired,
    position: PropTypes.string,
    id: PropTypes.number,
    businessUnit: PropTypes.object,
  }),
}

export default Employee
