import {
  payrollInputsDocTypes,
  payrollGtnDocTypes,
  payrollPayslipsTaxDocTypes,
  payrollOtherDocTypes,
  payrollGlReportsDocTypes,
  docTypes,
  payrollPaymentsDocTypes,
  docTypeIDs,
} from 'utils/enums/documentTypes'
import { types } from 'redux/config/documents'
import { payrunStatesEnum } from './enums/payrunStates'
import { gtnValidationStatusEnums } from './enums/gtnValidationEnums'

/**
 * Set documentTypes
 *
 * @param {Array} docTypes
 * @param {Array} docTypesToExclude
 * @param {Boolean} isCotUser
 *
 * @param isCotBotUser
 * @param isCotOrEngineerOrAdmin
 * @param cotUserSpecialRights
 * @returns Array
 */
export const setDocumentTypes = (
  docTypes,
  docTypesToExclude,
  isCotUser = false,
  isCotBotUser = false,
  isCotOrEngineerOrAdmin = false,
  cotUserSpecialRights = false
) => {
  // Auto saved files have to be excluded from COT, ER and ICP file upload modals
  const excludedForAll = ['inputsAutoSaved', 'gtnAutoSaved']

  // Additional input files have to be allowed only for COT BOT
  const allowedForCotBotOnly = ['additionalInputs']
  const filteredDocTypes = docTypes.filter((type) => !excludedForAll.includes(type.name))

  if (!isCotUser) return filteredDocTypes.filter((type) => !docTypesToExclude.includes(type.name))

  if (isCotOrEngineerOrAdmin && cotUserSpecialRights) {
    // COT can have special rights to upload bank file and/or beneficiary file and/or data migration file
    let filteredForSpecialRights = filteredDocTypes
    if (!cotUserSpecialRights.beneficiaryFile) filteredForSpecialRights = filteredForSpecialRights.filter((type) => type.name !== 'beneficiaryFile')
    if (!cotUserSpecialRights.bankFile) filteredForSpecialRights = filteredForSpecialRights.filter((type) => type.name !== 'bankFile')
    if (!cotUserSpecialRights.dataMigration) filteredForSpecialRights = filteredForSpecialRights.filter((type) => type.name !== 'dataMigration')
    return filteredForSpecialRights.filter((type) => !allowedForCotBotOnly.includes(type.name))
  }

  if (!isCotBotUser) return filteredDocTypes.filter((type) => !allowedForCotBotOnly.includes(type.name))
  return filteredDocTypes
}

export const getDocumentTypesByTabName = (tabName) => {
  switch (tabName) {
  case 'payrollInputsDocTypes':
    return payrollInputsDocTypes
  case 'payrollGtnDocTypes':
    return payrollGtnDocTypes
  case 'payrollPayslipsTaxDocTypes':
    return payrollPayslipsTaxDocTypes
  case 'payrollGlReportsDocTypes':
    return payrollGlReportsDocTypes
  case 'payrollPaymentsDocTypes':
    return payrollPaymentsDocTypes
  case 'payrollOtherDocTypes':
    return payrollOtherDocTypes
  default:
    return null
  }
}
/**
 * Get the Tabs to show for document modal based on the payrun status
 *
 * @param {Array} tabs array Array of tabs to perform a filter one
 * @param {String} payrunState The current payrun state
 * @returns Array
 */

export const getFilteredDocTabsForByPayrunState = (tabs, payrunState = null) => {
  if (!tabs) return tabs
  return tabs.filter((opt) => (payrunState ? opt.access.includes(payrunState) : null))
}

export const getDefaultTabByCurrentStep = (stepName, payrunState) => {
  let tabName
  const isValidPayrunState = [payrunStatesEnum.ACTIVE, payrunStatesEnum.INACTIVE, payrunStatesEnum.PREVIOUS, payrunStatesEnum.PREVIOUS_EXTRA_TASKS].includes(
    payrunState
  )

  if (payrunState !== payrunStatesEnum.ACTIVE) {
    tabName = 'payrollOtherDocTypes'
  }

  if (payrunState === payrunStatesEnum.INACTIVE) {
    tabName = 'payrollInputsDocTypes'
  }

  if (!tabName) {
    switch (stepName) {
    case 'Payroll Inputs':
      tabName = 'payrollInputsDocTypes'
      break
    case 'Approve and Send for Calculation':
      tabName = 'payrollInputsDocTypes'
      break
    case 'Preview Payroll Calculations':
    case 'Final Review and Authorization':
      tabName = 'payrollGtnDocTypes'
      break
    case 'Payslips':
    case 'Invoices':
      tabName = 'payrollPayslipsTaxDocTypes'
      break
    default:
      tabName = 'payrollOtherDocTypes'
      break
    }
  }
  // Here we are getting the index because tab navigation is index based
  const tabIndex = types['payrollInstance'].documentTypesTabs
    .filter((opt) => (isValidPayrunState ? opt.access.includes(payrunState) : true))
    .findIndex((e) => e.name === tabName)

  // react-tabs library keeps the tab closed if the tabIndex is -1. Any other value will default to the first tab.
  return tabIndex !== -1 ? tabIndex : 0
}

export const getTabIndexByDocType = (docType, payrunState) => {
  let tabName
  switch (docType) {
  case docTypes.INPUTS:
  case docTypes.INPUTS_VALIDATION_REPORT:
  case docTypes.MIPI_INPUTS:
    tabName = 'payrollInputsDocTypes'
    break
  case docTypes.GTN:
  case docTypes.GTN_MAPPING_REPORT:
    tabName = 'payrollGtnDocTypes'
    break
  case docTypes.PAYSLIPS:
  case docTypes.TAX_DOCUMENTS:
  case docTypes.PAYSLIP_UPLOAD_REPORT:
  case docTypes.TAX_DOCUMENT_UPLOAD_REPORT:
  case docTypes.PAYSLIPS_VALIDATION_REPORT:
  case docTypes.PAYSLIPS_HCM_REPORT:
  case docTypes.TAX_DOCS_VALIDATION_REPORT:
  case docTypes.TAX_DOCS_HCM_REPORT:
    tabName = 'payrollPayslipsTaxDocTypes'
    break
  case docTypes.GL_MAPPING:
  case docTypes.GL_REPORTS:
    tabName = 'payrollGlReportsDocTypes'
    break
  case docTypes.BANK_FILE:
  case docTypes.BENEFICIARY_FILE:
  case docTypes.BANK_FILE_VALIDATION_REPORT:
    tabName = 'payrollPaymentsDocTypes'
    break
  default:
    tabName = 'payrollOtherDocTypes'
    break
  }

  // Here we are getting the index because tab navigation is index based
  const tabIndex = types['payrollInstance'].documentTypesTabs
    .filter((opt) => (payrunState ? opt.access.includes(payrunState) : true))
    .findIndex((e) => e.name === tabName)

  // react-tabs library keeps the tab closed if the tabIndex is -1. Any other value will default to the first tab.
  return tabIndex !== -1 ? tabIndex : 0
}

export const getUploadedDocumentTypesObjects = (dataTypes, allDocTypes) => dataTypes.map((t) => allDocTypes.find((d) => d.id === t))

export const getTabsByUploadedDocumentTypes = (uploadedDataTypes, payrunState) => {
  let arr = []
  uploadedDataTypes.forEach((t) => {
    arr.push(getTabIndexByDocType(t.text, payrunState))
  })
  return arr
}

export const allowedShareForGTNValidations = [gtnValidationStatusEnums.validated, gtnValidationStatusEnums.overwritten, gtnValidationStatusEnums.notApplicable]
export const disableShareForGTNValidations = [gtnValidationStatusEnums.failedValidation]

export const disableEditForGTNValidationsCOT = [
  gtnValidationStatusEnums.pendingValidation,
  gtnValidationStatusEnums.validated,
  gtnValidationStatusEnums.overwritten,
  gtnValidationStatusEnums.notApplicable,
  gtnValidationStatusEnums.failedValidation,
]

export const omitDocTypeIdsForKU = [docTypeIDs.DATA_MIGRATION, docTypeIDs.DATA_MIGRATION_VALIDATION_REPORT]
export const omitDocTypeIdsForICP = [docTypeIDs.BENEFICIARY_FILE, docTypeIDs.DATA_MIGRATION, docTypeIDs.DATA_MIGRATION_VALIDATION_REPORT]
