import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommentIcon } from 'components/icons/CommentIcon'
import { buttonActions } from 'utils/locales/common.en'
import { saveSummaryNotes } from 'redux/actions/summarynotes'
import { errorMessageLabels } from 'utils/locales/errors.en'
import { showMessage } from 'redux/actions/modal'
import { LeaveConfirmationModal, LeaveRouteConfirmationModal } from 'components/ReusableModals/LeaveConfirmationModal'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'

export const SummaryNotes = ({ isDirty, payrollInstanceId, tableChangedData, isEdit, isAllRowsExpanded, toggleShowComments, toggleEditMode, refreshState }) => {
  const navigationLockedModalRef = useRef()
  const dispatch = useDispatch()
  const notesToSave = tableChangedData?.filter((note) => note.isDirty)
  const canEdit = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state))
  const showEditButton = isAllRowsExpanded && canEdit

  const handleIfInecessaryLeaveModal = (cb) => {
    if (isDirty) {
      navigationLockedModalRef?.current?.showModal()
      return
    }
    cb()
  }

  const handleSave = async () => {
    if (isDirty) {
      const savedNotesResponse = await dispatch(saveSummaryNotes(payrollInstanceId, notesToSave))
      if (savedNotesResponse?.hasErrors) {
        dispatch(showMessage({ body: errorMessageLabels.somethingWentWrong }))
      }
    }
    refreshState()
  }

  return (
    <div className='d-flex'>
      {isEdit && (
        <>
          <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={refreshState} />
          <LeaveRouteConfirmationModal isDirty={isDirty} />
        </>
      )}
      <button
        className={`${btnClasses} d-flex u-margin-right-small`}
        onClick={() => {
          handleIfInecessaryLeaveModal(toggleShowComments)
        }}
      >
        <CommentIcon className='u-margin-right-small' />
        {isAllRowsExpanded ? 'Close Comments' : 'View Comments'}
      </button>
      {showEditButton && (
        <button
          onClick={toggleEditMode}
          className={`${btnClasses} u-margin-right-small`}
          title={buttonActions.edit}
          data-testid='versioning-comments-edit'
          disabled={isAllRowsExpanded && isEdit}
        >
          <span className='icon icon--edit' />
        </button>
      )}
      {isEdit && (
        <>
          <button
            onClick={() => {
              handleIfInecessaryLeaveModal(refreshState)
            }}
            className={`${btnClasses} u-margin-right-small`}
            title={buttonActions.cancel}
            data-testid='versioning-cancel'
          >
            {buttonActions.cancel}
          </button>
          <button onClick={handleSave} className={`${btnClasses} u-margin-right-small`} title={buttonActions.save} data-testid='versioning-save'>
            {buttonActions.save}
          </button>
        </>
      )}
    </div>
  )
}
