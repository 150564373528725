import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { vendorColumnLabels } from 'utils/locales/vendor.en'
import { JoinColItemsByCommaFormat } from 'utils/tableDataFormatters'

export const getVendorUsersTableConfig = () => [
  new TableHeading({
    heading: vendorColumnLabels.name,
    accessor: 'name',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.status,
    accessor: 'status',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.mobilePhone,
    accessor: 'mobilePhone',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.workEmail,
    accessor: 'workEmail',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.position,
    accessor: 'position',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.country,
    accessor: 'country',
    disableSortBy: true,
    className: 'text-center',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.roleType,
    accessor: 'roleType',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
  }),
  new TableHeading({
    heading: vendorColumnLabels.accessArea,
    accessor: 'accessAreas',
    disableSortBy: true,
    className: 'text-left',
    columnClassName: 'text-left',
    Cell: JoinColItemsByCommaFormat,
  }),
  new TableHeading({
    heading: vendorColumnLabels.action,
    accessor: 'action',
    disableSortBy: true,
    actionCell: true,
    className: 'text-center',
    columnClassName: 'text-left',
  }),
]
