/**
 * All User's derived models common functionality
 *
 * `EmployeeSystemUser`, `CotUser`, `VendorUser` and other User derived models have very common functionality,
 * these can be reused.
 * However, JS doesn't support Traits or Multiple Inheritance natively.
 * It's over-engineering process to create workarounds, because of this we created this simple organization,
 * where we keep the things in common in a small reusable fashion (functions).
 *
 * Later, once we have more common features across the models,
 * we can move a step forward, implementing the following mixin conception:
 * @link https://stackoverflow.com/a/46524650/4312466
 */

export const getName = user => {
  if (user.title) return `${user.title} ${user.firstname} ${user.surname}`

  return `${user.firstname} ${user.surname}`
}
