import { createSelector } from 'reselect'
const getBreadcrumbs = (state) => state.breadcrumbs
const getBreadcrumbKey = (state, props) => props.breadcrumbKey

/**
 * Method to fetch the specific breadcrumb object by a given key
 */
export const getBreadcrumbsByKey = createSelector([getBreadcrumbs, getBreadcrumbKey], (breadcrumbs, breadcrumbKey) => {
  return breadcrumbs[breadcrumbKey] ?? null
})

/**
 * Method to fetch from the store the current breadcrumbs filters byt a given key
 */
export const getBreadcrumbFiltersByKey = createSelector([getBreadcrumbsByKey], (breadcrumbs) => {
  return breadcrumbs?.filters ?? null
})
