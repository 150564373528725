import _ from 'lodash'

/**
 * Compute all `param` object values.
 *
 * It will pass `(state, props)` to all object value functions,
 * and will create a new object, containing the resulted values.
 *
 * @example
 * computeParam({ filter: (state, props) => props.companyId }, {}, { companyId: 1 })
 * // will result to
 * { filter: 1 }
 *
 * @param {Object} param - Reference to `_computed` object, which values are functions, these expecting state, props.
 * @param {Object} state
 * @param {Object} props
 * @returns {{}}
 */
const computeParam = (param, state, props) => {
  return Object.keys(param).reduce((acc, key) => {
    acc[key] = param[key](state, props)

    return acc
  }, {})
}

/**
 * Pass (state, props) to `_computed` entities params, in order to support dynamic parameters passing.
 *
 * All the `entities` have `params` property.
 * There you can pass any parameters, these will be passed to the actions later, respecting the order they are defined.
 * In most of the cases, you want to pass computed (dynamic) parameters, there are result of some state or props
 * computation.
 *
 * This function checks for `_computed` key in the params object values, and create a new resulting object with the
 * newly computed values. Please refer to `computeParam` for implementation details.
 *
 *
 * @example
 *
 * Fetcher(Container,
 * [{
 *    name: 'company',
 *    params: [{
 *      _computed: {
 *        filter: (state, props) => props.companyId)
 *      }
 *    }]
 * }])
 *
 * @param {Array} entities
 * @param {Object} state
 * @param {Object} props
 * @returns {Array}
 */
export const computeEntities = (entities, state, props) =>
  entities.map((entity) => {
    const params = entity.params.map((param) => {
      if (param._computed) {
        const { _computed, ...rest } = param

        return {
          ...rest,
          ...computeParam(_computed, state, props),
        }
      }

      return param
    })

    return {
      ...entity,
      params,
    }
  })
/**
 * Normalize `entities` so that we always work with objects with the same properties.
 * This way we don't have to check if we are working with object or string every time
 *
 * Example for normalized 'company' entity:
 * {
 *    name: 'company',
 *    params: []
 * }
 */
export const normalizeEntities = (entities) =>
  entities.map((entity) => {
    if (_.isObject(entity)) {
      if (!('params' in entity)) {
        entity['params'] = []
      }
      return entity
    }

    return {
      name: entity,
      params: [],
    }
  })

export const validateEntities = (entities, actions) => {
  const errorEntities = entities.filter((entity) => !actions[entity.name])

  if (errorEntities.length) throw new Error(buildError(errorEntities))
}

export const buildError = (errorEntities) => {
  const entitiesNames = errorEntities.map((e) => e.name)

  return `No mapping ('redux/actions/index.js') found for the following entities: ${entitiesNames.join(', ')}`
}
