import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { isNull } from 'lodash'
import { sortByLabel } from 'utils/strings'

const AddServiceForm = ({ ...props }) => (
  <Form {...props}>
    <div className='o-layout u-text--center'>
      {isNull(props.countryId) && (
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='country'
            label='Country'
            labelClassName='c-label'
            className='u-text--left'
            component={CustomSelectField}
            placeholder='Select country'
            clearable={false}
            options={props.countries
              .map((country) => ({
                value: country.id,
                label: country.name,
              }))
              .sort(sortByLabel)}
            onChanged={props.onCountryChange}
          />
        </div>
      )}
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          name='countryService'
          label='Service'
          labelClassName='c-label'
          className='u-text--left'
          component={CustomSelectField}
          placeholder='Select service'
          clearable={false}
          options={props.services.map((service) => ({
            value: service.id,
            label: service.name,
          }))}
        />
      </div>
      <div className='o-layout__item u-1/1'>
        <button type='submit' className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'>
          Save
        </button>
      </div>
    </div>
  </Form>
)

AddServiceForm.propTypes = {
  countryId: PropTypes.number,
  countries: PropTypes.array,
  services: PropTypes.array,
}

export default AddServiceForm
