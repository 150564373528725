import CoreLayout from 'layouts/PageLayout/PageLayout'
import ChangeDetailsRoute from '../ChangeDetails'
import TimeAttendanceUpdatesRoute from '../TimeAttendanceUpdates'
import InstanceRouteNew from '../InstanceNew'
import InstanceEmptyRoute from '../InstanceEmpty'
import InstanceChanges from '../InstanceChanges'
import VariableElementsContainer from '../VariableElements'
import AddEmployeesRoute from '../AddEmployees'
import UnassignEmployees from '../UnassignEmployees'
import RouteView from './components/RouteView'
import NotesRoute from '../Notes'
import PayTaxesElementsContainer from 'routes/Payruns/routes/PayTaxesElements'

export default (store) => ({
  path: 'extra',
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  renderChildrenOutSideTab: true,
  wrappedComponent: CoreLayout,
  childRoutes: [
    ChangeDetailsRoute(store),
    TimeAttendanceUpdatesRoute(store),
    InstanceRouteNew(store),
    InstanceEmptyRoute(store),
    InstanceChanges(store),
    VariableElementsContainer(store),
    AddEmployeesRoute(store),
    UnassignEmployees(store),
    NotesRoute(store),
    PayTaxesElementsContainer(store),
  ],
})
