import createSelector from 'utils/createSelector'

const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId, 10)
const getPayrollId = (state, props) => parseInt(props.payroll, 10)

export const getCompanyByPayrollInstance = createSelector(getPayrollInstanceId,
  ({ PayrollInstance }, id) => {
    // get payroll instance
    let payrollInstance = PayrollInstance.withId(id)

    return {
      ...payrollInstance.payroll.company.ref,
    }
  }
)

export const getCompanyIdByPayrollInstance = createSelector(getPayrollInstanceId,
  ({ PayrollInstance }, id) => {
    // get payroll instance
    let payrollInstance = PayrollInstance.withId(id)
    // return payroll instance id
    return payrollInstance.company
  }
)
/**
 * Get Company id by Payroll id
 * @returns {string} if companyId is found or @returns {undefined}
 */

export const getCompanyIdByPayroll = createSelector(getPayrollId,
  ({ Payroll }, id) => {
    let payroll = Payroll.withId(id)
    return payroll?.company.id
  }
)
