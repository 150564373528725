import React from 'react'
import PropTypes from 'prop-types'
import { FadeModal as Modal } from 'boron-15'
import { removeTasksURLParameters } from 'utils/query'
import ResizeObserver from 'resize-observer-polyfill'
import { trimString } from '../utils/strings'

let contentStyle = {
  backgroundColor: '$color-gallery'
}

/**
 * !Important:
 * We are applying `ModalComponent.normalize` only in this 'boron/FadeModal' usage,
 * because in this case is most probably our Modal to have non-integer values for width/height.
 * In the rest cases `MessageModal` and `BaseConfirmationModal` its width is always 500px and it works correctly.
 * If we found out there is an issue too there, we can easily reuse this flow.
 * For more details, please refer to: `ModalComponent.normalize`.
 */
class ModalComponent extends React.Component {
  showModal () {
    this.refs.modal.show()
  }

  /**
   * Normalize the Modal transform values to integers,
   * otherwise having non-integers (decimals), the Modal texts get blurry.
   * By default `Modal` component has `transform: "translate3d(-50%, -50%, 0)"` value.
   * But in most of the cases the percentage value `-50%` (width of 101px will result to 50.5)
   * results in non-integer value and the texts get blurry.
   * Here, we will calculate the integer values for the translate matrix manually,
   * instead of using percentages.
   * @credits: https://stackoverflow.com/a/42256897/4312466
   */
  normalize () {
    // Here we need to make sure the modal DOM element exists,
    // because in the case a modal is being hidden, a transition happens and `ResizeObserver` will invoke `normalize`.
    // In such case the modal DOM element may be detached and an error will be thrown.
    // Because of this - we prefer to protect ourself here and to normalize the modal, only if exists.
    if (this.refs && this.refs.modal && this.refs.modal.refs.modal) {
      const ref = this.refs.modal.refs.modal

      // The getComputedStyle() method returns an object containing the values of all CSS properties of an element,
      // after applying active stylesheets and resolving any basic computation those values may contain.
      // @credits: https://developer.mozilla.org/en-US/docs/Web/API/Window/getComputedStyle
      // We invoke it on the modal ref, in order to get access to calculated transform matrix (mx)
      const modalStyle = window.getComputedStyle(ref, null)
      const mx = modalStyle.getPropertyValue('-webkit-transform') ||
          modalStyle.getPropertyValue('-moz-transform') ||
          modalStyle.getPropertyValue('-ms-transform') ||
          modalStyle.getPropertyValue('-o-transform') ||
          modalStyle.getPropertyValue('transform') || false

      // Width and Height of the Modal
      // We typecast the values, because they include `px` suffix
      const width = parseInt(modalStyle.getPropertyValue('width'))
      const height = parseInt(modalStyle.getPropertyValue('height'))

      if (mx) {
        // Normalize the matrix values into a numeric array
        const values = mx.replace(/ |\(|\)|matrix/g, '').split(',')

        // Last two numbers of the matrix are describing the translation to be applied by x and y.
        // Keep in mind the Modal has a default css position of: `top: 50%; left: 50%;`.
        // Therefore, with the below formula we are calculating the needed translation,
        // in order to center the Modal by x and y.
        // Please refer to tx, ty documentation here:
        // @link: https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix()
        values[4] = -(width / 2)
        values[5] = -(height / 2)

        // This is one of the most important steps in the algorithm/normalizing.
        // The matrix should be built by integers.
        // Otherwise, having  non-integer numbers (with decimal point),
        // the Modal texts get blurry and its a known issue:
        // @credits: https://stackoverflow.com/a/42256897/4312466
        for (let v in values) {
          values[v] = Math.ceil(values[v])
        }

        // Update the Modal transform value with the normalized matrix
        this.refs.modal.refs.modal.style.transform = 'matrix(' + values.join() + ')'
      }
    }
  }

  onShow () {
    // Here we need to make sure the modal DOM element exists, because of the following gotcha:
    // In the case we are opening/closing a Modal very quickly (it's not a normal user behaviour),
    // an already opened/showed Modal DOM element may be detached,
    // because of the next quickly triggered action for hiding the Modal.
    // In that case ResizeObserver.observe() will try to observe on a detached DOM element, and will throw an error.
    // Something like this happens:
    // 1. We are opening a Modal.
    // 2. `new ResizeObserver` is initialized and it's ready for start observing the Modal DOM element.
    // 3. But in the same time, we are closing the Modal very quickly and the DOM element is already detached in step 2.
    if (this.refs && this.refs.modal && this.refs.modal.refs.modal) {
      // On Modal show, we're normalizing the Modals transform values
      this.normalize()

      // But here we have a gotcha.
      // If the Modal height (content) is static - everything will be OK.
      // But in the cases (most of the cases) where we have a dynamic Modal height (when we list an API entities),
      // we need to recalculate/normalize the Modal position again.
      // For example - the Notes modal.
      // 1. When we open it the Notes are being fetched and we have an Loader.
      // 2. Once we receive the Notes - we render them and the height gets bigger.
      // Therefore we need to recalculate/recenter the Modal again.
      // That's the reason we observe here for size changes.
      new ResizeObserver(() => this.normalize()).observe(this.refs.modal.refs.modal)
    }
  }

  hideModal () {
    this.refs.modal.hide()
  }
  render () {
    const { onHide, modalHeading, modalSubHeading, children } = this.props

    return <div data-testid={this.props['data-testid']}>
      <Modal
        contentStyle={contentStyle}
        ref='modal'
        onHide={() => {
          removeTasksURLParameters()
          typeof onHide === 'function' && onHide()
        }}
        onShow={() => this.onShow()}
        {...this.props}
      >
        <span onClick={() => this.hideModal()} className='icon--ex' />
        {
          modalHeading
            ? <div className='o-layout'>
              <div className='o-layout__item u-text--center u-1/1'>
                <h2
                  className={
                    `u-text--center
                    u-text--huge
                    u-weight--bold
                    u-word-break-word
                    ${modalSubHeading ? 'u-margin-bottom-small' : ''}`
                  }
                  data-testid={trimString(modalHeading)}
                >
                  {modalHeading}
                </h2>
              </div>
            </div>
            : null
        }
        {
          modalSubHeading
            ? <div className='o-layout'>
              <div className='o-layout__item u-text--center u-1/1'>
                <h3 className='u-text--center u-text--huge u-weight--bold'>{modalSubHeading}</h3>
              </div>
            </div>
            : null
        }
        {children}
      </Modal>
    </div>
  }
}

ModalComponent.defaultProps = {
  cleaURL: true
}

ModalComponent.propTypes = {
  onHide: PropTypes.func,
  modalHeading: PropTypes.string.isRequired,
  modalSubHeading: PropTypes.string,
  children: PropTypes.node,
  'data-testid': PropTypes.string

}

export default ModalComponent
