import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'redux-form'
import SectionHeading from 'components/SectionHeading'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import Form from 'components/form/Form'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CheckboxField from 'components/form/CheckboxField'
import countries from 'redux/config/allCountries'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import { emailValidation, phoneValidation, required } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import { sortByLabel } from 'utils/strings'
import { getTodaysDate } from 'utils/date'
import { isSelectedRoleTypeRestrictedToBeGO } from 'utils/employee'
import ProcessingSSOModal from 'components/ProcessingSSOModal'
import { getSelectionOptions } from 'utils/selectOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { yesNoBoolValuesOptions } from 'redux/config/DropDownOptions/confirmationOptions'
import { buttonActions, modalHeadings, optionLabels, placeholders, status } from 'utils/locales/common.en'
import {
  disclaimers,
  keyUserActionConfirmations,
  keyUserButtonLabels,
  keyUserFieldLabels,
  keyUserFieldOptionLabels,
  keyUserSectionHeadings,
} from 'utils/locales/keyUsers.en'
import { mode2faEmailOption } from 'redux/config/DropDownOptions/mode2faOptions'
import { everybodySSOString } from 'redux/config/ssoAccessStrings'
import { FieldLayout } from 'routes/Companies/routes/Company/routes/RolesRights/components/FieldLayout'
import { useSelector } from 'react-redux'
import { isCotOem } from 'redux/selectors/auth'

const KeyPeopleForm = (props) => {
  const {
    resetForm,
    resetBusinessUnitField,
    workEmail,
    companies,
    businessUnits,
    globalCountries,
    allCurrencies,
    accessAreaCompanies,
    accessAreaBUsOptions,
    keyPeopleRoleTypes,
    hasCredentials,
    resetUserPassword,
    showPaymentCurrency,
    showStartDate,
    mobilePhoneDisabledReason,
    companyDisabledReason,
    isEdit,
    isCreate,
    isFetching,
    isFieldDisabled,
    isMobilePhoneFieldDisabled,
    isCot,
    isPayrollAnalyst,
    isUserGlobalOwner,
    isUserPayrollAdmin,
    receivesNotifications,
    receivesEmailNotifications,
    disableSpecialRightsEdit,
    selected,
    onAccessAreaBUsChange,
    onAccessAreaCompaniesChange,
    onRoleTypeChange,
    onReadOnlyChange,
    onGlobalOwnerChange,
    isSSOEnabled,
    isTenantSSOEnabled,
    defaultSSOEnabledFor,
    employeeId,
    handleSSOclick,
    showUserSSOField,
    showUserSSOButton,
    initialValues,
  } = props
  const { readOnly: isReadOnlySelected, globalOwner: isGlobalOwner, roleType } = selected
  const isCotOemUser = useSelector(isCotOem)

  const [submitErrors, setSubmitErrors] = useState([])
  const [isSSOSubmitting, setIsSSOSubmitting] = useState(false)
  const [updatedSSOStatus, setUpdatedSSOStatus] = useState(props.isSSOEnabled)
  const [keyUserEmail, setKeyUserEmail] = useState(null)
  const confirmUserPasswordReset = useRef()
  const submittingSSOModal = useRef()
  const isSelectedRoleRestrictedToBeGOorWithSpecialRights = roleType && isSelectedRoleTypeRestrictedToBeGO(roleType)
  const isGrayoutState = isReadOnlySelected || isSelectedRoleRestrictedToBeGOorWithSpecialRights || isFieldDisabled
  const disableSpecialRightsField = disableSpecialRightsEdit || isReadOnlySelected || isSelectedRoleRestrictedToBeGOorWithSpecialRights

  const specialRightsDisabledReason = selected.globalOwner && disclaimers.rightsDisabledReason
  /*
  Tenant can be set up for SSO and be set up so that new users are by default set up as SSO logins.
  In that case we should not show the mode2fafield because there is no benefit for the user to see it.
  But on employee edit we have the value isSSOEnabled which comes from the employee object,
  and we should base the visibility on that value. The logic is in this component because it's shared between
  Key User create and Key User edit components.
  To summarize:
  On create we hide it if the tenant is SSO and the defaultSSOEnabledFor is everybody
  On edit and view, we hide it if the user is SSO, or the field is disabled
  */

  const showMode2FaField = () => {
    if (isCreate) {
      return isTenantSSOEnabled ? defaultSSOEnabledFor !== everybodySSOString : true
    }
    if (isSSOEnabled) return false
    if (!hasCredentials) return false

    return !isFieldDisabled
  }

  if (isFetching) return <div>{status.loading}...</div>

  useEffect(() => {
    setIsSSOSubmitting(false)
  }, [initialValues?.isSSOEnabled])

  return (
    <>
      <ProcessingSSOModal isSSOSubmitting={isSSOSubmitting} isUserSSOEnabled={updatedSSOStatus} ref={submittingSSOModal} errors={submitErrors} />

      <Form {...props}>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='firstname'
                label={keyUserFieldLabels.firstName}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                component={InputField}
                type='text'
                formGroupClassName='u-margin-none'
                disabled={isFieldDisabled}
                validate={required}
                data-testid='firstname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='surname'
                label={keyUserFieldLabels.lastName}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                component={InputField}
                type='text'
                formGroupClassName='u-margin-none'
                disabled={isFieldDisabled}
                validate={required}
                data-testid='surname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='title'
                label={keyUserFieldLabels.title}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                data-testid='title'
                options={getSelectionOptions('title')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='location'
                label={keyUserFieldLabels.base}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                options={countries.map((country) => ({
                  value: country.name,
                  label: country.name,
                }))}
                data-testid='location'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='company'
                label={keyUserFieldLabels.company}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={placeholders.select}
                disabled={isEdit}
                disabledReason={companyDisabledReason}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                onChanged={resetBusinessUnitField}
                validate={required}
                data-testid='company-wrapper'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='businessUnit'
                label={keyUserFieldLabels.businessUnit}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                options={businessUnits.map((unit) => ({
                  value: unit.id,
                  label: unit.name,
                }))}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='position'
                label={keyUserFieldLabels.position}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                disabled={isFieldDisabled}
              />
            </div>
          </div>
          {isCot && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='globalOwner'
                  label={keyUserFieldLabels.globalOwner}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName={`u-margin-none ${isReadOnlySelected || isSelectedRoleRestrictedToBeGOorWithSpecialRights ? 'u-half-opacity' : ''}`}
                  placeholder={placeholders.select}
                  disabled={isFieldDisabled || isReadOnlySelected || isSelectedRoleRestrictedToBeGOorWithSpecialRights}
                  options={[
                    { value: true, label: optionLabels.yes },
                    { value: false, label: optionLabels.no },
                  ]}
                  data-testid='globalOwner-wrapper'
                  onChange={(e) => e !== isGlobalOwner && onGlobalOwnerChange(e)}
                />
              </div>
            </div>
          )}
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workEmail'
                label={keyUserFieldLabels.workEmail}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
                disabled={isFieldDisabled}
                data-testid='workEmail'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='officePhone'
                label={keyUserFieldLabels.officePhone}
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isFieldDisabled}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='mobile-phone-wrapper'>
              <Field
                name='mobilePhone'
                label={keyUserFieldLabels.mobilePhone}
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isMobilePhoneFieldDisabled}
                disabledReason={mobilePhoneDisabledReason}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='skype'
                label={keyUserFieldLabels.skype}
                component={InputField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                disabled={isFieldDisabled}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='dateOfBirth'
                label={keyUserFieldLabels.dob}
                component={FieldDatePicker}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                disabled={isFieldDisabled}
                maxDate={getTodaysDate}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='gender'
                label={keyUserFieldLabels.gender}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                options={[
                  { value: 'male', label: keyUserFieldOptionLabels.gender.male },
                  { value: 'female', label: keyUserFieldOptionLabels.gender.female },
                  { value: 'not stated', label: keyUserFieldOptionLabels.gender.notStated },
                  { value: 'other', label: keyUserFieldOptionLabels.gender.other },
                  { value: 'unknown', label: keyUserFieldOptionLabels.gender.unknown },
                ]}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='employeeId'
                label={keyUserFieldLabels.employeeId}
                component={InputField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                disabled={isFieldDisabled}
              />
            </div>
          </div>
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='isEmployee'
                label={keyUserFieldLabels.employeeProfile}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                options={[
                  { value: true, label: optionLabels.yes },
                  { value: false, label: optionLabels.no },
                ]}
                data-testid='is-employee-wrapper'
              />
            </div>
          </div>
          {showPaymentCurrency && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  title='Payment currency is required for creation of employee file.'
                  name='paymentCurrency'
                  label={keyUserFieldLabels.paymentCurrency}
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder={placeholders.select}
                  disabled={isFieldDisabled}
                  options={allCurrencies.map((currency) => ({
                    value: currency.id,
                    label: currency.abbreviature,
                  }))}
                  data-testid='paymentCurrency-wrapper'
                />
              </div>
            </div>
          )}
          {showStartDate && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='startDate'
                  label={keyUserFieldLabels.startDate}
                  component={FieldDatePicker}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={placeholders.select}
                  disabled={isFieldDisabled}
                  data-testid='start-date-wrapper'
                />
              </div>
            </div>
          )}{' '}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='hasCredentials'
                label={keyUserFieldLabels.accountStatus}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                options={[
                  { value: true, label: optionLabels.active },
                  { value: false, label: optionLabels.inactive },
                ]}
                data-testid='hasCredentials-wrapper'
              />
            </div>
          </div>
          {showUserSSOField && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='isSSOEnabled'
                  label={keyUserFieldLabels.sso}
                  component={InputField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={placeholders.select}
                  disabled
                  data-testid='isSSOEnabled'
                />
              </div>
            </div>
          )}
          {hasCredentials && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='username'
                  label={keyUserFieldLabels.username}
                  component={InputField}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  disabled={isFieldDisabled}
                />
              </div>
            </div>
          )}
          {showMode2FaField() && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='mode2fa'
                  label={keyUserFieldLabels.mode2fa}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder={placeholders.select}
                  disabled={isFieldDisabled}
                  options={isCot ? [...getSelectionOptions('mode2fa'), mode2faEmailOption] : getSelectionOptions('mode2fa')}
                  data-testid='mode2fa-wrapper'
                />
              </div>
            </div>
          )}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workflowNotifications'
                label={keyUserFieldLabels.workflowNotifications}
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={isEdit && !isCot && !receivesNotifications && !receivesEmailNotifications ? disclaimers.noAlertsAndEmails : placeholders.select}
                title={disclaimers.workflowNotifications}
                clearable={false}
                options={[
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: true }),
                    label: keyUserFieldOptionLabels.workflowNotifications.systemAndEmail,
                  },
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: false }),
                    label: keyUserFieldOptionLabels.workflowNotifications.system,
                  },
                  ...(isCot
                    ? [
                      {
                        value: JSON.stringify({ receivesNotifications: false, receivesEmailNotifications: false }),
                        label: keyUserFieldOptionLabels.workflowNotifications.noAlertsAndEmails,
                      },
                    ]
                    : []),
                ]}
                data-testid='workflowNotifications-wrapper'
              />
            </div>
          </div>
          {isCot && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='includeInCommunications'
                  label={keyUserFieldLabels.includeInComms}
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  clearable={false}
                  placeholder={placeholders.select}
                  options={yesNoBoolValuesOptions}
                  data-testid='includeInCommunications-wrapper'
                />
              </div>
            </div>
          )}
          {hasCredentials ? (
            <Authorization permissions={['EMPLOYEEUSER_EDIT']}>
              <div className='o-layout__item u-margin-bottom'>
                <div className='c-panel o-grid o-grid--medium ai-center'>
                  {!isSSOEnabled && (
                    <div
                      onClick={() => {
                        confirmUserPasswordReset.current.showModal()
                        setKeyUserEmail({ email: workEmail })
                      }}
                      className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
                       u-padding-right u-margin-small u-margin-right u-margin-left-none'
                      data-testid='reset-user-password'
                    >
                      {keyUserButtonLabels.resetPassword}
                    </div>
                  )}
                  {showUserSSOButton && (
                    <div className='c-panel u-margin-small u-margin-right u-margin-left-none u-padding-none'>
                      <button
                        type='button'
                        onClick={() => {
                          setIsSSOSubmitting(true)
                          submittingSSOModal.current.showModal()
                          handleSSOclick({
                            userId: employeeId,
                            isUserSSOEnabled: !isSSOEnabled,
                          }).then((res) => {
                            if (res.errors) {
                              setIsSSOSubmitting(false)
                              setSubmitErrors(res.errors._error)
                              return
                            }
                            setUpdatedSSOStatus(res.isSSOEnabled)
                            setIsSSOSubmitting(false)
                          })
                        }}
                        className={`c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right ${isSSOSubmitting ? 'pe-none' : ''}`}
                        data-testid='sso-button'
                      >
                        {`${updatedSSOStatus ? keyUserButtonLabels.disable : keyUserButtonLabels.enable} ${keyUserButtonLabels.sso} `}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Authorization>
          ) : null}
        </div>
        <Authorization permissions={['EMPLOYEEUSER_EDIT']}>
          <div className='u-text--center u-padding-top'>
            <SectionHeading text='Access Control' tagFullWidth tagTestId='headingAccessControl' />
          </div>
        </Authorization>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='countries'
                label={keyUserFieldLabels.countries}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent c-custom-select--specific-normal'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                formGroupClassName='u-margin-none'
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                multi
                options={globalCountries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                    abbreviature: country.abbreviature,
                  }))
                  .sort(sortByLabel)}
                onChanged={resetForm}
                validate={required}
                data-testid='countries-wrapper'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='accessAreaCompanies'
                label={keyUserFieldLabels.accessAreas}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent c-custom-select--specific-normal'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                onChanged={onAccessAreaCompaniesChange}
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                multi
                options={accessAreaCompanies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                validate={required}
                data-testid='accessAreaCompanies-wrapper'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='accessAreaBUs'
                label={keyUserFieldLabels.businessUnits}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent c-custom-select--specific-normal'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                onChanged={onAccessAreaBUsChange}
                placeholder={placeholders.select}
                disabled={isFieldDisabled}
                multi
                options={accessAreaBUsOptions}
                validate={required}
                data-testid='accessAreaBUs-wrapper'
              />
            </div>
          </div>
          {!isPayrollAnalyst ? (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='roleType'
                  label={keyUserFieldLabels.role}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent c-custom-select--specific-normal'
                  formGroupClassName='u-margin-none'
                  component={CustomSelectField}
                  placeholder={placeholders.select}
                  disabled={isFieldDisabled}
                  options={keyPeopleRoleTypes.map((roleType) => ({
                    value: roleType.type,
                    label: roleType.name,
                  }))}
                  validate={required}
                  onChanged={onRoleTypeChange}
                  data-testid='roleType-wrapper'
                />
              </div>
            </div>
          ) : null}
          {(isUserGlobalOwner || isUserPayrollAdmin || isCot) && (
            <div className='u-text--center u-padding-top'>
              <SectionHeading text={keyUserSectionHeadings.specialRights} tagFullWidth tagTestId='heading-special-rights' />
            </div>
          )}
          {(isUserGlobalOwner || isUserPayrollAdmin || isCot) && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  type='checkbox'
                  name='readOnly'
                  label={keyUserFieldLabels.readOnly}
                  formGroupClassName={`u-margin-none${isGlobalOwner || isPayrollAnalyst ? ' u-half-opacity' : ''}`}
                  component={CheckboxField}
                  className='control__indicator__input'
                  indicatorClassName='control__indicator'
                  disabled={disableSpecialRightsEdit || isFieldDisabled || isGlobalOwner}
                  disabledReason={specialRightsDisabledReason}
                  data-testid='read-only'
                  onChange={onReadOnlyChange}
                />
              </div>
            </div>
          )}
          {(isUserGlobalOwner || isCot) && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  type='checkbox'
                  name='accessChangeLogReport'
                  label={keyUserFieldLabels.accessChangeLogReport}
                  formGroupClassName={`u-margin-none${isGrayoutState ? ' u-half-opacity' : ''}`}
                  component={CheckboxField}
                  className='control__indicator__input'
                  indicatorClassName='control__indicator'
                  checked={selected.accessChangeLogReport}
                  disabled={isFieldDisabled || disableSpecialRightsField || selected.globalOwner}
                  disabledReason={specialRightsDisabledReason}
                  data-testid='access-change-log-report'
                />
              </div>
            </div>
          )}
          {(isUserGlobalOwner || isCot) && (
            <>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    type='checkbox'
                    name='unlockLockPayrunFile'
                    label={keyUserFieldLabels.lockUnlockPayrunFile}
                    formGroupClassName={`u-margin-none${isGrayoutState || selected.globalOwner ? ' u-half-opacity' : ''}`}
                    component={CheckboxField}
                    className='control__indicator__input'
                    indicatorClassName='control__indicator'
                    checked={selected.unlockLockPayrunFile}
                    disabled={isFieldDisabled || disableSpecialRightsField || selected.globalOwner}
                    data-testid='rights-to-lock-unlock'
                    disabledReason={specialRightsDisabledReason}
                  />
                </div>
              </div>

              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    type='checkbox'
                    name='gtnReImport'
                    label={keyUserFieldLabels.gtnReImport}
                    formGroupClassName={`u-margin-none${isGrayoutState || selected.globalOwner ? ' u-half-opacity' : ''}`}
                    component={CheckboxField}
                    className='control__indicator__input'
                    indicatorClassName='control__indicator'
                    checked={selected.gtnReImport}
                    disabled={isFieldDisabled || disableSpecialRightsField || selected.globalOwner}
                    disabledReason={specialRightsDisabledReason}
                    data-testid='right-to-reimport-gtn-report'
                  />
                </div>
              </div>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    type='checkbox'
                    name='payrollIntegrationsControlCenter'
                    label={keyUserFieldLabels.payrollICC}
                    formGroupClassName={`u-margin-none${isGrayoutState || selected.globalOwner ? ' u-half-opacity' : ''}`}
                    component={CheckboxField}
                    className='control__indicator__input'
                    indicatorClassName='control__indicator'
                    checked={selected.payrollIntegrationsControlCenter}
                    disabled={isFieldDisabled || disableSpecialRightsField || selected.globalOwner}
                    disabledReason={specialRightsDisabledReason}
                    data-testid='right-to-integration-control-center'
                  />
                </div>
              </div>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    type='checkbox'
                    name='allPayrunDocuments'
                    label={keyUserFieldLabels.allPayrunDocuments}
                    formGroupClassName={`u-margin-none${isGrayoutState ? ' u-half-opacity' : ''}`}
                    component={CheckboxField}
                    className='control__indicator__input'
                    indicatorClassName='control__indicator'
                    checked={selected.allPayrunDocuments}
                    disabled={isFieldDisabled || disableSpecialRightsField}
                    disabledReason={specialRightsDisabledReason}
                    data-testid='right-to-all-payrun-documents'
                  />
                </div>
              </div>
              <FieldLayout>
                <Field
                  type='checkbox'
                  name='reportDesigner'
                  label={keyUserFieldLabels.reportDesigner}
                  formGroupClassName={`u-margin-none${isReadOnlySelected || !isCot ? ' u-half-opacity' : ''}`}
                  component={CheckboxField}
                  className='control__indicator__input'
                  indicatorClassName='control__indicator'
                  checked={selected.reportDesigner}
                  disabled={isFieldDisabled || disableSpecialRightsField || !isCot || isCotOemUser}
                  disabledReason={specialRightsDisabledReason}
                  data-testid='right-to-all-payrun-documents'
                />
              </FieldLayout>
            </>
          )}
        </div>
        <Authorization permissions={['EMPLOYEEUSER_EDIT']}>
          <div className='o-layout u-padding'>
            <div className='o-layout__item u-text--center u-1/1'>
              <button
                type='submit'
                className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
            u-padding-right u-margin-top-small'
                data-testid='submit'
              >
                {buttonActions.save}
              </button>
            </div>
          </div>
        </Authorization>
      </Form>

      <ConfirmationModal
        ref={confirmUserPasswordReset}
        className='c-modal'
        modalHeading={modalHeadings.confirmation}
        onConfirm={() => resetUserPassword(keyUserEmail)}
      >
        <p>{keyUserActionConfirmations.confirmResetPwd}</p>
      </ConfirmationModal>
    </>
  )
}

KeyPeopleForm.propTypes = {
  isFetching: PropTypes.func,
  isCot: PropTypes.bool,
  resetForm: PropTypes.func,
  resetBusinessUnitField: PropTypes.func,
  onAccessAreaCompaniesChange: PropTypes.func,
  globalCountries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  isEdit: PropTypes.bool,
  accessAreaCompanies: PropTypes.array,
  accessAreaBUsOptions: PropTypes.array,
  keyPeopleRoleTypes: PropTypes.array,
  businessUnits: PropTypes.array,
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  workEmail: PropTypes.string,
  allCurrencies: PropTypes.array,
  showPaymentCurrency: PropTypes.bool,
  showStartDate: PropTypes.bool,
  onAccessAreaBUsChange: PropTypes.func,
  isPayrollAnalyst: PropTypes.bool,
  isFieldDisabled: PropTypes.bool,
  isMobilePhoneFieldDisabled: PropTypes.bool,
  mobilePhoneDisabledReason: PropTypes.string,
  companyDisabledReason: PropTypes.string,
  receivesNotifications: PropTypes.bool,
  receivesEmailNotifications: PropTypes.bool,
  onRoleTypeChange: PropTypes.func,
  selected: PropTypes.object,
  isUserGlobalOwner: PropTypes.bool,
  disableSpecialRightsEdit: PropTypes.bool,
  isUserPayrollAdmin: PropTypes.bool,
  onReadOnlyChange: PropTypes.func,
  onGlobalOwnerChange: PropTypes.func,
  showUserSSOField: PropTypes.bool,
  showUserSSOButton: PropTypes.bool,
  isSSOEnabled: PropTypes.bool,
  employeeId: PropTypes.number,
  handleSSOclick: PropTypes.func,
  initialValues: PropTypes.object,
}

export default KeyPeopleForm
