import ConfirmationModal from 'components/ConfirmationModal'
import React from 'react'
import { modalHeadings } from 'utils/locales/common.en'
import { gtnValidationMessages } from 'utils/locales/payruns.en'

export const GTNValidationModals = ({
  overrideGtnValidation,
  overrideValidationModalRef,
  shareConfirmationModalRef,
  documentId,
  onConfirmShare,
  onRejectShare,
}) => {
  const onOverrideConfirm = async () => {
    const res = await overrideGtnValidation(documentId)
    if (res.data) shareConfirmationModalRef.current?.showModal()
  }
  return (
    <>
      <ConfirmationModal
        ref={overrideValidationModalRef}
        className='c-modal'
        modalHeading={gtnValidationMessages.override.heading}
        onConfirm={onOverrideConfirm}
      >
        <p>{gtnValidationMessages.override.confirm1}</p>
        <p>{gtnValidationMessages.override.confirm2}</p>
      </ConfirmationModal>

      <ConfirmationModal
        ref={shareConfirmationModalRef}
        className='c-modal'
        modalHeading={modalHeadings.confirmation}
        onConfirm={onConfirmShare}
        onReject={onRejectShare}
      >
        <p>{gtnValidationMessages.share.confirm}</p>
      </ConfirmationModal>
    </>
  )
}
