import { trimString } from 'utils/strings'
import employeeStatusEnum from '../enums/employmentStatuses'

// eslint-disable-next-line no-unused-vars
class EmploymentStatusHelper {
  constructor (employeeStatus = null) {
    this.status = employeeStatus

    this.contractSectionsViewable = [
      trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE),
      trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE),
      trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE),
    ]

    this.contractTabTitleChanges = [
      trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE),
    ]

    this.contractInvoicePaymentCurrency = [
      trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE),
    ]

    this.contractorUniqueId = [
      trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE),
      trimString(employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE),
    ]
  }

  static allStatusOptionsForSelectBox = [
    { value: employeeStatusEnum.LOCAL.VALUE, label: employeeStatusEnum.LOCAL.LABEL },
    { value: employeeStatusEnum.EXPAT.VALUE, label: employeeStatusEnum.EXPAT.LABEL },
    { value: employeeStatusEnum.CONTRACTOR.VALUE, label: employeeStatusEnum.CONTRACTOR.LABEL },
    { value: employeeStatusEnum.CONTRACTOR_INDIVIDUAL.VALUE, label: employeeStatusEnum.CONTRACTOR_INDIVIDUAL.LABEL },
    { value: employeeStatusEnum.CONTRACTOR_COMPANY.VALUE, label: employeeStatusEnum.CONTRACTOR_COMPANY.LABEL },
    { value: employeeStatusEnum.CONTRACTOR_AGENCY.VALUE, label: employeeStatusEnum.CONTRACTOR_AGENCY.LABEL },
    { value: employeeStatusEnum.EMPLOYER_OF_RECORD.VALUE, label: employeeStatusEnum.EMPLOYER_OF_RECORD.LABEL },
    { value: employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE, label: employeeStatusEnum.SHORT_TERM_ASSIGNMENT.LABEL },
    { value: employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE, label: employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.LABEL },
    { value: employeeStatusEnum.SUPPLIER.VALUE, label: employeeStatusEnum.SUPPLIER.LABEL },
    { value: employeeStatusEnum.NON_EMPLOYEE.VALUE, label: employeeStatusEnum.NON_EMPLOYEE.LABEL },
  ]

  /**
   * Getter to determine if the contract area should be shown
   * based on current employment type
   */
  get shouldShowContractArea () {
    if (!this.status) return false
    return this.contractSectionsViewable.includes(trimString(this.status))
  }

  /**
   * Getter to determine if the invoice payment currency is available to show
   * in the pay and taxes area
   */
  get shouldShowInvoicePaymentCurrencyArea () {
    if (!this.status) return false
    return this.contractInvoicePaymentCurrency.includes(trimString(this.status))
  }

  /**
   * Getter to determine if the unique contractor ID is available to show
   * in the job and org area
   */
  get shouldShowUniqueContractorIdArea () {
    if (!this.status) return false
    return this.contractorUniqueId.includes(trimString(this.status))
  }

  /**
   * Getter to determine the section heading that should be shown
   * based on the current employment type
   */
  get sectionHeading () {
    if (!this.status) return null
    if (trimString(this.status) === trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE)) {
      return 'Contractor’s Company Details'
    }
    if (trimString(this.status) === trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE)) {
      return 'Contractor’s Agency Details'
    }
    if (
      trimString(this.status) === trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE) ||
      trimString(this.status) === trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE)
    ) {
      return 'Host Country Address Details'
    }
    return null
  }

  /**
   * Getter to determine if the Employee Personal Tabs
   * should change based on the current employment type
   */
  get isContractorChangeTitleStatus () {
    if (!this.status) return false
    return this.contractTabTitleChanges.includes(trimString(this.status))
  }

  /**
   * Static method to be used to determine if a passed in value is of contractor company type
   * @param {string} status The Current Employment Type Status
   * @returns bool
   */
  static isContractorCompany (status) {
    if (!status) return false
    return trimString(status) === trimString(employeeStatusEnum.CONTRACTOR_COMPANY.VALUE)
  }

  /**
   * Static method to be used to determine if a passed in value is of contractor agency type
   * @param {string} status The Current Employment Type Status
   * @returns bool
   */
  static isContractorAgency = (status) => {
    if (!status) return false
    return trimString(status) === trimString(employeeStatusEnum.CONTRACTOR_AGENCY.VALUE)
  }

  /**
   * Static method to be used to determine if a passed in value is of Short Term Assignment type
   * @param {string} status The Current Employment Type Status
   * @returns bool
   */
  static isShortTermAssignment = (status) => {
    if (!status) return false
    return trimString(status) === trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT.VALUE)
  }

  /**
   * Static method to be used to determine if a passed in value is of Short Term Assignment type
   * @param {string} status The Current Employment Type Status
   * @returns bool
   */
  static isShortTermAssignmentHost = (status) => {
    if (!status) return false
    return trimString(status) === trimString(employeeStatusEnum.SHORT_TERM_ASSIGNMENT_HOST.VALUE)
  }
}

export default EmploymentStatusHelper
