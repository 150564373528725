export const extraEmployeeFieldsConfig = [
  {
    category: 'personal',
    categoryName: 'Personal',
    fields: [
      {
        field: 'addressLineOne',
        name: 'Address line one',
      },
      {
        field: 'addressLineTwo',
        name: 'Address line two',
      },
      {
        field: 'addressLineThree',
        name: 'Address line three',
      },
      {
        field: 'city',
        name: 'City',
      },
      {
        field: 'district',
        name: 'District',
      },
      {
        field: 'postCode',
        name: 'Post code',
      },
      {
        field: 'addressCountry',
        name: 'Address country',
      },
    ],
  },
]

export const employeeFieldsConfig = [
  {
    category: 'personal',
    categoryName: 'Personal',
    fields: [
      {
        field: 'firstname',
        name: 'First name',
      },
      {
        field: 'surname',
        name: 'Surname',
      },
      {
        field: 'otherSurname',
        name: 'Other Surname',
      },
      {
        field: 'title',
        name: 'Title',
      },
      {
        field: 'nationality',
        name: 'Nationality',
      },
      {
        field: 'dateOfBirth',
        name: 'Date of birth',
      },
      {
        field: 'gender',
        name: 'Gender',
      },
      {
        field: 'maritalStatus',
        name: 'Marital status',
      },
      {
        field: 'personalEmail',
        name: 'Personal email',
      },
      {
        field: 'workEmail',
        name: 'Work email',
      },
      {
        field: 'skype',
        name: 'Skype',
      },
      {
        field: 'officePhone',
        name: 'Office phone',
      },
      {
        field: 'mobilePhone',
        name: 'Mobile phone',
      },
      {
        field: 'taxNumber',
        name: 'Tax number',
      },
      {
        field: 'race',
        name: 'Race',
      },
      {
        field: 'nativeFirstNames',
        name: 'Local First Name',
      },
      {
        field: 'nativeFamilyNames',
        name: 'Local Surname',
      },
      {
        field: 'socialInsuranceNumber',
        name: 'Social Insurance Number',
      },
      {
        field: 'socialSecurityId',
        name: 'Social Security Number',
      },
      {
        field: 'idCardNumber',
        name: 'ID Card Number',
      },
      {
        field: 'bpjsKetenagakerjaan',
        name: 'BPJS Ketenagakerjaan',
      },
      {
        field: 'bpjsKesehatan',
        name: 'BPJS Kesehatan',
      },
      {
        field: 'alienIdentificationNumber',
        name: 'Alien Identification Number',
      },
      {
        field: 'epfNumber',
        name: 'EPF Number',
      },
      {
        field: 'permanentAccountNumber',
        name: 'Permanent Account Number (PAN)',
      },
      {
        field: 'universalAccountNumber',
        name: 'Universal Account Number',
      },
      {
        field: 'otherLocalFirstName',
        name: 'Other Local First Name',
      },
      {
        field: 'otherLocalSurname',
        name: 'Other Local Surname',
      },
    ],
  },
  {
    category: 'job-and-org',
    categoryName: 'Job & Org',
    fields: [
      {
        field: 'employeeId',
        name: 'Employee ID',
      },
      {
        field: 'username',
        name: 'Username',
      },
      {
        field: 'position',
        name: 'Position',
      },
      {
        field: 'company',
        name: 'Company',
      },
      {
        field: 'businessUnit',
        name: 'Business unit',
      },
      {
        field: 'department',
        name: 'Department',
      },
      {
        field: 'costCenter',
        name: 'Cost center',
      },
      {
        field: 'subsidiary',
        name: 'Subsidiary',
      },
      {
        field: 'project',
        name: 'Project',
      },
      {
        field: 'hasCredentials',
        name: 'Employee Self-service',
      },
      {
        field: 'companyLink',
        name: 'Company Link',
      },
      {
        field: 'tradeUnion',
        name: 'Trade Union / Work Council',
      },
      {
        field: 'visaWorkPermit',
        name: 'Visa / Work permit',
      },
      {
        field: 'contractorTempAgency',
        name: 'Contractor/Temp Agency',
      },
      {
        field: 'typeOfContract',
        name: 'Type of contract',
      },
      {
        field: 'startDate',
        name: 'Start date',
      },
      {
        field: 'originalHireDate',
        name: 'Original hire date',
      },
      {
        field: 'endDate',
        name: 'End date',
      },
      {
        field: 'officeLocation',
        name: 'Office location',
      },
      {
        field: 'weeklyHours',
        name: 'Weekly hours',
      },
      {
        field: 'monthlyHours',
        name: 'Monthly hours',
      },
      {
        field: 'paymentType',
        name: 'Payment type',
      },
      {
        field: 'hourlyRate',
        name: 'Hourly rate',
      },
      {
        field: 'workType',
        name: 'Full Time / Part Time',
      },
      {
        field: 'occupationalStatusCode',
        name: 'Occupational Status Code',
      },
      {
        field: 'managementPandL',
        name: 'Management P&L',
      },
      {
        field: 'managerId',
        name: 'Manager ID',
      },
      {
        field: 'workLevel',
        name: 'Worklevel',
      },
      {
        field: 'rehire',
        name: 'Rehire',
      },
      {
        field: 'otherEmployeeId',
        name: 'Other Employee ID',
      },
      {
        field: 'identityType',
        name: 'Identity Type',
      },
      {
        field: 'dateIssue',
        name: 'Date Issue',
      },
      {
        field: 'expiryDate',
        name: 'Expiry Date',
      },
      {
        field: 'confirmationDate',
        name: 'Confirmation Date',
      },
      {
        field: 'fundType',
        name: 'Fund Type',
      },
      {
        field: 'cpfType',
        name: 'CPF Type',
      },
      {
        field: 'employmentInsuranceNumber',
        name: 'Employment Insurance Number',
      },
      {
        field: 'residentCardNumber',
        name: 'Resident Card Number',
      },
      {
        field: 'welfarePensionNumber',
        name: 'Welfare Pension Number',
      },
    ],
  },
  {
    category: 'pay-and-taxes',
    categoryName: 'Pay & Taxes',
    fields: [
      {
        field: 'employmentStatus',
        name: 'Type of Employment/Engagement',
      },
      {
        field: 'paymentCurrency',
        name: 'Payment currency',
      },
      {
        field: 'annualSalary',
        name: 'Annual salary',
      },
      {
        field: 'employeePensionContributionsPercentage',
        name: 'Employee pension contributions %',
      },
      {
        field: 'employerPensionContributionsPercentage',
        name: 'Employer pension contributions %',
      },
      {
        field: 'Pay elements',
        name: 'Pay elements',
      },
      {
        field: 'Employer Contributions',
        name: 'Employer contributions',
      },
      {
        field: 'Employee Deductions',
        name: 'Employee deductions',
      },
      {
        field: 'Time and Attendance',
        name: 'Time and attendance',
      },
      {
        field: 'Overtime',
        name: 'Overtime',
      },
      {
        field: 'Employee Net Deductions',
        name: 'Employee net deductions',
      },
    ],
  },
]

export const employeeCustomFieldsConfig = [
  {
    country: 'Germany',
    customFields: [
      {
        name: 'religion',
        printableName: 'Religion',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'birthName',
        printableName: 'Birth Name',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'prefixTitle',
        printableName: 'Prefix Title',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Job & Org',
        showInPayrollInstance: false,
      },
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Job & Org',
        showInPayrollInstance: false,
      },
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'churchTax',
        printableName: 'Church Tax',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'taxClass',
        printableName: 'Tax Class',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'insuranceNumber',
        printableName: 'Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'payScaleGroup',
        printableName: 'Pay Scale Group',
        tab: 'Job & Org',
        showInPayrollInstance: false,
      },
      {
        name: 'payScaleLevel',
        printableName: 'Pay Scale Level',
        tab: 'Job & Org',
        showInPayrollInstance: false,
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
          { value: 'Temporary Help', label: 'Temporary Help' },
          { value: 'Premium Wage', label: 'Premium Wage' },
          { value: 'Other Contractual Relationship', label: 'Other Contractual Relationship' },
          { value: 'Tariff Salary', label: 'Tariff Salary' },
          { value: 'Tariff Salary JCS', label: 'Tariff Salary JCS' },
          { value: 'Tariff Apprenticeship Remuneration', label: 'Tariff Apprenticeship Remuneration' },
          { value: 'Tariff Monthly Payment', label: 'Tariff Monthly Payment' },
          { value: 'Tariff Hourly Payment', label: 'Tariff Hourly Payment' },
          { value: 'Company Remuneration', label: 'Company Remuneration' },
          { value: 'Early Retirement', label: 'Early Retirement' },
          { value: 'Non-Tariff Hourly Payment', label: 'Non-Tariff Hourly Payment' },
          { value: 'Non-Tariff Salary', label: 'Non-Tariff Salary' },
          { value: 'Heritage', label: 'Heritage' },
          { value: 'Fee/Licences', label: 'Fee/Licences' },
          { value: 'Payment by Result', label: 'Payment by Result' },
          { value: 'Pension (minimum contribution base)', label: 'Pension (minimum contribution base)' },
          { value: 'Pension (general contribution determination)', label: 'Pension (general contribution determination)' },
          { value: 'Intern Remuneration', label: 'Intern Remuneration' },
          { value: 'OSTE Bachelor & Master', label: 'OSTE Bachelor & Master' },
          { value: 'OSTE General Manager and Higher', label: 'OSTE General Manager and Higher' },
        ],
      },
    ],
  },
  {
    country: 'United Kingdom',
    customFields: [
      {
        name: 'insuranceNumber',
        printableName: 'Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'taxCode',
        printableName: 'Tax Code',
        tab: 'Personal',
        showInPayrollInstance: true,
      },
      {
        name: 'niCategoryLetter',
        printableName: 'National Insurance Letter',
        tab: 'Personal',
        showInPayrollInstance: true,
        options: ['A', 'B', 'C', 'J', 'H', 'M', 'X', 'Z'],
      },
      {
        name: 'invoicePaymentCurrency',
        printableName: 'Invoice Payment Currency',
        tab: 'Pay & Taxes',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Australia',
    customFields: [
      {
        name: 'hecs',
        printableName: 'HECS',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'pensionBank',
        printableName: 'Pension Bank',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Canada',
    customFields: [
      {
        name: 'dependentLifeInsurance',
        printableName: 'Dependent Life Insurance',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'lifeInsurance',
        printableName: 'Life Insurance',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'accidentalDeathAndDismembermentInsurance',
        printableName: 'Accidental death & dismemberment ins.',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'extendedHealthCare',
        printableName: 'Extended Health Care',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'languagePreferences',
        printableName: 'Language Preferences',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Mexico',
    // Please note, that the same field can be part of two tabs/pages.
    // The requirement was to show the Mexico custom fields in Employee -> Create -> Job & Org tab,
    // while showing the same fields in Employee -> View/Edit -> Personal.
    // Later on - if we have the same case again, we can think about a better config/fields structure,
    // in order to prevent the fields duplication.
    customFields: [
      {
        name: 'federalTaxpayerRegistryCode',
        printableName: 'RFC',
        title: 'Federal Taxpayer Registry Code',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnCreate: true,
      },
      {
        name: 'generalPopulationRegistry',
        printableName: 'CURP',
        title: 'General Population Registry',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnCreate: true,
      },
      {
        name: 'socialSecurityNumber',
        printableName: 'IMSS',
        title: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnCreate: true,
      },
      {
        name: 'federalTaxpayerRegistryCode',
        printableName: 'RFC',
        title: 'Federal Taxpayer Registry Code',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnEditView: true,
      },
      {
        name: 'generalPopulationRegistry',
        printableName: 'CURP',
        title: 'General Population Registry',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnEditView: true,
      },
      {
        name: 'socialSecurityNumber',
        printableName: 'IMSS',
        title: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
        hideOnEditView: true,
      },
    ],
  },
  {
    country: 'Indonesia',
    customFields: [
      {
        name: 'religion',
        printableName: 'Religion',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'bpjsKetenagakerjaan',
        printableName: 'BPJS Ketenagakerjaan',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'bpjsKesehatan',
        printableName: 'BPJS Kesehatan',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Costa Rica',
    customFields: [
      {
        name: 'numberOfChildren',
        printableName: 'Number of Children',
        tab: 'Personal',
        showInPayrollInstance: false,
        type: 'number',
        min: '0',
      },
    ],
  },
  {
    country: 'China',
    customFields: [
      {
        name: 'nativeFirstNames',
        printableName: 'Local First Name',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'nativeFamilyNames',
        printableName: 'Local Surname',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'citizenship',
        printableName: 'Citizenship',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Citizen', label: 'Citizen' },
          { value: 'Non-citizen', label: 'Non-citizen' },
        ],
      },
    ],
  },
  {
    country: 'Singapore',
    customFields: [
      {
        name: 'religion',
        printableName: 'Religion',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'identityType',
        printableName: 'Identity Type',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'NRIC (Blue)', label: 'NRIC (Blue)' },
          { value: 'NRIC (Pink)', label: 'NRIC (Pink)' },
          { value: 'Employment Pass', label: 'Employment Pass' },
          { value: 'S Pass', label: 'S Pass' },
          { value: 'Work Permit', label: 'Work Permit' },
        ],
      },
      {
        name: 'alienIdentificationNumber',
        printableName: 'Alien Identification Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'dateIssue',
        printableName: 'Date Issue',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'date',
      },
      {
        name: 'expiryDate',
        printableName: 'Expiry Date',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'date',
      },
      {
        name: 'race',
        printableName: 'Race',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Chinese', label: 'Chinese' },
          { value: 'Indian', label: 'Indian' },
          { value: 'Malay', label: 'Malay' },
          { value: 'Eurasian', label: 'Eurasian' },
          { value: 'Caucasian', label: 'Caucasian' },
          { value: 'Other', label: 'Other' },
        ],
      },
      {
        name: 'citizenship',
        printableName: 'Citizenship',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Singapore Permanent Resident', label: 'Singapore Permanent Resident' },
          { value: 'Foreigner', label: 'Foreigner' },
          { value: 'Permanent Resident', label: 'Permanent Resident' },
          { value: 'Citizen', label: 'Citizen' },
          { value: 'Non-citizen', label: 'Non-citizen' },
        ],
      },
      {
        name: 'numberOfChildren',
        printableName: 'Number of Children',
        tab: 'Personal',
        showInPayrollInstance: false,
        type: 'number',
        min: '0',
      },
      {
        name: 'confirmationDate',
        printableName: 'Confirmation Date',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'date',
      },
      {
        name: 'fundType',
        printableName: 'Fund Type',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'CDAC', label: 'CDAC' },
          { value: 'MBMF', label: 'MBMF' },
          { value: 'SINDA', label: 'SINDA' },
          { value: 'ECF', label: 'ECF' },
          { value: 'Not Applicable', label: 'Not Applicable' },
        ],
      },
      {
        name: 'cpfType',
        printableName: 'CPF Type',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Local', label: 'Local' },
          { value: 'PR', label: 'PR' },
          { value: 'Singaporean', label: 'Singaporean' },
          { value: 'Dependant', label: 'Dependant' },
          { value: 'Employment Pass', label: 'Employment Pass' },
          { value: 'Workpermit/SPASS', label: 'Workpermit/SPASS' },
          { value: 'Permanent Resident', label: 'Permanent Resident' },
          { value: 'Permanent Resident(Graduated/Full Rates)', label: 'Permanent Resident(Graduated/Full Rates)' },
          { value: 'Vacational Trainee', label: 'Vacational Trainee' },
          { value: 'Not Applicable', label: 'Not Applicable' },
        ],
      },
      {
        name: 'workWeekGroup',
        printableName: 'Work Week Group',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: '5 Days', label: '5 Days' },
          { value: '6 Days', label: '6 Days' },
        ],
      },
      {
        name: 'irasType',
        printableName: 'IRAS Type',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'NRIC', label: 'NRIC' },
          { value: 'FIN', label: 'FIN' },
          { value: 'Non Application for SG TAX', label: 'Non Application for SG TAX' },
        ],
      },
      {
        name: 'workAuthorizationType',
        printableName: 'Work Authorization Type',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Employment Pass', label: 'Employment Pass' },
          { value: 'Permanent Resident', label: 'Permanent Resident' },
          { value: 'Work Permit', label: 'Work Permit' },
        ],
      },
      {
        name: 'fwlSpassCode',
        printableName: 'FWL/SPASS Code',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Basic Tier Skilled', label: 'Basic Tier Skilled' },
          { value: 'SPASS Basic Tier', label: 'SPASS Basic Tier' },
        ],
      },
    ],
  },
  {
    country: 'Hungary',
    customFields: [
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'socialSecurityTAJNumber',
        printableName: 'Social Security (TAJ) Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'India',
    customFields: [
      {
        name: 'epfNumber',
        printableName: 'EPF Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'permanentAccountNumber',
        printableName: 'Permanent Account Number (PAN)',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'universalAccountNumber',
        printableName: 'Universal Account Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Ireland',
    customFields: [
      {
        name: 'niCategoryLetter',
        printableName: 'National Insurance Letter',
        tab: 'Personal',
        showInPayrollInstance: true,
        options: ['A', 'A1', 'B', 'C', 'E', 'J', 'H', 'M', 'X', 'Z'],
      },
    ],
  },
  {
    country: 'Uganda',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Senegal',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Ghana',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Ivory Coast',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Spain',
    customFields: [
      {
        name: 'alienIdentificationNumber',
        printableName: 'Alien Identification Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Thailand',
    customFields: [
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Denmark',
    customFields: [
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
        ],
      },
    ],
  },
  {
    country: 'Netherlands',
    customFields: [
      {
        name: 'suffix',
        printableName: 'Suffix',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'I', label: 'I' },
          { value: 'II', label: 'II' },
          { value: 'III', label: 'III' },
          { value: 'JD', label: 'JD' },
          { value: 'JR', label: 'JR' },
          { value: 'MD', label: 'MD' },
          { value: 'PHD', label: 'PHD' },
          { value: 'SR', label: 'SR' },
        ],
      },
      {
        name: 'initials',
        printableName: 'Initials',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'prefixTitle',
        printableName: 'Prefix Title',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
        ],
      },
      {
        name: 'invoicePaymentCurrency',
        printableName: 'Invoice Payment Currency',
        tab: 'Pay & Taxes',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Sweden',
    customFields: [
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
        ],
      },
      {
        name: 'invoicePaymentCurrency',
        printableName: 'Invoice Payment Currency',
        tab: 'Pay & Taxes',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Norway',
    customFields: [
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
        ],
      },
    ],
  },
  {
    country: 'Finland',
    customFields: [
      {
        name: 'gender',
        printableName: 'Gender',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'other', label: 'Other' },
          { value: 'unknown', label: 'Unknown' },
        ],
      },
      {
        name: 'typeOfContract',
        printableName: 'Type of contract',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Unlimited', label: 'Unlimited' },
          { value: 'Job-Creating Program', label: 'Job-Creating Program' },
          { value: 'Occupational Training', label: 'Occupational Training' },
          { value: 'Tenured', label: 'Tenured' },
          { value: 'Tenure Eligible', label: 'Tenure Eligible' },
          { value: 'Non Tenured', label: 'Non Tenured' },
        ],
      },
    ],
  },
  {
    country: 'Brazil',
    customFields: [
      {
        name: 'rgNumber',
        printableName: 'General Registration (RG) Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'nci',
        printableName: 'National Civil Identification (NCI)',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'crnm',
        printableName: 'National Migration Registration Card (CRNM)',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'voterRegistrationNumber',
        printableName: 'Voter Registration Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'ctpsNumber',
        printableName: 'CTPS Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'pisNumber',
        printableName: 'PIS Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Belgium',
    customFields: [
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'insuranceNumber',
        printableName: 'Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'United Arab Emirates',
    customFields: [
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'age',
        printableName: 'Age',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'alienIdentificationNumber',
        printableName: 'Alien Identification Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'invoicePaymentCurrency',
        printableName: 'Invoice Payment Currency',
        tab: 'Pay & Taxes',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'France',
    customFields: [
      {
        name: 'collectiveBargainingFrance',
        printableName: 'Collective Bargaining France',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Cadre 10', label: 'Cadre 10' },
          { value: 'Cadre 100', label: 'Cadre 100' },
          { value: 'Cadre 105', label: 'Cadre 105' },
          { value: 'Cadre 115', label: 'Cadre 115' },
          { value: 'Cadre 130', label: 'Cadre 130' },
          { value: 'Cadre 150', label: 'Cadre 150' },
          { value: 'Cadre 170', label: 'Cadre 170' },
          { value: 'Cadre 210', label: 'Cadre 210' },
          { value: 'Cadre 270', label: 'Cadre 270' },
          { value: 'Cadre 95', label: 'Cadre 95' },
          { value: 'ETAM 200', label: 'ETAM 200' },
          { value: 'ETAM 210', label: 'ETAM 210' },
          { value: 'ETAM 220', label: 'ETAM 220' },
          { value: 'ETAM 230', label: 'ETAM 230' },
          { value: 'ETAM 240', label: 'ETAM 240' },
          { value: 'ETAM 250', label: 'ETAM 250' },
          { value: 'ETAM 275', label: 'ETAM 275' },
          { value: 'ETAM 310', label: 'ETAM 310' },
          { value: 'ETAM 355', label: 'ETAM 355' },
          { value: 'ETAM 400', label: 'ETAM 400' },
          { value: 'ETAM 450', label: 'ETAM 450' },
          { value: 'ETAM 500', label: 'ETAM 500' },
          { value: 'Other', label: 'Other' },
        ],
      },
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'invoicePaymentCurrency',
        printableName: 'Invoice Payment Currency',
        tab: 'Pay & Taxes',
        showInPayrollInstance: false,
      },
    ],
  },

  {
    country: 'Italy',
    customFields: [
      {
        name: 'citizenship',
        printableName: 'Citizenship',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Citizen', label: 'Citizen' },
          { value: 'Non-citizen', label: 'Non-citizen' },
        ],
      },
      {
        name: 'collectiveBargainingItaly',
        printableName: 'Collective Bargaining Italy',
        tab: 'Job & Org',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Dirigente', label: 'Dirigente' },
          { value: 'Employee', label: 'Employee' },
          { value: 'Quadro', label: 'Quadro' },
          { value: 'Other', label: 'Other' },
        ],
      },
    ],
  },
  {
    country: 'Japan',
    customFields: [
      {
        name: 'nativeFirstNames',
        printableName: 'Local First Name',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'nativeFamilyNames',
        printableName: 'Local Surname',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'otherLocalSurname',
        printableName: 'Other Local Surname',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'otherLocalFirstName',
        printableName: 'Other Local First Name',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'employmentInsuranceNumber',
        printableName: 'Employment Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'residentCardNumber',
        printableName: 'Resident Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'welfarePensionNumber',
        printableName: 'Welfare Pension Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Israel',
    customFields: [
      {
        name: 'citizenship',
        printableName: 'Citizenship',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Citizen', label: 'Citizen' },
          { value: 'Non-citizen', label: 'Non-citizen' },
        ],
      },
    ],
  },
  {
    country: 'Austria',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Switzerland',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'zemisNumber',
        printableName: 'ZEMIS Number',
        tab: 'Personal',
        showInPayrollInstance: true,
      },
      {
        name: 'foreignerCode',
        printableName: 'Foreigner code',
        tab: 'Personal',
        showInPayrollInstance: true,
        component: 'dropdown',
        options: [
          { value: 'Temporary resident (B)', label: 'Temporary resident (B)' },
          { value: 'Permanent resident (C)', label: 'Permanent resident (C)' },
          { value: 'Gainfully employed spouse and diplomat (Ci)', label: 'Gainfully employed spouse and diplomat (Ci)' },
          { value: 'Provisionally admitted (F)', label: 'Provisionally admitted (F)' },
          { value: 'Cross-border commuter (G)', label: 'Cross-border commuter (G)' },
          { value: 'Short-term resident (L)', label: 'Short-term resident (L)' },
        ],
      },
    ],
  },
  {
    country: 'State of Qatar',
    customFields: [
      {
        name: 'socialInsuranceNumber',
        printableName: 'Social Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Portugal',
    customFields: [
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Poland',
    customFields: [
      {
        name: 'idCardNumber',
        printableName: 'ID Card Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Russian Federation',
    customFields: [
      {
        name: 'insuranceNumber',
        printableName: 'Insurance Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Malaysia',
    customFields: [
      {
        name: 'asbMembershipNumber',
        printableName: 'ASB Membership Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'nationalIdentityNumberNew',
        printableName: 'National Identity Number: New',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'nationalIdentityNumberOld',
        printableName: 'National Identity Number: Old',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'pnbNumber',
        printableName: 'PNB Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'providentFundNumber',
        printableName: 'Provident Fund Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'tabungHajiNumber',
        printableName: 'Tabung Haji Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
      {
        name: 'race',
        printableName: 'Race',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Chinese', label: 'Chinese' },
          { value: 'Indian', label: 'Indian' },
          { value: 'Malay', label: 'Malay' },
          { value: 'Eurasian', label: 'Eurasian' },
          { value: 'Caucasian', label: 'Caucasian' },
          { value: 'Other', label: 'Other' },
        ],
      },
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Korea, Republic of',
    customFields: [
      {
        name: 'citizenship',
        printableName: 'Citizenship',
        tab: 'Personal',
        showInPayrollInstance: false,
        component: 'dropdown',
        options: [
          { value: 'Citizen', label: 'Citizen' },
          { value: 'Non-citizen', label: 'Non-citizen' },
        ],
      },
      {
        name: 'alienIdentificationNumber',
        printableName: 'Alien Identification Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
  {
    country: 'Lithuania',
    customFields: [
      {
        name: 'socialSecurityId',
        printableName: 'Social Security Number',
        tab: 'Personal',
        showInPayrollInstance: false,
      },
    ],
  },
]
