import React from 'react'
import SectionHeading from 'components/SectionHeading'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'

const ServiceCreate = ({ handleSubmit, pristine, submitting, types }) => {
  return (
    <div>
      <SectionHeading text='Create services' />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <form onSubmit={handleSubmit}>
            <div className='o-form-group'>
              <Field
                name='name'
                label='Name'
                component={InputField}
                type='text'
                className='c-input'
                labelClassName='c-label'
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='type'
                label='Type'
                component={CustomSelectField}
                type='text'
                labelClassName='c-label'
                placeholder='Select type'
                clearable={false}
                options={types.map(type => ({
                  value: type,
                  label: type
                }))}
              />
            </div>
            <div className='u-padding-top-small text-center'>
              <button
                type='submit'
                disabled={pristine || submitting}
                className='c-btn c-btn--small c-btn--curious u-margin-right-small'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'service', // a unique identifier for this form
})(ServiceCreate)
