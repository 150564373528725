import createSelector from 'utils/createSelector'
import _ from 'lodash'

const getPayrollInstanceTaskId = (state, props) => parseInt(props.payrollInstanceTaskId)

export const getPayrollInstanceTaskOwnerScopesByPITaskId = createSelector(getPayrollInstanceTaskId,
  ({ PayrollInstanceTask, BusinessUnit }, taskId) => {
    const task = PayrollInstanceTask.withId(taskId)

    return _.flatten(
      task.payrollInstanceTaskOwners.toModelArray().map(pito => {
        return pito.payrollInstanceTaskOwnerScopes.toModelArray().map(scope => ({
          ...scope.ref,
          key: buildKey(pito.id),
          owner: pito.owner.getName(),
          status: pito.status,
          pitoId: pito.id,
          businessUnit: BusinessUnit.withId(scope.taskableId).ref.name,
          isEditable: pito.isEditable
        }))
      }))
  })

export const getDisabledPayrollInstanceTaskOwnersByPITaskId = createSelector(
  getPayrollInstanceTaskOwnerScopesByPITaskId,
  (session, scopes) => scopes.filter(scope => !scope.isEditable).map(scope => ({ id: scope.pitoId })))

const buildKey = id => JSON.stringify({ id })

export const getInitials = createSelector(getPayrollInstanceTaskOwnerScopesByPITaskId,
  (session, scopes) => scopes.reduce((initials, item) => {
    const key = buildKey(item.pitoId)

    initials[key] = item.status
    return initials
  }, {}))
