import createSelector from 'utils/createSelector'
import { _getFieldsByBankAccountCountryAbbrAndCurrency } from 'redux/selectors/bankAccountTypes'
import countries from 'redux/config/allCountries'
import { _getCountryNameByAbbr } from 'redux/selectors/country'
import _ from 'lodash'

const getCompanyId = (state, props) => parseInt(props.companyId)
const getEmployeeId = (state, props) => parseInt(props.employeeId)
const getBankAccountId = (state, props) => parseInt(props.bankAccountId)

export const getBankAccounts = createSelector(({ BankAccount, BankAccountType }) => {
  return BankAccount.all()
    .filter({ employee: null })
    .toModelArray()
    .map(account => getBankAccountData(account, BankAccountType))
}
)

export const getBankAccountsByCompany = createSelector(getCompanyId,
  ({ BankAccount, BankAccountType }, companyId) => {
    return BankAccount
      .filter({ company: companyId, employee: null })
      .toModelArray()
      .map(account => getBankAccountData(account, BankAccountType))
  }
)

export const getBankAccountsByEmployee = createSelector(getEmployeeId,
  ({ BankAccount, BankAccountType }, employeeId) => {
    return BankAccount
      .filter({ employee: employeeId })
      .toModelArray()
      .map(account => getBankAccountData(account, BankAccountType))
  }
)

export const getBankAccountById = createSelector(getBankAccountId,
  ({ BankAccount, BankAccountType }, bankAccountId) => {
    const account = BankAccount.withId(bankAccountId)
    if (!account) return {}
    return getBankAccountData(account, BankAccountType)
  }
)

/**
 * Push account to correct format
 * @param {} account
 * @return {}
 */
const getBankAccountData = (account, bankAccountType) => {
  return {
    ...account,
    id: account.id,
    country: account.company.country.id,
    company: account.company.id,
    bankCountry: account.ref.bankCountry,
    bankCountryName: _getCountryNameByAbbr(countries, account.ref.bankCountry),
    currency: account.currency ? account.currency.id : null,
    currencyAbbr: account.currency.abbreviature,
    customFields: _.flatten(_getFieldsByBankAccountCountryAbbrAndCurrency(bankAccountType, account.ref.bankCountry, account.currency.abbreviature))
  }
}

export const getBankAccountCustomFieldsInitials = createSelector(getBankAccountById,
  (session, bankAccount) => {
    let formInitials = {}

    bankAccount.customFields.forEach(field => {
      const fieldName = field.name
      formInitials[fieldName] = bankAccount[fieldName]
    })

    return formInitials
  })
