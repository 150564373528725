import fieldSubCategoriesEnum from 'utils/enums/employees/fieldSubCategoriesEnum'
import fieldComponentsEnum from 'utils/enums/fieldComponentsEnum'
import { employeeAddressType } from './DropDownOptions/addressTypeOptions'
import { allCountriesOptions } from './DropDownOptions/allCountriesOptions'
import { yesNoBoolValuesOptions } from './DropDownOptions/confirmationOptions'

const addressFields = [
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'isPrimary',
    name: 'Primary Address',
    component: fieldComponentsEnum.DROPDOWN,
    options: yesNoBoolValuesOptions
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'type',
    name: 'Type',
    component: fieldComponentsEnum.DROPDOWN,
    options: employeeAddressType
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine1',
    name: 'Address Line One',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine2',
    name: 'Address Line Two',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'addressLine3',
    name: 'Address Line Three',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'city',
    name: 'City / Town',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'district',
    name: 'County / Borough / District',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'zipCode',
    name: 'Post Code',
    component: fieldComponentsEnum.INPUT
  },
  {
    subCategory: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
    field: 'country',
    name: 'Address Country',
    component: fieldComponentsEnum.DROPDOWN,
    options: allCountriesOptions
  },
]

export default addressFields
