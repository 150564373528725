const fieldSubCategoriesEnum = {
  CONTACT_DETAILS: 'contactDetails',
  PERSONAL_DETAILS: 'personalDetails',
  BIOGRAPHICAL_DETAILS: 'biographicalDetails',
  TAX_DETAILS: 'taxDetails',
  BANK_DETAILS: 'bankDetails',
  EMPLOYEE_ADDRESS_DETAILS: 'employeeUsersAddressDetails',
  EMERGENCY_PERSON_DETAILS: 'emergencyPersonDetails',
  CONTRACTOR_DETAILS: 'contractorDetails',
  JOB_PROFILE: 'jobProfile',
  ORGANIZATION_DETAILS: 'organizationDetails',
  EMPLOYEE_SELF_SERVICE: 'employeeSelfService',
  EMPLOYMENT_DETAILS: 'employmentDetails'
}

export default fieldSubCategoriesEnum
