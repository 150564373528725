import React from 'react'
import SectionHeading from 'components/SectionHeading'
import FormFields from './FormFields'

const SubCategoryCreate = ({ ...props }) => {
  return (
    <div>
      <SectionHeading text='Create Subcategories' />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <FormFields {...props} />
        </div>
      </div>
    </div>
  )
}

export default SubCategoryCreate
