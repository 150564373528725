import _ from 'lodash'

/**
 * Compile template passed in params
 *
 * Please refer to lodash `_.template` doc:
 * @link https://lodash.com/docs/#template
 *
 * @param {String} template
 * @param {Object} params
 * @returns {*}
 */
export const compile = (template, params) => {
  let compiled = _.template(template)

  return compiled({ ...params })
}

/**
 * Get URI by template and id
 *
 * @param {String} template - Example `/payruns/active/${id}/instance`
 * @param {Number} id - `id` that will be set in the above templated
 */
export const getCompiledUri = (template, id) => compile(template, { id })
