import React from 'react'
import ServiceVendorsContainer from '../containers/ServiceVendorsContainer'
import AssignCountryServicesContainer from '../containers/AssignCountryServicesContainer'
import AddVendorToServiceContainer from '../containers/AddVendorToServiceContainer'
import Modal from 'components/Modal'
import _ from 'lodash'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
    this.state = {
      modals: {
        showAssignCountryServicesModal: false,
        showVendorServiceForm: false,
      },
      companyId: 0,
      countryServiceId: 0,
      companyCountryServiceId: 0
    }
  }

  showModal (modal, params) {
    let modals = {}

    _.forOwn(this.state.modals, (value, key) => {
      if (key === modal) {
        modals[key] = true
      } else {
        modals[key] = false
      }
    })

    this.setState({
      modals,
      ...params
    })

    this.refs.routeModal.showModal()
  }

  hideModal () {
    this.refs.routeModal.hideModal()

    let modals = {}

    _.forOwn(this.state.modals, (value, key) => {
      modals[key] = false
    })

    this.setState({
      modals
    })
  }

  render () {
    return <div>
      <ServiceVendorsContainer
        onOpenAssign={params => this.showModal.bind(this, 'showAssignCountryServicesModal', params)}
        onOpenAddVendor={params => this.showModal.bind(this, 'showVendorServiceForm', params)}
        {...this.props}
      />

      <Modal
        ref='routeModal'
        className='c-modal'
        modalHeading={this.state.modals.showVendorServiceForm ? 'Assign vendor to service' : 'Assign services to company'}
      >
        {
          this.state.modals.showAssignCountryServicesModal
            ? <AssignCountryServicesContainer companyId={this.state.companyId} onSuccess={this.hideModal} {...this.props} />
            : ''
        }
        {
          this.state.modals.showVendorServiceForm
            // eslint-disable-next-line max-len
            ? <AddVendorToServiceContainer countryServiceId={this.state.countryServiceId} companyCountryServiceId={this.state.companyCountryServiceId} onSuccess={this.hideModal} {...this.props} />
            : ''
        }
      </Modal>
    </div>
  }
}

export default RouteView
