import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import TextareaField from 'components/form/TextareaField'
import CheckboxField from 'components/form/CheckboxField'
import { required } from 'utils/validations'
import CheckboxOption from 'components/form/select/CheckboxOption'
import PropTypes from 'prop-types'

const DocumentClassificationFormEdit = (props) => {
  const { hasDocumentClassification, documentTypes, isCot, showSubmitBtn, documenTypeCannotBeChanged, ...rest } = props

  return (
    <div className='o-layout'>
      <Form {...rest}>
        <div className='o-layout__item u-margin-bottom u-1/1 u-1/1@tablet u-1/1@desktop'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='description'
              label='Description'
              component={TextareaField}
              type='text'
              rows='3'
              className='c-textarea c-textarea--transparent c-textarea--no-resize'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
            />
          </div>
        </div>
        {hasDocumentClassification && (
          <>
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/1@tablet u-1/1@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='documentTypes'
                  label='Document Type *'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  options={documentTypes.map((type) => ({ value: type.id, label: type.text }))}
                  validate={required}
                  multi
                  data-testid='documentTypes-wrapper'
                  disabled={documenTypeCannotBeChanged}
                />
              </div>
            </div>
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/1@tablet u-1/1@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='version'
                  label='Version *'
                  labelClassName='c-label'
                  component={InputField}
                  type='number'
                  validate={required}
                  data-testid='version'
                  disabled={documenTypeCannotBeChanged}
                />
              </div>
            </div>
            {isCot && (
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/1@tablet u-1/1@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    name='readyForIntegration'
                    type='checkbox'
                    component={CheckboxField}
                    className='control__indicator__input'
                    label='Ready for Integration'
                  />
                </div>
              </div>
            )}
          </>
        )}
        {(hasDocumentClassification ? showSubmitBtn : true) && (
          <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
            <button
              type='submit'
              className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
              data-testid='submit-button'
            >
              Save
            </button>
          </div>
        )}
      </Form>
    </div>
  )
}

DocumentClassificationFormEdit.propTypes = {
  hasDocumentClassification: PropTypes.bool,
  documentTypes: PropTypes.array,
  isCot: PropTypes.bool,
  showSubmitBtn: PropTypes.bool,
  documenTypeCannotBeChanged: PropTypes.bool,
}

export default DocumentClassificationFormEdit
