import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/steps'

class Step extends CRUDModel {}
Step.modelName = 'Step'
Step.actionTypes = actionTypes

Step.fields = {
  id: attr(),
  process: fk('Process', 'steps')
}

export default Step
