import React from 'react'
import Flag from 'components/Flag'
import PropTypes from 'prop-types'

export const PayrunHeader = (props) => {
  const { payrun } = props

  return (
    <div className='o-media o-grid o-grid--middle'>
      <div className='o-media__img o-media__img--circle u-margin-right-small line-height--20'>
        <Flag flag={payrun.countryAbbr.toLowerCase()} title={payrun.countryName} size='medium' />
      </div>
      <div className='o-media__body line-height--20'>
        <span className='u-weight--bold u-text--large'>
          {payrun.companyName}
        </span>
        <span className='u-weight--regular'>
          {` / ${payrun.payrollName} / ${payrun.period}`}
        </span>
      </div>
    </div>
  )
}

PayrunHeader.propTypes = {
  payrun: PropTypes.object
}

export default PayrunHeader
