import React from 'react'
import Loader from 'components/Loader'
import StaticEmployees from 'components/employees/StaticEmployees'
import Pagination from 'utils/helperClasses/Pagination'
import PropTypes from 'prop-types'

const EmployeesSearchList = (props) => {
  const { isFetching } = props
  return (
    <>
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>
          <Pagination {...props} />
        </div>
      </div>
      {isFetching ? <Loader /> : <StaticEmployees {...props} />}
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>
          <Pagination {...props} />
        </div>
      </div>
    </>
  )
}

EmployeesSearchList.propTypes = {
  isFetching: PropTypes.bool
}

export default EmployeesSearchList
