import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ConfirmationModal from 'components/ConfirmationModal'
import MobileDetailsView from 'components/MobileDetailsView'
import Modal from 'components/Modal'
import AccordionContainer from 'containers/AccordionContainer'
import BankAccountFormCreateContainer from 'containers/BankAccountFormCreateContainer'
import BankAccountFormEditContainer from 'containers/BankAccountFormEditContainer'
import { Authorization } from 'containers/Authorization'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import { extractKeyValuePairFromArray, getFieldsBySection, renderTableFields } from 'utils/employee'
import PayslipTable from './table/PayslipTable'

function BankAccountView (props) {
  const {
    isFetching,
    bankAccounts,
    deleteBankAccount,
    showText,
    isEmployeeOnly,
    employee,
    companies,
    countries
  } = props
  const [bankAccountId, setBankAccountId] = React.useState(null)

  const modalCreateBankAccount = useRef(null)
  const modalEditBankAccount = useRef(null)
  const confirmModal = useRef(null)

  const getEmployeePersonalFields = (account) => getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'bankDetails',
    account,
    companies,
    countries
  })

  const mapAccountsDataToFields = (account, actionsButtons) => {
    let result = []
    if (actionsButtons) {
      result.push({
        name: account.bankCountryName,
        value: actionsButtons
      })
    }

    getEmployeePersonalFields(account).forEach(field => {
      if (field.field === 'payrollCountry') return
      if (field.field === 'company') return
      if (field.field === 'bankCountry') {
        result.push({
          ...field,
          value: account.bankCountryName
        })
      } else if (field.field === 'currency') {
        result.push({
          ...field,
          value: account.currencyAbbr
        })
      } else if (field.field === 'primaryBankAccount') {
        result.push({
          ...field,
          value: field.options.find(option => option.value === account.primaryBankAccount).label || 'No'
        })
      } else {
        result.push({
          ...field,
          value: account[field.field] || ' '
        })
      }
    })
    if (account.customFields) {
      account.customFields.forEach(field => {
        result.push({
          ...field,
          name: field.printable_name,
          value: account[field.name] || ' '
        })
      })
    }
    return result
  }

  const AddBtnMessage = ({ text, btnClasses }) => <span className='u-bg--white'>
    {text}
    <button onClick={() => modalCreateBankAccount.current.showModal()}
      className={`c-btn c-btn--curious u-margin-left-tiny ${btnClasses}`}
      data-testid='bank-account-create'
    >
      <span className='icon icon--plus' />
    </button>
  </span>

  const hasBankAccounts = bankAccounts?.length > 0
  const lastBankAccountIndex = bankAccounts?.length - 1

  if (isFetching) return <div>Loading...</div>

  return <>
    <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
      {showText && !hasBankAccounts ? (
        <Authorization
          permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}
        >
          <div className='u-text--center u-text--normal u-border-bottom--mischka
            u-bg--white u-margin-bottom u-padding-bottom-small'>
            <h6 className='u-text--large line-height--49 u-weight--bold u-bg--sauve
            rounded-t u-margin-bottom-small'>Bank Account Details</h6>
            <div className='u-weight--regular u-bg--white'>
              <AddBtnMessage text='Add Bank Account' btnClasses='c-btn--tiny' />
            </div>
          </div>
        </Authorization>
      ) : null}
      <div data-testid='bank-accounts'>
        {!isFetching &&
          bankAccounts.map((account, i) => {
            const actionsButtons = (
              <div className='o-layout__item' >
                <div className='u-float--right'>
                  <Authorization permissions={['BANKACCOUNT_EDIT']}>
                    <div
                      onClick={() => {
                        setBankAccountId(account.id)
                        modalEditBankAccount.current.showModal()
                      }}
                      className='c-btn c-btn--tiny c-btn--curious
                        u-padding-left-small u-padding-right-small u-margin-left-tiny'
                      title='Edit'
                      data-testid='bank-account-edit'
                    >
                      <span className='icon icon--edit' />
                    </div>
                  </Authorization>

                  <Authorization permissions={['BANKACCOUNT_DELETE']}>
                    <div
                      onClick={() => {
                        setBankAccountId(account.id)
                        confirmModal.current.showModal()
                      }}
                      className='c-btn c-btn--tiny c-btn--curious
                        u-padding-left-small u-padding-right-small u-margin-left-tiny'
                      title='Delete'
                      data-testid='bank-account-delete'
                    >
                      <span className='icon icon--trash' />
                    </div>
                  </Authorization>
                </div>
              </div>
            )
            return (
              <div key={account.id}>
                <PayslipTable
                  data={renderTableFields(mapAccountsDataToFields(account, actionsButtons))}
                  headings={[
                    {
                      accessor: 'name',
                      Header: 'Bank Details',
                      classNames: 'text-center',
                      disableFilters: true,
                      disableSortBy: true,
                      isKey: true
                    }
                  ]}
                  wrapperClassName='u-margin-bottom u-1/1 bank-account-table'
                />
                { lastBankAccountIndex === i &&
                <Authorization permissions={['BANKACCOUNT_CREATE']}>
                  <div className='u-weight--regular u-text--normal text-right u-margin-bottom'>
                    <AddBtnMessage
                      text='Add New Bank Account'
                      btnClasses='c-btn--small u-padding-left u-padding-right u-margin-left-small'
                    />
                  </div>
                </Authorization>
                }
              </div>
            )
          })}
      </div>

      <ConfirmationModal
        ref={confirmModal}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => deleteBankAccount(bankAccountId)}
      >
        <p>Are you sure you want to remove this bank account?</p>
      </ConfirmationModal>

      <Modal
        ref={modalEditBankAccount}
        className='c-modal c-modal--full'
        modalHeading='Edit bank account'
        data-testid='bank-account-edit-modal'
      >
        <BankAccountFormEditContainer
          onSubmit={() => modalEditBankAccount?.current ? modalEditBankAccount.current.hideModal() : null}
          bankAccountId={bankAccountId}
          {...props}
        />
      </Modal>

      <Modal
        ref={modalCreateBankAccount}
        className='c-modal c-modal--full'
        modalHeading='Add bank account'
        data-testid='bank-account-create-modal'
      >
        <BankAccountFormCreateContainer
          onSubmit={() => modalEditBankAccount?.current ? modalEditBankAccount.current.hideModal() : null}
          {...props}
        />
      </Modal>
    </div>
    {isEmployeeOnly &&
      <div className='u-hide-on-desktop'>
        {!isFetching &&
          <AccordionContainer title='Bank Details' titleColor='minsk' fullWidth rounded>
            {
              bankAccounts.map((account) => <MobileDetailsView
                key={account.id}
                rows={extractKeyValuePairFromArray(mapAccountsDataToFields(account))}
              />)
            }
          </AccordionContainer>
        }
      </div>
    }
  </>
}

BankAccountView.propTypes = {
  bankAccounts: PropTypes.array,
  deleteBankAccount: PropTypes.func,
  showText: PropTypes.string,
  isFetching: PropTypes.bool,
  isEmployeeOnly: PropTypes.bool,
  employee: PropTypes.object,
  companies: PropTypes.array,
  countries: PropTypes.array,
  text: PropTypes.string,
  btnClasses: PropTypes.string,
}

export default BankAccountView
