import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceStep'
import { isCompleted } from 'redux/models/payrollInstanceTask'
import { numberToLetter } from 'utils/strings'

class PayrollInstanceStep extends CRUDModel {
  getTasksCount (isUserCot) {
    let tasks = this.payrollInstanceTasks

    if (!isUserCot) {
      tasks = tasks.filter(task => task.globalOwner !== 'Payslip')
    }

    tasks = tasks.toModelArray().filter(task => task.payrollTask.active)

    return tasks.length
  }

  getCompletedTasksCount (isUserCot) {
    let tasks = this.payrollInstanceTasks

    if (!isUserCot) {
      tasks = tasks.filter(task => task.globalOwner !== 'Payslip')
    }

    tasks = tasks.filter(task => isCompleted(task.status))

    tasks = tasks.toModelArray().filter(task => task.payrollTask.active)

    return tasks.length
  }

  getName () {
    return this.payrollStep.name
  }

  getAlphabeticName () {
    // Step position is counted from 0.
    // `numberToLetter` starts counting from 1, so we just add `1` here
    return numberToLetter(this.position + 1).toUpperCase()
  }
}
PayrollInstanceStep.modelName = 'PayrollInstanceStep'
PayrollInstanceStep.actionTypes = actionTypes

PayrollInstanceStep.fields = {
  id: attr(),
  payrollInstanceProcess: fk('PayrollInstanceProcess', 'payrollInstanceSteps'),
  payrollStep: fk('PayrollStep', 'payrollInstanceSteps')
}

export default PayrollInstanceStep
