import React from 'react'
export const LoginIcons = () => (
  <div className='c-login__content-icons'>
    <div>
      <span className='icon icon--stats' />
      <p className='u-text-center u-margin-none'>Bigger Picture</p>
    </div>
    <div>
      <span className='icon icon--execution' />
      <p className='u-text-center u-margin-none'>Execution Excellence</p>
    </div>
    <div>
      <span className='icon icon--scale' />
      <p className='u-text-center u-margin-none'>Scale-Up Control</p>
    </div>
  </div>
)

export default LoginIcons
