import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchPayrolls } from 'redux/actions/payrolls'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { fetchCompanyCountryTermPivotIfNeeded } from '../redux/actions/companyCountryTermPivot'

import { createPayrollFilter, createPayrunFilter, PAYROL_FILTER_NAME, PAYRUN_FILTER_NAME } from 'redux/filters/payrollFilter'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { reportDesignerSchemaFields } from 'routes/Reporting/routes/ReportDesigner/components/ReportDesigner/fields'

/**
 * use Reporting fetch data to fetch it based on provided filters.
 *
 * @returns {Object} The fetch functions for reporting data.
 * @returns {Function} fetchReportData - Function to fetch report data based on changed field and filters.
 */
export const useFetchReportingData = ({ defaultData } = {}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsloading] = useState(true)
  /**
   * Fetch payrolls based on company filters.
   *
   * @param {Object} filters - The filters to apply.
   * @param {string|string[]} [filters.company] - The company or array of companies to filter by.
   * @returns {void}
   */
  const fetchCompanyPayrolls = (filters) =>
    !isEmpty(filters?.company) &&
    dispatch(
      fetchPayrolls({
        filter: createPayrollFilter(PAYROL_FILTER_NAME, filters),
      })
    )

  /**
   * Fetch payruns based on payroll filters.
   *
   * @param {Object} filters - The filters to apply.
   * @param {string|string[]} [filters.payroll] - The payroll or array of payrolls to filter by.
   * @returns {void}
   */
  const fetchPayrun = (filters) =>
    !isEmpty(filters?.payroll) &&
    dispatch(
      fetchPayrollInstances({
        filter: createPayrunFilter(PAYRUN_FILTER_NAME, filters),
      })
    )

  /**
   * Fetch report data based on the changed field and filters.
   *
   * @param {Object} params - The parameters for fetching report data.
   * @param {string} params.changedField - The field that has changed.
   * @param {Object} params.filters - The filters to apply.
   * @returns {void}
   */
  const fetchChangedFieldData = async ({ changedField, filters }) => {
    const dataToFetch = {
      [reportDesignerSchemaFields.company]: fetchCompanyPayrolls,
      [reportDesignerSchemaFields.payroll]: fetchPayrun,
    }
    dataToFetch[changedField]?.(filters)
  }

  const handleInitialData = async () => {
    await dispatch(fetchCountriesIfNeeded())
    await dispatch(fetchCompaniesIfNeeded())
    await dispatch(fetchCompanyCountryTermPivotIfNeeded())
    await dispatch(fetchBusinessUnitsIfNeeded())
    await dispatch(fetchCostCentersIfNeeded())
    await dispatch(fetchDepartmentsIfNeeded())

    if (!isEmpty(defaultData)) {
      await fetchChangedFieldData({
        changedField: reportDesignerSchemaFields.company,
        filters: defaultData,
      })
      await fetchChangedFieldData({
        changedField: reportDesignerSchemaFields.payroll,
        filters: defaultData,
      })
    }

    setIsloading(false)
  }

  const isFetchingDependentFields = useSelector((state) => {
    const { payrollInstances, payrolls } = state
    return {
      [reportDesignerSchemaFields.payroll]: payrolls?.filters[PAYROL_FILTER_NAME]?.isFetching,
      [reportDesignerSchemaFields.payrun]: payrollInstances?.filters[PAYRUN_FILTER_NAME]?.isFetching,
    }
  })

  useEffect(() => {
    handleInitialData()
  }, [dispatch])

  return { isLoading, isFetchingDependentFields, fetchChangedFieldData }
}
