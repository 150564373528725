import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const BUSINESS_UNITS_FETCH = 'BUSINESS_UNITS_FETCH'
export const BUSINESS_UNITS_RECEIVE = 'BUSINESS_UNITS_RECEIVE'
export const BUSINESS_UNITS_CREATE = 'BUSINESS_UNITS_CREATE'
export const BUSINESS_UNITS_UPDATE = 'BUSINESS_UNITS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: BUSINESS_UNITS_FETCH,
  receive: BUSINESS_UNITS_RECEIVE,
  create: BUSINESS_UNITS_CREATE,
  update: BUSINESS_UNITS_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'businessUnits', 'businessunits', 'BusinessUnits')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchBusinessUnitsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchBusinessUnits = actionUtility.fetchEntities
export const createBusinessUnit = actionUtility.createEntity
export const updateBusinessUnits = actionUtility.bulkUpdateEntity
