export const pollingJobTypesEnum = {
  PAYFILE_EXPORT_JOB: 'PayfileExportJob',
  PAYFILE_EXPORT_ARCHIVE_JOB: 'PayfileExportArchiveJob',
  PAYFILE_CHANGES_EXPORT_JOB: 'PayfileChangesExportJob',
  PAYFILE_IMPORT_JOB: 'PayfileImportJob',
  EMPTY_PAYFILE_EXPORT_JOB: 'EmptyPayfileExportJob',
  VARIANCE_EXPORT_JOB: 'VarianceExportJob',
  ELEMENT_VARIANCE_EXPORT_JOB: 'VarianceElementExportJob',
  EMPLOYEE_VARIANCE_EXPORT_JOB: 'VarianceEmployeeExportJob',
  REPORT_CREATE_JOB: 'ReportCreateJob',
}
