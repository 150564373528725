import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ onClick, type = 'button', className, children, disabled = false, title = '', ...rest }) => (
  <button onClick={onClick} className={className} type={type} disabled={disabled} title={title} data-testid={rest['data-testid']}>
    {children}
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.string
}

export default Button
