import React from 'react'
import PropTypes from 'prop-types'
import FieldErrors from './FieldErrors'

const RadioFields = ({ options, header, headerClassName, meta, input, ...rest }) => {
  return <div data-testid={rest['data-testid']}>
    <span className={headerClassName}>{ header }</span>
    <div className='o-grid o-grid--middle'>
      { options.map(o => {
        const checkedCompareValue = o.compareValue ? o.compareValue : o.value
        return <label key={o.value} className='c-label control control--radio'> {o.label}
          <input className='control__indicator__input' {...input}
            type='radio'
            value={o.value}
            checked={checkedCompareValue === input.value}
          />
          <div className='control__indicator' data-testid={o.value} />
        </label>
      }) }
    </div>
    <FieldErrors meta={meta} />
  </div>
}

RadioFields.propTypes = {
  // Heading label for the inputs group
  header: PropTypes.string,
  headerClassName: PropTypes.string,
  // Items, that will be rendered as inputs with labels
  options: PropTypes.arrayOf(PropTypes.shape({
    // Label if the input
    label: PropTypes.string,
    // Input's value
    value: PropTypes.string,
  })).isRequired,
  /**
   * @inheritDoc Please refer to meta documentation here:
   * https://redux-form.com/6.7.0/docs/api/field.md/
   */
  meta: PropTypes.object,
  /**
   * @inheritDoc Please refer to input documentation here:
   * https://redux-form.com/6.7.0/docs/api/field.md/
   */
  input: PropTypes.object
}

export default RadioFields
