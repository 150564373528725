import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_VERSION_FETCH = 'PAYROLL_INSTANCE_VERSION_FETCH'
export const PAYROLL_INSTANCE_VERSION_RECEIVE = 'PAYROLL_INSTANCE_VERSION_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_VERSION_FETCH,
  receive: PAYROLL_INSTANCE_VERSION_RECEIVE
}

const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceVersion', 'payrollinstanceversions', 'PayrollInstanceVersion')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceVersionIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceVersion = actionUtility.fetchEntities
