import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { textLabels, uploadTextLabels } from 'utils/locales/common.en'
import { cellRenderAction, dateTimePayslipTable, download } from 'utils/tableDataFormatters'

export const generateSupportingDocsHeadings = ({ DocumentActions, handleDownloadDocument, handleUpdateDoc }) => [
  new TableHeading({
    accessor: 'name',
    heading: textLabels.name,
    className: 'text-left',
    columnClassName: 'text-left',
    setWidth: '280px',
    disableSortBy: true,
    Cell: download,
    onClick: handleDownloadDocument,
  }),
  new TableHeading({
    accessor: 'description',
    heading: textLabels.description,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'documentType',
    heading: textLabels.type,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'version',
    heading: textLabels.version,
    className: 'text-center',
    columnClassName: 'text-center',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'uploadedOn',
    heading: uploadTextLabels.uploadedOn,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
    Cell: dateTimePayslipTable,
  }),
  new TableHeading({
    accessor: 'uploadedBy',
    heading: uploadTextLabels.uploadedBy,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: true,
  }),
  new TableHeading({
    accessor: 'action',
    heading: '',
    className: 'text-center',
    columnClassName: 'text-right',
    disableSortBy: true,
    Cell: cellRenderAction({ Actions: DocumentActions }),
    onClick: handleUpdateDoc,
  }),
]
