import { resourcesTypeNames, resourcesUploadText } from 'utils/locales/resources.en'

export const resourcesTypes = {
  releases: {
    dbName: 'Release Documentation',
    text: resourcesTypeNames.releases,
  },
  trainings: {
    dbName: 'Training Materials',
    text: resourcesTypeNames.trainings,
  },
}

export const accessibleBy = {
  client: 'Client',
  vendor: 'Vendor',
  both: 'Both',
}

export const uploadForm = [
  {
    value: accessibleBy.client,
    label: resourcesUploadText.options.client,
  },
  {
    value: accessibleBy.vendor,
    label: resourcesUploadText.options.vendor,
  },
  {
    value: accessibleBy.both,
    label: resourcesUploadText.options.both,
  },
]
