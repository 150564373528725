import { ROLE_VENDOR_ADMIN } from 'redux/config/auth'
import { isEmpty } from 'utils/fnkit/typeChecks'

const extractData = (data) => (typeof data === 'string' ? JSON.parse(data) : data)

export const buildVendorAccessAreas = (data, roleType) => {
  if (roleType === ROLE_VENDOR_ADMIN) {
    return
  }

  let accessAreas
  try {
    accessAreas = data?.map((acc) => {
      const area = extractData(acc)
      return {
        accessableType: 'Company',
        accessableId: area.companyId,
        accessableTenant: area.tenant,
      }
    })
  } catch (error) {
    accessAreas = []
  }
  return accessAreas
}

/**
 * Builds available access areas options based on the provided array and transfor to a CustomSelectField options.
 * @param {Object[]} availableAccessAreas - The array of available access areas.
 * @returns {Object[]} Returns an array of built available access areas options for the CustomSelectField component.
 */
export const buildAvailableAccessAreasOptions = (availableAccessAreas = []) =>
  availableAccessAreas.map((accessArea) => ({
    label: accessArea.name,
    value: JSON.stringify(accessArea),
    disabled: !accessArea?.accessable,
  }))

/**
 * Builds options for available tenants from external parent companies
 * @param {Array<object>} availableTenants - An array of available tenants.
 * @returns {Array<object>} - An array of options with labels, values, and disabled status.
 */
export const buildAvailableTenants = (availableTenants = []) =>
  availableTenants.map((tenant) => ({
    label: tenant.name,
    value: tenant.schema,
    disabled: !tenant?.extraData?.accessable,
  }))

/**
 * Checks if any of the selected tenants have access to any parent companies.
 * @param {Array<string>} selectedTenants - An array of selected tenant schemas.
 * @param {Array<Object>} parentCompanies - An array of parent company objects.
 * @param {string} parentCompanies.schema - The schema of the parent company.
 * @param {Object} parentCompanies.extraData - Additional data of the parent company.
 * @param {boolean} parentCompanies.extraData.accessable - Indicates if the parent company is accessible.
 * @returns {boolean} True if any selected tenant has access to any parent company, false otherwise.
 */
export const hasSomeAccessableTenant = (selectedTenants = [], parentCompanies = []) =>
  selectedTenants?.some((tenant) => {
    const parentCompany = parentCompanies.find((parent) => parent.schema === tenant)
    return parentCompany?.extraData?.accessable
  })

/**
 * Checks if every key in the `accesableCompanies` object matches a company schema in the `parentCompanies` array.
 *
 *
 * @param {Object} [accesableCompanies={}] - An object where each key represents a tenant id.
 * @param {Array<Object>} [parentCompanies=[]] - An array of objects, where each object represents a company
 * and contains at least a `schema` property corresponding to a tenant identifier.
 * @returns {boolean} Returns `true` if every tenant identifier from `accesableCompanies` keys is found as a `schema`
 *                    in at least one object in `parentCompanies`; otherwise, returns `false`.
 *
 * @example
 * const accesableCompanies = { 'tenant1': [1,2], 'tenant2': [2] };
 * const parentCompanies = [{ ...rest,schema: 'tenant1' }, { ...rest, schema: 'tenant2' }];
 * const result = hasEveryAccesableCompanies(accesableCompanies, parentCompanies);
 * console.log(result); // Output: true
 *
 * @example
 * // An example with missing tenant in parentCompanies
 * const accesableCompanies = { 'tenant1': [1,2], 'tenant3': [3] };
 * const parentCompanies = [{  ...rest, schema: 'tenant1' }];
 * const result = hasEveryAccesableCompanies(accesableCompanies, parentCompanies);
 * console.log(result); // Output: false
 */
export const hasEveryAccesableCompanies = (accesableCompanies = {}, parentCompanies = []) => {
  if (isEmpty(accesableCompanies)) {
    return false
  }
  return Object.keys(accesableCompanies)?.every((tenant) => parentCompanies?.find((c) => c.schema === tenant))
}

/**
 * Checks if two access areas have the same `accessableId` and `accessableTenant`.
 *
 * @param {Object} accessArea - The access area to check.
 * @param {Object} aaToCompare - The access area to compare against.
 * @param {string|number} accessArea.accessableId - The ID of the access area.
 * @param {string|number} accessArea.accessableTenant - The tenant of the access area.
 * @param {string|number} aaToCompare.accessableId - The ID of the access area to compare.
 * @param {string|number} aaToCompare.accessableTenant - The tenant of the access area to compare.
 * @returns {boolean} - Returns true if the access areas are the same, false otherwise.
 */
export const hasSameAccessArea = (accessArea, aaToCompare) =>
  accessArea.accessableId === aaToCompare.accessableId && accessArea.accessableTenant === aaToCompare.accessableTenant

/**
 * Returns the difference between two lists of access areas.
 *
 * @param {Array<Object>} accessAreas - The original array of access areas.
 * @param {Array<Object>} accessAreasToCompare - The array of access areas to compare against.
 * @returns {Array<Object>} - Returns an array of access areas that are present in the original array but not in the comparison array.
 */
export const getAccessAreasDifference = (accessAreas = [], accessAreasToCompare = []) =>
  accessAreas?.filter((originAccessArea) => {
    const isInitialAAIncludedInFormAAs = accessAreasToCompare.find((accessAreaToCompare) => hasSameAccessArea(originAccessArea, accessAreaToCompare))
    return !isInitialAAIncludedInFormAAs
  })
