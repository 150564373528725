/**
 * Merge arrays in a flatten one array
 *
 * @param {Array} arrays - The nested arrays, that will be merged in a flatten one
 *
 * @link https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript?page=1&tab=votes#comment50580016_10865042
 * @example:
 *
 * ```
 *  flatten([ [1], [2] ]) === [1, 2]
 * ```
 *
 * @return {Array}
 */
export const flatten = arrays => [].concat(...arrays)
