import createSelector from 'utils/createSelector'

const getCurrencyId = (state, props) => props.currencyId

export const getCurrencies = createSelector(({ Currency }) => {
  return Currency.all().toRefArray().map(currency => ({
    ...currency
  }))
})

export const getCurrencyById = createSelector(getCurrencyId,
  ({ Currency }, currencyId) => {
    return Currency.withId(currencyId).ref
  }
)
