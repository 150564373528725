import { connect } from 'react-redux'
import NavBarProfile from 'components/navbar/NavBarProfile'
import Fetcher from '../Fetcher'
import { getCotUser } from '../../redux/selectors/cotUsers'
import { logout } from 'redux/actions/auth'

const mapDispatchToProps = { logout }

const mapStateToProps = (state, props) => ({
  user: getCotUser(state, { userId: state.auth.userId })
})

const Container = connect(mapStateToProps, mapDispatchToProps)(NavBarProfile)

export default Fetcher(Container, ['cotUsers'])
