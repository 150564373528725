import React from 'react'
import { Field } from 'redux-form'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import SubmitButtons from './SubmitButtons'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'

class FiltersEmployeeReport extends React.Component {
  render () {
    const {
      selected,
      countries,
      companies,
      businessUnits,
      costCenters,
      departments,
      readonlyFields,
      isFieldDisabled,
      disabledReason,
      mostRecentRunReport = {},
    } = this.props
    /*
     * Every next filter is shown if the last required field has value
     */
    const shouldShowMostRecentRunReportItem = !this.props.reportItem

    return (
      <div className='o-block--inline'>
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='employeeStatus'
                label='Employee Self-Serve Turned On *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: 'active', label: 'Yes' },
                  { value: 'inactive', label: 'No' },
                  { value: 'all', label: 'Both' },
                ]}
                required
                enableRemovedOptionFix
                disabled={isFieldDisabled || readonlyFields.employeeStatus}
                isClearable={!isFieldDisabled}
                data-testid='employee-self-serve-turned-on-wrapper'
              />
            </div>
          </div>
        )}
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='showOnlyTotals'
                label='Show Only Totals *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No', title: 'Count only available in downloaded report as well.' },
                ]}
                required
                enableRemovedOptionFix
                disabled={isFieldDisabled || readonlyFields.showOnlyTotals}
                isClearable={!isFieldDisabled}
                data-testid='show-only-totals-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.subcategory) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='fromDate'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.subcategory) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='toDate'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.subcategory) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.country}
                disabledReason={disabledReason}
                multi
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                    abbreviature: country.abbreviature,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.country) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.company}
                disabledReason={disabledReason}
                multi
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                hasNoneOption
                isFieldDisabled={readonlyFields && readonlyFields.payroll}
                optionComponent={CheckboxOption}
                multi
                {...this.props}
                // Here we don't want to filter the Payrolls by dates, because `from/to` dates refer to the employees,
                // and the dates should filter the employees, not the payrolls.
                selected={{
                  ...this.props.selected,
                  fromDate: null,
                  toDate: null,
                }}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='employmentStatus'
                label='Type of Employment/Engagement'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
                data-testid='type-of-employment'
                multi
                hasNoneOption
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='businessUnit'
                label='Business Unit'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={businessUnits
                  .map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                  }))
                  .sort(sortByLabel)}
                data-testid='businessUnit-wrapper'
                hasNoneOption
                enableRemovedOptionFix
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='costCenter'
                label='Cost Center'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={costCenters
                  .map((cc) => ({
                    value: cc.id,
                    label: cc.name,
                  }))
                  .sort(sortByLabel)}
                hasNoneOption
                enableRemovedOptionFix
                data-testid='costCenter-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='department'
                label='Department'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={departments
                  .map((dep) => ({
                    value: dep.id,
                    label: dep.name,
                  }))
                  .sort(sortByLabel)}
                hasNoneOption
                enableRemovedOptionFix
                data-testid='department-wrapper'
              />
            </div>
          </div>
        )}
        {!this.props.hideNavigationButtons && (
          <SubmitButtons
            pristineCheck={!isEmpty(selected.company) && !isEmpty(selected.payroll)}
            onBackClick={this.props.navigateToParentOnClick}
            {...this.props}
          />
        )}
        {shouldShowMostRecentRunReportItem && <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem
            mostRecentRunReport={mostRecentRunReport}
            onOpenReport={this.props.handleReportModalOpen}
            onDelete={this.props.deleteReport}
            {...this.props}
          />
        </div>}
      </div>
    )
  }
}

FiltersEmployeeReport.propTypes = {
  resetFormFields: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
}

export default FiltersEmployeeReport
