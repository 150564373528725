import React from 'react'

import PayrollSummaryView from '../components/PayrollSummaryView'
import { PayrollSummaryProvider } from '../PayrollSummaryContext'
import PropTypes from 'prop-types'

const PayrollSummaryContainer = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props

  return (
    <PayrollSummaryProvider>
      <PayrollSummaryView payrollInstanceId={id} />
    </PayrollSummaryProvider>
  )
}

PayrollSummaryContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
export default PayrollSummaryContainer
