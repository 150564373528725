import React from 'react'
import SectionHeading from 'components/SectionHeading'
const classNames = require('classnames')

class Expandable extends React.Component {
  constructor (props) {
    const { isExpanded = true } = props
    super(props)

    this.state = {
      // Is the row Expanded?
      isExpanded: isExpanded
    }
  }

  /**
   * Toggle the expandable row
   */
  toggleExpandedRow () {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  /*
   * Arrow classes by toggling sections
   */
  getArrowClasses () {
    return classNames({
      'icon--arrow u-cursor--pointer u-float--right': true,
      'active': this.state.isExpanded
    })
  }

  getExpandableRowClasses () {
    return classNames({
      'u-hidden': !this.state.isExpanded
    })
  }

  render () {
    const { text, children } = this.props

    return <div>
      <SectionHeading text={text} fullWidth>
        <span className={this.getArrowClasses()} onClick={() => this.toggleExpandedRow()} />
      </SectionHeading>
      <div className={this.getExpandableRowClasses()}>{children}</div>
    </div>
  }
}

export default Expandable
