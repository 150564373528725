import React from 'react'
import PropTypes from 'prop-types'

export const VendorHeader = ({ vendor: { name, registrationNumber } }) => (
  <div className='o-media o-grid o-grid--middle'>
    <div className='o-media__body line-height--20'>
      <span className='u-weight--bold u-text--large'>{ name }</span>
      <span> / Registration Number: { registrationNumber }</span>
    </div>
  </div>
)

VendorHeader.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    registrationNumber: PropTypes.string.isRequired,
  })
}

export default VendorHeader
