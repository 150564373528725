import createSelector from 'utils/createSelector'

// get employee Id
const getEmployee = (state, props) => parseInt(props.employeeId)

// get payroll Id
const getPayroll = (state, props) => parseInt(props.payrollId)

export const getPayrollInstancesStatus = createSelector(getPayroll,
  ({ Payroll }, id) => {
    const { hasActivePayrollInstances, hasLockedPayrollInstances } = Payroll.withId(id)

    if (hasActivePayrollInstances) {
      return 'active'
    } else if (hasLockedPayrollInstances) {
      // There's one edge-case, that's communicated with the business team and we won't handle it for now.
      // It's possible the Payroll to have locked instances, but no future Payruns.
      // In that case, it's better to set status to `noFuture`.
      // But, in order to catch this case, the BE team needs to give us another flag `hasUpcomingPayrollInstances`.
      return 'locked'
    } else {
      return 'noFuture'
    }
  }
)

export const getAttachedPayrollEmployeeMessage = createSelector(getEmployee, getPayroll, getPayrollInstancesStatus,
  ({ EmployeeSystemUser, Payroll }, employeeId, payrollId, status) => {
    const payroll = Payroll.withId(payrollId)

    const { firstname, surname } = EmployeeSystemUser.withId(employeeId)
    const fullName = `${firstname} ${surname}`
    const messages = {
      active: `Employee ${fullName} is assigned to Payroll ${payroll.name}`,
      // eslint-disable-next-line max-len
      locked: `Employee ${fullName} is successfully assigned to Payroll ${payroll.name} but will be included in the next payrun as the current one is already in process and locked for changes.`,
      noFuture:  `Employee ${fullName} is assigned to Payroll ${payroll.name}, but last payrun is locked. There are no future payruns.`
    }
    return messages[status]
  }
)

export const getAttachedPayrollEmployeesMessage = createSelector(getPayroll, getPayrollInstancesStatus,
  ({ Payroll }, payrollId, status) => {
    const payroll = Payroll.withId(payrollId)
    const messages = {
      active: `Employees are assigned to Payroll ${payroll.name}`,
      // eslint-disable-next-line max-len
      locked: `Employees are successfully assigned to Payroll ${payroll.name} but will be included in the next payrun as the current one is already in process and locked for changes.`,
      noFuture:  `Employees are assigned to Payroll ${payroll.name}, but last payrun is locked. There are no future payruns.`
    }
    return messages[status]
  })
