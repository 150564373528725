export const chartLabels = {
  company: 'Company',
  country: 'Country',
  payroll: 'Payroll',
  noOfReversions: 'Total Reversions',
  newRunRequestedCompany: 'New Run Requested (Company)',
  newRunRequestedPayrollPartner: 'New Run Requested (Payroll Partner)',
  newRunRequestedOther: 'New Run Requested (Other)',
  clarificationRequired: 'Clarification Required',
}

export const chartSectionHeadings = {
  numberOfGtnVersions: 'GTN versions by',
  numberOfGtnVersionsByPayDate: 'GTN versions by Pay Date',
  numberOfReversions: 'Reversions by',
  numberOfReversionsByPayDate: 'Reversions by Pay Date and Reversion Type',
  reversionsType: 'Reversions Type',
  notAssigned: 'Not Assigned',
}
