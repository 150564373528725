import React from 'react'
import ResetPasswordWrapper from 'layouts/ResetPasswordWrapper'
import ChangePasswordContainer from './ChangePasswordContainer'

export const RouteView = props => {
  return <ResetPasswordWrapper>
    <ChangePasswordContainer {...props} />
  </ResetPasswordWrapper>
}

export default RouteView
