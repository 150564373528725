import { connect } from 'react-redux'
import DocumentsView from 'components/documents/DocumentsView'
import Fetcher from 'containers/Fetcher'
import { getCompanyWithCountry } from 'redux/selectors/company'

const mapStateToProps = (state, props) => {
  const { match: { params } } = props
  const company = getCompanyWithCountry(state, { companyId: params.companyId })

  return {
    companyName: company.name,
    countryName: company.country.name,
  }
}

const Container = connect(mapStateToProps)(DocumentsView)

export default Fetcher(Container, [
  'companies', 'parentCompanies', 'countries'
])
