import React from 'react'
import PropTypes from 'prop-types'
import EmployeePersonalFormFields from './EmployeePersonalFormFields'
import EmployeeJobOrgFormFields from './EmployeeJobOrgFormFields'
import EmployeePayTaxesFormFields from './EmployeePayTaxesFormFields'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const EmployeeCreate = (props) => {
  const { handleSubmit, onSubmit, tabIndex, onChangeTab } = props

  return <form onSubmit={handleSubmit(onSubmit)} data-testid='employee-create-form'>
    <Tabs selectedIndex={tabIndex} onSelect={onChangeTab}>
      <TabList className='c-tabs__list c-tabs__list-border-none'>
        <Tab className='c-tabs__item'>
          <span data-testid='personal-tab'>Personal</span>
        </Tab>
        <Tab className='c-tabs__item'>
          <span data-testid='job-and-org-tab'>Job & Org</span>
        </Tab>
        <Tab className='c-tabs__item'>
          <span data-testid='pay-and-taxes-tab'>Pay & Taxes</span>
        </Tab>
      </TabList>

      <TabPanel>
        <EmployeePersonalFormFields {...props} />
      </TabPanel>
      <TabPanel>
        <EmployeeJobOrgFormFields {...props} />
      </TabPanel>
      <TabPanel>
        <EmployeePayTaxesFormFields {...props} />
      </TabPanel>
    </Tabs>
  </form>
}

EmployeeCreate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChangeTab: PropTypes.func,
  tabIndex: PropTypes.number
}

export default EmployeeCreate
