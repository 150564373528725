import React from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { setStorageItem } from 'utils/storage'
import PropTypes from 'prop-types'
import { ToastWrapper } from 'components/toasts/ToastWrapper'
import { handleToastCompletion } from 'utils/toastHelpers'

export const SeeResultsButton = ({ onClick, text }) => (
  <button className='c-btn--unset-default-styles u-text--curious u-padding-left-tiny u-cursor--pointer u-underline' onClick={onClick}>
    {text}
  </button>
)

SeeResultsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

/**
 * Component to be used for async import jobs.
 * Responsible for displaying a toast notification regarding the status of a bulk import job.
 * The toast may optionally display warnings if errors were encountered during the job execution,
 * and provide options to navigate to detailed results.
 *
 * @param {Object} options - The configuration options for the toast component.
 * @param {Object} options.job - The job object containing the details of the bulk import job.
 * @param {Function} options.cb - A callback function invoked upon toast completion.
 * @param {Object} options.baseApiUsage - An object representing the base API usage details relevant to the bulk import.
 * @param {Object} options.toastMessages - An object containing the localized or custom messages to be displayed in the toast.
 * @param {string} options.seeResultsPath - The path used for navigation to the results of the job execution.
 * @param {boolean} [options.checkForErrors=false] - A flag indicating whether to check for errors in the job results and render a warning toast, if applicable.
 *
 * @returns {ReactElement|null} The JSX element representing the toast notification, or null if an error toast is rendered.
 */
export const BulkImportToast = ({ job, cb, baseApiUsage, toastMessages, seeResultsPath, checkForErrors = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onCompleteToast = () => handleToastCompletion(dispatch, job, cb, baseApiUsage, true)

  const handleSeeResultsButtonClick = () => {
    setStorageItem(
      'jobResult',
      JSON.stringify({
        id: job.id,
        jobResult: job.jobResult,
        filters: job.filters,
      })
    )

    if (seeResultsPath) {
      history.push(`${seeResultsPath}?jobId=${job.id}`)
    }
    toast.dismiss(job.id)
  }

  // if the job is completed successfully, but it contains errors, we will update the render of the toast here and show a warning toast
  if (checkForErrors && job.jobResult.errors) {
    toast.update(job.id, {
      render: (
        <ToastWrapper onCompleteToast={onCompleteToast}>
          {toastMessages?.withErrors}
          <SeeResultsButton onClick={handleSeeResultsButtonClick} text={toastMessages?.seeResults} />
        </ToastWrapper>
      ),
      type: 'warning',
      autoClose: false,
    })
    return null
  }

  return <ToastWrapper onCompleteToast={onCompleteToast}>{toastMessages?.successful}</ToastWrapper>
}

BulkImportToast.propTypes = {
  job: PropTypes.object.isRequired,
  cb: PropTypes.func.isRequired,
  baseApiUsage: PropTypes.object,
  toastMessages: PropTypes.shape({
    withErrors: PropTypes.string,
    successful: PropTypes.string,
    seeResults: PropTypes.string,
  }).isRequired,
  seeResultsPath: PropTypes.string,
  checkForErrors: PropTypes.bool,
}
