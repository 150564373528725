export const platformColors = {
  midGreen: '#88C8B4',
  midBlue: '#52A9DD',
}

export const getComputeDomVarColor = (varToResolve = '') => {
  let color
  try {
    color = window.getComputedStyle(document.documentElement).getPropertyValue(varToResolve).trim()
  } catch {
    color = platformColors.midBlue
  }

  return color
}
