import React from 'react'

/**
 * The most pure component ever exists.
 *
 * The idea behind this `RouteView` is not much simple, as the pure component itself.
 * We use this `/pure` route, in the case we are switching the selected tenant.
 * We need such component, in order to prevent unrelated API requests, according to the currently selected tenant.
 *
 * Let me explain the flow and the buggy use-case in simpler steps:
 *
 * 1. Imagine we are in the COS mode (tenant) and we are reviewing COS users. We get them via the API URI: `/cotUsers`.
 * 2. Now, we decide to change the tenant, i.e. from COS tenant to continue operating with a Birdale company (tenant).
 * 3. All's good! The tenant is changed with the appropriated action dispatched.
 * 4. But the problem occurs, because we are still on the previous tenant page (COS)
 *    and it's possible unrelated COS API requests to be processed via the Birdale tenant API URL.
 *    This is a big problem, because the different type of tenants have different API endpoints.
 *    For example: COS API endpoints differ from Company API endpoints.
 * 5. Because of this - before changing the tenant, we have to navigate to the most pure and safest component firstly.
 *    This will guarantee us that no further API request will be triggered.
 * 6. Now we are safe to dispatch tenant changing logic.
 *
 *
 * Maybe you're going to ask - why we don't navigate to Birdale internal page firstly, and change the tenant later.
 * Because the same bug will occur. Navigating firstly to Birdale will trigger new API requests,
 * but the previous tenant API URL will be used, because the tenant changing logic is not processed yet.
 *
 * Because of the above reason - we need such pure route.
 */
export default () => <div />
