import createSelector from 'utils/createSelector'
import _ from 'lodash'

// get payroll id
const getPayrollId = (state, props) => parseInt(props.payrollId, 10)

// get payroll by id
// and attach payroll's employees to it
export const getPayrollByIdWithEmployeeIds = createSelector(getPayrollId,
  ({ Payroll }, id) => {
    // get payroll
    let payroll = Payroll.withId(id)

    let allPayrollEmployees = []

    payroll
      .payrollEmployees
      .toModelArray()
      .map(pivot => {
        try {
          allPayrollEmployees.push(pivot.employee.id)
        } catch (error) {
          // A race condition CAN occur in which
          // the fetch for company employeeusers has not kicked off yet
          console.log('Waiting for response')
        }
      })

    let allPaygroupEmployees = []

    // get all paygroup employees for current payroll
    payroll
      .payrollPaygroups
      .toModelArray()
      .map(pivot => {
        let paygroup = pivot.paygroup

        paygroup.paygroupEmployees.toRefArray().map(paygroupEmployee => {
          allPaygroupEmployees.push(paygroupEmployee.employee)
        })
      })

    // combine payroll and paygroup employees
    let employeeIds = _.union(allPayrollEmployees, allPaygroupEmployees)

    return {
      ...payroll.ref,
      employeeIds,
      allPaygroupEmployees
    }
  }
)
