import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollTaskOwners'

class PayrollTaskOwners extends CRUDModel {}
PayrollTaskOwners.modelName = 'PayrollTaskOwners'
PayrollTaskOwners.actionTypes = actionTypes

PayrollTaskOwners.fields = {
  id: attr(),
  payrollTask: fk('PayrollTask', 'payrollTaskOwners'),
  owner: fk('EmployeeSystemUser', 'payrollTaskOwners')
}

export default PayrollTaskOwners
