import React, { useRef } from 'react'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import EmergencyPersonFormCreateContainer from 'containers/EmergencyPersonFormCreateContainer'
import PayslipTable from './table/PayslipTable'

function EmergencyPersonView (props) {
  const {
    employee,
    showText,
    editableOptions,
    defaultNestedColumns,
    inEditMode = false,
    data: { emergencyPersonDetails },
  } = props
  const modalCreateEmergencyPerson = useRef(null)

  const hasEmergencyData = !!employee.emergencyFirstname || !!employee.emergencySurname || !!employee.emergencyMobilePhone || !!employee.emergencyRelationship

  const link = (
    <button
      onClick={() => modalCreateEmergencyPerson.current.showModal()}
      className={`c-btn c-btn--tiny c-btn--curious u-margin-left-tiny ${inEditMode ? 'disabled' : null}`}
      disabled={inEditMode}
      data-testid='emergency-person-create'
    >
      <span className='icon icon--plus' />
    </button>
  )

  return (
    <>
      {showText && !hasEmergencyData ? (
        <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
          <div
            className='u-text--center u-text--normal u-border-bottom--mischka
            u-bg--white u-margin-bottom u-padding-bottom-small'
          >
            <h6 className='u-text-generic u-text--large line-height--49 u-weight--bold u-bg--sauve rounded-t u-margin-bottom-small'>
              Emergency Person Contact Details
            </h6>
            <span className='u-weight--regular'>Add Emergency Person Contact Details {link}</span>
          </div>
        </Authorization>
      ) : null}
      <div data-testid='emergency-person-details'>
        {hasEmergencyData && (
          <>
            <div className='o-layout'>
              <div className='o-layout__item u-1/1'>
                <PayslipTable
                  data={emergencyPersonDetails}
                  dynamicValues
                  editableOptions={editableOptions}
                  headings={[
                    {
                      accessor: 'name',
                      Header: 'Emergency Person Details',
                      classNames: 'text-center',
                      disableFilters: true,
                      disableSortBy: true,
                      isKey: true,
                      columns: defaultNestedColumns,
                    },
                  ]}
                  wrapperClassName='u-margin-bottom u-1/1'
                />
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        ref={modalCreateEmergencyPerson}
        className='c-modal c-modal--full'
        modalHeading='Emergency Person Contact Details'
        data-testid='emergency-person-create-modal'
      >
        <EmergencyPersonFormCreateContainer
          onSubmit={() => (modalCreateEmergencyPerson?.current ? modalCreateEmergencyPerson.current.hideModal() : null)}
          {...props}
        />
      </Modal>
    </>
  )
}

EmergencyPersonView.propTypes = {
  employee: PropTypes.object,
  data: PropTypes.object,
  showText: PropTypes.string,
  defaultNestedColumns: PropTypes.array,
  editableOptions: PropTypes.object,
  rows: PropTypes.array,
  inEditMode: PropTypes.bool,
}

export default EmergencyPersonView
