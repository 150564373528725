import CoreLayout from 'layouts/PageLayout/PageLayout'
import PayrunsRoute from './routes/payruns'
import InnerWrapper from 'layouts/InnerWrapper'

export default (store) => ({
  path : 'year',
  indexRoute: { component: InnerWrapper },
  isOrphanedChild: true,
  exact: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ PayrunsRoute(store) ]
})
