import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceCountryTermsPivot'

class PayrollInstanceCompanyCountryTerm extends CRUDModel {}
PayrollInstanceCompanyCountryTerm.modelName = 'PayrollInstanceCompanyCountryTerm'

PayrollInstanceCompanyCountryTerm.fields = {
  id: attr(),
  payrollInstance: fk('PayrollInstance', 'payrollInstanceCompanyCountryTerms'),
  companyCountryTerm: fk('CompanyCountryTerm', 'payrollInstanceCompanyCountryTerms')
}
PayrollInstanceCompanyCountryTerm.actionTypes = actionTypes

export default PayrollInstanceCompanyCountryTerm
