import React from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import BusinessUnitsOwnersTable from './BusinessUnitsOwnersTable'

const MultiOwners = ({ payrollTaskName, buOwnersData, ...rest }) => (
  <div>
    <div className='o-layout'>
      <div className='o-layout__item u-text--center u-1/1'>
        <h2 className='u-text--center u-text--huge'>
          <span>Owners for action <span className='u-weight--bold'>{ payrollTaskName }</span></span>
        </h2>
      </div>
    </div>
    <Form {...rest}>
      <div className='o-layout u-text--center'>
        <div className='o-layout'>
          <div className='o-layout__item u-1/1'>
            <div className='o-layout__item u-1/1'>
              <BusinessUnitsOwnersTable
                buOwnersData={buOwnersData}
                ownersProps={{
                  placeholder: 'No action owner selected',
                  disabled: true,
                  disabledReason: 'To change the task owner click edit button next to the task name.'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
)

MultiOwners.propTypes = {
  payrollTaskName: PropTypes.string,
  buOwnersData: PropTypes.array,
}

export default MultiOwners
