import { getFormValues } from 'redux-form'

export const areAllEmployeesSelected = ({ state, formName, employees }) => {
  const formValues = getFormValues(formName)(state)

  // Extract employee IDs from employees array
  const employeeIds = employees.map((employee) => employee.id)

  // Check if each employee ID is present in the formValues with a truthy value
  return employeeIds.every((id) => formValues?.[`id-${id}`])
}
