/**
 * Flattens an array up to the specified depth using the native Array.prototype.flat() method.
 * This provides a simple and efficient way to flatten an array up to a defined depth.
 * Requires ES2019 or later environments, or a suitable polyfill in older environments.
 * @param {Array} array - The array to flatten.
 * @param {number} [depth=1] - The maximum depth to flatten to.
 * @returns {Array} - The flattened array.
 */
export const flatten = (array, depth = 1) => array.flat(depth)

/**
 * Returns the first element of an array.
 * This is a simple utility function that retrieves the first item from an array.
 * @param {Array} array - The array to retrieve the first element from.
 * @returns {*} - The first element of the array.
 */
export const first = (array) => array?.[0]

/**
 * Creates a new array with all unique elements from the original array.
 * Utilizes Set to ensure all elements are unique.
 * @param {Array} array - The array from which to create a set of unique elements.
 * @returns {Array} - An array of unique elements.
 */
export const uniq = (array) => [...new Set(array)]

/**
 * Reverses an array.
 * @param {Array} array - The array to reverse.
 * @returns {Array} - The reversed array.
 */
export const reverse = (array) => array?.slice?.()?.reverse?.()
/**
 * Returns an array that is the intersection of the input arrays. Each value in the result is present in each of the arrays.
 * @param {*} arrays - Array of arrays to check if they contain each others values
 *
 * example:
 * const arrays = [[1, 2, 3], [101, 2, 1, 10], [2, 1]]
 * console.log(intersection(arrays))
 * output: [1, 2]
 */
export const intersection = (arrays) => {
  return arrays.reduce((a, b) => a.filter((val) => b.includes(val)))
}

/**
 * Converts the given input into an array.
 *
 * If the input is already an array, it is returned as-is. If the input is a
 * non-null and non-undefined value, it places the input inside a new array.
 * If the input is null or undefined, it returns an empty array.
 *
 * @param {*} value - The value to be converted into an array.
 * @returns {Array} - An array containing the input value, if it was not an array
 * itself; or the input value wrapped in an array if it was a non-array value;
 * or an empty array if null or undefined is provided.
 */
export const toArray = (value) => (Array.isArray(value) ? value : value ? [value] : [])
