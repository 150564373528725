import { uniq } from 'utils/fnkit/array'
import { pickBy } from 'utils/fnkit/object'
import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const DOCUMENT_USERS_FETCH = 'DOCUMENT_USERS_FETCH'
export const DOCUMENT_USERS_RECEIVE = 'DOCUMENT_USERS_RECEIVE'
export const DOCUMENT_USERS_INVALIDATE = 'DOCUMENT_USERS_INVALIDATE'
export const DOCUMENT_USERS_UPDATE = 'DOCUMENT_USERS_UPDATE'
export const DOCUMENT_USERS_RESET = 'DOCUMENT_USERS_RESET'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DOCUMENT_USERS_FETCH,
  receive: DOCUMENT_USERS_RECEIVE,
  invalidate: DOCUMENT_USERS_INVALIDATE,
  update: DOCUMENT_USERS_UPDATE,
  reset: DOCUMENT_USERS_RESET,
}
const actionUtility = new ActionUtility(actionTypes, 'documentUsers', 'documentusers', 'DocumentUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDocumentUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDocumentUsers = actionUtility.fetchEntities
export const invalidateDocumentUsers = actionUtility.invalidate
export const resetDocumentUsers = actionUtility.reset

/*
 * Save sharing preferences as update document users
 *
 * @param {Object} entity - the collected data
 * @param {Number} documentId
 * @param {String} documentTenant
 * @param {boolean} [excludeSftp=false] - A flag to indicate whether to exclude SFTP data.
 *
 * Expected data format:
 * {
 *   "data": [
 *       {"userId": 4, "userTenant": "tenant1", "documentId": 89, "documentTenant": "tenant1"},
 *       {"userId": 11, "userTenant": "tenant1", "documentId": 89, "documentTenant": "tenant1"},
 *       {"userId": 21, "userTenant": "tenant1", "documentId": 89, "documentTenant": "tenant1"}
 *   ],
 *   "params": {
 *       "document": {"documentId": 89, "documentTenant": "tenant1"},
 *       "userTenants": ["tenant1"]
 *       "shareWithSftp": true
 *   }
 * }
 */
export const updateDocumentUsers = (entity, documentId, documentTenant, excludeSftp = false) => {
  const { sftp, ...normalizedEntity } = entity
  if (!excludeSftp) {
    normalizedEntity.sftp = sftp
  }

  // Get the unique user tenants from all keys
  const allKeys = Object.keys(normalizedEntity)
  const userTenants = uniq(
    allKeys.map((key) => {
      const [userTenant] = key.split('-', 2)
      return userTenant
    })
  ).filter((tenant) => tenant !== 'sftp')

  // Get all checked fields
  const checkedFields = pickBy(normalizedEntity, (value) => value === true)

  // Get their keys
  const checkedKeys = Object.keys(checkedFields)

  // Arrays to collect user data and sftp data
  const dataUsers = []
  let shareWithSftp

  checkedKeys.forEach((key) => {
    // Split the keys in two parts
    const [userTenant, userId] = key.split('-', 2)

    if (userTenant === 'sftp') {
      shareWithSftp = true
    } else {
      dataUsers.push({
        userId: parseInt(userId),
        userTenant,
        documentId,
        documentTenant,
      })
    }
  })

  // Build document params
  const document = { documentId, documentTenant }

  // Build the actual data to share this document to selected users
  const params = { document, userTenants }
  if (shareWithSftp) {
    params.shareWithSftp = shareWithSftp
  }

  const payload = {
    data: dataUsers,
    params,
  }

  return (dispatch, getState, { api }) => api.patch('documentusers', { payload }).then(errorsHandling.handleFormErrors)
}
