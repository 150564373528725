import React from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import moment from 'moment'
import { ConfirmButton, RejectButton } from 'components/buttons/'

export const LeaveDelete = props => {
  if (props.isFetching) return <div>Loading...</div>
  const leavePeriod = props.attendances.find(record => record.id === props.attendancePeriodId)
  let leaveName = ''
  let periodStr = ''
  if (leavePeriod) {
    const { globalName, localName, fromDate, toDate } = leavePeriod
    leaveName = `${globalName} (${localName})`
    periodStr = `${moment(fromDate).format('DD/MM/YY')} - ${moment(toDate).format('DD/MM/YY')}`
  }
  return <div>
    {leavePeriod && <p>{`Are you sure you want to delete ${leaveName} that takes place during ${periodStr}`}</p>}
    <Form {...props}>
      <ConfirmButton type='submit' />
      <RejectButton onClick={props.onReject} />
    </Form>
  </div>
}

LeaveDelete.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  attendances: PropTypes.array,
  isFetching: PropTypes.bool,
  attendancePeriodId: PropTypes.string,
  // What to happen on rejected (confirmation) deletion
  onReject: PropTypes.func.isRequired
}

export default LeaveDelete
