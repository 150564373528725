import React from 'react'
import PayslipTable from 'components/table/PayslipTable'

const CostingTable = ({ ...props }) => (
  <PayslipTable
    data={props.rows.map((row) => {
      return {
        services: (
          <div>
            {row.name}
            <span
              onClick={() => props.onClick('removeService', props.countryId, row.id, row.name)}
              className='icon--ex u-margin-left-tiny u-margin-top-tiny'
              title='Remove service'
            />
          </div>
        ),
        serviceElement: (
          <div>
            {/* Service element */}
            {/* <span onClick={() => props.onClick('removeService', props.countryId, row.id)}
          className='icon--ex u-float--right u-margin-top-tiny' title='Remove service element' /> */}
          </div>
        ),
        serviceType: <div>{/* Type */}</div>,
        icpCost: <div>{/* 6 */}</div>,
        clientPrice: <div>{/* 10 */}</div>,
        margin: <div>{/* 4 */}</div>,
        minThreshold: <div>{/* 50 */}</div>,
        employeesThreshold: <div>{/* 1 to 10 */}</div>,
        currency: <div>{/* EUR */}</div>,
        actions: <div />,
      }
    })}
    headings={[
      {
        Header: <div className='o-grid o-grid--between'>Services</div>,
        accessor: 'services',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: (
          <div className='o-grid o-grid--between'>
            Service element
            {props.rows.length && !props.shouldHideServiceButton ? (
              <button
                onClick={() => props.onClick('addServiceElement', props.countryId)}
                className='c-btn c-btn--tiny u-bg--transparent u-text--tiny u-pading-none u-float--right'
                title='Add service element'
              >
                <span className='icon icon--plus' />
              </button>
            ) : null}
          </div>
        ),
        accessor: 'serviceElement',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Service cost type',
        accessor: 'serviceType',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ICP cost',
        accessor: 'icpCost',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Client price',
        accessor: 'clientPrice',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Margin',
        accessor: 'margin',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Min threshold',
        accessor: 'minThreshold',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Employees threshold',
        accessor: 'employeesThreshold',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
      },
    ]}
    wrapperClassName='u-1/1'
    trClassName='react-bs-table__cell--whitespace--normal'
  />
)

export default CostingTable
