import createSelector from 'utils/createSelector'

const getPaygroupId = (state, props) => parseInt(props.paygroupId)

export const getFixedPaygroupTermByPaygroup = createSelector(getPaygroupId, ({ PaygroupTerm }, paygroupId) => {
  return PaygroupTerm.all().toModelArray()
    .filter(pivot => pivot.paygroup.id === paygroupId && pivot.companyCountryTerm.isFixed())
    .map(pivot => ({
      id: pivot.companyCountryTerm.id,
      amount: pivot.amount
    }))
})
