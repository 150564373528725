import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceVersion'

class PayrollInstanceVersion extends CRUDModel {}
PayrollInstanceVersion.modelName = 'PayrollInstanceVersion'
PayrollInstanceVersion.actionTypes = actionTypes

PayrollInstanceVersion.fields = {
  id: attr()
}

export default PayrollInstanceVersion
