import React from 'react'
import SectionHeading from 'components/SectionHeading'

export const HomeView = ({ isCot, versions }) => (
  <div className='c-content-wrapper'>
    <SectionHeading text='Welcome!' />
    { isCot && <SectionHeading text={`Current FRONT-END revision version is: ${versions.frontEnd}`} /> }
    { isCot && <SectionHeading text={`Current BACK-END version is: ${versions.backEnd}`} /> }
    <div className='u-hidden-visually'>
      <h3>Click the below button in order to trigger an error</h3>
      <p><i>The idea here is to test our error reporting / monitoring tool in Production environment</i></p>
      <button onClick={() => ''.map()}>Trigger an error</button>
    </div>
  </div>
)

export default HomeView
