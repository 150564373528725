const images = {
  DUBAI: {
    NAME: 'dubai',
    URL: '/img/login-images/dubai.jpeg',
    DESCRIPTION: 'Dubai, United Arab Emirates'
  },
  DUBLIN: {
    NAME: 'dublin',
    URL: '/img/login-images/dublin.jpeg',
    DESCRIPTION: 'Dublin, Ireland'
  },
  EGYPT: {
    NAME: 'egypt',
    URL: '/img/login-images/egypt.jpeg',
    DESCRIPTION: 'Giza, Egypt'
  },
  NETHERLANDS: {
    NAME: 'netherlands',
    URL: '/img/login-images/netherlands.png',
    DESCRIPTION: 'Zaanstad, Netherlands'
  },
  PARIS: {
    NAME: 'paris',
    URL: '/img/login-images/paris.jpeg',
    DESCRIPTION: 'Paris, France'
  },
  ROME: {
    NAME: 'rome',
    URL: '/img/login-images/rome.jpeg',
    DESCRIPTION: 'Rome, Italy'
  },
  SANFRA: {
    NAME: 'sanfra',
    URL: '/img/login-images/sanfra.jpeg',
    DESCRIPTION: 'San Francisco, United States'
  },
  SWITZERLAND: {
    NAME: 'switzerland',
    URL: '/img/login-images/switzerland.png',
    DESCRIPTION: 'Oberried, Switzerland'
  },
  TORONTO: {
    NAME: 'toronto',
    URL: '/img/login-images/toronto.jpeg',
    DESCRIPTION: 'Toronto, Canada'
  },
  USA: {
    NAME: 'usa',
    URL: '/img/login-images/usa.jpeg',
    DESCRIPTION: 'Washington DC, United States'
  }
}

export const loginImages = [
  images.DUBAI,
  images.DUBLIN,
  images.NETHERLANDS,
  images.PARIS,
  images.ROME,
  images.SANFRA,
  images.SWITZERLAND,
  images.TORONTO,
  images.USA,
  images.EGYPT
]
