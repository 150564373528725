import React from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { fetchProcessesIfNeeded, createProcess } from 'redux/actions/processes'
import { fetchServicesIfNeeded, invalidateServices } from 'redux/actions/services'
import { getProcesses } from 'redux/selectors/processes'
import { getServices } from '../selectors/services'
import ProcessCreate from '../components/ProcessCreate'
import Processes from '../components/Processes'

class ProcessesContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { fetchServicesIfNeeded, fetchProcessesIfNeeded } = this.props
    fetchServicesIfNeeded()
    fetchProcessesIfNeeded()
  }

  render () {
    return <div>
      <ProcessCreate {...this.props} />
      <Processes {...this.props} />
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
  fetchProcessesIfNeeded: () => dispatch(fetchProcessesIfNeeded()),
  onSubmit: data => dispatch(createProcess(data))
    .then(() => {
      dispatch(invalidateServices())
      dispatch(reset('process'))
    })
})

const mapStateToProps = (state) => ({
  services: getServices(state),
  processes : getProcesses(state),
  isFetching : state.processes.isFetching || state.services.isFetching
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:
 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })
 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(ProcessesContainer)
