import React from 'react'
import BankAccountView from '../components/BankAccountView'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { sortByName } from 'utils/strings'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchBankAccountsIfNeeded, deleteBankAccount } from 'redux/actions/bankAccounts'
import { fetchBankAccountTypesIfNeeded } from 'redux/actions/bankAccountTypes'
import { getBankAccounts, getBankAccountsByCompany, getBankAccountsByEmployee } from 'redux/selectors/bankAccounts'
import { getCompanies, getCompanyRef } from 'redux/selectors/company'
import { getCountries } from 'redux/selectors/country'
import CompanyBankAccountView from 'routes/Companies/routes/Company/routes/Bank/components/CompanyBankAccountView'
import PropTypes from 'prop-types'

class BankAccountContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCountriesIfNeeded()
    this.props.fetchCurrenciesIfNeeded()
    this.props.fetchBankAccountsIfNeeded()
    this.props.fetchBankAccountTypesIfNeeded()
    this.props.fetchCompaniesIfNeeded()
  }

  render () {
    const { isCompany } = this.props
    return <>
      {isCompany
        ? <CompanyBankAccountView {...this.props} />
        : <BankAccountView {...this.props} />
      }
    </>
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch,
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchCurrenciesIfNeeded: () => dispatch(fetchCurrenciesIfNeeded()),
    fetchBankAccountsIfNeeded: () => dispatch(fetchBankAccountsIfNeeded()),
    fetchBankAccountTypesIfNeeded: () => dispatch(fetchBankAccountTypesIfNeeded()),
    deleteBankAccount: (id) => dispatch(deleteBankAccount(id))
  }
}

/**
 * Get BankAccounts,
 * according to router params, as `companyId` and `employeeId`.
 *
 * If we're loading the component in the Company routes,
 * then we get the accounts for the current Company.
 * If the Company is HQ, then we return all BankAccounts.
 *
 * If we're loading the component in the Employee routes,
 * then we get the accounts for the current Employee.
 *
 * @param state
 * @param props
 * @returns {*}
 * @private
 */
const _getBankAccounts = (state, props) => {
  const { match: { params: { companyId, employeeId } } } = props

  if (companyId) {
    const company = getCompanyRef(state, { companyId })

    if (company.isHQ) return getBankAccounts(state)

    return getBankAccountsByCompany(state, { companyId })
  }

  return getBankAccountsByEmployee(state, { employeeId })
}

// map to state
const mapStateToProps = (state, props) => {
  const { countries, companies, currencies, bankAccounts, bankAccountTypes } = state

  if (isFetching([countries, companies, currencies, bankAccounts, bankAccountTypes])) return { isFetching: true }
  return {
    bankAccounts: _getBankAccounts(state, props),
    showText: props.match.params.employeeId,
    companies: getCompanies(state),
    countries: getCountries(state).sort(sortByName)
  }
}

BankAccountContainer.propTypes = {
  fetchCountriesIfNeeded: PropTypes.func,
  fetchCurrenciesIfNeeded: PropTypes.func,
  fetchBankAccountsIfNeeded: PropTypes.func,
  fetchBankAccountTypesIfNeeded: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  isCompany: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountContainer)
