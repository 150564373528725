import { getStorageItem, removeItemFromLocalStorage } from './storage'

/**
 * Retrieves job result from local storage if the job ID matches the stored job ID.
 *
 * @function
 * @param {string|number} jobId - The ID of the job to retrieve result for.
 * @returns {Object} The job result object if the job ID matches, otherwise an empty object.
 *
 * This function checks the local storage for a `jobResult` item. If no `jobId` is provided or
 * the stored job ID does not match the given `jobId`, the `jobResult` item is removed from
 * local storage and an empty object is returned. If a match is found, the job results are returned.
 */
export const getJobResult = (jobId) => {
  if (!jobId) {
    removeItemFromLocalStorage('jobResult')
    return {}
  }

  const rawJob = getStorageItem('jobResult')
  const parsedJob = rawJob ? JSON.parse(rawJob) : null

  if (!parsedJob || +jobId !== parsedJob.id) {
    removeItemFromLocalStorage('jobResult')
    return {}
  }

  return parsedJob
}
