import _ from 'lodash'

/**
 * Normalize error response for Create / Update API calls
 *
 * @param {Object} errorResponse - here we parse the following structure:
 *
 * ```
 * // errorsResponse object
 * {
 *   country: [
 *     'This value should not be blank.'
 *   ],
 * }
 * ```
 *
 * @link - https://gitlab.com/dev-labs-bg/payslip/wikis/error-messages#create-update
 *
 * @return {{}}
 */
export const normalize = errorResponse => {
  let normalized = {}
  if (Array.isArray(errorResponse)) {
    errorResponse.forEach(err => {
      _.forOwn(err, (errors, fieldName) => {
        normalized[fieldName] = errors.map(error => error)
      })
    })
  } else {
    _.forOwn(errorResponse, (errors, fieldName) => {
      normalized[fieldName] = errors.map(error => error)
    })
  }

  Object.keys(normalized).forEach(key => {
    if (key.indexOf('.') === -1) return
    const values = key.split('.')
    const objName = values[values.length - 1]
    values.pop()
    const newKey = values.join('-')
    normalized[newKey] = { [objName]: normalized[key], ...normalized[newKey] }
    delete normalized[key]
  })

  return normalized
}

/**
 * Normalize error response for many-to-many calls
 *
 * Please refer to utils/redux/actions/apiThunks.js,
 * to check the possible many-to-many calls.
 *
 * @param {Object} errorResponse - here we parse the following structure:
 *
 * ```
 * // errorsResponse object
 * {
 *   "errors": {
 *      "5": {
 *         "paygroup":[
 *            "Frequency of payroll and paygroup do not match.",
 *            "Currency of payroll and paygroup do not match.",
 *            "Paygroup is already assigned to an on-cycle payroll."
 *         ]
 *      }
 *   }
 * }
 *
 * ```
 *
 * @link - https://gitlab.com/dev-labs-bg/payslip/wikis/error-messages#attach-paygroups-to-payroll
 *
 * @return {{}}
 */
export const pivotNormalize = errorResponse => {
  let normalized = {}

  _.forOwn(errorResponse, (error, id) => {
    const fieldName = `id-${id}`

    _.forOwn(error, function (errors) {
      normalized[fieldName] = errors
    })
  })

  return normalized
}

// Basically the same as @pivotNormalize except that it will take the extra
// parameter to allow it to be dynamic
export const pivotNestedNamedNormalize = (errorResponse, fieldPrefix) => {
  let normalized = {}
  _.forOwn(errorResponse, (error, id) => {
    const fieldName = `${fieldPrefix}-${id}`

    _.forOwn(error, function (errors, name) {
      const fieldKey = `${fieldName}-${name}`
      normalized[fieldKey] = errors
    })
  })
  return normalized
}

// Experimental functionality
export const pivotJSONNormalize = errorResponse => {
  let normalized = {}

  _.forOwn(errorResponse, (error, id) => {
    const fieldName = JSON.stringify({ id })

    _.forOwn(error, function (errors) {
      normalized[fieldName] = errors
    })
  })

  return normalized
}

// Experimental functionality
export const pivotNestedJSONNormalize = errorResponse => {
  let normalized = {}

  _.forOwn(errorResponse, (error, id) => {
    const fieldName = JSON.stringify({ id: parseInt(id) })

    normalized[fieldName] = error
  })

  return normalized
}

/**
 * Convert `SubmissionError` object to string sentence
 *
 * @param {SubmissionError} error
 * @param {Boolean} includeFieldName - Should display the wrong field name in the final normalized errors?
 */
export const errorToString = (error, includeFieldName = true) => {
  const normalizedErrors = []

  _.forOwn(error.errors, (errors, fieldName) => {
    const capitalizedName = _.capitalize(fieldName)
    // The first error message should begin with uppercase letter, the rest ones with lower
    const capitalizedErrors = errors.map((error, i) => i ? _.lowerFirst(error) : _.upperFirst(error)).join(', ')
    // `redux-form` API expects the generic form errors, to be attached to `_error` field.
    const isGenericError = fieldName === '_error'
    // Should we include the error field name or not?
    let normalizedError = includeFieldName && !isGenericError
      ? `${capitalizedName} - ${capitalizedErrors}` : capitalizedErrors

    // Add full stop at the end, if it's not already there
    normalizedError = normalizedError.charAt(normalizedError.length - 1) !== '.' ? `${normalizedError}.` : normalizedError

    normalizedErrors.push(normalizedError)
  })

  return normalizedErrors.join(' ')
}
