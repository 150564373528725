import React from 'react'
import PropTypes from 'prop-types'
import StepperConfirmationModal from './StepperConfirmationModal'
import Loader from './Loader'

const ProcessingSSOModal = React.forwardRef((props, ref) => {
  const {
    isSSOSubmitting,
    isUserSSOEnabled,
    onModalStateChange,
    isEmployeeUser = false,
    errors = []
  } = props

  const hasErrors = errors?.length > 0

  return <StepperConfirmationModal
    ref={ref}
    className={`c-modal ${(isSSOSubmitting && !hasErrors) ? 'hide-modal-close' : ''}`}
    disableBackdropClose={isSSOSubmitting}
    onHide={() => isEmployeeUser && onModalStateChange({ showSubmitSSOConfirmModal: false })}
    modalHeading={hasErrors ? 'Error' : ''}
  >
    {
      hasErrors && <div className='u-text--center u-text--normal u-weight--regular'>
        {errors.map((e, i) => <p key={i} className='u-text--large u-margin-bottom-small'>{e}</p>)}
      </div>
    }

    {
      !hasErrors && <div className='u-text--center u-text--normal u-weight--regular'>
        {
          isSSOSubmitting && (
            <>
              <Loader />
              <div>{isUserSSOEnabled ? 'Disabling SSO' : 'Enabling SSO'}</div>
            </>
          )
        }
        {
          !isSSOSubmitting && (
            <h6 className='u-margin-top-tiny'>SSO is successfully {isUserSSOEnabled ? 'enabled' : 'disabled'}</h6>
          )
        }
      </div>
    }
  </StepperConfirmationModal>
})

ProcessingSSOModal.propTypes = {
  isSSOSubmitting: PropTypes.bool,
  isUserSSOEnabled: PropTypes.bool,
  onModalStateChange: PropTypes.func,
  isEmployeeUser: PropTypes.bool,
  errors: PropTypes.array,
}

export default ProcessingSSOModal
