import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import EmergencyPersonForm from '../components/EmergencyPersonForm'
import { updateEmployeeEmergencyPerson } from 'redux/actions/employeeSystemUsers'

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: (data) =>
    dispatch(updateEmployeeEmergencyPerson(data, props.match.params.employeeId, false, true)).then(() => {
      props.onSubmit()
    }),
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'EmergencyPersonCreate',
  })(EmergencyPersonForm)
)
