import HomeContainer from './containers/HomeContainer'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import HomeVendorRoute from './routes/HomeVendor'

export default (store) => ({
  path : '/home',
  indexRoute: { component: HomeContainer },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ HomeVendorRoute(store) ]
})
