import loadable from '@loadable/component'
import { ALL_ROUTES } from 'configs/routes'
import CoreLayout from 'layouts/PageLayout/PageLayout'
const RouteViewLoadable = loadable(() => import('./components/RouteView'))

export default (store) => ({
  path : `/${ALL_ROUTES.COLOR_PICKER_GENERATOR}`,
  indexRoute: { component: RouteViewLoadable },
  authProtected: true,
  wrappedComponent: CoreLayout,
})
