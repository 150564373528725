import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ConfirmationModal from 'components/ConfirmationModal'
import ReportItem from 'routes/Reporting/components/ReportItem'
import Modal from 'components/Modal'
import ReportResultsModalView from 'routes/Reporting/components/ReportResultsModalView'

class SavedReportsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      reportId: null,
      reportName: null
    }
  }

  handleModalOpen (modalRef, report) {
    this.setState({ reportId: report.id, reportName: report.name })
    modalRef.showModal()
  }

  render () {
    const { reports, deleteReport } = this.props

    return <div>
      <SectionHeading text='Recent reports' />
      <Modal
        ref='modalViewReportModal'
        className='c-modal c-modal--full c-modal--overflow-y'
        topLevelClassnames='u-margin-top-small'
        modalHeading=''
        data-testid='view-report-modal'
      >
        <ReportResultsModalView reportId={this.state.reportId} />
      </Modal>
      <ConfirmationModal
        ref='deleteReport'
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => deleteReport(this.state.reportId)}
      >
        <p>Are you sure you want to delete the report?</p>
      </ConfirmationModal>

      <div className='o-layout'>
        {
          reports.map(report => <ReportItem
            key={report.id}
            report={report}
            showSave={!report.saved}
            onSaveReport={this.props.onSaveReport}
            handleReportModalOpen={() => this.handleModalOpen(this.refs.modalViewReportModal, report)}
            onDelete={() => { this.handleModalOpen(this.refs.deleteReport, report) }}
            {...this.props} />)
        }
      </div>
    </div>
  }
}

SavedReportsList.propTypes = {
  reports: PropTypes.array,
  deleteReport: PropTypes.func,
  onSaveReport: PropTypes.func
}

export default SavedReportsList
