export const buttonActions = {
  download: 'Download',
  run: 'Run',
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  add: 'Add',
  delete: 'Delete',
  history: 'History',
  ok: 'OK',
  actions: 'Actions',
  share: 'Share',
  uploadFile: 'Upload file',
  selectAll: 'Select All',
  deselectAll: 'Deselect All',
}

export const placeholders = {
  select: 'Select',
  selectAll: 'Select all',
  none: 'None',
  selectVersion: 'Select version',
  pleaseSelect: 'Please select',
  noResultsFound: 'No results found',
  noFieldsAvailable: 'No fields available',
  loading: 'Loading...',
}

export const status = {
  loading: 'Loading',
}

export const textLabels = {
  clients: 'Clients',
  accessAreas: 'Access Areas',
  role: 'Role',
  name: 'Name',
  description: 'Description',
  type: 'Type',
  version: 'Version',
  gtnVersion: 'GTN Version',
  submitTicket: 'Submit a Ticket',
  status: 'Status',
  category: 'Category',
  payrollName: 'Payroll Name',
  gtnValidationStatus: 'File Validation Status',
}

export const payslipLegends = {
  poweredBy: 'Powered by:',
  pb: 'PB:',
}

export const optionLabels = {
  yes: 'Yes',
  no: 'No',
  active: 'Active',
  inactive: 'Inactive',
  disable: 'Disable',
  enable: 'Enable',
  enabled: 'Enabled',
  disabled: 'Disabled',
}

export const modalHeadings = {
  confirmation: 'Confirmation',
}

export const userRelatedActions = {
  changePassword: 'Change Password',
}

export const gtnImportStatusLabels = {
  none: 'None',
  successful: 'Successful',
  unsuccessful: 'Unsuccessful',
}

export const glStatusLabels = {
  balanced: 'Balanced',
  notBalanced: 'Not Balanced',
}

export const inputsValidationReportStatusLabels = {
  blocked: 'Blocked',
  error: 'Error',
  success: 'Success',
  warning: 'Warning',
}

export const uploadTextLabels = {
  uploadedOn: 'Uploaded on',
  uploadedBy: 'Uploaded by',
}

export const categoryLabels = {
  company: 'Company',
  payroll: 'Payroll',
}

export const mode2faLabels = {
  email: 'Email',
  sms: 'SMS',
  totp: 'TOTP (recommended)',
}

export const downloadTextLabels = {
  excel: 'Excel',
  csv: 'CSV',
}

export const fieldLabels = {
  name: 'Report Name',
  fromDate: 'From Date',
  toDate: 'To Date',
  country: 'Country',
  company: 'Company',
  payroll: 'Payroll',
  payrun: 'Payrun',
  typeOfEmploymentEngagement: 'Type of Employment/Engagement',
  businessUnit: 'Business Unit',
  costCenter: 'Cost Center',
  department: 'Department',
}
