import React from 'react'
import CheckboxWrapper from './CheckboxWrapper'

// TO DO: PASS ERRORS HERE
const CheckboxStatic = ({ name, indicatorClassName, ...otherProps }) => {
  return <CheckboxWrapper indicatorClassName={indicatorClassName}>&nbsp;
    <input name={name} type='checkbox' {...otherProps} />
  </CheckboxWrapper>
}

export default CheckboxStatic
