export const disclaimers = {
  rightsDisabledReason: `These are default rights to all Global Owners and can't be restricted.`,
  noAlertsAndEmails: 'No alerts & Emails',
  workflowNotifications:
    'Selecting an option from this menu lets you manage email notifications related to your payroll workflow (steps and actions)' +
    ' processes only. You will still receive email notifications for notes and documents share.',
}

export const keyUserFieldLabels = {
  firstName: 'First Name *',
  lastName: 'Last Name *',
  title: 'Title',
  base: 'Base',
  company: 'Company *',
  businessUnit: 'Business Unit',
  position: 'Position',
  globalOwner: 'Global Owner',
  workEmail: 'Work Email *',
  officePhone: 'Office Phone',
  mobilePhone: 'Mobile Phone',
  skype: 'Skype',
  dob: 'Date Of Birth',
  gender: 'Gender',
  employeeId: 'Employee ID',
  employeeProfile: 'Employee Profile',
  paymentCurrency: 'Payment Currency *',
  startDate: 'Start Date *',
  accountStatus: 'Account Status',
  sso: 'SSO',
  username: 'Username',
  mode2fa: '2FA Mode',
  workflowNotifications: 'Workflow notifications',
  includeInComms: 'Include in Comms',
  countries: 'Countries *',
  accessAreas: 'Access Areas *',
  businessUnits: 'Business Units *',
  role: 'Role *',
  readOnly: 'Read Only',
  accessChangeLogReport: 'Access Change Log report',
  lockUnlockPayrunFile: 'Unlock/Lock Payrun File',
  gtnReImport: 'GTN Re-Import',
  payrollICC: 'Payroll Integrations Control Center',
  allPayrunDocuments: 'All Payrun Documents',
  reportDesigner: 'Report Designer',
}

export const keyUserButtonLabels = {
  resetPassword: 'Reset User Password',
  disable: 'Disable',
  enable: 'Enable',
  sso: 'SSO',
}

export const keyUserFieldOptionLabels = {
  gender: {
    male: 'Male',
    female: 'Female',
    notStated: 'Not stated',
    other: 'Other',
    unknown: 'Unknown',
  },
  workflowNotifications: {
    systemAndEmail: 'Alerts in system and emails',
    system: 'Alerts in system',
    noAlertsAndEmails: 'No Alerts & Emails',
  },
}

export const keyUserSectionHeadings = {
  specialRights: 'Special Rights',
}

export const keyUserActionConfirmations = {
  confirmResetPwd: 'Are you sure you want to reset the user password?',
}

export const keyUserTableHeadings = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
  position: 'Position',
  base: 'Base',
  role: 'Role',
  accessArea: 'Access Area',
  businessUnits: 'Business Units',
  action: 'Action',
}
