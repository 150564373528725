import React from 'react'
import thunk from 'redux-thunk'
import api, { getParams, SERVER_ERROR, TOKEN_ERROR } from 'utils/api'
import Promise from 'es6-promise'
import { resetAction } from 'redux/actions/persist'
import { showMessage } from 'redux/actions/modal'
import { isNetworkError } from 'utils/fetch'
import { clearStorageByNamepace } from 'utils/storage'

/**
 * Handle main (top level) errors
 *
 * Here we handle the errors, caught by utils/api._handleResponse
 *
 * @param {Error} error
 * @param {function} dispatch
 * @param {Object} state
 * @returns {*}
 */
export const handleMainErrors = (error, dispatch, { config, ...rest }, url = null) => {
  let message = ''

  if (url && url === 'reports' && (isNetworkError(error.message) || error.message === SERVER_ERROR)) {
    // We have no specific check for reports timing out.
    // The backend is generating the report, just cloudfront times out
    // Also based on browser the returned error can vary.  So we need to check all three scenarios
    message = (
      <span>
        <h2 className='u-margin-none u-text--huge u-weight--bold'>Your report is in progress</h2>
        <span>
          Look for it in the Recent Reports tab soon.
          <br />
          Any questions? contact us at support
        </span>
      </span>
    )
  } else if (isNetworkError(error.message)) {
    // If it's a network error (no internet connection), then we set our custom message
    message = 'It looks like you have network issue, please refresh the page. If the issue persists contact us at support'
  } else if (error.message === SERVER_ERROR) {
    // We just want to transform the SERVER_ERROR to JSX, because it's not possible to pass JSX via Error object.
    // TODO - find a better way. Previously I tried with a custom Error object, but in the production build,
    // there was a problem with the Error type and we can't differentiate the Errors.
    // However, have to do it better.
    message = <span>Something wrong happened on the server side, please refresh the page. If the issue persists contact us at support</span>
  } else {
    message = error.message
  }

  // If the token is expired or blacklisted on the BE,
  // then we reset the Store and we logout the user from the app
  if (message === TOKEN_ERROR) {
    const keycloakInstance = window.keycloakInstance
    if (keycloakInstance && keycloakInstance?.authenticated) {
      console.error('[Keycloak Token Error] - TOKEN_ERROR , Closing session after authentication failure')
      clearStorageByNamepace()
      keycloakInstance.logout()
    }
    if (!config?.migratedToRHSSO) {
      dispatch(resetAction({ config }))
    }
  }

  // Show error message to the user in a modal
  dispatch(showMessage({ body: message }))

  // Reject the promise, in order to prevent future chain execution (.then())
  return Promise.reject(error)
}

/**
 * Middleware that decorates `thunk` as passing the API utility.
 *
 * Doing it in that way we decouple the API layer from the Store.
 * Otherwise the API is connected to the Store, that results in Circular dependency issue,
 * because of the following flow:
 * ` store/store -> orm models -> actions -> API -> store/store `
 *
 * Now we can safely pass any Store property, to the API!
 *
 * @param dispatch
 * @param getState
 * @returns {(next:Dispatch<S>)=>Dispatch<S>}
 */
export default ({ dispatch, getState }) => {
  const decorated = thunk.withExtraArgument({
    api: {
      fetch: (uri, params) => api.fetch(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      post: (uri, params) => api.post(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState(), uri)),
      put: (uri, params) => api.put(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      patch: (uri, params) => api.patch(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      delete: (uri, params) => api.delete(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
      upload: (uri, params) => api.upload(...getParams(getState(), uri, params)).catch((e) => handleMainErrors(e, dispatch, getState())),
    },
  })

  return decorated({ dispatch, getState })
}
