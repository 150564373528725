/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import FiltersEmployeeReport from '../../../components/FiltersEmployeeReport'
import { getCountriesByAuth } from 'redux/selectors/country'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchAccessAreaPivotIfNeeded } from 'redux/actions/accessAreaPivot'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth, getCompanyRef } from 'redux/selectors/company'
import { getCostCentersByCompany, getCostCentersByCompanies, getCostCentersWithCompanyWithCountry } from 'redux/selectors/costCenters'
import { getDepartmentsByCompany, getDepartmentsByCompanies, getDepartmentsWithCompanyWithCountry } from 'redux/selectors/departments'
import { getBusinessUnitsByCompany, getBusinessUnitsByCompanies, getBusinessUnitsWithCompanyWithCountry } from 'redux/selectors/businessUnits'
import { sortByName } from 'utils/strings'

class FiltersEmployeeReportContainer extends React.Component {
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props

    dispatch(fetchBusinessUnitsIfNeeded())
    dispatch(fetchCostCentersIfNeeded())
    dispatch(fetchDepartmentsIfNeeded())
    dispatch(fetchAccessAreaPivotIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <FiltersEmployeeReport {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const { countries, companies, businessUnits, costCenters, departments, accessAreaPivot } = state
  if (isFetching([countries, companies, businessUnits, costCenters, departments, accessAreaPivot])) return { isFetching: true }
  const selector = formValueSelector(props.form)
  const values = selector(state, 'subcategory', 'fromDate', 'toDate', 'country', 'company', 'payroll', 'businessUnit', 'costCenter', 'departments')

  const companyId = values.company ? values.company : null
  const isHQ = values.company && values.company.length ? getCompanyRef(state, { companyId }).isHQ : null
  return {
    // All selected values
    selected: values,
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, {
        countriesIds: isArray(values.country) ? values.country : [values.country],
      })
      : getCompaniesByAuth(state, props),
    // Business Units by selected countries
    businessUnits:
      values.company && values.company.length
        ? getBusinessUnitsByCompanies(state, { companiesIds: values.company }).sort(sortByName)
        : isHQ
          ? getBusinessUnitsWithCompanyWithCountry(state).sort(sortByName)
          : getBusinessUnitsByCompany(state, { companyId }).sort(sortByName),
    // Cost Centers by selected countries
    costCenters:
      values.company && values.company.length
        ? getCostCentersByCompanies(state, { companiesIds: values.company }).sort(sortByName)
        : isHQ
          ? getCostCentersWithCompanyWithCountry(state).sort(sortByName)
          : getCostCentersByCompany(state, { companyId }).sort(sortByName),
    // Departments by selected countries
    departments:
      values.company && values.company.length
        ? getDepartmentsByCompanies(state, { companiesIds: values.company }).sort(sortByName)
        : isHQ
          ? getDepartmentsWithCompanyWithCountry(state).sort(sortByName)
          : getDepartmentsByCompany(state, { companyId }).sort(sortByName),
  }
}

FiltersEmployeeReportContainer.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps)(FiltersEmployeeReportContainer)
