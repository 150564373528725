import React from 'react'

import Loader from 'components/Loader'

import { getRegeneratedReportDesigner } from 'routes/Reporting/utils/regenerateReport'
import { useFetchReportingData } from 'hooks/useFetchReportingData'

export const ReportDesignerLayout = ({ children }) => (
  <div className='c-panel u-margin-top u-margin-bottom u-bg--white border-1-valhalla rounded u-padding-small u-bg--lilac'>{children}</div>
)

export const buildReportDesigner = (props, Container) => {
  const defaultData = getRegeneratedReportDesigner(props?.history)
  const { isLoading, fetchChangedFieldData, isFetchingDependentFields } = useFetchReportingData({ defaultData })

  if (isLoading) {
    return (
      <ReportDesignerLayout>
        <div className='d-flex jc--center ai-center w-full h-full'>
          <Loader />
        </div>
      </ReportDesignerLayout>
    )
  }
  return <Container {...props} defaultData={defaultData} fetchChangedFieldData={fetchChangedFieldData} isFetchingDependentFields={isFetchingDependentFields} />
}
