import React from 'react'

import { FloatingDialog, FloatingDialogContent, FloatingDialogDescription } from 'components/popover/FloatingDialog'
import ReportResultsModalView from 'routes/Reporting/components/ReportResultsModalView'

export const ReportDesignerModals = ({ report, isOpenMostReportModal, setIsOpenMostReportModal, updateMostRecentReport }) => {
  const hideMostRecentModal = () => {
    setIsOpenMostReportModal(false)
    updateMostRecentReport(undefined)
  }
  return (
    <FloatingDialog open={isOpenMostReportModal} onOpenChange={setIsOpenMostReportModal}>
      <FloatingDialogContent className='floatingDialog c-modal--full c-modal--overflow-y' overlayClassName='floatingDialog--centered-overlay' withEx>
        <FloatingDialogDescription className='ps-pl-[24] ps-pr-[24]'>
          {report?.id && <ReportResultsModalView reportId={report.id} selectedReport={report?.feCategoryId} onDeleteCallBack={hideMostRecentModal} />}
        </FloatingDialogDescription>
      </FloatingDialogContent>
    </FloatingDialog>
  )
}
