import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCompaniesByCountriesByAuth, getCompanyRef } from 'redux/selectors/company'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getFilteredPayrolls } from 'redux/selectors/payroll'
import { getFilteredPayrollInstances } from 'redux/selectors/payrollInstance'
import { getBusinessUnitsBasedOnCompanyType } from 'redux/selectors/businessUnits'
import { getCostCentersBasedOnCompanyType } from 'redux/selectors/costCenters'
import { getDeparmentsBasedOnCompanyType } from 'redux/selectors/departments'

import { PAYROL_FILTER_NAME, PAYRUN_FILTER_NAME } from 'redux/filters/payrollFilter'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'

import { getMostRunRecentReport } from 'routes/Reporting/selectors/reports'
import { setLastCreatedJobId } from '../redux/actions/pollingJob'
import reportOptionsConfig from '../configs/reports'

/**
 * Use Reporting hook selector
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.filters - The filters to apply for the selectors.
 * @param {string|string[]} [options.filters.company] - The company or array of companies to filter by.
 * @param {string|string[]} [options.filters.country] - The country or array of countries to filter by.
 * @returns {Object} The selected reporting data.
 * @returns {Array} countries - The list of countries based on the authentication.
 * @returns {Array} payrolls - The list of filtered payrolls.
 * @returns {Array} payruns - The list of filtered payroll instances.
 * @returns {Array} companies - The list of companies based on the selected countries.
 * @returns {Array} businessUnits - The list of business units based on the company type (Multiple or Single company).
 * @returns {Array} costCenters - The list of cost centers based on the company type (Multiple or Single company).
 * @returns {Array} deparments - The list of departments based on the company type (Multiple or Single company).
 */
export const useReportingSelector = ({ filters }) => {
  const dispatch = useDispatch()
  const recentReport = useSelector(getMostRunRecentReport)
  const [mostRecentReport, setMostRecentReport] = useState()

  const companyId = filters?.company ? filters?.company : null
  /**
   * This check only works if the company selection is unique, given a long period of dates.
   */
  const isHQ = useSelector((state) => (isArray(companyId) && !isEmpty(companyId) ? getCompanyRef(state, { companyId }).isHQ : null))

  /*
   * Effects
   */
  useEffect(() => {
    const hasReport = reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE === recentReport?.feCategoryId
    if (hasReport) {
      setMostRecentReport(recentReport)
    }
  }, [recentReport])

  useEffect(() => {
    return () => {
      dispatch(setLastCreatedJobId(undefined))
    }
  }, [])

  /*
   * Selectors
   */
  const businessUnits = useSelector((state) =>
    getBusinessUnitsBasedOnCompanyType({
      state,
      isHQ,
      companiesIds: companyId,
    })
  )
  const costCenters = useSelector((state) =>
    getCostCentersBasedOnCompanyType({
      state,
      isHQ,
      companiesIds: companyId,
    })
  )

  const departments = useSelector((state) =>
    getDeparmentsBasedOnCompanyType({
      state,
      isHQ,
      companiesIds: companyId,
    })
  )

  const countries = useSelector((state) => getCountriesByAuth(state))
  const payrolls = useSelector((state) => getFilteredPayrolls(state, { filter: PAYROL_FILTER_NAME }))
  const payruns = useSelector((state) =>
    getFilteredPayrollInstances(state, { filter: PAYRUN_FILTER_NAME })?.map((payrun) => ({
      ...payrun,
      name: payrun.payrollNameAndPeriod,
    }))
  )
  const companies = useSelector((state) => {
    const selectedCountry = filters?.country
    if (!isEmpty(selectedCountry)) {
      return getCompaniesByCountriesByAuth(state, {
        countriesIds: isArray(selectedCountry) ? selectedCountry : [selectedCountry],
      })
    }
    return []
  })
  const updateMostRecentReport = (value) => setMostRecentReport(value)

  return {
    countries,
    payrolls,
    payruns,
    companies,
    businessUnits,
    costCenters,
    departments,
    mostRecentReport,
    updateMostRecentReport,
  }
}
