import React from 'react'
import FieldDatePicker from 'components/form/FieldDatePicker'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment'

let classNames = require('classnames')

/**
 * Color schemes for highlighting week ranges
 */
const curious = 'curious'
const inherit = 'inherit'

class CalendarToolbar extends React.Component {
  constructor (props) {
    super(props)
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
    this.state = {
      datePickerStartDate: moment(props.date).toDate()
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    // let localDate = [...this.state.datePickerStartDate]
    // Here we check the day only, without the milliseconds,
    // because react-big-calendar always passes new date and the milliseconds differ
    if (!moment(this.state.datePickerStartDate).isSame(nextProps.date, 'day')) {
      this.setState({ datePickerStartDate: moment(nextProps.date).toDate() })
      // Update `redux-form` Store field value
      this.props.change('calendarDatepicker', nextProps.date)
    }
  }

  getViewBtnsClassNames (view) {
    return classNames({
      'c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-left-small u-text--valhalla': true,
      'active': this.props.view === view
    })
  }

  /**
   * Highlight datepicker week, based on selected date and calendar view
   *
   * @param date
   * @param view
   * @returns {[*]}
   */
  highlightDatePickerWeek (date, view) {
    const color = view === 'week' ? curious : inherit

    let newDate = moment(date)
    let dateNumber = newDate.date()
    let dateWeekDay = newDate.day()
    let dateMonth = newDate.month()
    let dateYear = newDate.year()

    let firstWeekDay
    if (dateWeekDay) {
      firstWeekDay = dateNumber - dateWeekDay
    } else {
      firstWeekDay = dateNumber - 7
    }

    let firstWeekDayDate = moment().year(dateYear).month(dateMonth).date(firstWeekDay)

    return [
      { [`react-datepicker__day--highlighted-${color}`] : [
        moment(firstWeekDayDate).add(1, 'days').toDate(),
        moment(firstWeekDayDate).add(2, 'days').toDate(),
        moment(firstWeekDayDate).add(3, 'days').toDate(),
        moment(firstWeekDayDate).add(4, 'days').toDate(),
        moment(firstWeekDayDate).add(5, 'days').toDate(),
        moment(firstWeekDayDate).add(6, 'days').toDate(),
        moment(firstWeekDayDate).add(7, 'days').toDate()
      ] }
    ]
  }

  handleDatePickerChange (date) {
    this.setState({ datePickerStartDate: date }, () => this.props.onChanged(date))
  }

  render () {
    const { label, goToBack, goToNext, goToCurrent, goToDayView, goToWeekView, goToMonthView, view } = this.props
    return <div className={view === 'week' ? 'c-calendar__toolbar--week' : null}>
      <div className='o-layout u-margin-bottom'>
        <div className='o-layout__item u-padding-bottom-tiny u-1/1'>
          <div
            onClick={goToCurrent}
            // eslint-disable-next-line max-len
            className='c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-right-small u-margin-bottom-tiny u-text--valhalla' >
            Today
          </div>
          <div className='o-grid--inline-flex o-grid--middle'>
            <div className='u-cursor--pointer' style={{ height: '100%', width: '20px' }} onClick={goToBack}>
              <span
                className='icon--arrow left icon--arrow--hover-left' />
            </div>
            <div className='u-text--small u-padding-left-small u-padding-right-small u-relative'>
              {label}
              <Field
                className='c-calendar__datepicker'
                name='calendarDatepicker'
                component={FieldDatePicker}
                formGroupClassName='u-margin-none u-height-0'
                labelClassName='c-label u-text--valhalla'
                highlightDates={this.highlightDatePickerWeek(this.state.datePickerStartDate, view)}
                onChanged={this.handleDatePickerChange}
                todayButton='Today'
                selected={this.state.datePickerStartDate}
                showWeekNumbers
              />
            </div>
            <div className='u-cursor--pointer' style={{ height: '100%', width: '20px' }} onClick={goToNext}>
              <span className='icon--arrow right icon--arrow--hover-right' />
            </div>
          </div>
          <div
            onClick={goToDayView}
            className={this.getViewBtnsClassNames('day')} >
            Day
          </div>
          <div
            onClick={goToWeekView}
            className={this.getViewBtnsClassNames('week')} >
            Week
          </div>
          <div
            onClick={goToMonthView}
            className={this.getViewBtnsClassNames('month')} >
            Month
          </div>
        </div>
      </div>
    </div>
  }
}

export default reduxForm({
  form: 'calendarPicker'
})(CalendarToolbar)
