import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import moment from 'moment'
import { getHolidays, getHolidaysByCompany } from '../selectors/events'

class DayWrapper extends React.Component {
  renderDayBgStyle () {
    return {
      flexBasis: this.props.children.props.style ? this.props.children.props.style.flexBasis : null,
      maxWidth: this.props.children.props.style ? this.props.children.props.style.maxWidth : null,
    }
  }

  renderCurrentDayBg () {
    let date = this.props.value
    let isCurrentDate = moment(date).isSame(new Date(), 'day')
    if (isCurrentDate) return <div className='u-bg-current-day' />
  }

  renderHolidayIcon () {
    let date = this.props.value
    return this.props.holidays.map(holiday => {
      // If the difference on subtraction is 0
      // There is a holiday on current day, because the dates are equal
      let isHoliday = holiday.start - date
      if (isHoliday === 0) {
        return <div key={holiday.id} className='icon--calendar-holiday u-index--on-top' title={holiday.title} />
      }
    })
  }

  render () {
    return <div className={this.props.children.props.className} style={this.renderDayBgStyle()}>
      { this.renderCurrentDayBg() }
      { this.renderHolidayIcon() }
    </div>
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const selector = formValueSelector('filterTasks')
  const selectedCompany = selector(state, 'company')

  return {
    ...props,
    holidays: selectedCompany ? getHolidaysByCompany(state, { companyId: selectedCompany }) : getHolidays(state),
  }
}

export default connect(mapStateToProps)(DayWrapper)
