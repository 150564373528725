import React from 'react'
import Modal from 'components/Modal'
import InnerWrapper from 'layouts/InnerWrapper'
import ClientsContainer from '../containers/ClientsContainer'
import ReferralsEditContainer from '../containers/ReferralsEditContainer'
import ReferralsCreateContainer from '../containers/ReferralsCreateContainer'
class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      referralId: null
    }
  }

  render () {
    return <InnerWrapper>
      <ClientsContainer {...this.props}
        onEdit={(referralId) => {
          this.setState({
            referralId
          })
          this.refs.editModal.showModal()
        }}

        onCreate={(referralId) => {
          this.setState({
            referralId
          })
          this.refs.createModal.showModal()
        }}
      />

      <Modal
        ref='editModal'
        className='c-modal'
        modalHeading='Edit referral'
      >
        <ReferralsEditContainer
          referralId={this.state.referralId}
          onEdit={() => this.refs.editModal.hideModal()}
        />
      </Modal>

      <Modal
        ref='createModal'
        className='c-modal'
        modalHeading='Add referral'
      >
        <ReferralsCreateContainer
          referralId={this.state.referralId}
          onCreate={() => this.refs.createModal.hideModal()}
        />
      </Modal>
    </InnerWrapper>
  }
}

export default RouteView
