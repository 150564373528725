import React from 'react'
import { NavLink } from 'react-router-dom'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import { emailValidation } from 'utils/validations'

class ForgottenPasswordView extends React.Component {
  render () {
    return <div className='c-login__content-forgotten-pass'>
      <div className='o-grid o-grid--center'>
        <Form showSubmitBtn btnText='Send' colorClassBtn='c-btn--curious u-margin-top-none' btnFullWidth classNames='c-btn--rounded' {...this.props}>
          <div className='o-form-group u-margin-bottom-large'>
            <Field
              name='email'
              label='Please provide your email:'
              component={InputField}
              type='text'
              autoFocus
              placeholder='Your email'
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label c-label--forgotten-pass'
              validate={emailValidation}
            />
          </div>
        </Form>
      </div>
      <div className='o-grid o-grid--center'>
        <div className='u-text--center u-text--medium'>
          <span>
            Back to <NavLink to='/' className='u-text--small u-text--curious'>Login</NavLink>
          </span>
        </div>
      </div>
    </div>
  }
}

export default ForgottenPasswordView
