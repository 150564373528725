import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import PayslipTable from 'components/table/PayslipTable'

class ShareOutsideCompany extends React.Component {
  render () {
    const { externalVendorUsers, initialValues, isOwner, sharingActionText = 'Share' } = this.props
    return (
      <div data-testid='outsideCompany-tabBody'>
        <PayslipTable
          headings={[
            { accessor: 'fullname', Header: 'Name', disableSortBy: true },
            {
              accessor: 'share',
              Header: sharingActionText,
              setWidth: '200px',
              classNames: 'text-center',
              columnClassName: 'text-center u-padding-right-tiny',
              disableSortBy: true,
              disableFilterBy: true,
            },
          ]}
          data={externalVendorUsers.map((user) => ({
            ...user,
            share: (
              <Field
                type='checkbox'
                labelClassName='u-hidden'
                component={CheckboxField}
                className='control__indicator__input'
                indicatorClassName='control__indicator--center'
                formGroupClassName='u-margin-none'
                name={`${user.extraData.tenant}-${user.extraData.id}`}
                disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
                data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
              />
            ),
          }))}
          wrapperClassName='u-1/1 u-margin-bottom'
          options={{ noDataText: 'No users to share with.' }}
          trClassName='react-bs-table__cell--whitespace--normal'
        />
      </div>
    )
  }
}

ShareOutsideCompany.propTypes = {
  externalVendorUsers: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string,
}

export default ShareOutsideCompany
