import React from 'react'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { useSelector } from 'react-redux'
import { getVariableCompanyTerms } from 'redux/selectors/companyCountryTermPivot'
import CheckboxField from 'components/form/CheckboxField'
import { Field } from 'redux-form'

const VariableField = ({ term, disabled, disabledReason, className = 'u-1/1 u-1/2@tablet' }) => {
  return <div className={`o-layout__item u-margin-bottom ${className} c--variable-field`}>
    <div className='c-panel o-grid o-grid--middle min-height-72 u-bg--white border-1-valhalla rounded u-padding-small'>
      <Field
        type='checkbox'
        component={CheckboxField}
        className='control__indicator__input'
        label={term.fullName}
        name={`variable-term-${term.companyCountryTermId}`}
        disabled={disabled}
        disabledReason={disabledReason}
        formGroupClassName='u-margin-none'
      />
    </div>
  </div>
}

VariableField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  term: PropTypes.object,
}

const VariableEditPayElementsContainer = (props) => {
  const variableTerms = useSelector(state => getVariableCompanyTerms(state, { companyId: props.companyId }))
  return (
    <div className='d-flex d-flex--column ai-center c--variable-edit-pay-elements-container'>
      {variableTerms.map((term) => <VariableField key={term.fullName} term={term} />)}
    </div>
  )
}

VariableEditPayElementsContainer.propTypes = {
  companyId: PropTypes.number
}

export default Fetcher(VariableEditPayElementsContainer, [ 'terms', 'companyCountryTermPivot', 'countryTermPivot' ])
