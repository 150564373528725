import { withTheme } from '@rjsf/core'
import { DualSelectorWidget } from './widgets/DualSelectorWidget'
import { DateWidget } from './widgets/DateWidget'
import { SelectWidget } from './widgets/SelectWidget'
import { SubmitButtonTemplate } from './templates/SubmitButtonTemplate'
import { ErrorListTemplate, FieldErrorTemplate } from './templates/ErrorTemplate'
import { FieldTemplate } from './templates/FieldTemplate'

export const SchemaFormThemes = {
  Default: 'Default',
}

/**
 * @typedef {Object} WidgetProps
 * @typedef {Object} FieldProps
 * @typedef {Object} TemplateProps
 * @typedef {Object} Theme
 * @property {WidgetProps} [widgets] - Custom widgets used in the theme.
 * @property {FieldProps} [fields] - Custom fields used in the theme.
 * @property {TemplateProps} [templates] - Custom templates used in the theme.
 * @typedef {Object} Themes
 * @property {Theme} Default - The default theme configuration.
 */

const Themes = {
  Default: {
    widgets: { DateWidget, SelectWidget, DualSelectorWidget },
  },
}

export const getGlobalUISchema = () => ({
  'ui:options': {
    date: 'DateWidget',
    select: 'SelectWidget',
  },
})

export const getGlobalTemplates = () => ({
  ButtonTemplates: { SubmitButton: SubmitButtonTemplate },
  ErrorListTemplate,
  FieldErrorTemplate,
  FieldTemplate,
})

export const applySchemaFormTheme = (theme = SchemaFormThemes.Default) => withTheme(Themes[theme])
