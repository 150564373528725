import React from 'react'
import PropTypes from 'prop-types'
import Board from 'react-trello'
import AssignmentCard from './AssignmentCard'
import AssignmentListOptions from './AssignmentListOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { CompletedIcon, InProgressIcon, NotStartedIcon } from 'components/icons/trelloIcons'

let eventBus
const setEventBus = (handle) => {
  eventBus = handle
}

const MyLaneHeader = (props) => {
  return props.label
}

const iconTypes = Object.freeze({
  notStarted: 'not-started',
  inProgress: 'in-progress',
  completed: 'completed',
})

const getIcon = (label = iconTypes.notStarted) => {
  const icons = {
    [iconTypes.notStarted]: () => <NotStartedIcon className={`icon icon--${label} u-float--right`} />,
    [iconTypes.inProgress]: () => <InProgressIcon className={`icon icon--${label} u-float--right`} />,
    [iconTypes.completed]: () => <CompletedIcon className={`icon icon--${label} u-float--right`} />,
  }
  return icons[label] || icons[iconTypes.notStarted]
}

class AssignmentsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      assignment: null,
      from: null,
      to: null,
      bulkArr: [],
      isChangingStatus: false,
    }
  }

  renderAssignments () {
    return {
      lanes: this.props.groupedAssignments.lanes.map((lane) => {
        const createIcon = getIcon(lane.label)
        return {
          ...lane,
          label: (
            <header>
              <span className='lane-title u-padding-left-small'>{lane.title}</span>
              <span>
                {createIcon()}
                {lane.cards.length > 0 && (
                  <AssignmentListOptions
                    selectAll={() => this.selectAll(lane.cards.filter((c) => c.draggable).map((c) => c.id))}
                    deselectAll={() => this.deselectAll(lane.cards.map((c) => c.id))}
                  />
                )}
              </span>
            </header>
          ),
        }
      }),
    }
  }

  showModal () {
    this.refs.confirmModal.showModal()
  }

  handleConfirm () {
    const { bulkArr } = this.state

    if (bulkArr.length > 1) {
      const assignmentsArr = bulkArr.map((a) => this.props.assignmentById(a))
      this.props.onUpdate(assignmentsArr, this.state.to)
    } else {
      this.props.onUpdate([this.state.assignment], this.state.to)
    }
  }

  handleReject () {
    const { assignment, from, to } = this.state
    eventBus.publish({
      type: 'ADD_CARD',
      laneId: from,
      card: assignment,
    })
    eventBus.publish({ type: 'REMOVE_CARD', laneId: to, cardId: assignment.id })
    this.setState({ isChangingStatus: false })
  }

  toggleSingleSelection (cardId, allCards) {
    const { bulkArr } = this.state
    let selectedCard = allCards.filter((c) => c.id === cardId).shift()

    if (!bulkArr.find((i) => i === cardId) && selectedCard?.draggable) {
      this.setState({ bulkArr: [...bulkArr, cardId] })
    } else {
      this.setState({ bulkArr: bulkArr.filter((i) => i !== cardId) })
    }
  }

  selectAll (arr) {
    const { bulkArr } = this.state
    this.setState({ bulkArr: [...bulkArr, ...arr] })
  }

  deselectAll (arr) {
    const { bulkArr } = this.state
    const newArr = bulkArr.filter((i) => !arr.includes(i))
    this.setState({ bulkArr: [...newArr] })
  }

  buildConfirmationText () {
    const { bulkArr } = this.state

    if (bulkArr.length > 1) {
      return `Are you sure you want to change the status of all ${bulkArr.length} selected actions?`
    }

    return 'Are you sure you want to change the status of this action?'
  }

  renderCard = (props) => {
    return <AssignmentCard bulkArr={this.state.bulkArr} {...props} />
  }

  render () {
    let allCards = []
    this.props.groupedAssignments.lanes.forEach((lane) => {
      lane.cards.map((card) => {
        allCards.push(card)
        card['isSelected'] = false
        this.state.bulkArr.forEach((arr) => {
          if (arr === card.id && card.draggable) {
            card['isSelected'] = true
          }
        })
        return card
      })
    })
    return (
      <div style={{ width: '100%' }}>
        <Board
          components={{ LaneHeader: MyLaneHeader, Card: this.renderCard }}
          data={this.renderAssignments()}
          eventBusHandle={setEventBus}
          className={`c-draggableboard ${this.state.isChangingStatus ? 'u-half-opacity' : ''}`}
          customCardLayout
          handleDragStart={(cardId, laneId) => {
            // console.log('Draged ' + cardId + ' from ' + laneId)
          }}
          handleDragEnd={(cardId, sourceLaneId, targetLaneId) => {
            const assignment = this.props.assignmentById(cardId)

            this.setState(
              {
                assignment,
                from: sourceLaneId,
                to: targetLaneId,
              },
              () => {
                /* Show modal if drag&drop to another status column  */
                if (sourceLaneId !== targetLaneId) {
                  this.showModal()
                  this.setState({ isChangingStatus: true })
                }
              }
            )
          }}
          onCardClick={(cardId, metadata) => {
            this.toggleSingleSelection(cardId, allCards)
          }}
        />

        <ConfirmationModal
          ref='confirmModal'
          className='c-modal'
          modalHeading='Confirmation'
          onConfirm={() => this.handleConfirm()}
          onReject={() => this.handleReject()}
        >
          <p>{this.buildConfirmationText()}</p>
        </ConfirmationModal>
      </div>
    )
  }
}

AssignmentsList.propTypes = {
  groupedAssignments: PropTypes.object,
  assignmentById: PropTypes.func,
  onUpdate: PropTypes.func,
  isDraggable: PropTypes.bool,
}

export default AssignmentsList
