import createSelector from 'utils/createSelector'
import { uniqBy } from 'lodash'

const companiesIds = (state, props) => props.companiesIds
const categoriesIds = (state, props) => props.categoriesIds
const subcategoriesIds = (state, props) => props.subcategoriesIds
const showOnlyTotals = (state, props) => props.showOnlyTotals
const isPayAndTaxesReport = (state, props) => props.isPayAndTaxesReport || false

export const getCCTsByCompaniesAndCategories = createSelector(companiesIds, categoriesIds, showOnlyTotals, isPayAndTaxesReport,
  ({ CompanyCountryTerm }, companiesIds, categoriesIds, showOnlyTotals, isPayAndTaxesReport) => {
    const ccterms = CompanyCountryTerm.filter(cct => companiesIds.includes(cct.company))
      .toModelArray()
      .filter(entity => {
        const category = entity.countryTerm.term.termSubcategory.termCategory

        // Return CCTs, across the selected `categoriesIds` only.
        if (!categoriesIds.includes(category.id)) return false

        // For the listed categories we have a bit more complex filtration logic.
        // Please continue reading the next comments below.
        const hasAdvancedFiltration = ['Pay elements', 'Employer Contributions',
          'Employee Deductions', 'Employee Net Deductions'].includes(category.name)

        // If we don't have the advanced filtration logic enabled for the current category,
        // then just return the CCT.
        if (!hasAdvancedFiltration) return true

        // If report type is PayAndTaxesReport we should return all the entities and we should skip the check under,
        // since we do not have 'showOnlyTotals' filter in this report
        if (isPayAndTaxesReport) return entity

        // If the advanced filtration is enabled, then we perform additional validation,
        // and return CCTs, these are matching the required format `entity.format`.
        if (showOnlyTotals) {
          return entity.format !== 'Percentage' && entity.format !== 'Unit'
        } else {
          return entity.format !== 'Percentage'
        }
      })
      .map(pivot => ({
        id: pivot.countryTerm.term.id,
        name: pivot.countryTerm.term.name,
        subCategoryName: pivot.countryTerm.term.termSubcategory.name,
        subCategoryId: pivot.countryTerm.term.termSubcategory.id
      }))
    return uniqBy(ccterms, 'id')
  }
)

export const getCCTsSubCategoriesByCompaniesAndCategories = createSelector(getCCTsByCompaniesAndCategories,
  (session, ccterms) => {
    return uniqBy(ccterms, 'subCategoryId').map(subCat => ({
      id: subCat.subCategoryId,
      name: subCat.subCategoryName,
    }))
  }
)

export const getCCTsByCompaniesAndCategoriesAndSubcategories = createSelector(
  getCCTsByCompaniesAndCategories, subcategoriesIds,
  (session, ccts, subcategoriesIds) => ccts.filter(cct => subcategoriesIds.includes(cct.subCategoryId))
)
