import CoreLayout from 'layouts/PageLayout/PageLayout'
import loadable from '@loadable/component'
const RouteViewRouteLoadable = loadable(() => import('./components/RouteView'))

export default (store) => ({
  path : ':payrollInstanceId/add-employees',
  authProtected: true,
  exact: true,
  renderOutsideParent: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: RouteViewRouteLoadable },
})
