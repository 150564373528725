import React from 'react'
import PropTypes from 'prop-types'
import AccordionIcon from 'components/accordion/AccordionIcon'
import AccordionHeaderTitle from 'components/accordion/AccordionHeaderTitle'
import AccordionSubtitle from 'components/accordion/AccordionSubtitle'

const AccordionHeader = (props) =>
  <div className={`d-flex ${props.headerDisplayModifiers || ''}`}>
    <AccordionIcon {...props} />
    <div className='u-flex-grow--1'>
      <AccordionHeaderTitle {...props} />
      <AccordionSubtitle {...props} />
    </div>
  </div>

AccordionHeader.propTypes = {
  headerDisplayModifiers: PropTypes.string
}

export default AccordionHeader
