import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'routes/Terms/modules/actions'

class Term extends CRUDModel {}
Term.modelName = 'Term'
Term.actionTypes = actionTypes

Term.fields = {
  id: attr(),
  name: attr(),
  termSubcategory: fk('SubCategory', 'terms')
}

export default Term
