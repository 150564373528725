import React from 'react'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slick'
import { CommonIcon, COMMON_ICONS_TYPES } from './CommonIcons'

function SliderArrow (props) {
  const { onClick, type } = props
  return <div
    className={`c-slider__btn c-slider__btn--${type}`}
    onClick={onClick}
  >
    <CommonIcon type={COMMON_ICONS_TYPES.arrow} className={`c-slider__btn__arrow c-slider__btn__arrow--${type}`}
    />
  </div>
}

export const Slider = props => {
  const { dots, infinite, slidesToShow, slidesToScroll, variableWidth,
    nextArrowClasses, prevArrowClasses, responsive, className, children } = props
  const settings = {
    dots,
    infinite,
    slidesToShow,
    slidesToScroll,
    variableWidth,
    nextArrow: <SliderArrow type='next' className={nextArrowClasses} />,
    prevArrow: <SliderArrow type='prev' className={prevArrowClasses} />,
    responsive
  }
  return <ReactSlider className={`c-slider ${className}`} {...settings}>{children}</ReactSlider>
}

SliderArrow.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func
}

Slider.propTypes = {
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  variableWidth: PropTypes.bool,
  nextArrowClasses: PropTypes.string,
  prevArrowClasses: PropTypes.string,
  responsive: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.array

}
export default Slider
