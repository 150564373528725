import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { makeScaleValidation } from 'utils/validations'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FlagOption from 'components/form/select/FlagOption'
import FlagValue from 'components/form/select/FlagValue'
import { sortByName } from 'utils/strings'

const scaleError = scale => (
  `Reporting rate should have a maximum of ${scale} digits after the decimal point.`
)
const scaleValidations = makeScaleValidation(6, scaleError)

const CompanyFormFields = ({ isCot, isEdit, currencies, countries, types,
  HQCompanies, isHQRelationToHQ, onRelationToHQChange, timezones }) => {
  const currenciesOptions = currencies.map(c => ({
    value: c.id,
    label: c.abbreviature
  }))

  return <>
    <div className='o-layout o-grid--strech'>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='name'
            label='Company name *'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='name'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='address'
            label='Address'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='address'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='registrationNumber'
            label='Registration Number *'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='country'
            label='Entity Country *'
            component={CustomSelectField}
            optionComponent={FlagOption}
            valueComponent={FlagValue}
            options={countries
              .sort(sortByName)
              .map(c => ({
                value: c.id,
                label: c.name,
                abbreviature: c.abbreviature
              }))}
            placeholder='Select'
            className='c-custom-select--transparent'
            disabled={isEdit}
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='registrationAddress'
            label='Registered address'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='website'
            label='Website *'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='hq'
            label='HQ Company'
            component={CustomSelectField}
            optionComponent={FlagOption}
            valueComponent={FlagValue}
            options={HQCompanies.map(c => ({
              value: c.id,
              label: c.name,
              abbreviature: c.country.abbreviature
            }))}
            disabled={isHQRelationToHQ}
            disabledReason="A HQ Company can't be related to another HQ."
            placeholder='Select'
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='relationToHQ'
            label='Connection to HQ*'
            component={CustomSelectField}
            options={types}
            placeholder='Select'
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            onChanged={onRelationToHQChange}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='currency'
            label='Company Currency*'
            component={CustomSelectField}
            options={currenciesOptions}
            placeholder='Select'
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            data-testid='currency'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='reportingCurrency'
            label='Reporting Currency'
            component={CustomSelectField}
            options={currenciesOptions}
            placeholder='Select'
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='reportingRate'
            label='Reporting Rate'
            component={InputField}
            type='number'
            step={0.000001}
            validate={scaleValidations}
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='timezone'
            label='Timezone *'
            component={CustomSelectField}
            options={timezones.map(timezone => ({
              value: timezone.id,
              label: timezone.zone
            }))}
            placeholder='Select'
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='directors'
            label='Directors'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='code'
            label='Company Code'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='vatRegistrationNumber'
            label='VAT Registration Number'
            component={InputField}
            type='text'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
          />
        </div>
      </div>
    </div>
    <div className='o-layout u-padding-top-small u-padding-bottom-small'>
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          data-testid='submit'
        >
          Save
        </button>
      </div>
    </div>
  </>
}

CompanyFormFields.propTypes = {
  isEdit: PropTypes.bool,
  isCot: PropTypes.bool,
  isHQRelationToHQ: PropTypes.bool,
  onRelationToHQChange: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    abbreviature: PropTypes.string.isRequired
  })).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  HQCompanies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  timezones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    zone: PropTypes.string.isRequired
  })).isRequired
}

export default CompanyFormFields
