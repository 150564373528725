import React from 'react'
import PropTypes from 'prop-types'
import InputField from './InputField'
import { checkValue } from 'utils/strings'
import PasswordStrengthBox from './PasswordStrengthBox'

class PasswordInputField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      type: 'password',
      icon: 'icon--visible',
      isSelected: false
    }
    this.showHide = this.showHide.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  showHide () {
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input',
      icon: this.state.icon === 'icon--visible' ? 'icon--not-visible' : 'icon--visible'
    })
  }

  onFocus () {
    this.setState({
      isSelected: true
    })
  }

  onBlur () {
    this.setState({
      isSelected: false
    })
  }

  render () {
    const { input, className, showPasswordStrengthBox } = this.props
    const { hasNumber, hasCapitalLetter, hasSpecialSymbol, hasMinimumLength, hasNoAdjacentRepeatingChars } = checkValue(input.value)

    return <InputField
      {...this.props}
      type={this.state.type}
      className={`${className} c-input--pass`}
      onChange={e => {
        input.onChange(e)
      }}
      onFocus={() => this.onFocus()}
      onBlur={() => this.onBlur()}
    >
      <div>
        <span className={'icon icon--visible u-cursor--pointer ' + this.state.icon} onClick={this.showHide} />
        <PasswordStrengthBox
          showPasswordStrengthBox={showPasswordStrengthBox}
          isSelected={this.state.isSelected}
          hasNumber={hasNumber}
          hasCapitalLetter={hasCapitalLetter}
          hasSpecialSymbol={hasSpecialSymbol}
          hasMinimumLength={hasMinimumLength}
          hasNoAdjacentRepeatingChars={hasNoAdjacentRepeatingChars}
        />
      </div>
    </InputField>
  }
}

PasswordInputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  passwordField: PropTypes.bool,
}

export default PasswordInputField
