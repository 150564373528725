import download from 'downloadjs'
import { isIOS } from 'mobile-device-detect'
import _ from 'lodash'
import qs from 'qs'
import { showMessage } from 'redux/actions/modal'
import { startToastFileGeneration } from 'redux/actions/toasts'
export default function (uri, params, actionCreator, errorsHandling, reducerName) {
  this.fetch = ({ filter, disableObsoleteFlow, ...rest } = {}) => {
    return async (dispatch, getState, { api }) => {
      // Keep track when the request is triggered
      const requestedAt = Date.now()
      const filterPayload = { ...(filter ? { filter, requestedAt } : { requestedAt }) }
      dispatch(actionCreator.fetch(filterPayload))

      const response = await api.fetch(filter ? `${uri}?${filter.uri}` : uri, { ...params, ...rest }).then((response) => {
        const filters = getState()[reducerName].filters

        if (response.statusCode === 403 && response.errorsCommon && actionCreator.receiveWithErrors) {
          dispatch(actionCreator.receiveWithErrors({ errors: response.errorsCommon, ...filterPayload }))
        }
        // Check whether the response is already outdated, in the case we filter a resource several times in a very
        // short period of time. Let me explain the use-case:
        // 1. We're on the Employees search page, where we have a static `search` filter name.
        // 2. We do a heavy search, that will take more than ~3 seconds.
        // 3. But without waiting for the response of above request, we decide to do new lightweight request,
        // that will take ~1 second and its response will be returned faster than the above request.
        // What's the problem?
        // Without this outdated check, response from step 3. will be overwritten by the response of step 2. and
        // outdated data will be kept in the Store.
        // Because of this, we keep track and compare the requests' triggered time (`requestedAt`) and we keep
        // the most recent data in the Store only.
        // In short: if the data in the Store is requested more recently,
        // then we don't care about the outdated / slow requests' responses.
        if (filter && filters[filter.name].requestedAt > requestedAt) return

        // We make it possible to disable the `CRUDModel.deleteObsoletedEntities` flow,
        // because for some use-cases, for example `Employee -> Search`, where we have a static filter name `search`,
        // it will delete Employees, these aren't deleted on the BE.
        // TODO - we have to rethink the flow.
        const prevFilterState = {
          ...(filter && !disableObsoleteFlow
            ? {
              prevFilterState: filters[filter.name],
            }
            : {}),
        }

        return dispatch(actionCreator.receive({ response, ...filterPayload, ...prevFilterState }))
      })
      return response
    }
  }

  this.create = (entity, { shouldFetch = true, shouldInvalidate = false, ...rest } = {}) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.create(entity))

      return api
        .post(uri, { ...params, ...rest, payload: entity })
        .then(errorsHandling.handleFormErrors)
        .then((response) => {
          if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
          if (shouldFetch) dispatch(this.fetch(rest))

          return response
        })
    }
  }

  this.createWithPolling = (entity, { childUri, params = {}, options = {}, ...rest } = {}, file = null) => {
    let method = 'post'

    return async (dispatch, getState, { api }) => {
      await dispatch(actionCreator.setShouldPoll({ enablePolling: true }))
      const shouldStart = !options.holdTillComplete
      await dispatch(actionCreator.setStartPolling({ shouldStartPolling: shouldStart }))
      const builtUrl = `${uri}${childUri ? `/${childUri}` : ''}`
      if (file) {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('entityId', entity.entityId)
        if (entity.company) formData.append('company', entity.company)
        entity = formData
        method = 'upload'
      }
      return api[method](builtUrl, { ...params, ...rest, payload: entity }).then((response) => {
        dispatch(actionCreator.setShouldPoll({ enablePolling: true }))
        dispatch(actionCreator.setStartPolling({ shouldStartPolling: true }))
        dispatch(startToastFileGeneration(response.id, response))
        return response
      })
    }
  }

  this.createWithoutForm = (entity, { shouldFetch = true, shouldInvalidate = false, ...rest } = {}) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.create(entity))

      return api
        .post(uri, { ...params, ...rest, payload: entity })
        .then(errorsHandling.handleReturnErrors)
        .then((response) => {
          if (response.errors) return response
          if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
          if (shouldFetch) dispatch(this.fetch(rest))

          return response
        })
    }
  }

  /**
   * Update Entity
   * @param {Object} entity - Data, that be sent to the API
   * @param {Number} id - ID of the entity, that will be updated
   * @param {bool} shouldFetch - should we fetch the entity after the update?
   * Most of the times, when we update an entity, we also fetch it after that, in order to have the latest backend data.
   * But there is a case, where we need to wait until all related entities are updated too.
   * Here I'll try to explain it better with an example:
   *
   * 1. We're updating an Employee and its CompanyCountryTerms. The both models are on a same UI page.
   * 2. We have to trigger CompanyCountryTerms update, only if Employee request is successful.
   * 3. When the Employee is updated, then we start fetching the Employee again, and here we do the same for CompanyCountryTerms.
   * 4. So the problem is that the UI will show 2 times the Loading component - one time for the Employee,
   and later for  CompanyCountryTerms. That's because of the synchronous calls we made. Here is the pseudo code:
   * 4.1. `Employee.update().then(() => CompanyCountryTerms.update())`
   * 5. So the solution is to trigger async fetching calls, when both of the entities are updates, something like that pseudo:
   * 5.1. `Employee.update({shouldFetch: false})
   *   .then(() => CompanyCountryTerms.update({shouldFetch: false}))
   *   .then(() => {
   *     // Here `isFetching` flag will be changed to the both entities as the same time,
   *     // and only one time the Loading component will be shown!
   *     Employee.fetch()
   *     CompanyCountryTerms.fetch()
   *   })`
   *
   * @returns {function(*=)}
   */
  this.update = (entity, id, shouldFetch = true, shouldInvalidate = false, returnInlineErrors = false) => {
    return async (dispatch, getState, { api }) => {
      dispatch(actionCreator.update(entity))
      const resp = await api
        .patch(uri + '/' + id, { ...params, payload: entity })
        .then(returnInlineErrors ? null : errorsHandling.handleFormErrors)
        .then((response) => {
          if (response.errors && returnInlineErrors) return response
          if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
          if (shouldFetch) dispatch(this.fetch())

          return response
        })
      return resp
    }
  }

  this.updateWithChildUri = (entity, id, childUri, shouldFetch = true, shouldInvalidate = false, params = {}) => {
    return async (dispatch, getState, { api }) => {
      dispatch(actionCreator.updateWithChildUri(entity))
      const resp = await api.patch(`${uri}/${id}/${childUri}`, { ...params, payload: entity }).then((response) => {
        if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
        if (shouldFetch) dispatch(this.fetch())

        return response
      })
      return resp
    }
  }

  /**
   * Bulk update Entity
   * @param {Object} entity - Data, that be sent to the API
   * @param {bool} shouldFetch - should we fetch the entity after the update?
   */
  this.bulkUpdate = (entity, shouldFetch = true) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.update(entity))
      return api
        .patch(uri, { ...params, payload: entity })
        .then(errorsHandling.handlePivotFormErrors)
        .then((response) => {
          if (shouldFetch) dispatch(this.fetch())

          return response
        })
    }
  }

  /**
   * Delete Entity
   * @param {Number} id - ID of the entity, that will be deleted
   * @param {bool} shouldFetch - should we fetch the entity after the update?
   * Most of the times, when we delete an entity, we also fetch it after that, in order to have the latest backend data.
   *
   * @returns {function(*=)}
   */
  this.delete = (id, shouldFetch = true, shouldInvalidate = false) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.delete(id))

      return api
        .delete(uri + '/' + id)
        .then(errorsHandling.handleFormErrors)
        .then((response) => {
          if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
          if (shouldFetch) dispatch(this.fetch())

          return response
        })
    }
  }

  this.filter = ({ filters }) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.filter())

      const filtersStringified = qs.stringify(filters)

      return api
        .fetch(`${uri}?${filtersStringified}`)
        .then(errorsHandling.handleFormErrors)
        .then((response) => {
          dispatch(actionCreator.filtered({ data: response, filters }))

          return response
        })
    }
  }

  this.retrieve = (props) => this.manyToMany({ ...props, method: 'fetch', handleErrors: errorsHandling.handleFormErrors, shouldFetch: false })

  /**
   * Attach child payload to the parent entity
   *
   * Example: (parent)[1, 2, 3] + (child)[4, 5, 6] = [1, 2, 3, 4, 5, 6]
   * @props {Object} props - @inheritdoc `this.manyToMany`
   */
  this.attach = (props) => this.manyToMany({ ...props, method: 'post', handleErrors: errorsHandling.handlePivotFormErrors })

  /**
   * Detach child payload to the parent entity
   *
   * Example: (parent)[1, 2, 3] - (child)[3] = [1, 2]
   * @props {Object} props - @inheritdoc `this.manyToMany`
   */
  this.detach = (props) => this.manyToMany({ ...props, method: 'delete', handleErrors: errorsHandling.handlePivotFormErrors })

  /**
   * Sync child payload to the parent entity
   *
   * Example: (parent)[1, 2, 3] + (child)[4, 5, 6] = [4, 5, 6]
   * @props {Object} props - @inheritdoc `this.manyToMany`
   */
  this.sync = (props) => this.manyToMany({ ...props, method: 'patch', handleErrors: errorsHandling.handlePivotFormErrors })

  /**
   * Merge child payload to the parent entity
   *
   * Example: (parent)[1, 2, 3] + (child)[1, 2, 3 ,4 ,5 ,6] = [1, 2, 3, 4, 5, 6]
   * @props {Object} props - @inheritdoc `this.manyToMany`
   */
  this.merge = (props) =>
    this.manyToMany({
      // Make possible to overwrite default error handler.
      // We have an experimental functionality (pivotJSONNormalize),
      // and doing this won't brake all current usages
      handleErrors: errorsHandling.handlePivotFormErrors,
      ...props,
      method: 'put',
    })

  /**
   * Handle many-to-many thunks
   *
   * This method is the base mechanism for the rest
   * many-to-many like methods as `attach, detach, sync, merge`.
   *
   * The rest methods only set default values,
   * according to their purpose.
   *
   * @props {Object} props
   * @props {string} props.method - HTTP request method (e.g. 'POST')
   * @props {Object} props.entity - Data to be sent to the BE. Most of the times we're sending an array with ids.
   * @props {Number} props.id - ID of the parent entity, where we'll perform some action
   * @props {string} props.childUri - URI of the child resource, which we want to relate to the parent entity
   * @props {function} props.actionFunc - What function to be dispatched
   * @props {bool} props.shouldFetch - Please refer to `this.update` `shouldFetch` documentation
   * @props {String|null} props.fieldPrefix - Prefix for the redux-form field; to properly show errors
   */
  this.manyToMany = ({
    method,
    entity,
    id,
    childUri,
    actionFunc,
    handleErrors,
    shouldFetch = true,
    shouldInvalidate = false,
    fieldPrefix = null,
    returnInlineErrors = false,
  }) => {
    return (dispatch, getState, { api }) => {
      dispatch(actionFunc(entity, id))
      return api[method](uri + '/' + id + '/' + childUri, { payload: entity })
        .then(returnInlineErrors ? null : errorsHandling.handleFormErrors)
        .then((resp) => (!returnInlineErrors ? handleErrors(resp, fieldPrefix) : resp))
        .then((response) => {
          if (response.errors && returnInlineErrors) return response
          if (!shouldFetch && shouldInvalidate) dispatch(actionCreator.invalidate())
          if (shouldFetch) dispatch(this.fetch())

          return response
        })
    }
  }

  /**
   * Download a file
   *
   * @props {Object} props
   * @props {Number} props.id - Entity's id, where the file belongs to.
   * @props {String} props.childUri
   * @props {Boolean} props.shouldDownload - default `true`, If set to `false` only
   *  download URL is returned instead of downloading the file
   *
   * Example:
   * ```
   * // uri = 'payrollinstanceemployees'
   *
   * // This will download an employee's payslip
   * this.download({ id: 1, childUri: 'downloadpayslip'})
   * ```
   *
   * @return {function(*, *, {api: *})}
   */
  this.download = ({ id, childUri, fullUrl, shouldDownload = true, filter, params = {} }) => {
    // We keep window reference, because on `iOS` mobile devices, downloading a `blob` data
    // in the case `Content Disposition: application/octet-stream`, is not working.
    // It tries to download the file with a different file extension.
    // If `Content Disposition` is more specific, i.e. `application/pdf`, it will work, but it would be hard to change
    // it for all already created files on the file server.
    // Because of this, for `iOS` devices only, we'll open the file with `window.open`, instead of downloading it.
    // The `windowReference` is needed, because `window.open()` doesn't work in async calls.
    // @credits: https://stackoverflow.com/a/39387533/4312466
    const windowReference = isIOS && window.open()

    return (dispatch, getState, { api }) => {
      return api
        .fetch(buildDownloadUrl({ id, childUri, fullUrl, filter }), { ...params, isURL: Boolean(fullUrl) })
        .then(errorsHandling.handleFormErrors)
        .then((response) => {
          // TODO: BE should return the response always attached to `data` prop firtly
          // Here they don't do it and this is a workaround. Once fixed, will remove the fix
          const { url, filename } = response.data || response

          // if shouldDownload is true, download the file
          if (shouldDownload) {
            if (isIOS) {
              // Make sure we return a Promise here too,
              // because in the default downloading case we always return a Promise and the function's clients expect
              // the Promise response.
              return new Promise((resolve) => {
                windowReference.location = url

                resolve(url)
              })
            }

            return api.fetch(url, { isURL: true }).then((r) => r.file.then((blob) => download(blob, filename)))
          } else {
            // TODO: otherwise return just the file url
            // Think of a way to open files in browser
            // instead of directly downloading the file
            return url
          }
        })
    }
  }

  /**
   * Build the download URI
   *
   * Because of the conditional and extra logic of download URI,
   * we decided to isolate it in its own reusable function.
   *
   * @param {{ id: Number, childUri: String, fullUrl: String, filter: fullUrl }} params
   * @return {*}
   */
  const buildDownloadUrl = ({ id, childUri, fullUrl, filter }) => {
    if (fullUrl) return fullUrl

    let url = id ? `${uri}/${id}/${childUri}` : `${uri}/${childUri}`

    if (filter) url = `${url}?${filter.uri}`

    return url
  }

  /**
   * Upload a file
   *
   * @props {Object} props
   * @props {Number} props.id - Entity's id, where the file belongs to.
   * @props {USVString|Blob} props.file - The file, that will be uploaded
   * @props {String} props.childUri
   *
   * Example:
   * ```
   * // uri = 'payrollinstanceemployees'
   *
   * // This will upload an employee's payslip
   * this.upload({ id: 1, file, childUri: 'uploadpayslip'})
   * ```
   *
   * @return {function(*, *, {api: *})}
   */
  this.upload = ({ id, file, childUri, shouldHandleErrors = true, data = {}, params = {} }) => {
    const url = buildUploadUrl(id, childUri)

    const message = data.uploadingMessage
    delete data['uploadingMessage']

    return (dispatch, getState, { api }) => {
      dispatch(
        showMessage({
          body: message || 'Uploading...',
        })
      )

      let formData = buildUploadData(file, data)
      let apiUpload = api.upload(url, { payload: formData, ...params })

      if (shouldHandleErrors) return apiUpload.then(errorsHandling.handleFormErrors)

      if (!shouldHandleErrors) return apiUpload
    }
  }

  /**
   * Build the upload url depending on that
   * which parameters we have passed to the upload func
   *
   * @param {*} id
   * @param {*} childUri
   */
  const buildUploadUrl = (id = null, childUri = null) => {
    let url
    if (childUri == null) {
      if (id == null) {
        // if there is no childUri and no id
        url = `${uri}`
      } else {
        // if there is no childUri and but id is set
        url = `${uri}/${id}`
      }
    } else {
      if (id == null) {
        // if there is childUri set and no id
        url = `${uri}/${childUri}`
      } else {
        // if there are both childUri and id set
        url = `${uri}/${id}/${childUri}`
      }
    }

    return url
  }

  /**
   * Build the FormData that should be sent along
   * with file if there is any extra data to be sent
   *
   * @param {*} file
   * @param {*} data
   */
  const buildUploadData = (file, data) => {
    let formData = new FormData()
    formData.append('file', file)

    _.forEach(data, function (value, key) {
      formData.append(key, value)
    })

    return formData
  }

  /*
   * Request new entity
   * @param {Object} entity - Data, that be sent to the API
   */
  this.request = (entity) => {
    return (dispatch, getState, { api }) => {
      return api
        .post(`${uri}/request`, { ...params, payload: entity })
        .then(errorsHandling.handleFormErrors)
        .then((response) => {
          return response
        })
    }
  }
}
