import { get, omit, omitBy, isNull, isEmpty } from 'lodash'
import { getMonthDateRangeByDate } from './date'
import moment from 'moment'

/**
 * Sanitize filters
 *
 * @param {Object} filter
 * @returns {*}
 */
export const buildFilters = (filter) => {
  let filterDate = filter.fromDate
  let view = filter.view

  // Remove null values
  let sanitized = omitBy(filter, isNull)

  // We set `payroll` and `companyProcess` based on `payrollsAndProcesses`,
  // which is a helper field and that's why it should not be send to BE
  sanitized = omit(sanitized, ['payrollsAndProcesses'])

  // ?:INFO Convert the date to a moment instance if the fromDate
  // ? is an instance of Date
  if (filterDate instanceof Date) filterDate = moment(filterDate)
  if (filter.toDate instanceof Date) filter.toDate = moment(filter.toDate)

  if (filterDate) {
    if (view === 'month') {
      let monthDateRange = getMonthDateRangeByDate(filterDate)
      sanitized['fromDate'] = monthDateRange.start.format('YYYY-MM-DD')
      sanitized['toDate'] = monthDateRange.end.format('YYYY-MM-DD')
    } else {
      let filterDateFormated = filterDate.format('YYYY-MM-DD')
      sanitized['fromDate'] = filterDateFormated
      sanitized['toDate'] = filter.toDate ? filter.toDate.format('YYYY-MM-DD') : filterDateFormated
    }
  }

  return sanitized
}

/**
 * Builds filter dropdown options based on existing data to get unique values.
 *
 * @param {Array} items - The original array containing items to be transformed.
 * @param {string} path - The property path to extract values from each item for sorting and uniqueness.
 * @returns {Array} Returns a new array of unique objects, each containing a 'value' and 'label' property, sorted by alphabetical criteria */
export const buildFilterOpts = (items, path) => {
  if (!path) return items
  const result = items?.reduce((acc, item) => {
    const value = get(item, path)
    const hasItem = acc.find((i) => i.value === value)
    if (!hasItem) {
      acc.push({ value, label: value })
    }
    return acc
  }, [])
  return result.sort((a, b) => a.value.localeCompare(b.value))
}

/**
 * Normalize filter object when BE expects different filter naming
 * FE filters are keys, BE filters are values
 * ex.
 *   const filtersMapping = {
 *      elementName: 'element',
 *      previous: 'previousZero',
 *       v1: 'v1Zero',
 *       v2: 'v2Zero',
 *      }
 * @param {Object} filters
 * @param {Object} filtersMapping
 * @returns
 */

export const normalizeFiltersForBE = (filters, filtersMapping = {}, withValue = false) => {
  return Object.keys(filters).reduce((normalized, filterName) => {
    // Normalize query filter parameters (their names must match the BE API)
    const name = filtersMapping[filterName] || filterName
    if (!isEmpty(filters[filterName])) {
      normalized[name] = withValue ? filters[filterName].value : filters[filterName]
    }
    return normalized
  }, {})
}

/**
 * Normalize partial filtering for BE by extracting enabled fields.
 * To enable partial text searches on a field BE requires partialSearchFields[0]=fullname&partialSearchFields[1]=position
 * ex.
 * if we have to filter by fullname and position field, this will return ['fullname', 'position']
 *
 * @param {Object} filters - The filters object containing filter keys.
 * @param {Object} enabledFields - The object containing enabled fields.
 * @returns {Array} Containing the list of partial field names
 *
 */
export const normalizePartialFilteringForBE = ({ filters, partialSearchFields }) =>
  Object.keys(filters).reduce((normalized, filterKey) => {
    if (partialSearchFields[filterKey]) normalized.push(partialSearchFields[filterKey])
    return normalized
  }, [])
