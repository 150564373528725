import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/vendorCountryTaskPivot'

class VendorCountryTask extends CRUDModel {}
VendorCountryTask.modelName = 'VendorCountryTask'
VendorCountryTask.actionTypes = actionTypes

VendorCountryTask.fields = {
  id: attr(),
  countryTask: fk('CountryTask', 'vendorCountryTasks'),
  vendor: fk('Vendor', 'vendorCountryTasks')
}

export default VendorCountryTask
