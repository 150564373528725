import { shareTableHeadings } from 'utils/locales/shares.en'

export const shareSftpHeadings = [
  {
    accessor: 'location',
    Header: shareTableHeadings.location,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    accessor: 'share',
    Header: shareTableHeadings.share,
    disableFilters: true,
    disableSortBy: true,
    setWidth: '70px',
    classNames: 'text-right',
    columnClassName: 'u-cursor--pointer text-right',
  },
]
