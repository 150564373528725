import React from 'react'
import { calendarEventsTitlesEnum } from 'utils/enums/calendarEvents'

const Legend = () => {
  return (
    <div className='o-grid calendar-yearly u-margin-top u-margin-bottom'>
      <div className='d-flex'><span className='calendar-yearly-legend-dot' /><span>Holidays</span></div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--inputs' />
        <span>{calendarEventsTitlesEnum.INPUTS_CUTOFF}</span>
      </div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--gtn' />
        <span>{calendarEventsTitlesEnum.GTN_CUTOFF}</span>
      </div>
      <div className='d-flex u-margin-left-huge'>
        <span className='calendar-yearly-legend-dot--paydate' />
        <span>{calendarEventsTitlesEnum.PAY_DATE}</span>
      </div>
    </div>
  )
}

export default Legend
