import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'
import { cookiePolicy } from 'utils/locales/policyCookies.en'

const tableHeadings = [
  { accessor: 'name', Header: cookiePolicy.table.headings.heading1, disableSortBy: true, disableFilters: true },
  { accessor: 'placed', Header: cookiePolicy.table.headings.heading2, disableSortBy: true, disableFilters: true },
  { accessor: 'purpose', Header: cookiePolicy.table.headings.heading3, disableSortBy: true, disableFilters: true },
  { accessor: 'functionality', Header: cookiePolicy.table.headings.heading4, disableSortBy: true, disableFilters: true },
  { accessor: 'expiration', Header: cookiePolicy.table.headings.heading5, disableSortBy: true, disableFilters: true },
]

const tableData = [
  {
    name: cookiePolicy.table.data.row1.name,
    placed: cookiePolicy.table.data.row1.placed,
    purpose: cookiePolicy.table.data.row1.purpose,
    functionality: cookiePolicy.table.data.row1.functionality,
    expiration: cookiePolicy.table.data.row1.expiration,
  },
  {
    name: cookiePolicy.table.data.row2.name,
    placed: cookiePolicy.table.data.row2.placed,
    purpose: cookiePolicy.table.data.row2.purpose,
    functionality: cookiePolicy.table.data.row2.functionality,
    expiration: cookiePolicy.table.data.row2.expiration,
  },
  {
    name: cookiePolicy.table.data.row3.name,
    placed: cookiePolicy.table.data.row3.placed,
    purpose: cookiePolicy.table.data.row3.purpose,
    functionality: cookiePolicy.table.data.row3.functionality,
    expiration: cookiePolicy.table.data.row3.expiration,
  },
  {
    name: cookiePolicy.table.data.row4.name,
    placed: cookiePolicy.table.data.row4.placed,
    purpose: cookiePolicy.table.data.row4.purpose,
    functionality: cookiePolicy.table.data.row4.functionality,
    expiration: cookiePolicy.table.data.row4.expiration,
  },
  {
    name: cookiePolicy.table.data.row5.name,
    placed: cookiePolicy.table.data.row5.placed,
    purpose: cookiePolicy.table.data.row5.purpose,
    functionality: cookiePolicy.table.data.row5.functionality,
    expiration: cookiePolicy.table.data.row5.expiration,
  },
]

const headingClassName = 'u-margin-top u-margin-bottom-none'
const paragraphClassName = 'u-margin-bottom-none'
const tableClassName = 'u-margin-top u-margin-bottom u-1/1'

const RouteView = () => (
  <div className='u-margin-top-neg u-text--normal'>
    <p className={paragraphClassName}> {cookiePolicy.sectionA.p1}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionA.p2}</p>
    <SectionHeading className={headingClassName} text={cookiePolicy.sectionB.heading} />
    <p className={paragraphClassName}> {cookiePolicy.sectionB.p1}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionB.p2}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionB.p3}</p>
    <SectionHeading className={headingClassName} text={cookiePolicy.sectionC.heading} />
    <p className={paragraphClassName}> {cookiePolicy.sectionC.p1}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionC.p2}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionC.p3}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionC.p4}</p>
    <PayslipTable wrapperClassName={tableClassName} headings={tableHeadings} data={tableData} />
    <SectionHeading className={headingClassName} text={cookiePolicy.sectionD.heading} />
    <p className={paragraphClassName}> {cookiePolicy.sectionD.p1}</p>
    <p className={paragraphClassName}> {cookiePolicy.sectionD.p2}</p>
    <SectionHeading className={headingClassName} text={cookiePolicy.sectionE.heading} />
    <p className={paragraphClassName}> {cookiePolicy.sectionE.p1}</p>
  </div>
)

export default RouteView
