import React from 'react'
import LeaveListContainer from '../containers/LeaveListContainer'
import TimeAttendanceSummaryContainer from '../containers/TimeAttendanceSummaryContainer'

export const TimeAttendanceView = props => {
  return <>
    <div className='o-layout'>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <LeaveListContainer {...props} />
      </div>
    </div>

    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <TimeAttendanceSummaryContainer {...props} />
      </div>
    </div>
  </>
}

export default TimeAttendanceView
