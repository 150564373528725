import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/vendors'

class Vendor extends CRUDModel {}
Vendor.modelName = 'Vendor'
Vendor.actionTypes = actionTypes

Vendor.fields = {
  id: attr(),
  hqCountry: fk('Country', 'vendors'),
  countryServices: many({
    to: 'CountryService',
    relatedName: 'vendors',
    through: 'VendorCountryService',
    throughFields: ['vendor', 'countryService']
  }),
  countryTasks: many({
    to: 'CountryTask',
    relatedName: 'vendors',
    through: 'VendorCountryTask',
    throughFields: ['vendor', 'countryTask']
  })
}

export default Vendor
