import InnerWrapper from 'layouts/InnerWrapper'
import React from 'react'
import { connect } from 'react-redux'
import { selectTenant } from 'redux/actions/tenants'
import { selectTenant as selectVendorTenant } from 'redux/actions/vendorTenant'
import { getParentCompaniesTenantById } from 'redux/selectors/tenants'
import { purify } from 'utils/router'

/**
 * As you can see, the `Invalidator` component wraps all Vendor->Client routes and return the children.
 * Its goal is to monitor the cases, where we navigate to other outer Routes / Components,
 * these are not part of Vendor->Client interface.
 * In this use-case, the `Invalidator` will reset the currently selected Client firstly
 * and later will navigate to the next outer Route.
 */
class Invalidator extends React.Component {
  componentWillUnmount () {
    const { isInvalidatorEnabled, resetTenant } = this.props

    // There's a case, we should not trigger the Invalidator mechanism. Let me explain it:
    // 1. We're COT and we're operating in the Vendor interface with a certain Client.
    // 2. We decide to navigate to the Client interface (via `TenantSelectorContainer`) and select a Client.
    // In this case, the `TenantSelectorContainer` is responsible for Tenant and Data resetting.
    // Otherwise, if both mechanisms are triggered (`Invalidator` and `TenantSelectorContainer`),
    // the data will be reset correctly, but the User will be redirected back to the Vendor interface,
    // because of `resetTenant` functionality.
    // The last redirection will cause the system to be inconsistent, because a Client tenant will be selected,
    // but we'll be navigated back to a Vendor tenant.
    isInvalidatorEnabled && resetTenant()
  }
  render () {
    return <InnerWrapper>{this.props.children}</InnerWrapper>
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setVendorTenant: () => {
      dispatch((dispatch, getState) => {
        const state = getState()
        const client = getParentCompaniesTenantById(state, { tenantId: props.match.params.tenantId })
        dispatch(selectVendorTenant({ url: client.url }))
      })
    },
    resetTenant: () => {
      dispatch((dispatch, getState) => {
        const state = getState()
        const { tenants } = state

        purify(props.history).then(() => {
          dispatch(selectTenant({ url: tenants.url, type: tenants.type, id: tenants.id }))
          props.history.goBack()
        })
      })
    },
    dispatch
  }
}

const mapStateToProps = state => ({
  isInvalidatorEnabled: state.invalidator.isEnabled
})
export default connect(mapStateToProps, mapDispatchToProps)(Invalidator)
