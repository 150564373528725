import createSelectorORM from 'utils/createSelector'
import { createSelector } from 'reselect'

export const getCompanyId = (state, props) => parseInt(props.companyId)
export const getUserId = (state, props) => parseInt(props.userId)

// get all locations
export const getAllOfficeLocations = createSelectorORM(
  ({ OfficeLocation }) => OfficeLocation.all().toRefArray())

// get locations by company id
export const getOfficeLocationsByCompany = createSelector(getAllOfficeLocations, getCompanyId, (officeLocations, companyId) =>
  officeLocations.filter((ol) => ol.company === companyId)
)

export const getOfficeLocationsWithCompanyWithCountry = createSelectorORM(
  ({ OfficeLocation }) => {
    return OfficeLocation.all()
      .toModelArray()
      .map(unit => ({
        ...unit.ref,
        company: { ...unit.company.ref },
        country: { ...unit.company.country.ref }
      }))
  })

// get all locations initial values
export const getAllOfficeLocationsInitialValues = createSelector(getAllOfficeLocations, locations => (
  locations.reduce((initialValues, location) => {
    const id = `id-${location.id}`
    initialValues[id] = location.name

    return initialValues
  }, {})
))

// get company locations initial values
export const getCompanyOfficeLocationsInitials = createSelector(getOfficeLocationsByCompany, officeLocations => (
  officeLocations.reduce((initialValues, location) => {
    const id = `id-${location.id}`
    initialValues[id] = location.name

    return initialValues
  }, {})
))
