import React from 'react'
import PropTypes from 'prop-types'

export const CustomLastRow = ({ row, isLastPage = false }) => {
  const { data, showOnLastPageOnly = false } = row
  if (showOnLastPageOnly && !isLastPage) return null
  return (
    <tr className='custom-last-row'>
      <td className=''> {/* Need an empty item to account for the hover border td */} </td>
      {data.map((d, i) => {
        return (
          <td key={`${d?.rowKey || i}${i}`} className={d.classNames}>
            {d.displayValue}
          </td>
        )
      })}
    </tr>
  )
}

CustomLastRow.propTypes = {
  row: PropTypes.object,
  showOnLastPageOnly: PropTypes.bool,
}
