import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const DATA_PROTECTION_FIELDS_FETCH = 'DATA_PROTECTION_FIELDS_FETCH'
export const DATA_PROTECTION_FIELDS_RECEIVE = 'DATA_PROTECTION_FIELDS_RECEIVE'
export const DATA_PROTECTION_FIELDS_UPDATE = 'DATA_PROTECTION_FIELDS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DATA_PROTECTION_FIELDS_FETCH,
  receive: DATA_PROTECTION_FIELDS_RECEIVE,
  update: DATA_PROTECTION_FIELDS_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'dataProtectionFields', 'dataprotectionfields', 'DataProtectionFields')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDataProtectionFieldsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDataProtectionFields = actionUtility.fetchEntities
export const updateDataProtectionField = actionUtility.updateEntity
