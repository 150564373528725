import React, { useRef, useState } from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import { emailValidation, phoneValidation } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import ProcessingSSOModal from 'components/ProcessingSSOModal'
import { getSelectionOptions } from 'utils/selectOptions'
import ConfirmationModal from 'components/ConfirmationModal'
import { sortByLabel } from 'utils/strings'
import { yesNoBoolValuesOptions } from 'redux/config/DropDownOptions/confirmationOptions'
import { VendorAccessControlForm } from './VendorAccessControlForm'
import { mode2faEmailOption } from 'redux/config/DropDownOptions/mode2faOptions'

const VendorUserForm = (props) => {
  const [state, setState] = useState({
    isSSOSubmitting: false,
    isVendorSSOEnabled: props.isVendorSSOEnabled,
    submitErrors: [],
    vendorUserEmail: null,
  })
  const submittingSSOModal = useRef(null)
  const confirmUserPasswordReset = useRef(null)

  const {
    isFetching,
    globalCountries,
    hasCredentials,
    isCot,
    isVendorAdmin,
    isEdit,
    isCreate,
    resetUserPassword,
    workEmail,
    isMobilePhoneFieldDisabled,
    timezones,
    receivesNotifications,
    receivesEmailNotifications,
    showUserSSOField = false,
    showUserSSOButton = false,
    vendorUserId,
    isVendorSSOEnabled,
    handleSSOclick,
    showMode2faFieldOnCreate,
    showMode2faFieldOnEdit,
  } = props
  const canModifyAdminContent = isVendorAdmin || isCot
  const showPasswordResetBtn = !(state.isVendorSSOEnabled || isVendorSSOEnabled)
  const isUserSSOEnabled = state.isVendorSSOEnabled || isVendorSSOEnabled
  const showMode2faField = isCreate ? showMode2faFieldOnCreate : showMode2faFieldOnEdit
  if (isFetching) return <div>Loading...</div>

  return (
    <>
      <ProcessingSSOModal isSSOSubmitting={state.isSSOSubmitting} isUserSSOEnabled={isUserSSOEnabled} ref={submittingSSOModal} errors={state.submitErrors} />

      <Form {...props}>
        <div className='o-layout'>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='firstname'
                label='First Name *'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                data-testid='firstname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='surname'
                label='Last Name *'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                data-testid='surname'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='title'
                label='Title'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={getSelectionOptions('title')}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='gender'
                label='Gender'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'not stated', label: 'Not stated' },
                ]}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='country'
                label='Country'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder='Select'
                options={globalCountries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))
                  .sort(sortByLabel)}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='timezone'
                label='Timezone'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                component={CustomSelectField}
                placeholder='Select'
                options={timezones.map((timezone) => ({
                  value: timezone.id,
                  label: timezone.zone,
                }))}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='position'
                label='Position'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workLocation'
                label='Work Location'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workEmail'
                label='Work Email *'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
                data-testid='workEmail'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='personalEmail'
                label='Personal Email'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                component={InputField}
                type='text'
                validate={emailValidation}
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small' data-testid='mobile-phone-wrapper'>
              <Field
                name='mobilePhone'
                label='Mobile Phone'
                component={PhoneCodeNumberField}
                type='text'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                formGroupClassName='u-margin-none'
                validate={phoneValidation}
                disabled={isMobilePhoneFieldDisabled}
                disabledReason='Please go to settings'
              />
            </div>
          </div>
          <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='hasCredentials'
                label='Account Status'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Inactive' },
                ]}
                data-testid='hasCredentials-wrapper'
              />
            </div>
          </div>
          {showMode2faField && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='mode2fa'
                  label='2FA Mode'
                  component={CustomSelectField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-custom-select--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  options={isCot ? [...getSelectionOptions('mode2fa'), mode2faEmailOption] : getSelectionOptions('mode2fa')}
                />
              </div>
            </div>
          )}
          {showUserSSOField && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='isSSOEnabled'
                  label='SSO'
                  component={InputField}
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  disabled
                  data-testid='isSSOEnabled-wrapper'
                />
              </div>
            </div>
          )}
          {hasCredentials && (
            <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='username'
                  label='Username'
                  component={InputField}
                  type='text'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent'
                  formGroupClassName='u-margin-none'
                />
              </div>
            </div>
          )}
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='workflowNotifications'
                label='Workflow notifications'
                component={CustomSelectField}
                labelClassName='c-label u-text--small u-text--curious'
                className='c-custom-select--transparent'
                formGroupClassName='u-margin-none'
                placeholder={isEdit && !canModifyAdminContent && !receivesNotifications && !receivesEmailNotifications ? 'No Alerts & Emails' : 'Select'}
                title='Selecting an option from this menu lets you manage email notifications related to your payroll
               workflow (steps and actions) processes only. You will still receive email notifications for notes and
               documents share.'
                clearable={false}
                options={[
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: true }),
                    label: 'Alerts in system and emails',
                  },
                  {
                    value: JSON.stringify({ receivesNotifications: true, receivesEmailNotifications: false }),
                    label: 'Alerts in system',
                  },
                  ...(canModifyAdminContent
                    ? [
                      {
                        value: JSON.stringify({ receivesNotifications: false, receivesEmailNotifications: false }),
                        label: 'No Alerts & Emails',
                      },
                    ]
                    : []),
                ]}
                data-testid='workflowNotifications-wrapper'
              />
            </div>
          </div>
          {canModifyAdminContent && (
            <>
              <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                  <Field
                    name='includeInCommunications'
                    label='Include in Comms'
                    component={CustomSelectField}
                    labelClassName='c-label u-text--small u-text--curious'
                    className='c-custom-select--transparent'
                    formGroupClassName='u-margin-none'
                    clearable={false}
                    placeholder='Select'
                    options={yesNoBoolValuesOptions}
                    data-testid='includeInCommunications-wrapper'
                  />
                </div>
              </div>
              <div className='u-margin-top-section'>
                <VendorAccessControlForm {...props} />
              </div>
            </>
          )}
          {hasCredentials && (
            <Authorization permissions={['VENDORUSER_EDIT']}>
              <div className='o-layout__item u-margin-bottom'>
                <div className='c-panel o-grid o-grid--medium ai-center'>
                  {showPasswordResetBtn && (
                    <div
                      onClick={() => {
                        setState({ ...state, vendorUserEmail: workEmail })
                        confirmUserPasswordReset.current.showModal()
                      }}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-small u-margin-right u-margin-left-none'
                      data-testid='reset-user-password'
                    >
                      Reset User Password
                    </div>
                  )}
                  {showUserSSOButton && (
                    <div className='c-panel u-margin-small u-margin-right u-margin-left-none u-padding-none'>
                      <button
                        type='button'
                        onClick={() => {
                          setState({ ...state, isSSOSubmitting: true })
                          submittingSSOModal.current.showModal()
                          handleSSOclick({
                            userId: vendorUserId,
                            isUserSSOEnabled: !isVendorSSOEnabled,
                          }).then((res) => {
                            if (res?.status) {
                              return setState({ ...state, isVendorSSOEnabled: res.isVendorSSOEnabled, isSSOSubmitting: false })
                            }
                            setState({
                              ...state,
                              isSSOSubmitting: false,
                              submitErrors: res.errors._error,
                            })
                          })
                        }}
                        className={`c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right
                        ${state.isSSOSubmitting ? 'pe-none' : ''}`}
                        data-testid='sso-button'
                      >
                        {`${state.isVendorSSOEnabled || isVendorSSOEnabled ? 'Disable' : 'Enable'} SSO`}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Authorization>
          )}
        </div>
        <Authorization permissions={['VENDORUSER_EDIT', 'VENDORUSER_DELETE']}>
          <div className='o-layout'>
            <div className='o-layout__item u-text--center u-1/1'>
              <button
                type='submit'
                className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left
              u-padding-right u-margin-top-small'
                data-testid='submit'
              >
                Save
              </button>
            </div>
          </div>
        </Authorization>
      </Form>

      <ConfirmationModal
        ref={confirmUserPasswordReset}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => resetUserPassword({ email: state.vendorUserEmail })}
      >
        <p>Are you sure you want to reset the user password?</p>
      </ConfirmationModal>
    </>
  )
}

VendorUserForm.propTypes = {
  isEdit: PropTypes.bool,
  resetField: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  globalCountries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  hasCredentials: PropTypes.bool,
  resetUserPassword: PropTypes.func,
  workEmail: PropTypes.string,
  isCot: PropTypes.bool,
  isVendorAdmin: PropTypes.bool,
  receivesNotifications: PropTypes.bool,
  receivesEmailNotifications: PropTypes.bool,
  isFetching: PropTypes.bool,
  isMobilePhoneFieldDisabled: PropTypes.bool,
  timezones: PropTypes.array,
  showUserSSOField: PropTypes.bool,
  showUserSSOButton: PropTypes.bool,
  isVendorSSOEnabled: PropTypes.bool,
  vendorUserId: PropTypes.number,
  handleSSOclick: PropTypes.func,
  parentCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      schema: PropTypes.string.isRequired,
    })
  ),
  externalCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      accessable: PropTypes.bool.isRequired,
      companyId: PropTypes.number.isRequired,
      country: PropTypes.number.isRequired,
      tenant: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
}

export default VendorUserForm
