import Fetcher from '../Fetcher'
import { connect } from 'react-redux'
import { createFilter } from 'utils/redux/filter'
import PayrunHeader from 'components/header/PayrunHeader'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'

const mapStateToProps = (state, { payrunInstanceId }) => {
  return {
    payrun: getPayrollInstanceRef(state, { payrollInstanceId: payrunInstanceId })
  }
}

const Container = connect(mapStateToProps)(PayrunHeader)

export default Fetcher(Container, [
  'countries',
  'parentCompanies',
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          forceVendorTenantAPI: (state, { vendorTenantUrl }) => !!vendorTenantUrl,
          filter: (state, props) => createFilter({
            id: props.payrunInstanceId
          })
        }
      }
    ]
  },
  {
    name: 'companies',
    params: [{
      _computed: { forceVendorTenantAPI: (state, { vendorTenantUrl }) => !!vendorTenantUrl }
    }],
  },
])
