import { graphTypesEnum } from 'utils/enums/analyticsEnums'
import { ReportingAnalyticsService } from './ReportingAnalyticsService'
import { DateType } from 'utils/date'
import { getMonthNameByIndex } from 'utils/enums/dateEnums'

export class ReportingAnalyticsWorkForceDetailService extends ReportingAnalyticsService {
  /**
   * @param {array} rawData The full data set returned when getting the details from a the Reports API
   * @param {array} otherSheets The otherSheets data returned from the server reporting API
   * @param {object} mappingOptions A series of options that will be used throughout the file.  General contains logic from enums/reports.js
   * @param {object} charts The active charts for a given report.  Data is found from enums/reports.js
   * @param {object} chartLogic Chart logic that will be used throughout the Services.  Logic is currently obtained from the enums/reports.js
  */
  constructor (rawData = [], otherSheets = [], mappingOptions = {}, charts = [], chartLogic = {}, reportFilters = []) {
    super(rawData, otherSheets, mappingOptions, charts, chartLogic)
    this.filteredDownReportTypes = this.getFilteredChartTypes()
    this.filteredDownRecords = []
    this.dateForLineChart = []
    this.noStartDateCount = 0
    this.workforcePieChart = []
    if (Object.keys(this.filteredDownReportTypes).length) {
      Object.keys(this.options.CHART_LOGIC.LINE_CHART_DATAKEYS).forEach(type => {
        this.workforcePieChart.push({ name: type, value: 0, cssName: '' })
      })
    }
  }

  /**
   * Method to set the value of the total workforce count
   * @param {object} item The current report record item that will be assigned the total workforce count
   */
  updateTotalWorkforceByTypeForPieChart = (item) => {
    let mappedData = []
    this.workforcePieChart.forEach(chartData => {
      if (item[chartData.name] > 0) {
        mappedData.push({
          value: item[chartData.name] || 0,
          name: chartData.name,
          cssName: this.options.CHART_LOGIC.CHART_DATAKEYS_CSS_NAMES[chartData.name]
        })
      }
    })

    this.workforcePieChart = mappedData
  }

  /**
   * Method to filter out the unrelated report types for a given report
   * The full details come enums/reports.js.  But this service only cares about PIe reports (graphTypesEnum.PIE_CHART)
   * @returns {array} The Pie charts that are activated for this report
  */
  getFilteredChartTypes = () => {
    const filteredCharts = Object.entries(this.chartTypes).filter(([key, value]) => value === graphTypesEnum.LINE_CHART)
    return Object.fromEntries(filteredCharts)
  }

  dateRange = (startDate, endDate) => {
    let start = startDate.split('-')
    let end = endDate.split('-')
    let startYear = parseInt(start[0])
    let endYear = parseInt(end[0])
    let dates = []

    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1
        let displayMonth = month < 10 ? '0' + month : month
        dates.push([i, displayMonth, '01'].join('-'))
      }
    }
    return dates
  }

  getAdjustedStartDate = (entityDate, filtersDate, entityField) => {
    if (!entityField) return filtersDate

    if (entityDate.year < filtersDate.year) {
      return filtersDate
    }

    if (entityDate.year === filtersDate.year && entityDate.month < filtersDate.month) {
      return filtersDate
    }
    if (!entityDate) return filtersDate
    return entityDate
  }

  groupEmploymentStatus = (status) => {
    let mappedStatus = status
    Object.keys(this.options.CHART_LOGIC.LINE_CHART_DATAKEYS).forEach(type => {
      if (this.options.CHART_LOGIC.LINE_CHART_DATAKEYS[type].length > 1) {
        const isGrouped = this.options.CHART_LOGIC.LINE_CHART_DATAKEYS[type].find(mappedType => {
          return mappedType === status
        })
        if (isGrouped) mappedStatus = type
      }
    })
    return mappedStatus
  }

  /**
   * Method to start process the data for this report.
   * It handles setting the data for each current report tile type
   * This includes the Totals count for Workforce, Active ESS, Inactive ESS
   * Will return a string message if no items are active
   * @returns null | string
  */
  processReportData = () => {
    if (Object.keys(this.filteredDownReportTypes).length === 0) return 'No Charts Specified'
    if (!this.options.reportStartDate) return 'No Start Date Set'
    const dateStart = this.options.reportStartDate
    const dateEnd = this.options.reportEndDate
    const startDateForSort = new DateType(this.options.reportStartDate)
    const types = Object.keys(this.options.CHART_LOGIC.LINE_CHART_DATAKEYS)

    let dates = this.dateRange(dateStart, dateEnd)
    let startTimeMap = new Map()
    let employeeStartMapWithTypes = new Map()
    let endingTimeMap = new Map()
    let employmentTypeMap = new Map()
    let finalData = []
    let chartDate = []
    let defaultObj = {}

    types.forEach(type => employeeStartMapWithTypes.set(type, []))

    dates.map(d => {
      if (!startTimeMap.has(new Date(d).getFullYear())) {
        startTimeMap.set(`${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}`, [])
      }

      types.forEach(type => {
        employmentTypeMap.set(`${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}-${type}`, 0)
      })

      if (!endingTimeMap.has(new Date(d).getFullYear())) {
        endingTimeMap.set(`${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}`, [])
      }
      return {
        name: d,
        year: new Date(d).getFullYear(),
        month: new Date(d).getMonth() + 1,
        pv: Math.random() * 1000
      }
    })

    let filteredData = [...this.chartData.data]

    const sortedData = filteredData.sort((a, b) => {
      if (a.startDate) return -1
      return a.startDate - b.startDate
    })
    const trackedEmployeeCount = {}
    sortedData.forEach((item) => {
      const employmentStatus = this.groupEmploymentStatus(item.employmentStatus || 'Not Assigned')
      if (trackedEmployeeCount[employmentStatus]) {
        trackedEmployeeCount[employmentStatus] += 1
      } else {
        trackedEmployeeCount[employmentStatus] = 1
      }
      const dateParts = item.startDate.split('/')
      const endDateParts = item.endDate.split('/')
      // month is 0-based, that's why we need dataParts[1] - 1
      let startDateObject = new DateType(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
      let endDateObject = item.endDate ? new DateType(+endDateParts[2], +endDateParts[1] - 1, +endDateParts[0]) : null
      if (endDateObject) {
        endDateObject = this.adjustEndDateForNextMonth(endDateObject)
      }
      // Get Peoples whose start dates are before the beginning of the sort time frame on year
      startDateObject = this.getAdjustedStartDate(startDateObject, startDateForSort, item.startDate)

      const startingTimesMapKey = `${startDateObject.year}-${startDateObject.month}`
      const employmentTypeMapKey = `${startDateObject.year}-${startDateObject.month}-${employmentStatus || 'Not Assigned'}`
      const employmentEndDatTypeMapKey = endDateObject ? `${endDateObject.year}-${endDateObject.month}-${employmentStatus || 'Not Assigned'}` : null

      // Create A list of all the people who started in a given month
      if (startTimeMap.has(startingTimesMapKey)) {
        let arr = startTimeMap.get(startingTimesMapKey)
        arr.push(item)
        startTimeMap.set(startingTimesMapKey, arr)
      }

      if (employmentTypeMap.has(employmentTypeMapKey)) {
        let value = employmentTypeMap.get(employmentTypeMapKey)
        value += 1
        employmentTypeMap.set(employmentTypeMapKey, value)
      } else {
        employmentTypeMap.set(employmentTypeMapKey, 0)
      }

      // Find the month the leaver is found in
      // This is the month *after* the end date
      if (employmentTypeMap.has(employmentEndDatTypeMapKey)) {
        let reduceValue = employmentTypeMap.get(employmentEndDatTypeMapKey)
        reduceValue -= 1
        employmentTypeMap.set(employmentEndDatTypeMapKey, reduceValue)
      }
    })

    types.forEach(type => { defaultObj[type] = 0 })

    for (const [key] of startTimeMap) {
      types.forEach(type => {
        const startingTimesMapKey = `${key}-${type}`
        defaultObj[type] = employmentTypeMap.get(startingTimesMapKey)
      })
      finalData.push({
        name: key,
        ...defaultObj
      })
    }
    finalData.forEach((data, index) => {
      if (index > 0) {
        types.forEach(type => {
          data[type] = finalData[index - 1][type] + data[type]
        })
      }
      const [, year, month] = data.name.match(/(\d*)-(\d*)/)

      data.name = getMonthNameByIndex(month)
      data.year = year
      chartDate.push({ ...data })
    })

    chartDate.forEach(data => {
      // delete records that have value 0 for all items
      // Check values for all the keys
      // If a value is 0, check if record exists with value greater than 0
      // If no such record exists, it's safe to delete the key
      for (const key in data) {
        if (data[key] === 0) {
          const hasValueGreaterThanZero = chartDate.find(e => e[key] > 0)
          if (!hasValueGreaterThanZero) delete data[key]
        }
      }
    })

    this.lineChartDataMapped = chartDate
    const hasValues = Object.values(trackedEmployeeCount).length > 0
    this.totalWorkCount = hasValues ? Object.values(trackedEmployeeCount).reduce((prev, current) => prev + current) : 0
    this.updateTotalWorkforceByTypeForPieChart(trackedEmployeeCount)
    this.setLineChartDataLegend(chartDate)
  }

  set employeesWithNoStartDate (count) {
    this.noStartDateCount = count
  }

  get employeesWithNoStartDate () {
    return this.noStartDateCount
  }

  get totalWorkforceByTypeForPieChart () {
    return this.workforcePieChart
  }
}
