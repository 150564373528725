export const vendorRolesLabels = {
  ROLE_VENDOR: 'ICP',
  ROLE_VENDOR_ADMIN: 'ICP Admin',
}

export const cotRolesLabels = {
  cot: 'COT',
  admin: 'COT Admin',
  bot: 'COT Bot',
  engineer: 'COT Engineer',
  oem: 'COT OEM',
}
