import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/businessUnits'

class BusinessUnit extends CRUDModel {}
BusinessUnit.modelName = 'BusinessUnit'
BusinessUnit.actionTypes = actionTypes

BusinessUnit.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'businessUnits'),
}

export default BusinessUnit
