import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import CotUsersList from '../components/CotUsersList'

class CotUsersView extends React.Component {
  render () {
    const { onCreate, onEdit, downloadReport } = this.props

    return <div>
      <SectionHeading text='COT Users'>
        <Authorization permissions={['COTUSER_CREATE']}>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-float--right'>
              <button
                type='button'
                onClick={() => downloadReport()}
                className='c-btn c-btn--small c-btn--curious
                 u-padding-left u-padding-right u-margin-right-small'
                title='Download'
                data-testid='download'
              >
                <span className='icon icon--download' />
              </button>
              <button
                title='Create COT user'
                onClick={onCreate}
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'>
                <span className='icon icon--plus' data-testid='cotCreate-button' />
              </button>
            </div>
          </div>
        </Authorization>
      </SectionHeading>

      <CotUsersList
        onClick={cotUserId => onEdit(cotUserId)}
        {...this.props}
      />
    </div>
  }
}

CotUsersView.propTypes = {
  onCreate: PropTypes.func,
  onEdit: PropTypes.func
}

export default CotUsersView
