import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { resetPassword, getCodeByToken } from 'redux/actions/auth'
import ResetPasswordConfirmationView from '../components/ResetPasswordConfirmationView'
import { showMessage } from 'redux/actions/modal'
import strings from 'strings'
import { isCotDomain } from 'utils/url'
class ResetPasswordConfirmationContainer extends React.Component {
  render () {
    return <ResetPasswordConfirmationView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state, props) => {
  const confirmationToken = props.match.params.token
  let hostUrlCot = isCotDomain(state.config.apiUrl)
  let senderService = hostUrlCot ? state.config.senderServiceCot : state.config.senderService

  return {
    is2FAenabled: state.config.is2FAenabled,
    confirmationToken,
    senderService: senderService,
    config: state.config,
    initialValues: {
      password: null,
      passwordConfirmation: null,
      confirmationToken,
      authCode: null
    }
  }
}

const getModalMessage = (senderService) => {
  return strings.authentication.twoFAWithoutAuth[senderService] ?? ''
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { senderService } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getNewCode: confirmationToken => {
      dispatch(getCodeByToken({ confirmationToken })).then(() => {
        dispatch(showMessage({
          body: getModalMessage(senderService),
        }))
      })
    },

    onSubmit: data => {
      return dispatch(resetPassword(data))
        .then(() => {
          dispatch(reset('resetPassword'))
          dispatch(showMessage({
            body: 'Your password has been reset.',
          }))
          window.location.href = '/home'
        })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'resetPasswordConfirmation'
})(ResetPasswordConfirmationContainer))
