import React from 'react'
import PropTypes from 'prop-types'
import ItemBox from 'components/ItemBox'
import Flag from 'components/Flag'
import { upperCase, startCase } from 'lodash'

export const Company = ({ company, match: { params } }) => {
  const { id, country, relationToHQ, name, code, isCompanyVendorRestricted } = company
  const { vendorId, tenantId } = params

  const companyLink = vendorId && tenantId
    ? isCompanyVendorRestricted
      ? `/vendors/${vendorId}/clients/${tenantId}/companies/${id}/details/payruns/documents`
      : `/vendors/${vendorId}/clients/${tenantId}/companies/${id}/details/payruns/active`
    : `/companies/${id}/corporate`

  return <ItemBox link={companyLink} data-testid={`company-${id}`}>
    <Flag flag={country.abbreviature.toLowerCase()} size='normal' classes='u-margin-right-tiny
    centered-image border-1-valhalla' />
    <h3 className='u-text--curious u-text--large u-padding-top-small
    u-margin-top u-margin-bottom-tiny u-word-break-word'>
      { code } { name }
    </h3>
    <div className='u-text--normal u-word-break-all'>
      { country.name } - { relationToHQ === 'hq' ? upperCase(relationToHQ) : startCase(relationToHQ) }
    </div>
  </ItemBox>
}

Company.propTypes = {
  company: PropTypes.object,
  params: PropTypes.object,
  match: PropTypes.object,
}

export default Company
