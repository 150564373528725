import { ORM } from 'redux-orm'
import Country from './models/country'
import Currency from './models/currency'
import Company from './models/company'
import ParentCompany from './models/parentCompany'
import Term from './models/term'
import CountryTerm from './models/countryTerm'
import CountryTimezone from './models/countryTimezone'
import Timezone from './models/timezone'
import Language from './models/language'
import TaxAgency from './models/taxAgency'
import Deadline from './models/deadline'
import CompanyCountryTerm from './models/companyCountryTerm'
import Category from './models/category'
import SubCategory from './models/subCategory'
import EmployeeSystemUser from './models/employeeSystemUser'
import Editor from './models/editor'
import CotUser from './models/cotUser'
import VendorUser from './models/vendorUser'
import CompanyCountryService from './models/companyCountryService'
import CompanyCountryProcess from './models/companyCountryProcess'
import CompanyCountryStep from './models/companyCountryStep'
import CompanyCountryTask from './models/companyCountryTask'
import CompanyHoliday from './models/companyHoliday'
import CountryService from './models/countryService'
import CountryProcess from './models/countryProcess'
import CountryStep from './models/countryStep'
import CountryTask from './models/countryTask'
import Service from './models/service'
import Process from './models/process'
import Step from './models/step'
import Task from './models/task'
import Paygroup from './models/paygroup'
import Payroll from './models/payroll'
import PaygroupTerm from './models/paygroupTerm'
import PaygroupEmployee from './models/paygroupEmployee'
import PayrollEmployee from './models/payrollEmployee'
import PayrollInstanceEmployee from './models/payrollInstanceEmployee'
import PayrollPaygroup from './models/payrollPaygroup'
import EmployeeCompanyCountryTerm from './models/employeeCompanyCountryTerm'
import PayrollInstanceCompanyCountryTerm from './models/payrollInstanceCompanyCountryTerm'
import EmployeeAttendance from './models/employeeAttendance'
import EmployeeOvertime from './models/employeeOvertime'
import Holiday from './models/holiday'
import Notification from './models/notification'
import EmployeeAttendancePeriod from './models/employeeAttendancePeriod'
import Vendor from './models/vendor'
import VendorCountryTask from './models/vendorCountryTask'
import VendorCountryService from './models/vendorCountryService'
import PayrollService from './models/payrollService'
import PayrollProcess from './models/payrollProcess'
import PayrollStep from './models/payrollStep'
import PayrollTask from './models/payrollTask'
import BusinessUnit from './models/businessUnit'
import Department from './models/department'
import CostCenter from './models/costCenter'
import OfficeLocation from './models/officeLocation'
import Subsidiary from './models/subsidiary'
import Project from './models/project'
import Payfile from './models/payfile'
import EmptyPayfile from './models/emptyPayfile'
import PayfileChange from './models/payfileChange'
import PayrollInstance from './models/payrollInstance'
import PayrollInstanceService from './models/payrollInstanceService'
import PayrollInstanceProcess from './models/payrollInstanceProcess'
import PayrollInstanceStep from './models/payrollInstanceStep'
import PayrollInstanceTask from './models/payrollInstanceTask'
import PayrollInstanceTaskOwner from './models/payrollInstanceTaskOwner'
import PayrollInstanceTaskOwnerScope from './models/payrollInstanceTaskOwnerScope'
import AccessArea from './models/accessArea'
import Event from './models/event'
import Assignment from './models/assignment'
import BankAccount from './models/bankAccount'
import BankAccountType from './models/bankAccountType'
import EmployeeCountry from './models/employeeCountry'
import Note from './models/note'
import NoteUser from './models/noteUsers'
import Report from './models/report'
import FxRate from './models/fxRate'
import DataProtectionField from './models/dataProtectionField'
import CountryDataProtectionField from './models/countryDataProtectionField'
import DataOwner from './models/dataOwner'
import CompanyDataProtectionField from './models/companyDataProtectionField'
import ApprovedCompanyDataProtectionField from './models/approvedCompanyDataProtectionField'
import Document from './models/document'
import DocumentUser from './models/documentUsers'
import ExternalVendorUser from './models/externalVendorUsers'
import ExternalEmployeeUser from './models/externalEmployeeUsers'
import CompanyDataProtectionFieldsRevision from './models/companyDataProtectionFieldsRevision'
import DocumentLog from './models/documentLogs'
import DocumentType from './models/documentTypes'
import CompanyDataProtectionFieldState from './models/companyDataProtectionFieldState'
import PayrollTaskOwners from './models/payrollTaskOwner'
import PayrollTaskOwnerScopes from './models/payrollTaskOwnerScope'
import AnnualSalary from './models/annualSalary'
import NoteShare from './models/noteShares'
import Referral from './models/referral'
import Dashboard from './models/dashboard'
import GeneralLedgers from './models/generalLedgers'
import ReportData from './models/reportData'
import GeneralLedgerGlobalData from './models/generalLedgerGlobalData'
import EmployeeUserAddresses from './models/employeeUserAddresses'
import VendorSwitch from './models/vendorSwitch'
import PollingJob from './models/PollingJob'
import PayrollInstanceVersion from './models/payrollInstanceVersion'
import ExternalCompany from './models/externalCompanies'
import Commentary from './models/commentary'
import AdditionalItem from './models/additionalITem'
import Resource from './models/resource'
import ResourceLog from './models/resourceLog'

const orm = new ORM({
  stateSelector: (state) => state.orm,
})
orm.register(
  Country,
  Currency,
  Company,
  ParentCompany,
  Category,
  SubCategory,
  Term,
  CountryTerm,
  CompanyCountryTerm,
  EmployeeSystemUser,
  Editor,
  CotUser,
  VendorUser,
  CompanyCountryService,
  CompanyCountryProcess,
  CompanyCountryStep,
  CompanyCountryTask,
  CountryService,
  CountryProcess,
  CountryStep,
  CompanyHoliday,
  Service,
  Paygroup,
  Payroll,
  PaygroupTerm,
  PaygroupEmployee,
  PayrollPaygroup,
  PayrollInstanceEmployee,
  EmployeeCompanyCountryTerm,
  Payfile,
  EmptyPayfile,
  PayfileChange,
  PayrollInstance,
  PayrollInstanceCompanyCountryTerm,
  PayrollEmployee,
  EmployeeAttendance,
  EmployeeOvertime,
  Holiday,
  Notification,
  EmployeeAttendancePeriod,
  Vendor,
  Process,
  Step,
  Task,
  CountryTask,
  VendorCountryTask,
  VendorCountryService,
  PayrollService,
  PayrollProcess,
  PayrollStep,
  PayrollTask,
  BusinessUnit,
  Department,
  CostCenter,
  OfficeLocation,
  Subsidiary,
  Project,
  PayrollInstanceService,
  PayrollInstanceProcess,
  PayrollInstanceStep,
  PayrollInstanceTask,
  PayrollInstanceTaskOwner,
  PayrollInstanceTaskOwnerScope,
  CountryTimezone,
  Timezone,
  Language,
  TaxAgency,
  Deadline,
  AccessArea,
  Event,
  Assignment,
  BankAccount,
  BankAccountType,
  EmployeeCountry,
  Note,
  NoteUser,
  Report,
  FxRate,
  DataProtectionField,
  CountryDataProtectionField,
  DataOwner,
  CompanyDataProtectionField,
  ApprovedCompanyDataProtectionField,
  Document,
  DocumentUser,
  ExternalVendorUser,
  ExternalEmployeeUser,
  ExternalCompany,
  CompanyDataProtectionFieldsRevision,
  DocumentLog,
  DocumentType,
  CompanyDataProtectionFieldState,
  PayrollTaskOwners,
  PayrollTaskOwnerScopes,
  AnnualSalary,
  NoteShare,
  Referral,
  GeneralLedgers,
  ReportData,
  GeneralLedgerGlobalData,
  VendorSwitch,
  EmployeeUserAddresses,
  Dashboard,
  PollingJob,
  PayrollInstanceVersion,
  Commentary,
  AdditionalItem,
  Resource,
  ResourceLog
)

export default orm
