import { ActionUtility } from 'utils/redux'
import { invalidateVendorCountryTask } from './vendorCountryTaskPivot'

// ------------------------------------
// Constants
// ------------------------------------
export const VENDOR_COUNTRY_SERVICE_FETCH = 'VENDOR_COUNTRY_SERVICE_FETCH'
export const VENDOR_COUNTRY_SERVICE_RECEIVE = 'VENDOR_COUNTRY_SERVICE_RECEIVE'
export const VENDOR_COUNTRY_SERVICE_CREATE = 'VENDOR_COUNTRY_SERVICE_CREATE'
export const VENDOR_COUNTRY_SERVICE_INVALIDATE = 'VENDOR_COUNTRY_SERVICE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: VENDOR_COUNTRY_SERVICE_FETCH,
  receive: VENDOR_COUNTRY_SERVICE_RECEIVE,
  create: VENDOR_COUNTRY_SERVICE_CREATE,
  invalidate: VENDOR_COUNTRY_SERVICE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'vendorCountryServicePivot', 'vendorcountryservices', 'VendorCountryService')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchVendorCountryServicePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchVendorCountryServicePivot = actionUtility.fetchEntities
export const invalidateVendorCountryServicePivot = actionUtility.invalidate

export const createVendorCountryService = (vendorId, countryServiceId) => {
  return dispatch => {
    const data = {
      'vendor': vendorId,
      'countryService': countryServiceId
    }

    return dispatch(actionUtility.createEntity(data)).then(() => {
      dispatch(invalidateVendorCountryTask())
    })
  }
}

export const removeVendorCountryService = (vendorId, countryServiceId) => {
  return (dispatch, getState, { api }) => {
    const uri = `vendors/${vendorId}/countryservices`

    const data = {
      'data' : [countryServiceId]
    }

    return api.delete(uri, { payload: data }).then(() => {
      dispatch(invalidateVendorCountryTask())
    })
  }
}
