import createSelector from 'utils/createSelector'
import { getCompiledUri } from 'utils/templates'
import { hasAccess } from 'redux/selectors/auth'
import _ from 'lodash'
import { completionURIs } from 'redux/config/tasks'
import {
  makeDisabledPayrollInstanceTasks,
  makePayrollInstanceTasksInitials
} from 'redux/selectors/payrollInstanceTasks'
import { tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import { statuses } from 'redux/config/payrollInstanceTasks'
import { sortASCByPosition } from 'redux/models/task'

const getPayrollInstanceStepId = (state, props) => parseInt(props.payrollInstanceStepId)
const getPayrollInstanceTaskId = (state, props) => parseInt(props.payrollInstanceTaskId)
const getPayrollInstanceTaskIds = (state, props) => props.payrollInstanceTaskIds
const getFormValues = (state, props) => props.formValues

export const getPayrollInstanceTasksByStep = createSelector(getPayrollInstanceStepId, hasAccess,
  ({ PayrollInstanceTask }, stepId, hasAccess) => {
    return _getPayrollInstanceTasksByStep(PayrollInstanceTask, stepId, hasAccess)
  })

// Transform task link value to URI
const buildLink = (task, hasAccess) => {
  let finishTaskLink = task.finishTaskLink

  const linkMapping = finishTaskLink ? _.find(completionURIs, { value: finishTaskLink }) : null
  const hasAccessToUri = linkMapping && hasAccess(linkMapping.requiredPermissions)

  if (!linkMapping || !hasAccessToUri) return null

  return _.includes(finishTaskLink, 'companies') ? getCompiledUri(linkMapping.uri, task.company)
    : getCompiledUri(linkMapping.uri, task.payrollInstance)
}

export const _getPayrollInstanceTasksByStep = (PayrollInstanceTask, stepId, hasAccess) => {
  const tasks = PayrollInstanceTask
    .filter(task => task.payrollInstanceStep === stepId)
    .toRefArray()
    .map(task => {
      return {
        ...task,
        finishTaskLink: buildLink(task, hasAccess),
        payrollTaskId: task.payrollTask
      }
    })
    .sort(sortASCByPosition)

  return tasks
}

export const getPayrollInstanceTasksByStepInitial = makePayrollInstanceTasksInitials(getPayrollInstanceTasksByStep)
export const getDisabledPayrollInstanceTasksByStep = makeDisabledPayrollInstanceTasks(getPayrollInstanceTasksByStep)

export const isPaymentTaskCompleted = createSelector(getFormValues,
  ({ PayrollInstanceTask }, formValues) => {
    // Completed tasks, according to the passed in `formValues`
    const completedTasks = _.pickBy(formValues, value => value.status === statuses.completed.value)

    // Sanitize the ids
    const ids = _.keys(completedTasks).map(key => parseInt(JSON.parse(key).id))

    // In the old Payruns, the tasks structure uses `sendForPayment` and this selector relied only on this identifier.
    // Later, the Business team decided to stop using `sendForPayment` and to start using `arrangeForPayment`
    // in the new Payruns. Because of this identifiers change - we should support both identifiers codes,
    // in order old and new Payruns to continue work correctly.
    const { sendForPayment, arrangeForPayment } = tasksIdentifiers

    const paymentTask = ids.find(id => {
      const payrollInstanceTask = PayrollInstanceTask.withId(id)

      return [sendForPayment.code, arrangeForPayment.code].includes(payrollInstanceTask.identifier)
    })

    return paymentTask !== undefined
  })

export const getPayrollInstanceTaskRef = createSelector(getPayrollInstanceTaskId,
  ({ PayrollInstanceTask }, taskId) => ({ ...PayrollInstanceTask.withId(taskId).ref }))

export const getPayrollInstanceTasksThatAreNotCompletedRef = createSelector(getPayrollInstanceTaskIds,
  ({ PayrollInstanceTask }, taskIds) => {
    let tasks = []
    taskIds.forEach(id => {
      tasks.push(PayrollInstanceTask.withId(id).ref)
    })

    return tasks.filter(task => task.status !== statuses.completed.value)
  })
