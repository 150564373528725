/**
 * Promisify the redirect to `/pure` route and zero delay Promise's resolving
 *
 * First of all we do such `/pure` route redirect for the following reasons:
 * @docs: routes/Pure/components/RouteView.js
 *
 * We promisify it, only for the easy of use. Nothing more. We could do it with callback too.
 *
 * The most important reason of this utility is the zero delay (setTimeout) functionality.
 * As you already know (please refer to the above docs), before switching to other Tenant,
 * we firstly go to this `/pure` route and after that we navigate to other route immediately.
 * Doing such immediate route changing (< 1ms), for some reason `react-router` doesn't mount the `/pure` component.
 * Therefore, this breaks our pure concept.
 * I guess it's caused by `react-router` performance optimizations, but I didn't find out any documentation notes.
 * Because of this - we zero delay promise resolving (next route navigating)
 * and the `pure` component is mounted successfully.
 *
 * Read more about zero delaying here:
 * @url https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#Zero_delays
 *
 * @example usage:
 * ```
 * purify(history).then(() => {
 *   dispatch(selectTenant({ url, type, id }))
 *   browserHistory.push(`/home`)
 * })
 * ```
 *
 * @returns Promise
 */
export const purify = (history) => new Promise(resolve => {
  history.push(`/pure`)
  setTimeout(resolve)
})

export const setActiveMenuByRoute = ({ match, userId, path = 'employees' }) => {
  return userId
    ? `${match.url}` === `/${path}/${userId}`
    : match.url.includes(path)
}
