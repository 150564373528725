import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyDataProtectionFieldState'

class CompanyDataProtectionFieldState extends CRUDModel {}
CompanyDataProtectionFieldState.modelName = 'CompanyDataProtectionFieldState'
CompanyDataProtectionFieldState.actionTypes = actionTypes

CompanyDataProtectionFieldState.fields = {
  id: attr(),
  companyDataProtectionFieldRevision: fk('CompanyDataProtectionFieldsRevision', 'companyDataProtectionFieldState'),
  countryDataProtectionField: fk('CountryDataProtectionField', 'companyDataProtectionFieldState')
}

export default CompanyDataProtectionFieldState
