import React from 'react'
import { NavLink } from 'react-router-dom'

const Logo = () => (
  <div className='logo-component'>
    <NavLink to='/' className='c-logo c-logo--negative--full o-block' />
    <NavLink to='/' className='c-logo c-logo--negative--short o-block' />
  </div>
)
export default Logo
