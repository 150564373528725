const enhancedPush = (history) => (path, state) => {
  try {
    let newPath
    let newSearch = ''

    if (typeof path === 'object' && path !== null) {
      newPath = path.pathname
      newSearch = path.search || ''
    } else if (typeof path === 'string') {
      const [pathname, search = ''] = path.split('?')
      newPath = pathname
      newSearch = search ? `?${search}` : ''
    }

    const isDifferentRoute = newPath !== history.location.pathname || newSearch !== history.location.search

    if (isDifferentRoute) {
      return history.push(path, state)
    }
  } catch (_) {
    return history.push(path, state)
  }
}

export const useHistoryProxy = (history) => {
  history.enhancedPush = enhancedPush(history)
  return history
}
