export const ALL_ROUTES = {
  RHSSO: 'rhsso',
  COLOR_PICKER_GENERATOR: 'color-picker-generator',
  PAYRUNS: {
    BASE: 'payruns',
    CLOSED: 'previous',
    REOPENED: 'reopened',
    PAY_AND_TAXES_CHANGES: 'pay-and-taxes-changes',
    PAYROLL_RECONCILIATION: 'payroll-reconciliation',
    ELEMENT_RECONCILIATION: 'element-reconciliation',
    PAYROLL_SUMMARY: 'payroll-summary',
  },
}
