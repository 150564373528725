export const types = [
  { value: 'NewRunRequested', label: 'New run requested' },
  { value: 'ClarificationRequired', label: 'Clarification required' },
]

const newRunRequestedReasons = [
  { value: 'Late inputs Company', label: 'Late inputs Company' },
  { value: 'Incorrect GTN calculations PP', label: 'Incorrect GTN calculations PP' },
  { value: 'Missing inputs PP', label: 'Missing inputs PP' },
  { value: 'Missing inputs Company', label: 'Missing inputs Company' },
  { value: 'Inputs adjustments Company', label: 'Inputs adjustments Company' },
  { value: 'Joiners/ Movers/ Leavers Company', label: 'Joiners/ Movers/ Leavers Company' },
  { value: 'Joiners/ Movers/ Leavers PP', label: 'Joiners/ Movers/ Leavers PP' },
  { value: 'Other', label: 'Other' },
]

const clarificationRequiredReasons = [
  { value: 'Pay elements', label: 'Pay elements' },
  { value: 'Deductions', label: 'Deductions' },
  { value: 'Net Pay', label: 'Net Pay' },
  { value: 'Pension', label: 'Pension' },
  { value: 'Employer Cost', label: 'Employer Cost' },
  { value: 'Other', label: 'Other' },
]

/**
 * Return reversion reasons by reversion type.
 * Currently this works with two types: 'ClarificationRequired' and 'NewRunRequested'
 *
 * @param {string} type
 * @returns {Array}
 */
export const getReasons = type => {
  if (!type) return null

  if (type === 'ClarificationRequired') {
    return clarificationRequiredReasons
  }

  return newRunRequestedReasons
}
