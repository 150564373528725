export const FETCH_BRANDINGS = 'FETCH_BRANDINGS'
export const RECEIVE_BRANDINGS = 'RECEIVE_BRANDINGS'

// ------------------------------------
// Actions
// ------------------------------------
const fetchBrandings = () => ({
  type: FETCH_BRANDINGS
})

export const receiveBrandings = brand => ({
  type: RECEIVE_BRANDINGS,
  payload: brand
})

export const getWhiteLabeledBrandStyles = (data) => {
  return (dispatch, getState, { _api }) => {
    dispatch(fetchBrandings())
    return fetch('/branding.json')
      .then(async (response) => {
        const resp = await response.json()
        return dispatch(receiveBrandings(resp))
      })
  }
}
