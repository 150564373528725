import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { getTabByTypeClass, getTabLabel, types } from 'redux/config/documents'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Form from '../form/Form'
import ShareActionOwners from './ShareActionOwners'
import ShareWithinCompany from './ShareWithinCompany'
import ShareOutsideCompany from './ShareOutsideCompany'
import ShareOnboardingVendor from './ShareOnboardingVendor'
import ShareOffboardingVendor from './ShareOffboardingVendor'
import ShareSftp from './ShareSftp'
import ConfirmationModal from 'components/ConfirmationModal'
import { reusableModalHeadings } from 'utils/locales/modals.en'
import { shareLabels } from 'utils/locales/shares.en'
import { getStorageItem, setStorageItem } from 'utils/storage'

const DocumentTabs = (props) => {
  const { typeClass, hasOnboardingVendor, hasOffboardingVendor, id } = props
  const tabs = typeClass ? types[typeClass].clientShareTabs : []
  const [formData, setFormData] = useState(null)
  const confirmationModal = useRef(null)

  // Key to store tabIndex in localStorage
  const storageKey = `${id}_tabIndex`

  // Retrieve tabIndex from localStorage or default to 0
  const savedTabIndex = parseInt(getStorageItem(storageKey), 10) || 0
  const [tabIndex, setTabIndex] = useState(savedTabIndex)

  const shouldShowConfirmationModal = props?.isSftpSelected && !props.initialValues?.sftp

  const handleTabChange = (index) => {
    // Store the tabIndex in localStorage
    setStorageItem(storageKey, index)
    setTabIndex(index) // Update the state
  }

  // Showing confirmation modal flow before submission
  const showConfirmationModal = (event, confirmationModal) => {
    event.preventDefault()
    setFormData({ event })
    confirmationModal.current.showModal()
  }

  // Direct form submissions
  const submitFormDirectly = (event, handleSubmit, onSubmit) => {
    const submitter = handleSubmit((data) => onSubmit(data))
    submitter(event)
  }

  const handleOnSubmit = (event) => {
    if (shouldShowConfirmationModal) {
      showConfirmationModal(event, confirmationModal)
    } else {
      submitFormDirectly(event, props.handleSubmit, props.onSubmit)
    }
  }

  const handleSubmitWithConfirmation = (isRejected) => {
    const submitter = props.handleSubmit((data) => props.onSubmit(data, isRejected))
    submitter(formData)
  }

  const tabComponents = {
    onboardingVendor: {
      Tab: hasOnboardingVendor && (
        <Tab key='onboardingVendor' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'onboardingVendor' })}</span>
        </Tab>
      ),
      TabPanel: hasOnboardingVendor && (
        <TabPanel key='onboardingVendor'>
          <ShareOnboardingVendor {...props} />
        </TabPanel>
      ),
    },
    offboardingVendor: {
      Tab: hasOffboardingVendor && (
        <Tab key='offboardingVendor' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'offboardingVendor' })}</span>
        </Tab>
      ),
      TabPanel: hasOffboardingVendor && (
        <TabPanel key='offboardingVendor'>
          <ShareOffboardingVendor {...props} />
        </TabPanel>
      ),
    },
    actionOwners: {
      Tab: (
        <Tab key='actionOwners' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'actionOwners' })}</span>
        </Tab>
      ),
      TabPanel: (
        <TabPanel key='actionOwners'>
          <ShareActionOwners {...props} />
        </TabPanel>
      ),
    },
    withinCompany: {
      Tab: (
        <Tab key='withinCompany' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'withinCompany' })}</span>
        </Tab>
      ),
      TabPanel: (
        <TabPanel key='withinCompany'>
          <ShareWithinCompany {...props} {...getTabByTypeClass({ typeClass, tabName: 'withinCompany' })} />
        </TabPanel>
      ),
    },
    outsideCompany: {
      Tab: (
        <Tab key='outsideCompany' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'outsideCompany' })}</span>
        </Tab>
      ),
      TabPanel: (
        <TabPanel key='outsideCompany'>
          <ShareOutsideCompany {...props} />
        </TabPanel>
      ),
    },
    sftp: {
      Tab: (
        <Tab key='sftp' className='c-tabs__item'>
          <span>{getTabLabel({ typeClass, tabName: 'sftp' })}</span>
        </Tab>
      ),
      TabPanel: (
        <TabPanel key='sftp'>
          <ShareSftp {...props} />
        </TabPanel>
      ),
    },
  }

  return (
    <>
      <ConfirmationModal
        ref={confirmationModal}
        className='c-modal'
        modalHeading={reusableModalHeadings.confirmation}
        onConfirm={() => handleSubmitWithConfirmation(false)}
        onReject={() => handleSubmitWithConfirmation(true)}
      >
        <p>{shareLabels.sftpConfirmation}</p>
        <p className='u-text--italic u-text--center u-text--small'>{shareLabels.sftpConfirmationDisclaimer}</p>
      </ConfirmationModal>
      <Form {...props} data-testid='share-form' handleSubmit={handleOnSubmit}>
        <Tabs selectedIndex={tabIndex} onSelect={handleTabChange}>
          <TabList className='c-tabs__list'>{tabs.map((tab) => tabComponents[tab.name]?.Tab)}</TabList>
          {tabs.map((tab) => tabComponents[tab.name]?.TabPanel)}
        </Tabs>
      </Form>
    </>
  )
}

DocumentTabs.propTypes = {
  typeClass: PropTypes.string,
  tabs: PropTypes.array,
  hasOnboardingVendor: PropTypes.bool,
  hasOffboardingVendor: PropTypes.bool,
  onChangeTab: PropTypes.func.isRequired, // Ensure this is passed in props
}

export default DocumentTabs
