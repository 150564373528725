import React from 'react'

/* eslint-disable max-len */
export const TicketingIcon = ({ className, children, title, ...props }) => (
  <div className={className} title={title}>
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.4167 9.375C10.4167 8.96296 10.5389 8.56017 10.7678 8.21756C10.9967 7.87496 11.3221 7.60793 11.7027 7.45025C12.0834 7.29257 12.5023 7.25131 12.9064 7.3317C13.3106 7.41208 13.6818 7.6105 13.9731 7.90186C14.2645 8.19322 14.4629 8.56444 14.5433 8.96856C14.6237 9.37269 14.5824 9.79158 14.4247 10.1723C14.2671 10.5529 14 10.8783 13.6574 11.1072C13.3148 11.3361 12.912 11.4583 12.5 11.4583V12.5M14.8437 19.7917L13.3333 21.8056C12.9167 22.3611 12.0833 22.3611 11.6667 21.8056L10.1562 19.7917H7.29167C4.99048 19.7917 3.125 17.9262 3.125 15.625V7.29167C3.125 4.99048 4.99048 3.125 7.29167 3.125H17.7083C20.0095 3.125 21.875 4.99048 21.875 7.29167V15.625C21.875 17.9262 20.0095 19.7917 17.7083 19.7917H14.8437Z'
        stroke='#291547'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='12.5002' cy='15.6252' r='1.04167' fill='#291547' />
    </svg>
    {children}
  </div>
)
