import React from 'react'
import { connect } from 'react-redux'
import HomeView from '../components/HomeView'
import {
  isCot,
  isUser,
  isPayrollAdmin,
  isPayrollAnalyst,
  isFinanceAdmin,
  isEmployeeOnly,
  isAccounting,
  isControlling,
  isPayrollDataEntry,
  isVendorInvolved,
} from 'redux/selectors/auth'
import { isParentCompanyTenant, isVendorTenant } from 'redux/selectors/tenants'
import PropTypes from 'prop-types'
import { useHistoryProxy } from 'utils/history'

class HomeContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const {
      id,
      tenantId,
      isUser,
      isPayrollAdmin,
      isPayrollAnalyst,
      isVendor,
      isParentCompanyTenant,
      isVendorTenant,
      isEmployeeOnly,
      isFinanceAdmin,
      isAccountingRole,
      isControlling,
      isCot,
      isPayrollDataEntry,
      history: reactHistory,
    } = this.props
    const history = useHistoryProxy(reactHistory)

    if (isCot && !isParentCompanyTenant && !isVendorTenant) return history.enhancedPush(`/home`)
    if (isEmployeeOnly) return history.enhancedPush(`/employees/${id}/payslips`)
    if (isUser && !(isPayrollAdmin || isPayrollAnalyst || isFinanceAdmin || isControlling || isPayrollDataEntry)) {
      return history.enhancedPush(`/employees/${id}/personal`)
    }
    if (isPayrollAdmin || isPayrollAnalyst || isFinanceAdmin || isControlling || isPayrollDataEntry) {
      return history.enhancedPush('/dashboard')
    }
    if (isVendor) return history.enhancedPush(`/vendors-redirect`)
    if (isVendorTenant) return history.enhancedPush(`/vendors/${tenantId}/company-details`)
    if (isParentCompanyTenant) return history.enhancedPush(`/dashboard`)
    if (isAccountingRole) return history.enhancedPush(`/dashboard`)
  }

  render () {
    return <HomeView {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.auth.userId,
    tenantId: state.tenants.id,
    isCot: isCot(state),
    isUser: isUser(state),
    isVendor: isVendorInvolved(state),
    isPayrollAdmin: isPayrollAdmin(state),
    isPayrollAnalyst: isPayrollAnalyst(state),
    isFinanceAdmin: isFinanceAdmin(state),
    isParentCompanyTenant: isParentCompanyTenant(state),
    isVendorTenant: isVendorTenant(state),
    isEmployeeOnly: isEmployeeOnly(state),
    isAccountingRole: isAccounting(state),
    isControlling: isControlling(state),
    isPayrollDataEntry: isPayrollDataEntry(state),
    // Current FE revision version
    // The variable is automatically injected by webpack.config.js
    versions: {
      // eslint-disable-next-line no-undef
      frontEnd: __VERSION__,
      backEnd: state.config.version,
    },
  }
}

HomeContainer.propTypes = {
  id: PropTypes.number,
  tenantId: PropTypes.number,
  history: PropTypes.object,
  isUser: PropTypes.bool,
  isPayrollAdmin: PropTypes.bool,
  isPayrollAnalyst: PropTypes.bool,
  isVendor: PropTypes.bool,
  isParentCompanyTenant: PropTypes.bool,
  isVendorTenant: PropTypes.bool,
  isEmployeeOnly: PropTypes.bool,
  isFinanceAdmin: PropTypes.bool,
  isAccountingRole: PropTypes.bool,
  isControlling: PropTypes.bool,
  isPayrollDataEntry: PropTypes.bool,
  isCot: PropTypes.bool,
}

export default connect(mapStateToProps)(HomeContainer)
