import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import employeeStatusEnum from 'utils/enums/employmentStatuses'
import { downloadTemplate, download } from 'redux/actions/employeeSystemUsers'
import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { commonLabels, commonDescriptions } from 'utils/locales/employeeBulkCommon.en'
import { requiredFieldsLabels, requiredFieldsDescriptions, dataValidationsLabels, dataValidationsDescriptions } from 'utils/locales/employeeBulkCreate.en'
import { createNewEmployeesBulkCreateJob } from 'redux/actions/pollingJob'

const TemplateClarifications = () => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading text='Create multiple employees' />
    <p className='u-margin-bottom-small'>This template allows you to create multiple employee files in bulk. Follow the simple process described below:</p>
    <ol className='u-margin-bottom-small'>
      <li>Download template</li>
      <li>Complete data -please pay attention to the required fields (listed below) and their validations</li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
      <li>Profiles will be created and import results displayed</li>
    </ol>
    <p className='u-margin-bottom-small'>
      <u>Error message</u>: This happens when there are errors in the upload file. You can view details on the import page. Fix the errors (you can work both
      with original or error file) and attempt the upload again.
    </p>
    <b>Note: </b>
    Employees will be assigned to the current active payrun and appear as joiners only if they have proper start dates (falling in current active payrun) and
    the payrun has not been locked for changes (Action B1 Approve All Updates is not completed)
  </div>
)

export default (props) => (
  <ImportFacade
    {...props}
    heading='Import employees'
    entityName='employees'
    downloadAction={download}
    downloadTemplateAction={() => downloadTemplate()}
    importAction={({ file }) => createNewEmployeesBulkCreateJob(file)}
    TemplateClarifications={TemplateClarifications}
    hasFilters={false}
    isAsync
  >
    <div className='o-layout'>
      <div className='o-layout__item u-1/2 u-text--normal'>
        <SectionHeading text='Required fields:' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.firstName}</span>
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.surname}</span>
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.workEmail}</span> {commonDescriptions.email}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.paymentCurrency}</span> {requiredFieldsDescriptions.paymentCurrency}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.company}</span> {requiredFieldsDescriptions.company}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.country}</span> {requiredFieldsDescriptions.country}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.startDate}</span> {requiredFieldsDescriptions.startDate}
        </p>
      </div>

      <div className='o-layout__item u-1/2 u-text--normal'>
        <SectionHeading fullWidth text='Other data validations (all are case-insensitive):' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.dateFormat}</span> {commonDescriptions.dateFormat}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.title}</span> - {titleTypes.map((t) => t.label).join(', ')}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.gender}</span> {dataValidationsDescriptions.gender}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.mobilePhone}</span> {commonDescriptions.mobilePhone}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.officePhone}</span> {dataValidationsDescriptions.officePhone}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.typeOfEmployment}</span> - {employeeStatusEnum.ALL_IMPORT_LABELS.join(', ')}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.fullTimePartTime}</span> {commonDescriptions.fullTimePartTime}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.visaWorkPermit}</span> {commonDescriptions.visaWorkPermit}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.employeeId}</span> {dataValidationsDescriptions.employeeId}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.maritalStatus}</span> {commonDescriptions.maritalStatus}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.employeeSelfService}</span> {dataValidationsDescriptions.employeeSelfService}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.mode2FA}</span> {dataValidationsDescriptions.mode2FA}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.paymentType}</span> {commonDescriptions.paymentType}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.orgUnit}</span> {commonDescriptions.orgUnit}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.churchTax}</span> {dataValidationsDescriptions.churchTax}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.taxClass}</span> {dataValidationsDescriptions.taxClass}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.nationalInsuranceLetter}</span> {commonDescriptions.nationalInsuranceLetter}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.hecs}</span> {dataValidationsDescriptions.hecs}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.otherAddressFields}</span> {commonDescriptions.otherAddressFields}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.typeOfAddress}</span> {commonDescriptions.typeOfAddress}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.countryOfBirth}</span> {commonDescriptions.countryOfBirth}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.endDate}</span> {commonDescriptions.endDate}
        </p>
      </div>
    </div>
  </ImportFacade>
)
