/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react'
import Select from 'components/form/select/Select'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import StatusSwitchToggle from 'components/form/StatusSwitchToggle'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import IntlTelInput from 'react-intl-tel-input'
import trim from 'lodash/trim'
import { phoneValidation } from 'utils/validations'
import { batch } from 'react-redux'
import NumberFormat from 'react-number-format'
import AmountFormat from 'components/AmountFormat'
import { calculateCellHeight } from './editCellTableHelper'
import { debounce } from 'lodash'

const commonBtnCss = 'c-btn c-btn--tiny c-btn--curious u-padding-left-small u-padding-right-small u-margin-left-tiny'

const formatInputValueToCorrectFormat = (date) => {
  if (date instanceof Date) return date
  date = trim(date)
  if (isString(date) && date !== '') return moment(date, 'DD/MM/YYYY').toDate()
  if (date === '') return null
  if (typeof date === 'number') return null
  return date.toDate()
}

const ErrorDisplay = ({ errors = [] }) => {
  return errors.map((err) => (
    <span key={err} title={err} className='error payslip-table-error-elipsis text-center'>
      {err}
    </span>
  ))
}

const ErrorContainer = ({ fieldErrors, children }) => (!isEmpty(fieldErrors) ? <div className='table-hint-container'>{children}</div> : <>{children}</>)

const EditableCell = ({
  value: initialValue,
  row: { index, original, values, ...restRow },
  column: {
    id,
    editable,
    state,
    requiredField,
    Header,
    inputType = 'input',
    charactersPerLine,
    focusableIndex,
    toggleOptions = {},
    dropDownOptionsForInput = [],
    hasInlineCancel = false,
    hasInlineSave = false,
    cellClassName,
    ...restColumn
  },
  inGlobalEditMode = false,
  onUpdateGlobalData,
  needsValueUpdate,
  focusableColumn,
  onCancelInlineAdd,
  onSaveInline,
  onValueChange = () => null,
  fieldErrors,
  formattedData = {},
  ...rest
}) => {
  if (!isEmpty(formattedData)) {
    inputType = formattedData.inputType
    dropDownOptionsForInput = formattedData.dropDownOptionsForInput
  }

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const [phoneNumberValidation, setPhoneNumberValidation] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [dateValidation, setDateValidation] = useState()
  const debouncedValueChange = debounce(() => {
    if (onUpdateGlobalData) {
      onUpdateGlobalData(index, id, value, original)
    }
  }, 500)

  const onChange = (e) => {
    setValue(e.target.value)
    onValueChange(e)
  }

  const onDateChange = (date) => {
    let hasErrors
    if (original.metaData.validation && value) {
      const compareValue = rest.data.find((info) => info.metaData?.field === original.metaData.validation.compare)
      hasErrors = original.metaData.validation.errorFn(value, compareValue.value)
    }

    batch(() => {
      setValue(date)
      setDateValidation(hasErrors)
    })
  }

  const onToggleChange = (val, q, a) => {
    setValue(toggleOptions.dataMappingForStatus[val])
    onUpdateGlobalData(index, id, toggleOptions.dataMappingForStatus[val], original)
  }

  const onSelectChange = (val) => {
    setValue(val)
    onUpdateGlobalData(index, id, val, original)
  }

  const onAmountChange = ({ target }) => {
    const val = target.value
    setValue(val)
  }

  const onPhoneBlur = (status, value, countryData, number) => {
    const sanitized = number.replace(/\s+/g, '')
    setValue(sanitized)
    onUpdateGlobalData(index, id, sanitized, original)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (onUpdateGlobalData) onUpdateGlobalData(index, id, value, original)
  }

  const phoneHandler = useCallback(
    (status, value, countryData, number, id) => {
      // Remove spaces
      const formattedVal = {}
      const sanitizedNumber = number.replace(/\s+/g, '')
      // Format the result for the normal e.target.value that is defaulted in the onChange handler
      formattedVal['target'] = { value: sanitizedNumber }
      if (phoneValidation(sanitizedNumber)) {
        setPhoneNumberValidation([phoneValidation(sanitizedNumber)])
      } else {
        setPhoneNumberValidation([])
      }
      onChange(formattedVal)
    },
    [value]
  )

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (needsValueUpdate) {
      debouncedValueChange()
      // Clear the debounce timeout
      return () => {
        debouncedValueChange.cancel()
      }
    }
  }, [value])

  if ((editable && inGlobalEditMode) || original.isNew) {
    let field = value

    // if a specific cell can't be edited, we are just returning the value before formatting it with an input
    if (original.disableEdit) return field
    let hasErrors = {}

    if (requiredField && fieldErrors.length > 0) {
      // For Existing records
      hasErrors = fieldErrors.find((err) => err.id === original.id)
      if (!original.id && !original.isNew) {
        hasErrors = fieldErrors.find((err) => err.id === values.id)
      }
      // For New Error Just return the error where there is no id
      // TODO: For Later work, perhaps generate a unique id for multiple 'new' edits
      if (original.isNew) {
        hasErrors = fieldErrors.find((err) => err.id === 0)
      }
    }
    let hasFormErrors = null
    if (!requiredField && fieldErrors.length) {
      const localErrors = fieldErrors[0]
      // Find Error by field name
      const currentField = original.metaData?.field || null
      if (currentField && localErrors[currentField]) {
        hasFormErrors = localErrors[currentField]
      }
      // For instances where all row items are in one object, (ie. inline editing of joined columns)
      // We use a mappedError field that can be placed on the header
      if (restColumn.mappedErrorField && !currentField) {
        hasErrors = fieldErrors.find((err) => err.id === original.id)

        if (!original.id && !original.isNew) {
          hasErrors = fieldErrors.find((err) => err.id === values.id)
        }

        if (localErrors[original?.metaData?.mappedErrorFieldId]) {
          if (localErrors[original.metaData.mappedErrorFieldId][restColumn.mappedErrorField]) {
            hasFormErrors = localErrors[original.metaData.mappedErrorFieldId][restColumn.mappedErrorField]
          }
        }
        if (original.isNew && original.metaData?.mappedNewErrorId && localErrors[original.metaData.mappedNewErrorId]) {
          hasFormErrors = localErrors[original.metaData.mappedNewErrorId][restColumn.mappedErrorField]
        }
      }
    }
    switch (inputType) {
    case 'readonly':
      if (formattedData['customDisplay']) {
        field = <div title={original.metaData?.disabledReason || formattedData['customDisplay']}>{formattedData['customDisplay']}</div>
        break
      }
      field = <div>{value}</div>
      break
    case 'input':
      const isFocusableInput = index === focusableIndex && focusableColumn === id

      field = (
        <>
          <input
            className={`c-inline-edit-input rounded ${cellClassName || ''}`}
            value={value || ''}
            autoFocus={isFocusableInput}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={restColumn.placeholder ?? null}
            type={original.metaData?.type || 'text'}
            data-testid={original.metaData?.field || null}
          />
          <ErrorContainer fieldErrors={fieldErrors}>
            {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
            {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
          </ErrorContainer>
        </>
      )
      break
    case 'textArea':
      const hasFocusableColumn = typeof focusableColumn === 'string'
      const isFocusable = (focusableIndex === undefined || index === focusableIndex) && (!hasFocusableColumn || focusableColumn === id)

      const areaStyle = calculateCellHeight(charactersPerLine, value)

      field = (
        <>
          <textarea
            className={`c-inline-edit-textarea rounded ${cellClassName || ''}`}
            style={areaStyle}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={isFocusable}
            placeholder={restColumn.placeholder ?? null}
            type={original.metaData?.type || 'text'}
            data-testid={original.metaData?.field || null}
            maxLength={restColumn.maxLength || 4096}
            onKeyDown={(e) => {
              e.target.style.height = `${e.target.scrollHeight}px`
            }}
          />
          <div>{requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}</div>
          {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
        </>
      )
      break
    case 'amount':
      let hasErrorsForInline
      if (original?.metaData?.checkForEmptyValue?.shouldCheck && !value) {
        const checkField = original.metaData.checkForEmptyValue.field
        const errorReported = fieldErrors.find((err) => err[checkField])
        hasErrorsForInline = errorReported ? errorReported[checkField] : null
      }
      field = (
        <>
          <div className={'u-relative w-full '} data-testid={original?.metaData?.field ?? ''}>
            <NumberFormat
              value={value === null ? '' : value}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              onChange={onAmountChange}
              onBlur={onBlur}
              className={`c-inline-edit-input rounded text-right`}
              placeholder={restColumn.placeholder ?? null}
              data-testid={original?.metaData?.field || null}
            />
            <ErrorContainer fieldErrors={fieldErrors}>
              {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
              {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
              {hasErrorsForInline && <ErrorDisplay errors={[hasErrorsForInline]} />}
            </ErrorContainer>
          </div>
        </>
      )
      break
    case 'email':
      field = (
        <>
          <input
            className={`c-inline-edit-input rounded ${cellClassName || ''}`}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            data-testid={original.metaData.field}
            type='email'
          />
          {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
          {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
        </>
      )
      break
    case 'date':
      const options = omit(formattedData.dateOptions, 'value')
      let hasLocalErrors
      let hasInlineErrors
      if (original.metaData.validation && value) {
        const compareValue = rest.data.find((info) => info.metaData?.field === original.metaData.validation.compare)
        hasLocalErrors = original.metaData.validation.errorFn(value, compareValue.value)
      }
      if (original.metaData.checkForEmptyDate?.shouldCheck && !value) {
        const checkField = original.metaData.checkForEmptyDate.field
        const errorReported = fieldErrors.find((err) => err[checkField])
        hasInlineErrors = errorReported ? errorReported[checkField] : null
      }

      field = (
        <>
          <div className={'u-relative '} data-testid={original.metaData.field}>
            <DatePicker
              className={`c-inline-edit-input rounded ${cellClassName || ''}`}
              selected={value ? formatInputValueToCorrectFormat(value) : null}
              dateFormat='dd/MM/yyyy'
              autoComplete='off'
              placeholderText={restColumn.placeholder ?? null}
              onChange={onDateChange}
              onBlur={onBlur}
              onCalendarClose={onBlur}
              // TODO Martin: we have this restriction on KU create so it is good to have it here as well, but need to be discussed
              // the ticket is : https://ppayslip.atlassian.net/jira/software/c/projects/PAY2/boards/94?modal=detail&selectedIssue=PAY2-383
              // <Jordan>: We can put this further up in the chain
              //  There is a file called EditableCellDataEmployeeFieldsFormatter, where we set minDate,
              // In which the metaData should be able to support the callback to get a max date
              // maxDate={getTodaysDate}
              {...options}
            />
            {hasFormErrors && <ErrorDisplay errors={[hasFormErrors]} />}
            {hasInlineErrors && <ErrorDisplay errors={[hasInlineErrors]} />}
          </div>
          {hasLocalErrors && <ErrorDisplay errors={[hasLocalErrors]} />}
        </>
      )
      break
    case 'phone':
      field = (
        <>
          <span data-testid={original.metaData?.field ? `${original.metaData?.field}-wrapper` : null}>
            <IntlTelInput
              containerClassName={'intl-tel-input u-margin-bottom-tiny'}
              inputClassName={`c-input ${cellClassName}`}
              disabled={original.metaData.disabledOptions?.isDisabled}
              onPhoneNumberChange={phoneHandler}
              onPhoneNumberBlur={onPhoneBlur}
              defaultValue={value}
              defaultCountry={'ie'}
              data-testid={original.metaData?.field || null}
              format
            />
          </span>
          {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
          {!requiredField && hasFormErrors && !phoneNumberValidation.length && original.value === value && <ErrorDisplay errors={hasFormErrors} />}
          {phoneNumberValidation && <ErrorDisplay errors={phoneNumberValidation} />}
        </>
      )
      break
    case 'statusSwitchToggle':
      const shouldHideLabel = original.isNew && (hasInlineCancel || hasInlineSave)
      field = (
        <StatusSwitchToggle
          input={{ value, onChange: onToggleChange }}
          statusMapping={toggleOptions.statusMapping}
          mappedStatusLabels={toggleOptions.mappedStatusLabels}
          label={shouldHideLabel ? '' : value}
        />
      )
      break
    case 'dropDownSelection':
      field = (
        <>
          <Select
            className='c-inline-select-dropdown'
            value={value}
            name={`${id}-${value}`}
            label={value}
            labelClassName='c-label'
            placeholder='Select'
            options={dropDownOptionsForInput}
            clearable={false}
            onChange={(updatedValue) => onSelectChange(updatedValue)}
          />
          {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
          {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
        </>
      )
      if (!original.isNew) {
        field = (
          <>
            <span data-testid={original.metaData?.field || null}>
              <Select
                className='c-inline-select-dropdown'
                value={value}
                name={`${id}-${value}`}
                label={value}
                labelClassName='c-label'
                placeholder='Select'
                options={dropDownOptionsForInput}
                clearable={formattedData['clearable'] || false}
                onChange={(updatedValue) => onSelectChange(updatedValue)}
              />
            </span>
            {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
            {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
          </>
        )
      }
      break
    default:
      field = (
        <>
          <input
            className={`c-inline-edit-input rounded ${cellClassName || ''}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={restColumn.placeholder ?? null}
            data-testid={original.metaData?.field || null}
          />
          {requiredField && hasErrors && hasErrors[id] && <ErrorDisplay errors={hasErrors[id]} />}
          {!requiredField && hasFormErrors && <ErrorDisplay errors={hasFormErrors} />}
        </>
      )
      break
    }
    if (original.isNew) {
      const needsAlignColumn = (requiredField && hasErrors && hasErrors[id]) || !isEmpty(fieldErrors)

      return (
        <div className={`inline-actions d-flex ${needsAlignColumn ? 'd-flex--column' : ''}`}>
          {field}
          {hasInlineCancel && (
            <button className={`${commonBtnCss} u-margin-left-large u-text--small`} title='Cancel' onClick={onCancelInlineAdd}>
              Cancel
            </button>
          )}
          {hasInlineSave && (
            <button className={`${commonBtnCss}`} onClick={onSaveInline} title='Save'>
              <span className='icon icon--save' data-testid='save' />
            </button>
          )}
        </div>
      )
    }
    return field
  }

  let readOnlyValue = value
  switch (inputType) {
  case 'input':
    readOnlyValue = value
    break
  case 'amount':
    readOnlyValue = (
      <div className='u-text--right'>
        <AmountFormat amount={value} />
      </div>
    )
    break
  case 'statusSwitchToggle':
    readOnlyValue = (
      <StatusSwitchToggle
        input={{ value, onChange: null }}
        statusMapping={toggleOptions.statusMapping}
        mappedStatusLabels={toggleOptions.mappedStatusLabels}
        label={value}
        disabled
        onChange={null}
      />
    )
    break
  default:
    readOnlyValue = value
    if (formattedData['customDisplay']) {
      readOnlyValue = formattedData['customDisplay']
    }
    break
  }

  return readOnlyValue
}

export default EditableCell
