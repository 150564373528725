import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollTaskOwnerScopes'

class PayrollTaskOwnerScopes extends CRUDModel {}
PayrollTaskOwnerScopes.modelName = 'PayrollTaskOwnerScopes'
PayrollTaskOwnerScopes.actionTypes = actionTypes

PayrollTaskOwnerScopes.fields = {
  id: attr(),
  payrollTaskOwner: fk('PayrollTaskOwners', 'payrollTaskOwnerScopes')
}

export default PayrollTaskOwnerScopes
