import React from 'react'
import { payrunProgressStatus } from 'utils/locales/payruns.en'
import { payrunProgressStatusesEnum } from 'utils/enums/payrunStatuses'
import { ArrowTooltip } from 'components/popover/Floater'

const ProgressStatus = (instance) => {
  const defaultStatusConfig = {
    style: 'neutral',
    text: payrunProgressStatus.notStarted,
  }

  const statusConfigCases = {
    [payrunProgressStatusesEnum.OVERDUE]: {
      style: 'warning',
      text: payrunProgressStatus.overdue,
    },
    [payrunProgressStatusesEnum.PENDING]: {
      style: 'caution',
      text: payrunProgressStatus.pending,
    },
    [payrunProgressStatusesEnum.ON_TIME]: {
      style: 'safe',
      text: payrunProgressStatus.onTime,
    },
  }

  const statusConfig = statusConfigCases[instance.progressStatus] || defaultStatusConfig
  const showTooltip = instance.progressStatus === payrunProgressStatusesEnum.OVERDUE && instance.overdueTasks

  const statusLabel = (
    <div className={`c-status-label c-status-label--tiny c-status-label--${statusConfig.style} u-text--small`}>
      {showTooltip ? (
        <ArrowTooltip hoverTriggerElement={statusConfig.text} hoverDelayClose={0}>
          <div className='generic-floater-box floater-size--xs'>
            <p className='text-center u-text--small'>
              <span className='u-weight--bold'>{instance.overdueTasks}</span>
              <span className='u-weight--regular'>{payrunProgressStatus.tooltip}</span>
            </p>
          </div>
        </ArrowTooltip>
      ) : (
        statusConfig.text
      )}
    </div>
  )

  return statusLabel
}

export default ProgressStatus
