import React from 'react'
import PayslipsContainer from '../containers/PayslipsContainer'

class RouteView extends React.Component {
  render () {
    return <PayslipsContainer {...this.props} />
  }
}

export default RouteView
