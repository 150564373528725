import Fetcher from './Fetcher'
import { connect } from 'react-redux'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import ContractorView from 'components/ContractorView'

// map to state
const mapStateToProps = (state, props) => {
  return {
    showText: props.match.params.employeeId,
  }
}

export default Fetcher(connect(mapStateToProps)(ContractorView), [
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
