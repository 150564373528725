import React from 'react'
import { isFetching } from 'utils/redux/fetching'
import OverdueActionsDashboardView from '../components/OverdueActionsDashboardView'
import { getAssignmentsForDashboard } from 'routes/Toolbox/routes/Actions/selectors/assignments'
import { connect } from 'react-redux'
import { buildFilters } from 'utils/filters'
import { createFilter } from 'utils/redux/filter'
import { fetchAssignments, resetAssignments } from 'redux/actions/assignments'
import PropTypes from 'prop-types'
class OverdueActionsDashboardContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.onFetchFilters()
  }

  render () {
    return (
      <span>
        <OverdueActionsDashboardView {...this.props} />
      </span>
    )
  }
}

const mapDispatchToProps = (dispatch, { filtersValues, owner, history }) => {
  const filters = buildFilters(filtersValues)

  let defaultFilters = {
    ...filters,
    status: ['in-progress', 'not-started', 'reverted'],
    limit: 4,
    sort: [{ name: 'deadline', order: 'asc' }],
    notInactive: true
  }
  if (owner) {
    defaultFilters['owner'] = owner
  }
  return {
    onNavigateToActions: () => {
      dispatch(resetAssignments())
      history.push(`/toolbox/actions?status=overdue`)
    },
    onFetchFilters: () =>
      dispatch(
        fetchAssignments({
          filter: createFilter(
            defaultFilters,
            'assignments'
          ),
          disableObsoleteFlow: true,
        })
      ),
    dispatch,
  }
}

// map to state
const mapStateToProps = (state, { onItemFetched }) => {
  const { assignments, companies } = state
  const entities = [
    { ref: assignments, filter: { name: 'assignments' } },
    companies,
  ]
  if (isFetching(entities)) return { isFetching: true }
  const items = getAssignmentsForDashboard(state, { filter: 'assignments' })
  onItemFetched('overdueActions', items.length)
  return {
    overdueAssignments: items
  }
}

OverdueActionsDashboardContainer.propTypes = {
  onFetchFilters: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverdueActionsDashboardContainer)
