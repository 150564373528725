import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const ItemBox = ({ link, children, ...rest }) => {
  return <div className='o-layout__item u-margin-bottom-huge u-1/2 u-1/4@tablet u-1/6@desktop'
    data-testid={rest['data-testid']}>
    <Link to={link}>
      <div className='c-panel c-panel--clickable o-block o-block--center u-bg--white u-padding-small
      u-relative border-1-valhalla rounded-md hover--shadow-md'>
        { children }
      </div>
    </Link>
  </div>
}

ItemBox.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ItemBox
