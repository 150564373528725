import React from 'react'
import PropTypes from 'prop-types'
import { handleErrors } from 'utils/form'
import Submit from 'components/buttons/Submit'

const Form = ({
  error,
  handleSubmit,
  children,
  showSubmitBtn = false,
  pristine,
  submitting,
  colorClassBtn,
  btnText,
  btnFullWidth,
  isButtonCentered = true,
  classNames,
  onKeyPress = null,
  ...rest
}) => (
  <form onSubmit={handleSubmit} className='c-form--state' onKeyPress={onKeyPress} data-testid={rest['data-testid']}>
    {error && handleErrors(error)}
    {children}
    {showSubmitBtn && (
      <div className={isButtonCentered ? `u-text--center` : ''}>
        <Submit pristine={pristine} submitting={submitting} colorClass={colorClassBtn} btnFullWidth={btnFullWidth} className={classNames}>
          {btnText}
        </Submit>
      </div>
    )}
  </form>
)

Form.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onKeyPress: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  // We have some use cases, where the Forms don't have any rendered fields.
  // The form data is initially predefined or changeable programmatically remotely.
  // The Form is needed in that case, in order to render the errors, if any.
  children: PropTypes.node,
  btnFullWidth: PropTypes.bool,
  classNames: PropTypes.string,
  showSubmitBtn: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  colorClassBtn: PropTypes.string,
  btnText: PropTypes.string,
  isButtonCentered: PropTypes.bool,
}

export default Form
