import React, { useState } from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { calendarYear, calendarNextYear } from 'utils/calendarYearDates'

const CalendarYearHeader = ({ countryName, companyName, payrollName, onSelectedYear, year, shouldShowNextYear }) => {
  const [selectedYear, setSelectedYear] = useState(year)
  return (
    <div className='calendar-yearly u-margin-top-tiny d-flex' data-testid='yearly-calendar-header'>
      <span className='u-text--xtra-huge u-weight--bold'>Payroll Calendar</span>
      {shouldShowNextYear ? (
        <div style={{ width: '90px' }}>
          <ReactSelect
            className={'c-custom-select year-select'}
            options={[
              { value: calendarYear, label: calendarYear },
              { value: calendarNextYear, label: calendarNextYear },
            ]}
            value={selectedYear}
            onChange={(value) => {
              onSelectedYear(value)
              setSelectedYear(value)
            }}
            clearable={false}
          />
        </div>
      ) : (
        <span className='u-text--xtra-huge u-weight--bold calendar-year-color'>&nbsp;{year}&nbsp;</span>
      )}
      <span className='u-text--xtra-huge'>
        | {countryName}/{companyName}/{payrollName}
      </span>
    </div>
  )
}

CalendarYearHeader.propTypes = {
  companyName: PropTypes.string,
  countryName: PropTypes.string,
  payrollName: PropTypes.string,
}

export default CalendarYearHeader
