import React, { useMemo } from 'react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell, Text } from 'recharts'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import { formatNumberToCurrency } from 'utils/number'

const YAxisLeftTick = ({ x, y, payload: { value } }) => {
  return (
    <g transform={`translate(${x - 25},${y})`}>
      <Text x={0} y={0} textAnchor='end' verticalAnchor='middle' width={100}>
        {value}
      </Text>
      <svg x={0} y={-14}>
        <foreignObject x={5} y={0} width='30' height='30'>
          <Flag country={value} size='tiny' classes='u-margin-right-tiny' />
        </foreignObject>
      </svg>
    </g>
  )
}

const ShortText = ({ value, x, y }) => {
  return (
    <g transform={`translate(${x - 15},${y})`}>
      <Text x={0} y={0} textAnchor='end' verticalAnchor='middle' width={190}>
        {value}
      </Text>
    </g>
  )
}
const YAxisLeftTickNoFlag = ({ x, y, payload: { value } }) => {
  return <ShortText x={x} y={y} value={value} />
}
const YAxisLeftTickNoFlagExport = ({ x, y, payload: { value } }) => {
  return <ShortText x={x} y={y} value={value} />
}

const commonProps = {
  value: PropTypes.any,
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
}

ShortText.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.any,
}

YAxisLeftTick.propTypes = commonProps
YAxisLeftTickNoFlag.propTypes = commonProps
YAxisLeftTickNoFlagExport.propTypes = commonProps

const ValueLabel = ({ x, y, payload, value, d }) => {
  const labelString = d.currency ? `${d.currency} ${formatNumberToCurrency(value, true)}` : value
  return (
    <Text x={x + 5} y={y + 16} style={{ fontWeight: 700 }}>
      {labelString}
    </Text>
  )
}

ValueLabel.propTypes = {
  value: PropTypes.any,
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  d: PropTypes.object,
}

let ctx
export const measureText = (text) => {
  if (!ctx) {
    ctx = document.createElement('canvas').getContext('2d')
    ctx.font = "14px 'Helvetica Neue"
  }
  return ctx.measureText(text).width
}

const BAR_AXIS_SPACE = 10

const SideBarChartWithFlags = ({ data, xKey, yKey, isExporting, queryKey }) => {
  const maxTextWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[yKey]
        const width = measureText(value.toLocaleString())
        if (width > acc) {
          return width
        }
        return acc
      }, 0),
    [yKey, queryKey]
  )
  const maxLabelWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[xKey]
        const width = measureText(value.toLocaleString())
        if (width > acc) {
          return width
        }
        return acc
      }, 0),
    [xKey, queryKey]
  )
  const dataHeight = 40 * data.length
  const hasFlags = queryKey === 'country'
  const dividedWidth = maxLabelWidth / 1.5

  const formatLabels = (data) =>
    data.map((d) => {
      let chars = d[xKey].split('')
      // we're adding a space to break the string if there is no space in the first 20 characters
      if (chars.length >= 20 && !chars.slice(0, 20).includes(' ')) {
        chars.splice(20, 0, ' ')
        d[xKey] = chars.join('')
      }
      return d
    })

  return (
    <ResponsiveContainer width={'100%'} height={dataHeight} className='u-overflow-y-auto' debounce={50}>
      <BarChart
        data={formatLabels(data)}
        layout='vertical'
        margin={{
          left: maxTextWidth + (BAR_AXIS_SPACE - 8) + 60,
          right: hasFlags ? 2 * maxTextWidth + (BAR_AXIS_SPACE - 8) : 2 * maxTextWidth,
        }}
      >
        <XAxis hide axisLine={false} type='number' />
        <YAxis
          yAxisId={0}
          width={hasFlags ? 60 : dividedWidth > 155 ? 156 : dividedWidth < 90 ? 100 : dividedWidth}
          dataKey={xKey} // Yes this looks weird, but that's because it's flipped.  So the XAxis is on the YAxis display
          type='category'
          axisLine={false}
          tickLine={false}
          tick={hasFlags ? YAxisLeftTick : isExporting ? YAxisLeftTickNoFlagExport : YAxisLeftTickNoFlag}
        />
        <Bar dataKey={yKey} minPointSize={2} barSize={21} x={39} label={<ValueLabel />}>
          {data.map((d, idx) => {
            return <Cell key={d[xKey]} fill='#BAE0E0' radius={[0, 15, 15, 0]} d={d} />
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

SideBarChartWithFlags.propTypes = {
  data: PropTypes.array,
  xKey: PropTypes.string,
  yKey: PropTypes.string,
  isExporting: PropTypes.bool,
  queryKey: PropTypes.any,
}

export default SideBarChartWithFlags
