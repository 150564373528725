import RouteView from './components/RouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import InnerWrapper from 'layouts/InnerWrapper'

export default (store) => ({
  path : 'users',
  wrappedComponent: CoreLayout,
  authProtected: true,
  indexRoute: { component: RouteView, wrappedComponent: InnerWrapper }
})
