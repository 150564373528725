import { ActionUtility } from 'utils/redux'
// ------------------------------------
// Constants
// ------------------------------------
export const DASHBOARD_FETCH = 'DASHBOARD_FETCH'
export const DASHBOARD_RECEIVE = 'DASHBOARD_RECEIVE'
export const DASHBOARD_UPDATE = 'DASHBOARD_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DASHBOARD_FETCH,
  receive: DASHBOARD_RECEIVE,
  create: DASHBOARD_UPDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'dashboards', 'dashboard', 'Dashboard')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDashboardItemsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDashboardItems = actionUtility.fetchEntities
export const updateDashboardItems = actionUtility.createEntity
