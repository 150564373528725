const KEY_TO_REMAP = 'extraFields.'
const KEY_TO_REMAP_TO = 'extraFields'
const getReMappedRecordTitle = (details) => { return details.replace(KEY_TO_REMAP, KEY_TO_REMAP_TO) }

const remapDataFields = (d) => {
  let record = d
  Object.keys(record).forEach(recordKey => {
    if (recordKey.indexOf(KEY_TO_REMAP) !== -1) {
      const newKey = getReMappedRecordTitle(recordKey)
      record[newKey] = record[recordKey]
    }
  })
  return record
}

const remapTitleFields = (d) => {
  let record = d
  Object.keys(record).forEach(recordKey => {
    if (recordKey === 'name' && record[recordKey].indexOf(KEY_TO_REMAP) !== -1) {
      const newValue = getReMappedRecordTitle(record[recordKey])
      record[recordKey] = newValue
    }
  })
  return record
}

/**
 * Method to handle the reformatting of report details for extra fields
 * extra fields come back as a extraFields.Some Name
 * React Table treats this as an object, and it fails to display the report values
 *
 * @param {Array} reportsData The results from the BE api for a given report
 * @returns {Array} with the modified name fields for extra fields
 */
export const formatReportExtraFieldsDetails = (reportsData) => {
  reportsData.forEach(details => {
    details.data.map(d => {
      return { ...remapDataFields(d) }
    })

    details.table.headings.map(d => {
      return { ...remapTitleFields(d) }
    })
  })

  return reportsData
}
