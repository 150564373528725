import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { isArray, includes, replace } from 'lodash'
import Fetcher from 'containers/Fetcher'
import FiltersGlobalPayAndTaxesReport from 'routes/Reporting/components/FiltersGlobalPayAndTaxesReport'
import { getCCTsByCompaniesAndCategoriesAndSubcategories, getCCTsSubCategoriesByCompaniesAndCategories } from './selectors/companyCountryTermPivot'
import { sortByName } from 'utils/strings'

const mapStateToProps = (state, props) => {
  const { countries, companies, accessAreaPivot } = state
  if (isFetching([countries, companies, accessAreaPivot])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'type',
    'category',
    'subcategory',
    'termCategory',
    'termSubcategory',
    'term',
    'combinedSubcategory',
    'fromDate',
    'toDate',
    'country',
    'company'
  )

  const selectorFilter = {
    companiesIds: values.company || [],
    categoriesIds:
      values.subcategory === 'Terms' && values.combinedSubcategory
        ? values.combinedSubcategory.filter((value) => includes(value, 'Terms-')).map((category) => parseInt(replace(category, /Terms-/g, '')))
        : [],
    isPayAndTaxesReport: true,
  }

  return {
    // All selected values
    selected: values,
    termsubcategories: getCCTsSubCategoriesByCompaniesAndCategories(state, selectorFilter),
    terms: getCCTsByCompaniesAndCategoriesAndSubcategories(state, { ...selectorFilter, subcategoriesIds: values.termSubcategory || [] }),
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] })
      : getCompaniesByAuth(state, props),
    initialValues: {
      subcategory: null,
      termSubcategory: null,
      termCategory: null,
    },
  }
}

const Container = connect(mapStateToProps)(FiltersGlobalPayAndTaxesReport)

export default Fetcher(
  Container,
  ['termCategories', 'termSubCategories', 'terms', 'countryTermPivot', 'companyCountryTermPivot', 'countries', 'companies', 'accessAreaPivot'],
  { wrapperDivClassName: 'o-block--inline' }
)
