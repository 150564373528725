import { ActionUtility } from 'utils/redux'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import { normalizeSyncAllData, normalizeSyncData, normalizeSyncTermsDataInstance } from 'redux/transformers/manyToManyTransformer'
import { invalidatePayrollInstanceCountryTermsPivot } from 'redux/actions/payrollInstanceCountryTermsPivot'
import { invalidateEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCES_FETCH = 'PAYROLL_INSTANCES_FETCH'
export const PAYROLL_INSTANCES_RECEIVE = 'PAYROLL_INSTANCES_RECEIVE'
export const PAYROLL_INSTANCES_INVALIDATE = 'PAYROLL_INSTANCES_INVALIDATE'
export const PAYROLL_INSTANCES_STATUS_INVALIDATE = 'PAYROLL_INSTANCES_STATUS_INVALIDATE'
export const PAYROLL_INSTANCES_UPDATE = 'PAYROLL_INSTANCES_UPDATE'
export const PAYROLL_INSTANCE_TERMS_SYNC = 'PAYROLL_INSTANCE_TERMS_SYNC'
export const PAYROLL_INSTANCE_EMPLOYEE_ATTACH = 'PAYROLL_INSTANCE_EMPLOYEE_ATTACH'
export const PAYROLL_INSTANCE_EMPLOYEE_DETACH = 'PAYROLL_INSTANCE_EMPLOYEE_DETACH'
export const PAYROLL_INSTANCES_RECEIVE_WITH_ERRORS = 'PAYROLL_INSTANCES_RECEIVE_WITH_ERRORS'
export const PAYROLL_INSTANCES_CLEAR_ERRORS = 'PAYROLL_INSTANCES_CLEAR_ERRORS'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCES_FETCH,
  receive: PAYROLL_INSTANCES_RECEIVE,
  invalidate: PAYROLL_INSTANCES_INVALIDATE,
  invalidateSpecificFilter: PAYROLL_INSTANCES_STATUS_INVALIDATE,
  update: PAYROLL_INSTANCES_UPDATE,
  syncPayrollInstanceTerms: PAYROLL_INSTANCE_TERMS_SYNC,
  attachEmployees: PAYROLL_INSTANCE_EMPLOYEE_ATTACH,
  detachEmployees: PAYROLL_INSTANCE_EMPLOYEE_DETACH,
  receiveWithErrors: PAYROLL_INSTANCES_RECEIVE_WITH_ERRORS,
  clearErrors: PAYROLL_INSTANCES_CLEAR_ERRORS,
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstances', 'payrollinstances', 'PayrollInstance')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstancesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstances = actionUtility.fetchEntities
export const invalidatePayrollInstances = actionUtility.invalidate
export const invalidatePayrollInstancesByStatus = actionUtility.invalidateSpecificFilter
export const clearErrorsPayrollInstances = actionUtility.clearErrors
export const downloadArchive = (id, params) => actionUtility.downloadEntity({ id, childUri: 'exportarchive', params })
export const uploadArchive = (id, file, params) => actionUtility.uploadEntity({ id, file, childUri: 'import', params })
export const downloadTaAndOvertimeArchive = (id, filter) => actionUtility.downloadEntity({ id, childUri: 'exporttaandovertimearchive', filter })
export const downloadPayFile = (id, params) => actionUtility.downloadEntity({ id, childUri: 'export', params })
export const downloadJoinersAndLeaversReport = (payrollInstanceId, isVendor = false) =>
  actionUtility.downloadEntity({
    childUri: `${payrollInstanceId}/joinersandleavers/download`,
    params: {
      forceVendorTenantAPI: isVendor,
    },
  })

export const updatePayrollInstances = (entity) => {
  let data = dateTransformer(entity, ['payDate'])

  return actionUtility.updateEntity(data, data['id'], false, true)
}

const syncTermsAction = (entity, id) => ({
  type: actionTypes.syncPayrollInstanceTerms,
  payload: {
    id,
    data: entity.data,
  },
})

/**
 * Send an api request to sync terms to payroll instance
 */
export const syncTerms = (payrollInstanceId, data, shouldFetch = false, shouldInvalidate = true) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.syncEntities({
        entity: normalizeSyncTermsDataInstance(data),
        id: payrollInstanceId,
        childUri: 'terms',
        actionFunc: syncTermsAction,
        shouldFetch,
        shouldInvalidate,
      })
    ).then(() => dispatch(invalidatePayrollInstanceCountryTermsPivot()))
  }
}

const attachEmployeesAction = (employeeSystemUsersIds, payrollInstanceId) => ({
  type: actionTypes.attachEmployees,
  payload: { payrollInstanceId, ...employeeSystemUsersIds },
})

// attach employees to payroll instance
export const attachEmployees = (entity) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: normalizeSyncAllData(entity, ['prePopulatedData'], { prePopulatedData: `${entity.prePopulatedData}` }, (value) => value),
        id: entity.payrollInstanceId,
        childUri: 'employees',
        actionFunc: attachEmployeesAction,
        shouldFetch: false,
        shouldInvalidate: true,
      })
    ).then(() => {
      dispatch(invalidateEmployeeSystemUsers())
      dispatch(invalidatePayrollInstanceEmployeePivot())
    })
  }
}

const detachEmployeesAction = (employeeSystemUsersIds, payrollInstanceId) => ({
  type: actionTypes.detachEmployees,
  payload: { payrollInstanceId, ...employeeSystemUsersIds },
})

// remove employees from payroll instance
export const detachEmployees = (entity) => {
  const data = normalizeSyncData(entity, null, (value) => value)

  return (dispatch) => {
    return dispatch(
      actionUtility.detachEntities({
        entity: data,
        id: entity.id,
        childUri: 'employees',
        actionFunc: detachEmployeesAction,
        shouldFetch: false,
        shouldInvalidate: true,
      })
    ).then(() => {
      dispatch(invalidatePayrollInstanceEmployeePivot())
    })
  }
}

export const triggerGlReportCreation = (payrollInstanceId) => {
  return (dispatch, getState, { api }) => {
    return api.post('payrollinstances/' + payrollInstanceId + '/gl-report-message')
  }
}

export const fetchJoinersAndLeaversCount = (payrollInstanceId, isVendor = false) => {
  return (dispatch, getState, { api }) => {
    return api.fetch(`payrollinstances/${payrollInstanceId}/joinersandleavers/count`, { forceVendorTenantAPI: isVendor }).then((res) => res)
  }
}

const exportBaseUrl = (id, filterUri) => `payrollinstances/${id}/joinersandleavers/export?${filterUri ?? ''}`

export const exportJoinersAndLeaversReport = (payrollInstanceId, isVendor = false, filterUri = '') => {
  return (dispatch, getState, { api }) => {
    return api.fetch(exportBaseUrl(payrollInstanceId, filterUri), { forceVendorTenantAPI: isVendor })
  }
}

export const fetchPayrollInstanceOverview = (payrollInstanceId) => {
  return (dispatch, getState, { api }) => {
    return api.fetch('payrollinstances/' + payrollInstanceId + '/overview').then((res) => res)
  }
}

export const fetchPayrollInstanceTotals = (payrollInstanceId, { disbursable }) => {
  return (dispatch, getState, { api }) => {
    const filters = disbursable ? `?disbursable=${disbursable}` : ''
    return api.fetch(`payrollinstances/${payrollInstanceId}/summarytotals${filters}`).then((res) => res)
  }
}
