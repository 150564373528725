import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/departments'

class Departments extends CRUDModel {}
Departments.modelName = 'Departments'
Departments.actionTypes = actionTypes

Departments.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'departments'),
}

export default Departments
