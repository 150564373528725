import React from 'react'
import PropTypes from 'prop-types'
import BaseField from './BaseField'
import IntlTelInput from 'react-intl-tel-input'

class PhoneCodeNumberField extends React.Component {
  render () {
    const { input: { value, onChange, onBlur, onFocus }, className, disabled, children } = this.props

    const handler = (status, value, countryData, number, id) => {
      // Remove spaces
      const sanitizedNumber = number.replace(/\s+/g, '')

      // Call all `redux-form` callbacks,
      // in order to support most of the `redux-form` features
      onChange(sanitizedNumber)
      onBlur(sanitizedNumber)
      onFocus && onFocus(sanitizedNumber)
    }

    return <BaseField {...this.props}>
      <IntlTelInput
        containerClassName={'intl-tel-input u-margin-bottom-tiny'}
        inputClassName={`c-input ${className}`}
        disabled={disabled}
        onPhoneNumberChange={handler}
        onPhoneNumberBlur={handler}
        defaultValue={value}
        defaultCountry={'ie'}
        format
      />
      { children }
    </BaseField>
  }
}

PhoneCodeNumberField.propTypes = {
  input: PropTypes.object,
  children: PropTypes.object,
  disabled: PropTypes.bool,
}

export default PhoneCodeNumberField
