export const buildFilterName = (props) => ({
  timeAndAttendance: `timeattendance&payrollinstance=${props?.payrollInstanceId}`,
  rolesAndRights: `rolesRights&companyId=${props?.companyId}`,
  companyDocumentShare: `companyDocumentShare&companyId=${props?.companyId}&documentId=${props?.documentId}`,
  companyPayrollDocumentShare: `companyPayrollDocumentShare&companyId=${props?.companyId}&documentId=${props?.documentId}`,
  payrollInstanceDocumentShare: `payrollInstanceDocumentShare&payrollInstanceId=${props?.payrollInstanceId}&documentId=${props?.documentId}`,
  unassignEmployeesPayrollInstance: `unassignEmployees&payrollInstanceId=${props?.payrollInstanceId}`,
  assignEmployeesPayrollInstance: `assignEmployees&payrollInstanceId=${props?.payrollInstanceId}`,
  assignEmployeesPayroll: `assignEmployees&payrollId=${props?.payrollId}`,
  unassignEmployeesPayroll: `unassignEmployees&payrollId=${props?.payrollId}`,
  assignEmployeesPaygroup: `assignEmployees&paygroupId=${props?.paygroupId}`,
  unassignEmployeesPaygroup: `unassignEmployees&paygroupId=${props?.paygroupId}`,
})

export const filterConfigurations = {
  assignEmployeesPayrollInstance: (params) => ({ attachableToPayrun: params.payrollInstanceId }),
  assignEmployeesPayroll: (params) => ({ attachableToPayroll: params.payrollId }),
  unassignEmployeesPayroll: (params) => ({ payroll: params.payrollId }),
  assignEmployeesPaygroup: (params) => ({ attachableToPayGroup: params.paygroupId }),
  unassignEmployeesPaygroup: (params) => ({ paygroup: params.paygroupId }),
}

export const getFilterFromConfig = (filterKey, params) => {
  const getConfig = filterConfigurations[filterKey]
  return getConfig ? getConfig(params) : {}
}
