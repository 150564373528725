import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { getCompaniesRef } from 'redux/selectors/company'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import { isMultitenancyEnabled } from 'utils/config'
import PropTypes from 'prop-types'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'

const Aside = (props) => {
  const { history, asideMode, selectAside, fetchCompaniesIfNeeded } = props

  useEffect(() => {
    fetchCompaniesIfNeeded()
  }, [])

  return (
    <aside className='o-aside'>
      <Logo />
      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav
        asideMode={asideMode}
        items={[
          { title: 'Home', name: 'home', path: '/home', isHome: true },
          {
            title: 'Company',
            name: 'company',
            path: '/companies',
          },
          { title: 'Employees', name: 'people', path: '/employees' },
          { title: 'Payroll', name: 'payroll', path: '/payruns/active' },
          {
            title: 'Toolbox',
            name: 'toolbox',
            path: '/toolbox',
            isLink: false,
            children: [
              { title: 'Calendar', name: 'calendar', path: '/toolbox/calendar', sub: true },
              { title: 'Actions', name: 'actions', path: '/toolbox/actions', sub: true },
              { title: 'Alerts', name: 'alerts', path: '/toolbox/alerts', sub: true },
            ],
          },
          {
            title: 'Admin',
            name: 'people',
            isLink: false,
            children: [
              { title: 'Countries', name: 'countries', path: '/countries', sub: true },
              { title: 'Global Terms', name: 'global-terms', path: '/terms', sub: true },
              { title: 'Clients', name: 'clients', path: '/clients', sub: true },
              {
                title: 'Global Services',
                name: 'global-services',
                path: '/services',
                sub: true,
                children: [
                  { title: 'Processes', name: 'processes', path: '/processes', sub: true },
                  { title: 'Steps', name: 'steps', path: '/steps', sub: true },
                  { title: 'Actions', name: 'actions', path: '/actions', sub: true },
                ],
              },
            ],
          },
          { title: 'Vendors', name: 'people', path: '/vendors' },
          { title: 'COT Users', name: 'payroll', path: '/users' },
        ]}
        {...props}
      />
    </aside>
  )
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => ({
  fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
})

// map to state
const mapStateToProps = (state, props) => {
  if (isFetching([state.companies])) return { isFetching: true }

  return {
    companies: getCompaniesRef(state, props),
  }
}

Aside.propTypes = {
  isFetching: PropTypes.bool,
  history: PropTypes.object,
  fetchCompaniesIfNeeded: PropTypes.func,
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
