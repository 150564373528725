import { ActionUtility } from 'utils/redux'
// ------------------------------------
// Constants
// ------------------------------------
export const GENERAL_LEDGERS_FETCH = 'GENERAL_LEDGERS_FETCH'
export const GENERAL_LEDGERS_CREATE = 'GENERAL_LEDGERS_CREATE'
export const GENERAL_LEDGERS_RECEIVE = 'GENERAL_LEDGERS_RECEIVE'
export const GENERAL_LEDGERS_UPDATE = 'GENERAL_LEDGERS_UPDATE'
export const GENERAL_LEDGERS_INVALIDATE = 'GENERAL_LEDGERS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: GENERAL_LEDGERS_FETCH,
  create: GENERAL_LEDGERS_CREATE,
  receive: GENERAL_LEDGERS_RECEIVE,
  update: GENERAL_LEDGERS_UPDATE,
  invalidate: GENERAL_LEDGERS_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'generalLedgers', 'generalledger', 'GeneralLedgers')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchGeneralLedgersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchGeneralLedgers = actionUtility.fetchEntities
export const createGeneralLedgers = actionUtility.createEntityWithoutForm
export const updateGeneralLedgers = actionUtility.updateEntity
export const invalidateGeneralLedgers = actionUtility.invalidate

export const downloadTemplate = () => actionUtility.downloadEntity({ childUri: 'bulkimportcreate' })

export const download = fullUrl => actionUtility.downloadEntity({ fullUrl })

export const downloadTemplateUpdate = () => actionUtility.downloadEntity({ childUri: 'bulkimportupdate' })

export const importAccountsUpdate = file => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: 'bulkimportupdate', shouldHandleErrors: false }))
    .then((response) => {
      dispatch(invalidateGeneralLedgers())
      return response
    })
)

export const importAccountsCreate = file => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: 'bulkimportcreate', shouldHandleErrors: false }))
    .then((response) => {
      dispatch(invalidateGeneralLedgers())
      return response
    })
)
export const editGeneralLedgers = (payload) => {
  return (dispatch, getState, { api }) => {
    return api.put(`generalledger`, { payload })
  }
}
