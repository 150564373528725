import RouteView from './components/RouteView.js'
import CompanyPayrunsRoute from './routes/CompanyPayruns'
import CompanyPayrollTimelineRoute from './routes/CompanyPayrollTimeline'
import NotesRoute from './routes/CompanyNotes'

export default (store) => ({
  path : ':companyId',
  indexRoute: { component: RouteView },
  childRoutes: [CompanyPayrunsRoute(store), CompanyPayrollTimelineRoute(store), NotesRoute(store)]
})
