import React from 'react'
import PropTypes from 'prop-types'

const PillBox = ({
  classList = '',
  onClick = null,
  parentCss = '',
  text,
  dataTestId = ''
}) => {
  const defaultCss = `c-pillbox rounded-md u-padding-small u-1/1`
  return (
    <div className={`c-pillbox-container u-padding-bottom-small ${parentCss}`}>
      <div className={`${defaultCss} ${classList}`} onClick={onClick} data-testid={`pillbox-${dataTestId}`}>
        {text}
      </div>
    </div>
  )
}

PillBox.propTypes = {
  classList: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  parentCss: PropTypes.string,
  dataTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PillBox
