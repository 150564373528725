import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import InputField from 'components/form/InputField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FlagOption from 'components/form/select/FlagOption'
import SubmitButtons from './SubmitButtons'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import { isEmpty, isNil, isArray } from 'lodash'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'

class FiltersPayrollReport extends React.Component {
  render () {
    const {
      setCurrency,
      resetFormFields,
      termsubcategories,
      terms,
      selected,
      countries,
      companies,
      currencies,
      fxRates,
      readonlyFields,
      isFieldDisabled,
      disabledReason,
      hasElementsSelections = false,
      handleModalOpen,
      forceShowTerms,
      customRatesFieldsNames,
      mostRecentRunReport = {},
    } = this.props
    /*
     * Every next filter is shown if the last required field has value
     */
    const shouldShowMostRecentRunReportItem = !this.props.reportItem

    return (
      <div className='o-block--inline'>
        {
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='showOnlyTotals'
                label='Show Only Totals *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--left-padding'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled || readonlyFields.showOnlyTotals}
                disabledReason={disabledReason}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                enableRemovedOptionFix
                data-testid='show-only-totals-wrapper'
              />
            </div>
          </div>
        }
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='fromDate'
              />
            </div>
          </div>
        )}
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                data-testid='toDate'
              />
            </div>
          </div>
        )}
        {!isNil(selected.showOnlyTotals) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={selected.subcategory !== 'TotalEmployerCosts' || selected.showOnlyTotals ? CheckboxOption : FlagOption}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi={selected.subcategory !== 'TotalEmployerCosts' || selected.showOnlyTotals}
                options={countries
                  .map((country) => ({
                    value: country.id,
                    label: country.name,
                    abbreviature: country.abbreviature,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {!isNil(selected.country) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                multi
                optionComponent={CheckboxOption}
                {...(!isFieldDisabled
                  ? {
                    onChanged: (value) => {
                      setCurrency()
                      resetFormFields(null, ['reportingRate', 'rate', 'toCurrency', 'fxRate', ...customRatesFieldsNames])
                    },
                  }
                  : {})}
                {...this.props}
              />
            </div>
          </div>
        )}
        {hasElementsSelections && !isEmpty(selected.payroll) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='termSubcategory'
                label='Elements Subcategory'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={termsubcategories.map((termcat) => ({
                  value: termcat.id,
                  label: termcat.name,
                }))}
                enableRemovedOptionFix
                data-testid='termSubcategory-wrapper'
              />
            </div>
          </div>
        )}
        {hasElementsSelections && !isEmpty(selected.payroll) && (selected.termSubcategory || forceShowTerms) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='term'
                label='Elements'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={terms.map((termcat) => ({
                  value: termcat.id,
                  label: termcat.name,
                }))}
                enableRemovedOptionFix
                data-testid='term-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payroll) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='payrollCurrency'
                label={`Payroll ${selected.payroll.length > 1 ? 'Currencies' : 'Currency'} *`}
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi
                disabled
                disabledReason={isFieldDisabled ? disabledReason : 'Payroll currency'}
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='payrollCurrency-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.payroll) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='reportingCurrency'
                label='Reporting Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi
                disabled
                disabledReason={isFieldDisabled ? disabledReason : 'Please choose "Change reporting currency"'}
                options={currencies.map((currency) => ({
                  value: currency.id,
                  label: currency.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='reportingCurrency-wrapper'
              />
            </div>
          </div>
        )}
        {selected.payrollCurrency && selected.reportingCurrency && JSON.stringify(selected.payrollCurrency) !== JSON.stringify(selected.reportingCurrency) ? (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              {isArray(selected.reportingRate) ? (
                <Field
                  name='reportingRate'
                  label='Rate'
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  multi
                  disabled
                  disabledReason={
                    isFieldDisabled ? disabledReason : 'If you want to change the rate please click the button "Change reporting currency" and choose an option'
                  }
                  options={fxRates.map((rate) => ({
                    value: rate.id,
                    label: rate.filterName,
                  }))}
                  data-testid='reportingRate'
                />
              ) : (
                <Field
                  name='reportingRate'
                  label='Rate'
                  labelClassName='c-label u-text--small u-text--curious'
                  className='c-input c-input--transparent c-input--overflow-ellipsis'
                  component={InputField}
                  type='text'
                  formGroupClassName='u-margin-none'
                  disabled
                  disabledReason={selected.reportingRate}
                  data-testid='reportingRate'
                />
              )}
            </div>
          </div>
        ) : null}
        {selected.payroll && selected.payroll.length && !isFieldDisabled && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-padding-top u-padding-bottom o-grid o-grid--medium'>
              <div
                onClick={() => handleModalOpen()}
                className='c-btn c-btn--small rounded shadow-md c-btn--curious u-padding-left u-padding-right'
                data-testid='changeCurrency-button'
              >
                {selected.reportingCurrency && selected.reportingCurrency.length ? 'Change Reporting Currency' : 'Choose Reporting Currency'}
              </div>
            </div>
          </div>
        )}
        {!this.props.hideNavigationButtons && (
          <SubmitButtons
            pristineCheck={!isEmpty(selected.payroll) && !isEmpty(selected.payrollCurrency)}
            onBackClick={this.props.navigateToParentOnClick}
            {...this.props}
          />
        )}
        {shouldShowMostRecentRunReportItem && <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem
            mostRecentRunReport={mostRecentRunReport}
            onOpenReport={this.props.handleReportModalOpen}
            onDelete={this.props.deleteReport}
            {...this.props}
          />
        </div>}
      </div>
    )
  }
}

FiltersPayrollReport.propTypes = {
  setCurrency: PropTypes.func,
  categories: PropTypes.array,
  termsubcategories: PropTypes.array,
  combinedSubcategories: PropTypes.array,
  onCombinedSubcategoryChange: PropTypes.func,
  terms: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  currencies: PropTypes.array,
  fxRates: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  forceShowTerms: PropTypes.bool,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
}

export default FiltersPayrollReport
