import React from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { ConfirmButton, RejectButton } from 'components/buttons/'

export const RemoveService = props => {
  if (props.isFetching) return <div>Loading...</div>

  return <div>
    <p>Are you sure you want to remove service <span className='u-weight--medium'>{props.serviceName}</span>?</p>
    <Form {...props}>
      <ConfirmButton type='submit' />
      <RejectButton onClick={props.onReject} />
    </Form>
  </div>
}

RemoveService.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  // What to happen on rejected (confirmation) deletion
  onReject: PropTypes.func.isRequired
}

export default RemoveService
