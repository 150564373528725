import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceEmployeePivot'

class PayrollInstanceEmployee extends CRUDModel {}
PayrollInstanceEmployee.modelName = 'PayrollInstanceEmployee'

PayrollInstanceEmployee.fields = {
  id: attr(),
  payrollInstance: fk('PayrollInstance', 'payrollInstanceEmployees'),
  paygroup: fk('Paygroup', 'payrollInstanceEmployees'),
  employee: fk('EmployeeSystemUser', 'payrollInstanceEmployees'),
  currency: fk('Currency', 'payrollInstanceEmployees'),
  annualSalary: fk('AnnualSalary', 'payrollInstanceEmployees'),
  employeeAttendances: fk('EmployeeAttendance', 'payrollInstanceEmployees'),
  employeeOvertimes: fk('EmployeeOvertime', 'payrollInstanceEmployees'),
}
PayrollInstanceEmployee.actionTypes = actionTypes

/**
 * Sort PayrollInstanceEmployee collection by employees' names
 *
 * @param {PayrollInstanceEmployee} a
 * @param {PayrollInstanceEmployee} b
 * @returns {number}
 */
export const sortByEmployeeName = (a, b) => {
  // Sort the employees by their surname firstly and after that by their firstname.
  const nameA = `${a.employee.surname} ${a.employee.firstname}`.toLowerCase()
  const nameB = `${b.employee.surname} ${b.employee.firstname}`.toLowerCase()

  if (nameA < nameB) return -1
  if (nameA > nameB) return 1

  // Names must be equal
  return 0
}

export default PayrollInstanceEmployee
