import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const TASKS_FETCH = 'TASKS_FETCH'
export const TASKS_RECEIVE = 'TASKS_RECEIVE'
export const TASKS_INVALIDATE = 'TASKS_INVALIDATE'
export const TASK_CREATE = 'TASK_CREATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TASKS_FETCH,
  receive: TASKS_RECEIVE,
  invalidate: TASKS_INVALIDATE,
  create: TASK_CREATE
}
export const actionUtility = new ActionUtility(actionTypes, 'tasks', 'tasks', 'Task')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTasksIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchTasks = actionUtility.fetchEntities
export const invalidateTasks = actionUtility.invalidate
export const createTask = actionUtility.createEntity
