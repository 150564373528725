import React from 'react'
import PropTypes from 'prop-types'
const classNames = require('classnames')

const AccordionSubtitle = ({ subtitle, subtitleKey = '', headerColor, mobileView = false }) => {
  const getSubtitleClasses = () => {
    return classNames({
      'u-margin-bottom-small' : true,
      'u-text--white' : headerColor === 'minsk',
      'u-margin-top-neg-tiny' : mobileView
    })
  }
  return (
    <div className={getSubtitleClasses()}>
      {subtitleKey && <span className='u-weight--bold u-padding-right'>{subtitleKey}</span>}
      <span>{subtitle}</span>
    </div>
  )
}

AccordionSubtitle.propTypes = {
  headerColor: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleKey: PropTypes.string,
  mobileView: PropTypes.bool
}

export default AccordionSubtitle
