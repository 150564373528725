import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_OVERTIME_FETCH = 'EMPLOYEE_OVERTIME_FETCH'
export const EMPLOYEE_OVERTIME_RECEIVE = 'EMPLOYEE_OVERTIME_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_OVERTIME_FETCH,
  receive: EMPLOYEE_OVERTIME_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'employeeOvertime', 'employeeovertimes', 'EmployeeOvertime')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeOvertimeIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeOvertime = actionUtility.fetchEntities
