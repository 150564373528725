import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { Route, Redirect } from 'react-router-dom'
import { getPermissions } from 'redux/actions/auth'

function handleAuthentication (props) {
  // eslint-disable-next-line no-unused-vars
  const { isAuthenticated, permissions, getPermissions, history, isFetchingPermissions } = props
  if (!isAuthenticated) {
    const baseRoute = window?.location?.pathname || ''
    const searchRoute = window?.location?.search || ''
    history.push({
      pathname: '/login',
      state: { entryRoute: `${baseRoute}${searchRoute}` },
    })
  }
  // Temporary remove isFetchingPermissions, until we can fix the RHSSO issue
  // The field isFetchingPermissions was to handle when logging out occasionally the permissions call was made
  if (isAuthenticated && !permissions) {
    return getPermissions()
  }
}

function AuthorizedRoute ({ children, ...rest }) {
  const { isAuthenticated, permissions } = rest
  useEffect(() => {
    // Update the document title using the browser API
    handleAuthentication(rest)
  })
  // Don't load child component, unless we are authenticated and have the permissions fetched.
  // About the permissions - some UI components may not be shown initially (because they rely on permissions),
  // in the case the permissions are in process of fetching.
  const isNotAuthed = !isAuthenticated || !permissions
  return (
    <React.Fragment>
      {isNotAuthed ? null : (
        <Route
          {...rest}
          render={({ location }) => {
            return isAuthenticated || permissions ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
          }}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.accessToken,
  permissions: state.auth.permissions,
  isFetchingPermissions: state.auth.isFetchingPermissions,
})

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRoute)
