import CoreLayout from 'layouts/PageLayout/PageLayout'
import EmployeesCreateRoute from './routes/EmployeesCreate'
import EmployeesUpdateRoute from './routes/EmployeesUpdate'
import BankAccountsRoute from './routes/BankAccounts'
import TermsRoute from './routes/Terms'
import InnerWrapper from 'layouts/InnerWrapper'
import FXRates from './routes/FXRates'
import GLAccounts from './routes/GLAccounts'
import GLAccountsUpdate from './routes/GLAccountsUpdate'
import PayAndTaxesCreateRoute from './routes/PayTaxesCreate'
import PayAndTaxesUpdateRoute from './routes/PayTaxesUpdate'

export default (store) => ({
  path : '/import',
  exact: true,
  indexRoute: { component: InnerWrapper },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [
    EmployeesCreateRoute(store),
    EmployeesUpdateRoute(store),
    BankAccountsRoute(store),
    TermsRoute(store),
    FXRates(store),
    GLAccounts(store),
    GLAccountsUpdate(store),
    PayAndTaxesCreateRoute(store),
    PayAndTaxesUpdateRoute(store)
  ]
})
