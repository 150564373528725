import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hasAccess } from 'redux/selectors/auth'

/**
 * Mechanisms for protecting UI parts, according to user's permissions.
 *
 * Please refer to project WIKI documentation for further details:
 * @link - https://gitlab.com/dev-labs-bg/payslip-frontend/wikis/Authorization
 */

// Default error, when the user doesn't have enough rights
export const noAccessError = <span>You don't have enough access rights!</span>
const noMsg = null

/**
 * HOC component, that allows `WrappedComponent` rendering,
 * only if the user has the needed permissions
 *
 * @param {array} requiredPermissions - required user's permissions,
 * that are needed to render the component
 *
 * @example - Usage:
 *
 * ```
 *   import { AuthorizationHOC } from 'containers/Authorization'
 *
 *   // It will render `CompaniesListContainer`,
 *   // only if currently logged in user has `COMPANIES_READ` permission
 *   Authorization(['COMPANIES_READ'])(CompaniesListContainer)
 * ```
 *
 * @link - Credits to https://medium.com/@ricardo_42589/this-is-awesome-470fe9bb6f56
 *
 * @returns {function}
 */
export const AuthorizationHOC = requiredPermissions => {
  return WrappedComponent => {
    class Auth extends React.Component {
      render () {
        if (!this.props.hasAccess) return noAccessError

        return <WrappedComponent {...this.props} />
      }
    }

    const mapStateToProps = state => ({
      hasAccess: hasAccess(state)(requiredPermissions)
    })

    return connect(mapStateToProps)(Auth)
  }
}

/**
 * Container component, that allows child props rendering,
 * only if the user has the needed permissions
 *
 * @example - Usage:
 *
 * ```
 *   import { Authorization } from 'containers/Authorization'
 *
 *   // It will render child props `<div>Protected area</div>`
 *   // only if currently logged in user has `COMPANIES_READ` permission
 *   <Authorization permissions={['COMPANIES_READ']} />
 *    <div>Protected area</div>
 *   </Authorization>
 * ```
 */
class Auth extends React.Component {
  render () {
    if (!this.props.hasAccess && !this.props.showMsg) return noMsg

    if (!this.props.hasAccess) return noAccessError

    return this.props.children
  }
}

Auth.Proptypes = {
  // Required user's permissions,
  // that are needed to render the protected child props
  permissions: PropTypes.array.isRequired,
  showMsg: PropTypes.bool.isRequired
}

const mapStateToProps = (state, props) => ({
  hasAccess: hasAccess(state)(props.permissions)
})

export const Authorization = connect(mapStateToProps)(Auth)
