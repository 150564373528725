import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_SERVICE_FETCH = 'COUNTRY_SERVICE_FETCH'
export const COUNTRY_SERVICE_RECEIVE = 'COUNTRY_SERVICE_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_SERVICE_FETCH,
  receive: COUNTRY_SERVICE_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'countryServicePivot', 'countryservices', 'CountryService')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryServicePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryServicePivot = actionUtility.fetchEntities
