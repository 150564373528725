import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/externalVendorUsers'

class ExternalVendorUser extends CRUDModel {}
ExternalVendorUser.modelName = 'ExternalVendorUser'

ExternalVendorUser.fields = {
  id: attr()
}
ExternalVendorUser.actionTypes = actionTypes

export default ExternalVendorUser
