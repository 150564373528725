import Fetcher from '../Fetcher'
import { connect } from 'react-redux'
import { getCountryRef } from 'redux/selectors/country'
import CountryHeader from 'components/header/CountryHeader'

const mapStateToProps = (state, { countryId }) => ({
  ...getCountryRef(state, { countryId })
})

const Container = connect(mapStateToProps)(CountryHeader)

export default Fetcher(Container, ['countries'])
