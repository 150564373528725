import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div style={{
        backgroundColor: '#291547',
        color: 'white',
        padding: '5px',
        borderRadius: '5px'
      }}>
        <div className='u-padding-left-small u-padding-right-small u-padding-bottom-small u-text--normal u-weight--bold'>{label}</div>
        <div className='u-padding-left-small u-padding-right-small'>
          {payload.map(p =>
            <div key={p.dataKey} className='d-flex u-margin-bottom-tiny'>
              <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: p.stroke, marginTop: '6px' }} />
              <span className='u-margin-left-tiny u-text--small'>{p.name} {p.value}</span>
            </div>)
          }
        </div>
      </div>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
  label: PropTypes.string,
}

/**
 * Colours should be an object of colors, with the keys that will draw the line
 * ie.  if you have line data of [cat, dog, mouse]
 * than the colours associated should be {cat: 'blue', dog: 'purple': mouse: 'teal'}
*/
const MonthByMonthLineChart = ({ data, xKey = 'name', lineAxis = [], colours = {}, xAxisTickIndicator = 0, isExporting = false, includeYear = false }) => {
  const heightDivision = data.length > 15 ? 20 : 30
  const calculatedHeight = heightDivision * data.length
  const legendMargin = includeYear ? '-5px' : '-20px'
  return (
    <ResponsiveContainer
      width={'100%'}
      height={isExporting ? 650 : calculatedHeight >= 400 ? calculatedHeight > 600 ? 650 : calculatedHeight : 650}
      maxHeight={650}
      className='u-overflow-y-auto'
      debounce={50}
    >
      <LineChart width={500} height={300} data={data} margin={{ top: 10, right: 50, left: 20, bottom: 30 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          stroke='#291547'
          tick={(props) => {
            // eslint-disable-next-line react/prop-types
            const { x, y, payload: { value, index } } = props
            const year = includeYear && data[index]?.year ? data[index]?.year : null
            return (<g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} >
                <tspan textAnchor='middle' x='0'>
                  {value}
                </tspan>
                {includeYear && <tspan textAnchor='middle' x='0' dy='20'>
                  { year }
                </tspan>
                }
              </text>
            </g>
            )
          }}
          dataKey={xKey} interval={xAxisTickIndicator} />
        <YAxis stroke='#291547' allowDecimals={false} />
        <Tooltip content={CustomTooltip} />
        <Legend
          iconType='circle'
          wrapperStyle={{ position: 'relative', marginTop: legendMargin }}
          width={'80%'}
          verticalAlign='bottom'
          formatter={(value, entry, index) => <span className='u-text--valhalla u-padding-right-small'>{value}</span>}
          iconSize={12}
          align='left'
        />
        {lineAxis.map(axis => {
          return (
            <Line dataKey={axis} stroke={colours[axis]} activeDot={{ r: 8 }} dot={{ r: 4 }} strokeWidth={2} />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}

MonthByMonthLineChart.propTypes = {
  colours: PropTypes.object,
  xKey: PropTypes.string,
  lineAxis: PropTypes.array,
  data: PropTypes.array,
  xAxisTickIndicator: PropTypes.number,
  isExporting: PropTypes.bool,
  includeYear: PropTypes.bool
}

export default MonthByMonthLineChart
