import createSelector from 'utils/createSelector'

// get paygroup id
const getPaygroupId = (state, props) => parseInt(props.paygroupId)

// get paygroup by id
// and add employee ids to it
export const getPaygroupByIdWithEmployeeIds = createSelector(getPaygroupId, ({ Paygroup }, id) => {
  // get paygroup
  let paygroup = Paygroup.withId(id)

  return {
    ...paygroup.ref,
    company: paygroup.company.id,
    // employeeIds: paygroup
    //   .paygroupEmployees
    //   .toModelArray()
    //   .map(pivot => pivot.employee.id)
  }
})

export const getPaygroupRef = createSelector(getPaygroupId, ({ Paygroup }, id) => ({ ...Paygroup.withId(id).ref }))
