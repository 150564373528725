import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import PropTypes from 'prop-types'
import { downloadReport, fetchReports } from 'redux/actions/reports'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import { fetchTermSubCategoriesIfNeeded } from 'redux/actions/termSubCategories'
import { fetchTermsIfNeeded } from 'routes/Terms/modules/actions'
import { createFilter } from 'utils/redux/filter'
import { getRecentReportsForDashboard } from 'routes/Reporting/selectors/reports'
import { types } from 'redux/config/reports'
import { fetchTermCategoriesIfNeeded } from 'redux/actions/termCategories'
import RecentReportsDashboardView from '../components/RecentReportsDashboardView'

class RecentReportDashboardContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch, employeeFilter } = this.props

    dispatch(fetchReports({
      filter: createFilter(
        {
          offset: 0,
          limit: 3,
        },
        'reports'
      ),
      disableObsoleteFlow: true,
    }))
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchPayrollsIfNeeded())
    dispatch(fetchBusinessUnitsIfNeeded())
    dispatch(fetchCostCentersIfNeeded())
    dispatch(fetchDepartmentsIfNeeded())
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
    dispatch(fetchTermCategoriesIfNeeded())
    dispatch(fetchTermSubCategoriesIfNeeded())
    dispatch(fetchTermsIfNeeded())
  }

  render () {
    return <RecentReportsDashboardView items={this.props.reports} {...this.props} />
  }
}

const mapStateToProps = (state, { onItemFetched }) => {
  const { reports, currencies, countries, companies, payrolls, businessUnits, costCenters, departments,
    employeeSystemUsers, termCategories, termSubCategories, terms } = state

  const employeeFilter = createFilter({ isKeyPerson: 1 })
  const entities = [ { ref: reports, filter: { name: 'reports' } }, currencies, countries, companies, payrolls, businessUnits, costCenters, departments,
    { ref: employeeSystemUsers, filter: employeeFilter }, termCategories, termSubCategories, terms]

  if (isFetching(entities)) return { isFetching: true, employeeFilter }
  const reportItems = getRecentReportsForDashboard(state, { filter: 'reports' })
  onItemFetched('recentReports', reportItems.length)
  return {
    isFetching: false,
    reports: reportItems,
    types,
    employeeFilter
  }
}

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    dispatch,
    onNavigatingToReports: () => history.push('/reporting/recent-reports'),
    downloadReport: id => dispatch(downloadReport(id)),
  }
}

RecentReportDashboardContainer.propTypes = {
  reports: PropTypes.array,
  employeeFilter: PropTypes.object,
  dispatch: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentReportDashboardContainer)
