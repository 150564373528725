import React from 'react'
import CompanyFormFields from './CompanyFormFields'
import Form from 'components/form/Form'
import PropTypes from 'prop-types'

export const CompanyForm = props => {
  if (props.isFetching) return <div>Loading...</div>

  return <Form {...props}>
    <CompanyFormFields {...props} />
  </Form>
}

CompanyForm.propTypes = {
  isFetching: PropTypes.bool
}

export default CompanyForm
