import { ActionUtility } from 'utils/redux'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { SubmissionError } from 'redux-form'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_HOLIDAY_FETCH = 'COMPANY_HOLIDAY_FETCH'
export const COMPANY_HOLIDAY_RECEIVE = 'COMPANY_HOLIDAY_RECEIVE'
export const COMPANY_HOLIDAY_CREATE = 'COMPANY_HOLIDAY_CREATE'
export const COMPANY_HOLIDAY_UPDATE = 'COMPANY_HOLIDAY_UPDATE'
export const COMPANY_HOLIDAY_INVALIDATE = 'COMPANY_HOLIDAY_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_HOLIDAY_FETCH,
  receive: COMPANY_HOLIDAY_RECEIVE,
  create: COMPANY_HOLIDAY_CREATE,
  update: COMPANY_HOLIDAY_UPDATE,
  invalidate: COMPANY_HOLIDAY_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'companyHolidayPivot', 'companyholidays', 'CompanyHoliday')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyHolidaysPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyHolidaysPivot = actionUtility.fetchEntities
export const invalidateCompanyHolidaysPivot = actionUtility.invalidate

export const createCompanyHolidayPivot = (entity) => {
  // set valid date
  const data = dateTransformer(entity, ['date'])

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data))
      .then(errorsHandling.handleFormErrors)
      .catch(showCountryErrorIfAny)
  }
}
export const updateCompanyHolidayPivot = (entity) => {
  // set valid date
  const data = entity.date ? dateTransformer(entity, ['date']) : entity

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.updateEntity(data, data.id))
      .then(errorsHandling.handleFormErrors)
      .catch(showCountryErrorIfAny)
  }
}

/**
 * When creating or updating holidays for a company, if there is already a holiday added for
 * this date and company, an error for existing company holiday for this date is thrown.
 * Since the company ID field is hidden in the form, the errors won't show unless we add them
 * to _error common errors array
 */
const showCountryErrorIfAny = (error) => {
  throw new SubmissionError({
    ...error.errors
  })
}
