import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import { getPayrollRef } from 'redux/selectors/payroll'
import SectionHeading from 'components/SectionHeading'

class PayrollHeadingContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchPayrollsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <SectionHeading {...this.props} />
  }
}

const mapDispatchToProps = {
  fetchPayrollsIfNeeded
}

// map to state
const mapStateToProps = (state, props) => {
  const entities = [ state.payrolls ]

  if (isFetching(entities)) return { isFetching: true }

  // get selected payroll
  let payroll = getPayrollRef(state,
    { payrollId: props.match.params.payrollId }
  )

  return {
    text: `${props.heading} ${payroll.name}`
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollHeadingContainer)
