import createSelector from 'utils/createSelector'
import { _getCompaniesByCountry, getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds } from 'redux/selectors/filters'
import _ from 'lodash'

const getProjectId = (state, props) => parseInt(props.projectId)
export const getCompanyId = (state, props) => parseInt(props.companyId)
const getCountryId = (state, props) => parseInt(props.countryId)

export const getProjectById = createSelector(getProjectId,
  ({ Project }, projectId) => {
    const project = Project.withId(projectId)
    return {
      ...project.ref,
      company: project.company.ref,
    }
  }
)

export const getProjects = createSelector(
  ({ Project }) => {
    return Project.all()
      .toRefArray()
      .map(project => ({
        ...project
      }))
  }
)

export const getProjectsWithCompanyWithCountry = createSelector(
  ({ Project }) => {
    return Project.all()
      .toModelArray()
      .map(project => ({
        ...project.ref,
        company: { ...project.company.ref },
        country: { ...project.company.country.ref }
      }))
  }
)

export const getProjectsByCompany = createSelector(getCompanyId,
  ({ Project }, companyId) => {
    return Project
      .filter(project => project.company === companyId)
      .toRefArray()
      .map(project => ({
        ...project
      }))
  }
)

export const getProjectsByCountryThroughCompany = createSelector(getCountryId,
  ({ Country }, countryId) => {
    const companies = _getCompaniesByCountry(Country, countryId)
    const projects = companies.map(company => {
      return company.projects.toModelArray().map(project => ({
        ...project.ref
      }))
    })

    return _.flatten(projects)
  }
)

export const getProjectsByCountriesThroughCompany = createSelector(getCompaniesByCountries,
  ({ Company }, companies) => {
    const projects = companies.map(companyRef => {
      const company = Company.withId(companyRef.id)
      return company.projects.toModelArray().map(project => ({ ...project.ref })).reverse()
    })

    return _.flatten(projects)
  }
)

export const getProjectsByCompanies = createSelector(getCompaniesIds,
  ({ Project }, companiesIds) => {
    return Project.filter(project => companiesIds.includes(project.company))
      .toRefArray()
      .map(project => ({ ...project }))
  }
)
