import React from 'react'
import PropTypes from 'prop-types'
import AccordionView from 'components/AccordionView'

/**
 * Accordion component
 *
 * options:
 * isExpanded - {bool} sets the default state of the accordion
 * onExpandedCallback {func} optional function to pass in, if you need to know
 * the items expanded state
 * separator - {bool} adds a horizontal line between title and content
 * title - {string}
 * subtitle - {string}
 * actionButtons - {array} array of buttons to render in the unexpanded part of the accordion
 * icon - {jsx} icon to render in the unexpanded part
 * headerColor - {string} ability to change the background color to 'minsk' and text to white
 * dataTestId - test id for the arrow button
 *
 */

class Accordion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isExpanded: this.props.isExpanded || false
    }
  }

  toggleExpanded = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
    if (this.props.onExpandedCallback) this.props.onExpandedCallback()
  }

  render () {
    return <AccordionView toggleExpanded={this.toggleExpanded} expanded={this.state.isExpanded} {...this.props} />
  }
}

Accordion.propTypes = {
  isExpanded: PropTypes.bool,
  onExpandedCallback: PropTypes.func
}

export default Accordion
