import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_STEP_FETCH = 'PAYROLL_STEP_FETCH'
export const PAYROLL_STEP_CREATE = 'PAYROLL_STEP_CREATE'
export const PAYROLL_STEP_UPDATE = 'PAYROLL_STEP_UPDATE'
export const PAYROLL_STEP_RECEIVE = 'PAYROLL_STEP_RECEIVE'
export const PAYROLL_STEP_INVALIDATE = 'PAYROLL_STEP_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_STEP_FETCH,
  create: PAYROLL_STEP_CREATE,
  update: PAYROLL_STEP_UPDATE,
  receive: PAYROLL_STEP_RECEIVE,
  invalidate: PAYROLL_STEP_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollStep', 'payrollsteps', 'PayrollStep')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollStepIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollStep = actionUtility.fetchEntities
export const createPayrollStep = actionUtility.createEntity
export const updatePayrollStep = actionUtility.updateEntity
export const invalidatePayrollStep = actionUtility.invalidate
