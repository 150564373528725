import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { getParentCompaniesTenants } from 'redux/selectors/tenants'
import ClientsList from '../components/ClientsList'
import { selectTenant } from 'redux/actions/tenants'
import { getReferrals } from 'redux/selectors/referrals'
import { fetchReferralsIfNeeded } from 'redux/actions/referrals'
class ClientsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchParentCompaniesIfNeeded()
    this.props.fetchReferralsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <ClientsList {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
    fetchReferralsIfNeeded: () => dispatch(fetchReferralsIfNeeded()),
    selectTenantSubmit: (url, type, id) => {
      // Before resetting everything, go to `/home` route,
      // in order to prevent unrelated api calls.
      // Imagine that you select a company tenant and you're on `/companies` route.
      // After that you change to a vendor tenant.
      // In that case fetch companies requests will be triggered, to the vendor tenant api url,
      // that isn't correct.
      // So before switching the tenant / api url, we go to the `home` neutral route.
      ownProps.history.push(`/home`)

      dispatch(selectTenant({ url, type, id }))
    }
  }
}

const mapStateToProps = (state, props) => {
  const { parentCompanies, referrals } = state
  const entities = [ parentCompanies, referrals ]

  if (isFetching(entities)) return { isFetching: true }
  return {
    clients: getParentCompaniesTenants(state),
    referrals: getReferrals(state, props)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer)
