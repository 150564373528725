import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'

class VendorsList extends React.Component {
  constructor (props) {
    super(props)

    this.formatNameColumn = this.formatNameColumn.bind(this)
  }

  renderData (vendors) {
    return vendors.map(vendor => {
      return {
        id: vendor.id,
        name: vendor.name,
        url: vendor.url,
        shortUrl: vendor.shortUrl
      }
    })
  }

  formatNameColumn ({ row: { values: { id, name, url } } }) {
    const { selectTenantSubmit } = this.props
    return <div title={name} onClick={() => selectTenantSubmit(url, 'vendor', id)}>{name}</div>
  }

  render () {
    const { vendors } = this.props

    const options = {
      searchPosition: 'left',
      noDataText: 'No matching records found.'
    }

    return <div>
      <SectionHeading text='Vendors list' />
      <PayslipTable
        data={this.renderData(vendors)}
        headings={[
          { accessor: 'id', Header: 'ID', isKey: true, isVisible: false },
          { accessor: 'name',
            Header: 'Name',
            disableFilters: true,
            columnClassName: 'u-text--curious u-cursor--pointer',
            Cell: this.formatNameColumn },
          { accessor: 'url', Header: 'Url', isVisible: false, disableFilters: true },
          { accessor: 'shortUrl', Header: () => (<span>Url</span>), disableFilters: true }
        ]}
        wrapperClassName='u-1/1@tablet u-1/2@desktop'
        trClassName='react-bs-table__cell--overflow-ellipsis'
        options={options}
        search
      />
    </div>
  }
}

VendorsList.propTypes = {
  vendors: PropTypes.array,
  selectTenantSubmit: PropTypes.func
}

export default VendorsList
