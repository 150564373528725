import React from 'react'
import Button from './Button'

export default ({ buttonText = 'Yes', disabled = false, ...rest }) => (
  <Button
    disabled={disabled}
    className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right ${disabled ? 'disabled' : ''}`}
    data-testid='confirm-button'
    {...rest} >
    {buttonText}
  </Button>
)
