import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import Fetcher from 'containers/Fetcher'
import PayrollInstanceTaskList from 'routes/Payruns/components/PayrollInstanceTask/PayrollInstanceTaskList'

import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { invalidatePayrollInstanceTask, mergePayrollInstanceTasks } from 'redux/actions/payrollInstanceTask'
import { invalidatePayrollInstanceStep } from 'redux/actions/payrollInstanceStep'
import { invalidatePayrollInstanceProcess } from 'redux/actions/payrollInstanceProcess'
import { invalidatePayrollInstanceService } from 'redux/actions/payrollInstanceService'

import { removeDisabledTasks, findCompletedReversionTask } from 'redux/models/payrollInstanceTask'

import {
  getDisabledPayrollInstanceTasksByFilter,
  getPayrollInstanceTasksByFilter,
  getPayrollInstanceTasksByFilterInitials,
} from '../selectors/payrollInstanceTask'

import { diff } from 'utils/object'
import { createFilter } from 'utils/redux/filter'

import { owners } from 'redux/config/tasks'

const mapStateToProps = (state, { filters }) => {
  const filter = { filter: filters.payrollInstanceTask.name }

  return {
    showSubmitBtn: true,
    tasks: getPayrollInstanceTasksByFilter(state, filter),
    initialValues: {
      ...getPayrollInstanceTasksByFilterInitials(state, filter)
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: tasks => dispatch((dispatch, getState) => {
    const { filters, shouldOpenNotesView } = ownProps
    const state = getState()
    const filter = { filter: filters.payrollInstanceTask.name }
    const disabledTasks = getDisabledPayrollInstanceTasksByFilter(state, filter)
    const initialTasks = getPayrollInstanceTasksByFilterInitials(state, filter)

    const normalized = diff(initialTasks, removeDisabledTasks(tasks, disabledTasks))
    const taskIdOfCompletedReversionTask = findCompletedReversionTask(normalized, initialTasks)

    if (taskIdOfCompletedReversionTask) {
      shouldOpenNotesView &&
        shouldOpenNotesView(
          true,
          normalized[taskIdOfCompletedReversionTask].id
        )
    }

    return dispatch(mergePayrollInstanceTasks(normalized, { forceVendorTenantAPI: true, shouldRefetch: false }))
      .then(() => {
        dispatch(invalidatePayrollInstances())
        dispatch(invalidatePayrollInstanceStep())
        dispatch(invalidatePayrollInstanceTask())
        dispatch(invalidatePayrollInstanceProcess())
        dispatch(invalidatePayrollInstanceService())
      })
  })
})
const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollInstanceTaskEdit',
})(PayrollInstanceTaskList))

export default Fetcher(Container,
  [
    {
      name: 'payrollInstanceTask',
      params: [{
        _computed: {
          filter: (state, { payrollInstanceId }) => createFilter({
            payrollInstance: payrollInstanceId,
            globalOwner:  [owners.vendor]
          })
        },
        forceVendorTenantAPI: true
      }]
    }
  ])
