import createSelector from 'utils/createSelector'
import _ from 'lodash'

const getFromCurrencies = (state, props) => _.flatten(props.fromCurrencies)
const getToCurrency = (state, props) => props.toCurrency
const getFxRateIds = (state, props) => props.fxRateIds

// Get all fx rates
export const getFxRates = createSelector(({ FxRate }) => {
  const fxRates = FxRate.all().toModelArray().map(fxRate => getFxRateProps(fxRate))
  return _.orderBy(fxRates, ['date["date"]'], ['desc'])
})

// Get fx rates by from currencies
export const getFxRatesByFromCurrenciesAndToCurrency = createSelector(getFromCurrencies, getToCurrency,
  ({ FxRate }, fromCurrencies, toCurrency) => {
    const fxRates = fromCurrencies && fromCurrencies.length ? FxRate.all().toModelArray()
      .filter(rate => fromCurrencies.includes(rate.fromCurrency.ref.id) && rate.toCurrency.ref.id === toCurrency)
      .map(fxRate => getFxRateProps(fxRate)) : []

    return _.orderBy(fxRates, ['date["date"]'], ['desc'])
  })

// Get fx rate by id
export const getFxRatesByIds = createSelector(getFxRateIds,
  ({ FxRate }, fxRateIds) => {
    return fxRateIds.map(id => FxRate.withId(id).ref)
  })

export const getFxRateProps = fxRate => ({
  ...fxRate.ref,
  fromCurrency: fxRate.fromCurrency.ref,
  toCurrency: fxRate.toCurrency.ref
})
