import { createFilter } from 'utils/redux/filter'
import { typeClassesWithSftpSharing, types } from '../config/documents'
import { isEmpty, isNil, isNull } from 'utils/fnkit/typeChecks'
import { capitalize } from 'utils/fnkit/string'

export const subTypesFilterClasses = Object.freeze({
  company: 'company',
  payroll: 'payroll',
})

export const documentFilterTypes = Object.freeze({
  payrollSummaryDocs: 'payrollSummaryDocs',
  dynamicDocumentFilter: 'dynamicDocumentFilter',
})
export const createDocumentFilterNameById = (id) => `${documentFilterTypes.payrollSummaryDocs}${id}`

export const createDynamicDocumentFilterName = (predicate = []) => `${documentFilterTypes.dynamicDocumentFilter}_${predicate.join('_')}`

export const prepareFilters = ({ filterNames, props }) => {
  let filters = {}

  filterNames.forEach((name) => {
    const propsValue = props[name]
    if (name === 'typeClass') {
      filters[name] = types[propsValue].filterName
      // Add showSftpInfo if typeClass is one of the specified values
      if (typeClassesWithSftpSharing.includes(propsValue)) {
        filters['showSftpInfo'] = true
      }
    } else if (name === 'documentId') {
      filters['id'] = propsValue
    } else if (name === 'documentTypes') {
      const docTypeIds = propsValue.map((filter) => props.allDocTypes?.find((e) => e.text === filter)).map((doc) => doc?.id)
      // In order to fetch documents without any document types set, we need to send 0 to the BE
      // if we don't have a specific filter set for the 'Type' column, we append 0 to the list,
      // if we have selected 'Other' on the filter, meaning we have value in the props.documentType, we use that value
      // We have to use isNil because the value could be 0
      if (propsValue.includes(0) && isNil(props.documentType)) {
        filters['documentTypes'] = [...docTypeIds, 0]
      } else if (propsValue.includes(0) && props.documentType) {
        filters['documentTypes'] = [props.documentType]
      } else {
        // In order to filter by 'Type' column, i.e. document type id, we need to modify the documentTypes payload so it only includes the filter selected
        // instead of sending the list of types pertaining to the tabs
        // We are checking if the documentType exists as a filter and if it has values
        if (filterNames.includes('documentType') && props.documentType) {
          filters['documentTypes'] = [props.documentType]
        } else {
          filters['documentTypes'] = [...docTypeIds]
        }
      }
    } else if (name === 'showSftpInfo') {
      filters['showSftpInfo'] = true
    } else {
      filters[name] = propsValue
    }
  })

  // Taking out the unnecessary filters from the payload
  delete filters['documentType']
  return filters
}

export const prepareSubTypeFilterClasses = ({ isVendorDocuments, filters }) => {
  if (isVendorDocuments) {
    const hasCategory = !isEmpty(filters?.category)
    return hasCategory ? [capitalize(filters.category)] : [capitalize(subTypesFilterClasses.company), capitalize(subTypesFilterClasses.payroll)]
  }
}

export const createDocsPaginationFilter = (filterName, { isVendorDocuments, filters, docFilterNames, sort, offset = 0, limit = 10 }) => {
  const computedFilters = prepareFilters({
    filterNames: docFilterNames || [],
    props: filters,
  })

  return createFilter(
    {
      ...computedFilters,
      ...(isVendorDocuments ? [] : { showSftpInfo: true }),
      includeSubTypeClasses: prepareSubTypeFilterClasses({ isVendorDocuments, filters }),
      offset,
      limit: limit,
      sort,
    },
    filterName
  )
}

export const createComputedDocsFilter = ({ docFilterNames }) => ({
  name: 'document',
  params: [
    {
      _computed: {
        filter: (_, props) => {
          let filterName = createDynamicDocumentFilterName([props?.typeId, props?.tab?.name])
          if (isEmpty(props?.allDocTypes)) {
            filterName = undefined
          }
          return createDocsPaginationFilter(filterName, { docFilterNames, filters: props })
        },
      },
      disableObsoleteFlow: true,
    },
  ],
})

export const createDocumentLogsFilter = ({ documentId, documentTenant, historyListDocumentId = null }) => {
  return createFilter(
    {
      documentId: !isNull(historyListDocumentId) ? historyListDocumentId : documentId,
      documentTenant,
      sort: [{ name: 'id', order: 'desc' }],
    },
    `documentLogs`
  )
}

/**
 * This filter creates document filter for entity in the Fetcher.
 *
 * In our document containers there are multiple times where we use 'createFilter' for document filtering.
 * Instead of multiple duplicate code declaration we decided to reuse our createDocumentFilter here.
 * For similar implementation check here:
 * \routes\Employees\routes\Employee\filters\createEmployeeFilter.js
 *
 * @param {String} filterName
 * filterName is array of the filter names by which we want to filter the document entity
 *
 * Example:
 * ```
 * 1. Filtering documents by typeClass and typeId
 * createDocumentFilter({ filterNames: ['typeClass', 'typeId'] }),
 *
 * filter created:
 * {
 *  'document',
 *  params: [{
 *    _computed: {
 *      filter: (state, props) => createFilter({ typeClass, typeId })
 *    },
 *    disableObsoleteFlow: true
 *  }]
 * }
 * ```
 */

export default ({ filterNames }) => ({
  name: 'document',
  params: [
    {
      _computed: {
        filter: (state, props) => createFilter(prepareFilters({ filterNames, state, props })),
      },
      disableObsoleteFlow: true,
    },
  ],
})
