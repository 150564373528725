import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMMENTARY_CREATE = 'COMMENTARY_CREATE'
export const COMMENTARY_FETCH = 'COMMENTARY_FETCH'
export const COMMENTARY_RECEIVE = 'COMMENTARY_RECEIVE'
export const COMMENTARY_DELETE = 'COMMENTARY_DELETE'
export const COMMENTARY_INVALIDATE = 'COMMENTARY_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  create: COMMENTARY_CREATE,
  fetch: COMMENTARY_FETCH,
  receive: COMMENTARY_RECEIVE,
  delete: COMMENTARY_DELETE,
  invalidate: COMMENTARY_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'commentary', 'payrollsummarycommentaries', 'Commentary')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCommentaryIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCommentary = actionUtility.fetchEntities
export const createCommentary = actionUtility.createEntity
export const deleteCommentary = actionUtility.deleteEntity
export const invalidateCommentary = actionUtility.invalidate

export const editCommentary = (payload) => {
  return (dispatch, getState, { api }) => {
    return api.put(`payrollsummarycommentaries`, { payload })
  }
}
