import createSelector from 'utils/createSelector'
import { isCot } from 'redux/selectors/auth'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { formatDateToUtc } from 'utils/date'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'

const getFilteredIds = makeFilteredIdsByFilter('notifications')

const isUserCot = (state) => isCot(state)

export const getFilteredNotifications = createSelector(getFilteredIds, ({ Notification }, ids) => ids.map((id) => Notification.withId(id).ref))

export const getFilteredNotificationsDecorated = createSelector(getFilteredIds, isUserCot, ({ Notification }, ids) => {
  return ids
    .map((id) => {
      const notification = Notification.withId(id)?.ref
      // Notification might not exist in the store during fetching or data invalidation
      if (!notification) return null

      return {
        ...notification,
        createdAt: formatDateToUtc(notification.created_at.date, dateFormatEnums.DefaultFnsDateWithTime),
        ...notification.extraData,
      }
    })
    .filter(Boolean)
})
