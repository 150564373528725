import { ActionUtility } from 'utils/redux'
import Promises from 'es6-promise'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_EMPLOYEE_FETCH = 'PAYROLL_INSTANCE_EMPLOYEE_FETCH'
export const PAYROLL_INSTANCE_EMPLOYEE_RECEIVE = 'PAYROLL_INSTANCE_EMPLOYEE_RECEIVE'
export const PAYROLL_INSTANCE_EMPLOYEE_UPDATE = 'PAYROLL_INSTANCE_EMPLOYEE_UPDATE'
export const PAYROLL_INSTANCE_EMPLOYEE_INVALIDATE = 'PAYROLL_INSTANCE_EMPLOYEE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_EMPLOYEE_FETCH,
  receive: PAYROLL_INSTANCE_EMPLOYEE_RECEIVE,
  update: PAYROLL_INSTANCE_EMPLOYEE_UPDATE,
  invalidate: PAYROLL_INSTANCE_EMPLOYEE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceEmployeePivot', 'payrollinstanceemployees', 'PayrollInstanceEmployee')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceEmployeePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceEmployeePivot = actionUtility.fetchEntities
export const updatePayrollInstanceEmployeePivot = actionUtility.updateEntity
export const invalidatePayrollInstanceEmployeePivot = actionUtility.invalidate
export const downloadPayslip = id => actionUtility.downloadEntity({ id, childUri: 'downloadpayslip' })
export const uploadPayslip = (id, file) => actionUtility.uploadEntity({ id, file, childUri: 'uploadpayslip' })

/**
 * Bulk update payroll instance employee
 *
 * TODO - That's the first time we do bulk update on a single entity.
 * Currently - we don't have error handling here and we have to create some type of abstraction
 *
 * There is planned refactoring, that will review / rethink the ways we communicate with the API,
 * here I mean CRUD + all other types of calls as sync, merge and so on.
 *
 * So we can revise this case later.
 */
export const bulkUpdatePayrollInstanceEmployee = (data, shouldNormalize = true) => {
  const entities = shouldNormalize ? [] : data

  if (shouldNormalize) {
    const prefix = 'payrollInstanceEmployeeId-'

    _.forOwn(data, function (value, key) {
      if (_.includes(key, prefix)) {
        entities.push({
          id: parseInt(_.replace(key, new RegExp(prefix, 'g'), '')),
          notes: value
        })
      }
    })
  }

  return dispatch => {
    const promises = entities.map(entity => {
      return dispatch(updatePayrollInstanceEmployeePivot(entity, entity.id, false))
    })

    return Promises.all(promises)
  }
}
