export const rowsToBeBolded = ['grossPay', 'netPay', 'netIncome', 'totalEmployerCost', 'totalTaxableIncome', 'headcount']

export const rowsToSkipCurrencyFormatting = ['headcount']

export const elementSortingOrder = [
  'Headcount',
  'Pay elements',
  'Time and Attendance',
  'Overtime',
  'Gross Pay',
  'Total Taxable Income',
  'Employee Deductions',
  'Net Income',
  'Employee Net Deductions',
  'Net Pay',
  'Employer Contributions',
  'Total Employer Cost',
]
