import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  PieChart,
  Pie,
  Label,
  Legend,
  Cell,
  ResponsiveContainer,
  Sector,
} from 'recharts'
import { isNull } from 'lodash'
const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        animationEasing='ease-in-out'
        fill={fill}
      />
    </g>
  )
}

const BasicPieChart = ({
  data,
  colors,
  centerHeader = null,
  centerSubTitle = null,
  isExporting = false
}) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const onMouseOver = useCallback((data, index) => {
    setActiveIndex(index)
  }, [])
  const onMouseLeave = useCallback((data, index) => {
    setActiveIndex(null)
  }, [])

  const renderColorfulLegendText = (value, entry) => {
    return (
      <span className='u-text--valhalla' style={{ fontWeight: 500, padding: '10px' }}>
        {value} {isExporting && <span> - <span className='u-weight--bold'>{entry.payload?.value}</span></span>}
      </span>
    )
  }

  let areZeroResultsReturned = 0
  data.forEach(i => {
    areZeroResultsReturned = i.value > 0 ? i : areZeroResultsReturned
  })
  const activeHoverClass = !isNull(activeIndex)
    ? `active-hover-field-${data[activeIndex].cssName || data[activeIndex].name.toLowerCase()}`
    : isExporting ? 'is-exporting' : ''

  return (
    <div className={`basic-pie-chart position-relative ${activeHoverClass}`}>
      <ResponsiveContainer className='container' height={350} width='100%'>
        <PieChart onMouseEnter={() => null} >
          <Legend
            layout='vertical'
            verticalAlign='bottom'
            align='left'
            formatter={renderColorfulLegendText}
            iconType='circle'
            iconSize={15}
          />
          <Pie
            data={data}
            activeIndex={activeIndex}
            cx={'40%'}
            cy={170}
            innerRadius={'65%'}
            outerRadius={'83%'}
            paddingAngle={0}
            blendStroke
            labelLine
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              name,
              cssName
            }) => {
              if (isNull(activeIndex)) return null
              const RADIAN = Math.PI / 180
              const radius = 25 + innerRadius + (outerRadius - innerRadius)
              const x = cx + radius * Math.cos(-midAngle * RADIAN)
              const y = cy + radius * Math.sin(-midAngle * RADIAN)
              return (
                <text
                  x={x}
                  y={y}
                  className={`label-value-display-${cssName || name.toLowerCase()}`}
                  fill='#291547'
                  fontSize={'14px'}
                  textAnchor={Math.ceil(x) > Math.ceil(cx) ? 'start' : Math.ceil(x) === Math.ceil(cx) ? 'middle' : 'end'}
                  dominantBaseline='central'
                >
                  {value}
                </text>
              )
            }}
            fill='#8884d8'
            dataKey='value'
            animationEasing='ease-in-out'
            activeShape={renderActiveShape}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            {centerHeader && (
              <Label
                value={centerHeader}
                color={'#291547'}
                position='center'
                fontSize='50px'
                className='recharts-label recharts-label-header'
              />
            )}
            {centerSubTitle && (
              <Label
                value={centerSubTitle}
                color={'#291547'}
                position='center'
                fontSize='15px'
                className='recharts-label recharts-label-subheader'
              />
            )}

            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[entry.name]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {areZeroResultsReturned === 0 && (
        <div className='zero-results-returned position-absolute'>
          No Records were found
        </div>
      )}
    </div>
  )
}

BasicPieChart.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.object,
  centerHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centerSubTitle: PropTypes.string,
  isExporting: PropTypes.bool,
}

export default BasicPieChart
