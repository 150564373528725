import { createReducerMeta } from 'utils/redux'
import { actionTypes } from '../actions/payfiles'
import _ from 'lodash'

export const createPayrunReducer = actionTypes => (
  createReducerMeta(actionTypes, { initialValues: {}, isImporting: null }, {
    [actionTypes.initials]: (state, { payload: { data, filters } }) => ({
      ...state,
      initialValues: {
        ...state.initialValues,
        [filters.id]: state.initialValues[filters.id]
          ? _.uniqBy([...state.initialValues[filters.id], ..._.flatten(data.data.map(entity => entity.data))], 'id')
          : _.flatten(data.data.map(entity => entity.data))
      } }),
    [actionTypes.resetInitials]: (state, action) => ({ ...state, initialValues: {} }),
    [actionTypes.import]: (state, action) => ({ ...state, isImporting: true }),
    [actionTypes.imported]: (state, action) => ({ ...state, isImporting: false }),

  })
)

export default createPayrunReducer(actionTypes)
