/**
 * Please read the following module documentation,
 * in order to understand what's the purpose of this action file
 *
 * @link https://github.com/rt2zz/redux-persist
 */

import { removeItemFromLocalStorage } from 'utils/storage'

// ------------------------------------
// Constants
// ------------------------------------
export const RESET = 'RESET'

// ------------------------------------
// Actions
// ------------------------------------
export const resetAction = (persistedState = {}) => {
  let reset = (dispatch, getStore) => {
    removeItemFromLocalStorage('entryRoute')
    dispatch({
      type: RESET,
      state: {
        _persist: getStore()._persist,
        ...persistedState,
      },
    })
  }

  reset.disableJWT = true

  return reset
}
