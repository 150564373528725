import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getCodeByAccessToken } from 'redux/actions/auth'
import ChangePhoneNumberStepOneView from '../components/ChangePhoneNumberStepOneView'
import { showMessage } from 'redux/actions/modal'
import strings from 'strings'
import { isCotDomain } from 'utils/url'

class ChangePhoneNumberStepOneContainer extends React.Component {
  render () {
    return <ChangePhoneNumberStepOneView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state) => {
  let hostUrlCot = isCotDomain(state.config.apiUrl)
  let senderService = hostUrlCot ? state.config.senderServiceCot : state.config.senderService
  return {
    senderService: senderService,
    initialValues: {
      mobilePhone: null
    }
  }
}

const getModalMessage = (senderService) => {
  return strings.authentication.twoFAWithAuth[senderService] ?? ''
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { senderService } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (data) => dispatch(getCodeByAccessToken(data))
      .then(() => {
        dispatch(showMessage({
          body: getModalMessage(senderService),
        }))
      })
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'updatePhoneNumber'
})(ChangePhoneNumberStepOneContainer))
