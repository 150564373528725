import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import moment from 'moment'

/**
 * Factory (wrapper) of `react-moment` library,
 * in order to add support for local time conversion.
 *
 * We just extend the library functionality, without changing its API.
 * So using this Factory, you have access to all the `react-moment` features.
 *
 * @param {String} date - Datetime string, please refer to `react-moment` docs.
 * @param {String} children - Datetime string, please refer to `react-moment` docs.
 * @param {bool} toLocal - Should convert the passed in `date` or `children` datetime to local time.
 * @return {Moment}
 */
const MomentFactory = ({ date, children, toLocal, ...rest }) => {
  let datetime = date || children

  // If `toLocal` is present, then we convert the `datetime` to local firstly
  if (toLocal) {
    const utc = moment.utc(datetime).toDate()
    datetime = moment(utc).local()
  }

  return <Moment date={datetime} {...rest} />
}

MomentFactory.propTypes = {
  date: PropTypes.string,
  children: PropTypes.string,
  toLocal: PropTypes.bool,
}

export default MomentFactory
