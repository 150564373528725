import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/notes'

class Note extends CRUDModel {}
Note.modelName = 'Note'
Note.actionTypes = actionTypes

Note.fields = {
  id: attr(),
  payrollInstance: fk('PayrollInstance', 'notes')
}

export default Note
