export const reusableModalHeadings = {
  confirmation: 'Confirmation',
  history: 'History',
}

export const reusableModalTexts = {
  leaveConfimartion: 'Are you sure you want to exit before saving the file? All changes made will be lost.',
  deleteConfirmation: 'Are you sure you want to delete this row?',
  areYouSureWantToDelete: 'Are you sure you want to delete ',
}
