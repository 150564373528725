import React from 'react'
import { variableDescription } from 'redux/config/companyCountryTerms'
import PayslipTable from 'components/table/PayslipTable'

class VariablePayElements extends React.Component {
  /**
   * Print variable pay elements
   * @param [] variableTerms
   * @return [
   *   [ row ]
   * ]
   */
  renderRows (variableTerms) {
    let rows = []

    variableTerms.map((term, index) => {
      let row = {
        title:
          (term.shouldOnlyUseGlobalName &&
            term.globalName === term.companyCountryTermLocalName) ||
          (term.companyCountryTermLocalName === null || term.companyCountryTermLocalName === '')
            ? term.globalName
            : `${term.globalName} / ${
              term.companyCountryTermLocalName || term.localName
            }`,
      }
      rows.push(row)
    })

    return rows
  }

  render () {
    return (
      <PayslipTable
        proportionsClassName='u-1/1'
        tableClassName='o-table--tiny o-table--striped'
        headingColSpan='2'
        headings={[
          {
            Header: 'Variable Pay Elements',
            classNames: 'u-text--center',
            title: variableDescription,
            disableSortBy: true,
            accessor: 'title',
            columns: [
              {
                Header: 'Title',
                accessor: 'title',
                disableSortBy: true,
                classNames: 'd-none',
              },
              {
                Header: 'Value',
                accessor: 'value',
                disableSortBy: true,
                classNames: 'd-none',
              },
            ],
          },
        ]}
        data={this.renderRows(this.props.variableTerms)}
      />
    )
  }
}

export default VariablePayElements
