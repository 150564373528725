import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/paygroupEmployeePivot'

class PaygroupEmployee extends CRUDModel {}
PaygroupEmployee.modelName = 'PaygroupEmployee'

PaygroupEmployee.fields = {
  id: attr(),
  paygroup: fk('Paygroup', 'paygroupEmployees'),
  employee: fk('EmployeeSystemUser', 'paygroupEmployees')
}
PaygroupEmployee.actionTypes = actionTypes

export default PaygroupEmployee
