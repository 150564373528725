import createSelector from 'utils/createSelector'
import { flatten } from 'utils/array'
import { getVendorCountryTaskFunc } from '../../routes/Vendors/routes/Vendor/routes/Costing/selectors/vendorCountryTask'
import { tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import { statuses } from 'redux/config/payrollInstanceTasks'
import _ from 'lodash'

/**
 * Get vendor id
 * @param state
 * @param props
 */
const getVendorId = (state, props) => parseInt(props.vendorId)
const getVendorStatus = (state, props) => props.vendorStatus

/**
 * Get vendor service tree
 */
export const getVendorServiceTree = createSelector(getVendorId,
  ({ Vendor, VendorCountryTask }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const tree = {}

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      const countryService = vendorCountryService.countryService

      let countryId = countryService.ref.country
      let service = {}

      if (!tree.hasOwnProperty(countryId)) {
        tree[countryId] = {
          countryName: countryService.country.name,
          countryId: countryService.country.id,
          services: [],
        }
      }

      service.name = countryService.service.name
      service.processes = countryService.countryProcesses.toModelArray().map(countryProcess => {
        return {
          name: countryProcess.process.name,
          keyEvent: countryProcess.process.keyEvent,
          steps: countryProcess.countrySteps.toModelArray().map(countryStep => {
            return {
              name: countryStep.step.name,
              tasks: countryStep.countryTasks.toModelArray().map(countryTask => {
                const vendorTask = getVendorCountryTaskFunc(VendorCountryTask, countryTask.id, vendorCountryService.id)

                return {
                  name: countryTask.name,
                  owner: countryTask.task.owner,
                  finishTaskLink: countryTask.finishTaskLink,
                  deadline: vendorTask ? vendorTask.deadlineMonthly : null,
                  countryTaskId: countryTask.id,
                  vendorCountryServiceId: vendorCountryService.id,
                  vendorCountryTaskId: vendorTask ? vendorTask.id : null
                }
              })
            }
          })
        }
      })
      tree[countryId].services.push(service)
    })

    return tree
  }
)

/**
 * Get vendor services
 */
export const getVendorServices = createSelector(getVendorId,
  ({ Vendor, VendorCountryTask }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const tree = {}

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      const countryService = vendorCountryService.countryService

      let countryId = countryService.ref.country
      let service = {}

      if (!tree.hasOwnProperty(countryId)) {
        tree[countryId] = {
          countryName: countryService.country.name,
          countryId: countryService.country.id,
          services: [],
        }
      }

      service.id = countryService.id
      service.name = countryService.service.name
      tree[countryId].services.push(service)
    })

    return tree
  }
)

/**
 * Get vendor countries
 */
export const getVendorCountries = createSelector(getVendorId,
  ({ Vendor, VendorCountryTask }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const vendorCountries = []

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      const countryService = vendorCountryService.countryService
      vendorCountries.push(countryService.ref.country)
    })

    return _.uniq(vendorCountries)
  }
)

/**
 * Get vendor instances
 */
export const getVendorPayrollInstances = createSelector(getVendorId,
  ({ Vendor, PayrollInstance }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const tree = {}

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      vendorCountryService.companyCountryServices.toModelArray().map(companyCountryService => {
        let companyId = companyCountryService.ref.company
        let company = companyCountryService.company

        if (!tree.hasOwnProperty(companyId)) {
          tree[companyId] = {
            companyName: company.name,
            companyId: company.id,
            payrollInstances: [],
          }
        }

        let payrollInstances = company.payrolls.toModelArray().map(payroll => {
          return getPayrollInstances(payroll)
        })

        tree[companyId].payrollInstances = flatten(payrollInstances)
      })
    })

    return tree
  }
)

/**
 * Get vendor companies
 */
export const getVendorCompanies = createSelector(getVendorId,
  ({ Vendor }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const tree = []

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      vendorCountryService.companyCountryServices.toModelArray().map(companyCountryService => {
        let company = companyCountryService.company
        tree.push({
          ...company.ref,
          country: company.country.ref
        })
      })
    })
    /**
     * Filter companies to be unique since if there is
     * more than one vendor company country service, the
     * company will be added multiple times to tree array
     *
     * This checks if there are companies that are equal
     * and returns only unique companies
     */
    return _.uniqWith(tree, _.isEqual)
  })

export const getVendorSpecialStatusCompanies = createSelector(getVendorId, getVendorStatus, ({ VendorSwitch }, vendorId, vendorStatus) => {
  return VendorSwitch
    .all()
    .toModelArray()
    .filter(vs => {
      if (vendorStatus === 'onboarding') {
        return vs.onboardingVendor === vendorId && (new Date(vs.effectiveDate.date) > new Date() || !vs.effectiveDateMigration)
      } else if (vendorStatus === 'offboarding') {
        return vs.offboardingVendor === vendorId && new Date(vs.effectiveDate.date) <= new Date() && vs.effectiveDateMigration && !vs.endDateMigration
      }
    })
    .map(vs => ({ ...vs.companyService.company.ref, country: vs.companyService.company.country.ref, isCompanyVendorRestricted: true }))
})

const getPayrollInstances = payroll => {
  return payroll.payrollInstances
    .toModelArray()
    .filter(instance => {
      const services = instance.payrollInstanceServices.toModelArray()
      const processes = flatten(services.map(process => process.payrollInstanceProcesses.toModelArray()))
      const steps = flatten(processes.map(process => process.payrollInstanceSteps.toModelArray()))
      const tasks = flatten(steps.map(step => step.payrollInstanceTasks.toRefArray()))

      const code = tasksIdentifiers.cotPrePayrollPartnerReview.code

      return tasks.filter(({ identifier, status }) => identifier === code &&
        status === statuses.completed.value).length
    })
    .map(payrollInstance => ({
      id: payrollInstance.id,
      name: payrollInstance.name,
      payDate: payrollInstance.payDate,
      fromDate: payrollInstance.fromDate,
      toDate: payrollInstance.toDate,
      status: payrollInstance.status,
      payrollName: payrollInstance.payroll.ref.name
    }))
}
