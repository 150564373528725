import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import LoginView from '../components/LoginView'
import { login, getCodeByCredentials } from 'redux/actions/auth'
import LoginWrapper from 'layouts/LoginWrapper'
import { useHistoryProxy } from 'utils/history'

const LoginContainer = (props) => {
  const { isLoggedIn, hasRHSSO, entryRoute, history: historyProps } = props
  const history = useHistoryProxy(historyProps)

  useEffect(() => {
    // Comment this out to troubleshoot logging into RHSSO and getting redirected back to the app [DEPRECATED UNTIL CREATE NEW LOGIN FLOW]
    if (isLoggedIn) {
      history.enhancedPush({
        pathname: '/home',
      })
    } else if (hasRHSSO) {
      history.enhancedPush({
        pathname: '/rhsso',
        state: { entryRoute },
      })
    }
  }, [isLoggedIn, hasRHSSO, historyProps])

  return (
    <LoginWrapper>
      <LoginView {...props} />
    </LoginWrapper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.accessToken,
  is2FAenabled: state.config.is2FAenabled,
  isSSOEnabled: state.config.isSSOEnabled,
  SSOurl: `${state.config.apiUrl}requestssologin`,
  hasRHSSO: state.config.migratedToRHSSO,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { is2FAenabled } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (data) => {
      return is2FAenabled
        ? dispatch(getCodeByCredentials(data)).then(() => {
          ownProps.history.push('/auth-code')
        })
        : dispatch(login(data)).then(() => {
          ownProps.history.push('/home')
        })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LoginContainer)
