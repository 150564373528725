import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/vendorSwitch'

class VendorSwitch extends CRUDModel {}
VendorSwitch.modelName = 'VendorSwitch'
VendorSwitch.actionTypes = actionTypes

VendorSwitch.fields = {
  id: attr(),
  companyService: fk('CompanyCountryService', 'companyServices'),
}

export default VendorSwitch
