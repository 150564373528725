import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
import { updatePayrollProcess } from 'redux/actions/payrollProcess'
import { getFilteredEmployees } from 'redux/selectors/employees'
import { getPayrollProcess } from '../selectors/payrollProcessesORM'
import ProcessForm from 'components/service/ProcessForm'

const mapDispatchToProps = (dispatch, { onSubmit }) => ({
  onSubmit: data => dispatch(updatePayrollProcess(data, data.id)).then(onSubmit)
})

const mapStateToProps = (state, props) => {
  const { payrollProcessId } = props

  const payrollProcessRef = getPayrollProcess(state, { payrollProcessId })

  return {
    owners: getFilteredEmployees(state, { filter: props.filters.employeeSystemUsers.name }),
    initialValues: {
      id: payrollProcessId,
      owner: payrollProcessRef.owner
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollProcessEdit'
})(ProcessForm))

export default Fetcher(Container, [
  'payrollProcess', 'accessAreaPivot',
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { match: { params } }) => createFilter({ isKeyPerson: 1, accessableCompanies: params.companyId })
      }
    }]
  }
])
