import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/languages'

class Language extends CRUDModel {}
Language.modelName = 'Language'

Language.fields = {
  id: attr()
}
Language.actionTypes = actionTypes

export default Language
