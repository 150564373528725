import createSelector from 'utils/createSelector'
import { getFilteredDocuments } from 'redux/selectors/document'

const getDocumentId = (state, props) => parseInt(props.id)

export const getDocumentRef = createSelector(getDocumentId, ({ Document }, id) => Document.withId(id).ref)
export const getFilteredDocumentsWithDocumentTypes = createSelector(getFilteredDocuments,
  ({ DocumentType }, documents) => documents.map(document => ({
    ...document,
    documentTypes: document.documentTypes && document.documentTypes.map(type => DocumentType.withId(type).text).join(', ')
  })))
