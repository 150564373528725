import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FETCH = 'APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FETCH'
export const APPROVED_COMPANY_DATA_PROTECTION_FIELDS_RECEIVE = 'APPROVED_COMPANY_DATA_PROTECTION_FIELDS_RECEIVE'
export const APPROVED_COMPANY_DATA_PROTECTION_FIELDS_INVALIDATE = 'APPROVED_COMPANY_DATA_PROTECTION_FIELDS_INVALIDATE'
export const APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTER = 'APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTER'
export const APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTERED = 'APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FETCH,
  receive: APPROVED_COMPANY_DATA_PROTECTION_FIELDS_RECEIVE,
  invalidate: APPROVED_COMPANY_DATA_PROTECTION_FIELDS_INVALIDATE,
  filter: APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTER,
  filtered: APPROVED_COMPANY_DATA_PROTECTION_FIELDS_FILTERED
}

// eslint-disable-next-line max-len
const actionUtility = new ActionUtility(actionTypes, 'approvedCompanyDataProtectionFieldsPivot', 'companycountrydataprotectionfields', 'ApprovedCompanyDataProtectionField')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchApprovedCompanyDataProtectionFieldsPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchApprovedCompanyDataProtectionFieldsPivot = actionUtility.fetchEntities
export const invalidateApprovedCompanyDataProtectionFieldsPivot = actionUtility.invalidate

export const filterApprovedCompanyDataProtectionFields = filters => {
  return actionUtility.filterEntity({ filters })
}
