import _ from 'lodash'

/**
 * Documentation to be added
 */
export const getFieldsKeyByPrefix = (data, prefix) => {
  let prefixed = []

  _.forOwn(data, (value, key) => {
    const isPrefixed = _.startsWith(key, prefix)

    if (isPrefixed) prefixed.push(key)
  })

  return prefixed
}
