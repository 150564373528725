import { getMappedOneOfEnumSchema } from 'utils/fnkit/components/SchemaForm/transform'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { fieldLabels } from 'utils/locales/common.en'
import { reportDesignerRequiredFields, reportDesignerSchemaFields } from './fields'
import { reportNameLabels } from 'utils/locales/report.en'

/**
 * Generates the schema for the report designer form.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Array<Object>} params.countries - The list of countries.
 * @param {Array<Object>} params.companies - The list of companies.
 * @param {Array<Object>} params.payrolls - The list of payrolls.
 * @param {Array<Object>} params.payruns - The list of payruns.
 * @param {Array<Object>} params.businessUnits - The list of business units.
 * @param {Array<Object>} params.costCenters - The list of cost centers.
 * @param {Array<Object>} params.departments - The list of departments.
 * @returns {Object} The schema for the report designer form.
 */
export const getReportDesignerSchema = ({ countries, companies, payrolls, payruns, businessUnits, costCenters, departments } = {}) => {
  return {
    title: '',
    type: 'object',
    required: [reportDesignerSchemaFields.reportName, ...reportDesignerRequiredFields],
    properties: {
      [reportDesignerSchemaFields.reportName]: {
        type: 'string',
        title: fieldLabels.name,
        default: reportNameLabels.workForcePayrunDetail,
      },
      [reportDesignerSchemaFields.fromDate]: {
        type: 'string',
        format: 'date',
        title: fieldLabels.fromDate,
      },
      [reportDesignerSchemaFields.toDate]: {
        type: 'string',
        format: 'date',
        title: fieldLabels.toDate,
      },
      [reportDesignerSchemaFields.country]: {
        type: 'array',
        title: fieldLabels.country,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(countries, 'name', 'id'),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.company]: {
        type: 'array',
        title: fieldLabels.company,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(companies, 'name', 'id'),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.payroll]: {
        type: 'array',
        title: fieldLabels.payroll,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(payrolls, 'name', 'id'),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.payrun]: {
        type: 'array',
        title: fieldLabels.payrun,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(payruns, 'name', 'id'),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.typeOfEmploymentEngagement]: {
        type: 'array',
        title: fieldLabels.typeOfEmploymentEngagement,
        items: {
          oneOf: [{ type: 'number' }, { type: 'string' }],
          ...getMappedOneOfEnumSchema(EmploymentStatusHelper.allStatusOptionsForSelectBox, 'label', 'value', { withNone: true }),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.businessUnit]: {
        type: 'array',
        title: fieldLabels.businessUnit,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(businessUnits, 'name', 'id', { withNone: true }),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.costCenter]: {
        type: 'array',
        title: fieldLabels.costCenter,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(costCenters, 'name', 'id', { withNone: true }),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.department]: {
        type: 'array',
        title: fieldLabels.department,
        items: {
          type: 'number',
          ...getMappedOneOfEnumSchema(departments, 'name', 'id', { withNone: true }),
        },
        uniqueItems: true,
      },
      [reportDesignerSchemaFields.categories]: {
        type: 'array',
        title: ' ',
        items: {
          title: 'Category',
          type: 'object',
          properties: {
            label: { type: 'string', title: 'Label' },
            value: { type: 'string', title: 'Value' },
            options: {
              type: 'array',
              title: 'Field',
              items: {
                type: 'object',
                properties: {
                  label: { type: 'string', title: 'Label' },
                  value: { type: 'string', title: 'Value' },
                  selected: { type: 'boolean', default: false },
                },
              },
            },
          },
        },
      },
    },
  }
}
