import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchTermsIfNeeded } from '../modules/actions'
import { fetchTermCategoriesIfNeeded } from 'redux/actions/termCategories'
import { fetchTermSubCategoriesIfNeeded } from 'redux/actions/termSubCategories'
import { getTerms } from 'redux/selectors/terms'
import Terms from '../components/Terms'

class TermsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { fetchTermsIfNeeded, fetchTermCategoriesIfNeeded, fetchTermSubCategoriesIfNeeded } = this.props
    fetchTermsIfNeeded()
    fetchTermCategoriesIfNeeded()
    fetchTermSubCategoriesIfNeeded()
  }

  render () {
    return <div>
      <Terms {...this.props} />
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  fetchTermsIfNeeded: () => dispatch(fetchTermsIfNeeded()),
  fetchTermCategoriesIfNeeded: () => dispatch(fetchTermCategoriesIfNeeded()),
  fetchTermSubCategoriesIfNeeded: () => dispatch(fetchTermSubCategoriesIfNeeded()),
})

const mapStateToProps = state => {
  const { terms, termCategories, termSubCategories } = state

  const entities = [ terms, termCategories, termSubCategories ]

  if (isFetching(entities)) return { isFetching: true }

  const termsData = getTerms(state)

  return {
    terms: termsData
  }
}

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:
 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })
 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(TermsContainer)
