import { SubmissionError } from 'redux-form'
import _ from 'lodash'
import moment from 'moment'
import { showMessage } from 'redux/actions/modal'
import { getDatesInRange } from 'utils/date'
import { fetchEmployeeOvertime } from 'redux/actions/employeeOvertime'
import { fetchEmployeeAttendance } from 'redux/actions/employeeAttendance'
import { roundHalfValue } from 'utils/number'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_ATTENDANCE_PERIOD_FETCH = 'EMPLOYEE_ATTENDANCE_PERIOD_FETCH'
export const EMPLOYEE_ATTENDANCE_PERIOD_RECEIVE = 'EMPLOYEE_ATTENDANCE_PERIOD_RECEIVE'
export const EMPLOYEE_ATTENDANCE_PERIOD_INVALIDATE = 'EMPLOYEE_ATTENDANCE_PERIOD_INVALIDATE'
export const EMPLOYEE_SYSTEM_USER_BULK_ATTACH_ATTENDANCE = 'EMPLOYEE_SYSTEM_USER_BULK_ATTACH_ATTENDANCE'
export const EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME = 'EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_ATTENDANCE_PERIOD_FETCH,
  receive: EMPLOYEE_ATTENDANCE_PERIOD_RECEIVE,
  invalidate: EMPLOYEE_ATTENDANCE_PERIOD_INVALIDATE,
  bulkAttachAttendance: EMPLOYEE_SYSTEM_USER_BULK_ATTACH_ATTENDANCE,
  bulkSyncAttendanceOvertime: EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME,
}

const fetch = () => ({
  type: actionTypes.fetch,
})

const receive = (entity) => ({
  type: actionTypes.receive,
  payload: { response: { data: entity } },
})

const bulkSyncAttendanceOvertimeAction = (entity) => ({
  type: actionTypes.bulkSyncAttendanceOvertime,
  payload: entity,
})

const bulkAttachAttendanceAction = (data) => ({
  type: actionTypes.bulkAttachAttendance,
  payload: data,
})

// ------------------------------------
// Thunk
// ------------------------------------
export const invalidateEmployeeAttendancePeriod = () => ({
  type: actionTypes.invalidate,
})

export const fetchEmployeeAttendancePeriods = (employeeId) => {
  return (dispatch, getState, { api }) => {
    dispatch(fetch())

    const uri = `employeeusers/${employeeId}/attendanceperiods`
    const currentYear = new Date().getFullYear()

    return api
      .post(uri, {
        payload: {
          // The BE API requires a date period, in order to get the attendances.
          // But we needed all the attendances here.
          // Because of this we set an earlier `fromDate` (first possible UTC date)
          // and we set `toDate` to be the next year.
          // It's possible the user to select an attendance in two different years (for example, 25.12.2018 - 02.01.2019)
          params: {
            fromDate: '1970-01-01',
            toDate: `${currentYear + 1}-12-31`,
          },
        },
      })
      .then((response) => dispatch(receive(response)))
  }
}
export const fetchEmployeeAttendancePeriodsIfNeeded = (employeeId) => {
  return (dispatch, getState, { api }) => {
    const { itemsById } = getState().orm.EmployeeAttendancePeriod

    const hasData = _.find(itemsById, { employee: employeeId })

    if (!hasData) {
      dispatch(fetchEmployeeAttendancePeriods(employeeId))
    }
  }
}

export const deleteEmployeeAttendancePeriod = (attendancePeriodId) => {
  return (dispatch, getState, { api }) => {
    const { itemsById } = getState().orm.EmployeeAttendancePeriod
    const period = itemsById[attendancePeriodId]

    let data = {
      params: {
        fromDate: period.fromDate,
        toDate: period.toDate,
        employees: [period.employee],
        types: ['attendance'],
      },
      data: {},
    }

    // sync to back end
    return api
      .patch('employeeattendanceovertimes', { payload: data })
      .then(handleAttendancePeriodErrors)
      .then(() => {
        dispatch(bulkSyncAttendanceOvertimeAction(data))
      })
  }
}

export const handleAttendancePeriodErrors = (response) => {
  let errors

  if (response.errors) {
    errors = _.flatten(
      response.errors.map((errors) => {
        const errorMessages = []
        _.forOwn(errors.errors, (messages) => {
          errorMessages.push(messages)
        })

        return errorMessages
      })
    )
  }

  if (response.errorsCommon) {
    errors = response.errorsCommon.concat(errors)
  }

  if (errors) throw new SubmissionError({ _error: errors })

  return response
}

/**
 * Note <Bobby>
 * I've done this in this fashion because there's
 * going to be a different way of sending the data
 * once the back end changes come in effect.
 * So there's no point in tidying up things here
 * for now. Don't hate me :)
 */
export const bulkSyncAttendanceOvertime = (data) => {
  let backEndData = {}
  // set empty data
  backEndData['data'] = {}

  // set patch params
  backEndData['params'] = {
    fromDate: moment(data['fromDate'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
    toDate: moment(data['toDate'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
    employees: data['employees'],
    types: ['attendance', 'overtime'],
  }

  let timeAndAttendance = {}
  let overtime = {}

  // get all time attendance form inputs
  _.forOwn(data, (el, key) => {
    if (key.includes('attendance')) {
      timeAndAttendance[key] = el
    } else if (key.includes('overtime')) {
      overtime[key] = el
    }
  })

  // create an object for each filled date
  let counter = 0
  _.forOwn(timeAndAttendance, (cct, key) => {
    // don't send an empty cct object,
    // back end knows how to deal with no-shows
    if (cct === '' || cct == null) return

    // break down key data
    let keyData = key.split('_')
    let employee = parseInt(keyData[1])
    let chosenDate = keyData[2]

    // add to form
    backEndData['data'][counter] = {
      employee: employee,
      date: moment(chosenDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      companyCountryTerm: cct,
      type: 'attendance',
    }

    // increase array key
    counter++
  })

  _.forOwn(overtime, (hours, key) => {
    // don't send an empty object,
    // back end knows how to deal with no-shows
    if (hours === '' || hours === 0) return

    // break down key data
    let keyData = key.split('_')
    let employee = parseInt(keyData[1])
    let chosenDate = keyData[2]

    // add to form
    backEndData['data'][counter] = {
      employee: employee,
      date: moment(chosenDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      companyCountryTerm: data[`cct_${chosenDate}`],
      type: 'overtime',
      hours: hours,
    }

    counter++
  })

  return (dispatch, getState, { api }) => {
    // sync to back end
    return api
      .patch('employeeattendanceovertimes', { payload: backEndData })
      .then(handleFormErrors)
      .then((payload) => {
        showModalMessage(payload, dispatch)
      })
      .then((response) => {
        dispatch(fetchEmployeeAttendance())
        dispatch(fetchEmployeeOvertime())
      })
  }
}

/**
 * Handle form errors specifically for this case
 *
 * @param response - received data
 * @return mixed
 */
const handleFormErrors = (response) => {
  // Errors mapped to a field
  let normalizedErrors = {}

  if (response.errors) {
    response.errors.forEach(({ employee, date, type, errors }) => {
      const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      const fieldName = `${type}_${employee}_${formattedDate}`

      // Assign all error messages to the field
      _.forOwn(errors, (errorsMessages) => {
        normalizedErrors[fieldName] = [...(errors[fieldName] ? errors[fieldName] : []), ...errorsMessages]
      })
    })
  }

  if (response.errors || response.errorsCommon) {
    throw new SubmissionError({
      ...normalizedErrors,
      ...(response.errorsCommon ? { _error: response.errorsCommon } : {}),
    })
  }

  return response
}

// Show modal message, if there is `warning` property in the payload
const showModalMessage = (payload, dispatch) => {
  if (payload.warning) {
    dispatch(showMessage({ body: `${payload.warning}` }))
  }
}

export const bulkAttachAttendances = (data) => {
  const backEndData = {
    data: buildAttendanceDates(data),
    params: {
      employees: [data.employeeId],
      types: ['attendance'],
      fromDate: moment(data.from).format('YYYY-MM-DD'),
      toDate: data.actualLastDayOfLeave ? moment(data.actualLastDayOfLeave).format('YYYY-MM-DD') : moment(data.to).format('YYYY-MM-DD'),
      estimatedLastDayOfLeave: data.to ? moment(data.to).format('YYYY-MM-DD') : null,
      actualLastDayOfLeave: data.actualLastDayOfLeave ? moment(data.actualLastDayOfLeave).format('YYYY-MM-DD') : null,
      lastDayOfWork: data.lastDayOfWork ? moment(data.lastDayOfWork).format('YYYY-MM-DD') : null,
      previousFromDate: null,
      previousToDate: null,
      totalDays: roundHalfValue(parseFloat(data.totalDays)) ?? null,
    },
  }

  return (dispatch, getState, { api }) => {
    dispatch(bulkAttachAttendanceAction(data))

    return api
      .post('employeeattendanceovertimes', { payload: backEndData })
      .then(handleAttendancePeriodErrors)
      .then((payload) => {
        showModalMessage(payload, dispatch)
      })
      .then(() => {
        data['params'] = { employees: [data.employeeId] }
        dispatch(bulkSyncAttendanceOvertimeAction(data))
      })
  }
}

const buildAttendanceDates = ({ employeeId, companyCountryTerm, from, to, actualLastDayOfLeave, lastDayOfWork, totalDays }) => {
  // Reset days, in order to remove seconds and milliseconds.
  // Otherwise choosing the same dates for `from` and `to` could cause an issue.
  // For example it is possible the user to choose firstly `to` and after that `from'.
  // In that case the range won't be calculated correctly.
  const fromDate = moment(from).startOf('day')
  const toDate = actualLastDayOfLeave ? moment(actualLastDayOfLeave).startOf('day') : moment(to).startOf('day')
  const range = getDatesInRange(fromDate, toDate, true)

  let data = []

  range.forEach((date, i) => {
    data.push({
      date,
      employee: employeeId,
      companyCountryTerm: companyCountryTerm,
      type: 'attendance',
      estimatedLastDayOfLeave: moment(to).format('YYYY-MM-DD'),
      firstDayOfLeave: fromDate.format('YYYY-MM-DD'),
      actualLastDayOfLeave: actualLastDayOfLeave ? moment(actualLastDayOfLeave).format('YYYY-MM-DD') : null,
      lastDayOfWork: lastDayOfWork ? moment(lastDayOfWork).format('YYYY-MM-DD') : null,
      totalDays: roundHalfValue(parseFloat(totalDays)) ?? null,
    })
  })

  return data
}

export const bulkSyncAttendances = (data) => {
  const backEndData = {
    data: buildAttendanceDates(data),
    params: {
      employees: [data.employeeId],
      types: ['attendance'],
      fromDate: moment(data.from).format('YYYY-MM-DD'),
      toDate: data.actualLastDayOfLeave ? moment(data.actualLastDayOfLeave).format('YYYY-MM-DD') : moment(data.to).format('YYYY-MM-DD'),
      estimatedLastDayOfLeave: data.to ? moment(data.to).format('YYYY-MM-DD') : null,
      actualLastDayOfLeave: data.actualLastDayOfLeave ? moment(data.actualLastDayOfLeave).format('YYYY-MM-DD') : null,
      lastDayOfWork: data.lastDayOfWork ? moment(data.lastDayOfWork).format('YYYY-MM-DD') : null,
      previousFromDate: moment(data.previousFromDate).format('YYYY-MM-DD'),
      previousToDate: moment(data.previousToDate).format('YYYY-MM-DD'),
      totalDays: roundHalfValue(parseFloat(data.totalDays)) ?? null,
    },
  }

  return (dispatch, getState, { api }) => {
    return api
      .put('employeeattendanceovertimes', { payload: backEndData })
      .then(handleAttendancePeriodErrors)
      .then((payload) => {
        showModalMessage(payload, dispatch)
        dispatch(bulkSyncAttendanceOvertimeAction(backEndData))
      })
  }
}
