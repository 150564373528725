export const payrunStatusesEnum = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  SENT_AND_LOCKED: 'sent-and-locked',
  GROSS_TO_NET_CALCULATIONS_AND_REVIEW_DONE: 'gross-to-net-calculations-and-review-done',
  COT_FINAL_PAYROLL_REPORTS_REVIEW_DONE: 'cot-final-payroll-reports-review-done',
  COMPLETED: 'completed',
  COMPLETED_WITH_EXTRA_TASKS: 'completed-with-extra-tasks',
  REOPENED: 'reopened',
  REVERTED: 'reverted',
}

export const payrunProgressStatusesEnum = {
  OVERDUE: 'Overdue',
  PENDING: 'Pending',
  ON_TIME: 'On Time',
}
