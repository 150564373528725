/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { isFunction, isObject, isString, isNull } from 'lodash'
import { CustomLastRow } from './CustomLastRow'
import EditableCell from '../cell/EditableCell'
import Loader from 'components/Loader'

const TableBodyLoader = () => (
  <div className='u-absolute d-flex jc--center ai-center w-full h-full'>
    <Loader />
  </div>
)

const PayslipTableBodyCell = React.memo(
  ({ cell }) => {
    const headerStr = isFunction(cell.column.Header) ? cell.column.id : cell.column.Header
    const dataTestIdValue = cell.value ? `-${cell.value}` : ''
    return (
      <td
        {...cell.getCellProps()}
        data-portalid={`tablebody-tr-td-${headerStr}-${cell.row?.id}`}
        className={cell.column.columnClassName || ''}
        data-testid={`tablebody-tr-td-${headerStr}-${cell.row.original.title || '/'}${dataTestIdValue}`}
      >
        {cell.column.actionCell ? (
          cell.render('Cell')
        ) : (
          <div title={isString(cell.value) && !cell.column.disableTitleTag ? cell.value : ''} className={`${cell.column.columnClassName || ''}`}>
            {cell.render('Cell')}
            {cell.column.minWidth > 0 && <div style={{ minWidth: cell.column.minWidth }} />}
          </div>
        )}
      </td>
    )
  },
  (prevProps, nextProps) => {
    // some fields options are dependent an another fields value eg. payrolls and paymentCurrency
    if (prevProps.cell.row.original.metaData?.optionsDependency) {
      if (prevProps.cell.row.original.metaData?.options !== nextProps.cell.row.original.metaData?.options) {
        return false
      }
    }
    if (nextProps.editableOptions?.fieldErrors?.length > 0) return false
    // If the cell isn't editable, always trigger a re-render
    if (!prevProps.cell.column.editable) return false
    if (isNull(prevProps.editableOptions)) return false
    if (prevProps.editableOptions.inGlobalEditMode !== nextProps.editableOptions.inGlobalEditMode) {
      return false
    }
    if (prevProps.editableOptions.inAddMode !== nextProps.editableOptions.inAddMode || prevProps.editableOptions.inAddMode) {
      return false
    }
    return prevProps.cell.row.original.value === nextProps.cell.row.original.value
  }
)

const LayoutRow = ({ children }) => (
  <>
    <td className='' />
    {children}
  </>
)
const ClassicRowTemplate = ({ row, editableOptions }) => (
  <>
    <td className=''> {/* Need an empty item to account for the hover border td */} </td>
    {row.cells.map((cell, i) => {
      let key = isObject(cell.value) ? i : cell.value
      return <PayslipTableBodyCell key={`${key}-${cell.row.index}-${i}`} cell={cell} editableOptions={editableOptions} />
    })}
  </>
)

const ExpandibleRowTemplate = ({ row, editableOptions }) => (
  <>
    <td className=''> {/* Need an empty item to account for the hover border td */} </td>
    {row.cells.map((cell, i) => {
      let key = isObject(cell.value) ? i : cell.value
      return <PayslipTableBodyCell key={`${key}-${cell.row.index}-${i}`} cell={cell} editableOptions={editableOptions} />
    })}
  </>
)

const ExpandibleEmptyRow = ({ row, editableOptions }) => {
  const hasExpandedComponent = isFunction(row.original.ExpandedComponent)
  const isEditMode = editableOptions?.inGlobalEditMode || editableOptions?.inAddMode
  const columnKey = row.original.columnKey
  const value = row.original?.value
  let editableTemplate
  if (isEditMode && columnKey) {
    const cell = row?.cells?.find((c) => c?.column?.id === columnKey)

    if (cell) {
      cell.row = {
        ...cell.row,
        index: row.original.rowIndex,
      }
      editableTemplate = (
        <div {...cell.getCellProps()}>
          <EditableCell {...cell} {...editableOptions} value={value} />
        </div>
      )
    }
  }

  return hasExpandedComponent ? (
    <LayoutRow>
      <td className='expanded-section' colSpan={row.allCells.length}>
        {isEditMode ? editableTemplate : row.original.ExpandedComponent(editableOptions)}
      </td>
    </LayoutRow>
  ) : (
    <>
      {isEditMode && (
        <LayoutRow>
          <td className='expanded-section' colSpan={row.allCells.length}>
            {editableTemplate}
          </td>
        </LayoutRow>
      )}
    </>
  )
}
const UnavailableExpandableRowTemplate = ({ row, editableOptions }) => (
  <>
    <td className=''> {/* Need an empty item to account for the hover border td */} </td>
    <td className='expanded-section' colSpan={row.allCells.length}>
      {isFunction(row.original.ExpandedComponent) ? row.original.ExpandedComponent(editableOptions) : row.original.ExpandedComponent}
    </td>
  </>
)

const PayslipTableBodyRow = ({
  row,
  expandableClass,
  trBodyClassName,
  trBodyClassNameCb = null,
  useCustomExpandedDisplay,
  editableOptions,
  overrideRowDependency,
}) => {
  let classes = ''
  if (isFunction(trBodyClassNameCb)) {
    classes = trBodyClassNameCb(row)
  } else {
    classes = trBodyClassName
  }
  const rowIsSelectedClass = row.isSelected ? ' row-selected ' : ''
  const rowOverridesclass = row?.selectionOpts?.className
  classes += row.isSelected && rowOverridesclass ? rowOverridesclass : rowIsSelectedClass

  let dependency = overrideRowDependency
    ? [row.original]
    : [editableOptions.inGlobalEditMode, editableOptions.fieldErrors, editableOptions.inAddMode, row.original]

  if (row.original.metaData?.shouldChangeUpdateFieldOptions) {
    dependency.push(row.original.metaData.options)
  }

  return useMemo(() => {
    return (
      <tr {...row.getRowProps({ className: classes })}>
        {useCustomExpandedDisplay && (
          <>
            {!row.canExpand && !expandableClass && <UnavailableExpandableRowTemplate row={row} editableOptions={editableOptions} />}
            {!row.canExpand && expandableClass && <ExpandibleEmptyRow row={row} editableOptions={editableOptions} />}
            {row.canExpand && <ExpandibleRowTemplate row={row} editableOptions={editableOptions} />}
          </>
        )}
        {!useCustomExpandedDisplay && <ClassicRowTemplate row={row} editableOptions={editableOptions} />}
      </tr>
    )
  }, dependency)
}

export const PayslipTableBody = ({
  getTableBodyProps,
  expandableClass,
  prepareRow,
  rows,
  trBodyClassName,
  useCustomExpandedDisplay,
  trBodyActionBar,
  customLastRow,
  isLastPage,
  editableOptions,
  overrideRowDependency,
  isFetching,
}) => {
  if (isFetching) {
    return <TableBodyLoader />
  }

  return (
    <tbody {...getTableBodyProps()}>
      {trBodyActionBar ? (
        <tr>
          <td className=''> {/* Need an empty item to account for the hover border td */} </td>
          <td colSpan={trBodyActionBar.options?.colSpan || null}>{trBodyActionBar.Component}</td>
        </tr>
      ) : null}
      {rows.map((row, i) => {
        prepareRow(row)
        const rowExpandedClass = row.isExpanded ? 'u-bg--lilac row-expanded' : ''
        return (
          <PayslipTableBodyRow
            key={`table-body-row-${i}`}
            expandableClass={expandableClass}
            trBodyClassNameCb={trBodyClassName || null}
            trBodyClassName={`u-text--valhalla ${trBodyClassName || ''} ${expandableClass || rowExpandedClass}`}
            useCustomExpandedDisplay={useCustomExpandedDisplay}
            row={row}
            editableOptions={editableOptions}
            overrideRowDependency={overrideRowDependency}
          />
        )
      })}
      {customLastRow && <CustomLastRow row={customLastRow} isLastPage={isLastPage} />}
    </tbody>
  )
}
