import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryTermPivot'

class CountryTerm extends CRUDModel {
  /*
   * Global name and local name can be the same.
   * Because of that we show 'Global name' / 'Local name'
   * to remove duplication, when they are the same, we display only global name.
   */
  shouldOnlyUseGlobalName () {
    return this.localName === this.term.name
  }
}
CountryTerm.modelName = 'CountryTerm'

CountryTerm.fields = {
  id: attr(),
  localName: attr(),
  term: fk('Term', 'countryTerms')
}
CountryTerm.actionTypes = actionTypes

export default CountryTerm
