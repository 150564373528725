import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceTaskOwner'

class PayrollInstanceTaskOwner extends CRUDModel {}

PayrollInstanceTaskOwner.modelName = 'PayrollInstanceTaskOwner'
PayrollInstanceTaskOwner.actionTypes = actionTypes

PayrollInstanceTaskOwner.fields = {
  id: attr(),
  payrollInstanceTask: fk('PayrollInstanceTask', 'payrollInstanceTaskOwners'),
  owner: fk('EmployeeSystemUser', 'payrollInstanceTaskOwners'),
}

export default PayrollInstanceTaskOwner
