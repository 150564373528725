/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 *
 * @param {*} value1 - The first value to compare.
 * @param {*} value2 - The second value to compare.
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 */
export const isEqual = (value1, value2) => {
  if (value1 === value2) return true
  if (typeof value1 !== typeof value2) return false
  if (typeof value1 !== 'object' || value1 === null || value2 === null) return false

  const keys1 = Object.keys(value1)
  const keys2 = Object.keys(value2)
  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => isEqual(value1[key], value2[key]))
}
