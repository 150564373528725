import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from '../../../../../modules/actions'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompanyServicePivotIfNeeded } from 'redux/actions/companyServicePivot'
import { fetchCompanyProcessPivotIfNeeded } from 'redux/actions/companyProcessPivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchCountryProcessPivotIfNeeded } from 'redux/actions/countryProcessPivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchProcessesIfNeeded } from 'redux/actions/processes'
import { fetchPayrollService } from 'redux/actions/payrollService'
import { fetchPayrollProcess } from 'redux/actions/payrollProcess'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import CompanyServicesList from '../components/CompanyServicesList'
import { getCompanyServicesWithProcessesByTypeByCompany,
  getCompanyServicesWithProcessesByTypeGroupedByService } from '../selectors/companyServicesORM'
import { getCompanyRef } from 'redux/selectors/company'
import { types } from 'redux/config/services'

class CompanyServicesListContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCompaniesIfNeeded()
    this.props.fetchCountriesIfNeeded()
    this.props.fetchCompanyServicePivotIfNeeded()
    this.props.fetchCompanyProcessPivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchCountryProcessPivotIfNeeded()
    this.props.fetchServicesIfNeeded()
    this.props.fetchProcessesIfNeeded()
    this.props.fetchPayrollService()
    this.props.fetchPayrollProcess()
    this.props.fetchPayrollsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <CompanyServicesList {...this.props} />
  }
}

const mapDispatchToProps = {
  fetchCountriesIfNeeded,
  fetchCompaniesIfNeeded,
  fetchCompanyServicePivotIfNeeded,
  fetchCompanyProcessPivotIfNeeded,
  fetchCountryServicePivotIfNeeded,
  fetchCountryProcessPivotIfNeeded,
  fetchServicesIfNeeded,
  fetchProcessesIfNeeded,
  fetchPayrollService,
  fetchPayrollProcess,
  fetchPayrollsIfNeeded
}

const mapStateToProps = (state, props) => {
  const { countries, companies, companyServicePivot, companyProcessPivot,
    countryServicePivot, countryProcessPivot, services, processes, payrolls, payrollService, payrollProcess } = state

  if (isFetching([countries, companies, companyServicePivot, companyProcessPivot,
    countryServicePivot, countryProcessPivot, services, processes, payrolls, payrollService, payrollProcess])) return { isFetching: true }

  const companyId = props.match.params.companyId
  const company = getCompanyRef(state, { companyId })

  const operationalServices = company.isHQ
    ? getCompanyServicesWithProcessesByTypeGroupedByService(state, { type: types.operational })
    : getCompanyServicesWithProcessesByTypeByCompany(state, { companyId, type: types.operational })

  const setupServices = company.isHQ
    ? getCompanyServicesWithProcessesByTypeGroupedByService(state, { type: types.setup })
    : getCompanyServicesWithProcessesByTypeByCompany(state, { companyId, type: types.setup })

  return {
    companyId,
    operationalServices,
    setupServices,
    isTableRepresentation: company.isHQ
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyServicesListContainer)
