import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

/**
 * Component that decorates passed in tree data,
 * to flat row data
 *
 * It fully supports flatten decoration of `services` and `steps` trees.
 *
 * You have to pass `services`, or `steps` props
 * and the component would pass decorated data to the children component as `rows` props.
 *
 * Please refer to propTypes declaration, to check what's the structure of the input props.
 *
 * Example usage:
 *
 * ```
 * const steps = [{
 *  name: 'First step',
 *  tasks: [{
 *    name: 'First task',
 *    owner: 'Jordan'
 *  },{
 *    name: 'Second task',
 *    owner: 'Nadezhda'
 *  },{
 *    name: 'Third task',
 *    owner: 'Borislav'
 *  }]
 * }]
 *
 * <ServicesTreeDecorator steps={steps} >
 *   // Here the parent decorator, would pass `rows` props to PayrollProcessTree automatically.
 *   // `rows` will have the following structure
 *   // rows = [{
 *   //   stepName: 'First step',
 *   //   taskName: 'First task',
 *   //   taskOwner: 'Jordan Enev'
 *   //  },{
 *   //   stepName: '',
 *   //   taskName: 'Second task',
 *   //   taskOwner: 'Nadezhda',
 *   //  },{
 *   //   stepName: '',
 *   //   taskName: 'Third task',
 *   //   taskOwner: 'Borislav'
 *   // }]
 *   <PayrollProcessTree />
 * </ServicesTreeDecorator>
 * ```
 *
 */
class ServicesTreeDecorator extends React.Component {
  // this is a single row from the tree table
  rows = []

  getRenders () {
    const { services, steps } = this.props

    // If no one print property is set, then set services as default printer
    return {
      renderServices: !steps ? true : services !== undefined,
      renderSteps: steps !== undefined
    }
  }

  pushDataToRow (service, process, step, task) {
    const { renderServices, renderSteps } = this.getRenders()

    let row = {}

    const taskRow = {
      ...task ? {
        ...task.active ? { taskActive: task.active } : {},
        ...task.name ? { taskName: task.name } : {},
        ...task.owner ? { taskOwner: task.owner } : {},
        ...task.globalOwner ? { taskGlobalOwner: task.globalOwner } : {},
        ...task.finishTaskLink ? { finishTaskLink: task.finishTaskLink } : {},
        ...task.deadline !== 'undefined' ? {
          taskDeadline: this.props.isDeadlineDate && task.deadline !== null ? moment(task.deadline).format('DD/MM/YYYY') : task.deadline
        } : {},
        ...task.deadlineMonthly !== 'undefined' ? { deadlineMonthly: task.deadlineMonthly } : {},
        ...task.deadlineFortnightly !== 'undefined' ? { deadlineFortnightly: task.deadlineFortnightly } : {},
        ...task.deadlineWeekly !== 'undefined' ? { deadlineWeekly: task.deadlineWeekly } : {},
        ...task.countryTaskId ? { countryTaskId: task.countryTaskId } : {},
        ...task.vendorCountryServiceId ? { vendorCountryServiceId: task.vendorCountryServiceId } : {},
        ...task.vendorCountryTaskId ? { vendorCountryTaskId: task.vendorCountryTaskId } : {},
        ...task.id ? { taskId: task.id } : {},
        ...task.identifier ? { taskIdentifier: task.identifier } : {},
        ...task.taskableType ? { taskableType: task.taskableType } : {}
      } : null,
    }

    const stepRow = {
      ...step ? {
        ...step.id ? { stepId: step.id } : {},
        ...step.name ? { stepName: step.name } : {},
        ...step.isMoveable ? { stepIsMoveable: step.isMoveable } : {},
      } : null,
      ...taskRow
    }

    const processRow = {
      ...process ? {
        ...process.name ? { processName: process.name } : {},
        ...process.keyEvent ? { processKeyEvent: process.keyEvent } : {}
      } : null,
      ...stepRow,
      ...taskRow
    }

    const serviceRow = {
      ...service ? {
        ...service.name ? { serviceName: service.name } : {},
        ...service.type ? { serviceType: service.type } : {}
      } : null,
      ...processRow,
      ...stepRow,
      ...taskRow
    }

    if (renderServices) row = serviceRow
    if (renderSteps) row = stepRow

    this.rows.push(row)
  }

  // render the tree
  renderTree () {
    const { renderServices, renderSteps } = this.getRenders()

    this.rows = []

    if (renderServices) this.renderServicesTree()
    if (renderSteps) this.renderStepsTree()
  }

  renderServicesTree () {
    const { services } = this.props

    services.map(service => {
      // print the whole service
      this.printService(service)
    })
  }

  renderStepsTree () {
    const { steps } = this.props

    steps.map(step => {
      // print the whole service
      this.printStep(step)
    })
  }

  /**
   * Print a service, along with his processes, steps and tasks
   * First we add the service with his first process, first step and first task
   * then we print all the tasks for the step if any
   * then we print all the steps for the process if any
   * then we print the remaining processes
   */
  printService (service) {
    let process = service.processes.length ? service.processes[0] : ''
    let step = process && process.steps.length ? process.steps[0] : ''
    let task = step && step.tasks.length ? step.tasks[0] : ''

    this.pushDataToRow(service, process, step, task)
    this.printTasksIfAny(task, step)
    this.printStepsIfAny(step, process)
    this.printProcessesIfAny(process, service)
  }

  /**
   * Print a step, along with his tasks
   * First we add the step with his first task,
   * then we print all the tasks for the step if any
   */
  printStep (step) {
    let task = step && step.tasks.length ? step.tasks[0] : ''

    this.pushDataToRow(null, null, step, task)
    this.printTasksIfAny(task, step)
  }

  /**
   * Print rows with only task data
   */
  printTasksIfAny (task, step) {
    if (task && step.tasks[1]) {
      step.tasks.map((singleTask, taskIndex) => {
        if (taskIndex && parseInt(taskIndex, 10) !== 0) {
          this.pushDataToRow(null, null, null, singleTask)
        }
      })
    }
  }

  /**
   * Print rows with step data and (task data, if any)
   */
  printStepsIfAny (step, process) {
    if (step && process.steps[1]) {
      process.steps.map((singleStep, stepIndex) => {
        if (stepIndex && parseInt(stepIndex, 10) !== 0) {
          let task = singleStep && singleStep.tasks.length ? singleStep.tasks[0] : ''
          this.pushDataToRow(null, null, singleStep, task)

          this.printTasksIfAny(task, singleStep)
        }
      })
    }
  }

  /**
   * Print rows with process data and (step+task data, if any)
   */
  printProcessesIfAny (process, service) {
    if (process && service.processes[1]) {
      service.processes.map((singleProcess, processIndex) => {
        if (processIndex !== 0) {
          let step = singleProcess && singleProcess.steps.length ? singleProcess.steps[0] : ''
          let task = step && step.tasks.length ? step.tasks[0] : ''
          this.pushDataToRow(null, singleProcess, step, task)

          this.printTasksIfAny(task, step)
          this.printStepsIfAny(step, singleProcess)
        }
      })
    }
  }

  render () {
    this.renderTree()
    return React.cloneElement(this.props.children, { rows: this.rows })
  }
}

ServicesTreeDecorator.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    processes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      keyEvent: PropTypes.string,
      steps: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        tasks: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          globalOwner: PropTypes.string,
          owner: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
          ]),
          finishTaskLink: PropTypes.string,
          deadline: PropTypes.number,
        })).isRequired
      })).isRequired
    })).isRequired
  })),
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      owner: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
      globalOwner: PropTypes.string,
      finishTaskLink: PropTypes.string,
      deadline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })).isRequired
  })),
  isDeadlineDate: PropTypes.bool
}

export default ServicesTreeDecorator
