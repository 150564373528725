import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_DATA_PROTECTION_FIELDS_FETCH = 'COMPANY_DATA_PROTECTION_FIELDS_FETCH'
export const COMPANY_DATA_PROTECTION_FIELDS_RECEIVE = 'COMPANY_DATA_PROTECTION_FIELDS_RECEIVE'
export const COMPANY_DATA_PROTECTION_FIELDS_FILTER = 'COMPANY_DATA_PROTECTION_FIELDS_FILTER'
export const COMPANY_DATA_PROTECTION_FIELDS_FILTERED = 'COMPANY_DATA_PROTECTION_FIELDS_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_DATA_PROTECTION_FIELDS_FETCH,
  receive: COMPANY_DATA_PROTECTION_FIELDS_RECEIVE,
  filter: COMPANY_DATA_PROTECTION_FIELDS_FILTER,
  filtered: COMPANY_DATA_PROTECTION_FIELDS_FILTERED
}
const actionUtility = new ActionUtility(actionTypes, 'companyDataProtectionFieldsPivot', 'countrydataprotectionfieldswithdata', 'CompanyDataProtectionField')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyDataProtectionFieldsPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyDataProtectionFieldsPivot = actionUtility.fetchEntities

export const filterCompanyDataProtectionFields = filters => {
  return actionUtility.filterEntity({ filters })
}
