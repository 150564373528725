export default function (types) {
  this.fetch = payload => {
    return { type: types.fetch, payload }
  }

  this.receive = payload => ({
    type: types.receive,
    payload
  })

  this.create = entity => ({
    type: types.create,
    payload: entity
  })

  this.update = entity => ({
    type: types.update,
    payload: entity
  })

  this.updateWithChildUri = entity => ({
    type: types.updateWithChildUri,
    payload: entity
  })

  this.delete = entity => ({
    type: types.delete,
    payload: entity
  })

  this.invalidate = () => ({
    type: types.invalidate
  })

  this.invalidateSpecificFilter = (filter) => ({
    type: types.invalidateSpecificFilter,
    payload: filter
  })

  this.reset = () => ({
    type: types.reset
  })

  this.errors = errors => ({
    type: types.errors,
    payload: errors
  })

  this.setActiveId = id => ({
    type: types.setActiveId,
    payload: { id }
  })

  this.setLastCreatedId = id => ({
    type: types.setLastCreatedId,
    payload: { id }
  })

  this.setLastRunReportType = obj => ({
    type: types.setLastRunReportType,
    payload: { obj }
  })

  this.setShouldPoll = payload => ({
    type: types.setShouldPoll,
    payload: payload
  })

  this.setStartPolling = payload => ({
    type: types.setStartPolling,
    payload: payload
  })

  this.setPollingDelay = payload => ({
    type: types.setPollingDelay,
    payload: payload
  })

  this.receiveWithErrors = errors => ({
    type: types.receiveWithErrors,
    payload: errors
  })

  this.clearErrors = () => ({
    type: types.clearErrors
  })

  this.filter = () => {
    return { type: types.filter }
  }

  this.filtered = entity => ({
    type: types.filtered,
    payload: entity
  })

  this.resetFilters = () => ({
    type: types.resetFilters
  })
}
