import React from 'react'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import { phoneValidation } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import PropTypes from 'prop-types'

class PhoneNumberStepOneView extends React.Component {
  render () {
    return <div className='o-layout u-margin-bottom'>
      <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
        <Form {...this.props}>
          <div className='o-form-group'>
            <Field
              name='mobilePhone'
              label='1. Enter new phone number'
              component={PhoneCodeNumberField}
              validate={phoneValidation}
              type='text'
              className='c-input c-input--mobile'
              autoFocus
              labelClassName='c-label c-label--mobile'
            />
          </div>
          <div className='o-form-group u-margin-top'>
            <button
              type='submit'
              disabled={this.props.pristine || this.props.submitting}
              className='c-btn c-btn--submit c-btn--small c-btn--mobile c-btn--curious u-padding-left u-padding-right'
              tabIndex='-1'
            >
              Confirm
            </button>
          </div>
        </Form>
      </div>
    </div>
  }
}

PhoneNumberStepOneView.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
}

export default PhoneNumberStepOneView
