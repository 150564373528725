import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { reduxForm } from 'redux-form'
import { normalizeStringToBoolean } from 'redux/transformers/booleanTransformer'
import { createCotUser } from 'redux/actions/cotUsers'
import { invalidateAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { omit } from 'lodash'
import { getCountries } from 'redux/selectors/country'
import { getTimezones } from 'redux/selectors/timezones'
import CotUserForm from '../components/CotUserForm'
import CotUserFormHOC, { buildAccessArea } from './CotUserFormHOC'
import { isRoleCotBot } from 'utils/roles'
import { mapPropertyValueToBoolean } from 'utils/sanitizer'

const form = 'cotUserCreateForm'

const mapStateToProps = (state, props) => {
  return {
    formName: form,
    countries: getCountries(state),
    timezones: getTimezones(state),
    initialValues: {
      roleType: '',
    },
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: (data) => {
      const { accessAreaParentCompany } = data
      let normalized = {
        ...omit(data, ['accessAreaParentCompany', 'beneficiaryFile', 'bankFile', 'dataMigration', 'reportDesigner']),
        accessAreas: buildAccessArea(accessAreaParentCompany, 'ParentCompany'),
      }

      if (!isRoleCotBot(data.roleType)) {
        const beneficiaryFile = mapPropertyValueToBoolean(data, 'beneficiaryFile')
        const bankFile = mapPropertyValueToBoolean(data, 'bankFile')
        const dataMigration = mapPropertyValueToBoolean(data, 'dataMigration')
        const reportDesigner = mapPropertyValueToBoolean(data, 'reportDesigner')

        normalized['cotSpecialRight'] = {
          ...beneficiaryFile,
          ...bankFile,
          ...dataMigration,
          ...reportDesigner,
        }
      }

      normalized = normalizeStringToBoolean(normalized, ['receivesNotifications'])

      return dispatch(createCotUser(normalized)).then(() => {
        props.onCreate()
        dispatch(invalidateAccessAreaPivot())
      })
    },
  }
}

const Container = CotUserFormHOC(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form })(CotUserForm)), form)

export default Fetcher(Container, ['cotUsers', 'countries', 'timezones'])
