import React from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

/**
 * Defer `redux-form` <Field /> mounting
 *
 * Mounting many `redux-form` fields at once, causes a performance issue.
 * Here you can read more about the problem: https://stackoverflow.com/q/53192260/4312466
 *
 * Because of this we implemented this workaround,
 * that will defer <Field /> rendering, unless you start editing it.
 * Until then - we just render a static stateless component.
 *
 * Known issue: Loosing focus when we switch between <DefferedFields /> via Tab key.
 * We can try to manually trigger the focus on the newly mounted `redux-form` <Field />
 */
class DeferredField extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isEditable: props.isEditable }

    this.enableField = this.enableField.bind(this)
  }

  enableField () {
    if (this.state.isEditable) return true

    this.setState({ isEditable: true })
  }

  render () {
    const { component: Component, initialValue, isSelectField } = this.props

    return this.state.isEditable ? (
      <Field {...this.props} />
    ) : (
      <Component
        {...this.props}
        input={{
          value: initialValue,
          onMouseEnter: this.enableField,
          onFocus: this.enableField,
        }}
        // If the componenent is select,
        // then we want to pass down `passInputPropsToParent` prop.
        // We do this, in order to pass the `input` props to the parent (wrapper) element of the `react-select`,
        // because `react-select` third party component, doesn't support `onMouseEnter` and `onFocus` events.
        // The workaround is to pass the handlers to our custom wrapper.
        {...(isSelectField ? { passInputPropsToParent: true } : {})}
        meta={{}}
      />
    )
  }
}

const mapStateToProps = (state, { name, formName }) => {
  const { initial, values } = state.form[formName]

  return {
    isEditable: initial[name] !== values[name],
    initialValue: initial[name]
  }
}

export default connect(mapStateToProps)(DeferredField)
