import { createReducerMeta } from 'utils/redux'
import { PAYROLL_EMPLOYEE_FETCH, PAYROLL_EMPLOYEE_RECEIVE,
  PAYROLL_EMPLOYEE_INVALIDATE } from '../actions/payrollEmployeePivot'

const actionTypes = {
  fetch: PAYROLL_EMPLOYEE_FETCH,
  receive: PAYROLL_EMPLOYEE_RECEIVE,
  invalidate: PAYROLL_EMPLOYEE_INVALIDATE
}

export default createReducerMeta(actionTypes)
