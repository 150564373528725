import React from 'react'

export const ToastWrapper = ({ onCompleteToast, children }) => {
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-wrapper'>
      <span className='u-text--small'>{children}</span>
      <button className='c-btn--unset-default-styles position-absolute u-absolute--top-right' onClick={onCompleteToast}>
        <span className='icon--ex' />
      </button>
    </div>
  )
}
