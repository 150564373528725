import React from 'react'
import PropTypes from 'prop-types'
import { fixedDescription } from 'redux/config/companyCountryTerms'
import PayslipTable from 'components/table/PayslipTable'
import { formatFixedTermName } from '../utility'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { EditableCellDataEmployeeFieldsFormatter } from 'components/table/cell/EditableCellDataEmployeeFieldsFormatter'
import { format } from 'date-fns'
import EditableCell from 'components/table/cell/EditableCell'
import { isDate } from 'lodash'

const EmptyRow = (props) => {
  return (
    <div className='v-hidden'><span title='Toggle Row Expanded' className='icon--arrow' /></div>
  )
}

const ExpandedRow = ({ fixedTerms, editableOptions, dataKey }) => {
  const valueEditOptions = { Cell: EditableCell, placeholder: 'Value', editable: true, inputType: 'amount' }
  const dateOptions = { Cell: EditableCell, inputType: 'date', editable: true }
  let rows = []

  fixedTerms.map((term, index) => {
    let row = {
      title: formatFixedTermName(term),
      validFrom: term.validFrom ? (isDate(term.validFrom) ? format(term.validFrom, 'dd/MM/yyyy') : term.validFrom) : null,
      validTo: term.validTo ? (isDate(term.validTo) ? format(term.validTo, 'dd/MM/yyyy') : term.validTo) : null,
      amount: term.amount,
      metaData: { subCategory: dataKey, mappedErrorFieldId: term.employeeTermId },
      emptyRow: <EmptyRow />
    }
    if (term.isNew) {
      row['isNew'] = term.isNew
    }
    rows.push(row)
  })

  return (
    <PayslipTable
      wrapperClassName='u-1/1'
      tableElementClassName='u-margin-top-none table--layout-auto '
      editableOptions={editableOptions}
      headings={[
        new TableHeading({ accessor: 'title', heading: 'Title', className: 'd-none', columnClassName: 'text-left u-padding-left-none' }),
        new TableHeading({
          accessor: 'validFrom',
          heading: 'From Date',
          className: 'd-none',
          columnClassName: 'text-right u-fixed-width--125',
          mappedErrorField: 'validFrom',
          ...dateOptions,
        }),
        new TableHeading({
          accessor: 'validTo',
          heading: 'To Date',
          className: 'd-none',
          columnClassName: 'text-right u-fixed-width--125',
          ...dateOptions,
          mappedErrorField: 'validTo',
        }),
        new TableHeading({ accessor: 'amount', heading: 'Value', className: 'd-none ', columnClassName: 'text-right u-fixed-width--200', ...valueEditOptions }),
        new TableHeading({ accessor: 'emptyRow', heading: 'emptyRow', className: 'd-none ', columnClassName: 'text-right u-fixed-width--50' }),
      ]}
      data={rows}
    />
  )
}

ExpandedRow.propTypes = {
  fixedTerms: PropTypes.array,
  editableOptions: PropTypes.object,
  dataKey: PropTypes.string,
}

const FixedPayEditableElements = ({
  fixedTerms,
  inEditMode,
  inAddMode,
  onUpdateGlobalDataForSave,
  dataKey,
  futureDataKey,
  futureFixedTerms,
  fieldErrors,
  onValueChange,
}) => {
  const editableOptions = {
    inGlobalEditMode: inEditMode,
    inAddMode: inAddMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    onCancelInlineAdd: () => null,
    onSaveInline: () => null,
    fieldErrors: fieldErrors,
    onValueChange: onValueChange,
  }
  const getRowExpandable = (futureFixedTerms, options, dataKey) => {
    return <ExpandedRow fixedTerms={futureFixedTerms} editableOptions={options} dataKey={futureDataKey} />
  }

  let rows = []
  fixedTerms.map((term, index) => {
    const futureItems = futureFixedTerms ? futureFixedTerms.filter((future) => term.id === future.countryTermId) : []

    let row = {
      title: formatFixedTermName(term),
      validFrom: term.validFrom ? (isDate(term.validFrom) ? format(term.validFrom, 'dd/MM/yyyy') : term.validFrom) : null,
      validTo: term.validTo ? (isDate(term.validTo) ? format(term.validTo, 'dd/MM/yyyy') : term.validTo) : null,
      amount: term.amount,
      editDisabled: term.shouldDisableEditOnLoad || ((!term.validFrom && !term.validTo) && !inEditMode),
      subRows: [{ id: term.id, ExpandedComponent: (props) => getRowExpandable(futureItems, props, dataKey), editableOptions: editableOptions }],
      metaData: { subCategory: dataKey, hasFutureItems: !!futureItems.length, mappedErrorFieldId: term.employeeTermId, mappedNewErrorId: term.addServerId },
    }
    if (term.isNew) {
      row['isNew'] = term.isNew
    }
    rows.push(row)
  })
  const commonSettings = { disableSortBy: false, className: 'd-none' }
  const dateOptions = {
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
    inputType: 'date',
    editable: true,
  }
  const valueEditOptions = {
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
    placeholder: 'Value',
    editable: true,
  }

  return (
    <div className='page--pay-taxes-fixed-pay-elements'>
      <PayslipTable
        wrapperClassName='u-1/1 fixed-pay-expanded-table'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        data-testid='employee-pay-and-taxes-fixed-pay-elements'
        data={rows}
        headingColSpan='2'
        dynamicValues
        useCustomExpandedDisplay
        autoResetExpanded={!inEditMode}
        editableOptions={editableOptions}
        headings={[
          {
            Header: 'Fixed Pay Elements',
            classNames: 'text-center',
            title: fixedDescription,
            columns: [
              new TableHeading({ accessor: 'title', heading: 'Title', ...commonSettings, columnClassName: 'text-left' }),
              new TableHeading({
                accessor: 'validFrom',
                heading: 'Value',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--125',
                ...dateOptions,
                placeholder: 'From Date',
                mappedErrorField: 'validFrom',
              }),
              new TableHeading({
                accessor: 'validTo',
                heading: 'Title',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--125',
                ...dateOptions,
                placeholder: 'To Date',
                mappedErrorField: 'validTo',
              }),
              new TableHeading({
                accessor: 'amount',
                heading: 'Value',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--200',
                ...valueEditOptions,
                inputType: 'amount',
                placeholder: 'Value',
              }),
              {
                id: 'expander',
                expander: true,
                editable: false,
                columnClassName: 'text-right u-fixed-width--50',
                classNames: 'd-none',
                disableFilters: true,
                disableSortBy: true,
                // eslint-disable-next-line react/prop-types
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                  <span {...getToggleAllRowsExpandedProps()}>{' '}</span>
                ),
                Cell: ({ row }) => {
                  // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                  // to build the toggle for expanding a row
                  const View = row.canExpand ? (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                      className={row.isExpanded ? 'icon--arrow active' : 'icon--arrow'}
                    />
                  ) : null

                  return row.original.metaData.hasFutureItems ? View : null
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}

FixedPayEditableElements.propTypes = {
  fixedTerms: PropTypes.array,
  inAddMode: PropTypes.bool,
  inEditMode: PropTypes.bool,
  onUpdateGlobalDataForSave: PropTypes.func,
  dataKey: PropTypes.string,
  futureFixedTerms: PropTypes.array,
  fieldErrors: PropTypes.array,
  futureDataKey: PropTypes.string,
  onValueChange: PropTypes.func,
}

export default FixedPayEditableElements
