import Fetcher from 'containers/Fetcher'
import TimeAttendanceSummaryView from '../components/TimeAttendanceSummaryView'
import { connect } from 'react-redux'
import { getEmployee } from '../../../selectors/employeesORM'
import { getPayrollInstancesWithEmployeesAndTheirAttendanceOvertime } from '../selectors/payrollInstances'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'

const mapStateToProps = (state, props) => {
  // get the employee's payroll instances
  let employeePI = getPayrollInstancesWithEmployeesAndTheirAttendanceOvertime(state, {
    employeeId: props.match.params.employeeId
  })

  const employee = getEmployee(state, { employeeId: props.match.params.employeeId })

  return {
    employeePI,
    employee,
  }
}

const Container = connect(mapStateToProps)(TimeAttendanceSummaryView)

export default Fetcher(Container, [
  'countries', 'companyCountryTermPivot', 'countryTermPivot', 'terms', 'payrolls', 'termSubCategories',
  createEmployeeFilter({ name: 'payrollInstances' }),
  createEmployeeFilter({ name: 'payrollInstanceEmployeePivot' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
