import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/timezones'

class Timezone extends CRUDModel {}
Timezone.modelName = 'Timezone'

Timezone.fields = {
  id: attr()
}
Timezone.actionTypes = actionTypes

export default Timezone
