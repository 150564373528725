import CoreLayout from 'layouts/PageLayout/PageLayout'
import loadable from '@loadable/component'
import { ALL_ROUTES } from 'configs/routes'
const RouteViewRouteLoadable = loadable(() => import('./components/RouteView'))

export default (store) => ({
  path : `:payrollInstanceId/${ALL_ROUTES.PAYRUNS.PAY_AND_TAXES_CHANGES}`,
  authProtected: true,
  exact: true,
  renderOutsideParent: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: RouteViewRouteLoadable },
})
