import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceProcess'

class PayrollInstanceProcess extends CRUDModel {}
PayrollInstanceProcess.modelName = 'PayrollInstanceProcess'
PayrollInstanceProcess.actionTypes = actionTypes

PayrollInstanceProcess.fields = {
  id: attr(),
  payrollInstanceService: fk('PayrollInstanceService', 'payrollInstanceProcesses'),
  payrollProcess: fk('PayrollProcess', 'payrollInstanceProcesses'),
  owner: fk('EmployeeSystemUser', 'payrollInstanceProcesses'),
}

export default PayrollInstanceProcess
