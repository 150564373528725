import React from 'react'
import { useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { useFetch } from 'hooks/useFetch'
import { isArray } from 'lodash'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getProcesses } from 'redux/selectors/processes'
import FiltersAccessManagementReport from 'routes/Reporting/components/FiltersAccessManagementReport'
import { buildSelectOpt } from 'utils/options'
import { sortByLabel } from 'utils/strings'

export const FiltersAccessManagementReportContainer = (props) => {
  const fetchOptions = ['countries', 'companies', 'processes']
  const fetcherData = useFetch(fetchOptions)

  const selector = formValueSelector(props.form)
  const values = useSelector((state) => selector(state, 'subcategory', 'company', 'country'))

  const countries = buildSelectOpt(useSelector((state) => (fetcherData.isFetching ? [] : getCountriesByAuth(state)))).sort(sortByLabel)
  const companies = buildSelectOpt(
    useSelector((state) =>
      fetcherData.isFetching
        ? []
        : values.country
          ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] })
          : getCompaniesByAuth(state)
    )
  ).sort(sortByLabel)
  const processes = buildSelectOpt(useSelector((state) => (fetcherData.isFetching ? [] : getProcesses(state))))

  return <FiltersAccessManagementReport {...props} countries={countries} companies={companies} processes={processes} selected={values} />
}
