import React from 'react'
import ChangePasswordContainer from '../containers/ChangePasswordContainer'
import ChangePhoneNumberView from './ChangePhoneNumberView'
import InnerWrapper from 'layouts/InnerWrapper'

const RouteView = props => (
  <InnerWrapper>
    <ChangePasswordContainer {...props} />
    <ChangePhoneNumberView {...props} />
  </InnerWrapper>
)

export default RouteView
