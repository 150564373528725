import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/documentUsers'

class DocumentUser extends CRUDModel {}
DocumentUser.modelName = 'DocumentUser'

DocumentUser.fields = {
  id: attr()
}
DocumentUser.actionTypes = actionTypes

export default DocumentUser
