import { ActionUtility } from 'utils/redux'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { SubmissionError } from 'redux-form'

// ------------------------------------
// Constants
// ------------------------------------
export const HOLIDAYS_FETCH = 'HOLIDAYS_FETCH'
export const HOLIDAYS_RECEIVE = 'HOLIDAYS_RECEIVE'
export const HOLIDAYS_CREATE = 'HOLIDAYS_CREATE'
export const HOLIDAYS_UPDATE = 'HOLIDAYS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: HOLIDAYS_FETCH,
  receive: HOLIDAYS_RECEIVE,
  create: HOLIDAYS_CREATE,
  update: HOLIDAYS_UPDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'holidays', 'holidays', 'Holiday')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchHolidaysIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchHolidays = actionUtility.fetchEntities

export const createHoliday = (entity) => {
  // set valid date
  const data = dateTransformer(entity, ['date'])

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data))
      .then(errorsHandling.handleFormErrors)
      .catch(showCountryErrorIfAny)
  }
}
export const updateHoliday = (entity) => {
  // set valid date
  const data = dateTransformer(entity, ['date'])

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.updateEntity(data, data.id))
      .then(errorsHandling.handleFormErrors)
      .catch(showCountryErrorIfAny)
  }
}

/**
 * When creating or updating holidays for a country, if there is already a holiday added for
 * this date and country, an error for existing country holiday for this date is thrown.
 * Since the country ID field is hidden in the form, the errors won't show unless we add them
 * to _error common errors array
 */
const showCountryErrorIfAny = (error) => {
  const commonErrors = !Array.isArray(error['_error']) ? [error['_error']] : error['_error']
  throw new SubmissionError({
    ...error.errors,
    _error: error.errors.country ? [...commonErrors, error.errors.country] : commonErrors
  })
}
