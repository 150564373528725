import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/assignments'

class Assignment extends CRUDModel {}
Assignment.modelName = 'Assignment'
Assignment.actionTypes = actionTypes

Assignment.fields = {
  id: attr()
}

export default Assignment
