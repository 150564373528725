import React from 'react'
import PropTypes from 'prop-types'
import DocumentsViewContainer from '../containers/DocumentsViewContainer'

export const RouteView = props => <DocumentsViewContainer
  title='Documents'
  typeClass='company'
  typeId={props.match.params.companyId}
  {...props}
/>

RouteView.propTypes = {
  params: PropTypes.shape({
    companyId: PropTypes.string
  })
}

export default RouteView
