import React from 'react'
import ClientsContainer from '../containers/ClientsContainer'
import InnerWrapper from 'layouts/InnerWrapper'

class RouteView extends React.Component {
  render () {
    return <InnerWrapper>
      <ClientsContainer {...this.props} />
    </InnerWrapper>
  }
}

export default RouteView
