import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { reduxForm } from 'redux-form'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { types } from 'redux/config/documents'
import Form from 'components/form/Form'
import ShareVendorColleagues from 'components/documents/ShareVendorColleagues'
import ShareVendorClients from 'components/documents/ShareVendorClients'
import ShareVendorActionOwners from 'components/documents/ShareVendorActionOwners'
import { isCot } from 'redux/selectors/auth'
import {
  getVendorUsersWithSharedDocumentsWithoutLoggedVendorUser,
  getVendorUsersHavingCredentialsWithAccessToCompanyByCompanyId,
} from 'redux/selectors/vendorUsers'
import {
  getExternalActionOwners,
  getExternalClients,
  getExternalClientsHavingCredentials,
  getExternalPayrollInstanceProcessOwner,
  getExternalPayrollProcessOwner,
} from 'redux/selectors/externalEmployeeUsers'
import { getInitialValues } from 'redux/selectors/documentUsers'
import {
  getPayrollProcessIdByPayrollId,
  getPayrollProcessTasksOwnersIds,
} from 'routes/Companies/routes/Company/routes/DatesProcesses/routes/Payroll/selectors/payrollServiceORM'
import {
  getPayrollInstanceProcessIdByPayrollInstanceId,
  getPayrollInstanceProcessTasksOwnersIds,
} from 'routes/Payruns/routes/ChangeDetails/selectors/payrollProcessORM'
import { invalidateDocuments } from 'redux/actions/document'
import { resetDocumentUsers, updateDocumentUsers } from 'redux/actions/documentUsers'
import { invalidateDocumentLogs } from 'redux/actions/documentLogs'
import { createFilter } from 'utils/redux/filter'
import { buildShareBtnLabel } from 'redux/helpers/buildShareBtnLabel'
import { getFilteredDocuments } from 'redux/selectors/document'
import createDocumentFilter from 'redux/filters/createDocumentFilter'

const DocumentsShareVendorModalFormContainer = (props) => {
  const { typeClass, type } = props
  const tabs = typeClass ? types[typeClass].vendorShareTabs : []
  const btnText = buildShareBtnLabel(type)

  return (
    <Form {...props} showSubmitBtn btnText={btnText}>
      <Tabs>
        <TabList className='c-tabs__list'>
          {tabs.map((tab) => (
            <Tab key={tab.name} className='c-tabs__item'>
              <span>{tab.label}</span>
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab) => (
          <TabPanel key={tab.name}>
            {tab.name === 'actionOwners' && <ShareVendorActionOwners {...props} />}
            {tab.name === 'outsideCompany' && <ShareVendorClients {...props} />}
            {tab.name === 'withinCompany' && <ShareVendorColleagues {...props} />}
          </TabPanel>
        ))}
      </Tabs>
    </Form>
  )
}

DocumentsShareVendorModalFormContainer.propTypes = {
  typeClass: PropTypes.string,
  type: PropTypes.string,
}

/**
 * Important Gotcha: About building Users and their Form initial values.
 * As you will see below, when we are building the needed Users to be listed in the tabs,
 * but only these Users having credentials.
 * BUT, here is the Gotcha: for the User initial values we are building all the Users having a shared document,
 * no matter having or not the needed credentials.
 * BUT why? Because we want to keep the shared file for the inactive (without credentials) users too.
 * Here's the case:
 * 1. Let's say we want to share a file to John.
 * 2. John has credentials (his account is active) and we share the file successfully.
 * 3. A month later, we decide to make John account inactive.
 * 4. And now in the Share modal, we won't list John as sharing option, because his account is inactive,
 * but we still want to keep the file shared and that's the reason we keep adding it in the Form initial values.
 */
const mapStateToProps = (state, props) => {
  const { typeClass, documentId, documentTenant, payrollId, payrollInstanceId, filters } = props

  const companyId = props.match.params.companyId
  const tenant = state.vendorTenant.schema

  const payrollProcessId = payrollId ? getPayrollProcessIdByPayrollId(state, { payrollId }) : null
  const payrollInstanceProcessId = payrollInstanceId ? getPayrollInstanceProcessIdByPayrollInstanceId(state, { payrollInstanceId }) : null

  const filteredDocuments = getFilteredDocuments(state, { filter: filters.document.name })
  const document = filteredDocuments[0]

  /*
   * DocumentsShareModalFormContainer combines containers for the three share vendor tabs:
   * - ShareVendorActionOwners
   * - ShareVendorClients
   * - ShareVendorColleagues
   *
   * This is needed because we have to submit TOGETHER  all the three share tabs and sync the data.
   *
   * That's why we get here separately:
   * - ownersUsers - processOwner & actionOwnersUsers
   * - colleaguesUsers
   * - clientsUsers
   * pass them to the tabs and set their initial values.
   */
  const payrollProcessOwner = payrollProcessId
    ? getExternalPayrollProcessOwner(state, { companyId, tenant, documentId, documentTenant, payrollProcessId })
    : null
  const payrollInstanceProcessOwner = payrollInstanceProcessId
    ? getExternalPayrollInstanceProcessOwner(state, {
      companyId,
      tenant,
      documentId,
      documentTenant,
      payrollInstanceProcessId,
    })
    : null

  const processOwner = payrollProcessOwner || payrollInstanceProcessOwner
  const processOwnerId = processOwner && processOwner.length ? processOwner[0].extraData.id : null
  const processOwnerInitialValues = processOwner ? getInitialValues(state, { users: processOwner, userType: 'externalEmployeeUser' }) : null

  const payrollProcessTasksOwners = payrollProcessId ? getPayrollProcessTasksOwnersIds(state, { payrollProcessId, typeClass }) : null

  const payrollInstanceProcessTasksOwners = payrollInstanceId ? getPayrollInstanceProcessTasksOwnersIds(state, { payrollInstanceId, typeClass }) : null

  const tasksOwners = payrollProcessTasksOwners || payrollInstanceProcessTasksOwners

  const actionOwners = getExternalActionOwners(state, {
    companyId,
    tenant,
    documentId,
    documentTenant,
    tasksOwners,
    processOwnerId,
    typeClass,
  })
  const actionOwnersInitialValues = getInitialValues(state, { users: actionOwners, userType: 'externalEmployeeUser' })

  const colleaguesUsersHavingCredentials = getVendorUsersHavingCredentialsWithAccessToCompanyByCompanyId(state, { companyId, tenant })
  const colleaguesUsers = getVendorUsersWithSharedDocumentsWithoutLoggedVendorUser(state, { documentId, documentTenant })
  const colleaguesInitialValues = getInitialValues(state, { users: colleaguesUsers, userType: 'vendorUser' })

  const clientsUsersHavingCredentials = getExternalClientsHavingCredentials(state, {
    companyId,
    tenant,
    documentId,
    documentTenant,
    processOwnerId,
    tasksOwners,
    typeClass,
  })
  const clientsUsers = getExternalClients(state, {
    companyId,
    tenant,
    documentId,
    documentTenant,
    processOwnerId,
    tasksOwners,
    typeClass,
  })
  const clientsInitialValues = getInitialValues(state, { users: clientsUsers, userType: 'externalEmployeeUser' })

  return {
    colleaguesUsers: colleaguesUsersHavingCredentials,
    clientsUsers: clientsUsersHavingCredentials,
    processOwner,
    actionOwners,
    initialValues: {
      ...processOwnerInitialValues,
      ...actionOwnersInitialValues,
      ...clientsInitialValues,
      ...colleaguesInitialValues,
    },
    isCot: isCot(state),
    isOwner: document && document.extraData && document.extraData.isOwner,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { documentId, documentTenant, onShared } = props
  return {
    onSubmit: (data) =>
      dispatch(updateDocumentUsers(data, documentId, documentTenant)).then(() => {
        onShared()
        dispatch(invalidateDocuments())
        dispatch(resetDocumentUsers())
        dispatch(invalidateDocumentLogs())
      }),
  }
}

const makePayrollInstanceFilter = (name, filterName = 'payrollInstance') => ({
  name,
  params: [
    {
      forceVendorTenantAPI: true,
      _computed: {
        filter: (state, { payrollInstanceId }) =>
          createFilter({
            [filterName]: payrollInstanceId,
          }),
      },
    },
  ],
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'shareVendorFiles',
  })(DocumentsShareVendorModalFormContainer)
)

const CompanyFetcher = Fetcher(Container, [
  'vendorUsers',
  'externalEmployeeUsers',
  {
    name: 'documentUsers',
    params: [
      {
        _computed: {
          filter: (state, { documentId, documentTenant }) => createFilter({ documentId, documentTenant }),
        },
        disableObsoleteFlow: true,
      },
    ],
  },
  createDocumentFilter({ filterNames: ['id'] }),
  { name: 'payrollProcess', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollService', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollStep', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTask', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTaskOwners', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTaskOwnerScopes', params: [{ forceVendorTenantAPI: true }] },
  { name: 'companyTaskPivot', params: [{ forceVendorTenantAPI: true }] },
  { name: 'countryTaskPivot', params: [{ forceVendorTenantAPI: true }] },
  { name: 'tasks', params: [{ forceVendorTenantAPI: true }] },
])

const PayrollInstanceFetcher = Fetcher(Container, [
  'vendorUsers',
  'externalEmployeeUsers',
  {
    name: 'documentUsers',
    params: [
      {
        _computed: {
          filter: (state, { documentId, documentTenant }) => createFilter({ documentId, documentTenant }),
        },
        disableObsoleteFlow: true,
      },
    ],
  },
  createDocumentFilter({ filterNames: ['id'] }),
  { name: 'payrollProcess', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollService', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollStep', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTask', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTaskOwners', params: [{ forceVendorTenantAPI: true }] },
  { name: 'payrollTaskOwnerScopes', params: [{ forceVendorTenantAPI: true }] },
  { name: 'companyTaskPivot', params: [{ forceVendorTenantAPI: true }] },
  { name: 'countryTaskPivot', params: [{ forceVendorTenantAPI: true }] },
  { name: 'tasks', params: [{ forceVendorTenantAPI: true }] },
  makePayrollInstanceFilter('payrollInstanceService'),
  makePayrollInstanceFilter('payrollInstanceProcess'),
  makePayrollInstanceFilter('payrollInstanceStep'),
  makePayrollInstanceFilter('payrollInstanceTask'),
  makePayrollInstanceFilter('payrollInstanceTaskOwner'),
  makePayrollInstanceFilter('payrollInstanceTaskOwnerScope'),
])

/**
 * This Component is reused for Vendor -> PayrollInstances and Documents pages.
 *
 * When we reuse it for PayrollInstance, we have to fetch different data.
 * Because of this we have this default stateless component, in order to determine what data is needed to be fetched.
 *
 * @param props
 * @returns {*}
 */
export default (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.typeClass === 'payrollInstance') {
    return <PayrollInstanceFetcher {...props} />
  }

  return <CompanyFetcher {...props} />
}
