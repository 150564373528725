import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { createDocumentFilterNameById, prepareFilters } from 'redux/filters/createDocumentFilter'
import { fetchParentCompanies } from 'redux/actions/parentCompanies'
import { fetchDocumentTypes } from 'redux/actions/documentTypes'
import { fetchDocuments } from 'redux/actions/document'
import { createFilter } from 'utils/redux/filter'

import { SupportingDocsContent } from '../components/SupportingDocsContent'
import { LinkDocument } from 'components/documents/LinkDocument'

export const Container = (props) => {
  return (
    <div className='rounded border u-padding-small'>
      <div className='d-flex jc--flex-end'>
        <LinkDocument payrollInstanceId={props.payrollInstanceId} />
      </div>
      <SupportingDocsContent {...props} />
    </div>
  )
}

Container.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
}

export const SummarySupportingDocsContainer = (props) => {
  const dispatch = useDispatch()
  const computedFilters = prepareFilters({
    filterNames: ['typeClass', 'typeId'],
    props: {
      typeId: props.payrollInstanceId,
      typeClass: 'payrollInstance',
    },
  })

  useEffect(() => {
    dispatch(fetchParentCompanies())
    dispatch(fetchDocumentTypes())
    dispatch(fetchDocuments({ filter: createFilter(computedFilters, createDocumentFilterNameById(props.payrollInstanceId)) }))
  }, [dispatch])

  return <Container {...props} payrollSummary={false} />
}

SummarySupportingDocsContainer.propTypes = {
  payrollInstanceId: PropTypes.string.isRequired,
}
