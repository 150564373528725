import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ConfirmationModal from 'components/ConfirmationModal'
import Modal from 'components/Modal'
import BankAccountFormCreateContainer from 'containers/BankAccountFormCreateContainer'
import BankAccountFormEditContainer from 'containers/BankAccountFormEditContainer'
import { Authorization } from 'containers/Authorization'

const CompanyBankAccountView = (props) => {
  const {
    isFetching,
    bankAccounts,
    deleteBankAccount,
    showText
  } = props
  const [bankAccountId, setBankAccountId] = useState(null)
  const modalCreateBankAccount = useRef(null)
  const modalEditBankAccount = useRef(null)
  const confirmModal = useRef(null)

  return <>
    {isFetching && <div>Loading...</div>}
    {showText ? (
      <Authorization
        permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}
      >
        <p className='u-text--normal u-padding-top-small'>
          Please{' '}
          <span
            onClick={() => modalCreateBankAccount.current.showModal()}
            className='u-text--curious u-cursor--pointer'
            data-testid='bank-account-create'
          >
            click here
          </span>{' '}
          to add new bank account.
        </p>
      </Authorization>
    ) : null}
    <div data-testid='bank-accounts'>
      {!isFetching &&
          bankAccounts.map((account) => {
            return (
              <div key={account.id}>
                <div className='o-layout o-grid--middle o-grid--strech u-margin-top u-margin-bottom-small'>
                  <div className='o-layout__item u-1/1 u-1/2@tablet'>
                    <h3 className='u-text--normal u-margin-none'>
                      {account.bankCountryName}
                    </h3>
                  </div>
                  <div className='o-layout__item u-1/1 u-1/2@tablet'>
                    <div className='u-float--right'>
                      <Authorization permissions={['BANKACCOUNT_EDIT']}>
                        <div
                          onClick={() => {
                            setBankAccountId(account.id)
                            modalEditBankAccount.current.showModal()
                          }}
                          className='c-btn c-btn--tiny c-btn--curious
                          u-padding-left-small u-padding-right-small u-margin-left-tiny'
                          title='Edit'
                          data-testid='bank-account-edit'
                        >
                          <span className='icon icon--edit' />
                        </div>
                      </Authorization>
                      <Authorization permissions={['BANKACCOUNT_DELETE']}>
                        <div
                          onClick={() => {
                            setBankAccountId(account.id)
                            confirmModal.current.showModal()
                          }}
                          className='c-btn c-btn--tiny c-btn--curious
                          u-padding-left-small u-padding-right-small u-margin-left-tiny'
                          title='Delete'
                          data-testid='bank-account-delete'
                        >
                          <span className='icon icon--trash' />
                        </div>
                      </Authorization>
                    </div>
                  </div>
                </div>

                <div className='o-layout o-grid--strech' data-testid='bank-account-details'>
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Bank name
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='bankName'
                      >
                        {account.bankName}&nbsp;
                      </p>
                    </div>
                  </div>
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Currency
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='bankCurrency'
                      >
                        {account.currencyAbbr}
                      </p>
                    </div>
                  </div>
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Type of account
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='type-of-account'
                      >
                        {account.typeOfAccount}&nbsp;
                      </p>
                    </div>
                  </div>
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Bank address
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='bankAddress'
                      >
                        {account.bankAddress}&nbsp;
                      </p>
                    </div>
                  </div>
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Account holder
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='accountHolder'
                      >
                        {account.accountHolder}&nbsp;
                      </p>
                    </div>
                  </div>
                  {account.customFields.map((field) => {
                    return (
                      <div
                        key={field.name}
                        className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'
                      >
                        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                          <span className='u-text--small u-text--curious'>
                            {field['printable_name']}
                          </span>
                          <p className='u-text--normal u-margin-none u-word-break-all'>
                            {account[field.name]}&nbsp;
                          </p>
                        </div>
                      </div>
                    )
                  })}
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@desktop'>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <span className='u-text--small u-text--curious'>
                        Description
                      </span>
                      <p
                        className='u-text--normal u-margin-none u-word-break-all'
                        data-testid='accountDescription'
                      >
                        {account.description}&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
    </div>

    <ConfirmationModal
      ref={confirmModal}
      className='c-modal'
      modalHeading='Confirmation'
      onConfirm={() => deleteBankAccount(bankAccountId)}
    >
      <p>Are you sure you want to remove this bank account?</p>
    </ConfirmationModal>

    <Modal
      ref={modalEditBankAccount}
      className='c-modal c-modal--full'
      modalHeading='Edit bank account'
      data-testid='bank-account-edit-modal'
    >
      <BankAccountFormEditContainer
        onSubmit={() => modalEditBankAccount.current.hideModal()}
        bankAccountId={bankAccountId}
        {...props}
      />
    </Modal>

    <Modal
      ref={modalCreateBankAccount}
      className='c-modal c-modal--full'
      modalHeading='Add bank account'
      data-testid='bank-account-create-modal'
    >
      <BankAccountFormCreateContainer
        onSubmit={() => modalCreateBankAccount.current.hideModal()}
        {...props}
      />
    </Modal>
  </>
}

CompanyBankAccountView.propTypes = {
  bankAccounts: PropTypes.array,
  deleteBankAccount: PropTypes.func,
  showText: PropTypes.string,
  isFetching: PropTypes.bool
}

export default CompanyBankAccountView
