import React from 'react'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'

const FlagValue = ({ children, value: { label, abbreviature } }) => {
  return <div className='Select-value' title={label}>
    <span className='Select-value-label'>
      { abbreviature && <Flag flag={abbreviature.toLowerCase()} size='miniature' classes='u-margin-right-tiny' /> }
      { children }
    </span>
  </div>
}

FlagValue.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object.isRequired
}

export default FlagValue
