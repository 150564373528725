import createSelector from 'utils/createSelector'

const getSubCategoryId = (state, props) => parseInt(props.subCategoryId)

export const getSubCategoriesWithCategoryName = createSelector(
  ({ Category, SubCategory }) => {
    return SubCategory
      .all()
      .toModelArray()
      .map(subCategory => {
        return {
          ...subCategory.ref,
          categoryRef: subCategory.termCategory.ref
        }
      })
      .reverse()
  })

export const getSubCategoryById = createSelector(getSubCategoryId,
  ({ SubCategory }, subCategoryId) => SubCategory.withId(subCategoryId).ref
)
