import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import Flag from 'components/Flag'
import { Authorization } from 'containers/Authorization'
import Modal from 'components/Modal'
import CountryFormCreateContainer from '../containers/CountryFormCreateContainer'
import { sortByName } from 'utils/strings'
import { useSelector } from 'react-redux'
import { isCotOem } from 'redux/selectors/auth'

const CountryItem = ({ country, hasLink, children }) => (hasLink ? <Link to={`/countries/${country.id}/basics`}>{children}</Link> : children)

const CountriesList = ({ countries, isFetching }) => {
  const createCountryModal = useRef(null)
  const isCotOemUSer = useSelector(isCotOem)
  return (
    <>
      <Modal ref={createCountryModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading='Create Country'>
        <CountryFormCreateContainer onCreate={() => createCountryModal.current.hideModal()} />
      </Modal>

      <SectionHeading text='Countries'>
        <Authorization permissions={['COUNTRY_CREATE']}>
          <div className='o-layout__item u-1/1 u-1/2@tablet u-1/2@desktop'>
            <div className='u-float--right'>
              <button
                title='Create Country'
                data-testid='create-country-btn'
                onClick={() => createCountryModal.current.showModal()}
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              >
                <span className='icon icon--plus' />
              </button>
            </div>
          </div>
        </Authorization>
      </SectionHeading>

      {isFetching === true ? 'Loading...' : null}

      {countries.sort(sortByName).map((country) => (
        <div key={country.id} data-testid={`country-abbrv-${country.abbreviature}`}>
          <CountryItem country={country} hasLink={!isCotOemUSer}>
            <Flag flag={country.abbreviature.toLowerCase()} size='tiny' classes='u-margin-right-tiny' />
            <span data-testid='country-name'>{country.name}</span>
          </CountryItem>
        </div>
      ))}
    </>
  )
}

CountriesList.propTypes = {
  isFetching: PropTypes.bool,
  countries: PropTypes.array,
}

export default CountriesList
