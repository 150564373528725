import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import DashboardTileHeader from './DashboardTileHeader'
import EmptyListNotice from './EmptyListNotice'
import Moment from 'components/Moment'
import { ReportViewButton } from 'routes/Reporting/components/ReportViewButton'

const RecentReportsListItem = ({ item, handleReportModalOpen }) => {
  return (
    <li className='u-padding-top-small u-padding-bottom-small u-text--normal u-border-bottom--mischka u-text--valhalla page--recent-reports-list-item'>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
        <div className='text-left u-padding-left-small'>
          {item.name}&nbsp;
          <br />
          <small className='u-text--small'>
            Created:&nbsp;
            <Moment date={item.createdAt.date} format='DD/MM/YYYY HH:mm ' />
            {item.createdAt.timezone ? item.createdAt.timezone : null}
          </small>
        </div>
        <div className='d-flex ai-center jc--flex-end'>
          <ReportViewButton
            className='c-btn c-btn--curious c-btn--tiny border-none u-margin-right-tiny u-margin-bottom-tiny'
            report={item}
            handleClick={(e) => {
              e.preventDefault()
              handleReportModalOpen(item.id)
            }}
          />
        </div>
      </div>
    </li>
  )
}

const RecentReportsList = ({ items, handleReportModalOpen }) => {
  if (items.length < 1) return <EmptyListNotice text='No Recent Reports' />
  return (
    <ul className='list-unstyled u-margin-none'>
      {items.map((item) => (
        <RecentReportsListItem key={item.id} item={item} handleReportModalOpen={handleReportModalOpen} />
      ))}
    </ul>
  )
}

function RecentReportsDashboardView (props) {
  const hasItems = !props.isFetching && props.items.length > 0 ? 'has-items' : 'no-items'
  return (
    <div className='page--recent-reports-dashboard-view' data-testid='dashboard-recent-reports'>
      <DashboardTileHeader title='Recent Reports' onClick={props.onNavigatingToReports} />
      <div className={`dashboard-tile-container ${hasItems}`}>{!props.isFetching ? <RecentReportsList items={props.items} {...props} /> : <Loader />}</div>
    </div>
  )
}

RecentReportsDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigatingToReports: PropTypes.func,
  items: PropTypes.array,
}

RecentReportsListItem.propTypes = {
  item: PropTypes.object,
  handleReportModalOpen: PropTypes.func,
}

RecentReportsList.propTypes = {
  items: PropTypes.array,
  handleReportModalOpen: PropTypes.func,
}

export default RecentReportsDashboardView
