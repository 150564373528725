import { isEmpty } from 'lodash'

export const dummyRowForAddingComment = {
  id: 0,
  body: '',
  date: '',
  authorName: '',
  disableEdit: false,
  isNew: true,
}
/**
 * Transforms the payload into structure needed by the BE
 * And deletes the isNew flag from the data to reset the style of the cell
  {
    "body": "text",
    "payrollInstance": "2"
  }
*/

export const normalizeCommentaryPayload = (payload) => {
  delete payload.isNew
  return {
    body: payload.body,
    payrollInstance: payload.payrollInstance,
  }
}
/**
 * Transforms the payload into structure needed by the BE
  {
    "1": {
        "body": "Hola 4"
    },
    "25": {
        "body": "Hola 4"
    }
  }
*/

export const normalizeCommentaryEditPayload = (payload) => {
  let data = {}
  payload.forEach((comment) => {
    data[comment.id] = {
      body: comment.body,
    }
  })
  return data
}

/**
 * Validates commentary data against an empty comment body, and returns formatted errors
 */

export const verifyData = (data) => {
  return data
    .map((d) => {
      const error = {}
      if (isEmpty(d.body.trim())) {
        error['id'] = d.id
        error['body'] = ['Field is Required']
      }
      return error
    })
    .filter((error) => Object.keys(error).length > 0)
}
