import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import SwitchToggle from 'components/form/SwitchToggle'
import _ from 'lodash'
import PayslipTable from './table/PayslipTable'
const classNames = require('classnames')

class FieldsGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // Show/Hide fields
      // By default only first group of fields is visible
      expandFields: props.index === 0,
    }
  }

  /*
   * Arrow classes by toggling filters
   */
  getArrowClasses () {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-right-tiny': true,
      active: this.state.expandFields,
    })
  }

  /*
   * Toggle fields
   */
  toggleFields () {
    this.setState({
      expandFields: !this.state.expandFields,
    })
  }

  render () {
    const { categoryName, fields } = this.props
    return (
      <div>
        <div className=' u-1/1@tablet u-2/3@desktop u-bg--white u-padding-tiny u-margin-bottom-small border-1-valhalla rounded'>
          <SectionHeading text={categoryName} className='u-margin-bottom-none'>
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <span
                  onClick={() => this.toggleFields()}
                  className={this.getArrowClasses()}
                />
              </div>
            </div>
          </SectionHeading>
        </div>
        {this.state.expandFields ? (
          !_.isEmpty(fields) ? (
            <PayslipTable
              data={fields.map((field) => {
                return {
                  fieldName: field.name,
                  isSensitiveData: (
                    <SwitchToggle
                      input={{ value: field.isSensitive }}
                      disabled
                    />
                  ),
                  action: (
                    <button
                      onClick={() => this.props.onClick(field.id)}
                      className='c-btn c-btn--tiny c-btn--curious'
                    >
                      <span className='icon icon--edit' title='Edit' />
                    </button>
                  ),
                }
              })}
              headings={[
                {
                  accessor: 'fieldName',
                  Header: 'Field name',
                  isKey: true,
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  accessor: 'isSensitiveData',
                  Header: 'Is sensitive',
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  accessor: 'action',
                  Header: 'Action',
                  actionCell: true,
                  disableFilters: true,
                  disableSortBy: true,
                },
              ]}
              wrapperClassName='u-margin-bottom u-1/1@tablet u-2/3@desktop'
            />
          ) : (
            <p className='u-margin-bottom'>No data</p>
          )
        ) : null}
      </div>
    )
  }
}

FieldsGroup.propTypes = {
  categoryName: PropTypes.string,
  fields: PropTypes.array,
  index: PropTypes.number,
}

export default FieldsGroup
