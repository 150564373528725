import createSelector from 'utils/createSelector'
import { isCot, hasAccess } from 'redux/selectors/auth'
import { completionURIs } from 'redux/config/tasks'
import moment from 'moment'
import _ from 'lodash'
import { getCompiledUri } from 'utils/templates'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'

const getFilteredIds = makeFilteredIdsByFilter('assignments')

export const getAssignmentsForDashboard = createSelector(
  isCot,
  getFilteredIds,
  hasAccess,
  ({ Assignment, Company }, isUserCot, filteredIds, hasAccess) => {
    return _.sortBy(
      Assignment.all()
        .toRefArray()
        .filter((assignment) => filterAssignments(assignment, null, filteredIds, isUserCot))
        .map((assignment) => getAssignment(assignment, Company, hasAccess)),
      ['deadline']
    )
  }
)
export const getOverdueAssignmentsGroupedByStatus = createSelector(
  isCot,
  getFilteredIds,
  hasAccess,
  ({ Assignment, Company }, isUserCot, filteredIds, hasAccess) => {
    const data = {
      lanes: [],
    }

    const statuses = ['not-started', 'in-progress']

    statuses.forEach((statusLabel) => {
      data.lanes.push({
        id: statusLabel,
        title: _.upperFirst(_.replace(statusLabel, '-', ' ')),
        label: statusLabel,
        cards: _.sortBy(
          Assignment.filter((assignment) =>
            filterAssignments(assignment, statusLabel, filteredIds, isUserCot)
          )
            .toRefArray()
            .map((assignment) => getAssignment(assignment, Company, hasAccess, false)),
          ['epochDeadline']
        ),
      })
    })

    data.lanes = _.reverse(_.sortBy(data.lanes, ['id']))

    return data
  }
)

export const getAssignmentsGroupedByStatus = createSelector(
  isCot,
  getFilteredIds,
  hasAccess,
  ({ Assignment, Company }, isUserCot, filteredIds, hasAccess) => {
    const data = {
      lanes: [],
    }

    const statuses = ['not-started', 'in-progress', 'completed']

    statuses.forEach((statusLabel) => {
      data.lanes.push({
        id: statusLabel,
        title: _.upperFirst(_.replace(statusLabel, '-', ' ')),
        label: statusLabel,
        cards: _.sortBy(
          Assignment.filter((assignment) =>
            filterAssignments(assignment, statusLabel, filteredIds, isUserCot)
          )
            .toRefArray()
            .map((assignment) => getAssignment(assignment, Company, hasAccess)),
          ['deadline']
        ),
      })
    })

    data.lanes = _.reverse(_.sortBy(data.lanes, ['id']))

    return data
  }
)

const filterAssignments = (assignment, statusLabel, filteredIds, isUserCot) => {
  const isLaneStatus = statusLabel ? assignment.status === statusLabel : true
  const isReverted =
    statusLabel === 'in-progress' ? assignment.status === 'reverted' : null
  const hasFilter = filteredIds ? filteredIds.includes(assignment.id) : true
  const showCotAssignments = !isUserCot ? assignment.owner !== 'Payslip' : true
  return (isLaneStatus || isReverted) && showCotAssignments && hasFilter
}

/**
 * Get a name initials
 *
 * John Doe => JD
 *
 * @param {String} name
 * @returns {String}
 */
const getInitials = (name) =>
  name
    .split(' ')
    .map((n) => n[0])
    .join('')

const showOverdueDays = (val = null) => {
  if (val <= 0) return null
  return val === 1 ? `${val} day late` : `${val} days late`
}

const getAssignment = (assignment, Company, hasAccess, draggable = true) => {
  const payrollInstanceId = assignment.extraData.payrollInstance
  const companyId = assignment.extraData.company
  const { isPayrollInstanceFrozen } = assignment.extraData.newLockUnlock

  // Transform task link value to URI
  let finishTaskLink = assignment.finishTaskLink

  const linkMapping = finishTaskLink
    ? _.find(completionURIs, { value: finishTaskLink })
    : null
  const hasAccessToUri =
    linkMapping && hasAccess(linkMapping.requiredPermissions)

  finishTaskLink =
    linkMapping && hasAccessToUri
      ? _.includes(finishTaskLink, 'companies')
        ? getCompiledUri(linkMapping.uri, companyId)
        : getCompiledUri(linkMapping.uri, payrollInstanceId)
      : null

  const company = assignment.company
    ? Company.withId(assignment.company)
    : null

  return {
    ...assignment,
    id: assignment.id.toString(),
    ownerInitials:
      assignment.owner !== 'Payslip'
        ? getInitials(assignment.owner)
        : assignment.owner,
    epochDeadline: assignment.deadline ? moment(assignment.deadline.date).unix() : null,
    deadline: assignment.deadline
      ? 'DD ' + moment(assignment.deadline.date).format('DD/MM/YYYY')
      : null,
    completionDate: assignment.completionDate
      ? 'CD ' + moment(assignment.completionDate.date).format('DD/MM/YYYY')
      : null,
    flag: company ? company.country.abbreviature.toLowerCase() : null,
    company: company ? company.name : null,
    country: company ? company.country.name : null,
    metadata: { testmeta: assignment.name },
    finishTaskLink,
    draggable: (assignment.isEditable && draggable) && !isPayrollInstanceFrozen,
    overdueDays: showOverdueDays(assignment.overdueDays),
    isPayrollInstanceFrozen: isPayrollInstanceFrozen
  }
}
