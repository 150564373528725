import { getSubdomainBrandConfig } from 'components/BrandConfig'
import { getParsedDomain, isPathnameClients } from './query'
import { subdomainEnums } from './enums/subdomainEnums'
import { useSelector } from 'react-redux'
import { tenantTypes } from './enums/tenantTypes'
import { isVendorInvolved } from 'redux/selectors/auth'
import { isNull } from 'lodash'
import { subBrandingEnums } from './enums/subbrandingEnums'

export const getTenantSubdomain = (tenantDomain) => tenantDomain?.subdomain?.split('.')?.[0]

export const getSubBrandingTitle = () => {
  const tenants = useSelector((state) => state.tenants)
  const tenantDomain = getParsedDomain(tenants.url)
  const tenantSubdomain = getTenantSubdomain(tenantDomain)

  const vendorTenant = useSelector((state) => state.vendorTenant)
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const brandsConfig = useSelector((state) => state.config.brands)

  const parsedDomain = getParsedDomain(window.location.hostname)
  const { subdomain } = parsedDomain
  const isCotSubdomain = subdomain === subdomainEnums.COS

  const isCotViewingTenant = tenants.url && isCotSubdomain
  const isCotViewingVendor = tenants.type === tenantTypes.VENDOR && isCotSubdomain
  const isVendorViewingTenant = isVendorUser && !isNull(vendorTenant.url) && isPathnameClients(window.location.pathname)

  let theme = {}

  if ((isCotViewingTenant || isCotViewingVendor) && tenantSubdomain) theme = getSubdomainBrandConfig(brandsConfig, tenantSubdomain)

  if (isVendorViewingTenant) theme = getSubdomainBrandConfig(brandsConfig, vendorTenant.schema)

  if (!isCotSubdomain) theme = getSubdomainBrandConfig(brandsConfig, subdomain)

  return subBrandingEnums[theme?.brand]
}

export const getBrandData = () => {
  const tenants = useSelector((state) => state.tenants)
  const tenantDomain = getParsedDomain(tenants.url)
  const tenantSubdomain = getTenantSubdomain(tenantDomain)
  const brandsConfig = useSelector((state) => state.config.brands)

  return {
    data: getSubdomainBrandConfig(brandsConfig, tenantSubdomain),
    isFetching: isNull(brandsConfig),
  }
}
