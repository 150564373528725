import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/additionalItems'

class AdditionalItem extends CRUDModel {}
AdditionalItem.modelName = 'AdditionalItem'

AdditionalItem.fields = {
  id: attr(),
}
AdditionalItem.actionTypes = actionTypes

export default AdditionalItem
