import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'routes/Companies/modules/actions'

class Company extends CRUDModel {
  getDirector () {
    return this.employeeSystemUsers.filter({
      roleType: 'director'
    }).first()
  }

  hasService (name) {
    return this.companyCountryServices.toModelArray()
      .filter(pivot => (
        pivot.countryService.service.name === name
      ))
      .length > 0
  }

  isHQ () {
    return this.relationToHQ === 'hq'
  }
}
Company.modelName = 'Company'
Company.actionTypes = actionTypes

Company.fields = {
  id: attr(),
  name: attr(),
  hq: fk('Company'),
  parentCompany: fk('ParentCompany', 'companies'),
  currency: fk('Currency', 'companies'),
  country: fk('Country', 'companies'),
  reportingCurrency: fk('Currency'),
  countryServices: many({
    to: 'CountryService',
    relatedName: 'companies',
    through: 'CompanyCountryService',
    throughFields: ['company', 'countryService']
  }),
}

export default Company
