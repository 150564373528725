import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { buttonActions, placeholders } from 'utils/locales/common.en'
import { hasAllRequiredFields, reportDesignerSchemaFields } from './fields'
import { getMaxDate, isLessThanAMonth } from 'utils/fnkit/dates/ranges'

/**
 * Generates the UI schema for the report designer form.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.formId - The ID of the form.
 * @param {Object} params.formData - The data of the form.
 * @returns {Object} The UI schema for the report designer form.
 */
export const getReportDesignerUISchema = ({ formId, formData, reportingOptionsData, isFetchingDependentFields }) => {
  const itemClassNames = 'ps-w-[270] schema-form--label'
  const hasCompany = !isEmpty(formData?.company)
  const isMulti = isLessThanAMonth(formData)
  const dateOptions = {
    'ui:classNames': itemClassNames,
    'ui:options': {
      isClearable: true,
      dateFormat: dateFormatEnums.DefaultDateFormatWithoutTime,
      valueFormat: dateFormatEnums.AcceptedByBackend,
    },
  }

  const globalUISchema = {
    'ui:rootFieldId': formId,
    'ui:classNames': 'schema-form--grid',
    'ui:submitButtonOptions': {
      label: buttonActions.run,
      className: 'c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small',
      containerClassName: 'd-flex ai-center jc--center',
      buttonProps: {
        disabled: !hasAllRequiredFields(formData),
      },
    },
  }
  const propertyUISchemaOverrides = {
    [reportDesignerSchemaFields.reportName]: {
      'ui:options': {
        disabled: true,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.fromDate]: dateOptions,
    [reportDesignerSchemaFields.toDate]: {
      ...dateOptions,
      'ui:options': {
        ...dateOptions['ui:options'],
        maxDate: formData?.fromDate ? getMaxDate(formData.fromDate) : undefined,
      },
    },
    [reportDesignerSchemaFields.country]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: isMulti,
        clearable: true,
        abbreviatures: reportingOptionsData?.countries?.map((c) => ({ value: c.id, abbreviature: c.abbreviature })),
        disabled: isEmpty(formData?.fromDate) || isEmpty(formData?.toDate),
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.company]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: isMulti,
        clearable: true,
        disabled: isEmpty(formData?.country),
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.payroll]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: !hasCompany,
        placeholder: isFetchingDependentFields[reportDesignerSchemaFields.payroll] ? placeholders.loading : undefined,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.payrun]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: isEmpty(formData?.payroll),
        placeholder: isFetchingDependentFields[reportDesignerSchemaFields.payrun] ? placeholders.loading : undefined,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.typeOfEmploymentEngagement]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.businessUnit]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: !hasCompany,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.costCenter]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: !hasCompany,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.department]: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: !hasCompany,
      },
      'ui:classNames': itemClassNames,
    },
    [reportDesignerSchemaFields.categories]: {
      'ui:widget': 'DualSelectorWidget',
      'ui:classNames': 'ps-w-[270] schema-form--gr-4',
      'ui:options': {
        multi: true,
        clearable: true,
        disabled: !hasCompany,
      },
    },
  }

  return {
    ...globalUISchema,
    ...propertyUISchemaOverrides,
  }
}
