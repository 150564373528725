import React from 'react'
import NavBarProfileItem from './NavBarProfileItem'
import PropTypes from 'prop-types'
import ReactAvatar from 'components/ReactAvatar'
import { HeaderIcon, HEADER_ICONS_TYPES } from 'components/header/HeaderIcons'
import { navBarProfileTitles } from 'utils/locales/navBarProfile.en'

class NavBarProfile extends React.Component {
  renderItems (props) {
    const items = [
      { title: navBarProfileTitles.settings, name: 'settings', path: '/settings' },
      { title: navBarProfileTitles.privacySecurity, name: 'privacy', path: '/privacysecurity/privacy' },
      { title: navBarProfileTitles.logOut, name: 'logout' },
    ]

    return items.map((item) => {
      return <NavBarProfileItem key={item.name} item={item} {...props} />
    })
  }

  render () {
    const { user, showCompany } = this.props

    return (
      <div className='c-nav-bar u-margin-left-small c-nav-bar-profile'>
        <div className='o-media o-media--small o-grid o-grid--middle'>
          <div className='o-media__img o-media__img--circle c-nav-bar-profile--avatar'>
            <ReactAvatar firstName={user.firstname} lastName={user.surname} size={35} className='c-avatar-override' />
          </div>
          <HeaderIcon type={HEADER_ICONS_TYPES.polygon} />
        </div>

        {/* Nav bar profile dropdown - START */}
        <div className='c-nav-bar__dropdown c-nav-bar__dropdown--fixed-width-medium'>
          <ul className='c-nav u-margin-left-none u-margin-bottom-none'>
            <li className='o-list-bare u-hidden u-show-on-desktop'>
              <div className='o-block o-block--left u-padding-small'>
                <div className='u-text--normal u-weight--bold'>
                  {user.firstname} {user.surname}
                </div>
                <div className='u-text--small'>
                  {user.position ? user.position + ' / ' : null}
                  {showCompany ? user.company.name : null}
                </div>
              </div>
            </li>
            {this.renderItems(this.props)}
          </ul>
        </div>
        {/* Nav bar profile dropdown - END */}
      </div>
    )
  }
}

NavBarProfile.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
  showCompany: PropTypes.bool,
}

export default NavBarProfile
