import Keycloak from 'keycloak-js'
import { keycloakUrl } from 'utils/config'

const getKeycloak = (realm, clientId) => {
  return new Keycloak({
    url: keycloakUrl,
    realm: realm,
    clientId: `${clientId}-payslip`,
    pkceMethod: 'S256',
  })
}

export default getKeycloak
