export const jobStatusEnum = {
  NOT_STARTED: 'not-started',
  COMPLETED: 'completed',
  FAILED: 'failed',
}
// BE Changed they procedure about the params
// But don't want to mix datatypes in the enum
// So creating the second instance here
export const jobStatusFilterEnum = {
  NOT_READ: 0,
  READ: 1,
}
