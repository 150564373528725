import _ from 'lodash'
import createSelectorORM from 'utils/createSelector'
import { createSelector } from 'reselect'
import { getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds } from 'redux/selectors/filters'
import { sortByName } from 'utils/strings'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'

export const getCompanyId = (state, props) => parseInt(props.companyId)
export const getUserId = (state, props) => parseInt(props.userId)

// get all departments
export const getAllDepartments = createSelectorORM(({ Departments }) => Departments.all().toRefArray())

// get departments by company id
export const getDepartmentsByCompany = createSelectorORM(getCompanyId, ({ Departments }, company) => Departments.filter({ company }).toRefArray())

// get all departments initial values
export const getAllDepartmentsInitialValues = createSelector(getAllDepartments, (departments) =>
  departments.reduce((initialValues, department) => {
    const id = `id-${department.id}`
    initialValues[id] = department.name

    return initialValues
  }, {})
)

// get company departments initial values

export const getCompanyDepartmentsInitialValues = createSelector(getDepartmentsByCompany, (departments) =>
  departments.reduce((initialValues, department) => {
    const id = `id-${department.id}`
    initialValues[id] = department.name

    return initialValues
  }, {})
)

export const getDepartmentsWithCompanyWithCountry = createSelectorORM(({ Departments }) => {
  return Departments.all()
    .toModelArray()
    .map((unit) => ({
      ...unit.ref,
      company: { ...unit.company.ref },
      country: { ...unit.company.country.ref },
    }))
})

export const getDepartmentsByCountriesThroughCompany = createSelectorORM(getCompaniesByCountries, ({ Company }, companies) => {
  const departments = companies.map((companyRef) => {
    const company = Company.withId(companyRef.id)
    return company.departments
      .toModelArray()
      .map((department) => ({
        ...department.ref,
      }))
      .reverse()
  })

  return _.flatten(departments)
})

export const getDepartmentsByCompanies = createSelectorORM(getCompaniesIds, ({ Departments }, companiesIds) => {
  return Departments.filter((department) => companiesIds.includes(department.company))
    .toRefArray()
    .map((department) => ({ ...department }))
})

export const getDepartments = createSelectorORM(({ Departments }) => {
  return Departments.all()
    .toRefArray()
    .map((department) => ({ ...department }))
})

export const getDeparmentsBasedOnCompanyType = ({ state, isHQ, companiesIds }) => {
  const hasCompanies = isArray(companiesIds) && !isEmpty(companiesIds)
  /**
   * In case we have an array of companies, we need all
   * the deparments; otherwise, we need to check if it is a
   *  single company HQ and call the appropriate method.
   */
  const companies = hasCompanies ? getDepartmentsByCompanies(state, { companiesIds }).sort(sortByName) : null

  if (companies) {
    return companies
  }

  return isHQ ? getDepartmentsWithCompanyWithCountry(state).sort(sortByName) : getDepartmentsByCompany(state, { companyId: companiesIds }).sort(sortByName)
}
