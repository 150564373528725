import React from 'react'

import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton, RejectButton } from 'components/buttons'
import { reusableModalHeadings, reusableModalTexts } from 'utils/locales/modals.en'

export const DeleteConfirmationModal = ({ modalRef, confirmButtonText, rejectButtonText, deleteModalText, onConfirmation, onReject, hideReject }) => {
  const handleReject = () => {
    onReject?.()
    modalRef?.current?.hideModal()
  }
  return (
    <BaseConfirmationModal ref={modalRef} className='c-modal' modalHeading={reusableModalHeadings.confirmation}>
      {deleteModalText || <p>{reusableModalTexts.deleteConfirmation}</p>}
      <ConfirmButton buttonText={confirmButtonText} onClick={onConfirmation} />
      {!hideReject && <RejectButton buttonText={rejectButtonText} onClick={handleReject} />}
    </BaseConfirmationModal>
  )
}
