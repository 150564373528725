import React from 'react'
import PropTypes from 'prop-types'

export const HeadQuarter = ({ tenantName, logo }) => {
  return <div className='o-block o-block--center o-block--inline-block u-bg--white u-padding u-margin-bottom-large'>
    <h2 className='u-text--giant u-text--minsk u-weight--bold u-margin-none' data-testid={`hq-${tenantName}`}>
      { logo ? <img src={logo} style={{ width: 150 }} /> : tenantName }
    </h2>
  </div>
}

HeadQuarter.propTypes = {
  tenantName: PropTypes.string,
  logo: PropTypes.string,
}

export default HeadQuarter
