import React from 'react'
import EventsFilters from 'components/EventsFilters'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { filterEvents } from 'redux/actions/events'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import moment from 'moment'

const addedOptionsToOwnersSelect = [
  { value: 'Payslip', label: 'Payslip' },
  { value: 'Payroll Partner', label: 'Payroll Partner' }
]

class CalendarFiltersContainer extends React.Component {
  componentDidMount () {
    const { shouldFilterInitially, handleSubmit } = this.props

    if (shouldFilterInitially) handleSubmit()
  }

  render () {
    return <div className='c-calendar__filters'>
      <EventsFilters shouldDisplay addedOptionsToOwnersSelect={addedOptionsToOwnersSelect} {...this.props} />
    </div>
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, { initialValues }) => {
  return {
    onSubmit: ({ payrollsAndProcesses, ...rest }) => {
      let filters = rest
      if (initialValues.owner && !rest['owner'] && isEmpty(rest)) {
        rest['owner'] = initialValues.owner
      }
      const fromDate = rest.fromDate
        ? moment(rest.fromDate).format('YYYY-MM-DD')
        : moment(initialValues.fromDate).format('YYYY-MM-DD')
      const toDate = rest.toDate
        ? moment(rest.toDate).format('YYYY-MM-DD')
        : moment(initialValues.toDate).format('YYYY-MM-DD')
      rest.fromDate = fromDate
      rest.toDate = toDate
      if (filters.owner === 'Payslip' || filters.owner === 'Payroll Partner') {
        let selectedGlobalOwner = filters.owner
        filters.globalOwner = selectedGlobalOwner
        delete filters.owner
      }
      dispatch(filterEvents(rest))
    },
    dispatch,
  }
}

CalendarFiltersContainer.propTypes = {
  shouldFilterInitially: PropTypes.bool,
  handleSubmit: PropTypes.func,
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'filterTasks',
  })(CalendarFiltersContainer)
)
