export const errorMessageLabels = {
  somethingWentWrong: 'Something went wrong.',
}

export const formErrorLabels = {
  isRequired: 'This value should not be blank.',
  requiredOption: 'Should select at least one option.',
}

export const consoleTextErrors = {
  onTransformData: 'Error during the data transformation....',
  onImageGeneration: 'Error during the image generation...',
  onReportingGeneration: 'Error during the report generation....',
}

export const vendorUserErrors = {
  cannotBeDeleted: 'Vendor user could not be deleted',
}
