import Fetcher from '../Fetcher'
import { connect } from 'react-redux'
import { getCompanyWithCountry } from 'redux/selectors/company'
import CompanyHeader from 'components/header/CompanyHeader'
import { getParentCompanyById } from '../../redux/selectors/parentCompanies'

const mapStateToProps = (state, { companyId, isCot, vendorTenantUrl }) => {
  const company = getCompanyWithCountry(state, { companyId })

  return {
    company,
    ...isCot && vendorTenantUrl && {
      tenant: getParentCompanyById(state, { parentCompanyId: company.parentCompany }).name
    }
  }
}

const Container = connect(mapStateToProps)(CompanyHeader)

export default Fetcher(Container, [
  'countries',
  'parentCompanies',
  {
    name: 'companies',
    params: [{
      _computed: { forceVendorTenantAPI: (state, { vendorTenantUrl }) => !!vendorTenantUrl }
    }],
  },
])
