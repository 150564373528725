import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/processes'

class Process extends CRUDModel {}
Process.modelName = 'Process'
Process.actionTypes = actionTypes

Process.fields = {
  id: attr(),
  service: fk('Service', 'processes')
}

export default Process
