import React from 'react'
import VendorsContainer from '../containers/VendorsContainer'
import InnerWrapper from 'layouts/InnerWrapper'
import { useDispatch } from 'react-redux'
import { setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { closeAllToastMessages } from 'redux/actions/toasts'

export const VendorsView = props => {
  const dispatch = useDispatch()
  dispatch(setShouldPoll({ enablePolling: false }))
  dispatch(setStartPolling({ shouldStartPolling: false }))
  dispatch(closeAllToastMessages())
  return <InnerWrapper>
    <VendorsContainer {...props} />
  </InnerWrapper>
}

export default VendorsView
