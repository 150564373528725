import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { startOf, add } from 'react-big-calendar/lib/utils/dates'
import { getNumberOfWeeksInMonth } from 'utils/date'
import { weekWithShortNameDays, getMonthNameByIndex } from 'utils/enums/dateEnums'
import { calendarEventsStepEnum, calendarEventsTitlesEnum } from 'utils/enums/calendarEvents'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { checkIfGtnCutOffDay } from 'utils/events/Events'

function createCalendar (givenDate = moment()) {
  moment.locale('en-GB')
  givenDate = moment(givenDate)

  const first = givenDate.clone().startOf('month')
  const last = givenDate.clone().endOf('month')

  const weeksCount = getNumberOfWeeksInMonth(givenDate.year(), givenDate.month())
  const calendar = Object.assign([], { givenDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = givenDate.year()
    calendar.month = givenDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      // Offset calculation makes sure days are correctly displayed when weeks start on Monday
      const dayOffset = first.day() === 0 ? 5 : -2

      const date = givenDate.clone().set('date', day - first.day() - dayOffset)
      date.calendar = calendar
      week.push(date)
    }
  }
  return calendar
}

function CalendarDate (props) {
  const { dateToRender, dateOfMonth, holidayList = [], events = [], payrollInstances = [] } = props

  const holiday = holidayList.find(day => moment(day.date.date).format('YYYY-MM-DD') === dateToRender.format('YYYY-MM-DD'))
  const eventsToday = events.filter(event => moment(event.end).format('YYYY-MM-DD') === dateToRender.format('YYYY-MM-DD'))
  const currentPayrollInstance = eventsToday.find(e => e.payrollInstance)?.payrollInstance
  const currentPayrollInstanceEvents = events.filter(e => e.payrollInstance === currentPayrollInstance)

  const { INPUTS_CUTOFF } = calendarEventsStepEnum
  const isInputsCutOff = eventsToday.find(event => event.title === INPUTS_CUTOFF)

  const isGTNCutOffDay = checkIfGtnCutOffDay(eventsToday, currentPayrollInstanceEvents)
  const isPayDate = payrollInstances.find(pi => moment(pi.payDate.date).format('YYYY-MM-DD') === dateToRender.format('YYYY-MM-DD'))

  const isInputsAndPayDate = isInputsCutOff && isPayDate
  const isInputsAndGTN = isInputsCutOff && isGTNCutOffDay
  const isPayDateAndGTN = isPayDate && isGTNCutOffDay

  const isAllEventsInOneDay = isInputsCutOff && isInputsAndGTN && isPayDateAndGTN

  const classes = classNames({
    'calendar-yearly-holiday-calendar-date': holiday,
    'calendar-yearly-special-date': isInputsCutOff || isPayDate || isGTNCutOffDay,
    'calendar-yearly-inputs-calendar-date': isInputsCutOff,
    'calendar-yearly-payday-calendar-date': isPayDate,
    'calendar-yearly-gtn-calendar-date': isGTNCutOffDay,
    'calendar-yearly-inputspayday-calendar-date': isInputsAndPayDate,
    'calendar-yearly-inputsgtn-calendar-date': isInputsAndGTN,
    'calendar-yearly-paydaygtn-calendar-date': isPayDateAndGTN,
    'calendar-yearly-all-calendar-date': isAllEventsInOneDay,
  })

  let specialDayHover = []

  if (holiday) specialDayHover.push(holiday.name)
  if (isInputsCutOff) specialDayHover.push(calendarEventsTitlesEnum.INPUTS_CUTOFF)
  if (isPayDate) specialDayHover.push(calendarEventsTitlesEnum.PAY_DATE)
  if (isGTNCutOffDay) specialDayHover.push(calendarEventsTitlesEnum.GTN_CUTOFF)

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <span disabled className='calendar-yearly-date calendar-yearly-date--inactive-month'>
        {dateToRender.date()}
      </span>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <span disabled className='calendar-yearly-date calendar-yearly-date--inactive-month'>
        {dateToRender.date()}
      </span>
    )
  }

  return (
    <div className={`calendar-yearly-date in-month`}>
      <div className={classes}>
        <div className='circle-border' />
        <div className='hover-box calendar-yearly-date-hover-box'>
          {specialDayHover.map(day => <p key={day}>{day}</p>)}
        </div>
        {dateToRender.date()}
      </div>
    </div>
  )
}

CalendarDate.propTypes = {
  dateOfMonth: PropTypes.object,
  dateToRender: PropTypes.object,
  events: PropTypes.array,
  holidayList: PropTypes.array,
  payrollInstances: PropTypes.array
}

const Calendar = (props) => {
  const [calendar, setCalendar] = useState(null)

  useEffect(() => {
    setCalendar(createCalendar(props.date))
  }, [props.date])

  if (!calendar) return null

  return (
    <div className='calendar-yearly-month'>
      <div className='calendar-yearly-month--name'>
        {getMonthNameByIndex(calendar.givenDate.month() + 1)}
      </div>
      <div className='calendar-yearly-flex u-margin-bottom-small'>
        {weekWithShortNameDays.map((day, index) => (
          <span key={index} className='calendar-yearly-day'>
            {day}
          </span>
        ))}
      </div>
      {calendar.map((week, index) => (
        <div key={index} className='calendar-yearly-flex'>
          {week.map(date => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={calendar.givenDate}
              events={props.events}
              payrollInstances={props.payrollInstances}
              {...props}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

Calendar.propTypes = {
  date: PropTypes.object,
  events: PropTypes.array,
  payrollInstances: PropTypes.array
}

const Year = (props) => {
  let { date, holidays, events, payrollInstances } = props
  const months = []
  const firstMonth = startOf(date, 'year')

  for (let i = 0; i < 12; i++) {
    months.push(
      <Calendar
        key={i + 1}
        date={add(firstMonth, i, 'month')}
        holidayList={holidays}
        events={events}
        payrollInstances={payrollInstances}
      />
    )
  }
  return (
    <div className='o-grid calendar-yearly u-margin-bottom' data-testid='yearly-calendar'>{months.map(month => month)}</div>
  )
}

Year.propTypes = {
  date: PropTypes.object,
  events: PropTypes.array,
  holidays: PropTypes.array,
  payrollInstances: PropTypes.array
}

export default Year
