import React from 'react'
import { Authorization } from 'containers/Authorization'

export const formatTableData = ({ data, showActions, onEdit }) =>
  data.map((employee) => {
    const { title, firstname, surname, hasCredentials, position, location, roleTypeName, accessAreas, isAccessable, id } = employee
    return {
      ...employee,
      name: `${title || ''} ${firstname} ${surname}`,
      status: hasCredentials,
      position: position,
      base: location,
      role: roleTypeName,
      accessArea: accessAreas ? accessAreas.join(', ') : null,
      action:
        isAccessable && showActions ? (
          <div>
            <Authorization permissions={['EMPLOYEEUSER_EDIT']}>
              <button onClick={() => onEdit(id)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'>
                <span className='icon icon--edit' data-testid={`${id}-edit`} title='Edit' />
              </button>
            </Authorization>
            <Authorization permissions={['EMPLOYEEUSER_NORMAL_EDIT']}>
              <button onClick={() => onEdit(id)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'>
                <span className='icon icon--view' title='View' />
              </button>
            </Authorization>
          </div>
        ) : null,
    }
  })
