import React, { useState } from 'react'

const DropdownButton = ({ title, children, isDisabled }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div>
      <button
        className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right-small d-flex ai-center
        ${isDropdownOpen ? 'c-btn--curious-active' : ''}
        ${isDisabled ? 'disabled' : ''}`}
        onClick={handleButtonClick}
        title={title}
        disabled={isDisabled}
      >
        <span className='u-margin-right-small'>{title}</span>
        <span className='icon--arrow icon--arrow--white' />
      </button>
      {isDropdownOpen && <div className='dropdown-button-drawer shadow rounded border'>{children}</div>}
    </div>
  )
}

export default DropdownButton
