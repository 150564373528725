import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadTemplateUpdate, importPayTaxesUpdate, download } from 'redux/actions/companyCountryTermPivot'
import { useSelector } from 'react-redux'
import { getCompanyRef } from 'redux/selectors/company'
import Fetcher from 'containers/Fetcher'
import { elementsBulkLabels } from 'utils/locales/elementsBulk.en'

const TemplateClarifications = ({ isHQ = false }) => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading text='Update elements in bulk' />
    <p className='u-margin-bottom-small'>This template allows you to update multiple elements in bulk. Follow the simple process described below:</p>

    <ol className='u-margin-bottom-small'>
      <li>Download template</li>
      <li data-testid='pay-taxes-header-details'>
        {`${isHQ ? 'Country, Company,' : ''} Global Name, Category + Subcategory are not editable - informational purposes only`}
      </li>
      <li>Complete data</li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
    </ol>
  </div>
)

const Container = (props) => {
  const company = useSelector((state) => getCompanyRef(state, { companyId: props.match.params.companyId }))
  return (
    <ImportFacade
      {...props}
      heading='Update elements in bulk'
      entityName='elements'
      downloadAction={download}
      downloadTemplateAction={() => downloadTemplateUpdate(props.match?.params?.companyId)}
      importAction={({ file }) => importPayTaxesUpdate(file, props.match?.params?.companyId)}
      TemplateClarifications={() => {
        return <TemplateClarifications isHQ={company.isHQ} />
      }}
      hasFilters={false}
    >
      <div className='o-layout'>
        <div className='o-layout__item u-1/2 u-text--normal'>
          <SectionHeading text='Data Validations' />
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>GL Expense/GL Liability</span> {elementsBulkLabels.gl}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Disbursable</span> {elementsBulkLabels.disbursable}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Fixed/Variable</span> {elementsBulkLabels.fixedVariable}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Editable By</span> {elementsBulkLabels.editableBy}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Net Value</span> {elementsBulkLabels.netValue}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Recurring</span> {elementsBulkLabels.recurring}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Data Format</span> {elementsBulkLabels.dataFormat}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Taxable</span> {elementsBulkLabels.taxable}
          </p>
          <p className='u-margin-bottom-tiny'>
            <span className='u-weight--bold'>Tax Only</span> {elementsBulkLabels.taxOnly}
          </p>
        </div>
      </div>
    </ImportFacade>
  )
}

Container.propTypes = {
  match: PropTypes.object,
}

export default Fetcher(Container, ['companies'])
