import InnerWrapper from 'layouts/InnerWrapper'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import InnerWrapperContainer from '../../containers/InnerWrapperContainer'
import ActiveRoute from './routes/Active'
import ExtraRoute from './routes/Extra'
import PreviousRoute from './routes/Previous'
import InactiveRoute from './routes/Inactive'
import DocumentsRoute from '../Documents/index'

export default (store) => ({
  path : 'payruns',
  indexRoute: { component: InnerWrapperContainer, wrappedComponent: InnerWrapper },
  wrappedComponent: CoreLayout,
  authProtected: true,
  tabbedLayout: true,
  childRoutes: [ ActiveRoute(store), ExtraRoute(store), PreviousRoute(store), InactiveRoute(store), DocumentsRoute(store) ]
})
