import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getCodeByToken } from 'redux/actions/auth'
import UserActivationForm from '../components/UserActivationForm'
import { showMessage } from 'redux/actions/modal'
import strings from 'strings'
import { isCotDomain } from 'utils/url'

class ConfirmPhoneNumberContainer extends React.Component {
  render () {
    return <UserActivationForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state, props) => {
  let hostUrlCot = isCotDomain(state.config.apiUrl)
  let senderService = hostUrlCot ? state.config.senderServiceCot : state.config.senderService
  return {
    state,
    label: 'Please confirm your number ends on:',
    buttonText: 'Confirm',
    colorClassBtn: 'c-btn--curious u-margin-top-none',
    isDisabled: true,
    senderService,
    initialValues: {
      confirmationToken: props.match.params.token,
      mobilePhone: state.auth.mobilePhone.phone,
      email: state.auth.email.email,
    }
  }
}
const getModalMessage = (senderService) => {
  return strings.authentication.twoFAWithAuth[senderService] ?? ''
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { senderService } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (data) => dispatch(getCodeByToken(data))
      .then(() => {
        ownProps.history.push(`/reset-password-confirmation/${ownProps.match.params.token}`)
      })
      .then(() => {
        dispatch(showMessage({
          body: getModalMessage(senderService),
        }))
      })
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'authCodeSend',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(ConfirmPhoneNumberContainer))
