import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import PayrunContainer from 'routes/Payruns/routes/Instance/containers/PayrunContainer'
import { downloadPayFile, filterPayfiles } from 'redux/actions/payfiles'
import { getPayfileInitials, getPayfile } from 'routes/Payruns/routes/Instance/selectors/payfile'
import { showMessage } from 'redux/actions/modal'
import useQuery from 'hooks/useQuery'
import { createNewPayfileExportJob, createNewPayfileImportJob } from 'redux/actions/pollingJob'

export default props => {
  const rest = {
    entityName: 'payfiles',
    filterAction: filterPayfiles,
    downloadAction: createNewPayfileExportJob,
    importAction: createNewPayfileImportJob,
    downloadDirectly: downloadPayFile,
    getEntitySelector: getPayfile,
    getEntityInitialsSelector: getPayfileInitials,
    lastSavedChanges: false,
    getLastSavedChanges: () => {
      return rest['lastSavedChanges']
    },
    setLastSavedChanges: (isSaved) => {
      rest['lastSavedChanges'] = isSaved
    },
    onSuccessfulUpload: ({ dispatch }) => dispatch(showMessage({
      body: <span>File successfully uploaded!<br />Please refresh to see all changes updated!</span>,
    })),
    onSuccessfulSubmit: ({ dispatch, changedFields, formName }) => {
      dispatch(showMessage({
        body: 'You can see all changes after refresh.',
      }))
    }
  }

  return <InnerWrapper>
    <PayrunContainer query={useQuery()} {...props} {...rest} />
  </InnerWrapper>
}
