import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const REFERRALS_FETCH = 'REFERRALS_FETCH'
export const REFERRALS_RECEIVE = 'REFERRALS_RECEIVE'
export const REFERRAL_CREATE = 'REFERRAL_CREATE'
export const REFERRAL_UPDATE = 'REFERRAL_UPDATE'
export const REFERRAL_DELETE = 'REFERRAL_DELETE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: REFERRALS_FETCH,
  receive: REFERRALS_RECEIVE,
  create: REFERRAL_CREATE,
  update: REFERRAL_UPDATE,
  delete: REFERRAL_DELETE,
}
const actionUtility = new ActionUtility(actionTypes, 'referrals', 'referrals', 'Referral')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchReferralsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchReferrals = actionUtility.fetchEntities
export const createReferral = actionUtility.createEntity
export const updateReferral = actionUtility.updateEntity
export const deleteReferral = actionUtility.deleteEntity
