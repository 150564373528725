import { createReducer } from 'utils/redux'
import { TERMS_FETCH, TERMS_RECEIVE, TERM_CREATE } from 'routes/Terms/modules/actions'

const actionTypes = {
  fetch: TERMS_FETCH,
  receive: TERMS_RECEIVE,
  create: TERM_CREATE
}

export default createReducer(actionTypes)
