import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import PayslipTable from 'components/table/PayslipTable'
class ShareVendorColleagues extends React.Component {
  buildHeadings () {
    const shareText = this.props.sharingActionText ? this.props.sharingActionText : 'Share'
    return [
      { accessor: 'fullname', Header: 'Name', disableSortBy: true },
      {
        accessor: 'share',
        Header: shareText,
        setWidth: '100px',
        classNames:'text-center',
        columnClassName: 'u-text--center u-padding-right-small',
        disableSortBy: true
      }
    ]
  }

  render () {
    const { colleaguesUsers, initialValues, isOwner } = this.props
    const options = {
      searchPosition: 'left',
      noDataText: 'No matching records found.',
    }

    return <div className='position-relative'>
      <PayslipTable
        data={colleaguesUsers.map(user => ({
          ...user,
          share: <Field
            type='checkbox'
            labelClassName='u-hidden'
            component={CheckboxField}
            className='control__indicator__input'
            formGroupClassName='u-margin-none'
            name={`${user.vendor.schema}-${user.id}`}
            indicatorClassName='control__indicator--center'
            disabled={initialValues[`${user.vendor.schema}-${user.id}`] && !isOwner}
          />
        }))}
        headings={this.buildHeadings()}
        wrapperClassName='u-1/1 u-margin-bottom'
        modifierClasses='u-margin-top-small'
        options={options}
        search
        trClassName='react-bs-table__cell--whitespace--normal'
      />
    </div>
  }
}

ShareVendorColleagues.propTypes = {
  colleaguesUsers: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string
}

export default ShareVendorColleagues
