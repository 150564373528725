import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/bankAccounts'

class BankAccount extends CRUDModel {}
BankAccount.modelName = 'BankAccount'
BankAccount.actionTypes = actionTypes

BankAccount.fields = {
  id: attr(),
  bankCountry: fk('Country', 'bankAccount'),
  company: fk('Company', 'bankAccount'),
  currency: fk('Currency', 'bankAccount'),
  type: fk('BankAccountType', 'bankAccount'),
}

export default BankAccount
