import React, { useState, useEffect } from 'react'
import { createFilter } from 'utils/redux/filter'
import { useDispatch, useSelector } from 'react-redux'
import ElementReconciliationView from '../components/ElementReconciliationView'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import Loader from 'components/Loader'
import { fetchVariance } from 'redux/actions/payfiles'
import { startToastFileGeneration } from 'redux/actions/toasts'
import { createNewElementVarianceExportJob, createNewEmployeeVarianceExportJob } from 'redux/actions/pollingJob'
import { toast } from 'react-toastify'
import ToastLoadingViews from 'components/ToastLoadingViews'
import { getBreadcrumbFiltersByKey } from 'redux/selectors/breadcrumbs'
import { ALL_ROUTES } from 'configs/routes'
import moment from 'moment'
import { getQuery } from 'utils/query'
import { getTitleAndVersionData, formatCell, getTermName, generateEmptyCellPlaceHolder } from 'routes/Payruns/utils/versioning'
import { getNonCompletedElementVarianceJobs } from 'redux/selectors/pollingJobs'
import { elementReconciliationHeadings, payrollReconciliationHeadings } from 'utils/locales/reconciliation.en'
import { sortBySurnameThenFirstName } from 'utils/strings'
import { CellDefaultNumberFormat } from 'utils/tableDataFormatters'
import { useVarianceTerm } from 'hooks/useVarianceTerm'
import { isEmpty } from 'utils/fnkit/typeChecks'

const ElementReconciliationContainer = (props) => {
  const dispatch = useDispatch()

  const [initialLoading, setInitialLoading] = useState(false)
  const [selectedElement, setSelectedElement] = useState(parseInt(getQuery(location.search)?.element, 10) || getQuery(location.search)?.element)
  const [needsRefetch, setNeedsRefetch] = useState(false)
  const [varianceData, setVarianceData] = useState()
  const [tableData, setTableData] = useState([])
  const [totalsData, setTotalsData] = useState({})
  const inProgressPollingJobs = useSelector((state) => getNonCompletedElementVarianceJobs(state))

  const [togglesState, setToggleStates] = useState({
    onlyVariance: true,
    excludeZeroes: true,
  })
  const onlyVariance = togglesState.onlyVariance
  const excludeZeroes = togglesState.excludeZeroes

  let versionLeft
  let versionRight
  const payrollInstanceId = parseInt(props.match.params.id, 10)

  const isLoadingJob = useSelector((state) => {
    const filterLoading = createFilter({ id: payrollInstanceId, loading: 'true' })
    const filterCompleted = createFilter({ id: payrollInstanceId, completed: 'true' })
    return !!(state.payrollInstances.filters[filterLoading.name] && !state.payrollInstances.filters[filterCompleted.name])
  })

  const { versionData, title, isFetching } = getTitleAndVersionData(payrollInstanceId)

  const preselectedFilter = useSelector((state) => {
    const breadcrumbKey = `/${ALL_ROUTES.PAYRUNS.BASE}/${payrollInstanceId}/${ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION}`
    return getBreadcrumbFiltersByKey(state, { breadcrumbKey })
  })

  // sets versions
  if (versionData.length > 1) {
    ;[versionRight, versionLeft] = versionData
    if (preselectedFilter) {
      versionLeft = versionData.find((v) => v.id === preselectedFilter.params.versionOne)
      versionRight = versionData.find((v) => v.id === preselectedFilter.params.versionTwo)
    }
  }
  const { termVarianceData, termTableOptions } = useVarianceTerm({
    payrollInstanceId,
    versionLeft,
    versionRight,
    selectedElement,
    excludeZeroes,
    onlyVariance,
    needsRefetch,
  })
  const noData = versionData.length < 2 && isEmpty(varianceData) && isEmpty(termVarianceData)

  // populate element dropdown
  let elementOptions = []
  if (varianceData && varianceData.termData) {
    Object.values(varianceData.termData).forEach((element) => {
      const termName = getTermName({
        companyLocalName: element.companyLocalName,
        countryLocalName: element.countryLocalName,
        globalName: element.globalName,
      })
      elementOptions.push({
        value: element.id,
        label: termName,
      })
    })
    Object.keys(varianceData.informationalElementsData).forEach((element) => {
      elementOptions.push({
        value: element,
        label: varianceData.informationalElementsData[element].name,
      })
    })
  }

  const downloadReport = (entity, reportType) => {
    const cb = reportType === 'element' ? createNewElementVarianceExportJob : createNewEmployeeVarianceExportJob

    return dispatch((dispatch, getState) => {
      return dispatch(cb(entity, {}, { holdTillComplete: true })).then((job) => {
        dispatch(startToastFileGeneration(job.id))
        toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, autoClose: false, closeOnClick: false })
      })
    })
  }

  const prepareDataForView = () => {
    let remappedTableData = []
    let remappedTotals = {}
    const [termName] = Object.keys(termVarianceData.termData)
    const [informationalElementName] = Object.keys(termVarianceData.informationalElementsData)

    const elementName = termName || informationalElementName

    Object.values(termVarianceData.body).forEach((e) => {
      remappedTableData.push({
        employeeId: e.employeeId,
        firstName: e.firstName,
        surname: e.surname,
        previous: e[elementName].previous,
        v1: e[elementName].v1,
        v2: e[elementName].v2,
        variance: e[elementName].variance,
        variance_p: e[elementName]['variance_p'],
        result: e[elementName].result,
      })
    })

    // Default sort should be sorted by employee surname, then firstName
    remappedTableData = remappedTableData.sort(sortBySurnameThenFirstName)

    const totals = termVarianceData.totals

    remappedTotals = {
      showOnLastPageOnly: true,
      data: [
        formatCell('Total', 'text-left u-weight--bold'),
        generateEmptyCellPlaceHolder(), // empty cells to account for columns which don't have totals data
        generateEmptyCellPlaceHolder(),
        formatCell(totals.previous, 'text-right u-weight--bold'),
        formatCell(totals.v1, 'text-right u-weight--bold'),
        formatCell(totals.v2, 'text-right u-weight--bold'),
        formatCell(totals.variance, 'text-right u-weight--bold'),
        formatCell(totals.variance_p, 'text-right u-weight--bold'),
        formatCell(totals.result, 'text-center u-weight--bold'),
      ],
    }
    setTableData(remappedTableData)
    setTotalsData(remappedTotals)
  }

  const getHeadingForOption = (option, tableHeading = false) => {
    if (option && tableHeading) return `${option.type} v${option.version}`
    if (option) return `${option.type} v${option.version} ${moment(option.createdAt.date).format('DD/MM/YYYY HH:mm')} ${option.createdAt.timezone}`
    return ''
  }

  const tableHeadings = [
    new TableHeading({
      accessor: 'employeeId',
      heading: elementReconciliationHeadings.employeeId,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'firstName',
      heading: elementReconciliationHeadings.firstName,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'surname',
      heading: elementReconciliationHeadings.lastName,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'previous',
      heading: elementReconciliationHeadings.previous,
      className: 'text-right',
      columnClassName: 'text-right',
      disableSortBy: false,
      Cell: CellDefaultNumberFormat,
    }),
    new TableHeading({
      accessor: 'v1',
      heading: getHeadingForOption(versionLeft, true),
      className: 'text-right',
      columnClassName: 'text-right',
      disableSortBy: !getHeadingForOption(versionLeft),
      Cell: CellDefaultNumberFormat,
    }),
    new TableHeading({
      accessor: 'v2',
      heading: getHeadingForOption(versionRight, true),
      className: 'text-right',
      columnClassName: 'text-right',
      disableSortBy: !getHeadingForOption(versionRight),
      Cell: CellDefaultNumberFormat,
    }),
    new TableHeading({
      accessor: 'variance',
      heading: payrollReconciliationHeadings.variance,
      className: 'text-right',
      columnClassName: 'text-right',
      disableSortBy: false,
      Cell: CellDefaultNumberFormat,
    }),
    new TableHeading({
      accessor: 'variance_p',
      heading: `${payrollReconciliationHeadings.variance} %`,
      className: 'text-right',
      columnClassName: 'text-right',
      disableSortBy: false,
      Cell: CellDefaultNumberFormat,
    }),
    new TableHeading({
      accessor: 'result',
      heading: payrollReconciliationHeadings.result,
      className: 'text-center',
      columnClassName: 'text-center',
      disableSortBy: false,
    }),
  ]

  const onElementSelect = (element) => {
    setSelectedElement(element?.value)
    setNeedsRefetch(true)
  }
  // sets flag that shows if the default options have already loaded for the version selections
  useEffect(() => {
    setInitialLoading(!!(versionLeft && versionRight))
  }, [versionLeft, versionRight])

  // makes initial fetch to populate variance data, needs default selections to be loaded
  useEffect(() => {
    const fetchVarianceData = async () => {
      await dispatch(
        fetchVariance({
          id: payrollInstanceId,
          filter: createFilter({
            versionOne: versionLeft.id,
            versionTwo: versionRight.id,
            offset: 0,
            limit: 1,
          }),
        })
      ).then((res) => setVarianceData(res))
    }

    if (initialLoading && !isEmpty(versionLeft) && !isEmpty(versionRight)) fetchVarianceData()
  }, [initialLoading])

  // handles remapping the data for the tables when there is fresh data or the variance filter is updated
  useEffect(() => {
    if (!noData && termVarianceData) {
      prepareDataForView()
    }
  }, [termVarianceData, onlyVariance])

  /*
   * Actions
   */
  const onToggleExcludeZeroes = () => {
    const newExcludeZeroesState = !togglesState.excludeZeroes
    setToggleStates({
      ...togglesState,
      onlyVariance: !newExcludeZeroesState ? newExcludeZeroesState : togglesState.onlyVariance,
      excludeZeroes: !togglesState.excludeZeroes,
    })
    setNeedsRefetch(true)
  }
  const onToggleVariance = () => {
    const newOnlyVarianceState = !togglesState.onlyVariance
    setToggleStates({
      ...togglesState,
      onlyVariance: newOnlyVarianceState,
      excludeZeroes: newOnlyVarianceState || togglesState.excludeZeroes,
    })
    setNeedsRefetch(true)
  }
  if (isFetching) return <Loader />

  return (
    <ElementReconciliationView
      excludeZeroes={excludeZeroes}
      onToggleExcludeZeroes={onToggleExcludeZeroes}
      selectedVersionLeft={getHeadingForOption(versionLeft)}
      selectedVersionRight={getHeadingForOption(versionRight)}
      selectedOptionLeft={versionLeft}
      selectedOptionRight={versionRight}
      title={title}
      tableData={tableData}
      tableHeadings={tableHeadings}
      totals={totalsData}
      noData={noData}
      elementOptions={elementOptions}
      initialElement={selectedElement}
      onElementSelect={onElementSelect}
      onToggleVariance={onToggleVariance}
      toggleValue={onlyVariance}
      downloadReport={downloadReport}
      payrollInstanceId={payrollInstanceId}
      inProgressPollingJobs={inProgressPollingJobs}
      isLoadingJob={isLoadingJob}
      tableOptions={termTableOptions}
    />
  )
}

export default ElementReconciliationContainer
