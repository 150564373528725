import { useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getFilteredDocumentLogs } from 'redux/selectors/documentLogs'
import { fetchDocumentLogs } from 'redux/actions/documentLogs'
import { createDocumentLogsFilter } from 'redux/filters/createDocumentFilter'

export const useDocumentTableActions = ({ onDelete, openShareModal, openEditModal }) => {
  const INITIAL_STATE = {
    id: null,
    documentId: null,
    documentName: null,
    documentTenant: null,
    fileName: null,
  }
  const dispatch = useDispatch()
  /*
   * Modals Creation
   */
  const deleteModalRef = useRef()
  const historyModalRef = useRef()
  /*
   * use States
   */
  const [documentsState, setDocumentsState] = useState(INITIAL_STATE)

  /*
   * Selectors
   */
  const documentLogs = useSelector((state) => getFilteredDocumentLogs(state, { filter: 'documentLogs' }))

  /*
   * Fetchers
   */
  const fetchLogs = (logFilters) =>
    dispatch(
      fetchDocumentLogs({
        filter: createDocumentLogsFilter(logFilters),
      })
    )
  /*
   * Event actions
   */
  const updateState = (newState) =>
    setDocumentsState({
      ...documentsState,
      ...newState,
    })
  const resetState = () => setDocumentsState(INITIAL_STATE)

  const onShareButtonClick = (doc) => {
    updateState({
      id: doc.id,
      documentId: doc.documentId,
      documentName: doc.name,
    })
    openShareModal(doc)
  }

  const handleHistoryClick = async (doc) => {
    updateState({
      id: doc.id,
      documentId: doc.documentId,
      documentName: doc.name,
      documentTenant: doc.documentTenant,
    })
    await fetchLogs({ ...doc, historyListDocumentId: doc.documentId })
    historyModalRef.current.showModal()
  }
  const onEditClick = (doc) => {
    const newState = {
      id: doc.id,
      documentId: doc.documentId,
      documentName: doc.name,
      documentTenant: doc.documentTenant,
    }
    updateState(newState)
    openEditModal({
      documentName: doc.name,
      ...doc,
    })
  }

  const handleTrashAction = (doc) => {
    updateState({
      id: doc.id,
      documentId: doc.documentId,
      fileName: doc.name,
    })
    deleteModalRef.current.showModal()
  }
  const onConfirmDelete = async () => {
    await onDelete(documentsState.id)
    resetState()
  }

  return {
    documentsState,
    historyModalRef,
    deleteModalRef,
    documentLogs,
    onShareButtonClick,
    handleHistoryClick,
    onEditClick,
    handleTrashAction,
    onConfirmDelete,
  }
}
