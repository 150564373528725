import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
import { reduxForm } from 'redux-form'
import DataProtectionPolicyForm from '../components/DataProtectionPolicyForm'
import { getFilteredEmployees } from 'redux/selectors/employees'
import { updateDataProtectionPolicy } from 'redux/actions/dataOwners'

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  onSubmit: data => dispatch(updateDataProtectionPolicy(data, props.match.params.companyId)).then(() => {
    props.onEdit()
  })
})

const mapStateToProps = (state, props) => {
  const { data } = props

  return {
    keyPeople: getFilteredEmployees(state, { filter: props.filters.employeeSystemUsers.name }),
    initialValues: {
      retentionPeriod: data.retentionPeriod,
      process: data.process,
      trigger: data.trigger,
      dataOwners: data.dataOwnersIds
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'dataProtectionPolicyForm',
})(DataProtectionPolicyForm))

export default Fetcher(Container, ['parentCompanies', 'accessAreaPivot',
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { match: { params } }) => createFilter({ isKeyPerson: 1, accessableCompanies: params.companyId })
      }
    }]
  }
])
