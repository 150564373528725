import React from 'react'
import PropTypes from 'prop-types'
import PayrollProcessTreeContainer from 'containers/PayrollProcessTreeContainer'
import PayrollInstanceProcessTreeContainer from 'containers/PayrollInstanceProcessTreeContainer'

class ShareActionOwners extends React.Component {
  render () {
    const { typeClass } = this.props

    return (
      <div data-testid='actionOwners-tabBody'>
        {typeClass === 'payroll' && <PayrollProcessTreeContainer isShare taskActive {...this.props} />}
        {typeClass === 'payrollInstance' && <PayrollInstanceProcessTreeContainer isShare showProcessOwnerIfHasCredentials {...this.props} />}
      </div>
    )
  }
}

ShareActionOwners.propTypes = {
  typeClass: PropTypes.string,
  sharingActionText: PropTypes.string,
}

export default ShareActionOwners
