import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPTY_PAYFILES_FETCH = 'EMPTY_PAYFILES_FETCH'
export const EMPTY_PAYFILES_RECEIVE = 'EMPTY_PAYFILES_RECEIVE'
export const EMPTY_PAYFILES_FILTER = 'EMPTY_PAYFILES_FILTER'
export const EMPTY_PAYFILES_FILTERED = 'EMPTY_PAYFILES_FILTERED'
export const EMPTY_PAYFILES_IMPORT = 'EMPTY_PAYFILES_IMPORT'
export const EMPTY_PAYFILES_IMPORTED = 'EMPTY_PAYFILES_IMPORTED'
export const EMPTY_PAYFILES_INITIAL_VALUES = 'EMPTY_PAYFILES_INITIAL_VALUES'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPTY_PAYFILES_FETCH,
  receive: EMPTY_PAYFILES_RECEIVE,
  filter: EMPTY_PAYFILES_FILTER,
  filtered: EMPTY_PAYFILES_FILTERED,
  import: EMPTY_PAYFILES_IMPORT,
  imported: EMPTY_PAYFILES_IMPORTED,
  initials: EMPTY_PAYFILES_INITIAL_VALUES
}
const actionUtility = new ActionUtility(actionTypes, 'emptyPayfiles', 'emptypayfiles', 'EmptyPayfile')

// ------------------------------------
// Thunk
// ------------------------------------
export const downloadPayFile = (id, params) => actionUtility.downloadEntity({ id, childUri: 'export', params })

export const filterPayfiles = ({ filters }) => {
  return dispatch => {
    return dispatch(actionUtility.filterEntity({ filters }))
      .then(response => dispatch({ type: actionTypes.initials, payload: { data: response, filters } }))
  }
}
