import React from 'react'

import PropTypes from 'prop-types'

/**
 * FitCard component
 *
 * This component renders a display card with an icon, a numerical count, a subtitle,
 * and an optional hint. The card is styled with various default and customizable classes.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.classNames=''] - Additional CSS class names to apply to the card.
 * @param {number} [props.count=0] - The numerical count to display on the card.
 * @param {string} [props.icon=''] - The icon class name to display inside the card.
 * @param {string} [props.subtitle=''] - The subtitle text to display below the count.
 * @param {string|null} [props.hint=null] - An optional hint text to display instead of the count.
 *
 * @returns {JSX.Element} The rendered FitCard component.
 */
export const FitCard = ({ classNames = '', count = 0, icon = '', subtitle = '', hint = null }) => {
  return (
    <div className={`c-display-cards shadow rounded border  ps-h-[143] ${classNames}`}>
      <div className='d-flex ai-center h-full ps-pl-[38] ps-pr-[23]'>
        <div className='d-flex ai-center'>
          <div className='rounded-full ps-w-[90] ps-h-[90]'>
            <span className={`icon--${icon}`} />
          </div>
        </div>
        <div className='u-margin-left-small u-margin-top-small'>
          {hint ? (
            <div className='c-display-cards-fallback u-weight--regular u-margin-bottom-small'>{hint}</div>
          ) : (
            <div className='c-display-cards-count'>{count}</div>
          )}
          <div className='c-display-cards--subtitle-fit'>{subtitle}</div>
        </div>
      </div>
    </div>
  )
}

FitCard.propTypes = {
  classNames: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  hint: PropTypes.string,
}
