import { createReducer } from 'utils/redux/reducer'
import { RECEIVE_SETTINGS, RECEIVE_BRANDS_CONFIG_SETTINGS } from '../actions/api'

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  apiUrl: null,
  is2FAenabled: null,
  isSSOEnabled: null,
  version: null,
  brands: null,
}

export default createReducer(initialState, {
  [RECEIVE_SETTINGS]: (state, { payload }) => ({ ...state, ...payload }),
  [RECEIVE_BRANDS_CONFIG_SETTINGS]: (state, { payload }) => ({ ...state, brands: payload }),
})
