import React from 'react'
import { isFetching } from 'utils/redux/fetching'
import { connect } from 'react-redux'
import actions from 'redux/actions'

import Loader from 'components/Loader'
import { computeEntities, normalizeEntities, validateEntities } from 'utils/fetching'

/**
 * HOC for reusing the fetching entities mechanism and flow
 *
 * @param {React.Component} WrappedComponent
 * @param {Array.<Object|String>} entities - Entities names as strings or objects, containing entities names, which should be fetched, and params array.
 * Example entity object - { name: 'company', params: [{ forceVendorTenantAPI: true }] }
 * All entities:  `redux/actions/index.js`
 * @param {Object} props
 * @param {Boolean} props.showLoader
 * @param {Boolean} props.renderWhileFetching - Should render the `WrappedComponent`, while the data is in process
 * of being fetched?
 * @param {String} props.wrapperDivClassName - Fetcher renders multiple children (if we have Loader),
 * and because of this we wrap them all with <div />. With this parameter, it's possible to add classNames to this div.
 *
 * One possible use-case to enable `renderWhileFetching` option is when you want to make another API call
 * via WrappedComponent IN PARALLEL, while the `Fetcher` HOC component is still fetching the `entities`.
 * Otherwise (if the option is disabled), the API call will be invoked only when all the `entities` are fetched.
 *
 * @example (entities as string, i.e. fetch all the entities records):
 *
 * ```
 * // Load `ContainerComponent`, only if the `companies, countries` are fetched.
 * // Also show the `<Loader />` component, while the entities are being fetched.
 * Fetcher(ContainerComponent, ['companies', 'countries'])
 * ```
 *
 * @example (entities as object + filter parameters, i.e. to filter the entitiy)
 * @url Detailed Filters documentation - https://gitlab.com/dev-labs-bg/payslip-frontend/wikis/Advance-Filtration
 *
 * ```
 * // 1. Example with static params:
 *
 * Fetcher(Component, [
 * {
 *   name: 'employeeSystemUsers',
 *   params: [{
 *     filter: { company: 5 }
 *   }],
 * }
 * ])
 *
 *
 * // 2. Example with dynamic params:
 *
 * Fetcher(Component, [
 * {
 *   name: 'employeeSystemUsers',
 *   params: [{
 *     _computed: { filter: (state, props) => createFilter({ company: props.company }) }
 *   }],
 * }
 *])
 *
 * ```
 *
 * @return {*}
 */
export default (WrappedComponent, entities, { showLoader = true, renderWhileFetching = false, wrapperDivClassName = '' } = {}) => {
  const normalizedEntities = normalizeEntities(entities)

  validateEntities(normalizedEntities, actions)

  const mapStateToProps = (state, props) => {
    // Keep entities filters, in order WrappedComponent to reuse them for the selectors
    const filters = {}
    const computedEntities = computeEntities(normalizedEntities, state, props)
    const entitiesState = computedEntities.map(entity => {
      if (entity.params[0] && entity.params[0].filter) {
        filters[entity.name] = entity.params[0].filter

        return {
          ref: state[entity.name],
          filter: entity.params[0].filter
        }
      }

      return state[entity.name]
    })

    // It's important to group and isolate the Fetcher props in `localProps` and skip passing them down,
    // in order to prevent potential props overwriting in the case we have nested Fetchers composition.
    // Using `localProps` for isolating/scoping Fetcher props is much safer
    // than spreading out all `mapStateToProps` props down to the child component (`WrappedComponent`).
    // Here's the use case:
    // 1. We have 2 nested Fetchers - parent and child.
    // 2. Please take a look and note how in the first return statement we return `isFetching: true`
    // while in the second one we don't return `isFetching`, but `fetched: true`.
    // 3. Then in the both return cases, it's possible the missing prop to be returned by the parent Fetcher,
    // and this will break our current Fetcher class logic/flow below.
    // For example, let's say the entities are in process of fetching, and the first return statement is returned.
    // But the parent Fetcher (that, wraps the current one) passed down `fetched: true`.
    // This will result in rendering `WrappedComponent`, despite the entities aren't fetched yet.
    // Yeah, we can define explicitly `isFetching, fetched` in both return statements and it will work,
    // but it's safer to group all internal Fetcher props in `localProps`, in order to not miss defining a new prop
    // in both statements in future.
    const localProps = {
      entities: computedEntities, filters, actions, WrappedComponent, showLoader, renderWhileFetching, wrapperDivClassName
    }

    if (isFetching(entitiesState)) return { localProps: { ...localProps, isFetching: true } }

    return { localProps: { ...localProps, fetched: true } }
  }

  return connect(mapStateToProps, null, null, { forwardRef: true })(Fetcher)
}

export class Fetcher extends React.Component {
  constructor (props) {
    super(props)
    this.wrappedComponentRef = React.createRef()
  }
  componentDidMount () {
    this.fetch(this.props, true)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    // Here, we DO NOT enable `enableForceFetching`, because it may lead to infinitive fetching loop.
    // Because of this, we enable it in `componentDidMount` only, in order to fetch forcefully all needed entities,
    // when the component is mounted.
    this.fetch(nextProps)
  }

  fetch (props, enableForceFetching) {
    const { dispatch, localProps: { entities, actions } } = props

    entities.forEach(entity => {
      const isForceFetching = enableForceFetching && entity.isForceFetching
      const action = isForceFetching ? actions[entity.name].fetch : actions[entity.name].fetchIfNeeded
      dispatch(action(...entity.params))
    })
  }

  shouldShowLoader () {
    const { showLoader, isFetching } = this.props.localProps

    return showLoader && isFetching
  }

  shouldRenderComponent () {
    const { fetched, renderWhileFetching } = this.props.localProps

    return fetched || renderWhileFetching
  }

  render () {
    const { localProps: { WrappedComponent, isFetching, filters, wrapperDivClassName }, ...rest } = this.props

    // Here we don't pass `localProps` down intentionally.
    // Please refer to `localProps` documentation in `mapStateToProps`
    // For the same reason as `localProps`, we pass `isFetching, filters` after `{...rest}`,
    // in order to prevent a parent component's props (`rest`) having a priority,
    // over the `localProps`, these we want to pass down.
    return <div className={wrapperDivClassName}>
      { this.shouldShowLoader() && <Loader /> }

      { this.shouldRenderComponent() &&
        <WrappedComponent ref={this.wrappedComponentRef} {...rest} isFetching={isFetching} filters={filters} />
      }
    </div>
  }
}
