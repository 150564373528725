import { createContext, useContext, useMemo, useState } from 'react'

import { useFloating, useClick, useDismiss, useRole, useInteractions } from '@floating-ui/react'

export const useFloatingDialog = ({ initialOpen = false, open: controlledOpen, onOpenChange: setControlledOpen, dismissOptions }) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  })
  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown', ...dismissOptions })
  const role = useRole(context)
  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  )
}

export const FloatingDialogContext = createContext(null)
export const useFloatingDialogContext = () => {
  const context = useContext(FloatingDialogContext)

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />')
  }

  return context
}
