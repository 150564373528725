import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryTimezonePivot'

class CountryTimezone extends CRUDModel {}
CountryTimezone.modelName = 'CountryTimezone'

CountryTimezone.fields = {
  id: attr(),
  country: fk('Country', 'countryTimezone'),
  timezone: fk('Timezone', 'countryTimezone')
}
CountryTimezone.actionTypes = actionTypes

export default CountryTimezone
