import React from 'react'
import { connect } from 'react-redux'
import { hasAccess, isEmployeeOnly } from 'redux/selectors/auth'
import Fetcher from 'containers/Fetcher'
import { isFetching } from 'utils/redux/fetching'
import { fetchEmployeeAttendancePeriodsIfNeeded } from 'redux/actions/employeeAttendancePeriods'
import { getAttendancesByEmployee } from '../selectors/employeeAttendancePeriodORM'
import LeavesView from '../components/LeavesView'
import { flatten } from 'lodash'
import moment from 'moment'

class LeaveListContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchEmployeeAttendancePeriodsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div />
    return <LeavesView {...this.props} />
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { employeeAttendancePeriods } = state
  const employeeId = props.match.params.employeeId

  if (isFetching([employeeAttendancePeriods]) || props.isFetching) return { isFetching: true }

  const attendances = getAttendancesByEmployee(state, { employeeId })
  const attendanceRows = attendances ? attendances.map(item => ([
    { 'Leave': `${moment(item.fromDate).format('DD/MM/YY')} - ${moment(item.toDate).format('DD/MM/YY')}` },
    { 'Type': item.globalName },
    { 'Total Days': item.totalDays }
  ])) : null

  return {
    attendances,
    attendanceRows: flatten(attendanceRows),
    hasAccess: hasAccess(state),
    isEmployeeOnly: isEmployeeOnly(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const employeeId = parseInt(props.match.params.employeeId)

  return {
    fetchEmployeeAttendancePeriodsIfNeeded: () => dispatch(fetchEmployeeAttendancePeriodsIfNeeded(employeeId)),
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(LeaveListContainer)

export default Fetcher(Container, [
  'terms', 'countryTermPivot', 'companyCountryTermPivot'
], { renderWhileFetching: true })
