import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { updatePayrollStep } from 'redux/actions/payrollStep'
import { invalidatePayrollInstanceStep } from 'redux/actions/payrollInstanceStep'
import { getPayrollStepById, getPayrollStepsOptionsByPayrollProcessId, getReorderedPayrollStepsByPayrollProcessId }
  from '../selectors/payrollSteps'
import PayrollStepForm from '../components/PayrollStepForm'

class PayrollStepEditContainer extends React.Component {
  render () {
    return <PayrollStepForm {...this.props} />
  }
}

PayrollStepEditContainer.propTypes = {
  payrollStepId: PropTypes.number
}

const mapDispatchToProps = (dispatch, { onSubmit, payrollStepId }) => ({
  onSubmit: data => dispatch(updatePayrollStep(data, payrollStepId)).then(() => {
    onSubmit()
    dispatch(invalidatePayrollInstanceStep())
  })
})

const mapStateToProps = (state, props) => {
  const { payrollStepId } = props

  const step = getPayrollStepById(state, { payrollStepId })
  const payrollProcessId = step.payrollProcess

  const { name, position = null } = getFormValues('payrollStepEdit')(state) || {}

  return {
    stepsOptions: getPayrollStepsOptionsByPayrollProcessId(state, {
      payrollProcessId,
      shouldIncludeLastOption: true,
      shouldUseShortName: true,
    }),
    steps: getReorderedPayrollStepsByPayrollProcessId(state, { payrollStepId, payrollProcessId, name, position }),
    initialValues: {
      name: step.name,
      position: step.position
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollStepEdit'
})(PayrollStepEditContainer))

export default Fetcher(Container, [
  'payrollStep',
])
