import { createReducerMeta } from 'utils/redux'
import { VENDOR_USER_FETCH, VENDOR_USER_RECEIVE } from 'redux/actions/vendorUsers'

const actionTypes = {
  fetch: VENDOR_USER_FETCH,
  receive: VENDOR_USER_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducerMeta(actionTypes)
