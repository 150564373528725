/* eslint-disable react/prop-types */
import React from 'react'
import { fetchPayrolls } from 'redux/actions/payrolls'
import { selectTenant } from 'redux/actions/tenants'

const PayrollLink = ({
  resetTenant,
  tenant,
  isCotUser,
  isVendor,
  selectTenant: selectVendorTenant,
  payrollProcessId,
  history,
  companyId,
  dispatch,
  vendorId
}) => {
  const { id: tenantId } = tenant
  let pathToTenantWorkFlowDetails

  return (
    <span
      className='u-text--curious u-cursor--pointer'
      onClick={async () => {
        resetTenant()
        selectVendorTenant({
          url: tenant.url,
          schema: tenant.schema,
          shouldUse: true,
        })
        await dispatch(
          (isVendor || isCotUser)
            ? fetchPayrolls({ forceVendorTenantAPI: true })
            : fetchPayrolls({})
        ).then((res) => {
          if (isCotUser) dispatch(selectTenant({ url: tenant.url, type: tenant.type, id: tenantId }))

          try {
            pathToTenantWorkFlowDetails = `/companies/${companyId}/dates-processes/payroll/${payrollProcessId}`

            history.push(pathToTenantWorkFlowDetails)
          } catch (e) {
            const endPath = '/payruns/active'
            let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/`
            let pathToTenantPayrollInstances = endPath
            let pathToVendorPayrollInstances = `${baseVendor}/${endPath}`

            let path = isVendor
              ? pathToVendorPayrollInstances
              : pathToTenantPayrollInstances

            return history.push(path)
          }
        })
      }}
      data-testid='notification-link'
    > here
    </span>
  )
}

export default PayrollLink
