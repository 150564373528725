import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { dateTransformer } from 'redux/transformers/dateTransformer'
import { sanitizeObject } from 'utils/sanitizer'
import _ from 'lodash'
import { formatNameForChangeLogReport } from 'utils/dataFormatting'

// ------------------------------------
// Constants
// ------------------------------------
export const REPORTS_FETCH = 'REPORTS_FETCH'
export const REPORTS_RECEIVE = 'REPORTS_RECEIVE'
export const REPORT_CREATE = 'REPORT_CREATE'
export const REPORT_DELETE = 'REPORT_DELETE'
export const REPORT_SET_LAST_CREATED_ID = 'REPORT_SET_LAST_CREATED_ID'
export const REPORT_SET_LAST_RUN_BY_TYPE = 'REPORT_SET_LAST_RUN_BY_TYPE'
export const REPORT_UPDATE = 'REPORT_UPDATE'
export const REPORT_INVALIDATE = 'REPORT_INVALIDATE'
export const REPORT_RESET = 'REPORT_RESET'
// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: REPORTS_FETCH,
  receive: REPORTS_RECEIVE,
  create: REPORT_CREATE,
  delete: REPORT_DELETE,
  setLastCreatedId: REPORT_SET_LAST_CREATED_ID,
  setLastRunReportType: REPORT_SET_LAST_RUN_BY_TYPE,
  invalidate: REPORT_INVALIDATE,
  reset: REPORT_RESET,
}
const actionUtility = new ActionUtility(actionTypes, 'reports', 'reports', 'Report')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchReportsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchReports = actionUtility.fetchEntities
export const setLastCreatedReportId = actionUtility.setLastCreatedId
export const setLastRunReportType = actionUtility.setLastRunReportType
export const downloadReport = (id, childUri = 'export') => actionUtility.downloadEntity({ id, childUri })
export const deleteReport = actionUtility.deleteEntity
export const invalidateReports = actionUtility.invalidate
export const resetReports = actionUtility.reset

/*
 * Create report
 */
export const createReport = (entity, saved, setMostRecentRun = true) => {
  let transformedData = dateTransformer(entity, ['fromDate', 'toDate'])
  if (transformedData.type === 'ChangeLogReport' && transformedData.modifiedBy) {
    transformedData = formatNameForChangeLogReport(transformedData)
  }

  // Pick the allowed report filters
  const filters = _.pick(transformedData, [
    'termCategory',
    'termSubcategory',
    'term',
    'showOnlyTotals',
    'fromDate',
    'toDate',
    'employeeStatus',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'businessUnit',
    'costCenter',
    'department',
    'processOwner',
    'process',
    'globalOwner',
    'employmentStatus',
    'modifiedBy',
  ])

  // By default, `redux-orm` should clean the Store, when a field is unregistered,
  // but unfortunately it is kept in the Store with `null`
  // More info: https://github.com/erikras/redux-form/issues/2325
  const sanitizedFilters = sanitizeObject(filters)

  // Build specific data for all report types
  const data = {
    type: transformedData.type,
    feCategoryId: entity.selectedReportType,
    category: transformedData.category,
    subcategory: transformedData.subcategory,
    filters: sanitizedFilters,
    saved,
  }

  // Build custom rates params when there are multi payroll currencies
  const multiCustomRates = []
  for (let key in entity) {
    // Custom rates having values.
    // In our form, on changing `toCurrency` value, we are resetting all custom rates values (setting `null` as value).
    // That's needed, because new customRate fields will be rendered.
    // But the old customRate fields will be still kept in the form, having `null` as value.
    // That's the reasons we make sure to skip them here.
    if (_.includes(key, 'customRate') && entity[key] !== null) {
      let splited = _.split(key, '-')
      multiCustomRates.push({
        fromCurrency: parseInt(splited[1]),
        toCurrency: transformedData.toCurrency,
        rate: parseFloat(entity[key]),
      })
    }
  }

  // Send custom fxRate when there are multi payroll currencies
  if (multiCustomRates.length) {
    data['params'] = {
      fxRateCustom: multiCustomRates,
    }
  }

  // Send custom fxRate, when there is only one payroll currency
  if (transformedData.toCurrency && transformedData.rate) {
    data['params'] = {
      fxRateCustom: [
        {
          fromCurrency: transformedData.payrollCurrency[0],
          toCurrency: transformedData.toCurrency,
          rate: parseFloat(transformedData.rate),
        },
      ],
    }
  }

  // Send predefined fxRate in expected format
  if (transformedData.fxRate) {
    data['params'] = {
      fxRate: transformedData.fxRate,
    }
  }

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data))
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        // Save last created report id to redux store
        dispatch(setLastCreatedReportId(response.id))
        if (setMostRecentRun) {
          dispatch(setLastRunReportType({ [entity.selectedReportType]: response.id }))
        }
        return response
      })
  }
}

export const updateReport = ({ id }, saved, shouldRefetch = false) => {
  return (dispatch, getState, { api }) => {
    // Define uri for single and
    const uri = `reports/${id}`

    dispatch({
      type: REPORT_UPDATE,
      uri,
    })

    return api
      .patch(uri, { payload: { saved } })
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        if (shouldRefetch) {
          if (shouldRefetch) dispatch(fetchReports())
        }
        return response
      })
  }
}
