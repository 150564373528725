import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const OFFICE_LOCATION_FETCH = 'OFFICE_LOCATION_FETCH'
export const OFFICE_LOCATION_RECEIVE = 'OFFICE_LOCATION_RECEIVE'
export const OFFICE_LOCATION_CREATE = 'OFFICE_LOCATION_CREATE'
export const OFFICE_LOCATION_UPDATE = 'OFFICE_LOCATION_UPDATE'
export const OFFICE_LOCATION_DELETE = 'OFFICE_LOCATION_DELETE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: OFFICE_LOCATION_FETCH,
  receive: OFFICE_LOCATION_RECEIVE,
  create: OFFICE_LOCATION_CREATE,
  update: OFFICE_LOCATION_UPDATE,
  delete: OFFICE_LOCATION_DELETE
}
const actionUtility = new ActionUtility(actionTypes, 'officeLocations', 'officelocations', 'OfficeLocation')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchOfficeLocationsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchOfficeLocations = actionUtility.fetchEntities
export const createOfficeLocation = actionUtility.createEntity
export const updateOfficeLocation = actionUtility.bulkUpdateEntity
export const deleteOfficeLocation = actionUtility.deleteEntity
