import React from 'react'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import LeavesTable from './LeavesTable'
import MobileDetailsView from 'components/MobileDetailsView'
import Modal from 'components/Modal'
import LeaveFormCreateContainer from '../containers/LeaveFormCreateContainer'

class LeavesView extends React.Component {
  /**
   * Handle modal opening
   * @param modalRef
   */
  handleModalOpen (modalRef) {
    modalRef.showModal()
  }

  render () {
    const { attendanceRows, isEmployeeOnly } = this.props

    return <div>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <Modal
          ref='modalCreateLeave'
          className='c-modal c-modal--half'
          modalHeading='Add Personal Leave'
          data-testid='leave-create-modal'
        >
          <LeaveFormCreateContainer onCreate={() => this.refs.modalCreateLeave.hideModal()} match={this.props.match} />
        </Modal>
        <SectionHeading text='Personal Leave'>
          <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <div
                  onClick={() => this.handleModalOpen(this.refs.modalCreateLeave)}
                  className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
                  data-testid='leave-create'
                >
                  <span className='icon icon--plus' />
                </div>
              </div>
            </div>
          </Authorization>
        </SectionHeading>
        <LeavesTable {...this.props} />
      </div>
      {isEmployeeOnly &&
        <div className='u-hide-on-desktop'>
          <SectionHeading text='Time & Attendance' />
          <AccordionContainer title='Personal Leave' titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={attendanceRows} />
          </AccordionContainer>
        </div>
      }
    </div>
  }
}

export default LeavesView
