import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_TASK_OWNERS_FETCH = 'PAYROLL_TASK_OWNERS_FETCH'
export const PAYROLL_TASK_OWNERS_RECEIVE = 'PAYROLL_TASK_OWNERS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_TASK_OWNERS_FETCH,
  receive: PAYROLL_TASK_OWNERS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollTaskOwners', 'payrolltaskowners', 'PayrollTaskOwners')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollTaskOwnersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollTaskOwners = actionUtility.fetchEntities
