import React from 'react'
import { Link } from 'react-router-dom'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import Moment from 'components/Moment'
import Flag from 'components/Flag'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import ReversedFlagRenderer from 'components/payruns/ReversedFlagRenderer'
import PayslipTable from 'components/table/PayslipTable'
import { sortByLabel } from 'utils/strings'
import { payrollInstanceHoverDates, defaultFormat } from 'utils/tableDataFormatters'
import { SelectColumnFilter } from 'components/table/filters'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import Button from 'components/buttons/Button'
import { NotesIcon } from 'components/icons/NoteIcons'
let classNames = require('classnames')

class PayrollInstanceList extends React.Component {
  constructor (props) {
    super(props)
    this.state = { instanceId: null }

    this.buildRow = this.buildRow.bind(this)
  }

  onUpload (instanceId) {
    this.setState({ instanceId })

    this.dropzoneRef.open()
  }

  buildHeadings () {
    const { isActive, showImportColumn, countries, payrolls, filter, payrunState } = this.props
    const isAccordionLayout = payrunState === payrunStatesEnum.INACTIVE

    let headings = []

    if (!isAccordionLayout) {
      headings.push({
        accessor: 'country',
        Header: 'Country',
        setWidth: '90px',
        classNames: 'u-text--center',
        Filter: SelectColumnFilter,
        dropDownOptions: {
          name: 'country',
          defaultValue: filter.params['country'] || null,
          options: countries.map((c) => ({
            value: `${c.value}`,
            label: c.label,
          })),
        },
        disableSortBy: true,
      })
    }

    headings.push({
      accessor: 'payrollInstances',
      Header: 'Payroll Name',
      setWidth: '170px',
      Filter: !isAccordionLayout && SelectColumnFilter,
      dropDownOptions: {
        name: 'payrollInstances',
        defaultValue: filter.params['payroll'] || null,
        options: payrolls
          .map((p) => ({
            value: `${p.value}`,
            label: p.label,
          }))
          .sort(sortByLabel),
      },
      disableSortBy: true,
    })

    if (this.props.showCount) {
      headings.push({
        accessor: 'employeeCount',
        Header: 'Headcount',
        classNames: 'text-center',
        setWidth: '98px',
        disableSortBy: true,
        disableFilters: true,
      })
    }
    if (this.props.showProgress) {
      headings.push({
        accessor: 'progress',
        Header: 'Progress',
        setWidth: '80px',
        classNames: 'u-text-overflow--unset u-white-space--unset text-center',
        disableSortBy: true,
        disableFilters: true,
      })
    }

    if (isAccordionLayout) {
      headings.push(
        {
          accessor: 'fromDateString',
          Header: 'From Date',
          setWidth: '80px',
          classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
          disableSortBy: true,
        },
        {
          accessor: 'toDateString',
          Header: 'To Date',
          setWidth: '80px',
          classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
          disableSortBy: true,
        }
      )
    }

    headings.push({
      accessor: 'payDateString',
      Header: 'Pay Date',
      setWidth: '80px',
      classNames: 'u-text-overflow--unset u-white-space--unset u-text--left',
      disableSortBy: true,
      Cell: !isAccordionLayout ? payrollInstanceHoverDates : defaultFormat,
    })

    if (!isAccordionLayout) {
      headings.push(
        {
          accessor: 'gtnDueDate',
          Header: 'GTN due date',
          setWidth: '80px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'isReverted',
          Header: '',
          classNames: 'text-center',
          columnClassName: 'text-center u-margin-right u-margin-top-tiny',
          setWidth: '35px',
          disableSortBy: true,
          disableFilters: true,
        },
        ...(isActive
          ? [
            {
              accessor: 'step',
              Header: 'Step',
              setWidth: '150px',
              classNames: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              accessor: 'actions',
              Header: 'Actions',
              classNames: 'text-center',
              setWidth: '70px',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              accessor: 'timelines',
              Header: `Payroll Timelines`,
              classNames: 'text-center u-white-space-pre-line',
              setWidth: '100px',
              className: 'u-white-space-pre-line',
              disableSortBy: true,
              disableFilters: true,
            },
          ]
          : []),
        {
          accessor: 'inputFile',
          Header: 'Input file',
          setWidth: '90px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'files',
          Header: 'Files',
          setWidth: '70px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        },
        ...(showImportColumn
          ? [
            {
              accessor: 'import',
              Header: 'Import GTN calc',
              setWidth: '150px',
              classNames: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
          ]
          : []),
        {
          accessor: 'notes',
          Header: 'Notes',
          setWidth: '60px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        }
      )
    }

    if (isAccordionLayout) {
      headings.push(
        {
          accessor: 'timelines',
          Header: `Payroll Timelines`,
          setWidth: '100px',
          classNames: 'u-text-overflow--unset u-white-space--unset text-center',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'joiners',
          Header: 'Joiners & Leavers',
          disableSortBy: true,
          disableFilters: true,
          classNames: 'u-text-overflow--unset u-white-space--unset text-center',
          setWidth: '80px',
        },
        {
          accessor: 'files',
          Header: 'Files',
          setWidth: '70px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'notes',
          Header: 'Notes',
          setWidth: '60px',
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true,
        }
      )
    }
    return headings
  }

  buildRow (payrollInstance) {
    const { downloadFile, onFilesButtonClick, onActionsButtonClick, onJoinersButtonClick, isActive, disabledPayFileExportButton, showImportColumn } = this.props
    const { vendorId, tenantId, companyId } = this.props.match.params
    const basePath = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}`
    const isGenerateDisabled = disabledPayFileExportButton(payrollInstance.id)
    return {
      id: payrollInstance.id,
      country: (
        <div className='d-flex ai-center jc--center'>
          <Flag flag={payrollInstance.countryAbbr.toLowerCase()} title={`${payrollInstance.countryName} - ${payrollInstance.companyName}`} size='tiny' />
        </div>
      ),
      payrollInstances: payrollInstance.payrollName,
      employeeCount: <div className='text-center'>{payrollInstance.employees.length || 0}</div>,
      progress: (
        <div className='d-flex jc--center'>
          <div style={{ width: 40, height: 40 }}>
            <CircularProgressbar
              value={payrollInstance.progressPercentage || 0}
              text={`${payrollInstance.progressPercentage || 0}%`}
              styles={buildStyles({
                textSize: '1.5rem',
                textColor: '#000',
                pathColor: `#5292dd`,
              })}
            />
          </div>
        </div>
      ),
      fromDateString: <Moment format='DD/MM/YYYY'>{payrollInstance.fromDate.date}</Moment>,
      toDateString: <Moment format='DD/MM/YYYY'>{payrollInstance.toDate.date}</Moment>,
      payDateString: <Moment format='DD/MM/YYYY'>{payrollInstance.payDate.date}</Moment>,
      gtnDueDate: payrollInstance.extraData['gross-to-net-calculations-deadline'] ? (
        <div className='text-center'>
          <Moment format='DD/MM/YYYY'>{payrollInstance.extraData['gross-to-net-calculations-deadline'].date}</Moment>
        </div>
      ) : null,
      files: (
        <div className='u-text--center'>
          <button
            type='button'
            onClick={() => onFilesButtonClick(payrollInstance)}
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
            title='Files'
            data-testid={`files-button-${payrollInstance.id}`}
          >
            Files
          </button>
        </div>
      ),
      notes: (
        <div className='o-grid o-grid--middle o-grid--center'>
          <Link to={`${basePath}/payrun/${this.props.payrunState}/${payrollInstance.id}/notes`}>
            <NotesIcon className='u-relative' title='Notes' data-testid={`payrollInstance-${payrollInstance.id}-notes`}>
              {payrollInstance.isLastAddedNoteFromOtherUser && <span className='c-notification__indicator u-bg--supernova' />}
            </NotesIcon>
          </Link>
        </div>
      ),
      step: (
        <span>
          {payrollInstance.currentStepName} / {payrollInstance.alphabeticStepRange}
        </span>
      ),
      isReverted: <ReversedFlagRenderer instance={payrollInstance} />,
      ...(payrollInstance.areUpdatesApproved
        ? {
          inputFile: (
            <div className='u-text--center'>
              <Button
                className={`u-text--tiny u-margin-bottom-tiny c-btn c-btn--curious c-btn--tiny ${isGenerateDisabled && 'disabled'}`}
                onClick={() => downloadFile(payrollInstance.id)}
                title={`${isGenerateDisabled ? 'The generation of this payrun file is already in progress' : 'Download'}`}
                disabled={isGenerateDisabled}
                data-testid='download'
              >
                <span className='icon icon--download' />
              </Button>
            </div>
          ),
        }
        : {}),
      ...(showImportColumn && payrollInstance.areUpdatesApproved
        ? {
          import: (
            <div className='u-text--center'>
              <button
                type='button'
                onClick={() => {
                  this.onUpload(payrollInstance.id)
                }}
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
                title='Upload'
              >
                <span className='icon icon--upload' />
              </button>
            </div>
          ),
        }
        : {}),
      ...(isActive && payrollInstance.hasCurrentStepVendorTask
        ? {
          actions: (
            <div className='u-text--center'>
              <span
                className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
                onClick={() => onActionsButtonClick(payrollInstance)}
                title='Actions'
                data-testid={`actions-button-${payrollInstance.id}`}
              >
                Actions
              </span>
            </div>
          ),
        }
        : {}),
      timelines: (
        <div className='u-text--center'>
          <Link
            to={`${basePath}/payroll/${payrollInstance.id}/timelines`}
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
            data-testid={`timelines-button-${payrollInstance.id}`}
            title='Timeline'
          >
            Timeline
          </Link>
        </div>
      ),
      joiners: (
        <div className='text-center'>
          <button
            type='button'
            onClick={() => onJoinersButtonClick(payrollInstance)}
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
            title='Files'
            data-testid={`joiners-button-${payrollInstance.id}`}
          >
            <span className='icon icon--preview' />
          </button>
        </div>
      ),
    }
  }

  buildRows () {
    const { payrollInstances } = this.props

    return payrollInstances.map(this.buildRow)
  }

  render () {
    const { payrollInstances, selectedPayroll, onSubmit, selectedCountry, uploadFile, onFilter, isFetching, ...rest } = this.props
    const { limit } = rest.pagination
    const options = {
      noDataText: 'No matching records found.',
      pageSize: rest.pagination && limit,
      sizePerPageList: [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
      ],
      pageIndex: rest.pagination && rest.pagination.currentPage,
      pageCount: rest.pagination && rest.pagination.totalPages,
      onPageChange: ({ pageSize, pageIndex, filters }) => {
        if (!this.props.isFetching) {
          let normalizedFilters = {}
          filters.forEach((filter) => {
            normalizedFilters[filter.id] = filter
          })
          onFilter({
            page: pageIndex,
            limit: pageSize,
            filters: normalizedFilters,
          })
        }
      },
      onFilterChange: (filters) => onFilter({ filters }),
    }

    return (
      <div data-testid='payrollInstance-table'>
        <Dropzone
          onDrop={(files) => uploadFile(this.state.instanceId, files[0])}
          ref={(node) => {
            this.dropzoneRef = node
          }}
          className='u-relative'
        />

        <PayslipTable
          data={this.buildRows()}
          headings={this.buildHeadings()}
          wrapperClassName={classNames({
            'u-1/1 react-bs-table--overflow-auto': true,
            'u-half-opacity': this.props.isFetching,
          })}
          options={options}
          pagination
          remote
          skipPageReset={this.props.isFetching}
          showFilters={this.props.showFilters}
          data-testid='payrollInstance-table'
          totalCount={rest.pagination && rest.pagination.totalCount}
        />
      </div>
    )
  }
  componentWillUnmount () {
    this.props.onFilter({ filters: {} })
  }
}

PayrollInstanceList.propTypes = {
  payrollInstances: PropTypes.array,
  uploadFile: PropTypes.func,
  isFetching: PropTypes.bool,
  showFilters: PropTypes.bool,
  downloadFile: PropTypes.func,
  onFilesButtonClick: PropTypes.func,
  onNotesButtonClick: PropTypes.func,
  onActionsButtonClick: PropTypes.func,
  isActive: PropTypes.bool,
  showImportColumn: PropTypes.bool,
  showCount: PropTypes.bool,
  showProgress: PropTypes.bool,
  selectedCountry: PropTypes.number,
  selectedPayroll: PropTypes.number,
  onSubmit: PropTypes.func,
  onFilter: PropTypes.func,
  countries: PropTypes.array,
  payrolls: PropTypes.array,
  filter: PropTypes.object,
  match: PropTypes.object,
  disabledPayFileExportButton: PropTypes.func,
  payrunState: PropTypes.string,
}

export default PayrollInstanceList
