import { defaultFormat } from 'utils/tableDataFormatters'

/**
 * Class to create a heading object for use with PayslipTable component
 *
 * @param {String} accessor - key to link the data in the heading to the correct table column
 * @param {String} heading - column heading
 * @param {String} [className] - class name for heading cell
 * @param {String} [columnClassName] - class name to be applied to column
 * @param {Boolean} [disableFilters] - table filters, disabled by default
 * @param {Boolean} [disableSortBy] - table sort, disabled by default
 * @param {String} [inputType] - if a cell is editable, set up what kind of input needs to be rendered
 * @param {String} [placeholder] - placeholder for input
 * @param {String} [mappedErrorField] - name of the field used to render errors
 * @param {Function} [Cell] - function for cell formatting, see src/utils/tableDataFormatters.js
 * @param {Function} [Filter] - filter function
 * @param {object} [dropDownOptions] - dropdown options for when the filter is a dropdown select
 * @param {Boolean} [editable] - whether a cell is editable or not
 * @param {Boolean} [requiredField] - whether an editable cell is a required field or not
 * @returns {object}
 */
export class TableHeading {
  constructor (options) {
    this.id = options.id
    this.accessor = options.accessor
    this.Header = options.heading
    this.classNames = options.className ?? 'u-text--center'
    this.columnClassName = options.columnClassName ?? 'u-text--center'
    this.disableFilters = options.disableFilters ?? true
    this.disableSortBy = options.disableSortBy ?? true
    this.inputType = options.inputType ?? null
    this.placeholder = options.placeholder ?? ''
    this.mappedErrorField = options.mappedErrorField ?? options.accessor
    this.Cell = options.Cell ?? defaultFormat
    this.dropDownOptions = options.dropDownOptions ?? null
    this.setWidth = options.setWidth
    this.actionCell = options.actionCell
    this.formatExtraData = options.formatExtraData
    this.headerAlign = options.headerAlign

    if (options.editable) {
      this.editable = options.editable
      this.inputType = options.inputType
      this.charactersPerLine = options?.charactersPerLine
      this.requiredField = options.requiredField
      this.focusableIndex = options?.focusableIndex
    }

    if (options.expander) {
      this.expander = options.expander
    }

    if (options?.Filter) {
      this.Filter = options.Filter
    }

    this.onClick = options?.onClick
    this.filter = options?.filter

    if (options.datePickerOptions) {
      this.datePickerOptions = options.datePickerOptions
    }
    if (options?.onOverrideClick) {
      this.onOverrideClick = options.onOverrideClick
    }
  }
}
