import React, { useRef } from 'react'
import SectionHeading from 'components/SectionHeading'
import ConfirmationModal from 'components/ConfirmationModal'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { resourcesTableTitles, resourcesDeleteText } from 'utils/locales/resources.en'
import { generateResourcesTableHeadings } from '../utils/resourcesTableConfig'
import ResourcesUploadHeader from './ResourcesUploadHeader'
import ResourcesList from './ResourcesList'

const ResourcesView = ({
  isCotAdminUser,
  isCotUser,
  isCotOemUser,
  clientTablePermission,
  vendorTablePermission,
  clientTable,
  vendorTable,
  handleDownload,
  selectedRow,
  setSelectedRow,
  deleteFile,
  historyModalRef,
  historyData,
  getHistory,
  ...rest
}) => {
  // delete button and action required for table headings
  const deleteModalRef = useRef()

  const deleteButton = ({ row, onClick }) => (
    <button onClick={() => onClick(row)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title='Delete'>
      <span className='icon icon--trash' />
    </button>
  )

  const deleteButtonAction = (row) => {
    setSelectedRow(row)
    deleteModalRef.current.showModal()
  }

  // history button and action required for table headings
  const historyButton = ({ row, onClick }) => (
    <button onClick={() => onClick(row)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title='History'>
      <span className='icon icon--history' />
    </button>
  )

  const historyButtonAction = (row) => {
    setSelectedRow(row)
    getHistory(row.id)
  }

  // table headings
  const headings = generateResourcesTableHeadings(
    isCotAdminUser,
    isCotUser,
    isCotOemUser,
    handleDownload,
    deleteButton,
    deleteButtonAction,
    historyButton,
    historyButtonAction
  )

  return (
    <>
      {isCotAdminUser && <ResourcesUploadHeader {...rest} />}
      {isCotUser && <SectionHeading text={resourcesTableTitles.client} />}
      {clientTablePermission && <ResourcesList headings={headings} {...clientTable} />}
      {isCotUser && <SectionHeading text={resourcesTableTitles.vendor} />}
      {vendorTablePermission && <ResourcesList headings={headings} {...vendorTable} />}
      <ConfirmationModal ref={deleteModalRef} className='c-modal' modalHeading={resourcesDeleteText.modalHeading} onConfirm={() => deleteFile()}>
        <p>
          {resourcesDeleteText.confirmation}
          <b>{selectedRow?.fileName}</b>?
        </p>
      </ConfirmationModal>
      <HistoryModal modalRef={historyModalRef} logs={historyData} heading={`${selectedRow?.fileName ?? 'File'} History`} />
    </>
  )
}

export default ResourcesView
