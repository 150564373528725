import React from 'react'
import PropTypes from 'prop-types'
import AmountFormat from 'components/AmountFormat'
import PayslipTable from 'components/table/PayslipTable'

export const AnnualSalaryList = props => {
  const { annualSalaries } = props

  return <PayslipTable
    wrapperClassName='u-1/1'
    tableElementClassName='u-margin-bottom-large table--layout-auto'
    options={{ noDataMessage: 'There is no data to display' }}
    data={annualSalaries.map(salary => ({
      id: salary.id,
      value: <AmountFormat amount={salary.value} />,
      effectiveDate: salary.effectiveDate,
      reason: <span data-testid='reason'>{salary.reason}</span>,
      dateAdded: salary.dateAdded,
      addedBy: salary.addedBy
    }))}
    headings={[
      { accessor: 'value', Header: 'Value', disableFilters: true, disableSortBy: true },
      { accessor: 'effectiveDate', Header: 'Effective date', disableFilters: true, disableSortBy: true },
      { accessor: 'reason', Header: 'Reason', disableFilters: true, disableSortBy: true },
      { accessor: 'dateAdded', Header: 'Date changed', disableFilters: true, disableSortBy: true },
      { accessor: 'addedBy', Header: 'Changed by', disableFilters: true, disableSortBy: true }
    ]}
  />
}

AnnualSalaryList.propTypes = {
  annualSalaries: PropTypes.array
}

export default AnnualSalaryList
