import React from 'react'
import { connect } from 'react-redux'
import { downloadArchive, fetchPayrollInstances, invalidatePayrollInstancesByStatus, updatePayrollInstances } from 'redux/actions/payrollInstances'
import { getFilteredPayrollInstances } from 'redux/selectors/payrollInstance'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import PayrollInstanceTable from '../components/PayrollInstanceTable'
import Fetcher from 'containers/Fetcher'
import { createFilter } from 'utils/redux/filter'
import { getPayrolls, getPayrollsWithActiveOrLockedPayrollInstances } from 'redux/selectors/payroll'
import { getCountriesByCompanies } from 'redux/selectors/country'
import Loader from 'components/Loader'
import { getFuturePayrunsReportToFilterDate } from 'utils/calendarYearDates'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { getCompaniesByCountry, getCompaniesRef } from 'redux/selectors/company'
import { sortByLabel } from 'utils/strings'
import { isEmpty } from 'lodash'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'
import { triggerZeroTouch } from 'redux/actions/document'
import { pinPayroll } from 'redux/actions/payrolls'
import { ALL_ROUTES } from 'configs/routes'

const hasInitialPayrunFilter = (payrunState) => [payrunStatesEnum.PREVIOUS, payrunStatesEnum.INACTIVE].includes(payrunState)

const buildFilterName = (props) => {
  return hasInitialPayrunFilter(props.payrunState) ? `${props.payrunState}_payroll=${props.payrollId}` : `${props.payrunState}`
}

let modalState = {
  showConfirmUnlock: false,
  showConfirmLock: false,
  modalHeading: '',
  reimportGTNConfirmationModal: false,
  successLockWithGTNReimport: false,
  documentId: null,
  documentName: null,
  documentVersion: null,
}

// On the FE the filters have one name, while on the BE is another one.
// We can unify the names later, because our goal is to refactor only the COS Alerts,
// without refactoring the Client/Vendor alerts, these are using the same names too.
const filtersMapping = {
  type: 'payrollInstanceName',
  status: 'createdAt',
  payrollInstances: 'payroll',
  reportToDate: 'reportToDate',
}

const _createFilter = ({ page = 0, limit, filters, state, props } = {}) => {
  const { payrunState } = props
  const filterName = buildFilterName(props)
  // Payroll instances on the future payruns tab should show only up to 2024.
  // This is currently hardcoded here to show all instances for 2023.
  const futurePayrunsEndDate = props.payrunState === payrunStatesEnum.INACTIVE ? getFuturePayrunsReportToFilterDate : null

  const payrollId = hasInitialPayrunFilter ? props.payrollId : null

  limit = limit !== undefined ? limit : state.payrollInstances.filters[filterName]?.params.limit
  page = page !== undefined ? page : state.payrollInstances.filters[filterName]?.params.offset / limit
  filters = filters !== undefined ? filters : state.payrollInstances.filters[filterName] ? state.payrollInstances.filters[filterName]?.params : null

  const sort = [
    { name: 'country', order: 'asc' },
    { name: 'name', order: 'asc' },
    { name: 'fromDate', order: 'desc' },
  ]

  const sortFuturePayruns = [
    { name: 'country', order: 'asc' },
    { name: 'name', order: 'asc' },
    { name: 'fromDate', order: 'asc' },
  ]

  return createFilter(
    {
      payroll: payrollId,
      payrunState,
      sort: payrunState !== payrunStatesEnum.INACTIVE ? sort : sortFuturePayruns,
      reportToDate: futurePayrunsEndDate,
      offset: page * limit,
      limit,
      ...filters,
    },
    filterName
  )
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFilter: ({ page = 0, limit, filters }) =>
      dispatch((dispatch, getState) => {
        // Prevent making this call if the user has no access token
        // As the user logout triggers faster than the componentWillUnmount *before* the compo
        if (getState().auth.accessToken === null && isEmpty(filters) && !getState().auth.username) return
        dispatch(
          fetchPayrollInstances({
            filter: _createFilter({
              page,
              limit,
              // Normalize query filter parameters (their names must match the BE API)
              ...(filters && {
                filters: Object.keys(filters).reduce((normalized, filterName) => {
                  // Set the correct filter name, expected by the BE API
                  const name = filtersMapping[filterName] || filterName

                  // Extract the filter's value
                  normalized[name] = filters[filterName].value
                  return normalized
                }, {}),
              }),
              state: getState(),
              props,
            }),
            disableObsoleteFlow: true,
          })
        )
      }),
    downloadArchive: (id) => dispatch(downloadArchive(id)),
    dispatch,
    onHandleSubmit: (data) =>
      dispatch(updatePayrollInstances(data)).then(
        (res) => res,
        // eslint-disable-next-line handle-callback-err
        (err) => {
          return { hasError: true }
        }
      ),
    onModalStateChange: ({
      showConfirmLock = false,
      showConfirmUnlock = false,
      heading = '',
      reimportGTNConfirmationModal = false,
      successLockWithGTNReimport = false,
      documentId = null,
      documentName = null,
      documentVersion = null,
    }) => {
      modalState = {
        showConfirmUnlock: showConfirmUnlock,
        showConfirmLock: showConfirmLock,
        modalHeading: heading,
        reimportGTNConfirmationModal: reimportGTNConfirmationModal,
        documentId: documentId,
        successLockWithGTNReimport: successLockWithGTNReimport,
        documentName: documentName,
        documentVersion: documentVersion,
      }
    },
    onZeroTouchSubmit: (data) =>
      dispatch(triggerZeroTouch(data)).then(
        (res) => res,
        (err) =>
          dispatch(
            showMessage({
              body: errorToString(err) || 'Something went wrong.',
            })
          )
      ),
    onPinRow: (id, pinned) => {
      return dispatch(pinPayroll(id, !pinned)).then((r) => {
        dispatch(invalidatePayrollInstancesByStatus('active'))
        dispatch(invalidatePayrollInstancesByStatus('previousWithExtraTasks'))
        dispatch(fetchPayrollInstances({ filter: props.filters.payrollInstances }))
      })
    },
  }
}

const mapStateToProps = (state, props) => {
  let filterName = buildFilterName(props)
  let selectedFilterCompanies = []
  const isFetchedAtLeastOnce = state.payrollInstances.filters[filterName] && state.payrollInstances.filters[filterName].ids

  if (!isFetchedAtLeastOnce) return { isInitialFetching: true }

  const filter = props.filters.payrollInstances
  const {
    totalCount,
    params: { offset, limit, payroll, country },
  } = state.payrollInstances.filters[filter.name]

  let payrollInstances = []
  try {
    payrollInstances = getFilteredPayrollInstances(state, {
      filter: filter.name,
    })
  } catch (error) {
    // Wait on Payroll Instance to be completed
  }

  // This list of payrolls determines the filter contents on payrun tables
  // The Closed Payrun table filters have to include all payrolls on the table
  let payrolls = props.payrunState === payrunStatesEnum.PREVIOUS ? getPayrolls(state) : getPayrollsWithActiveOrLockedPayrollInstances(state)

  if (filter.params.country) {
    selectedFilterCompanies = getCompaniesByCountry(state, { countryId: filter.params.country }).map((data) => data.id)
    payrolls = payrolls.filter((p) => selectedFilterCompanies.includes(p.company))
  }

  const payrollsArrayFilterData = payrolls.map((p) => {
    return {
      value: p.id,
      label: p.name,
    }
  })

  const allCompaniesByPayrolls = [...new Set(payrolls.map((p) => p.company))]

  let countriesArrayFilterData = getCountriesByCompanies(state, { companiesIds: allCompaniesByPayrolls })
  if (filter.params.payroll) {
    const countryId = payrollInstances.length && payrollInstances[0].countryId ? payrollInstances[0].countryId : null
    countriesArrayFilterData = countriesArrayFilterData.filter((country) => country.id === countryId)
  }
  countriesArrayFilterData = countriesArrayFilterData.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const allCompanies = getCompaniesRef(state, props)
  const allCompaniesCountries = getCountriesByCompanies(state, { companiesIds: allCompanies.map((c) => c.company) })
    .map((c) => ({ value: c.id, label: c.name }))
    .sort(sortByLabel)

  const analyzeBtnUrl = isFinanceAdmin(state) || isAccounting(state) ? ALL_ROUTES.PAYRUNS.PAYROLL_SUMMARY : ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION
  const hasUnlockedInstances = payrollInstances.some((payrollInstance) => !payrollInstance.newLockUnlock.isPayrollInstanceLocked)

  return {
    payrollInstances: payrollInstances,
    // TODO - Here we have to check by specific permission, instead of a role.
    // But we do it as a hot fix, because we have to deploy it live asap.
    selectedPayroll: payroll,
    selectedCountry: country,
    hasAccess: !isFinanceAdmin(state) && !isAccounting(state),
    hasUnlockedInstances,
    payrolls: payrollsArrayFilterData,
    countries: countriesArrayFilterData.sort(sortByLabel),
    allCompaniesCountries,
    canPinPayroll: props.payrunState === payrunStatesEnum.ACTIVE,
    requireConfirmBeforeUnPin: props.payrunState !== payrunStatesEnum.ACTIVE,
    filter: filter,
    analyzeBtnUrl,
    pagination: {
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit: limit,
      totalCount: totalCount,
    },
    modalState: modalState,
  }
}

const ConditionalLoader = (Component, conditionalProp) => (props) => (props[conditionalProp] ? <Loader /> : <Component {...props} />)

const Component = connect(mapStateToProps, mapDispatchToProps)(ConditionalLoader(PayrollInstanceTable, 'isInitialFetching'))

export default Fetcher(
  Component,
  [
    'payrolls',
    'countries',
    {
      name: 'employeeSystemUsers',
      params: [
        {
          _computed: {
            filter: (state) => createFilter({ id: state.auth.userId }),
          },
        },
      ],
    },
    {
      name: 'payrollInstances',
      params: [
        {
          _computed: {
            filter: (state, props) => {
              let filterName = buildFilterName(props)

              const isFetchedAtLeastOnce = state.payrollInstances.filters[filterName] && !!state.payrollInstances.filters[filterName].ids
              // If payrollInstances are already fetched (for example in the case the payrollInstances are invalidated),
              // we don't pass any filter params and we expect/delegate `_createFilter` to refetch the payrollInstances
              // using the filter parameters already cached in the Store/state.
              if (isFetchedAtLeastOnce) return _createFilter({ state, props })

              //  On initial loading (payrollInstances are not fetched at least once),
              // then we predefine the filter parameters
              return _createFilter({
                page: 0,
                limit: props.payrunState === payrunStatesEnum.INACTIVE || props.payrunState === payrunStatesEnum.PREVIOUS ? 10 : 20,
                filters: {},
                state,
                props,
              })
            },
          },
          disableObsoleteFlow: true,
        },
      ],
    },
  ],
  { renderWhileFetching: true, showLoader: false }
)
