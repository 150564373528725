import createSelector from 'utils/createSelector'
import { isCot } from 'redux/selectors/auth'
import { getCountriesIds } from 'redux/selectors/filters'
import { flatten } from 'lodash'
import moment from 'moment'
import { sortByName, trimString } from 'utils/strings'

const companyId = (state, props) => parseInt(props.companyId)
const countryId = (state, props) => parseInt(props.countryId)
const getCompanyProcessId = (state, props) => parseInt(props.companyProcessId)

// TODO - This can be a local selector, to Company route, but has to be checked
export const getCompany = createSelector(companyId, ({ Company, EmployeeSystemUser }, companyId) => {
  const company = Company.withId(companyId)

  const globalOwnersList = company.globalOwner
    .map((ownerId) => {
      const employee = EmployeeSystemUser.withId(ownerId)
      return employee ? employee.fullname : null
    })
    .filter(Boolean)
    .join(', ')

  return {
    ...company.ref,
    ...(company.hq ? { hq: company.hq.ref } : {}),
    isHQ: company.isHQ(),
    director: company.getDirector(),
    country: { ...company.country.ref },
    currency: { ...company.currency.ref },
    setupDateInPayslipFormatted: company.setupDateInPayslip ? moment(company.setupDateInPayslip).format('DD/MM/YYYY') : null,
    ...(company.reportingCurrency ? { reportingCurrency: company.reportingCurrency.ref } : {}),
    globalOwnersList,
  }
})

export const getCompanies = createSelector(({ Company }) => {
  return Company.all().toModelArray().map(_getCompany)
})

export const getAccessibleCompanies = createSelector(({ Company }) => {
  return Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()
    .map(_getCompany)
})

export const getAccessibleCompaniesRef = createSelector(({ Company }) => {
  return Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()
    .map((company) => ({ ...company.ref }))
})

export const getCompaniesWithCountry = createSelector(({ Company }) => {
  return Company.all()
    .toModelArray()
    .map((company) => ({
      ...company.ref,
      country: { ...company.country.ref },
    }))
})

export const getAccessibleCompaniesWithCountry = createSelector(({ Company }) => {
  return Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()
    .map((company) => ({
      ...company.ref,
      country: { ...company.country.ref },
    }))
})

const _getCompany = (Company) => ({
  ...Company.ref,
  director: Company.getDirector(),
  isHQ: Company.isHQ(),
  country: {
    abbreviature: Company.country.abbreviature,
    name: Company.country.name,
    id: Company.country.id,
  },
})

// TODO - This can be a local selector, to Company route, but has to be checked
export const getCompanyRef = createSelector(companyId, ({ Company }, companyId) => {
  const company = Company.withId(companyId)

  return {
    ...company.ref,
    isHQ: company.isHQ(),
  }
})

export const getCompaniesRef = createSelector(({ Company }) => {
  return Company.all()
    .toRefArray()
    .map((company) => ({ ...company }))
})

export const getCompanyWithCountry = createSelector(companyId, ({ Company }, companyId) => {
  const company = Company.withId(companyId)

  return {
    ...company?.ref,
    country: { ...company?.country.ref },
  }
})

/**
 * Get Companies by country id
 *
 * @param {SessionBoundModel} Country
 * @param {Number} countryId
 * @private
 */
export const _getCompaniesByCountry = (Country, countryId) => Country.withId(countryId).companies.toModelArray()

export const getCompaniesByCountry = createSelector(countryId, ({ Country }, countryId) => {
  return _getCompaniesByCountry(Country, countryId).map((company) => ({ ...company.ref }))
})

export const getCompaniesByCountries = createSelector(getCountriesIds, ({ Country }, countriesIds) => {
  let companies = countriesIds.map((id) => {
    return _getCompaniesByCountry(Country, id).map((company) => ({ ...company.ref }))
  })
  return flatten(companies).sort(sortByName)
})

export const getAccessibleCompaniesByCountries = createSelector(getCountriesIds, ({ EmployeeSystemUser, Company }, countriesIds) => {
  const companies = Company.all()
    .filter((company) => company.isAccessable)
    .toRefArray()

  return companies.filter((company) => countriesIds.includes(company.country)).map((company) => ({ ...company }))
})

export const getCompanyByCompanyProcess = createSelector(getCompanyProcessId, ({ CompanyCountryProcess }, companyProcessId) => {
  const process = CompanyCountryProcess.withId(companyProcessId)

  return { ...process.companyService.company.ref }
})

export const getCompaniesByAuth = (state) => (isCot(state) ? getCompanies(state) : getAccessibleCompanies(state))
export const getCompaniesByCountriesByAuth = (state, props) => {
  return isCot(state) ? getCompaniesByCountries(state, props) : getAccessibleCompaniesByCountries(state, props)
}

// Get passed 'companies' array, sorted alphabetically
export const getSortedAccessibleCompanies = (companies) =>
  companies.sort((a, b) => {
    if (a.country.name.toLowerCase() === b.country.name.toLowerCase()) {
      return trimString(a.name) > trimString(b.name) ? 1 : -1
    }
    return a.country.name.toLowerCase() > b.country.name.toLowerCase() ? 1 : -1
  })
