import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { getCountries } from 'routes/Countries/selectors/countries'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchVendorCountryServicePivotIfNeeded } from 'redux/actions/vendorCountryServicePivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchProcessesIfNeeded } from 'redux/actions/processes'
import { fetchStepsIfNeeded } from 'redux/actions/steps'
import { fetchTasksIfNeeded } from 'redux/actions/tasks'
import { fetchCountryProcessPivotIfNeeded } from 'redux/actions/countryProcessPivot'
import { fetchCountryStepPivotIfNeeded } from 'redux/actions/countryStepPivot'
import { fetchCountryTaskPivotIfNeeded } from 'redux/actions/countryTaskPivot'
import { fetchVendorCountryTaskPivotIfNeeded } from 'redux/actions/vendorCountryTaskPivot'
import CostingView from '../components/CostingView'
import { getVendorServices } from 'redux/selectors/vendorCountryServices'
import { isCotOem } from 'redux/selectors/auth'

class SLAsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCountriesIfNeeded()
    this.props.fetchVendorsIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchServicesIfNeeded()
    this.props.fetchProcessesIfNeeded()
    this.props.fetchStepsIfNeeded()
    this.props.fetchTasksIfNeeded()
    this.props.fetchCountryProcessPivotIfNeeded()
    this.props.fetchCountryStepPivotIfNeeded()
    this.props.fetchCountryTaskPivotIfNeeded()
    this.props.fetchVendorCountryTaskPivotIfNeeded()
  }

  render () {
    return <CostingView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
    fetchProcessesIfNeeded: () => dispatch(fetchProcessesIfNeeded()),
    fetchStepsIfNeeded: () => dispatch(fetchStepsIfNeeded()),
    fetchTasksIfNeeded: () => dispatch(fetchTasksIfNeeded()),
    fetchCountryProcessPivotIfNeeded: () => dispatch(fetchCountryProcessPivotIfNeeded()),
    fetchCountryStepPivotIfNeeded: () => dispatch(fetchCountryStepPivotIfNeeded()),
    fetchCountryTaskPivotIfNeeded: () => dispatch(fetchCountryTaskPivotIfNeeded()),
    fetchVendorCountryTaskPivotIfNeeded: () => dispatch(fetchVendorCountryTaskPivotIfNeeded()),
  }
}

const mapStateToProps = (state, props) => {
  const {
    vendors,
    services,
    vendorCountryServicePivot,
    countryServicePivot,
    processes,
    steps,
    tasks,
    countryProcessPivot,
    countryStepPivot,
    countryTaskPivot,
    vendorCountryTaskPivot,
  } = state

  if (
    isFetching([
      vendors,
      services,
      vendorCountryServicePivot,
      countryServicePivot,
      processes,
      steps,
      tasks,
      countryProcessPivot,
      countryStepPivot,
      countryTaskPivot,
      vendorCountryTaskPivot,
    ])
  ) {
    return { isFetching: true }
  }

  const vendorId = props.match.params.vendorId
  const vendorServices = getVendorServices(state, { vendorId: props.match.params.vendorId })

  const countryData = getCountries(state)

  return {
    vendorId,
    vendorServices,
    countries: countryData,
    isCotOemUser: isCotOem(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SLAsContainer)
