import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import SSO from './SSO'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import PasswordInputField from 'components/form/PasswordInputField'

class LoginView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // is2FactorAuthenticationVisible flag, controls whether the 2FA fields are shown or hidden.
      // If props.isSSOEnabled is true, 2FA fields should be hidden and vide versa
      is2FactorAuthenticationVisible : !props.isSSOEnabled
    }
  }

  showFields = () => {
    this.setState({ is2FactorAuthenticationVisible : true })
  }

  render () {
    const { is2FactorAuthenticationVisible } = this.state

    return <div>
      { this.props.isSSOEnabled && !is2FactorAuthenticationVisible && (
        <div className='c-login__content-sso'>
          <div className=' o-grid o-grid--center'>
            <SSO url={this.props.SSOurl} />
          </div>

          <div className='o-layout o-grid o-grid--center '>
            <div className='u-margin-bottom'>
              <p className='u-text--medium u-text--center'>
                Don't have access to SSO (Single Sign On)?<br />
                <span onClick={() => this.showFields()}
                  className='u-text--curious u-cursor--pointer' data-testid='show-2fa-login'>
                  Click here</span> to Log in with 2FA (Two Factor Authentication)
              </p>
            </div>
          </div>
        </div>
      )}
      {
        is2FactorAuthenticationVisible &&
        <div>
          <div className='o-grid o-grid--center u-padding-top-large'>
            <Form showSubmitBtn colorClassBtn='c-btn--curious' btnText='Login' btnFullWidth classNames='c-btn--rounded' {...this.props}>
              <div
                title='Your username is your email, unless changed.'
                className='o-form-group u-margin-bottom-small'>
                <Field
                  name='username'
                  label='Username'
                  component={InputField}
                  type='text'
                  autoFocus
                  placeholder='Your username is your email, unless changed.'
                  className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
                  labelClassName='c-label u-text--curious'
                />
              </div>
              <div className='o-form-group u-margin-bottom-large u-padding-top-small'>
                <Field
                  name='password'
                  label='Password'
                  component={PasswordInputField}
                  type='password'
                  className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
                  labelClassName='c-label u-text--curious'
                  autocomplete='off'
                />
              </div>
            </Form>
          </div>
          <div className='o-grid o-grid--center u-margin-top'>
            <div className='u-text--center'>
              <Link to='/forgotten-password' className='u-text--small u-text--curious'>Forgotten password?</Link>
            </div>
          </div>

          {/* TODO - iva - remove the rocket before merging */}
          {/* <div className='o-grid o-grid--center u-margin-top' style={{ position: 'fixed', top: '0', left: '20px' }}>
            <div className='u-text--center'>
              <Link to='/rhsso' className='u-text--small u-text--curious'>&#128640;</Link>
            </div>
          </div> */}
        </div>
      }
    </div>
  }
}

export default reduxForm({
  form: 'login', // a unique identifier for this form
  destroyOnUnmount: false
})(LoginView)
