/**
 * * This file holds dates used by the yearly calendar and future payruns features
 */
// Calendar Year component requires a start date to show the whole year
// Also used for the yearly calendar header, and to filter holiday lists
export const currentDate = new Date()
export const calendarYear = currentDate.getFullYear()
export const calendarNextYear = calendarYear + 1

export const getSelectedYearStartDate = (year) => {
  if (!year) {
    const date = new Date()
    date.setDate(1)
    date.setMonth(0)
    return date
  }
  return new Date(`${year}-01-01T00:00:00`)
}

export const getCalendarEventFiltersForYear = (year) => {
  const startDate = `${+year}-01-01`
  const endDate = `${+year + 1}-02-01`
  return {
    fromDate: startDate,
    toDate: endDate
  }
}

export const shouldShowNextYear = (cutoffMonth = 9) => {
  const currentMonth = currentDate.getMonth()
  // Months are zero indexed, so >= 9 means from october 1st to end of year
  return currentMonth >= cutoffMonth
}

// When fetching future payruns, BE requires reportTo filter to add a boundary to the results in the future
export const getFuturePayrunsReportToFilterDate = `${calendarNextYear}-12-31`
