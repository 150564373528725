import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import {
  downloadEmployeeTermsTemplate,
  downloadEmployeeTermsWrongImportedFile,
} from 'redux/actions/employeeTermPivot'
import { importEmployeeTerms } from 'redux/actions/employeeTermPivotEnhanced'
import { showMessage } from 'redux/actions/modal'
import SectionHeading from 'components/SectionHeading'

const TemplateClarifications = () => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading fullWidth text='Update employees’ fixed pay elements and recurring contributions/deductions in bulk' />
    <p className='u-margin-bottom-small'>
      With this template you can update employees’ fixed/permanent pay elements and recurring contributions deductions. The generated template will include only
      employees present in the active payrun.
    </p>
    <ul className='margin-bottom-small'>
      <li>Select company and payroll and download template</li>
      <li>Complete data</li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
    </ul>
    <u>Error message</u>: This happens when there are errors in the upload file. You can view details on the import page. Fix the errors (you can work both with
    original or error file) and attempt the upload again.
  </div>
)

const RouteView = props => <ImportFacade
  {...props}
  heading='Import employee terms'
  templateSubHeading='Please be advised that only employees
  present in the current payrun will be included in the template.'
  TemplateClarifications={TemplateClarifications}
  entityName='employee terms'
  downloadTemplateAction={({ payrollId }) => dispatch => (
    dispatch(downloadEmployeeTermsTemplate(payrollId))
      .then(() => dispatch(showMessage({
        body: 'Please note that if you are making Annual Salary changes ' +
          'you need to update effective date and Basic Pay / Salary value as well.'
      })))
  )}
  downloadAction={downloadEmployeeTermsWrongImportedFile}
  importAction={({ payrollId, file }) => importEmployeeTerms(payrollId, file)}
/>

export default RouteView
