import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ImportFile from '../components/ImportFile'
import Fetcher from 'containers/Fetcher'
import { getPayrollRef } from 'redux/selectors/payroll'
import { getQuery } from 'utils/query'
import { removeItemFromLocalStorage } from 'utils/storage'
import { getJobResult } from 'utils/job'

const AsyncImportContainer = ({ payrollId, shouldUseCompanyAsFilter, location, importAction, downloadAction, companyId }) => {
  const { jobId } = getQuery(location.search, 'jobId')
  const dispatch = useDispatch()

  const savedJobDetails = getJobResult(jobId)

  const { failure, success, total = null } = savedJobDetails?.jobResult?.additionalInfo || {}
  const errors = savedJobDetails?.jobResult?.errors || null
  const { url } = savedJobDetails?.jobResult?.data || {}

  const showConfirmation = useSelector((state) =>
    payrollId && !shouldUseCompanyAsFilter ? getPayrollRef(state, { payrollId }).hasLockedPayrollInstances : false
  )

  const getFile = (link) => {
    dispatch(downloadAction(link))
  }

  const importFile = (file) => {
    removeItemFromLocalStorage('jobResult')
    return dispatch(importAction({ payrollId, companyId, file, shouldUseCompanyAsFilter }))
  }

  return (
    <ImportFile
      failure={failure}
      success={success}
      total={total}
      errors={errors}
      url={url}
      showConfirmation={showConfirmation}
      importFile={importFile}
      getFile={getFile}
      payrollId={payrollId}
      shouldUseCompanyAsFilter={shouldUseCompanyAsFilter}
    />
  )
}

AsyncImportContainer.propTypes = {
  payrollId: PropTypes.number,
  importAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func,
  shouldUseCompanyAsFilter: PropTypes.bool,
  location: PropTypes.object.isRequired,
  companyId: PropTypes.number,
}

export default Fetcher(AsyncImportContainer, ['payrolls'])
