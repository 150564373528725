import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import InnerWrapper from '../components/InnerWrapper'
import { connect, useDispatch } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { showMessage } from 'redux/actions/modal'
import { createFilter } from 'utils/redux/filter'
import { getVendorRestrictedStatus } from 'redux/selectors/vendorSwitch'
import { fetchPayrollInstancesIfNeeded, clearErrorsPayrollInstances } from 'redux/actions/payrollInstances'

const InnerWrapperContainer = props => {
  const [ showExtraPayrunsTab, setShowExtraPayrunsTab ] = useState(false)
  const dispatch = useDispatch()

  const { isVendorRestricted = true } = props

  useEffect(() => {
    if (!isVendorRestricted) {
      dispatch(async (dispatch, getState) => {
        const payrollInstancesFilter = createFilter({
          payrunState: 'previousWithExtraTasks',
          company: props.match.params.companyId
        })

        await dispatch(fetchPayrollInstancesIfNeeded({
          forceVendorTenantAPI: true,
          filter: payrollInstancesFilter
        }))
        const state = getState()
        const filter = payrollInstancesFilter
        const { ids } = state.payrollInstances.filters[filter.name]
        setShowExtraPayrunsTab(ids?.length > 0)
      })
    }
  }, [isVendorRestricted])

  return <InnerWrapper showExtraPayrunsTab={showExtraPayrunsTab} {...props} />
}

InnerWrapperContainer.propTypes = {
  isVendorRestricted: PropTypes.bool,
  match: PropTypes.object
}

const mapStateToProps = (state, props) => {
  const { match: { params: { vendorId, tenantId, companyId } }, isFetching, dispatch, history } = props

  const isVendorRestricted = getVendorRestrictedStatus(state, { vendorId, companyId })

  if (state.payrollInstances.errorPayload) {
    dispatch(showMessage({
      body: state.payrollInstances.errorPayload.errors
    }))
    history.push(`/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}/details/payruns/documents`)
    dispatch(clearErrorsPayrollInstances())
  }
  return {
    companyId,
    vendorId,
    tenantId,
    isVendorRestricted,
    isFetching,
    showDocuments: true,
  }
}

const Component = connect(mapStateToProps)(InnerWrapperContainer)

export default Fetcher(Component, [
  { name: 'companies', params: [{ forceVendorTenantAPI: true }] },
  { name: 'vendorSwitch', params: [{ forceVendorTenantAPI: true }] },
  { name: 'companyServicePivot', params: [{ forceVendorTenantAPI: true }] },
])
