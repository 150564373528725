import React from 'react'
import { isEmpty } from 'utils/fnkit/typeChecks'

import { errorId } from '@rjsf/utils'

export const ErrorListTemplate = () => null
export const FieldErrorTemplate = ({ errors = [], idSchema }) => {
  if (isEmpty(errors)) {
    return null
  }
  const id = errorId(idSchema)
  return (
    <div id={id}>
      {errors
        .filter((elem) => !!elem)
        .map((error, index) => (
          <span className='error first-letter-uppercase' key={index}>
            {`${error?.charAt(0)?.toUpperCase()}${error?.slice(1)}`}
          </span>
        ))}
    </div>
  )
}
