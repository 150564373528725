import { DatePickerColumnFilter, SelectColumnFilter } from 'components/table/filters'
import { subTypesFilterClasses } from 'redux/filters/createDocumentFilter'
import { documentTypeOptionsWithIds } from 'utils/enums/documentTypes'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import {
  categoryLabels,
  glStatusLabels,
  gtnImportStatusLabels,
  inputsValidationReportStatusLabels,
  textLabels,
  uploadTextLabels,
} from 'utils/locales/common.en'
import { documentTypeLabels } from 'utils/locales/document.en'
import { tablePlaceholderLabels } from 'utils/locales/table.en'
import { cellRenderAction, dateTimePayslipTable, downloadGTNFile, downloadOnPayslipTable } from 'utils/tableDataFormatters'
import { gtnValidationStatusEnums } from 'utils/enums/gtnValidationEnums'
import { getDocumentTypesByTabName, omitDocTypeIdsForICP, omitDocTypeIdsForKU } from 'utils/documentTypes'

const documentGtnImportStatusOptions = [
  { value: 'null', label: gtnImportStatusLabels.none },
  { value: gtnImportStatusLabels.successful, label: gtnImportStatusLabels.successful },
  { value: gtnImportStatusLabels.unsuccessful, label: gtnImportStatusLabels.unsuccessful },
]

const gtnValidationStatusOptions = [
  { value: gtnValidationStatusEnums.pendingValidation, label: gtnValidationStatusEnums.pendingValidation },
  { value: gtnValidationStatusEnums.validated, label: gtnValidationStatusEnums.validated },
  { value: gtnValidationStatusEnums.overwritten, label: gtnValidationStatusEnums.overwritten },
  { value: gtnValidationStatusEnums.failedValidation, label: gtnValidationStatusEnums.failedValidation },
  { value: gtnValidationStatusEnums.error, label: gtnValidationStatusEnums.error },
  { value: gtnValidationStatusEnums.notApplicable, label: gtnValidationStatusEnums.notApplicable },
]

const getDocumentTypeOptions = ({ tabName, isKU = false, isVendorUser = false }) => {
  if (!tabName) return []
  const docTypes = getDocumentTypesByTabName(tabName)

  return documentTypeOptionsWithIds
    .filter((option) => docTypes.includes(option.label))
    .filter((doctype) => {
      if (isKU) return !omitDocTypeIdsForKU.includes(doctype.value)
      if (isVendorUser) return !omitDocTypeIdsForICP.includes(doctype.value)
      return true
    })
}

export const documentTableHeadingTypes = Object.freeze({
  name: 'name',
  description: 'description',
  documentType: 'documentType',
  version: 'version',
  uploadedOn: 'uploadedOn',
  uploadedBy: 'uploadedBy',
  action: 'action',
  importStatus: 'importStatus',
  glStatus: 'glStatus',
  inputsValidationReportStatus: 'inputsValidationReportStatus',
  gtnReImport: 'gtnReimport',
  category: 'category',
  payrollName: 'payrollName',
  gtnValidationStatus: 'gtnValidationStatus',
})

export const staticDocsTableOpts = {
  noDataMessage: tablePlaceholderLabels.noDataMessage,
  sizePerPageList: [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ],
  showPageSizeSelector: true,
}

const createFullHeadingList = ({ labels, handleDownloadDocument, DocumentActions, onOverrideClick = null, tabName, isKU, isVendorUser }) => {
  const isGTNTab = tabName === 'payrollGtnDocTypes'
  return [
    new TableHeading({
      accessor: documentTableHeadingTypes.name,
      heading: textLabels.name,
      className: 'text-left',
      columnClassName: 'text-left',
      setWidth: '280px',
      Cell: isGTNTab ? downloadGTNFile : downloadOnPayslipTable,
      onClick: handleDownloadDocument,
      onOverrideClick,
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.description,
      heading: textLabels.description,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      setWidth: '140px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.documentType,
      heading: textLabels.type,
      className: 'text-left',
      columnClassName: 'text-left',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: 'docType',
        options: getDocumentTypeOptions({ tabName, isKU, isVendorUser }),
      },
      disableSortBy: false,
      disableFilters: false,
      setWidth: '110px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.version,
      heading: labels?.[documentTableHeadingTypes.version] || textLabels.version,
      className: 'text-center',
      setWidth: labels?.[documentTableHeadingTypes.version] ? '128px' : '90px',
      columnClassName: 'text-center',
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.gtnValidationStatus,
      heading: textLabels.gtnValidationStatus,
      className: 'u-text--center',
      columnClassName: 'u-text--center',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.gtnValidationStatus,
        options: gtnValidationStatusOptions,
      },
      disableSortBy: false,
      disableFilters: false,
      setWidth: '135px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.category,
      heading: textLabels.category,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.category,
        options: [
          { value: subTypesFilterClasses.company, label: categoryLabels.company },
          { value: subTypesFilterClasses.payroll, label: categoryLabels.payroll },
        ],
      },
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.payrollName,
      heading: textLabels.payrollName,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.importStatus,
      heading: documentTypeLabels.gtnImportStatus,
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.importStatus,
        options: documentGtnImportStatusOptions,
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.glStatus,
      heading: textLabels.status,
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.glStatus,
        options: [
          { value: 'Balanced', label: glStatusLabels.balanced },
          { value: 'Not Balanced', label: glStatusLabels.notBalanced },
        ],
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.inputsValidationReportStatus,
      heading: textLabels.status,
      setWidth: '120px',
      Filter: SelectColumnFilter,
      dropDownOptions: {
        name: documentTableHeadingTypes.inputsValidationReportStatus,
        options: [
          { value: 'Blocked', label: inputsValidationReportStatusLabels.blocked },
          { value: 'Error', label: inputsValidationReportStatusLabels.error },
          { value: 'Success', label: inputsValidationReportStatusLabels.success },
          { value: 'Warning', label: inputsValidationReportStatusLabels.warning },
        ],
      },
      disableSortBy: false,
      disableFilters: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.uploadedOn,
      heading: uploadTextLabels.uploadedOn,
      className: 'text-left',
      columnClassName: 'text-left',
      Cell: dateTimePayslipTable,
      Filter: DatePickerColumnFilter,
      disableSortBy: false,
      disableFilters: false,
      setWidth: '150px',
      datePickerOptions: {
        isClearable: true,
      },
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.uploadedBy,
      heading: uploadTextLabels.uploadedBy,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      disableFilters: false,
      setWidth: '150px',
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.gtnReImport,
      heading: documentTypeLabels.gtnImport,
      headerAlign: 'left',
      setWidth: '50px',
      classNames: 'u-text--center',
      columnClassName: 'u-text--center',
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: documentTableHeadingTypes.action,
      heading: '',
      className: 'text-center',
      columnClassName: 'text-right',
      disableSortBy: true,
      Cell: cellRenderAction({ Actions: DocumentActions }),
      setWidth: '180px',
    }),
  ]
}

export const buildDocumentTableHeadings = (omittedColumns = [], options = {}) =>
  createFullHeadingList(options)?.filter(({ accessor }) => !omittedColumns.includes(accessor))

export const normalizeDocumentTableFilters = (filters = []) =>
  filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.id]: filter.value,
    }),
    {}
  )

export const getDocFilterNames = (tableHeadings = []) =>
  tableHeadings.reduce((acc, heading) => {
    if (!heading?.disableFilters) {
      acc.push(heading.accessor)
    }
    return acc
  }, [])
