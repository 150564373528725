import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_PROCESS_FETCH = 'COUNTRY_PROCESS_FETCH'
export const COUNTRY_PROCESS_RECEIVE = 'COUNTRY_PROCESS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_PROCESS_FETCH,
  receive: COUNTRY_PROCESS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'countryProcessPivot', 'countryprocesses', 'CountryProcess')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryProcessPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryProcessPivot = actionUtility.fetchEntities
