import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstances'

class PayrollInstance extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      [Model.actionTypes.syncPayrollInstanceTerms]: (Model, { payload: { id, data } }) => {
        Model.withId(id).payrollInstanceCompanyCountryTerms.filter(entity => (
          !data.hasOwnProperty(entity.companyCountryTerm)
        )).delete()
      },
      [Model.actionTypes.detachEmployees]: (Model, { payload: { payrollInstanceId, data } }) => {
        Model.withId(payrollInstanceId).payrollInstanceEmployees.filter(entity => {
          return data.hasOwnProperty(entity.employee)
        }).delete()
      },
    }
  }

  /**
   * Checks if the last PI Note is added by other User.
   *
   * @param {String} tenant - Notes are being added from different tenants,
   * because of this we have to compare the `tenant` value too.
   * Otherwise there may be duplicated Owners ids and the method won't work correctly.
   * @param {Number} loggedUserId
   * @returns {boolean}
   */
  isLastAddedNoteFromOtherUser (tenant, loggedUserId) {
    const notes = this.notes.toRefArray()
    const lastNote = notes[notes.length - 1]

    if (!lastNote) return false

    return lastNote.ownerEm !== tenant || lastNote.ownerId !== loggedUserId
  }

  /**
   * Is PI active?
   * @returns {boolean}
   */
  isActive () {
    return this.status === 'active'
  }

  /**
   * Is PI locked for changes?
   * @returns {boolean}
   */
  isLocked () {
    return ['sent-and-locked', 'gross-to-net-calculations-and-review-done',
      'cot-final-payroll-reports-review-done'].includes(this.status)
  }
}
PayrollInstance.modelName = 'PayrollInstance'

PayrollInstance.fields = {
  id: attr(),
  payroll: fk('Payroll', 'payrollInstances'),
  currency: fk('Currency', 'payrollInstances'),
}
PayrollInstance.actionTypes = actionTypes

export default PayrollInstance
