import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchEmployeeAttendancePeriodsIfNeeded, fetchEmployeeAttendancePeriods, deleteEmployeeAttendancePeriod } from 'redux/actions/employeeAttendancePeriods'
import { fetchEmployeeAttendance } from 'redux/actions/employeeAttendance'
import LeaveDelete from '../components/LeaveDelete'

class LeaveDeleteContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchEmployeeAttendancePeriodsIfNeeded()
  }

  render () {
    return <LeaveDelete {...this.props} />
  }
}

LeaveDeleteContainer.propTypes = {
  // What to happen on successful deletion
  onSubmit: PropTypes.func.isRequired,
  // Note <Bobby>: I'm removing this as required because,
  // when we delete an item, it no longer exists and
  // returns a warning
  attendancePeriodId: PropTypes.string
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, props) => {
  const employeeId = parseInt(props.match.params.employeeId)

  return {
    fetchEmployeeAttendancePeriodsIfNeeded: () => dispatch(fetchEmployeeAttendancePeriodsIfNeeded(employeeId)),
    onSubmit: ({ attendancePeriodId }) => {
      dispatch(deleteEmployeeAttendancePeriod(attendancePeriodId)).then(() => {
        props.onSubmit()
        dispatch(fetchEmployeeAttendancePeriods(employeeId))
        dispatch(fetchEmployeeAttendance())
      })
    }
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { employeeAttendancePeriods } = state
  const { attendancePeriodId } = props

  if (isFetching([employeeAttendancePeriods])) return { isFetching: true }

  return {
    initialValues: {
      attendancePeriodId
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'employeeLeaveDelete'
})(LeaveDeleteContainer))
