import { canRefreshToken } from '../actions/auth'
import { isExpired } from 'utils/jwt'

/**
 * Middleware that handles JWT refreshing
 *
 * It works in the following flow:
 * 1. The middleware is activated before any action creator / API call (e.g. dispatch(fetchCountriesIfNeeded()).
 * 2. It checks if JWT is expired.
 * 3. If JWT is NOT expired, then the action creator calls are being proceed (as usual).
 * 4. Otherwise, if JWT IS expired, then we trigger `refreshToken` API CALL for getting a new token.
 * 4.1 While waiting for the token, all upcoming action creator calls are kept in a queue and executed later,
 * once the token is received.
 * 4.2 If the refresh token API request fails, then we reset the Store and the user is being logged out.
 * Possible fail request reasons are - expired refresh token or blacklisted token.
 *
 * It's important jwt middleware to be placed before thunk middleware, because thunk decorates action creator calls,
 * and we can't determine which requests are async!
 *
 * @url https://stackoverflow.com/questions/36948557/how-to-use-redux-to-refresh-jwt-token
 * @param dispatch
 * @param getState
 * @returns {function(*=): function(*=)}
 */
export default ({ dispatch, getState }) => {
  return (next) => (action) => {
    // Only worry about for refreshing the token when:
    // 1. We call async actions. Most of our API requests, are wrapped in action creator functions,
    // therefore we check the following: `typeof action === 'function'`.
    // 2. Check we don't explicitly disable JWT refreshing with: `disableJWT`.
    // For example when we logout the user, we should not refresh the token.
    // Logout is async, because it calls the API to invalidate the token.
    // 3. Make sure we have `accessToken`, because the user may not be logged in AND
    // having `apiUrl` already fetched, in order to know from where to get the token.
    const state = getState()
    const migratedToRHSSO = state.config.migratedToRHSSO
    const needsAccessToken =
      typeof action === 'function' && !action.disableJWT && state.auth && state.auth.accessToken && state.config.apiUrl && !migratedToRHSSO

    if (needsAccessToken) {
      const isRefreshFlow = isExpired(state.auth.accessToken)

      // If the token is expired
      if (isRefreshFlow) {
        // Are we already refresh the token?
        // Basically, if there's a Promise in the Store, then we already had the new token fetched,
        // and we just have to invoke the rest actions in the Promise's queue (then). All's good!
        // But there's a corner case: if the user closes the app, while a new token request is being invoked,
        // and later the user opens the app again, then the `refreshTokenPromise` won't be a Promise anymore,
        // because the Promise is not a serializable type of object.
        // Because of this, we explicitly check:
        // 1. Do we have a saved Promise `refreshTokenPromise) in the Store?
        // 2. And whether `refreshTokenPromise` is still a Promise, because of the corner case mentioned above.
        // * If the user doesn't close the app in this corner case, everything works correctly.
        console.log('Refreshing api token')

        const promise = state.auth.refreshTokenPromise
        const isPromise = promise && typeof promise.then === 'function'

        if (!isPromise) {
          return canRefreshToken(dispatch, getState).then(() => next(action))
        } else {
          return promise.then(() => next(action))
        }
      }
    }
    return next(action)
  }
}
