import React from 'react'
import { range } from 'utils/rangeOfNumbers'
import {
  LEFT_CHEVRON,
  RIGHT_CHEVRON,
  LEFT_DOUBLE_CHEVRON,
  RIGHT_DOUBLE_CHEVRON,
} from 'utils/enums/employeePaginationChevrons'
let classNames = require('classnames')

export const setPaginationControl = (
  totalPages,
  currentPage,
  pageNeighbours,
  totalNumbers,
  totalBlocks
) => {
  if (totalPages === 1) return null

  if (totalPages > totalBlocks) {
    const startPage = Math.max(1, currentPage - pageNeighbours)
    const endPage = Math.min(totalPages, currentPage + pageNeighbours)
    let pages = range(startPage, endPage)

    if (pages.length < 5 && startPage === 1) { pages = range(startPage, startPage + 4) }

    if (pages.length < 5 && startPage > 1) { pages = range(totalPages - 4, endPage) }

    /**
     * hasHiddenPagesLeft: has hidden pages to the left
     * hasHiddenPagesRight: has hidden pages to the right
     * totalHiddenPages: number of hidden pages either to the left or to the right
     */
    const hasHiddenPagesLeft = startPage > 1
    const hasHiddenPagesRight = totalPages - endPage >= 1
    const totalHiddenPages = totalNumbers - (pages.length + 1)

    switch (true) {
    // handle: < {4 5} [6] {7 8} >
    case !hasHiddenPagesLeft && !hasHiddenPagesRight: {
      pages = [LEFT_CHEVRON, ...pages, RIGHT_CHEVRON]
      break
    }

    // handle: << < {3 4} [5] {5 6}
    case hasHiddenPagesLeft && !hasHiddenPagesRight: {
      const extraPages = range(startPage - totalHiddenPages, startPage - 1)
      currentPage <= endPage - 1
        ? (pages = [
          LEFT_DOUBLE_CHEVRON,
          LEFT_CHEVRON,
          ...extraPages,
          ...pages,
          RIGHT_CHEVRON,
        ])
        : (pages = [
          LEFT_DOUBLE_CHEVRON,
          LEFT_CHEVRON,
          ...extraPages,
          ...pages,
        ])
      break
    }

    // handle: {2 3} [4] {5 6} > >>
    case !hasHiddenPagesLeft && hasHiddenPagesRight: {
      const extraPages = range(endPage + 1, endPage + totalHiddenPages)
      currentPage >= startPage + 1
        ? (pages = [
          LEFT_CHEVRON,
          ...pages,
          ...extraPages,
          RIGHT_CHEVRON,
          RIGHT_DOUBLE_CHEVRON,
        ])
        : (pages = [
          ...pages,
          ...extraPages,
          RIGHT_CHEVRON,
          RIGHT_DOUBLE_CHEVRON,
        ])
      break
    }

    // handle: << < {4 5} [6] {7 8} > >>
    case hasHiddenPagesLeft && hasHiddenPagesRight:
    default: {
      pages = [
        LEFT_DOUBLE_CHEVRON,
        LEFT_CHEVRON,
        ...pages,
        RIGHT_CHEVRON,
        RIGHT_DOUBLE_CHEVRON,
      ]
      break
    }
    }

    return [...pages]
  }

  return range(1, totalPages)
}

class Pagination extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      /**
       * Which page will be the next current / active page?
       *
       * In the case we're switching between the pagination pages,
       * there's a little delay when we navigate from one page to another, because of the back-end server response time.
       * Because of this, when the user clicks on a pagination page we mark it as `candidateCurrentPage`,
       * while we're waiting for the back-end response.
       * `candidateCurrentPage` has a special UI styling, indicating that this page will be the next active one.
       * Once the back-end returns the response, this page is now the active one.
       */
      candidateCurrentPage: null,
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (next) {
    // `candidateCurrentPage` gets out of sync, if we change the current page outside of this component.
    // For example if we change it via Search bar filter, the `candidateCurrentPage` stay on the previous clicked item.
    // So if we catch a change of the `currentPage`, then we make sure to reset the `candidateCurrentPage`.
    if (
      this.props.pagination &&
      next.pagination &&
      next.pagination.currentPage !== this.props.pagination.currentPage
    ) {
      this.setState({ candidateCurrentPage: null })
    }
  }

  render () {
    if (!this.props.pagination) return null

    const {
      pagination: { totalPages, currentPage, hidePagination, pageNeighbours },
      filterResults,
    } = this.props
    if (hidePagination) return null

    /**
     * totalNumbers: the total page numbers to show on the control, it is zero based.
     * totalBlocks: blocks to check for the left(<) (<<) and right(>) (>>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 0
    const totalBlocks = totalNumbers + 1

    const pages = setPaginationControl(
      totalPages,
      currentPage,
      pageNeighbours,
      totalNumbers,
      totalBlocks
    )

    return (
      <div className='c-custom-pagination u-float--left u-margin-none'>
        <ul>
          {pages.map((page, index) => {
            if (page === LEFT_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({ candidateCurrentPage: currentPage - 1 })
                    filterResults(currentPage - 2)
                  }}
                >
                  <span aria-hidden='true'>{'<'}</span>
                  <span className='sr-only'>Previous</span>
                </li>
              )
            }
            if (page === LEFT_DOUBLE_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({ candidateCurrentPage: 0 })
                    filterResults(0)
                  }}
                >
                  <span aria-hidden='true'>{'<<'}</span>
                  <span className='sr-only'>Go to first page</span>
                </li>
              )
            }
            if (page === RIGHT_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({ candidateCurrentPage: currentPage + 1 })
                    filterResults(currentPage)
                  }}
                >
                  <span> {'>'} </span>
                  <span className='sr-only'>Next</span>
                </li>
              )
            }
            if (page === RIGHT_DOUBLE_CHEVRON) {
              return (
                <li
                  key={index}
                  className='c-custom-pagination__item u-cursor--pointer'
                  onClick={() => {
                    this.setState({ candidateCurrentPage: totalPages })
                    filterResults(totalPages - 1)
                  }}
                >
                  <span>{'>>'}</span>
                  <span className='sr-only'>Go to last page</span>
                </li>
              )
            }
            if (
              currentPage === page ||
              (totalPages === currentPage &&
                currentPage === page &&
                totalPages > totalBlocks)
            ) {
              return (
                <span
                  key={index}
                  className='c-custom-pagination__item c-custom-pagination__item--active'
                >
                  {page}
                </span>
              )
            }

            return (
              <li
                type='button'
                key={index}
                className={classNames({
                  'c-custom-pagination__item u-cursor--pointer': true,
                  'c-custom-pagination__item--clicked':
                    this.state.candidateCurrentPage === page,
                })}
                onClick={() => {
                  this.setState({ candidateCurrentPage: page })
                  filterResults(page - 1)
                }}
              >
                {page}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Pagination
