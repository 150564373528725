import allCountries from '../allCountries'

const allCountriesOptions = allCountries.map(c => {
  return {
    label: c.name,
    value: c.name
  }
})

export { allCountriesOptions }
