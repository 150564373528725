import { isEmpty } from 'utils/fnkit/typeChecks'

/**
 * Updates the selected state of an option within a selected item.
 *
 * @param {Object} selectedItem - The currently selected item.
 * @param {Object} input - The input event containing the target value and checked state.
 * @param {Array<Object>} items - The list of items to update.
 * @returns {Array<Object>} The updated list of items with the selected state modified.
 */
export const getValuesBySelectedOption = (selectedItem, input, items = []) => {
  const optionValue = input.target.value
  const selected = input.target.checked
  return items.map((item) => {
    if (item.value === selectedItem?.value) {
      return {
        ...item,
        options: item.options.map((option) => (option.value === optionValue ? { ...option, selected } : option)),
      }
    }
    return item
  })
}

/**
 * Updates all options within a selected item based on the select mode.
 *
 * @param {Object} selectedItem - The currently selected item.
 * @param {string} selectMode - The mode to apply (SelectAll or DeSelectAll).
 * @param {Array<Object>} items - The list of items to update.
 * @returns {Array<Object>} The updated list of items with all options selected or deselected.
 */
export const getUpdateOptions = (selectedItem, hasAllSelected, items = []) =>
  items.map((item) => {
    if (item.value === selectedItem?.value) {
      const optValue = !hasAllSelected
      return {
        ...item,
        options: item.options.map((option) => ({ ...option, selected: optValue })),
      }
    }
    return item
  })

/**
 * Checks if all options of a selected item are marked as selected.
 *
 * @param {Object} selectedItem - The selected item object.
 * @param {Array} selectedItem.options - The array of option objects.
 * @param {boolean} selectedItem.options[].selected - The selected status of an option.
 * @returns {boolean} - Returns true if all options are selected, false otherwise.
 */
export const hasAllSelectedOptions = (selectedItem) => !isEmpty(selectedItem?.options) && selectedItem?.options?.every((item) => item?.selected)

/**
 * Checks if one of the options are selected
 *
 * @param {Object} selectedItem - The selected item object.
 * @param {Array} selectedItem.options - The array of option objects.
 * @param {boolean} selectedItem.options[].selected - The selected status of an option.
 * @returns {boolean} - Returns true if some options is selected
 */
export const hasSomeSelectedOptions = (selectedItem) => !isEmpty(selectedItem?.options) && selectedItem?.options?.some((item) => item?.selected)
