import _ from 'lodash'

/**
 * Load a relationship dynamically
 *
 * @param entity - target entity
 * @param fkeys - all the relatioships you want to load as follows:
 * [{reference: string, collection: reducer[Byids, AllIds]}]
 */
export const loadRef = (entity, fkeys) => {
  if (!entity) return null

  let e = Object.assign({}, entity)

  fkeys.map(object => {
    let key = object.ref
    let targetEntity = object.entity

    // Property name of the reference
    const ref = key + 'Ref'

    // init empty array if enrties not loaded
    if (!targetEntity.allIds.length) {
      if (_.isArray(e[key])) {
        e[ref] = []
      } else {
        e[ref] = {}
      }

      return e
    }

    // Is a collection of foreign key ids?
    if (_.isArray(e[key])) {
      e[ref] = []
      e[key].map(id => {
        // get target entity single object
        let loadedObject = targetEntity.byIds[id]

        // load it's relationships if any
        loadedObject = loadNested(loadedObject, object)

        // push to final array
        e[ref].push(loadedObject)
      })
    } else {
      // get target entity single object
      let loadedObject = targetEntity.byIds[entity[key]]

      // load it's relationships if any
      loadedObject = loadNested(loadedObject, object)

      // push to final object
      e[ref] = loadedObject
    }
  })

  return e
}

/**
 * Load nested relationships
 * @param {} loadedObject - the object we're appending the
 *  relationships to
 * @param {} object - information about the next relationship
 * @return {} loadedObject
 */
const loadNested = (loadedObject, object) => {
  if (object.nested) {
    object.nested.map(singleNest => {
      loadedObject = loadRef(loadedObject, [
        {
          ref: singleNest.ref,
          entity: singleNest.entity,
          nested: singleNest.nested ? singleNest.nested : null
        }
      ])
      return loadedObject
    })
  }

  return loadedObject
}
