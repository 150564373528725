import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/fxRates'

class FxRate extends CRUDModel {}
FxRate.modelName = 'FxRate'

FxRate.fields = {
  id: attr(),
  fromCurrency: fk('Currency', 'fxRatesFrom'),
  toCurrency: fk('Currency', 'fxRatesTo'),
}
FxRate.actionTypes = actionTypes

export default FxRate
