import { ROLE_COT_BOT, ROLE_COT_ADMIN, cotRoleTypes, cotUserRoleTypes } from 'redux/config/auth'

export const getCotRoleType = (userRole) => {
  let userRoleType
  cotRoleTypes.find((role) => {
    if (role.type === userRole) userRoleType = role.name
  })

  return userRoleType
}

/**
 * There are several COT roles that have the same rights as ROLE_COT
 * This methods checks if the provided one is one of those roles
 * @param  {string} userRole
 * @returns {boolean}
 */
export const isRoleCotUser = (userRole) => !!cotUserRoleTypes.find((role) => role.type === userRole)

export const isRoleCotBot = (userRole) => userRole === ROLE_COT_BOT

export const isRoleCotAdmin = (userRole) => userRole === ROLE_COT_ADMIN
