import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const ACCESS_AREA_FETCH = 'ACCESS_AREA_FETCH'
export const ACCESS_AREA_RECEIVE = 'ACCESS_AREA_RECEIVE'
export const ACCESS_AREA_INVALIDATE = 'ACCESS_AREA_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: ACCESS_AREA_FETCH,
  receive: ACCESS_AREA_RECEIVE,
  invalidate: ACCESS_AREA_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'accessAreaPivot', 'accessareas', 'AccessArea')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchAccessAreaPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchAccessAreaPivot = actionUtility.fetchEntities
export const invalidateAccessAreaPivot = actionUtility.invalidate
