import CoreLayout from 'layouts/PageLayout/PageLayout'
import CalendarRoute from './routes/Calendar'
import ActionsRoute from './routes/Actions'
import NotificationsRoute from './routes/Notifications'
import InnerWrapper from 'layouts/InnerWrapper'

export default (store) => ({
  path : '/toolbox',
  exact: true,
  skipChildren: true,
  indexRoute: { component: InnerWrapper },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ CalendarRoute(store), ActionsRoute(store), NotificationsRoute(store) ]
})
