import React from 'react'
import PropTypes from 'prop-types'

const DisplayCard = ({ classNames = '', count = 0, icon = '', subtitle = '', fallback = null }) => {
  return (
    <div className={`c-display-cards shadow rounded border u-padding u-padding-bottom-large ${classNames}`}>
      <div className='d-flex'>
        <div>
          <div className='rounded-full u-margin-right-small' style={{ height: '90px', width: '90px' }}>
            <span className={`icon--${icon}`} />
          </div>
        </div>
        <div className='u-margin-left-small u-margin-top-small'>
          {!fallback && <div className='c-display-cards-count u-margin-bottom-small'>{ count }</div>}
          {fallback && <div className='c-display-cards-fallback u-weight--regular u-margin-bottom-small'>{ fallback }</div>}
          <div className='c-display-cards-subtitle'>{ subtitle }</div>
        </div>
      </div>
    </div>
  )
}

DisplayCard.propTypes = {
  classNames: PropTypes.string,
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  sizing: PropTypes.shape({
    maxWidth: PropTypes.string,
    tabletWidth: PropTypes.string,
    mobileWidth: PropTypes.string,
  })
}

export default DisplayCard
