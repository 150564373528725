import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COST_CENTERS_FETCH = 'COST_CENTERS_FETCH'
export const COST_CENTERS_RECEIVE = 'COST_CENTERS_RECEIVE'
export const COST_CENTERS_CREATE = 'COST_CENTERS_CREATE'
export const COST_CENTERS_UPDATE = 'COST_CENTERS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COST_CENTERS_FETCH,
  receive: COST_CENTERS_RECEIVE,
  create: COST_CENTERS_CREATE,
  update: COST_CENTERS_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'costCenters', 'costcenters', 'CostCenters')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCostCentersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCostCenters = actionUtility.fetchEntities
export const createCostCenter = actionUtility.createEntity
export const updateCostCenters = actionUtility.bulkUpdateEntity
