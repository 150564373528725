import React, { useEffect, useContext } from 'react'
import EmployeeMobileNav from 'components/EmployeeMobileNav'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { createFilter } from 'utils/redux/filter'
import Logo from '../Logo'
import MainNav from '../MainNav'
import { getEmployeeById } from 'routes/Payruns/routes/Instance/selectors/employeesORM'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import PropTypes from 'prop-types'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'

const event = new Event('closeSideMenu')

const Aside = (props) => {
  const keycloak = useContext(KeycloakCTX)

  useEffect(() => {
    const { dispatch, employeeFilter } = props
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
  }, [])

  const { isFetching, EmployeeStatus, userId, logout, selectAside, asideMode } = props
  if (isFetching) return <div>Loading...</div>
  const tabPayslips = EmployeeStatus.isContractorChangeTitleStatus ? 'Invoices' : 'Payslips'
  const tabDocuments = EmployeeStatus.isContractorChangeTitleStatus ? 'Documents' : 'Tax Documents'
  const mobileRoutes = [
    {
      title: `My ${tabPayslips}`,
      name: 'home',
      path: `/employees/${userId}/payslips`,
      onClick: () => window.dispatchEvent(event),
      isHome: true,
    },
    {
      title: 'Personal',
      name: 'home',
      path: `/employees/${userId}/personal`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: 'Job & Org',
      name: 'home',
      path: `/employees/${userId}/job-and-org`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: 'Pay & Taxes',
      name: 'home',
      path: `/employees/${userId}/pay-taxes`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: 'Time & Attendance',
      name: 'home',
      path: `/employees/${userId}/time-attendance`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: `${tabDocuments}`,
      name: 'home',
      path: `/employees/${userId}/documents`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: 'Settings',
      name: 'home',
      path: `/settings`,
      onClick: () => window.dispatchEvent(event),
    },
    {
      title: 'Log Out',
      name: 'home',
      onClick: () => logout(keycloak),
    },
  ]

  return (
    <>
      <span id='desktop-employee-header-view'>
        <aside id='employee-self-service-menu' className='o-aside'>
          <Logo />

          <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

          <MainNav
            items={[
              {
                title: 'Home',
                name: 'home',
                path: `/employees/${userId}/payslips`,
                isHome: true,
              },
            ]}
            {...props}
          />
        </aside>
      </span>

      <span id='mobile-employee-header-view'>
        <EmployeeMobileNav routes={mobileRoutes} />
      </span>
    </>
  )
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch,
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { employeeSystemUsers } = state
  const employeeFilter = createFilter({ id: props.userId })
  const entities = [{ ref: employeeSystemUsers, filter: employeeFilter }]
  if (isFetching(entities)) return { isFetching: true, employeeFilter }
  const employee = getEmployeeById(state, { employeeId: props.userId })
  const { employmentStatus } = employee
  const EmployeeStatus = new EmploymentStatusHelper(employmentStatus)
  return {
    EmployeeStatus,
  }
}

Aside.propTypes = {
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  employeeFilter: PropTypes.object,
  EmployeeStatus: PropTypes.object,
  userId: PropTypes.number,
  logout: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
