const yesNo = '- Yes, No'

export const elementsBulkLabels = {
  category: '- Pay elements, Employee Deductions, Employee Net Deductions, Employer Contributions',
  subcategory: '- a pre-existing sub-category setup. If you need a new sub-category please contact support',
  gl: '- Account number needs to be setup in the ‘Account Table’ in the General Ledger tab',
  disbursable: yesNo,
  fixedVariable: '- Fixed, Variable. Applicable for Pay Elements only',
  editableBy: '- Both, Company, Payroll Partner',
  netValue: yesNo + '. Applicable for Pay Elements only',
  recurring: yesNo + '. Applicable for Employee Deductions, Employee Net Deductions and Employer Contributions only',
  dataFormat: '- Value, Percentage, Unit',
  taxable: yesNo,
  taxOnly: yesNo,
}
