import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EXTERNAL_VENDOR_USERS_FETCH = 'EXTERNAL_VENDOR_USERS_FETCH'
export const EXTERNAL_VENDOR_USERS_RECEIVE = 'EXTERNAL_VENDOR_USERS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EXTERNAL_VENDOR_USERS_FETCH,
  receive: EXTERNAL_VENDOR_USERS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'externalVendorUsers', 'external_vendorusers', 'ExternalVendorUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchExternalVendorUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchExternalVendorUsers = actionUtility.fetchEntities
