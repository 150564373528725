import React from 'react'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import PropTypes from 'prop-types'

class ResetPasswordEmailView extends React.Component {
  render () {
    return <>
      <div className='o-grid o-grid--center'>
        <Form onSubmit={this.props.onSubmit} btnText='Next' colorClassBtn='c-btn--curious' btnFullWidth {...this.props}>
          <div className='o-form-group u-margin-top-large u-margin-bottom-small u-padding-bottom-tiny'>
            <Field
              name='email'
              label={`We'll send an authentication code to the following email: `}
              placeholder={`${this.props.email || ''}`}
              component={InputField}
              type='text'
              autoFocus
              className='c-input c-input__value-havelock c-input--alt c-input--rounded u-margin-top u-margin-bottom'
              labelClassName='c-label'
              disabled
            />
          </div>
          <button
            type='submit'
            className={`c-btn c-btn--full c-btn--submit c-btn--small c-btn--curious c-btn--rounded u-padding-left u-padding-right
            ${!this.props.email ? 'disabled' : ''}
            `}
            tabIndex='-1'
            disabled={!this.props.email}
          >
            Next
          </button>
        </Form>
      </div>
      <div className='o-layout o-grid o-grid--center'>
        <p className='u-text--small u-text--center u-margin-top'>If the provided email is not correct, please contact an administrator.</p>
      </div>
    </>
  }
}

ResetPasswordEmailView.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
}

export default ResetPasswordEmailView
