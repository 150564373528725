import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import EditableCell from 'components/table/cell/EditableCell'
import { SelectColumnFilter } from 'components/table/filters'

const columnFilter = { type: 'TextFilter', delay: 1000, placeholder: 'Filter' }
const mappedStatuses = {
  active: true,
  inactive: false,
}
const dataMappingForStatus = {
  true: 'active',
  false: 'inactive',
}
const mappedStatusLabels = {
  active: 'Active',
  inactive: 'Inactive',
}
const GeneralLedgersList = ({
  tableData, inEditMode, onUpdateGlobalDataForSave, skipPageReset,
  inAddMode, onCancelInlineAdd, onSaveInline, fieldErrors, onCancel, showFilters
}) => {
  const headings = [
    {
      accessor: 'accountNumber',
      Header: 'Account Number',
      filter: columnFilter,
      editable: true,
      requiredField: true,
      dataSort: true,
      setWidth: 250,
      Cell: EditableCell,
      tableSearchId: 'general-ledger-list',
      cellClassName: 'u-border--transparent-3'
    },
    {
      accessor: 'accountDescription',
      Header: 'Account Description',
      filter: columnFilter,
      editable: true,
      dataSort: true,
      Cell: EditableCell,
      tableSearchId: 'general-ledger-list',
      cellClassName: 'u-border--transparent-3'
    },
    {
      accessor: 'accountType',
      Header: 'Type',
      setWidth: 250,
      inputType: 'dropDownSelection',
      dropDownOptionsForInput: [
        { value: 'Expense', label: 'Expense' },
        { value: 'Liability', label: 'Liability' },
        { value: 'Expense/Liability', label: 'Expense/Liability' }
      ],
      dropDownOptions: {
        name: 'accountType',
        options: [
          { value: 'Expense', label: 'Expense' },
          { value: 'Liability', label: 'Liability' },
          { value: 'Expense/Liability', label: 'Expense/Liability' }
        ]
      },
      requiredField: true,
      filter: 'equals',
      editable: true,
      dataSort: true,
      Filter: SelectColumnFilter,
      Cell: EditableCell,
      tableSearchId: 'general-ledger-list',
    },
    {
      accessor: 'status',
      Header: 'Status',
      inputType: 'statusSwitchToggle',
      editable: true,
      dropDownOptions: {
        name: 'status',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' }
        ]
      },
      toggleOptions: {
        statusMapping: mappedStatuses,
        dataMappingForStatus: dataMappingForStatus,
        mappedStatusLabels: mappedStatusLabels
      },
      Filter: SelectColumnFilter,
      Cell: EditableCell,
      filter: 'equals',
      dataSort: false,
      hasInlineSave: true,
      setWidth: 250,
      hasInlineCancel: true
    },
  ]
  const options = {
    noDataMessage: 'No matching records found.',
    pageSize: 10,
    pageIndex: 0,
    showPageSizeSelector: tableData.length > 10
  }

  const editableOptions = {
    inGlobalEditMode: inEditMode,
    inAddMode: inAddMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    onCancelInlineAdd: onCancelInlineAdd,
    onSaveInline: onSaveInline,
    fieldErrors: fieldErrors
  }

  return (
    <div id='general-ledger-list' className={`o-layout`}>
      <PayslipTable
        skipPageReset={skipPageReset}
        tableHeaderClass='table-border--none'
        data={tableData}
        showFilters={showFilters}
        headings={headings}
        wrapperClassName='o-layout__item u-1/1 react-bs-table--overflow-auto'
        modifierClasses={`react-bs-container-body`}
        tableElementClassName='table--layout-auto'
        options={options}
        paginationStateControl={{ resetWhileInAddMode: { mode: inAddMode, callback: onCancel } }}
        editableOptions={editableOptions}
        pagination
        showFilterBtn
        dynamicValues
        enableSortReset={!inEditMode}
        enableFilterReset={!inEditMode && !inAddMode}
        trClassName='react-bs-table__cell--whitespace--normal'
        data-testid='documents-table'
      />
    </div>
  )
}

GeneralLedgersList.propTypes = {
  tableData: PropTypes.array,
  inEditMode: PropTypes.bool,
  onUpdateGlobalDataForSave: PropTypes.func,
  skipPageReset: PropTypes.bool,
  inAddMode: PropTypes.bool,
  onCancelInlineAdd: PropTypes.func,
  onSaveInline: PropTypes.func,
  onCancel: PropTypes.func,
  showFilters: PropTypes.bool,
  fieldErrors: PropTypes.array
}

export default GeneralLedgersList
