import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'

const ColorValue = ({ children, value: { label, color } }) => {
  return <div className='Select-value' title={label}>
    <span className='Select-value-label' style={{ display: 'inline-block' }}>
      { color && <Box color={color} /> }
      { children }
    </span>
  </div>
}

ColorValue.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object
}

export default ColorValue
