import createSelector from 'utils/createSelector'

// get company id
const getCompanyId = (state, props) => parseInt(props.companyId)

// get paygroups for the given company
export const getPaygroupsByCompany = createSelector(getCompanyId,
  ({ Paygroup }, companyId) => {
    return Paygroup
      .filter(
        paygroup => paygroup.company === companyId && paygroup.status === 'active'
      )
      .toModelArray()
      .map(
        paygroup => ({
          ...paygroup.ref,
        })
      )
      .reverse()
  }
)
