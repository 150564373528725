import React, { useEffect, useState } from 'react'

import PayslipTable from 'components/table/PayslipTable'
import { addExpandableSubRow } from 'components/table/cell/ExpandableCell'

export const SummaryAdditionalItems = ({ data, tableHeadings, editableTableState, onUpdateGlobalData }) => {
  const [contentState, setContentState] = useState({
    tableData: [],
    hasAllRowsExpanded: false,
  })
  const { tableData, hasAllRowsExpanded } = contentState

  const editableOptions = {
    ...editableTableState,
    onUpdateGlobalData,
    focusableColumn: 'item',
  }

  useEffect(() => {
    setContentState({
      tableData: addExpandableSubRow(data, {
        idKey: 'id',
        expandedTextKey: 'comment',
        columnKey: 'comment',
      }),
      hasAllRowsExpanded: true,
    })
  }, [data])

  return (
    <PayslipTable
      data={tableData}
      headings={tableHeadings}
      editableOptions={editableOptions}
      expandableClass='row-expanded'
      wrapperClassName='u-margin-top-large'
      hasAllRowsExpanded={hasAllRowsExpanded}
      useCustomExpandedDisplay
      dynamicValues
    />
  )
}
