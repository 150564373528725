import _ from 'lodash'

/**
 * Transform percentage value
 * Clear the % symbol
 *
 * @param entity - target entity
 * @param dateKeys - all the percentages you're sending
 */
export const percentageTransformer = (entity, dateKeys) => {
  let e = { ...entity }

  dateKeys.forEach(dateKey => {
    if (e[dateKey]) {
      e[dateKey] = _.replace(e[dateKey], ' %', '')
    }
  })

  return e
}
