import React from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import PropTypes from 'prop-types'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchVendorCountryServicePivotIfNeeded, createVendorCountryService } from 'redux/actions/vendorCountryServicePivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchProcessesIfNeeded } from 'redux/actions/processes'
import { fetchStepsIfNeeded } from 'redux/actions/steps'
import { fetchTasksIfNeeded } from 'redux/actions/tasks'
import { fetchCountryProcessPivotIfNeeded } from 'redux/actions/countryProcessPivot'
import { fetchCountryStepPivotIfNeeded } from 'redux/actions/countryStepPivot'
import { fetchCountryTaskPivotIfNeeded } from 'redux/actions/countryTaskPivot'
import { fetchVendorCountryTaskPivotIfNeeded } from 'redux/actions/vendorCountryTaskPivot'
import AddServiceForm from '../components/AddServiceForm'
import { getVendorServiceTree } from 'redux/selectors/vendorCountryServices'
import { getUnassignedCountryServicesByVendor, getUnassignedCountriesByVendor } from '../selectors/countryService'
import { countryServices } from 'utils/enums/payroll'
const formName = 'addServiceForm'
class AddServiceFormContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCountriesIfNeeded()
    this.props.fetchVendorsIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchServicesIfNeeded()
    this.props.fetchProcessesIfNeeded()
    this.props.fetchStepsIfNeeded()
    this.props.fetchTasksIfNeeded()
    this.props.fetchCountryProcessPivotIfNeeded()
    this.props.fetchCountryStepPivotIfNeeded()
    this.props.fetchCountryTaskPivotIfNeeded()
    this.props.fetchVendorCountryTaskPivotIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <AddServiceForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const vendorId = parseInt(props.match.params.vendorId)

  return {
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
    fetchProcessesIfNeeded: () => dispatch(fetchProcessesIfNeeded()),
    fetchStepsIfNeeded: () => dispatch(fetchStepsIfNeeded()),
    fetchTasksIfNeeded: () => dispatch(fetchTasksIfNeeded()),
    fetchCountryProcessPivotIfNeeded: () => dispatch(fetchCountryProcessPivotIfNeeded()),
    fetchCountryStepPivotIfNeeded: () => dispatch(fetchCountryStepPivotIfNeeded()),
    fetchCountryTaskPivotIfNeeded: () => dispatch(fetchCountryTaskPivotIfNeeded()),
    fetchVendorCountryTaskPivotIfNeeded: () => dispatch(fetchVendorCountryTaskPivotIfNeeded()),
    onSubmit: (data) =>
      dispatch(createVendorCountryService(vendorId, data.countryService)).then(() => {
        props.onSubmit()
      }),
    onCountryChange: (val) => {
      dispatch((dispatch, getState) => {
        const state = getState()
        const services = getUnassignedCountryServicesByVendor(state, {
          countryId: val,
          vendorId,
        })
        const payrollCalculationServiceId = services.find((service) => service.name === countryServices.PAYROLL_CALCULATION)?.id

        return dispatch(change(formName, 'countryService', payrollCalculationServiceId))
      })
    },
  }
}

const mapStateToProps = (state, props) => {
  const {
    countries,
    vendors,
    services,
    vendorCountryServicePivot,
    countryServicePivot,
    processes,
    steps,
    tasks,
    countryProcessPivot,
    countryStepPivot,
    countryTaskPivot,
    vendorCountryTaskPivot,
  } = state

  if (
    isFetching([
      countries,
      vendors,
      services,
      vendorCountryServicePivot,
      countryServicePivot,
      processes,
      steps,
      tasks,
      countryProcessPivot,
      countryStepPivot,
      countryTaskPivot,
      vendorCountryTaskPivot,
    ])
  ) {
    return { isFetching: true }
  }

  const vendorId = props.match.params.vendorId
  const vendorServiceTree = getVendorServiceTree(state, { vendorId: props.match.params.vendorId })

  const selector = formValueSelector(formName)
  const countryValue = selector(state, 'country')

  let servicesData = getUnassignedCountryServicesByVendor(state, {
    countryId: props.countryId || countryValue,
    vendorId: props.match.params.vendorId,
  })
  return {
    vendorId,
    vendorServiceTree,
    countries: getUnassignedCountriesByVendor(state, {
      vendorId: props.match.params.vendorId,
    }),
    services: servicesData,
  }
}

AddServiceFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  countryId: PropTypes.number,
  isFetching: PropTypes.bool,
  fetchCountriesIfNeeded: PropTypes.func,
  fetchVendorsIfNeeded: PropTypes.func,
  fetchVendorCountryServicePivotIfNeeded: PropTypes.func,
  fetchCountryServicePivotIfNeeded: PropTypes.func,
  fetchServicesIfNeeded: PropTypes.func,
  fetchProcessesIfNeeded: PropTypes.func,
  fetchStepsIfNeeded: PropTypes.func,
  fetchTasksIfNeeded: PropTypes.func,
  fetchCountryProcessPivotIfNeeded: PropTypes.func,
  fetchCountryStepPivotIfNeeded: PropTypes.func,
  fetchCountryTaskPivotIfNeeded: PropTypes.func,
  fetchVendorCountryTaskPivotIfNeeded: PropTypes.func,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
  })(AddServiceFormContainer)
)
