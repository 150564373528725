import { ActionUtility } from 'utils/redux'
import { dateTransformer } from 'redux/transformers/dateTransformer'

// ------------------------------------
// Constants
// ------------------------------------
export const FX_RATE_FETCH = 'FX_RATE_FETCH'
export const FX_RATE_RECEIVE = 'FX_RATE_RECEIVE'
export const FX_RATE_CREATE = 'FX_RATE_CREATE'
export const FX_RATE_UPDATE = 'FX_RATE_UPDATE'
export const FX_RATE_INVALIDATE = 'FX_RATE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: FX_RATE_FETCH,
  receive: FX_RATE_RECEIVE,
  create: FX_RATE_CREATE,
  update: FX_RATE_UPDATE,
  invalidate: FX_RATE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'fxRates', 'fxrates', 'FxRate', { forceDefaultAPI: false })

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchFxRatesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchFxRates = actionUtility.fetchEntities
export const updateFxRate = actionUtility.updateEntity
export const invalidateFxRates = actionUtility.invalidate

export const createFxRate = (entity) => {
  // set valid date
  const data = dateTransformer(entity, ['date'])

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data))
  }
}

export const download = fullUrl => actionUtility.downloadEntity({ fullUrl })
export const downloadTemplate = () => actionUtility.downloadEntity({ childUri: 'bulkimportcreate' })

export const importFxRatesCreate = file => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: 'bulkimportcreate', shouldHandleErrors: false }))
    .then((response) => {
      dispatch(invalidateFxRates())
      return response
    })
)
