import React from 'react'
import SectionHeading from 'components/SectionHeading'
import CostingTable from './CostingTable'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'

const CostingView = (props) => {
  const { countries, vendorServices, onClick, isFetching, isCotOemUser } = props
  const shouldHideCreateCountryAndServiceButton = isCotOemUser

  const renderGroupTables = () => {
    return countries.map((country) => {
      const hasVendorService = vendorServices.hasOwnProperty(country.id)
      const vendorCountryServices = hasVendorService ? vendorServices[country.id].services : []

      return (
        hasVendorService && (
          <div key={country.id}>
            <SectionHeading text={country.name} className='u-margin-top-large'>
              {!shouldHideCreateCountryAndServiceButton && (
                <Authorization permissions={['VENDORCOUNTRYSERVICE_CREATE']}>
                  <div className='o-layout__item u-1/1 u-1/2@tablet'>
                    <div className='u-float--right'>
                      <button
                        title='Add service'
                        onClick={() => onClick('addService', country.id)}
                        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                      >
                        <span className='icon icon--plus' />
                      </button>
                    </div>
                  </div>
                </Authorization>
              )}
            </SectionHeading>
            <CostingTable
              rows={vendorCountryServices}
              countryId={country.id}
              onClick={onClick}
              shouldHideServiceButton={shouldHideCreateCountryAndServiceButton}
            />
          </div>
        )
      )
    })
  }

  if (isFetching) return <div>Loading...</div>

  return <div>{renderGroupTables()}</div>
}

CostingView.propTypes = {
  countries: PropTypes.array,
  vendorServices: PropTypes.object,
  onClick: PropTypes.func,
}

export default CostingView
