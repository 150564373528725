import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import BaseField from './BaseField'
import moment from 'moment'
import _ from 'lodash'
import { Portal } from 'react-overlays'
/*
 * CustomDatePicker was added in order to be fixed a strange behaviour of the react datepicker.
 * When the datepicker is open, if you try to click on select,
 * the select opens and the datepicker closes BUT almost instantly
 * the select closes and datepicker opens again.
 * This causes a weird focus behaviour on the react datepicker.
 *
 * Please look the workaround at:
 * https://github.com/Hacker0x01/react-datepicker/issues/730#issuecomment-314699156
 */
class CustomDatePicker extends DatePicker {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)
  }

  deferFocusInput = () => {
    this.cancelFocusInput()
  }
}

const CalendarWrapper = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return (
    <Portal container={el}>
      {children}
    </Portal>
  )
}

const formatInputValueToCorrectFormat = (date) => {
  if (date instanceof Date) return date
  if (_.isString(date) && date !== '') return moment(date).toDate()
  if (date === '') return null
  if (typeof date === 'number') return null
  return date.toDate()
}

const FieldDatePicker = props => {
  const {
    input,
    selected,
    startDate,
    endDate,
    disabled,
    className = 'c-input',
    placeholderText = '',
    showMonthDropdown = true,
    showYearDropdown = true,
    highlightDates,
    onSelect,
    onChanged,
    selectsStart,
    selectsEnd,
    todayButton = false,
    isClearable,
    showWeekNumbers,
    minDate = moment('01/01/1900').toDate(),
    maxDate,
    showDisabledMonthNavigation,
    calendarClassName = '',
    yearDropdownItemNumber = 50,
    calendarStartDay = 1
  } = props

  // The idea here is to define `rest` const, with only the needed input props.
  // Later we spread `rest` const at `<Datepicker />`.
  // In `rest` const we keep most of `input` parameters (from `redux-form`), except `value`,`onBlur`, `onChange`.
  // - Passing `value` param to <Datepicker /> resets the selected value.
  // - Passing `onBlur`, updates the Store one more time with the following date format - '12/09/2016',
  // even though it's already updated by `onChange` event, with the following format - '2017-10-11T08:17:17.000Z'.
  // - We remove `onChange` too, but later we invoke it with our custom `onChanged` handler.
  // Otherwise, keeping it in `rest`, will overwrite our `onChange` property in <DatePicker /> definition.
  const { value, onBlur, onChange, ...rest } = input
  return <BaseField {...props} useTestProp>
    <CustomDatePicker
      className={className}
      dateFormat='dd/MM/yyyy'
      selected={selected || (input.value ? formatInputValueToCorrectFormat(input.value) : null)}
      placeholderText={placeholderText}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      popperContainer={CalendarWrapper}
      scrollableYearDropdown
      highlightDates={highlightDates || []}
      todayButton={todayButton}
      isClearable={isClearable}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      yearDropdownItemNumber={yearDropdownItemNumber}
      showDisabledMonthNavigation={showDisabledMonthNavigation}
      autoComplete='off'
      calendarClassName={calendarClassName}
      showWeekNumbers={showWeekNumbers}
      calendarStartDay={calendarStartDay}
      {...onChanged ? { onChange: date => {
        onChanged(date)
        onChange(date)
      } } : { onChange }}
      {...onSelect ? { onSelect } : {}}
      {...selectsStart ? { selectsStart } : {}}
      {...selectsEnd ? { selectsEnd } : {}}
      {...startDate ? { startDate } : {}}
      {...endDate ? { endDate } : {}}
      {...rest}
    />
  </BaseField>
}

FieldDatePicker.propTypes = {
  // Handler, attached on `<Datepicker /> onChange` property.
  // The tricky part is that we can't pass `onChange` directly,
  // because of `<FieldDatePicker />` component is always decorated by `redux-form `<Field />` firstly.
  // So trying to pass custom `onChange` handler, it's always removed by `redux-form`,
  // because of `redux-form` has the same handler, that is passed later in `input` props.
  onChanged: PropTypes.func
}

export default FieldDatePicker
