import React from 'react'
import PropTypes from 'prop-types'
import NotificationHOC from 'components/NotificationsHOC'
import { SelectColumnFilter } from 'components/table/filters'
import PayslipTable from 'components/table/PayslipTable'
import { country, icon, status } from 'utils/tableDataFormatters'
import SectionHeading from 'components/SectionHeading'
import FilterButton from 'components/buttons/FilterButton'
let classNames = require('classnames')

const columnFilter = { type: 'TextFilter', delay: 1000, placeholder: 'Filter' }

class NotificationList extends React.Component {
  constructor (props) {
    super(props)
    this.state = ({
      notificationId: null,
      showFilters: false
    })
  }
  trClassFormat ({ original }) {
    // row is the current row data
    // bold the whole row if notification is unread
    return !original.isRead ? 'u-weight--medium' : '' // return class name.
  }

  onPageChange = ({ pageSize, pageIndex, filters }) => {
    if (!this.props.isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })
      this.props.onFilter({
        page: pageIndex,
        limit: pageSize,
        filters: normalizedFilters,
      })
    }
  }

  render () {
    const { isFetching, pagination, onFilter, tenants, countries } = this.props

    const options = {
      noDataText: 'No matching records found.',
      pageSize: pagination && pagination.limit,
      sizePerPageList: [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
      ],
      pageIndex: pagination && pagination.currentPage,
      pageCount: pagination && pagination.totalPages,
      showPageSizeSelector: pagination && pagination.totalCount >= 25,
      onPageChange: this.onPageChange,
      onSizePerPageList: limit => onFilter({ limit }),
      onFilterChange: filters => onFilter({ filters }),
    }
    const clientOptions = tenants.filter(tenant => tenant.type !== 'vendor')

    return <div className='u-relative'>
      <SectionHeading text='Alerts' fullWidth>
        <FilterButton
          className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right'
          onClick={() => this.setState({ showFilters: !this.state.showFilters })} />
      </SectionHeading>

      <PayslipTable
        data={this.props.notifications}
        headings={[
          {
            accessor: 'type',
            Header: 'Icon',
            setWidth: '120px',
            Cell: icon,
            disableSortBy: true,
          },
          {
            accessor: 'client',
            Header: 'Client',
            setWidth: '120px',
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'client',
              options: clientOptions.filter(option => option.name !== 'Vendors').map(tenant => ({
                value: tenant.schema,
                label: tenant.name,
                disabled: tenant.disabled,
                className: classNames({ 'option--as-group-title': tenant.disabled })
              })),
              multi: true
            },
            disableSortBy: true
          },
          {
            accessor: 'countryName',
            Header: 'Country',
            setWidth: '130px',
            Cell: country,
            Filter: SelectColumnFilter,
            dropDownOptions: {
              name: 'countryName',
              options: countries.map(c => ({
                value: c.id,
                label: c.name
              })),
              multi: true },
            disableSortBy: true
          },
          {
            accessor: 'payrollName',
            Header: 'Payroll Name',
            setWidth: '300px',
            disableSortBy: true
          },
          {
            accessor: 'period',
            Header: 'Pay Period',
            setWidth: '200px',
            disableSortBy: true
          },
          {
            accessor: 'body',
            Header: 'Notification',
            columnClassName: 'u-word-break-word',
            disableSortBy: true },
          {
            accessor: 'status',
            Header: 'Status',
            setWidth: '170px',
            Cell: status,
            filterOptions: columnFilter,
            disableSortBy: true
          }
        ]}
        trBodyClassName={this.trClassFormat}
        options={options}
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName={classNames({
          'u-1/1 react-bs-table--overflow-auto': true,
          'u-half-opacity': isFetching
        })}
        pagination
        remote
        showFilters={this.state.showFilters}
        totalCount={pagination && pagination.totalCount}
      />
    </div>
  }
}

NotificationList.propTypes = {
  notifications: PropTypes.array,
  tenants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    schema: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
}
export default NotificationHOC(NotificationList)
