import React from 'react'
import ResetPasswordWrapper from 'layouts/ResetPasswordWrapper'
import ResetPasswordConfirmationContainer from '../containers/ResetPasswordConfirmationContainer'

export const RouteView = props => {
  return <ResetPasswordWrapper>
    <ResetPasswordConfirmationContainer {...props} />
  </ResetPasswordWrapper>
}

export default RouteView
