import React from 'react'
import PropTypes from 'prop-types'

class DownloadTemplateView extends React.Component {
  render () {
    const { downloadTemplate } = this.props
    return <div className='u-margin-bottom'>
      <button
        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
        title='Download template'
        onClick={downloadTemplate}
      >
        <span className='icon icon--download' />
      </button>
    </div>
  }
}

DownloadTemplateView.propTypes = {
  downloadTemplate: PropTypes.func.isRequired,
}

export default DownloadTemplateView
