export const SortingTypes = {
  Asc: 'asc',
  Desc: 'desc',
}

/**
 * Sorts an array of objects by a specified property in alphabetical order.
 *
 * @param {Array<Object>} arr - The array of objects to sort.
 * @param {string} order - The order to sort the objects, either 'asc' (ascending) or 'desc' (descending).
 * @param {string} property - The property of the objects to sort by. Default is 'label'.
 * @returns {Array<Object>} - The sorted array of objects.
 */
export const sortStrings = (arr = [], order = SortingTypes.Asc, property = 'label') => {
  if (!Array.isArray(arr)) {
    throw new Error('@sortStrings Should be an array of strings.')
  }
  const sortedArray = arr.sort((a, b) => {
    const aValue = (a[property] || '').toString()
    const bValue = (b[property] || '').toString()

    if (order === SortingTypes.Asc) {
      return aValue.localeCompare(bValue)
    } else if (order === SortingTypes.Desc) {
      return bValue.localeCompare(aValue)
    } else {
      throw new Error('@sortStrings Order must be either "asc" or "desc".')
    }
  })
  return sortedArray
}
