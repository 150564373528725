import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const TERM_SUBCATEGORIES_FETCH = 'TERM_SUBCATEGORIES_FETCH'
export const TERM_SUBCATEGORIES_RECEIVE = 'TERM_SUBCATEGORIES_RECEIVE'
export const TERM_SUBCATEGORIES_CREATE = 'TERM_SUBCATEGORIES_CREATE'
export const TERM_SUBCATEGORIES_UPDATE = 'TERM_SUBCATEGORIES_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TERM_SUBCATEGORIES_FETCH,
  receive: TERM_SUBCATEGORIES_RECEIVE,
  create: TERM_SUBCATEGORIES_CREATE,
  update: TERM_SUBCATEGORIES_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'termSubCategories', 'termsubcategories')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTermSubCategories = actionUtility.fetchEntities
export const fetchTermSubCategoriesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const createTermSubCategory = actionUtility.createEntity
export const updateTermSubCategory = actionUtility.updateEntity
