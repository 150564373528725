import Fetcher from './Fetcher'
import RecurringDeductionsTable from 'components/RecurringDeductionsTable'
import { connect } from 'react-redux'
import { getRecurringDeductionsByCompany } from 'redux/selectors/companyCountryTermPivot'

const mapStateToProps = (state, { companyId }) => {
  return {
    deductions: getRecurringDeductionsByCompany(state, { companyId })
  }
}

const Container = connect(mapStateToProps)(RecurringDeductionsTable)

export default Fetcher(Container, ['companyCountryTermPivot', 'countryTermPivot', 'terms'])
