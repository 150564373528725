import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_DATA_PROTECTION_FIELDS_REVISION_FETCH = 'COMPANY_DATA_PROTECTION_FIELDS_REVISION_FETCH'
export const COMPANY_DATA_PROTECTION_FIELDS_REVISION_RECEIVE = 'COMPANY_DATA_PROTECTION_FIELDS_REVISION_RECEIVE'
export const COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTER = 'COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTER'
export const COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTERED = 'COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_DATA_PROTECTION_FIELDS_REVISION_FETCH,
  receive: COMPANY_DATA_PROTECTION_FIELDS_REVISION_RECEIVE,
  filter: COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTER,
  filtered: COMPANY_DATA_PROTECTION_FIELDS_REVISION_FILTERED
}
// eslint-disable-next-line max-len
const actionUtility = new ActionUtility(actionTypes, 'companyDataProtectionFieldsRevision', 'companydataprotectionfieldrevisions', 'CompanyDataProtectionFieldsRevision')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyDataProtectionFieldsRevisionIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyDataProtectionFieldsRevision = actionUtility.fetchEntities

export const filterCompanyDataProtectionFieldsRevision = filters => {
  return actionUtility.filterEntity({ filters })
}
