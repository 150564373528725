import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/projects'

class Project extends CRUDModel {}
Project.modelName = 'Project'
Project.actionTypes = actionTypes

Project.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'projects'),
}

export default Project
