import { useEffect } from 'react'
import isNull from 'lodash/isNull'
import { useDispatch, useSelector } from 'react-redux'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'
import { isVendorInvolved } from 'redux/selectors/auth'
import { subdomainEnums } from 'utils/enums/subdomainEnums'
import { tenantTypes } from 'utils/enums/tenantTypes'
import { getParsedDomain, isPathnameClients, isPathnamePure } from 'utils/query'
import { getSubdomainBrandConfig, generateCssFileUrl } from './BrandConfig'

const CACHED_CSS_APPLIED = {}
const NO_BRANDING_FOUND = {}

const brandingCssId = 'branding-style'
/**
 * Method to disabled all the sheets that have been applied.  Basically reverting back to main
 */
function disableAnyAddedStyleSheets () {
  document.querySelectorAll(`link.${brandingCssId}`).forEach((link) => {
    link.disabled = true
  })
}

/**
 * Method to handle the activation of a given stylesheet.  It will disable all other
 * branded style sheets and only enable the one whose href is passed in
 * @param {string} brandToApply The Brand url that will be applied
 */
function handleActivationOfFoundUrl (brandToApply) {
  document.querySelectorAll(`link.${brandingCssId}`).forEach((link) => {
    link.disabled = link.href !== brandToApply
  })
}
/**
 * Method which creates a link tag and attaches it to the <head> to apply a stylesheet
 * It handles the activation of already placed tags as well.  And prevents attaching identical tags
 * @param {string} url Url for a given link.css tag to attach
 * @returns {false}
 */
function attachBrandedCss (url) {
  const baseURL = document.location.origin
  const linkURL = `${baseURL}/${url}`
  handleActivationOfFoundUrl(linkURL)
  if (document.querySelector(`[href="${linkURL}"]`)) return
  const link = document.createElement('link')
  link.href = linkURL
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.className = brandingCssId
  document.getElementsByTagName('head')[0].appendChild(link)
  return false
}

/**
 * Method to determine if the item is a valid stylesheet.  We check the response for 200 AND if the content-type
 * is text/css, if both are true then the fetched url is stylesheet that has been uploaded correctly
 * @param {object} response Fetch request response
 * @returns {boolean}
 */
function isStyleSheetFound (response) {
  return response.status === 200 && response.headers.get('content-type').indexOf('text/css') !== -1
}

const getBrandCssUrl = async ({ subdomain, branding, brandsConfig }) => {
  let cssUrl
  try {
    if (CACHED_CSS_APPLIED[subdomain]) return CACHED_CSS_APPLIED[subdomain]

    for (const urlObject of branding) {
      const brandConfig = getSubdomainBrandConfig(brandsConfig, subdomain)
      const url = generateCssFileUrl({ subdomain, urlObject, brandsConfig })

      if (NO_BRANDING_FOUND[url] || !brandConfig || !url) return

      const response = await fetch(`${document.location.origin}/${url}`)

      if (isStyleSheetFound(response)) {
        CACHED_CSS_APPLIED[subdomain] = url
        cssUrl = url
        break
      }
    }
  } catch (error) {
    console.error('Error during customization....', error)
    cssUrl = null
  }

  return cssUrl
}

/**
 * Method to attach find the branding for a tenant user
 * @param {object} {subdomain: { string }, branding: { array }}
 * @returns {string}
 */
const getTenantBranding = async ({ subdomain, branding, brandsConfig }) => {
  if (subdomain === subdomainEnums.COS) return null
  return getBrandCssUrl({ subdomain, branding, brandsConfig })
}

/**
 * Method to attach find the branding for a vendor user
 * @param {object} {vendorTenant: { object }, branding: { array }}
 * @returns {string}
 */
const getVendorTenantBranding = async ({ branding, vendorTenant, brandsConfig }) => getBrandCssUrl({ subdomain: vendorTenant.schema, branding, brandsConfig })

/**
 * Method to attach find the branding for a COT user
 * @param {object} { branding {array}, tenants {array}, isCotViewingVendorViewingTenant {boolean}, vendorTenant: {object} }
 * @returns {string}
 */
const getCotBranding = async ({ branding, tenants, isCotViewingVendorViewingTenant, vendorTenant, brandsConfig }) => {
  if (!tenants.url) return

  const tenantDomain = getParsedDomain(tenants.url)
  const tempDomain = isCotViewingVendorViewingTenant ? vendorTenant.schema : tenantDomain.subdomain.split('.')[0]

  if (tempDomain === subdomainEnums.COS) return null

  return getBrandCssUrl({ subdomain: tempDomain, branding, brandsConfig })
}

const BrandApplier = () => {
  const dispatch = useDispatch()
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const tenants = useSelector((state) => state.tenants)
  const vendorTenant = useSelector((state) => state.vendorTenant)
  const parsedDomain = getParsedDomain(window.location.hostname)
  const { subdomain } = parsedDomain
  const isCotSubdomain = subdomain === subdomainEnums.COS
  const brandings = useSelector((state) => state.brandings)
  const brandsConfig = useSelector((state) => state.config.brands)

  useEffect(() => {
    async function fetchBranding () {
      await dispatch(getWhiteLabeledBrandStyles())
    }

    if (!brandings.brands.length) fetchBranding()
  }, [])

  useEffect(() => {
    if (brandings.isFetching || !brandings.brands) return
    const hasTenantButNoVendorTenant = !isNull(tenants.url) && isNull(vendorTenant.url)
    const isCotViewingTenant = tenants.url && isCotSubdomain
    const isCotViewingVendor = tenants.type === tenantTypes.VENDOR && isCotSubdomain
    const isCotViewingVendorViewingTenant = isCotViewingVendor && !isNull(vendorTenant.url) && isPathnameClients(window.location.pathname)

    const isVendorViewingTenant = isVendorUser && !isNull(vendorTenant.url) && isPathnameClients(window.location.pathname)
    let brandingCallback = isCotViewingTenant ? getCotBranding : getTenantBranding
    if (isVendorViewingTenant) brandingCallback = getVendorTenantBranding

    const shouldApplyBranding = !(isCotViewingVendor && hasTenantButNoVendorTenant && isPathnamePure(window.location.pathname))

    async function applyBranding () {
      const brand = await brandingCallback({ subdomain, branding: brandings.brands, tenants, vendorTenant, isCotViewingVendorViewingTenant, brandsConfig })

      if (brand) {
        attachBrandedCss(brand)
      }
      if (!brand) {
        disableAnyAddedStyleSheets()
      }
    }
    if (shouldApplyBranding) applyBranding()
  }, [tenants, brandings, vendorTenant, brandsConfig])

  return null
}

export default BrandApplier
