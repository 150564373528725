import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import Dropzone from 'react-dropzone'

import SectionHeading from 'components/SectionHeading'
import { DocumentList } from 'components/DocumentList/DocumentList'
import { documentTableHeadingTypes } from 'components/DocumentList/documentTableUtils'
import { HistoryModal } from 'components/ReusableModals/HistoryModal'
import { DeleteConfirmationModal } from 'components/ReusableModals/DeleteConfirmationModal'
import { DocumentEmployeeView } from './DocumentEmployeeView'
import { createDynamicDocumentFilterName } from 'redux/filters/createDocumentFilter'
import { getFilteredDocsCount } from 'redux/selectors/document'
import { useDocumentTableActions } from 'hooks/useDocumentActions'
import { DocumentActionsRow, DocumentHeaderActions, DocumentsDeleteModalText } from './helpers/documentTemplates'
import { documentsMapper } from './helpers/documentsListUtils'

const getOmittedColumns = ({ showCategoryPayrollName, hasDocumentClassification }) =>
  [
    !hasDocumentClassification && documentTableHeadingTypes.documentType,
    !hasDocumentClassification && documentTableHeadingTypes.version,
    !showCategoryPayrollName && documentTableHeadingTypes.category,
    !showCategoryPayrollName && documentTableHeadingTypes.payrollName,
  ]?.filter(Boolean)

const generateDocActions = (actionProps) => (data) => {
  const doc = data?.row
  const hasHistoryAction = doc?.extraData?.isOwner || actionProps?.isCot
  const rowProps = { ...actionProps, hasHistoryAction, doc }
  return <DocumentActionsRow {...rowProps} />
}
export const DocumentsViewTable = ({
  extraFilters,
  isVendorDocuments,
  typeClass,
  typeId,
  typeTenant,
  filterNames,
  onDelete,
  uploadFile,
  onUpload,
  hasDocumentClassification,
  title,
  showCategoryPayrollName: needsCategoryPayroll,
  openShareModal,
  openEditModal,
  isCot,
  canCreate,
  isVendor = false,
  isEmployeeOnly,
  fetchInitialDocs,
}) => {
  const showCategoryPayrollName = needsCategoryPayroll && isVendorDocuments
  const dispatch = useDispatch()
  const filterName = createDynamicDocumentFilterName([typeId, typeClass])
  let dropzoneRef = null
  const {
    documentsState,
    historyModalRef,
    deleteModalRef,
    documentLogs,
    onShareButtonClick,
    handleHistoryClick,
    onEditClick: handleEditClick,
    handleTrashAction,
    onConfirmDelete,
  } = useDocumentTableActions({ onDelete, openShareModal, openEditModal })

  /*
   * Selectors
   */
  const { documents } = useSelector((state) => getFilteredDocsCount(state, filterName))

  /*
   * Conditionals
   */
  const omittedColumns = [
    'importStatus',
    'glStatus',
    'inputsValidationReportStatus',
    'gtnValidationStatus',
    'gtnReimport',
    ...getOmittedColumns({ showCategoryPayrollName, hasDocumentClassification, isVendorDocuments }),
  ]

  /*
   * Effects
   */
  useEffect(() => {
    fetchInitialDocs()
  }, [dispatch])

  /*
   * Template Declarations
   */
  const handleDropZone = () => dropzoneRef?.open()
  const headerActions = <DocumentHeaderActions canCreate={canCreate} handleClick={hasDocumentClassification || isVendor ? onUpload : handleDropZone} />

  /*
   * Event actions
   */
  const handleShareButtonClick = (doc) => {
    onShareButtonClick({
      id: doc.id,
      documentId: doc.documentId,
      documentTenant: doc.documentTenant,
      documentName: doc.name,
      typeClass: doc.typeClass,
      ...(doc.typeClass === 'Payslip\\CoreBundle\\Entity\\Payroll' ? { payrollId: doc.extraData.typeObject.id } : null),
    })
  }

  /*
   * Texts info
   */
  const historyHeading = `${documentsState?.documentName} history`

  return (
    <>
      <HistoryModal className='c-modal--half c-modal--overflow-y' modalRef={historyModalRef} heading={historyHeading} logs={documentLogs} />
      <DeleteConfirmationModal
        modalRef={deleteModalRef}
        onConfirmation={onConfirmDelete}
        deleteModalText={<DocumentsDeleteModalText fileName={documentsState.fileName} />}
        highlightedText={documentsState?.fileName}
      />
      <div id='is-employee-only-need' className={isEmployeeOnly ? 'u-hide-on-mobile' : 'u-padding-left'}>
        <SectionHeading text={title} />
        <Dropzone
          onDrop={(files) => uploadFile(files[0])}
          ref={(node) => {
            dropzoneRef = node
          }}
          className='u-relative'
        />
        <div className='o-layout'>
          <DocumentList
            isVendorDocuments={isVendorDocuments}
            extraFilters={extraFilters}
            filterName={filterName}
            typeClass={typeClass}
            typeId={typeId}
            typeTenant={typeTenant}
            filterNames={filterNames}
            omittedColumns={omittedColumns}
            headerActions={headerActions}
            DocumentActions={generateDocActions({
              isCot,
              canCreate,
              handleShareButtonClick,
              handleHistoryClick,
              handleEditClick,
              handleTrashAction,
            })}
            dataMapper={(documents) =>
              documentsMapper({
                documents,
                showCategoryPayrollName,
              })
            }
          />
        </div>
      </div>
      {isEmployeeOnly && <DocumentEmployeeView title={title} documents={documents} />}
    </>
  )
}

DocumentsViewTable.propTypes = {
  extraFilters: PropTypes.object,
  isVendorDocuments: PropTypes.bool,
  typeTenant: PropTypes.string,
  typeClass: PropTypes.string,
  typeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filterNames: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  uploadFile: PropTypes.func,
  onUpload: PropTypes.func,
  hasDocumentClassification: PropTypes.bool,
  title: PropTypes.string,
  showCategoryPayrollName: PropTypes.bool,
  openShareModal: PropTypes.func,
  openEditModal: PropTypes.func,
  isCot: PropTypes.bool,
  canCreate: PropTypes.bool,
  isVendor: PropTypes.bool,
  isEmployeeOnly: PropTypes.bool,
  fetchInitialDocs: PropTypes.func.isRequired,
}

export default DocumentsViewTable
