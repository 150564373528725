import { ActionUtility } from 'utils/redux'
import { dateTransformer, normalizeDate } from 'redux/transformers/dateTransformer'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_TASK_FETCH = 'PAYROLL_INSTANCE_TASK_FETCH'
export const PAYROLL_INSTANCE_TASK_RECEIVE = 'PAYROLL_INSTANCE_TASK_RECEIVE'
export const PAYROLL_INSTANCE_TASK_UPDATE = 'PAYROLL_INSTANCE_TASK_UPDATE'
export const PAYROLL_INSTANCE_TASK_INVALIDATE = 'PAYROLL_INSTANCE_TASK_INVALIDATE'
export const PAYROLL_INSTANCE_TASK_FILTER = 'PAYROLL_INSTANCE_TASK_FILTER'
export const PAYROLL_INSTANCE_TASK_FILTERED = 'PAYROLL_INSTANCE_TASK_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_TASK_FETCH,
  receive: PAYROLL_INSTANCE_TASK_RECEIVE,
  update: PAYROLL_INSTANCE_TASK_UPDATE,
  invalidate: PAYROLL_INSTANCE_TASK_INVALIDATE,
  filter: PAYROLL_INSTANCE_TASK_FILTER,
  filtered: PAYROLL_INSTANCE_TASK_FILTERED
}
const URI = 'payrollinstancetasks'
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceTask', URI, 'PayrollInstanceTask')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceTaskIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceTask = actionUtility.fetchEntities
export const invalidatePayrollInstanceTask = actionUtility.invalidate

export const updatePayrollInstanceTask = entity => {
  return dispatch => {
    const normalized = dateTransformer(entity, ['deadline'], '', 'YYYY-MM-DD')

    let owners
    const isMAO = normalized.taskableType === 'BusinessUnit'

    if (entity.owner !== undefined) {
      // In the case the single owner is not declared explicitly, then we don't send it via the request data,
      // because of permissions / auth restrictions it's possible the logged in user to not have enough rights
      // for updating it.
      // In such cases - we send the rest data only.
      owners = buildSingleOwners(normalized)
    } else if (isMAO) {
      owners = buildMultiOwners(normalized)
    }

    const data = {
      ...owners && { owners },
      deadline: normalized.deadline,
      taskableType: normalized.taskableType
    }

    return dispatch(actionUtility.updateEntity(data, normalized.id, false, true))
  }
}

export const updatePayrollInstanceTaskStatus = ({ id, ...entity }) => {
  return dispatch => {
    return dispatch(actionUtility.updateEntity(entity, id))
  }
}

/*
 * Build data to update single owner
 */
const buildSingleOwners = (entity) => {
  return [{
    owner: entity.owner,
    taskableId: entity.companyId
  }]
}

/*
 * Build data to update multi owner
 */
const buildMultiOwners = (entity) => {
  const multiOwners = []
  const picked = _.pickBy(entity, (value, key) => _.startsWith(key, 'actionOwnerBu-'))
  _.forOwn(picked, (value, key) => {
    multiOwners.push({
      owner: value,
      taskableId: parseInt(key.split('-')[1])
    })
  })

  return multiOwners
}

const normalizeTask = ({ id, status }) => ({ [id]: { status } })

const normalizeReversionTask = task => {
  const { id, status, type, reason: reasonValue, otherReason, note, deadlines } = task
  const reason = reasonValue === 'Other' ? otherReason : reasonValue

  const common = {
    [id]: {
      status,
      reversion: {
        type,
        note,
        reason
      },
    }
  }

  if (type === 'ClarificationRequired') {
    return common
  }

  if (type === 'NewRunRequested') {
    return {
      [id]: {
        ...common[id],
        reversion: {
          ...common[id].reversion,
          deadlines: deadlines && deadlines.map(date => normalizeDate(date))
        }
      }
    }
  }

  return {}
}

const normalizeTasksMerge = data => {
  return Object.keys(data).reduce((tasks, key) => {
    const task = data[key]

    if (task.type) {
      return { ...tasks, ...normalizeReversionTask(task) }
    }

    return { ...tasks, ...normalizeTask(task) }
  }, {})
}

export const mergePayrollInstanceTasks = (data, { shouldNormalize = true, shouldRefetch = true, ...rest } = {}) => {
  return (dispatch, getState, { api }) => {
    const payload = shouldNormalize ? { data: normalizeTasksMerge(data) } : { data }

    return api.put(`${URI}/update`, { payload, ...rest })
      .then(errorsHandling.handlePivotNestedJSONNormalize)
      .then(() => shouldRefetch && dispatch(fetchPayrollInstanceTask(rest)))
  }
}
