import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'

const StepCreate = (props) => {
  const { services, processes } = props
  return (
    <div>
      <SectionHeading text='Create step' />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <Form showSubmitBtn btnText='Submit' {...props}>
            <div className='o-form-group'>
              <Field
                name='name'
                label='Name'
                component={InputField}
                type='text'
                className='c-input'
                labelClassName='c-label'
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='service'
                label='Service'
                component={CustomSelectField}
                labelClassName='c-label'
                clearable={false}
                placeholder={services.length ? 'Select a service' : 'Loading...'}
                options={services.map(service => ({
                  value: service.id,
                  label: service.name
                }))}
              />
            </div>
            <div className='o-form-group'>
              <Field
                name='process'
                label='Process'
                component={CustomSelectField}
                type='number'
                labelClassName='c-label'
                clearable={false}
                placeholder='Select a process'
                options={processes.map(process => ({
                  value: process.id,
                  label: process.name
                }))}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'step', // a unique identifier for this form
})(StepCreate)
