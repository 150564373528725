import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import LeaveCreate from '../components/LeaveCreate'
import { getEmployee } from '../../../selectors/employeesORM'
import termCategoriesConfig from 'redux/config/termCategories'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import { fetchEmployeeAttendance } from 'redux/actions/employeeAttendance'
import { getCompanyCountryTermsPivotByCompanyAndCategoryForAttendance } from 'redux/selectors/companyCountryTermPivot'
import { bulkAttachAttendances, fetchEmployeeAttendancePeriods } from 'redux/actions/employeeAttendancePeriods'

const mapDispatchToProps = (dispatch, props) => {
  const employeeId = parseInt(props.match.params.employeeId)

  return {
    onSubmit: data => (
      dispatch(bulkAttachAttendances(data)).then((res) => {
        props.onCreate()
        dispatch(fetchEmployeeAttendancePeriods(employeeId))
        dispatch(fetchEmployeeAttendance())
      })
    )
  }
}

const mapStateToProps = (state, props) => {
  const employee = getEmployee(state, { employeeId: props.match.params.employeeId })

  const leaveTypes = getCompanyCountryTermsPivotByCompanyAndCategoryForAttendance(state, {
    companyId: employee.company,
    categoryName: termCategoriesConfig.timeAttendance
  })

  return {
    leaveTypes,
    initialValues: {
      employeeId: employee.id,
      from: null,
      to: null,
      actualLastDayOfLeave: null,
      lastDayOfWork: null,
      totalDays: null
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'employeeLeave'
})(LeaveCreate))

export default Fetcher(Container, [
  'terms', 'termCategories', 'termSubCategories',
  'countryTermPivot', 'companyCountryTermPivot',
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
