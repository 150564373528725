import _ from 'lodash'
import { amountTransformer } from 'redux/transformers/amountsTransformer'

/**
 * Transform data to its appropriate form
 * before sending it to the API
 *
 * @param [] data - post data
 * @param {Function} validate
 * @return [] data - transformed post data
 */
export const normalizeSyncTermsData = (data, validate = value => value) => {
  let pivotData = {}
  pivotData['data'] = {}

  // sync terms
  _.forOwn(data, function (value, key) {
    if (_.includes(key, 'fixed-term-') && validate(value)) {
      key = _.replace(key, /fixed-term-/g, '')

      pivotData['data'][key] = {
        amount: value
      }
    } else if (_.includes(key, 'variable-term-') && validate(value)) {
      key = _.replace(key, /variable-term-/g, '')

      pivotData['data'][key] = {}
    } else if (_.includes(key, 'deduction-') && validate(value.amount)) {
      key = _.replace(key, /deduction-/g, '')
      const { validFrom, validTo, amount } = value

      pivotData['data'][key] = { validFrom, validTo, amount }
    } else if (_.includes(key, 'term-') && validate(value)) {
      key = _.replace(key, /term-/g, '')

      pivotData['data'][key] = {
        amount: value
      }
    }
  })

  return pivotData
}

export const normalizeSyncTermsDataInstance = data => {
  let normalizedData = normalizeSyncTermsData(data)
  let normalizedDataArr = Object.keys(normalizedData.data)

  let termsDataArr = {
    data: {}
  }

  normalizedDataArr.forEach((entry) => {
    termsDataArr.data[entry] = {}
  })

  return termsDataArr
}

/**
 * Transform data to its appropriate form
 * before sending it to the API
 *
 * @param {Array} entity - post data
 * @param {Array} fieldNames - post data
 * @param {Object} extraFields - if passed in, we add the value of the field
 * to the transformed data
 *
 * @return [] data - transformed post data
 */
export const normalizeSyncData = (entity, fieldName, validateValue = value => value !== '') => {
  let data = {}
  data['data'] = {}

  _.forOwn(entity, function (value, key) {
    if (_.includes(key, 'id-') && validateValue(value)) {
      key = parseInt(_.replace(key, /id-/g, ''))
      data['data'][key] = {}

      if (fieldName) data['data'][key][fieldName] = value
    }
  })

  return data
}
// This method will be merged with the above one, and all the places where one of them is used, will be refactored in other branch
export const normalizeSyncAllData = (entity, fieldNames = [], extraFields = {}, validateValue = value => value !== '') => {
  let data = {}
  data['data'] = {}

  _.forOwn(entity, function (value, key) {
    if (_.includes(key, 'id-') && validateValue(value)) {
      key = parseInt(_.replace(key, /id-/g, ''), 10)
      data['data'][key] = {}

      // In the `fieldNames` array we could have field names, which exist in the `extraFields` object as property with appropriate values.
      // example:
      // fieldNames = ['prePopulated']
      // extraFields = { prePopulated: 'all' }
      // If so, we send to the BE these properties with their values contained in `extraFields` object.
      // If this is not the case and field name in the `fieldNames` array is not present in the`extraFields` object, we check all the 'id-' properties
      // in the `entity` object and assign their boolean value to the field name.
      // example:
      // fieldNames = ['prePopulated', 'isApproved']
      // extraFields = { prePopulated: 'all' }
      // entity = {
      //    id-10: true,
      //    id-11: false
      //  }
      // The transformed data will look like the following:
      // 10: {
      //    prePopulated: 'all',
      //    isApproved: true
      //  }
      // 11: {
      //    prePopulated: 'all',
      //    isApproved: false
      // }

      if (fieldNames.length) {
        fieldNames.forEach(name => {
          if (extraFields[name]) {
            let fieldToAdd = extraFields[name]
            if (fieldToAdd !== 'undefined' && validateValue(fieldToAdd)) {
              data['data'][key][name] = fieldToAdd
            }
          } else {
            data['data'][key][name] = value
          }
        })
      }
    }
  })
  return data
}

/**
 * Transform data to its appropriate form
 * before sending it to the API
 * data param needs to be like this:
 * {
 *   0 : {id: num, amount: num},
 *   1 : {id: num, amount: num}
 *   ...
 * }
 *
 * @param [] data - post data
 * @return [] finalData - transformed post data
 */
export const normalizeMergeTermsData = data => {
  let finalData = {}
  finalData['data'] = {}

  _.forOwn(data, (object, key) => {
    finalData['data'][object.id] = {
      amount: object.amount
    }
  })

  return finalData
}

export const normalizeMergePayrollInstanceEmployeeTermsData = data => {
  let normalized = { data: [] }
  const prefix = 'payrollInstanceEmployee-term-'

  _.forOwn(data, (value, key) => {
    if (_.includes(key, prefix)) {
      // Remove prefix identifier from the key
      const normalizedKey = _.replace(key, new RegExp(prefix, 'g'), '')
      let keyData = normalizedKey.split('_')

      // setup form data
      let amount = value ? amountTransformer(value) : 0
      let employee = keyData[0]
      let cct = keyData[1]

      normalized.data.push({
        'payrollInstanceEmployee' : parseInt(employee),
        'companyCountryTerm' : parseInt(cct),
        'amount' : amount,
      })
    }
  })

  return normalized
}
