import React from 'react'

import Flag from 'components/Flag'
import { Link } from 'react-router-dom'
import { isNaN } from 'lodash'
import moment from 'moment'
import { Field } from 'redux-form'
import { Authorization } from 'containers/Authorization'
import AmountInputField from 'components/form/AmountInputField'
import InputField from 'components/form/InputField'
import DeferredField from 'components/form/DeferredField'
import { amountTransformer } from 'redux/transformers/amountsTransformer'
import { ALL_ROUTES } from 'configs/routes'
import { canLoggedInUserDownloadDocumentByType } from 'containers/documents/utils/documents'
import { rowsToBeBolded, rowsToSkipCurrencyFormatting } from 'routes/Payruns/routes/PayrollReconciliation/utils/elements'
import AmountFormat from 'components/AmountFormat'
import { formatNumberToCurrency } from './number'
import { consoleTextErrors } from './locales/errors.en'
import { isCot } from 'redux/selectors/auth'
import { useSelector } from 'react-redux'
import { CircleExclamationIcon } from 'components/icons/CircleIcon'
import { gtnValidationMessages } from './locales/payruns.en'
import { gtnValidationStatusEnums } from './enums/gtnValidationEnums'
import { ArrowTooltip } from 'components/popover/Floaters'
import { isEmpty } from './fnkit/typeChecks'

/**
 * Icon cell formatter
 *
 * Used to build an icon
 *
 * @param cell
 * @return {*}
 */
export const icon = ({
  cell: {
    value,
    row: { values },
  },
}) => {
  return <span className={`icon icon--${value}`} />
}

/**
 * Country cell formatter
 *
 * Converts country object to flag icon and country name
 *
 * @param cell
 * @param row
 * @return {*}
 */
export const country = ({
  cell: {
    value,
    row: { values },
  },
}) => {
  return (
    <div className='o-grid o-grid--around text-center' title={values.company}>
      <Flag flag={value.abbr} size='tiny' classes='u-margin-right-tiny' />
    </div>
  )
}

/**
 * Flag cell formatter
 *
 * Converts country abbreviation to flag icon
 *
 * @param cell
 * @param row
 * @return {*}
 */
export const flag = ({
  cell: {
    value,
    row: { values },
  },
}) => {
  return (
    <div className='o-grid u-overflow-visible' title={values.company}>
      <Flag flag={value.toLowerCase()} size='tiny' classes='u-margin-right-tiny' />
    </div>
  )
}

/**
/**
 * Status cell formatter
 *
 * Used for notifications list only
 * Shows notification date and read state
 *
 * @param cell
 * @return {*}
 */
export const status = ({ cell: { value } }) => {
  return (
    <div>
      <div>{value.createdAt}</div>
      {value.isRead ? (
        <span className='u-text--tiny' data-testid={`notification-confirmation`}>
          Read.
        </span>
      ) : (
        <Authorization permissions={['NOTIFICATION_EDIT']}>
          <button
            type='button'
            onClick={() => value.onRead(value.id)}
            className='c-btn c-btn--tiny c-btn--curious u-margin-top-tiny u-text--small'
            data-testid={`mark-as-read-button`}
          >
            Mark as Read
          </button>
        </Authorization>
      )}
    </div>
  )
}

/**
 * Input
 *
 * Used for formatting input cell
 *
 * @param cell
 * @return {*}
 */
export const input = (cell, row, formatExtraData, rowIdx) => {
  // TODO - it looks like this `input` formatter is not used anymore.
  //  Also I've checked the GIT Annotation history, in order to demystify the below condition.
  //  It looks like that the main idea is NOT to render the input field for the last row (maybe it's the total row),
  //  `formatExtraData` contains the length of all rows.
  //  However, it should be rechecked carefully and if it's not used anymore, we should remove it.
  if (formatExtraData === rowIdx + 1) return <span>{cell}</span>
  return (
    <Field
      name={`payrollInstanceEmployee-term-${cell}`}
      component={AmountInputField}
      type='text'
      placeholder='Enter value'
      className={`c-input c-input--transparent u-border-bottom--minsk ${isNaN(parseInt(cell)) ? 'u-text--left' : 'u-text--right'}`}
      formGroupClassName='u-margin-none'
    />
  )
}

export const amountDeferredInput = ({ cell }) => {
  const { row, column } = cell
  const { values } = row
  // TODO - reuse routes/Payruns/routes/rountes/Instance/selectors/payfile => `buildFieldName` function
  const fieldName = JSON.stringify({
    PIEmployeeId: values[column.employeeFieldName],
    fieldName: column.id,
  })

  return (
    <DeferredField
      name={fieldName}
      formName='payrollInstanceForm'
      component={AmountInputField}
      parse={amountTransformer}
      type='text'
      placeholder='Enter value'
      className={`c-input c-input--payrun-file rounded ${isNaN(parseInt(values[column.employeeFieldName])) ? 'u-text--left' : 'u-text--right'}`}
      formGroupClassName='u-margin-none'
    />
  )
}

export const deferredInput = ({ cell }) => {
  const { row, column } = cell
  const { values } = row

  // TODO - reuse routes/Payruns/routes/rountes/Instance/selectors/payfile => `buildFieldName` function
  const fieldName = JSON.stringify({
    PIEmployeeId: values[column.employeeFieldName],
    fieldName: column.id,
  })
  const isNotesColumn = column.id === 'notes'

  return (
    <DeferredField
      name={fieldName}
      formName='payrollInstanceForm'
      component={InputField}
      type='text'
      parse={(value) => {
        if (value === '' || value === null) return null
        return value
      }}
      placeholder='Enter value'
      className={`c-input c-input--payrun-file rounded
    ${isNotesColumn ? 'u-padding-left-tiny' : ''}
    ${isNaN(parseInt(values[column.employeeFieldName])) ? 'u-text--right' : 'u-text--left'}`}
      formGroupClassName='u-margin-none'
    />
  )
}

/**
 * Date and time cell formatter
 *
 * Used for documents list only
 * Formatting date and times as show only date in cell, and date and time on hover
 *
 * @param cell
 * @return {*}
 */
export const dateTime = (cell) => {
  return <span title={`${moment(cell.date).format('DD/MM/YYYY HH:mm')} ${cell.timezone ? cell.timezone : ''}`}>{moment(cell.date).format('DD/MM/YYYY')}</span>
}

/**
 * Date and time cell formatter for Payslip Table
 *
 * Used for documents list only
 * Formatting date and times as show only date in cell, and date and time on hover
 *
 * @param cell
 * @return {*}
 */

export const dateTimePayslipTable = (cell) => {
  return (
    <span title={`${moment(cell.value.date).format('DD/MM/YYYY HH:mm')} ${cell.value.timezone ? cell.value.timezone : ''}`}>
      {moment(cell.value.date).format('DD/MM/YYYY')}
    </span>
  )
}

/*
 * Filter to be used when the cells are formatted with the above dateTimePayslipTable formatter
 */

export const dateTimePayslipTableFilter = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = moment(row.values[id].date).format('DD/MM/YYYY')
    return rowValue ? String(rowValue).includes(String(filterValue)) : true
  })
}

/**
 * Download cell formatter
 *
 * Click on name and download file.
 *
 * @param cell
 * @return {*}
 */
export const downloadNoRestrictions = ({ cell }) => {
  return (
    <div className='u-cursor--pointer u-text--curious' onClick={() => cell.column.onClick(cell.row.original.id)}>
      {cell.value}
    </div>
  )
}

/**
 * Download cell formatter
 *
 * Used for documents list only with dynamic table
 * Click on name and download file.
 * Files restricted for download should not be clickable, just show regular text, and the restricted files
 * need to have a hover message for the user
 *
 * @param cell
 * @return {*}
 */
export const download = ({ cell }) => {
  if (cell.row.original.type) {
    if (!canLoggedInUserDownloadDocumentByType(cell.row.original.type)) {
      return (
        <div className='disabled-greyed' title='You are not allowed to download this file'>
          {cell.value}
        </div>
      )
    }
  }
  return (
    <div className='u-cursor--pointer u-text--curious' onClick={() => cell.column.onClick(cell.row.original.id)}>
      {cell.value}
    </div>
  )
}

/**
 * Download cell formatter
 *
 * Used for documents list only with PayslipTable
 * Click on name and download file.
 * Files restricted for download should not be clickable, just show regular text, and the restricted files
 * need to have a hover message for the user
 *
 * @param cell
 * @return {*}
 */

export const downloadOnPayslipTable = ({ cell }) => {
  if (isEmpty(cell.row.original.documentType)) return download({ cell })
  if (cell.row.original?.documentType.includes('GTN') && cell.row.original.gtnValidationStatus) {
    return downloadGTNFile(cell)
  }
  if (cell.row.original.documentType) {
    if (!canLoggedInUserDownloadDocumentByType(cell.row.original.documentType)) {
      return <div title='You are not allowed to download this file'>{cell.value}</div>
    }
  }
  return (
    <div title={cell.row.original.size} className='u-cursor--pointer u-text--curious' onClick={() => cell.column.onClick(cell.row.original.id)}>
      {cell.value}
    </div>
  )
}

export const downloadGTNFile = (cell) => {
  const { gtnValidationStatus, isOwner } = cell.row.original
  const isCotUser = useSelector(isCot)

  const downloadCell = (
    <div title={cell.row.original.size} className='u-cursor--pointer u-text--curious' onClick={() => cell.column.onClick(cell.row.original.id)}>
      {cell.value}
    </div>
  )

  if (!gtnValidationStatus) return downloadCell

  const noDownloadCell = (error = null) => <div title={error ? gtnValidationMessages.error.message : gtnValidationMessages.pending.message}>{cell.value}</div>

  const overrideCell = (
    <div className='d-flex ai-center jc--space-between'>
      <span title={cell.row.original.size} className='u-cursor--pointer u-text--curious' onClick={() => cell.column.onClick(cell.row.original.id)}>
        {cell.value}
      </span>
      <ArrowTooltip hoverTriggerElement={<CircleExclamationIcon />}>
        <div className='gtn-floater-box text-left'>
          <div className='u-text--mini'>
            {gtnValidationMessages.override.prefix}
            <span className='u-text--highlight'>{gtnValidationMessages.override.item1}</span>
            {gtnValidationMessages.override.concat}
            <span className='u-text--highlight'>{gtnValidationMessages.override.item2}</span>
            {gtnValidationMessages.override.suffix}
          </div>
          <div className='u-text--mini'>
            <br />
            {gtnValidationMessages.override.message}
          </div>
          <button className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-top-small' onClick={() => cell.column.onOverrideClick(cell.row.original)}>
            {gtnValidationMessages.override.label}
          </button>
        </div>
      </ArrowTooltip>
    </div>
  )

  const options = {
    [gtnValidationStatusEnums.pendingValidation]: () => (isOwner || isCotUser ? downloadCell : noDownloadCell()),
    [gtnValidationStatusEnums.validated]: () => downloadCell,
    [gtnValidationStatusEnums.notApplicable]: () => downloadCell,
    [gtnValidationStatusEnums.overwritten]: () => downloadCell,
    [gtnValidationStatusEnums.failedValidation]: () => (isOwner || isCotUser ? overrideCell : noDownloadCell()),
    [gtnValidationStatusEnums.error]: () => (isOwner || isCotUser ? downloadCell : noDownloadCell('error')),
  }

  return options[gtnValidationStatus]()
}

/**
 * Process link cell formatter
 *
 * Used for linked process text
 *
 * @param cell
 * @return {*}
 */
export const processLink = ({
  cell: {
    value,
    row: {
      values: { payroll },
    },
  },
}) => {
  return (
    <Link
      key={payroll.id}
      to={`/companies/${payroll.company.id}/dates-processes/${payroll.uri}/${payroll.id}`}
      className='o-block o-block--left u-text--curious u-text--overflow-ellipsis'
    >
      {payroll.name}
    </Link>
  )
}

/**
 * Employee personal link cell formatter
 *
 * Used for linked process text
 *
 * @param cell
 * @param row
 * @return {*}
 */
export const employeePersonalLink = ({ cell: { value, row }, column }) => {
  const { values } = row
  // If we know which field name to use for employee id, then use it. Otherwise, fallback to `row.id`.
  const employeeId = column.employeeFieldName ? values[column.employeeFieldName] : row.id

  return (
    <Link className='u-text--curious' to={`/employees/${employeeId}/personal`}>
      {value}
    </Link>
  )
}

/**
 * Formatter to add a datatest to a text cell
 *
 * Used when the column has a filter and the cell needs a data-testid
 *
 * Prefix can be passed with formatExtraData.testPrefix
 */

export const cellWithDataTestId = (cell, row, formatExtraData) => <span data-testid={`${formatExtraData.testPrefix || 'id'}-${row.id}`}>{cell}</span>

/*
 * Extra data cell formatter
 *
 * Formats cells based on an object passed as formatExtraData in the table header
 * formatExtraData: keyUserStatus
 *
 * Objects can be set in utils/enumObjects
 * keyUserStatus = {true: 'Active', false: 'Inactive'}
 *
 * @param cell
 * @param formatExtraData
 * @return {*}
 *
 * note: USED WITH DYNAMIC TABLE ONLY
 */
export const enumObjectDataFormatter = (cell, row, formatExtraData) => {
  return formatExtraData[cell]
}

/*
 * Extra data cell formatter for PayslipTable
 *
 * Formats cells based on an object passed as formatExtraData in the table header
 * formatExtraData: keyUserStatus
 *
 * Objects can be set in utils/enumObjects
 * keyUserStatus = {true: 'Active', false: 'Inactive'}
 *
 * @param cell
 * @return {*}
 */
export const enumObjectDataFormatterPayslipTable = (cell) => {
  const formattedString = cell.column.formatExtraData[cell.value]
  return <span title={formattedString}>{formattedString}</span>
}
/**
 * Key person name and hover box formatter
 *
 * Used for KeyPeopleTable only to enable text filtering on a cell that holds JSX
 *
 * Needs contact details passed in as data into the DynamicTable
 * so they become available in the row
 *
 * @param cell
 * @param row
 * @return {*}
 */

export const employeeDetailsHoverBox = ({
  cell: {
    row: { original, values },
  },
}) => {
  const { skype, officePhone, mobilePhone, workEmail, id } = original

  return (
    <div data-testid={`${id}-name`} className='hover-box-cell'>
      {values.name}
      <div className='hover-box hover-box--key-users'>
        <p>
          <span className='u-weight--regular'>Name:</span>
          <span className='u-weight--bold u-margin-left-small'>{values.name}</span>
        </p>
        {skype && (
          <p>
            <span className='u-weight--regular'>Skype:</span>
            <span className='u-weight--bold u-margin-left-small'>{skype}</span>
          </p>
        )}
        {officePhone && (
          <p>
            <span className='u-weight--regular'>Office phone:</span>
            <span className='u-weight--bold u-margin-left-small'>{officePhone}</span>
          </p>
        )}
        {mobilePhone && (
          <p>
            <span className='u-weight--regular'>Mobile phone:</span>
            <span className='u-weight--bold u-margin-left-small'>{mobilePhone}</span>
          </p>
        )}
        {workEmail && (
          <p>
            <span className='u-weight--regular'>Email:</span>
            <span className='u-weight--bold u-margin-left-small'>{workEmail}</span>
          </p>
        )}
      </div>
    </div>
  )
}

/**
 * Key person business units column formatter
 *
 * Used for KeyPeopleTable only to enable different styling within cells
 *
 * Business units with the isCompany flag are bold with the "All BUs" text
 * Business units without the flag are regular
 *
 * @param cell
 * @return {*}
 */

export const keyPeopleBusinessUnits = ({
  cell: {
    row: {
      values: { businessUnits },
    },
  },
}) => {
  const isCompanyBus = businessUnits
    .filter((bu) => bu.isCompany)
    .map((bu) => (
      <span key={bu.name} className='u-weight--medium'>
        All {bu.name} BUs
      </span>
    ))

  const otherBus = businessUnits.filter((bu) => !bu.isCompany).map((bu) => bu.name)

  const buString = isCompanyBus.length || otherBus.length ? [...isCompanyBus, ...otherBus].reduce((prev, curr) => [prev, ', ', curr]) : []

  const buTooltipString = [
    ...businessUnits.filter((bu) => bu.isCompany).map((bu) => `All ${bu.name} BUs`),
    ...businessUnits.filter((bu) => !bu.isCompany).map((bu) => bu.name),
  ].join(', ')

  return <div title={buTooltipString}>{buString}</div>
}

export const keyPeopleBases = ({
  cell: {
    row: {
      original: { code, base },
    },
  },
}) => {
  return code ? (
    <div className='o-grid o-grid--medium o-grid--center'>
      <Flag flag={code.toLowerCase()} title={base} size='tiny' />
    </div>
  ) : null
}

export const payrollInstanceHoverDates = ({
  cell: {
    row: { original, values },
  },
}) => {
  const { fromDateString, toDateString } = original

  return (
    <div className='hover-box-cell text-center'>
      {values.payDateString}
      <div className='hover-box'>
        <p>
          <span className='u-weight--regular'>From Date:</span>
          <span className='u-weight--bold'>{fromDateString}</span>
        </p>
        <p>
          <span className='u-weight--regular'>To Date:</span>
          <span className='u-weight--bold'>{toDateString}</span>
        </p>
      </div>
    </div>
  )
}

const VarianceLink = ({ shouldShowLink, payrollInstanceId, elementId, children }) => {
  if (shouldShowLink) {
    return (
      <Link
        className={`hover-box-cell u-text--curious text-right ${rowsToBeBolded.includes(elementId) ? 'u-weight--bold' : ''}`}
        to={`/${ALL_ROUTES.PAYRUNS.BASE}/${payrollInstanceId}/${ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION}?element=${elementId}`}
      >
        {children}
      </Link>
    )
  }
  return children
}

export const varianceHover = ({
  cell: {
    row: { values, original },
  },
}) => {
  const { payrollInstanceId, elementId, result, elementName } = original
  if (elementName === 'Headcount') return <span className='u-weight--bold'>{values.variance}</span>
  const hasVariance = result === 'Variance'

  return (
    <VarianceLink shouldShowLink={hasVariance} payrollInstanceId={payrollInstanceId} elementId={elementId}>
      {formatNumberToCurrency(values.variance, false, 'ENEN', null, 2)}
      <div className='hover-box hover-box--variance text-left'>
        <span className='u-text--tiny'>View on Element Reconciliation page</span>
      </div>
    </VarianceLink>
  )
}

export const variancePercentageHover = ({
  cell: {
    row: { values, original },
  },
}) => {
  const { payrollInstanceId, elementId, result, elementName } = original
  if (elementName === 'Headcount') return <span className='u-weight--bold'>{values.variance_p}</span>
  const hasVariance = result === 'Variance'

  return (
    <VarianceLink shouldShowLink={hasVariance} payrollInstanceId={payrollInstanceId} elementId={elementId}>
      {formatNumberToCurrency(values.variance_p, false, 'ENEN', null, 2)}
      <div className='hover-box hover-box--variance text-left'>
        <span className='u-text--tiny'>View on Element Reconciliation page</span>
      </div>
    </VarianceLink>
  )
}

export const versioningElementName = ({
  cell: {
    row: {
      original: { elementId, elementName },
    },
  },
}) => <span className={rowsToBeBolded.includes(elementId) ? 'u-weight--bold' : ''}>{elementName}</span>

export const versioningCellFormatting = ({
  cell: {
    row: {
      original: { elementId },
    },
    value,
  },
}) => {
  let val = value
  if (!rowsToSkipCurrencyFormatting.includes(elementId)) {
    val = formatNumberToCurrency(value, false, 'ENEN', null, 2)
  }

  return <span className={rowsToBeBolded.includes(elementId) ? 'u-weight--bold' : ''}>{val}</span>
}

export const conditionalBoldValue = (needsBoldStyle) => (cell) => {
  const row = cell?.row?.original
  const styles = needsBoldStyle(row) ? 'u-weight--bold' : ''
  return <span className={styles}>{cell.value}</span>
}

export const defaultFormat = (cell) => <div>{cell.value}</div>

export const cellRenderAction =
  ({ Actions }) =>
    (cell) => {
      const row = cell?.row?.original
      const onClick = cell.column.onClick
      return (
        <span className='cell-render-actions'>
          <Actions row={row} onClick={onClick} />
        </span>
      )
    }

export const includesFilterTable = (rows, id, filterValue) => {
  const accessor = Array.isArray(id) ? id[0] : id
  return filterValue?.length > 0
    ? rows?.filter((r) => {
      const originalRow = r.original
      return filterValue?.some((filter) => originalRow[accessor]?.includes(filter))
    })
    : rows
}

export const CellNumberFormat = (cell) => <AmountFormat amount={cell?.value || ''} />

export const CellDefaultNumberFormat = (cell) => <AmountFormat amount={cell?.value || 0} />

export const JoinColItemsByCommaFormat = (cell) => {
  let template = <div className='text-center'>-</div>
  try {
    const value = cell?.value?.map((item) => item.name).join(', ')
    template = <div>{value}</div>
  } catch (error) {
    console.error(consoleTextErrors.onTransformData, error)
  }
  return template
}

export const ResourcesExtraDataFullName = ({ cell }) => {
  return `${cell.value.firstname ?? ''} ${cell.value.surname ?? ''}`
}
