/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { owners } from 'redux/config/tasks'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import CheckboxWrapper from 'components/form/CheckboxWrapper'
import PayslipTable from './table/PayslipTable'

export const InactiveUser = () => <span className='u-text--italic u-text--small'>Inactive User</span>

class ProcessTree extends React.Component {
  buildColumns () {
    const { typeClass, isShare, sharingActionText = 'Share' } = this.props

    let headings = []

    if (typeClass !== 'vendor') {
      headings.push(
        { accessor: 'stepName', Header: 'Steps of the process', disableSortBy: true, disableFilters: true },
        { accessor: 'stepAction', Header: '', disableSortBy: true, disableFilters: true },
        { accessor: 'taskName', Header: 'Actions per step', disableSortBy: true, disableFilters: true }
      )
    }

    headings.push(
      {
        accessor: 'owner',
        Header: 'Action owner',
        setWidth: '160px',
        disableSortBy: true,
        classNames: 'white-space--unset',
        disableFilters: true
      }
    )

    if (isShare) {
      headings.push({
        accessor: 'share',
        Header: sharingActionText,
        classNames: 'text-center',
        columnClassName: 'u-text--center',
        disableSortBy: true,
        disableFilters: true,
      })
    }

    if (!isShare) {
      headings.push(
        {
          accessor: 'taskDeadline',
          Header: 'Timelines',
          classNames: 'text-center',
          columnClassName: 'text-center',
          formatExtraData: { isInheritColor: true },
          disableSortBy: true,
          disableFilters: true,
        },
        {
          accessor: 'action',
          Header: '',
          disableSortBy: true,
          disableFilters: true,
        }
      )
    }

    return headings
  }

  getTaskOwner (row) {
    const { isShare } = this.props

    // Here we have two cases:
    // 1. Task global owner is not Company, Payslip or Payroll Partner,
    // 2. The owner is Payslip or Payroll Partner and it's task is active
    if (![owners.company, owners.cot, owners.vendor].includes(row.taskGlobalOwner) ||
    ([owners.cot, owners.vendor].includes(row.taskGlobalOwner) && row.taskActive)) return row.taskGlobalOwner

    // Task global owner is company, the task has no key people for owner and the task is active
    if (!row.taskOwner.fullname &&
      !row.taskOwner.multi &&
      row.taskActive) return <span className='u-text--italic'>Company</span>

    // Task global owner is company, the task has no key people for owner and the task is not active
    if (!row.taskOwner.fullname && !row.taskOwner.multi && !row.taskActive) {
      let owner = 'Not needed'
      if (row.taskGlobalOwner === owners.cot) return `${owner} (${owners.cot})`

      if (row.taskGlobalOwner === owners.vendor) return `${owner} (${owners.vendor})`

      if (row.taskGlobalOwner === owners.company) return `${owner} (${owners.company})`

      return owner
    }

    // Task global owner is company
    // taskableType is "BusinessUnit"
    // We have to show the multi label and it has to be clickable
    if (row.taskableType === 'BusinessUnit' && !isShare) {
      return <span
        onClick={() => this.props.onMultiView(row.taskId, true)}
        className='u-text--curious u-cursor--pointer'>{row.taskOwner.multi}</span>
    }

    // Share mode on files section
    // Task global owner is company
    // taskableType is "BusinessUnit"
    // We have to show the task owner of every single business unit
    if (row.taskableType === 'BusinessUnit' && isShare) {
      return <div>
        {
          row.taskOwner.multiOwners.map(owner => {
            return owner.hasCredentials ? <div className='u-margin-bottom-tiny'>{ owner.fullname }</div>
              : <div><InactiveUser /></div>
          })
        }
      </div>
    }

    // In any other case display just the task owner full name
    return row.taskOwner.hasCredentials ? row.taskOwner.fullname : <InactiveUser />
  }

  getTaskOwnerShareCheckbox (row) {
    const { initialValues, isOwner } = this.props
    // The task has single taskOwner and the owner has credentials
    if (row.taskOwner && row.taskOwner.id && row.taskOwner.hasCredentials) {
      return <Field
        type='checkbox'
        labelClassName='u-hidden'
        component={CheckboxField}
        className='control__indicator__input'
        indicatorClassName='control__indicator--center'
        formGroupClassName='u-margin-none'
        name={`${row.taskOwner.schema}-${row.taskOwner.id}`}
        disabled={initialValues[`${row.taskOwner.schema}-${row.taskOwner.id}`] && !isOwner}
        data-testid={`share-checkbox-${row.taskOwner.schema}-${row.taskOwner.id}`}
      />
    }

    // The task has multi taskOwner-s
    if (row.taskOwner.multiOwners && row.taskOwner.multiOwners.length) {
      return <div>
        {
          row.taskOwner.multiOwners.map(owner => {
          // In the case the `owner` doesn't have credentials, we imitate and still render `CheckboxWrapper` layout,
          // in order too keep the checkboxes aligned alongside to owner's names.
          // Otherwise the checkboxes will be displaced.
            return owner.hasCredentials ? <Field
              type='checkbox'
              labelClassName='u-hidden'
              component={CheckboxField}
              className='control__indicator__input'
              indicatorClassName='control__indicator--center'
              formGroupClassName='u-margin-bottom-tiny'
              name={`${owner.schema}-${owner.id}`}
              disabled={initialValues[`${row.taskOwner.schema}-${row.taskOwner.id}`] && !isOwner}
              data-testid={`share-checkbox-${row.taskOwner.schema}-${row.taskOwner.id}`}
            /> : <div className='o-form-group u-relative u-margin-bottom-tiny u-zero-opacity'>
              <CheckboxWrapper />
            </div>
          })
        }
      </div>
    }

    return null
  }

  render () {
    const { rows,
      name,
      owner,
      processId,
      isPayrollProcess,
      onProcessEdit,
      onTaskEdit,
      onStepEdit,
      onCreate,
      payDate,
      keyEvent,
      sharingActionText = 'Share',
      keyEventDate,
      isCot,
      isEditDisabled,
      isShare,
      hideProcessOwner,
      addBtnTitle = 'Add',
      isOwner,
      initialValues,
      isReadOnlyMode,
      hideProcessDetailsAsVendor
    } = this.props

    return <>
      {!hideProcessDetailsAsVendor && !isShare &&
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-margin-bottom-small'>
          Process name: <span className='u-weight--bold'>{name}</span>
        </div>
        <div className='o-layout__item u-1/1 u-margin-bottom-small'>
          Process owner: <span className='u-weight--bold'>{owner ? owner.fullname : null}</span>
          {
            !isEditDisabled && !isReadOnlyMode ? <button
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-left-small'
              title='Edit'
              onClick={() => onProcessEdit(processId, 'editOwner')}
            >
              <span className='icon icon--edit' data-testid='processOwner-edit' />
            </button> : null
          }
        </div>
        <div className='o-layout__item u-1/1 u-margin-bottom-small'>
          Process key event: <span className='u-weight--bold'>{keyEvent}</span>
        </div>
        {
          isPayrollProcess
            ? <div className='o-layout__item u-1/1 u-margin-bottom-small'>
              Event date: <span className='u-weight--bold'>{payDate}</span>
            </div>
            : null
        }
        {/* {
          !isPayrollProcess
            ? <div className='o-layout__item u-1/1 u-margin-bottom-small'>
              Process key event date: <span className='u-weight--bold'>
                {
                  keyEventDate && keyEventDate.date
                    ? <Moment format='DD/MM/YYYY'>{keyEventDate.date}</Moment>
                    : null
                }
              </span>
              {
                !isEditDisabled && !isReadOnlyMode
                  ? (
                    <button
                      className='c-btn c-btn--tiny c-btn--curious u-text--tiny
                      u-margin-left-small'
                      title='Edit'
                      onClick={() => onProcessEdit(processId, 'editKeyEventDate')}
                    >
                      <span className='icon icon--edit' data-testid='keyEventDate-edit' />
                    </button>
                  )
                  : null
              }
            </div>
            : null
        } */}
      </div>
      }
      <div className='o-layout'>
        <div className={!isShare ? 'o-layout__item u-1/1 u-2/3@desktop' : 'o-layout__item u-1/1'}>
          { !isShare && isPayrollProcess && !isEditDisabled && <div className='u-text--right'>
            <div
              onClick={onCreate}
              className='c-btn c-btn--tiny u-text--tiny u-cursor--pointer c-btn--curious u-margin-bottom-tiny'
              style={{ marginRight: 8 }}
              title={addBtnTitle}
              data-testid='create'
            >
              <span className='icon icon--plus' />
            </div>
          </div>
          }
          {
            isShare && owner && <PayslipTable
              data={!hideProcessOwner ? [{
                id: owner.id,
                name: owner.hasCredentials ? owner.fullname : <InactiveUser />,
                share: owner.hasCredentials ? <Field
                  type='checkbox'
                  labelClassName='u-hidden'
                  component={CheckboxField}
                  className='control__indicator__input'
                  indicatorClassName='control__indicator--center'
                  formGroupClassName='u-margin-none forced-padding-right--8px'
                  name={`${owner.schema}-${owner.id}`}
                  disabled={initialValues[`${owner.schema}-${owner.id}`] && !isOwner}
                  data-testid={`share-checkbox-${owner.schema}-${owner.id}`}
                /> : null
              }] : []}
              headings={[
                { accessor: 'name', Header: 'Process owner', disableSortBy: true, disableFilters: true },
                { accessor: 'share',
                  Header: sharingActionText,
                  classNames:'text-right',
                  columnClassName: 'u-text--right',
                  disableSortBy: true,
                  disableFilters: true
                },
              ]}
              wrapperClassName='u-1/1 u-margin-bottom'
              trClassName='react-bs-table__cell--whitespace--normal'
            />
          }

          <PayslipTable
            data={
              rows.map(row => ({
                id: row.id,
                stepName: row.stepName,
                stepAction: row.stepIsMoveable && !isEditDisabled ? <button
                  className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                  title='Edit'
                  onClick={() => onStepEdit(row.stepId)}
                  data-testid={`step-${row.id}-edit`}
                >
                  <span className='icon icon--edit' />
                </button>
                  : null,
                taskName: row.taskName,
                owner: this.getTaskOwner(row),
                ...isShare ? { share: this.getTaskOwnerShareCheckbox(row) } : {},
                taskDeadline: row.taskDeadline,
                action: !hideProcessDetailsAsVendor && row.taskName && (isCot ||
                  ((row.taskGlobalOwner === owners.company || row.taskGlobalOwner === owners.vendor) &&
                    !isEditDisabled)) &&
                  !isReadOnlyMode ? <button
                    className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-float--right'
                    title='Edit'
                    onClick={() => onTaskEdit(row.taskId)}
                    data-testid={`task-${row.taskId}-edit`}
                  >
                    <span className='icon icon--edit' />
                  </button>
                  : null
              }))
            }
            headings={this.buildColumns()}
            tableElementClassName='u-margin-bottom-large table--layout-auto'
            wrapperClassName='u-1/1'
            data-testid='process-tree-table'
          />
        </div>
      </div>
    </>
  }
}

ProcessTree.propTypes = {
  typeClass: PropTypes.string,
  processId: PropTypes.number,
  isPayrollProcess: PropTypes.bool,
  isCot: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  name: PropTypes.string,
  owner: PropTypes.object,
  payDate: PropTypes.string,
  keyEvent: PropTypes.string,
  keyEventDate: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.shape({
    stepName: PropTypes.string,
    taskName: PropTypes.string,
    taskOwner: PropTypes.object,
    taskDeadline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  onTaskEdit: PropTypes.func,
  onMultiView: PropTypes.func,
  onProcessEdit: PropTypes.func,
  isReadOnlyMode: PropTypes.bool,
  isShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  initialValues: PropTypes.object,
  sharingActionText: PropTypes.string,
  onStepEdit: PropTypes.func,
  onCreate: PropTypes.func,
  hideProcessOwner: PropTypes.bool,
  addBtnTitle: PropTypes.string,
  hideProcessDetailsAsVendor: PropTypes.bool,
}

export default ProcessTree
