import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const VENDOR_COUNTRY_TASK_FETCH = 'VENDOR_COUNTRY_TASK_FETCH'
export const VENDOR_COUNTRY_TASK_RECEIVE = 'VENDOR_COUNTRY_TASK_RECEIVE'
export const VENDOR_COUNTRY_TASK_CREATE = 'VENDOR_COUNTRY_TASK_CREATE'
export const VENDOR_COUNTRY_TASK_UPDATE = 'VENDOR_COUNTRY_TASK_UPDATE'
export const VENDOR_COUNTRY_TASK_INVALIDATE = 'VENDOR_COUNTRY_TASK_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: VENDOR_COUNTRY_TASK_FETCH,
  receive: VENDOR_COUNTRY_TASK_RECEIVE,
  create: VENDOR_COUNTRY_TASK_CREATE,
  update: VENDOR_COUNTRY_TASK_UPDATE,
  invalidate: VENDOR_COUNTRY_TASK_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'vendorCountryTaskPivot', 'vendorcountrytasks', 'VendorCountryTask')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchVendorCountryTaskPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchVendorCountryTaskPivot = actionUtility.fetchEntities
export const createVendorCountryTask = actionUtility.createEntity
export const updateVendorCountryTask = actionUtility.updateEntity
export const invalidateVendorCountryTask = actionUtility.invalidate
