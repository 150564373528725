import React from 'react'
import { connect } from 'react-redux'
import MessageModal from '../components/MessageModal'
import { clearMessage } from 'redux/actions/modal'
import { TOKEN_ERROR } from 'utils/api'

/**
 * Display a message in a Modal
 *
 * The modal would be opened on `showMessage()` action dispatchting
 * Modal hiding is handled by `<MessageModal />` component automatically,
 * we just clear the modal data in the Store.
 */
class ModalContainer extends React.Component {
  constructor (props) {
    super(props)
    this.onHide = this.onHide.bind(this)
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (nextProps) {
    const isModalChanged = this.props.modal !== nextProps.modal
    // Display the modal, only if a new modal store data is being dispatched
    // and the modal has to be opened
    if (isModalChanged && nextProps.showModal) {
      this.refs.modal.showModal()
    }

    // Hide the modal, only if the modal was visible before and now is hidden
    if (isModalChanged && !nextProps.showModal) {
      this.refs.modal.hideModal()
    }
  }

  onHide () {
    const { content } = this.props
    const isTokenError = content.message === TOKEN_ERROR

    this.props.clearMessage()

    // We do this workaround (reloading the tab, when there's a token error message),
    // in order to reload the FE build assets, when a new FE version is deployed.
    // Here's what happens behind the scenes:
    // The BE invalidates all the tokens, when they deploy a new FE or BE version.
    // Knowing that, we logout the user and  display a modal message with `TOKEN_ERROR`.
    // With this workaround, we will require the user to get the new version.
    // I know it's a very hackish way of getting the new updates,
    // but the requirement was to handle the deploys somehow.
    // Once the task's priority changed, we can handle it better.
    if (isTokenError) window.location.reload()
  }

  render () {
    const { content } = this.props

    return <MessageModal className='c-modal' ref='modal' onHide={this.onHide} {...content} />
  }
}

const mapDispatchToProps = { clearMessage }

const mapStateToProps = ({ modal, modal: { title, body } }) => {
  const showModal = body !== null

  return {
    showModal,
    modal,
    content: showModal
      ? {
        ...(modal.title ? { modalHeading: modal.title } : {}),
        message: modal.body,
      }
      : {},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
