import React from 'react'
import PropTypes from 'prop-types'
import AccordionBody from 'components/accordion/AccordionBody'
import AccordionHeader from 'components/accordion/AccordionHeader'
const classNames = require('classnames')

class AccordionView extends React.Component {
  getAccordionClasses () {
    const { headerColor, expanded, classNames: classes, useShadow = false } = this.props
    let css = classNames({
      'u-bg--white u-margin-bottom u-padding-left-small u-padding-right-small': true,
      'u-bg--minsk': headerColor === 'minsk',
      'rounded-md': true,
      'u-bg--lilac bg-expanded-hover': expanded,
      'shadow-md': useShadow,
      'border-1-valhalla': true,
    })

    return `${css} ${classes || ''}`
  }

  render () {
    const { dataTestId } = this.props

    return (
      <div className={this.getAccordionClasses()} data-testid={`accordion-${dataTestId || 'data'}`}>
        <AccordionHeader {...this.props} />
        <AccordionBody {...this.props} />
      </div>
    )
  }
}

AccordionView.propTypes = {
  dataTestId: PropTypes.string,
  headerColor: PropTypes.string,
  rounded: PropTypes.bool,
  useShadow: PropTypes.bool,
  classNames: PropTypes.string,
  expanded: PropTypes.bool,
}

export default AccordionView
