/* eslint-disable react/jsx-no-bind */
import React from 'react'
import PropTypes from 'prop-types'
import BaseConfirmationModal from './BaseConfirmationModal'
import { ConfirmButton, RejectButton } from './buttons/'

class StepperConfirmationModal extends React.Component {
  constructor (props) {
    super(props)
    this.modalRef = React.createRef()
  }

  showModal = () => {
    this.modalRef.current.showModal()
  }

  hideModal = () => {
    this.modalRef.current.hideModal()
  }

  onConfirm = () => {
    this.props.onConfirm()
    this.hideModal()
  }

  onNextStep = () => {
    if (this.props.onNextStep) {
      this.props.onNextStep()
    }
  }

  onReject = () => {
    if (this.props.onReject) {
      this.props.onReject()
    }

    this.hideModal()
  }

  render () {
    const showNextStep = this.props.onNextStep && !this.props.hideNextStep && !this.props.disableConfirmButton
    return (
      <BaseConfirmationModal ref={this.modalRef} {...this.props}>
        {this.props.children}
        {showNextStep && (
          <>
            <ConfirmButton buttonText={this.props.confirmButtonText} onClick={this.onNextStep.bind(this)} disabled={this.props.disableConfirmButton} />
            <RejectButton buttonText={this.props.rejectButtonText} onClick={this.onReject.bind(this)} />
          </>
        )}
        {this.props.isProcessCompleted && (
          <>
            <ConfirmButton buttonText={this.props.confirmButtonText} onClick={this.onConfirm.bind(this)} disabled={this.props.disableConfirmButton} />

            <RejectButton buttonText={this.props.rejectButtonText} onClick={this.onReject.bind(this)} />
          </>
        )}
      </BaseConfirmationModal>
    )
  }
}

StepperConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  confirmButtonText: PropTypes.string,
  disableBackdropClose: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  rejectButtonText: PropTypes.string,
  isProcessCompleted: PropTypes.bool,
  onNextStep: PropTypes.func,
  hideNextStep: PropTypes.bool,
  ref: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default StepperConfirmationModal
