import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'redux-form'

import SectionHeading from 'components/SectionHeading'
import PasswordInputField from 'components/form/PasswordInputField'
import Form from 'components/form/Form'
import Loader from 'components/Loader'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'
import { userRelatedActions } from 'utils/locales/common.en'

const ChangePasswordView = (props) => {
  if (props.isFetching) return <Loader />

  const keycloak = useContext(KeycloakCTX)
  const hasRHSSO = useSelector((state) => state.config.migratedToRHSSO)

  return (
    <div className='u-margin-bottom-large'>
      {!props.isUserSSOEnabled && (
        <>
          <SectionHeading text='Change password' />
          {hasRHSSO ? (
            <button
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              type='button'
              onClick={() => keycloak.login({ action: 'UPDATE_PASSWORD' })}
            >
              {userRelatedActions.changePassword}
            </button>
          ) : (
            <div className='o-layout'>
              <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
                <Form showSubmitBtn isButtonCentered={false} colorClassBtn='c-btn--curious c-btn--mobile' btnText='Send' {...props}>
                  <div className='o-form-group'>
                    <Field
                      name='old'
                      label='Current Password'
                      component={PasswordInputField}
                      type='password'
                      autoFocus
                      className='c-input c-input--mobile'
                      labelClassName='c-label c-label--mobile'
                      passwordField
                    />
                  </div>
                  <div className='o-form-group'>
                    <Field
                      name='password'
                      label='New Password'
                      component={PasswordInputField}
                      type='password'
                      className='c-input c-input--mobile'
                      labelClassName='c-label c-label--mobile'
                      passwordField
                      showPasswordStrengthBox
                    />
                  </div>
                  <div className='o-form-group'>
                    <Field
                      name='passwordConfirmation'
                      label='Password Confirmation'
                      component={PasswordInputField}
                      type='password'
                      className='c-input c-input--mobile'
                      labelClassName='c-label c-label--mobile'
                      passwordField
                    />
                  </div>
                </Form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ChangePasswordView
