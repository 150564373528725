import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import FiltersPayrollReport from '../../../components/FiltersPayrollReport'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getCurrencies } from 'redux/selectors/currencies'
import { getFxRates } from 'redux/selectors/fxRates'
import { getCCTsByCompaniesAndCategoriesAndSubcategories, getCCTsSubCategoriesByCompaniesAndCategories } from './selectors/companyCountryTermPivot'
import { replace, isArray, includes } from 'lodash'
import Fetcher from 'containers/Fetcher'
import { sortByName } from 'utils/strings'

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'type',
    'category',
    'subcategory',
    'termCategory',
    'termSubcategory',
    'term',
    'combinedSubcategory',
    'showOnlyTotals',
    'fromDate',
    'toDate',
    'country',
    'company',
    'payroll',
    'payrollCurrency',
    'reportingCurrency',
    'reportingRate'
  )

  const allCurrencies = getCurrencies(state)

  const selectorFilter = {
    companiesIds: values.company || [],
    categoriesIds:
      props.hasElementsSelections && values.combinedSubcategory
        ? values.combinedSubcategory.filter((value) => includes(value, 'Terms-')).map((category) => parseInt(replace(category, /Terms-/g, '')))
        : [],
    showOnlyTotals: values.showOnlyTotals,
  }
  return {
    // All selected values
    selected: values,
    termsubcategories: getCCTsSubCategoriesByCompaniesAndCategories(state, selectorFilter),
    terms: getCCTsByCompaniesAndCategoriesAndSubcategories(state, { ...selectorFilter, subcategoriesIds: values.termSubcategory || [] }),
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected country
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] })
      : getCompaniesByAuth(state, props),
    // All currencies
    currencies: allCurrencies,
    fxRates: getFxRates(state),
    initialValues: {
      subcategory: null,
      termCategory: null,
      payrollCurrency: null,
    },
  }
}

const Container = connect(mapStateToProps)(FiltersPayrollReport)

export default Fetcher(
  Container,
  [
    'termCategories',
    'termSubCategories',
    'terms',
    'countries',
    'countryTermPivot',
    'companies',
    'currencies',
    'payrolls',
    'fxRates',
    'accessAreaPivot',
    'companyCountryTermPivot',
  ],
  { wrapperDivClassName: 'o-block--inline' }
)
