import InnerWrapper from 'layouts/InnerWrapper'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import VendorCompanyRoute from './routes/VendorCompany'

export default (store) => ({
  path : 'companies',
  indexRoute: { component: RouteView, wrappedComponent: InnerWrapper },
  wrappedComponent: CoreLayout,
  authProtected: true,
  childRoutes: [ VendorCompanyRoute(store) ]
})
