import CoreLayout from 'layouts/PageLayout/PageLayout'
import InnerWrapper from 'layouts/InnerWrapper'
import VendorContainer from './containers/VendorContainer'

export default (store) => ({
  path : 'company-details',
  wrappedComponent: CoreLayout,
  authProtected: true,
  indexRoute: { component: VendorContainer, wrappedComponent: InnerWrapper }
})
