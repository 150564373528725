import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, destroy } from 'redux-form'
import AuthCodeView from '../components/AuthCodeView'
import { login, getCodeByCredentials } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import { withRouter } from 'react-router'
import strings from 'strings'
import { isCotDomain } from 'utils/url'

class AuthCodeContainer extends React.Component {
  render () {
    return <AuthCodeView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state) => {
  const selector = formValueSelector('login')
  const values = selector(state, 'username', 'password')
  return {
    values,
    config: state.config,
    initialValues: {
      username: values.username,
      password: values.password,
      authCode: null
    }
  }
}
const getModalMessage = (senderService) => {
  return strings.authentication.twoFAWithoutAuth[senderService] ?? ''
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { values, config } = stateProps
  let hostUrlCot = isCotDomain(config.apiUrl)
  let senderService = hostUrlCot ? config.senderServiceCot : config.senderService

  const credentials = {
    username: values.username,
    password: values.password
  }

  return {
    senderService: senderService,
    getNewCode: () => dispatch(getCodeByCredentials(credentials)).then(() => {
      dispatch(showMessage({
        body: getModalMessage(senderService),
      }))
    }),
    onSubmit: (data) => {
      credentials['authCode'] = data.authCode
      return dispatch(login(credentials)).then((res) => {
        const responseData = res.payload
        const { changePasswordToken } = responseData

        changePasswordToken
          ? ownProps.history.push('/change-password')
          : ownProps.history.push('/home')
        dispatch(destroy('login'))
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'authCode'
})(withRouter(AuthCodeContainer)))
