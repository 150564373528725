import createSelector from 'utils/createSelector'
import { getDocumentUsersByDocIdAndDocTenant } from './documentUsers'

const getCompanyId = (state, props) => parseInt(props.companyId)
const getVendorId = (state, props) => parseInt(props.vendorId)

// Get filtered by company id external vendor users with shared docs info
export const getExternalVendorUsersByCompanyIdWithSharedDocuments = createSelector(
  getCompanyId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ ExternalVendorUser }, companyId, docUsers) => {
    return ExternalVendorUser.filter((user) => user.extraData.company.includes(companyId))
      .toRefArray()
      .map((vendorUser) => {
        if (!docUsers) return vendorUser

        const file = docUsers.find((docUser) => docUser.userId === vendorUser.extraData.id && docUser.userTenant === vendorUser.extraData.tenant)

        return {
          ...vendorUser,
          share: file !== undefined,
        }
      })
  }
)

// Get only external vendor users that particular file is shared to
export const getExternalVendorUsersByCompanyIdWithSharedDocument = createSelector(getExternalVendorUsersByCompanyIdWithSharedDocuments, (session, users) =>
  users.filter((u) => u.share)
)

// Get only external vendor users these having credentials
export const getExternalVendorUsersByCompanyIdHavingCredentials = createSelector(getExternalVendorUsersByCompanyIdWithSharedDocuments, (session, users) =>
  users.filter((u) => u.hasCredentials)
)

// Get filtered by vendor id external vendor users with shared docs info
export const getExternalVendorUsersByVendorIdWithSharedDocuments = createSelector(
  getVendorId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ ExternalVendorUser }, vendorId, docUsers) => {
    return ExternalVendorUser.all()
      .toModelArray()
      .filter((user) => user.vendor === vendorId && user.hasCredentials)
      .map((vendorUser) => {
        if (!docUsers) return vendorUser

        const file = docUsers.find((docUser) => docUser.userId === vendorUser.extraData.id && docUser.userTenant === vendorUser.extraData.tenant)

        return {
          ...vendorUser,
          share: file !== undefined,
        }
      })
  }
)
