import createSelector from 'utils/createSelector'
import { filterByPeriod } from 'redux/helpers/filterByPeriod'

// get payroll instance id
const getEmployeeId = (state, props) => parseInt(props.employeeId, 10)

// get the payroll instance
export const getPayrollInstancesWithEmployeesAndTheirAttendanceOvertime = createSelector(getEmployeeId,
  ({ PayrollInstance, EmployeeAttendance, EmployeeOvertime }, employeeId) => {
    // all payroll instances, sorted by id (from newest to oldest payroll instances)
    let payrollInstances = PayrollInstance.all().toModelArray().sort((a, b) => b.id - a.id)

    // employee attendance
    let employeeAttendance = EmployeeAttendance.filter(
      ea => ea.employee === employeeId
    )

    // employee overtime
    let employeeOvertime = EmployeeOvertime.filter(
      eo => eo.employee === employeeId
    )

    let employeePI = []
    // get all PI where employee's a part of
    payrollInstances.map(pi => {
      // init payroll instance
      let payrollInstance = { ...pi.ref, payrollName: pi.payrollName }

      // cycle through payroll instance employees
      // and get only current employee
      pi.payrollInstanceEmployees
        .filter(pivotEmployee => pivotEmployee.employee === employeeId)
        .toModelArray()
        .map(pivot => {
        // get employee attendance between PI period
        // and count days absence according to cct
          filterByPeriod(
            employeeAttendance,
            'date',
            pi.fromDate.date,
            pi.toDate.date
          )
            .toModelArray()
            .map(ea => {
              payrollInstance[`${ea.companyCountryTerm.id}Count`]
                ? payrollInstance[`${ea.companyCountryTerm.id}Count`]++
                : payrollInstance[`${ea.companyCountryTerm.id}Count`] = 1
            })

          // define overtime hours
          let overtimeHours = 0

          // get employee overtime between PI period
          // and count hours according to cct
          filterByPeriod(
            employeeOvertime,
            'date',
            pi.fromDate.date,
            pi.toDate.date
          )
            .toModelArray()
            .map(ea => {
              payrollInstance[`${ea.companyCountryTerm.id}Hours`]
                ? payrollInstance[`${ea.companyCountryTerm.id}Hours`] += ea.hours
                : payrollInstance[`${ea.companyCountryTerm.id}Hours`] = ea.hours

              // add to total overtime
              overtimeHours += ea.hours
            })

          // set overtime hours
          payrollInstance['overtimeHours'] = overtimeHours
          employeePI.push(payrollInstance)
        })
    })

    // return the pi's the employee's a part of
    return employeePI
  })
