import React from 'react'

/* eslint-disable max-len */
export const MagnifierSearchIcon = ({ className, children, title, ...props }) => (
  <svg className={className} width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M52.5 86.25C71.1396 86.25 86.25 71.1396 86.25 52.5C86.25 33.8604 71.1396 18.75 52.5 18.75C33.8604 18.75 18.75 33.8604 18.75 52.5C18.75 71.1396 33.8604 86.25 52.5 86.25Z'
      fill='var(--ps-color-medium-magnifier-search)'
    />
    <path
      d='M60 82.5C41.3587 82.5 26.25 67.3912 26.25 48.75C26.25 37.0837 32.1675 26.805 41.1637 20.7412C28.11 25.4025 18.75 37.8412 18.75 52.5C18.75 71.1412 33.8587 86.25 52.5 86.25C59.475 86.25 65.955 84.1312 71.3363 80.5087C67.7887 81.7725 63.9825 82.5 60 82.5Z'
      fill='var(--ps-color-magnifier-search)'
    />
    <path
      d='M52.4994 18.75C41.2644 18.75 31.3381 24.2625 25.2031 32.7037C30.7681 28.6612 37.5931 26.25 44.9994 26.25C63.6406 26.25 78.7494 41.3587 78.7494 60C78.7494 67.4062 76.3381 74.2312 72.2956 79.7962C80.7369 73.6612 86.2494 63.735 86.2494 52.5C86.2494 33.8587 71.1406 18.75 52.4994 18.75Z'
      fill='var(--ps-color-light-magnifier-search)'
    />
    <path
      d='M52.5 86.25C71.1396 86.25 86.25 71.1396 86.25 52.5C86.25 33.8604 71.1396 18.75 52.5 18.75C33.8604 18.75 18.75 33.8604 18.75 52.5C18.75 71.1396 33.8604 86.25 52.5 86.25Z'
      stroke='var(--ps-color-dark-magnifier-search)'
      strokeWidth='5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M59.9844 31.2788C66.3669 33.5363 71.4369 38.5988 73.7056 44.9776'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M101.249 101.25L76.3711 76.3726'
      stroke='var(--ps-color-dark-magnifier-search)'
      strokeWidth='5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
