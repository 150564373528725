import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Form from 'components/form/Form'
import TextareaField from 'components/form/TextareaField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import InputField from '../../../../components/form/InputField'
import { types, getReasons } from 'redux/config/reversions'

const renderDeadlinesHeading = (isNewRunRequested, heading) => {
  if (!isNewRunRequested) return null

  return (
    <div className='o-layout__item u-1/1'>
      <h4 className='u-text--large u-weight--bold u-margin-bottom-small'>Please set up timelines for {heading}</h4>
    </div>
  )
}

const renderDeadlines = (isNewRunRequested, namePrefix, draftTasks) => {
  if (!isNewRunRequested) return null

  return draftTasks.map(({ label }, index) => <div className='o-layout__item u-1/1' key={index}>
    <div className='c-panel o-form-group o-text--center'>
      <Field
        name={`${namePrefix}.deadlines[${index}]`}
        label={label + ' *'}
        labelClassName='c-label'
        component={FieldDatePicker}
        required
        data-testid={`deadline-${JSON.parse(namePrefix).id}-${index}`}
      />
    </div>
  </div>)
}

const PayrollInstanceTaskReversionForm = ({ reversibleTaskId, reversionType, reversionReason, reversionDraftableTasks,
  reversionDraftableTasksHeading, ...rest }) => {
  const namePrefix = reversibleTaskId
  const isClarificationRequired = reversionType === 'ClarificationRequired'
  const isNewRunRequested = reversionType === 'NewRunRequested'
  const isOtherReason = reversionReason === 'Other'

  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <h3 className='u-text--large u-text--center u-weight--bold u-margin-none'>
          Set up new reversion:
        </h3>
      </div>
      <Form showSubmitBtn {...rest} pristine={!reversionType || rest.pristine} data-testid='reversion-form'>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel o-form-group o-text--center'>
            <Field
              name={`${namePrefix}.type`}
              label='Choose type *'
              component={CustomSelectField}
              className='c-custom-select'
              labelClassName='c-label'
              formGroupClassName='u-margin-none'
              clearable={false}
              placeholder='Choose type'
              options={types}
              required
              data-testid='type'
            />
          </div>
        </div>
        {reversionType && (
          <div>
            <div className='o-layout__item u-1/1'>
              <div className='c-panel o-form-group o-text--center'>
                <Field
                  name={`${namePrefix}.reason`}
                  label='Choose reason *'
                  component={CustomSelectField}
                  className='c-custom-select'
                  labelClassName='c-label'
                  formGroupClassName='u-margin-none'
                  clearable={false}
                  placeholder='Choose type'
                  options={getReasons(reversionType)}
                  required
                  data-testid='reason'
                />
              </div>
            </div>
            {isOtherReason && (
              <div className='o-layout__item u-1/1'>
                <div className='c-panel o-form-group o-text--center'>
                  <Field
                    name={`${namePrefix}.otherReason`}
                    label='Write a reason *'
                    labelClassName='c-label'
                    className='c-input'
                    component={InputField}
                    type='text'
                    required
                    data-testid='otherReason'
                  />
                </div>
              </div>
            )}
            <div className='o-layout__item u-1/1'>
              <div className='c-panel o-form-group o-text--center'>
                <Field
                  name={`${namePrefix}.note`}
                  label={`Write note: ${isClarificationRequired ? '*' : ''}`}
                  component={TextareaField}
                  type='text'
                  rows='5'
                  className='c-textarea c-textarea--no-resize'
                  required={isClarificationRequired}
                  data-testid='note'
                />
              </div>
            </div>
            {renderDeadlinesHeading(isNewRunRequested, reversionDraftableTasksHeading)}
            {renderDeadlines(isNewRunRequested, namePrefix, reversionDraftableTasks)}
          </div>
        )}
      </Form>
    </div>
  )
}

PayrollInstanceTaskReversionForm.propTypes = {
  reversibleTaskId: PropTypes.string.isRequired,
  reversionType: PropTypes.string,
  reversionReason: PropTypes.string,
  reversionDraftableTasks: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })),
  isCOT: PropTypes.bool.isRequired,
}

export default PayrollInstanceTaskReversionForm
