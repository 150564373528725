import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import CompaniesContainer from '../containers/CompaniesContainer'
import CompanyFormCreateContainer from '../containers/CompanyFormCreateContainer'

class RouteView extends React.Component {
  render () {
    return <InnerWrapper>
      <Modal ref='createModal' className='c-modal c-modal--full c-modal--overflow-y' modalHeading='Create company'>
        <CompanyFormCreateContainer onSubmit={() => this.refs.createModal.hideModal()} {...this.props} />
      </Modal>
      <CompaniesContainer onCreate={() => this.refs.createModal.showModal()} {...this.props} />
    </InnerWrapper>
  }
}

export default RouteView
