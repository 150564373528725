import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import AccordionActionButtons from 'components/accordion/AccordionActionButtons'
import AccordionExtender from 'components/accordion/AccordionExtender'
const classNames = require('classnames')

const AccordionHeaderTitle = ({ title, titleComponent, ...rest }) => {
  const getSectionHeadingClasses = () => {
    const { headerColor, titleColor, mobileView } = rest
    return classNames({
      'u-margin-top-small': true,
      'u-text--white' : headerColor === 'minsk',
      'u-text--minsk' : titleColor === 'minsk',
      'u-margin-bottom-none mobile-accordion-title': mobileView
    })
  }
  return (
    <SectionHeading
      text={title}
      titleComponent={titleComponent}
      className={getSectionHeadingClasses()}
      {...rest}
    >
      <div className={rest.mobileView ? 'u-float--right u-padding-bottom-tiny' : 'u-float--right'}>
        <AccordionActionButtons {...rest} />
        <AccordionExtender {...rest} />
      </div>
    </SectionHeading>
  )
}

AccordionHeaderTitle.propTypes = {
  headerColor: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.object,
  mobileView: PropTypes.bool
}

export default AccordionHeaderTitle
