import { isNumber } from 'utils/fnkit/typeChecks'
import { createFilter } from 'utils/redux/filter'

export const createVarianceTermFilter = ({ payrollInstanceId, filters, varianceElement, offset = 0, limit = 10 }) => {
  const filter = {
    ...filters,
    offset,
    limit,
  }

  if (isNumber(varianceElement)) {
    filter['cctId'] = varianceElement
  } else {
    filter['informationalElement'] = varianceElement
  }

  return {
    id: payrollInstanceId,
    filter: createFilter(filter),
  }
}
