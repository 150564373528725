/* eslint-disable handle-callback-err */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'

export default class LoadableErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  render () {
    window.hasError = this.state.hasError
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>{this.props.message}</div>
    }
    return this.props.children
  }
}
