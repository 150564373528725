import React from 'react'

export const confirmMessage = (
  <span>
    Please note that current Payrun is already locked for changes. Any updates you make now will be applied in the next Payrun.
    <br />
    <br />
    If you would like these changes to be applied in the current Payrun, please contact us at support.
  </span>
)

export const message = (
  <p className='u-text--italic u-text--center u-text--small'>
    * Please note that current Payrun is already locked for changes. Any updates you make now will be applied in the next Payrun.
    <br />
    If you would like these changes to be applied in the current Payrun, please contact us at support.
  </p>
)

export const confirmLockedPayrunMessage = (
  <span>
    Please note that the current Payrun is already locked for changes. Any updates you make now will be applied in the next Payrun.
    <br />
    <br />
    If you would like these change to be applied in the current Payrun please unlock the file and apply the changes.
  </span>
)
