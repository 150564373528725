import { connect } from 'react-redux'
import { invalidateEmployeeTermPivot } from 'redux/actions/employeeTermPivot'
import { attachEmployees } from 'redux/actions/paygroups'
import { invalidatePaygroupTermPivot } from 'redux/actions/paygroupTermPivot'
import { getPaygroupByIdWithEmployeeIds } from '../../../../../selectors/paygroup'
import { showMessage } from 'redux/actions/modal'
import { invalidatePayrollInstanceEmployeePivot } from 'redux/actions/payrollInstanceEmployeePivot'
import EmployeeAttachDetachHOC from 'containers/employee/EmployeeAttachDetachHOC'
import PayrollPaygroupEmployeesContainer from './PayrollPaygroupEmployeesContainer'
import Fetcher from 'containers/Fetcher'
import { getFilteredEmployeesWithCompanyAndBusinessUnit } from 'redux/selectors/employees'
import { buildFilterName } from 'redux/filters/commonFilters'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import attachDettachEmployeesFilter, { prepareAttachDettachEmployeeFilters } from 'redux/filters/attachDettachEmployeesFilter'

const limit = 24

// say what you're dispatching on init
const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state, props) => {
  // get selected paygroup
  let paygroup = getPaygroupByIdWithEmployeeIds(state, {
    paygroupId: props.match.params.paygroupId,
  })

  const paygroupId = props.match.params.paygroupId

  const employees = getFilteredEmployeesWithCompanyAndBusinessUnit(state, { filter: props.filters.employeeSystemUsers.name })

  const filterName = buildFilterName({ paygroupId }).assignEmployeesPaygroup

  const {
    totalCount,
    params: { offset, limit },
  } = state.employeeSystemUsers.filters[filterName]

  return {
    state,
    employees,
    totalPages: Math.ceil(totalCount / limit),
    currentPage: offset / limit + 1,
    hidePagination: totalCount <= limit,
    paygroupId,
    companyId: paygroup.company,
    paygroupName: paygroup.name,
    content: `Are you sure you want to assign this/these employee/s to ${paygroup.name}?`,
    buttonName: 'Assign',
    paygroup,
    initialValues: {
      paygroupId: paygroup.id,
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { companyId, state, paygroupId, paygroupName } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleAll: (value, eployees) => ownProps.updateEmployees('paygroupAssignEmployees', eployees, value),
    onSubmit: (data) =>
      dispatch(attachEmployees(data)).then(() => {
        dispatch(invalidateEmployeeTermPivot())
        dispatch(invalidatePaygroupTermPivot())
        dispatch(invalidatePayrollInstanceEmployeePivot())

        // On successful sync, go back to the company payroll page
        const path = `/companies/${companyId}/payroll`
        ownProps.history.push(path)
        // Display successful message
        dispatch(
          showMessage({
            body: `Employees successfully assigned to Paygroup ${paygroupName}`,
          })
        )
      }),
    onPageChange: (page) => {
      const filter = prepareAttachDettachEmployeeFilters({
        page,
        limit,
        filters: {
          ...ownProps.filters.employeeSystemUsers.params,
          attachableToPayGroup: paygroupId,
        },
        state,
        filterName: buildFilterName({ paygroupId }).assignEmployeesPaygroup,
      })
      dispatch(fetchEmployeeSystemUsers({ filter }))
    },
  }
}

const Container = EmployeeAttachDetachHOC(connect(mapStateToProps, mapDispatchToProps, mergeProps)(PayrollPaygroupEmployeesContainer))

export default Fetcher(Container, [
  attachDettachEmployeesFilter({ limit, filterKey: 'assignEmployeesPaygroup' }),
  'employeeTermPivot',
  'paygroups',
  'paygroupEmployeePivot',
  'companyCountryTermPivot',
  'paygroupTermPivot',
])
