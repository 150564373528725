import { ROLE_PAYROLL_ADMIN, ROLE_PAYROLL_ANALYST, ROLE_PAYROLL_DATA_ENTRY,
  ROLE_FINANCE_ADMIN, ROLE_PAYROLL_ADMIN_READ_ONLY, ROLE_PAYROLL_ANALYST_READ_ONLY,
  ROLE_PAYROLL_DATA_ENTRY_READ_ONLY, ROLE_FINANCE_ADMIN_READ_ONLY,
  ROLE_ACCOUNTING, ROLE_ACCOUNTING_READ_ONLY, ROLE_CONTROLLING, ROLE_CONTROLLING_READ_ONLY
} from 'redux/config/auth'

// key people role types as defined at back end
export default [
  {
    name: 'Payroll Admin',
    type: ROLE_PAYROLL_ADMIN,
    readOnlyType: ROLE_PAYROLL_ADMIN_READ_ONLY
  },
  {
    name: 'Payroll Analyst',
    type: ROLE_PAYROLL_ANALYST,
    readOnlyType: ROLE_PAYROLL_ANALYST_READ_ONLY
  },
  {
    name: 'Payroll Data Entry',
    type: ROLE_PAYROLL_DATA_ENTRY,
    readOnlyType: ROLE_PAYROLL_DATA_ENTRY_READ_ONLY
  },
  {
    name: 'Finance Admin',
    type: ROLE_FINANCE_ADMIN,
    readOnlyType: ROLE_FINANCE_ADMIN_READ_ONLY
  },
  {
    name: 'Accounting',
    type: ROLE_ACCOUNTING,
    readOnlyType: ROLE_ACCOUNTING_READ_ONLY
  },
  {
    name: 'Controlling',
    type: ROLE_CONTROLLING,
    readOnlyType: ROLE_CONTROLLING_READ_ONLY
  }
]
