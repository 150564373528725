/**
 * `The object keys` - The statuses identifiers. They are used only for accessing the target status.
 * `value` - represents the real back-end value of the status
 * `label` - used for presentation purposes
 */
export const statuses = {
  notStarted: {
    value: 'not-started',
    label: 'Not started'
  },
  inProgress: {
    value: 'in-progress',
    label: 'In progress'
  },
  completed: {
    value: 'completed',
    label: 'Completed'
  },
  reverted: {
    value: 'reverted',
    label: 'Reverted'
  }
}
