import React from 'react'
import RecentReportsContainer from '../containers/RecentReportsContainer'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { reportGroupingsEnum } from 'utils/enums/reportGroupings'

export default props => {
  const hrReports = new ReportItemBuilder(reportGroupingsEnum.HR)
  const financeReports = new ReportItemBuilder(reportGroupingsEnum.FINANCE)
  const payrollReports = new ReportItemBuilder(reportGroupingsEnum.PAYROLL)
  const controlAndGovernanceReports = new ReportItemBuilder(
    reportGroupingsEnum.CONTROL_AND_GOVERNANCE
  )

  const reports = [
    { items: hrReports, icon: { name: 'hr-report', bgColor: 'pink' } },
    { items: financeReports, icon: { name: 'finance-report', bgColor: 'light-green' } },
    { items: payrollReports, icon: { name: 'payroll-report', bgColor: 'blue' } },
    { items: controlAndGovernanceReports, icon: { name: 'control-governance-report', bgColor: 'orange' } },
  ]

  return <RecentReportsContainer reportItems={reports} {...props} />
}
