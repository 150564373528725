import createSelector from 'utils/createSelector'

const getSchema = (state, props) => props.schema
const getVendorId = (state, props) => parseInt(props.vendorId, 10)

export const getVendors = createSelector(({ Vendor }) => {
  return Vendor.all().toRefArray().map(vendor => ({
    ...vendor
  }))
})

export const getVendorIdBySchema = createSelector(getSchema, ({ Vendor }, schema) => {
  return Vendor.filter({ schema }).first().id
})

export const getVendorById = createSelector(getVendorId, ({ Vendor }, vendorId) => {
  return Vendor.withId(vendorId)
})
