import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/documentTypes'

class DocumentType extends CRUDModel {}
DocumentType.modelName = 'DocumentType'

DocumentType.fields = {
  id: attr()
}
DocumentType.actionTypes = actionTypes

export default DocumentType
