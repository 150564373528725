import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const BANK_ACCOUNTS_FETCH = 'BANK_ACCOUNTS_FETCH'
export const BANK_ACCOUNTS_RECEIVE = 'BANK_ACCOUNTS_RECEIVE'
export const BANK_ACCOUNTS_FILTER = 'BANK_ACCOUNTS_FILTER'
export const BANK_ACCOUNTS_FILTERED = 'BANK_ACCOUNTS_FILTERED'
export const BANK_ACCOUNT_CREATE = 'BANK_ACCOUNT_CREATE'
export const BANK_ACCOUNT_UPDATE = 'BANK_ACCOUNT_UPDATE'
export const BANK_ACCOUNT_DELETE = 'BANK_ACCOUNT_DELETE'
export const BANK_ACCOUNT_INVALIDATE = 'BANK_ACCOUNT_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: BANK_ACCOUNTS_FETCH,
  receive: BANK_ACCOUNTS_RECEIVE,
  filter: BANK_ACCOUNTS_FILTER,
  filtered: BANK_ACCOUNTS_FILTERED,
  create: BANK_ACCOUNT_CREATE,
  update: BANK_ACCOUNT_UPDATE,
  delete: BANK_ACCOUNT_DELETE,
  invalidate: BANK_ACCOUNT_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'bankAccounts', 'bankaccounts', 'BankAccount')

export const filterAction = response => ({
  type: BANK_ACCOUNTS_FILTERED,
  payload: response
})

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchBankAccountsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchBankAccounts = actionUtility.fetchEntities
export const createBankAccount = actionUtility.createEntity
export const updateBankAccount = actionUtility.updateEntity
export const deleteBankAccount = actionUtility.deleteEntity
export const invalidateBankAccount = actionUtility.invalidate

export const updateInlineBankAccounts = (data = []) => {
  return dispatch => {
    const promises = data.map(d => dispatch(updateBankAccount(...d)).then(res => {
      return res
    }))
    return Promise.all(promises)
  }
}
