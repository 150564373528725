import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/paygroupTermPivot'

class PaygroupTerm extends CRUDModel {}
PaygroupTerm.modelName = 'PaygroupTerm'

PaygroupTerm.fields = {
  id: attr(),
  paygroup: fk('Paygroup', 'paygroupTerms'),
  companyCountryTerm: fk('CompanyCountryTerm', 'paygroupTerms'),
}
PaygroupTerm.actionTypes = actionTypes

export default PaygroupTerm
