import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const GENERAL_LEDGER_GLOBAL_DATA_FETCH = 'GENERAL_LEDGER_GLOBAL_DATA_FETCH'
export const GENERAL_LEDGER_GLOBAL_DATA_RECEIVE = 'GENERAL_LEDGER_GLOBAL_DATA_RECEIVE'
export const GENERAL_LEDGER_GLOBAL_DATA_UPDATE = 'GENERAL_LEDGER_GLOBAL_DATA_UPDATE'
export const GENERAL_LEDGER_GLOBAL_DATA_INVALIDATE = 'GENERAL_LEDGER_GLOBAL_DATA_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: GENERAL_LEDGER_GLOBAL_DATA_FETCH,
  receive: GENERAL_LEDGER_GLOBAL_DATA_RECEIVE,
  update: GENERAL_LEDGER_GLOBAL_DATA_UPDATE,
  invalidate: GENERAL_LEDGER_GLOBAL_DATA_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes,
  'generalLedgerGlobalData', 'generalledgerglobaldata', 'GeneralLedgerGlobalData'
)

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchGeneralLedgerGlobalDataIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchGeneralLedgerGlobalData = actionUtility.fetchEntities
export const invalidateGeneralLedgerGlobalData = actionUtility.invalidate

export const updateGeneralLedgerGlobalData = (payload, id, filter) => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.updateEntity(payload, id, false))
      .then(resp => dispatch(invalidateGeneralLedgerGlobalData())
      )
  }
}
