import createSelector from 'utils/createSelector'
import { makeFilteredIdsByFilter } from './filters'

const getFilteredIds = makeFilteredIdsByFilter('termSubCategories')
const getCategoryId = (state, props) => props.categoryId
const getCategoryName = (state, props) => props.categoryName

export const getSubCategories = createSelector(({ SubCategory }) => SubCategory.all().toRefArray())

export const getSubCategoriesByCategoryId = createSelector(getCategoryId,
  ({ SubCategory }, categoryId) => SubCategory
    .filter(subCategory => subCategory.termCategory === categoryId).toRefArray()
)

export const getFilteredSubCategories = createSelector(getFilteredIds,
  ({ SubCategory }, ids) => ids.map(id => SubCategory.withId(id).ref))

export const getSubCategoriesByCategoryName = createSelector(getCategoryName,
  ({ SubCategory }, categoryName) => SubCategory
    .all()
    .toModelArray()
    .filter(subCategory => subCategory.termCategory.name === categoryName)
    .map(subCategory => subCategory.ref)
)
