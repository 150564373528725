import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/externalEmployeeUsers'

class ExternalEmployeeUser extends CRUDModel {}
ExternalEmployeeUser.modelName = 'ExternalEmployeeUser'

ExternalEmployeeUser.fields = {
  id: attr()
}
ExternalEmployeeUser.actionTypes = actionTypes

export default ExternalEmployeeUser
