import React from 'react'

import PropTypes from 'prop-types'

import SectionHeading from 'components/SectionHeading'
import MobileDetailsView from 'components/MobileDetailsView'
import AccordionContainer from 'containers/AccordionContainer'
import { documentTextLabels } from 'utils/locales/document.en'
import { buttonActions, uploadTextLabels } from 'utils/locales/common.en'
import { formatDate } from 'utils/date'

const getDocEmployeeActionButtons = ({ doc, onDownload }) => (
  <button
    className='c-btn c-btn--small c-btn--curious u-margin-right-tiny rounded'
    title={buttonActions.download}
    onClick={() => onDownload(doc.id)}
    data-testid={`payslip-mobile-${doc.id}-download`}
  >
    <span className='icon icon--download' />
  </button>
)

export const DocumentEmployeeView = ({ documents, title, onDownload }) => {
  return (
    <div className='u-hide-on-desktop'>
      <SectionHeading text={title} />
      {documents && documents.length ? (
        documents.map((doc) => (
          <AccordionContainer
            title={doc.name}
            titleColor='minsk'
            rounded
            fullWidth
            mobileView
            key={doc.id}
            actionButtons={getDocEmployeeActionButtons({ doc, onDownload })}
            subtitleKey={`${uploadTextLabels.uploadedOn}:`}
            subtitle={formatDate(doc.uploadedOn.date)}
          >
            <MobileDetailsView
              rows={[
                { [uploadTextLabels.uploadedBy]: `${doc.extraData.firstname} ${doc.extraData.surname}` },
                { Size: doc.size },
                { Description: doc.description },
              ]}
              textAreaIndex={2}
            />
          </AccordionContainer>
        ))
      ) : (
        <div className='mobile-no-data-message--centered'>
          <span>{documentTextLabels.noDocumentsMessage}</span>
        </div>
      )}
    </div>
  )
}

DocumentEmployeeView.propTypes = {
  documents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
}
