/** Replace list of field options
 *
 * @param {Object} data
 * @param {Array} updateDropDownOptions - Array with data coming from employeeFieldsConfigAlt,
     used to point which field value will trigger another fields options change, eg see paymentCurrencyField
 * @param {Object} options - New options in format:
 *  { fieldName: fieldOptionsArray }
 *  example:
 *  {
 *   payroll: payrollOptions,
 *   paygroup: paygroupOptions
 *   }
 * @returns {Object}
 *
*/
export const replaceOptions = (data, updateDropDownOptions, options) => {
  const updatedData = Object.keys(data).map((subCat) => {
    if (updateDropDownOptions.find(o => o.subCategory === subCat)) {
      return {
        [subCat]: data[subCat].map((row) => {
          const hasUpdatedOptions = updateDropDownOptions.find(o => o.name === row.metaData.field)

          return hasUpdatedOptions ? {
            ...row,
            metaData: {
              ...row.metaData,
              options: options[row.metaData.field]
            }
          } : row
        })
      }
    }
    return {
      [subCat]: data[subCat]
    }
  })

  return Object.assign({}, ...updatedData)
}
