import React from 'react'
import PropTypes from 'prop-types'
import { types } from 'redux/config/reversions'

const getReversionTypeLabel = type => types.find(t => t.value === type).label

export const ReversedFlag = ({ type, reason, isCompleted, instanceId }) => (
  <span
    className={`${isCompleted ? 'icon--reversion--green' : 'icon--reversion--red'}`}
    title={`${isCompleted ? 'Reversion Completed' : `${getReversionTypeLabel(type)} / ${reason}`}`}
    data-testid={`reversedFlagId-${instanceId}`}
  />
)

ReversedFlag.propTypes = {
  type: PropTypes.string,
  reason: PropTypes.string,
  isCompleted: PropTypes.bool,
  instanceId: PropTypes.number,
}

export default ReversedFlag
