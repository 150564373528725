import isObject from 'lodash/isObject'

export const buildBankAccountData = (savedBankAccounts, bankAccounts) => {
  const bankAccountUpdates = []

  Object.keys(savedBankAccounts).forEach(bankAccountId => {
    let payloadData = {}
    const initValues = bankAccounts.find(acc => acc.id === parseInt(bankAccountId, 10))
    delete savedBankAccounts[bankAccountId]['currency']
    delete savedBankAccounts[bankAccountId]['bankCountry']
    savedBankAccounts[bankAccountId].forEach(detailVal => {
      payloadData[detailVal.metaData.field || detailVal.metaData.apiName] = typeof detailVal.value === 'string' ? detailVal.value.trim() : detailVal.value
    })

    payloadData = {
      ...payloadData,
      country: initValues.country,
      company: initValues.company,
      bankCountry: initValues.bankCountry,
      currency: initValues.currency,
      bankAccountId: initValues.id,
      payrollCountry: initValues.country,
    }
    bankAccountUpdates.push([payloadData, bankAccountId, false, false, true])
  })

  return bankAccountUpdates
}

export const buildEmployeeAddressData = (savedEmployeeAddr, addresses) => {
  const updates = []
  Object.keys(savedEmployeeAddr).forEach(addressId => {
    let payloadData = {}
    savedEmployeeAddr[addressId].forEach(update => {
      payloadData[update.metaData.field || update.metaData.apiName] = typeof update.value === 'string' ? update.value.trim() : update.value
    })
    payloadData = { ...payloadData }

    updates.push([payloadData, parseInt(addressId, 10), false, false, true])
  })

  return updates
}

export const formatPayloadForJobAndOrg = (data) => {
  const payload = []
  data.forEach((d) => {
    const { metaData } = d
    const { options, field } = metaData
    let serverValue = {}
    switch (field) {
    case 'businessUnit':
    case 'department':
    case 'costCenter':
    case 'officeLocation':
    case 'subsidiary':
    case 'project':
      serverValue = options.find((opt) => opt.name === d.value)
      d.serverValue = serverValue ? serverValue.id : null
      break

    default:
      break
    }
    payload.push(d)
  })
  return payload
}

/**
 * Method to determine if there is at least 1 valid isPrimary address
 * Loop through all the initial array data to see if there is an equal amount
 * of no isPrimarys to the length of the data being sent
 * @param {Extra Address Data} data array
 * @returns boolean
 */
export const validateAddressPrimarys = (data) => {
  let amountOfNoPrimarys = 0
  if (data.length === 0) return false
  data.forEach(address => {
    address.forEach(addressData => {
      if (isObject(addressData) && !addressData?.isPrimary) {
        amountOfNoPrimarys++
      }
    })
  })

  return amountOfNoPrimarys === data.length
}

/**
 * Method to determine if there is only 1 valid isPrimary address
 * Loop through all the initial array data to see if there is an equal amount
 * of no isPrimarys to the length of the data being sent
 * @param {Extra Address Data} data array
 * @returns boolean
 */
export const validateTooManyAddressPrimarys = (data) => {
  let amountOfNoPrimarys = 0
  if (data.length === 0) return false
  data.forEach(address => {
    address.forEach(addressData => {
      if (isObject(addressData) && addressData?.isPrimary) {
        amountOfNoPrimarys++
      }
    })
  })
  return amountOfNoPrimarys > 1
}

/**
 * Method to determine if there is at least 1 valid primaryBankAccount
 * Loop through all the initial array data to see if there is an equal amount
 * of no isPrimarys to the length of the data being sent
 * @param {Bank Accounts Data} data array
 * @returns boolean
 */

export const validateBankAccountPrimarys = (data) => {
  const bankAccountData = Object.values(data)
  if (bankAccountData.length === 0) return false
  let amountOfNoPrimarys = 0
  bankAccountData.forEach(bankAccount => {
    const primaryField = bankAccount.find(field => field.title === 'Primary Bank Account')
    const primaryValue = !!primaryField?.value
    if (!primaryValue) {
      amountOfNoPrimarys++
    }
  })

  return amountOfNoPrimarys === bankAccountData.length
}

/**
 * Method to determine if there is only 1 valid primaryBankAccount
 * Loop through all the initial array data to see if there is an equal amount
 * of no isPrimarys to the length of the data being sent
 * @param {Bank Accounts Data} data array
 * @returns boolean
 */
export const validateTooManyBankAccountPrimarys = (data) => {
  const bankAccountData = Object.values(data)
  if (bankAccountData.length === 0) return false
  let amountOfPrimarys = 0
  bankAccountData.forEach(bankAccount => {
    const primaryField = bankAccount.find(field => field.title === 'Primary Bank Account')
    const primaryValue = !!primaryField?.value
    if (primaryValue) {
      amountOfPrimarys++
    }
  })

  return amountOfPrimarys > 1
}
