import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const TAXAGENCIES_FETCH = 'TAXAGENCIES_FETCH'
export const TAXAGENCIES_RECEIVE = 'TAXAGENCIES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TAXAGENCIES_FETCH,
  receive: TAXAGENCIES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'taxAgencies', 'taxagencies', 'TaxAgency')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTaxAgenciesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchTaxAgencies = actionUtility.fetchEntities
