import { uploadMessages } from 'utils/locales/document.en'

export class DocumentMessages {
  constructor (typeClass, details, documentTypes) {
    this.type = typeClass
    this.details = details
    this.isGTNFile = documentTypes?.includes(1)
  }

  buildMessage (prefix, detailList) {
    return `${prefix} ${detailList.join(', ')}${this.isGTNFile ? uploadMessages.gtnSuffix : ''}.`
  }

  get confirmationMsg () {
    const { company, country, payroll } = this.details

    switch (this.type) {
    case 'payrollInstance':
    case 'payroll':
      return this.buildMessage(uploadMessages.prefix, [company, country, payroll])
    case 'company':
      return this.buildMessage(uploadMessages.prefix, [company, country])
    default:
      break
    }
  }
}
