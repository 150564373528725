import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

export const AmountFormat = ({ amount, suffix }) => (
  <NumberFormat value={amount} displayType={'text'} thousandSeparator fixedDecimalScale decimalScale={2} suffix={suffix} />
)

AmountFormat.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
}

export default AmountFormat
