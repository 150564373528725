import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Fetcher from '../../../containers/Fetcher'
import { createFilter } from '../../../utils/redux/filter'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { getFilteredPayrolls } from '../selectors/payrolls'
import moment from 'moment'
import { sortByLabel } from 'utils/strings'

class PayrollSelectContainer extends React.Component {
  render () {
    const { payrolls, onChanged, isFieldDisabled, disabledReason, optionComponent, multi, hasNoneOption, isFetching, payrollRequired = true } = this.props

    return (
      <Field
        name='payroll'
        label={payrollRequired ? 'Payroll *' : 'Payroll'}
        component={CustomSelectField}
        className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        optionComponent={optionComponent}
        placeholder={isFetching ? 'Loading...' : 'Select'}
        disabled={isFieldDisabled}
        disabledReason={disabledReason}
        options={payrolls
          .map((payroll) => ({
            value: payroll.id,
            label: payroll.name,
          }))
          .sort(sortByLabel)}
        onChanged={onChanged}
        multi={multi}
        hasNoneOption={hasNoneOption}
        data-testid='payroll-wrapper'
        // While fetching, the payrolls are empty array. If we enable the flag, it will reset the selected values.
        // That's the reasons we are disabling it in that case only.
        enableRemovedOptionFix={!this.props.isFetching}
      />
    )
  }
}

PayrollSelectContainer.propTypes = {
  payrolls: PropTypes.array.isRequired,
  onChanged: PropTypes.func,
  isFieldDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  disabledReason: PropTypes.string,
  payrollRequired: PropTypes.bool,
  multi: PropTypes.bool,
  hasNoneOption: PropTypes.bool,
  optionComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

const mapStateToProps = (state, props) => {
  const payrolls = !props.isFetching ? getFilteredPayrolls(state, { filter: props.filters.payrolls.name }) : []

  return {
    isFetching: props.isFetching,
    payrolls: payrolls,
  }
}

const Container = connect(mapStateToProps)(PayrollSelectContainer)

export const normalizeDate = (date) => {
  // In the case a `date` is not selected/passed, we don't do anything.
  // Returning `undefined` is pretty enough, because `createFilter` function won't pass it as filter parameters
  // in that case.
  if (!date) return

  // In the case it's a string, it's already normalized for the BE request filters.
  // It's passed as a string, in the case a report is already created and the date is returned by the BE.
  if (typeof date === 'string') return date

  if (date instanceof Date) return moment(date).format('YYYY-MM-DD')

  // Here we have a moment object and we should normalize it to a string.
  // moment object is passed in the case we are in a process of creating a report.
  return date.format('YYYY-MM-DD')
}

export default Fetcher(
  Container,
  [
    {
      name: 'payrolls',
      params: [
        {
          _computed: {
            filter: (state, props) =>
              createFilter({
                reportFromDate: normalizeDate(props.selected.fromDate),
                reportToDate: normalizeDate(props.selected.toDate),
                company: props.selected.company,
                sort: [{ name: 'id', order: 'desc' }],
              }),
          },
        },
      ],
    },
  ],
  { renderWhileFetching: true, showLoader: false }
)
