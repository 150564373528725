import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { change, formValueSelector } from 'redux-form'
import isArray from 'lodash/isArray'
import { getProcesses } from 'redux/selectors/processes'
import { getPayrollProcessOwners } from './selectors/payrollInstanceProcess'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getKeyPeople, getKeyPeopleByAccessAreas } from 'redux/selectors/employees'
import FiltersAuditTrailReport from '../../../components/FiltersAuditTrailReport'
import { createFilter } from '../../../../../utils/redux/filter'
import { sortByName } from 'utils/strings'

const mapDispatchToProps = (dispatch, props) => {
  return {
    setProcessOwner: () => {
      dispatch((dispatch, getState) => {
        // Get selected payrolls' ids
        const state = getState()
        const selector = formValueSelector(props.form)
        const ids = [selector(state, 'payroll')]

        // In case `payrolls` are nullified, we short circuit the evaluation of `getPayrollProcessOwners`
        const processOwners = ids && getPayrollProcessOwners(state, { ids })

        // Set the unique process owners
        dispatch(change(props.form, 'processOwner', processOwners))
      })
    },
  }
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form)
  const values = selector(state, 'subcategory', 'process', 'fromDate', 'toDate', 'country', 'company', 'payroll', 'processOwner')
  const isSinglePayroll = props.lockedInReportFields?.isSinglePayroll

  return {
    // employeeFilter,
    // All selected values
    selected: values,
    initialValues: {
      processOwner: null,
    },
    // Global processes - only Payroll Calculation for now
    processes: getProcesses(state),
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] })
      : getCompaniesByAuth(state, props),
    // Owners by selected access areas
    owners: values.company && values.company.length ? getKeyPeopleByAccessAreas(state, { accessAreasIds: values.company }) : getKeyPeople(state),
    isSinglePayroll,
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(FiltersAuditTrailReport)

export default Fetcher(
  Container,
  [
    'processes',
    'countries',
    'companies',
    'payrolls',
    'businessUnits',
    'costCenters',
    'accessAreaPivot',
    'parentCompanies',
    {
      name: 'employeeSystemUsers',
      params: [{ filter: createFilter({ isKeyPerson: 1 }) }],
    },
  ],
  { wrapperDivClassName: 'o-block--inline' }
)
