import React from 'react'
import VendorFormFields from './VendorFormFields'
import Form from 'components/form/Form'

export const CompanyForm = props => {
  if (props.isFetching) return <div>Loading...</div>

  return <Form showSubmitBtn {...props}>
    <VendorFormFields {...props} />
  </Form>
}

export default CompanyForm
