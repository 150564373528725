import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/resources'

class Resource extends CRUDModel {}
Resource.modelName = 'Resource'

Resource.fields = {
  id: attr(),
}

Resource.actionTypes = actionTypes

export default Resource
