export const serverUrl = 'https://cos.api.payslip.com';
export const sentryKey = 'https://627a1f511b9b4f598e72d3e759fcc0ca@sentry.payslip.com/3';
export const keycloakUrl = 'https://auth.payslip.com/auth';
// ------------------------------------
// General configs
// ------------------------------------

/**
 * Default server API url
 *
 * If Multitenancy is enabled, that url MUST point to COT api url,
 * because the COT API is used for getting the mapping between subdomain and tenant api url,
 * except the case where the subdomain is `cot`.
 *
 * If Multitenancy isn't enabled, then the URL can point anywhere we want.
 *
 * @type {string}
 */


/**
 * Uri of the server, where the API is set
 * @type {string}
 */
const apiUri = '/api/v1/'

/**
 * API url
 *
 * It's defined by the above settings,
 * so please refer to `serverUrl` and `apiUri` documentation
 *
 * @type {string}
 */
export const apiUrl = `${serverUrl}${apiUri}`

/**
 * Sentry.io key / url for reporting errors
 *
 * @type {string}
 */
// export const sentryKey = 'https://d876b3a1cb8f49cd8a1a50ece1ac0469@sentry.io/295280'

/**
 * Keycloack URL should point to the one you are using
 * depending on the environment
 *
 * @type {string}
 */
// export const keycloakUrl = 'http://localhost:8080/auth'
// export const keycloakUrl = 'http://auth.pay-sso.com/auth'

// ------------------------------------
// Multitenancy configs
// ------------------------------------

/**
 * Is Multitenancy feature enabled
 *
 * Later on, when the feature is completed at all, it will be enabled by default.
 * Now we're still developing it, so in order to test it, we made it optional.
 *
 * If we disable it `isMultitenancyEnabled = false`, then `serverUrl` will be used for all API calls.
 * If we enable it and we choose a tenant (from the tenant selector dropdown),
 * then for all of API requests (except the authentication request), we will use the url,
 * that comes from `tenants` config below.
 *
 * @type {boolean}
 */
export const isMultitenancyEnabled = true

/**
 * What is the subdomain of COT.
 *
 * For now it's hardcoded, because we will have exactly 1 COT subdomain,
 * and it's unnecessary to be dynamically fetcheched via the API
 * @type {string}
 */
export const cotSubdomain = 'cos'

/**
 * Custom top-level domains can optionally be specified,
 * in order to add support for local development
 *
 * @url https://github.com/peerigon/parse-domain#introducing-custom-tlds
 * @type {[*]}
 */
export const customTlds = ['test']

/**
 * Tenants logos
 *
 * If a Tenant has a logo URL listed here, this logo will be used in some Components, instead of Tenant's name.
 *
 * @type {Object} - For logos object `key` we use the Tenant's schema, and for `value` we use Logo's URL.
 * Logo's URL can be external or a local one (should be located in `/public/img/` directory).
 *
 * @example:
 *
 * ```
 * const logos = {
 *   // External URL example
 *   'tenant1': 'https://upload.wikimedia.org/wikipedia/en/thumb/e/e4/Unilever.svg/542px-Unilever.svg.png',
 *   // Local URL example
 *   'tenant2': '/img/logo/payslip-logo.png'
 * }
 * ```
 *
 */

export const logos = {
  unilever: '/img/logo/unilever-logo.png',
  livebird: '/img/logo/livebird-logo.png',
  stripe: '/img/logo/stripe-logo.png',
  sunbird: '/img/logo/sunbird-logo.png',
  wave: '/img/logo/wave-logo.png',
  databricks: '/img/logo/databricks.png',
  platform: '/img/logo/platform.png',
  control: '/img/logo/control.png',
  automation: '/img/logo/automation.png',
  trialbird11: '/img/logo/trialbird11.png',
}

/**
 * Tenants, having access to the Dashboard feature (demo purpose only)
 *
 * The Dashboard feature isn't developed yet. We just have its design only.
 * In order to present it to some of the current or potential Clients (tenants),
 * we are just showing the Dashboard design as an image.
 *
 * @type {Object} - For dashboard object `key` we use the Tenant's schema, and for `value` we use Dashboard's URL.
 * Dashboard's URL can be external or a local one (should be located in `/public/img/` directory).
 *
 * @example:
 *
 * ```
 * const dashboard = {
 *   // External URL example
 *   'tenant1': 'https://upload.wikimedia.org/wikipedia/en/thumb/e/e4/Unilever.svg/542px-Unilever.svg.png',
 *   // Local URL example
 *   'tenant2': '/img/dashboard.png'
 * }
 * ```
 *
 */
export const dashboard = {
	'tenant2': '/img/dashboard-livebird.png',
	'space': '/img/dashboard-livebird.png',
	'livebird': '/img/dashboard-livebird.png',
	'sunbird': '/img/dashboard-sunbird.png',
	'dawnbird': '/img/dashboard-dawnbird.png',
	'tenant1': '/img/dashboard-dawnbird.png'
}
