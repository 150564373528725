import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import PayslipTable from 'components/table/PayslipTable'
import { TableHeading } from 'utils/helperClasses/tableHeadings'

export const PayrollInstanceJoinersView = ({ data, onSave, onDownload, message }) => {
  if (!data) return <Loader />
  if (message) {
    return <div className='u-1/1 u-text--center u-margin-top'>
      {message}
    </div>
  }

  const headings = [
    new TableHeading({ accessor: 'joiners', heading: 'Joiners' }),
    new TableHeading({ accessor: 'leavers', heading: 'Leavers' }),
    new TableHeading({ accessor: 'actions', heading: 'Actions' })
  ]

  return (
    <PayslipTable
      data={[{
        joiners: data.joiners,
        leavers: data.leavers,
        actions: <div>
          <button
            className='c-btn c-btn--tiny c-btn--curious u-margin-right-tiny rounded'
            title='Download'
            onClick={onDownload}
            data-testid='joiners-leavers-download'
          >
            <span className='icon icon--download' />
          </button>
          <button
            className='c-btn c-btn--tiny c-btn--curious u-margin-right-tiny rounded'
            title='Save'
            onClick={onSave}
            data-testid='joiners-leavers-save'
          >
            <span className='icon icon--save' />
          </button>
        </div>
      }]}
      headings={headings}
      wrapperClassName='u-1/1 u-margin-top'
    />
  )
}

PayrollInstanceJoinersView.propTypes = {
  data: PropTypes.shape({
    joiners: PropTypes.number,
    leavers: PropTypes.number
  }),
  onSave: PropTypes.func,
  message: PropTypes.string
}
