import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PasswordInputField from 'components/form/PasswordInputField'
import { Link } from 'react-router-dom'
import Form from 'components/form/Form'
import PasswordPolicyMessage from 'components/auth/PasswordPolicyMessage'

export const ChangePasswordView = (props) => {
  return <div className='u-margin-bottom-large'>
    <div className='o-grid o-grid--center'>
      <h5><b>Password Security:</b></h5>
      {props.initialValues.showLegacyPasswordReason
        ? <p className='u-text--normal'>Account security is always a priority at Payslip,
          and we have recently updated our security policy for password protection on your account.
        </p>
        : <p className='u-text--normal u-text-left'>Your password has expired.</p>
      }
      <p className='u-text--normal'>You are required to create a new password for account login.</p>
      <PasswordPolicyMessage />
    </div>
    <div className='o-grid o-grid--center'>
      <div>
        <Form showSubmitBtn btnText='Send' colorClassBtn='c-btn--curious' btnFullWidth classNames='c-btn--rounded' {...props}>
          <div className='o-form-group'>
            <Field
              name='old'
              label='Current Password'
              component={PasswordInputField}
              type='password'
              autoFocus
              className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
            />
          </div>
          <div className='o-form-group'>
            <Field
              name='password'
              label='New Password'
              component={PasswordInputField}
              type='password'
              className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
              showPasswordStrengthBox
            />
          </div>
          <div className='o-form-group'>
            <Field
              name='passwordConfirmation'
              label='Password Confirmation'
              component={PasswordInputField}
              type='password'
              className='c-input c-input--alt c-input--rounded u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
            />
          </div>
        </Form>
        <div className='o-layout o-grid o-grid--center u-margin-top-small'>
          <Link to='/forgotten-password' className='u-text--small u-text--curious'>Forgotten password?</Link>
        </div>
      </div>
    </div>
  </div>
}

ChangePasswordView.propTypes = {
  hidePasswordInputs: PropTypes.bool,
  initialValues: PropTypes.shape({
    showLegacyPasswordReason: PropTypes.bool
  })
}

export default ChangePasswordView
