import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollTask'
import { getAlphabeticName } from './payrollStep'

class PayrollTask extends CRUDModel {
  getAlphabeticName () {
    return getAlphabeticName(this.position)
  }
}
PayrollTask.modelName = 'PayrollTask'
PayrollTask.actionTypes = actionTypes

PayrollTask.fields = {
  id: attr(),
  payrollStep: fk('PayrollStep', 'payrollTasks'),
  owner: fk('EmployeeSystemUser', 'payrollTasks'),
  companyTask: fk('CompanyCountryTask', 'payrollTasks')
}

export default PayrollTask
