import InnerWrapper from 'layouts/InnerWrapper'
import loadable from '@loadable/component'
import CoreLayout from 'layouts/PageLayout/PageLayout'

const RouteViewLoadable = loadable(() => import('./components/RouteView'))

export default (store) => ({
  path: 'payroll/:payrollInstanceId/timelines',
  indexRoute: { component: RouteViewLoadable, wrappedComponent: InnerWrapper },
  tabbedRoute: false,
  wrappedComponent: CoreLayout,
  authProtected: true,
})
