import { payrunStatusesEnum } from './enums/payrunStatuses'

/**
 *
 * @param {Number} id
 * @param {Array} payrollInstances
 *
 * @returns String
 */
export const activeTabStatuses = ['active', 'gross-to-net-calculations-and-review-done', 'cot-final-payroll-reports-review-done', 'sent-and-locked', 'reverted']
export const closedTabStatuses = ['completed']
export const reopenedTabStatuses = ['reopened']
export const postPayrollTabStatuses = ['completed-with-extra-tasks']
export const inactiveTabStatuses = ['inactive']

export const setStatus = (payrollInstanceStatus) => {
  if (!payrollInstanceStatus) return
  if (activeTabStatuses.includes(payrollInstanceStatus)) return 'active'
  if (closedTabStatuses.includes(payrollInstanceStatus)) return 'previous'
  if (reopenedTabStatuses.includes(payrollInstanceStatus)) return 'previous'
  if (postPayrollTabStatuses.includes(payrollInstanceStatus)) return 'extra'
  if (inactiveTabStatuses.includes(payrollInstanceStatus)) return 'inactive'
}

export const setStatusForNotifications = (payrollInstanceStatus) => {
  if (reopenedTabStatuses.includes(payrollInstanceStatus)) return 'reopened'
  return setStatus(payrollInstanceStatus)
}

export const isPayrollInstanceClosedOrWithExtraSteps = (status) => status === 'completed' || status === 'completed-with-extra-tasks'

/**
 * Array of allowed statuses for pre-payroll state.
 * @type {string[]}
 */

export const prePayrollAllowedStatuses = [
  payrunStatusesEnum.ACTIVE,
  payrunStatusesEnum.SENT_AND_LOCKED,
  payrunStatusesEnum.GROSS_TO_NET_CALCULATIONS_AND_REVIEW_DONE,
  payrunStatusesEnum.COT_FINAL_PAYROLL_REPORTS_REVIEW_DONE,
  payrunStatusesEnum.REVERTED,
  payrunStatusesEnum.REOPENED,
]

/**
 * Checks if the given payroll instance is in pre-payroll state or is reopened.
 * @param {object} payrollInstance - The payroll instance object to check.
 * @param {string} payrollInstance.status - The status of the payroll instance.
 * @returns {boolean} - True if the payroll instance is in pre-payroll state or is reopened, otherwise false.
 */
export const isInPrePayrollOrIsReopened = (payrollInstance) => prePayrollAllowedStatuses.includes(payrollInstance.status)
