import * as htmlToImage from 'html-to-image'
import { jsPDF } from 'jspdf'
import { isEmpty } from 'lodash'

import { consoleTextErrors } from 'utils/locales/errors.en'

/**
 * Libraries docs:
 * html-to-image: https://github.com/bubkoo/html-to-image#readme
 * jsPDF: https://rawgit.com/MrRio/jsPDF/master/docs/index.html
 */

export const ExportFormatType = Object.freeze({
  Pdf: 'pdf',
  Svg: 'toSvg',
  Png: 'toPng',
  Jpeg: 'toJpeg',
  Canvas: 'toCanvas',
})

export const ExportOrientationType = Object.freeze({
  portrait: 'p',
  landscape: 'l',
})

const imageInPdfSettings = Object.freeze({
  x: 24,
  y: 24,
  format: 'jpeg',
  canvasPadding: 24,
  compression: 'MEDIUM',
})

export const baseImageOptions = Object.freeze({
  cacheBust: true,
  backgroundColor: 'white',
  style: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})
const exclusionClasses = ['c-btn', 'cell-render-actions']
export const ExclusionIds = Object.freeze({
  noElementData: 'no-element-data',
})

/**
 * Determines whether a DOM element does not contain any of the specified exclusion classes.
 * This function checks if the given DOM node has a class list that excludes all classes defined
 * in the `exclusionClasses` array. It's a boolean function that returns `true` if none of the
 * exclusion classes are found on the `node`, and `false` otherwise.
 *
 * @param {Element} node - The DOM element to check for exclusion classes.
 * @returns {boolean} - Returns `true` if the element does not contain any of the specified exclusion classes, `false` otherwise.
 *
 */
export const getElementExclusions = (node) => !exclusionClasses.some((classname) => node.classList?.contains(classname))

/**
 * Generates an image from a DOM element using the specified format and options for `htmlToImage` library.
 * of the provided DOM element. The format determines the image format (e.g., 'toPng', 'toJpeg').
 * Additional options for image generation can be passed which may include custom settings
 * for the `htmlToImage` library's function call. The `filter` option is set to use
 * `getElementExclusions` to determine which elements should be included in the image.
 *
 * @param {Object} params - The parameters object for generating the image.
 * @param {Element} params.domElement - The DOM element to be converted into an image.
 * @param {Object} params.options - Options for image generation. Must include a 'format' property.
 * @param {string} params.options.format - The image format to use, corresponds to `htmlToImage` methods.
 * @returns {Promise} - A Promise that resolves to the generated image data URL.
 *
 */
export const generateImageByElement = ({ domElement, options }) => {
  const { format } = options
  return htmlToImage[format](domElement, {
    ...baseImageOptions,
    filter: getElementExclusions,
    ...options,
  })
}

/**
 * Retrieves a list of DOM elements that correspond to the given section IDs within a specified container element by the ExportBlockItem component.
 * The function attempts to find each section by its ID within the containerElement. If an error occurs during
 * this process (such as if containerElement is null or not a DOM element), the error is logged, and an empty
 * array is returned.
 *
 * @param {Object} params - The parameters for retrieving the DOM sections.
 * @param {string[]} params.sectionsToPrint - An array of section IDs to be retrieved.
 * @param {Element} params.containerElement - The parent DOM element in which to query for the sections as a React Ref element.
 * @returns {Element[]} An array of DOM elements that were successfully retrieved, which can be empty if none are found or an error occurs.
 */
export const getDomSections = ({ sectionsToPrint, containerElement }) => {
  let sections
  try {
    sections = sectionsToPrint.map((section) => containerElement.querySelector(`#${section}`)).filter(Boolean)
  } catch (error) {
    console.error(consoleTextErrors.onImageGeneration, error)
    sections = []
  }
  return sections
}
/**
 * Converts an array of DOM sections into canvas elements asynchronously using the specified options.
 * If a DOM section contains an element with an ID listed in the exclusion IDs, it is not processed.
 *
 * @param {Element[]} sections - An array of DOM elements to be converted into canvas elements.
 * @param {Object} options - Options to be used for generating canvas elements.
 * @returns {Promise<HTMLCanvasElement[]>} A promise that resolves to an array of canvas elements generated from the DOM sections.
 */
export const sectionsToCanvas = async (sections, options) =>
  Promise.all(
    sections.reduce((acc, domElement) => {
      const hasNoDataId = !!domElement?.querySelector(`#${ExclusionIds.noElementData}`)
      if (!hasNoDataId) {
        acc.push(generateImageByElement({ domElement, options }))
      }
      return acc
    }, [])
  )

/**
 * Calculates the maximum width and the total height from an array of canvas elements.
 *
 * @param {HTMLCanvasElement[]} canvases - An array of canvas elements.
 * @returns {Object} An object containing the maximum width and the total height of the canvases.
 */
export const getCanvasesSize = (canvases) => ({
  maxWidth: Math.max(...canvases.map((canvas) => canvas.width)),
  totalHeight: canvases.reduce((sum, canvas) => sum + canvas.height, 0),
})

/**
 * Generates a PDF document from an array of section elements, combining them into a single canvas and then into a single PDF page with jspdf Library.
 * Each section is first converted to a canvas element, and then all are drawn onto a combined canvas which is used to create the PDF.
 *
 * @param {Object} params - The parameters for generating the PDF.
 * @param {Element[]} params.sections - An array of DOM elements to be included in the PDF.
 * @param {function} params.getFileName - A function that returns the desired file name for the PDF.
 * @param {Object} params.pdfOptions - Options to be applied when initializing the jsPDF instance.
 * @param {Object} params.imgOptions - Options to be used when converting sections to canvas elements.
 * @returns {Promise<void>} - A promise that resolves when the PDF has been generated and saved.
 */
export const generatePdfBySections = async ({ sections, getFileName, pdfOptions, imgOptions }) => {
  const canvases = await sectionsToCanvas(sections, imgOptions)

  if (isEmpty(canvases)) {
    return
  }

  const { maxWidth, totalHeight } = getCanvasesSize(canvases)
  const combinedCanvas = document.createElement('canvas')
  combinedCanvas.width = maxWidth
  combinedCanvas.height = totalHeight
  const context = combinedCanvas.getContext('2d')

  let yOffset = 0

  for (const canvas of canvases) {
    context.drawImage(canvas, 0, yOffset)
    yOffset += canvas.height
  }

  const pdf = jsPDF({
    unit: 'px',
    orientation: combinedCanvas.width > combinedCanvas.height ? ExportOrientationType.landscape : ExportOrientationType.portrait,
    format: [combinedCanvas.width, combinedCanvas.height],
    ...pdfOptions,
  })

  pdf.addImage(
    combinedCanvas.toDataURL('image/png'), // Allow canvas transparency
    imageInPdfSettings.format,
    imageInPdfSettings.x,
    imageInPdfSettings.y,
    combinedCanvas.width - imageInPdfSettings.canvasPadding * 2,
    combinedCanvas.height - imageInPdfSettings.canvasPadding * 2,
    undefined,
    imageInPdfSettings.compression
  )

  pdf.save(getFileName())
}
