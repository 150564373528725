import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import { Link } from 'react-router-dom'
import PayslipTable from 'components/table/PayslipTable'
import { flag, processLink } from 'utils/tableDataFormatters'

class CompanyServicesList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      processes: [],
      isOperational: null
    }
  }
  /**
   * Handle modal opening
   * @param modalRef
   */
  handleModalOpen (modalRef) {
    modalRef.showModal()
  }

  handleServiceClick (processes) {
    this.setState({ processes })
    this.handleModalOpen(this.refs.modalShowServices)
  }

  renderServices (services, isOperational) {
    let rows = []
    services.forEach((service, i) => {
      rows.push({
        services: <div key={services} onClick={() => {
          this.setState({ isOperational })
          this.handleServiceClick(service.processes)
        }}>
          <span>{service.name}</span>
          <span
            className='u-text--curious u-cursor--pointer u-float--right
            u-weight--medium u-padding-left-small u-padding-right-small'
            data-testid='payroll-calculation'
          >
            {service.processesCount}
          </span>
        </div>
      })
    })
    return rows
  }

  renderProcesses () {
    const { isTableRepresentation } = this.props

    if (isTableRepresentation) return this.renderProcessesAsTable()

    return this.renderProcessesAsList()
  }

  renderProcessLink ({ name, id, uri, company }) {
    return <Link key={id} to={`/companies/${company.id}/dates-processes/${uri}/${id}`}
      className='o-block o-block--left u-text--curious u-text--overflow-ellipsis'>{name}</Link>
  }

  renderProcessesAsTable () {
    const { processes } = this.state

    return <PayslipTable
      data={processes.map(process => {
        return {
          id: process.id,
          country: process.country.abbreviature,
          company: process.company.name,
          payroll: {
            name: process.name,
            id: process.id,
            uri: process.uri,
            company: process.company
          }
        }
      })}
      headings={[
        { accessor: 'country', Header: 'Country', disableFilters: true, Cell: flag, setWidth: '120px' },
        { accessor: 'company', Header: 'Company', disableFilters: true, },
        {
          accessor: 'payroll',
          Header: this.state.isOperational
            ? 'Payroll'
            : 'Process',
          disableFilters: true,
          Cell: processLink
        }
      ]}
      className='u-1/1'
    />
  }

  renderProcessesAsList () {
    const { processes } = this.state

    return processes.map(process => this.renderProcessLink(process)).reverse()
  }

  render () {
    const { operationalServices, setupServices } = this.props

    return <>
      <Modal
        ref='modalShowServices'
        className='c-modal c-modal--half c-modal--overflow-y'
        modalHeading='Go to'
      >
        <div className='o-layout'>
          <div className='o-layout__item u-1/1 u-1/1@tablet u-1/1@desktop'>
            {this.renderProcesses()}
          </div>
        </div>
      </Modal>

      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
          <PayslipTable
            wrapperClassName='u-1/1'
            headings={[
              {
                accessor: 'services',
                Header: 'Operational services',
                disableSortBy: true,
                classNames: 'u-text--center',
                disableFilters: true,
              }
            ]}
            data={this.renderServices(operationalServices, true)}
          />
        </div>

        { setupServices.length && <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
          <PayslipTable
            wrapperClassName='u-1/1'
            headings={[
              {
                accessor: 'services',
                Header: 'Setup services',
                disableSortBy: true,
                classNames: 'u-text--center',
                disableFilters: true,
              }
            ]}
            data={this.renderServices(setupServices, false)}
          />
        </div> }
      </div>
    </>
  }
}

CompanyServicesList.propTypes = {
  operationalServices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    processes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      uri: PropTypes.string
    })),
    processesCount: PropTypes.number.isRequired
  })),
  setupServices: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    processes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      uri: PropTypes.string
    })),
    processesCount: PropTypes.number.isRequired
  })),
  // Represent the processes in a table,
  // or fallback to the default list presentation
  isTableRepresentation: PropTypes.bool
}

export default CompanyServicesList
