import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import GlobalDataProtectionContainer from '../containers/GlobalDataProtectionContainer'
import GlobalDataProtectionEditContainer from '../containers/GlobalDataProtectionEditContainer'

class RouteView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dataFieldId: null
    }
  }
  render () {
    return <InnerWrapper>
      <Modal
        ref='editModal'
        className='c-modal c-modal--half c-modal--overflow-y'
        modalHeading='Edit data protection field'
      >
        <GlobalDataProtectionEditContainer
          dataFieldId={this.state.dataFieldId}
          onEdit={() => this.refs.editModal.hideModal()}
        />
      </Modal>

      <GlobalDataProtectionContainer
        onEdit={(dataFieldId) => {
          this.setState({
            dataFieldId
          })
          this.refs.editModal.showModal()
        }}
        {...this.props} />
    </InnerWrapper>
  }
}

export default RouteView
