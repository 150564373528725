import createSelector from 'utils/createSelector'
import { getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds, makeFilteredIdsByFilter } from 'redux/selectors/filters'
import _ from 'lodash'

const getPayrollId = (state, props) => parseInt(props.payrollId)
const getCompanyId = (state, props) => parseInt(props.companyId)
// get filtered ids, by passed in filter
export const getFilteredIdsByFilter = makeFilteredIdsByFilter('payrolls')

export const getPayrollById = createSelector(getPayrollId, ({ Payroll }, payrollId) => {
  const payrollById = Payroll.withId(payrollId)
  return {
    ...payrollById.ref,
    company: payrollById.company.ref,
  }
})

export const getPayrolls = createSelector(({ Payroll }) =>
  Payroll.all()
    .toModelArray()
    .map((payroll) => {
      return {
        ...payroll.ref,
      }
    })
    .reverse()
)

export const getPayrollsByCountriesThroughCompany = createSelector(getCompaniesByCountries, ({ Company }, companies) => {
  const payrolls = companies.map((companyRef) => {
    const companyById = Company.withId(companyRef.id)
    return companyById.payrolls
      .toModelArray()
      .map((payroll) => ({
        ...payroll.ref,
      }))
      .reverse()
  })

  return _.flatten(payrolls)
})

const _getPayrollsByCompany = (Company, companyId) => Company.withId(companyId).payrolls.toModelArray()

export const getPayrollsByCompany = createSelector(getCompanyId, ({ Company }, companyId) =>
  _getPayrollsByCompany(Company, companyId).map((payroll) => ({
    ...payroll.ref,
  }))
)

export const getPayrollsByCompanies = createSelector(getCompaniesIds, ({ Payroll }, companiesIds) =>
  Payroll.filter((payroll) => companiesIds.includes(payroll.company))
    .toModelArray()
    .map((payroll) => ({
      ...payroll.ref,
    }))
)

export const getPayrollWithPaygroupsById = createSelector(getPayrollId, ({ Payroll }, payrollId) => {
  const payrollById = Payroll.withId(payrollId)

  return {
    ...payrollById.ref,
    paygroupIds: payrollById.payrollPaygroups.toRefArray().map((pivot) => pivot.paygroup),
  }
})

export const getPayrollRef = createSelector(getPayrollId, ({ Payroll }, id) => ({ ...Payroll.withId(id).ref }))

export const getFilteredPayrolls = createSelector(getFilteredIdsByFilter, ({ Payroll }, filteredIds) => filteredIds?.map((id) => Payroll.withId(id).ref))

export const getPayrollsWithActiveOrLockedPayrollInstances = createSelector(({ Payroll }) =>
  Payroll.all()
    .toModelArray()
    .map((payroll) => {
      return {
        ...payroll.ref,
      }
    })
    .reverse()
    .filter((p) => p.hasActivePayrollInstances !== false || p.hasLockedPayrollInstances !== false)
)

export const getPayrollsByCompanyWithActiveOrLockedPayrollInstances = createSelector(getCompanyId, ({ Company }, companyId) =>
  _getPayrollsByCompany(Company, companyId)
    .map((payroll) => ({
      ...payroll.ref,
    }))
    .filter((p) => p.hasActivePayrollInstances !== false || p.hasLockedPayrollInstances !== false)
)
