import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollProcess'

class PayrollProcess extends CRUDModel {}
PayrollProcess.modelName = 'PayrollProcess'
PayrollProcess.actionTypes = actionTypes

PayrollProcess.fields = {
  id: attr(),
  owner: fk('EmployeeSystemUser', 'payrollProcesses'),
  payrollService: fk('PayrollService', 'payrollProcesses'),
  companyProcess: fk('CompanyCountryProcess', 'payrollProcesses')
}

export default PayrollProcess
