import React from 'react'
import PayslipTable from 'components/table/PayslipTable'

export const DataProtectionResourcesList = () => {
  return (
    <PayslipTable
      data={[
        {
          field: (
            <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>
                Personal data definition
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                'Personal Data' means any information relating to an identified
                or identifiable natural person ('data subject'); an identifiable
                natural person is one who can be identified, directly or
                indirectly, in particular by reference to an identifier such as
                a name, an identification number, location data, an online
                identifier or to one or more factors specific to the physical,
                physiological, genetic, mental, economic, cultural or social
                identity of that natural person; GDPR, Article 4.{' '}
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>
                Disclaimer
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                The employer has ownership for all the data and protection of
                data of its employees and customers. Payslip has invested best
                efforts to identify information fields on this software which
                may be used or misused to identify an employee and has invested
                in additional security reviews to protect this data. Each
                company needs to make its own judgement on the data they choose
                to protect. Payslip does not guarantee that the data fields it
                has chosen to identify as personal data fields is the full and
                final list or meets the preferences of all companies. Payslip
                recommends and expects each employer to review all data fields
                of its employees and advise Payslip should it judge additional
                data fields to be deemed personal data fields within the Payslip
                software.
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>
                Article 29 Working Party
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a
                  href='https://ec.europa.eu/justice/data-protection/article-29/index_en.htm'
                  target='blank_'
                  className='u-text--curious'
                >
                  https://ec.europa.eu/justice/data-protection/article-29/index_en.htm
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>
                Overview on Binding Corporate rules
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a
                  href='https://ec.europa.eu/justice/data-protection/international-transfers/binding-corporate-rules/index_en.htm'
                  target='blank_'
                  className='u-text--curious'
                >
                  https://ec.europa.eu/justice/data-protection/international-transfers/binding-corporate-rules/index_en.htm
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>
                List of companies for which the EU BCR cooperation procedure is
                closed
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a
                  href='https://ec.europa.eu/justice/data-protection/international-transfers/binding-corporate-rules/bcr_cooperation/index_en.htm'
                  target='blank_'
                  className='u-text--curious'
                >
                  https://ec.europa.eu/justice/data-protection/international-transfers/binding-corporate-rules/bcr_cooperation/index_en.htm
                </a>
              </div>
            </div>
          ),
        },
        {
          field: (
            <div className='o-layout o-layout--middle u-text--normal'>
              <div className='u-text--normal o-layout__item u-1/3'>
                Restoring trust in transatlantic data flows through strong
                safeguards: European Commission presents EU-U.S. Privacy Shield
              </div>
              <div className='u-text--normal o-layout__item u-2/3'>
                <a
                  href='http://europa.eu/rapid/press-release_IP-16-433_en.htm'
                  target='blank_'
                  className='u-text--curious'
                >
                  http://europa.eu/rapid/press-release_IP-16-433_en.htm
                </a>
              </div>
            </div>
          ),
        },
      ]}
      headings={[
        {
          accessor: 'field',
          Header: 'Data Protection Resources',
          isKey: true,
          classNames: 'text-center',
          disableSortBy: true,
          disableFilters: true
        },
      ]}
      wrapperClassName='u-margin-bottom u-1/1'
      trClassName='react-bs-table__cell--whitespace--normal'
    />
  )
}

export default DataProtectionResourcesList
