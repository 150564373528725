import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import { InactiveUser } from 'components/ProcessTree'
import PayslipTable from 'components/table/PayslipTable'

class ShareActionOwners extends React.Component {
  render () {
    const { processOwner, actionOwners, initialValues, isOwner, sharingActionText = 'Share' } = this.props

    return <div data-testid='actionOwners-tabBody'>
      <PayslipTable
        data={processOwner.map(user => ({
          ...user,
          fullname: user.hasCredentials ? user.fullname : <InactiveUser />,
          share: user.hasCredentials ? <Field
            type='checkbox'
            labelClassName='u-hidden'
            component={CheckboxField}
            className='control__indicator__input'
            formGroupClassName='u-margin-none'
            name={`${user.extraData.tenant}-${user.extraData.id}`}
            data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
            indicatorClassName='control__indicator--center'
            disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
          /> : null
        }))}
        headings={[
          { accessor: 'fullname', Header: 'Process owner', disableSortBy: true },
          // eslint-disable-next-line max-len
          { accessor: 'share', Header: sharingActionText, setWidth: '100px', classNames: 'text-center', columnClassName: 'u-text--center', disableFilters: true, disableSortBy: true }
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={{
          noDataText: 'No process owner found to share with.'
        }}
        trClassName='react-bs-table__cell--whitespace--normal'
      />

      <PayslipTable
        data={actionOwners.map(user => ({
          ...user,
          fullname: user.hasCredentials ? user.fullname : <InactiveUser />,
          share: user.hasCredentials ? <Field
            type='checkbox'
            labelClassName='u-hidden'
            component={CheckboxField}
            className='control__indicator__input'
            data-testid={`share-checkbox-${user.extraData.tenant}-${user.extraData.id}`}
            formGroupClassName='u-margin-none'
            name={`${user.extraData.tenant}-${user.extraData.id}`}
            disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
            indicatorClassName='control__indicator--center'
          /> : null
        }))}
        headings={[
          { accessor: 'fullname', Header: 'Action owner', disableSortBy: true },
          // eslint-disable-next-line max-len
          { accessor: 'share', Header: sharingActionText, setWidth: '100px', classNames: 'text-center', columnClassName: 'u-text--center', disableFilters: true, disableSortBy: true }
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        options={{
          noDataText: 'No action owners found to share with.'
        }}
        trClassName='react-bs-table__cell--whitespace--normal'
      />
    </div>
  }
}

ShareActionOwners.propTypes = {
  actionOwners: PropTypes.array,
  processOwner: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string
}

export default ShareActionOwners
