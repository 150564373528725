import isArray from 'lodash/isArray'
import {
  EMPLOYEE_EXTRA_FIELDS_SCHEMAS,
  EMPLOYEE_EXTRA_FIELDS_DEFAULT_SCHEMA,
  ACCEPTED_TYPE_VALUES,
  ACCEPTED_FORMAT_VALUES
} from '../schemas/employeeFieldsSchema'

export const remapExtraFields = (fields, inViewMode = false) => {
  return fields.map(field => {
    const { location, metaData } = field
    let fieldMakeup = EMPLOYEE_EXTRA_FIELDS_DEFAULT_SCHEMA
    if (field.type && !isArray(field.type)) { fieldMakeup = EMPLOYEE_EXTRA_FIELDS_SCHEMAS.find(schema => schema.mappedServerType === field.type) }
    if (isArray(field.type)) {
      const foundType = field.type.find(t => Object.values(ACCEPTED_TYPE_VALUES).includes(t))
      fieldMakeup = EMPLOYEE_EXTRA_FIELDS_SCHEMAS.find(schema => schema.mappedServerType === foundType)
    }

    if (field.enum) { fieldMakeup = EMPLOYEE_EXTRA_FIELDS_SCHEMAS.find(schema => schema.mappedServerType === ACCEPTED_TYPE_VALUES.ENUM) }

    if (field.format === ACCEPTED_FORMAT_VALUES.DATE && (field.type === ACCEPTED_TYPE_VALUES.STRING || isArray(field.type))) {
      fieldMakeup = EMPLOYEE_EXTRA_FIELDS_SCHEMAS.find(schema => schema.mappedServerType === ACCEPTED_TYPE_VALUES.DATE)
    }

    if (field.format === ACCEPTED_FORMAT_VALUES.EMAIL && field.type === ACCEPTED_TYPE_VALUES.STRING) {
      fieldMakeup = EMPLOYEE_EXTRA_FIELDS_SCHEMAS.find(schema => schema.mappedServerType === ACCEPTED_TYPE_VALUES.EMAIL)
    }

    fieldMakeup.subCategory = location.category
    fieldMakeup.field = `extraFields.${field.fieldTypeName}`
    fieldMakeup.name = metaData.label

    if (field.enum) {
      let options = field.enum.map(val => { return { value: val, label: val } })

      if (metaData.enumDisplayValues) {
        options = Object.keys(metaData.enumDisplayValues).map(key => {
          return { value: key, label: metaData.enumDisplayValues[key] }
        })
      }
      fieldMakeup.options = options
    }

    if (inViewMode) {
      field.metaData = {
        ...field.metaData,
        ...fieldMakeup
      }
    }
    return {
      ...field,
      ...fieldMakeup
    }
  })
}

export const positionExtraFields = (extraFieldsResp, extraFields, section, subSection) => {
  const { properties } = extraFields
  Object.keys(properties).forEach(propName => {
    const { metaData } = properties[propName]

    metaData?.appSections.forEach(appSection => {
      if (appSection.name === 'frontEndLocations') {
        appSection.locations.forEach(location => {
          if (location.tab.toLowerCase() === section && location.category === subSection) {
            extraFieldsResp.push({ ...properties[propName], location, fieldTypeName: propName })
          }
        })
      }
    })

    return properties[propName]?.metaData
  })

  return extraFieldsResp
}

export const getUnpositionedFields = (extraFieldsResp, allFields) => {
  let unpositionedFields = []
  const mappedExtraFields = remapExtraFields(extraFieldsResp)
  mappedExtraFields.forEach(mappedField => {
    let insertPosition = allFields.length - 1
    if (mappedField.location.afterField) {
      insertPosition = allFields.findIndex(item => item.field === mappedField.location.afterField)
      if (insertPosition === -1) {
        unpositionedFields.push(mappedField)
      } else {
        insertPosition += 1
      }
    }
    if (insertPosition !== -1) allFields.splice(insertPosition, 0, mappedField)
  })
  return { allFields, unpositionedFields }
}

export const positionUnpositionedFields = (unpositionedFields, allFields) => {
  let count = 0
  const initialLength = unpositionedFields.length
  if (unpositionedFields.length) {
    while (unpositionedFields.length > 0 || count < initialLength) {
      unpositionedFields.forEach((unpositioned, i) => {
        let customFieldInsertPosition = allFields.findIndex(item => {
          return item.field === `extraFields.${unpositioned.location.afterField}`
        })
        if (customFieldInsertPosition !== -1) {
          const position = customFieldInsertPosition + 1
          allFields.splice(position, 0, unpositioned)
          unpositionedFields.splice(i, 1)
        } else {
          allFields.push(unpositioned)
          unpositionedFields.splice(i, 1)
        }
      })
      count += 1
    }
  }
  return allFields
}
