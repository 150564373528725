import React from 'react'
import Report from './Report'
import PropTypes from 'prop-types'

const ReportsList = ({ reports, onDownloadClick, ...rest }) => {
  return (
    <>
      {reports.map((report) => (
        <Report key={report.reportName} onDownloadClick={onDownloadClick(report.reportName)} {...report} {...rest} />
      ))}
    </>
  )
}

ReportsList.propTypes = {
  reports: PropTypes.array,
}

export default ReportsList
