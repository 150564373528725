import React from 'react'
import PropTypes from 'prop-types'
import InnerWrapper from 'layouts/InnerWrapper'
import { Switch, useRouteMatch, useHistory } from 'react-router-dom'
import generateAllRoutes from 'utils/routeGenerator'

function VendorRouteView (props) {
  let { path } = useRouteMatch()
  let history = useHistory()

  let routes = []
  if (props.childRoutes) {
    props.childRoutes.forEach(child => {
      routes = generateAllRoutes(child, path, routes, history)
    })
  }
  return (
    <InnerWrapper>
      <Switch>
        {[...routes]}
      </Switch>
    </InnerWrapper>
  )
}

VendorRouteView.propTypes = {
  childRoutes: PropTypes.array,
}

export default VendorRouteView
