import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import ConfirmationModal from 'components/ConfirmationModal'
import { Authorization } from 'containers/Authorization'
import PayslipTable from 'components/table/PayslipTable'
import { getVendorUsersTableConfig } from '../vendorUsersTableConfig'
import { vendorRolesLabels } from '../../../../../../../utils/locales/roles.en'

const VendorUsersList = (props) => {
  const [vendorUserId, setVendorUserId] = useState(null)
  const [vendorName, setVendorName] = useState(null)
  const deleteModalRef = useRef(null)
  const vendorAccessAreas = props?.vendorAccessAreas || {}

  const handleModalOpen = (modalRef, vendorUserId, vendorName) => {
    setVendorUserId(vendorUserId)
    setVendorName(vendorName)
    modalRef?.current?.showModal()
  }
  const renderRows = (props) => {
    const vendorUsers = props.vendorUsers

    return vendorUsers.map((vendorUser) => ({
      name: vendorUser.name,
      mobilePhone: vendorUser.mobilePhone,
      workEmail: vendorUser.workEmail,
      position: vendorUser.position,
      roleType: vendorRolesLabels[vendorUser.roleType],
      accessAreas: vendorAccessAreas[vendorUser.id],
      country: vendorUser.country && (
        <div className='o-grid o-grid--medium o-grid--center u-overflow-visible'>
          <Flag flag={vendorUser.country.abbreviature.toLowerCase()} title={vendorUser.country.name} size='tiny' />
        </div>
      ),
      status: vendorUser.hasCredentials ? 'Active' : 'Inactive',
      action: (
        <div className='u-text--center d-flex'>
          <Authorization permissions={['VENDORUSER_EDIT']}>
            <button
              onClick={() => props.onClick(vendorUser.id)}
              className='c-btn c-btn--tiny c-btn--curious u-margin-right-tiny'
              data-testid={`edit-${vendorUser.id}`}
            >
              <span className='icon icon--edit' title='Edit' />
            </button>
          </Authorization>
          <Authorization permissions={['VENDORUSER_DELETE']}>
            <button
              onClick={() => {
                const fullName = `${vendorUser.firstname} ${vendorUser.surname}`
                const nameToShow = vendorUser.title ? `${vendorUser.title} ${fullName}` : fullName
                handleModalOpen(deleteModalRef, vendorUser.id, nameToShow)
              }}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              title='Delete'
            >
              <span className='icon icon--trash' />
            </button>
          </Authorization>
        </div>
      ),
    }))
  }
  return (
    <div>
      <PayslipTable
        wrapperClassName='u-1/1'
        tableClassName='o-table--tiny o-table--striped u-margin-bottom-large'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        headings={getVendorUsersTableConfig()}
        data={renderRows(props)}
      />
      <ConfirmationModal ref={deleteModalRef} className='c-modal' modalHeading='Confirmation' onConfirm={() => props.deleteVendorUser(vendorUserId)}>
        <p>
          Are you sure you want to delete user <span className='u-weight--bold'>{vendorName}</span>?
        </p>
      </ConfirmationModal>
    </div>
  )
}

VendorUsersList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  vendorUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstname: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      gender: PropTypes.string,
      workEmail: PropTypes.string.isRequired,
      title: PropTypes.string,
      roleType: PropTypes.string.isRequired,
      country: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        abbreviature: PropTypes.string,
      }),
    })
  ),
  onClick: PropTypes.func,
  deleteVendorUser: PropTypes.func,
}

export default VendorUsersList
