import React from 'react'
import PropTypes from 'prop-types'
import Employee from './Employee'
import { isEmpty } from 'utils/fnkit/typeChecks'
import NoResultsView from 'components/NoResultsView'

export const StaticEmployees = ({ employeeSystemUsers }) => {
  const showNoDataMessage = isEmpty(employeeSystemUsers)
  return (
    <div className='o-layout u-margin-top-large o-grid--strech' data-testid='employees-listing'>
      {showNoDataMessage && <NoResultsView className='w-full h-full' />}
      {employeeSystemUsers?.map((employee) => (
        <Employee key={employee.id} employee={employee} />
      ))}
    </div>
  )
}

StaticEmployees.propTypes = {
  employeeSystemUsers: PropTypes.array,
}

export default StaticEmployees
