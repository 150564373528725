import createDeepEqualSelector from 'utils/createDeepEqualSelector'

const parseId = (id) => parseInt(id)

export const getIds = createDeepEqualSelector(
  (state, { ids }) => ids.map(parseId),
  (ids) => ids
)

export const getCountriesIds = createDeepEqualSelector(
  (state, { countriesIds }) => countriesIds.map(parseId),
  (ids) => ids
)

export const getCompaniesIds = createDeepEqualSelector(
  (state, { companiesIds }) => companiesIds.map(parseId),
  (ids) => ids
)

export const getBusinessUnitsIds = createDeepEqualSelector(
  (state, { businessUnitsIds }) => businessUnitsIds.map(parseId),
  (ids) => ids
)

export const getCostCentersIds = createDeepEqualSelector(
  (state, { costCentersIds }) => costCentersIds.map(parseId),
  (ids) => ids
)

export const getProjectsIds = createDeepEqualSelector(
  (state, { projectsIds }) => projectsIds.map(parseId),
  (ids) => ids
)

export const getPayrollsIds = createDeepEqualSelector(
  (state, { payrollsIds }) => payrollsIds.map(parseId),
  (ids) => ids
)

export const getPaygroupsIds = createDeepEqualSelector(
  (state, { paygroupsIds }) => paygroupsIds.map(parseId),
  (ids) => ids
)

export const getPayrollInstancesIds = createDeepEqualSelector(
  (state, { payrollInstancesIds }) => payrollInstancesIds.map(parseId),
  (ids) => ids
)

export const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)
export const getFilteredEmployees = (state) => state.employeeSystemUsers.filteredIds
export const makeFilteredIdsByFilter =
  (entity) =>
    (state, { filter }) =>
      state[entity].filters[filter]?.ids
