import createSelector from 'utils/createSelector'
import { _getCompaniesByCountry, getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds } from 'redux/selectors/filters'
import _ from 'lodash'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'
import { sortByName } from 'utils/strings'

const getCostCenterId = (state, props) => parseInt(props.costCenterId)
export const getCompanyId = (state, props) => parseInt(props.companyId)
const getCountryId = (state, props) => parseInt(props.countryId)

export const getCostCenterById = createSelector(getCostCenterId, ({ CostCenter }, costCenterId) => {
  const costCenter = CostCenter.withId(costCenterId)
  return {
    ...costCenter.ref,
    company: costCenter.company.ref,
  }
})

export const getCostCenters = createSelector(({ CostCenter }) => {
  return CostCenter.all()
    .toRefArray()
    .map((cc) => ({
      ...cc,
    }))
})

export const getCostCentersWithCompanyWithCountry = createSelector(({ CostCenter }) => {
  return CostCenter.all()
    .toModelArray()
    .map((cc) => ({
      ...cc.ref,
      company: { ...cc.company.ref },
      country: { ...cc.company.country.ref },
    }))
})

export const getCostCentersByCompany = createSelector(getCompanyId, ({ CostCenter }, companyId) => {
  return CostCenter.filter((cc) => cc.company === companyId)
    .toRefArray()
    .map((cc) => ({
      ...cc,
    }))
})

export const getCostCentersByCountryThroughCompany = createSelector(getCountryId, ({ Country }, countryId) => {
  const companies = _getCompaniesByCountry(Country, countryId)
  const costCenters = companies.map((company) => {
    return company.costCenters.toModelArray().map((costCenter) => ({
      ...costCenter.ref,
    }))
  })

  return _.flatten(costCenters)
})

export const getCostCentersByCountriesThroughCompany = createSelector(getCompaniesByCountries, ({ Company }, companies) => {
  const costCenters = companies.map((companyRef) => {
    const company = Company.withId(companyRef.id)
    return company.costCenters
      .toModelArray()
      .map((costCenter) => ({
        ...costCenter.ref,
      }))
      .reverse()
  })

  return _.flatten(costCenters)
})

export const getCostCentersByCompanies = createSelector(getCompaniesIds, ({ CostCenter }, companiesIds) => {
  return CostCenter.filter((cc) => companiesIds.includes(cc.company))
    .toRefArray()
    .map((cc) => ({ ...cc }))
})

export const getCostCentersBasedOnCompanyType = ({ state, isHQ, companiesIds }) => {
  const hasCompanies = isArray(companiesIds) && !isEmpty(companiesIds)
  /**
   * In case we have an array of companies, we need all
   * the cost centers; otherwise, we need to check if it is a
   *  single company HQ and call the appropriate method.
   */
  const companies = hasCompanies ? getCostCentersByCompanies(state, { companiesIds }).sort(sortByName) : null

  if (companies) {
    return companies
  }

  return isHQ ? getCostCentersWithCompanyWithCountry(state).sort(sortByName) : getCostCentersByCompany(state, { companyId: companiesIds }).sort(sortByName)
}
