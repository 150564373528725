import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchJoinersAndLeaversCount, exportJoinersAndLeaversReport, downloadJoinersAndLeaversReport } from 'redux/actions/payrollInstances'
import { PayrollInstanceJoinersView } from '../components/PayrollInstanceJoinersView'
const PayrollInstanceJoinersContainer = ({ payrollInstanceId, isVendorUser = false, shouldUseFilter = false, filter = {} }) => {
  const [joinersAndLeavers, setJoinersAndLeavers] = useState(null)
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchJoinersAndLeaversCount(payrollInstanceId, isVendorUser)).then(res => {
      if (res.data) {
        setJoinersAndLeavers(res.data)
        if (res.data.joiners === 0 && res.data.leavers === 0) setMessage('There are no joiners or leavers for this payrun.')
      }
    })
  }, [])

  const onSave = () => {
    const filterUri = shouldUseFilter ? filter.uri : null
    dispatch(exportJoinersAndLeaversReport(payrollInstanceId, isVendorUser, filterUri)).then(res => {
      setMessage(`Your Joiners and Leavers extract will be saved in the 'Inputs' tab of the Files section.`)
    })
  }

  const onDownload = () => {
    dispatch(downloadJoinersAndLeaversReport(payrollInstanceId, isVendorUser))
  }

  return (
    <PayrollInstanceJoinersView
      data={joinersAndLeavers}
      onSave={onSave}
      onDownload={onDownload}
      message={message}
    />
  )
}

PayrollInstanceJoinersContainer.propTypes = {
  payrollInstanceId: PropTypes.number,
  isVendorUser: PropTypes.bool,
  shouldUseFilter: PropTypes.bool,
  filter: PropTypes.object,
}

export default PayrollInstanceJoinersContainer
