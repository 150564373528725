
import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'

export default (store) => ({
  path : '/dashboard',
  exact: true,
  indexRoute: { component: RouteView },
  authProtected: true,
  wrappedComponent: CoreLayout,
})
