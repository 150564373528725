import React from 'react'
import { platformColors } from 'utils/colors'

export const CheckIconAlt = ({ className, width = '13', height = '13', color = platformColors.midBlue, ...props }) => (
  <div className={className}>
    <svg width={width} height={height} viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle className='icon--check-alt' cx='6.5' cy='6.5' r='6.5' fill={color} />
      <rect x='2.16669' y='6.5553' width='1.75491' height='4.36574' rx='0.361111' transform='rotate(-45 2.16669 6.5553)' fill='white' />
      <rect x='5.73401' y='10.1219' width='1.75491' height='7.45275' rx='0.361111' transform='rotate(-135 5.73401 10.1219)' fill='white' />
    </svg>
  </div>
)
