import React from 'react'

class AssignmentListOptions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showOptions: false,
      isSelectAll: true
    }
  }

  render () {
    const { isSelectAll } = this.state
    return <div className='u-float--right u-relative'>
      <span
        onClick={() => this.setState({ showOptions: !this.state.showOptions })}
        className='icon icon--dots  u-cursor--pointer'
      />
      {
        this.state.showOptions && <div className='c-draggableboard__options'>
          <ul className='o-list-bare u-margin-none'>
            <li onClick={() => {
              if (isSelectAll) this.props.selectAll()
              if (!isSelectAll) this.props.deselectAll()

              this.setState({ showOptions: false, isSelectAll: !isSelectAll })
            }} className='u-cursor--pointer'>{isSelectAll ? 'Select all' : 'Deselect all'}</li>
          </ul>
        </div>
      }
    </div>
  }
}

export default AssignmentListOptions
