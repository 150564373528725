import React from 'react'
import PropTypes from 'prop-types'
import BaseField from './BaseField'

class InputField extends React.Component {
  render () {
    const { input, type, min, max, step, autoFocus, disabled, onKeyPress, autocomplete = 'on',
      className = 'c-input', placeholder, onChange, onFocus, onBlur, children } = this.props

    return <BaseField {...this.props}>
      <input
        {...input}
        type={type}
        min={min}
        max={max}
        step={step}
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autocomplete}
        onChange={onChange || input.onChange}
        onFocus={onFocus || input.onFocus}
        onBlur={onBlur || input.onBlur}
        data-testid={this.props['data-testid']}
      />
      { children }
    </BaseField>
  }
}

InputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.object,
  autocomplete: PropTypes.string,
  // Used by Cypress E2E framework, for querying DOM easily
  // @credits https://docs.cypress.io/guides/references/best-practices.html#Selecting-Elements
  'data-testid': PropTypes.string,
}

export default InputField
