const currencyLocale = {
  ENEN: 'en-EN',
  ENUS: 'en-US',
  DEDE: 'de-DE',
}

const currencyType = {
  EUR: 'EUR',
  JPY: 'JPY',
  USD: 'USD'
}

export { currencyLocale, currencyType }
