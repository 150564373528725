import { createReducer } from 'utils/redux'
import { COST_CENTERS_FETCH, COST_CENTERS_RECEIVE } from '../actions/costCenters'

const actionTypes = {
  fetch: COST_CENTERS_FETCH,
  receive: COST_CENTERS_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(actionTypes)
