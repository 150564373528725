import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/dashboards'

class Dashboard extends CRUDModel {}
Dashboard.modelName = 'Dashboard'

Dashboard.fields = {
  id: attr()
}

Dashboard.actionTypes = actionTypes

export default Dashboard
