import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/form/select/Select'
import { Value } from 'react-select'

// In case multi values are selected, just show a single item (Multiple selected), because there isn't enough space
// in the columns and our goal is to keep the select Filter shorter.
// If only one item is selected, just show it (as it works by default).
const TrimmedValue = (props) => {
  let { id, multi, selected } = props

  if (multi) {
    // `react-select` pass the `id` with the following string structure: `react-select-26--value-0`
    // The last number corresponds to the current element position in the array of selected items.
    const position = Number(id.split('--value-')[1])

    // Don't render the rest items, after the first one item/position
    if (position > 0) return null

    // If it's the first item/position and we have other selected items, show `Multiple` item only
    if (position === 0 && selected.length > 1) {
      return (
        <Value {...props} disabled>
          Multiple
        </Value>
      )
    }
  }

  // Default Value renderer
  return <Value {...props} />
}

/**
 * `Select` component for `payslip-table` columns.
 * It adapts our `Select` to `payslip-table` columns filtering API.
 */
const ColumnSelectFilter = (props) => {
  const { defaultValue, initialValue, filterHandler } = props
  const [value, setValue] = useState(defaultValue || initialValue || null)

  const handleChange = (newValue) => {
    setValue(newValue)
    filterHandler(newValue)
  }

  return <Select {...props} value={value} onChange={handleChange} valueComponent={TrimmedValue} />
}

// We use this function to create a Select filter configuration object,
// that complies with `payslip-table` API.
// Example (when you create a new heading column, having Select filter):
// { name: 'type', text: 'Type', filter: createColumnSelectFilter({ options: typesOptions, multi: true }) }`
export const createColumnSelectFilter = (props) => ({
  type: 'CustomFilter',
  getElement: (filterHandler) => <ColumnSelectFilter {...props} filterHandler={filterHandler} />,
})

ColumnSelectFilter.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  filterHandler: PropTypes.func.isRequired,
}

export default ColumnSelectFilter
