import React from 'react'
import PropTypes from 'prop-types'
import DisplayCard from 'components/displays/DisplayCard'
import FinanceDisplayCard from './FinanceDisplayCard'
import SideBarChartWithFlags from 'components/charts/SideBarChartWithFlags'
import SectionHeading from 'components/SectionHeading'
import MonthByMonthLineChart from 'components/charts/MonthByMonthLineChart'
import BasicPieChart from 'components/charts/BasicPieChart'
import Select from 'react-select/lib/Select'
import { totalsCalculations } from 'utils/enums/analyticsEnums'
import { useDispatch } from 'react-redux'
import { showMessage } from 'redux/actions/modal'
import { analyticsTemplateTypes, buildAnalyticsReport } from './AnalyticsBuilder'

const noFromDateMessageForLineChart = 'Please select a From Date and To Date to generate this graph.'
const noFromDateMessageForTile = 'Please select a From Date and To Date to generate this tile.'
const dateRangeForLineError = 'Please select a date range of at least 2 months to generate this graph.'
const dateRangeForTileError = 'Please select a date range of at least 2 months to generate this tile.'
const selectReportingCurrencyTileError = 'Please select a single reporting currency to generate this visual.'

const ReportingAnalyticsView = ({
  report: { namedFilters, ...report },
  charts,
  chartLogic,
  data,
  tileService,
  pieChartService,
  sideBarService,
  workForceDetailService,
  setHeadCountSort,
  hasHeadCountSortOpened,
  noFromDateSet,
  dateRangeDifferent,
  onSideBarFilterChange,
  onFirstFilterChange,
  sideBarQueryFirst,
  sideBarQuery,
  useDefaultSideBarSelect,
  isExporting,
  lineGraphIntervals,
  fetchedErrors,
}) => {
  const dispatch = useDispatch()
  const sortByTotal = (a, b) => {
    return hasHeadCountSortOpened === 'asc' ? a.total - b.total : b.total - a.total
  }
  const sortByAxis = (a, b) => {
    const totalsKey = chartLogic.SIDE_BARCHART_AXIS.yAxis
    return hasHeadCountSortOpened === 'asc' ? a[totalsKey] - b[totalsKey] : b[totalsKey] - a[totalsKey]
  }

  const isCalculated = chartLogic.TOTALS_CALCULATION === totalsCalculations.CALCULATED
  const isDateRangeIncorrect = dateRangeDifferent < 2 && !noFromDateSet
  const errorMessageForGraph = isDateRangeIncorrect ? dateRangeForLineError : noFromDateSet ? noFromDateMessageForLineChart : null
  const errorMessageForTile = isDateRangeIncorrect ? dateRangeForTileError : noFromDateSet ? noFromDateMessageForTile : null
  const tileFallBackError = isCalculated && errorMessageForTile ? errorMessageForTile : null
  const disabledLineChart = chartLogic.DISABLED_REPORTS && chartLogic.DISABLED_REPORTS.includes('HEADCOUNT_BY_MONTH')
  const noDataError = !data.length ? 'No data available' : null
  const financeTilesFallBackError = noDataError || (!tileService.showFinanceTiles && selectReportingCurrencyTileError) ? selectReportingCurrencyTileError : null
  const setGridColsTo4 = (tileService.showFinanceTiles || financeTilesFallBackError) && 'd-grid--cols-4'
  if (fetchedErrors.length > 0) {
    dispatch(
      showMessage({
        body: fetchedErrors.map((err) => {
          const values = Object.values(err)
          return `${values.join('\r\n')}`
        }),
      })
    )
  }
  const analyticsTileTemplates = buildAnalyticsReport(analyticsTemplateTypes.tiles, charts, {
    tileService,
    tileFallBackError,
    noDataError,
    financeTilesFallBackError,
  })
  const analyticsSideBarTemplates = buildAnalyticsReport(analyticsTemplateTypes.sideBar, charts, {
    sideBarService,
    sideBarQuery,
    chartLogic,
    onSideBarFilterChange,
    isExporting,
    sortByTotal: sortByAxis,
    setHeadCountSort,
    hasHeadCountSortOpened,
  })
  const needsCols = !chartLogic.IS_WORKFLOW_TOTALS_COUNT
  return (
    <div className='page--reporting-analytics-view'>
      <div className={`d-grid d-grid--gap-4 d-grid--cols-3 d-grid--tablet-cols-2 d-grid--mobile-cols-1 ${needsCols ? setGridColsTo4 : ''}`}>
        {analyticsTileTemplates}
        {charts.GLOBAL_WORKFORCE_HEADCOUNT && (
          <DisplayCard count={tileService.totalWorkCount} icon='report-workforce-headcount-logo' subtitle='Workforce Headcount' />
        )}
        {charts.GLOBAL_JOINERS_HEADCOUNT && (
          <DisplayCard count={tileService.joinersCount} icon='report-joiners-headcount-logo' subtitle='Joiners Headcount' fallback={tileFallBackError} />
        )}
        {charts.GLOBAL_LEAVERS_HEADCOUNT && (
          <DisplayCard count={tileService.leavesCount} icon='report-leavers-headcount-logo' subtitle='Leavers Headcount' fallback={tileFallBackError} />
        )}
        {charts.TOTAL_EMPLOYER_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalEmployerCosts}
            icon='report-total-costs-logo'
            subtitle='Total Employer Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={noDataError || financeTilesFallBackError}
            isFinanceReport
            hasDisclaimer={tileService.financePayrollCounts.totalErCost < tileService.financePayrollCounts.totalPayrollCount}
          />
        )}
        {charts.EMPLOYER_CONTRIBUTION_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalErContributionCosts}
            icon='report-er-contribution-costs-logo'
            subtitle='Employer Contribution Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={financeTilesFallBackError}
          />
        )}
        {charts.TOTAL_PAY_ELEMENT_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalPayElementCosts}
            icon='report-total-pay-elements-logo'
            subtitle='Pay Element Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={financeTilesFallBackError}
          />
        )}
        {charts.TOTAL_EE_DEDUCTION_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalEeDeductionCosts}
            icon='report-total-ee-deductions-logo'
            subtitle='Employee Deduction Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={financeTilesFallBackError}
          />
        )}
        {charts.TOTAL_EE_NET_DEDUCTION_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalEeNetDeductionCosts}
            icon='report-total-ee-net-deductions-logo'
            subtitle='Employee Net Deduction Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={financeTilesFallBackError}
          />
        )}
        {charts.TOTAL_PAYROLL_ELEMENT_COSTS && (
          <FinanceDisplayCard
            amount={tileService.totalPayrollElementCosts}
            icon='report-total-pay-elements-logo'
            subtitle='Payroll Element Costs'
            currency={tileService.currency}
            classNames='forced-padding-bottom--24px'
            fallback={financeTilesFallBackError}
          />
        )}
      </div>
      {analyticsSideBarTemplates}
      {charts.HEADCOUNT_BY_COUNTRY && (
        <>
          <div className='u-padding-top relative'>
            <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large overflow-y position-relative'>
              {!chartLogic.SIDE_BAR_FILTER_OPTIONS && <SectionHeading extraHuge huge={false} text={'Headcount by Country'} />}
              {chartLogic.SIDE_BAR_FILTER_OPTIONS && (
                <div className='d-flex'>
                  <h2 className='u-margin-none u-text--xtra-huge u-weight--bold'>Headcount by</h2>
                  <div className='u-margin-left-small u-margin-bottom u-min-width-200'>
                    <Select
                      className='c-custom-select c-inline-select-dropdown'
                      value={sideBarQuery}
                      labelClassName='c-label'
                      placeholder='Select'
                      options={chartLogic.SIDE_BAR_FILTER_OPTIONS}
                      clearable={false}
                      onChange={onSideBarFilterChange}
                    />
                  </div>
                </div>
              )}
              <div className='headcount-sort position-absolute' onClick={() => setHeadCountSort(hasHeadCountSortOpened === 'desc' ? 'asc' : 'desc')}>
                <span className={`icon icon--chart-sort-icon ${hasHeadCountSortOpened === 'desc' ? 'sort-opened' : ''}`} />
              </div>
              <SideBarChartWithFlags
                data={
                  !useDefaultSideBarSelect
                    ? sideBarService.getCountsByQueryType(sideBarQuery).sort(sortByTotal)
                    : sideBarService.sideBarDataMapped.sort(sortByTotal)
                }
                xKey={chartLogic.SIDE_BARCHART_AXIS.xAxis}
                yKey={chartLogic.SIDE_BARCHART_AXIS.yAxis}
                queryKey={sideBarQuery}
                isExporting={isExporting}
              />
            </div>
          </div>
        </>
      )}
      {charts.HEADCOUNT_BY_MONTH && !disabledLineChart && (
        <>
          <div className='u-padding-top'>
            <div className='c-display-cards headcount-by-period shadow rounded border u-padding u-padding-bottom-large overflow-y'>
              {dateRangeDifferent >= 2 && (
                <>
                  <SectionHeading fullWidth extraHuge huge={false} text={'Headcount by Period'} />
                  <MonthByMonthLineChart
                    data={workForceDetailService.lineChartDataMapped}
                    lineAxis={workForceDetailService.lineChartDataLegend}
                    colours={chartLogic.CHART_DATAKEYS_COLORS}
                    xAxisTickIndicator={lineGraphIntervals}
                    includeYear={dateRangeDifferent > 12}
                    isExporting={isExporting}
                  />
                </>
              )}
              {errorMessageForGraph && (
                <div>
                  <strong>Headcount by Period</strong>&nbsp;{errorMessageForGraph}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {charts.GLOBAL_COSTS && (
        <>
          <div className='u-padding-top relative'>
            <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large overflow-y position-relative'>
              {!chartLogic.SIDE_BAR_FILTER_OPTIONS && <SectionHeading extraHuge huge={false} text={'Totals by Country'} />}
              {chartLogic.SIDE_BAR_FILTER_OPTIONS && (
                <div className='d-flex'>
                  <h2 className='u-margin-none u-text--xtra-huge u-weight--bold'>Total {chartLogic.SIDE_BARCHART_HEADER} Costs by</h2>
                  <div className='u-margin-left-small u-margin-bottom u-min-width-200'>
                    <Select
                      className='c-custom-select c-inline-select-dropdown'
                      value={sideBarQuery}
                      labelClassName='c-label'
                      placeholder='Select'
                      options={chartLogic.SIDE_BAR_FILTER_OPTIONS}
                      clearable={false}
                      onChange={onSideBarFilterChange}
                    />
                  </div>
                </div>
              )}
              <div className='headcount-sort position-absolute' onClick={() => setHeadCountSort(hasHeadCountSortOpened === 'desc' ? 'asc' : 'desc')}>
                <span className={`icon icon--chart-sort-icon ${hasHeadCountSortOpened === 'desc' ? 'sort-opened' : ''}`} />
              </div>
              <SideBarChartWithFlags
                data={sideBarService.calculateTotalsByQueryType(sideBarQuery).sort(sortByAxis)}
                xKey={chartLogic.SIDE_BARCHART_AXIS.xAxis}
                yKey={chartLogic.SIDE_BARCHART_AXIS.yAxis}
                queryKey={sideBarQuery}
                isExporting={isExporting}
              />
            </div>
          </div>
        </>
      )}
      {charts.GLOBAL_COSTS_PER_ELEMENT && (
        <>
          <div className='u-padding-top relative'>
            <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large overflow-y position-relative'>
              {!chartLogic.SIDE_BAR_FILTER_OPTIONS && <SectionHeading extraHuge huge={false} text={'Totals by Country'} />}
              {chartLogic.SIDE_BAR_FILTER_OPTIONS_FIRST && chartLogic.SIDE_BAR_FILTER_OPTIONS && (
                <div className='d-flex'>
                  <span className='u-margin-none u-text--xtra-huge u-weight--bold'>Total</span>
                  <div className='u-margin-left-small u-margin-bottom u-min-width-250'>
                    <Select
                      className='c-custom-select c-inline-select-dropdown u-text--normal'
                      value={sideBarQueryFirst}
                      labelClassName='c-label'
                      placeholder='Select'
                      options={chartLogic.SIDE_BAR_FILTER_OPTIONS_FIRST.filter((o) => namedFilters.termCategory.includes(o.value))}
                      clearable={false}
                      onChange={onFirstFilterChange}
                    />
                  </div>
                  <span className='u-margin-left-small u-text--xtra-huge u-weight--bold'>by</span>
                  <div className='u-margin-left-small u-margin-bottom u-min-width-200'>
                    <Select
                      className='c-custom-select c-inline-select-dropdown u-text--normal'
                      value={sideBarQuery}
                      labelClassName='c-label'
                      placeholder='Select'
                      options={chartLogic.SIDE_BAR_FILTER_OPTIONS}
                      clearable={false}
                      onChange={onSideBarFilterChange}
                    />
                  </div>
                </div>
              )}
              <div className='headcount-sort position-absolute' onClick={() => setHeadCountSort(hasHeadCountSortOpened === 'desc' ? 'asc' : 'desc')}>
                <span className={`icon icon--chart-sort-icon ${hasHeadCountSortOpened === 'desc' ? 'sort-opened' : ''}`} />
              </div>
              <SideBarChartWithFlags
                data={sideBarService.calculateTotalsByQueryElementAndType(sideBarQueryFirst, sideBarQuery).sort(sortByAxis)}
                xKey={chartLogic.SIDE_BARCHART_AXIS.xAxis}
                yKey={chartLogic.SIDE_BARCHART_AXIS.yAxis}
                queryKey={sideBarQuery}
                isExporting={isExporting}
              />
            </div>
          </div>
        </>
      )}

      <div className='d-grid d-grid--gap-4 d-grid--cols-2 d-grid--tablet-cols12 d-grid--mobile-cols-1 '>
        {charts.HEADCOUNT_BY_WORKER_TYPE && (
          <div className='u-padding-top'>
            <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large overflow-y workforce-count-pie'>
              <SectionHeading fullWidth extraHuge huge={false} text={'Headcount by Worker Type'} />
              <BasicPieChart
                data={workForceDetailService.totalWorkforceByTypeForPieChart}
                centerSubTitle='Headcount'
                centerHeader={workForceDetailService.totalWorkCount}
                colors={chartLogic.CHART_DATAKEYS_COLORS}
                isExporting={isExporting}
              />
            </div>
          </div>
        )}
        {charts.EMPLOYEE_SELF_SERVICE && (
          <div className='u-padding-top'>
            <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large overflow-y ess-activity-pie'>
              <SectionHeading fullWidth extraHuge huge={false} text={'Employee Self Service'} />
              <BasicPieChart
                data={pieChartService.essActivityPieData}
                centerSubTitle='Headcount'
                centerHeader={pieChartService.totalWorkCount}
                colors={{ Active: '#91d4e8', Inactive: '#e293a7' }}
                isExporting={isExporting}
              />
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
    </div>
  )
}

ReportingAnalyticsView.propTypes = {
  tileService: PropTypes.object,
  sideBarService: PropTypes.object,
  pieChartService: PropTypes.object,
  workForceDetailService: PropTypes.object,
  charts: PropTypes.object,
  chartLogic: PropTypes.object,
  setHeadCountSort: PropTypes.func,
  hasHeadCountSortOpened: PropTypes.string,
  noFromDateSet: PropTypes.bool,
  isExporting: PropTypes.bool,
  onSideBarFilterChange: PropTypes.func,
  onFirstFilterChange: PropTypes.func,
  dateRangeDifferent: PropTypes.number,
  sideBarQuery: PropTypes.any,
  sideBarQueryFirst: PropTypes.any,
  useDefaultSideBarSelect: PropTypes.any,
  lineGraphIntervals: PropTypes.number,
  report: PropTypes.object,
  fetchedErrors: PropTypes.array,
  data: PropTypes.array,
}

export default ReportingAnalyticsView
