/**
 * Common Reducer handlers and state shape
 *
 * Most of the Entities have `fetch`, `receive` action creators
 * So this module gives the basic Reducer structure and handlers.
 *
 * It's extendable, so you can easily add your custom handlers.
 *
 * @param {Object} initialState - Initial state of the Reducer.
 * If passed, it will extend (or overwrite) the default one `defaultState` of this module
 * @param {Object} handlers - Add custom Reducer action handlers.
 * If passed, it will extend (or overwrite) the default `getHandlers()` of this module
 * @example {
 *  COUNTRIES_INVALIDATE: (state, action) => ({...state, invalidated: true})
 * }
 * @returns {function(*=, *=)}
 */
function _createReducer (initialState, handlers) {
  return (state = initialState, action) => {
    const handler = handlers[action.type]
    return handler ? handler(state, action) : state
  }
}

export function createReducer (initialState, handlers) {
  return _createReducer(initialState, handlers)
}
