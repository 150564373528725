import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import TermCategories from 'components/TermCategories'

const FormFields = ({ ...props }) => {
  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <Form {...props} showSubmitBtn colorClassBtn={'c-btn--curious'} btnText={'Submit'}>
          <Field
            name='name'
            label='Name'
            labelClassName='c-label'
            component={InputField}
            type='text' />
          {props.categories && <TermCategories {...props} />}
        </Form>
      </div>
    </div>
  )
}

export default FormFields
