import React from 'react'
import SectionHeading from 'components/SectionHeading'
import TermsFormFields from './TermsFormFields'

const TermCreate = ({ ...props }) => {
  return (
    <div>
      <SectionHeading text='Create terms' />
      <div className='o-layout'>
        <div className='o-layout__item u-1/1 u-1/2@tablet u-1/4@desktop'>
          <TermsFormFields {...props} />
        </div>
      </div>
    </div>
  )
}

export default TermCreate
