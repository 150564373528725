import createSelector from 'utils/createSelector'
import { makeFilteredIdsByFilter } from './filters'

export const getFilteredIdsByFilter = makeFilteredIdsByFilter('editors')

export const getEditors = createSelector(
  ({ Editor }) => {
    return Editor.all()
      .toRefArray().map(editor => ({
        ...editor
      }))
  })

export const getFilteredEditors = createSelector(getFilteredIdsByFilter,
  ({ Editor }, filteredIds) => {
    return filteredIds.map(id => Editor.withId(id).ref)
  }
)
