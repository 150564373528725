import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import PasswordInputField from 'components/form/PasswordInputField'
import PasswordPolicyMessage from 'components/auth/PasswordPolicyMessage'
import RequestCodeView from 'components/RequestCodeView'

class ResetPasswordConfirmationView extends React.Component {
  render () {
    const { is2FAenabled, confirmationToken, onSubmit, error, getNewCode } = this.props

    return <>
      <div className=' o-grid o-grid--center'>
        <PasswordPolicyMessage />
      </div>
      <div className=' o-grid o-grid--center'>
        <Form onSubmit={onSubmit} showSubmitBtn btnText='Send' colorClassBtn='c-btn--curious' btnFullWidth classNames='c-btn--rounded' {...this.props}>
          <div className='o-form-group o-block o-block--left'>
            <Field
              name='password'
              label='New Password'
              component={PasswordInputField}
              type='password'
              autoFocus
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
              showPasswordStrengthBox
            />
          </div>
          <div className='o-form-group o-block o-block--left u-padding-top-small'>
            <Field
              name='passwordConfirmation'
              label='Confirm New Password'
              component={PasswordInputField}
              type='password'
              className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
              labelClassName='c-label u-text--curious'
              passwordField
            />
          </div>
          {
            is2FAenabled
              ? <div className='o-form-group o-block o-block--left u-padding-top-small'>
                <Field
                  name='authCode'
                  label='Code'
                  component={InputField}
                  type='text'
                  className='c-input c-input--rounded c-input--alt u-margin-bottom-tiny'
                  labelClassName='c-label u-text--curious'
                />
              </div>
              : null
          }
          <div className='o-form-group u-text--center'>
            {error && <div className='error'>{error}</div>}
          </div>
        </Form>
      </div>
      {
        is2FAenabled
          ? <RequestCodeView gridClassNames='u-padding-top' onClick={() => getNewCode(confirmationToken)} textClassNames=' u-text--medium' />
          : null
      }
    </>
  }
}

ResetPasswordConfirmationView.propTypes = {
  confirmationToken: PropTypes.string,
  is2FAenabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  getNewCode: PropTypes.func,
}

export default ResetPasswordConfirmationView
