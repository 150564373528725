import qs from 'qs'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

export const createFilter = (params, name) => {
  const sanitized = omitBy(params, isNil)
  // It's important to use `{ arrayFormat: 'indices' }`, because the order (index) of passed SORTING params matters.
  const uri = qs.stringify(sanitized, { arrayFormat: 'indices' })
  return {
    params: sanitized,
    uri,
    name: name || uri
  }
}
