/* eslint-disable jsx-quotes */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const PADLOCK_ICONS_TYPES = {
  padlockLocked: 'padlockLocked',
  padlockUnlocked: 'padlockUnlocked',
  padlockLockedBigDisabled: 'padlockLockedBigDisabled',
  padlockUnlockedBigDisabled: 'padlockUnlockedBigDisabled',
  padlockLockedBig: 'padlockLockedBig',
  padlockUnlockedBig: 'padlockUnlockedBig',
}
const defaultPadlockColor = '#5292dd'

export const PadlockIconLocked = (props) => {
  const { width = 14, height = 18, fill = defaultPadlockColor, className = '' } = props
  return (
    <svg className={`icon-padlock-locked ${className}`} width={width} height={height} viewBox='0 0 14 18' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.8 10.7778C7.8 10.3359 7.44183 9.97778 7 9.97778C6.55817 9.97778 6.2 10.3359 6.2 10.7778H7.8ZM6.2 13.4444C6.2 13.8863 6.55817 14.2444 7 14.2444C7.44183 14.2444 7.8 13.8863 7.8 13.4444H6.2ZM6.14286 1.8H7.85714V0.2H6.14286V1.8ZM9.62857 3.66667V6.33333H11.2286V3.66667H9.62857ZM10.4286 5.53333H3.57143V7.13333H10.4286V5.53333ZM4.37143 6.33333V3.66667H2.77143V6.33333H4.37143ZM12.2 9V14.3333H13.8V9H12.2ZM10.4286 16.2H3.57143V17.8H10.4286V16.2ZM1.8 14.3333V9H0.2V14.3333H1.8ZM3.57143 16.2C2.62027 16.2 1.8 15.3919 1.8 14.3333H0.2C0.2 16.2202 1.68227 17.8 3.57143 17.8V16.2ZM12.2 14.3333C12.2 15.3919 11.3797 16.2 10.4286 16.2V17.8C12.3177 17.8 13.8 16.2202 13.8 14.3333H12.2ZM10.4286 7.13333C11.3797 7.13333 12.2 7.9414 12.2 9H13.8C13.8 7.11308 12.3177 5.53333 10.4286 5.53333V7.13333ZM3.57143 5.53333C1.68227 5.53333 0.2 7.11308 0.2 9H1.8C1.8 7.9414 2.62027 7.13333 3.57143 7.13333V5.53333ZM7.05714 10.7778C7.05714 10.8119 7.04448 10.8362 7.03022 10.851C7.01645 10.8653 7.00625 10.8667 7 10.8667V12.4667C7.94239 12.4667 8.65714 11.6829 8.65714 10.7778H7.05714ZM7 10.8667C6.99375 10.8667 6.98355 10.8653 6.96978 10.851C6.95552 10.8362 6.94286 10.8119 6.94286 10.7778H5.34286C5.34286 11.6829 6.05761 12.4667 7 12.4667V10.8667ZM6.94286 10.7778C6.94286 10.7436 6.95552 10.7193 6.96978 10.7045C6.98355 10.6903 6.99375 10.6889 7 10.6889V9.08889C6.05761 9.08889 5.34286 9.8727 5.34286 10.7778H6.94286ZM7 10.6889C7.00625 10.6889 7.01645 10.6903 7.03022 10.7045C7.04448 10.7193 7.05714 10.7436 7.05714 10.7778H8.65714C8.65714 9.8727 7.94239 9.08889 7 9.08889V10.6889ZM6.2 10.7778V13.4444H7.8V10.7778H6.2ZM7.85714 1.8C8.8083 1.8 9.62857 2.60806 9.62857 3.66667H11.2286C11.2286 1.77975 9.7463 0.2 7.85714 0.2V1.8ZM6.14286 0.2C4.2537 0.2 2.77143 1.77975 2.77143 3.66667H4.37143C4.37143 2.60806 5.1917 1.8 6.14286 1.8V0.2Z' />
    </svg>
  )
}

export const PadlockIconUnlocked = (props) => {
  const { width = 19, height = 18, fill = defaultPadlockColor, className = '' } = props
  return (
    <svg className={`icon-padlock-unlocked ${className}`} width={width} height={height} viewBox='0 0 19 18' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.57143 6.33333V7.13333H3.57143L3.57143 6.33333ZM7.8 10.7778C7.8 10.3359 7.44183 9.97777 7 9.97777C6.55817 9.97777 6.2 10.3359 6.2 10.7778H7.8ZM6.2 13.4444C6.2 13.8863 6.55817 14.2444 7 14.2444C7.44183 14.2444 7.8 13.8863 7.8 13.4444H6.2ZM10.4286 6.33333V5.53333H10.4286L10.4286 6.33333ZM3.57143 7.13333L10.4286 7.13333L10.4286 5.53333L3.57143 5.53333L3.57143 7.13333ZM12.2 8.99999V14.3333H13.8V8.99999H12.2ZM10.4286 16.2H3.57143V17.8H10.4286V16.2ZM1.8 14.3333V8.99999H0.2V14.3333H1.8ZM7.05714 10.7778C7.05714 10.8119 7.04448 10.8362 7.03022 10.851C7.01645 10.8653 7.00625 10.8667 7 10.8667V12.4667C7.94239 12.4667 8.65714 11.6828 8.65714 10.7778H7.05714ZM7 10.8667C6.99375 10.8667 6.98355 10.8653 6.96978 10.851C6.95552 10.8362 6.94286 10.8119 6.94286 10.7778H5.34286C5.34286 11.6828 6.05761 12.4667 7 12.4667V10.8667ZM6.94286 10.7778C6.94286 10.7436 6.95552 10.7193 6.96978 10.7045C6.98355 10.6903 6.99375 10.6889 7 10.6889V9.08888C6.05761 9.08888 5.34286 9.8727 5.34286 10.7778H6.94286ZM7 10.6889C7.00625 10.6889 7.01645 10.6903 7.03022 10.7045C7.04448 10.7193 7.05714 10.7436 7.05714 10.7778H8.65714C8.65714 9.8727 7.94239 9.08888 7 9.08888V10.6889ZM6.2 10.7778V13.4444H7.8V10.7778H6.2ZM3.57143 16.2C2.62027 16.2 1.8 15.3919 1.8 14.3333H0.2C0.2 16.2202 1.68227 17.8 3.57143 17.8V16.2ZM12.2 14.3333C12.2 15.3919 11.3797 16.2 10.4286 16.2V17.8C12.3177 17.8 13.8 16.2202 13.8 14.3333H12.2ZM10.4286 7.13333C11.3797 7.13333 12.2 7.94139 12.2 8.99999H13.8C13.8 7.11308 12.3177 5.53333 10.4286 5.53333V7.13333ZM3.57143 5.53333C1.68227 5.53333 0.2 7.11308 0.2 8.99999H1.8C1.8 7.94139 2.62027 7.13333 3.57143 7.13333V5.53333Z' />
      <path d='M13.5714 1L13.5714 1.8H13.5714L13.5714 1ZM15.6507 1V0.2H15.6507L15.6507 1ZM10.2 6.33334C10.2 6.77517 10.5582 7.13334 11 7.13334C11.4418 7.13334 11.8 6.77517 11.8 6.33334H10.2ZM17.2 6.33334C17.2 6.77517 17.5582 7.13334 18 7.13334C18.4418 7.13334 18.8 6.77517 18.8 6.33334H17.2ZM13.5714 1.8L15.6507 1.8L15.6507 0.2L13.5714 0.200003L13.5714 1.8ZM11.8 6.33334V3.66667H10.2V6.33334H11.8ZM13.5714 0.200003C11.6823 0.200004 10.2 1.77975 10.2 3.66667H11.8C11.8 2.60807 12.6203 1.8 13.5714 1.8L13.5714 0.200003ZM15.6507 1.8C16.3227 1.8 16.6647 2.05479 16.8672 2.33915C17.099 2.66446 17.2 3.11078 17.2 3.5H18.8C18.8 2.88922 18.651 2.08554 18.1704 1.41085C17.6606 0.695212 16.828 0.2 15.6507 0.2V1.8ZM17.2 3.5V6.33334H18.8V3.5H17.2Z' />
    </svg>
  )
}

export const PadlockBlockedIcon = (props) => {
  const { width = 76, height = 99, fill = 'none', className = '' } = props
  return (
    <svg className={`padlock-blocked ${className}`} width={width} height={height} viewBox='0 0 76 99' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5916 18.169C18.5916 11.8783 23.7094 6.76056 30.0001 6.76056C36.2908 6.76056 41.4085 11.8783 41.4085 18.169V29.5775H48.1691V18.169C48.1691 8.15053 40.0185 0 30.0001 0C19.9816 0 11.8311 8.15053 11.8311 18.169V29.5775H18.5916V18.169Z'
        fill='#DBD6E2'
      />
      <path
        opacity='0.6'
        d='M30 29.5774V45.8756C33.7278 45.8756 36.7606 48.9084 36.7606 52.6362C36.7606 55.133 35.399 57.3163 33.3803 58.4872V67.0336H30V83.6619H55C57.7614 83.6619 60 81.4233 60 78.6619V34.5774C60 31.816 57.7614 29.5774 55 29.5774H30Z'
        fill='#6E647D'
      />
      <path
        opacity='0.4'
        d='M26.6197 67.0336V58.4872C24.601 57.3165 23.2394 55.133 23.2394 52.6362C23.2394 48.9084 26.2722 45.8756 30 45.8756V29.5774H5C2.23858 29.5774 0 31.816 0 34.5774V78.6619C0 81.4233 2.23858 83.6619 5 83.6619H30V67.0336H26.6197Z'
        fill='#6E647D'
      />
      <path
        d='M23.2393 52.6361C23.2393 55.1329 24.6008 57.3164 26.6195 58.4871V67.0335H33.3801V58.4871C35.3988 57.3164 36.7604 55.1329 36.7604 52.6361C36.7604 48.9083 33.7276 45.8755 29.9998 45.8755C26.272 45.8755 23.2393 48.9083 23.2393 52.6361Z'
        fill='#DBD6E2'
      />
      <path
        d='M34.5 77.8452C34.5 66.6274 43.6272 57.5 54.8452 57.5C66.0632 57.5 75.1905 66.6272 75.1905 77.8452C75.1905 89.0632 66.063 98.1905 54.8452 98.1905C43.6274 98.1905 34.5 89.063 34.5 77.8452Z'
        fill='#F5F4F8'
        stroke='#EB3528'
      />
      <line x1='61.5562' y1='71.1307' x2='49.5353' y2='83.1516' stroke='#EB3528' strokeWidth='5' strokeLinecap='round' />
      <line x1='49.5355' y1='71.3733' x2='61.5564' y2='83.3941' stroke='#EB3528' strokeWidth='5' strokeLinecap='round' />
    </svg>
  )
}

export const PadlockLockedBig = (props) => {
  const { width = 60, height = 84, fill = 'none', className = '' } = props
  return (
    <svg className={`padlock-locked-modal ${className}`} width={width} height={height} viewBox='0 0 60 84' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5916 18.169C18.5916 11.8783 23.7094 6.76056 30.0001 6.76056C36.2908 6.76056 41.4085 11.8783 41.4085 18.169V29.5775H48.1691V18.169C48.1691 8.15053 40.0185 0 30.0001 0C19.9816 0 11.8311 8.15053 11.8311 18.169V29.5775H18.5916V18.169Z'
        fill='var(--ps-padlock-top)'
      />
      <path
        d='M30 29.5775V45.8756C33.7278 45.8756 36.7606 48.9084 36.7606 52.6362C36.7606 55.1331 35.399 57.3164 33.3803 58.4873V67.0337H30V83.662H55C57.7614 83.662 60 81.4234 60 78.662V34.5775C60 31.816 57.7614 29.5775 55 29.5775H30Z'
        fill='var(--ps-padlock-right)'
      />
      <path
        d='M26.6197 67.0337V58.4873C24.601 57.3165 23.2394 55.1331 23.2394 52.6362C23.2394 48.9084 26.2722 45.8756 30 45.8756V29.5775H5C2.23858 29.5775 0 31.816 0 34.5775V78.662C0 81.4234 2.23858 83.662 5 83.662H30V67.0337H26.6197Z'
        fill='var(--ps-padlock-left)'
      />
      <path
        d='M23.2395 52.6362C23.2395 55.133 24.6011 57.3165 26.6198 58.4873V67.0336H33.3803V58.4873C35.399 57.3165 36.7606 55.133 36.7606 52.6362C36.7606 48.9084 33.7278 45.8756 30.0001 45.8756C26.2723 45.8756 23.2395 48.9084 23.2395 52.6362Z'
        fill='var(--ps-padlock-top)'
      />
    </svg>
  )
}

export const PadlockUnlockedBig = (props) => {
  const { width = 78, height = 83, fill = 'none', className = '' } = props
  return (
    <svg className={`padlock-unlocked-modal ${className}`} width={width} height={height} viewBox='0 0 78 83' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M47.9461 18.0252C47.9461 11.7843 53.0233 6.70707 59.2643 6.70707C65.5052 6.70707 70.5824 11.7843 70.5824 18.0252V29.3434H77.2895V18.0252C77.2895 8.08604 69.2035 0 59.2643 0C49.3251 0 41.239 8.08604 41.239 18.0252V29.3434H47.9461V18.0252Z'
        fill='var(--ps-padlock-top)'
      />
      <path
        d='M29.7626 29.3434V45.5127C33.4609 45.5127 36.4696 48.5215 36.4696 52.2197C36.4696 54.6968 35.1188 56.8629 33.1161 58.0245V66.5033H29.7626V83H54.5252C57.2866 83 59.5252 80.7614 59.5252 78V34.3435C59.5252 31.582 57.2866 29.3434 54.5252 29.3434H29.7626Z'
        fill='var(--ps-padlock-right)'
      />
      <path
        d='M26.4091 66.5033V58.0245C24.4064 56.8631 23.0556 54.6968 23.0556 52.2197C23.0556 48.5215 26.0643 45.5127 29.7626 45.5127V29.3434H5C2.23857 29.3434 0 31.582 0 34.3434V78C0 80.7614 2.23858 83 5 83H29.7626V66.5033H26.4091Z'
        fill='var(--ps-padlock-left)'
      />
      <path
        d='M23.0555 52.2196C23.0555 54.6967 24.4063 56.8629 26.4091 58.0244V66.5032H33.1161V58.0244C35.1189 56.8629 36.4697 54.6967 36.4697 52.2196C36.4697 48.5214 33.4609 45.5126 29.7626 45.5126C26.0643 45.5126 23.0555 48.5214 23.0555 52.2196Z'
        fill='var(--ps-padlock-top)'
      />
    </svg>
  )
}

export const PadlockUnlockedBigDisabled = (props) => {
  const { width = 78, height = 83, fill = 'none', className = '' } = props
  return (
    <svg className={`padlock-unlocked-modal ${className}`} width={width} height={height} viewBox='0 0 78 83' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M47.9461 18.0252C47.9461 11.7843 53.0233 6.70707 59.2643 6.70707C65.5052 6.70707 70.5824 11.7843 70.5824 18.0252V29.3434H77.2895V18.0252C77.2895 8.08604 69.2035 0 59.2643 0C49.3251 0 41.239 8.08604 41.239 18.0252V29.3434H47.9461V18.0252Z'
        fill='var(--ps-padunlock-top)'
      />
      <path
        opacity='0.6'
        d='M30 29.5774V45.8756C33.7278 45.8756 36.7606 48.9084 36.7606 52.6362C36.7606 55.133 35.399 57.3163 33.3803 58.4872V67.0336H30V83.6619H55C57.7614 83.6619 60 81.4233 60 78.6619V34.5774C60 31.816 57.7614 29.5774 55 29.5774H30Z'
        fill='var(--ps-padunlock-right)'
      />
      <path
        opacity='0.4'
        d='M26.6197 67.0336V58.4872C24.601 57.3165 23.2394 55.133 23.2394 52.6362C23.2394 48.9084 26.2722 45.8756 30 45.8756V29.5774H5C2.23858 29.5774 0 31.816 0 34.5774V78.6619C0 81.4233 2.23858 83.6619 5 83.6619H30V67.0336H26.6197Z'
        fill='var(--ps-padunlock-left)'
      />
      <path
        d='M23.2393 52.6361C23.2393 55.1329 24.6008 57.3164 26.6195 58.4871V67.0335H33.3801V58.4871C35.3988 57.3164 36.7604 55.1329 36.7604 52.6361C36.7604 48.9083 33.7276 45.8755 29.9998 45.8755C26.272 45.8755 23.2393 48.9083 23.2393 52.6361Z'
        fill='var(--ps-padunlock-top)'
      />
    </svg>
  )
}

export const PadlockLockedBigDsabledd = (props) => {
  const { width = 60, height = 84, fill = 'none', className = '' } = props
  return (
    <svg className={className} width={width} height={height} viewBox='0 0 60 84' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5916 18.169C18.5916 11.8783 23.7094 6.76056 30.0001 6.76056C36.2908 6.76056 41.4085 11.8783 41.4085 18.169V29.5775H48.1691V18.169C48.1691 8.15053 40.0185 0 30.0001 0C19.9816 0 11.8311 8.15053 11.8311 18.169V29.5775H18.5916V18.169Z'
        fill='var(--ps-padunlock-top)'
      />
      <path
        opacity='0.6'
        d='M30 29.5774V45.8756C33.7278 45.8756 36.7606 48.9084 36.7606 52.6362C36.7606 55.133 35.399 57.3163 33.3803 58.4872V67.0336H30V83.6619H55C57.7614 83.6619 60 81.4233 60 78.6619V34.5774C60 31.816 57.7614 29.5774 55 29.5774H30Z'
        fill='var(--ps-padunlock-right)'
      />
      <path
        opacity='0.4'
        d='M26.6197 67.0336V58.4872C24.601 57.3165 23.2394 55.133 23.2394 52.6362C23.2394 48.9084 26.2722 45.8756 30 45.8756V29.5774H5C2.23858 29.5774 0 31.816 0 34.5774V78.6619C0 81.4233 2.23858 83.6619 5 83.6619H30V67.0336H26.6197Z'
        fill='var(--ps-padunlock-left)'
      />
      <path
        d='M23.2393 52.6361C23.2393 55.1329 24.6008 57.3164 26.6195 58.4871V67.0335H33.3801V58.4871C35.3988 57.3164 36.7604 55.1329 36.7604 52.6361C36.7604 48.9083 33.7276 45.8755 29.9998 45.8755C26.272 45.8755 23.2393 48.9083 23.2393 52.6361Z'
        fill='var(--ps-padunlock-top)'
      />
    </svg>
  )
}

export const PadlockIcon = ({ type, ...rest }) => {
  switch (type) {
  case PADLOCK_ICONS_TYPES.padlockLocked:
    return <PadlockIconLocked {...rest} />
  case PADLOCK_ICONS_TYPES.padlockUnlocked:
    return <PadlockIconUnlocked {...rest} />
  case PADLOCK_ICONS_TYPES.padlockLockedBig:
    return <PadlockLockedBig {...rest} />
  case PADLOCK_ICONS_TYPES.padlockUnlockedBig:
    return <PadlockUnlockedBig {...rest} />
  case PADLOCK_ICONS_TYPES.padlockLockedBigDisabled:
    return <PadlockLockedBigDsabledd {...rest} />
  case PADLOCK_ICONS_TYPES.padlockUnlockedBigDisabled:
    return <PadlockUnlockedBigDisabled {...rest} />
  default:
    return null
  }
}

const padlockProps = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
}

PadlockIcon.propTypes = {
  type: PropTypes.string,
}

PadlockIconLocked.propTypes = padlockProps
PadlockIconUnlocked.propTypes = padlockProps
PadlockBlockedIcon.propTypes = padlockProps
PadlockLockedBig.propTypes = padlockProps
PadlockUnlockedBig.propTypes = padlockProps
PadlockLockedBigDsabledd.propTypes = padlockProps
PadlockUnlockedBigDisabled.propTypes = padlockProps
