import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/officeLocations'

class OfficeLocation extends CRUDModel {}
OfficeLocation.modelName = 'OfficeLocation'
OfficeLocation.actionTypes = actionTypes

OfficeLocation.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'officeLocations'),
}

export default OfficeLocation
