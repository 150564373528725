import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { reduxForm } from 'redux-form'
import { getInitialValues } from 'redux/selectors/documentUsers'
import { getEmployeeWithSharedDocument } from 'redux/selectors/employees'
import { invalidateDocuments } from 'redux/actions/document'
import { invalidateDocumentUsers, updateDocumentUsers } from 'redux/actions/documentUsers'
import { invalidateDocumentLogs } from 'redux/actions/documentLogs'
import { createFilter } from '../../utils/redux/filter'
import { getFilteredDocuments } from 'redux/selectors/document'
import { isCot } from 'redux/selectors/auth'
import createDocumentFilter from 'redux/filters/createDocumentFilter'
import DocumentTabs from 'components/documents/DocumentTabs'
import createEmployeeFilter from '../../redux/filters/createEmployeeFilter'
import { buildShareBtnLabel } from '../../redux/helpers/buildShareBtnLabel'

const mapStateToProps = (state, props) => {
  const { documentId, documentTenant, filters, type } = props

  let employee = getEmployeeWithSharedDocument(state, {
    employeeId: props.match.params.employeeId,
    documentId,
    documentTenant
  })

  const keyPeopleInitialValues = getInitialValues(state, {
    users: [employee],
    userType: 'employeeUser'
  })

  const filteredDocuments = getFilteredDocuments(state, { filter: filters.document.name })
  const document = filteredDocuments[0]

  return {
    btnText: buildShareBtnLabel(type),
    showSubmitBtn: true,
    keyPeopleWithinCompanyUsers: [employee],
    initialValues: {
      ...keyPeopleInitialValues,
    },
    isCot: isCot(state),
    isOwner: document && document.extraData && document.extraData.isOwner
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { documentId, documentTenant, onShared } = props
  return {
    onSubmit: data => (
      dispatch(updateDocumentUsers(data, documentId, documentTenant))
        .then(() => {
          onShared()
          dispatch(invalidateDocuments())
          dispatch(invalidateDocumentUsers())
          dispatch(invalidateDocumentLogs())
        })
    )
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'shareNonVendorFiles'
})(DocumentTabs))

export default Fetcher(Container, [
  createEmployeeFilter({ filterName: 'id' }),
  {
    name: 'documentUsers',
    params: [{
      _computed: {
        filter: (state, { documentId, documentTenant }) => createFilter({ documentId, documentTenant })
      },
      disableObsoleteFlow: true
    }]
  },
  createDocumentFilter({ filterNames: ['id'] }),
])
