import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, submit } from 'redux-form'
import InnerWrapper from 'layouts/InnerWrapper'
import NotificationListContainer from '../containers/NotificationListContainer'
import NotificationFormEditContainer from '../containers/NotificationFormEditContainer'
import { onChangeSubmit } from 'utils/form'

class RouteView extends React.Component {
  render () {
    const { dispatch } = this.props
    return <InnerWrapper>
      <NotificationFormEditContainer />
      <NotificationListContainer
        onRead={(notificationId) => {
          dispatch(change('notificationEditForm', 'id', notificationId))
          onChangeSubmit(() => dispatch(submit('notificationEditForm')))()
        }}
        {...this.props}
      />
    </InnerWrapper>
  }
}

RouteView.propTypes = {
  dispatch: PropTypes.func
}

export default connect()(RouteView)
