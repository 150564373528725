import createSelector from 'utils/createSelector'
import moment from 'moment'

const getEmployeeId = (state, props) => parseInt(props.employeeId)

export const getAnnualSalariesByEmployee = createSelector(getEmployeeId, ({ AnnualSalary, EmployeeSystemUser }, employeeId) => {
  return AnnualSalary.filter({ employee: employeeId })
    .toModelArray()
    .map((salary) => ({
      ...salary.ref,
      // It's a quick fix.
      // By design, the BE should not return value here, if the currently logged user doesn't have
      // access to this EmployeeSystemUser. It should return `null`
      addedBy: salary.ref.addedBy ? `${salary.ref.addedBy.firstName} ${salary.ref.addedBy.lastName}` : 'Central Operations Team',
      dateAdded: moment(salary.dateAdded.date).format('DD/MM/YYYY'),
      effectiveDate: salary.effectiveDate ? moment(salary.effectiveDate.date).format('DD/MM/YYYY') : null,
    }))
    .reverse()
})
