import { createReducer } from 'utils/redux/reducer'
import { VENDOR_TENANT_SELECT } from '../actions/vendorTenant'

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer({
  url: null,
  schema: ''
}, {
  [VENDOR_TENANT_SELECT]: (state, { payload: { url, schema = '' } }) => ({ ...state, url, schema })
})
