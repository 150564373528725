import React from 'react'
import LeaveForm from './LeaveForm'

export const LeaveCreate = props => {
  if (props.isFetching) return <div>Loading...</div>

  return <LeaveForm {...props} />
}

export default LeaveCreate
