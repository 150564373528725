import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const SUBSIDIARIES_FETCH = 'SUBSIDIARIES_FETCH'
export const SUBSIDIARIES_RECEIVE = 'SUBSIDIARIES_RECEIVE'
export const SUBSIDIARIES_CREATE = 'SUBSIDIARIES_CREATE'
export const SUBSIDIARIES_UPDATE = 'SUBSIDIARIES_UPDATE'
export const SUBSIDIARIES_DELETE = 'SUBSIDIARIES_DELETE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: SUBSIDIARIES_FETCH,
  receive: SUBSIDIARIES_RECEIVE,
  create: SUBSIDIARIES_CREATE,
  update: SUBSIDIARIES_UPDATE,
  delete: SUBSIDIARIES_DELETE
}
const actionUtility = new ActionUtility(actionTypes, 'subsidiary', 'subsidiaries', 'Subsidiary')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchSubsidiariesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchSubsidiaries = actionUtility.fetchEntities
export const createSubsidiary = actionUtility.createEntity
export const updateSubsidiary = actionUtility.bulkUpdateEntity
export const deleteSubsidiary = actionUtility.deleteEntity
