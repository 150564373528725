import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import VendorRoute from './routes/Vendor'

export default (store) => ({
  path : '/vendors',
  indexRoute: { component: RouteView },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ VendorRoute(store) ]
})
