import React from 'react'
import CheckboxField from 'components/form/CheckboxField'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { Field } from 'redux-form'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'

class AssignCountryServices extends React.Component {
  /**
   * Render the table headers
   *
   * @return array
   */
  renderHeaders () {
    return [
      {
        Header: 'Service',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Type',
        accessor: 'serviceType',
        disableSortBy: true
      },
      {
        Header: 'Assign',
        accessor: 'assign',
        disableSortBy: true
      }
    ]
  }

  /**
   * Render the table rows
   *
   * @param array countryServices
   * @return array
   */
  renderRows (countryServices) {
    return countryServices.map(countryService => {
      return {
        name: countryService.service.name,
        serviceType: _.capitalize(countryService.service.type),
        assign: <Field
          type='checkbox'
          component={CheckboxField}
          formGroupClassName='u-margin-none'
          className='control__indicator__input'
          label=''
          name={`id-${countryService.id}`}
        />
      }
    })
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <Form {...this.props}>
      <div className='o-layout'>
        <div className='o-layout__item'>
          <PayslipTable
            wrapperClassName='u-1/1'
            tableElementClassName='u-margin-bottom-small'
            headings={this.renderHeaders()}
            data={this.renderRows(this.props.countryServices)}
          />
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          >
            Save
          </button>
        </div>
      </div>
    </Form>
  }
}

AssignCountryServices.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  countryServices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    service: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  }))
}

export default AssignCountryServices
