import React from 'react'
import PropTypes from 'prop-types'

export const EmployeeHeader = ({ employee: { firstname, surname, position, companyRef, id }, ...props }) => {
  return <div className='o-media o-grid o-grid--middle employee-header'>
    <div className='o-media__body employee-header-details line-height--20'>
      <span className='u-weight--bold u-text--large u-margin-right-small'>
        {firstname} {surname}
      </span>
      <span className='u-text--small u-weight--regular'>ID Number: {id}</span>

      <span className='o-block o-block--left u-text--normal u-weight--regular'>
        {position ? `${position} / ` : ''}
        {companyRef.code ? `${companyRef.code} ` : ''}
        {companyRef.name ? `${companyRef.name} / ` : ''}
        {companyRef.countryRef.name || ''}
      </span>
    </div>
  </div>
}

EmployeeHeader.propTypes = {
  employee: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    position: PropTypes.string,
    id: PropTypes.number.isRequired,
    companyRef: PropTypes.object
  })
}

export default EmployeeHeader
