import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const CURRENCIES_FETCH = 'CURRENCIES_FETCH'
export const CURRENCIES_RECEIVE = 'CURRENCIES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: CURRENCIES_FETCH,
  receive: CURRENCIES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'currencies', 'currencies', 'Currency')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCurrenciesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCurrencies = actionUtility.fetchEntities
