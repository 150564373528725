import React from 'react'
import PayElementsExpandable from './PayElementsExpandable'
import PayElementsNotExpandable from './PayElementsNotExpandable'

export default ({ isExpandable = false, ...rest }) => {
  const props = {
    fixedHeading: 'Add Values to Fixed Pay Elements',
    variableHeading: 'Choose Variable Pay Elements'
  }

  if (isExpandable) return <PayElementsExpandable {...props} {...rest} />

  return <PayElementsNotExpandable {...props} {...rest} />
}
