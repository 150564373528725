import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/noteShares'

class NoteShare extends CRUDModel {}
NoteShare.modelName = 'NoteShare'

NoteShare.fields = {
  id: attr()
}
NoteShare.actionTypes = actionTypes

export default NoteShare
