import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/services'

class Service extends CRUDModel {}
Service.modelName = 'Service'
Service.actionTypes = actionTypes

Service.fields = {
  id: attr(),
  name: attr()
}

export default Service
