import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { createTermSubCategory } from 'redux/actions/termSubCategories'
import { getSubCategoriesWithCategoryName } from '../selectors/termSubCategories'
import { getCategories } from 'redux/selectors/termCategories'
import SubCategoryCreate from '../components/SubCategoryCreate'

const mapStateToProps = (state) => {
  const { termSubCategories } = state

  return {
    categories: getCategories(state),
    subCategories: termSubCategories,
    termSubCategories: getSubCategoriesWithCategoryName(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(createTermSubCategory(data)).then(() => {
    dispatch(reset('subCategoryCreateForm'))
  })
})

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'subCategoryCreateForm'
})(SubCategoryCreate))

export default Fetcher(Container, ['termSubCategories', 'termCategories'])
