import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import store from 'store/store'
import PropTypes from 'prop-types'
import InitialDataContainer from 'containers/InitialDataContainer'
import ResetContainer from 'containers/ResetContainer'
import ModalContainer from '../containers/ModalContainer'
import { ToastContainer } from 'react-toastify'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LoginContainer from 'routes/Login/containers/LoginContainer'

import { createBrowserHistory } from 'history'
import ErrorContainer from 'routes/Error/containers/ErrorContainer'
import generateAllRoutes from 'utils/routeGenerator'
import PollingTracker from './PollingTracker'
import BrandApplier from './BrandApplier'
import { KeycloakProvider } from './Keycloak/KeycloakProvider'

const customHistory = createBrowserHistory()

class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    let routes = []
    this.props.routes.childRoutes.forEach((child, i) => {
      routes = generateAllRoutes(child, child.path, routes, customHistory)
    })
    return (
      <Provider store={this.props.store}>
        <PersistGate persistor={store.persistor}>
          <KeycloakProvider>
            <BrandApplier />
            <div style={{ height: '100%' }}>
              <ResetContainer>
                <InitialDataContainer>
                  <Router history={customHistory}>
                    <Switch>
                      {routes}
                      <Route exact path='/' component={LoginContainer} />
                      <Route component={ErrorContainer} />
                    </Switch>
                    <ToastContainer />
                  </Router>
                </InitialDataContainer>
              </ResetContainer>
              <ModalContainer />
              <PollingTracker />
            </div>
          </KeycloakProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
