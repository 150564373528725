import qs from 'qs'
import parseDomain from 'parse-domain'
import { createBrowserHistory } from 'history'
import { customTlds } from 'utils/config'
const browserHistory = createBrowserHistory()

export const getQuery = search => qs.parse(search, { ignoreQueryPrefix: true })

export const getParsedDomain = (hostname) => {
  hostname = hostname || window.location.hostname

  return parseDomain(hostname, { customTlds })
}

export const removeURLParameter = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?')
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0;) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
    return url
  } else {
    return url
  }
}

export const removeTasksURLParameters = () => {
  let sanitized = removeURLParameter(window.location.pathname, 'action')
  sanitized = removeURLParameter(sanitized, 'id')
  browserHistory.push(sanitized)
}

/**
 * Add `http://` protocol to a link, in case it is not already added.
 *
 * Please note, this won't work for links including `://`,
 * but it's good enough for our needs.
 *
 * @credits https://stackoverflow.com/a/3543258/4312466
 * @param {String} link
 * @returns {String}
 */
export const withHttp = link => link.indexOf('://') === -1 ? 'http://' + link : link

/**
 * Utility method to just handle the check if a string contains a
 * value in the old indexOf style
 * @param {string} str
 * @param {string} value
 * @returns {boolean}
 */
export const doesStringContainValue = (str, value) => str.indexOf(value) !== -1

/**
 * Method to determine if the pathname is a vendors path name.  Indicating
 * that the use is in the vendor section.  Generally to use when a component lives
 * outside of the react route (ie. polling tracker or brand applier)
 * @param {string} pathname
 * @returns {boolean}
 */
export const isPathnameVendor = (pathname) => doesStringContainValue(pathname, 'vendors')

/**
 * Method to determine if the pathname is a clients path name.  Indicating
 * that the use is a client section.  Generally to use when a component lives
 * outside of the react route (ie. polling tracker or brand applier)
 * @param {string} pathname
 * @returns {boolean}
 */
export const isPathnameClients = (pathname) => doesStringContainValue(pathname, 'clients')

/**
 * Method to determine if the pathname is a vendors path name.  Indicating
 * that the use is in the 'pure' route.  Generally to use when a component lives
 * outside of the react route (ie. polling tracker or brand applier)
 * @param {strung} pathname
 * @returns {boolean}
 */
export const isPathnamePure = (pathname) => doesStringContainValue(pathname, 'pure')
