import { isString } from 'utils/fnkit/typeChecks'
import { employeeFieldEnum } from 'utils/enums/employees/fieldEnum'

// Transform form field values 'yes'/'no' to true/false (Boolean)
// We need this transformers, because `react-select` has issues with Boolean `false `values.
// For example: If you select a `false` option and later your decide to clear the selected value with `x` icon,
// `false` option is still selected.
export const normalizeStringToBoolean = (formData, fields) => {
  const normalized = { ...formData }

  fields.forEach((field) => {
    if (normalized[field] === employeeFieldEnum.YES) {
      normalized[field] = true
    } else if (normalized[field] === employeeFieldEnum.NO || normalized[field] === null) {
      // Here we respects `null` option, because on react-select clear, a null value is set
      normalized[field] = false
    }
  })

  return normalized
}

// Transform field values true/false (Boolean) to yes/no
export const normalizeBooleanToString = (formData, fields) => {
  const normalized = { ...formData }

  fields.forEach((field) => {
    normalized[field] = normalized[field] ? employeeFieldEnum.YES : employeeFieldEnum.NO
  })

  return normalized
}

export const booleanToStringActiveInactive = (field) => {
  return field && field.toString() === employeeFieldEnum.TRUE ? employeeFieldEnum.ENABLED : employeeFieldEnum.DISABLED
}

/**
 * Transforms a string representation of a boolean or a specific match string into a boolean value.
 *
 * @param {string} fieldValue - The value to be checked, which can be of any type but is expected to be a string.
 * @param {string} trueValue - The string that represents the boolean value true.
 * @returns {boolean} Returns true if `fieldValue` is a string and matches `matchString`,
 * or if the string representation of `fieldValue` is 'true'.
 *
 * @example
 * transformStringValueToBoolean('yes', 'yes'); // Returns true
 * transformStringValueToBoolean('no', 'yes'); // Returns false
 * transformStringValueToBoolean(true, 'yes'); // Returns true
 * transformStringValueToBoolean(false, 'yes'); // Returns false
 */
export const transformStringValueToBoolean = (fieldValue, trueValue) => {
  const stringValue = fieldValue.toString()
  return isString(fieldValue) ? fieldValue === trueValue : stringValue === employeeFieldEnum.TRUE
}
