import React from 'react'
import KeyPeopleContainer from '../containers/KeyPeopleContainer'
import Modal from 'components/Modal'
import KeyPeopleFormCreateContainer from '../containers/KeyPeopleFormCreateContainer'
import KeyPeopleFormEditContainer from '../containers/KeyPeopleFormEditContainer'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { isEmpty } from 'utils/fnkit/typeChecks'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.editModal = React.createRef()
    this.createModal = React.createRef()

    this.state = {
      employeeId: 0,
      filters: {},
    }
  }

  setFilters = (filters) => {
    if (isEmpty(this.state.filters)) this.setState({ filters: filters })
  }

  componentDidMount () {
    const { search } = this.props.location
    const query = new URLSearchParams(search)
    if (includes(search, 'openEditModal')) {
      this.setState(
        {
          employeeId: query.get('employeeId'),
        },
        () => this.editModal.current.showModal()
      )
    }
  }

  render () {
    return (
      <>
        <Modal ref={this.createModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading='Key User Details'>
          <KeyPeopleFormCreateContainer onCreate={() => this.createModal.current.hideModal()} filters={this.state.filters} />
        </Modal>

        <Modal ref={this.editModal} className='c-modal c-modal--full c-modal--overflow-y' modalHeading='Key User Details'>
          <KeyPeopleFormEditContainer employeeId={this.state.employeeId} onEdit={() => this.editModal.current.hideModal()} />
        </Modal>

        <KeyPeopleContainer
          onCreate={() => this.createModal.current.showModal()}
          onEdit={(employeeId) => {
            this.setState({
              employeeId,
            })
            this.editModal.current.showModal()
          }}
          setFilters={this.setFilters}
          {...this.props}
        />
      </>
    )
  }
}

RouteView.propTypes = {
  location: PropTypes.object,
}

export default Fetcher(RouteView, ['companies'])
