import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/reportData'

class ReportData extends CRUDModel {}
ReportData.modelName = 'ReportData'
ReportData.actionTypes = actionTypes

ReportData.fields = {
  id: attr()
}

export default ReportData
