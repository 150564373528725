export const documentTextLabels = {
  link: 'Link new document',
  filesSection: 'Files Section',
  noDocumentsMessage: 'No files to display.',
}

export const documentTypeLabels = {
  inputs: 'Inputs',
  gtn: 'GTN',
  gtnImportStatus: 'GTN Import Status',
  gtnImport: 'GTN Import',
  payAndTax: 'Payslips and Tax Documents',
  gl: 'GL',
  payments: 'Payments',
  other: 'Other',
}

export const uploadMessages = {
  prefix: 'File successfully uploaded in',
  gtnSuffix: '',
}
