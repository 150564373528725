import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { resourcesTableHeadings } from 'utils/locales/resources.en'
import { dateTimePayslipTable, ResourcesExtraDataFullName, downloadNoRestrictions, cellRenderAction } from 'utils/tableDataFormatters'

export const generateResourcesTableHeadings = (
  isCotAdminUser,
  isCotUser,
  isCotOemUser,
  handleDownload,
  deleteButton,
  deleteButtonAction,
  historyButton,
  historyButtonAction
) => {
  const headings = [
    new TableHeading({
      accessor: 'fileName',
      heading: resourcesTableHeadings.name,
      className: 'text-left',
      columnClassName: 'text-left',
      onClick: handleDownload,
      Cell: downloadNoRestrictions,
    }),
    new TableHeading({
      accessor: 'uploadedOn',
      heading: resourcesTableHeadings.uploadedOn,
      className: 'text-left',
      columnClassName: 'text-left',
      disableSortBy: false,
      Cell: dateTimePayslipTable,
      setWidth: '200px',
    }),
  ]

  if (isCotUser) {
    headings.push(
      new TableHeading({
        accessor: 'extraData',
        heading: resourcesTableHeadings.uploadedBy,
        className: 'text-left',
        columnClassName: 'text-left',
        Cell: ResourcesExtraDataFullName,
        setWidth: '200px',
      })
    )
  }
  if (!isCotOemUser) {
    headings.push(
      new TableHeading({
        accessor: 'history',
        heading: '',
        className: 'text-right',
        columnClassName: 'text-right',
        Cell: cellRenderAction({ Actions: historyButton }),
        onClick: (row) => {
          historyButtonAction(row)
        },
        setWidth: '50px',
      })
    )
  }
  if (isCotAdminUser) {
    headings.push(
      new TableHeading({
        accessor: 'delete',
        heading: '',
        className: 'text-right',
        columnClassName: 'text-right',
        Cell: cellRenderAction({ Actions: deleteButton }),
        onClick: (row) => {
          deleteButtonAction(row)
        },
        setWidth: '50px',
      })
    )
  }

  return headings
}
