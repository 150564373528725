import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, reset, destroy } from 'redux-form'
import { updateEmployeeSystemUserPhone } from 'redux/actions/employeeSystemUsers'
import { updateCotUserPhone } from 'redux/actions/cotUsers'
import { updateVendorUserPhone } from 'redux/actions/vendorUsers'
import { showMessage } from 'redux/actions/modal'
import ChangePhoneNumberStepTwoView from '../components/ChangePhoneNumberStepTwoView'
import { isCot, isVendorInvolved } from 'redux/selectors/auth'
import { isCotDomain } from 'utils/url'

class ChangePhoneNumberStepTwoContainer extends React.Component {
  render () {
    return <ChangePhoneNumberStepTwoView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

const mapStateToProps = (state) => {
  let hostUrlCot = isCotDomain(state.config.apiUrl)
  let senderService = hostUrlCot ? state.config.senderServiceCot : state.config.senderService
  return {
    state,
    senderService: senderService,
    initialValues: {
      authCode: null
    }
  }
}

/**
 * This container is used by different roles / tenant modes,
 * therefore updating the user is accomplished through different API methods calls.
 *
 * So here we map user role to the appropriate API update method.
 * @param state
 * @return {*}
 */
const getUpdateAction = state => {
  if (isCot(state)) {
    return updateCotUserPhone
  } else if (isVendorInvolved(state)) {
    return updateVendorUserPhone
  } else {
    return updateEmployeeSystemUserPhone
  }
}

const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps
  const { state, senderService } = stateProps

  const selector = formValueSelector('updatePhoneNumber')
  const mobilePhone = selector(state, 'mobilePhone')

  return {
    senderService: senderService,
    onSubmit: (data) => {
      const sanitizedData = {
        ...data,
        'mobilePhone': mobilePhone,
        'id': state.auth.userId
      }

      const action = getUpdateAction(state)

      return dispatch(action(sanitizedData)).then(() => {
        dispatch(reset('updatePhoneNumber'))
        dispatch(destroy('updatePhoneNumber'))
        dispatch(showMessage({
          body: 'Phone number was updated successfully!',
        }))
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'updatePhoneNumber'
})(ChangePhoneNumberStepTwoContainer))
