import CoreLayout from 'layouts/PageLayout/PageLayout'
import ReleasesRoute from './routes/Releases'
import InnerWrapper from './components/InnerWrapper'

export default (store) => ({
  path: '/resources',
  indexRoute: { component: InnerWrapper },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayout: true,
  childRoutes: [ReleasesRoute(store)],
})
