import addressFields from 'redux/config/employeeAddressFieldsConfig'
import { isEmpty } from 'utils/fnkit/typeChecks'

import { categoriesTextLabel } from 'utils/locales/category.en'

/**
 * Fixed Subcategories hardcoded keys
 */
export const fixedSubcategoryKeys = {
  personalDetails: 'personalDetails',
  biographicalDetails: 'biographicalDetails',
  contactDetails: 'contactDetails',
  addressDetails: 'addressDetails',
  bankDetails: 'bankDetails',
  emergencyPersonDetails: 'emergencyPersonDetails',
  taxDetails: 'taxDetails',
  contractorDetails: 'contractorDetails',
  employmentDetails: 'employmentDetails',
  jobProfile: 'jobProfile',
  organizationDetails: 'organizationDetails',
  employeeSelfService: 'employeeSelfService',
  annualSalary: 'annualSalary',
  payElements: 'payElements',
  employeeDeductions: 'employeeDeductions',
  employeeNetDeductions: 'employeeNetDeductions',
  employerContributions: 'employerContributions',
  informationalElements: 'informationalElements',
}
/**
 * Fixed SubCategory Labels
 */
export const fixedSubCategoriesLabels = {
  personalDetails: categoriesTextLabel.personalDetails,
  biographicalDetails: categoriesTextLabel.biographicalDetails,
  contactDetails: categoriesTextLabel.contactDetails,
  addressDetails: categoriesTextLabel.addressDetails,
  bankDetails: categoriesTextLabel.bankDetails,
  emergencyPersonDetails: categoriesTextLabel.emergencyPersonContactDetails,
  taxDetails: categoriesTextLabel.taxAndIdDetails,
  contractorDetails: categoriesTextLabel.contractorsDetails,
  employmentDetails: categoriesTextLabel.employmentDetails,
  jobProfile: categoriesTextLabel.jobProfile,
  organizationDetails: categoriesTextLabel.organization,
  employeeSelfService: categoriesTextLabel.employeeSelfService,
  annualSalary: categoriesTextLabel.annualSalary,
  payElements: categoriesTextLabel.payElements,
  employeeDeductions: categoriesTextLabel.employeeDeductions,
  employeeNetDeductions: categoriesTextLabel.employeeNetDeductions,
  employerContributions: categoriesTextLabel.employerContributions,
  informationalElements: categoriesTextLabel.informationalElements,
}
/**
 * subcategories divided by fronted section
 */
export const fixedSubcategoriesBySection = {
  personal: [
    fixedSubcategoryKeys.personalDetails,
    fixedSubcategoryKeys.biographicalDetails,
    fixedSubcategoryKeys.contactDetails,
    fixedSubcategoryKeys.bankDetails,
    fixedSubcategoryKeys.emergencyPersonDetails,
    fixedSubcategoryKeys.taxDetails,
    fixedSubcategoryKeys.contractorDetails,
  ],
  'job-and-org': [
    fixedSubcategoryKeys.employmentDetails,
    fixedSubcategoryKeys.jobProfile,
    fixedSubcategoryKeys.organizationDetails,
    fixedSubcategoryKeys.employeeSelfService,
  ],
}

export const fixedInformationalElementsArray = [
  { field: 'netPay', name: 'Net Pay' },
  { field: 'totalEmployerCost', name: 'Total Employer Cost' },
  { field: 'netIncome', name: 'Net Income' },
  { field: 'totalTaxableIncome', name: 'Total Taxable Income' },
  { field: 'grossPay', name: 'Gross Pay' },
]

export const fixedAnnualSalaryFields = [
  { field: 'value', name: 'Annual Salary' },
  { field: 'effectiveDate', name: 'Effective Date' },
]

export const fixedDesignerReportTermCategories = [
  {
    id: 1,
    key: fixedSubcategoryKeys.payElements,
  },
  {
    id: 2,
    key: fixedSubcategoryKeys.employerContributions,
  },
  {
    id: 3,
    key: fixedSubcategoryKeys.employeeDeductions,
  },
  {
    id: 9,
    key: fixedSubcategoryKeys.employeeNetDeductions,
  },
]

export const fixedSubcategories = {
  [fixedSubcategoryKeys.addressDetails]: addressFields,
  [fixedSubcategoryKeys.annualSalary]: fixedAnnualSalaryFields,
  [fixedSubcategoryKeys.informationalElements]: fixedInformationalElementsArray,
}

export const fixedPayrollInstanceFields = ['otherEmployeeId', 'firstname', 'surname', 'employmentStatus', 'managementPandL']
export const fixedOrgUnitFields = ['paygroup', 'businessUnit', 'costCenter', 'department', 'officeLocation', 'subsidiary', 'project']

export const fixedCategoriesEntity = {
  [fixedSubcategoryKeys.addressDetails]: 'address',
  [fixedSubcategoryKeys.bankDetails]: 'bankaccount',
  [fixedSubcategoryKeys.payElements]: 'companycountryterm',
  [fixedSubcategoryKeys.employerContributions]: 'companycountryterm',
  [fixedSubcategoryKeys.employeeDeductions]: 'companycountryterm',
  [fixedSubcategoryKeys.employeeNetDeductions]: 'companycountryterm',
  [fixedSubcategoryKeys.informationalElements]: 'payrollinstanceemployee',
  [fixedSubcategoryKeys.annualSalary]: 'annualsalary',
}
export const extrafieldEntityKey = 'extrafield'
export const fixedExcludedCategories = {
  [fixedSubcategoryKeys.bankDetails]: ['payrollCountry', 'company'],
  [fixedSubcategoryKeys.organizationDetails]: ['makeKeyUser', 'countries', 'company', 'payroll', 'resetUserPassword'],
  [fixedSubcategoryKeys.jobProfile]: ['employeeId'],
  [fixedSubcategoryKeys.employmentDetails]: ['hasMoved'],
}

export const fixedExtraPersonalFields = [
  {
    subCategory: 'bankDetails',
    field: 'bankCode',
    name: 'Bank Code',
  },
  {
    subCategory: 'bankDetails',
    field: 'clearingNumber',
    name: 'Clearing Number',
  },
]

/**
 * Filters the provided fields array based on specified subcategories.
 *
 * @param {Array} fields - The array of field objects to filter.
 * @param {Array} subcategories - The array of subcategories to be considered for filtering.
 * @returns {Array} - An array of filtered field objects whose subCategory is in the subcategories list
 *                    and that contain a valid 'name' property.
 */
const getFilteredFields = (fields, subcategories) =>
  fields.filter(({ subCategory, name, field }) => subcategories.includes(subCategory) && name && !fixedExcludedCategories[subCategory]?.includes(field))
/**
 * Transforms an array of field objects into an object where keys are sub-categories and values are arrays
 * of mapped fields.
 *
 * @param {Array} fields - An array of field objects to be mapped.
 * @param {Function} fieldsMapper - A function that receives a field object and returns a mapped value.
 * @returns {Object} An object with sub-categories as keys and arrays of mapped fields as values.
 */
const mapFields = (fields, fieldsMapper) =>
  fields.reduce((acc, { subCategory, name, field }) => {
    acc[subCategory] = [...(acc[subCategory] || []), fieldsMapper({ name, field })]
    return acc
  }, {})

/**
 * Generates an object containing fixed and filtered fields based on the provided configuration and fieldsMapper.
 *
 * @param {Array} config - The configuration array containing category and fields details.
 * @param {Function} fieldsMapper - A function to map the name and field to a specific structure.
 *
 * @returns {Object} An object where keys are subcategories and values are arrays of mapped fields.
 */

export const generateFixedFilteredFields = (config, fieldsMapper) => {
  if (isEmpty(config)) return {}
  return config.reduce((categories, { category, fields }) => {
    const subcategories = fixedSubcategoriesBySection[category] || []
    const filteredFields = getFilteredFields(fields, subcategories)
    const mappedFields = mapFields(filteredFields, fieldsMapper)
    return { ...categories, ...mappedFields }
  }, {})
}
