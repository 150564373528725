import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PayslipTable from 'components/table/PayslipTable'
import CustomSelectField from 'components/form/select/CustomSelectField'

const BusinessUnitsOwnersTable = ({ buOwnersData, ownersProps = {} }) => (
  <PayslipTable
    headings={[
      {
        accessor: 'businessUnit',
        Header: 'Business Unit Name',
        disableSortBy: true,
        disableFilters: true,
        columnClassName: 'text-left',
      },
      {
        accessor: 'actionOwners',
        Header: 'Action Owner',
        disableSortBy: true,
        disableFilters: true,
        columnClassName: 'u-overflow-visible text-center',
      },
    ]}
    data={buOwnersData.map((d) => ({
      id: d.id,
      businessUnit: d.businessUnit,
      actionOwners: (
        <Field
          className='c-custom-select--transparent'
          name={`actionOwnerBu-${d.id}`}
          formGroupClassName='u-margin-none'
          component={CustomSelectField}
          placeholder={'Select'}
          options={d.actionOwners.map((owner) => ({
            value: owner.id,
            label: owner.name,
          }))}
          {...ownersProps}
        />
      ),
    }))}
    modifierClasses='react-bs-container-body'
    wrapperClassName='u-1/1 u-margin-top u-margin-bottom'
    tableElementClassName='table--layout-auto'
    trClassName='react-bs-table__cell--whitespace--normal'
  />
)

BusinessUnitsOwnersTable.propTypes = {
  buOwnersData: PropTypes.array.isRequired,
  ownersProps: PropTypes.object,
}

export default BusinessUnitsOwnersTable
