import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { fetchDataProtectionFieldsIfNeeded } from 'redux/actions/dataProtectionFields'
import { fetchCountryDataProtectionFieldsPivotIfNeeded } from 'redux/actions/countryDataProtectionFieldsPivot'
import { filterCompanyDataProtectionFields } from 'redux/actions/companyDataProtectionFieldsPivot'
import { filterApprovedCompanyDataProtectionFields } from 'redux/actions/approvedCompanyDataProtectionFieldsPivot'
import { getFullCompanyDataProtectionFieldsForApprovalList, getLatestApproval,
  getApprovedCompanyDataProtectionFields, getApprovedCompanyDataProtectionFieldsWithData } from 'redux/selectors/approvedCompanyDataProtectionFields'
import { filterCompanyDataProtectionFieldsRevision } from 'redux/actions/companyDataProtectionFieldsRevision'
import { getCompanyRef } from 'redux/selectors/company'
import PersonalDataList from '../components/PersonalDataList'
import ApproveFieldsContainer from './ApproveFieldsContainer'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import { createFilter } from '../../../../../../../utils/redux/filter'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'

class PersonalDataContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch, employeeFilter } = this.props
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchDataProtectionFieldsIfNeeded())
    dispatch(fetchCountryDataProtectionFieldsPivotIfNeeded())
    dispatch(filterCompanyDataProtectionFields({ company: this.props.companyId, isApproved: true }))
    dispatch(filterApprovedCompanyDataProtectionFields({ company: this.props.companyId }))
    dispatch(filterCompanyDataProtectionFieldsRevision({ company: this.props.match.params.companyId }))
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
  }

  render () {
    if (this.props.isFetching) return <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>Loading...</div>
    if (this.props.approvedDataProtectionFields.length) {
      return <PersonalDataList {...this.props} />
    } else {
      return <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop u-margin-top-large u-padding-top-small page--personal-data-container'>
        <ApproveFieldsContainer {...this.props} />
      </div>
    }
  }
}

PersonalDataContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

const mapStateToProps = (state, props) => {
  const { companies, dataProtectionFields, countryDataProtectionFieldsPivot, companyDataProtectionFieldsPivot,
    approvedCompanyDataProtectionFieldsPivot, companyDataProtectionFieldsRevision, employeeSystemUsers } = state

  const employeeFilter = createFilter({ isKeyPerson: 1, accessableCompanies: props.match.params.companyId })
  const entities = [companies, dataProtectionFields, countryDataProtectionFieldsPivot, companyDataProtectionFieldsPivot,
    approvedCompanyDataProtectionFieldsPivot, companyDataProtectionFieldsRevision,
    { ref: employeeSystemUsers, filter: employeeFilter } ]

  const companyId = parseInt(props.match.params.companyId)

  if (isFetching(entities, true)) return { isFetching: true, companyId, employeeFilter }

  const company = getCompanyRef(state, { companyId: companyId })

  /**
   * dataFields - fields that should be displayed in `Personal Data Fields (Sensitive Data)` table
   * fields - fields that should be displayed in approve fields form
   * approvedDataProtectionFields - get approved dpf to check if there are any and display the appropriate component
   * latestApprovedDateAuthor - get latest approval author name and date of approval to show in approved fields list
   */
  return {
    employeeFilter,
    companyId,
    dataFields: getApprovedCompanyDataProtectionFieldsWithData(state, { companyId: props.match.params.companyId }),
    fields: getFullCompanyDataProtectionFieldsForApprovalList(state, { countryId: company.country }),
    approvedDataProtectionFields: getApprovedCompanyDataProtectionFields(state),
    latestApprovedDateAuthor: getLatestApproval(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataContainer)
