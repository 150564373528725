import Fetcher from '../Fetcher'
import { connect } from 'react-redux'
import VendorHeader from 'components/header/VendorHeader'
import { getVendor } from '../../redux/selectors/vendor'

const mapStateToProps = (state, { vendorId }) => ({
  vendor: getVendor(state, { vendorId })
})

const Container = connect(mapStateToProps)(VendorHeader)

export default Fetcher(Container, ['vendors', 'countries'])
