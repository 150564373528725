/* eslint-disable react/prop-types */
import React from 'react'
import { setStatusForNotifications } from 'utils/payrollInstanceStatuses'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import { createFilter } from 'utils/redux/filter'
import { selectTenant } from 'redux/actions/tenants'
import { purify } from 'utils/router'

const PayrunLink = ({
  resetTenant,
  tenant,
  dispatch,
  isVendor,
  selectTenant: selectVendorTenant,
  isCotUser,
  history,
  payrollInstanceId,
  companyId,
  vendorId,
  isTimelineNavigation,
  isActionTimelineNavigation,
  isDocumentGTNReportSharedNotificationER,
  isDocumentGTNReportSharedNotificationCOT
}) => {
  const { id: tenantId } = tenant
  let pathToTenantPayrollInstanceNotes
  let pathToVendorPayrollInstanceNotes
  let pathToTenantPayrollInstanceNotesForCOTUsers
  let tenantPayrunChangeDetails
  let vendorPayrunChangeDetails
  let pathToTenantPayrunGTNFile
  let pathToTenantPayrunChangeDetailsCOTUsers
  const payrollInstanceFilter = createFilter({ id: payrollInstanceId })

  return (
    <span
      className='u-text--curious u-cursor--pointer'

      onClick={async () => {
        resetTenant()
        selectVendorTenant({
          url: tenant.url,
          schema: tenant.schema,
          shouldUse: true,
        })
        let status

        await dispatch(
          (isVendor || isCotUser)
            ? fetchPayrollInstances({ filter: payrollInstanceFilter, forceVendorTenantAPI: true })
            : fetchPayrollInstances({ filter: payrollInstanceFilter })
        ).then((res) => {
          if (isCotUser) dispatch(selectTenant({ url: tenant.url, type: tenant.type, id: tenantId }))

          try {
            let payrollInstanceStatus = res.payload.response.data[0].status
            status = setStatusForNotifications(payrollInstanceStatus)

            let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}`
            pathToTenantPayrollInstanceNotes = `/payruns/${status}/${payrollInstanceId}/notes`
            pathToTenantPayrollInstanceNotesForCOTUsers = `${pathToTenantPayrollInstanceNotes}?redirectHome=true`

            pathToVendorPayrollInstanceNotes = `${baseVendor}/payrun/${status}/${payrollInstanceId}/notes`

            tenantPayrunChangeDetails = `/payruns/${status}/${payrollInstanceId}/change-details`
            pathToTenantPayrunChangeDetailsCOTUsers = `${tenantPayrunChangeDetails}?redirectHome=true`
            vendorPayrunChangeDetails = `${baseVendor}/payroll/${payrollInstanceId}/timelines`

            pathToTenantPayrunGTNFile = `/payruns/${status}/${payrollInstanceId}/instance?redirectHome=true`

            let path = isVendor
              ? pathToVendorPayrollInstanceNotes
              : isCotUser
                ? pathToTenantPayrollInstanceNotesForCOTUsers
                : pathToTenantPayrollInstanceNotes

            if (isTimelineNavigation || isActionTimelineNavigation) {
              path = isVendor
                ? vendorPayrunChangeDetails
                : isCotUser
                  ? pathToTenantPayrunChangeDetailsCOTUsers
                  : tenantPayrunChangeDetails
            }

            if (isDocumentGTNReportSharedNotificationCOT || isDocumentGTNReportSharedNotificationER) {
              path = pathToTenantPayrunGTNFile
            }

            if (isCotUser) {
              purify(history).then(() => {
                history.push('/home')
                history.push(path)
              })
            } else {
              history.push(path)
            }
          } catch (e) {
            const endPath = '/payruns/active'
            let baseVendor = `/vendors/${vendorId}/clients/${tenantId}/companies/${companyId}`
            let pathToTenantPayrollInstances = endPath
            let pathToVendorPayrollInstances = `${baseVendor}/${endPath}`

            let path = isVendor
              ? pathToVendorPayrollInstances
              : pathToTenantPayrollInstances

            return history.push(path)
          }
        })
      }}
      data-testid='notification-link'
    >
      {(isTimelineNavigation || isActionTimelineNavigation)
        ? 'here'
        : (isDocumentGTNReportSharedNotificationCOT || isDocumentGTNReportSharedNotificationER)
          ? 'Review'
          : 'View Note'}
    </span>
  )
}

export default PayrunLink
