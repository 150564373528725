import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { createFilter } from 'utils/redux/filter'
import { change, getFormValues } from 'redux-form'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { getEmployees } from 'redux/selectors/employees'
import EmployeesSearchList from '../components/EmployeesSearchList'
import { limit } from 'containers/employee/EmployeesSearchBarContainer'
import { pushBreadcrumb } from 'redux/actions/breadcrumbs'
import { getBreadcrumbFiltersByKey } from 'redux/selectors/breadcrumbs'

class EmployeesSearchListContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { filter, fetchEmployeeSystemUsers } = this.props
    // If `payrollInstance` is passed as query param, we will filter the employees by PayrollInstance.id initially.
    // It's important to always fetch forcefully here, because the cached `search` items may be result from other
    // searching criteria parameters.
    fetchEmployeeSystemUsers({ filter, disableObsoleteFlow: true })
    this.props.fetchCompaniesIfNeeded()
    this.props.fetchBusinessUnitsIfNeeded()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (next) {
    const thisId = this.props.query.get('payrollInstance')
    const nextId = next.query.get('payrollInstance')
    // In the case we have `payrollInstance` query param firstly, and later we navigate to the same page, but now
    // without having the parameter, then we want to reset the form and get the employees without any filters applied.
    // Example:
    // 1. Let's say we are on Payrun page. There we click on an Action link and we navigate to Employees search page,
    // having `payrollInstance` query param attached. All's good.
    // 2. But we decide navigating to Employees search page again, but now from the aside navigation.
    // The parameter will be removed, because the aside link is `/employees` (without query parameter)
    // In that case we want to reset the form, because even the `payrollInstance` field is hidden,
    // it's still kept in the Store and is being sent to the API.
    const triggerWhenGoingFromQueryParamToNone = thisId && !nextId
    const triggerWhenGoingFromNoneToQueryParam = !thisId && nextId
    if (triggerWhenGoingFromQueryParamToNone) {
      this.props.resetFormForNavigation(next.location.search)
      this.props.fetchEmployeeSystemUsers({ filter: next.filter, disableObsoleteFlow: true })
    }
    if (triggerWhenGoingFromNoneToQueryParam) {
      // Not too happy with this, but the back navigation from employees to employees with
      // a query param AFTER a refresh is a rather large annoyance to handle for some reason
      // and the corner case that this is, probably isn't really worth investigating too much
      location.reload()
    }
  }

  render () {
    return <EmployeesSearchList {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchEmployeeSystemUsers: data => dispatch(fetchEmployeeSystemUsers(data)),
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    fetchBusinessUnitsIfNeeded: () => dispatch(fetchBusinessUnitsIfNeeded()),
    filterResults: page => dispatch((dispatch, getState) => {
      const state = getState()
      const searchFilters = getFormValues('employeeSearch')(state)

      const payrollInstance = props.query.get('payrollInstance')

      const filter = createFilter({
        ...searchFilters,
        payrollInstance,
        limit,
        offset: page * limit,
        isEmployee: true,
        isAccessable: true,
        payloadFormat: 'reduced'
      }, 'search')
      return dispatch(fetchEmployeeSystemUsers({ filter, disableObsoleteFlow: true }))
    }),
    resetFormForNavigation: (search) => dispatch((dispatch, getState) => {
      const state = getState()
      const formFields = Object.keys(state.form.employeeSearch.registeredFields)
      const currentBreadcrumbs = getBreadcrumbFiltersByKey(state, { breadcrumbKey: `employees${search}` })
      formFields.forEach(field => {
        const cachedFilterValue = currentBreadcrumbs?.params[field] ?? null
        return dispatch(change('employeeSearch', field, cachedFilterValue))
      })
    }),
    resetForm: (fields) => dispatch((dispatch, getState) => {
      const state = getState()
      const formFields = Object.keys(state.form.employeeSearch.registeredFields)
      formFields.forEach(field => dispatch(change('employeeSearch', field, null)))
    }),
    startBreadcrumb: (payload) => dispatch(pushBreadcrumb(payload)),
  }
}

// map to state
const mapStateToProps = (state, { query, enablePagination, location }) => {
  const { employeeSystemUsers, companies, businessUnits } = state

  const payrollInstance = query.get('payrollInstance')

  let filter = createFilter({
    payrollInstance,
    ...enablePagination && { offset: 0, limit, isEmployee: true, isAccessable: true, payloadFormat: 'reduced' } },
  `search${location.search}`)
  const currentBreadcrumbs = getBreadcrumbFiltersByKey(state, { breadcrumbKey: `employees${location.search}` })
  filter = currentBreadcrumbs ?? filter
  const filters = state.employeeSystemUsers.filters[filter.name]

  const isFetchedAtLeastOnce = filters && filters.ids
  // If the employees are fetched at least once, we will know the `totalCount` and we can render the pagination.
  const pagination = enablePagination && isFetchedAtLeastOnce && {
    totalPages: Math.ceil(filters.totalCount / limit),
    currentPage: (filters.params.offset / limit) + 1,
    hidePagination: Math.ceil(filters.totalCount / limit) <= 1,
    limit,
    pageNeighbours: 2
  }

  if (isFetching([
    { ref: employeeSystemUsers, filter },
    companies,
    businessUnits
  ])) {
    return { isFetching: true, filter, pagination }
  }

  return {
    employeeSystemUsers: getEmployees(state, { filter: filter.name }),
    filter,
    pagination
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSearchListContainer)
