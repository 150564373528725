import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import DashboardView from '../components/DashboardView'
import { isCot, isPayrollAdmin, isPayrollAnalyst, isPayrollDataEntry, isFinanceAdmin, isAccounting, isControlling } from 'redux/selectors/auth'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { updateDashboardItems } from 'redux/actions/dashboards'
import { getFilteredDashboardItems } from '../selectors/dashboard'
import { createFilter } from 'utils/redux/filter'
import Modal from 'components/Modal'
import ReportResultsModalView from '../../Reporting/components/ReportResultsModalView'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'

// Check to see if there has been a change in the objects
// If there hasn't been any changes don't need to show the state change
export const objectsAreSame = (x, y) => {
  let objectsAreSame = true
  for (const propertyName in x) {
    if (!objectsAreSame) return
    if (y[propertyName] && x[propertyName] !== y[propertyName]) {
      objectsAreSame = false
      break
    }
  }
  return objectsAreSame
}
class DashboardContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = { layout: this.props.dashboardItems, reportId: null }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(getWhiteLabeledBrandStyles())
  }
  handleModalOpen (modalRef, reportId) {
    if (reportId) this.setState({ reportId })
    modalRef.showModal()
  }

  onLayoutChange (layouts) {
    let areTheyTheSame = true
    this.props.dashboardItems.forEach((item) => {
      if (!areTheyTheSame) return
      layouts.forEach((layout) => {
        if (!areTheyTheSame) return
        if (item.i === layout.i) {
          areTheyTheSame = objectsAreSame(item, layout)
        }
      })
    })

    if (!areTheyTheSame) {
      this.setState({ layout: [...layouts] })
    }
  }

  onItemFetched = (itemKey, count) => {
    const layoutItems = [...this.state.layout]
    const currentItem = layoutItems.find((layout) => layout.i === itemKey)
    if (currentItem.h >= 1 && count <= 1) {
      currentItem.h = 1
    }
  }

  render () {
    return (
      <span>
        <Modal
          ref='modalViewReportModal'
          className='c-modal c-modal--full c-modal--overflow-y'
          topLevelClassnames='u-margin-top-small'
          modalHeading=''
          data-testid='view-report-modal'
        >
          <ReportResultsModalView reportId={this.state.reportId} onDeleteCallBack={() => this.refs.modalViewReportModal.hideModal()} />
        </Modal>
        <DashboardView
          handleReportModalOpen={(reportId) => this.handleModalOpen(this.refs.modalViewReportModal, reportId)}
          onSaveLayout={this.props.onSaveLayout}
          layout={this.state.layout}
          onItemFetched={this.onItemFetched}
          onNavigatingToReports
          onLayoutChange={(layout) => this.onLayoutChange(layout)}
          {...this.props}
        />
      </span>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSaveLayout: (layout) => dispatch(updateDashboardItems(layout)),
  dispatch,
})

const mapStateToProps = (state) => {
  const parentCompaniesData = state.parentCompanies.allIds.map((id) => state.parentCompanies.byIds[id])
  const accessRoles = {
    isCot: isCot(state),
    isPayrollAdmin: isPayrollAdmin(state),
    isPayrollAnalyst: isPayrollAnalyst(state),
    isPayrollDataEntry: isPayrollDataEntry(state),
    isFinanceAdmin: isFinanceAdmin(state),
    isAccounting: isAccounting(state),
    isControlling: isControlling(state),
  }

  const tenant = state.tenants.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }) : parentCompaniesData[0]

  return {
    dashboardItems: getFilteredDashboardItems(state, { accessRoles }),
    isCot: isCot(state),
    owner: !isCot(state) ? state.auth.userId : null,
    dashboardURL: tenant.dashboardEnabled,
  }
}

DashboardContainer.propTypes = {
  onSaveLayout: PropTypes.func,
  dashboardItems: PropTypes.array,
}

const employeeKeyUser = {
  name: 'employeeSystemUsers',
  params: [
    {
      filter: createFilter({ isKeyPerson: 1 }),
    },
  ],
}
const Container = connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
export default Fetcher(Container, ['parentCompanies', 'dashboards', 'companies', 'countries', employeeKeyUser])
