import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const VENDOR_SWITCH_FETCH = 'VENDOR_SWITCH_FETCH'
export const VENDOR_SWITCH_RECEIVE = 'VENDOR_SWITCH_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: VENDOR_SWITCH_FETCH,
  receive: VENDOR_SWITCH_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'vendorSwitch', 'vendorswitch', 'VendorSwitch')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchVendorSwitchIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchVendorSwitch = actionUtility.fetchEntities
