import React from 'react'

import EditableCell from 'components/table/cell/EditableCell'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { payrollSummaryAdditionalItems } from 'utils/locales/payrollSummary.en'
import { tablePlaceholderLabels } from 'utils/locales/table.en'
import { buttonActions } from 'utils/locales/common.en'
import { CellNumberFormat } from 'utils/tableDataFormatters'

const btnClassName = 'c-btn c-btn--tiny c-btn--curious u-text--tiny'

export const getAdditionalItemsTableHeadings = ({ handleDelete, handleHistory }) => [
  new TableHeading({
    accessor: 'item',
    heading: payrollSummaryAdditionalItems.item,
    className: 'text-left',
    columnClassName: 'text-left',
    editable: true,
    Cell: EditableCell,
    inputType: 'input',
    disableSortBy: true,
    charactersPerLine: 110,
    requiredField: true,
    focusableIndex: 0,
  }),
  new TableHeading({
    accessor: 'currentPayrunValue',
    heading: payrollSummaryAdditionalItems.currentPayrun,
    className: 'text-right',
    columnClassName: 'text-right',
    editable: true,
    Cell: EditableCell,
    inputType: 'amount',
    disableSortBy: true,
    requiredField: true,
  }),
  new TableHeading({
    accessor: 'previousPayrunValue',
    heading: payrollSummaryAdditionalItems.prevPayrun,
    className: 'text-right',
    columnClassName: 'text-right',
    editable: true,
    Cell: EditableCell,
    inputType: 'amount',
    disableSortBy: true,
    requiredField: true,
  }),
  new TableHeading({
    accessor: 'variance',
    heading: payrollSummaryAdditionalItems.variance,
    className: 'text-right',
    columnClassName: 'text-right',
    editable: false,
    Cell: CellNumberFormat,
    disableSortBy: true,
  }),
  new TableHeading({
    id: 'comment',
    heading: '',
    editable: true,
    inputType: 'textArea',
    charactersPerLine: 180,
    disableSortBy: true,
    placeholder: tablePlaceholderLabels.leaveAcomment,
    Cell: ({ row }) => {
      const original = row?.original
      const isNew = original?.isNew
      return (
        <>
          {!isNew && (
            <div className='d-flex jc--flex-end ai-center'>
              <button onClick={() => handleHistory(row.original)} className={`${btnClassName}`} title={buttonActions.history}>
                <span className='icon icon--history' />
              </button>
              {original?.isTheLoggedUserTheOwner && (
                <button
                  onClick={() => handleDelete(row.original)}
                  className={`${btnClassName} u-margin-left-tiny`}
                  title={buttonActions.remove}
                  data-testid='additional-items-remove'
                >
                  <span className='icon icon--trash' />
                </button>
              )}
            </div>
          )}
        </>
      )
    },
  }),
]
