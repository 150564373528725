import React from 'react'
import Flag from 'components/Flag'
import PropTypes from 'prop-types'

export const CompanyHeader = props => {
  const { tenant, company } = props
  const { country, name, code, relationToHQ } = company
  const nameComposed = code ? `${code} ${name}` : name

  return <div className='o-media d-flex d-flex--no-wrap ai-center company-header-details'>
    <div className='o-media__img o-media__img--circle u-margin-right-small line-height--17'>
      <Flag flag={country.abbreviature.toLowerCase()} size='medium' />
    </div>
    <div className='o-media__body'>
      <span className='u-weight--bold u-text--large u-word-break-all'>
        {tenant ? `${tenant} / ${nameComposed}` : nameComposed}
      </span>
      <span className='u-weight--regular'>
        {` / ${country.abbreviature.toUpperCase()} - ${relationToHQ.toUpperCase()}`}
      </span>
    </div>
  </div>
}

CompanyHeader.propTypes = {
  tenant: PropTypes.string,
  company: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    relationToHQ: PropTypes.string,
    country: PropTypes.shape({
      abbreviature: PropTypes.string
    }),
  }),
}

export default CompanyHeader
