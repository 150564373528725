import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYGROUP_TERM_PIVOT_FETCH = 'PAYGROUP_TERM_PIVOT_FETCH'
export const PAYGROUP_TERM_PIVOT_RECEIVE = 'PAYGROUP_TERM_PIVOT_RECEIVE'
export const PAYGROUP_TERM_PIVOT_INVALIDATE = 'PAYGROUP_TERM_PIVOT_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYGROUP_TERM_PIVOT_FETCH,
  receive: PAYGROUP_TERM_PIVOT_RECEIVE,
  invalidate: PAYGROUP_TERM_PIVOT_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'paygroupTermPivot', 'paygroupterms', 'PaygroupTerm')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPaygroupTermPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPaygroupTermPivot = actionUtility.fetchEntities
export const invalidatePaygroupTermPivot = actionUtility.invalidate
