import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/commentary'

class Commentary extends CRUDModel {}
Commentary.modelName = 'Commentary'
Commentary.actionTypes = actionTypes

Commentary.fields = {
  id: attr(),
  payrollInstance: fk('PayrollInstance', 'commentary'),
}

export default Commentary
