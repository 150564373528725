import InnerWrapper from 'layouts/InnerWrapper'
import React from 'react'
import { connect } from 'react-redux'
import ErrorView from '../components/ErrorView'

class ErrorContainer extends React.Component {
  render () {
    return <InnerWrapper>
      <ErrorView {...this.props} />
    </InnerWrapper>
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer)
