import React from 'react'
import { Authorization } from '../../containers/Authorization'
import TicketingContainer from 'containers/header/TicketingContainer'
import NavBarAlertsWidgetContainer from 'containers/navbar/NavBarAlertsWidgetContainer'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import HeaderSubBranding from './HeaderSubBranding'

const HeaderLayout = ({ children, renderRightColumn, isCot }) => (
  <header className='c-header'>
    <div className='o-layout o-layout--middle d-flex'>
      <div className='o-layout__item u-1/1'>{children || <HeaderSubBranding />}</div>
      <div className='o-layout__item u-1/1 u-1/3@tablet d-flex jc--flex-end'>
        <TicketingContainer />
        <div className='u-padding-top-tiny'>
          <Authorization permissions={['NOTIFICATION_VIEW']}>
            <NavBarAlertsWidgetContainer isCot={isCot} history={useHistory()} />
          </Authorization>
        </div>
        <div>{renderRightColumn()}</div>
      </div>
    </div>
  </header>
)

HeaderLayout.propTypes = {
  children: PropTypes.any,
  renderRightColumn: PropTypes.func,
  isCot: PropTypes.bool,
}

export default HeaderLayout
