import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { deleteCotUser, downloadReport } from 'redux/actions/cotUsers'
import { getCotUsers } from '../selectors/cotUsers'
import CotUsersView from '../components/CotUsersView'
import { isCotAdmin } from 'redux/selectors/auth'
import { getCotUserAccessAreasInitials } from 'redux/selectors/cotUsers'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { isRoleCotUser } from 'utils/roles'

const mapStateToProps = (state, props) => {
  const cotUsers = getCotUsers(state)
  const modifiedCotUsers = cotUsers.map(user => {
    let accessAreasCompanies
    let tenants = ['All clients']
    if (isRoleCotUser(user.roleType)) {
      accessAreasCompanies = getCotUserAccessAreasInitials(state, { userId: user.id }).companies

      tenants = accessAreasCompanies.map(company => getParentCompanyById(state, { parentCompanyId: company })).map(t => t.name)

      return { ...user, tenants }
    }

    return { ...user, tenants }
  })

  return {
    cotUsers: modifiedCotUsers,
    isCotAdmin: isCotAdmin(state),
    loggedCotId: state.auth.userId,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    deleteCotUser: (id) => dispatch(deleteCotUser(id)),
    downloadReport: () => dispatch(downloadReport())
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(CotUsersView)

export default Fetcher(Container, ['countries', 'cotUsers', 'accessAreaPivot'])
