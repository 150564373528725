import React from 'react'
import BaseField from './BaseField'

const TextareaField = props => {
  const { input, type, rows, className = 'c-input', placeholder, enableGrowOnText } = props

  const handleKeyDown = (e) => {
    if (enableGrowOnText) {
      e.target.style.height = 'inherit'
      e.target.style.height = `${e.target.scrollHeight}px`
    }
  }

  return <BaseField {...props}>
    <textarea type={type} placeholder={placeholder} rows={rows} className={className} onKeyDown={handleKeyDown} {...input} data-testid={props['data-testid']} />
  </BaseField>
}

export default TextareaField
