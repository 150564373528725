import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/termCategories'

class Category extends CRUDModel {}
Category.modelName = 'Category'

Category.fields = {
  id: attr(),
  name: attr(),
}
Category.actionTypes = actionTypes

export default Category
