/**
 * Formats the name data for sending to the server by parsing and restructuring the 'modifiedBy' property.
 * Assumes 'modifiedBy' property is received as an array of JSON strings.
 * @param {object} data - The data object requiring formatting for server submission.
 * @param {string[]} data.modifiedBy - The array of JSON strings representing modified by details.
 * @returns {object} The formatted data object with 'modifiedBy' property parsed into an array of objects.
 *
 * data structure example:
 * ```
 * data = {
 *  modifiedBy: [
 *  '{"id":1,"tenant":"cos"}',
 *  '{"id":2,"tenant":"cos"}'
 *  ]
 * }
 * ```
 */
export const formatNameForChangeLogReport = (data) => {
  let obj = []
  data.modifiedBy.map((item) => {
    return obj.push(JSON.parse(item))
  })

  return { ...data, modifiedBy: obj }
}
