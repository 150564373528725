import createSelector from 'utils/createSelector'
import moment from 'moment'
import _ from 'lodash'

const getFilteredIds = (state, props) => state.companyDataProtectionFieldsRevision.filteredIds

export const getCompanyDataProtectionFieldsRevisions = createSelector(getFilteredIds,
  ({ CompanyDataProtectionFieldsRevision }, filteredIds) => {
    const revisions = CompanyDataProtectionFieldsRevision.filter(rev => filteredIds.includes(rev.id))
      .toModelArray()
      .map(rev => {
        const modifiedBy = rev.modifiedBy ? rev.modifiedBy.ref.fullname : 'COT user'
        return {
          ...rev.ref,
          label: `${moment(rev.modifiedAt.date).format('DD/MM/YYYY')} by ${modifiedBy}`,
          modifiedBy
        }
      })
    return _.orderBy(revisions, ['modifiedAt["date"]'], ['desc'])
  }
)
