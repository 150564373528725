import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_TASK_OWNER_SCOPES_FETCH = 'PAYROLL_TASK_OWNER_SCOPES_FETCH'
export const PAYROLL_TASK_OWNER_SCOPES_RECEIVE = 'PAYROLL_TASK_OWNER_SCOPES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_TASK_OWNER_SCOPES_FETCH,
  receive: PAYROLL_TASK_OWNER_SCOPES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollTaskOwnerScopes', 'payrolltaskownerscopes', 'PayrollTaskOwnerScopes')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollTaskOwnerScopesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollTaskOwnerScopes = actionUtility.fetchEntities
