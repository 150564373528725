import React from 'react'

import PropTypes from 'prop-types'
import PillBox from '../../../../../components/displays/PillBox'

export const ReportAccordionView = ({ report, navigateToReportFilters }) => {
  const { reports } = report
  return (
    <div
      className='report-accordion-grid d-grid d-grid--responsive
    d-grid--column-gap-small d-grid--row-gap-small u-margin-top-small'
    >
      {reports?.map((rep, i) => (
        <PillBox
          onClick={() => navigateToReportFilters(rep.LABEL, rep.VALUE, rep.DESCRIPTION)}
          key={i}
          text={rep.LABEL}
          parentCss={'u-padding-left-none'}
          dataTestId={rep.LABEL}
          classList='u-bg--white c-pillbox--hover-sauve'
        />
      ))}
    </div>
  )
}

ReportAccordionView.propTypes = {
  report: PropTypes.object,
  navigateToReportFilters: PropTypes.func,
}
