import { createReducer } from 'utils/redux'
import { TASKS_FETCH, TASKS_RECEIVE, TASKS_INVALIDATE, TASK_CREATE } from 'redux/actions/tasks'

const actionTypes = {
  fetch: TASKS_FETCH,
  receive: TASKS_RECEIVE,
  invalidate: TASKS_INVALIDATE,
  create: TASK_CREATE
}

export default createReducer(actionTypes)
