import createSelector from 'utils/createSelector'
import { getCompaniesByAuth } from 'redux/selectors/company'

const getCompanyId = (state, props) => parseInt(props.companyId)

export const getHQCompanies = createSelector(getCompaniesByAuth, (session, companies) => companies.filter(c => c.isHQ))

/**
 * Get the HQ Companies, by excluding a specific company by id
 *
 * For instance: it's possible to transform already created HQ company to another company type.
 * In this case, we should exclude the transforming HQ company from the HQ Companies list,
 * because it should not be possible to relate to itself.
 */
export const getHQCompaniesByExcludedHQ = createSelector(getHQCompanies, getCompanyId,
  (session, companies, companyId) => companies.filter(c => c.id !== companyId))
