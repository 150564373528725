import React from 'react'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import PayrollContainer from 'containers/PayrollContainer'

const { payDateButtonItem, actionOwnersButtonItem } = PayrollTabModalOptionButtons

export default props => {
  return <PayrollContainer
    payrunState={payrunStatesEnum.INACTIVE}
    buttonOptions={{ ...payDateButtonItem, ...actionOwnersButtonItem }}
    heading='Future Payruns'
    payrollKey='payrolls-for-inactive'
    {...props}
  />
}
