export const requiredFieldsLabels = {
  firstName: 'First Name',
  surname: 'Surname',
  workEmail: 'Work Email',
  paymentCurrency: 'Payment Currency',
  company: 'Company',
  country: 'Country',
  startDate: 'Start Date',
}

export const requiredFieldsDescriptions = {
  paymentCurrency: '- Should be the ISO 4217 code, e.g. EUR, USD, GBP, etc.',
  company: '- Should match exactly the company name, e.g. Birdale GmbH',
  country: '- Should be only one country, e.g. Ireland',
  startDate: '- Should be a date',
}

export const dataValidationsLabels = {
  churchTax: 'Church Tax',
  employeeId: 'Employee ID',
  gender: 'Gender',
  hecs: 'HECS',
  officePhone: 'Office Phone',
  taxClass: 'Tax Class',
}

export const dataValidationsDescriptions = {
  churchTax: '- Yes, No',
  employeeId: '- Should be unique per user per company',
  employeeSelfService: '- Yes, No. Default is No. If Yes, user is activated directly after import',
  mode2FA: '- totp, sms',
  gender: '- Male, Female',
  hecs: '- Yes, No',
  officePhone: "- Same as mobile phone, but doesn't need to be unique",
  taxClass: '- I, II, III, IV, V, VI, Pauschal Steuer 2%, Pauschal 25%',
}
