import React from 'react'
import { Field } from 'redux-form'
import FieldDatePicker from 'components/form/FieldDatePicker'
import InputField from 'components/form/InputField'
import moment from 'moment'
import Moment from 'components/Moment'
import { onChangeSubmit } from 'utils/form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
let classNames = require('classnames')

class ActionsDateFilters extends React.Component {
  constructor (props) {
    super(props)
    this.goToCurrent = this.goToCurrent.bind(this)
    this.goToBack = this.goToBack.bind(this)
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
    this.goToNext = this.goToNext.bind(this)
    this.handleChangeView = this.handleChangeView.bind(this)
  }

  handleChangeView (view) {
    this.props.changeView(view)
    onChangeSubmit(this.props.handleSubmit)()
  }

  getViewBtnsClassNames (view) {
    return classNames({
      'c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-left-small': true,
      'active': this.props.view === view
    })
  }

  handleDatePickerChange (date) {
    onChangeSubmit(this.props.handleSubmit)()
  }

  getLabel () {
    const { view, fromDate } = this.props

    const formats = {
      day: 'MMMM YYYY, Do dddd',
      month: 'MMMM YYYY'
    }

    const format = formats[view]
    return <Moment format={format}>{fromDate.toString()}</Moment>
  }

  /**
   * Go to current day
   */
  goToCurrent () {
    this.props.changeDate(moment())
    onChangeSubmit(this.props.handleSubmit)()
  }

  /**
  * Go to prev month/day
  */
  goToBack () {
    const { view, fromDate } = this.props

    let date

    if (view === 'month') {
      date = moment(fromDate).subtract(1, 'month')
    }
    if (view === 'day') {
      date = moment(fromDate).subtract(1, 'day')
    }

    this.props.changeDate(date)
    onChangeSubmit(this.props.handleSubmit)()
  }

  /**
   * Go to next month/day
   */
  goToNext () {
    const { view, fromDate } = this.props

    let date

    if (view === 'month') {
      date = moment(fromDate).add(1, 'month')
    }
    if (view === 'day') {
      date = moment(fromDate).add(1, 'day')
    }

    this.props.changeDate(date)
    onChangeSubmit(this.props.handleSubmit)()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>
    const { handleSubmit, shouldShowDateFilters, initialValues } = this.props
    if (!shouldShowDateFilters) return <div><SectionHeading text={this.props.headerText || initialValues.headerText} /></div>
    return <div>
      <div
        onClick={this.goToCurrent}
        className='c-btn c-btn--tiny u-text--small c-btn--transparent u-padding-left u-padding-right u-margin-right-small u-margin-bottom-tiny' >
        Today
      </div>
      <div className='o-grid--inline-flex o-grid--middle'>
        <span
          onClick={this.goToBack}
          className='icon--arrow left' />
        <form onSubmit={handleSubmit} className='u-text--small u-padding-left-small u-padding-right-small u-relative'>
          { this.getLabel() }
          <Field
            className='c-calendar__datepicker'
            name='fromDate'
            component={FieldDatePicker}
            formGroupClassName='u-margin-none u-height-0'
            labelClassName='c-label'
            onChanged={this.handleDatePickerChange}
            todayButton='Today'
          />
          <Field
            name='view'
            type='hidden'
            component={InputField}
            formGroupClassName='u-margin-none'
            labelClassName='c-label'
          />
        </form>
        <span
          onClick={this.goToNext}
          className='icon--arrow right' />
      </div>
      <div
        onClick={() => this.handleChangeView('day')}
        className={this.getViewBtnsClassNames('day')} >
        Day
      </div>
      <div
        onClick={() => this.handleChangeView('month')}
        className={this.getViewBtnsClassNames('month')} >
        Month
      </div>
    </div>
  }
}

ActionsDateFilters.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  fromDate: PropTypes.object.isRequired,
  shouldShowDateFilters: PropTypes.bool
}

export default ActionsDateFilters
