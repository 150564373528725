import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, reduxForm } from 'redux-form'
import { omit } from 'lodash'

import { isFetching } from 'utils/redux/fetching'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchTimezonesIfNeeded } from 'redux/actions/timezones'
import { createVendorUser } from 'redux/actions/vendorUsers'
import { isCot, isCotOem, isVendorAdmin } from 'redux/selectors/auth'
import { getCountries } from 'redux/selectors/country'
import { getTimezones } from 'redux/selectors/timezones'
import VendorUserForm from '../components/VendorUserForm'
import { getAccessableExternalParentCompanies } from 'redux/selectors/externalCompanies'
import { buildVendorAccessAreas } from '../vendorUserHelper'
import { ROLE_VENDOR_ADMIN, vendorRoleTypes } from 'redux/config/auth'
import { getVendorById } from 'redux/selectors/vendors'
import { everybodySSOString } from 'redux/config/ssoAccessStrings'

const formName = 'vendorUserCreateForm'

const VendorUserFormCreateContainer = (props) => {
  const { dispatch } = props

  useEffect(() => {
    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchVendorsIfNeeded())
    dispatch(fetchTimezonesIfNeeded())
  }, [dispatch])

  return <VendorUserForm {...props} />
}

VendorUserFormCreateContainer.propTypes = {
  onCreate: PropTypes.func,
  dispatch: PropTypes.func,
}

// map to state
const mapStateToProps = (state, props) => {
  const { countries } = state
  const entities = [countries]
  const isCotUser = isCot(state)
  const parentCompanies = getAccessableExternalParentCompanies(state)

  const { vendorId } = props.match.params

  const vendor = getVendorById(state, { vendorId })?.ref
  const { defaultSSOEnabledFor, isVendorSSOEnabled } = vendor

  const showMode2faFieldOnCreate = isVendorSSOEnabled ? defaultSSOEnabledFor !== everybodySSOString : true

  if (isFetching(entities)) return { isFetching: true }
  const isCotOemRole = isCotOem(state)

  return {
    canEditRole: true,
    vendorRoleTypes: isCotOemRole ? vendorRoleTypes.filter((role) => role.type !== ROLE_VENDOR_ADMIN) : vendorRoleTypes,
    isCot: isCotUser,
    isCreate: true,
    isVendorAdmin: isVendorAdmin(state),
    isTenantSSOEnabled: state.config.isSSOEnabled,
    globalCountries: getCountries(state),
    externalCompanies: props?.externalCompanies,
    parentCompanies: parentCompanies,
    timezones: getTimezones(state),
    showMode2faFieldOnCreate,
    initialValues: {
      vendor: vendorId,
      roleType: vendorRoleTypes[0].type,
      // Set user to be inactive by default
      hasCredentials: false,
    },
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetField: (field, value) => dispatch(change(formName, field, value)),
    onSubmit: (data) => {
      const { workflowNotifications, accessAreas, ...rest } = data
      // `workflowNotifications` value results in 2 backend fields,
      // please refer to the field options for more details
      let sanitized = {
        ...omit(rest, ['tenants']),
        attachAccessAreas: buildVendorAccessAreas(accessAreas, rest.roleType),
        ...(workflowNotifications && JSON.parse(workflowNotifications)),
      }

      return dispatch(createVendorUser(sanitized)).then(() => {
        props.onCreate()
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
  })(VendorUserFormCreateContainer)
)
