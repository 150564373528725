import createSelector from 'utils/createSelector'

const getUserId = (state, props) => parseInt(props.userId)

export const getCotUser = createSelector(getUserId,
  ({ CotUser }, userId) => {
    const user = CotUser.withId(userId)
    return {
      ...user.ref
    }
  }
)

export const getCotUserWithCountry = createSelector(getUserId,
  ({ CotUser }, userId) => {
    const user = CotUser.withId(userId)
    return {
      ...user.ref,
      ...user.country ? { country: { ...user.country.ref } } : {}
    }
  }
)

export const getCotUserAccessAreasInitials = createSelector(getUserId,
  ({ CotUser, ParentCompany }, id) => {
    const cotUser = CotUser.withId(id)
    const accessCompanies = getCotUserAccessCompanies(cotUser, ParentCompany).map(({ id }) => id)

    return {
      companies: accessCompanies
    }
  })

const getCotUserAccessCompanies = (cot, ParentCompany) => {
  const accessAreas = cot.accessArea.all().toModelArray()

  return accessAreas
    .filter(aa => aa.accessableType === 'Payslip\\CoreBundle\\Entity\\ParentCompany')
    .map(aa => {
      const parentCompany = ParentCompany.withId(aa.accessableId)

      return parentCompany.ref
    })
}
