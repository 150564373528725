import React from 'react'
import Back from 'components/Back'
import PropTypes from 'prop-types'

export const CalendarYearButtonsHeader = ({ downloadCalendar }) => {
  return (
    <div className='calendar-yearly d-flex jc--space-between u-margin-top'>
      <Back />
      <button
        className='c-btn c-btn--small c-btn--curious u-margin-bottom'
        title='Download'
        onClick={(e) => {
          e.preventDefault()
          downloadCalendar()
        }}
      >
        <span className={`icon icon--download`} />
      </button>
    </div>
  )
}

CalendarYearButtonsHeader.propTypes = {
  downloadCalendar: PropTypes.func
}

export default CalendarYearButtonsHeader
