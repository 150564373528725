import React from 'react'
import PropTypes from 'prop-types'

export const InnerWrapper = ({ children }) => (
  <div className='c-content-wrapper'>
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        {children}
      </div>
    </div>
  </div>
)

InnerWrapper.propTypes = {
  children: PropTypes.node,
}

export default InnerWrapper
