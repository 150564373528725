import { createReducer } from './reducer'

/**
 * Keep in mind that these reducers are obsolete!!!
 *
 * When we started the project, all of the entities used it as a base Store reducer (structure).
 *
 * Later, we move on `redux-orm` library, in order to handle entities relations better.
 * We refactored most of the entities, the complex ones, (you can check redux/models/),
 * but some entities may still use this one.
 *
 * The plan is to refactor the rest entities in the time, once we have to change or reuse them.
 */

/**
 * Default Reducer handlers to the supported  default action types
 *
 * @param {Object} actionTypes - Action types mapping. We support `fetch` and `receive` action types,
 * therefore we need to provide the real Entity action types values, mapped to the supported ones.
 * @example {
 *  fetch: COUNTRIES_FETCH,
 *  receive: COUNTRIES_RECEIVE
 * }
 */
const getHandlers = actionTypes => ({
  [actionTypes.fetch]: (state, action) => ({ ...state, isFetching: true }),
  [actionTypes.filter]: (state, action) => ({ ...state, isFiltering: true }),
  [actionTypes.invalidate]: (state, action) => ({ ...state, didInvalidated: true }),
  [actionTypes.errors]: (state, action) => ({ ...state, errors: action.payload }),
  [actionTypes.receive]: (state, action) => {
    let byIds = {}
    let allIds = []

    action.payload.response.data.map(entity => {
      byIds[entity.id] = entity
      allIds.push(entity.id)
    })

    return {
      ...state,
      isFetching: false,
      isFiltering: false,
      didInvalidated: false,
      receivedAt: Date.now(),
      byIds,
      allIds,
      lastCreatedId: null
    }
  }
})

const defaultState = {
  byIds: {},
  allIds: [],
  isFetching: null,
  isFiltering: null,
  didInvalidated: false,
  errors: [],
  receivedAt: null,
  lastCreatedId: null
}

export default (actionTypes, initialState, handlers) => {
  initialState = { ...defaultState, ...initialState }
  handlers = { ...getHandlers(actionTypes), ...handlers }

  return createReducer(initialState, handlers)
}
