import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_TIMEZONE_FETCH = 'COUNTRY_TIMEZONE_FETCH'
export const COUNTRY_TIMEZONE_RECEIVE = 'COUNTRY_TIMEZONE_RECEIVE'
export const COUNTRY_TIMEZONE_INVALIDATE = 'COUNTRY_TIMEZONE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_TIMEZONE_FETCH,
  receive: COUNTRY_TIMEZONE_RECEIVE,
  invalidate: COUNTRY_TIMEZONE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'countryTimezonePivot', 'countrytimezones', 'CountryTimezone')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryTimezonePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryTimezonePivot = actionUtility.fetchEntities
export const invalidateCountryTimezonePivot = actionUtility.invalidate
