import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import AccordionContainer from 'containers/AccordionContainer'

import BankAccountContainer from 'containers/BankAccountContainer'
import BankAccountFormCreateContainer from 'containers/BankAccountFormCreateContainer'
import ContractorContainer from 'containers/ContractorContainer'
import EmergencyPersonContainer from 'containers/EmergencyPersonContainer'
import Modal from 'components/Modal'
import MobileDetailsView from 'components/MobileDetailsView'
import SectionHeading from 'components/SectionHeading'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import {
  extractKeyValuePairFromArray,
  getContractorNameField,
  getFieldsBySection,
} from 'utils/employee'
import PayslipTable from 'components/table/PayslipTable'
import { EditableCellDataEmployeeFieldsFormatter } from 'components/table/cell/EditableCellDataEmployeeFieldsFormatter'
import EmployeeFieldsWithAdd from './EmployeeFieldsWithAdd'
import AddNewEmployeeAddressContainer from '../containers/AddNewEmployeeAddressContainer'
const defaultNestedColumns = [
  {
    Header: 'Title',
    accessor: 'title',
    disableSortBy: true,
    classNames: 'd-none',
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: true,
    cellClassName: 'w-100 u-border--mischka',
    classNames: 'd-none',
    editable: true,
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
  },
]
const GlobalErrorMessage = ({ text }) => <div className='text-center u-text--normal error'>{text}</div>

function PersonalView (props) {
  const {
    isFetching,
    EmployeeStatus,
    isEmployeeOnly,
    employee,
    inEditMode,
    onUpdateGlobalDataForSave,
    fieldErrors,
    onDeleteBankAccount,
    onDeleteEmployeeAddress,
    bankAccounts,
    employeeAddresses,
    extraFields,
    onValueChange,
    globalAddressErrors,
    globalAddressTooManyErrors,
    globalBankAccountErrors,
    globalBankAccountTooManyErrors,
    data: {
      personalDetails,
      biographicalDetails,
      employeeUsersAddressDetails,
      taxDetails,
      contactDetails,
      bankDetails,
    },
  } = props

  const modalCreateBankAccount = useRef(null)
  const modalCreateEmployeeAddress = useRef(null)

  const getEmployeePersonalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    isEmployeeOnly: isEmployeeOnly,
    subSection: 'personalDetails',
    extraFields
  })

  const getEmployeeBiographicalFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'biographicalDetails',
    extraFields
  })

  const getEmployeeTaxFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'taxDetails',
    extraFields
  })

  const getEmployeeContactFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'contactDetails',
    extraFields
  })

  const getEmployeeContractorsFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'contractorDetails',
    extraFields
  })

  const getEmployeeEmergencyPersonFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'personal',
    subSection: 'emergencyPersonDetails',
    extraFields
  })

  if (isFetching) return <div>Loading...</div>
  getEmployeeBiographicalFields.forEach(fields => {
    if (fields.field === 'gender') {
      const value = fields.options.find(f => f.value === fields.value)
      fields.value = value?.label || ''
    }
  })

  const editableOptions = {
    inGlobalEditMode: inEditMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    fieldErrors: fieldErrors,
    onValueChange: onValueChange
  }

  const mapAddressesForMobile = (addr) => {
    let address = []
    const field = employeeUsersAddressDetails[addr.id]
    field.forEach(({ title, value, metaData }) => {
      if (metaData.field === 'isPrimary') {
        value = value ? 'Yes' : 'No'
      }

      address.push({
        name: title,
        value: value
      })
    })
    return address
  }

  return <>
    <div className={isEmployeeOnly ? 'u-hide-on-mobile o-layout' : 'o-layout'}>
      <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='personal-details'>
        <PayslipTable
          data={personalDetails}
          dynamicValues
          data-testid={'personal-details-table'}
          editableOptions={editableOptions}
          headings={[
            {
              accessor: 'name',
              Header: 'Personal Details',
              disableSortBy: true,
              disableFilters: true,
              classNames: 'u-text--center',
              isKey: true,
              columns: defaultNestedColumns
            }
          ]}
          wrapperClassName='u-margin-bottom u-1/1'
        />

        <PayslipTable
          data={contactDetails}
          dynamicValues
          editableOptions={editableOptions}
          headings={[
            {
              accessor: 'name',
              Header: 'Contact Details',
              disableSortBy: true,
              disableFilters: true,
              classNames: 'u-text--center',
              isKey: true,
              columns: defaultNestedColumns,
            }
          ]}
          wrapperClassName='u-margin-bottom u-1/1'
        />
        <EmergencyPersonContainer
          editableOptions={editableOptions}
          defaultNestedColumns={defaultNestedColumns}
          {...props}
        />
        {
          EmployeeStatus.shouldShowContractArea &&
          <div className='u-1/1'>
            <ContractorContainer editableOptions={editableOptions} defaultNestedColumns={defaultNestedColumns} {...props} />
          </div>
        }
      </div>

      <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='biographical-details'>
        <PayslipTable
          data={biographicalDetails}
          dynamicValues
          editableOptions={editableOptions}
          data-testid={'biographical-details-table'}
          headings={[
            {
              accessor: 'name',
              Header: 'Biographical Details',
              disableSortBy: true,
              disableFilters: true,
              classNames: 'u-text--center',
              isKey: true,
              columns: defaultNestedColumns
            }
          ]}
          wrapperClassName='u-margin-bottom u-1/1'
        />
        <div data-testid='employee-user-address-details'>
          {globalAddressErrors && <GlobalErrorMessage text='One Address must be marked as ‘Primary’' />}
          {globalAddressTooManyErrors && <GlobalErrorMessage text='Only one Address can be marked as ‘Primary’' />}
          <EmployeeFieldsWithAdd
            identifier='employee-user-address'
            header='Address Details'
            headerKey='type'
            onDeleteClick={(id) => { if (!props.inEditMode) onDeleteEmployeeAddress(id) }}
            onAddClick={() => modalCreateEmployeeAddress.current.showModal()}
            addText='Add New Address'
            permissions={
              {
                create: ['EMPLOYEEUSERADDRESS_CREATE'],
                delete: ['EMPLOYEEUSERADDRESS_DELETE'],
                edit: ['EMPLOYEEUSERADDRESS_EDIT'],
              }
            }
            editableOptions={editableOptions}
            inEditMode={inEditMode}
            items={employeeAddresses}
            data={employeeUsersAddressDetails}
            headings={[
              {
                accessor: 'name',
                Header: 'Address Details',
                classNames: 'text-center',
                disableFilters: true,
                disableSortBy: true,
                isKey: true,
                columns: defaultNestedColumns,
              }
            ]}
          />
        </div>
      </div>

      <div className='o-layout__item u-1/1 u-1/3@tablet' data-testid='tax-and-id-details'>
        <PayslipTable
          data={taxDetails}
          dynamicValues
          editableOptions={editableOptions}
          headings={[
            {
              accessor: 'name',
              Header: 'Tax & ID Details',
              disableSortBy: true,
              disableFilters: true,
              classNames: 'u-text--center',
              isKey: true,
              columns: defaultNestedColumns
            }
          ]}
          wrapperClassName='u-margin-bottom u-1/1'
        />
        <div data-testid='bank-account-details'>
          {globalBankAccountErrors && <GlobalErrorMessage text='One Bank Account must be marked as ‘Primary’' />}
          {globalBankAccountTooManyErrors && <GlobalErrorMessage text='Only one Bank Account can be marked as ‘Primary’' />}
          <EmployeeFieldsWithAdd
            identifier='bank-account'
            header='Bank Details'
            headerKey='bankCountryName'
            onDeleteClick={(id) => { if (!props.inEditMode) onDeleteBankAccount(id) }}
            onAddClick={() => modalCreateBankAccount.current.showModal()}
            addText='Add New Bank Account'
            permissions={
              {
                create: ['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'],
                delete: ['BANKACCOUNT_CREATE'],
                edit: ['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT'],
              }
            }
            editableOptions={editableOptions}
            inEditMode={inEditMode}
            items={bankAccounts}
            data={bankDetails}
            headings={[
              {
                accessor: 'name',
                Header: 'Bank Details',
                classNames: 'text-center',
                disableFilters: true,
                disableSortBy: true,
                isKey: true,
                columns: defaultNestedColumns,
              }
            ]}
          />
        </div>
      </div>
    </div>
    <Modal
      ref={modalCreateBankAccount}
      className='c-modal c-modal--full'
      modalHeading='Add Bank Account'
      data-testid='bank-account-create-modal'
    >
      <BankAccountFormCreateContainer
        onSubmit={() => modalCreateBankAccount?.current ? modalCreateBankAccount.current.hideModal() : null}
        {...props}
      />
    </Modal>
    <Modal
      ref={modalCreateEmployeeAddress}
      className='c-modal c-modal--full'
      modalHeading='Add New Address'
      data-testid='employee-address-create-modal'
    >
      <AddNewEmployeeAddressContainer
        onSubmit={() => modalCreateEmployeeAddress?.current ? modalCreateEmployeeAddress.current.hideModal() : null}
        {...props}
      />
    </Modal>
    {isEmployeeOnly &&
      <div className='u-hide-on-desktop'>
        <SectionHeading text='Personal' />
        <AccordionContainer title='Personal Details' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeePersonalFields)} />
        </AccordionContainer>
        <AccordionContainer title='Biographical Details' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeBiographicalFields)} />
        </AccordionContainer>
        <AccordionContainer title='Contact Details' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeContactFields)} />
        </AccordionContainer>
        <AccordionContainer title='Addresss Details' titleColor='minsk' fullWidth rounded>
          {employeeAddresses.map(addr => <MobileDetailsView rows={extractKeyValuePairFromArray(mapAddressesForMobile(addr))} temp />)}
        </AccordionContainer>
        <AccordionContainer title='Tax & ID Details' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeTaxFields)} />
        </AccordionContainer>
        <BankAccountContainer {...props} />
        {
          EmployeeStatus.shouldShowContractArea &&
          <AccordionContainer title={EmployeeStatus.sectionHeading} titleColor='minsk' fullWidth rounded>
            <MobileDetailsView rows={
              extractKeyValuePairFromArray([
                getContractorNameField({ employee, withNameAndValue: true }), ...getEmployeeContractorsFields])
            } />
          </AccordionContainer>
        }
        <AccordionContainer title='Emergency Person Details' titleColor='minsk' fullWidth rounded>
          <MobileDetailsView rows={extractKeyValuePairFromArray(getEmployeeEmergencyPersonFields)} />
        </AccordionContainer>
      </div>
    }
  </>
}

GlobalErrorMessage.propTypes = {
  text: PropTypes.string
}
PersonalView.propTypes = {
  isFetching: PropTypes.bool,
  employeeCustomFields: PropTypes.array,
  employeeAddresses: PropTypes.array,
  EmployeeStatus: PropTypes.object,
  employee: PropTypes.object,
  isEmployeeOnly: PropTypes.bool,
  fieldErrors: PropTypes.array,
  inEditMode: PropTypes.bool,
  data: PropTypes.object,
  bankAccounts: PropTypes.array,
  onUpdateGlobalDataForSave: PropTypes.func,
  onDeleteBankAccount: PropTypes.func,
  onDeleteEmployeeAddress: PropTypes.func,
  text: PropTypes.string,
  btnClasses: PropTypes.string,
  extraFields: PropTypes.object,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  globalAddressErrors: PropTypes.bool,
  globalAddressTooManyErrors: PropTypes.bool
}

export default PersonalView
