import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const TIMEZONES_FETCH = 'TIMEZONES_FETCH'
export const TIMEZONES_RECEIVE = 'TIMEZONES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TIMEZONES_FETCH,
  receive: TIMEZONES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'timezones', 'timezones', 'Timezone')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTimezonesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchTimezones = actionUtility.fetchEntities
