import { FETCH_BRANDINGS, RECEIVE_BRANDINGS } from 'redux/actions/brandings'
import { createReducer } from 'utils/redux/reducer'
const initialState = {
  brands: [],
  isFetching: null
}

export default createReducer(initialState, {
  [FETCH_BRANDINGS]: (state, { payload }) => ({ ...state, brands: [...state.brands], isFetching: true }),
  [RECEIVE_BRANDINGS]: (state, { payload }) => ({ ...state, brands: payload, isFetching: false }),
})
