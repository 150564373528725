import React from 'react'
import PropTypes from 'prop-types'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

export const InlineTableActionButtons = ({ onCancel = null, onSave = null, isUpdating = false }) => {
  return (
    <>
      <button
        onClick={onCancel}
        className={`${btnClasses}`}
        disabled={isUpdating}
        title='Cancel'
        data-testid='general-ledger-account-cancel'
      >
        Cancel
      </button>
      <button
        onClick={onSave}
        className={`${btnClasses} ${isUpdating ? 'c-btn--submitting' : ''}`}
        disabled={isUpdating}
        title='Save'
        data-testid='inline-action-save'
      >
        Save
      </button>
    </>
  )
}

InlineTableActionButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
}
