import { generateDescriptionDocLink, getGTNImportTemplate } from './documentTemplates'

export const payrollDocsMapper = (
  documents = [],
  {
    onDownload,
    openReimportGTNModal,
    hasRightsToLockUnlock,
    openReimportGTNWhenPayrunIsUnlockedModal,
    openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
    showGTNColumn,
    isPayrollInstanceLocked,
  }
) =>
  documents?.map((doc, i) => {
    let description = null

    if (doc.gtnFileId !== null) {
      description = generateDescriptionDocLink(doc, { onDownload })
    }
    const shouldShowGTNbutton = doc.extraData.showGtnImportButton
    const showGTNReimportButton = shouldShowGTNbutton && showGTNColumn
    return {
      ...doc,
      description: description || doc.description,
      isOwner: doc.extraData.isOwner,
      gtnReimport:
        showGTNReimportButton &&
        getGTNImportTemplate(doc, {
          isPayrollInstanceLocked,
          openReimportGTNModal,
          hasRightsToLockUnlock,
          openReimportGTNWhenPayrunIsUnlockedModal,
          openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
        }),
    }
  })
