// ------------------------------------
// Constants
// ------------------------------------
export const ASIDE_SELECT = 'ASIDE_SELECT'

// ------------------------------------
// Actions
// ------------------------------------
export const selectAside = (collapsed) => (dispatch) => {
  dispatch({
    type: ASIDE_SELECT,
    payload: { collapsed }
  })
}
