import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const NOTE_SHARES_FETCH = 'NOTE_SHARES_FETCH'
export const NOTE_SHARES_RECEIVE = 'NOTE_SHARES_RECEIVE'
export const NOTE_SHARES_INVALIDATE = 'NOTE_SHARES_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: NOTE_SHARES_FETCH,
  receive: NOTE_SHARES_RECEIVE,
  invalidate: NOTE_SHARES_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'noteShares', 'noteshares', 'NoteShare')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchNoteSharesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchNoteShares = actionUtility.fetchEntities
export const invalidateNoteShares = actionUtility.invalidate
