import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const BANK_ACCOUNT_TYPES_FETCH = 'BANK_ACCOUNT_TYPES_FETCH'
export const BANK_ACCOUNT_TYPES_RECEIVE = 'BANK_ACCOUNT_TYPES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: BANK_ACCOUNT_TYPES_FETCH,
  receive: BANK_ACCOUNT_TYPES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'bankAccountTypes', 'bankaccounttypes', 'BankAccountType')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchBankAccountTypesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchBankAccountTypes = actionUtility.fetchEntities
