import createSelector from 'utils/createSelector'
import { getFixedPaygroupTermByPaygroup } from 'redux/selectors/paygroupTerm'
import _ from 'lodash'

// get the company id

// get the passed employee ids
const getEmployeeIds = (state, props) => props.employeeIds

// Get employees, which company country terms differ with the selected paygroup
export const getEmployeesWhichFixedTermsDifferWithPaygroup = createSelector(
  getEmployeeIds,
  getFixedPaygroupTermByPaygroup,
  ({ EmployeeSystemUser }, employeeIds, paygroupTerms) => {
    return EmployeeSystemUser.filter((employee) => employee.isEmployee && employee.isAccessable && _.includes(employeeIds, employee.id))
      .toModelArray()
      .filter((employee) => {
        // get employee terms
        // I haven't filtered out the variable terms
        // because it's one extra loop, instead
        // i have one extra check to see if the term
        // is variable to skip it at the final loop
        let employeeTerms = employee.employeeCompanyCountryTerms.toModelArray()

        let shouldBeAdded = employeeTerms.some((empTerm) => {
          if (empTerm.term.isVariable()) return false

          let paygroupTerm = _.find(paygroupTerms, { id: empTerm.term.id })
          return paygroupTerm && paygroupTerm.amount === empTerm.amount
        })

        return !shouldBeAdded
      })
  }
)
