import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { batch, useDispatch } from 'react-redux'
import omit from 'lodash/omit'
import usePageUIState from 'hooks/usePageUIState'
import JobOrgView from '../components/JobOrgView'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import { InlineTableActionButtons } from 'components/buttons/InlineTableActionButtons'
import { getFieldsBySection, renderInlineEditFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'
import { updateEmployeeSystemUser, updateEmployeeUserSSO } from 'redux/actions/employeeSystemUsers'
import { formatPayloadForJobAndOrg } from '../../Personal/containers/helpers'
import { resetPasswordSendEmail } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import ConfirmationModal from 'components/ConfirmationModal'
import { useFirstRender } from 'hooks/useFirstRender'
import { replaceOptions } from '../utils'
import { attachEmployeeToPayroll } from 'redux/actions/payrolls'
import { attachEmployeeToPaygroup } from 'redux/actions/paygroups'
import { fetchPayrollEmployeePivot } from 'redux/actions/payrollEmployeePivot'
import { fetchPaygroupEmployeePivot } from 'redux/actions/paygroupEmployeePivot'
import { createFilter } from 'utils/redux/filter'
const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
const saveOptions = {
  dateFields: ['startDate', 'originalHireDate', 'endDate', 'confirmationDate'],
  shouldFetch: false,
  shouldInvalidate: true,
  returnInlineErrors: true,
}

const saveMessage = 'Your changes have been successfully saved'
export const JobOrgViewContainer = (props) => {
  const [updateDropDownOptions, setUpdateDropDownOptions] = useState({})
  const [refreshOptions, setRefreshOptions] = useState(false)
  const dispatch = useDispatch()
  const confirmCancelModal = useRef(null)
  const confirmUserPasswordReset = useRef(null)
  const { setPageUIState, inEditMode } = usePageUIState({})
  const [userEmail, setUserEmail] = useState(null)
  const firstRender = useFirstRender()

  const {
    isEmployeeOnly,
    EmployeeStatus,
    employee,
    isPaymentTypeHourly,
    countries,
    companies,
    businessUnits,
    costCenters,
    departments,
    officeLocations,
    subsidiaries,
    projects,
    currencies,
    allPayrolls,
    allPaygroups,
    payrolls,
    paygroups,
    isPayrollDisabled,
    areChildEntitiesDisabled,
    showUserSSOField,
    extraFields,
    isCotUser,
  } = props

  const showUniqueContractorIDField = EmployeeStatus.shouldShowUniqueContractorIdArea
  const isUserSSOEnabled = employee.isSSOEnabled

  const showMode2faFieldOnEdit = employee.hasCredentials ? !isUserSSOEnabled : false

  const getEmployeeJobProfileFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'job-and-org',
    subSection: 'jobProfile',
    isPaymentTypeHourly,
    showUniqueContractorIDField: showUniqueContractorIDField,
    extraFields,
  })

  const showInvoicePaymentCurrencyArea = EmployeeStatus.shouldShowInvoicePaymentCurrencyArea
  const getEmployeeOrganizationFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({}),
    employee,
    section: 'job-and-org',
    countries,
    companies,
    businessUnits,
    costCenters,
    currencies,
    departments,
    officeLocations,
    subsidiaries,
    projects,
    payrolls,
    paygroups,
    areChildEntitiesDisabled,
    subSection: 'organizationDetails',
    showInvoicePaymentCurrencyArea: showInvoicePaymentCurrencyArea,
    extraFields,
  })

  const getEmployeeEmploymentFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({ isCotUser }),
    employee,
    isPayrollDisabled,
    section: 'job-and-org',
    subSection: 'employmentDetails',
    extraFields,
  })

  const getEmployeeSelfServiceFields = getFieldsBySection({
    fields: employeeFieldsConfigAlt({ isUserSSOEnabled, showUserSSOField, showMode2faFieldOnEdit }),
    employee,
    section: 'job-and-org',
    subSection: 'employeeSelfService',
    extraFields,
    isCotUser,
  })

  const defaults = {
    jobProfile: renderInlineEditFields(getEmployeeJobProfileFields),
    organizationDetails: renderInlineEditFields(getEmployeeOrganizationFields),
    employmentDetails: renderInlineEditFields(getEmployeeEmploymentFields),
    employeeSelfService: renderInlineEditFields(getEmployeeSelfServiceFields),
  }

  const [data, setData] = useState(defaults)
  const [isUpdating, setIsUpdating] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([])
  useEffect(() => {
    if (!firstRender) {
      const selectedCurrency = data.organizationDetails.find((d) => d.metaData.field === 'paymentCurrency')?.value
      const filteredPayrolls = selectedCurrency ? allPayrolls.filter((p) => selectedCurrency === p.currency) : allPayrolls
      const filteredPaygroups = selectedCurrency ? allPaygroups.filter((p) => selectedCurrency === p.currency) : allPaygroups

      const options = {
        payroll: filteredPayrolls,
        paygroup: filteredPaygroups,
      }

      setData((old) => replaceOptions(old, updateDropDownOptions, options))
    }
  }, [refreshOptions])

  const onEdit = () => {
    setPageUIState(pageUIStatesEnum.EDIT)
  }

  const onCancel = () => {
    setData(defaults)
    setPageUIState(pageUIStatesEnum.VIEW)
  }

  const onValueChange = () => {
    setFieldErrors([])
  }

  const onSubmitPayrollAndPaygroup = async (payload) => {
    if (payload.payroll) {
      await dispatch(attachEmployeeToPayroll({ employeeId: payload.id, payrollId: payload.payroll }, { shouldFetch: false }))
      await dispatch(showMessage({ body: saveMessage }))
    }
    if (payload.paygroup) {
      await dispatch(attachEmployeeToPaygroup({ employeeId: payload.id, paygroupId: payload.paygroup }, { shouldFetch: false }))
      dispatch(showMessage({ body: saveMessage }))
    }
    await dispatch(fetchPayrollEmployeePivot({ filter: createFilter({ employee: payload.id }) }))
    await dispatch(fetchPaygroupEmployeePivot({ filter: createFilter({ employee: payload.id }) }))
  }

  const onSave = () => {
    if (!isUpdating) setIsUpdating(true)
    if (isUpdating) return
    let errors = []

    const values = Object.values(data).flat()
    const sanitized = formatPayloadForJobAndOrg(values)
    const payload = { id: employee.id }

    sanitized.forEach((val) => {
      if (val.metaData.validation && val.value) {
        const compareValue = sanitized.find((info) => info.metaData?.field === val.metaData.validation.compare)
        const hasErrors = val.metaData.validation.errorFn(val.value, compareValue.value)

        if (hasErrors) {
          errors.push({
            [val.metaData.field]: hasErrors,
          })
        }
      }
      payload[val.metaData.field] = typeof val.value === 'string' ? val.value.trim() : val.value

      if (val.serverValue !== undefined) {
        payload[val.metaData.field] = val.serverValue || null
      }
    })

    if (errors.length) {
      setIsUpdating(false)
      return
    }
    saveOptions['fields'] = extraFields

    dispatch(updateEmployeeSystemUser(omit(payload, ['countries', 'company', 'isSSOEnabled']), saveOptions)).then((resp) => {
      if (resp.errors) {
        batch(() => {
          setFieldErrors([...fieldErrors, resp.errors])
          setIsUpdating(false)
        })
      } else {
        if (!areChildEntitiesDisabled && (payload.payroll || payload.paygroup)) {
          onSubmitPayrollAndPaygroup(payload)
        } else {
          dispatch(showMessage({ body: saveMessage }))
        }
      }
    })
  }
  const resetUserPassword = (data) => {
    dispatch(resetPasswordSendEmail(data)).then(() => {
      dispatch(showMessage({ body: 'A password reset link has been sent to the user’s email' }))
    })
  }

  const handleSSOclick = (data) => {
    return dispatch(updateEmployeeUserSSO(data)).then((res) => {
      if (res?.status) {
        props.onModalStateChange({ showSubmitSSOConfirmModal: true })
      }
      return res
    })
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value, { metaData }) => {
    setData((old) => {
      if (old[metaData.subCategory]?.length) {
        return {
          ...old,
          [metaData.subCategory]: old[metaData.subCategory].map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[metaData.subCategory][rowIndex],
                [columnId]: value,
                isDirty: true,
              }
            }
            return row
          }),
        }
      }
    })
    if (metaData.onChangeUpdateFieldOptions) {
      setUpdateDropDownOptions(metaData.onChangeUpdateFieldOptions)
      setRefreshOptions(!refreshOptions)
    }
  }

  return (
    <>
      <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
        <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
          <SectionHeading>
            <div className='o-layout__item u-1/1 u-1/2@desktop'>
              <div className='u-float--right'>
                {inEditMode && <InlineTableActionButtons onCancel={confirmCancelModal.current.showModal} onSave={onSave} isUpdating={isUpdating} />}
                <div className={`${btnClasses} ${inEditMode ? 'disabled' : ''}`} onClick={onEdit} disabled={inEditMode} data-testid='employee-job-org-edit'>
                  <span className='icon icon--edit' />
                </div>
              </div>
            </div>
          </SectionHeading>
        </Authorization>
      </div>
      <div className='page--employee-job-org-container'>
        <JobOrgView
          data={data}
          inEditMode={inEditMode}
          fieldErrors={fieldErrors}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          handleSSOclick={handleSSOclick}
          onValueChange={onValueChange}
          onUserPasswordReset={(data) => {
            setUserEmail(data)
            confirmUserPasswordReset.current.showModal()
          }}
          {...props}
          payrolls={payrolls}
          paygroups={paygroups}
          currencies={currencies}
        />
      </div>
      <ConfirmationModal ref={confirmCancelModal} className='c-modal' modalHeading='Confirmation' onConfirm={() => onCancel()}>
        <p>Are you sure you want to cancel? All changes made will be lost.</p>
      </ConfirmationModal>
      <ConfirmationModal ref={confirmUserPasswordReset} className='c-modal' modalHeading='Confirmation' onConfirm={() => resetUserPassword(userEmail)}>
        <p>Are you sure you want to reset the user password?</p>
      </ConfirmationModal>
    </>
  )
}

JobOrgViewContainer.propTypes = {
  isEmployeeOnly: PropTypes.bool,
  isFetching: PropTypes.bool,
  EmployeeStatus: PropTypes.object,
  employee: PropTypes.object,
  isPaymentTypeHourly: PropTypes.bool,
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  officeLocations: PropTypes.array,
  subsidiaries: PropTypes.array,
  projects: PropTypes.array,
  showUserSSOField: PropTypes.bool,
  onModalStateChange: PropTypes.func,
  extraFields: PropTypes.object,
}
