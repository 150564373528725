/**
 * Extracts initial filter values from the given state object for a specified entity and filter name.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.state - The state object containing filter data.
 * @param {string} params.entity - The entity name to extract filters from.
 * @param {string} params.filterName - The specific filter name to look for within the entity's filters.
 * @param {Object} params.filterKeys - The keys that map to filter parameters in the state.
 *
 * @returns {Array<Object>} An array of objects representing the initial filters, each containing an `id` and `value` property.
 *
 * @example
 * const state = {
 *   employee: {
 *     filters: {
 *       userFilter: {
 *         params: {
 *           fullname: 'John Doe',
 *           roleType: 'admin',
 *         },
 *       },
 *     },
 *   },
 * };
 *
 * const filterKeys = {
 *   name: 'fullname',
 *   roleTypeName: 'roleType',
 * };
 *
 * const initialFilters = getInitialFiltersFromState({
 *   state,
 *   entity: 'employee',
 *   filterName: 'userFilter',
 *   filterKeys,
 * });
 *
 * console.log(initialFilters);
 * // Output: [
 * //   { id: 'name', value: 'John Doe' },
 * //   { id: 'roleTypeName', value: 'Payroll Admin' }
 * // ]
 */
export const getInitialFiltersFromState = ({ state, entity, filterName, filterKeys }) => {
  const initialFilters = []

  Object.keys(filterKeys).forEach((key) => {
    if (state[entity].filters[filterName].params[filterKeys[key]]) {
      initialFilters.push({
        id: key,
        value: state[entity].filters[filterName].params[filterKeys[key]],
      })
    }
  })

  return initialFilters
}

/**
 * Extracts initial sorting values from the given state object for a specified entity and filter name.
 *
 * @param {Object} params - The parameters for retrieving the initial sort configuration.
 * @param {Object} params.state - The current state of the application.
 * @param {string} params.entity - The entity type to retrieve sorting for.
 * @param {string} params.filterName - The name of the filter containing sorting parameters.
 * @param {Object} params.sortKeys - An object where keys represent the internal sort identifiers and values represent the corresponding sort keys in the state.
 *
 * @returns {Array} An array of objects representing the initial sort configuration.
 * Each object contains an `id` representing the sort key and a `desc` boolean indicating the order (true for descending, false for ascending).
 *
 * @example
 * const state = {
 *   document: {
 *     filters: {
 *       myFilter: {
 *         params: {
 *           sort: [
 *             { name: 'date', order: 'asc' },
 *             { name: 'name', order: 'desc' },
 *           ],
 *         },
 *       },
 *     },
 *   },
 * }
 *
 * const sortKeys = {
 *   dateSort: 'date',
 *   nameSort: 'name',
 * }
 *
 * const result = getInitialSortFromState({
 *   state,
 *   entity: 'document',
 *   filterName: 'myFilter',
 *   sortKeys,
 * })
 *
 * console.log(result)
 * // Output: [
 * //   { id: 'dateSort', desc: false },
 * //   { id: 'nameSort', desc: true }
 * // ]
 */
export const getInitialSortFromState = ({ state, entity, filterName, sortKeys }) => {
  const initialSort = []

  Object.keys(sortKeys).forEach((key) => {
    const sortItem = state[entity].filters[filterName].params.sort.find((item) => item.name === sortKeys[key])
    if (sortItem) {
      initialSort.push({
        id: key,
        desc: sortItem.order === 'desc',
      })
    }
  })

  return initialSort
}
