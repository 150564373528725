import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyStepPivot'

class CompanyCountryStep extends CRUDModel {}
CompanyCountryStep.modelName = 'CompanyCountryStep'
CompanyCountryStep.actionTypes = actionTypes

CompanyCountryStep.fields = {
  id: attr(),
  companyProcess: fk('CompanyCountryProcess', 'companyCountrySteps'),
  countryStep: fk('CountryStep', 'companyCountrySteps')
}

export default CompanyCountryStep
