import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import TermCategoriesAndSub from 'components/TermCategoriesAndSub'

const TermsFormFields = ({ ...props }) => {
  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <Form {...props} showSubmitBtn colorClassBtn={'c-btn--curious'} btnText={'Submit'}>
          <Field
            name='name'
            label='Name'
            labelClassName='c-label'
            component={InputField}
            type='text' />
          <Field
            name='definition'
            label='Definition'
            labelClassName='c-label'
            component={InputField}
            type='text' />
          {
            props.subCategories
              ? <TermCategoriesAndSub {...props} />
              : null
          }
        </Form>
      </div>
    </div>
  )
}

export default TermsFormFields
