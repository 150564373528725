import createSelector from 'utils/createSelector'
import { isCot } from 'redux/selectors/auth'
import { owners } from 'redux/config/tasks'
import _ from 'lodash'
import { numberToLetter } from 'utils/strings'

const getPayrollProcessId = (state, props) => parseInt(props.payrollProcessId)
const getPayrollId = (state, props) => parseInt(props.payrollId)
const getTypeClass = (state, props) => props.typeClass
const getTaskActive = (state, props) => props.taskActive
// Should include Steps, these don't have Tasks?
const getShouldIncludeEmptySteps = (state, props) => props.shouldIncludeEmptySteps

/*
 * Get task owner according to its taskableType
 */
const getTaskOwner = (payrollTask) => {
  const hasTasks = payrollTask.payrollTaskOwners.count()
  /*
   * When taskableType is "Company", get the actual task owner.
   * It's relation from payrollTask to owners through payrollTaskOwners
   * (not property owner of payrollTask as it was before that).
   */
  if (payrollTask.taskableType === 'Company' && hasTasks) {
    return payrollTask.payrollTaskOwners.toModelArray()[0].owner.ref
  }

  /*
   * When taskableType is "BusinessUnit", get the task owner of every single business unit
   */
  if (payrollTask.taskableType === 'BusinessUnit' && hasTasks) {
    // Get payroll task owners
    const payrollTaskOwners = payrollTask.payrollTaskOwners.toModelArray()

    // Return label for multi and the owners' props
    return {
      multi: 'Multiple owners',
      multiOwners: payrollTaskOwners.map((pto) => ({
        id: pto.owner.id,
        fullname: pto.owner.fullname,
        schema: pto.owner.company.parentCompany.schema,
        hasCredentials: pto.owner.hasCredentials,
      })),
    }
  }

  return {}
}

/*
 * Build payroll process tree
 */
export const getPayrollProcessTree = createSelector(
  getPayrollProcessId,
  getTypeClass,
  isCot,
  getTaskActive,
  getShouldIncludeEmptySteps,
  ({ PayrollProcess, PayrollTaskOwnerScopes, EmployeeSystemUser }, payrollProcessId, typeClass, isUserCot, taskActive, shouldIncludeEmptySteps) => {
    if (payrollProcessId) {
      const process = PayrollProcess.withId(payrollProcessId)
      const payroll = process.payrollService.payroll

      const tree = {
        name: payroll.name,
        isPayrollProcess: true,
        owner: process.owner ? { ...process.owner.ref } : null,
        keyEvent: process.companyProcess.countryProcess.keyEvent
          ? process.companyProcess.countryProcess.keyEvent
          : process.companyProcess.countryProcess.process.keyEvent,
        payDate: payroll.getFormattedPayDate(),
        steps: [],
      }

      process.payrollSteps
        .orderBy('position')
        .toModelArray()
        .forEach((payrollStep, indexStep) => {
          const step = {
            id: payrollStep.id,
            name: `${numberToLetter(indexStep + 1).toUpperCase()}. ${payrollStep.name}`,
            isMoveable: payrollStep.extraData ? payrollStep.extraData.isMoveable : false,
            tasks: payrollStep.payrollTasks
              .orderBy('position')
              .toModelArray()
              .filter((payrollTask) => {
                const payrollTaskOwner = payrollTask.globalOwner

                // Here we have 2 cases:
                // 1.(Boolean) `taskActive` is passed in. In this case we can get either the active or inactive tasks.
                // 2.(undefined) It's not passed in, then we return both type of tasks and we don't do any filtration.
                // Because of this, firstly we have to check should we do  the filtration by `active` task field.
                const shouldFilterByActive = taskActive !== undefined
                // We set `true` as default value, if we shouldn't do the filtration,
                // in order to easily attach the filter to the rest ones: `...restFilters && activeFilter`.
                // Setting it to `true`, it won't have any impact to the rest filers.
                const activeFilter = shouldFilterByActive ? payrollTask.active === taskActive : true

                if (typeClass && typeClass !== 'vendor') {
                  return payrollTaskOwner !== owners.cot && payrollTaskOwner !== owners.vendor && payrollTaskOwner !== owners.software && activeFilter
                }

                // Dont show 'Not needed' action/s with owner 'Payslip'('COT'), to the ER user
                if (!isUserCot && payrollTaskOwner === owners.cot) return payrollTask.active && activeFilter

                return activeFilter
              })
              .map((payrollTask, index) => ({
                ...payrollTask,
                id: payrollTask.id,
                name: `${numberToLetter(indexStep + 1).toUpperCase()}${index + 1}. ${payrollTask.name}`,
                ownerOld: payrollTask.owner ? payrollTask.owner.ref : null,
                owner: getTaskOwner(payrollTask),
                deadline:
                  !isUserCot && payrollTask.name === 'GTN Calculations'
                    ? payrollStep.payrollTasks.toModelArray()[index + 1].deadline // Get next deadline for GTN Calculations task
                    : payrollTask.deadline,
              })),
          }

          if (step.tasks.length || shouldIncludeEmptySteps) tree.steps.push(step)
        })

      return tree
    }
  }
)

export const getPayrollProcessTasksOwnersIds = createSelector(
  getPayrollProcessId,
  getTypeClass,
  isCot,
  ({ PayrollProcess }, payrollProcessId, typeClass, isUserCot) => {
    if (payrollProcessId) {
      const process = PayrollProcess.withId(payrollProcessId)

      const payrollOwners = process.payrollSteps
        .toModelArray()
        .map((payrollStep) =>
          payrollStep.payrollTasks.toModelArray().map((payrollTask) => payrollTask.payrollTaskOwners.toRefArray().map((pivot) => pivot.owner))
        )

      payrollOwners.push(process.ref.owner)

      return _.uniq(_.flattenDeep(payrollOwners))
    }
  }
)

export const getPayrollProcessIdByPayrollId = createSelector(getPayrollId, ({ Payroll }, payrollId) => {
  const payroll = Payroll.withId(payrollId)
  return payroll.payrollServices.first().payrollProcesses.first().id
})
