import createSelector from 'utils/createSelector'
import { insertItemInOrderedList, getPayrollStepById } from './payrollSteps'
import { isCot } from 'redux/selectors/auth'
import { owners } from 'redux/config/tasks'
import { getAlphabeticName } from '../../../../../../../../../redux/models/payrollStep'

const getPayrollTaskId = (state, props) => parseInt(props.payrollTaskId)
const getPayrollStepId = (state, props) => parseInt(props.payrollStepId)
const getTaskName = (state, props) => props.name
const getTaskPosition = (state, props) => parseInt(props.position)

export const getPayrollTask = createSelector(getPayrollTaskId,
  ({ PayrollTask }, payrollTaskId) => {
    const payrollTask = PayrollTask.withId(payrollTaskId)
    return {
      ...payrollTask.ref,
      owner: payrollTask.taskableType === 'Company' && payrollTask.payrollTaskOwners.toModelArray().length
        ? payrollTask.payrollTaskOwners.toModelArray()[0].owner.ref.id : null
    }
  }
)

const _setAlphabeticName = step => (item, i) => `${getAlphabeticName(step.position)}${i + 1}. ${item.name}`

const getPayrollTasksByPayrollStepId = createSelector(getPayrollStepId, isCot,
  ({ PayrollTask }, payrollStep, isCot) => PayrollTask
    .filter(task => (
      (task.payrollStep === payrollStep) &&
      (!isCot ? task.globalOwner !== owners.cot : true) // If it's not COT, then we should not return the COT tasks
    ))
    .orderBy('position')
    .toModelArray()
    .map((task, i) => ({
      ...task.ref,
      alphabeticName: _setAlphabeticName(task.payrollStep)(task, i),
      alphabeticShortName: `${task.payrollStep.getAlphabeticName()}${task.position + 1}`,
    }))
)

// Here we convert Tasks as Dropdown position options and add 'Last' position option
export const getPayrollTasksOptionsByPayrollStepId = createSelector(getPayrollTasksByPayrollStepId,
  (session, tasks) => [
    ...tasks.map(({ alphabeticShortName, position }) => ({
      value: position,
      label: alphabeticShortName
    })),
    {
      // Here we send such a big number, not a real use-case, in order the newly created Step to be the Last one.
      // Later, after the new Step is created, the BE will update the position with the real index.
      value: 1000,
      label: 'Last'
    }
  ]
)

/**
 * Insert a new Task, in an ordered list of Tasks and return all the Tasks ordered by position.
 *
 * Also if we pass an `id` param, it means we're changing the position of already created Task.
 */
export const getReorderedPayrollTasksByPayrollStepId = createSelector(
  getPayrollTasksByPayrollStepId, getPayrollTaskId, getTaskName, getTaskPosition, getPayrollStepById,
  (session, tasks, id, name, position, step) => (
    insertItemInOrderedList(tasks, id, name, position, _setAlphabeticName(step))
  ))
