import reportOptionsConfig from 'configs/reports'
import newTypes from 'redux/config/newReportTypes'
import { getEntityCategoryField } from 'routes/Reporting/utils/reportFieldUtils'
import { extrafieldEntityKey } from 'utils/enums/fixedCategoriesField'
import { isDurationMoreThanAMonthAndYear } from 'utils/fnkit/dates/ranges'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { formErrorLabels } from 'utils/locales/errors.en'

const REPORT_TYPE = reportOptionsConfig.CUSTOM_BUILD_REPORT.VALUE

export const reportDesignerSchemaFields = {
  reportName: 'reportName',
  fromDate: 'fromDate',
  toDate: 'toDate',
  country: 'country',
  company: 'company',
  payroll: 'payroll',
  payrun: 'payrollInstance',
  typeOfEmploymentEngagement: 'employmentStatus',
  businessUnit: 'businessUnit',
  costCenter: 'costCenter',
  department: 'department',
  categories: 'categories',
}
export const fieldsToRegenerate = [
  reportDesignerSchemaFields.fromDate,
  reportDesignerSchemaFields.toDate,
  reportDesignerSchemaFields.country,
  reportDesignerSchemaFields.company,
  reportDesignerSchemaFields.payroll,
  reportDesignerSchemaFields.payrun,
  reportDesignerSchemaFields.businessUnit,
  reportDesignerSchemaFields.costCenter,
  reportDesignerSchemaFields.department,
]

export const reportDesignerDateFields = [reportDesignerSchemaFields.fromDate, reportDesignerSchemaFields.toDate]

export const reportDesignerRequiredFields = [
  ...reportDesignerDateFields,
  reportDesignerSchemaFields.country,
  reportDesignerSchemaFields.company,
  reportDesignerSchemaFields.payroll,
  reportDesignerSchemaFields.payrun,
]
export const reportDesignerDatesResetDependencies = [
  reportDesignerSchemaFields.country,
  reportDesignerSchemaFields.company,
  reportDesignerSchemaFields.payroll,
  reportDesignerSchemaFields.payrun,
  reportDesignerSchemaFields.businessUnit,
  reportDesignerSchemaFields.costCenter,
  reportDesignerSchemaFields.department,
  reportDesignerSchemaFields.categories,
]
export const reportDesignerCompanyResetDependencies = [
  reportDesignerSchemaFields.payroll,
  reportDesignerSchemaFields.payrun,
  reportDesignerSchemaFields.businessUnit,
  reportDesignerSchemaFields.costCenter,
  reportDesignerSchemaFields.department,
  reportDesignerSchemaFields.categories,
]

/**
 * Retrieves the field IDs that need to be reset based on a
 * given field ID and form data.
 *
 * @param {string} fieldId - The ID of the field that is being checked for
 * reset conditions.
 * @param {Object} formData - The form data object that contains the current
 * state of all form fields.
 *
 * @returns {Object} - An object containing two properties:
 *  - `fieldIdsToReset` {Array<string> | undefined}: An array of field IDs that
 * should be reset.
 *  - `fieldIdsValueToReset` {Array<string> | undefined}: An array of field IDs whose values should be reset.
 */
export const getReportResetFieldIds = (fieldId, formData) => {
  const resetCases = [
    /**
     * Checks if the current field ID matches the 'fromDate' field ID
     * reset the date picket toDate every time that we change the fromDate
     */
    {
      isValid: () => fieldId === reportDesignerSchemaFields.fromDate,
      fieldIdsToReset: [reportDesignerSchemaFields.toDate, ...reportDesignerDatesResetDependencies],
      fieldIdsValueToReset: {
        toDate: undefined,
      },
    },
    {
      /*
       * Valid when is a date fieldId and the rage of date is
       * more than a month and a year and the country is selected
       */
      isValid: () => isDurationMoreThanAMonthAndYear(formData) && !isEmpty(formData?.country) && reportDesignerDateFields.includes(fieldId),
      fieldIdsToReset: reportDesignerDatesResetDependencies,
      fieldIdsValueToReset: {},
    },
    /*
     * Valid when the user change the dates and the above
     * criterion of ranges does not apply.
     */
    {
      isValid: () => reportDesignerDateFields.includes(fieldId),
      fieldIdsToReset: reportDesignerDatesResetDependencies,
      fieldIdsValueToReset: {},
    },
    /*
     * Valid when the fieldId being changed is the country
     */
    {
      isValid: () => fieldId === reportDesignerSchemaFields.country,
      fieldIdsToReset: [reportDesignerSchemaFields.company, ...reportDesignerCompanyResetDependencies],
      fieldIdsValueToReset: {},
    },
    {
      isValid: () => fieldId === reportDesignerSchemaFields.company,
      fieldIdsToReset: reportDesignerCompanyResetDependencies,
      fieldIdsValueToReset: {},
    },
    /*
     * Valid when payrolls are selected or deselected.
     */
    {
      isValid: () => fieldId === reportDesignerSchemaFields.payroll,
      fieldIdsToReset: [reportDesignerSchemaFields.payrun],
      fieldIdsValueToReset: {},
    },
  ]
  const validCase = resetCases.find((caseToValidate) => caseToValidate.isValid())
  return {
    fieldIdsToReset: validCase?.fieldIdsToReset,
    fieldIdsValueToReset: validCase?.fieldIdsValueToReset,
  }
}
/**
 * Custom validation function for the report designer form.
 *
 * @param {Object} formData - The data from the form that needs to be validated.
 * This object contains key-value pairs where the key
 * represents the field name and the value represents the field's value.
 * @param {Object} errors - The errors object that will be modified to include validation errors.
 * Each key corresponds to a field in the form, and the value is an
 * object with methods to add errors.
 *
 * @returns {Object} errors - The modified errors object containing any
 * validation errors that were found during the validation process.
 */
export const reportDesignerCustomValidate = (formData = {}, errors = {}) => {
  Object.keys(formData).forEach((k) => {
    const field = formData[k]
    const hasError = reportDesignerRequiredFields.includes(k) && isEmpty(field)
    if (hasError) {
      errors[k]?.addError(formErrorLabels.requiredOption)
    }
  })
  return errors
}

/**
 * Groups items by their category.
 *
 * @param {Array} fields - The array of items to group.
 * @returns {Object} - An object with keys as categories and values as arrays of items.
 */
const groupFieldsByCategory = (fields) => {
  return fields.reduce((result, item) => {
    if (!result[item.category]) {
      result[item.category] = []
    }
    result[item.category].push(item)
    return result
  }, {})
}

/**
 * Sorts fields alphabetically by 'feName' within each 'category'.
 *
 * @param {Array} fields - The array of items to sort.
 * @returns {Array} - The sorted array.
 */
export const sortFieldsByCategoryAndName = (fields) => {
  const groupedFields = groupFieldsByCategory(fields)

  for (const category in groupedFields) {
    groupedFields[category].sort((a, b) => a.feName.localeCompare(b.feName))
  }

  return Object.values(groupedFields).flat()
}

/**
 * Normalizes the category fields within an array of categories.
 *
 * @param {Array} categories - An array of category objects that need to be normalized. Each category object can contain various fields that might require
 * normalization.
 *
 * @returns {Array} - The normalized array of category objects.
 */
export const normalizeReportDesignerCategoryFields = (categories = []) =>
  categories.reduce((fields, category) => {
    const options = category.options?.filter((o) => o.selected)
    if (options?.length) {
      options?.forEach((field) => {
        const value = field.value
        const isExtrafield = value.includes(`${extrafieldEntityKey}/`)
        return fields.push({
          field: isExtrafield ? value.replace(`${extrafieldEntityKey}/`, '') : value,
          feName: field.label,
          category: category.label,
          entity: isExtrafield ? extrafieldEntityKey : getEntityCategoryField(category.value, value),
        })
      })
    }
    return sortFieldsByCategoryAndName(fields)
  }, [])

/**
 * Normalizes the fields within the report designer form data.
 *
 * @param {Object} formData - An object representing the form data to be
 * normalized.
 * This object contains key-value pairs where the key represents the field name
 * and the value represents the field's value.
 *
 * @returns {Object} - The normalized form data object.
 */
export const normalizeReportDesignerFields = (formData = {}) => {
  if (isEmpty(formData)) {
    return
  }
  const { categories, reportName, ...rest } = formData
  return {
    type: newTypes[REPORT_TYPE].type,
    feCategoryId: REPORT_TYPE,
    category: newTypes[REPORT_TYPE].category,
    subcategory: newTypes[REPORT_TYPE].subcategory,
    filters: {
      ...rest,
      fields: normalizeReportDesignerCategoryFields(categories),
    },
  }
}

/**
 * Maps default selected options to the appropriate categories based on the provided fields.
 *
 * @param {Array} fields - Array of field objects, each containing a category and field value.
 * @param {Array} categories - Array of category objects, each containing a label and options array.
 * @returns {Array} Updated categories array with the selected options marked.
 */
export const mapCategoryFieldsDefaultOptions = (fields = [], categories = []) => {
  if (isEmpty(fields) || isEmpty(categories)) {
    return categories
  }
  const selectedOptions = fields.reduce((categories, { category, field }) => {
    if (!categories[category]) {
      categories[category] = []
    }
    categories[category].push(field)
    return categories
  }, {})
  return categories.map((category) => {
    const defaultOptions = selectedOptions[category.label] || []
    const hasDefaultOptions = !isEmpty(defaultOptions) && !isEmpty(category.options)
    if (hasDefaultOptions) {
      category.options = category.options?.map((option) => {
        const value = option.value.replace(`${extrafieldEntityKey}/`, '')
        return {
          ...option,
          selected: defaultOptions.includes(value),
        }
      })
    }
    return category
  })
}

/**
 * Checks if the given form data object contains all required fields.
 *
 * @param {Object} [formData={}] - The form data to be checked.
 * @returns {boolean} - Returns true if all required fields are present and not empty, otherwise false.
 */
export const hasAllRequiredFields = (formData = {}) => {
  const requiredFieldsData = Object.keys(formData).filter((k) => reportDesignerRequiredFields.includes(k) && !isEmpty(formData[k]))
  return requiredFieldsData.length === reportDesignerRequiredFields.length
}
