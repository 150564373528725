import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import AttachingToPayrollContainer from '../containers/AttachingToPayrollContainer'
import PayrollHeadingContainer from '../../../../../containers/PayrollHeadingContainer'
import useQuery from 'hooks/useQuery'

export default props => <InnerWrapper>
  <PayrollHeadingContainer
    heading='Employees - Assigning to Payroll'
    {...props} />
  <EmployeesSearchBarContainer query={useQuery()} {...props} />
  <AttachingToPayrollContainer {...props} />
</InnerWrapper>
