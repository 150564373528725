import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
let classNames = require('classnames')

/**
 * Navigation tab item
 *
 * @link https://stackoverflow.com/a/35080727/4312466
 */
function TabItem (props) {
  const location = useLocation()
  const { to, children } = props
  const isActive = location.pathname === to || location.pathname.includes(to)

  const classes = classNames({
    'c-tabs__item': true,
    'c-tabs__item--selected': isActive
  })

  return (
    // eslint-disable-next-line react/prop-types
    <div className={classes} data-title={children} data-testid={props['data-testid']}>
      <Link
        to={to}
      >
        {children}
      </Link>
    </div>
  )
}

TabItem.contextTypes = { router: PropTypes.object }

TabItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
}

export default TabItem
