import AttachRoutes from './routes/Assign'
import DetachRoutes from './routes/Unassign'
import CoreLayout from 'layouts/PageLayout/PageLayout'

export default (store) => ({
  path : 'employees',
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ AttachRoutes(store), DetachRoutes(store) ]
})
