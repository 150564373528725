import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchVendorCountryTaskPivotIfNeeded, updateVendorCountryTask } from 'redux/actions/vendorCountryTaskPivot'
import TimelineForm from '../components/TimelineForm'
import { getVendorCountryTask } from '../selectors/vendorCountryTask'
import PropTypes from 'prop-types'
import { showMessage } from 'redux/actions/modal'

class TimelineFormEditContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchVendorCountryTaskPivotIfNeeded()
  }

  render () {
    return <TimelineForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchVendorCountryTaskPivotIfNeeded: () => dispatch(fetchVendorCountryTaskPivotIfNeeded()),
    onSubmit: data => dispatch(updateVendorCountryTask(data, data.id)).then(() => {
      props.onSubmit()
      dispatch(showMessage({
        body: `Timeline successfully updated.`,
      }))
    })
  }
}

const mapStateToProps = (state, props) => {
  const { vendorCountryTaskPivot } = state

  if (isFetching([vendorCountryTaskPivot])) return { isFetching: true }

  const { vendorCountryTaskId } = props

  const vendorCountryTask = getVendorCountryTask(state, { countryTaskId: props.countryTaskId, vendorCountryServiceId: props.vendorCountryServiceId })

  return {
    initialValues: {
      id: vendorCountryTaskId,
      deadlineMonthly: vendorCountryTask ? vendorCountryTask.deadlineMonthly : null,
      deadlineFortnightly: vendorCountryTask ? vendorCountryTask.deadlineFortnightly : null,
      deadlineWeekly: vendorCountryTask ? vendorCountryTask.deadlineWeekly : null
    }
  }
}

TimelineFormEditContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  vendorCountryTask: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'timelineEdit'
})(TimelineFormEditContainer))
