import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/noteUsers'

class NoteUser extends CRUDModel {}
NoteUser.modelName = 'NoteUser'

NoteUser.fields = {
  id: attr()
}
NoteUser.actionTypes = actionTypes

export default NoteUser
