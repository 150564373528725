import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/paygroups'

class Paygroup extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      [Model.actionTypes.detachPaygroupEmployees]: (Model, { payload: { paygroupId, data } }) => {
        Model.withId(paygroupId).paygroupEmployees.filter(entity => {
          return data.hasOwnProperty(entity.employee)
        }).delete()
      },
    }
  }
}
Paygroup.modelName = 'Paygroup'

Paygroup.fields = {
  id: attr(),
  currency: fk('Currency', 'paygroups'),
  company: fk('Company', 'paygroups'),
}
Paygroup.actionTypes = actionTypes

export default Paygroup
