import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeTermPivot'

class EmployeeCompanyCountryTerm extends CRUDModel {
  isFixed () {
    return this.amount
  }

  isVariable () {
    return !this.isFixed()
  }
}
EmployeeCompanyCountryTerm.modelName = 'EmployeeCompanyCountryTerm'
EmployeeCompanyCountryTerm.actionTypes = actionTypes

EmployeeCompanyCountryTerm.fields = {
  id: attr(),
  employee: fk('EmployeeSystemUser', 'employeeCompanyCountryTerms'),
  term: fk('CompanyCountryTerm', 'employeeCompanyCountryTerms'),
}

export default EmployeeCompanyCountryTerm
