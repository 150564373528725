import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyDataProtectionFieldsPivot'

class CompanyDataProtectionField extends CRUDModel {}
CompanyDataProtectionField.modelName = 'CompanyDataProtectionField'
CompanyDataProtectionField.actionTypes = actionTypes

CompanyDataProtectionField.fields = {
  id: attr(),
  dataProtectionField: fk('DataProtectionField', 'companyDataProtectionFields')
}

export default CompanyDataProtectionField
