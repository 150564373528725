import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollEmployeePivot'

class PayrollEmployee extends CRUDModel {}
PayrollEmployee.modelName = 'PayrollEmployee'

PayrollEmployee.fields = {
  id: attr(),
  payroll: fk('Payroll', 'payrollEmployees'),
  employee: fk('EmployeeSystemUser', 'payrollEmployees')
}
PayrollEmployee.actionTypes = actionTypes

export default PayrollEmployee
