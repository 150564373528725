import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'

class ServiceElementForm extends React.Component {
  render () {
    return <div className='o-layout'>
      <form>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='serviceElement'
            label='Service element'
            labelClassName='c-label'
            component={InputField}
            type='text'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='type'
            label='Type'
            labelClassName='c-label'
            component={CustomSelectField}
            placeholder='Select'
            clearable={false}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' }
            ]}
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='icpCost'
            label='ICP Cost'
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='clientPrice'
            label='Client price'
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='margin'
            label='Margin'
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='minThreshold'
            label='Min threshold'
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='Employees threshold'
            label='employeesThreshold'
            labelClassName='c-label'
            component={InputField}
            type='number'
          />
        </div>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <Field
            name='currency'
            label='Currency'
            labelClassName='c-label'
            component={CustomSelectField}
            placeholder='Select'
            clearable={false}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' }
            ]}
          />
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            type='submit'
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small' >
            Save
          </button>
        </div>
      </form>
    </div>
  }
}

export default reduxForm({
  form: 'serviceElementForm'
})(ServiceElementForm)
