import React from 'react'
import AsyncImportContainer from 'routes/Import/containers/AsyncImportContainer'
import { getQuery } from 'utils/query'

const AsyncImportWrapper = (props) => {
  const { jobId } = getQuery(props.location.search, 'jobId')

  return <AsyncImportContainer key={jobId} {...props} />
}

export default AsyncImportWrapper
