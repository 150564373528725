import React from 'react'
import { connect } from 'react-redux'
import DocumentsView from '../components/DocumentsView'
import Fetcher from 'containers/Fetcher'
import { getCompanyWithCountry } from 'redux/selectors/company'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { getPayrollInstanceByIdWithCurrentStep } from 'redux/selectors/payrollInstance'
import { createFilter } from 'utils/redux/filter'
import { getVendorRestrictedStatus } from 'redux/selectors/vendorSwitch'
import { getDocumentTypes } from 'redux/selectors/documentTypes'

const mapStateToProps = (state, props) => {
  const { match: { params }, payrollInstanceId } = props

  const company = getCompanyWithCountry(state, { companyId: params.companyId })
  const typeTenant = getParentCompanyById(state, { parentCompanyId: company.parentCompany }).schema

  const currentStep = payrollInstanceId ? getPayrollInstanceByIdWithCurrentStep(state, { payrollInstanceId }).currentStep.name : null

  return {
    typeTenant,
    companyName: company.name,
    countryName: company.country.name,
    payrollName: props.instance ? props.instance.payrollName : '',
    allDocTypes: getDocumentTypes(state),
    currentStep,
    isVendorRestricted: getVendorRestrictedStatus(state, { vendorId: params.vendorId, companyId: params.companyId })
  }
}

const Container = connect(mapStateToProps)(DocumentsView)

const PayrollDocumentsFetcher = Fetcher(Container, [
  { name: 'countries', params: [{ forceVendorTenantAPI: true }] },
  { name: 'companies', params: [{ forceVendorTenantAPI: true }] },
  'parentCompanies',
  {
    name: 'payrollInstanceStep',
    params: [{
      _computed: {
        filter: (state, { payrollInstanceId }) => createFilter({
          payrollInstance: payrollInstanceId
        })
      },
      forceVendorTenantAPI: true
    }]
  }
])

const DocumentsFetcher = Fetcher(Container, [
  { name: 'countries', params: [{ forceVendorTenantAPI: true }] },
  { name: 'companies', params: [{ forceVendorTenantAPI: true }] },
  'parentCompanies'
])

export default props => {
  if (props.typeClass === 'payrollInstance') {
    return <PayrollDocumentsFetcher {...props} />
  }
  return <DocumentsFetcher {...props} />
}
