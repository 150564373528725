import React from 'react'

export const CircleICon = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='8' cy='8' r='8' fill='#5292DD' />
    <rect x='5.17188' y='12.5723' width='2.46434' height='10.4655' rx='1.14286' transform='rotate(-135 5.17188 12.5723)' fill='white' />
    <rect width='2.46434' height='10.4655' rx='1.14286' transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 10.8291 12.7168)' fill='white' />
  </svg>
)

export const CircleExclamationIcon = ({ className, children, color = '#EB3528', ...props }) => (
  <div className={className}>
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='9.5' cy='9.5' r='9.5' fill={color} />
      <rect x='8' y='8' width='3' height='8' rx='1' fill='white' />
      <rect x='8' y='3' width='3' height='3' rx='1' fill='white' />
    </svg>
    {children}
  </div>
)
