import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import CreateForm from '../components/PayrollTask/CreateForm'
import { createPayrollTask } from 'redux/actions/payrollTask'
import { getPayrollStepsOptionsByPayrollProcessId } from '../selectors/payrollSteps'
import { getPayrollTasksOptionsByPayrollStepId,
  getReorderedPayrollTasksByPayrollStepId } from '../selectors/payrollTasksORM'
import { isCot } from 'redux/selectors/auth'

class PayrollTaskCreateContainer extends React.Component {
  render () {
    return <CreateForm {...this.props} />
  }
}

PayrollTaskCreateContainer.propTypes = {
  payrollProcessId: PropTypes.number
}

const mapDispatchToProps = (dispatch, { onSubmit, params: { companyId } }) => ({
  onSubmit: data => {
    return dispatch(createPayrollTask({
      ...data,
      active: true,
      deadline: null,
      owner: null,
      taskableType: 'Company',
      companyId
    })).then(onSubmit)
  }
})

const mapStateToProps = (state, { payrollProcessId }) => {
  const { payrollStep : payrollStepId, name, position = null } = getFormValues('payrollTaskCreate')(state) || {}

  const globalOwnersOptions = [
    { label: 'Company', value: 'Company' },
    { label: 'Payroll Partner', value: 'Payroll Partner' }
  ]

  return {
    globalOwnersOptions: isCot(state) ? [{ label: 'Payslip', value: 'Payslip' }, ...globalOwnersOptions] : globalOwnersOptions,
    stepsOptions: getPayrollStepsOptionsByPayrollProcessId(state, { payrollProcessId, shouldUseIdAsValue: true }),
    tasksOptions: payrollStepId ? getPayrollTasksOptionsByPayrollStepId(state, { payrollStepId }) : [],
    tasks: payrollStepId ? getReorderedPayrollTasksByPayrollStepId(state, { payrollStepId, name, position }) : [],
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollTaskCreate'
})(PayrollTaskCreateContainer))

export default Fetcher(Container, [
  'payrollStep',
  'payrollTask',
])
