import { PhoneNumberUtil } from 'google-libphonenumber'
import _ from 'lodash'
import moment from 'moment'
const phoneUtil = PhoneNumberUtil.getInstance()

export const required = (value) => {
  if (_.isArray(value) && _.isEmpty(value)) return 'Required'

  if (value === 0) return undefined

  return value ? undefined : 'Required'
}

/**
 * Check if provided email address is valid
 *
 * @param value - provided email
 * @return string
 */
export const emailValidation = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined)

/**
 * Check if provided bank account is valid
 *
 * @param value - provided bank account
 * @return string
 */
export const bankAccountValidation = (value) => (value && !/^[a-zA-Z0-9]*$/i.test(value) ? 'Invalid bank account' : undefined)

/**
 * Check if provided end date is after start date
 *
 * @param value - provided end date
 * @return string
 */
export const endDateValidation = (value, allValues) =>
  allValues.startDate && allValues.endDate
    ? allValues.startDate > allValues.endDate
      ? 'End date should be greater than or equal to Start Date'
      : undefined
    : undefined

export const inlineEndDateValidation = (value, compareValue) => {
  let hasError
  const momentValue = value instanceof Date ? value : moment(value, 'DD/MM/YYYY').toDate()
  const momentCompare = compareValue instanceof Date ? compareValue : moment(compareValue, 'DD/MM/YYYY').toDate()

  if (value && compareValue) {
    hasError = momentValue < momentCompare ? 'End date should be greater than or equal to Start Date' : undefined
  }
  return hasError
}

export const inlineStartDateValidation = (value, compareValue) => {
  let hasError
  const momentValue = value instanceof Date ? value : moment(value, 'DD/MM/YYYY').toDate()
  const momentCompare = compareValue instanceof Date ? compareValue : moment(compareValue, 'DD/MM/YYYY').toDate()

  if (value && compareValue) {
    hasError = momentValue > momentCompare ? 'Start date should be less than or equal to End Date' : undefined
  }
  return hasError
}

export const phoneValidation = (value) => {
  // No value, no error :)
  if (!value) return undefined

  let isPhoneValid
  let error
  const validationMessage = 'Please type a valid number'

  // We wrap the validation in try/catch block,
  // because `phoneUtil` method expects country code to be passed in,
  // when the phone number length (`value`) is too short.
  // Otherwise, if there're enough characters, then the method tries to guess the country code, that suit's perfectly.
  // If we want to skip the block, then we should explicitly pass the country code, i.e. (`{ code: 359, phone: 8888}`),
  // but this will require us to always transform the phone number object to a string, before sending it to the BE.
  // Currently we don't perform any additional actions and just sending the phone input,
  // which looks the simpler solution.
  try {
    const number = phoneUtil.parseAndKeepRawInput(value)
    isPhoneValid = phoneUtil.isValidNumber(number)
  } catch (errors) {
    error = validationMessage
  }

  if (!isPhoneValid || error) return validationMessage

  return undefined
}

/**
 * Count the number of decimals
 *
 * @param {Number|String} value
 * @returns {number}
 */
const countDecimals = (value) => (value % 1 ? value.toString().split('.')[1].length : 0)

const getDefaultScaleError = (scale) => `Fx rate conversion rates should have a maximum of ${scale} digits after the decimal point.`

const getMinimumDefaultScaleError = (scale) => `The version of the document should be greater than ${scale}`

/**
 * Check the value, against the maximum allowed scale
 *
 * @param {Number} scale - Maximum allowed scale
 * @param {Function} getError - The error message to be returned, in the case the validation fails
 * @return function - Validation function
 */
export const makeScaleValidation =
  (scale, getError = getDefaultScaleError) =>
    (value) =>
      value && countDecimals(value) > scale ? getError(scale) : undefined

/**
 * Check the value, against the minimum allowed scale
 *
 * @param {Number} scale - Minimum allowed scale
 * @param {Function} getError - The error message to be returned, in the case the validation fails
 * @return function - Validation function
 */
export const makeMinimumScaleValidation =
  (scale, getError = getMinimumDefaultScaleError) =>
    (value) =>
      value <= scale ? getError(scale) : undefined

export const maxIntegerValue = (value) => (value && value > 2147483647 ? `Value must be an integer` : undefined)
export const minLengthOfZero = (value) => (value && value < 1 ? `Value must be greater than 0` : undefined)
export const mustBeANumber = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined)
export const valueCantBeAnEmptySpace = (value) => (value && value.trim() === '' ? 'A Valid ID must be provided' : undefined)
