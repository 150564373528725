import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import SubmitButtons from './SubmitButtons'
import CheckboxOption from 'components/form/select/CheckboxOption'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import ChangedBySelectContainer from '../containers/ChangedBySelectContainer'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import moment from 'moment'
import MostRecentRunReportItem from 'routes/Reporting/components/MostRecentRunReportItem'
import { sortByLabel } from 'utils/strings'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { getDateBasedOnMaxDate } from 'utils/fnkit/dates/checks'

class FiltersChangeLogReport extends React.Component {
  constructor (props) {
    super(props)
    const start = moment(props.selected.fromDate || '01-06-2022', 'MM-DD-YYYY')

    this.state = {
      startDate: start.toDate(),
      maxDate: start.clone().add(31, 'days').toDate(),
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.selected.fromDate) {
      this.setState({
        maxDate: moment(newProps.selected.fromDate, 'MM-DD-YYYY').add(31, 'days').toDate(),
      })
    }
  }

  setMaxDate = () => {
    this.setState({
      maxDate: moment(this.props.selected.fromDate).add(31, 'days').toDate(),
    })
  }

  render () {
    const { selected, countries, companies, businessUnits, isFieldDisabled, disabledReason, mostRecentRunReport = {} } = this.props
    const shouldShowMostRecentRunReportItem = !this.props.reportItem
    const isRolesAndRightsReport = selected.subcategory === 'RolesAndRights'
    const showPayrollFilters = !isRolesAndRightsReport
    const shouldLimitFromTo = !isRolesAndRightsReport
    const isMultiCountryAndCompany = isRolesAndRightsReport

    /*
     * Every next filter is shown if the last required field has value
     */
    return (
      <div className='o-block--inline'>
        {selected.subcategory && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input date-picker--with-tooltip'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='From Date *'
                labelClassName='c-label u-text--small u-text--curious'
                name='fromDate'
                component={FieldDatePicker}
                calendarClassName={'report-show-disabled-message'}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                minDate={this.state.startDate}
                data-testid='from-date'
                onChange={shouldLimitFromTo && this.setMaxDate}
              />
            </div>
          </div>
        )}
        {selected.fromDate && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                className='c-input c-input--transparent c-input__value-placeholder'
                label='To Date *'
                labelClassName='c-label u-text--small u-text--curious'
                name='toDate'
                component={FieldDatePicker}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                formGroupClassName='u-margin-none'
                isClearable={!isFieldDisabled}
                minDate={moment(selected.fromDate, 'MM-DD-YYYY').toDate()}
                maxDate={shouldLimitFromTo && this.state.maxDate}
                selected={shouldLimitFromTo && getDateBasedOnMaxDate(selected.toDate, this.state.maxDate)}
                data-testid='to-date'
              />
            </div>
          </div>
        )}
        {selected.fromDate && selected.toDate && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='country'
                label='Country *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi={this.props.reportItem || isMultiCountryAndCompany}
                optionComponent={isMultiCountryAndCompany ? CheckboxOption : undefined}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                  abbreviature: country.abbreviature,
                }))}
                enableRemovedOptionFix
                data-testid='country-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.country) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                multi={this.props.reportItem || isMultiCountryAndCompany}
                optionComponent={isMultiCountryAndCompany ? CheckboxOption : undefined}
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={companies
                  .map((company) => ({
                    value: company.id,
                    label: company.name,
                  }))
                  .sort(sortByLabel)}
                enableRemovedOptionFix
                data-testid='company-wrapper'
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && showPayrollFilters && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <PayrollSelectContainer
                hasNoneOption
                payrollRequired={false}
                optionComponent={CheckboxOption}
                multi
                {...this.props}
                // Here we don't want to filter the Payrolls by dates, because `from/to` dates refer to the employees,
                // and the dates should filter the employees, not the payrolls.
                selected={{
                  ...this.props.selected,
                  fromDate: null,
                  toDate: null,
                }}
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='employmentStatus'
                label='Type of Employment/Engagement'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                optionComponent={CheckboxOption}
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                options={EmploymentStatusHelper.allStatusOptionsForSelectBox}
                data-testid='type-of-employment'
                multi
                hasNoneOption
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <Field
                name='businessUnit'
                label='Business Unit'
                component={CustomSelectField}
                optionComponent={CheckboxOption}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Any'
                disabled={isFieldDisabled}
                disabledReason={disabledReason}
                multi
                options={businessUnits
                  .map((unit) => ({
                    value: unit.id,
                    label: unit.name,
                  }))
                  .sort(sortByLabel)}
                data-testid='businessUnit-wrapper'
                enableRemovedOptionFix
                hasNoneOption
              />
            </div>
          </div>
        )}
        {!isEmpty(selected.country) && !isEmpty(selected.company) && (
          <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-modern-input'>
              <ChangedBySelectContainer {...this.props} />
            </div>
          </div>
        )}
        {!this.props.hideNavigationButtons && (
          <SubmitButtons pristineCheck={!isEmpty(selected.company)} onBackClick={this.props.navigateToParentOnClick} {...this.props} />
        )}

        {shouldShowMostRecentRunReportItem && (
          <div
            className='o-layout__item u-padding-left-small u-text--center u-1/1
           u-margin-top-small u-padding-top-small'
          >
            <MostRecentRunReportItem
              mostRecentRunReport={mostRecentRunReport}
              onOpenReport={this.props.handleReportModalOpen}
              onDelete={this.props.deleteReport}
              {...this.props}
            />
          </div>
        )}
      </div>
    )
  }
}

FiltersChangeLogReport.propTypes = {
  resetFormFields: PropTypes.func,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  // Props in order to check if the previous filter has a value
  // Show next filter only if the previous is filled
  selected: PropTypes.object,
  // Dropdown options data
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  departments: PropTypes.array,
  payrolls: PropTypes.array,
  // Disabled fields and submit btn check
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showRunReportBtn: PropTypes.bool,
  navigateToParentOnClick: PropTypes.func,
  mostRecentRunReport: PropTypes.object,
  deleteReport: PropTypes.func,
  handleReportModalOpen: PropTypes.func,
}

export default FiltersChangeLogReport
