import { createReducer } from 'utils/redux/reducer'
import {
  PUSH_BREADCRUMB,
  PUSH_BREADCRUMB_FILTER,
  CLEAR_BREADCRUMB_FILTER,
  RESET_BREADCRUMB,
  CLEAR_ALL_BREADCRUMB_FILTER_OF_TYPE,
} from '../actions/breadcrumbs'

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  '/': {
    text: null,
    path: '/',
    filters: null,
    children: []
  }
}

export default createReducer(initialState, {
  [PUSH_BREADCRUMB]: (state, { payload }) => {
    const localState = { ...state }
    const { location } = payload
    const { pathname, search } = location
    const isBaseRoute = pathname.split('/').length <= 2
    let keyName = pathname.split('/')[1] += search
    // Save the base routes as the base object
    // All other children routes will be pushed to the children attribute of their parent
    if (isBaseRoute) {
      localState[keyName] = {
        ...localState[keyName],
        text: keyName,
        path: keyName,
        children: localState[keyName]?.children?.length ? localState[keyName].children : []
      }
    }
    if (!isBaseRoute && localState[keyName] && localState[keyName].children) {
      const hasChild = localState[keyName].children.find(child => child[keyName])
      // If the child already exists don't worry about it for now
      // later we can look at figuring out to extend the children to have
      // separate configurations (filters, more children etc)
      // but for the initial MVP of this, we don't have to worry about it
      if (hasChild) return { ...state, ...localState }
      let child = {}
      child[keyName] = { text: keyName, path: keyName }

      localState[keyName].children.push(child)
      localState[keyName] = {
        ...localState[keyName],
        children: localState[keyName].children
      }
    }
    return { ...state, ...localState }
  },
  [PUSH_BREADCRUMB_FILTER]: (state, { payload }) => {
    const localState = { ...state }
    const { routeKey, filters } = payload

    localState[routeKey] = {
      ...localState[routeKey],
      filters: filters
    }
    return { ...state, ...localState }
  },
  [CLEAR_BREADCRUMB_FILTER]: (state, { payload }) => {
    const localState = { ...state }
    const { routeKey } = payload
    localState[routeKey] = { ...localState[routeKey], filters: null }
    return { ...state, ...localState }
  },
  [CLEAR_ALL_BREADCRUMB_FILTER_OF_TYPE]: (state, { payload }) => {
    const localState = { ...state }
    const { type } = payload
    const breadcrumbKeysToClear = Object.keys(localState).filter(k => k.indexOf(type) >= 0)
    breadcrumbKeysToClear.forEach(key => { localState[key] = { ...localState[key], filters: null } })
    return { ...state, ...localState }
  },
  [RESET_BREADCRUMB]: () => ({ ...initialState })
})
