import CoreLayout from 'layouts/PageLayout/PageLayout'
import loadable from '@loadable/component'
const PayrollContainerRouteLoadable = loadable(() => import('./containers/PayrollContainer'))

export default (store) => ({
  path : ':payrollInstanceId/time-attendance-updates',
  authProtected: true,
  exact: true,
  renderOutsideTabbedParent: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: PayrollContainerRouteLoadable },
})
