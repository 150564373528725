import React from 'react'
import PropTypes from 'prop-types'
import ReversedFlag from './ReversedFlag'

function ReversedFlagRenderer ({ instance }) {
  if (instance.hasReversions && !instance.isReversionCompleted) {
    return (
      <ReversedFlag
        type={instance.reversionType}
        reason={instance.reversionReason}
        instanceId={instance.id}
      />
    )
  }

  if (instance.hasReversions && instance.isReversionCompleted) {
    return <ReversedFlag instanceId={instance.id} isCompleted />
  }

  return null
}

ReversedFlagRenderer.propTypes = {
  instance: PropTypes.shape({
    hasReversions: PropTypes.bool,
    isReversionCompleted: PropTypes.bool,
    type: PropTypes.string,
    reason: PropTypes.string
  })
}

export default ReversedFlagRenderer
