import React from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import CustomSelectField from 'components/form/select/CustomSelectField'
import {
  endDateValidation,
  emailValidation,
  phoneValidation,
} from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'
import { Authorization } from 'containers/Authorization'

export const EmployeeCustomFieldComponent = ({
  field,
  setCountry = null,
  accessingMode = null,
}) => {
  const fieldsToTrigerSetCountry = ['currencies', 'currency', 'bankCountry']
  // Render select options if any
  const renderOptions = (options = null) =>
    options
      ? options.map((option) => {
        if (field.field === 'currency') {
          return {
            value: option.id,
            label: option.abbreviature,
          }
        }
        if (field.field === 'primaryBankAccount') {
          return {
            value: option.value,
            label: option.label
          }
        }
        return {
          value: option.id || option.name || option.value || option,
          label: option.name || option.label || option,
        }
      })
      : null

  const components = {
    dropdown: (
      <Field
        name={field['field'] || field['name']}
        label={field['name'] || field['printableName']}
        title={field['title'] || field['name'] || field['printableName']}
        component={CustomSelectField}
        className='c-custom-select--transparent'
        placeholder={'Select'}
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        options={renderOptions(field.options)}
        disabled={field.disabled}
        data-testid={field['field']}
        onChange={
          setCountry &&
          fieldsToTrigerSetCountry.includes(field.field) &&
          ((e) => setCountry(e))
        }
      />
    ),
    date: (
      <Field
        name={field['field'] || field['name']}
        label={field['name'] || field['printableName']}
        data-testid={field['field']}
        component={FieldDatePicker}
        className='c-input c-input--transparent'
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        validate={endDateValidation}
        // TODO Martin: we have this restriction on KU create so it is good to have it here as well, but need to be discussed
        // maxDate={getTodaysDate}
      />
    ),
    input:
      accessingMode && field.accessLayers && field.accessLayers[accessingMode] ? (
        <Authorization permissions={field.accessLayers[accessingMode]}>
          <div key={field['name']} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name={field['field'] || field['name']}
                label={field['name'] || field['printableName']}
                title={field['title']}
                data-testid={field['field']}
                component={InputField}
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                type={field.type ? field.type : 'text'}
                min={field.min ? Number(field.min) : null}
              />
            </div>
          </div>
        </Authorization>
      ) : (
        <Field
          name={field['field'] || field['name']}
          label={field['name'] || field['printableName']}
          title={field['title']}
          data-testid={field['field']}
          component={InputField}
          className='c-input c-input--transparent'
          labelClassName='c-label u-text--small u-text--curious'
          formGroupClassName='u-margin-none'
          type={field.type ? field.type : 'text'}
          min={field.min ? Number(field.min) : null}
        />
      ),
    email: (
      <Field
        name={field['field'] || field['name']}
        label={field['name'] || field['printableName']}
        data-testid={field['field']}
        component={InputField}
        type='text'
        className='c-input c-input--transparent'
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        validate={emailValidation}
      />
    ),
    phone: (
      <Field
        name={field['field'] || field['name']}
        label={field['name'] || field['printableName']}
        data-testid={field['field']}
        component={PhoneCodeNumberField}
        validate={phoneValidation}
        disabled={field.isMobilePhoneFieldDisabled}
        disabledReason={field.mobilePhoneDisabledReason}
        type='text'
        className='c-input--transparent'
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
      />
    ),
    button: (
      <div className='c-panel u-padding-small'>
        <div className='u-padding-top-tiny'>
          <button
            type='button'
            onClick={() => field.onClick()}
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          >
            {field.name}
          </button>
        </div>
      </div>
    ),
    authorizedButton: (
      <Authorization permissions={['CONVERT_EMPLOYEE_TO_KEYPERSON']}>
        <div className='c-panel u-padding-small'>
          <div className='u-padding-top-tiny'>
            <button
              type='button'
              onClick={() => field.onClick()}
              className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
            >
              Make key person
            </button>
          </div>
        </div>
      </Authorization>
    ),
  }

  return components[field.component]
    ? components[field.component]
    : components['input']
}

export default EmployeeCustomFieldComponent
