import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/editors'

class Editor extends CRUDModel {}
Editor.modelName = 'Editor'
Editor.actionTypes = actionTypes

Editor.fields = {
  id: attr(),
}

export default Editor
