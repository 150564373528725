import React from 'react'
import PropTypes from 'prop-types'
import CheckboxStatic from 'components/form/CheckboxStatic'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import { vendorCompanyDetailsLabels } from 'utils/locales/vendor.en'

export const CompanyDetails = (props) => {
  const {
    onEdit,
    isCot,
    isCotButNotCotOem,
    vendor: {
      name,
      status,
      registrationNumber,
      address,
      registrationAddress,
      website,
      hqCountry,
      holidaysCalendar,
      payrollSoftware,
      senderService,
      dataProtection,
      professionalIndemnity,
    },
  } = props

  return (
    <div>
      {isCot && (
        <SectionHeading bolded>
          <Authorization permissions={['VENDOR_EDIT']}>
            <div className='o-layout__item u-1/1 u-1/2@desktop'>
              <div className='u-float--right'>
                <div onClick={onEdit} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny' data-testid='vendor-edit'>
                  <span className='icon icon--edit' />
                </div>
              </div>
            </div>
          </Authorization>
        </SectionHeading>
      )}

      <p className='u-text--normal u-margin-none' data-testid='name'>
        <strong>{vendorCompanyDetailsLabels.name}</strong>: {name}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='status'>
        <strong>{vendorCompanyDetailsLabels.status}</strong>: {status}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='registrationNumber'>
        <strong>{vendorCompanyDetailsLabels.companyRegNumber}</strong>: {registrationNumber}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='address'>
        <strong>{vendorCompanyDetailsLabels.address}</strong>: {address}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='registeredAddress'>
        <strong>{vendorCompanyDetailsLabels.registeredAddress}</strong>: {registrationAddress}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='website'>
        <strong>{vendorCompanyDetailsLabels.website}</strong>: {website}
      </p>
      <p className='u-text--normal u-margin-none' data-testid='hqCountry'>
        <strong>{vendorCompanyDetailsLabels.hqCountry}</strong>: {hqCountry.name}
      </p>
      {isCotButNotCotOem && (
        <p className='u-text--normal u-margin-none' data-testid='holidaysCalendar'>
          <strong>{vendorCompanyDetailsLabels.holidaysCalendar}</strong>: {holidaysCalendar}
        </p>
      )}
      <p className='u-text--normal u-margin-none' data-testid='payrollSoftware'>
        <strong>{vendorCompanyDetailsLabels.payrollSoftware}</strong>: {payrollSoftware}
      </p>
      {isCot && (
        <p className='u-text--normal u-margin-none' data-testid='payrollSoftware'>
          <strong>{vendorCompanyDetailsLabels.senderService}</strong>: {senderService}
        </p>
      )}
      {isCotButNotCotOem && (
        <div className='o-layout'>
          <div className='o-layout__item'>
            <div className='u-float--left'>
              <p className='u-text--normal u-margin-none'>
                <strong>{vendorCompanyDetailsLabels.dataProtection}: </strong>
              </p>
            </div>
            <div className='u-float--left'>
              <CheckboxStatic
                className='control__indicator__input'
                checked={dataProtection}
                disabled
                indicatorClassName='control__indicator--center'
                data-testid='dataProtection'
              />
            </div>
          </div>
          <div className='o-layout__item'>
            <div className='u-float--left'>
              <p className='u-text--normal u-margin-none'>
                <strong>{vendorCompanyDetailsLabels.professionalIndemnity}: </strong>
              </p>
            </div>
            <div className='u-float--left'>
              <CheckboxStatic
                className='control__indicator__input'
                checked={professionalIndemnity}
                disabled
                indicatorClassName='control__indicator--center'
                data-testid='professionalIndemnity'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CompanyDetails.propTypes = {
  vendor: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    registrationNumber: PropTypes.string,
    address: PropTypes.string,
    registrationAddress: PropTypes.string,
    website: PropTypes.string,
    holidaysCalendar: PropTypes.string,
    payrollSoftware: PropTypes.string,
    dataProtection: PropTypes.bool,
    professionalIndemnity: PropTypes.bool,
    hqCountry: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
}

export default CompanyDetails
