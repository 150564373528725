import React from 'react'
import Form from 'components/form/Form'
import ConfirmationModal from 'components/ConfirmationModal'

class ConfirmationForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      proxy: null
    }
  }

  render () {
    const { showConfirmation, message, submitOnReject } = this.props

    return <div>
      <ConfirmationModal
        ref='confirmationModal'
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => {
          const submitter = this.props.handleSubmit(data => this.props.onSubmit(data))
          submitter(this.state.proxy)
        }}
        onReject={() => {
          if (!submitOnReject) return

          const submitter = this.props.handleSubmit(data => this.props.onSubmit(data))
          submitter(this.state.proxy)
        }}
      >
        <p>{message}</p>
      </ConfirmationModal>

      <Form {...this.props} handleSubmit={(proxy) => {
        // Here it's a bit complicated to postpone submitting the Form, depending on `showConfirmation` flag.
        // However we find a way how to hook up in the `handleSubmit` `react-redux` flow.
        // Generally speaking `handleSubmit` is a decorator function, that accepts our custom `onSubmit` handler.
        // Therefore, in the first case, when we should show the Confirmation Modal, we prevent the Form submitting
        // and we are keeping the proxy/event in the State, in order to submit the Form manually using the proxy data.
        // In the second case, when we should not show the Confirmation, then we manually trigger `handleSubmit`.
        // The way/syntax of submitting the decorator `handleSubmit` is complex, but it's part of the `redux-form`
        // API. In the below link - you can read more more how `handleSubmit` works:
        // @link: https://stackoverflow.com/questions/43966804/how-to-handlesubmit-with-a-redux-form

        // Show the modal, only if the employee is part of active payruns and the annual salary value is changed
        if (showConfirmation) {
          // Show confirmation modal
          proxy.preventDefault()
          // https://reactjs.org/docs/events.html#event-pooling
          proxy.persist()
          this.setState({ proxy })
          this.refs.confirmationModal.showModal()
        } else {
          const submitter = this.props.handleSubmit(data => this.props.onSubmit(data))
          submitter(proxy) // submits
        }
      }}>
        {this.props.children}
      </Form>
    </div>
  }
}

export default ConfirmationForm
