import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'

const CountryForm = (props) => {
  const { languages, timezones, currencies, allCountries, onCountryChange } = props
  return <Form {...props}>
    <div className='o-layout'>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='name'
            label='Country Name *'
            labelClassName='c-label u-text--small u-text--curious'
            className='c-custom-select--transparent c-custom-select--specific-large'
            formGroupClassName='u-margin-none'
            data-testid='country-name-select'
            component={CustomSelectField}
            placeholder='Select'
            onChanged={onCountryChange}
            options={allCountries.map(abb => ({
              value: abb.name,
              label: abb.name
            }))}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop d-none'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='abbreviature'
            label='Аbbreviature *'
            labelClassName='c-label u-text--small u-text--curious'
            className='c-custom-select--transparent'
            formGroupClassName='u-margin-none'
            type='hidden'
            component={InputField}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='lang'
            label='Language *'
            data-testid='language-select'
            labelClassName='c-label u-text--small u-text--curious'
            className='c-custom-select--transparent'
            formGroupClassName='u-margin-none'
            component={CustomSelectField}
            placeholder='Select'
            options={languages.map(lang => ({
              value: lang.id,
              label: lang.name
            }))}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='timezones'
            label='Timezone'
            data-testid='timezone-select'
            labelClassName='c-label u-text--small u-text--curious'
            className='c-custom-select--transparent'
            formGroupClassName='u-margin-none'
            component={CustomSelectField}
            placeholder='Select'
            multi
            options={timezones.map(timezone => ({
              value: timezone.id,
              label: timezone.zone
            }))}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='currency'
            label='Currency *'
            data-testid='currency-select'
            labelClassName='c-label u-text--small u-text--curious'
            className='c-custom-select--transparent'
            formGroupClassName='u-margin-none'
            component={CustomSelectField}
            placeholder='Select'
            options={currencies.map(currency => ({
              value: currency.id,
              label: currency.abbreviature
            }))}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='taxStartDate'
            label='Tax year start date *'
            data-testid='tax-start-date-input'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            component={FieldDatePicker}
          />
        </div>
      </div>
      <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='taxEndDate'
            label='Tax year end date *'
            data-testid='tax-end-date-input'
            className='c-input c-input--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            component={FieldDatePicker}
          />
        </div>
      </div>
    </div>
    <div className='o-layout'>
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          data-testid='submit'
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
        >
          Save
        </button>
      </div>
    </div>
  </Form>
}

CountryForm.propTypes = {
  languages: PropTypes.array,
  timezones: PropTypes.array,
  currencies: PropTypes.array,
  allCountries: PropTypes.array,
  onCountryChange: PropTypes.func
}

export default CountryForm
