import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/resourceLogs'

class ResourceLog extends CRUDModel {}
ResourceLog.modelName = 'ResourceLog'

ResourceLog.fields = {
  id: attr(),
}

ResourceLog.actionTypes = actionTypes

export default ResourceLog
