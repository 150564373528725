import React from 'react'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { createFilter } from 'utils/redux/filter'
import { toastEntityTypesEnum } from 'utils/enums/toastEntityTypesEnum'
import { useDispatch, useSelector } from 'react-redux'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { toast } from 'react-toastify'
import { isCot } from 'redux/selectors/auth'
import { toasts } from 'utils/locales/toasts.en'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'

const PayrunFileToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {toasts.errorPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.payrunFile.fileName}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const PayrunVarianceToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {toasts.errorPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.reconciliation.fileName}
      </span>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ElementVarianceToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-element-variance-export'>
      <span className='u-text--small'>
        {toasts.errorPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.elementReconciliation.fileName}
      </span>
      <div id={`entity-${job.entityId}-close`} className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

ElementVarianceToast.propTypes = {
  job: PropTypes.object,
  baseApiUsage: PropTypes.object,
  cb: PropTypes.func,
}

const PayrunFileImportToast = ({ job, cb, baseApiUsage }) => {
  const dispatch = useDispatch()
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))

  const onCompleteToast = (justFlagAsRead) => {
    if (cb) {
      dispatch(cb(job.id, job, baseApiUsage, justFlagAsRead))
    }
    toast.dismiss(job.id)
  }

  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {toasts.errorImportPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.payrunFile.fileName}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => onCompleteToast(true)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

const ReportCreateFailedToast = ({ job, cb, baseApiUsage }) => {
  const builder = new ReportItemBuilder()
  const feCategoryId = job.filters.feCategoryId
  const reportName = builder.getReportNameCategory(feCategoryId)
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {toasts.errorPrefix} <strong>{reportName}</strong>
        {toasts.reports.report}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(job.id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

export const GenericFailedToast = ({ id, name }) => {
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>
        {toasts.errorPrefix} <strong>{name} </strong>
        {toasts.reports.report}
      </span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

export const UnexpectedErrorToast = ({ id }) => (
  <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
    <span className='u-text--small'>{toasts.unexpectedError}</span>
    <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
      <span className='icon--ex' />
    </div>
  </div>
)

export const EmployeeImportFailedToast = ({ id, name }) => {
  return (
    <div className='d-flex ai-center jc--space-between' data-testid='toast-completed-payrunfile-export'>
      <span className='u-text--small'>{toasts.employees.importFailed}</span>
      <div className='position-absolute u-absolute--top-right' onClick={() => toast.dismiss(id)}>
        <span className='icon--ex' />
      </div>
    </div>
  )
}

PayrunFileToast.propTypes = {
  job: PropTypes.object,
  baseApiUsage: PropTypes.object,
  cb: PropTypes.func,
}

const FetchedPayfileExportView = Fetcher(PayrunFileToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayfileImportView = Fetcher(PayrunFileImportToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedPayrunVarianceView = Fetcher(PayrunVarianceToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const FetchedElementVarianceView = Fetcher(ElementVarianceToast, [
  {
    name: 'payrollInstances',
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const ToastFailedViews = ({ job, cb, baseApiUsage }) => {
  let View = null
  const state = useSelector((state) => state)
  const isCotUser = useSelector((state) => isCot(state))
  if (!state.tenants.url && isCotUser) {
    View = <UnexpectedErrorToast id={job.id} baseApiUsage={baseApiUsage} />
  }
  if (state.tenants.url || !isCotUser) {
    if (job.type === toastEntityTypesEnum.PAYFILE_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_CHANGES_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.EMPTY_PAYFILE_EXPORT_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_EXPORT_ARCHIVE_JOB) {
      View = <FetchedPayfileExportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.PAYFILE_IMPORT_JOB) {
      View = <FetchedPayfileImportView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.VARIANCE_EXPORT_JOB) {
      View = <FetchedPayrunVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.ELEMENT_VARIANCE_EXPORT_JOB) {
      View = <FetchedElementVarianceView job={job} cb={cb} baseApiUsage={baseApiUsage} />
    }
    if (job.type === toastEntityTypesEnum.REPORT_CREATE_JOB) {
      View = <ReportCreateFailedToast job={job} />
    }
    if (job.type === toastEntityTypesEnum.EMPLOYEE_BULK_CREATE_JOB || job.type === toastEntityTypesEnum.EMPLOYEE_BULK_UPDATE_JOB) {
      View = <EmployeeImportFailedToast id={job.id} name={job.name} />
    }
  }
  return View
}

export default ToastFailedViews
