import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioFields from 'components/form/RadioFields'
import Form from 'components/form/Form'
import { batch } from 'react-redux'

let DeleteEmployeeAddressView = (props) => {
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [filteredList, setFilteredList] = useState(props.addresses)

  useEffect(() => {
    const currentRecord = props.addresses.find(address => address.id === props.selectedId)
    const records = props.addresses.filter(address => address.id !== props.selectedId || (address.type === '' && address.addressLine1 === ''))
    batch(() => {
      setSelectedAddress(currentRecord)
      setFilteredList(records)
    })
    return () => {
      setFilteredList(null)
    }
  }, [])

  const onChange = (e) => props.onSelectChange(e.target.value)
  const showListOfItems = selectedAddress && selectedAddress.isPrimary && filteredList.length >= 1

  return (
    <>
      <p>Are you sure you want to remove this address?</p>
      {showListOfItems &&
        <div>
          <p>Please select an existing address to become the new primary address</p>
          <div className='o-layout__item u-margin-bottom u-1/1 '>
            <div className='c-panel u-bg--white border-0 rounded u-padding-small'>
              <Form onSubmit={null}>
                <Field
                  component={RadioFields}
                  name='addresses'
                  onChange={onChange}
                  options={filteredList.map(addr => {
                    return {
                      label: `${addr.type || 'Address'} - ${addr.addressLine1 || 'Blank'}`,
                      value: addr.id,
                      compareValue: addr.id.toString()
                    }
                  })}
                  header=''
                  headerClassName='u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                />
              </Form>
            </div>
          </div>
        </div>
      }
    </>
  )
}

DeleteEmployeeAddressView.propTypes = {
  addresses: PropTypes.array,
  selectedId: PropTypes.number,
  onSelectChange: PropTypes.func,
}

DeleteEmployeeAddressView = reduxForm({
  // a unique name for the form
  form: 'deleteEmployeeAddress'
})(DeleteEmployeeAddressView)

export default DeleteEmployeeAddressView
