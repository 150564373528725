import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import { Authorization } from 'containers/Authorization'
import ConfirmationModal from 'components/ConfirmationModal'
import { getCotRoleType } from 'utils/roles'

class CotUsersList extends React.Component {
  constructor (props) {
    super(props)
    this.state = ({
      cotUserId: null,
      cotUserName: null,
    })
  }

  /**
   * Handle modal opening
   * @param modalRef reference to the modal that should be open
   * @param cotUserId cotUser ID that should be deleted
   * @param cotUserName cotUser name that should be displayed in the confirmation modal
   */
  handleModalOpen = (modalRef, cotUserId, cotUserName) => {
    this.setState({
      cotUserId: cotUserId,
      cotUserName: cotUserName
    })
    modalRef.showModal()
  }

  renderRows = () => {
    const { cotUsers, isCotAdmin, loggedCotId } = this.props
    return cotUsers.map(cotUser => {
      /**
       * Title isn't always added so we do this if we don't want to display null
       * in the modal title.
       */
      const deleteModalTitle = (cotUser.title ? cotUser.title + ' ' : '') + cotUser.firstname + ' ' + cotUser.surname
      /**
       * If current logged in user is not `ROLE_COT_ADMIN`, then he can edit only his own user.
       * Otherwise, if he is `ROLE_COT_ADMIN`, then he can edit all the users.
       */
      const canEdit = !isCotAdmin ? loggedCotId === cotUser.id : true

      const tenantsList = cotUser.tenants
      const tenantsAccessAreas = tenantsList.map((t, i) => {
        return i !== tenantsList.length - 1 ? `${t}, ` : `${t}`
      })
      const cotRoleType = getCotRoleType(cotUser.roleType)

      return {
        name: cotUser.name,
        status: cotUser.hasCredentials ? 'Active' : 'Inactive',
        position: cotUser.position,
        base: cotUser.country && <div className='o-grid o-grid--medium o-grid--center'>
          <Flag flag={cotUser.country.abbreviature.toLowerCase()} title={cotUser.country.name} size='tiny' />
        </div>,
        role: cotRoleType,
        accessAreas: tenantsAccessAreas,
        workEmail: cotUser.workEmail,
        mobilePhone: cotUser.mobilePhone,
        officePhone: cotUser.officePhone,
        action: <div className='u-text--center'>
          { canEdit && <Authorization permissions={['COTUSER_EDIT']}>
            <button
              onClick={() => this.props.onClick(cotUser.id)}
              className='c-btn c-btn--tiny c-btn--curious u-margin-right-tiny'>
              <span className='icon icon--edit' title='Edit' data-testid={`cotEdit-button-${cotUser.id}`} />
            </button>
          </Authorization>
          }
          <Authorization permissions={['COTUSER_DELETE']}>
            <button
              onClick={() => this.handleModalOpen(this.refs.deleteModal, cotUser.id, deleteModalTitle)}
              className='c-btn c-btn--tiny c-btn--curious u-text--tiny'
              title='Delete'
            >
              <span className='icon icon--trash' />
            </button>
          </Authorization>
        </div>
      }
    })
  }

  render () {
    return <div className='u-relative'>
      <PayslipTable
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        wrapperClassName='u-1/1 react-bs-table--overflow-auto'
        headings={[
          {
            Header: 'Name',
            accessor: 'name',
            disableSortBy: true
          },

          {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true
          },

          {
            Header: 'Position',
            accessor: 'Position',
            disableSortBy: true
          },

          {
            Header: 'Base',
            accessor: 'base',
            classNames: 'text-center',
            columnClassName: 'u-overflow-visible',
            disableSortBy: true
          },

          {
            Header: 'Role',
            accessor: 'role',
            disableSortBy: true
          },
          {
            Header: 'Access Area',
            accessor: 'accessAreas',
            disableSortBy: true
          },
          {
            Header: 'Email',
            accessor: 'workEmail',
            disableSortBy: true
          },
          {
            Header: 'Mobile Phone',
            accessor: 'mobilePhone',
            disableSortBy: true
          },
          {
            Header: 'Office Phone',
            accessor: 'officePhone',
            disableSortBy: true
          },
          {
            Header: 'Action',
            accessor: 'action',
            actionCell: true,
            classNames: 'text-center',
            disableSortBy: true
          }
        ]}

        data={this.renderRows(this.props)}
      />
      <ConfirmationModal
        ref='deleteModal'
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => this.props.deleteCotUser(this.state.cotUserId)}
      >
        <p>Are you sure you want to delete user <span className='u-weight--bold'>{this.state.cotUserName}</span>?</p>
      </ConfirmationModal>
    </div>
  }
}

CotUsersList.propTypes = {
  cotUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    gender: PropTypes.string,
    workEmail: PropTypes.string.isRequired,
    title: PropTypes.string,
    roleType: PropTypes.string.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      abbreviature: PropTypes.string
    })
  })),
  loggedCotId: PropTypes.number,
  isCotAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  deleteCotUser: PropTypes.func
}

export default CotUsersList
