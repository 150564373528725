import createSelector from 'utils/createSelector'
import { employeeFieldsConfig } from 'redux/config/employeeFieldsConfig'

const getCountryId = (state, props) => parseInt(props.countryId)
const getFilteredIds = (state, props) => state.companyDataProtectionFieldsPivot.filteredIds

export const getCompanyDataProtectionFields = createSelector(getCountryId, getFilteredIds,
  ({ CompanyDataProtectionField }, countryId, filteredIds) => {
    return CompanyDataProtectionField.filter(field => filteredIds.includes(field.id)).toModelArray()
      .map(cdpf => {
        let employeeDataFieldCat
        let employeeDataFieldObj

        if (cdpf.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
          employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === cdpf.dataProtectionField.ref.entityField))
          employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === cdpf.dataProtectionField.ref.entityField)
        }
        if (cdpf.dataProtectionField.ref.entityClass.includes('TermCategory')) {
          employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === cdpf.dataProtectionField.ref.fieldValue))
          employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === cdpf.dataProtectionField.ref.fieldValue)
        }

        return {
          ...cdpf.ref,
          name: employeeDataFieldObj.name,
          dataProtectionField: { ...cdpf.dataProtectionField.ref }
        }
      })
  })
