import createSelector from 'utils/createSelector'
import { jobStatusEnum } from 'utils/enums/jobStatusEnum'
import { pollingJobTypesEnum } from 'utils/enums/pollingJobTypesEnum'

const getJobId = (state, props) => parseInt(props.jobId, 10)
const getFeCategory = (state, props) => parseInt(props.feCategoryId, 10)

export const getNonCompletedJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedPayFileExportJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.PAYFILE_EXPORT_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedEmptyPayFileExportJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.EMPTY_PAYFILE_EXPORT_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedPayFileExportChangesJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.PAYFILE_CHANGES_EXPORT_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedPayFileExportArchiveJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.PAYFILE_EXPORT_ARCHIVE_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedPayrunVarianceJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.VARIANCE_EXPORT_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getNonCompletedElementVarianceJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.ELEMENT_VARIANCE_EXPORT_JOB || job.type === pollingJobTypesEnum.EMPLOYEE_VARIANCE_EXPORT_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getCompletedReportCreateJobByIdAndFeCategory = createSelector(getJobId, getFeCategory, ({ PollingJob }, jobId, feCategoryId) =>
  PollingJob.filter((job) => job.id === jobId)
    .filter((job) => job.status === jobStatusEnum.COMPLETED)
    .filter((job) => job.type === pollingJobTypesEnum.REPORT_CREATE_JOB)
    .filter((job) => job.filters.feCategoryId === feCategoryId)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)

export const getCompletedReportCreateJobs = createSelector(({ PollingJob }) =>
  PollingJob.filter((job) => job.status === jobStatusEnum.COMPLETED || job.status === jobStatusEnum.NOT_STARTED)
    .filter((job) => job.type === pollingJobTypesEnum.REPORT_CREATE_JOB)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
    .sort((a, b) => b.id - a.id)
)

export const getJobById = createSelector(getJobId, ({ PollingJob }, jobId) =>
  PollingJob.filter((job) => job.id === jobId)
    .toModelArray()
    .map((job) => ({ ...job.ref }))
)
