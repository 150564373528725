import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_TASKS_FETCH = 'COMPANY_TASKS_FETCH'
export const COMPANY_TASKS_RECEIVE = 'COMPANY_TASKS_RECEIVE'
export const COMPANY_TASKS_UPDATE = 'COMPANY_TASKS_UPDATE'
export const COMPANY_TASKS_INVALIDATE = 'COMPANY_TASKS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_TASKS_FETCH,
  receive: COMPANY_TASKS_RECEIVE,
  update: COMPANY_TASKS_UPDATE,
  invalidate: COMPANY_TASKS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'companyTaskPivot', 'companytasks', 'CompanyCountryTask')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyTaskPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyTaskPivot = actionUtility.fetchEntities
export const updateCompanyTaskPivot = actionUtility.updateEntity
export const invalidateCompanyTaskPivot = actionUtility.invalidate
