import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/bankAccountTypes'

class BankAccountType extends CRUDModel {}
BankAccountType.modelName = 'BankAccountType'
BankAccountType.actionTypes = actionTypes

BankAccountType.fields = {
  id: attr()
}

export default BankAccountType
