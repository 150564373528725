import React from 'react'
import ResourcesContainer from 'routes/Resources/containers/ResourcesContainer'
import { resourcesTypes } from 'routes/Resources/utils/resourcesOptions'
import { getBrandData } from 'utils/subbranding'
import Loader from 'components/Loader'

const RouteView = () => {
  const brandData = getBrandData()
  if (brandData.isFetching) return <Loader />

  const hasAccessToResourcesMenu = brandData.data?.resourcesMenu !== false
  if (!hasAccessToResourcesMenu) return <div />

  const props = {
    resourceType: resourcesTypes.releases,
  }

  return <ResourcesContainer {...props} />
}

export default RouteView
