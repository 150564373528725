import React, { forwardRef, useLayoutEffect } from 'react'

import { useMergeRefs, FloatingPortal, FloatingFocusManager, FloatingOverlay, useId } from '@floating-ui/react'
import { FloatingDialogContext, useFloatingDialog, useFloatingDialogContext } from './useFloatingDialog'

export const FloatingDialog = ({ children, ...options }) => (
  <FloatingDialogContext.Provider value={useFloatingDialog(options)}>{children}</FloatingDialogContext.Provider>
)

export const FloatingDialogContent = forwardRef(({ overlayClassName, withEx, ...props }, propRef) => {
  const { context: floatingContext, ...context } = useFloatingDialogContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])
  const { children } = props

  if (!floatingContext.open) return null

  const handleCloseClick = () => context?.setOpen()

  return (
    <FloatingPortal>
      <FloatingOverlay className={`floatingDialog--overlay ${overlayClassName || ''}`} lockScroll>
        <FloatingFocusManager context={floatingContext}>
          <div ref={ref} aria-labelledby={context.labelId} aria-describedby={context.descriptionId} {...context.getFloatingProps(props)}>
            {withEx && (
              <div className='d-flex ai-center jc--flex-end ps-mr-[14] ps-mt-[12]'>
                <span className='icon--ex ps-m-0' onClick={handleCloseClick} />
              </div>
            )}
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export const FloatingDialogHeading = forwardRef(({ children, ...props }, ref) => {
  const { setLabelId } = useFloatingDialogContext()
  const id = useId()

  useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <span {...props} ref={ref} id={id}>
      {children}
    </span>
  )
})

export const FloatingDialogDescription = forwardRef(({ children, ...props }, ref) => {
  const { setDescriptionId } = useFloatingDialogContext()
  const id = useId()

  useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  return (
    <section {...props} ref={ref} id={id}>
      {children}
    </section>
  )
})
