import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import RadioFields from 'components/form/RadioFields'
import Form from 'components/form/Form'
import { batch } from 'react-redux'

let DeleteEmployeeBankAccountView = (props) => {
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [filteredList, setFilteredList] = useState(props.bankAccounts)

  useEffect(() => {
    const currentRecord = props.bankAccounts.find(bankAccount => bankAccount.id === props.selectedId)
    const records = props.bankAccounts.filter(bankAccount => bankAccount.id !== props.selectedId)
    batch(() => {
      setSelectedBankAccount(currentRecord)
      setFilteredList(records)
    })
    return () => {
      setFilteredList(null)
    }
  }, [])

  const onChange = (e) => props.onSelectChange(e.target.value)
  const showListOfItems = selectedBankAccount && selectedBankAccount.primaryBankAccount && filteredList.length >= 1

  return (
    <>
      <p>Are you sure you want to delete this bank account?</p>
      {showListOfItems &&
        <div>
          <p>Please select an existing bank account to become the new primary bank account</p>
          <div className='o-layout__item u-margin-bottom u-1/1 '>
            <div className='c-panel u-bg--white border-0 rounded u-padding-small'>
              <Form onSubmit={null}>
                <Field
                  component={RadioFields}
                  name='bankAccounts'
                  onChange={onChange}
                  options={filteredList.map(bankAccount => {
                    const buildBankLabel = ({ bankName, accountNumber, iban, bankCountryName, currencyAbbr }) => {
                      let label = bankName || `${bankCountryName} ${currencyAbbr}`
                      label += (accountNumber || iban) ? ` - ${accountNumber || ''} ${iban || ''}` : ''
                      return label
                    }
                    return {
                      label: buildBankLabel(bankAccount),
                      value: bankAccount.id,
                      compareValue: bankAccount.id.toString()
                    }
                  })}
                  header=''
                  headerClassName='u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bank-accounts-list'
                />
              </Form>
            </div>
          </div>
        </div>
      }
    </>
  )
}

DeleteEmployeeBankAccountView.propTypes = {
  bankAccounts: PropTypes.array,
  selectedId: PropTypes.number,
  onSelectChange: PropTypes.func,
}

DeleteEmployeeBankAccountView = reduxForm({
  // a unique name for the form
  form: 'DeleteEmployeeBankAccount'
})(DeleteEmployeeBankAccountView)

export default DeleteEmployeeBankAccountView
