import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { required } from 'utils/validations'
import FieldDatePicker from 'components/form/FieldDatePicker'
import AmountInputField from 'components/form/AmountInputField'
import { roundHalfValue } from 'utils/number'

class LeaveFormFields extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: props.initialValues.from,
      endDate: props.initialValues.to,
      lastDayOfWork: props.initialValues.lastDayOfWork,
      actualLastDayOfLeave: props.initialValues.actualLastDayOfLeave
    }
  }

  render () {
    return <div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label='Last Day of Work'
          labelClassName='c-label'
          className='c-input'
          name='lastDayOfWork'
          component={FieldDatePicker}
          selectsStart
          selected={this.state.lastDayOfWork}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChanged={lastDayOfWork => this.setState({ lastDayOfWork })}
          data-testid='lastDayOfWork-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label='First Day of Leave *'
          labelClassName='c-label'
          className='c-input'
          name='from'
          component={FieldDatePicker}
          selectsEnd
          selected={this.state.startDate}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChanged={startDate => this.setState({ startDate })}
          validate={required}
          minDate={this.state.lastDayOfWork}
          showDisabledMonthNavigation
          data-testid='firstDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label='Estimated Last Day of Leave *'
          labelClassName='c-label'
          className='c-input'
          name='to'
          component={FieldDatePicker}
          selectsEnd
          selected={this.state.endDate}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChanged={endDate => this.setState({ endDate })}
          validate={required}
          minDate={this.state.startDate}
          showDisabledMonthNavigation
          data-testid='estimatedLastDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          label='Actual Last Day of Leave'
          labelClassName='c-label'
          className='c-input'
          name='actualLastDayOfLeave'
          component={FieldDatePicker}
          selectsEnd
          selected={this.state.actualLastDayOfLeave}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChanged={actualLastDayOfLeave => this.setState({ actualLastDayOfLeave })}
          minDate={this.state.startDate}
          showDisabledMonthNavigation
          data-testid='actualLastDayOfLeave-wrapper'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <Field
          name='totalDays'
          label='Total Days'
          labelClassName='c-label'
          className='c-input'
          formGroupClassName='u-margin-bottom-tiny'
          component={AmountInputField}
          format={(val) => {
            const modValue = Math.abs(val % 1)
            const hasCorrectFormat = modValue === 0 || modValue === 0.5
            if (hasCorrectFormat) return val
            return roundHalfValue(val)
          }}
          data-testid='total-days-wrapper'
        />
        <span className='u-text--small'><em>Enter full or half days only eg. 0.5 or 1</em></span>
      </div>
    </div>
  }
}

LeaveFormFields.propTypes = {
  initialValues: PropTypes.object
}

export default LeaveFormFields
