import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isCot, isVendorInvolved } from 'redux/selectors/auth'
import Fetcher from '../Fetcher'
import { getFilteredNotifications } from 'redux/selectors/notifications'
import NavBarAlertsWidget from 'components/navbar/NavBarAlertsWidget'
import { createFilter } from '../../utils/redux/filter'
import { purify } from '../../utils/router'
import { selectTenant } from '../../redux/actions/tenants'
import { fetchCotUsersIfNeeded } from '../../redux/actions/cotUsers'
import { fetchCountriesIfNeeded } from '../../routes/Countries/modules/actions'
import { getParentCompaniesTenants } from 'redux/selectors/tenants'

const mapDispatchToProps = (dispatch, { isCot, history }) => ({
  // Before navigating (routing) to Alerts route, we have to check if the current logged in user is COT.
  // If so, then we have to perform tenant switching firstly,
  // because COT Alerts are located in the COT mode and have to switch between the current tenant mode to the COT mode.
  onNavigatingToAlerts: () => {
    if (!isCot) return history.push('/toolbox/alerts')

    // In the case we re COT
    // Before switching the COT api url, we go to the `/pure` neutral route.
    purify(history).then(() => {
      dispatch(selectTenant({ url: null, type: null, id: null }))

      history.push(`/alerts`)

      // After setting the tenant to COT we should fetch the COT users again, so the NavBarProfile loads correctly.
      dispatch(fetchCotUsersIfNeeded())
      // Also we should fetch the countries again, so the CountryHeader loads correctly.
      // Otherwise it says 'Loading...'
      dispatch(fetchCountriesIfNeeded())
    })
  },
})

const mapStateToProps = (state, { filters }) => {
  let recentNotifications = getFilteredNotifications(state, { filter: filters.notifications.name })
  if (isCot(state) || isVendorInvolved(state)) {
    const parentCompaniesTenants = getParentCompaniesTenants(state)
    recentNotifications = recentNotifications.filter(({ extraData }) => parentCompaniesTenants.find((t) => t.name === extraData.client))
  }
  /**
   * Check if there are any unread messages from the recent notifications
   * Let's assume that there aren't any unread notifications
   */
  let hasUnread = false

  /**
   * check for each recent notification if it's read
   */
  recentNotifications.forEach((notification) => {
    if (!notification.isRead) {
      hasUnread = true
    }
  })

  return {
    notifications: recentNotifications,
    hasUnread,
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(NavBarAlertsWidget)

Container.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default Fetcher(Container, [
  'parentCompanies',
  {
    name: 'notifications',
    params: [
      {
        filter: createFilter({ offset: 0, limit: 3, sort: [{ name: 'createdAt', order: 'desc' }] }),
      },
    ],
  },
])
