import InnerWrapper from '../../components/VendorRouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import CompanyDetailsRoute from './routes/CompanyDetails'
import CostingRoute from './routes/Costing'
import SLAsRoute from './routes/SLAs'
import UsersRoute from './routes/Users'
import ClientsRoute from './routes/Clients'

export default (store) => ({
  path : ':vendorId',
  indexRoute: { component: InnerWrapper },
  wrappedComponent: CoreLayout,
  authProtected: true,
  childRoutes: [ CompanyDetailsRoute(store), CostingRoute(store), SLAsRoute(store), UsersRoute(store), ClientsRoute(store) ]
})
