import React from 'react'
import PropTypes from 'prop-types'
import { handleErrors } from 'utils/form'

const FieldErrors = ({ meta: { touched, error } }) => <div>{touched && error && handleErrors(error)}</div>

FieldErrors.propTypes = {
  /**
   * @inheritDoc Please refer to meta documentation here:
   * https://redux-form.com/6.7.0/docs/api/field.md/
   */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
  })
}

export default FieldErrors
