export const toasts = {
  thePrefix: 'The',
  errorPrefix: 'There was an error generating the',
  errorImportPrefix: 'There was an error importing the',
  unexpectedError: 'An unexpected error occurred',
  preparingPrefix: 'We are preparing the',
  yourPrefix: 'Your',
  seeResults: 'See results',
  payrunFile: {
    fileName: 'payrun file',
    ready: 'payrun file is ready',
    uploaded: 'payrun file was successfully uploaded. Please refresh to see changes.',
  },
  reconciliation: {
    fileName: 'reconciliation file',
    ready: 'reconciliation file is ready',
  },
  elementReconciliation: {
    fileName: 'Element reconciliation',
    alternateFileName: 'Employee reconciliation',
    ready: 'Element reconciliation file is ready.',
    alternateReady: 'Employee reconciliation file is ready.',
  },
  reports: {
    inProgress: 'report is in progress',
    ready: 'is ready and available in Recent Reports',
    report: 'report',
    reportIsReady: 'Report is ready.',
    reportInProgress: 'Report is in Progress',
  },
  employees: {
    createImportInProgress: 'Create Multiple Employees import is in progress.',
    createImportSuccessful: 'Create Multiple Employees import was successful.',
    createImportWithErrors: 'Create Multiple Employees import is complete and errors have been identified.',
    updateImportInProgress: 'Employee Mass Update import is in progress.',
    updateImportSuccessful: 'Employee Mass Update import was successful.',
    updateImportWithErrors: 'Employee Mass Update import is complete and errors have been identified.',
    importFailed: 'An error has occured while importing employees. Please try again.',
  },
}
