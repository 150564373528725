import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import Dropzone from 'react-dropzone'
import InputField from 'components/form/InputField'
import { required, makeMinimumScaleValidation } from 'utils/validations'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CustomSelectField from 'components/form/select/CustomSelectField'

const minValueValidation = makeMinimumScaleValidation(0)
class DocumentClassificationUploadForm extends React.Component {
  render () {
    const { disabled, disabledReason, showSubmitBtn, uploadFile, resetForm, documentTypes } = this.props
    return <div className='o-layout'>
      <Form>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='documentTypes'
              label='Document Type *'
              component={CustomSelectField}
              optionComponent={CheckboxOption}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              disabled={disabled}
              disabledReason={disabledReason}
              onChange={resetForm}
              options={documentTypes.map(type => ({ value: type.id, label: type.text }))}
              validate={required}
              multi
              data-testid='documentTypes-wrapper'
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-top u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='version'
              label='Version *'
              labelClassName='c-label'
              component={InputField}
              type='number'
              validate={minValueValidation}
              data-testid='version'
            />
          </div>
        </div>
        <div className='o-layout__item u-1/1'>
          <div className='c-panel u-padding-small'>
            <p className='u-text--small u-text--center'>
              Important: For data security, before you proceed with a document upload,
              please make sure that you have selected the correct file, and you share it with the appropriate user.
            </p>
          </div>
        </div>
        { showSubmitBtn &&
          <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
            <button
              type='button'
              className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
              onClick={() => this.dropzoneRef.open()}
              data-testid='select-file'
            >
              Select file
            </button>
          </div>
        }
      </Form>
      <Dropzone
        onDrop={files => uploadFile(files[0])}
        // eslint-disable-next-line no-return-assign
        ref={node => this.dropzoneRef = node}
        className='u-relative'
      />
    </div>
  }
}

DocumentClassificationUploadForm.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showSubmitBtn: PropTypes.bool,
  uploadFile: PropTypes.func,
  resetForm: PropTypes.func,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string
  })),
}

export default DocumentClassificationUploadForm
