import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const DOCUMENT_FETCH = 'DOCUMENT_FETCH'
export const DOCUMENT_RECEIVE = 'DOCUMENT_RECEIVE'
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE'
export const DOCUMENT_BULK_UPDATE = 'DOCUMENT_BULK_UPDATE'
export const DOCUMENT_INVALIDATE = 'DOCUMENT_INVALIDATE'
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE'
export const SEND_SQS = 'SEND_SQS'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DOCUMENT_FETCH,
  receive: DOCUMENT_RECEIVE,
  update: DOCUMENT_UPDATE,
  bulkUpdate: DOCUMENT_BULK_UPDATE,
  invalidate: DOCUMENT_INVALIDATE,
  delete: DOCUMENT_DELETE,
  create: SEND_SQS,
}
const actionUtility = new ActionUtility(actionTypes, 'document', 'documents', 'Document')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDocumentsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDocuments = actionUtility.fetchEntities
export const updateDocument = actionUtility.updateEntity
export const bulkUpdateDocument = actionUtility.bulkUpdateEntity
export const invalidateDocuments = actionUtility.invalidate
export const deleteDocument = actionUtility.deleteEntity
export const uploadDocument = (file, data) => actionUtility.uploadEntity({ file, data })
export const downloadDocument = (id) => actionUtility.downloadEntity({ id, childUri: 'download' })
export const downloadDocumentByUrl = (fullUrl) => actionUtility.downloadEntity({ fullUrl })

const triggerZeroTouchAction = (data) => ({
  type: actionTypes.create,
  payload: data,
})

export const triggerZeroTouch = (data) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: data,
        id: data.id,
        childUri: `gtnimportmessage`,
        actionFunc: triggerZeroTouchAction,
        shouldFetch: false,
      })
    ).then(errorsHandling.handlePivotJSONNormalize)
  }
}
