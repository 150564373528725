/**
 * termSubcategories items,
 * these are referenced in the code, are listed here.
 *
 * In that way we prevent using magic numbers
 * and also we can change the values below easily.
 */
export default {
  overtimeAdditions: 'Overtime additions',
}
