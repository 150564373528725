export const payrollSummaryTitles = {
  payrollSummary: 'Payroll Summary',
  overview: 'Overview',
  commentary: 'Commentary',
}

export const payrollSummaryApprovalText = {
  approve: 'Approve',
  approved: 'Approved',
}

export const payrollSummaryItems = {
  country: 'Country',
  companyCode: 'Company Code',
  payroll: 'Payroll',
  currency: 'Currency',
  headcount: 'Headcount',
  payrollPeriod: 'Payroll Period',
  payDate: 'Pay Date',
  currentPayrun: 'Current Payrun',
  previousPayrun: 'Previous Payrun',
  difference: 'Difference',
}

export const payrollSummaryDisclaimers = {
  editDisabledMessage: 'Payrun is completed. Unlock the payrun file to edit',
}

export const payrollCommentaryHeadings = {
  comments: 'Comments',
  date: 'Date',
  submittedBy: 'Submitted by',
}

export const payrollSummaryTotalsTable = {
  viewBy: 'View by',
  disbursableOnly: 'Disbursable only',
  category: 'Category',
  subcategory: 'Subcategory',
  currentPayrun: 'Current Payrun',
  previousPayrun: 'Previous Payrun',
  variance: 'Variance',
  comments: 'Comments',
}

export const payrollSummarySupportingDocs = {
  supportingDocs: 'Supporting Documents',
}

export const payrollSummaryAdditionalItems = {
  sectionHeading: 'Additional Items',
  item: 'Item',
  currentPayrun: 'Current Payrun',
  prevPayrun: 'Previous Payrun',
  variance: 'Variance',
  comments: 'Comments',
  historyHeading: 'Non additional items History',
}
