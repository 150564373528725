import { reduxForm, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { createCompany } from 'routes/Companies/modules/actions'
import CompanyForm from '../components/CompanyForm'
import { isCot } from 'redux/selectors/auth'
import { getCurrencies } from 'redux/selectors/currencies'
import { getCountries } from 'redux/selectors/country'
import { getParentCompanies } from 'redux/selectors/parentCompanies'
import { getTimezones } from 'redux/selectors/timezones'
import { getHQCompanies } from '../selectors/company'
import { types } from 'redux/config/companies'
import values from 'lodash/values'
import Fetcher from 'containers/Fetcher'

const isHQRelationToHQ = value => value === types.hq.value

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => dispatch(createCompany(data)).then(props.onSubmit),
    onRelationToHQChange: value => {
      if (isHQRelationToHQ(value)) {
        dispatch(change('companyCreate', 'hq', null))
      }
    }
  }
}

const mapStateToProps = state => {
  if (isFetching([
    state.companies, state.currencies, state.countries, state.parentCompanies,
    state.timezones
  ])) return { isFetching: true }

  // Set the Parent company ID, for which we will create a Company.
  // If there's selected tenant, then we use use it (because the user operates via COT portal),
  // otherwise (not COT) we're using the Parent company ID, that's returned from the API (it's always 1 record in that case)
  const parentCompany = state.tenants.id || getParentCompanies(state)[0].id
  const relationToHQ = formValueSelector('companyCreate')(state, 'relationToHQ')

  return {
    currencies: getCurrencies(state),
    countries: getCountries(state),
    types: values(types),
    timezones: getTimezones(state),
    HQCompanies: getHQCompanies(state),
    isHQRelationToHQ: isHQRelationToHQ(relationToHQ),
    isCot: isCot(state),
    initialValues: { parentCompany }
  }
}

const Component = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'companyCreate'
})(CompanyForm))

export default Fetcher(Component,
  ['companies', 'currencies', 'countries', 'parentCompanies', 'timezones']
)
