import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PARENTCOMPANIES_FETCH = 'PARENTCOMPANIES_FETCH'
export const PARENTCOMPANIES_RECEIVE = 'PARENTCOMPANIES_RECEIVE'
export const PARENTCOMPANIES_INVALIDATE = 'PARENTCOMPANIES_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PARENTCOMPANIES_FETCH,
  receive: PARENTCOMPANIES_RECEIVE,
  invalidate: PARENTCOMPANIES_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'parentCompanies', 'parentcompanies')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchParentCompaniesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchParentCompanies = actionUtility.fetchEntities
export const invalidateParentCompaniesIfNeeded = actionUtility.invalidate

export const downloadVendorsAndKeyUsersReport = () => actionUtility.downloadEntity({ childUri: 'vendors/keyuserexport' })
