import moment from 'moment'
import { format as formatFns } from 'date-fns'
import { dateFormatEnums } from './enums/dateFormatEnums'

/**
 * Get dates in a specific range
 *
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @param {Boolean} includeWeekends
 * @param {String} format
 * @returns {Array}
 */
export const getDatesInRange = (startDate, endDate, includeWeekends = true, format = 'YYYY-MM-DD') => {
  // Keep all dates in the range
  let dates = []

  // Current day, being iterated in the range
  let currentDate = moment(startDate)

  while (currentDate <= endDate) {
    if (!isWeekend(currentDate) || includeWeekends) {
      dates.push(currentDate.format(format))
    }
    currentDate = currentDate.add(1, 'days')
  }

  return dates
}

/**
 * Is passed in date a weekend
 *
 * @param {Moment} date
 * @returns {boolean}
 */
export const isWeekend = (date) => {
  const day = date.isoWeekday()

  return day % 6 === 0 || day % 7 === 0
}

/**
 * Get ordinal day
 *
 * @link https://stackoverflow.com/a/13627586/4312466
 *
 * @param {Number} day
 * @returns {string}
 */
export const getOrdinalDay = (day) => {
  let j = day % 10
  let k = day % 100

  if (j === 1 && k !== 11) {
    return day + 'st'
  }
  if (j === 2 && k !== 12) {
    return day + 'nd'
  }
  if (j === 3 && k !== 13) {
    return day + 'rd'
  }

  return day + 'th'
}

/**
 * Get date range of month by passing a specific date
 *
 * @link  https://stackoverflow.com/a/26131085/4312466
 *
 * @param {Date} date
 * @returns {object}
 */
export const getMonthDateRangeByDate = (date) => {
  let month = date.month()
  let year = date.year()

  let startDate = moment([year, month])
  let endDate = moment(startDate).endOf('month')

  return { start: startDate, end: endDate }
}

/**
 * Get today's day
 *
 */
export const getTodaysDate = new Date()

/**
 * Get dates by ascending/descending order
 *
 */
export const sortDate = (a, b, order) => {
  const dateA = new Date(a.uploadedOn.date)
  const dateB = new Date(b.uploadedOn.date)
  return order === 'desc' ? dateB - dateA : dateA - dateB
}

export const getMonthsDifference = (startDate, endDate) => {
  return endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear())
}

/**
 * Special DateType Object that extends the standard Date
 * This allows us to set custom dates to match the mix match that comes back from
 * the server
 *
 * ex. new DateType(29/01/2022)
 */
export class DateType extends Date {
  get month () {
    return this.getMonth() + 1
  }

  get day () {
    return this.getDate()
  }

  get year () {
    return this.getFullYear()
  }
}

/**
 * Calculates the number of weeks within a month
 * Based on Monday being the first day
 */

export const getNumberOfWeeksInMonth = (year, month) => {
  const first = new Date(year, month, 1)
  const last = new Date(year, month + 1, 0)
  let dayOfWeek = first.getDay()
  if (dayOfWeek === 0) dayOfWeek = 7
  let days = dayOfWeek + last.getDate() - 1
  return Math.ceil(days / 7)
}

/**
 * Converts a given date to UTC format according to the specified format.
 * @param {Date|number|string} date - The date to convert to UTC. It can be a Date object, a timestamp, or a string representing a date.
 * @param {string} format - The format string to apply to the converted date. Uses Moment.js format syntax.
 * @returns {string} The date converted to UTC format with the specified format.
 */
export const dateToUtc = (date, format) => moment(date).utc().format(`${format} [UTC]`)

/**
 * Formats a given date to UTC format according to the specified format.
 * @param {Date|number|string} date - The date to format to UTC. It can be a Date object, a timestamp, or a string representing a date.
 * @param {string} format - The format string to apply to the formatted date. Uses date-fns format syntax.
 * @returns {string} The date formatted to UTC with the specified format, appended with 'UTC'.
 */
export const formatDateToUtc = (date, format) => `${formatFns(new Date(date), format)} UTC`

/**
 * Formats a given date according to the specified format using date-fns.
 * @param {Date|string|number} date - The date to format. It can be a Date object, a string (to be parsed as a date), or a timestamp.
 * @param {string} format - The format string specifying how the date should be formatted. Uses date-fns format syntax.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date, format = dateFormatEnums.DefaultDateFormatWithoutTime) => formatFns(new Date(date), format)
