import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EDITORS_FETCH = 'EDITORS_FETCH'
export const EDITORS_RECEIVE = 'EDITORS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EDITORS_FETCH,
  receive: EDITORS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'editors', 'changeset/editors', 'Editor')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEditorsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEditors = actionUtility.fetchEntities
