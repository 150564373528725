import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { invalidateParentCompaniesIfNeeded, fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import { getContactedParentCompanies } from 'redux/selectors/tenants'
import { getVendorIdBySchema } from 'redux/selectors/vendors'
import ClientsList from '../components/ClientsList'
import { selectTenant } from 'redux/actions/vendorTenant'
import { getTenantSubdomain } from 'utils/tenant'

class ClientsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(fetchParentCompaniesIfNeeded())
    dispatch(fetchVendorsIfNeeded())
    this.props.resetTenant()
    this.props.invalidate()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <ClientsList {...this.props} />
  }
}

const invalidate = () => {
  return dispatch => {
    dispatch(invalidateParentCompaniesIfNeeded())
    dispatch(invalidatePayrollInstances())
  }
}

const fetch = () => {
  return dispatch => {
    dispatch(fetchParentCompaniesIfNeeded())
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
    selectTenant: data => {
      dispatch(selectTenant(data))
      dispatch(fetch())
    },
    resetTenant: () => {
      dispatch(invalidate())
      dispatch(selectTenant({ url: null, schema: null }))
    },
    invalidate: () => dispatch(invalidate()),
    dispatch
  }
}

const mapStateToProps = (state, props) => {
  const { parentCompanies, tenants, vendors } = state

  const entities = [ parentCompanies, vendors ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    clients: getContactedParentCompanies(state),
    vendorId: tenants.id || getVendorIdBySchema(state, { schema: getTenantSubdomain() })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer)
