import createSelector from 'utils/createSelector'
import _ from 'lodash'

// get the service id
const getCountryServiceId = (state, props) => parseInt(props.countryServiceId, 10)

// get the route vendors
export const getVendorsWithCountryServices = createSelector(getCountryServiceId,
  ({ Vendor }, countryServiceId) => {
    let vendors = []
    let vendorCountryService = {}

    Vendor
      .all()
      .toModelArray()
      .filter(vendor => _.includes(_.map(vendor.countryServices.toRefArray(), 'id'), countryServiceId))
      .forEach(vendor => {
        vendor
          .vendorCountryServices
          .filter(vendorCountryService => vendorCountryService.countryService === countryServiceId)
          .toModelArray()
          .forEach(singleVendorCountryService => {
          // set the vendor countryService
            vendorCountryService = {
              ...singleVendorCountryService.ref
            }
          })

        vendors.push({
          ...vendor.ref,
          vendorCountryService
        })
      })

    return vendors
  })
