export const reconciliationTitles = {
  variancesOnly: 'Variances Only',
  variancesOnlyAlt: 'Variances only',
  excludeZeroes: 'Exclude Zeros',
  payrollPlusEeReconciliation: 'Payroll + Employee Reconciliation',
  elementReconciliation: 'Element Reconciliation',
  payrollReconciliation: 'Payroll Reconciliation',
  totalVarianceByValue: 'Total Variance by Value',
  totalVarianceByPercentage: 'Total Variance by Percentage',
  totalElementsWithVariance: 'Total Elements with Variance',
  employeeReconciliation: 'Employee Reconciliation',
}

export const reconciliationDisclaimers = {
  payrollLine1: 'This view will be generated when there are two versions of',
  payrollLine2: 'the payrun file available',
  viewNotGenerated: 'This view will be generated when there are two versions of the payrun file available',
  elementNotSelected: 'Please select an element from the filter above to generate the Employee Reconciliation',
}

export const payrollReconciliationHeadings = {
  category: 'Category',
  subcategory: 'Subcategory',
  element: 'Element',
  dataFormat: 'Data Format',
  previousPayrun: 'Previous Payrun',
  variance: 'Variance',
  result: 'Result',
}

export const elementReconciliationHeadings = {
  employeeId: 'Employee ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  previous: 'Previous Payrun',
}

export const reconciliationTableMessages = {
  noRecords: 'No matching records found.',
}
