import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import DashboardContainer from '../containers/DashboardContainer'

class RouteView extends React.Component {
  render () {
    return <InnerWrapper>
      <DashboardContainer {...this.props} />
    </InnerWrapper>
  }
}

export default RouteView
