import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_PROCESS_FETCH = 'PAYROLL_PROCESS_FETCH'
export const PAYROLL_PROCESS_RECEIVE = 'PAYROLL_PROCESS_RECEIVE'
export const PAYROLL_PROCESS_UPDATE = 'PAYROLL_PROCESS_UPDATE'
export const PAYROLL_PROCESS_INVALIDATE = 'PAYROLL_PROCESS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_PROCESS_FETCH,
  receive: PAYROLL_PROCESS_RECEIVE,
  update: PAYROLL_PROCESS_UPDATE,
  invalidate: PAYROLL_PROCESS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollProcess', 'payrollprocesses', 'PayrollProcess')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollProcessIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollProcess = actionUtility.fetchEntities
export const updatePayrollProcess = actionUtility.updateEntity
export const invalidatePayrollProcess = actionUtility.invalidate
