import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayslipTable from 'components/table/PayslipTable'

const PayrollStepForm = ({ steps, stepsOptions, ...rest }) => <div>
  <Form {...rest}>
    <div className='o-layout u-text--center'>
      <div className='o-layout__item u-1/1'>
        <Field
          name='name'
          label='Name'
          labelClassName='c-label'
          component={InputField}
          type='text'
        />
      </div>
      <div className='o-layout__item u-1/3'>
        <Field
          name='position'
          label='Choose position:'
          component={CustomSelectField}
          labelClassName='c-label'
          className='u-text--left'
          placeholder='Select'
          options={stepsOptions}
        />
      </div>
      <div className='page--payroll-step-form o-layout__item u-2/3 u-margin-top'>
        <PayslipTable
          data={steps}
          dynamicValues
          headings={[
            {
              accessor: 'alphabeticName',
              Header: 'Current Steps:',
              columnClassName: 'text-left',
              disableSortBy: true,
              disableFilters: true,
              Cell: ({ cell: { value, row: { original } } }) => {
                return <div title={value} className={original.isModified ? 'u-weight--bold' : ''}>{value}</div>
              }
            }

          ]}
        />
      </div>
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious
               u-padding-left u-padding-right u-margin-top-small' >
          Save
        </button>
      </div>
    </div>
  </Form>
</div>

PayrollStepForm.propTypes = {
  stepsOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
}

export default PayrollStepForm
