import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import AmountFormat from 'components/AmountFormat'
import MobileDetailsView from 'components/MobileDetailsView'
import VariablePayElements from './VariablePayElements'
import Deductions from './Deductions'
import SectionHeading from 'components/SectionHeading'
import { getTermDisplayName } from 'utils/sections/payAndTaxes/employee'
import AnnualSalaryTableView from './AnnualSalaryTableView'
import AddBtnMessage from '../../Personal/components/AddBtnMessage'
import TermsFormEditContainer from '../containers/TermsFormEditContainer'
import FixedPayEditableElements from './FixedPayEditableElements'

const mobilePayTaxesViews = [
  { key: 'jobOrg', title: 'Job & Org', color: 'minsk', isSingleColumn: false, isMultiRow: false },
  { key: 'fixedPayElements', title: 'Fixed Pay Elements', color: 'minsk', isSingleColumn: false, isMultiRow: true },
  { key: 'variablePayElements', title: 'Variable Pay Elements', color: 'minsk', isSingleColumn: true, isMultiRow: true },
  { key: 'employeeDeductions', title: 'Employee Deductions', color: 'minsk', isSingleColumn: false, isMultiRow: true },
  { key: 'employeeNetDeductions', title: 'Employee Net Deductions', color: 'minsk', isSingleColumn: false, isMultiRow: true },
  { key: 'employeeContributions', title: 'Employer Contributions', color: 'minsk', isSingleColumn: false, isMultiRow: true },
]

const DeductionViews = ({ isEmployeeOnly = false, inEditMode, inAddMode, onUpdateGlobalDataForSave, fieldErrors, onValueChange, ...rest }) => {
  const { employerDeductions, employeeDeductions, futureEmployeeDeductions, employeeNetDeductions, futureEmployerDeductions, futureEmployeeNetDeductions } =
    rest.data
  return (
    <div>
      {!isEmployeeOnly && (
        <Deductions
          title='Employer Contributions'
          dataKey='employerDeductions'
          futureDataKey='futureEmployerDeductions'
          deductions={employerDeductions}
          futureDeductions={futureEmployerDeductions}
          inAddMode={inAddMode}
          inEditMode={inEditMode}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          fieldErrors={fieldErrors}
          onValueChange={onValueChange}
        />
      )}
      <Deductions
        title='Employee Deductions'
        dataKey='employeeDeductions'
        futureDataKey='futureEmployeeDeductions'
        deductions={employeeDeductions}
        futureDeductions={futureEmployeeDeductions}
        inAddMode={inAddMode}
        inEditMode={inEditMode}
        onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
        fieldErrors={fieldErrors}
        onValueChange={onValueChange}
      />
      <Deductions
        title='Employee Net Deductions'
        dataKey='employeeNetDeductions'
        futureDataKey='futureEmployeeNetDeductions'
        deductions={employeeNetDeductions}
        futureDeductions={futureEmployeeNetDeductions}
        inAddMode={inAddMode}
        inEditMode={inEditMode}
        onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
        fieldErrors={fieldErrors}
        onValueChange={onValueChange}
      />
      {isEmployeeOnly && (
        <Deductions
          title='Employer Contributions'
          dataKey='employerDeductions'
          futureDataKey='futureEmployerDeductions'
          deductions={employerDeductions}
          futureDeductions={futureEmployerDeductions}
          inAddMode={inAddMode}
          inEditMode={inEditMode}
          onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
          fieldErrors={fieldErrors}
          onValueChange={onValueChange}
        />
      )}
    </div>
  )
}

DeductionViews.propTypes = {
  employerDeductions: PropTypes.array,
  employeeDeductions: PropTypes.array,
  employeeNetDeductions: PropTypes.array,
  isEmployeeOnly: PropTypes.bool,
  inEditMode: PropTypes.bool,
  inAddMode: PropTypes.bool,
  onUpdateGlobalDataForSave: PropTypes.func,
  fieldErrors: PropTypes.array,
  onValueChange: PropTypes.func
}

class PayTaxesView extends React.Component {
  renderMobileJobOrgRows () {
    const {
      employee,
      employeeAnnualSalary,
      employeeCustomFields,
      effectiveDate
    } = this.props
    let rows = []
    let excludedRows = ['invoicePaymentCurrency']

    rows.push({ 'Annual salary': <AmountFormat key={'annual'} amount={parseFloat(employeeAnnualSalary)} /> })
    rows.push({ 'Annual Salary Effective Date': effectiveDate })

    employeeCustomFields
      .filter((field) => !excludedRows.includes(field.name))
      .forEach((field) => rows.push({ [this.getPrintableName(field.name)]: employee[field.name] }))

    return rows
  }

  renderMobileFixedPayElRows () {
    const { fixedTerms, futureFixedTerms } = this.props.data
    let rows = []
    fixedTerms.forEach((term) => {
      const expandedRows = futureFixedTerms.find(ft => ft.countryTermId === term.id) ?? null
      const { globalName, localName, name, amount, id } = term
      const showAndNamesMatch = term.shouldOnlyUseGlobalName && globalName === localName
      const hasLocalName = localName === null || localName === ''
      rows.push({ [showAndNamesMatch || hasLocalName ? globalName : `${globalName} / ${localName || name}`]: {
        validFrom: term.validFrom,
        validTo: term.validTo,
        amount: <AmountFormat key={id} amount={amount} />,
        expandedItems: expandedRows && [expandedRows].map((item, idx) => {
          return {
            ...item,
            amount: <AmountFormat key={`${item.amount}-${item.idx}`} amount={item.amount} />
          }
        })
      }
      })
    })

    return rows
  }

  renderMobileVariablePayElRows () {
    const { variableTerms } = this.props
    let rows = []

    variableTerms.forEach((term, i) => {
      rows.push(
        (term.shouldOnlyUseGlobalName && term.globalName === term.companyCountryTermLocalName) ||
          term.companyCountryTermLocalName === null ||
          term.companyCountryTermLocalName === ''
          ? term.globalName
          : `${term.globalName} / ${term.companyCountryTermLocalName || term.localName}`
      )
    })

    return rows
  }

  renderDeductionsRows (deductions, futureDeductions) {
    let rows = []
    deductions.forEach((term) => {
      const expandedRows = futureDeductions.find(ft => ft.countryTermId === term.countryTermId) ?? null
      rows.push({ [getTermDisplayName(term)]: {
        validFrom: term.validFrom,
        validTo: term.validTo,
        amount: <AmountFormat key={term.id} amount={term.amount} />,
        expandedItems: expandedRows && [expandedRows].map((item, idx) => {
          return {
            ...item,
            amount: <AmountFormat key={idx} amount={item.amount} />
          }
        })
      }
      })
    })
    return rows
  }

  hasCustomField (fieldName) {
    const { employeeCustomFields } = this.props
    return employeeCustomFields.find((field) => field.name === fieldName)
  }

  getPrintableName (fieldName) {
    const { employeeCustomFields } = this.props
    return employeeCustomFields.filter((field) => field.name === fieldName).map((field) => field.printableName)
  }

  getRowsForView (type, props) {
    if (type === 'jobOrg') return this.renderMobileJobOrgRows()
    if (type === 'fixedPayElements') return this.renderMobileFixedPayElRows()
    if (type === 'variablePayElements') return this.renderMobileVariablePayElRows()
    if (type === 'employeeDeductions') return this.renderDeductionsRows(this.props.data.employeeDeductions, this.props.data.futureEmployeeDeductions)
    if (type === 'employeeNetDeductions') return this.renderDeductionsRows(this.props.data.employeeNetDeductions, this.props.data.futureEmployeeNetDeductions)
    if (type === 'employeeContributions') return this.renderDeductionsRows(this.props.data.employerDeductions, this.props.data.futureEmployerDeductions)
    return []
  }

  onAddVariableElements () {
    this.refs.modalEditTerms.showModal()
  }

  render () {
    const { employee, data, onUpdateGlobalDataForSave, fieldErrors, onValueChange } = this.props
    const { isFetching, hasEmployeeActiveInstances, hasEmployeeLockedInstances, inlineMode, showConfirmation } = this.props
    const { disableNonGlobalButtons, isEmployeeOnly, inAddMode, inEditMode } = this.props
    const { onAnnualSalaryHistoryBtnClick, setInlineMode, employeeAnnualSalary } = this.props

    if (isFetching) return <div>Loading...</div>
    return (
      <>
        <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
          <div className='page--pay-taxes-view-container'>
            <div className='o-layout'>
              <div className='o-layout__item page--pay-taxes-view-container-layout'>
                <AnnualSalaryTableView
                  salary={employeeAnnualSalary}
                  onAnnualSalaryHistoryBtnClick={onAnnualSalaryHistoryBtnClick}
                  employeeId={employee.id}
                  inlineMode={inlineMode}
                  setInlineMode={setInlineMode}
                  disableSalaryInlineButtons={disableNonGlobalButtons}
                  showConfirmation={showConfirmation}
                  hasEmployeeLockedInstances={hasEmployeeLockedInstances}
                  hasEmployeeActiveInstances={hasEmployeeActiveInstances}
                  hideInlineButtons={isEmployeeOnly}
                />
                <>
                  <FixedPayEditableElements
                    dataKey='fixedTerms'
                    futureDataKey='futureFixedTerms'
                    fixedTerms={data.fixedTerms}
                    futureFixedTerms={data.futureFixedTerms}
                    isEmployeeOnly={isEmployeeOnly}
                    inAddMode={inAddMode}
                    inEditMode={inEditMode}
                    onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
                    fieldErrors={fieldErrors}
                    onValueChange={onValueChange}
                  />
                  <VariablePayElements variableTerms={this.props.variableTerms} />
                  <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
                    <div className='u-weight--regular u-text--normal text-right u-margin-bottom u-margin-top'>
                      <AddBtnMessage
                        onClick={() => this.onAddVariableElements()}
                        isDisabled={disableNonGlobalButtons || inlineMode}
                        text='Add Variable Elements'
                        testId='add-variable-elements-btn'
                        btnClasses='c-btn--small u-padding-left u-padding-right u-margin-left-small'
                      />
                    </div>
                  </Authorization>
                </>
              </div>
              <div className='o-layout__item u-1/1@tablet u-1/2@desktop'>
                <DeductionViews
                  data={data}
                  isEmployeeOnly={isEmployeeOnly}
                  inAddMode={inAddMode}
                  inEditMode={inEditMode}
                  onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
                  fieldErrors={fieldErrors}
                  onValueChange={onValueChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          ref='modalEditTerms'
          className='c-modal c-modal--half c-modal--overflow-y'
          modalHeading='Choose Variable Pay Elements'
          data-testid='terms-edit-modal'
        >
          <TermsFormEditContainer onSubmit={() => null} {...this.props} />
        </Modal>
        {isEmployeeOnly &&
        <div className='u-hide-on-desktop'>
          <SectionHeading text='Pay & Taxes' />
          {mobilePayTaxesViews.map(view => (
            <AccordionContainer key={view.key} title={view.title} titleColor={view.color} fullWidth rounded>
              <MobileDetailsView rows={this.getRowsForView(view.key, this.props)} isSingleColumn={view.isSingleColumn} isMultiRow={view.isMultiRow} />
            </AccordionContainer>
          ))}
        </div>}
      </>
    )
  }
}

PayTaxesView.propTypes = {
  isFetching: PropTypes.bool,
  employerDeductions: PropTypes.array,
  employeeDeductions: PropTypes.array,
  employeeNetDeductions: PropTypes.array,
  onEdit: PropTypes.func,
  isEmployeeOnly: PropTypes.bool,
  isEditDeductionsButtonVisible: PropTypes.bool,
  onJobAndOrgEdit: PropTypes.func,
  onTermsEdit: PropTypes.func,
  onDeductionsEdit: PropTypes.func,
  employee: PropTypes.object,
  employeePayrolls: PropTypes.array,
  employeePaygroups: PropTypes.array,
  employeeAnnualSalary: PropTypes.number,
  EmployeeStatus: PropTypes.object,
  employeeCustomFields: PropTypes.array,
  effectiveDate: PropTypes.string,
  fixedTerms: PropTypes.array,
  inEditMode: PropTypes.bool,
  inAddMode: PropTypes.bool,
  variableTerms: PropTypes.array,
  data: PropTypes.object,
  onUpdateGlobalDataForSave: PropTypes.func,
  fieldErrors: PropTypes.array,
  onValueChange: PropTypes.func,
  hasEmployeeActiveInstances: PropTypes.bool,
  hasEmployeeLockedInstances: PropTypes.bool,
  inlineMode: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  disableNonGlobalButtons: PropTypes.bool,
  onAnnualSalaryHistoryBtnClick: PropTypes.func,
  setInlineMode: PropTypes.func,
}

export default PayTaxesView
