import CoreLayout from 'layouts/PageLayout/PageLayout'
import ReportAnalyticsRoute from './routes/ReportAnalytics'
import InnerWrapper from 'layouts/InnerWrapper'

export default (store) => ({
  path : '/reporting/analytics',
  skipChildren: true,
  exact: true,
  indexRoute: { component: InnerWrapper },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ReportAnalyticsRoute(store)]
})
