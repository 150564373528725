import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const DEPARTMENT_UNITS_FETCH = 'DEPARTMENT_UNITS_FETCH'
export const DEPARTMENT_UNITS_RECEIVE = 'DEPARTMENT_UNITS_RECEIVE'
export const DEPARTMENT_UNITS_CREATE = 'DEPARTMENT_UNITS_CREATE'
export const DEPARTMENT_UNITS_UPDATE = 'DEPARTMENT_UNITS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DEPARTMENT_UNITS_FETCH,
  receive: DEPARTMENT_UNITS_RECEIVE,
  create: DEPARTMENT_UNITS_CREATE,
  update: DEPARTMENT_UNITS_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'departments', 'departments', 'Departments')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDepartmentsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDepartments = actionUtility.fetchEntities
export const createDepartment = actionUtility.createEntity
export const updateDepartments = actionUtility.bulkUpdateEntity
