import React from 'react'
import PropTypes from 'prop-types'
import { Link, Prompt } from 'react-router-dom'
import SectionHeading from 'components/SectionHeading'
import Form from 'components/form/Form'
import ConfirmationModal from 'components/ConfirmationModal'
import Dropzone from 'react-dropzone'
import Back from 'components/Back'
import { Field, reduxForm } from 'redux-form'
import { isIE } from 'mobile-device-detect'
import { isFunction, isEmpty } from 'utils/fnkit/typeChecks'
import { replace } from 'utils/fnkit/string'
import PayslipTable from 'components/table/PayslipTable'
import { CellNumberFormat, amountDeferredInput, deferredInput, employeePersonalLink } from 'utils/tableDataFormatters'
import { LEFT_CHEVRON, RIGHT_CHEVRON, LEFT_DOUBLE_CHEVRON, RIGHT_DOUBLE_CHEVRON } from 'utils/enums/employeePaginationChevrons'
import { setPaginationControl } from 'utils/helperClasses/Pagination'
import { formatNumberToCurrency } from 'utils/number'
import InputField from 'components/form/InputField'
import { onChangeSubmit } from 'utils/form'
import Button from 'components/buttons/Button'
import { ALL_ROUTES } from 'configs/routes'
import NoResultsView from 'components/NoResultsView'

let classNames = require('classnames')

class PayrollInstance extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      /**
       * Which page will be the next current / active page?
       *
       * In the case we're switching between the pagination pages,
       * there's a little delay when we navigate from one page to another, because of the back-end server response time.
       * Because of this, when the user clicks on a pagination page we mark it as `candidateCurrentPage`,
       * while we're waiting for the back-end response.
       * `candidateCurrentPage` has a special UI styling, indicating that this page will be the next active one.
       * Once the back-end returns the response, this page is now the active one.
       */
      candidateCurrentPage: null,
      confirmedNavigation: false,
      hasSubmitted: false,
      nextLocation: null,
    }
  }

  /**
   * Handle modal opening
   * @param modalRef
   */
  handleModalOpen (modalRef) {
    modalRef.showModal()
  }

  onUpload () {
    this.dropzoneRef.open()
  }

  isPaginationLastPage () {
    const {
      pagination: { currentPage, totalPages },
    } = this.props

    return currentPage + 1 === totalPages
  }

  /**
   * Render the pagination pages (items)
   *
   * Pagination and its filtration mechanism is zero-based.
   * But, keep in mind that, when we render the pages, we start counting them from 1: `{ page + 1 }`,
   * because of usability reasons.
   * @returns {JSX}
   */
  renderPagination () {
    const {
      pagination: { currentPage, totalPages },
      filter,
    } = this.props

    if (totalPages === 1) return null

    let pageNeighbours = 2
    const totalNumbers = pageNeighbours * 2 + 0
    const totalBlocks = totalNumbers + 1
    const pages = setPaginationControl(totalPages, currentPage + 1, pageNeighbours, totalNumbers, totalBlocks)

    return (
      <div className='c-custom-pagination u-margin-right-none'>
        {pages.map((page) => {
          if (page === LEFT_CHEVRON) {
            return (
              <li
                key={page}
                className='c-custom-pagination__item u-cursor--pointer'
                onClick={() => {
                  this.setState({
                    candidateCurrentPage: currentPage - 1,
                  })
                  filter(currentPage - 1)
                }}
              >
                <span aria-hidden='true'>{'<'}</span>
                <span className='sr-only'>Previous</span>
              </li>
            )
          }
          if (page === LEFT_DOUBLE_CHEVRON) {
            return (
              <li
                key={page}
                className='c-custom-pagination__item u-cursor--pointer'
                onClick={() => {
                  this.setState({
                    candidateCurrentPage: 0,
                  })
                  filter(0)
                }}
              >
                <span aria-hidden='true'>{'<<'}</span>
                <span className='sr-only'>Go to first page</span>
              </li>
            )
          }
          if (page === RIGHT_CHEVRON) {
            return (
              <li
                key={page}
                className='c-custom-pagination__item u-cursor--pointer'
                onClick={() => {
                  this.setState({
                    candidateCurrentPage: currentPage + 1,
                  })
                  filter(currentPage + 1)
                }}
              >
                <span> {'>'} </span>
                <span className='sr-only'>Next</span>
              </li>
            )
          }
          if (page === RIGHT_DOUBLE_CHEVRON) {
            return (
              <li
                key={page}
                className='c-custom-pagination__item u-cursor--pointer'
                onClick={() => {
                  this.setState({
                    candidateCurrentPage: totalPages - 1,
                  })
                  filter(totalPages - 1)
                }}
              >
                <span>{'>>'}</span>
                <span className='sr-only'>Go to last page</span>
              </li>
            )
          }
          if (currentPage + 1 === page) {
            return <span className='c-custom-pagination__item c-custom-pagination__item--active'>{page}</span>
          }

          return (
            <button
              type='button'
              key={page}
              className={classNames({
                'c-custom-pagination__item': true,
                'c-custom-pagination__item--clicked': this.state.candidateCurrentPage === page,
              })}
              onClick={() => {
                this.setState({ candidateCurrentPage: page - 1 })
                filter(page - 1)
              }}
            >
              {page}
            </button>
          )
        })}
      </div>
    )
  }
  isRedirectedFromAlerts = () => (isFunction(this.props.query) ? this.props.query('redirectHome') : this.props.query.get('redirectHome'))

  handleBlockedNavigation = (nextLocation, action) => {
    this.setState({ nextLocation: nextLocation })
    const isDirty = this.props.checkIfIsDirty()
    if (isDirty && !this.state.confirmedNavigation) {
      this.handleModalOpen(this.refs.confirmLeaveModal)
      return false
    }
    return true
  }

  handleConfirmNavigationClick = () => {
    this.setState({ confirmedNavigation: true }, () => {
      if (this.state.nextLocation.pathname.includes('employees')) {
        return this.props.history.push(this.state.nextLocation.pathname)
      }
      if (this.isRedirectedFromAlerts()) {
        return this.props.history.push(`/payruns/${this.props.payrollInstanceTabStatus}`)
      }
      this.props.history.goBack()
    })
  }

  onBackNavigate = () => {
    const { search } = this.props.location
    const { payfile } = this.props
    const employeeId = search ? new URLSearchParams(search).get('employeeId') : null
    const path = employeeId ? `/employees/${employeeId}/payroll-updates` : `/payruns/active?action=openFilesModal&id=${payfile.id}`

    this.props.history.push(path)
  }

  render () {
    const {
      isFetching,
      payfile: { id, name, data, footer = [], headings, flags, authTaskId },
      downloadPayFile,
      uploadFile,
      updateTask,
      submitting,
      isSaveEnabled,
      isAuthorizedEnabled,
      isImportEnabled,
      isDownloadEnabled,
      currentSearch,
      disabledPayFileExportButton,
      hasAccessToVersioning,
    } = this.props

    // Don't show view at all if no employees (data)
    let itemsToRemove = []

    // Apply props on headings based on flags
    const normalizedHeadings = headings.map((heading, i) => {
      let structure = { ...heading }
      if (structure.name === 'id') {
        structure['isKey'] = true
        structure['isVisible'] = false
      }
      structure['classNames'] = `${structure['className'] || ''} `
      delete structure['className']

      if (structure.isEmployeePersonalLink) {
        structure['classNames'] = `${structure['classNames'] || ''} u-text--left`
      }
      if (!structure.isEmployeePersonalLink) {
        structure['classNames'] = structure.isParent ? `${structure['classNames'] || ''} u-text--center` : `${structure['classNames'] || ''} u-text--right`
      }
      // Structure the data for the new table
      structure['accessor'] = structure.name
      structure['Header'] = structure.text
      structure['disableFilters'] = true
      structure['disableSortBy'] = true
      structure['minWidth'] = 150
      structure['rowSpan'] = structure.rowspan
      if (structure.name.startsWith('extraFields.')) {
        structure['accessor'] = replace(structure.name, 'extraFields.', '')
      }

      if (structure.name === 'notes') {
        structure['classNames'] = 'fix-width u-text--left u-min-width-300'
        structure['columnClassName'] += ' text-input u-text--left'
      }
      if (structure.isParent) {
        structure['columns'] = []
        structure['classNames'] += ' colspan-header border-1-valhalla--left border-1-valhalla--right'
        const startIndex = i + 1
        const items = headings.slice(startIndex, startIndex + structure.colspan)
        items.forEach((el, index) => {
          let isOneCell = items.length === 1
          let cellBorderClass = ''
          if (!isOneCell && index === 0) cellBorderClass = 'border-1-valhalla--left'
          if (!isOneCell && index === items.length - 1) cellBorderClass = 'border-1-valhalla--right'
          if (isOneCell) cellBorderClass = 'border-1-valhalla--left border-1-valhalla--right'

          itemsToRemove.push(el.name)
          let dataRow = {
            Header: el.text,
            accessor: el.name,
            classNames: ` white-space--normal-forced word-wrap--break-word u-text--right ${cellBorderClass}`,
            columnClassName: 'u-text--right',
            minWidth: 150,
            disableSortBy: true,
            disableFilters: true,
          }
          if (el.isEditable) {
            dataRow['Cell'] = el.type === 'decimal' ? amountDeferredInput : deferredInput
            dataRow.employeeFieldName = 'id'
          }
          if (el.isNumber) el.columnClassName = 'u-text--right'

          if (el.isNumber && !el.isEditable) {
            dataRow['Cell'] = CellNumberFormat
          }
          structure['columns'].push(dataRow)
        })
      }

      if (structure.isNumber) structure.columnClassName = 'u-text--right'

      if (structure.isEditable) {
        structure['Cell'] = structure.type === 'decimal' ? amountDeferredInput : deferredInput
        structure.employeeFieldName = 'id'
      }

      if (structure.isNumber && !structure.isEditable) structure['Cell'] = CellNumberFormat

      if (structure.isEmployeePersonalLink) {
        structure['Cell'] = employeePersonalLink
        structure.employeeFieldName = 'sysEmpId'
      }

      if (structure.isParent) {
        structure.classNames += ' u-text--center '
      }

      if (structure.isNested) {
        structure.classNames += ' grouped-structure__sub u-text--center u-bg--white u-text--minsk '
        structure.row = 1
      }
      const columnFilter = {
        type: 'TextFilter',
        delay: 1000,
        placeholder: 'Filter',
      }
      if (structure.hasFilter) structure.filter = columnFilter
      return structure
    })

    // Align footer values
    let alignedFooter = footer.map((item) => {
      return {
        ...item,
        align: isNaN(item.label) ? 'left' : 'right',
      }
    })

    const filteredHeadings = normalizedHeadings.filter((head) => !itemsToRemove.includes(head.name))
    const firstFooterItem = alignedFooter[0]
    const emptyRows = firstFooterItem ? Array(firstFooterItem.columnIndex).fill({ displayValue: ' ' }) : []

    // Add empty items to account for possible fromDate and toDate columns
    for (let idx = 0; idx < alignedFooter.length; idx++) {
      if (idx === 0) continue
      let item = alignedFooter[idx]
      const difference = item.columnIndex - alignedFooter[idx - 1].columnIndex
      if (difference > 1) {
        const addedRows = Array(difference - 1).fill({
          columnIndex: item.columnIndex,
          label: '',
          name: item.name,
        })
        alignedFooter.splice(idx, 0, ...addedRows)
      }
    }

    alignedFooter.map((footer, i) => {
      footer['displayValue'] = formatNumberToCurrency(footer.label, false, 'ENEN', null, 2)
      footer['rowKey'] = footer.isLabel ? footer.label.toLowerCase() : footer.name.toLowerCase()
      footer['classNames'] = footer.isBold ? ' u-weight--bold u-text--right ' : ' u-text--right '
    })

    alignedFooter.unshift(...emptyRows)

    const filteredData = data.map((el) => {
      let formatted = { ...el }
      return Object.entries(formatted).reduce((newObj, [key, value]) => {
        const newKey = key.startsWith('extraFields.') ? key.replace('extraFields.', '') : key
        newObj[newKey] = value
        return newObj
      }, {})
    })

    const tableData = {
      headings: filteredHeadings,
      data: filteredData,
      // Always add a Footer row (even an empty one),
      // because the horizontal scrolling functionality is attached to the Footer.
      // Other approach would be to conditionally attach the scrolling functionality to the body or footer,
      // but would be much harder to do it.
      // Fill the footer with data, only on the last table pagination page.
      // That's needed, because we assumed that the footer contains a total row
      // and we want it to be visible only on the last page.
      customLastRow: {
        insertAfterRow: data.length,
        data: this.isPaginationLastPage() ? alignedFooter : [],
      },
    }

    const pagination = this.renderPagination()

    const shouldUseUploadPath = flags.isUpdatable || this.props.location.pathname.indexOf(ALL_ROUTES.PAYRUNS.REOPENED) > 0
    return (
      <div className={`u-flag--isPayfile u-padding-left page--payrun-file ${!isEmpty(currentSearch) ? 'searching' : ''}`}>
        <Prompt when message={this.handleBlockedNavigation} />
        <Dropzone onDrop={(files) => uploadFile(id, files[0])} ref={(node) => (this.dropzoneRef = node)} className='u-relative' />

        <ConfirmationModal
          ref='confirmUpload'
          className='c-modal'
          modalHeading='Confirmation'
          onConfirm={() => {
            this.onUpload()
          }}
        >
          <p>Payrun file status is "Sent & Locked".</p>
          <p>
            Any changes applied now will NOT be visible on "P&T Changes" Tab. If you need these applied in current payrun please change the status to "Active".
          </p>
          <p>Do you still want to proceed?</p>
        </ConfirmationModal>
        <ConfirmationModal ref='confirmLeaveModal' className='c-modal' modalHeading='Confirmation' onConfirm={this.handleConfirmNavigationClick}>
          <p>Are you sure you want to exit before saving the file? All changes made will be lost.</p>
        </ConfirmationModal>
        <div className='o-layout'>
          <div className='o-layout__item u-1/1 u-1/2@tablet u-padding-left-none'>
            <Back onBackNavigate={this.onBackNavigate} />
          </div>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-text--right'>
              {isImportEnabled && flags.isImportable && (
                <button
                  type='button'
                  onClick={() => (shouldUseUploadPath ? this.onUpload() : this.refs.confirmUpload.showModal())}
                  className='c-btn c-btn--small c-btn--curious
                  u-padding-left u-padding-right u-margin-left u-margin-bottom-tiny'
                  title='Import'
                  data-testid='upload'
                >
                  <span className='icon icon--upload' />
                </button>
              )}
              {isDownloadEnabled && flags.isDownloadable && (
                <Button
                  // eslint-disable-next-line max-len
                  className={`u-margin-left u-padding-left u-padding-right u-margin-bottom-tiny c-btn c-btn--small c-btn--curious ${
                    disabledPayFileExportButton && 'disabled'
                  }`}
                  onClick={() => downloadPayFile(id)}
                  title={`${disabledPayFileExportButton ? 'The downloading of this payrun file is already in progress' : 'Download'}`}
                  disabled={disabledPayFileExportButton}
                  data-testid='download'
                >
                  <span className='icon icon--download' />
                </Button>
              )}
            </div>
          </div>
          <Form
            {...this.props}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault()
              if ((e.key === '' || e.key === ' ') && e.target.type === 'submit') {
                e.preventDefault()
              }
            }}
          >
            <SectionHeading text={name}>
              <div className='o-layout__item o-layout--right u-1/1 u-1/2@desktop'>
                {hasAccessToVersioning && (
                  <Link
                    to={`/${ALL_ROUTES.PAYRUNS.BASE}/${id}/${ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION}`}
                    className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left'
                    title='Analyze'
                    data-testid={`analyze-button-${id}`}
                  >
                    <span className='icon icon--bar-chart' />
                  </Link>
                )}
                {isSaveEnabled && flags.isUpdatable && (
                  <button
                    type='submit'
                    disabled={isFetching || submitting}
                    className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left ${submitting ? 'c-btn--submitting' : ''}`}
                    title='Save'
                    data-testid='save'
                  >
                    <span className='icon icon--save' />
                  </button>
                )}
                {isAuthorizedEnabled && flags.isAuthorizeAllowed && (
                  <div
                    onClick={() => this.handleModalOpen(this.refs.authorizeInstance)}
                    className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'
                    title='Authorize'
                  >
                    Authorize
                  </div>
                )}
                {isAuthorizedEnabled && flags.isAuthorized && (
                  <div
                    className='c-btn c-btn--submit c-btn--small c-btn--curious u-margin-left-tiny u-cursor--not-allowed u-padding-left u-padding-right'
                    title='Authorized'
                  >
                    Authorized
                  </div>
                )}
              </div>
              <div>
                <div className='o-layout__item o-layout--left u-1/1 u-1/2@desktop u-margin-top'>
                  <Field
                    name='search'
                    placeholder='Search by ID, Firstname or Surname'
                    component={InputField}
                    type='text'
                    className='c-input'
                    labelClassName='c-label'
                    formGroupClassName='u-margin-none'
                    onChange={onChangeSubmit(this.props.onSearch)}
                    data-testid='search'
                  />
                </div>
                <div className='o-layout__item o-layout--right u-1/1 u-1/2@desktop u-margin-top'>{pagination}</div>
              </div>
            </SectionHeading>

            <ConfirmationModal
              ref='authorizeInstance'
              className='c-modal'
              modalHeading='Confirmation'
              onConfirm={() => {
                updateTask(authTaskId)
              }}
            >
              <p>
                Do you want to authorize <span className='u-weight--bold'>{name}</span>?
              </p>
            </ConfirmationModal>
            {data.length && (
              <PayslipTable
                dynamicValues
                remote
                overrideRowDependency
                onDataChangeRedrawHeaders
                {...tableData}
                wrapperClassName={classNames({
                  'u-1/1 react-bs-table--overflow-auto': true,
                  'u-half-opacity': isFetching || submitting,
                  'react-bs-table': true,
                })}
                trClassName={(row, rowIndex) => 'react-bs-table__cell--overflow-ellipsis'}
                modifierClasses={classNames({
                  'react-bs-container-body': true,
                  // On IE columns freezing functionality (added by this class) doesn't work,
                  // because IE doesn't support `position: sticky`. We tried to reimplement it with `position: fixed`,
                  // but in the short period of time we were trying we didn't manage to do fix it.
                  // Because of this we decided to not enable the freezing columns for IE.
                  'o-payslip-table--payfile': !isIE,
                })}
                tableElementClassName='table--layout-auto'
                data-testid='payrun-table-wrapper'
              />
            )}
            {!data.length && <NoResultsView />}
            <div className='o-layout__item u-1/1 u-margin-top-small'>
              <div className='u-float--right'>{pagination}</div>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

PayrollInstance.propTypes = {
  pagination: PropTypes.object,
  filter: PropTypes.func,
  isFetching: PropTypes.bool,
  payfile: PropTypes.object,
  downloadPayFile: PropTypes.func,
  uploadFile: PropTypes.func,
  updateTask: PropTypes.func,
  submitting: PropTypes.bool,
  isSaveEnabled: PropTypes.bool,
  isAuthorizedEnabled: PropTypes.bool,
  isImportEnabled: PropTypes.bool,
  isDownloadEnabled: PropTypes.bool,
  checkIfIsDirty: PropTypes.func,
  payrollInstanceTabStatus: PropTypes.string,
  currentSearch: PropTypes.string,
  history: PropTypes.object,
  onSearch: PropTypes.func,
  disabledPayFileExportButton: PropTypes.bool,
  query: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default reduxForm({
  form: 'payrollInstanceForm',
  // Here we're enabling this option, because on each pagination page change,
  // the fields returned by the back-end have to be populated with corresponding page values.
  // Because of this we have to update the `initialValues` and reinitialize them on each page change.
  enableReinitialize: true,
  touchOnBlur: false,
})(PayrollInstance)
