const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const yesNoBoolValuesOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export { yesNoOptions, yesNoBoolValuesOptions }
