import createSelector from 'utils/createSelector'
import { _getCompaniesByCountry, getCompaniesByCountries } from 'redux/selectors/company'
import { getCompaniesIds } from 'redux/selectors/filters'
import _ from 'lodash'
import { isArray, isEmpty } from 'utils/fnkit/typeChecks'
import { sortByName } from 'utils/strings'

const getBusinessUnitId = (state, props) => parseInt(props.businessUnitId)
export const getCompanyId = (state, props) => parseInt(props.companyId)
const getCountryId = (state, props) => parseInt(props.countryId)

export const getBusinessUnitById = createSelector(getBusinessUnitId, ({ BusinessUnit }, businessUnitId) => {
  const businessUnit = BusinessUnit.withId(businessUnitId)
  return {
    ...businessUnit.ref,
    company: businessUnit.company.ref,
  }
})

export const getBusinessUnits = createSelector(({ BusinessUnit }) => {
  return BusinessUnit.all()
    .toRefArray()
    .map((unit) => ({
      ...unit,
    }))
})

export const getBusinessUnitsByAccessibleCompanies = createSelector(({ BusinessUnit, Company }) => {
  const accessibleCompaniesIds = Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()
    .map((company) => company.id)

  return BusinessUnit.all()
    .filter((bu) => accessibleCompaniesIds.includes(bu.company))
    .toModelArray()
    .map((unit) => ({
      ...unit.ref,
      company: { ...unit.company.ref },
    }))
})

export const getBusinessUnitsWithCompanyWithCountry = createSelector(({ BusinessUnit }) => {
  return BusinessUnit.all()
    .toModelArray()
    .map((unit) => ({
      ...unit.ref,
      company: { ...unit.company.ref },
      country: { ...unit.company.country.ref },
    }))
})

export const getBusinessUnitsByCompany = createSelector(getCompanyId, ({ BusinessUnit }, companyId) => {
  return BusinessUnit.filter((unit) => unit.company === companyId)
    .toRefArray()
    .map((unit) => ({
      ...unit,
    }))
})

export const getBusinessUnitsByCompanies = createSelector(getCompaniesIds, ({ BusinessUnit }, companiesIds) => {
  return BusinessUnit.filter((unit) => companiesIds.includes(unit.company))
    .toModelArray()
    .map((unit) => ({ ...unit.ref, company: { ...unit.company.ref } }))
})

export const getBusinessUnitsByCountryThroughCompany = createSelector(getCountryId, ({ Country }, countryId) => {
  const companies = _getCompaniesByCountry(Country, countryId)
  const businessUnits = companies.map((company) => {
    return company.businessUnits.toModelArray().map((businessUnit) => ({
      ...businessUnit.ref,
    }))
  })

  return _.flatten(businessUnits)
})

export const getBusinessUnitsByCountriesThroughAccessibleCompany = createSelector(getCompaniesByCountries, ({ Company }, companies) => {
  const accessibleCompaniesIds = Company.all()
    .filter((company) => company.isAccessable)
    .toModelArray()
    .map((company) => company.id)

  const businessUnits = companies
    .filter((company) => accessibleCompaniesIds.includes(company.id))
    .map((companyRef) => {
      const company = Company.withId(companyRef.id)
      return company.businessUnits
        .toModelArray()
        .map((businessUnit) => ({
          ...businessUnit.ref,
          company: { ...companyRef },
        }))
        .reverse()
    })

  return _.flatten(businessUnits)
})

export const getBusinessUnitsBasedOnCompanyType = ({ state, isHQ, companiesIds }) => {
  const hasCompanies = isArray(companiesIds) && !isEmpty(companiesIds)
  /**
   * In case we have an array of companies, we need all
   * the business units; otherwise, we need to check if it is a
   *  single company HQ and call the appropriate method.
   */
  const companies = hasCompanies ? getBusinessUnitsByCompanies(state, { companiesIds }).sort(sortByName) : null

  if (companies) {
    return companies
  }

  return isHQ ? getBusinessUnitsWithCompanyWithCountry(state).sort(sortByName) : getBusinessUnitsByCompany(state, { companyId: companiesIds }).sort(sortByName)
}
