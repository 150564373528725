import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PropTypes from 'prop-types'
import { onChangeSubmit } from 'utils/form'
import FlagOption from 'components/form/select/FlagOption'
import FlagValue from 'components/form/select/FlagValue'
import ColorOption from 'components/form/select/ColorOption'
import ColorValue from 'components/form/select/ColorValue'
import { Authorization } from 'containers/Authorization'
import { getSelectionOptions } from 'utils/selectOptions'
import { sortByLabel } from 'utils/strings'

class EventsFilters extends React.Component {
  // On each form field change, we are submitting the form manually and resetting the passed `fields`.
  onSubmit (fields) {
    onChangeSubmit(this.props.handleSubmit)()

    if (fields) this.props.resetFields(fields)
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    const {
      companies,
      payrolls,
      payrollsProcesses,
      owners,
      handleSubmit,
      shouldMergePayrollsAndProcesses,
      shouldNotShowStatus = false,
      addedOptionsToOwnersSelect,
    } = this.props
    if (!this.props.shouldDisplay) return null

    let ownersOptions = owners.map(owner => ({
      value: owner.id,
      label: owner.name
    }))

    if (addedOptionsToOwnersSelect) ownersOptions = [...ownersOptions, ...addedOptionsToOwnersSelect]

    return (
      <form onSubmit={handleSubmit}>
        <div className='text-center u-relative u-float--right'>
          <Link
            to={`/toolbox/calendar/year/payruns/${this.props.selectedPayroll}`}
            className={`c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny u-margin-left-small u-padding-top-tiny u-padding-bottom-tiny ${
              !this.props.selectedPayroll && 'disabled'
            }`}
            onClick={(e) => {
              if (!this.props.selectedPayroll) {
                e.preventDefault()
              }
            }}
            data-testid={`calendar-button-${this.props.selectedPayroll}`}
            title='Calendar'
          >
            <span className='icon icon--calendar-year' />
          </Link>
        </div>
        {!shouldNotShowStatus && (
          <Field
            name='actionStatus'
            component={CustomSelectField}
            className='c-custom-select--small c-custom-select--min-width--normal'
            formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
            labelClassName='c-label'
            placeholder='Action Status'
            clearable
            options={getSelectionOptions('statusesForActions').map((status) => ({
              value: status.id,
              label: status.name,
            }))}
            onChanged={() => this.onSubmit()}
          />
        )}
        <Authorization permissions={['TOOLBOX_OWNER_FIELD_VIEW']}>
          <Field
            name='owner'
            component={CustomSelectField}
            className='c-custom-select--small c-custom-select--min-width--normal'
            formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
            labelClassName='c-label'
            placeholder='Owner'
            clearable
            options={ownersOptions}
            onChanged={() => this.onSubmit()}
          />
        </Authorization>
        {!shouldMergePayrollsAndProcesses && (
          <Field
            name='payroll'
            component={CustomSelectField}
            optionComponent={ColorOption}
            valueComponent={ColorValue}
            type='text'
            className='c-custom-select--small c-custom-select--min-width--large'
            formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
            labelClassName='c-label'
            placeholder='Process name'
            clearable
            options={payrolls.map((payroll) => ({
              value: payroll.id,
              label: payroll.name,
            }))}
            onChanged={(id) => {
              this.props.setCompany(id)
              this.onSubmit(['owner'])
            }}
          />
        )}

        {shouldMergePayrollsAndProcesses && (
          <Field
            name='payrollsAndProcesses'
            component={CustomSelectField}
            optionComponent={ColorOption}
            valueComponent={ColorValue}
            type='text'
            className='c-custom-select--small c-custom-select--min-width--large'
            formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
            labelClassName='c-label'
            placeholder='Process name'
            clearable
            data-testid='payrolls-and-processes-button'
            options={payrollsProcesses}
            onChanged={(selected) => {
              this.props.setCompany(selected)
              this.onSubmit([])
            }}
          />
        )}
        <Field
          name='company'
          component={CustomSelectField}
          optionComponent={FlagOption}
          valueComponent={FlagValue}
          type='text'
          className='c-custom-select--small c-custom-select--min-width--large'
          formGroupClassName='u-margin-none u-margin-left-small u-margin-bottom-tiny u-float--right'
          labelClassName='c-label'
          placeholder='Country/Company'
          clearable
          options={companies
            .map((company) => ({
              value: company.id,
              label: company.name,
              abbreviature: company.country.abbreviature,
            }))
            .sort(sortByLabel)}
          onChanged={() => this.onSubmit(['payroll', 'companyProcess', 'payrollsAndProcesses'])}
        />
      </form>
    )
  }
}

EventsFilters.propTypes = {
  shouldNotShowStatus: PropTypes.bool,
  isFetching: PropTypes.bool,
  companies: PropTypes.array,
  payrolls: PropTypes.array,
  payrollsProcesses: PropTypes.array,
  owners: PropTypes.array,
  handleSubmit: PropTypes.func,
  resetFields: PropTypes.func,
  shouldMergePayrollsAndProcesses: PropTypes.bool,
  setCompany: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  addedOptionsToOwnersSelect: PropTypes.array,
  selectedPayroll: PropTypes.number
}

export default EventsFilters
