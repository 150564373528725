import React from 'react'
import { FadeModal as Modal } from 'boron-15'
import PropTypes from 'prop-types'

let contentStyle = {
  backgroundColor: '$color-gallery'
}

class BaseConfirmationModal extends React.Component {
  constructor (props) {
    super(props)
    this.modalRef = React.createRef()
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.onReject = this.onReject.bind(this)
  }

  showModal () {
    this.modalRef.current.show()
  }

  hideModal () {
    this.modalRef.current.hide()
  }

  onConfirm () {
    this.props.onConfirm()
    this.hideModal()
  }

  onReject () {
    this.props.onReject && this.props.onReject()
    this.hideModal()
  }

  render () {
    const canCloseByBackdrop = !this.props.disableBackdropClose
    // eslint-disable-next-line react/prop-types
    return <div data-testid={this.props['data-testid']}>
      <Modal
        contentStyle={contentStyle}
        ref={this.modalRef}
        closeOnClick={canCloseByBackdrop}
        {...this.props}
      >
        <span onClick={() => this.onReject()} className='icon--ex' />
        <div className='o-layout'>
          <div className='o-layout__item u-text--center u-1/1'>
            <h2 className='u-text--center u-text--huge u-weight--bold'>{this.props.modalHeading}</h2>
          </div>
        </div>
        <div className='o-layout'>
          <div className='o-layout__item u-text--center u-1/1 u-text--overflow-wrap'>
            {this.props.children}
          </div>
        </div>
      </Modal>
    </div>
  }
}

BaseConfirmationModal.propTypes = {
  disableBackdropClose: PropTypes.bool,
  modalHeading: PropTypes.string,
  onReject: PropTypes.func,
  children: PropTypes.any,
  onConfirm: PropTypes.func,
  hideModal: PropTypes.func,
}

export default BaseConfirmationModal
