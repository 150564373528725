import React from 'react'
import { change } from 'redux-form'
import PropTypes from 'prop-types'
import PredefinedRateFormContainer from '../containers/PredefinedRateFormContainer'
import CustomRateForm from './CustomRateForm'
import PrimaryButton from 'components/buttons/Primary'

class ReportingCurrencyForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // Mode can be:
      // - null - on first opening it is empty
      // - predefined - to select from predefined fx rates
      // - custom - to enter custom fx rates
      selectedMode: null
    }
  }

  render () {
    const { customRatesFieldsNames, resetFormFields } = this.props
    return <div className='o-layout '>
      <div className='o-layout__item u-text--center u-1/1'>
        <PrimaryButton
          onClick={() => {
            this.setState({ selectedMode: 'predefined' })
            // Reset toCurrency and rate fields
            this.props.dispatch(change(this.props.form, 'toCurrency', null))
            this.props.dispatch(change(this.props.form, 'rate', null))
            const fieldsToReset = ['fxRate', 'rate', ...customRatesFieldsNames]
            resetFormFields(null, fieldsToReset)
          }}
          data-testid='predefinedRate'
        >
          Predefined Rate
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            this.setState({ selectedMode: 'custom' })
            const fieldsToReset = ['fxRate', 'rate']
            resetFormFields(null, fieldsToReset)
          }}
          classes='u-margin-left-tiny'
          data-testid='customRate'
        >
          Custom Rate
        </PrimaryButton>
      </div>
      { this.state.selectedMode === 'predefined' ? <PredefinedRateFormContainer {...this.props} /> : null }
      { this.state.selectedMode === 'custom' ? <CustomRateForm {...this.props} /> : null }
    </div>
  }
}

ReportingCurrencyForm.propTypes = {
  dispatch: PropTypes.func,
  customRatesFieldsNames: PropTypes.array,
  resetFormFields: PropTypes.func
}

export default ReportingCurrencyForm
