import React from 'react'
import Dropzone from 'react-dropzone'
import PayslipTable from 'components/table/PayslipTable'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { Loader } from 'components/Loader'
import ConfirmationModal from 'components/ConfirmationModal'
import { confirmMessage } from 'components/payruns/Messages'
import { isNil } from 'utils/fnkit/typeChecks'

class ImportFile extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.getDefaultState()
  }

  getDefaultState () {
    return {
      // Link to the new generated file with the wrong employee terms rows
      link: this.props.url,
      // Detailed list with all the wrong employee terms rows,
      // ordered by rows, fields and errors
      errors: this.props.errors,
      // Number of failure, success, total imported employee terms
      failure: this.props.failure,
      success: this.props.success,
      total: this.props.total,
      isLoading: false,
    }
  }

  onUpload () {
    this.dropzoneRef.open()
  }

  /*
   * Render the errors in table format
   *
   * Columns:
   * - line
   * - field
   * - error
   */
  renderErrors (errors) {
    let rows = []
    _.forOwn(errors, (er, line) => {
      _.forOwn(er, (e, field) => {
        rows.push({
          row: line,
          field: field,
          error: e,
        })
      })
    })
    return rows
  }

  renderLink () {
    const { shouldDownloadDirectly, getFile } = this.props
    const { link } = this.state

    if (!shouldDownloadDirectly) {
      return (
        <span onClick={() => getFile(link)} className='u-text--curious u-cursor--pointer'>
          this file
        </span>
      )
    }

    return (
      <a href={link} className='u-text--curious u-cursor--pointer'>
        this file
      </a>
    )
  }

  render () {
    const { importFile, entityName, showConfirmation } = this.props
    const { errors, failure, success, total } = this.state

    return (
      <div className='u-margin-bottom'>
        <ConfirmationModal
          ref='confirmUpload'
          className='c-modal'
          modalHeading='Confirmation'
          onConfirm={() => {
            this.onUpload()
          }}
        >
          <p>{confirmMessage}</p>
        </ConfirmationModal>

        <Dropzone
          onDrop={(files) => {
            this.setState({ isLoading: true, errors: null, link: null })
            importFile(files[0]).then((error) => {
              this.setState({ isLoading: false })
              if (error.data && error.data.url) this.setState({ link: error.data.url })
              if (error.errors) this.setState({ errors: error.errors })
              if (error.additionalInfo) {
                this.setState({
                  failure: error.additionalInfo.failure,
                  success: error.additionalInfo.success,
                  total: error.additionalInfo.total,
                })
              }
              if (!error.errors) {
                this.setState(this.getDefaultState())
              }
            })
          }}
          ref={(node) => {
            this.dropzoneRef = node
          }}
          className='u-relative'
        />

        <button
          type='button'
          onClick={() => (showConfirmation ? this.refs.confirmUpload.showModal() : this.onUpload())}
          className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
          title='Import'
          data-testid='upload-button'
        >
          <span className='icon icon--upload' />
        </button>
        {this.state.isLoading && <Loader />}
        {!isNil(total) && (
          <div className='u-margin-top'>
            <p>
              Tried to import <span className='u-weight--bold'>{total}</span> {entityName}.&nbsp; Successful - <span className='u-weight--bold'>{success}</span>
              .
              {failure > 0 && (
                <span>
                  {' '}
                  Failed - <span className='u-weight--bold'>{failure}</span>, you can see all of them in {this.renderLink()}.
                </span>
              )}
            </p>
          </div>
        )}
        {errors && (
          <PayslipTable
            wrapperClassName='u-1/1'
            tableElementClassName='u-margin-bottom-large table--layout-auto'
            headings={[
              {
                accessor: 'row',
                Header: 'Row',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                accessor: 'field',
                Header: 'Field',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                accessor: 'error',
                Header: 'Error',
                disableSortBy: true,
                disableFilters: true,
              },
            ]}
            data={this.renderErrors(errors)}
          />
        )}
      </div>
    )
  }
}

ImportFile.propTypes = {
  importFile: PropTypes.func,
  showConfirmation: PropTypes.bool,
}

export default ImportFile
