/* eslint-disable no-template-curly-in-string */
// Possible task owners
export const owners = {
  company: 'Company',
  software: 'Software',
  cot: 'Payslip',
  vendor: 'Payroll Partner',
}

export const taskableTypes = {
  company: 'Company'
}

/**
 * Mapping between routes URIs and their UI value (naming) representation
 *
 * Having that mapping, we're flexible enough to change a URI,
 * without the need of updating all already created Tasks' links.
 *
 * Object has to have `value` and `uri` fields, as follows:
 * ```
 *   {
 *     // Value of the Tasks shouldn't be changed.
 *     // If we need changing it, we also should change the Tasks in the DB, these are using it.
 *     value: 'some user friendly naming',
 *
 *     // `URI the above `value` points to
 *     uri: 'uri/the/value/points'
 *   }
 * ```
 *
 * @type {[*]}
 */
export const completionURIs = [
  {
    value: '',
    uri: 'None',
    requiredPermissions: []
  },
  {
    value: '/companies/${id}/corporate',
    uri: '/companies/${id}/corporate',
    requiredPermissions: ['COMPANY_VIEW']
  },
  {
    value: '/companies/${id}/pay-taxes?action=openCCTRequestForm',
    uri: '/companies/${id}/pay-taxes?action=openCCTRequestForm',
    requiredPermissions: ['COMPANY_COUNTRY_TERM-REQUEST_CREATE']
  },
  {
    value: '/payruns/active/${id}/instance',
    uri: '/payruns/active/${id}/instance',
    requiredPermissions: ['PAYFILE_VIEW']
  },
  {
    value: '/payruns/active/${id}/input-changes-file',
    uri: '/payruns/active/${id}/input-changes-file',
    requiredPermissions: ['PAYFILE_VIEW']
  },
  {
    value: '/payruns/active/${id}/time-attendance-updates',
    uri: '/payruns/active/${id}/time-attendance-updates',
    requiredPermissions: ['PAYFILE_VIEW']
  },
  {
    value: '/employees',
    uri: '/employees',
    requiredPermissions: ['EMPLOYEESEARCH_VIEW']
  },
  {
    value: '/employees?payrollInstance=${id}',
    uri: '/employees?payrollInstance=${id}',
    requiredPermissions: ['EMPLOYEESEARCH_VIEW']
  },
  {
    value: '/payruns/active?action=openFilesModal&id=${id}',
    uri: '/payruns/active?action=openFilesModal&id=${id}',
    requiredPermissions: ['PAYFILE_VIEW']
  }
]
