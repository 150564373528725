import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/accessAreaPivot'

class AccessArea extends CRUDModel {}
AccessArea.modelName = 'AccessArea'

AccessArea.fields = {
  id: attr(),
  company: fk('Company', 'accessArea'),
  employee: fk('EmployeeSystemUser', 'accessArea'),
  cot: fk('CotUser', 'accessArea'),
  vendor: fk('Vendor', 'accessArea')
}
AccessArea.actionTypes = actionTypes

export default AccessArea
