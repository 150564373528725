import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import SubCategoriesListContainer from '../containers/SubCategoriesListContainer'
import SubCategoryCreateContainer from '../containers/SubCategoryCreateContainer'
import SubCategoriesEditContainer from '../containers/SubCategoriesEditContainer'
import Modal from 'components/Modal'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      subCategoryId: null
    }
  }

  render () {
    return <InnerWrapper>
      <SubCategoryCreateContainer />
      <SubCategoriesListContainer
        onEdit={(subCategoryId) => {
          this.setState({
            subCategoryId
          })
          this.refs.editModal.showModal()
        }}
      />

      <Modal
        ref='editModal'
        className='c-modal'
        modalHeading='Edit Subcategory'
      >
        {this.state.subCategoryId && <SubCategoriesEditContainer
          subCategoryId={this.state.subCategoryId}
          onEdit={() => this.refs.editModal.hideModal()}
        />}
      </Modal>

    </InnerWrapper>
  }
}

export default RouteView
