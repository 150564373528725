import React, { memo, useCallback } from 'react'

import PropTypes from 'prop-types'

import validator from '@rjsf/validator-ajv8'
import { applySchemaFormTheme, getGlobalTemplates, getGlobalUISchema } from './theme'
import { areSchemaFormPropsEqual } from './capabilities'

/**
 * SchemaForm Component
 *
 * This component renders a form based on the provided JSON schema.
 *
 * @component
 * @example
 * Form Props: https://rjsf-team.github.io/react-jsonschema-form/docs/api-reference/form-props
 * JSON Schema: https://rjsf-team.github.io/react-jsonschema-form/docs/json-schema/
 * UI Schema: https://rjsf-team.github.io/react-jsonschema-form/docs/api-reference/uiSchema
 * Playground https://rjsf-team.github.io/react-jsonschema-form/
 * const onSubmit = (data) => { console.log(data); }
 * return <SchemaForm schema={schema} onSubmit={onSubmit} />
 *
 * @param {Object} props - The component props
 * @param {Object} props.schema - The JSON schema defining the form
 * @param {function} props.onSubmit - The callback to be called on form submission
 * @param {Object} [props.uiSchema] - The UI schema for customizing the form UI
 * @param {Object} [props.formData] - The initial form data
 * @param {Object} [props.widgets] - The custom widgets for form fields
 * @param {Object} [props.fields] - The custom fields for form
 * @param {Object} [props.idPrefix] - The prefix for form field IDs
 * @param {Object} [props.idSeparator] - The separator for form field IDs
 *
 * @returns {JSX.Element} The rendered SchemaForm component
 */
export const SchemaForm = memo(
  ({ className, schema, customValidate, uiSchema, formData, formContext, theme, customValidator = validator, templates, onChange, onSubmit, onError }) => {
    const ThemedForm = applySchemaFormTheme(theme)
    const handleFormChange = useCallback(
      (form, fieldId) => {
        if (fieldId) {
          onChange(form, fieldId)
        }
      },
      [onChange]
    )

    return (
      <ThemedForm
        className={className}
        schema={schema}
        customValidate={customValidate}
        uiSchema={{
          ...getGlobalUISchema(),
          ...uiSchema,
        }}
        templates={{
          ...getGlobalTemplates(),
          ...templates,
        }}
        formData={formData}
        validator={customValidator}
        onChange={handleFormChange}
        onSubmit={onSubmit}
        onError={onError}
        formContext={formContext}
        extraErrorsBlockSubmit={false}
      />
    )
  },
  areSchemaFormPropsEqual
)

SchemaForm.propTypes = {
  className: PropTypes.string,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  formContext: PropTypes.object,
  theme: PropTypes.object,
  customValidator: PropTypes.func,
  templates: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
}
