import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'

import { buildOptBasedOnObject } from 'utils/options'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { isEqual } from 'utils/fnkit/base'

import { fixedExtraPersonalFields, fixedSubcategories, fixedSubCategoriesLabels, generateFixedFilteredFields } from 'utils/enums/fixedCategoriesField'
import { getCompanyCountryTermsBasedOnCategories } from 'redux/selectors/companyCountryTermPivot'
import { combineFixedAndExtraFieldsByCategory, getSpecificFieldsByCountry } from 'routes/Reporting/utils/reportFieldUtils'
import { retrieveCompaniesCustomFields } from 'routes/Companies/modules/actions'

/**
 * Maps given subcategory objects to a new object using a fields mapper function.
 *
 * @param {Object} subcategories - An object containing various subcategory objects.
 * @param {Function} fieldsMapper - The function to map the fields of subcategories.
 * @returns {Object} The mapped object with the same keys as the input subcategories.
 */
const mapSubcategoriesWithCustomMapper = (subcategories, fieldsMapper) =>
  Object.keys(subcategories).reduce((acc, key) => {
    return { ...acc, [key]: subcategories[key].map(fieldsMapper) }
  }, {})

export const useCategoryFields = ({
  defaultFields,
  fixedCategories,
  selectedCountries,
  countries: countriesData,
  company,
  fieldsMapper,
  categoryListMapper,
  defaultOptionsMapper,
}) => {
  const dispatch = useDispatch()
  /*
   * Hook states
   */
  const [state, setState] = useState({
    fields: {},
    lastCompanies: [],
    categories: buildOptBasedOnObject(fixedSubCategoriesLabels),
  })
  const { fields, lastCompanies, categories } = state

  /*
   * Selectors
   */
  const countries = selectedCountries?.map((countryId) => countriesData.find((item) => item.id === countryId)?.name)
  const employeeFields = countries
    ? getSpecificFieldsByCountry(
      employeeFieldsConfigAlt({
        personalExtraFields: fixedExtraPersonalFields,
      }),
      countries
    )
    : []

  const filteredFields = generateFixedFilteredFields(employeeFields, fieldsMapper)
  const otherFields = mapSubcategoriesWithCustomMapper(fixedSubcategories, fieldsMapper)
  const countryCompanyFilteredFields = useSelector((state) =>
    getCompanyCountryTermsBasedOnCategories(state, {
      companies: company,
      fixedCategories,
    })
  )
  const countryCompanyFields = mapSubcategoriesWithCustomMapper(countryCompanyFilteredFields, fieldsMapper)

  /**
   * Retrieved data
   */
  const retrieveExtrafields = async () => {
    const extraFields = await dispatch(retrieveCompaniesCustomFields(company))
    const fixedFields = { ...filteredFields, ...otherFields, ...countryCompanyFields }
    const newFields = combineFixedAndExtraFieldsByCategory(fixedFields, extraFields, fieldsMapper)
    const newCategories = categoryListMapper(categories, newFields)
    setState({
      fields: newFields,
      lastCompanies: company,
      categories: defaultFields ? defaultOptionsMapper(defaultFields, newCategories) : newCategories,
    })
  }

  /*
   * Effects
   */
  useEffect(() => {
    const needsRetrieveFields = !isEmpty(company) && !isEqual(lastCompanies, company)
    if (needsRetrieveFields) {
      retrieveExtrafields()
    }
  }, [company])

  return {
    categories,
    fields,
  }
}
