import { connect } from 'react-redux'
import NavBarProfile from 'components/navbar/NavBarProfile'
import Fetcher from '../Fetcher'
import { getVendorUser } from '../../redux/selectors/vendorUsers'
import { logout } from 'redux/actions/auth'

const mapStateToProps = state => ({
  user: getVendorUser(state, { userId: state.auth.userId })
})

const mapDispatchToProps = { logout }

const Container = connect(mapStateToProps, mapDispatchToProps)(NavBarProfile)

export default Fetcher(Container, ['vendorUsers', 'countries'])
