import { createFilter } from 'utils/redux/filter'
import { buildFilterName } from './commonFilters'

const initialSort = [
  {
    name: 'firstname',
    order: 'asc',
  },
  {
    name: 'surname',
    order: 'asc',
  },
]

export const prepareEESystemUsersFilters = ({ page, limit, filters, state, props, sort = initialSort } = {}) => {
  const { isHQ } = props
  const companyId = parseInt(props.companyId)
  const filterName = buildFilterName({ companyId })['rolesAndRights']
  limit = limit ?? state.employeeSystemUsers.filters[filterName].params.limit
  page = page ?? state.employeeSystemUsers.filters[filterName].params.offset / limit
  filters = filters ?? state.employeeSystemUsers.filters[filterName].params

  if (isHQ === false) {
    if (!filters['inclusiveAccessableCompanies']?.toString().split(',').includes(companyId.toString())) {
      filters['inclusiveAccessableCompanies'] = (filters['inclusiveAccessableCompanies'] ? filters['inclusiveAccessableCompanies'] + ',' : '') + companyId
    } else {
      filters['inclusiveAccessableCompanies'] = filters['inclusiveAccessableCompanies']?.toString()
    }
  }

  return createFilter(
    {
      ...filters,
      offset: page * limit,
      limit,
      isKeyPerson: 1,
      sort,
    },
    filterName
  )
}

export default ({ sort = [], companyId, filterName, isHQ }) => ({
  name: 'employeeSystemUsers',
  params: [
    {
      _computed: {
        filter: (state) => {
          const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName]?.ids

          if (isFetchedAtLeastOnce) return prepareEESystemUsersFilters({ state, sort, props: { companyId, isHQ } })

          return prepareEESystemUsersFilters({ page: 0, limit: 25, filters: { isKeyPerson: 1 }, sort, state, props: { companyId, isHQ } })
        },
      },
    },
  ],
})
