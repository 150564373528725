import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { filterCompanyDataProtectionFieldStates } from 'redux/actions/companyDataProtectionFieldState'
import { getCompanyDataProtectionFieldStatesWithCategories,
  getFieldsStateInitialValues, getApprovalUserDate } from 'redux/selectors/companyDataProtectionFieldState'
import ApproveFieldsForm from '../components/ApproveFieldsForm'

class RevisionFieldsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(filterCompanyDataProtectionFieldStates({ companyDataProtectionFieldRevision: this.props.revisionId }))
  }
  render () {
    if (this.props.isFetching) return <div>Loading...</div>
    return <ApproveFieldsForm {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const { companyDataProtectionFieldState } = state
  const entities = [ companyDataProtectionFieldState ]
  if (isFetching(entities, true)) return { isFetching: true }

  return {
    fields: getCompanyDataProtectionFieldStatesWithCategories(state),
    initialValues: getFieldsStateInitialValues(state),
    latestApprovedDateAuthor: getApprovalUserDate(state),
    showApproveMsgBtn: false,
    disabled: true,
    disabledReason: 'You cannot change revision fields values.'
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: 'revisionFields',
})(RevisionFieldsContainer))
