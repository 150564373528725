import { SubmissionError } from 'redux-form'
import { normalize, pivotNormalize, pivotJSONNormalize, pivotNestedJSONNormalize, pivotNestedNamedNormalize } from '../../apiErrors'
import _ from 'lodash'

/**
 * Handle BE API form errors
 *
 * @param {Object} response
 * @param {Function} normalizer - the way we will normalize the errors. It depends on the form's type, we submitted.
 * @param {String} fieldPrefix - Prefix for the named field attr (i.e deductions-countryTerm)
 * All errors have to be normalized in object literal structure, in order to support `redux-form` API.
 * @return {*}
 */
export const handleErrors = (response, normalizer, fieldPrefix = null) => {
  let errors = {}

  // Here the BE returns the form's fields errors
  if (response.errors) {
    errors = normalizer(response.errors, fieldPrefix)
  }

  // If the errors, can't be attached to a specific field,
  // then the BE return these errors in `errorsCommon` response key.
  if (response.errorsCommon) {
    errors['_error'] = response.errorsCommon
  }

  // If there is an error, we comply `redux-form` API and throw the below error.
  // @link https://redux-form.com/7.0.3/docs/api/submissionerror.md/
  if (!_.isEmpty(errors)) throw new SubmissionError(errors)

  // We have to return the response, if there aren't any errors,
  // in order the promise chain to continue work
  return response
}
export const handleReturnedErrors = (response, normalizer) => {
  let errors = {}

  // Here the BE returns the form's fields errors
  if (response.errors) {
    errors = normalizer(response.errors)
  }

  // If the errors, can't be attached to a specific field,
  // then the BE return these errors in `errorsCommon` response key.
  if (response.errorsCommon) {
    errors['_error'] = response.errorsCommon
  }

  // We have to return the response, if there aren't any errors,
  // in order the promise chain to continue work
  return response
}

/**
 * Resolves mismatches between error message keys and form field names in the error response.
 *
 * In some scenarios, error message keys do not align with the corresponding form field names.
 * This function accepts an object in the format {errorKey: formKey} to handle such mismatches.
 *
 * @param {object} response
 * @param {Function} normalizer
 * @param {Array} errorMappings Array of key value pairs in format [[formKey1, errorKey1], [formKey2, errorKey2]]
 *                               where 'errorKey' is the error message key and 'formKey' is the corresponding form field name.
 * @return {*}
 */
export const handleFormErrorsWhereFormKeyAndErrorKeyMismatch = (response, normalizer, errorMappings) => {
  if (response.errors) {
    errorMappings.forEach(([formKey, errorKey]) => {
      response.errors[errorKey] && (response.errors[formKey] = response.errors[errorKey])
    })
  }
  handleErrors(response, normalizer)
}

export default {
  handleFormErrors: (response) => handleErrors(response, normalize),
  handleReturnErrors: (response) => handleReturnedErrors(response, normalize),
  handlePivotFormErrors: (response) => handleErrors(response, pivotNormalize),
  handleNestedNamedPivotFormErrors: (response, prefix) => handleErrors(response, pivotNestedNamedNormalize, prefix),
  handlePivotJSONNormalize: (response) => handleErrors(response, pivotJSONNormalize),
  handlePivotNestedJSONNormalize: (response) => handleErrors(response, pivotNestedJSONNormalize),
  handleFormErrorsWhereFormKeyAndErrorKeyMismatch: (response, errorMappings) =>
    handleFormErrorsWhereFormKeyAndErrorKeyMismatch(response, normalize, errorMappings),
}
