import React from 'react'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Modal from 'components/Modal'
import EmployeeFormCreateContainer from 'routes/Employees/routes/Employee/containers/EmployeeFormCreateContainer'
import PropTypes from 'prop-types'
import { onChangeSubmit } from 'utils/form'
import CheckboxOption from 'components/form/select/CheckboxOption'
import isEmpty from 'lodash/isEmpty'
import { Authorization } from 'containers/Authorization'
import { sortByLabel } from 'utils/strings'

const noneOption = { value: 0, label: 'None' }

class EmployeesSearch extends React.Component {
  constructor (props) {
    super(props)
    this.modalCreateEmployee = React.createRef(null)
  }

  render () {
    const {
      handleSubmit,
      resetForm,
      selectCountryAndCompanyByEntityValue,
      countries,
      companies,
      businessUnits,
      costCenters,
      projects,
      payrolls,
      showAddBtn,
      payrollInstance,
      payrollInstances,
      history,
      disableCountryCompanyPayrollAndPayrun,
    } = this.props
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className='o-layout o-layout--middle u-padding-top u-padding-bottom'>
            <div className='o-layout__item u-1/1 u-1/2@desktop u-margin-bottom-small u-margin-top-tiny'>
              <Field
                name='search'
                placeholder='Search by id, first name, surname, email and tax number'
                component={InputField}
                type='text'
                className='c-input'
                labelClassName='c-label'
                formGroupClassName='u-margin-none'
                onChange={onChangeSubmit(handleSubmit)}
                data-testid='search'
              />
            </div>
            <div className='o-layout__item o-layout--right u-1/1 u-1/2@desktop u-margin-bottom-small'>
              <div className='c-employee__buttons'>
                {showAddBtn && (
                  <Authorization permissions={['EMPLOYEEUSER_CREATE', 'EMPLOYEEUSER_NORMAL_CREATE']}>
                    <div
                      onClick={() => this.modalCreateEmployee.current.showModal()}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny
                    u-margin-top-tiny'
                      title='Create a new employee'
                      data-testid='employee-create'
                    >
                      <span className='icon icon--plus' />
                    </div>
                  </Authorization>
                )}
                {showAddBtn && (
                  <Authorization permissions={['EMPLOYEEUSER_IMPORT']}>
                    <div
                      onClick={() => history.push('/import/employees')}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny
                      u-margin-top-tiny'
                      title='Create multiple employees'
                      data-testid='employee-create-multi'
                    >
                      Create multiple employees
                    </div>
                  </Authorization>
                )}
                {showAddBtn && (
                  <Authorization permissions={['EMPLOYEETERM_IMPORT']}>
                    <div
                      onClick={() => history.push('/import/terms')}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny
                    u-margin-top-tiny'
                      title='Update fixed pay elements and recurring deductions/contributions in bulk'
                      data-testid='terms-import'
                    >
                      Import terms
                    </div>
                  </Authorization>
                )}
                {showAddBtn && (
                  <Authorization permissions={['EMPLOYEEUSER_BULK_IMPORT']}>
                    <div
                      onClick={() => history.push('/import/employees-bulk')}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny
                    u-margin-top-tiny'
                      title='Update employees’ Personal and Job & Org Data in bulk'
                      data-testid='employee-mass-update'
                    >
                      Employees mass updates
                    </div>
                  </Authorization>
                )}
                {showAddBtn && (
                  <Authorization permissions={['EMPLOYEEUSER_BANK_ACCOUNT_IMPORT']}>
                    <div
                      onClick={() => history.push('/import/bankaccounts')}
                      className='c-btn c-btn--small c-btn--long c-btn--curious u-margin-left-tiny
                    u-margin-top-tiny'
                      title='Create and/or update employees‘ bank details in bulk'
                      data-testid='bank-details-update'
                    >
                      Bank details updates
                    </div>
                  </Authorization>
                )}
              </div>
            </div>
          </div>
          <div className='o-layout u-padding-top u-padding-bottom'>
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='country'
                  label='Country'
                  data-testid='employee-search-bar-country-wrapper'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  labelClassName='c-label'
                  className={`c-custom-select--overflowy c-custom-select--employee ${disableCountryCompanyPayrollAndPayrun && 'disabled'}`}
                  placeholder='Select'
                  disabled={disableCountryCompanyPayrollAndPayrun}
                  multi
                  options={countries
                    .map((country) => ({
                      value: country.id,
                      label: country.name,
                      abbreviature: country.abbreviature,
                    }))
                    .sort(sortByLabel)}
                  onChanged={() => {
                    onChangeSubmit(handleSubmit)()
                    resetForm(['company', 'businessUnit', 'costCenter', 'project', 'payroll', 'paygroup', 'payrollInstance'])
                  }}
                />
              </div>
            </div>
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='company'
                  label='Company'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  labelClassName='c-label'
                  className={`c-custom-select--overflowy c-custom-select--employee ${disableCountryCompanyPayrollAndPayrun && 'disabled'}`}
                  placeholder='Select'
                  disabled={disableCountryCompanyPayrollAndPayrun}
                  multi
                  options={companies
                    .map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))
                    .sort(sortByLabel)}
                  onChanged={(value) => {
                    if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('company', value)
                    onChangeSubmit(handleSubmit)()
                    resetForm(['businessUnit', 'costCenter', 'project', 'payroll', 'paygroup', 'payrollInstance'])
                  }}
                  data-testid='company-wrapper'
                />
              </div>
            </div>
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='payroll'
                  label='Payroll'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  data-testid='employee-search-bar-payroll-wrapper'
                  labelClassName='c-label'
                  className={`c-custom-select--overflowy c-custom-select--employee ${disableCountryCompanyPayrollAndPayrun && 'disabled'}`}
                  placeholder='Select'
                  disabled={disableCountryCompanyPayrollAndPayrun}
                  multi
                  options={[
                    noneOption,
                    ...payrolls
                      .map((payroll) => ({
                        value: payroll.id,
                        label: payroll.name,
                      }))
                      .sort(sortByLabel),
                  ]}
                  onChanged={(value) => {
                    if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('payroll', value)
                    onChangeSubmit(handleSubmit)()
                  }}
                />
              </div>
            </div>
            {payrollInstance && (
              <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
                <div className='c-modern-input'>
                  <Field
                    name='payrollInstance'
                    label='Payrun'
                    data-testid='employee-search-bar-payrun-wrapper'
                    component={CustomSelectField}
                    optionComponent={CheckboxOption}
                    labelClassName='c-label'
                    className={`c-custom-select--overflowy c-custom-select--employee ${disableCountryCompanyPayrollAndPayrun && 'disabled'}`}
                    disabled={disableCountryCompanyPayrollAndPayrun}
                    placeholder='Select'
                    multi
                    options={payrollInstances.map((instance) => ({
                      value: instance.id,
                      label: instance.filterName,
                    }))}
                    onChanged={(value) => {
                      if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('payrollInstance', value)
                      onChangeSubmit(handleSubmit)()
                    }}
                  />
                </div>
              </div>
            )}
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='businessUnit'
                  label='Business Unit'
                  data-testid='employee-search-bar-business-unit-wrapper'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  labelClassName='c-label'
                  className='c-custom-select--overflowy c-custom-select--employee'
                  placeholder='Select'
                  multi
                  options={[noneOption, ...businessUnits]}
                  onChanged={(value) => {
                    if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('businessUnit', value)
                    onChangeSubmit(handleSubmit)()
                  }}
                />
              </div>
            </div>
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='costCenter'
                  label='Cost Center'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  labelClassName='c-label'
                  className='c-custom-select--overflowy c-custom-select--employee'
                  placeholder='Select'
                  multi
                  options={[
                    noneOption,
                    ...costCenters.map((center) => ({
                      value: center.id,
                      label: center.name,
                    })),
                  ]}
                  onChanged={(value) => {
                    if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('costCenter', value)
                    onChangeSubmit(handleSubmit)()
                  }}
                />
              </div>
            </div>
            <div className='o-layout__item u-1/1 u-1/3@tablet u-1/6@desktop'>
              <div className='c-modern-input'>
                <Field
                  name='project'
                  label='Project'
                  component={CustomSelectField}
                  optionComponent={CheckboxOption}
                  labelClassName='c-label'
                  className='c-custom-select--overflowy c-custom-select--employee'
                  placeholder='Select'
                  multi
                  options={[
                    noneOption,
                    ...projects.map((project) => ({
                      value: project.id,
                      label: project.name,
                    })),
                  ]}
                  onChanged={(value) => {
                    if (!isEmpty(value)) selectCountryAndCompanyByEntityValue('project', value)
                    onChangeSubmit(handleSubmit)()
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        <Modal
          ref={this.modalCreateEmployee}
          className='c-modal c-modal--full c-modal--overflow-y'
          modalHeading='Create Employee'
          data-testid='employee-create-modal'
        >
          <EmployeeFormCreateContainer history={this.props.history} onCreate={() => this.modalCreateEmployee.current.hideModal()} />
        </Modal>
      </>
    )
  }
}

EmployeesSearch.propTypes = {
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  selectCountryAndCompanyByEntityValue: PropTypes.func,
  countries: PropTypes.array,
  companies: PropTypes.array,
  businessUnits: PropTypes.array,
  costCenters: PropTypes.array,
  projects: PropTypes.array,
  payrolls: PropTypes.array,
  payrollInstances: PropTypes.array,
  payrollInstance: PropTypes.string,
  showAddBtn: PropTypes.bool,
  history: PropTypes.object,
  disableCountryCompanyPayrollAndPayrun: PropTypes.bool,
}

export default EmployeesSearch
