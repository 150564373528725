import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchProcessesIfNeeded } from 'redux/actions/processes'
import { fetchStepsIfNeeded } from 'redux/actions/steps'
import { fetchTasksIfNeeded } from 'redux/actions/tasks'
import ServicesTreeDecorator from 'components/ServicesTreeDecorator'
import ServicesTree from 'components/ServicesTree'
import { getServicesTree } from '../selectors/services'
import _ from 'lodash'

class ServicesListContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchServicesIfNeeded()
    this.props.fetchProcessesIfNeeded()
    this.props.fetchStepsIfNeeded()
    this.props.fetchTasksIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <ServicesTreeDecorator {...this.props}>
      <ServicesTree />
    </ServicesTreeDecorator>
  }
}

const mapDispatchToProps = {
  fetchServicesIfNeeded,
  fetchProcessesIfNeeded,
  fetchStepsIfNeeded,
  fetchTasksIfNeeded
}

const mapStateToProps = state => {
  if (isFetching([state.services, state.processes, state.steps,
    state.tasks, state.tasks ])) {
    return { isFetching: true }
  }

  const serviceTree = getServicesTree(state)

  // Sort tasks by position
  serviceTree.map(service => {
    service.processes.map(process => {
      process.steps.map(step => {
        step.tasks = _.sortBy(step.tasks, ['position'])
      })
    })
  })

  return {
    services: serviceTree
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesListContainer)
