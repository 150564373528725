export const keycloakRealms = {
  PAYSLIP_APPS: 'PayslipApps',
  PAYSLIP_COT: 'PayslipCOT',
  PAYSLIP_ICP: 'PayslipICP',
  SERVICE_ACCOUNTS: 'ServiceAccounts',
}

export const keycloakTokenExpiration = {
  REFRESH_TOKEN: 12600,
  MIN_EXPIRED_VALIDITY: 300,
}
