import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/currencies'

class Currency extends CRUDModel {}
Currency.modelName = 'Currency'

Currency.fields = {
  id: attr(),
  name: attr()
}
Currency.actionTypes = actionTypes

export default Currency
