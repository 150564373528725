import React from 'react'
import PropTypes from 'prop-types'
let classNames = require('classnames')

const SectionHeading = ({
  children,
  bolded = true,
  huge = true,
  extraHuge = false,
  priority = 2,
  text,
  className = '',
  fullWidth,
  customGrid,
  tagTestId,
  marginAuto = false,
  titleComponent = null,
  tagFullWidth,
}) => {
  const Tag = `h${priority}`

  const classes = classNames({
    'u-margin-none': true,
    'u-text--huge': huge,
    'u-text--xtra-huge': extraHuge,
    'u-text--normal': !huge && !extraHuge,
    'u-weight--bold': bolded,
    'u-margin--auto': marginAuto,
    'w-100': tagFullWidth,
  })

  const gridClasses = classNames({
    'u-1/1': true,
    'u-1/2@tablet': !fullWidth && !customGrid,
    [customGrid]: customGrid,
  })

  return (
    <div className={'o-layout o-layout--middle u-margin-bottom-small ' + className}>
      <div className={'o-layout__item ' + gridClasses}>
        <div className='o-grid o-grid--between o-grid--middle'>
          {titleComponent || (
            <Tag className={classes} data-testid={tagTestId}>
              {text}
            </Tag>
          )}
          {fullWidth && children}
        </div>
      </div>
      {!fullWidth && children}
    </div>
  )
}

SectionHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  bolded: PropTypes.bool,
  huge: PropTypes.bool,
  priority: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  extraHuge: PropTypes.bool,
  customGrid: PropTypes.string,
  marginAuto: PropTypes.bool,
  tagTestId: PropTypes.string,
  titleComponent: PropTypes.object,
  tagFullWidth: PropTypes.bool,
}

export default SectionHeading
