import classNames from 'classnames'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const RowValues = ({ item }) => {
  return (
    <>
      <td>{item?.validFrom && <span className='u-weight--regular'> {format(new Date(item.validFrom), 'dd/MM/yyyy')} </span>}</td>
      <td>{item?.validTo && <span className='u-weight--regular'> {format(new Date(item.validTo), 'dd/MM/yyyy')}</span>}</td>
      <td>{item?.amount && <span className='u-weight--regular'> {item.amount} </span>}</td>
    </>
  )
}

RowValues.propTypes = {
  item: PropTypes.object,
}

const MultiRowValues = ({ row }) => {
  const [expanded, setExpanded] = useState(false)
  const title = Object.keys(row)
  const values = Object.values(row).filter((r) => r)
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-cursor--pointer u-float--left u-margin-left-tiny u-padding-top accordion-expander': true,
      active: expanded,
    })
  }
  if (!values.length) {
    return null
  }
  const hasExpandedItems = values.find((item) => item.expandedItems)
  const hasNestedItems = hasExpandedItems && hasExpandedItems.expandedItems && hasExpandedItems.expandedItems.length

  return (
    <>
      <div className='border-none'>{title}</div>
      <div className='u-margin-top-small border-none'>
        <table>
          <tr>
            {values.map((r, idx) => (
              <>
                <RowValues key={`${idx}-${title}-${r.validFrom}`} item={r} />
                <td>
                  {!!hasNestedItems && (
                    <div className='accordion-extender u-text--small u-cursor--pointer u-float--right border-none u-relative'>
                      <span className={getArrowClasses()} onClick={toggleExpanded} />
                    </div>
                  )}
                </td>
              </>
            ))}
          </tr>
          {expanded && (
            <tr className='expanded-'>
              {hasExpandedItems.expandedItems.map((r, idx) => (
                <>
                  <RowValues key={`${idx}-${title}-${r.validFrom}`} item={r} />
                  <td style={{ paddingTop: '2.50rem' }}>{/** Empty Row */}</td>
                </>
              ))}
            </tr>
          )}
        </table>
      </div>
    </>
  )
}

MultiRowValues.propTypes = {
  row: PropTypes.array,
}

const SingleRowValues = ({ row }) => {
  return (
    <>
      <span className='u-1/2'>{Object.keys(row)}</span>
      <span className='u-weight--regular'>{Object.values(row)}</span>
    </>
  )
}

SingleRowValues.propTypes = {
  row: PropTypes.array,
}

const MobileDetailsView = ({ rows, isSingleColumn = false, textAreaIndex = null, isMultiRow = false }) => {
  const renderRows = (rows) =>
    isSingleColumn
      ? rows.map((row, i) => (
        <div key={`${i}-${row}`} className={'u-padding-tiny d-flex'}>
          <span className='u-1/1'>{row}</span>
        </div>
      ))
      : rows.map((row, i) => (
        <div
          key={`${i}-${Object.keys(row)}`}
          className={
            i === textAreaIndex
              ? `u-padding-tiny ${!isMultiRow ? 'd-flex' : ''} d-flex--col`
              : `u-padding-tiny ${!isMultiRow ? 'd-flex' : ''} jc--space-between`
          }
        >
          {isMultiRow ? <MultiRowValues key={`${i}-${row.title}`} row={row} /> : <SingleRowValues key={`${i}-${Object.keys(row)}`} row={row} />}
        </div>
      ))
  return <div className='rounded u-text--overflow-ellipsis mobile-rows'>{renderRows(rows)}</div>
}

MobileDetailsView.propTypes = {
  rows: PropTypes.array,
  isSingleColumn: PropTypes.bool,
  textAreaIndex: PropTypes.number,
  isMultiRow: PropTypes.bool
}

export default MobileDetailsView
