/* eslint-disable react/jsx-no-bind */
import React from 'react'
import PropTypes from 'prop-types'
import BaseConfirmationModal from './BaseConfirmationModal'
import { ConfirmButton, RejectButton } from './buttons/'

class ConfirmationModal extends React.Component {
  constructor (props) {
    super(props)
    this.modalRef = React.createRef()
  }

  showModal = () => {
    this.modalRef.current.showModal()
  }

  hideModal = () => {
    this.modalRef.current.hideModal()
  }

  onConfirm = () => {
    this.props.onConfirm()
    this.hideModal()
  }

  onReject = () => {
    if (this.props.onReject) {
      this.props.onReject()
    }

    this.hideModal()
  }

  render () {
    return <BaseConfirmationModal ref={this.modalRef} {...this.props}>
      {this.props.children}
      <ConfirmButton
        buttonText={this.props.confirmButtonText}
        onClick={this.onConfirm.bind(this)}
        disabled={this.props.disableConfirmButton}
      />
      {!this.props.hideReject && <RejectButton
        buttonText={this.props.rejectButtonText}
        onClick={this.onReject.bind(this)}
      />
      }
    </BaseConfirmationModal>
  }
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  hideReject: PropTypes.bool,
  onReject: PropTypes.func,
  confirmButtonText: PropTypes.string,
  disableBackdropClose: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  rejectButtonText: PropTypes.string,
  ref: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ConfirmationModal
