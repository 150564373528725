import { createReducerMeta } from 'utils/redux'
import { TOAST_FILE_GENERATION_START, TOAST_FILE_GENERATION_DONE } from 'redux/actions/toasts'

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducerMeta({}, {}, {
  [TOAST_FILE_GENERATION_START]: (state, { payload }) => (
    { ...state, }
  ),
  [TOAST_FILE_GENERATION_DONE]: (state, { payload }) => (
    { ...state }
  ),
})
