import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/pollingJob'

class PollingJob extends CRUDModel {}
PollingJob.modelName = 'PollingJob'

PollingJob.fields = {
  id: attr(),
}

PollingJob.actionTypes = actionTypes

export default PollingJob
