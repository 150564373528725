import React from 'react'

export const DualSelectorBox = ({ boxRef, className = '', title, children }) => (
  <div className={`d-flex d-flex--col ${className}`}>
    <span className='u-weight--bold'>{title}</span>
    <div ref={boxRef} className='dual-selector-widget--box u-bg--white border-1-valhalla rounded'>
      {children}
    </div>
  </div>
)
