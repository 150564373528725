import React from 'react'
import Button from './Button'

export default ({ buttonText = 'No', ...rest }) => (
  <Button
    className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-small'
    data-testid='reject-button'
    {...rest} >
    {buttonText}
  </Button>
)
