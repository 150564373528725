import React from 'react'
import PropTypes from 'prop-types'
const classNames = require('classnames')

const AccordionBody = ({ expanded, separator, children }) => {
  const getHiddenClasses = () => {
    return classNames({
      'accordion-body': true,
      'expanded': expanded
    })
  }

  return (
    <div className={getHiddenClasses()}>
      {separator && <hr />}
      {children}
    </div>
  )
}

AccordionBody.propTypes = {
  children: PropTypes.any.isRequired,
  expanded: PropTypes.bool,
  separator: PropTypes.bool
}

export default AccordionBody
