import moment from 'moment'
import _ from 'lodash'

/**
 * Transform date fields, in order to match the BE specifics.
 *
 * @param {Object} entity - The data object
 * @param {Array} fields - The data's fields names as String values, these are dates and have to be transformed.
 * @param {String} subField - If one of the above fields' values is object, then we have to specify it's name.
 * @return {Object} normalized
 */
export const dateTransformer = (entity, fields, subField = '', fromFormat = 'DD/MM/YYYY') => {
  let normalized = _.cloneDeep(entity)

  fields.forEach(field => {
    if (!normalized[field] || (subField && !normalized[field][subField])) return null

    if (normalized[field] && normalized[field][subField]) {
      normalized[field][subField] = normalizeDate(normalized[field][subField], fromFormat)
    } else {
      normalized[field] = normalizeDate(normalized[field], fromFormat)
    }
  })

  return normalized
}

export const normalizeDate = (date, fromFormat = 'DD/MM/YYYY') => {
  if (!date) return
  return moment(date, fromFormat).format('YYYY-MM-DD')
}
