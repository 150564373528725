import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import split from 'lodash/split'
import pickBy from 'lodash/pickBy'

// ------------------------------------
// Constants
// ------------------------------------
export const NOTE_USERS_FETCH = 'NOTE_USERS_FETCH'
export const NOTE_USERS_RECEIVE = 'NOTE_USERS_RECEIVE'
export const NOTE_USERS_CREATE = 'NOTE_USERS_CREATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: NOTE_USERS_FETCH,
  receive: NOTE_USERS_RECEIVE,
  create: NOTE_USERS_CREATE,
}
const actionUtility = new ActionUtility(actionTypes, 'noteUsers', 'noteusers', 'NoteUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchNoteUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded

export const updateNoteUsers = (entity, noteId, actionProps) => {
  // Get the unique user tenants from all keys

  // Get all checked fields
  const checkedFields = pickBy(entity, value => value === true)

  // Get their keys
  const checkedKeys = Object.keys(checkedFields)

  // Build data users
  const dataUsers = checkedKeys.map(key => {
    // Split the keys in two parts
    const [ userTenant, userId ] = split(key, '-', 2)

    return {
      userId: parseInt(userId),
      userTenant,
    }
  })

  // Build the actual data to share this document to selected users
  const data = {
    data: dataUsers,
    params: { note: noteId }
  }

  return (dispatch, getState, { api }) => {
    return api.post('notes/notifyusers', { payload: data, ...actionProps })
      .then(errorsHandling.handleFormErrors)
  }
}
