import React from 'react'
import PayrollContainer from 'containers/PayrollContainer'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { PayrollTabModalOptionButtons } from 'utils/buttonOptions'

const { addPayAndTaxesElementsButton } = PayrollTabModalOptionButtons

export default (props) => {
  return (
    <PayrollContainer
      payrunState={payrunStatesEnum.PREVIOUS}
      heading='Closed Payruns'
      payrollKey='payrolls-for-closed'
      buttonOptions={addPayAndTaxesElementsButton}
      {...props}
    />
  )
}
