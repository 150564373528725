import React from 'react'
import PropTypes from 'prop-types'
import CustomSelectField from 'components/form/select/CustomSelectField'
import InputField from 'components/form/InputField'
import PayElements from 'components/PayElements'
import RecurringDeductionsContainer from 'containers/RecurringDeductionsContainer'
import { Field } from 'redux-form'
import AmountInputField from 'components/form/AmountInputField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import Expandable from 'components/Expandable'
import { taxClassFields } from 'redux/config/employeeFieldsConfigAlt'

class EmployeePayTaxesFormFields extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      companyId: this.props.companyId ? this.props.companyId : null,
    }
  }

  render () {
    const {
      selectedCompany,
      arePayelementsDisabled,
      paygroupEmployeeName,
      resetForm,
      employeeCustomFieldsPayAndTaxes,
      hadAnnualSalaryAndHasChanged,
      hadNotAnnualSalaryAndHasChanged,
      hasEffectiveDate,
      isEdit,
      onAnnualSalaryHistoryBtnClick,
      isExpandable = false,
      pristine,
      submitting,
      fields,
    } = this.props

    const companyId = this.state.companyId ? this.state.companyId : selectedCompany

    return (
      <div className='page--employee-pay-taxes-form-fields'>
        <div className='o-layout'>
          <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small u-relative'>
              <Field
                name={fields.annualSalaryValue.name}
                label='Annual salary'
                component={AmountInputField}
                type='text'
                className='c-input c-input--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName={isEdit ? 'u-margin-bottom-none u-margin-right-large' : 'u-margin-none'}
                data-testid='annualSalary'
              />
              {isEdit && (
                <div
                  onClick={onAnnualSalaryHistoryBtnClick}
                  className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-absolute u-absolute--history-btn'
                  title='History'
                >
                  <span className='icon icon--history' />
                </div>
              )}
            </div>
          </div>
          {(hadAnnualSalaryAndHasChanged || hadNotAnnualSalaryAndHasChanged) && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={fields.effectiveDate.name}
                  label={hadAnnualSalaryAndHasChanged ? 'Effective date *' : 'Effective date'}
                  component={FieldDatePicker}
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='effectiveDate'
                />
              </div>
            </div>
          )}
          {hadAnnualSalaryAndHasChanged && hasEffectiveDate && isEdit && (
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={fields.reason.name}
                  label='Reason'
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='reason'
                />
              </div>
            </div>
          )}
          {employeeCustomFieldsPayAndTaxes.map((field) => {
            if (field.name !== 'invoicePaymentCurrency') {
              return (
                <div key={field['name']} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
                  <div className={`c-panel u-bg--white border-1-valhalla rounded u-padding-small`}>
                    {field.name === 'churchTax' ? (
                      <Field
                        name={field['name']}
                        data-testid='employee-church-tax'
                        label={field['printableName']}
                        component={CustomSelectField}
                        className='c-custom-select--transparent'
                        labelClassName='c-label u-text--small u-text--curious'
                        formGroupClassName='u-margin-none'
                        placeholder='Select'
                        onChanged={resetForm}
                        options={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' },
                        ]}
                      />
                    ) : null}
                    {field.name === 'niCategoryLetter' ? (
                      <Field
                        name={field['name']}
                        label={field['printableName']}
                        data-testid='employee-ni-category-letter'
                        component={CustomSelectField}
                        className='c-custom-select--transparent c-custom-select--specific-normal'
                        labelClassName='c-label u-text--small u-text--curious'
                        formGroupClassName='u-margin-none'
                        placeholder='Select'
                        onChanged={resetForm}
                        options={field.options.map((n) => ({
                          value: n,
                          label: n,
                        }))}
                      />
                    ) : null}
                    {field.name === 'taxClass' ? (
                      <Field
                        name={field['name']}
                        label={field['printableName']}
                        component={CustomSelectField}
                        data-testid='employee-tax-class'
                        className='c-custom-select--transparent c-custom-select--specific-normal'
                        labelClassName='c-label u-text--small u-text--curious'
                        formGroupClassName='u-margin-none'
                        placeholder='Select'
                        onChanged={resetForm}
                        options={taxClassFields.map((n) => ({ value: n, label: n }))}
                      />
                    ) : null}
                    {field.name !== 'churchTax' && field.name !== 'niCategoryLetter' && field.name !== 'taxClass' && field.name !== 'invoicePaymentCurrency' ? (
                      <Field
                        name={field['name']}
                        label={field['printableName']}
                        data-testid={`employees-employee-employee-paytaxes-form-field-${field.name}`}
                        component={InputField}
                        type='text'
                        className='c-input c-input--transparent'
                        labelClassName='c-label u-text--small u-text--curious'
                        formGroupClassName='u-margin-none'
                      />
                    ) : null}
                  </div>
                </div>
              )
            }
          })}
        </div>

        {/*
       Please note, if you're going to move `<PayElements />` or `<RecurringDeductions />` to other tab,
       different the last one, then we would have a problem with tab error handling logic.
       Please refer to `formFields` definition for more details at:
       src/routes/Employees/routes/Employee/containers/EmployeeFormCreateContainer
       */}

        {!isEdit && (
          <PayElements
            disabled={arePayelementsDisabled}
            disabledReason={`The value of these elements comes from ${paygroupEmployeeName},
            as this employee is part of it. To change them, please change the values of the Paygroup elements.`}
            companyId={companyId}
            isExpandable={isExpandable}
            className='u-1/1'
            itemsClassName='u-1/1 u-1/4@tablet'
          />
        )}

        {!isEdit && (
          <Expandable text='Recurring Deductions' isExpanded={false}>
            <RecurringDeductionsContainer companyId={companyId} tableGrid='u-3/4' />
          </Expandable>
        )}
        <div className='o-layout'>
          <div className='o-layout__item u-text--center u-1/1'>
            <button
              type='submit'
              disabled={pristine || submitting}
              className={`c-btn c-btn--small c-btn--curious u-padding-left u-padding-right
             ${submitting ? 'c-btn--submitting' : ''}`}
              data-testid='submit'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }
}

EmployeePayTaxesFormFields.propTypes = {
  selectedCompany: PropTypes.number,
  arePayelementsDisabled: PropTypes.bool,
  paygroupEmployeeName: PropTypes.string,
  employeeCustomFieldsPayAndTaxes: PropTypes.array,
  resetForm: PropTypes.func,
  hadAnnualSalaryAndHasChanged: PropTypes.bool,
  hasEffectiveDate: PropTypes.bool,
  isEdit: PropTypes.bool,
  onAnnualSalaryHistoryBtnClick: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  hadNotAnnualSalaryAndHasChanged: PropTypes.bool,
  isExpandable: PropTypes.bool,
  companyId: PropTypes.number,
  fields: PropTypes.object,
}

export default EmployeePayTaxesFormFields
