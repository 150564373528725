import React from 'react'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { connect } from 'react-redux'
import {
  getEmployeeFieldsByCountry,
  getEmployeeJobOrgFields,
  getEmployeeWithCompanyCountryBusinessUnitCostCenterProject,
} from '../../../selectors/employeesORM'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import { isCot, isEmployeeOnly } from 'redux/selectors/auth'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { JobOrgViewContainer } from './JobOrgViewContainer'
import { getCountries } from 'routes/Countries/selectors/countries'
import { getCompaniesRef } from 'redux/selectors/company'
import { sortByName } from 'utils/strings'
import { getAttachedPayrollEmployeeMessage } from 'redux/selectors/payrollEmployeeMessage'
import { getBusinessUnitsByCompany } from 'redux/selectors/businessUnits'
import { getDepartmentsByCompany } from 'redux/selectors/departments'
import { getCostCentersByCompany } from 'redux/selectors/costCenters'
import { getProjectsByCompany } from 'redux/selectors/projects'
import { getOfficeLocationsByCompany } from 'redux/selectors/officeLocations'
import { getSubsidiariesByCompany } from 'redux/selectors/subsidiary'
import { getCurrencies } from 'redux/selectors/currencies'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { enableEmployeeUserSSOStrings } from 'redux/config/ssoAccessStrings'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'
import Loader from 'components/Loader'
import { getPaygroupsByEmployee } from '../../PayTaxes/selectors/employeePaygroups'
import { getAllPayrollsByEmployee, getNonOnceOffPayrollsByEmployee, getPaygroupPayrollsByEmployee } from '../../PayTaxes/selectors/employeePayrolls'
import { getPayrollsByCompany } from 'redux/selectors/payroll'
import { getPaygroupsByCompany } from '../../../selectors/paygroups'

let modalState = {
  showSubmitSSOConfirmModal: false,
}

const JobOrgContainer = (props) => {
  const { fields, isFetchingExtraFields } = useEmployeeCustomFields(retrieveCompanyCustomFields, props.employee.companyRef?.id, [props.employee.companyRef?.id])

  if (isFetchingExtraFields) return <Loader />
  if (props.isFetching) return <Loader />
  return <JobOrgViewContainer extraFields={fields} {...props} />
}

JobOrgContainer.propTypes = {
  employee: PropTypes.object,
  isEmployeeOnly: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const employee = getEmployeeWithCompanyCountryBusinessUnitCostCenterProject(state, {
    employeeId: props.match.params.employeeId,
  })
  const businessUnitsByCompany = getBusinessUnitsByCompany(state, { companyId: employee.company.id }).sort(sortByName)
  const costCentersByCompany = getCostCentersByCompany(state, { companyId: employee.company.id }).sort(sortByName)
  const projectsByCompany = getProjectsByCompany(state, { companyId: employee.company.id }).sort(sortByName)

  const employeeCustomFieldsObj = getEmployeeFieldsByCountry(state, { country: employee.countryName })
  const employeeCustomFieldsJobAndOrg = (employeeCustomFieldsObj ? getEmployeeJobOrgFields(state, { country: employee.countryName }) : []).filter(
    (field) => !field.hideOnEditView
  )

  const departmentsByCompany = getDepartmentsByCompany(state, { companyId: employee.company.id }).sort(sortByName)
  const officeLocationsByCompany = getOfficeLocationsByCompany(state, { companyId: employee.company.id })
  const subsidiariesByCompany = getSubsidiariesByCompany(state, { companyId: employee.company.id })

  const allCurrencies = getCurrencies(state)

  const currenciesOptions = allCurrencies.map((currency) => ({
    value: currency.id,
    label: currency.abbreviature,
  }))

  const employeePaygroups = getPaygroupsByEmployee(state, { employeeId: props.match.params.employeeId })
  const employeePayrolls = getAllPayrollsByEmployee(state, { employeeId: props.match.params.employeeId })

  const mapToValueAndLabelWithCurrency = (data) =>
    data.map((p) => ({
      value: p.id,
      label: p.name,
      currency: p.currency,
    }))

  const allPayrolls = mapToValueAndLabelWithCurrency(getPayrollsByCompany(state, { companyId: employee.company.id }))
  const allPaygroups = mapToValueAndLabelWithCurrency(getPaygroupsByCompany(state, { companyId: employee.company.id }))

  let payrolls = []
  let paygroups = []

  if (employee.paymentCurrency) {
    payrolls = allPayrolls.filter((p) => p.currency === employee.paymentCurrency.id)
    paygroups = allPaygroups.filter((p) => p.currency === employee.paymentCurrency.id)
  }

  let nonOnceOffPayrolls = getNonOnceOffPayrollsByEmployee(state, { employeeId: props.match.params.employeeId })
  let paygroupPayrollsByEmployee = getPaygroupPayrollsByEmployee(state, { employeeId: props.match.params.employeeId })

  const isPayrollDisabled = paygroupPayrollsByEmployee.length > 0 || nonOnceOffPayrolls.length > 0 || employee.payrollName
  const isPaygroupDisabled = employeePaygroups.length > 0 || nonOnceOffPayrolls.length > 0
  const areChildEntitiesDisabled = isPayrollDisabled || isPaygroupDisabled

  const messageAfterAssignment = (employeeId, payrollId) => getAttachedPayrollEmployeeMessage(state, { employeeId, payrollId })

  const parentCompaniesData = state.parentCompanies.allIds.map((id) => state.parentCompanies?.byIds[id])
  const tenant = state.tenants.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }) : parentCompaniesData[0]

  const { defaultSSOEnabledFor, isTenantSSOEnabled } = tenant
  const isEmployeeSelfServiceActive = employee.hasCredentials
  const showUserSSOField = isTenantSSOEnabled && isEmployeeSelfServiceActive && enableEmployeeUserSSOStrings.includes(defaultSSOEnabledFor)
  const showUserSSOButton = showUserSSOField && isCot(state)

  return {
    employee: {
      ...employee,
      paygroups: employeePaygroups,
      payrolls: employeePayrolls,
    },
    areChildEntitiesDisabled,
    countries: getCountries(state),
    companies: getCompaniesRef(state).sort(sortByName),
    companyId: employee.company ? employee.company.id : null,
    businessUnits: businessUnitsByCompany,
    costCenters: costCentersByCompany,
    departments: departmentsByCompany,
    officeLocations: officeLocationsByCompany,
    subsidiaries: subsidiariesByCompany,
    projects: projectsByCompany,
    currencies: currenciesOptions,
    allPayrolls,
    allPaygroups,
    payrolls,
    paygroups,
    messageAfterAssignment,
    hasCredentials: employee.hasCredentials,
    employeeCustomFieldsJobAndOrg,
    isEdit: true,
    isCotUser: isCot(state),
    allCurrencies,
    permissions: state.auth.permissions,
    canEditCompanyLink: employee.companyLink,
    typeOfEmploymentSelectedValue: employee.employmentStatus,
    isPaymentTypeHourly: employee.paymentType === 'Hourly',
    isEmployeeOnly: isEmployeeOnly(state),
    EmployeeStatus: new EmploymentStatusHelper(employee.employmentStatus),
    showUserSSOField,
    showUserSSOButton,
    modalState,
  }
}

const mapDispatchToProps = () => {
  return {
    onModalStateChange: ({ showSubmitSSOConfirmModal = false }) => {
      modalState = {
        showSubmitSSOConfirmModal: showSubmitSSOConfirmModal,
      }
    },
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(JobOrgContainer)

export default Fetcher(Container, [
  'countries',
  'companies',
  'businessUnits',
  'costCenters',
  'projects',
  'departments',
  'officeLocations',
  'subsidiary',
  'currencies',
  'employeeCountryPivot',
  'accessAreaPivot',
  'parentCompanies',
  'payrolls',
  'paygroups',
  'paygroupTermPivot',
  'payrollPaygroupPivot',
  createEmployeeFilter({ name: 'employeeCountryPivot' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' }),
  createEmployeeFilter({ name: 'payrollEmployeePivot' }),
  createEmployeeFilter({ name: 'paygroupEmployeePivot' }),
])
