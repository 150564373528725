import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEE_COUNTRY_FETCH = 'EMPLOYEE_COUNTRY_FETCH'
export const EMPLOYEE_COUNTRY_RECEIVE = 'EMPLOYEE_COUNTRY_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEE_COUNTRY_FETCH,
  receive: EMPLOYEE_COUNTRY_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'employeeCountryPivot', 'employeecountries', 'EmployeeCountry')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeCountryPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeCountryPivot = actionUtility.fetchEntities
