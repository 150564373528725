import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import { phoneValidation, required } from 'utils/validations'
import PhoneCodeNumberField from 'components/form/PhoneCodeNumberField'

const UserActivationForm = ({ label, buttonText, isDisabled, includePhoneCode, ...rest }) => <div className='c-login__content-user-activation'>
  <div className='o-grid o-grid--center'>
    <Form classNames='c-btn--rounded' {...rest}>
      <div className='o-form-group o-block o-block--left u-margin-bottom-small'>
        <Field
          name='mobilePhone'
          label={label}
          labelClassName='c-label'
          component={includePhoneCode ? PhoneCodeNumberField : InputField}
          type='text'
          disabled={isDisabled}
          {...includePhoneCode ? { validate: [required, phoneValidation] } : {}}
          className='c-input c-input--rounded c-input--alt'
        />
        <div className='o-form-group u-text--center u-margin-top'>
          <button
            type='submit'
            className='c-btn c-btn--submit c-btn--small c-btn--curious c-btn--rounded c-btn--full u-padding-left u-padding-right u-margin-top-small'
            tabIndex='-1'
          >
            {buttonText}
          </button>
        </div>
      </div>
    </Form>
  </div>
</div>

UserActivationForm.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonText: PropTypes.string,
  isDisabled: PropTypes.bool,
  // Should the form render the number, decorated in `<PhoneCodeNumberField />` field?
  includePhoneCode: PropTypes.bool
}

export default UserActivationForm
