/* eslint-disable max-len */
export const resourcesTabs = {
  releases: 'Releases',
}

export const resourcesTableHeadings = {
  name: 'Name',
  uploadedOn: 'Uploaded on',
  uploadedBy: 'Uploaded by',
}

export const resourcesTableTitles = {
  client: 'ER Portal',
  vendor: 'ICP Portal',
}

export const resourcesTypeNames = {
  releases: 'Release Documentation',
  trainings: 'Training Materials',
}

export const resourcesErrorText = {
  general: 'Something went wrong',
}

export const resourcesDeleteText = {
  modalHeading: 'Confirmation',
  confirmation: 'Are you sure you want to delete ',
}

export const resourcesUploadText = {
  header: 'Document Upload',
  label: 'Portal *',
  placeHolder: 'Select',
  options: {
    client: 'ER',
    vendor: 'ICP',
    both: 'Both',
  },
  button: 'Select file',
}
