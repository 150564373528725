import React from 'react'
import LoginWrapper from 'layouts/LoginWrapper'
import ForgottenPasswordContainer from '../containers/ForgottenPasswordContainer'

export const RouteView = props => {
  return <LoginWrapper>
    <ForgottenPasswordContainer {...props} />
  </LoginWrapper>
}

export default RouteView
