// ------------------------------------
// Constants
// ------------------------------------
export const VENDOR_TENANT_SELECT = 'VENDOR_TENANT_SELECT'

// ------------------------------------
// Actions
// ------------------------------------
export const selectTenant = ({ url, schema = '' }) => {
  const select = dispatch => {
    return dispatch({
      type: VENDOR_TENANT_SELECT,
      payload: { url, schema }
    })
  }

  select.disableJWT = true

  return select
}
