import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import EmergencyPersonFields from 'components/EmergencyPersonFields'
import ContractorFields from 'components/ContractorFields'
import { createEmployeeFields, renderFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'
import fieldSubCategoriesEnum from 'utils/enums/employees/fieldSubCategoriesEnum'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'

function EmployeePersonalFormFields (props) {
  const { selectedCountryName, EmployeeStatus, selectedCompany } = props
  const [isEmergencyPersonFieldsVisible, setIsEmergencyPersonFieldsVisible] = React.useState(false)
  const [country, setCountry] = React.useState(selectedCountryName)
  const { fields } = useEmployeeCustomFields(retrieveCompanyCustomFields, selectedCompany, [selectedCompany])

  return <>
    <SectionHeading text='Personal details' priority={4} bolded={false} />
    <div className='o-layout o-grid--strech'>
      {createEmployeeFields({
        fields: employeeFieldsConfigAlt({ isCreate: true }),
        section: 'personal',
        subSection: fieldSubCategoriesEnum.PERSONAL_DETAILS,
        country,
        extraFields: fields
      }).map(field => renderFields(field, setCountry))}
    </div>

    <SectionHeading text='Biographical Details' priority={4} bolded={false} />
    <div className='o-layout o-grid--strech'>
      {createEmployeeFields({
        fields: employeeFieldsConfigAlt({ isCreate: true }),
        section: 'personal',
        subSection: fieldSubCategoriesEnum.BIOGRAPHICAL_DETAILS,
        country,
        extraFields: fields
      }).map(field => renderFields(field, setCountry))}
    </div>

    <SectionHeading text='Tax & ID Details' priority={4} bolded={false} />
    <div className='o-layout o-grid--strech'>
      {createEmployeeFields({
        fields: employeeFieldsConfigAlt({ isCreate: true }),
        section: 'personal',
        subSection: fieldSubCategoriesEnum.TAX_DETAILS,
        country,
        extraFields: fields
      }).map(field => renderFields(field, setCountry))}
    </div>

    <SectionHeading text='Contact Details' priority={4} bolded={false} />
    <div className='o-layout o-grid--strech'>
      {createEmployeeFields({
        fields: employeeFieldsConfigAlt({ isCreate: true }),
        section: 'personal',
        subSection: fieldSubCategoriesEnum.CONTACT_DETAILS,
        secondarySubSection: fieldSubCategoriesEnum.EMPLOYEE_ADDRESS_DETAILS,
        country,
        extraFields: fields
      }).map(field => renderFields(field, setCountry))}
    </div>

    {
      EmployeeStatus.shouldShowContractArea &&
      <div>
        <SectionHeading text={EmployeeStatus.sectionHeading} priority={4} bolded={false} />
        <ContractorFields {...props} />
      </div>
    }

    <SectionHeading text='Emergency Person Contact Details' priority={4} bolded={false} />
    <div className='o-layout'>
      <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <Authorization permissions={['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']}>
          {!isEmergencyPersonFieldsVisible &&
          <p className='u-text--normal u-padding-top-small'>
            Please <span onClick={() => setIsEmergencyPersonFieldsVisible(!isEmergencyPersonFieldsVisible)}
              className='u-text--curious u-cursor--pointer'
              data-testid='bank-account-create'>
              click here</span> to add Emergency Person Contact Details.
          </p>}
        </Authorization>
      </div>
    </div>
    {
      isEmergencyPersonFieldsVisible &&
      <EmergencyPersonFields {...props} />
    }
    <div className='o-layout'>
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          data-testid='submit'
        >
          Save
        </button>
      </div>
    </div>
  </>
}

EmployeePersonalFormFields.propTypes = {
  EmployeeStatus: PropTypes.object,
  selectedCompany: PropTypes.number,
  selectedCountryName: PropTypes.string
}

export default EmployeePersonalFormFields
