import React from 'react'
import { connect } from 'react-redux'
import { isVendorInvolved } from 'redux/selectors/auth'
import { isVendorTenant } from 'redux/selectors/tenants'
import WrapperComponent from '../containers/CalendarYearPayrunContainer'

export const RouteView = props => <WrapperComponent {...props} />

const mapStateToProps = (state, props) => {
  return {
    isVendor: isVendorInvolved(state) || isVendorTenant(state)
  }
}

export default connect(mapStateToProps)(RouteView)
