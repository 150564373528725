import React, { useRef } from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'

const DocumentUploadForm = (props) => {
  const { disabled, disabledReason, showSubmitBtn, isPayroll, payrolls, uploadFile, resetForm } = props
  const dropzoneRef = useRef(null)

  return <div className='o-layout'>
    <Form>
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='documentType'
            label='Document Type *'
            component={CustomSelectField}
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            placeholder='Category'
            disabled={disabled}
            disabledReason={disabledReason}
            onChange={resetForm}
            options={[{
              value: 'Company',
              label: 'General'
            },
            {
              value: 'Payroll',
              label: 'Payroll'
            }]}
            data-testid='documentType-wrapper'
          />
        </div>
      </div>
      { isPayroll &&
        <div className='o-layout__item u-margin-top u-1/1'>
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
            <Field
              name='payrollId'
              label='Payroll *'
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              disabled={disabled}
              disabledReason={disabledReason}
              options={payrolls.map(payroll => ({
                value: payroll.id,
                label: payroll.name
              }))}
            />
          </div>
        </div>
      }
      <div className='o-layout__item u-1/1'>
        <div className='c-panel u-padding-small'>
          <p className='u-text--small u-text--center'>
            Important: For data security, before you proceed with a document upload,
            please make sure that you have selected the correct file, and you share it with the appropriate user.
          </p>
        </div>
      </div>
      { showSubmitBtn &&
        <div className='o-layout__item u-text--center u-margin-top u-1/1 u-1/1@tablet'>
          <button
            type='button'
            className='c-btn c-btn--small c-btn--curious u-text--small u-margin-left-tiny u-align-self--center'
            onClick={() => dropzoneRef.current.open()}>
            Select file
          </button>
        </div>
      }
    </Form>
    <Dropzone
      onDrop={files => uploadFile(files[0])}
      // eslint-disable-next-line no-return-assign
      ref={dropzoneRef}
      className='u-relative'
    />
  </div>
}

DocumentUploadForm.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  showSubmitBtn: PropTypes.bool,
  isPayroll: PropTypes.bool,
  payrolls: PropTypes.array,
  uploadFile: PropTypes.func,
  resetForm: PropTypes.func
}

export default DocumentUploadForm
