import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchEmployeeSystemUsersIfNeeded } from 'redux/actions/employeeSystemUsers'
import { fetchDataOwnersIfNeeded } from 'redux/actions/dataOwners'
import { filterCompanyDataProtectionFieldsRevision } from 'redux/actions/companyDataProtectionFieldsRevision'
import DataProtectionPolicyList from '../components/DataProtectionPolicyList'
import { getDataProtectionPolicy } from '../selectors/dataProtectionPolicy'
import { getCompanyDataProtectionFieldsRevisions } from 'redux/selectors/companyDataProtectionFieldsRevision'
import { createFilter } from 'utils/redux/filter'

class DataProtectionPolicyContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch, employeeFilter } = this.props
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchEmployeeSystemUsersIfNeeded({ filter: employeeFilter }))
    dispatch(fetchDataOwnersIfNeeded())
    dispatch(filterCompanyDataProtectionFieldsRevision({ company: this.props.match.params.companyId }))
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <DataProtectionPolicyList {...this.props} />
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

const mapStateToProps = (state, props) => {
  const { companies, employeeSystemUsers, dataOwners, companyDataProtectionFieldsRevision } = state

  const employeeFilter = createFilter({ isKeyPerson: 1, accessableCompanies: props.match.params.companyId })
  const entities = [ companies, { ref: employeeSystemUsers, filter: employeeFilter }, dataOwners,
    companyDataProtectionFieldsRevision ]

  if (isFetching(entities)) return { isFetching: true, employeeFilter }

  return {
    employeeFilter,
    data: getDataProtectionPolicy(state, { companyId: props.match.params.companyId }),
    revisions: getCompanyDataProtectionFieldsRevisions(state)
  }
}

DataProtectionPolicyContainer.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(DataProtectionPolicyContainer)
