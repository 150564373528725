import React from 'react'

/* eslint-disable max-len */
export const CommentIcon = ({ className, children, title, ...props }) => (
  <div className={className} title={title}>
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M5.67828 20.6004L10.687 15.5917H18.6C19.7046 15.5917 20.6 14.6963 20.6 13.5917V3.40039C20.6 2.29582 19.7046 1.40039 18.6 1.40039H3.40002C2.29546 1.40039 1.40002 2.29582 1.40002 3.40039V13.5917C1.40002 14.6963 2.29546 15.5917 3.40002 15.5917H5.67828V20.6004Z'
        stroke={props?.stroke || '#ffff'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
    {children}
  </div>
)
