import Loader from 'components/Loader'
import React from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import PropTypes from 'prop-types'
import DashboardTileHeader from './DashboardTileHeader'
import moment from 'moment'
import {
  WeeklyHeader,
  formats,
  Event,
} from 'components/calendar/CustomComponents'
moment.locale('en-GB')

const localizer = momentLocalizer(moment) // or globalizeLocalizer
const CalendarView = ({ tasks }) => (
  <div className='c-calendar__holder--border dashboard-calendar'>
    <BigCalendar
      defaultDate={new Date()}
      events={tasks}
      defaultView='week'
      toolbar
      popup
      formats={formats}
      localizer={localizer}
      components={{
        event: Event,
        toolbar: (toolbar) => <span>{null}</span>,
        week: {
          header: WeeklyHeader,
        },
      }}
    />
  </div>
)

function CalendarActionDashboardView (props) {
  return (
    <div data-testid='dashboard-calendar'>
      <DashboardTileHeader title='This week actions' onClick={props.onNavigatingToCalendar} />
      <div className='u-margin-left-small u-margin-right-small'>
        {!props.isFetching ? <CalendarView {...props} /> : <Loader />}
      </div>
    </div>
  )
}
CalendarActionDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigatingToCalendar: PropTypes.func
}

CalendarView.propTypes = {
  tasks: PropTypes.array
}

export default CalendarActionDashboardView
