/**
 * Determines if the provided value is a string.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is a string, otherwise false.
 */
export const isString = (value) => typeof value === 'string'

/**
 * Checks if the provided value is null.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is null, otherwise false.
 */
export const isNull = (value) => value === null

/**
 * Checks if the value is null or undefined.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is null or undefined, otherwise false.
 */
export const isNil = (value) => value == null

/**
 * Checks if the provided value is a Date object.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is a Date, otherwise false.
 */
export const isDate = (value) => value instanceof Date

/**
 * Determines if the provided value is an array.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is an array, otherwise false.
 */
export const isArray = (value) => Array.isArray(value)

/**
 * Checks if the provided value is undefined.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is undefined, otherwise false.
 */
export const isUndefined = (value) => value === undefined

/**
 * Determines whether the provided value is a function.
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is a function, otherwise false.
 */
export const isFunction = (value) => typeof value === 'function'

/**
 * Checks if the value is NaN.
 * @param {*} value - The value to test.
 * @returns {boolean} - True if the value is NaN, otherwise false.
 */
export const isNaN = (value) => Number.isNaN(value)

/**
 * Checks if the provided value is "empty".
 *
 * A value is considered empty if it is one of the following:
 * - null
 * - undefined
 * - an empty string ("")
 * - an empty array ([])
 * - an empty object ({}), i.e., an object with no own enumerable properties
 *
 * @param {*} value - The value to check.
 * @returns {boolean} - True if the value is considered empty, otherwise false.
 */
export const isEmpty = (value) => {
  if (value == null) {
    return true
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0
  }

  if (value instanceof Object) {
    return Object.keys(value).length === 0 && value.constructor === Object
  }
  return false
}

/**
 * Checks if the provided value is a number.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} - Returns true if the value is a number, false otherwise.
 */
export const isNumber = (value) => typeof value === 'number' && !isNaN(value)
