import createSelector from 'utils/createSelector'
import { isCot } from 'redux/selectors/auth'
import { owners } from 'redux/config/tasks'
import _ from 'lodash'
import { numberToLetter } from 'utils/strings'
import { sortASCByPosition } from 'redux/models/task'

const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)
const isLoggedUserCot = (state) => isCot(state)
const getTypeClass = (state, props) => props.typeClass

/*
 * Get task owner according to its taskableType
 */
const getTaskOwner = (payrollInstanceTask) => {
  const hasTasks = payrollInstanceTask.payrollInstanceTaskOwners.count()
  /*
   * When taskableType is "Company", get the actual task owner.
   */
  if (payrollInstanceTask.taskableType === 'Company' && hasTasks) {
    return payrollInstanceTask.payrollInstanceTaskOwners.toModelArray()[0].owner.ref
  }

  /*
   * When taskableType is "BusinessUnit", get the task owner of every single business unit
   */
  if (payrollInstanceTask.taskableType === 'BusinessUnit' && hasTasks) {
    // Get payroll task owners
    const owners = payrollInstanceTask.payrollInstanceTaskOwners.toModelArray()

    // Return label for multi and the owners' props
    return {
      multi: 'Multiple owners',
      multiOwners: owners.map((pto) => ({
        id: pto.owner.id,
        fullname: pto.owner.fullname,
        schema: pto.owner.company.parentCompany.schema,
        hasCredentials: pto.owner.hasCredentials,
      })),
    }
  }

  return {}
}

/**
 * Get PayrollInstanceProcess by PayrollInstance
 *
 * By a business requirement, it's supposed  one payroll instance
 * to have exactly one payroll instance service and process.
 *
 * Because of this we're getting the first service and process of the instance
 * below in the selector.
 */
export const getPayrollInstanceProcessTreeByPayrollInstance = createSelector(
  getPayrollInstanceId,
  isLoggedUserCot,
  getTypeClass,
  ({ PayrollInstance, Payroll: payrollState }, payrollInstanceId, isUserCot, typeClass) => {
    if (payrollInstanceId) {
      const instance = PayrollInstance.withId(payrollInstanceId)

      const service = instance.payrollInstanceServices.first()
      const process = service.payrollInstanceProcesses.first()
      const payroll = instance.payroll ?? payrollState.withId(instance.payrollId)

      let tree = {
        id: process.id,
        name: payroll.name,
        owner: process.owner ? process.owner.ref : null,
        keyEvent: process.payrollProcess.companyProcess.countryProcess.keyEvent
          ? process.payrollProcess.companyProcess.countryProcess.keyEvent
          : process.payrollProcess.companyProcess.countryProcess.process.keyEvent,
        keyEventDate: process.payrollProcess.companyProcess.keyEventDate
          ? process.payrollProcess.companyProcess.keyEventDate
          : process.payrollProcess.companyProcess.countryProcess.keyEventDate,
        instanceStatus: instance.status,
        steps: [],
      }

      process.payrollInstanceSteps
        .toModelArray()
        .sort(sortASCByPosition)
        .forEach((payrollInstanceStep, indexStep) => {
          let step = {
            name: `${numberToLetter(indexStep + 1).toUpperCase()}. ${payrollInstanceStep.payrollStep.name}`,
            tasks: payrollInstanceStep.payrollInstanceTasks
              .toModelArray()
              .filter((payrollInstanceTask) => {
                let payrollInstanceOwner = payrollInstanceTask.globalOwner
                const isActive = payrollInstanceTask.active

                if (typeClass && typeClass !== 'vendor') {
                  return payrollInstanceOwner !== owners.cot && payrollInstanceOwner !== owners.vendor && payrollInstanceOwner !== owners.software && isActive
                }

                return isActive
              })
              .map((payrollInstanceTask, index) => ({
                id: payrollInstanceTask.id,
                identifier: payrollInstanceTask.identifier,
                taskableType: payrollInstanceTask.taskableType,
                name: `${numberToLetter(indexStep + 1).toUpperCase()}${index + 1}. ${payrollInstanceTask.name}`,
                ownerOld: payrollInstanceTask.owner ? payrollInstanceTask.owner.ref : null,
                owner: getTaskOwner(payrollInstanceTask),
                globalOwner: payrollInstanceTask.globalOwner,
                deadline: payrollInstanceTask.deadline ? payrollInstanceTask.deadline.date : null,
                position: payrollInstanceTask.position,
                active: payrollInstanceTask.active,
              }))
              .sort(sortASCByPosition),
          }

          if (step.tasks.length) tree.steps.push(step)
        })

      return tree
    }
  }
)

export const getPayrollInstanceProcessTasksOwnersIds = createSelector(
  getPayrollInstanceId,
  isLoggedUserCot,
  getTypeClass,
  ({ PayrollInstance }, payrollInstanceId, isUserCot, typeClass) => {
    if (payrollInstanceId) {
      const instance = PayrollInstance.withId(payrollInstanceId)

      const service = instance.payrollInstanceServices.first()
      const process = service.payrollInstanceProcesses.first()

      const taskOwners = process.payrollInstanceSteps
        .toModelArray()
        .map((payrollInstanceStep) =>
          payrollInstanceStep.payrollInstanceTasks
            .toModelArray()
            .map((payrollInstanceTask) => payrollInstanceTask.payrollInstanceTaskOwners.toRefArray().map((pivot) => pivot.owner))
        )

      taskOwners.push(process.ref.owner)

      return _.uniq(_.flattenDeep(taskOwners))
    }
  }
)

export const getPayrollInstanceProcessIdByPayrollInstanceId = createSelector(getPayrollInstanceId, ({ PayrollInstance }, payrollInstanceId) => {
  const payrollInstance = PayrollInstance.withId(payrollInstanceId)
  return payrollInstance.payrollInstanceServices.first().payrollInstanceProcesses.first().id
})
