import createSelector from 'utils/createSelector'
import { employeeCustomFieldsConfig } from 'redux/config/employeeFieldsConfig'

const getEmployeeId = (state, props) => parseInt(props.employeeId)
const getCustomFields = (state, props) => props.customFields
const getCountryName = (state, props) => props.country

export const getEmployee = createSelector(getEmployeeId,
  ({ EmployeeSystemUser }, employeeId) => {
    const employee = EmployeeSystemUser.withId(employeeId)
    return {
      ...employee.ref,
    }
  }
)

export const getEmployeeWithCountryName = createSelector(getEmployeeId,
  ({ EmployeeSystemUser }, employeeId) => {
    const employee = EmployeeSystemUser.withId(employeeId)
    return {
      ...employee.ref,
      employeeAddresses: employee.addresses.toRefArray(),
      mobilePhone: employee.mobilePhone || null,
      isContractorCompany: employee.getIsContractorCompany(),
      isContractorAgency: employee.getIsContractorAgency(),
      isShortTermAssignment: employee.getIsShortTermAssignment(),
      isShortTermAssignmentHost: employee.getIsShortTermAssignmentHost(),
      companyCountry: employee.companyRef.countryRef,
      countryName: employee.countries.toRefArray().map(country => country.name)
    }
  }
)

export const getEmployeeWithCompanyCountryBusinessUnitCostCenterProject = createSelector(getEmployeeId,
  ({ EmployeeSystemUser }, employeeId) => {
    const employee = EmployeeSystemUser.withId(employeeId)
    return {
      ...employee.ref,
      department: employee.department ? employee.department.ref : null,
      officeLocation: employee.officeLocation ? employee.officeLocation.ref : null,
      subsidiary: employee.subsidiary ? employee.subsidiary.ref : null,
      countriesIds: employee.countries.toRefArray().map(country => country.id),
      countries: employee.countries.toRefArray().map(country => country.name),
      countryName: employee.company.country.name,
      company: employee.company.ref,
      businessUnit: employee.businessUnit ? employee.businessUnit.ref : null,
      costCenter: employee.costCenter ? employee.costCenter.ref : null,
      project: employee.project ? employee.project.ref : null,
      paymentCurrency: { ...employee.paymentCurrency ? employee.paymentCurrency.ref : null }
    }
  }
)

export const getEmployeeWithCurrencyAndTerms = createSelector(getEmployeeId,
  ({ EmployeeSystemUser, Currency }, employeeId) => {
    const employee = EmployeeSystemUser.withId(employeeId)
    return {
      ...employee.ref,
      paymentCurrency: { ...employee.paymentCurrency ? employee.paymentCurrency.ref : null },
      invoicePaymentCurrency: { ...employee.invoicePaymentCurrency ? Currency.withId(employee.invoicePaymentCurrency).ref : null },
      employeeCompanyCountryTerms: employee.employeeCompanyCountryTerms.toModelArray().map(pivot => ({
        ...pivot.ref,
        cct: { ...pivot.term.ref }
      })),
      countries: employee.countries.toRefArray().map(country => country.name),
      companyCountry: employee.companyRef.countryRef
    }
  }
)

export const getEmployeeCustomFieldsInitials = createSelector(getEmployeeId, getCustomFields,
  ({ EmployeeSystemUser }, employeeId, customFields) => {
    const employee = EmployeeSystemUser.withId(employeeId)

    let formInitials = {}

    customFields.forEach(field => {
      const fieldName = field.name
      formInitials[fieldName] = employee[fieldName]
    })

    return formInitials
  })

/*
 * Get employee custom fields by country
 */
export const getEmployeeFieldsByCountry = createSelector(getCountryName,
  (session, countryName) => {
    return employeeCustomFieldsConfig.find(fields => fields.country === countryName)
  }
)
/*
 * Get employee custom fields for Personal tab
 */
export const getEmployeePersonalFields = createSelector(getEmployeeFieldsByCountry,
  (session, fields) => {
    let customFields = fields.customFields
    return customFields.filter(field => field.tab === 'Personal')
  }
)

/*
 * Get employee custom fields for Pay & Taxes tab
 */
export const getEmployeePayTaxesFields = createSelector(getEmployeeFieldsByCountry,
  (session, fields) => {
    let customFields = fields.customFields
    return customFields.filter(field => field.tab === 'Pay & Taxes')
  }
)

/*
* Get employee custom fields for Job & Org Tab
*/
export const getEmployeeJobOrgFields = createSelector(getEmployeeFieldsByCountry,
  (session, fields) => {
    let customFields = fields.customFields
    return customFields.filter(field => field.tab === 'Job & Org')
  }
)
