import createSelector from 'utils/createSelector'

const getTermId = (state, props) => parseInt(props.termId)

export const getTerms = createSelector(
  ({ Term, Category, SubCategory }) => {
    return Term
      .all()
      .toModelArray()
      .map(term => {
        return {
          ...term.ref,
          termSubcategoryRef: term.termSubcategory.ref,
          categoryRef: term.termSubcategory.termCategory.ref
        }
      })
      .reverse()
  })

getTerms.default = []

export const getTermById = createSelector(getTermId,
  ({ Term }, termId) => Term.withId(termId)
)
