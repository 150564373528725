import { SelectColumnFilter } from 'components/table/filters'
import { getDocTypesLabellingOpts } from 'utils/enums/documentTypes'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { textLabels, uploadTextLabels } from 'utils/locales/common.en'
import { documentTextLabels } from 'utils/locales/document.en'
import { dateTimePayslipTable, dateTimePayslipTableFilter, download, includesFilterTable } from 'utils/tableDataFormatters'

export const getLinkDocSelectionCell = () => ({
  ...new TableHeading({
    accessor: 'section',
    heading: documentTextLabels.filesSection,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
    filter: includesFilterTable,
  }),
  Filter: SelectColumnFilter,
  dropDownOptions: {
    name: 'type',
    options: getDocTypesLabellingOpts(),
    multi: true,
  },
})

export const generateLinkDocsHeadings = ({ handleDownloadDocument }) => [
  new TableHeading({
    accessor: 'name',
    heading: textLabels.name,
    className: 'text-left',
    columnClassName: 'text-left',
    setWidth: '280px',
    disableSortBy: false,
    Cell: download,
    disableFilters: false,
    onClick: handleDownloadDocument,
  }),
  new TableHeading({
    accessor: 'description',
    heading: textLabels.description,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
  }),
  new TableHeading({
    accessor: 'documentType',
    heading: textLabels.type,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
  }),
  new TableHeading({
    accessor: 'version',
    heading: textLabels.version,
    className: 'text-center',
    columnClassName: 'text-center',
    disableSortBy: false,
    disableFilters: false,
  }),
  new TableHeading({
    accessor: 'uploadedOn',
    heading: uploadTextLabels.uploadedOn,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
    Cell: dateTimePayslipTable,
    filter: dateTimePayslipTableFilter,
  }),
  new TableHeading({
    accessor: 'uploadedBy',
    heading: uploadTextLabels.uploadedBy,
    className: 'text-left',
    columnClassName: 'text-left',
    disableSortBy: false,
    disableFilters: false,
  }),
]

export const getLinkDocSelectedRowProps = (options) => ({
  type: 'checkbox',
  opts: {
    labelKey: 'section',
    cell: getLinkDocSelectionCell(),
    className: ' ',
  },
  isUnselectable: (_row) => false,
  ...options,
})

export const getLinkDocOpts = (data) => ({
  pageSize: 10,
  pageIndex: 0,
  showPageSizeSelector: data.length > 10,
})
