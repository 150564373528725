import React from 'react'
import { connect } from 'react-redux'
import {
  getEvents,
  getFilteredEvents,
} from 'routes/Toolbox/routes/Calendar/selectors/events'
import { isFetching } from 'utils/redux/fetching'
import CalendarActionDashboardView from '../components/CalendarActionDashboardView'
import { fetchEvents } from 'redux/actions/events'
import { createFilter } from 'utils/redux/filter'
import { buildFilters } from 'utils/filters'
import PropTypes from 'prop-types'
class CalendarActionDashboardContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.onFetchFilters()
  }

  render () {
    return <CalendarActionDashboardView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, { filtersValues, owner, history }) => {
  const filters = buildFilters(filtersValues)
  let defaultFilters = {
    ...filters,
    notInactive: true
  }
  if (owner) {
    defaultFilters['owner'] = owner
  }
  return {
    onFetchFilters: () =>
      dispatch(
        fetchEvents({
          filter: createFilter(defaultFilters, 'events'),
          disableObsoleteFlow: true,
        })
      ),
    onNavigatingToCalendar: () => history.push('/toolbox/calendar'),
    dispatch,
  }
}

// map to state
const mapStateToProps = (state) => {
  const { events } = state
  const entities = [{ ref: events, filter: { name: 'events' } }]
  if (isFetching(entities)) return { isFetching: true }
  return {
    isFiltering: state.events.isFiltering,
    tasks: state.events.filteredIds
      ? getFilteredEvents(state)
      : getEvents(state),
  }
}

CalendarActionDashboardContainer.propTypes = {
  onFetchFilters: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarActionDashboardContainer)
