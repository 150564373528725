import React from 'react'
import PropTypes from 'prop-types'
import PayrollInstanceTaskList from './PayrollInstanceTaskList'
import PayrollInstanceTaskReversionForm from './PayrollInstanceTaskReversionForm'
import Submit from 'components/buttons/Submit'

class PayrollInstanceTaskView extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isReversionFormVisible: false }
    this.showReversionForm = this.showReversionForm.bind(this)
  }

  showReversionForm () {
    this.setState({ isReversionFormVisible: true })
  }

  render () {
    const { showSubmitBtnOnActionsForm, reversibleTaskId } = this.props
    const { isReversionFormVisible } = this.state

    return <>
      {!isReversionFormVisible && <PayrollInstanceTaskList showSubmitBtn={showSubmitBtnOnActionsForm} {...this.props} /> }
      <div className='o-layout'>
        { reversibleTaskId && !isReversionFormVisible && <div className='o-layout__item u-text--center u-1/1'>
          <Submit onClick={this.showReversionForm} />
        </div> }
      </div>
      { isReversionFormVisible && <PayrollInstanceTaskReversionForm {...this.props} /> }
    </>
  }
}

PayrollInstanceTaskView.propTypes = {
  showSubmitBtnOnActionsForm: PropTypes.bool.isRequired,
  reversibleTaskId: PropTypes.number.isRequired
}

export default PayrollInstanceTaskView
