import React from 'react'
import PropTypes from 'prop-types'
import allCountries from 'redux/config/allCountries'

const specialConditions = [
  { name: 'Vietnam', code: 'VN' },
  { name: 'United States of America', code: 'US' },
]

export const Flag = ({ size, flag, title, classes, country }) => {
  let abbr = country ? allCountries.find(c => c.name === country.trim())?.code.toLocaleLowerCase() : null
  if (!abbr && country) {
    // Check the special conditions (as the server values returned are incorrect with what the value should be)
    abbr = specialConditions.find(c => c.name === country.trim())?.code.toLocaleLowerCase()
  }
  if (!flag && !abbr) return null

  return <span
    className={`flag-icon flag-icon-squared flag-icon--circle
    flag-icon--shadow flag-icon--${size} flag-icon-${abbr || flag} ` + classes}
    title={title}
  />
}

Flag.propTypes = {
  size: PropTypes.string,
  flag: PropTypes.string,
  classes: PropTypes.string,
  title: PropTypes.string,
  country: PropTypes.string
}

export default Flag
