import { createReducerMeta } from 'utils/redux'
import { BUSINESS_UNITS_FETCH, BUSINESS_UNITS_RECEIVE } from '../actions/businessUnits'

const actionTypes = {
  fetch: BUSINESS_UNITS_FETCH,
  receive: BUSINESS_UNITS_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducerMeta(actionTypes)
