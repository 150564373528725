import { connect } from 'react-redux'
import NavBarProfile from 'components/navbar/NavBarProfile'
import { getUser } from 'redux/selectors/employees'
import { createFilter } from 'utils/redux/filter'
import Fetcher from '../Fetcher'
import { logout } from 'redux/actions/auth'

const mapStateToProps = (state, props) => ({
  user: getUser(state, { userId: state.auth.userId }),
  showCompany: true
})

const mapDispatchToProps = { logout }

const Container = connect(mapStateToProps, mapDispatchToProps)(NavBarProfile)

export default Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: state => createFilter({ id: state.auth.userId })
      }
    }]
  }
])
