import { fetchEmployeeSystemUsersIfNeeded, fetchEmployeeSystemUsers } from './employeeSystemUsers'
import { fetchCompaniesIfNeeded, fetchCompanies } from 'routes/Companies/modules/actions'
import { fetchBusinessUnitsIfNeeded, fetchBusinessUnits } from './businessUnits'
import { fetchCostCentersIfNeeded, fetchCostCenters } from './costCenters'
import { fetchOfficeLocationsIfNeeded, fetchOfficeLocations } from './officeLocations'
import { fetchSubsidiariesIfNeeded, fetchSubsidiaries } from './subsidiaries'
import { fetchParentCompaniesIfNeeded, fetchParentCompanies } from './parentCompanies'
import { fetchAccessAreaPivotIfNeeded, fetchAccessAreaPivot } from './accessAreaPivot'
import { fetchCountriesIfNeeded, fetchCountries } from 'routes/Countries/modules/actions'
import { fetchCurrenciesIfNeeded, fetchCurrencies } from './currencies'
import { fetchLanguagesIfNeeded, fetchLanguages } from './languages'
import { fetchTimezonesIfNeeded, fetchTimezones } from './timezones'
import { fetchTermsIfNeeded, fetchTerms } from 'routes/Terms/modules/actions'
import { fetchCountryTermPivotIfNeeded, fetchCountryTermPivot } from './countryTermPivot'
import { fetchCompanyCountryTermPivotIfNeeded, fetchCompanyCountryTermPivot } from './companyCountryTermPivot'
import { fetchPayrollsIfNeeded, fetchPayrolls } from './payrolls'
import { fetchPayrollInstancesIfNeeded, fetchPayrollInstances } from './payrollInstances'
import { fetchDocumentsIfNeeded, fetchDocuments } from './document'
import { fetchDocumentUsersIfNeeded, fetchDocumentUsers } from './documentUsers'
import { fetchVendorsIfNeeded, fetchVendors } from 'redux/actions/vendors'
import { fetchVendorUsersIfNeeded, fetchVendorUsers } from 'redux/actions/vendorUsers'
import { fetchExternalVendorUsersIfNeeded, fetchExternalVendorUsers } from 'redux/actions/externalVendorUsers'
import { fetchExternalEmployeeUsersIfNeeded, fetchExternalEmployeeUsers } from 'redux/actions/externalEmployeeUsers'
import { fetchPayrollServiceIfNeeded, fetchPayrollService } from 'redux/actions/payrollService'
import { fetchPayrollProcessIfNeeded, fetchPayrollProcess } from 'redux/actions/payrollProcess'
import { fetchPayrollStepIfNeeded, fetchPayrollStep } from 'redux/actions/payrollStep'
import { fetchPayrollTaskIfNeeded, fetchPayrollTask } from 'redux/actions/payrollTask'
import { fetchCompanyServicePivotIfNeeded, fetchCompanyServicePivot } from 'redux/actions/companyServicePivot'
import { fetchCompanyProcessPivotIfNeeded, fetchCompanyProcessPivot } from 'redux/actions/companyProcessPivot'
import { fetchCompanyStepPivotIfNeeded, fetchCompanyStepPivot } from 'redux/actions/companyStepPivot'
import { fetchCompanyTaskPivotIfNeeded, fetchCompanyTaskPivot } from 'redux/actions/companyTaskPivot'
import { fetchCountryProcessPivotIfNeeded, fetchCountryProcessPivot } from 'redux/actions/countryProcessPivot'
import { fetchCountryStepPivotIfNeeded, fetchCountryStepPivot } from 'redux/actions/countryStepPivot'
import { fetchCountryTaskPivotIfNeeded, fetchCountryTaskPivot } from 'redux/actions/countryTaskPivot'
import { fetchServicesIfNeeded, fetchServices } from 'redux/actions/services'
import { fetchProcessesIfNeeded, fetchProcesses } from 'redux/actions/processes'
import { fetchStepsIfNeeded, fetchSteps } from 'redux/actions/steps'
import { fetchTasksIfNeeded, fetchTasks } from 'redux/actions/tasks'
import { fetchPayrollInstanceServiceIfNeeded, fetchPayrollInstanceService } from 'redux/actions/payrollInstanceService'
import { fetchPayrollInstanceProcessIfNeeded, fetchPayrollInstanceProcess } from 'redux/actions/payrollInstanceProcess'
import { fetchPayrollInstanceStepIfNeeded, fetchPayrollInstanceStep } from 'redux/actions/payrollInstanceStep'
import { fetchPayrollInstanceTaskIfNeeded, fetchPayrollInstanceTask } from 'redux/actions/payrollInstanceTask'
import { fetchDocumentLogsIfNeeded, fetchDocumentLogs } from 'redux/actions/documentLogs'
import { fetchDocumentTypesIfNeeded, fetchDocumentTypes } from 'redux/actions/documentTypes'
import { fetchPayrollInstanceTaskOwnerIfNeeded, fetchPayrollInstanceTaskOwner } from 'redux/actions/payrollInstanceTaskOwner'
import { fetchPayrollInstanceCountryTermsPivotIfNeeded, fetchPayrollInstanceCountryTermsPivot } from 'redux/actions/payrollInstanceCountryTermsPivot'
import { fetchPayrollInstanceTaskOwnerScopeIfNeeded, fetchPayrollInstanceTaskOwnerScope } from 'redux/actions/payrollInstanceTaskOwnerScope'
import { fetchPayrollEmployeePivotIfNeeded, fetchPayrollEmployeePivot } from 'redux/actions/payrollEmployeePivot'
import { fetchPayrollTaskOwnersIfNeeded, fetchPayrollTaskOwners } from 'redux/actions/payrollTaskOwners'
import { fetchPayrollTaskOwnerScopesIfNeeded, fetchPayrollTaskOwnerScopes } from 'redux/actions/payrollTaskOwnerScopes'
import { fetchEmployeeCountryPivotIfNeeded, fetchEmployeeCountryPivot } from './employeeCountryPivot'
import { fetchPayrollInstanceEmployeePivotIfNeeded, fetchPayrollInstanceEmployeePivot } from './payrollInstanceEmployeePivot'
import { fetchAnnualSalariesIfNeeded, fetchAnnualSalaries } from 'redux/actions/annualSalaries'
import { fetchEmployeeTermPivotIfNeeded, fetchEmployeeTermPivot } from 'redux/actions/employeeTermPivot'
import { fetchPaygroupEmployeePivotIfNeeded, fetchPaygroupEmployeePivot } from 'redux/actions/paygroupEmployeePivot'
import { fetchPayrollPaygroupPivotIfNeeded, fetchPayrollPaygroupPivot } from 'redux/actions/payrollPaygroupPivot'
import { fetchNotesIfNeeded, fetchNotes } from 'redux/actions/notes'
import { fetchPaygroupsIfNeeded, fetchPaygroups } from 'redux/actions/paygroups'
import { fetchTermSubCategoriesIfNeeded, fetchTermSubCategories } from 'redux/actions/termSubCategories'
import { fetchTermCategoriesIfNeeded, fetchTermCategories } from 'redux/actions/termCategories'
import { fetchEmployeeOvertimeIfNeeded, fetchEmployeeOvertime } from 'redux/actions/employeeOvertime'
import { fetchEmployeeAttendanceIfNeeded, fetchEmployeeAttendance } from 'redux/actions/employeeAttendance'
import { fetchCompanyHolidaysPivotIfNeeded, fetchCompanyHolidaysPivot } from 'redux/actions/companyHolidayPivot'
import { fetchNotificationsIfNeeded, fetchNotifications } from 'redux/actions/notifications'
import { fetchProjectsIfNeeded, fetchProjects } from 'redux/actions/projects'
import { fetchReferralsIfNeeded, fetchReferrals } from 'redux/actions/referrals'
import { fetchPaygroupTermPivotIfNeeded, fetchPaygroupTermPivot } from 'redux/actions/paygroupTermPivot'
import { fetchBankAccountTypesIfNeeded, fetchBankAccountTypes } from 'redux/actions/bankAccountTypes'
import { fetchCotUsersIfNeeded, fetchCotUsers } from 'redux/actions/cotUsers'
import { fetchNoteSharesIfNeeded, fetchNoteShares } from 'redux/actions/noteShares'
import { fetchFxRatesIfNeeded, fetchFxRates } from 'redux/actions/fxRates'
import { fetchAssignmentsIfNeeded, fetchAssignments } from 'redux/actions/assignments'
import { fetchDepartmentsIfNeeded, fetchDepartments } from 'redux/actions/departments'
import { fetchDashboardItemsIfNeeded, fetchDashboardItems } from 'redux/actions/dashboards'
import { fetchGeneralLedgersIfNeeded, fetchGeneralLedgers } from 'redux/actions/generalLedgers'
import { fetchEditorsIfNeeded, fetchEditors } from 'redux/actions/editors'
import { fetchReportDataIfNeeded, fetchReportData } from 'redux/actions/reportData'
import { fetchGeneralLedgerGlobalDataIfNeeded, fetchGeneralLedgerGlobalData } from 'redux/actions/generalLedgerGlobalData'
import { fetchVendorSwitchIfNeeded, fetchVendorSwitch } from 'redux/actions/vendorSwitch'
import { fetchBankAccounts, fetchBankAccountsIfNeeded } from './bankAccounts'
import { fetchCountryServicePivotIfNeeded, fetchCountryServicePivot } from 'redux/actions/countryServicePivot'
import { fetchVendorCountryServicePivotIfNeeded, fetchVendorCountryServicePivot } from 'redux/actions/vendorCountryServicePivot'
import { fetchEmployeeUserAddressesIfNeeded, fetchEmployeeUserAddresses } from 'redux/actions/employeeUserAddresses'
import { fetchEventsIfNeeded, fetchEvents } from 'redux/actions/events'
import { fetchReports, fetchReportsIfNeeded } from './reports'
import { fetchPollingJobs } from './pollingJob'
import { fetchPayrollInstanceVersion, fetchPayrollInstanceVersionIfNeeded } from './payrollInstanceVersion'
import { fetchExternalCompanies, fetchExternalCompaniesIfNeeded } from './externalCompanies'
import { fetchCommentary, fetchCommentaryIfNeeded } from './commentary'
import { fetchAdditionalItems, fetchAdditionalItemsIfNeeded } from './additionalItems'
import { fetchResources, fetchResourcesIfNeeded } from './resources'
import { fetchResourceLogs, fetchResourceLogsIfNeeded } from './resourceLogs'

export default {
  employeeSystemUsers: { fetchIfNeeded: fetchEmployeeSystemUsersIfNeeded, fetch: fetchEmployeeSystemUsers },
  editors: { fetchIfNeeded: fetchEditorsIfNeeded, fetch: fetchEditors },
  companies: { fetchIfNeeded: fetchCompaniesIfNeeded, fetch: fetchCompanies },
  currencies: { fetchIfNeeded: fetchCurrenciesIfNeeded, fetch: fetchCurrencies },
  businessUnits: { fetchIfNeeded: fetchBusinessUnitsIfNeeded, fetch: fetchBusinessUnits },
  costCenters: { fetchIfNeeded: fetchCostCentersIfNeeded, fetch: fetchCostCenters },
  countries: { fetchIfNeeded: fetchCountriesIfNeeded, fetch: fetchCountries },
  departments: { fetchIfNeeded: fetchDepartmentsIfNeeded, fetch: fetchDepartments },
  officeLocations: { fetchIfNeeded: fetchOfficeLocationsIfNeeded, fetch: fetchOfficeLocations },
  subsidiary: { fetchIfNeeded: fetchSubsidiariesIfNeeded, fetch: fetchSubsidiaries },
  parentCompanies: { fetchIfNeeded: fetchParentCompaniesIfNeeded, fetch: fetchParentCompanies },
  accessAreaPivot: { fetchIfNeeded: fetchAccessAreaPivotIfNeeded, fetch: fetchAccessAreaPivot },
  terms: { fetchIfNeeded: fetchTermsIfNeeded, fetch: fetchTerms },
  countryTermPivot: { fetchIfNeeded: fetchCountryTermPivotIfNeeded, fetch: fetchCountryTermPivot },
  companyCountryTermPivot: { fetchIfNeeded: fetchCompanyCountryTermPivotIfNeeded, fetch: fetchCompanyCountryTermPivot },
  languages: { fetchIfNeeded: fetchLanguagesIfNeeded, fetch: fetchLanguages },
  timezones: { fetchIfNeeded: fetchTimezonesIfNeeded, fetch: fetchTimezones },
  payrolls: { fetchIfNeeded: fetchPayrollsIfNeeded, fetch: fetchPayrolls },
  payrollInstances: { fetchIfNeeded: fetchPayrollInstancesIfNeeded, fetch: fetchPayrollInstances },
  document: { fetchIfNeeded: fetchDocumentsIfNeeded, fetch: fetchDocuments },
  documentUsers: { fetchIfNeeded: fetchDocumentUsersIfNeeded, fetch: fetchDocumentUsers },
  vendorUsers: { fetchIfNeeded: fetchVendorUsersIfNeeded, fetch: fetchVendorUsers },
  vendors: { fetchIfNeeded: fetchVendorsIfNeeded, fetch: fetchVendors },
  externalVendorUsers: { fetchIfNeeded: fetchExternalVendorUsersIfNeeded, fetch: fetchExternalVendorUsers },
  externalEmployeeUsers: { fetchIfNeeded: fetchExternalEmployeeUsersIfNeeded, fetch: fetchExternalEmployeeUsers },
  externalCompanies: { fetchIfNeeded: fetchExternalCompaniesIfNeeded, fetch: fetchExternalCompanies },
  payrollService: { fetchIfNeeded: fetchPayrollServiceIfNeeded, fetch: fetchPayrollService },
  payrollProcess: { fetchIfNeeded: fetchPayrollProcessIfNeeded, fetch: fetchPayrollProcess },
  payrollStep: { fetchIfNeeded: fetchPayrollStepIfNeeded, fetch: fetchPayrollStep },
  payrollTask: { fetchIfNeeded: fetchPayrollTaskIfNeeded, fetch: fetchPayrollTask },
  companyServicePivot: { fetchIfNeeded: fetchCompanyServicePivotIfNeeded, fetch: fetchCompanyServicePivot },
  companyProcessPivot: { fetchIfNeeded: fetchCompanyProcessPivotIfNeeded, fetch: fetchCompanyProcessPivot },
  companyStepPivot: { fetchIfNeeded: fetchCompanyStepPivotIfNeeded, fetch: fetchCompanyStepPivot },
  companyTaskPivot: { fetchIfNeeded: fetchCompanyTaskPivotIfNeeded, fetch: fetchCompanyTaskPivot },
  countryProcessPivot: { fetchIfNeeded: fetchCountryProcessPivotIfNeeded, fetch: fetchCountryProcessPivot },
  countryStepPivot: { fetchIfNeeded: fetchCountryStepPivotIfNeeded, fetch: fetchCountryStepPivot },
  countryTaskPivot: { fetchIfNeeded: fetchCountryTaskPivotIfNeeded, fetch: fetchCountryTaskPivot },
  services: { fetchIfNeeded: fetchServicesIfNeeded, fetch: fetchServices },
  processes: { fetchIfNeeded: fetchProcessesIfNeeded, fetch: fetchProcesses },
  steps: { fetchIfNeeded: fetchStepsIfNeeded, fetch: fetchSteps },
  tasks: { fetchIfNeeded: fetchTasksIfNeeded, fetch: fetchTasks },
  payrollInstanceEmployeePivot: {
    fetchIfNeeded: fetchPayrollInstanceEmployeePivotIfNeeded,
    fetch: fetchPayrollInstanceEmployeePivot,
  },
  payrollInstanceService: { fetchIfNeeded: fetchPayrollInstanceServiceIfNeeded, fetch: fetchPayrollInstanceService },
  payrollInstanceProcess: { fetchIfNeeded: fetchPayrollInstanceProcessIfNeeded, fetch: fetchPayrollInstanceProcess },
  payrollInstanceStep: { fetchIfNeeded: fetchPayrollInstanceStepIfNeeded, fetch: fetchPayrollInstanceStep },
  payrollInstanceTask: { fetchIfNeeded: fetchPayrollInstanceTaskIfNeeded, fetch: fetchPayrollInstanceTask },
  payrollInstanceTaskOwner: {
    fetchIfNeeded: fetchPayrollInstanceTaskOwnerIfNeeded,
    fetch: fetchPayrollInstanceTaskOwner,
  },
  payrollInstanceTaskOwnerScope: {
    fetchIfNeeded: fetchPayrollInstanceTaskOwnerScopeIfNeeded,
    fetch: fetchPayrollInstanceTaskOwnerScope,
  },
  payrollInstanceCountryTermsPivot: {
    fetchIfNeeded: fetchPayrollInstanceCountryTermsPivotIfNeeded,
    fetch: fetchPayrollInstanceCountryTermsPivot,
  },
  documentLogs: { fetchIfNeeded: fetchDocumentLogsIfNeeded, fetch: fetchDocumentLogs },
  documentTypes: { fetchIfNeeded: fetchDocumentTypesIfNeeded, fetch: fetchDocumentTypes },
  payrollEmployeePivot: { fetchIfNeeded: fetchPayrollEmployeePivotIfNeeded, fetch: fetchPayrollEmployeePivot },
  payrollTaskOwners: { fetchIfNeeded: fetchPayrollTaskOwnersIfNeeded, fetch: fetchPayrollTaskOwners },
  payrollTaskOwnerScopes: { fetchIfNeeded: fetchPayrollTaskOwnerScopesIfNeeded, fetch: fetchPayrollTaskOwnerScopes },
  employeeCountryPivot: { fetchIfNeeded: fetchEmployeeCountryPivotIfNeeded, fetch: fetchEmployeeCountryPivot },
  countryServicePivot: { fetchIfNeeded: fetchCountryServicePivotIfNeeded, fetch: fetchCountryServicePivot },
  vendorCountryServicePivot: { fetchIfNeeded: fetchVendorCountryServicePivotIfNeeded, fetch: fetchVendorCountryServicePivot },
  annualSalaries: { fetchIfNeeded: fetchAnnualSalariesIfNeeded, fetch: fetchAnnualSalaries },
  employeeTermPivot: { fetchIfNeeded: fetchEmployeeTermPivotIfNeeded, fetch: fetchEmployeeTermPivot },
  paygroupEmployeePivot: { fetchIfNeeded: fetchPaygroupEmployeePivotIfNeeded, fetch: fetchPaygroupEmployeePivot },
  payrollPaygroupPivot: { fetchIfNeeded: fetchPayrollPaygroupPivotIfNeeded, fetch: fetchPayrollPaygroupPivot },
  notes: { fetchIfNeeded: fetchNotesIfNeeded, fetch: fetchNotes },
  paygroups: { fetchIfNeeded: fetchPaygroupsIfNeeded, fetch: fetchPaygroups },
  termSubCategories: { fetchIfNeeded: fetchTermSubCategoriesIfNeeded, fetch: fetchTermSubCategories },
  employeeOvertime: { fetchIfNeeded: fetchEmployeeOvertimeIfNeeded, fetch: fetchEmployeeOvertime },
  employeeAttendance: { fetchIfNeeded: fetchEmployeeAttendanceIfNeeded, fetch: fetchEmployeeAttendance },
  companyHolidayPivot: { fetchIfNeeded: fetchCompanyHolidaysPivotIfNeeded, fetch: fetchCompanyHolidaysPivot },
  notifications: { fetchIfNeeded: fetchNotificationsIfNeeded, fetch: fetchNotifications },
  projects: { fetchIfNeeded: fetchProjectsIfNeeded, fetch: fetchProjects },
  referrals: { fetchIfNeeded: fetchReferralsIfNeeded, fetch: fetchReferrals },
  paygroupTermPivot: { fetchIfNeeded: fetchPaygroupTermPivotIfNeeded, fetch: fetchPaygroupTermPivot },
  termCategories: { fetchIfNeeded: fetchTermCategoriesIfNeeded, fetch: fetchTermCategories },
  bankAccountTypes: { fetchIfNeeded: fetchBankAccountTypesIfNeeded, fetch: fetchBankAccountTypes },
  bankAccounts: { fetchIfNeeded: fetchBankAccountsIfNeeded, fetch: fetchBankAccounts },
  cotUsers: { fetchIfNeeded: fetchCotUsersIfNeeded, fetch: fetchCotUsers },
  noteShares: { fetchIfNeeded: fetchNoteSharesIfNeeded, fetch: fetchNoteShares },
  fxRates: { fetchIfNeeded: fetchFxRatesIfNeeded, fetch: fetchFxRates },
  assignments: { fetchIfNeeded: fetchAssignmentsIfNeeded, fetch: fetchAssignments },
  dashboards: { fetchIfNeeded: fetchDashboardItemsIfNeeded, fetch: fetchDashboardItems },
  generalLedgers: { fetchIfNeeded: fetchGeneralLedgersIfNeeded, fetch: fetchGeneralLedgers },
  generalLedgerGlobalData: { fetchIfNeeded: fetchGeneralLedgerGlobalDataIfNeeded, fetch: fetchGeneralLedgerGlobalData },
  reportData: { fetchIfNeeded: fetchReportDataIfNeeded, fetch: fetchReportData },
  employeeUserAddresses: { fetchIfNeeded: fetchEmployeeUserAddressesIfNeeded, fetch: fetchEmployeeUserAddresses },
  vendorSwitch: { fetchIfNeeded: fetchVendorSwitchIfNeeded, fetch: fetchVendorSwitch },
  events: { fetchIfNeeded: fetchEventsIfNeeded, fetch: fetchEvents },
  reports: { fetchIfNeeded: fetchReportsIfNeeded, fetch: fetchReports },
  pollingJobs: { fetch: fetchPollingJobs },
  payrollInstanceVersion: { fetchIfNeeded: fetchPayrollInstanceVersionIfNeeded, fetch: fetchPayrollInstanceVersion },
  commentary: { fetchIfNeeded: fetchCommentaryIfNeeded, fetch: fetchCommentary },
  additionalItems: { fetchIfNeeded: fetchAdditionalItemsIfNeeded, fetch: fetchAdditionalItems },
  resources: { fetchIfNeeded: fetchResourcesIfNeeded, fetch: fetchResources },
  resourceLogs: { fetchIfNeeded: fetchResourceLogsIfNeeded, fetch: fetchResourceLogs },
}
