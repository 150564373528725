import React from 'react'
import FixedPayElementsContainer from 'containers/FixedPayElementsContainer'
import VariablePayElementsContainer from 'containers/VariablePayElementsContainer'

export default ({ companyId, disabled, disabledReason, className = 'u-1/1 u-1/2@desktop', itemsClassName }) => {
  const props = { companyId, disabled, disabledReason, className: itemsClassName }

  return <div className='o-layout'>
    <div className={`o-layout__item ${className}`}>
      <h4 className='u-text--huge u-margin-bottom-small'>Add Values to Fixed Pay Elements</h4>
      { companyId &&
      <FixedPayElementsContainer {...props} /> }
    </div>
    <div className={`o-layout__item ${className}`}>
      <h4 className='u-text--huge u-margin-bottom-small'>Choose Variable Pay Elements</h4>
      { companyId &&
      <VariablePayElementsContainer {...props} /> }
    </div>
  </div>
}
