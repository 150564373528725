import React from 'react'
import PropTypes from 'prop-types'

const AddBtnMessage = ({ text, btnClasses, isDisabled, testId = '', onClick = null }) => {
  return (
    <span className='u-bg--white'>
      {text}
      <button
        onClick={onClick}
        className={`c-btn c-btn--curious u-margin-left-tiny ${btnClasses} ${isDisabled ? 'disabled' : ''}`}
        data-testid={`${testId}-create`}
        disabled={isDisabled}
      >
        <span className='icon icon--plus' />
      </button>
    </span>
  )
}

AddBtnMessage.propTypes = {
  text: PropTypes.string,
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  btnClasses: PropTypes.string,
  onClick: PropTypes.func,
}
export default AddBtnMessage
