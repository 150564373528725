import jwtDecode from 'jwt-decode'
import { SubmissionError } from 'redux-form'
import { resetAction } from 'redux/actions/persist'
import Promise from 'es6-promise'
import { isExpired } from 'utils/jwt'
import api, { TOKEN_ERROR, getParams } from 'utils/api'
import { handleMainErrors } from 'redux/middlewares/enhancedThunk'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { showMessage } from 'redux/actions/modal'
import { createBrowserHistory } from 'history'
import { errorToString } from 'utils/apiErrors'
import { setShouldPoll, setStartPolling } from './pollingJob'
import { closeAllToastMessages } from './toasts'
import { batch } from 'react-redux'
import { resetBreadcrumb } from './breadcrumbs'
import { keycloakTokenExpiration } from 'utils/enums/keycloakRealms'
import { clearStorageByNamepace } from 'utils/storage'

const customHistory = createBrowserHistory()
// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RECEIVE_REFRESHED_TOKEN = 'RECEIVE_REFRESHED_TOKEN'
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const RECEIVE_PERMISSIONS_ACTION = 'RECEIVE_PERMISSIONS_ACTION'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'
export const FETCH_MOBILE_PHONE = 'FETCH_MOBILE_PHONE'
export const RECEIVE_MOBILE_PHONE = 'RECEIVE_MOBILE_PHONE'
export const FETCH_EMAIL = 'FETCH_EMAIL'
export const RECEIVE_EMAIL = 'RECEIVE_EMAIL'
export const FETCH_PERMISSIONS_ACTION = 'FETCH_PERMISSIONS_ACTION'

// ------------------------------------
// Actions
// ------------------------------------

export const loginAction = (data) => ({
  type: LOGIN,
  payload: data,
})

export const updatePasswordAction = (data) => ({
  type: UPDATE_PASSWORD,
  payload: data,
})

export const forgottenPasswordAction = () => ({
  type: FORGOTTEN_PASSWORD,
})

export const resetPasswordAction = () => ({
  type: RESET_PASSWORD,
})

export const receivePermissionsAction = (permissions) => ({
  type: RECEIVE_PERMISSIONS_ACTION,
  payload: permissions,
})

export const fetchPermissionsAction = () => ({
  type: FETCH_PERMISSIONS_ACTION,
})

const fetchMobilePhoneAction = () => ({
  type: FETCH_MOBILE_PHONE,
})

export const receiveMobilePhoneAction = (mobilePhone) => ({
  type: RECEIVE_MOBILE_PHONE,
  payload: mobilePhone,
})

const fetchEmailAction = () => ({
  type: FETCH_EMAIL,
})

export const receiveEmailAction = (email) => ({
  type: RECEIVE_EMAIL,
  payload: email,
})

// ------------------------------------
// Thunk
// ------------------------------------

// Login
export const login = (data) => {
  return (dispatch, getState, { api }) => {
    return api
      .post('auth/login', { payload: data, forceUserAPI: true })
      .then(errorsHandling.handleFormErrors)
      .then((response) => dispatch(loginByTokens(response)))
  }
}

export const loginByTokens = (tokens) => {
  if (tokens.change_password_token) {
    return updatePasswordAction({
      username: tokens.username,
      changePasswordToken: tokens.change_password_token,
      showLegacyPasswordReason: tokens.show_legacy_password_reason,
    })
  }

  const decoded = jwtDecode(tokens.token)
  return loginAction({
    roles: decoded.roles,
    accessToken: tokens.token,
    refreshToken: tokens.refresh_token,
    userId: decoded.user,
    rhssoLogin: false,
  })
}

export const loginByRHSSOTokens = (tokens) => {
  return loginAction({
    roles: tokens.tokenParsed.roles,
    accessToken: tokens.token,
    refreshToken: tokens.refresh_token,
    userId: tokens.tokenParsed.user,
    rhssoLogin: true,
  })
}

// Get auth code by credentials
export const getCodeByCredentials = (data) => {
  return (dispatch, getState, { api }) => {
    return api.post('auth/code/credentials', { payload: data, forceUserAPI: true }).then(errorsHandling.handleFormErrors)
  }
}

// Get auth code by reset
export const getCodeByToken = (data) => {
  return (dispatch, getState, { api }) => {
    return api.post('auth/code/confirmationtoken', { payload: data, forceUserAPI: true }).then(errorsHandling.handleFormErrors)
  }
}

// Get auth code by reset
export const getCodeByAccessToken = (data) => {
  return (dispatch, getState, { api }) => {
    return api.post('auth/code/accesstoken', { payload: data, forceUserAPI: true }).then(errorsHandling.handleFormErrors)
  }
}

const resetActions = async (config, dispatch) => {
  batch(() => {
    dispatch(setShouldPoll({ enablePolling: false }))
    dispatch(setStartPolling({ shouldStartPolling: false }))
    dispatch(closeAllToastMessages())
    dispatch(resetAction({ config }))
    dispatch(resetBreadcrumb())
  })
}
// Logout
const rhssoLogoutThunk = async ({ keycloak, state, dispatch }) => {
  const { config } = state
  try {
    clearStorageByNamepace()
    await resetActions(config, dispatch)
    await keycloak.logout()
  } catch (error) {
    console.error('Error during rhsso logout', error)
  }
}

const apiLogoutThunk = async ({ state, dispatch, api }) => {
  const { auth, config } = state
  const canLogout = auth.accessToken && !isExpired(auth.accessToken)
  if (canLogout) {
    api.fetch('auth/logout')
  }
  clearStorageByNamepace()
  resetActions(config, dispatch)
}

// Logout
export const logout = (keycloak) => {
  const logoutThunk = async (dispatch, getState, { api }) => {
    const state = getState()
    const { config } = getState()
    const isRHSSO = config.migratedToRHSSO
    // Invalidate token on the BE, only if the token exists and it isn't expired yet.
    // We explicitly check for token existence, because it's possible the user to invoke `logout` in a case when
    // the user is not logged in yet.
    // For example, when the user is on login page, but for some reason (bug in the app) tries to reset the app
    // to its initial state, triggering `ResetContainer` functionality. In this case we don't have a token yet.

    if (isRHSSO) {
      await rhssoLogoutThunk({
        state,
        dispatch,
        keycloak,
      })
    }

    if (!isRHSSO) {
      await apiLogoutThunk({
        state,
        dispatch,
        api,
      })
    }
  }

  logoutThunk.disableJWT = true

  return logoutThunk
}

// Forgotten password
export const resetPasswordSendEmail = (data) => {
  return (dispatch, getState, { api }) => {
    dispatch(forgottenPasswordAction)

    return api.post('auth/password/reset', { payload: data }).then(errorsHandling.handleFormErrors)
  }
}

// Update password
export const updatePassword = (data) => {
  const hasErrors = data.password !== data.passwordConfirmation

  if (hasErrors) {
    throw new SubmissionError({
      passwordConfirmation: 'The passwords do not match',
    })
  }

  return (dispatch, getState, { api }) => {
    dispatch(updatePasswordAction)

    return api.post('auth/password/force-change', { payload: data }).then(errorsHandling.handleFormErrors)
  }
}

// Get user phone
export const getUserPhone = (data) => {
  return (dispatch, getState, { api }) => {
    dispatch(fetchMobilePhoneAction())

    return api
      .post('auth/phonenumber', { payload: data })
      .then(errorsHandling.handleFormErrors)
      .then((response) => dispatch(receiveMobilePhoneAction(response)))
      .catch((error) => {
        customHistory.push('/')

        dispatch(
          showMessage({
            body: errorToString(error, false),
          })
        )
      })
  }
}

// Update user phone
export const updateUserPhone = (data) => {
  return (dispatch, getState, { api }) => {
    return api
      .patch('auth/phonenumber', { payload: data })
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        dispatch(
          getUserPhone({
            confirmationToken: data.confirmationToken,
          })
        )

        return response
      })
  }
}

// Get user email
export const getUserEmail = (data) => {
  return (dispatch, getState, { api }) => {
    dispatch(fetchEmailAction())

    return api
      .post('auth/email', { payload: data })
      .then(errorsHandling.handleFormErrors)
      .then((response) => dispatch(receiveEmailAction(response)))
      .catch((error) => {
        customHistory.push('/')

        dispatch(
          showMessage({
            body: errorToString(error, false),
          })
        )
      })
  }
}

// Reset password
export const resetPassword = (data) => {
  const hasErrors = data.password !== data.passwordConfirmation

  if (hasErrors) {
    throw new SubmissionError({
      passwordConfirmation: 'The passwords do not match',
    })
  }

  return (dispatch, getState, { api }) => {
    dispatch(resetPasswordAction)

    return api.post('auth/password/submit', { payload: data }).then(errorsHandling.handleFormErrors)
  }
}

// Get permissions
export const getPermissions = () => {
  return (dispatch, getState, { api }) => {
    dispatch(fetchPermissionsAction())
    return api.fetch('permissions').then((response) => {
      dispatch(receivePermissionsAction(response))
    })
  }
}

/**
 * Trigger `refreshToken` API call and create a Promise,
 * for using it as a queue
 *
 * It's important to note that in the function body it's recommended
 * to diimport {LAST_TIME_TOKEN_WAS_REFRESHED} from './auth';
spatch object literal actions, instead of action creators (functions).
 * That's because, once we invoke `refreshToken` in jwt middleware,
 * all further action creators are queued to the Promise, created here (`refreshTokenPromise`).
 *
 * Please refer to `redux/middlewares/jwt.js`.
 *
 * @inheritDoc redux/middlewares/jwt.js
 * @param dispatch
 * @param getState
 */
export function canRefreshToken (dispatch, getState) {
  const params = getParams(getState(), 'auth/login/refresh', {
    forceUserAPI: true,
    payload: { refresh_token: getState().auth.refreshToken },
  })

  // Here we need api utility explicitly, because `refreshToken` functions isn't dispatched.
  // Please refer to docs, for more details.
  const refreshTokenPromise = api
    .post(...params)
    .then((response) => {
      const payload = {
        accessToken: response.token,
        refreshToken: response.refresh_token,
      }

      dispatch({
        type: RECEIVE_TOKEN,
        payload,
      })

      return Promise.resolve(payload)
    })
    // If we can't refresh the token, in most times it means the `refresh_token` is already expired
    // However, the Back-end will return appropriate `error` message
    .catch((error) => handleMainErrors(error, dispatch, getState()))

  dispatch({
    type: REFRESH_TOKEN,
    // we want to keep track of token promise in the state so that we don't try to refresh
    // the token again while refreshing is in process
    payload: { refreshTokenPromise },
  })

  return refreshTokenPromise
}

export const handleExpiredSession = ({ kc, dispatch, state }) => {
  if (kc) {
    dispatch(logout(kc))
  }

  handleMainErrors({ message: TOKEN_ERROR }, dispatch, state)
}

export async function refreshRHSSOToken ({ keycloak: kc, dispatch, state }) {
  const msgError = 'Error refreshing token on rhsso!'
  const expiredProps = { kc, dispatch, state }
  let isRefreshed

  // Refresh token block
  try {
    isRefreshed = await kc.updateToken(keycloakTokenExpiration.REFRESH_TOKEN)

    if (isRefreshed) {
      console.log('yipeeeee I refreshed', isRefreshed)
      const payload = {
        accessToken: kc.token,
        refreshToken: kc.refreshToken,
      }
      dispatch({
        type: RECEIVE_TOKEN,
        payload,
      })
    } else {
      console.error(msgError)
      handleExpiredSession(expiredProps)
    }
  } catch (error) {
    console.error(msgError, error)
    handleExpiredSession(expiredProps)
    isRefreshed = false
  }

  return isRefreshed
}
