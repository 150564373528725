import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadBankAccountsTemplate, download, importBankAccounts } from 'redux/actions/employeeSystemUsers'
import SectionHeading from 'components/SectionHeading'

const TemplateClarifications = () => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading text='Create and/or update employees‘ bank details in bulk' />
    <p className='u-margin-bottom-small'>With this template you can create and/ or update employees’ Bank Details in bulk.</p>
    <ul className='margin-bottom-small'>
      <li>Select company and payroll and download template</li>
      <li>Complete data</li>
      <li>
        Columns A-F <b>cannot be updated</b> - informational purposes only
      </li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
    </ul>
    <u>Error message</u>: This happens when there are errors in the upload file. You can view details on the import page. Fix the errors (you can work both with
    original or error file) and attempt the upload again.
  </div>
)

const RouteView = (props) => (
  <ImportFacade
    {...props}
    heading='Import bank accounts'
    entityName='bank accounts'
    TemplateClarifications={TemplateClarifications}
    downloadTemplateAction={({ payrollId, companyId, shouldUseCompanyAsFilter }) => {
      if (!shouldUseCompanyAsFilter) companyId = null

      return downloadBankAccountsTemplate(payrollId, companyId)
    }}
    downloadAction={download}
    importAction={({ payrollId, companyId, shouldUseCompanyAsFilter, file }) => {
      if (!shouldUseCompanyAsFilter) companyId = null

      return importBankAccounts(payrollId, companyId, file)
    }}
    shouldIncludeAllPayrollsOption
  >
    <div className='o-layout'>
      <div className='o-layout__item u-1/1 u-text--normal'>
        <SectionHeading text='Data validations (all are case-insensitive):' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>Replace or new (R/N)</span> - R, N, Replace, New. If no value is provided, a new primary bank account is created.
          If there is already 1, it is updated. In case there is more than one, the existing primary bank account is updated.
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>Currency</span> - abbreviation of the currency (EUR, USD, etc.)
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>Bank Account Country</span> - Name of the country
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>Country specific fields</span> - based on the combination between Country/Currency different validations are present
          for different columns. Those will be shown if the update is not successful.
        </p>
      </div>
    </div>
  </ImportFacade>
)

export default RouteView
