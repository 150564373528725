import { isAfter } from 'date-fns'

/**
 * Returns a date based on a maximum allowable date.
 * If `selectedDate` is after `maxDate`, returns `maxDate`. Otherwise, returns `selectedDate`.
 *
 * @param {Date | string} selectedDate - The date to be compared, which can be a Date object or a string.
 * @param {Date | string} maxDate - The maximum allowable date, which can be a Date object or a string.
 * @returns {Date} - `maxDate` if `selectedDate` is after `maxDate`, otherwise `selectedDate`.
 */
export const getDateBasedOnMaxDate = (selectedDate, maxDate) => {
  const selectedDateObj = typeof selectedDate === 'string' ? new Date(selectedDate) : selectedDate
  const maxDateObj = typeof maxDate === 'string' ? new Date(maxDate) : maxDate

  if (isAfter(selectedDateObj, maxDateObj)) {
    return maxDateObj
  }
  return selectedDateObj
}
