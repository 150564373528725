import React from 'react'
import { MagnifierSearchIcon } from './icons/magnifierSearchIcon'
import { placeholders } from 'utils/locales/common.en'

const NoResultsView = ({ className = '', message = placeholders.noResultsFound }) => {
  return (
    <div data-testid='no-results' className={`d-flex d-flex--column jc--center ai-center u-text--huge search-results-container ${className}`}>
      <MagnifierSearchIcon />
      <p className='search-results-text'>{message}</p>
    </div>
  )
}

export default NoResultsView
