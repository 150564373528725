import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import { updateEmployeeSystemUser, updateEmployeeUserSSO } from 'redux/actions/employeeSystemUsers'
import KeyPeopleForm from '../components/KeyPeopleForm'
import keyPeopleRoleTypes from 'redux/config/keyPeopleRoleTypes'
import { getKeyPersonAccessAreasInitials, getKeyPersonWithCountryWithBusinessUnit, getUser } from 'redux/selectors/employees'
import moment from 'moment'
import { fetchEmployeeCountryPivot } from 'redux/actions/employeeCountryPivot'
import { fetchAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { fetchCompanies } from 'routes/Companies/modules/actions'
import { resetPasswordSendEmail } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import { hasAccess, isCot, isPayrollAdmin, isPayrollAnalyst } from 'redux/selectors/auth'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import KeyPeopleFormHOC, { buildAccessArea } from './KeyPeopleFormHOC'
import { ROLE_EMPLOYEE } from 'redux/config/auth'
import { getCountriesByCompanies } from 'redux/selectors/country'
import { mapPropertyValueToBoolean } from 'utils/sanitizer'
import { isSelectedRoleTypeRestrictedToBeGO } from 'utils/employee'
import { booleanToStringActiveInactive } from 'redux/transformers/booleanTransformer'
import { getParentCompanyById } from 'redux/selectors/parentCompanies'
import { enableKeyUserSSOStrings } from 'redux/config/ssoAccessStrings'

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetUserPassword: (data) =>
      dispatch(resetPasswordSendEmail(data)).then(() => {
        dispatch(
          showMessage({
            body: 'A password reset link has been sent to the user’s email',
          })
        )
      }),
    handleSSOclick: (data) =>
      dispatch(updateEmployeeUserSSO(data)).then((res) => {
        if (res?.status) {
          let value = booleanToStringActiveInactive(res.isSSOEnabled)
          dispatch(change('keyPeopleEditForm', 'isSSOEnabled', value))
        }
        return res
      }),
  }
}

const mapStateToProps = (state, props) => {
  let employee = getKeyPersonWithCountryWithBusinessUnit(state, {
    userId: props.employeeId,
  })

  const isFieldDisabled = !hasAccess(state)(['EMPLOYEEUSER_EDIT'])
  const isMobilePhoneFieldDisabled = !isCot(state) && state.auth.userId === employee.id

  const readOnly = !!keyPeopleRoleTypes.find((roleType) => roleType.readOnlyType === employee.roleType)
  // In order to make an employee key person we have to
  // reset its role and choose one from the key roles.
  // In all other cases the initial role stays.
  let roleType = employee.roleType !== ROLE_EMPLOYEE ? employee.roleType : null
  if (readOnly) roleType = keyPeopleRoleTypes.find((roleType) => roleType.readOnlyType === employee.roleType && roleType.type).type
  const accessAreas = getKeyPersonAccessAreasInitials(state, { userId: props.employeeId })
  const { gtnReImport, unlockLockPayrunFile, accessChangeLogReport, payrollIntegrationsControlCenter, allPayrunDocuments, reportDesigner } =
    employee.specialRight

  const accessAreasCountries = getCountriesByCompanies(state, { companiesIds: accessAreas.companies }).map((item) => item.id)

  const selector = formValueSelector('keyPeopleEditForm')
  const values = selector(state, 'filrstname', 'roleType', 'readOnly', 'globalOwner')
  const user = !isCot(state) && getUser(state, { userId: state.auth.userId })

  const parentCompaniesData = state.parentCompanies.allIds.map((id) => state.parentCompanies?.byIds[id])
  const tenant = state.tenants.id ? getParentCompanyById(state, { parentCompanyId: state.tenants.id }) : parentCompaniesData[0]
  const { defaultSSOEnabledFor, isTenantSSOEnabled } = tenant
  const isEmployeeSelfServiceActive = employee.hasCredentials
  const showUserSSOField = isTenantSSOEnabled && isEmployeeSelfServiceActive && enableKeyUserSSOStrings.includes(defaultSSOEnabledFor)
  const showUserSSOButton = showUserSSOField && isCot(state)

  return {
    isEdit: true,
    showUserSSOField,
    showUserSSOButton,
    companyDisabledReason: 'Company can not be changed',
    hasCredentials: employee.hasCredentials,
    workEmail: employee.workEmail,
    isPayrollAnalyst: isPayrollAnalyst(state),
    isFieldDisabled,
    // The mobile phone of the logged user can be updated only from 'My settings'
    // When he is listed in the key people table, he could not update his phone
    // We check the logged user and the edited user ids and if they match the field is disabled
    // *Cot user is not listed in the key people table, but cot user id and key people id can match,
    // because they are coming from different entities, respectively 'cotusers' and 'employeeusers'
    isMobilePhoneFieldDisabled: isFieldDisabled || isMobilePhoneFieldDisabled,
    mobilePhoneDisabledReason: isMobilePhoneFieldDisabled ? 'Please go to settings' : '',
    receivesNotifications: employee.receivesNotifications,
    receivesEmailNotifications: employee.receivesEmailNotifications,
    selected: values,
    isUserGlobalOwner: user.globalOwner || false,
    disableSpecialRightsEdit: state.auth.userId === employee.id,
    isUserPayrollAdmin: isPayrollAdmin(state),
    gtnReImport,
    unlockLockPayrunFile,
    payrollIntegrationsControlCenter,
    allPayrunDocuments,
    isSSOEnabled: employee.isSSOEnabled,
    employeeId: employee.id,
    initialValues: {
      ...pick(employee, [
        'id',
        'employeeId',
        'firstname',
        'location',
        'surname',
        'title',
        'company',
        'position',
        'globalOwner',
        'workEmail',
        'officePhone',
        'mobilePhone',
        'skype',
        'gender',
        'isEmployee',
        'hasCredentials',
        'mode2fa',
        'isSSOEnabled',
        'username',
        'includeInCommunications',
      ]),
      dateOfBirth: employee.dateOfBirth ? moment(employee.dateOfBirth.date) : null,
      startDate: employee.startDate ? moment(employee.startDate.date) : null,
      businessUnit: employee.businessUnit.id,
      accessAreaCompanies: accessAreas.companies,
      accessAreaBUs: accessAreas.businessUnits,
      countries: accessAreasCountries,
      paymentCurrency: employee.paymentCurrency,
      roleType,
      readOnly,
      // `workflowNotifications` is only a FE field,
      // that represents and results in two BE fields when the data is send back to the BE
      workflowNotifications: JSON.stringify({
        receivesNotifications: employee.receivesNotifications,
        receivesEmailNotifications: employee.receivesEmailNotifications,
      }),
      gtnReImport,
      unlockLockPayrunFile,
      accessChangeLogReport,
      payrollIntegrationsControlCenter,
      allPayrunDocuments,
      reportDesigner,
      isSSOEnabled: booleanToStringActiveInactive(employee.isSSOEnabled),
    },
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isMobilePhoneFieldDisabled } = stateProps
  const { onEdit, dispatch } = ownProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: (data) => {
      const { accessAreaBUs, readOnly, workflowNotifications } = data
      const isSelectedRoleRestrictedToBeGO = isSelectedRoleTypeRestrictedToBeGO(data.roleType)

      const submittedRoleType = readOnly
        ? { roleType: keyPeopleRoleTypes.find((roleType) => roleType.type === data.roleType).readOnlyType }
        : { roleType: data.roleType }
      const accessChangeLogReport = mapPropertyValueToBoolean(data, 'accessChangeLogReport')
      const unlockLockPayrunFile = mapPropertyValueToBoolean(data, 'unlockLockPayrunFile')
      const gtnReImport = mapPropertyValueToBoolean(data, 'gtnReImport')
      const payrollIntegrationsControlCenter = mapPropertyValueToBoolean(data, 'payrollIntegrationsControlCenter')
      const allPayrunDocuments = mapPropertyValueToBoolean(data, 'allPayrunDocuments')
      const reportDesigner = mapPropertyValueToBoolean(data, 'reportDesigner')

      // If selected role is not allowed to be GO, then set GO to false
      if (isSelectedRoleRestrictedToBeGO) {
        data.globalOwner = false
      }

      let sanitized = {
        ...(!ownProps.isCot && data.globalOwner),
        ...omit(data, [
          'accessAreaCompanies',
          'accessAreaBUs',
          'readOnly',
          'roleType',
          'workflowNotifications',
          'isSSOEnabled',
          'accessChangeLogReport',
          'unlockLockPayrunFile',
          'gtnReImport',
          'payrollIntegrationsControlCenter',
          'allPayrunDocuments',
          `${!ownProps.isCot && 'globalOwner'}`,
        ]),
        accessAreas: buildAccessArea(accessAreaBUs),
        ...submittedRoleType,
        // `workflowNotifications` value results in 2 backend fields,
        // please refer to the field options for more details
        ...(workflowNotifications && JSON.parse(workflowNotifications)),
        mobilePhone: data.mobilePhone || null,
        // If selected role is not allowed to be GO, then set these properties to false
        specialRight: isSelectedRoleRestrictedToBeGO
          ? {
            accessChangeLogReport: false,
            unlockLockPayrunFile: false,
            gtnReImport: false,
            payrollIntegrationsControlCenter: false,
            allPayrunDocuments: false,
            reportDesigner: false,
          }
          : {
            ...accessChangeLogReport,
            ...unlockLockPayrunFile,
            ...gtnReImport,
            ...payrollIntegrationsControlCenter,
            ...allPayrunDocuments,
            ...reportDesigner,
          },
      }

      sanitized = isMobilePhoneFieldDisabled ? omit(sanitized, ['mobilePhone']) : sanitized

      return dispatch(
        updateEmployeeSystemUser(sanitized, {
          dateFields: ['dateOfBirth', 'startDate'],
          shouldFetch: false,
          shouldInvalidate: true,
        })
      ).then(() => {
        onEdit()
        dispatch(fetchAccessAreaPivot())
        dispatch(fetchEmployeeCountryPivot())
        dispatch(fetchCompanies())
      })
    },
  }
}

const form = 'keyPeopleEditForm'
const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(
  reduxForm({
    form,
  })(KeyPeopleForm)
)

export default KeyPeopleFormHOC(Component, form)
