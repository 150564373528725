import { isEmpty } from 'lodash'
import { normalizeNumber } from 'redux/transformers/amountsTransformer'
import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const ADDITIONAL_ITEMS_CREATE = 'ADDITIONAL_ITEMS_CREATE'
export const ADDITIONAL_ITEMS_FETCH = 'ADDITIONAL_ITEMS_FETCH'
export const ADDITIONAL_ITEMS_RECEIVE = 'ADDITIONAL_ITEMS_RECEIVE'
export const ADDITIONAL_ITEMS_DELETE = 'ADDITIONAL_ITEMS_DELETE'
export const ADDITIONAL_ITEMS_INVALIDATE = 'ADDITIONAL_ITEMS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  create: ADDITIONAL_ITEMS_CREATE,
  fetch: ADDITIONAL_ITEMS_FETCH,
  receive: ADDITIONAL_ITEMS_RECEIVE,
  delete: ADDITIONAL_ITEMS_DELETE,
  invalidate: ADDITIONAL_ITEMS_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'additionalItems', 'additionalitems', 'AdditionalItem')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchAdditionalItemsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchAdditionalItems = actionUtility.fetchEntities
export const deleteAdditionalItem = actionUtility.deleteEntity

const normalizeEntity = (
  { item, currentPayrunValue, previousPayrunValue, comment } = {
    item: '',
    currentPayrunValue: 0,
    previousPayrunValue: 0,
    comment: '',
  }
) => ({
  item,
  currentPayrunValue: normalizeNumber(currentPayrunValue),
  previousPayrunValue: normalizeNumber(previousPayrunValue),
  comment,
})

const normalizeErrors = (errors = {}) =>
  Object.keys(errors)?.reduce(
    (acc, errorId) => [
      ...acc,
      {
        id: parseInt(errorId, 10),
        ...errors[errorId],
      },
      {
        [errorId]: errors[errorId],
      },
    ],
    []
  )

export const createAdditionalItem =
  (payrollInstance, entity, options) =>
    (dispatch, getState, { api }) => {
      const dataToSave = {
        payrollInstance,
        ...normalizeEntity(entity),
      }
      return api.post(`additionalitems`, { payload: dataToSave }).then((res) => {
        const hasErrors = !isEmpty(res?.errors)
        return {
          hasErrors,
          data: res,
          hasCommonErrors: !isEmpty(res?.errorsCommon),
          errors: normalizeErrors({
            0: res?.errors,
          }),
        }
      })
    }

export const saveAdditionalItems = (data = []) => {
  return (dispatch, getState, { api }) => {
    const normalizedData = data.reduce(
      (acc, entity) => ({
        ...acc,
        [entity.id]: normalizeEntity(entity),
      }),
      {}
    )
    return api.patch(`additionalitems`, { payload: normalizedData }).then((res) => ({
      hasErrors: !isEmpty(res?.errors),
      data: res,
      hasCommonErrors: !isEmpty(res?.errorsCommon),
      errors: normalizeErrors(res?.errors),
    }))
  }
}
