import createSelector from 'utils/createSelector'
import countries from 'redux/config/allCountries'
import _ from 'lodash'

export const getCountryAbbr = (state, props) => props.bankCountry
export const getSelectedCurrency = (state, props) => parseInt(props.currency)

export const getBankAccountTypes = createSelector(
  ({ BankAccountType }) => {
    return BankAccountType.all()
      .toRefArray().map(type => ({
        ...type,
        country: (countries.find(country => country.code === type['country_abbr']))
      }))
  })

export const getFieldsByBankAccountCountryAbbrAndCurrency = createSelector(getCountryAbbr, getSelectedCurrency,
  ({ BankAccountType, Currency }, countryAbbr, currency) => {
    const currencies = Currency.all()
      .toRefArray()
      .map(c => ({
        ...c
      }))

    let selectedCurrency = currencies.find(c => c.id === currency)
    let selectedCurrencyAbbr = selectedCurrency.abbreviature
    return _.flatten(_getFieldsByBankAccountCountryAbbrAndCurrency(BankAccountType, countryAbbr, selectedCurrencyAbbr))
  })

export const getCurrenciesByBankAccountCountryAbbr = createSelector(getCountryAbbr,
  ({ BankAccountType, Currency }, countryAbbr) => {
    const currenciesByType = BankAccountType.filter(bankAccountType => bankAccountType['country_abbr'] === countryAbbr)
      .toModelArray().map(bankAccountType => bankAccountType.currency)
    const currencies = Currency.all()
      .filter(currency => currenciesByType.includes(currency.abbreviature))
      .toRefArray()
      .map(currency => ({
        ...currency
      }))

    return currencies
  })

/**
 * Get custom fields by bank account type
 *
 * @param {SessionBoundModel} BankAccountType
 * @param {String} type
 * @private
 */
export const _getFieldsByBankAccountCountryAbbrAndCurrency = (BankAccountType, countryAbbr, currencyAbbr) => BankAccountType
  .filter(bankAccountType => bankAccountType['country_abbr'] === countryAbbr && bankAccountType.currency === currencyAbbr)
  .toModelArray().map(bankAccountType => bankAccountType['custom_fields'])
