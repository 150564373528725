import React from 'react'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import Modal from 'components/Modal'
import DataProtectionPolicyEditContainer from '../containers/DataProtectionPolicyEditContainer'
import RevisionFieldsContainer from '../containers/RevisionFieldsContainer'
import PayslipTable from 'components/table/PayslipTable'

class DataProtectionPolicyList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      revisionId: null
    }
  }
  render () {
    const { data, revisions } = this.props
    const revisionsCount = revisions.length
    return <div>
      <div className='o-layout '>
        <Authorization permissions={['COMPANYDATAPROTECTIONFIELDREVISION_EDIT']}>
          <div className='o-layout--right o-layout__item u-1/1'>
            <div
              onClick={() => this.refs.editDataProtectionPolicyModal.showModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
              title='Edit'>
              <span className='icon icon--edit' />
            </div>
          </div>
        </Authorization>
      </div>

      <Modal
        ref='editDataProtectionPolicyModal'
        className='c-modal c-modal--half'
        modalHeading='Edit data protection policy'
      >
        <DataProtectionPolicyEditContainer data={data} onEdit={() => this.refs.editDataProtectionPolicyModal.hideModal()} {...this.props} />
      </Modal>

      <Modal
        ref='dpfRevisionPreviewModal'
        className='c-modal c-modal--half c-modal--overflow-y'
        modalHeading='Fields history'
      >
        <RevisionFieldsContainer revisionId={this.state.revisionId} {...this.props} />
      </Modal>
      <PayslipTable
        data={[
          {
            field: <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>Retention Period</div>
              <div className='u-text--normal o-layout__item u-2/3'>{ data.retentionPeriod }</div>
            </div>
          },
          {
            field: <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>Process</div>
              <div className='u-text--normal o-layout__item u-2/3'>{ data.process }</div>
            </div>
          },
          {
            field: <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>Trigger</div>
              <div className='u-text--normal o-layout__item u-2/3'>{ data.trigger }</div>
            </div>
          },
          {
            field: <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>Data owner in employer</div>
              <div className='u-text--normal o-layout__item u-2/3'>{ data.dataOwners.join(', ') }</div>
            </div>
          },
          {
            field: <div className='o-layout o-layout--middle'>
              <div className='u-text--normal o-layout__item u-1/3'>Data fields register</div>
              <div className='u-text--normal o-layout__item u-2/3'>{ revisions.map((rev, index) => {
                const postfix = revisionsCount !== index + 1 ? ', ' : ''
                return <span
                  key={rev.id}
                  className='u-cursor--pointer u-margin-bottom-tiny u-text--curious'
                  onClick={() => {
                    this.setState({ revisionId: rev.id })
                    this.refs.dpfRevisionPreviewModal.showModal()
                  }}>
                  {`${rev.label}${postfix}`}
                </span>
              }) }</div>
            </div>
          }
        ]}
        headings={[
          { accessor: 'field', Header: 'Data Protection Policy', isKey: true, classNames: 'text-center', disableSortBy: true, disableFilters: true }
        ]}
        wrapperClassName='u-margin-bottom u-1/1 table-text-full-length'
      />
    </div>
  }
}

DataProtectionPolicyList.propTypes = {
  data: PropTypes.object,
  revisions: PropTypes.array
}

export default DataProtectionPolicyList
