import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, change, getFormValues } from 'redux-form'
import { createFilter } from 'utils/redux/filter'
import { getFormFieldValue } from 'redux/selectors/form'
import { updatePayrollTask } from 'redux/actions/payrollTask'
import { fetchPayrollTaskOwners } from 'redux/actions/payrollTaskOwners'
import { fetchPayrollTaskOwnerScopes } from 'redux/actions/payrollTaskOwnerScopes'
import { getFilteredEmployees } from 'redux/selectors/employees'
import { getPayrollTask, getPayrollTasksOptionsByPayrollStepId,
  getReorderedPayrollTasksByPayrollStepId } from '../selectors/payrollTasksORM'
import { getFilteredBusinessUnitsWithKeyPeople } from 'redux/selectors/businessUnitsOwners'
import { getBusinessUnitsOwnersInitialValues } from '../selectors/businessUnitsOwners'
import { getPayrollProcessWithService } from '../selectors/payrollProcessesORM'

import Fetcher from 'containers/Fetcher'
import SingleEditForm from '../components/PayrollTask/SingleEditForm'
import SingleMoveableEditForm from '../components/PayrollTask/SingleMoveableEditForm'
import MultiEditForm from '../components/PayrollTask/MultiEditForm'
import MultiMoveableEditForm from '../components/PayrollTask/MultiMoveableEditForm'
import MultiOwners from '../components/PayrollTask/MultiOwners'

/**
 * PayrollTaskEditContainer
 *
 * The gotcha in this component is that we set `PayrollTask.active` value,
 * according to the Task's selected `owner` value.
 *
 * By convention - these logic should be represented in two different fields: `Owner` dropdown and `Active` radio.
 * But the Business requirement is to introduce a new `Owner` value - `owner-not-needed`, instead of having two fields.
 *
 * In short: a Task is active when (owner !== 'owner-not-needed').
 * And 'owner-not-needed' value can be selected, only if the Task is skippable.
 *
 * For more details, please refer to:
 * @link https://gitlab.com/dev-labs-bg/payslip/wikis/Tasks-new-fields
 */
class PayrollTaskEditContainer extends React.Component {
  /**
   * Render the appropriate PayrollTask form.
   *
   * In the beginning of the feature and project we had only 1 simple and straightforward PayrollTask.
   * But in the process of project evolution, we start to have different layouts, fields and logic in that form.
   * We tried to manage the complexity and the differences with flags, but having many flags and use-cases in one form
   * was hard to introduce new changes or changing the current behaviour behaviour, without breaking something.
   * At all - it was hard to follow and understand what the form does exactly.
   * Because of this, we move the flags here and we decided to split the form in several smaller forms.
   * Now, each form has only one responsibility and the flags drastically decreased.
   *
   * Please refer to each form, in order to understand their differences.
   * In short, depending on a few flags below, we determine:
   * - Can we update the Action owners, or we just show them as a static view?
   * - Are we updating a Single or Multiple Action owners?
   * - Can we update the Action position and name, or it's not allowed?
   */
  render () {
    const { isMulti, isMoveable, isStatic } = this.props

    // We just want to show the multi owners table.
    // It's a static view, without option for editing the owners.
    if (isStatic) return <MultiOwners {...this.props} />

    // If the action (task) has multiple owners
    if (isMulti) {
      // `isMoveable` means that the action is created on PayrollProcess level only,
      // i.e. it doesn't have a CompanyTask parent.
      if (isMoveable) {
        return <MultiMoveableEditForm {...this.props} />
      } else {
        return <MultiEditForm {...this.props} />
      }
    } else {
      // If the action is created on PayrollProcess level only,
      // i.e. it doesn't have a CompanyTask parent.
      // If so, we can change it's position.
      if (isMoveable) {
        return <SingleMoveableEditForm {...this.props} />
      } else {
        return <SingleEditForm {...this.props} />
      }
    }
  }
}

PayrollTaskEditContainer.propTypes = {
  payrollTaskId: PropTypes.number
}

const isTaskActive = owner => owner !== 'owner-not-needed'

/**
 * Convert owners as dropdown options
 * and optionally include the predefined `owner-not-needed` value
 *
 * @param owners
 * @param {bool} includeOwnerNotNeeded - Keep in mind that this option is only available on Payroll level,
 * i.e. `Owner not needed` can be selected only on Payroll Task.
 * For Payrun level Task it's not possible, because of the business logic decision.
 * @param {bool} isCOT - if so, then we return only `notNeededOption` as owner option
 * @param {bool} isPP (Payroll Partner) - if so, then we return only `notNeededOption` as owner option
 * @return {[*,*]}
 */
const getOwnerOptions = (owners, includeOwnerNotNeeded, isCOT, isPP) => {
  const notNeededOption = { value: 'owner-not-needed', label: 'Not needed' }

  if (isCOT || isPP) return [notNeededOption]

  const options = owners.map(owner => ({
    value: owner.id,
    label: owner.firstname + ' ' + owner.surname
  }))

  return includeOwnerNotNeeded ? [notNeededOption, ...options] : options
}

const mapDispatchToProps = (dispatch, { onSubmit, filters }) => ({
  onSubmit: (data, _, props) => dispatch((dispatch, getState) => {
    const active = isTaskActive(data.owner)
    const buOwnersData = getFilteredBusinessUnitsWithKeyPeople(getState(), { filter: filters.businessUnits.name })
    const sanitized = { ...data, owner: active ? data.owner : null, active }
    return dispatch(updatePayrollTask(sanitized, sanitized.id, props.initialValues, buOwnersData)).then(() => {
      dispatch(fetchPayrollTaskOwners())
      dispatch(fetchPayrollTaskOwnerScopes())
      onSubmit()
    })
  }),
  onOwnerChange: owner => {
    if (!isTaskActive(owner)) dispatch(change('payrollTaskEdit', 'deadline', null))
  }
})

const mapStateToProps = (state, props) => {
  const { payrollTaskId, filters } = props

  const payrollTaskRef = getPayrollTask(state, { payrollTaskId })
  const isSkippable = payrollTaskRef.extraData.isSkippable
  const isMoveable = payrollTaskRef.extraData.isMoveable
  const selectedOwner = getFormFieldValue(state, 'payrollTaskEdit', 'owner')
  const selectedTaskableType = getFormFieldValue(state, 'payrollTaskEdit', 'taskableType')

  const buOwnersData = getFilteredBusinessUnitsWithKeyPeople(state, { filter: filters.businessUnits.name })
  const buOwnersInitialValues = getBusinessUnitsOwnersInitialValues(state, { payrollTaskId })

  const isGlobalOwnerCOT = payrollTaskRef.globalOwner === 'Payslip'
  const isGlobalOwnerPP = payrollTaskRef.globalOwner === 'Payroll Partner'

  const {
    payrollStep : payrollStepId = payrollTaskRef.payrollStep,
    name = payrollTaskRef.name,
    position = payrollTaskRef.position
  } = getFormValues('payrollTaskEdit')(state) || {}

  return {
    payrollTaskName: payrollTaskRef.name,
    owners: getOwnerOptions(getFilteredEmployees(state, { filter: props.filters.employeeSystemUsers.name }),
      isSkippable, isGlobalOwnerCOT, isGlobalOwnerPP),
    tasksOptions: getPayrollTasksOptionsByPayrollStepId(state, { payrollStepId }),
    tasks: getReorderedPayrollTasksByPayrollStepId(state, { payrollStepId, payrollTaskId, name, position }),
    isMoveable,
    showOwnerField: payrollTaskRef.globalOwner !== 'Software',
    isMAOEnabled: !isGlobalOwnerCOT && !isGlobalOwnerPP,
    disableDeadline: !isTaskActive(selectedOwner),
    buOwnersData,
    isMulti: selectedTaskableType !== 'Company' && !isGlobalOwnerCOT,
    initialValues: {
      id: payrollTaskId,
      owner: payrollTaskRef.active ? payrollTaskRef.owner : 'owner-not-needed',
      deadline: payrollTaskRef.deadline,
      taskableType: payrollTaskRef.taskableType,
      companyId: payrollTaskRef.company,
      name: payrollTaskRef.name,
      // API doesn't accept `position` if the task is not moveable
      ...isMoveable && { position: payrollTaskRef.position },
      payrollStep: payrollTaskRef.payrollStep,
      ...buOwnersInitialValues
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollTaskEdit'
})(PayrollTaskEditContainer))

export default Fetcher(
  Fetcher(Container, [
    'payrollTask',
    'payrollTaskOwners',
    'payrollTaskOwnerScopes',
    'accessAreaPivot',
    {
      name: 'businessUnits',
      params: [{
        _computed: {
          filter: (state, { match: { params: { payrollProcessId } } }) => {
            const { service: { payroll } } = getPayrollProcessWithService(state, { payrollProcessId })

            return createFilter({ payroll })
          }
        }
      }]
    },
    {
      name: 'employeeSystemUsers',
      params: [{
        _computed: {
          filter: (state, props) => createFilter({
            isKeyPerson: 1,
            accessableCompanies: props.match.params.companyId
          })
        }
      }]
    }
  ]),
  [
    'payrollProcess',
    'payrollService'
  ])
