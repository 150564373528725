import React from 'react'
import { connect } from 'react-redux'
import UserActivationContainer from '../containers/UserActivationContainer'
import ResetPasswordConfirmationContainer from
  'routes/ResetPasswordConfirmation/containers/ResetPasswordConfirmationContainer'
import LoginWrapper from 'layouts/LoginWrapper'
import PropTypes from 'prop-types'

export const RouteView = ({ is2FAenabled, ...props }) => {
  return <>
    <LoginWrapper>
      {
        is2FAenabled
          ? <UserActivationContainer {...props} />
          : <ResetPasswordConfirmationContainer {...props} />
      }
    </LoginWrapper>
  </>
}

RouteView.propTypes = {
  is2FAenabled: PropTypes.bool
}

const mapStateToProps = state => ({
  is2FAenabled: state.config.is2FAenabled
})

export default connect(mapStateToProps)(RouteView)
