import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'
import RadioFields from 'components/form/RadioFields'
import { required } from 'utils/validations'
import TasksTable from './TasksTable'

const CreateForm = ({ tasks, globalOwnersOptions, tasksOptions, stepsOptions, ...rest }) => (
  <div>
    <Form showSubmitBtn {...rest}>
      <div className='o-layout u-text--center'>
        <div className='o-layout__item u-1/1'>
          <div className='o-grid o-grid--middle o-grid--center o-form-group'>
            <Field
              component={RadioFields}
              name='globalOwner'
              options={globalOwnersOptions}
              header='Type *'
              headerClassName='c-label o-block--inline-block u-margin-bottom-tiny'
              formGroupClassName='u-margin-none'
              validate={required}
            />
          </div>
        </div>
        <div className='o-layout__item u-1/1'>
          <Field
            name='name'
            label='Name *'
            labelClassName='c-label'
            component={InputField}
            type='text'
            validate={required}
          />
        </div>
        <div className='o-layout__item u-1/1'>
          <Field
            name='payrollStep'
            label='Select in which step: *'
            component={CustomSelectField}
            labelClassName='c-label'
            className='u-text--left'
            placeholder='Select'
            options={stepsOptions}
            validate={required}
          />
        </div>

        <div className='o-layout__item u-1/3'>
          <Field
            name='position'
            label='Select position: *'
            component={CustomSelectField}
            labelClassName='c-label'
            className='u-text--left'
            placeholder='Select'
            options={tasksOptions}
            validate={required}
          />
        </div>
        <div className='page--create-form o-layout__item u-2/3 u-margin-top'>
          <TasksTable tasks={tasks} />
        </div>
      </div>
    </Form>
  </div>
)

CreateForm.propTypes = {
  tasks: PropTypes.array.isRequired,
  tasksOptions: PropTypes.array.isRequired,
  globalOwnersOptions: PropTypes.array.isRequired,
  stepsOptions: PropTypes.array.isRequired
}

export default CreateForm
