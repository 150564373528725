import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_FETCH = 'PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_FETCH'
export const PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_RECEIVE = 'PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_RECEIVE'
export const PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_INVALIDATE = 'PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_FETCH,
  receive: PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_RECEIVE,
  invalidate: PAYROLL_INSTANCE_COUNTRY_TERMS_PIVOT_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceCountryTermsPivot', 'payrollinstancecountryterms', 'PayrollInstanceCompanyCountryTerm')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceCountryTermsPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceCountryTermsPivot = actionUtility.fetchEntities
export const invalidatePayrollInstanceCountryTermsPivot = actionUtility.invalidate
