import createSelector from 'utils/createSelector'
import { getFilteredIdsByFilter } from 'redux/selectors/payroll'
import { sortByName } from 'utils/strings'

export const getFilteredPayrolls = createSelector(getFilteredIdsByFilter, ({ Payroll }, filteredIds) => {
  return filteredIds
    .map((id) => {
      const payroll = Payroll.withId(id)

      return {
        ...payroll.ref,
      }
    })
    .sort(sortByName)
})
