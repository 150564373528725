import createSelector from 'utils/createSelector'
import { getPayrollInstanceId } from 'redux/selectors/filters'

export const getPayfile = createSelector(getPayrollInstanceId, ({ Payfile }, id) => {
  try {
    return { ...Payfile.withId(id).ref }
  } catch (e) {
    return {}
  }
})

export const getPayfileInitials = createSelector(getPayrollInstanceId, ({ Payfile }, id) => {
  return Payfile.withId(id).data.reduce((accumulator, row) => {
    Object.keys(row).forEach(field => {
      const fieldName = buildFieldName(row.id, field)
      accumulator[fieldName] = row[field]
    })

    return accumulator
  }, {})
})

export const buildFieldName = (PIEmployeeId, fieldName) => JSON.stringify({ PIEmployeeId, fieldName })
