import React from 'react'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

class AddVendorToServiceForm extends React.Component {
  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <Form {...this.props}>
      <div className='o-layout'>
        <div className='o-layout__item'>
          <div className='o-form-group'>
            <Field
              name='vendorCountryService'
              label='Vendor'
              component={CustomSelectField}
              type='number'
              labelClassName='c-label u-text--small'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              options={this.props.vendors.map(vendor => ({
                value: vendor.vendorCountryService.id,
                label: vendor.name
              }))}
            />
          </div>
        </div>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          >
            Save
          </button>
        </div>
      </div>
    </Form>
  }
}

AddVendorToServiceForm.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    vendorCountryService: PropTypes.shape({
      id: PropTypes.number
    }),
  }))
}

export default AddVendorToServiceForm
