import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const DEADLINES_FETCH = 'DEADLINES_FETCH'
export const DEADLINES_RECEIVE = 'DEADLINES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DEADLINES_FETCH,
  receive: DEADLINES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'deadlines', 'deadlines', 'Deadline')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDeadlinesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDeadlines = actionUtility.fetchEntities
