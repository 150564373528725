import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import InstanceRouteNew from '../InstanceNew'
import InstanceChanges from '../InstanceChanges'
import NotesRoute from '../Notes'
import { ALL_ROUTES } from 'configs/routes'

export default (store) => ({
  path : ALL_ROUTES.PAYRUNS.REOPENED,
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  renderChildrenOutSideTab: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ InstanceRouteNew(store), InstanceChanges(store), NotesRoute(store)
  ]
})
