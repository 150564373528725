import React from 'react'

/* eslint-disable max-len */
export const RetryBaseIcon = ({ className, children, title, ...props }) => (
  <div className={className} title={title}>
    <svg width='17' height='17' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.9327 10.2553C10.1555 11.3343 9.0185 12.0942 7.73047 12.3953C6.44243 12.6964 5.08992 12.5187 3.92142 11.8946C2.75292 11.2705 1.84692 10.2421 1.36986 8.99822C0.892799 7.75437 0.87673 6.37865 1.3246 5.12381C1.77248 3.86896 2.65421 2.81928 3.80781 2.16761C4.96141 1.51594 6.30939 1.30605 7.6041 1.57651C8.89881 1.84697 10.0533 2.5796 10.8554 3.63981C11.6576 4.70002 12.0535 6.01659 11.9705 7.34765M11.9705 7.34765L13 6.30919M11.9705 7.34765L10.941 6.30919'
        stroke='white'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

    {children}
  </div>
)
