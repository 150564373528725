import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { pull, uniq } from 'lodash'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'
import FilterButton from 'components/buttons/FilterButton'

class PredefinedRateForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // Predefined fx rates ids
      predefinedFxRateIds: [],
      showFilters: false
    }
  }

  /**
   * Select/Deselect table rows, when mode is checkbox
   * @param row
   * @param isSelected
   */
  onRowSelectCheckbox (row) {
    let rateIds = this.state.predefinedFxRateIds
    if (!row.isSelected) {
      rateIds.push(row.original.id)
      this.setState({ predefinedFxRateIds: rateIds })
    } else {
      pull(rateIds, row.original.id)
      this.setState({ predefinedFxRateIds: rateIds })
    }
  }

  /**
   * Select/Deselect table rows, when mode is radio
   * @param row
   * @param isSelected
   */
  onRowSelectRadio (row) {
    if (!row.isSelected) {
      this.setState({ predefinedFxRateIds: [row.original.id] })
    } else {
      this.setState({ predefinedFxRateIds: [] })
    }
  }

  /**
   * Set some of the rows as unselectable,
   * when there is already selected fx rate for this fromCurrency
   */
  setUnselectable (row) {
    const { fxRates } = this.props

    const selectedFromCurrency = uniq(
      this.state.predefinedFxRateIds.map(
        id => fxRates.find(rate => rate.id === id).fromCurrency.id))

    const disabledRates = fxRates
      ? fxRates
        .filter(rate => selectedFromCurrency.includes(rate.fromCurrency.id) &&
          !this.state.predefinedFxRateIds.includes(rate.id))
      : []

    return disabledRates.find(rate => rate.id === row.original.id)
  }

  render () {
    const {
      selected,
      currencies,
      fxRates,
      customRatesFieldsNames,
      setReportingCurrencyDetails,
      resetFormFields,
      isFieldDisabled,
      disabledReason,
      onHide,
      shouldShowCheckboxOptions
    } = this.props

    const { payrollCurrency, toCurrency } = selected
    const { predefinedFxRateIds } = this.state

    // We should enable selecting multiple predefined rates with checkboxes,
    // only in the case we have the ability to select multiple payrolls in the reporting filters.
    // TODO: However, here the check should be improved and it may be better to check the type of `selected.payroll`,
    //  but for now we're fixing an urgent bug on production and later we should rethink and improve the logic here.
    const isCheckbox = shouldShowCheckboxOptions

    const options = {
      noDataText: 'No predefined fx rates for this currency. Please add.',
    }

    const selectRowProp = {
      type: isCheckbox ? 'checkbox' : 'radio',
      clickToSelect: true,
      onClick: (row, isSelected) => isCheckbox
        ? this.onRowSelectCheckbox(row, isSelected)
        : this.onRowSelectRadio(row, isSelected),
      columnWidth: '40px',
      bgColor: '#b9adca',
      isUnselectable: isCheckbox ? (row) => this.setUnselectable(row) : []
    }

    return <div className='o-layout__item u-1/1' data-testid='predefinedRate-form'>
      <SectionHeading text='Predefined Rate' />
      <div className='c-panel u-margin-top u-margin-bottom u-bg--white border-1-valhalla rounded u-padding-small'>
        <Field
          name='toCurrency'
          label='Choose Currency'
          component={CustomSelectField}
          className='c-custom-select--transparent'
          labelClassName='c-label u-text--small u-text--curious'
          formGroupClassName='u-margin-none'
          placeholder='Select'
          disabled={isFieldDisabled}
          disabledReason={disabledReason}
          options={currencies.map(currency => ({
            value: currency.id,
            label: currency.abbreviature
          }))}
          onChanged={value => {
            this.setState({ predefinedFxRateIds: [] })
            const fieldsToReset = ['fxRate', 'rate', ...customRatesFieldsNames]
            resetFormFields(value, fieldsToReset)
          }}
          data-testid='toCurrency'
        />
      </div>
      {
        toCurrency &&
          <div>
            <div className='d-flex jc--space-between'>
              <p className='u-margin-bottom-none'>
                Please select fx rate for every payroll currency.
                If there is no predefined fx rates for some currency, please add.
              </p>
              <div>
                <FilterButton
                  className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right'
                  onClick={() => this.setState({ showFilters: !this.state.showFilters })} />
              </div>
            </div>
            <PayslipTable
              tableElementClassName='table--layout-auto'
              data={fxRates.map(fxRate => {
                return {
                  id: fxRate.id,
                  date: moment(fxRate.date.date).format('DD/MM/YYYY'),
                  fromCurrency: fxRate.fromCurrency.abbreviature,
                  toCurrency: fxRate.toCurrency.abbreviature,
                  rate: fxRate.rate,
                  notes: fxRate.notes
                }
              })}
              headings={[
                { accessor: 'date', Header: 'Date', },
                {
                  accessor: 'fromCurrency',
                  Header: 'From currency',
                },
                { accessor: 'toCurrency', Header: 'To currency', },
                {
                  accessor: 'rate',
                  Header: 'Rate',
                  dataFormatter: 'cellWithDataTestId',
                  formatExtraData: { testPrefix: 'fxRate' }
                },
                { accessor: 'notes', Header: 'Notes', disableSortBy: true, disableFilters: true }
              ]}
              wrapperClassName='u-margin-top-small u-margin-bottom u-1/1 react-bs-table--overflow-auto'
              trClassName='react-bs-table__cell--whitespace--normal'
              rowSelection={selectRowProp}
              showFilters={this.state.showFilters}
              options={options}
            />
          </div>
      }
      {
        // Show "Save" button if
        // - there is selected predefined fx rates for every Payroll Currencies
        // - there is one less selected predefined fx rate for every Payroll Currencies
        // when toCurrency is equal to one of the Payroll Currencies
        ((predefinedFxRateIds.length === payrollCurrency.length) || (payrollCurrency.includes(toCurrency) &&
        predefinedFxRateIds.length === payrollCurrency.length - 1)) &&
        <div className='u-text--center'>
          <div
            onClick={() => {
              setReportingCurrencyDetails(predefinedFxRateIds)
              onHide()
            }}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid='save-button'
          >
            Save
          </div>
        </div>
      }
    </div>
  }
}

PredefinedRateForm.propTypes = {
  selected: PropTypes.object,
  fxRates: PropTypes.array,
  customRatesFieldsNames: PropTypes.array,
  currencies: PropTypes.array,
  setReportingCurrencyDetails: PropTypes.func,
  resetFormFields: PropTypes.func,
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  onHide: PropTypes.func,
  shouldShowCheckboxOptions: PropTypes.bool
}

export default PredefinedRateForm
