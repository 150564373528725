/**
 * Get the string combination to display for pay and taxes on the employee page
 * If there is no local name or the local and global name are the same, will return the global name
 * Otherwise it will return the combination of both of them separated by a /
 *
 * @param {object} view
 * @returns string A display name to show for the given term
 */
export const getTermDisplayName = ({ localName, termName }) => {
  if (!localName) return termName
  if (localName.trim() === termName.trim()) return termName
  return `${termName} / ${localName}`
}
