import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'

export const ReactAvatar = ({ firstName, lastName, size = 60, textSizeRatio = 3, className = '', color = '#5292dd' }) => (
  <Avatar name={`${firstName} ${lastName}`} color={color} size={size} round title={' '} className={`c-avatar ${className}`} textSizeRatio={textSizeRatio} />
)

ReactAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  textSizeRatio: PropTypes.number,
}

export default ReactAvatar
