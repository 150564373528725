import React, { useRef, useState } from 'react'

import { FloatingArrow, arrow, autoUpdate, useFloating, shift, flip, offset, useHover, useInteractions } from '@floating-ui/react'

const DEFAULT_ARROW_PROPS = {
  width: 27,
  height: 18,
  fill: 'var(--ps-color-floater-fill)',
}

export const ArrowTooltip = ({ hoverTriggerElement, middleware, children, placement = 'top', arrowProps }) => {
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const defaultMiddleware = middleware || [offset(20), flip(), shift()]
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement,
    onOpenChange: setIsOpen,
    middleware: [
      ...defaultMiddleware,
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const hover = useHover(context, {
    delay: {
      open: 0,
      close: 400,
    },
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])
  const referenceProps = {
    ref: refs.setReference,
    className: 'reference',
    ...getReferenceProps(),
  }

  return (
    <>
      <div {...referenceProps}>{hoverTriggerElement}</div>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          {children}
          <FloatingArrow ref={arrowRef} context={context} {...DEFAULT_ARROW_PROPS} {...arrowProps} />
        </div>
      )}
    </>
  )
}
