import { isEmpty } from 'lodash'

export const additionalItemsStateTypes = Object.freeze({
  onFetchData: 'onFetchData',
  onToggleMode: 'onToggleMode',
  onEditData: 'onEditData',
  onHasErrors: 'onHasErrors',
  onResetState: 'onResetState',
})

export const additionalItemsMode = Object.freeze({
  add: 'add',
  edit: 'edit',
})

const getTableChangedData = (state, { rowIndex, columnId, value: rowValue }) => {
  const notFoundItemdIdex = -1
  const dataToStore = state.tableChangedData
  const originalItem = state.data[rowIndex]
  const savedItemIndex = dataToStore.findIndex(({ id }) => id === originalItem.id)
  const value = isEmpty(rowValue) ? '' : rowValue
  const originalItemValue = originalItem?.[columnId] || ''
  const isDirty = originalItemValue !== value

  if (savedItemIndex > notFoundItemdIdex) {
    dataToStore[savedItemIndex][columnId] = value
    dataToStore[savedItemIndex].isDirty = isDirty
  } else {
    dataToStore.push({
      ...originalItem,
      id: originalItem.id,
      [columnId]: value,
      isDirty,
    })
  }

  return dataToStore
}

export const ADDITIONAL_ITEMS_INITIAL_STATE = {
  data: undefined,
  isLoading: true,
  hasItems: false,
  mode: undefined,
  editableTableState: {},
  tableChangedData: [],
  needsRefetch: true,
  isDirty: false,
}

const getDummyAdditionalItem = () => ({
  id: 0,
  item: '',
  currentPayrunValue: '0',
  previousPayrunValue: '0',
  variance: undefined,
  comment: '',
  disableEdit: false,
  isNew: true,
})

export const additionalItemsStateReducer = (state, action) => {
  switch (action.type) {
  case additionalItemsStateTypes.onFetchData:
    const data = action.payload
    return {
      ...state,
      data,
      isLoading: false,
      needsRefetch: false,
      hasItems: data?.length > 0,
    }
  case additionalItemsStateTypes.onToggleMode:
    const mode = action.payload
    const inAddMode = mode === additionalItemsMode.add
    const inGlobalEditMode = mode === additionalItemsMode.edit
    const dataWithDummy = inGlobalEditMode ? state.data : [getDummyAdditionalItem(), ...state.data]
    return {
      ...state,
      mode,
      data: dataWithDummy,
      tableChangedData: [],
      editableTableState: mode
        ? {
          inGlobalEditMode,
          inAddMode,
          fieldErrors: [],
        }
        : undefined,
    }
  case additionalItemsStateTypes.onHasErrors:
    return {
      ...state,
      editableTableState: state.mode
        ? {
          ...state.editableTableState,
          fieldErrors: action.payload,
        }
        : undefined,
    }
  case additionalItemsStateTypes.onEditData:
    const tableChangedData = getTableChangedData(state, action.payload)
    return {
      ...state,
      tableChangedData: getTableChangedData(state, action.payload),
      isDirty: !isEmpty(tableChangedData?.filter((item) => item.isDirty)),
    }
  case additionalItemsStateTypes.onResetState:
    return {
      ...state,
      ...ADDITIONAL_ITEMS_INITIAL_STATE,
    }
  default:
    return state
  }
}
