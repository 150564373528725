import { createReducerMeta } from 'utils/redux'
import { EMPLOYEE_ATTENDANCE_PERIOD_FETCH,
  EMPLOYEE_ATTENDANCE_PERIOD_RECEIVE, EMPLOYEE_ATTENDANCE_PERIOD_INVALIDATE } from '../actions/employeeAttendancePeriods'

const actionTypes = {
  fetch: EMPLOYEE_ATTENDANCE_PERIOD_FETCH,
  receive: EMPLOYEE_ATTENDANCE_PERIOD_RECEIVE,
  invalidate: EMPLOYEE_ATTENDANCE_PERIOD_INVALIDATE
}

export default createReducerMeta(actionTypes)
