import React from 'react'
import Select from './Select'
import BaseField from '../BaseField'

const CustomSelectField = ({ input, passInputPropsToParent, ...rest }) => {
  // Instead of using `data-testid` in <Select /> (react-select) component,
  // we delegate `data-testid` usage to <BaseField />.
  // That's needed, because current `react-select` version doesn't support data props attaching.
  return <BaseField {... passInputPropsToParent ? { input } : {}} useTestProp {...rest} >
    <Select {...input} {...rest} />
  </BaseField>
}

export default CustomSelectField
