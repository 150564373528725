import { ActionUtility } from 'utils/redux'
import _ from 'lodash'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { createFilter } from 'utils/redux/filter'

// ------------------------------------
// Constants
// ------------------------------------
export const VENDOR_USER_FETCH = 'VENDOR_USER_FETCH'
export const VENDOR_USER_RECEIVE = 'VENDOR_USER_RECEIVE'
export const VENDOR_USER_UPDATE = 'VENDOR_USER_UPDATE'
export const VENDOR_USER_CREATE = 'VENDOR_USER_CREATE'
export const VENDOR_USER_DELETE = 'VENDOR_USER_DELETE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: VENDOR_USER_FETCH,
  receive: VENDOR_USER_RECEIVE,
  update: VENDOR_USER_UPDATE,
  create: VENDOR_USER_CREATE,
  delete: VENDOR_USER_DELETE,
}
const actionUtility = new ActionUtility(actionTypes, 'vendorUsers', 'vendorusers', 'VendorUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchVendorUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchVendorUsers = actionUtility.fetchEntities
export const createVendorUser = actionUtility.createEntity
export const updateVendorUser = actionUtility.updateEntity
export const deleteVendorUser = actionUtility.deleteEntity

export const updateVendorUserPhone = (entity) => {
  let data = _.omit(entity, ['id'])

  return (dispatch) => {
    return dispatch(actionUtility.updateEntity(data, entity.id))
  }
}

// Update user SSO status
export const updateVendorUserSSO = (data) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.syncEntities({
        entity: { isSSOEnabled: data.isUserSSOEnabled },
        id: data.userId,
        childUri: `switchsso`,
        actionFunc: () => ({ type: 'UPDATE_USER_SSO' }),
      })
    )
      .then(errorsHandling.handleFormErrors)
      .then((res) => {
        dispatch(fetchVendorUsers({ filter: createFilter({ id: data.userId }) }))
        return { ...res, isVendorSSOEnabled: data.isUserSSOEnabled }
      })
      .catch((error) => {
        return error
      })
  }
}

export const updateVendorUserWithAAs = (data, id, handleData) => {
  return (dispatch, getState) => {
    const state = getState()
    return dispatch(actionUtility.updateEntity(handleData(id, state, data), id)).catch(errorsHandling.handleFormErrors)
  }
}
