import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyDataProtectionFieldsRevision'

class CompanyDataProtectionFieldsRevision extends CRUDModel {}
CompanyDataProtectionFieldsRevision.modelName = 'CompanyDataProtectionFieldsRevision'
CompanyDataProtectionFieldsRevision.actionTypes = actionTypes

CompanyDataProtectionFieldsRevision.fields = {
  id: attr(),
  modifiedBy: fk('EmployeeSystemUser', 'companyDataProtectionFieldsRevision')
}

export default CompanyDataProtectionFieldsRevision
