export const shareLabels = {
  disclaimer:
    'This document will only be sent externally if an SFTP is configured. Please exercise caution to ensure that you are sharing the correct ' +
    'information, and any personal data being shared is accurate and necessary for the intended purpose. Ensure documents do not contain any sensitive ' +
    'information, such as medical records, which are not permitted on Payslip.',
  sftpConfirmation: 'Are you sure you want to send this document to an external SFTP?',
  sftpConfirmationDisclaimer: 'Once sent externally, we cannot undo the action, delete the document or monitor its usage such as downloads or shares.',
}

export const shareTableHeadings = {
  location: 'Location',
  share: 'Share',
}
