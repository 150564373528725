import { createFilter } from 'utils/redux/filter'
import { buildFilterName, getFilterFromConfig } from './commonFilters'

export const prepareAttachDettachEmployeeFilters = ({ page = 0, limit, sort, filters, state, filterName } = {}) => {
  limit = limit !== undefined ? limit : state.employeeSystemUsers.filters[filterName].params.limit
  page = page !== undefined ? page : state.employeeSystemUsers.filters[filterName].params.offset / limit
  filters = filters !== undefined ? filters : state.employeeSystemUsers.filters[filterName] ? state.employeeSystemUsers.filters[filterName].params : null

  return createFilter(
    {
      ...filters,
      sort,
      offset: page * limit,
      limit,
      payloadFormat: 'reduced',
    },
    filterName
  )
}

export default ({ sort = [], limit = 20, filterKey }) => ({
  name: 'employeeSystemUsers',
  params: [
    {
      _computed: {
        filter: (state, { match: { params } }) => {
          const filterName = buildFilterName(params)[filterKey]
          const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName] && !!state.employeeSystemUsers.filters[filterName].ids

          if (isFetchedAtLeastOnce) return prepareAttachDettachEmployeeFilters({ state, filterName })

          const filters = getFilterFromConfig(filterKey, params)

          return prepareAttachDettachEmployeeFilters({
            page: 0,
            limit,
            filters,
            sort,
            state,
            filterName,
          })
        },
      },
      disableObsoleteFlow: true,
    },
  ],
  isForceFetching: true,
})
