import React, { useRef } from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import MessageModal from 'components/MessageModal'
import FieldEmployees from 'components/employees/FieldEmployees'
import ConfirmationModal from 'components/ConfirmationModal'
import CustomSelectField from 'components/form/select/CustomSelectField'
import Pagination from 'utils/helperClasses/Pagination'
import Loader from 'components/Loader'

const PayrollPaygroupEmployees = (props) => {
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const pagination = {
    currentPage: props.currentPage,
    totalPages: props.totalPages,
    hidePagination: props.hidePagination,
    pageNeighbours: 2,
  }

  const confirmModalRef = useRef()
  const messageModalRef = useRef()

  const getConfirmMessage = (content) => {
    const { employeesWithDifferTerms } = props

    // show panel only if there are employees with different terms
    if (employeesWithDifferTerms && employeesWithDifferTerms.length) {
      return (
        <div>
          <p>Fixed Pay Element values are different, the paygroup ones will overwrite the employee ones.</p>
          <p>Do you agree to overwrite for the following employees?</p>

          <ul className='o-list-bare'>
            {employeesWithDifferTerms.map((employee, index) => (
              <li key={index} className='u-weight--medium'>
                {employee.getName()}
              </li>
            ))}
          </ul>
        </div>
      )
    }

    return <p>{content}</p>
  }

  const showModal = () => {
    if (props.hasEmployees()) {
      confirmModalRef.current.showModal()
    } else {
      messageModalRef.current.showModal()
    }
  }

  const checkValue = (value, option) => {
    const hasSelectedOption = option || false
    setConfirmButtonDisabled(!hasSelectedOption)
  }
  const {
    toggleAll,
    handleSubmit,
    payrollInstancePayPeriod,
    showPagination,
    employeeSystemUsers,
    isFetching,
    content,
    buttonName,
    areAllEmployeesSelected,
    ...rest
  } = props
  const isOnceOffPayroll = payrollInstancePayPeriod === 'Once off'

  const onSubmitClick = () => {
    setIsSubmitting(true)
    handleSubmit()
  }

  if (isFetching) return <div>Loading...</div>

  if (isSubmitting) return <Loader />
  if (!employeeSystemUsers.length) return <div>Currently, there are no employees</div>

  const paginationComponent = <Pagination pagination={pagination} filterResults={(page) => props.onPageChange(page)} />

  return (
    <span>
      <ConfirmationModal
        ref={confirmModalRef}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={onSubmitClick}
        confirmButtonText={isOnceOffPayroll ? 'Confirm' : 'Yes'}
        rejectButtonText={isOnceOffPayroll ? 'Cancel' : 'No'}
        disableConfirmButton={isOnceOffPayroll && confirmButtonDisabled}
      >
        {getConfirmMessage(content)}

        {isOnceOffPayroll && (
          <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small u-margin-large u-text--left'>
            <Field
              name='prePopulatedData'
              label=''
              component={CustomSelectField}
              className='c-custom-select--transparent'
              labelClassName='c-label u-text--left'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              options={[
                {
                  value: 'all',
                  label: 'Both Fixed Pay Elements and Recurring Deductions',
                },
                {
                  value: 'payElements',
                  label: 'Fixed Pay Elements only',
                },
                {
                  value: 'recurringDeductions',
                  label: 'Recurring Deductions only',
                },
                {
                  value: 'no',
                  label: 'None',
                },
              ]}
              data-testid='prepopulated-data-wrapper'
              onChange={checkValue}
            />
          </div>
        )}
      </ConfirmationModal>
      <MessageModal ref={messageModalRef} className='c-modal' modalHeading='Oops...' message='No employees selected. Please select an employee to continue' />
      <div className='o-grid o-grid--middle o-grid--center'>
        <button onClick={() => showModal()} className='c-btn c-btn--small c-btn--curious' data-testid='choose-single-button'>
          {buttonName}
        </button>
        <button
          onClick={() => {
            toggleAll(!areAllEmployeesSelected, employeeSystemUsers)
          }}
          className='c-btn c-btn--small c-btn--curious u-margin-left'
          data-testid='choose-all-button'
        >
          {areAllEmployeesSelected ? 'Deselect all' : 'Select all'}
        </button>
      </div>
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>{paginationComponent}</div>
      </div>
      <form>
        <FieldEmployees slicedEmployeeSystemUsers={employeeSystemUsers} {...rest} />
      </form>
      <div className='o-layout__item u-1/1 u-margin-top-small'>
        <div className='u-float--right'>{paginationComponent}</div>
      </div>
    </span>
  )
}

PayrollPaygroupEmployees.propTypes = {
  toggleAll: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hasEmployees: PropTypes.func,
  employeesWithDifferTerms: PropTypes.array,
  employeeSystemUsers: PropTypes.array,
  payrollInstancePayPeriod: PropTypes.string,
  showPagination: PropTypes.bool,
  buttonName: PropTypes.string,
  isFetching: PropTypes.bool,
  content: PropTypes.string,
}

export default PayrollPaygroupEmployees
