import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import AnnualSalaryList from '../components/AnnualSalaryList'
import { getAnnualSalariesByEmployee } from 'redux/selectors/annualSalaries'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'

const mapStateToProps = (state, props) => ({
  annualSalaries: getAnnualSalariesByEmployee(state, { employeeId: props.match.params.employeeId })
})

const Container = connect(mapStateToProps)(AnnualSalaryList)

export default Fetcher(Container, [ createEmployeeFilter({ name: 'annualSalaries' }) ])
