import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/reports'

class Report extends CRUDModel {}
Report.modelName = 'Report'
Report.actionTypes = actionTypes

Report.fields = {
  id: attr()
}

export default Report
