import { cotRolesLabels } from 'utils/locales/roles.en'

/*
  COT - ROLES
*/
export const ROLE_COT_ADMIN = 'ROLE_COT_ADMIN'
export const ROLE_COT = 'ROLE_COT'
export const ROLE_COT_BOT = 'ROLE_COT_BOT'
export const ROLE_COT_ENGINEER = 'ROLE_COT_ENGINEER'
export const ROLE_COT_OEM = 'ROLE_COT_OEM'

export const cotRoleTypes = [
  {
    name: cotRolesLabels.cot,
    type: ROLE_COT,
  },
  {
    name: cotRolesLabels.admin,
    type: ROLE_COT_ADMIN,
  },
  {
    name: cotRolesLabels.oem,
    type: ROLE_COT_OEM,
  },
  {
    name: cotRolesLabels.bot,
    type: ROLE_COT_BOT,
  },
  {
    name: cotRolesLabels.engineer,
    type: ROLE_COT_ENGINEER,
  },
]

export const cotUserRoleTypes = [
  {
    name: cotRolesLabels.cot,
    type: ROLE_COT,
  },
  {
    name: cotRolesLabels.bot,
    type: ROLE_COT_BOT,
  },
  {
    name: cotRolesLabels.engineer,
    type: ROLE_COT_ENGINEER,
  },
  {
    name: cotRolesLabels.oem,
    type: ROLE_COT_OEM,
  },
]

/*
  VENDOR - ROLES
*/
export const ROLE_VENDOR_ADMIN = 'ROLE_VENDOR_ADMIN'
export const ROLE_VENDOR = 'ROLE_VENDOR'

export const vendorRoleTypes = [
  {
    name: 'ICP Admin',
    type: ROLE_VENDOR_ADMIN,
  },
  {
    name: 'ICP',
    type: ROLE_VENDOR,
  },
]

/*
  EMPLOYEE - ROLES
*/
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE'

/*
  PAYROLL - ROLES
*/
export const ROLE_PAYROLL_ADMIN = 'ROLE_PAYROLL_ADMIN'
export const ROLE_PAYROLL_ADMIN_READ_ONLY = 'ROLE_PAYROLL_ADMIN_READ_ONLY'
export const ROLE_PAYROLL_ANALYST = 'ROLE_PAYROLL_ANALYST'
export const ROLE_PAYROLL_ANALYST_READ_ONLY = 'ROLE_PAYROLL_ANALYST_READ_ONLY'
export const ROLE_PAYROLL_DATA_ENTRY = 'ROLE_PAYROLL_DATA_ENTRY'
export const ROLE_PAYROLL_DATA_ENTRY_READ_ONLY = 'ROLE_PAYROLL_DATA_ENTRY_READ_ONLY'

/*
  FINANCE - ROLES
*/
export const ROLE_FINANCE_ADMIN = 'ROLE_FINANCE_ADMIN'
export const ROLE_FINANCE_ADMIN_READ_ONLY = 'ROLE_FINANCE_ADMIN_READ_ONLY'

/*
  ACCOUNTING - ROLES
*/
export const ROLE_ACCOUNTING = 'ROLE_ACCOUNTING'
export const ROLE_ACCOUNTING_READ_ONLY = 'ROLE_ACCOUNTING_READ_ONLY'
export const ROLE_CONTROLLING = 'ROLE_CONTROLLING'
export const ROLE_CONTROLLING_READ_ONLY = 'ROLE_CONTROLLING_READ_ONLY'
