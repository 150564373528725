export const tenantTypes = {
  COS: 'cos',
  COT: 'cot',
  VENDOR: 'vendor',
  TENANT: 'tenant',
}

export const resetPasswordMissingPhoneNumberMessage = {
  COT_AND_VENDOR: 'We are missing your phone number, please contact the Payslip Team to update your user profile.',
  TENANT: 'We are missing your phone number, please contact your employer to update your user profile.',
}
