import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import CountriesListContainer from '../containers/CountriesListContainer'

export const RouteView = props => {
  return <InnerWrapper>
    <CountriesListContainer {...props} />
  </InnerWrapper>
}

export default RouteView
