import { ActionUtility } from 'utils/redux'
import { dateTransformer } from 'redux/transformers/dateTransformer'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_PROCESS_FETCH = 'COMPANY_PROCESS_FETCH'
export const COMPANY_PROCESS_RECEIVE = 'COMPANY_PROCESS_RECEIVE'
export const COMPANY_PROCESS_UPDATE = 'COMPANY_PROCESS_UPDATE'
export const COMPANY_PROCESS_INVALIDATE = 'COMPANY_PROCESS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_PROCESS_FETCH,
  receive: COMPANY_PROCESS_RECEIVE,
  update: COMPANY_PROCESS_UPDATE,
  invalidate: COMPANY_PROCESS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'companyProcessPivot', 'companyprocesses', 'CompanyCountryProcess')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyProcessPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyProcessPivot = actionUtility.fetchEntities
export const invalidateCompanyProcessPivot = actionUtility.invalidate

export const updateCompanyProcessPivot = entity => {
  const data = dateTransformer(entity, ['keyEventDate'])
  return actionUtility.updateEntity(data, data.id)
}
