import React from 'react'

const PasswordStrengthBox = props => {
  const { showPasswordStrengthBox = false, isSelected, hasNumber, hasCapitalLetter, hasSpecialSymbol, hasMinimumLength, hasNoAdjacentRepeatingChars } = props

  const passwordRules = [
    {
      name: 'oneNumber',
      validation: hasNumber,
      label: 'Have One Number'
    },
    {
      name: 'oneCapitalLetter',
      validation: hasCapitalLetter,
      label: 'Have One capital letter'
    },
    {
      name: 'oneSpecialSymbol',
      validation: hasSpecialSymbol,
      label: 'Have One special symbol'
    },
    {
      name: 'tenCharacters',
      validation: hasMinimumLength,
      label: 'Have 10 characters minimum'
    },
    {
      name: 'noAdjacentRepeating',
      validation: hasNoAdjacentRepeatingChars,
      label: 'Not have repeating adjacent characters eg. AA'
    }
  ]

  return (
    showPasswordStrengthBox &&
    <div className={`password__strength ${isSelected ? 'active' : ''}`}>
      <span className='u-text--small'>
        <strong>Password must</strong>
      </span>
      <ul>
        {
          passwordRules.map(rule => (
            <li key={rule.name} className={`${rule.validation ? 'fulfilled' : ''}`}>
              {rule.label}
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default PasswordStrengthBox
