import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/annualSalaries'

class AnnualSalary extends CRUDModel {}
AnnualSalary.modelName = 'AnnualSalary'
AnnualSalary.actionTypes = actionTypes

AnnualSalary.fields = {
  id: attr(),
  addedBy: fk('EmployeeSystemUser', 'annualSalaries'),
  employee: fk('EmployeeSystemUser', 'annualSalariesValues'),
}

export default AnnualSalary
