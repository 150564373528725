import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import { updatePassword, updatePasswordAction, loginByTokens } from 'redux/actions/auth'
import ChangePasswordView from '../components/ChangePasswordView'
import { showMessage } from 'redux/actions/modal'

class ChangePasswordContainer extends React.Component {
  state = {
    hidePasswordInputs: true
  }

  onShowPasswordInputs = () => {
    this.setState({ hidePasswordInputs: !this.state.hidePasswordInputs })
  }

  componentDidMount = () => {
    const hasChangePasswordToken = this.props.initialValues.change_password_token
    // Redirect user to go to the `/login` route, if no changePasswordToken is provided
    if (hasChangePasswordToken === null) {
      this.props.history.push('/login')
    }
  }

  render () {
    return <ChangePasswordView {...this.props} onShowPasswordInputs={this.onShowPasswordInputs} hidePasswordInputs={this.state.hidePasswordInputs} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    dispatch,
    onSubmit: (data) => dispatch(updatePassword(data))
      .then((response) => {
        // After successful password change, we are redirecting the user to the '/home' route
        props.history.push('/home')
        if (!response.errors) {
          dispatch(showMessage({ body: 'Your password was changed successfully!' }))
          dispatch(updatePasswordAction({ username: null, changePasswordToken: null, showLegacyPasswordReason: false }))
          dispatch(loginByTokens(response))
        }
      })

      .catch((e) => {
        if (e && e.errors && e.errors.change_password_valid_until) {
          // After unsuccessful password change, due to token expire, we are redirecting the user to the '/login' route
          props.history.push('/login')
          // To make sure we are starting fresh, we are resetting these values
          dispatch(updatePasswordAction({
            username: null,
            changePasswordToken: null,
            showLegacyPasswordReason: false
          }))

          dispatch(showMessage({
            body: e.errors.change_password_valid_until
          }))
        } else {
          throw new SubmissionError(
            e.errors
          )
        }
      })
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      old: null,
      password: null,
      passwordConfirmation: null,
      username: state.auth.username,
      change_password_token: state.auth.changePasswordToken,
      showLegacyPasswordReason: state.auth.showLegacyPasswordReason
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'changePassword'
})(ChangePasswordContainer))
