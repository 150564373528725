import createSelector from 'utils/createSelector'

export const getCountries = createSelector(({ Country }) => {
  return Country
    .all()
    .toRefArray()
    .map(country => ({
      ...country
    }))
})
