import { ActionUtility } from 'utils/redux'
import { fetchPayrollInstanceTask } from 'redux/actions/payrollInstanceTask'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_STEP_FETCH = 'PAYROLL_INSTANCE_STEP_FETCH'
export const PAYROLL_INSTANCE_STEP_RECEIVE = 'PAYROLL_INSTANCE_STEP_RECEIVE'
export const PAYROLL_INSTANCE_STEP_TASKS_MERGE = 'PAYROLL_INSTANCE_STEP_TASKS_MERGE'
export const PAYROLL_INSTANCE_STEP_INVALIDATE = 'PAYROLL_INSTANCE_STEP_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_STEP_FETCH,
  receive: PAYROLL_INSTANCE_STEP_RECEIVE,
  mergeTasks: PAYROLL_INSTANCE_STEP_TASKS_MERGE,
  invalidate: PAYROLL_INSTANCE_STEP_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceStep', 'payrollinstancesteps', 'PayrollInstanceStep')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceStepIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceStep = actionUtility.fetchEntities
export const invalidatePayrollInstanceStep = actionUtility.invalidate

// a merge tasks action
const mergeTasksAction = entity => ({
  type: actionTypes.mergeTasks,
  payload: entity
})

/**
 * Send an api request to merge payroll instance tasks to payroll instance step
 */
export const mergeTasks = (tasks, stepId, shouldNormalize = true) => {
  let normalized = {}

  if (shouldNormalize) {
    _.forOwn(tasks, (status, key) => {
      const { id } = JSON.parse(key)

      normalized[id] = { status }
    })
  } else {
    normalized = tasks
  }

  return dispatch => {
    return dispatch(actionUtility.mergeEntities({
      entity: { data: normalized },
      id: stepId,
      childUri: 'payrollinstancetasks',
      actionFunc: mergeTasksAction,
      handleErrors: errorsHandling.handlePivotJSONNormalize
    })).then(() => {
      dispatch(fetchPayrollInstanceTask())
    })
  }
}
