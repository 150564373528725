import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from './table/PayslipTable'

function ContractorView (props) {
  const { EmployeeStatus, data: { contractorDetails }, defaultNestedColumns, editableOptions } = props
  return <>
    <div data-testid='contractor-view-details'>
      <div className='o-layout o-grid--middle o-grid--strech u-margin-top u-margin-bottom-small'>
        <div className='o-layout__item u-1/1 u-1/2@tablet'>
          <h3 className='u-text--normal u-margin-none' />
        </div>
      </div>

      <div className='o-layout'>
        <div className='o-layout__item u-1/1'>
          <PayslipTable
            data={contractorDetails}
            dynamicValues
            editableOptions={editableOptions}
            specialEmptyRowSpan={2}
            headings={[
              {
                accessor: 'name',
                Header: EmployeeStatus.sectionHeading,
                disableSortBy: true,
                disableFilters: true,
                classNames: 'u-text--center',
                isKey: true,
                columns: defaultNestedColumns
              }
            ]}
            wrapperClassName='u-margin-bottom u-1/1'
          />
        </div>
      </div>
    </div>
  </>
}

ContractorView.propTypes = {
  EmployeeStatus: PropTypes.object,
  defaultNestedColumns: PropTypes.bool,
  data: PropTypes.obj,
  editableOptions: PropTypes.obj
}

export default ContractorView
