import React from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { fetchTasksIfNeeded, createTask } from 'redux/actions/tasks'
import { fetchStepsIfNeeded, invalidateSteps } from 'redux/actions/steps'
import { fetchProcessesIfNeeded } from 'redux/actions/processes'
import { getProcesses } from 'redux/selectors/processes'
import { getFilteredSteps } from '../selectors/steps'
import { getTasks } from '../selectors/tasks'
import Tasks from '../components/Tasks'
import TaskCreate from '../components/TaskCreate'

class TasksContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { fetchStepsIfNeeded, fetchTasksIfNeeded, fetchProcessesIfNeeded } = this.props
    fetchStepsIfNeeded()
    fetchTasksIfNeeded()
    fetchProcessesIfNeeded()
  }

  render () {
    return <div>
      <TaskCreate {...this.props} />
      <Tasks {...this.props} />
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  fetchStepsIfNeeded: () => dispatch(fetchStepsIfNeeded()),
  fetchTasksIfNeeded: () => dispatch(fetchTasksIfNeeded()),
  fetchProcessesIfNeeded: () => dispatch(fetchProcessesIfNeeded()),
  onSubmit: data => dispatch(createTask(data)).then(() => {
    dispatch(invalidateSteps())
    dispatch(reset('task'))
  })
})

const mapStateToProps = (state) => ({
  processes: getProcesses(state),
  steps: getFilteredSteps(state),
  tasks: getTasks(state),
  owners: ['Payslip', 'Company', 'Payroll Partner', 'Software'],
  isFetching : state.tasks.isFetching || state.steps.isFetching || state.processes.isFetching
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:
 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })
 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer)
