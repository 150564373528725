import VendorCompaniesRoute from './routes/VendorCompanies'
import InnerWrapper from 'layouts/InnerWrapper'
import Invalidator from './components/Invalidator'
import CoreLayout from 'layouts/PageLayout/PageLayout'

export default (store) => ({
  path : ':tenantId',
  indexRoute: { component: InnerWrapper, wrappedComponent: Invalidator },
  wrappedComponent: CoreLayout,
  authProtected: true,
  childRoutes: [ VendorCompaniesRoute(store) ]
})
