import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  hasAccess,
  isCot,
  isCotAdmin,
  isPayrollAdmin,
  isPayrollAnalyst,
  isPayrollDataEntry,
  isFinanceAdmin,
  isAccounting,
  isControlling,
  isUser,
  isVendorInvolved,
  isKeyUser,
  isCotOem,
} from 'redux/selectors/auth'
import { isParentCompanyTenant, isVendorTenant } from 'redux/selectors/tenants'
import { isMultitenancyEnabled } from 'utils/config'
import { selectAside } from 'redux/actions/aside'
import { logout } from 'redux/actions/auth'
import Raven from 'redux/middlewares/raven'

import Aside from '../components/aside/Aside'
import AsideCos from '../components/aside/Aside_Cos'
import AsideCosAdmin from '../components/aside/Aside_Cos_Admin'
import AsideCosTenant from '../components/aside/Aside_Cos_Tenant'
import AsideCosVendor from '../components/aside/Aside_Cos_Vendor'
import AsideEmployee from '../components/aside/Aside_Employee'
import AsidePayrollAdmin from '../components/aside/Aside_Payroll_Admin'
import AsidePayrollAnalyst from '../components/aside/Aside_Payroll_Analyst'
import AsidePayrollDataEntry from '../components/aside/Aside_Payroll_Data_Entry'
import AsideFinanceAdmin from '../components/aside/Aside_Finance_Admin'
import AsideAccounting from '../components/aside/Aside_Accounting'
import AsideControlling from '../components/aside/Aside_Controlling'
import AsideVendor from '../components/aside/Aside_Vendor'
import PropTypes from 'prop-types'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'
import { getUser } from 'redux/selectors/employees'
import Fetcher from './Fetcher'
import { createFilter } from 'utils/redux/filter'
import { buildControlCenterUrl } from 'utils/url'
import { AsideCosOem } from 'components/aside/AsideCosOem'
import { getTenantSubdomain } from 'utils/subbranding'
import { getSubdomainBrandConfig } from 'components/BrandConfig'
import { getParsedDomain } from 'utils/query'

const AsideContainer = (props) => {
  const keycloak = useContext(KeycloakCTX)
  const {
    isCot,
    isCotAdmin,
    isUser,
    isVendor,
    isParentCompanyTenant,
    isVendorTenant,
    isPayrollAdmin,
    isPayrollAnalyst,
    isFinanceAdmin,
    isPayrollDataEntry,
    isAccounting,
    isControlling,
    isCotOemUser,
  } = props

  const monitorUserRolesBug = ({ auth, logout }) => {
    // We have the following bug and we want to track it to Sentry, in order to have more details for it.
    // For some reasons, there is a use-case, where the authenticated user doesn't have roles,
    // i.e. `state.auth.roles = []`. That bug - will break our UI and its logic.
    // Unfortunately we didn't manage to reproduce it.
    // Because of this we are trying to catch it here and log it in Sentry.
    // Also if the bug occurs - we will `logout` the user,
    // because the app can't work correctly without knowing the user roles.
    if (!auth.roles.length) {
      // Capture the bug
      Raven.captureException(`BUG CAPTURING: The authenticated user, does NOT have a role. Permissions are: ${auth.isFetchingPermissions}`)

      // Logout the user, because the app will be inconsistent
      logout(keycloak)
    }
  }

  useEffect(() => {
    monitorUserRolesBug(props)
  }, [props])

  if (!isMultitenancyEnabled) return <Aside {...props} />

  if (isParentCompanyTenant) return <AsideCosTenant {...props} />

  if (isVendorTenant) return <AsideCosVendor {...props} />

  if (isCotOemUser) return <AsideCosOem {...props} />

  if (isCotAdmin) return <AsideCosAdmin {...props} />

  if (isCot) return <AsideCos {...props} />

  if (isPayrollAdmin) return <AsidePayrollAdmin {...props} />

  if (isPayrollAnalyst) return <AsidePayrollAnalyst {...props} />

  if (isFinanceAdmin) return <AsideFinanceAdmin {...props} />

  if (isPayrollDataEntry) return <AsidePayrollDataEntry {...props} />

  if (isAccounting) return <AsideAccounting {...props} />

  if (isControlling) return <AsideControlling {...props} />

  if (isUser) return <AsideEmployee {...props} />

  if (isVendor) return <AsideVendor {...props} />

  return null
}

const mapDispatchToProps = (dispatch, state) => ({
  selectAside: (mode) => dispatch(selectAside(mode)),
  logout: (keycloak) => dispatch(logout(keycloak)),
})

const mapStateToProps = (state) => {
  const user = !isCot(state) && isKeyUser(state) && getUser(state, { userId: state.auth.userId })
  const brandData = getSubdomainBrandConfig(state.config.brands, getTenantSubdomain(getParsedDomain(state.tenants.url)))
  return {
    isCot: isCot(state),
    isCotAdmin: isCotAdmin(state),
    isCotOemUser: isCotOem(state),
    isUser: isUser(state),
    isVendor: isVendorInvolved(state),
    isParentCompanyTenant: isParentCompanyTenant(state),
    isVendorTenant: isVendorTenant(state),
    isPayrollAdmin: isPayrollAdmin(state),
    isPayrollAnalyst: isPayrollAnalyst(state),
    isPayrollDataEntry: isPayrollDataEntry(state),
    isFinanceAdmin: isFinanceAdmin(state),
    isAccounting: isAccounting(state),
    isControlling: isControlling(state),
    userId: state.auth.userId,
    asideMode: state.aside.collapsed,
    hasAccess: hasAccess(state),
    auth: state.auth,
    userHasAccessToPICC: isCot(state) || user?.globalOwner || user?.specialRight.payrollIntegrationsControlCenter,
    controlCenterUrl: buildControlCenterUrl(state),
    hasAccessToResources: brandData?.resourcesMenu !== false,
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(AsideContainer)

const KeyUserFetcher = Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [
      {
        _computed: { filter: (state, props) => createFilter({ id: state.auth.userId }) },
      },
    ],
  },
])

export default (props) => {
  // if a key user is logged in, we have to make a call to the api/v1/employeesystemusers endpoint to get that users special rights
  // for cot and vendor subdomain such endpoint doesn't exist, so we avoid making the call by returning only the Container component and avoiding Fetcher
  const isKeyUserLoggedIn = useSelector((state) => isKeyUser(state))
  if (isKeyUserLoggedIn) return <KeyUserFetcher {...props} />
  return <Container {...props} />
}

AsideContainer.propTypes = {
  isCot: PropTypes.bool,
  isCotAdmin: PropTypes.bool,
  isUser: PropTypes.bool,
  isVendor: PropTypes.bool,
  isParentCompanyTenant: PropTypes.bool,
  isVendorTenant: PropTypes.bool,
  isPayrollAdmin: PropTypes.bool,
  isPayrollAnalyst: PropTypes.bool,
  isFinanceAdmin: PropTypes.bool,
  isPayrollDataEntry: PropTypes.bool,
  isAccounting: PropTypes.bool,
  isControlling: PropTypes.bool,
  hasAccessToResources: PropTypes.bool,
}
