import { ActionUtility } from 'utils/redux'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const EVENTS_FETCH = 'EVENTS_FETCH'
export const EVENTS_RECEIVE = 'EVENTS_RECEIVE'
export const EVENTS_FILTER = 'EVENTS_FILTER'
export const EVENTS_FILTERED = 'EVENTS_FILTERED'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EVENTS_FETCH,
  receive: EVENTS_RECEIVE,
  filter: EVENTS_FILTER,
  filtered: EVENTS_FILTERED
}
const actionUtility = new ActionUtility(actionTypes, 'events', 'events', 'Event')

export const filterAction = (ids) => ({
  type: EVENTS_FILTERED,
  payload: { ids }
})

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEventsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEvents = actionUtility.fetchEntities

export const filterEvents = filters => {
  let defaultFilters = {
    ...filters,
    notInactive: true
  }
  let sanitized = _.omitBy(defaultFilters, _.isNull)
  return actionUtility.filterEntity({ filters: sanitized })
}
