import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import SubCategoriesTable from '../components/SubCategoriesTable'
import { getSubCategoriesWithCategoryName } from '../selectors/termSubCategories'

const mapStateToProps = (state) => {
  return {
    termSubCategories: getSubCategoriesWithCategoryName(state)
  }
}

const Container = connect(mapStateToProps)(SubCategoriesTable)

export default Fetcher(Container, ['termSubCategories', 'termCategories'])
