import React, { useEffect, forwardRef, useRef } from 'react'

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <React.Fragment>
      <input type='checkbox' ref={resolvedRef} {...rest} />
    </React.Fragment>
  )
})

const IndeterminateRadio = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])
  return (
    <React.Fragment>
      <input type='radio' ref={resolvedRef} {...rest} />
    </React.Fragment>
  )
})

const TableSelectionItem = ({ options, row, toggleAllRowsSelected }) => {
  let selection = null
  const onClickHandler = () => {
    const isSelected = !row?.isSelected
    if (options.type === 'radio') toggleAllRowsSelected(false)
    row?.toggleRowSelected(isSelected)
    options.onClick(row, isSelected)
  }

  switch (options.type) {
  case 'radio':
    selection = <IndeterminateRadio {...row.getToggleRowSelectedProps()} onClick={onClickHandler} />
    break
  case 'checkbox':
    selection = <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={onClickHandler} disabled={options.isUnselectable(row)} />
    break
  default:
    selection = <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={onClickHandler} disabled={options.isUnselectable(row)} />
    break
  }

  return selection
}

export const buildSelectionHooks = (rowSelection) => {
  const selectionOpts = rowSelection?.opts
  const cellSelection = selectionOpts?.cell
  const id = cellSelection?.accessor || 'selection'
  return {
    ...cellSelection,
    id,
    Cell: ({ row, toggleAllRowsSelected }) => {
      row.selectionOpts = selectionOpts
      const labelKey = selectionOpts?.labelKey
      const label = row?.original[labelKey]

      return (
        <div className='d-flex ai-center'>
          <TableSelectionItem row={row} options={rowSelection} toggleAllRowsSelected={toggleAllRowsSelected} />
          {label && <span className='ml-3'>{label}</span>}
        </div>
      )
    },
  }
}
