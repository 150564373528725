import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

let classNames = require('classnames')

class EmployeeMobileNavItem extends React.Component {
  render () {
    const {
      path,
      isLink = true,
      title,
      children,
      onClick,
    } = this.props.item

    const classes = classNames({
      'o-list-bare': true,
    })

    return (
      <li className={classes}>
        <Link
          to={isLink ? path : null}
          onClick={onClick}
          activeClassName='active'
          className='o-block o-block--left'
        >
          <span className='nav-label'>{title}</span>
        </Link>
        {children}
      </li>
    )
  }
}

EmployeeMobileNavItem.contextTypes = { router: PropTypes.object }

export default EmployeeMobileNavItem
