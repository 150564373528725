import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/dataProtectionFields'

class DataProtectionField extends CRUDModel {}
DataProtectionField.modelName = 'DataProtectionField'
DataProtectionField.actionTypes = actionTypes

DataProtectionField.fields = {
  id: attr()
}

export default DataProtectionField
