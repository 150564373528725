import { useLocation } from 'react-router'

// the query string for you.
// @returns URLSearchParams

/**
 * Method to parse out query strings from a location API object
 *
 * Retrieve the query param of choice using the following class method
 * get('payrollInstance')
 * @param {object} location
 *
 * @returns {class} URLSearchParams
 */
export default function useQuery (location = useLocation()) {
  return new URLSearchParams(location.search)
}
