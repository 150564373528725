import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadTemplate, importAccountsCreate, download } from 'redux/actions/generalLedgers'

const TemplateClarifications = () => <div className='u-margin-bottom-small u-text--normal'>
  <SectionHeading text='Create multiple accounts' />
  <p className='u-margin-bottom-small'>This template allows you to create multiple accounts in bulk.
    Follow the simple process described below:</p>

  <ul className='u-margin-bottom-small'>
    <li>Download template</li>
    <li>Complete data</li>
    <li>Import the completed template using Import button</li>
    <li>Wait for confirmation message</li>
  </ul>
</div>

export default props => <ImportFacade
  {...props}
  heading='Import accounts'
  entityName='generalLedgers'
  downloadAction={download}
  downloadTemplateAction={() => downloadTemplate()}
  importAction={({ file }) => importAccountsCreate(file)}
  TemplateClarifications={TemplateClarifications}
  hasFilters={false}
>
  <div className='o-layout'>
    <div className='o-layout__item u-1/2 u-text--normal'>
      <SectionHeading text='Required fields:' />
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Account Number</span> - Field must be numeric
      </p>
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Account Type</span> - Expense, Liability, Expense/Liability
      </p>
    </div>

    <div className='o-layout__item u-1/2 u-text--normal'>
      <SectionHeading fullWidth text='Other data validations (all are case-insensitive):' />
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Account Description</span> - Free text
      </p>
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Status</span> - Active, Inactive. If a value is not populated the account will be defaulted to ‘Active’
      </p>
    </div>
  </div>
</ImportFacade>
