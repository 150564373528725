import React from 'react'
import PropTypes from 'prop-types'
import { formatNumberToCurrency } from 'utils/number'

const FinanceDisplayCard = ({
  amount = 0,
  icon = '',
  subtitle = '',
  fallback = null,
  currency = null,
  hasDisclaimer = false
}) => {
  return (
    <div className='c-display-cards shadow rounded border u-padding u-padding-bottom-large'>
      <div className='d-flex jc--flex-end u-margin-bottom-neg-small' style={{ height: '23px' }}>
        <span>{ currency }</span>
      </div>
      <div className='d-flex jc--space-between'>
        <div className='rounded-full' style={{ height: '80px', width: '120px' }}>
          <span className={`icon--${icon} u-margin-top-neg-25 u-margin-left-neg-small`} style={{ width: '100px' }} />
        </div>
        <div className='u-margin-left-small u-margin-top-small'>
          {!fallback && <div className='c-display-cards-amount u-text--right u-margin-bottom'>
            {formatNumberToCurrency(amount, true)}
          </div>
          }
          {hasDisclaimer && !fallback &&
          <div className='d-flex'>
            <span className='icon--report-disclaimer-warning u-padding-right' />
            <span className='u-text--tiny u-margin-bottom-small'>{ subtitle } are displayed only when your ICP provides the data</span>
          </div>
          }
          {fallback && <div className='c-display-cards-fallback u-text--normal u-weight--regular u-margin-bottom-small'>{ fallback }</div>}
        </div>
      </div>
      <div className='c-display-cards-subtitle'>{ subtitle }</div>
    </div>
  )
}

FinanceDisplayCard.propTypes = {
  amount: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  currency: PropTypes.string,
  hasDisclaimer: PropTypes.bool
}

export default FinanceDisplayCard
