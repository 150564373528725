import createSelector from 'utils/createSelector'
import { getLoggedInUserId, isCot } from 'redux/selectors/auth'
import _ from 'lodash'
import { flatten } from 'utils/array'
import { tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import { statuses } from 'redux/config/payrollInstanceTasks'

const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId, 10)
const getCompanyId = (state, props) => parseInt(props.companyId, 10)
const getTenant = (state, props) => props.tenant
const getPayrollId = (state, props) => parseInt(props.payrollId, 10)

/**
 * Status as array, which contains one or n number of statuses
 *
 * @param state
 * @param props
 */
const status = (state, props) => props.status

const _getPreviousPayrollInstanceId = (instance, Payroll) => {
  const allPayrollInstances = Payroll.withId(instance.payroll.id).payrollInstances
  const completedPayrollInstances = allPayrollInstances.filter((i) => i.status === 'completed').toRefArray()
  const index = completedPayrollInstances.findIndex((i) => i.id === instance.id)
  return index > 0 ? completedPayrollInstances[index - 1].id : null
}

/**
 * Get payrollinstances by status
 */
export const getPayrollInstancesByStatus = createSelector(
  status,
  getTenant,
  getLoggedInUserId,
  ({ PayrollInstance, Payroll }, statuses, tenant, loggedUserId) => {
    return PayrollInstance.filter((instance) => instance.isAccessable && statuses.includes(instance.status))
      .toModelArray()
      .map((instance) => _getPayrollInstance(instance, Payroll, tenant, loggedUserId))
      .reverse()
  }
)

/**
 * Get payrollinstances
 */
export const getPayrollInstances = createSelector(getTenant, getLoggedInUserId, ({ PayrollInstance, Payroll }, tenant, loggedUserId) => {
  return PayrollInstance.filter((instance) => instance.isAccessable)
    .toModelArray()
    .map((instance) => _getPayrollInstance(instance, Payroll, tenant, loggedUserId))
    .reverse()
})

/**
 * Get payrollinstances by payroll Id, reversed order
 */
export const getPayrollInstancesByPayrollId = createSelector(
  getTenant,
  getLoggedInUserId,
  getPayrollId,
  ({ PayrollInstance, Payroll }, tenant, loggedUserId, payrollId) => {
    return PayrollInstance.filter((instance) => instance.isAccessable && instance.payrollId === payrollId)
      .toModelArray()
      .map((instance) => _getPayrollInstance(instance, Payroll, tenant, loggedUserId))
      .reverse()
  }
)

/**
 * Get payroll instances by status without color
 * TODO - think of a better naming
 */
export const getCleanPayrollInstancesByStatus = createSelector(
  status,
  getTenant,
  getLoggedInUserId,
  ({ PayrollInstance, Payroll }, statuses, tenant, loggedUserId) => {
    return PayrollInstance.filter((instance) => instance.isAccessable && statuses.includes(instance.status))
      .toModelArray()
      .map((instance) => {
        // Please have in mind that prevPayrollInstanceId usage was removed, but we leave it for now
        const prevPayrollInstanceId = _getPreviousPayrollInstanceId(instance, Payroll)

        return {
          ...instance.ref,
          isLastAddedNoteFromOtherUser: instance.isLastAddedNoteFromOtherUser(tenant, loggedUserId),
          payroll: instance.payroll.ref,
          payrollInstanceServices: instance.payrollInstanceServices.toModelArray().map((service) => ({
            ...service.ref,
            payrollInstanceProcesses: service.payrollInstanceProcesses.toModelArray().map((process) => ({
              ...process.ref,
              payrollInstanceSteps: process.payrollInstanceSteps.toModelArray().map((step) => ({
                ...step.ref,
                payrollInstanceTasks: step.payrollInstanceTasks.toModelArray().map((task) => ({
                  ...task.ref,
                  payrollTask: { ...task.payrollTask.ref },
                })),
              })),
            })),
          })),
          companyName: instance.payroll.company.name,
          countryAbbr: instance.payroll.company.country.abbreviature,
          countryName: instance.payroll.company.country.name,
          countryId: instance.payroll.company.country.id,
          shortName: instance.payroll.shortName,
          prevPayrollInstanceId,
        }
      })
  }
)

/**
 * Get payroll instances by status by company
 */
export const getPayrollInstancesByStatusByCompany = createSelector(getCleanPayrollInstancesByStatus, getCompanyId, (session, payrollInstances, companyId) => {
  return payrollInstances.filter((instance) => instance.payroll.company === companyId)
})

export const getVendorPayrollInstancesByStatusByCompany = createSelector(getPayrollInstancesByStatusByCompany, ({ Document }, payrollInstances) => {
  return payrollInstances
    .filter((instance) => instance.isAccessable)
    .filter((instance) => {
      if (instance.status === 'sent-and-locked') {
        const services = instance.payrollInstanceServices
        const processes = flatten(services.map((process) => process.payrollInstanceProcesses))
        const steps = flatten(processes.map((process) => process.payrollInstanceSteps))
        const tasks = flatten(steps.map((step) => step.payrollInstanceTasks))

        const code = tasksIdentifiers.cotPrePayrollPartnerReview.code

        return tasks.filter(({ identifier, status, payrollTask }) => identifier === code && (status === statuses.completed.value || !payrollTask.active)).length
      } else {
        return true
      }
    })
    .reverse()
})

/**
 * Get payroll instance info
 */
const _getPayrollInstance = (instance, Payroll, tenant, loggedUserId) => {
  // Please have in mind that prevPayrollInstanceId usage was removed, but we leave it for now
  const prevPayrollInstanceId = _getPreviousPayrollInstanceId(instance, Payroll)

  return {
    ...instance.ref,
    payroll: instance.payroll.ref,
    companyName: instance.payroll.company.name,
    countryAbbr: instance.payroll.company.country.abbreviature,
    countryName: instance.payroll.company.country.name,
    countryId: instance.payroll.company.country.id,
    shortName: instance.payroll.shortName,
    notes: instance.notes
      .toRefArray()
      .map((note) => ({ ...note }))
      .reverse(),
    isLastAddedNoteFromOtherUser: instance.isLastAddedNoteFromOtherUser(tenant, loggedUserId),
    prevPayrollInstanceId,
  }
}

export const getCurrentStepByInstanceId = createSelector(getPayrollInstanceId, isCot, ({ PayrollInstance }, payrollInstanceId, isCot) => {
  const instance = PayrollInstance.withId(payrollInstanceId)

  return getCurrentStepByInstance(instance, isCot)
})

/**
 * Get current step by instance
 *
 * TODO: This function is not part of any real feature.
 *  We should double check this statement and remove it.
 *
 * @param SessionBoundModel instance
 *
 * @return SessionBoundModel
 */
export const getCurrentStepByInstance = (instance, isUserCot) => {
  let step = null

  const services = instance.payrollInstanceServices.toModelArray()
  // .filter(service => service.payrollService.companyService.countryService.service.name === payrollName)

  for (let service of services) {
    if (step) break

    let processes = service.payrollInstanceProcesses.toModelArray()

    for (let process of processes) {
      if (step) break

      let steps = process.payrollInstanceSteps.toModelArray()

      step = getCurrentStep(steps, isUserCot)

      step = {
        id: step.id,
        stepName: step.getName(),
        alphabeticName: step.getAlphabeticName(),
        tasksCompleted: step.getCompletedTasksCount(isUserCot),
        tasksCount: step.getTasksCount(isUserCot),
      }
    }
  }

  return step
}

/**
 * Get current step
 *
 * Current step is the step, that has uncompleted tasks.
 * If all the steps have completed tasks,
 * then the last step is the current one
 *
 * @param {SessionBoundModel[]} steps
 *
 * @return SessionBoundModel
 */
const getCurrentStep = (steps, isUserCot) => {
  // Order payroll instance steps by their position
  steps = _.orderBy(steps, (step) => {
    return step.payrollStep.position
  })

  // Current step is the step, that has uncompleted tasks
  let step = steps.find((step) => {
    let taskCount = step.getTasksCount(isUserCot) !== step.getCompletedTasksCount(isUserCot)

    if (isUserCot) return taskCount

    /*
     * Additional check for non cot users:
     * We have to recount the completed tasks number,
     * because non cot users don't see the cot tasks,
     * but step can not be completed if the cot tasks are not completed.
     */
    const currentTasks = step.payrollInstanceTasks.toModelArray()
    let prePPReviewNotCompleted = currentTasks.find(
      (task) => task.payrollTask.active && task.identifier === 'cot-pre-payroll-partner-review' && task.status !== 'completed'
    )
    let gtnCalcCompleted = currentTasks.find(
      (task) => task.payrollTask.active && task.identifier === 'gross-to-net-calculations' && task.status === 'completed'
    )
    let gtnReviewNotCompleted = currentTasks.find(
      (task) => task.payrollTask.active && task.identifier === 'cot-gross-to-net-review' && task.status !== 'completed'
    )

    return prePPReviewNotCompleted || (gtnCalcCompleted && gtnReviewNotCompleted) || taskCount
  })

  // If all the steps have completed tasks, then the last step is the current one
  if (!step) step = steps[steps.length - 1]

  return step
}
