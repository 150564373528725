import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_COUNTRY_TERM_PIVOT_FETCH = 'COMPANY_COUNTRY_TERM_PIVOT_FETCH'
export const COMPANY_COUNTRY_TERM_PIVOT_RECEIVE = 'COMPANY_COUNTRY_TERM_PIVOT_RECEIVE'
export const COMPANY_COUNTRY_TERM_PIVOT_INVALIDATE = 'COMPANY_COUNTRY_TERM_PIVOT_INVALIDATE'
export const COMPANY_COUNTRY_TERM_PIVOT_SWITCH = 'COMPANY_COUNTRY_TERM_PIVOT_SWITCH'
// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_COUNTRY_TERM_PIVOT_FETCH,
  receive: COMPANY_COUNTRY_TERM_PIVOT_RECEIVE,
  invalidate: COMPANY_COUNTRY_TERM_PIVOT_INVALIDATE,
  updateWithChildUri: COMPANY_COUNTRY_TERM_PIVOT_SWITCH,
}
export const actionUtility = new ActionUtility(actionTypes, 'companyCountryTermPivot', 'companycountryterms', 'CompanyCountryTerm')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyCountryTermPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyCountryTermPivot = actionUtility.fetchEntities
export const invalidateCompanyCountryTermPivot = actionUtility.invalidate
export const switchCompanyCountryTermPivot = actionUtility.updateEntityWithChildUri

export const downloadTemplateCreate = (companyId) => (
  actionUtility.downloadEntity({ childUri: `bulkcreate?company=${companyId}` })
)

export const downloadTemplateUpdate = (companyId) => (
  actionUtility.downloadEntity({ childUri: `bulkupdate?company=${companyId}` })
)

export const importPayTaxesCreate = (file, companyId) => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: `bulkcreate?company=${companyId}`, shouldHandleErrors: false }))
    .then((response) => {
      return response
    })
)

export const importPayTaxesUpdate = (file, companyId) => dispatch => (
  dispatch(actionUtility.uploadEntity({ file, childUri: `bulkupdate?company=${companyId}`, shouldHandleErrors: false }))
    .then((response) => {
      return response
    })
)

/**
 * Method to handle the switching of company country terms
 * @param {*} companyCountryTermId - ID from the CompanyCountryTerm entity
 * @param {*} entity { <countryTermId> : {} } This is the idea that will be related to the CountryTerm Model
 * @returns
 */
export const handleCompanyCountyTermToCountryTermSwitch = (companyCountryTermId, entity) => dispatch => (
  dispatch(switchCompanyCountryTermPivot(entity, companyCountryTermId, 'switch')).then(resp => resp)
)

export const download = fullUrl => actionUtility.downloadEntity({ fullUrl })
