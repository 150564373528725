import React from 'react'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import Loader from 'components/Loader'
import Moment from 'components/Moment'
import ReversedFlagRenderer from 'components/payruns/ReversedFlagRenderer'
import DashboardTileHeader from './DashboardTileHeader'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import PayslipTable from 'components/table/PayslipTable'
import PayrollsPinButton from 'routes/Payruns/components/PayrollsPinButton'
import ProgressStatus from 'components/payruns/ProgressStatus'

const renderPagination = ({ totalPages, pagination, components }) => {
  if (!totalPages && !pagination) return null
  return (
    <div className='c-custom-pagination u-float--right'>
      <div>{components.pageList}</div>
    </div>
  )
}

const PayrollDashboardTable = ({ payrollInstances, ...rest }) => {
  const options = {
    noDataMessage: 'No matching records found.',
    pageSize: rest.pagination && rest.pagination.pageSize,
    sizePerPageList: [
      { value: 25, label: 25 },
      { value: 50, label: 50 },
      { value: 100, label: 100 },
    ],
    pageIndex: rest.pagination && rest.pagination.pageIndex,
    pageCount: rest.pagination && rest.pagination.pageCount,
    paginationPanel: renderPagination,
  }

  const headings = [
    {
      Header: '',
      accessor: 'placeholder',
      classNames: 'white-space--unset fixed-width--42',
      setWidth: '84px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Payroll name',
      accessor: 'name',
      classNames: 'white-space--unset fixed-width--172',
      setWidth: '172px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Headcount',
      accessor: 'employeeCount',
      classNames: 'u-text--center',
      setWidth: '120px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      setWidth: '100px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      classNames: 'u-text-overflow--unset u-white-space--unset text-center',
      setWidth: '125px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Pay date',
      accessor: 'payDate',
      setWidth: '100px',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Steps',
      accessor: 'steps',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      classNames: 'text-center',
      columnClassNames: 'text-center',
      setWidth: '50px',
      disableSortBy: true,
      disableFilters: true,
    },
  ]
  function renderTableRows (payrollInstances) {
    return payrollInstances.map((payrollInstance) => {
      return {
        placeholder: (
          <div className='u-text--center d-flex ai-center jc--center'>
            <PayrollsPinButton id={payrollInstance.id} pinned={payrollInstance.pinned} canPinPayroll={false} onClick={() => null} />
            <Flag flag={payrollInstance.countryAbbr.toLowerCase()} title={`${payrollInstance.countryName} - ${payrollInstance.companyName}`} size='tiny' />
          </div>
        ),
        name: (
          <span className='c-badge' title={payrollInstance.payrollName}>
            {payrollInstance.shortName}
          </span>
        ),
        employeeCount: <div className='text-center'>{payrollInstance.employees.length || 0}</div>,
        status: payrollInstance.progressStatus && <ProgressStatus {...payrollInstance} />,
        progress: (
          <div className='d-flex jc--center'>
            <div style={{ width: 40, height: 40 }}>
              <CircularProgressbar
                value={payrollInstance.progressPercentage || 0}
                text={`${payrollInstance.progressPercentage || 0}%`}
                styles={buildStyles({ textSize: '1.5rem', textColor: '#000', pathColor: `#5292dd` })}
              />
            </div>
          </div>
        ),
        payDate: <Moment format='DD/MM/YYYY'>{payrollInstance.payDate.date}</Moment>,
        steps: (
          <span data-testid={`payrollInstanceId-${payrollInstance.id}`}>
            {payrollInstance.currentStepName} / {payrollInstance.alphabeticStepRange}
          </span>
        ),
        actions: (
          <div className='text-left d-flex ai-center jc--space-between'>
            <span className='u-margin-right-tiny'>
              {payrollInstance.completedStepTasksCount} / {payrollInstance.totalStepTasksCount}
            </span>
            <ReversedFlagRenderer instance={payrollInstance} />
          </div>
        ),
      }
    })
  }

  return (
    <PayslipTable
      data={renderTableRows(payrollInstances)}
      headings={headings}
      options={options}
      pagination
      modifierClasses='react-bs-container-body'
      tableElementClassName='table--layout-auto'
      wrapperClassName='u-1/1 react-bs-table--overflow-auto'
      totalCount={rest.pagination && rest.pagination.pageCount}
    />
  )
}
function PayrollDashboardView (props) {
  return (
    <div data-testid='dashboard-payroll-view' className='page--payroll-dashboard-view'>
      <DashboardTileHeader title={`Payroll Dashboard`} onClick={props.onNavigateToPayroll} buttonText='View' />
      <div
        className='dashboard-tile-container dashboard-tile-container--no-border
      u-margin-left-small u-margin-right-small u-overflow-x-hidden'
      >
        {!props.isFetching ? (
          <PayrollDashboardTable totalPages={props.pagination.pageCount} payrollInstances={props.payrollInstances} {...props} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

PayrollDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigateToPayroll: PropTypes.func,
  payrollInstances: PropTypes.array,
  pagination: PropTypes.object,
}

PayrollDashboardTable.propTypes = {
  payrollInstances: PropTypes.array,
}

export default PayrollDashboardView
