import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_TERM_FETCH = 'COUNTRY_TERM_FETCH'
export const COUNTRY_TERM_RECEIVE = 'COUNTRY_TERM_RECEIVE'
export const COUNTRY_TERM_UPDATE = 'COUNTRY_TERM_UPDATE'
export const COUNTRY_TERM_INVALIDATE = 'COUNTRY_TERM_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_TERM_FETCH,
  receive: COUNTRY_TERM_RECEIVE,
  update: COUNTRY_TERM_UPDATE,
  invalidate: COUNTRY_TERM_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'countryTermPivot', 'countryterms')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryTermPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryTermPivot = actionUtility.fetchEntities
export const invalidateCountryTermPivot = actionUtility.invalidate
export const updateCountryTerm = (entity, termId) => {
  const url = `countryterms/${termId}`

  return (dispatch, getState, { api }) => {
    return api
      .patch(url, { payload: entity })
      .then((response) => {
        errorsHandling.handleFormErrorsWhereFormKeyAndErrorKeyMismatch(response, [['localName', 'country']])
      })
      .then((response) => {
        dispatch(fetchCountryTermPivot())
        return response
      })
  }
}
