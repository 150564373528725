import React from 'react'

/**
 * Handle errors rendering,
 * according to error's type
 *
 * If it's an Array, then display multiple errors messages,
 * otherwise display single error message
 *
 * @param {Array | String} error
 */
export const handleErrors = error => Array.isArray(error) ? error.map(renderError) : renderError(error)
const renderError = error => <div key={error} className='error u-text--center'>{error}</div>

export const onChangeSubmit = handleSubmit => () => {
  setTimeout(() => handleSubmit(), 0)
}
