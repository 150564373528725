import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'

const TermCategories = ({ categories }) => {
  return (
    <Field
      name='termCategory'
      label='Category'
      labelClassName='c-label'
      component={CustomSelectField}
      clearable={false}
      placeholder={categories.length ? 'Select Category' : 'Loading...'}
      options={categories.map(category => ({
        value: category.id,
        label: category.name
      }))}
    />)
}

TermCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
}

export default TermCategories
