import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { toNumber } from 'lodash'
import moment from 'moment'
import PayslipTable from 'components/table/PayslipTable'
import { CellNumberFormat } from 'utils/tableDataFormatters'
const classNames = require('classnames')

class ChildReport extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // Show/Hide report table
      // By default all tables are not expanded
      // *When child table is only one, it is expanded
      expandReport: props.index === 0,
    }
  }

  /**
   * Dynamically reset all the registered filter columns
   */
  resetAllFilters () {
    for (let key in this.refs) {
      const ref = this.refs[key]
      // `isOnlyHead` is added by `react-bootstrap` library automatically,
      // to all the columns which act as a grouping Heading column.
      // If we don't restrict by this field, then clearing the filters is broken,
      // because for some reason these types of columns also have `cleanFiltered` function.
      const isFilterableColumn = !ref.props.isOnlyHead && typeof ref.cleanFiltered === 'function'

      if (isFilterableColumn) ref.cleanFiltered()
    }
  }

  /*
   * Arrow classes by toggling filters
   */
  getArrowClasses () {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-top-tiny': true,
      active: this.state.expandReport,
    })
  }

  /*
   * Toggle child reports
   */
  toggleReport () {
    this.setState({
      expandReport: !this.state.expandReport,
    })
  }

  /*
   * Formatting data in all cells
   *
   * Data in every cell can be different type
   * All dates are formated with Moment
   * All strings are truncated with ellipses in the end and
   * they are decorated with additional title attribute
   */
  dataFormatter (cell, row, col) {
    const dataNumber = col.isPercentage ? toNumber(cell.replace('%', '')) : toNumber(cell)
    const isNegative = dataNumber && dataNumber < 0
    const isPositive = dataNumber && dataNumber > 0 && col.isDifference

    const classes = classNames({
      // Color all negative values in red
      'u-text--mandy u-weight--medium': isNegative,
      // Color only the positive difference numbers in blue
      'u-text--curious u-weight--medium': isPositive,
      'u-text--overflow-ellipsis': !isPositive && !isNegative,
    })

    return (
      <div title={cell} className={classes}>
        {cell}
      </div>
    )
  }

  /**
   * Is a date column?
   */
  isDateCol (col) {
    return ['fromDate', 'toDate', 'startDate', 'endDate', 'payDate', 'effectiveDate', 'deadline', 'completionDate'].includes(col.name)
  }

  /**
   * Is a period date column?
   */
  isPeriodCol (col) {
    return ['period'].includes(col.name)
  }

  _sortDate (a, b, order) {
    // Handling empty dates. We just move them in the bottom of results.
    if (!a) return 1
    if (!b) return -1

    if (order === 'asc') {
      return moment(a, 'DD/MM/YYYY').unix() - moment(b, 'DD/MM/YYYY').unix()
    } else if (order === 'desc') {
      return moment(b, 'DD/MM/YYYY').unix() - moment(a, 'DD/MM/YYYY').unix()
    }
  }

  sortDate (col) {
    return (a, b, order) => this._sortDate(a[col.name], b[col.name], order)
  }

  sortPeriod (col) {
    return (a, b, order) => {
      // Handling empty dates. We just move them in the bottom of results.
      if (!a[col.name]) return 1
      if (!b[col.name]) return -1

      // The period is represented as a string in `DD/MM/YYYY - DD/MM/YYYY` format,
      // like '01/12/2019 - 31/12/2019'.
      const [fromDate, toDate] = a[col.name].split(' - ')
      const [fromDateB, toDateB] = b[col.name].split(' - ')

      // First we make the sorting by `fromDate` and if they are equal, then we compare by `toDate`
      return this._sortDate(fromDate, fromDateB, order) || this._sortDate(toDate, toDateB, order)
    }
  }

  /**
   * Depending on the `col.name`,
   * we determine what sorting function to return.
   *
   * Object @col
   * String @col.name
   */
  addSorting (col) {
    if (this.isDateCol(col)) {
      return { sortFunc: this.sortDate(col) }
    }

    if (this.isPeriodCol(col)) {
      return { sortFunc: this.sortPeriod(col) }
    }

    return {}
  }

  render () {
    const { childReport, headings, reportNameCategory = null, isFetching } = this.props

    const options = {
      pageSize: 25,
      pageIndex: 0,
      showPageSizeSelector: childReport.data.length >= 25,
    }

    // Apply props on headings based on flags
    const normalizedHeadings = headings.map((heading, i) => {
      let structure = { ...heading }
      if (structure.hidden) {
        structure['isVisible'] = false
      }
      if (structure.name === 'changeId') {
        structure['isKey'] = true
        structure['isVisible'] = false
      }
      let classNamesForColumn = ''
      let headerClassNames = ''
      if (structure.alignment === 'center') {
        classNamesForColumn = 'text-left'
      }
      if (structure.isNumber || structure.type === 'decimal') {
        structure['Cell'] = CellNumberFormat
        classNamesForColumn = 'text-right u-margin-right'
        headerClassNames = 'text-right'
      }
      if (structure.name === 'payrun') {
        classNamesForColumn = 'u-text--overflow-ellipsis--nowrap'
      }

      // Structure the data for the new table
      structure['accessor'] = structure.name
      structure['Header'] = structure.text
      structure['disableSortBy'] = false
      structure['setWidth'] = structure.name === 'systemEmployeeId' ? '50px' : '150px'
      structure['rowSpan'] = structure.rowspan
      structure['colSpan'] = structure.colspan
      structure['classNames'] = headerClassNames
      structure['columnClassName'] = classNamesForColumn
      structure['isVisible'] = !structure.hidden

      return structure
    })
    const tableHeadings = {
      headings: normalizedHeadings.filter((heading) => !heading.isKey && heading.isVisible),
    }

    return (
      <div>
        {/* Report heading */}
        <SectionHeading text={reportNameCategory || childReport.name}>
          <div className='o-layout__item u-1/1 u-1/2@tablet'>
            <div className='u-float--right'>
              <span onClick={() => this.toggleReport()} className={this.getArrowClasses()} />
            </div>
          </div>
        </SectionHeading>

        {this.state.expandReport && (
          <div>
            {/* Additional info before table */}
            {childReport.info && childReport.info.length && (
              <div className='u-margin-bottom'>
                {childReport.info.map((i) => {
                  return (
                    <p key={i.value} className='u-margin-none'>
                      {i.text + ': ' + i.value}
                    </p>
                  )
                })}
              </div>
            )}
            {childReport.data.length ? (
              <div className='u-relative'>
                {/* Table */}
                <PayslipTable
                  data={childReport.data}
                  options={options}
                  pagination
                  {...tableHeadings}
                  wrapperClassName={classNames({
                    'u-1/1 react-bs-table--overflow-auto': true,
                    'u-half-opacity': isFetching,
                    'react-bs-table': true,
                  })}
                  trClassName={(row, rowIndex) => 'react-bs-table__cell--overflow-ellipsis'}
                  modifierClasses={classNames({
                    'react-bs-container-body': true,
                    'u-overflow-y-hidden': true,
                  })}
                  tableElementClassName='table--layout-auto'
                />
              </div>
            ) : (
              <div className='u-margin-bottom-small'>No data</div>
            )}
          </div>
        )}
      </div>
    )
  }
}

ChildReport.propTypes = {
  childReport: PropTypes.object,
  headings: PropTypes.array,
  reportNameCategory: PropTypes.string,
  index: PropTypes.number,
  isFetching: PropTypes.bool,
}

export default ChildReport
