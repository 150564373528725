/**
 * isFetching utility
 *
 * For all Store entities we keep `isFetching` flag.
 *
 * Thanks to that flag - we know whether an entity is being fetched.
 * For instance - knowing that, we can display a loading indicator.
 *
 * There is a tricky part. We support 3 states (values) for `isFetching`.
 * We know that the naming predispose for a boolean value,
 * but we support null / true / false.
 *
 * `null` - entity isn't fetched
 * `true` - entity being fetched
 * `false` - entity is fetched
 *
 * We did it in that way, because if we use it in a component (and we don't support `null`),
 * then its first value is `false`.
 * Therefore it misleads us that the fetching is completed and there isn't any data.
 *
 * Adding `null` as default value give us the knowledge that the fetching isn't started yet.
 *
 * Maybe you'll ask why we don't set `true` as default value? Good question!
 * In that way it will break our API fetching mechanism, because before fetching an entity
 * we're checking whether it is being fetching at the moment (in order to prevent duplicate calls).
 * @ref Please check `utils/redux/action.shouldFetch()`
 *
 */

/**
 * Is one of the entities being fetched
 *
 * @param {Array} entities
 */
export const isFetchingEntities = (entities) => entities.filter((e) => e.isFetching).length > 0
export const isFilteringEntities = (entities) => entities.filter((e) => e.isFiltering).length > 0

/**
 * Is fetching of entities triggered
 *
 * The fetching is triggered when all entities `isFetching` flag is false
 *
 * @param {Array} entities
 */
export const isFetchingTriggered = (entities) => entities.filter((e) => e.isFetching === false).length === entities.length

const normalize = (entities) =>
  entities.map((entity) => {
    // Check if the entity supports advanced filtering.
    // Later, once we remove and refactor `filtersLegacy` flow, then we can simplify the `fetching` and `normalizing` flow
    if (entity.ref && entity.filter) {
      const e = entity.ref
      // If the `filter.name` is omitted, then we set a default value to `/` (a.k.a. fetching all the entity items)
      const filter = entity.filter.name ? entity.filter.name : '/'

      return (
        e.filters[filter] || {
          isFetching: null,
        }
      )
    }

    return entity
  })

/**
 * Are entities being fetched
 *
 * The first `isFetching` value is `null`.
 * Therefore we may to treat it as:
 * 'the fetching isn't triggered yet'.
 *
 * However, that doesn't suit us, because when we mount a component,
 * the first thing we want to do is to fetch all needed entities data.
 * Therefore we have to show a loading indicator.
 *
 * Because of this, we assume that using this function
 * we treat not triggered fetching as the fetching is being started,
 * because of the above reason (loading indicator).
 *
 * TODO - `handleFiltering` is currently experimental feature, so let's document it, once we validated it!
 *
 * @param {Array} entities
 */
export const isFetching = (entities, handleFiltering) => {
  entities = normalize(entities)

  return handleFiltering
    ? isFilteringEntities(entities) || isFetchingEntities(entities) || !isFetchingTriggered(entities)
    : isFetchingEntities(entities) || !isFetchingTriggered(entities)
}

/**
 * Checks if the entity is fetching data based on a custom filter name.
 * @param {string} filterName - The name of the custom filter.
 * @param {Object} entity - The entity object containing filters.
 * @returns {boolean} True if the entity is fetching data based on the custom filter, false otherwise.
 */
export const isFetchingByCustomFilter = (filterName, entity) => entity?.filters[filterName]?.isFetching ?? true
