import React from 'react'
import PropTypes from 'prop-types'
import BankAccountForm from 'components/BankAccountForm'
import CompanyBankAccountForm from 'routes/Companies/routes/Company/routes/Bank/components/CompanyBankAccountForm'

export const BankAccountFormEdit = props => {
  const { isCompany } = props
  return <>
    <div className='o-layout o-layout--middle'>
      <div className='o-layout__item u-1/1 u-1/2@tablet'>
        <h5 className='u-text--large'>{ props.bankAccount.bankCountryName }</h5>
      </div>
    </div>
    {isCompany
      ? <CompanyBankAccountForm {...props} />
      : <BankAccountForm {...props} />
    }
  </>
}

BankAccountFormEdit.propTypes = {
  bankAccount: PropTypes.object,
  isCompany: PropTypes.bool
}

export default BankAccountFormEdit
