import Fetcher from '../Fetcher'
import { connect } from 'react-redux'
import { getUser } from 'redux/selectors/employees'
import { createFilter } from '../../utils/redux/filter'
import EmployeeHeader from 'components/header/EmployeeHeader'

const mapStateToProps = (state, { employeeId }) => ({
  employee: getUser(state, { userId: employeeId })
})

const Container = connect(mapStateToProps)(EmployeeHeader)

export default Fetcher(Container, [
  {
    name: 'employeeSystemUsers',
    params: [{
      _computed: {
        filter: (state, { employeeId }) => createFilter({ id: employeeId })
      }
    }]
  }
])
