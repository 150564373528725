import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const RESOURCES_CREATE = 'RESOURCES_CREATE'
export const RESOURCES_FETCH = 'RESOURCES_FETCH'
export const RESOURCES_RECEIVE = 'RESOURCES_RECEIVE'
export const RESOURCES_UPDATE = 'RESOURCES_UPDATE'
export const RESOURCES_DELETE = 'RESOURCES_DELETE'
export const RESOURCES_INVALIDATE = 'RESOURCES_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  create: RESOURCES_CREATE,
  fetch: RESOURCES_FETCH,
  receive: RESOURCES_RECEIVE,
  update: RESOURCES_UPDATE,
  delete: RESOURCES_DELETE,
  invalidate: RESOURCES_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'resources', 'resources', 'Resource')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchResourcesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchResources = actionUtility.fetchEntities
export const createResource = actionUtility.createEntity
export const updateResource = actionUtility.updateEntity
export const deleteResource = actionUtility.deleteEntity
export const invalidateResources = actionUtility.invalidate
export const downloadResource = (id) => actionUtility.downloadEntity({ id, childUri: 'download' })
export const uploadResource = (file, data) => actionUtility.uploadEntity({ file, data })
