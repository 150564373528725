import createSelector from 'utils/createSelector'

const getEmployeeId = (state, props) => parseInt(props.employeeId)
const getStatuses = (state, props) => props.statuses

export const getPayrollInstancesByEmployee = createSelector(getEmployeeId, ({ PayrollInstanceEmployee }, employeeId) => {
  let payrollInstance = PayrollInstanceEmployee.filter({
    employee: employeeId,
  })
    .toModelArray()
    .map((payrollInstanceEmployee) => {
      if (!payrollInstanceEmployee.payrollInstance) {
        return undefined
      }
      return {
        id: payrollInstanceEmployee.id,
        name: payrollInstanceEmployee.payrollInstance.name,
        fromDate: payrollInstanceEmployee.payrollInstance.fromDate,
        toDate: payrollInstanceEmployee.payrollInstance.toDate,
        payDate: payrollInstanceEmployee.payrollInstance.payDate,
        grossPay: payrollInstanceEmployee.grossPay,
        netIncome: payrollInstanceEmployee.netIncome,
        netPay: payrollInstanceEmployee.netPay,
        instanceId: payrollInstanceEmployee.payrollInstance.id,
        payrollInstance: {
          ...payrollInstanceEmployee.payrollInstance.ref,
        },
        employeeId: payrollInstanceEmployee.ref.employee,
        payrollName: payrollInstanceEmployee.payrollInstance?.payrollName,
      }
    })
    .filter((item) => item !== undefined)
    .reverse()

  return payrollInstance.length > 0 ? payrollInstance : []
})

export const getPayrollInstancesByEmployeeByStatuses = createSelector(
  getPayrollInstancesByEmployee,
  getStatuses,
  (session, payrollInstanceEmployees, statuses) => {
    return payrollInstanceEmployees.filter((entity) => statuses.includes(entity.payrollInstance.status))
  }
)
