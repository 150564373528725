import createSelector from 'utils/createSelector'
import { uniq } from 'utils/fnkit/array'
import { has } from 'utils/fnkit/object'
import { isEmpty } from 'utils/fnkit/typeChecks'

const getPayrollId = (state, props) => props.payrollId
const getPayrollInstanceId = (state, props) => props.payrollInstanceId
const getFxRateId = (state, props) => props.fxRateId

// Get currency by payroll
export const getCurrencyByPayroll = createSelector(getPayrollId, ({ Payroll }, payrollId) => {
  const payroll = Payroll.filter((payroll) => payroll.id === payrollId).count() && Payroll.withId(payrollId)

  // Make sure the payroll is not deleted, because this function is invoked in the Reporting pages,
  // where in the case a Payroll is deleted, it will be still part of the Report filters.
  if (!payroll) return null

  return payroll.currency.id
})
// Get currency by payrollInstance
export const getCurrencyByPayrollInstance = createSelector(getPayrollInstanceId, ({ PayrollInstance }, payrollInstanceId) => {
  return PayrollInstance.withId(payrollInstanceId).currency.id
})

// Get reporting rate by fx rate
export const getFxRateRefById = createSelector(getFxRateId, ({ FxRate }, fxRateId) => {
  return FxRate.withId(fxRateId).ref
})

/**
 *
 * @param {*} state
 * @param {*} report
 * @param {*} payrollCurrency
 * @returns
 */
export const setReportingCurrency = (state, report, payrollCurrency) => {
  if (!has(report.params, 'fxRate') && !has(report.params, 'fxRateCustom')) return payrollCurrency
  if (!isEmpty(report.params.fxRate)) return uniq(report.params.fxRate.map((rate) => getFxRateRefById(state, { fxRateId: rate }).toCurrency))
  if (!isEmpty(report.params.fxRateCustom)) return report.params.fxRateCustom[0].toCurrency

  return null
}
/**
 *
 * @param {*} state
 * @param {*} report
 * @returns
 */
export const setReportingRate = (state, report) => {
  if (!isEmpty(report.params.fxRate)) return report.params.fxRate.map((rate) => getFxRateRefById(state, { fxRateId: rate }).id)
  if (!isEmpty(report.params.fxRateCustom)) return report.namedParams.fxRate.join(', ')

  return null
}

/**
 *
 * @param {*} state
 * @param {*} report
 * @returns
 */
export const getPayrollCurrency = (state, report) =>
  report.filters.payroll && report.filters.payroll.length
    ? uniq(report.filters.payroll.map((id) => getCurrencyByPayroll(state, { payrollId: id })).filter((currency) => currency))
    : null
