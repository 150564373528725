import React from 'react'
import Back from 'components/Back'

class RouteView extends React.Component {
  render () {
    return <div>
      <Back />
      <img src='/img/maps/process-map-3x.png' className='u-img--fluid' alt='Process map' />
    </div>
  }
}

export default RouteView
