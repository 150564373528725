import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_PAYGROUP_FETCH = 'PAYROLL_PAYGROUP_FETCH'
export const PAYROLL_PAYGROUP_RECEIVE = 'PAYROLL_PAYGROUP_RECEIVE'
export const PAYROLL_PAYGROUP_INVALIDATE = 'PAYROLL_PAYGROUP_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_PAYGROUP_FETCH,
  receive: PAYROLL_PAYGROUP_RECEIVE,
  invalidate: PAYROLL_PAYGROUP_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'payrollPaygroupPivot', 'payrollpaygroups', 'PayrollPaygroup', {},
  { fetch: 'PAYROLLPAYGROUP_VIEW' })

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollPaygroupPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollPaygroupPivot = actionUtility.fetchEntities
export const invalidatePayrollPaygroupPivot = actionUtility.invalidate
