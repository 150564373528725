import React from 'react'
import CalendarToolbar from './CalendarToolbar'
import Flag from 'components/Flag'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ReversedFlagRenderer from 'components/payruns/ReversedFlagRenderer'
const classNames = require('classnames')
/**
 * Define the date formats for different calendar views - month/week/day
 */
export const formats = {
  dayFormat: 'ddd DD/MM',
  dayRangeHeaderFormat: ({ start, end }, culture, moment) =>
    moment.format(start, 'MMMM D', culture) + ' — ' +
    moment.format(end, 'MMMM D, YYYY', culture),

  weekdayFormat: (date, culture, moment) =>
    moment.format(date, 'dddd', culture),

  dayHeaderFormat: (date, culture, moment) =>
    moment.format(date, 'MMMM YYYY, Do dddd', culture),

}

/**
 * Custom daily header, which substitutes the default daily header
 */
export const DailyHeader = (props) => {
  const { label } = props
  return <div className='daily-header u-bg--sauve'>
    {label}
  </div>
}

/**
 * Custom weekly header, which substitutes the default weekly header
 */
export const WeeklyHeader = (props) => {
  const { label } = props
  return <div className='weekly-header u-bg--sauve'>
    {label}
  </div>
}

/**
 * Custom event view, which substitutes the default event view
 */
export let Event = (props) => {
  let { event, extraClass } = props
  let isEventPast = event.status === 'completed' ? 'u-line-through' : ''
  const action = <div
    className={classNames({
      'rbc-event-content-custom u-relative': true,
      // In the case a Calendar's event is not a Link, set the default cursor pointer.
      // Otherwise - it's a pointer, but nothing will happen on clicking on it and it will be confusing.
      'u-cursor--default': !event.finishTaskLink,
      [extraClass]: extraClass,
    })}
  >
    <Flag flag={event.flag} size='miniature' classes='u-margin-right-tiny' title={event.companyName} />
    <span className={'o-block o-block--left event-name ' + isEventPast} title={event.detailedTitle}>
      {event.title}
    </span>
    <span className='isReverted'>
      <ReversedFlagRenderer instance={event} />
    </span>
    <span
      className='owner'
      style={{ 'backgroundColor': event.headlampColor }}
      title={event.owner}>
      {event.ownerInitials}&nbsp;
    </span>
  </div>
  return event.finishTaskLink ? <Link to={event.finishTaskLink}>{action}</Link> : action
}

/**
 * Custom event daily view, which substitutes the default event view
*/
export let EventDaily = ({ event }) => {
  return <Event extraClass='rbc-event-content-daily' event={event} />
}

const calculateRange = (datetime, view) => {
  const date = moment(datetime)

  if (view === 'day') {
    return {
      fromDate: date.startOf('day').format('YYYY-MM-DD'),
      toDate: date.endOf('day').format('YYYY-MM-DD')
    }
  }

  if (view === 'week') {
    return {
      fromDate: date.startOf('week').format('YYYY-MM-DD'),
      toDate: date.endOf('week').format('YYYY-MM-DD')
    }
  }

  if (view === 'month') {
    return {
      fromDate: moment(date.startOf('month')).subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment(date.endOf('month')).add(7, 'days').format('YYYY-MM-DD')
    }
  }
}

/**
 * Custom toolbar, which substitude the default react-big-calendar toolbar.
 * The param toolbar comes from the calendar component.
 *
 * @param toolbar
 */
export const CustomToolbar = (toolbar, { onRangeChange }) => {
  /**
   * Go to day calendar view
   */
  const goToDayView = () => {
    toolbar.onView('day')
    onRangeChange(calculateRange(toolbar.date, 'day'))
  }

  /**
   * Go to week calendar view
   */
  const goToWeekView = () => {
    toolbar.onView('week')
    onRangeChange(calculateRange(toolbar.date, 'week'))
  }

  /**
   * Go to month calendar view
   */
  const goToMonthView = () => {
    toolbar.onView('month')
    onRangeChange(calculateRange(toolbar.date, 'month'))
  }

  /**
   * Go to prev month/week/day
   */
  const goToBack = () => {
    if (toolbar.view === 'month') {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1)
      onRangeChange(calculateRange(toolbar.date, 'month'))
    } else if (toolbar.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() - 7)
      onRangeChange(calculateRange(toolbar.date, 'week'))
    } else {
      toolbar.date.setDate(toolbar.date.getDate() - 1)
      onRangeChange(calculateRange(toolbar.date, 'day'))
    }
    toolbar.onNavigate('prev')
  }

  /**
   * Go to next month/week/day
   */
  const goToNext = () => {
    if (toolbar.view === 'month') {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1)
      onRangeChange(calculateRange(toolbar.date, 'month'))
    } else if (toolbar.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() + 7)
      onRangeChange(calculateRange(toolbar.date, 'week'))
    } else {
      toolbar.date.setDate(toolbar.date.getDate() + 1)
      onRangeChange(calculateRange(toolbar.date, 'day'))
    }
    toolbar.onNavigate('next')
  }

  /**
   * Go to current day
   */
  const goToCurrent = () => {
    const now = new Date()
    toolbar.date.setDate(now.getDate())
    toolbar.date.setMonth(now.getMonth())
    toolbar.date.setYear(now.getFullYear())
    toolbar.onNavigate('current')
    onRangeChange(calculateRange(toolbar.date, toolbar.view))
  }

  /**
   * Go to specific date, choosed from datepicker
   * @param date
   */
  const goTo = (date) => {
    date = moment(date)
    toolbar.date.setDate(date.date())
    toolbar.date.setMonth(date.month())
    toolbar.date.setYear(date.year())
    toolbar.onNavigate()
    onRangeChange(calculateRange(toolbar.date, toolbar.view))
  }

  return <CalendarToolbar
    label={toolbar.label}
    goToBack={goToBack}
    goToNext={goToNext}
    goToCurrent={goToCurrent}
    goToDayView={goToDayView}
    goToWeekView={goToWeekView}
    goToMonthView={goToMonthView}
    view={toolbar.view}
    date={toolbar.date}
    onChanged={date => goTo(date)}
    countries={toolbar}
  />
}
