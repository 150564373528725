export const accountTypes = {
  EXPENSE: 'Expense',
  LIABILITY: 'Liability',
  EXPENSE_LIABILITY: 'Expense/Liability'
}

export const accountStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const filterForExpense = (item) => (
  [accountTypes.EXPENSE, accountTypes.EXPENSE_LIABILITY].includes(item.accountType) &&
  (item.status === accountStatus.ACTIVE)
)

export const filterForLiability = (item) => (
  [accountTypes.LIABILITY, accountTypes.EXPENSE_LIABILITY].includes(item.accountType) &&
  (item.status === accountStatus.ACTIVE)
)
