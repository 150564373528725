import createSelector from 'utils/createSelector'

const getVendorId = (state, props) => parseInt(props.vendorId)
const getCompanyId = (state, props) => parseInt(props.companyId)
const getVendorStatus = (state, props) => props.vendorStatus

export const getVendorRestrictedStatus = createSelector(getVendorId, getCompanyId, ({ VendorSwitch }, vendorId, companyId) => {
  const today = new Date()
  let status
  try {
    status = VendorSwitch
      .all()
      .toModelArray()
      .filter(vs => (vs.onboardingVendor === vendorId && (new Date(vs.effectiveDate.date) > today || !vs.effectiveDateMigration)) ||
        (vs.offboardingVendor === vendorId && new Date(vs.effectiveDate.date) <= today && !vs.endDateMigration && vs.effectiveDateMigration))
      .map(vs => vs.companyService.company.ref.id)
      .includes(companyId)
  } catch {}

  return status
})

export const getSpecialStatusVendorId = createSelector(getCompanyId, getVendorStatus, ({ VendorSwitch }, companyId, vendorStatus) => {
  const today = new Date()
  let vendor = []
  try {
    vendor = VendorSwitch
      .all()
      .toModelArray()
      .filter(vs => {
        if (vendorStatus === 'onboarding') {
          return new Date(vs.effectiveDate.date) > today || !vs.effectiveDateMigration
        } else if (vendorStatus === 'offboarding') {
          return new Date(vs.effectiveDate.date) <= today && !vs.endDateMigration && vs.effectiveDateMigration
        }
      })
      .filter(vs => vs.companyService.company.ref.id === companyId)
      .map(vs => {
        if (vendorStatus === 'onboarding') return vs.onboardingVendor
        if (vendorStatus === 'offboarding') return vs.offboardingVendor
      })
  } catch {}

  return vendor
})
