import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FieldDatePicker from 'components/form/FieldDatePicker'

export const ProcessForm = ({ owners, fieldName, editEntity, ...props }) => {
  return <Form {...props} data-testid='processOwner-form'>
    <div className='o-layout u-text--center'>
      <div className='o-layout__item u-text--center u-1/1 u-1/2@tablet'>
        <div className='o-form-group'>
          {
            editEntity === 'editOwner'
              ? (
                <Field
                  name={fieldName || 'owner'}
                  label='Owner'
                  component={CustomSelectField}
                  labelClassName='c-label'
                  className='u-text--left'
                  placeholder='Select'
                  clearable={false}
                  options={owners.map(owner => ({
                    value: owner.id,
                    label: owner.firstname + ' ' + owner.surname
                  }))}
                  data-testid='owner-wrapper'
                />
              )
              : null
          }
          {
            editEntity === 'editKeyEventDate'
              ? (
                <Field
                  label='Process key event date'
                  className='c-input'
                  labelClassName='c-label'
                  name='keyEventDate'
                  component={FieldDatePicker}
                />
              )
              : null

          }
        </div>
      </div>
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'
          data-testid='save'
        >
          Save
        </button>
      </div>
    </div>
  </Form>
}

ProcessForm.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired
  })).isRequired,
  fieldName: PropTypes.string,
  // Help this prop we know which form fields to show
  // We reuse the global modal and form by changing only the form fields
  // Feel free to add more options in case of new edit forms
  editEntity: PropTypes.string
}

export default ProcessForm
