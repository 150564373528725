import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PayrollProcessTreeContainer from 'containers/PayrollProcessTreeContainer'
import PayrollTaskEditContainer from '../containers/PayrollTaskEditContainer'
import PayrollTaskCreateContainer from '../containers/PayrollTaskCreateContainer'
import PayrollProcessEditContainer from '../containers/PayrollProcessEditContainer'
import PayrollStepCreateContainer from '../containers/PayrollStepCreateContainer'
import PayrollStepEditContainer from '../containers/PayrollStepEditContainer'
import Modal from 'components/Modal'
import SectionHeading from 'components/SectionHeading'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
    this.state = {
      payrollTaskId: null,
      payrollStepId: null,
      payrollProcessId: null,
      editEntity: null,
      isStatic: false,
      isTaskEdit: false,
      isTaskCreate: false,
      isProcessEdit: false,
      isStepCreate: false,
      isStepEdit: false
    }
  }

  openModal () {
    this.refs.modalEdit.showModal()
  }

  hideModal () {
    this.refs.modalEdit.hideModal()
  }

  handleOnTaskEdit = (payrollTaskId, isStatic) => {
    this.setState({
      payrollTaskId,
      isStatic,
      isTaskEdit: true,
      isTaskCreate: false,
      isProcessEdit: false,
      isStepCreate: false,
      isStepEdit: false
    })

    this.openModal()
  }

  handleOnTaskCreate = (payrollProcessId) => {
    this.setState({
      payrollProcessId,
      isTaskEdit: false,
      isTaskCreate: true,
      isProcessEdit: false,
      isStepCreate: false,
      isStepEdit: false
    })

    this.openModal()
  }

  handleOnStepEdit = (payrollStepId) => {
    this.setState({
      payrollStepId,
      isStepEdit: true,
      isTaskEdit: false,
      isTaskCreate: false,
      isProcessEdit: false,
      isStepCreate: false
    })

    this.openModal()
  }

  handleOnProcessEdit = (payrollProcessId, editEntity) => {
    this.setState({
      payrollProcessId,
      editEntity,
      isProcessEdit: true,
      isTaskEdit: false,
      isTaskCreate: false,
      isStepCreate: false,
      isStepEdit: false
    })

    this.openModal()
  }

  handleOnStepCreate = (payrollProcessId) => {
    this.setState({
      payrollProcessId,
      isStepCreate: true,
      isProcessEdit: false,
      isTaskEdit: false,
      isTaskCreate: false,
      isStepEdit: false
    })

    this.openModal()
  }

  renderModalTitle () {
    const { isProcessEdit, isStepCreate, isTaskCreate } = this.state

    if (isProcessEdit) return 'Update Process Owner'

    if (isStepCreate) return 'Create a Step'

    if (isTaskCreate) return 'Create an Action'

    return ''
  }

  render () {
    const { isTaskEdit, isStepEdit, isProcessEdit, isStepCreate, isTaskCreate, editEntity, isStatic,
      payrollTaskId, payrollProcessId, payrollStepId } = this.state
    const currentLocation = this.props.location.pathname

    return <div>

      <SectionHeading text='Workflow'>
        <div className='o-layout__item u-1/1 u-1/2@tablet d-none'>
          <div className='u-float--right'>
            <Link
              to={`${currentLocation}/process-map`}
              className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
            >
              View process map
            </Link>
          </div>
        </div>
      </SectionHeading>

      <Modal
        ref='modalEdit'
        className={`c-modal ${isTaskEdit && 'c-modal--half'}`}
        modalHeading={this.renderModalTitle()}
      >
        { isTaskEdit && <PayrollTaskEditContainer
          payrollTaskId={payrollTaskId}
          isStatic={isStatic}
          onSubmit={this.hideModal}
          {...this.props}
        />
        }
        { isTaskCreate && <PayrollTaskCreateContainer
          payrollProcessId={payrollProcessId}
          onSubmit={this.hideModal}
          {...this.props}
        />
        }

        { isProcessEdit && <PayrollProcessEditContainer
          payrollProcessId={payrollProcessId} {...this.props}
          editEntity={editEntity}
          onSubmit={this.hideModal}
        />
        }

        { isStepCreate && <PayrollStepCreateContainer
          payrollProcessId={payrollProcessId}
          onSubmit={this.hideModal}
          {...this.props}
        />
        }

        { isStepEdit && <PayrollStepEditContainer
          payrollStepId={payrollStepId}
          onSubmit={this.hideModal}
          {...this.props}
        />
        }

      </Modal>
      <PayrollProcessTreeContainer
        onTaskEdit={this.handleOnTaskEdit}
        onMultiView={this.handleOnTaskEdit}
        onProcessEdit={this.handleOnProcessEdit}
        onStepCreate={this.handleOnStepCreate}
        onTaskCreate={this.handleOnTaskCreate}
        onStepEdit={this.handleOnStepEdit}
        shouldIncludeEmptySteps
        addBtnTitle='Add step or action'
        {...this.props} />
    </div>
  }
}

RouteView.propTypes = {
  location: PropTypes.object
}

export default RouteView
