import React from 'react'
import PropTypes from 'prop-types'

const RequestCodeView = ({ gridClassNames = '', textClassNames = 'u-text--small', onClick = null }) => {
  return (
    <div className={`o-grid o-grid--center ${gridClassNames}`}>
      <div className={`u-text--center ${textClassNames}`}>
        <span>
          Not getting the code?
          <span
            data-testid='request-new-code'
            onClick={onClick}
            className='u-text--curious u-cursor--pointer'
          >
            &nbsp;Request new code.
          </span>
        </span>
      </div>
    </div>
  )
}

RequestCodeView.propTypes = {
  onClick: PropTypes.func,
  textClassNames: PropTypes.string,
  gridClassNames: PropTypes.string,
}

export default RequestCodeView
