import { createSelector } from 'reselect'
import { processes } from 'redux/selectors/store/processes'
import { steps } from 'redux/selectors/store/steps'
import { loadRef } from 'redux/helpers/loadRef'

export const getSteps = createSelector([ steps, processes ],
  (steps, processes) => steps.allIds.map(id => {
    return loadRef(steps.byIds[id], [
      {
        ref: 'process',
        entity: processes
      }
    ])
  })
    .reverse()
)
