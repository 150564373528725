import React from 'react'
import PropTypes from 'prop-types'
import FormFieldsGroup from './FormFieldsGroup'

export const DataProtectionFieldsListForm = ({ fields, disabled, disabledReason, isApproveModal }) => {
  return <div>
    {fields.map((cat, i) => <FormFieldsGroup
      key={cat.category}
      fields={cat.fields}
      category={cat.category}
      categoryName={cat.categoryName}
      index={i}
      disabled={disabled}
      disabledReason={disabledReason}
      isApproveModal={isApproveModal} />
    )}
  </div>
}

DataProtectionFieldsListForm.propTypes = {
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  isApproveModal: PropTypes.bool
}
export default DataProtectionFieldsListForm
