import { isEqual } from 'utils/fnkit/base'

/**
 * Compares the previous form data with the current form data to check if there are any changes.
 *
 * @function hasSchemaFormChanges
 * @param {Object} previousFormData - The data from the previous form submission.
 * @param {Object} form - The current form containing the latest data.
 * @returns {boolean} Returns true if the form data has changed, otherwise false.
 */
export const hasSchemaFormChanges = (previousFormData, formData) => !isEqual(previousFormData, formData)

/**
 * Resets specific fields in the provided form data.
 *
 * @param {Object} formData - The current form data object that needs updating.
 * @param {Array} [fieldIds=[]] - An array of field IDs to reset in the form data.
 * @param {Object} [newFieldsValue={}] - An object containing new values for each field ID.
 *
 * @returns {Object} The updated form data with specified fields reset to new values or empty arrays.
 */
export const resetSchemaFormFields = (formData, fieldIds = [], newFieldsValue = {}) =>
  fieldIds.reduce((newForm, fieldId) => {
    let newValue = []
    if (newFieldsValue.hasOwnProperty(fieldId)) {
      newValue = newFieldsValue[fieldId]
    }
    return { ...newForm, [fieldId]: newValue }
  }, formData)

/**
 * areSchemaFormPropsEqual
 *
 * This function is a custom memoization comparison function intended for use with React's `memo` to optimize the
 * re-rendering of form components that use JSON schema forms. It compares the relevant props deeply (or shallowly
 * for specific cases) to determine if a re-render is necessary.
 *
 * The function performs the following checks:
 *
 * 1. Deeply compares `formData` to detect any changes in the form's data.
 * 2. Deeply compares `schema` to detect any changes in the form's structure.
 * 3. Deeply compares `uiSchema` to detect any changes in the UI configuration.
 * 4. Shallowly compares the `selectedItemValue` within `formContext` to detect changes in the currently selected item.
 *
 * If any of these comparisons detect a difference, the function returns `false`, triggering a re-render.
 * If no differences are detected, it returns `true`, preventing an unnecessary re-render.
 *
 * Usage:
 *
 * import React from 'react';
 * import { areSchemaFormPropsEqual } from './path/to/areSchemaFormPropsEqual';
 *
 * const MyMemoizedComponent = React.memo(MyComponent, areSchemaFormPropsEqual);
 *
 * @param {Object} prevProps - The previous props passed to the component.
 * @param {Object} nextProps - The next props to be passed to the component.
 * @returns {boolean} - Returns `true` if the props are equal and no re-render is needed, otherwise `false`.
 */
export const areSchemaFormPropsEqual = (prevProps, nextProps) => {
  if (!isEqual(prevProps.formData, nextProps.formData)) return false
  if (!isEqual(prevProps.schema, nextProps.schema)) return false
  if (!isEqual(prevProps.uiSchema, nextProps.uiSchema)) return false
  if (prevProps.formContext?.selectedItemValue !== nextProps.formContext?.selectedItemValue) return false
  return true
}
