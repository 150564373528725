import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const DOCUMENT_TYPES_FETCH = 'DOCUMENT_TYPES_FETCH'
export const DOCUMENT_TYPES_RECEIVE = 'DOCUMENT_TYPES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DOCUMENT_TYPES_FETCH,
  receive: DOCUMENT_TYPES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'documentTypes', 'documenttypes', 'DocumentType')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDocumentTypesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDocumentTypes = actionUtility.fetchEntities
