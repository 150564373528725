import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import { isMultitenancyEnabled } from 'utils/config'
import PropTypes from 'prop-types'
import { getContactedParentCompanies } from 'redux/selectors/tenants'
import { closeAllToastMessages } from 'redux/actions/toasts'
import { setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { useParams } from 'react-router'
import { asideCosVendorTitles, asideTitles } from 'utils/locales/aside.en'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { asideGlobalRoutes } from './routes'

const Aside = (props) => {
  const { tenantId, selectAside, asideMode, history, isCotOemUser, hasAccessToResources } = props

  const dispatch = useDispatch()
  const params = useParams()

  const renderItems = () => {
    let items = [
      { title: asideTitles.home, name: 'home', path: `/vendors/${tenantId}/company-details`, isHome: true },
      { title: asideTitles.clients, name: 'clients', path: `/vendors/${tenantId}/clients` },
      { title: asideTitles.users, name: 'home', path: `/vendors/${tenantId}/users` },
      ...(hasAccessToResources ? [asideGlobalRoutes.resources] : []),
      { title: asideCosVendorTitles.icpCosting, name: 'home', path: `/vendors/${tenantId}/icp-costing` },
    ]

    if (!isCotOemUser) {
      items.push({ title: asideCosVendorTitles.icpSlas, name: 'home', path: `/vendors/${tenantId}/icp-slas` })
    }

    return items
  }
  useEffect(() => {
    if (!params.tenantId) {
      dispatch(closeAllToastMessages())
      dispatch(setShouldPoll({ enablePolling: false }))
      dispatch(setStartPolling({ shouldStartPolling: false }))
    }
  }, [])
  return (
    <aside className='o-aside'>
      <Logo />

      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav items={renderItems()} {...props} />
    </aside>
  )
}
// map to state
const mapStateToProps = (state, props) => ({
  tenantId: state.tenants.id,
  parentCompanies: !props.isFetching ? getContactedParentCompanies(state) : [],
})

Aside.propTypes = {
  tenantId: PropTypes.number,
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  parentCompanies: PropTypes.array,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

const Container = connect(mapStateToProps)(Aside)

export default Fetcher(Container, ['parentCompanies'], { renderWhileFetching: true, showLoader: false })
