import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'

export const Processes = ({ processes, isFetching }) => {
  if (isFetching) return (<div>Loading...</div>)
  return <div>
    <SectionHeading text='Processes list' className='u-margin-top-large' />
    {isFetching === true ? 'Loading...' : null}
    <PayslipTable
      wrapperClassName='u-1/1@tablet u-1/2@desktop'
      headings={[
        {
          accessor: 'name',
          Header: 'Name',
          disableSortBy: true
        },
        {
          accessor: 'keyEvent',
          Header: 'Key Event',
          disableSortBy: true
        },
        {
          accessor: 'serviceRef',
          Header: 'Service',
          disableSortBy: true
        }
      ]}
      data={processes.map(process => (
        {
          name: process.name,
          keyEvent: process.keyEvent,
          serviceRef: process.serviceRef.name
        }
      ))}
    />
  </div>
}

export default Processes
