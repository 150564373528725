import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollService'

class PayrollService extends CRUDModel {}
PayrollService.modelName = 'PayrollService'
PayrollService.actionTypes = actionTypes

PayrollService.fields = {
  id: attr(),
  payroll: fk('Payroll', 'payrollServices'),
  companyService: fk('CompanyCountryService', 'payrollServices')
}

export default PayrollService
