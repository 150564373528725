import React, { useRef, useState, useEffect } from 'react'
import { useHistory, Prompt } from 'react-router-dom'

import { reusableModalHeadings, reusableModalTexts } from 'utils/locales/modals.en'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { ConfirmButton, RejectButton } from 'components/buttons'

export const LeaveConfirmationModal = ({ modalRef, confirmButtonText, rejectButtonText, onConfirmation, onReject, hideReject }) => {
  const handleReject = () => {
    onReject?.()
    modalRef?.current?.hideModal()
  }
  return (
    <>
      <BaseConfirmationModal ref={modalRef} className='c-modal' modalHeading={reusableModalHeadings.confirmation}>
        <p>{reusableModalTexts.leaveConfimartion}</p>
        <ConfirmButton buttonText={confirmButtonText} onClick={onConfirmation} />
        {!hideReject && <RejectButton buttonText={rejectButtonText} onClick={handleReject} />}
      </BaseConfirmationModal>
    </>
  )
}

export const LeaveRouteConfirmationModal = ({ isDirty, ...props }) => {
  const modalRef = useRef()
  const history = useHistory()
  const [nextLocationPath, setNextLocationPath] = useState()
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleBlockedNavigation = (nextLocation, _) => {
    if (isDirty && !confirmedNavigation) {
      modalRef?.current?.showModal()
      setNextLocationPath(nextLocation?.pathname)
      return false
    }
    return true
  }

  const onConfirmation = () => {
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && nextLocationPath) {
      history.push(nextLocationPath)
    }
  }, [confirmedNavigation])

  return (
    <>
      <Prompt when message={handleBlockedNavigation} />
      <LeaveConfirmationModal modalRef={modalRef} onConfirmation={onConfirmation} {...props} />
    </>
  )
}
