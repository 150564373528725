import { EXTERNAL_COMPANIES_FETCH, EXTERNAL_COMPANIES_RECEIVE } from '../actions/externalCompanies'
import { createReducer } from 'utils/redux/reducer'
import { defaultState, getHandlers } from 'utils/redux/reducer-meta'

const actionTypes = {
  fetch: EXTERNAL_COMPANIES_FETCH,
  receive: EXTERNAL_COMPANIES_RECEIVE,
}

export const handlerOverrides = () => {
  const defaultHandlers = getHandlers(actionTypes)
  const receiveType = actionTypes.receive
  return {
    ...defaultHandlers,
    [receiveType]: (state, action) => {
      const receiveState = defaultHandlers[receiveType](state, action)
      let aggregatedState
      try {
        const filterName = action.payload?.filter?.name || '/'
        aggregatedState = {
          ...receiveState,
          filters: {
            ...receiveState.filters,
            [filterName]: {
              ...receiveState.filters[filterName],
              parentCompanies: action.payload?.response?.parentCompanies || [],
            },
          },
        }
      } catch (error) {
        console.error('Error during custom reducer creation...', error)
      }
      return aggregatedState
    },
  }
}

export default createReducer(defaultState, handlerOverrides())
