import createSelector from 'utils/createSelector'
import { getKeyPeopleRole } from 'redux/models/employeeSystemUser'
import { getEmployeeProps } from 'redux/selectors/employees'
import _ from 'lodash'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'

const getCompanyId = (state, props) => parseInt(props.companyId)
export const getFilteredIdsByFilter = makeFilteredIdsByFilter('employeeSystemUsers')

/**
 * Get the key people for the HQ company
 * They are employees that have a roleType
 *
 * @return EmployeeSystemUser
 */
export const getKeyPeopleWithCountryWithBusinessUnit = createSelector(getFilteredIdsByFilter, ({ EmployeeSystemUser, Company, BusinessUnit }, filteredIds) => {
  return filteredIds
    .map((id) => EmployeeSystemUser.withId(id))
    .filter((employee) => getKeyPeopleRole(employee.roleType))
    .map((employee) => ({
      ...getEmployeeProps(employee, Company, BusinessUnit),
    }))
    .sort((a, b) => filteredIds.indexOf(a.id) - filteredIds.indexOf(b.id))
})

/**
 * Get the key people for the company
 * They are employees that have a roleType
 *
 * @return EmployeeSystemUser
 */
export const getKeyPeopleWithCountryWithBusinessUnitByCompany = createSelector(
  getFilteredIdsByFilter,
  getCompanyId,
  ({ EmployeeSystemUser, Company, BusinessUnit }, filteredIds, companyId) => {
    return filteredIds
      .map((id) => EmployeeSystemUser.withId(id))
      .filter((employee) => getKeyPeopleRole(employee.roleType) && _.includes(employee.accessableCompanies, companyId))
      .map((employee) => ({
        ...getEmployeeProps(employee, Company, BusinessUnit),
      }))
  }
)
