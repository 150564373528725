import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import PayrollDashboardView from '../components/PayrollDashboardView'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import { getFilteredPayrollInstances } from 'redux/selectors/payrollInstance'
import { createFilter } from 'utils/redux/filter'
import { getPaginationObject } from 'utils/pagination'

const mapDispatchToProps = (dispatch, { history }) => {
  return {
    onNavigateToPayroll: () => history.push('/payruns/active'),
    dispatch
  }
}

const mapStateToProps = (state, props) => {
  const filter = props.filters.payrollInstances
  const items = getFilteredPayrollInstances(state, { filter: filter.name })
  const totalCount = items.length || 0

  return {
    payrollInstances: items,
    // TODO - Here we have to check by specific permission, instead of a role.
    // But we do it as a hot fix, because we have to deploy it live asap.
    hasAccess: !isFinanceAdmin(state) && !isAccounting(state),
    pagination: getPaginationObject(15, 0, totalCount)
  }
}

const Component = connect(mapStateToProps, mapDispatchToProps)(PayrollDashboardView)

export default Fetcher(Component, [{
  name: 'payrollInstances',
  isForceFetching: true,
  params: [{
    _computed: {
      filter: (state, { payrunState }) => createFilter({
        payrunState,
        sort: [{ name: 'country', order: 'asc' }, { name: 'name', order: 'asc' }, { name: 'fromDate', order: 'desc' }],
      })
    },
    // Here it's important to disable it, because on `payrollInstances` refetch + applying `payrunState` filter,
    // some Payruns will be deleted, because of the current obsolete mechanism (keep in mind we consider refactoring it)
    // Here's an example case:
    // 1. We are on tab "Pre Payroll Steps" (Tab 1.) and we complete all Payrun' ( Payrun 1.) steps.
    // 2. Tab 1. Payruns will be refetched and Payrun 1. won't be returned, because it will be moved to
    // the next tab Post Pay Date steps.
    // 3. But, because Payrun 1. is not part anymore of Tab 1., our obsolete logic will assume that the Payrun 1. is
    // deleted on the BE and will deleted it here on the FE too.
    // <Note>: Once we refactor our deletion/obsolete flow, this kind of quirks will be removed.
    disableObsoleteFlow: true,
  }]
}])
