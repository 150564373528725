import { isEmpty } from 'lodash'

export const updateVarianceNotes =
  ({ versionOne, versionTwo, data = {} }) =>
    (dispatch, getState, { api }) => {
      const normalizedData = {
        versionOne,
        versionTwo,
        notes: data.map((item) => ({
          itemKey: item.elementId.toString(),
          itemType: item.belongsTo,
          body: item.note,
        })),
      }
      return api.patch(`variancenotes`, { payload: normalizedData }).then((res) => ({
        hasErrors: !isEmpty(res?.errors),
        data: res,
      }))
    }
