import { currencyLocale, currencyType } from './enums/currencyEnums'

/**
 * Is numeric value
 *
 * @param {String | Number} number
 * @doc
 * @return boolean
 */
export const isNumeric = (number) => !isNaN(parseFloat(number)) && isFinite(number)

/**
 * Typecast Boolean to Int
 *
 * @credits https://stackoverflow.com/a/22239859/4312466
 * @param {Boolean} bool
 * @returns {number}
 */
export const boolToInt = (bool) => bool | 0

/**
 * Generate random integer from the given range
 *
 * @param {Number} min
 * @param {Number} max
 * @return number
 */
export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
/**
 *
 * @param {string or number} input
 * @returns {string} in format X,XXX
 */

export const formatNumberToCurrency = (input, round = false, locale = 'ENEN', type = 'null', decimalScale = null) => {
  if (input === '') input = 0
  if (isNaN(input)) return input
  const setLocale = currencyLocale[locale]
  const setCurrency = type && currencyType[type]
  const amount = round ? Math.round(parseFloat(input)) : input

  let currencyOptions = {}

  if (setCurrency) {
    currencyOptions['currency'] = setCurrency
    currencyOptions['currencyDisplay'] = 'code'
    currencyOptions.style = 'currency'
  }

  if (decimalScale) {
    currencyOptions['minimumFractionDigits'] = decimalScale
    currencyOptions['maximumFractionDigits'] = decimalScale
  }

  return Intl.NumberFormat(setLocale, currencyOptions).format(amount)
}

/**
 * Method to handle the rounding of half numbers
 * @param {number} num
 * @returns a rounded number to the closest half (1.4 === 1.5)
 */
export const roundHalfValue = (num) => {
  return Math.round(num * 2) / 2
}
