import RouteView from './components/RouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'

export default (store) => ({
  path : ':reportId',
  indexRoute: { component: RouteView },
  isOrphanedChild: true,
  exact: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
})
