import { createReducerMeta } from 'utils/redux'
import { PAYGROUPS_FETCH, PAYGROUPS_RECEIVE, PAYGROUP_SET_ACTIVE_ID } from 'redux/actions/paygroups'

const actionTypes = {
  fetch: PAYGROUPS_FETCH,
  receive: PAYGROUPS_RECEIVE,
  setActiveId: PAYGROUP_SET_ACTIVE_ID
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducerMeta(actionTypes)
