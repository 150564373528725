import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_TASK_FETCH = 'COUNTRY_TASK_FETCH'
export const COUNTRY_TASK_RECEIVE = 'COUNTRY_TASK_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_TASK_FETCH,
  receive: COUNTRY_TASK_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'countryTaskPivot', 'countrytasks', 'CountryTask')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryTaskPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryTaskPivot = actionUtility.fetchEntities
