import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollPaygroupPivot'

class PayrollPaygroup extends CRUDModel {}
PayrollPaygroup.modelName = 'PayrollPaygroup'

PayrollPaygroup.fields = {
  id: attr(),
  paygroup: fk('Paygroup', 'payrollPaygroups'),
  payroll: fk('Payroll', 'payrollPaygroups'),
}
PayrollPaygroup.actionTypes = actionTypes

export default PayrollPaygroup
