import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import {
  getPayrollInstanceProcessTasksOwnersIds,
  getPayrollInstanceProcessTreeByPayrollInstance,
} from 'routes/Payruns/routes/ChangeDetails/selectors/payrollProcessORM'
import { getIsSharedDocByUserByDocIdByDocTenant } from 'redux/selectors/documentUsers'
import { getUserWithSchema } from 'redux/selectors/employees'
import PayrollInstanceProcessTree from 'components/PayrollInstanceProcessTree'
import { hasAccess, isCot } from 'redux/selectors/auth'
import _ from 'lodash'
import { createFilter } from '../utils/redux/filter'

const mapStateToProps = (state, props) => {
  const {
    typeClass,
    payrollInstanceId,
    match: { params },
    documentId,
    documentTenant,
    isShare,
    isNotify,
    initialValues,
  } = props

  const payrollInstance = params && params.payrollInstanceId ? parseInt(params.payrollInstanceId) : payrollInstanceId

  let tree = getPayrollInstanceProcessTreeByPayrollInstance(state, { payrollInstanceId: payrollInstance, typeClass })
  if (isShare && tree.owner) {
    let owner = getUserWithSchema(state, { userId: tree.owner.id })
    tree.owner = {
      ...owner,
      share: getIsSharedDocByUserByDocIdByDocTenant(state, {
        documentId,
        documentTenant,
        userId: owner.id,
        userTenant: owner.schema,
      }),
    }
  }

  // Sort tasks by position
  tree.steps.map((step) => {
    step.tasks = step.tasks.map((task) => {
      // Check if file is shared to user
      let ownerUser = task.owner && task.owner.id ? getUserWithSchema(state, { userId: task.owner.id }) : task.owner
      if (ownerUser) {
        ownerUser = {
          ...ownerUser,
          share: getIsSharedDocByUserByDocIdByDocTenant(state, {
            documentId: documentId,
            documentTenant: documentTenant,
            userId: ownerUser.id,
            userTenant: ownerUser.schema,
          }),
        }
      }
      return {
        ...task,
        owner: task.owner ? { ...ownerUser } : null,
      }
    })
    step.tasks = _.sortBy(step.tasks, ['position'])
  })

  return {
    // Here you may ask why we don't require `PAYROLLINSTANCETASK_EDIT` permission. Good point!
    // Some of the roles can update PayrollInstanceTask via Payroll->Actions modal and they needed the above permission,
    // but the same time they shouldn't be allowed to update the same tasks (owners and deadlines) here.
    // Because of this, we introduce a new permission, called `PAYROLLINSTANCETREE_EDIT`, in order to handle this case.
    isEditDisabled: !hasAccess(state)(['PAYROLLINSTANCEPROCESS_EDIT', 'PAYROLLINSTANCETREE_EDIT']),
    isReadOnlyMode: tree.instanceStatus === 'completed-with-extra-tasks',
    tree,
    isCot: isCot(state),
    hideProcessOwner: isNotify && !tree.owner.hasCredentials,
    initialValues,
  }
}

const makePayrollInstanceFilter = (name, filterName = 'payrollInstance') => ({
  name,
  params: [
    {
      _computed: {
        filter: (state, { payrollInstanceId, match: { params } }) =>
          createFilter({
            [filterName]: params && params.payrollInstanceId ? params.payrollInstanceId : payrollInstanceId,
          }),
      },
    },
  ],
})

const Container = connect(mapStateToProps)(PayrollInstanceProcessTree)

export default Fetcher(
  Fetcher(Container, [
    {
      name: 'employeeSystemUsers',
      params: [
        {
          _computed: {
            filter: (state, { payrollInstanceId, match: { params } }) => {
              const processOwners = getPayrollInstanceProcessTasksOwnersIds(state, { payrollInstanceId })

              return createFilter({ isKeyPerson: 1, id: processOwners })
            },
          },
        },
      ],
    },
  ]),
  [
    'companyProcessPivot',
    'countryProcessPivot',
    'parentCompanies',
    makePayrollInstanceFilter('payrolls'),
    makePayrollInstanceFilter('payrollService'),
    makePayrollInstanceFilter('payrollProcess'),
    makePayrollInstanceFilter('payrollStep'),
    makePayrollInstanceFilter('payrollTask'),
    makePayrollInstanceFilter('payrollInstances', 'id'),
    makePayrollInstanceFilter('payrollInstanceService'),
    makePayrollInstanceFilter('payrollInstanceProcess'),
    makePayrollInstanceFilter('payrollInstanceStep'),
    makePayrollInstanceFilter('payrollInstanceTask'),
    makePayrollInstanceFilter('payrollInstanceTaskOwner'),
    makePayrollInstanceFilter('payrollInstanceTaskOwnerScope'),
  ]
)
