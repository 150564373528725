import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeSystemUsers'
import { EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME } from 'redux/actions/employeeAttendancePeriods'
import keyPeopleRoleTypes from 'redux/config/keyPeopleRoleTypes'
import _ from 'lodash'
import moment from 'moment'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'

export const getKeyPeopleRole = employeeRoleType =>
  keyPeopleRoleTypes.find((roleType) => roleType.type === employeeRoleType || roleType.readOnlyType === employeeRoleType)

/**
 * Get 'to be deleted' attendance / overtimes elemenets
 *
 * @param Employee employee
 * @param string relationship
 * @param [] payload
 * @param string type
 * @return [Employee]
 */
const getFilteredAttendanceOvertimeByType = (employee, relationship, payload, type) => {
  return employee[relationship]
  // Filter only in the specific payload period
    .filter(entity => {
      const entityDate = moment(entity.date.date).format('YYYY-MM-DD')
      const isBetween = moment(entityDate)
        .isBetween(moment(payload.params.fromDate), moment(payload.params.toDate), null, '[]')

      return isBetween
    })
    .filter(entity => {
      let isInArray = false

      _.forOwn(payload.data, (dataEl, dataKey) => {
        if (
          dataEl.employee === employee.id &&
          dataEl.type === type &&
          moment(dataEl.date).format('YYYY-MM-DD') === moment(entity.date.date).format('YYYY-MM-DD')
        ) {
          isInArray = true
        }
      })

      return !isInArray
    })
}

class EmployeeSystemUser extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      [Model.actionTypes.syncTerms]: (Model, { payload: { employeeId, data } }) => {
        const isModelCreated = Model.filter({ id: employeeId }).toRefArray().length

        // When we're syncing terms to an employee, immediately after the employee creation,
        // then the employee model isn't created yet, because the current flow is as follows:
        // #1 - Trigger BE call for creating an employee
        // #2 - If it's successful, use the response for getting the Employee id
        // #3 - Sync terms to the Employee, using the employee id, returned from the creation response
        // #4 - Here we fetch all the employees again, in order to get the created employee
        // #5 - When we have the response from step #4, then the employee model is created.
        if (isModelCreated) {
          Model.withId(employeeId).employeeCompanyCountryTerms.filter(entity => (
            !data.hasOwnProperty(entity.term)
          )).delete()
        }
      },
      [EMPLOYEE_SYSTEM_USER_BULK_SYNC_ATTENDANCE_OVERTIME]: (Model, { payload }) => {
        // delete unused relationships

        payload.params.employees.map(employeeId => {
          let employee = Model.withId(employeeId)

          // delete missing attendances
          let attendances = getFilteredAttendanceOvertimeByType(employee, 'employeeAttendances', payload, 'attendance')
          attendances.delete()

          // delete missing overtimes
          let overtimes = getFilteredAttendanceOvertimeByType(employee, 'employeeOvertimes', payload, 'overtime')
          overtimes.delete()
        })
      }
    }
  }

  getIsContractorCompany () {
    return EmploymentStatusHelper.isContractorCompany(this.employmentStatus)
  }

  getIsContractorAgency () {
    return EmploymentStatusHelper.isContractorAgency(this.employmentStatus)
  }

  getIsShortTermAssignment () {
    return EmploymentStatusHelper.isShortTermAssignment(this.employmentStatus)
  }

  getIsShortTermAssignmentHost () {
    return EmploymentStatusHelper.isShortTermAssignmentHost(this.employmentStatus)
  }

  getName () {
    return `${this.firstname} ${this.surname}`
  }
}
EmployeeSystemUser.modelName = 'EmployeeSystemUser'
EmployeeSystemUser.actionTypes = actionTypes

EmployeeSystemUser.fields = {
  id: attr(),
  company: fk('Company', 'employeeSystemUsers'),
  businessUnit: fk('BusinessUnit', 'employeeSystemUsers'),
  costCenter: fk('CostCenter', 'employeeSystemUsers'),
  department: fk('Departments', 'employeeSystemUsers'),
  officeLocation: fk('OfficeLocation', 'employeeSystemUser'),
  subsidiary: fk('Subsidiary', 'employeeSystemUser'),
  project: fk('Project', 'employeeSystemUsers'),
  paymentCurrency: fk('Currency', 'employeeSystemUsers'),
  countries: many({
    to: 'Country',
    relatedName: 'employeeSystemUsers',
    through: 'EmployeeCountry',
    throughFields: ['employee', 'country']
  }),
  accessAreasCompanies: many({
    to: 'Company',
    relatedName: 'accessAreaEmployeeSystemUsers',
    through: 'AccessArea',
    throughFields: ['employee', 'company']
  })
}

export default EmployeeSystemUser
