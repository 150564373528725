import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { createPayrollStep } from 'redux/actions/payrollStep'
import { invalidatePayrollInstanceStep } from 'redux/actions/payrollInstanceStep'
import { getPayrollStepsOptionsByPayrollProcessId, getReorderedPayrollStepsByPayrollProcessId }
  from '../selectors/payrollSteps'
import PayrollStepForm from '../components/PayrollStepForm'

class PayrollStepCreateContainer extends React.Component {
  render () {
    return <PayrollStepForm {...this.props} />
  }
}

PayrollStepCreateContainer.propTypes = {
  payrollProcessId: PropTypes.number
}

const mapDispatchToProps = (dispatch, { onSubmit }) => ({
  onSubmit: data => dispatch(createPayrollStep(data)).then(() => {
    onSubmit()
    dispatch(invalidatePayrollInstanceStep())
  })
})

const mapStateToProps = (state, props) => {
  const { payrollProcessId } = props

  const { name, position = null } = getFormValues('payrollStepCreate')(state) || {}

  return {
    stepsOptions: getPayrollStepsOptionsByPayrollProcessId(state, {
      payrollProcessId,
      shouldUseShortName: true,
      shouldIncludeLastOption: true,
    }),
    steps: getReorderedPayrollStepsByPayrollProcessId(state, { payrollProcessId, name, position }),
    initialValues: {
      payrollProcess: payrollProcessId
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'payrollStepCreate'
})(PayrollStepCreateContainer))

export default Fetcher(Container, [
  'payrollStep',
])
