/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { batch, useDispatch } from 'react-redux'
import ConfirmationModal from 'components/ConfirmationModal'
import Modal from 'components/Modal'
import DocumentsShareModalFormContainer from 'containers/documents/DocumentsShareModalFormContainer'
import EmployeeDocumentsShareModalFormContainer from 'containers/documents/EmployeeDocumentsShareModalFormContainer'
import DocumentsListContainer from 'containers/documents/DocumentsListContainer'
import { types } from 'redux/config/documents'
import { getDocumentTypesByTabName, getDefaultTabByCurrentStep, getFilteredDocTabsForByPayrunState } from 'utils/documentTypes'
import { DocumentMessages } from 'utils/helperClasses/documents'
import DocumentFormEditContainer from 'containers/documents/DocumentFormEditContainer'
import SectionHeading from 'components/SectionHeading'
import { getLockUnlockPayloadString } from 'utils/sections/payruns/lockUnlock'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { invalidateDocuments, updateDocument } from 'redux/actions/document'
import { gtnValidationStatusEnums } from 'utils/enums/gtnValidationEnums'
import { destroy } from 'redux-form'
import { removeItemFromLocalStorage } from 'utils/storage'
import { invalidateDocumentUsers } from 'redux/actions/documentUsers'

const DocumentsView = (props) => {
  const shareModalRef = useRef()
  const confirmShareModalRef = useRef()
  const editModalRef = useRef()
  const reimportGTN = useRef()
  const reimportGTNWhenPayrunIsUnlocked = useRef()
  const reimportGTNConfirmation = useRef()
  const reimportGTNConfirmationWithPayrunLocked = useRef()
  const reimportGTNUserNotAuthorizedToLockUnlockPayrun = useRef()

  const [id, setId] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [documentTenant, setDocumentTenant] = useState(null)
  const [documentName, setDocumentName] = useState(null)
  const [documentTypeClass, setDocumentTypeClass] = useState(null)
  const [documentTypeId, setDocumentTypeId] = useState(null)
  const [documentTypes, setDocumentTypes] = useState([])
  const [documentVersion, setDocumentVersion] = useState(null)
  const [isDocUploaded, setIsDocUploaded] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [tabIndexBasedOnDocUploadedType, setTabIndexBasedOnDocUploadedType] = useState(0)
  // Depending on this flag value, we will show different modal confirmation message.
  const [isProcessOwnerAutoShared, setIsProcessOwnerAutoShared] = useState(null)
  const [payrollId, setPayrollId] = useState(null)
  const [clearFilters, setClearFilters] = useState(false)

  const dispatch = useDispatch()

  const {
    typeClass,
    dynamicTypeClass,
    companyName,
    countryName,
    payrollName,
    currentStep,
    payrunState,
    onZeroTouchSubmit,
    onHandleSubmit,
    onModalStateChange,
    payrollInstanceId,
    hasUserLockUnlockPermission,
    isPayrollInstanceLocked,
  } = props

  const { confirmationMsg, shareFileMsg } = types[typeClass]
  const ShareModalContainer = typeClass === 'employee' ? EmployeeDocumentsShareModalFormContainer : DocumentsShareModalFormContainer
  const type = dynamicTypeClass ? types[dynamicTypeClass] : types[typeClass]
  const tabs = typeClass ? getFilteredDocTabsForByPayrunState(types[typeClass].documentTypesTabs, payrunState) : null

  const message = ['payrollInstance', 'company', 'payroll'].includes(type.shortValue)
    ? new DocumentMessages(type.shortValue, { company: companyName, country: countryName, payroll: payrollName }, documentTypes).confirmationMsg
    : confirmationMsg

  const showConfirmShareModal = (id, documentId, documentTenant, documentName, documentTypeClass, documentTypeId, isProcessOwnerAutoShared, documentTypes) => {
    setId(id)
    setDocumentId(documentId)
    setDocumentTenant(documentTenant)
    setDocumentName(documentName)
    setDocumentTypeClass(documentTypeClass)
    setDocumentTypeId(documentTypeId)
    setDocumentTypes(documentTypes)
    setIsProcessOwnerAutoShared(isProcessOwnerAutoShared)

    confirmShareModalRef.current.showModal()
  }

  const handleOnFileUploaded = (tabIndex) => {
    setIsDocUploaded(true)
    setTabIndexBasedOnDocUploadedType(tabIndex)
  }
  const overrideGtnValidation = (documentId) =>
    dispatch(updateDocument({ gtnValidationStatus: gtnValidationStatusEnums.overwritten }, documentId, false, false))

  const tabIndex = isDocUploaded || isSelected ? tabIndexBasedOnDocUploadedType : getDefaultTabByCurrentStep(currentStep, payrunState)

  const removeTabIndexFromLocalStorage = () => {
    const storageKey = `${documentId}_tabIndex`
    removeItemFromLocalStorage(storageKey)
  }

  const closeModalAndRemoveStorage = () => {
    removeTabIndexFromLocalStorage()
    batch(() => {
      dispatch(destroy('shareNonVendorFiles'))
      dispatch(invalidateDocumentUsers())
      // Normally, instead of clearing the filters, we would clear the redux state using invalidate or invalidateSpecificFilter,
      // but both of those cause the AsideContainer to reload.
      // In order to prevent refactoring a component used widely as that, and to minimize needed testing we manually signal the children that the filters
      // found in the redux state are not valid anymore, and next time the share modal is opened, the data will be fetched with the default filters
      setClearFilters(true)
    })
    shareModalRef.current.hideModal()
  }

  // We don't want the redux form state to persist when opening a new document share
  // That is why we are resetting the form here where we can monitor the documentId change
  useEffect(() => {
    if (documentId) {
      dispatch(destroy('shareNonVendorFiles'))
      dispatch(invalidateDocuments())
    }
  }, [documentId, dispatch])

  return (
    <>
      <ConfirmationModal
        ref={confirmShareModalRef}
        className='c-modal'
        modalHeading='Confirmation'
        onConfirm={() => shareModalRef.current.showModal()}
        data-testid='share-confirm-modal'
      >
        <p>{message}</p>
        <p>{shareFileMsg}</p>
      </ConfirmationModal>
      <Modal
        ref={shareModalRef}
        className='c-modal c-modal--half c-modal--overflow-y'
        modalHeading={`Share ${documentName}`}
        onHide={closeModalAndRemoveStorage}
      >
        <ShareModalContainer
          id={id}
          documentId={documentId}
          documentTenant={documentTenant}
          onShared={closeModalAndRemoveStorage}
          clearFilters={clearFilters}
          {...props}
        />
      </Modal>
      <Modal ref={editModalRef} className='c-modal c-modal--half' modalHeading={`Edit ${documentName}`}>
        <DocumentFormEditContainer
          id={id}
          documentId={documentId}
          documentTenant={documentTenant}
          hasDocumentClassification={typeClass === 'payrollInstance'}
          payrollId={payrollId}
          onSubmitChanges={() => editModalRef.current.hideModal()}
          {...props}
          typeClass={type.shortValue}
        />
      </Modal>

      <ConfirmationModal
        ref={reimportGTN}
        className='c-modal u-text--valhalla'
        modalHeading={'GTN Import'}
        onConfirm={() => {
          onZeroTouchSubmit({ id: documentId }).then((res) => res.success && reimportGTNConfirmation.current.showModal())
        }}
      >
        <div className='u-text--center u-text--normal u-weight--regular'>
          <div className='u-relative u-margin-bottom zt' />
          <p>
            Do you want to re-import GTN file{' '}
            <span className='u-weight--bold'>
              {documentName}
              {documentVersion ? `_V${documentVersion}` : ''}
            </span>{' '}
            ?
          </p>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        ref={reimportGTNWhenPayrunIsUnlocked}
        className='c-modal u-text--valhalla'
        modalHeading={'GTN Import'}
        onConfirm={() => {
          if (!hasUserLockUnlockPermission) {
            return reimportGTNUserNotAuthorizedToLockUnlockPayrun.current.showModal()
          }
          onHandleSubmit({
            id: payrollInstanceId,
            ...(props.payrunState === payrunStatesEnum.ACTIVE && { kuLockUnlockPayrun: getLockUnlockPayloadString(isPayrollInstanceLocked) }),
          }).then(
            (res) =>
              !res.hasError &&
              onZeroTouchSubmit({ id: documentId }).then(
                (res) =>
                  res.success &&
                  onModalStateChange({
                    showConfirmLock: false,
                    showConfirmUnlock: false,
                    heading: '',
                    reimportGTNConfirmationModal: true,
                    reimportGTNConfirmationWithPayrunLocked: false,
                    documentId: documentId,
                  })
              )
          )
        }}
      >
        <div className='u-text--center u-text--normal u-weight--regular'>
          <div className='u-relative u-margin-bottom zt' />
          <p>
            {props.payrunState === payrunStatesEnum.ACTIVE && 'Do you want to import your GTN and lock your payrun file'}
            {props.payrunState !== payrunStatesEnum.ACTIVE && 'Do you want to import your GTN'}
            <span className='u-weight--medium'>
              {' '}
              {documentName}_V{documentVersion}
            </span>
            ?
          </p>
        </div>
      </ConfirmationModal>

      <Modal
        ref={reimportGTNConfirmation}
        className='c-modal u-text--valhalla'
        modalHeading={'GTN Mapping and Import is in progress.'}
        onHide={() =>
          onModalStateChange({
            showConfirmLock: false,
            showConfirmUnlock: false,
            heading: '',
            reimportGTNConfirmationModal: false,
          })
        }
      >
        <div className='u-text--center u-text--normal u-weight--regular'>
          <div className='u-relative u-margin-bottom zt zt-animation' />
          <p>GTN Mapping Report will be produced and you will be notified of the result shortly in the "Alerts" section.</p>

          <button
            onClick={() => reimportGTNConfirmation.current.hideModal()}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid={`closeModal`}
          >
            OK
          </button>
        </div>
      </Modal>

      <Modal
        ref={reimportGTNUserNotAuthorizedToLockUnlockPayrun}
        className='c-modal u-text--valhalla'
        modalHeading={'GTN Import'}
        onHide={() =>
          onModalStateChange({
            showConfirmLock: false,
            showConfirmUnlock: false,
            heading: '',
            reimportGTNConfirmationModal: false,
          })
        }
      >
        <div className='u-text--center u-text--normal u-weight--regular'>
          <div className='u-relative u-margin-bottom zt' />
          <p>
            To re-import GTN data Payrun File should be locked for changes. Your Payrun File is still unlocked. You are not authorised to lock the Payrun File,
            please contact your Global Owner for access.
          </p>

          <button
            onClick={() => reimportGTNUserNotAuthorizedToLockUnlockPayrun.current.hideModal()}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid={`closeModal`}
          >
            OK
          </button>
        </div>
      </Modal>

      {tabs && (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndexBasedOnDocUploadedType(index)
            setIsSelected(true)
          }}
        >
          <TabList className='c-tabs__list c-tabs__list-border-none'>
            {tabs.map((tab) => (
              <Tab key={tab.name} className='c-tabs__item' data-testid={`tab-${tab.label}`}>
                <span>{tab.label}</span>
              </Tab>
            ))}
          </TabList>

          {tabs.map((tab) => (
            <TabPanel key={tab.name}>
              <SectionHeading text={tab.documentsTableTitle} fullWidth className='u-padding-top' />
              <DocumentsListContainer
                overrideGtnValidation={(id) => overrideGtnValidation(id)}
                updateTitleForEmployee={typeClass === 'employee'}
                openConfirmShareModal={(id, docId, docTenant, docName, docTypeClass, docTypeId, isProcessOwnerAutoShared, documentTypes) =>
                  showConfirmShareModal(id, docId, docTenant, docName, docTypeClass, docTypeId, isProcessOwnerAutoShared, documentTypes)
                }
                openShareModal={({ id, documentId, documentTenant, documentName }) => {
                  batch(() => {
                    setId(id)
                    setDocumentId(documentId)
                    setDocumentTenant(documentTenant)
                    setDocumentName(documentName)
                  })
                  shareModalRef.current.showModal()
                }}
                openEditModal={({ id, documentId, documentTenant, documentName }) => {
                  batch(() => {
                    setId(id)
                    setDocumentId(documentId)
                    setDocumentTenant(documentTenant)
                    setDocumentName(documentName)
                  })
                  editModalRef.current.showModal()
                }}
                openReimportGTNModal={({ id, documentId, documentTenant, documentName, documentVersion }) => {
                  batch(() => {
                    setId(id)
                    setDocumentId(documentId)
                    setDocumentTenant(documentTenant)
                    setDocumentName(documentName)
                    setDocumentVersion(documentVersion)
                  })
                  reimportGTN.current.showModal()
                }}
                openReimportGTNWhenPayrunIsUnlockedModal={({ id, documentId, documentTenant, documentName, documentVersion }) => {
                  batch(() => {
                    setId(id)
                    setDocumentId(documentId)
                    setDocumentTenant(documentTenant)
                    setDocumentName(documentName)
                    setDocumentVersion(documentVersion)
                  })
                  reimportGTNWhenPayrunIsUnlocked.current.showModal()
                }}
                openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal={() => reimportGTNUserNotAuthorizedToLockUnlockPayrun.current.showModal()}
                {...props}
                documentTypes={getDocumentTypesByTabName(tab.name)}
                tab={tab}
                tabIndex={tabIndex}
                onFileUploaded={(tabIndex) => handleOnFileUploaded(tabIndex)}
                documentId={documentId}
                refetchDocuments={() => dispatch(invalidateDocuments())}
              />
            </TabPanel>
          ))}
        </Tabs>
      )}

      {!tabs && (
        <DocumentsListContainer
          updateTitleForEmployee={typeClass === 'employee'}
          openConfirmShareModal={(id, docId, docTenant, docName, docTypeClass, docTypeId, isProcessOwnerAutoShared) =>
            showConfirmShareModal(id, docId, docTenant, docName, docTypeClass, docTypeId, isProcessOwnerAutoShared)
          }
          openShareModal={({ id, documentId, documentTenant, documentName }) => {
            batch(() => {
              setId(id)
              setDocumentId(documentId)
              setDocumentTenant(documentTenant)
              setDocumentName(documentName)
            })
            shareModalRef.current.showModal()
          }}
          openEditModal={({ id, documentId, documentTenant, documentName }) => {
            batch(() => {
              setId(id)
              setDocumentId(documentId)
              setDocumentTenant(documentTenant)
              setDocumentName(documentName)
            })
            editModalRef.current.showModal()
          }}
          openReimportGTNModal={({ id, documentId, documentTenant, documentName, documentVersion }) => {
            batch(() => {
              setId(id)
              setDocumentId(documentId)
              setDocumentTenant(documentTenant)
              setDocumentName(documentName)
              setDocumentVersion(documentVersion)
            })
            reimportGTN.current.showModal()
          }}
          openReimportGTNWhenPayrunIsUnlockedModal={({ id, documentId, documentTenant, documentName }) => {
            batch(() => {
              setId(id)
              setDocumentId(documentId)
              setDocumentTenant(documentTenant)
              setDocumentName(documentName)
              setDocumentVersion(documentVersion)
            })
            reimportGTNWhenPayrunIsUnlocked.current.showModal()
          }}
          openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal={() => reimportGTNUserNotAuthorizedToLockUnlockPayrun.current.showModal()}
          {...props}
        />
      )}
    </>
  )
}

DocumentsView.propTypes = {
  typeClass: PropTypes.string,
  dynamicTypeClass: PropTypes.string,
  companyName: PropTypes.string,
  countryName: PropTypes.string,
  payrollName: PropTypes.string,
  currentStep: PropTypes.string,
  payrunState: PropTypes.string,
  onZeroTouchSubmit: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  onModalStateChange: PropTypes.func,
  payrollInstanceId: PropTypes.number,
  modalState: PropTypes.object,
  hasUserLockUnlockPermission: PropTypes.bool,
}

export default DocumentsView
