import React from 'react'
import { connect } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import { isCot, isCotOrEngineerOrAdmin, isVendorInvolved } from 'redux/selectors/auth'
import { getFilteredNotificationsDecorated } from 'redux/selectors/notifications'
import { downloadDocumentByUrl } from 'redux/actions/document'
import NotificationView from '../components/NotificationView'
import { createFilter } from '../../../utils/redux/filter'
import Loader from 'components/Loader'
import { fetchNotifications } from '../../../redux/actions/notifications'
import { getParentCompaniesTenants, getVendorsTenants } from '../../../redux/selectors/tenants'
import { getCountries } from '../../../redux/selectors/country'
import { getVendorUser } from 'redux/selectors/vendorUsers'
import { selectTenant } from 'redux/actions/vendorTenant'
import { fetchParentCompaniesIfNeeded, invalidateParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { sortByName } from 'utils/strings'
import { getCotUser } from 'redux/selectors/cotUsers'

const filterName = 'notifications'

// On the FE the filters have one name, while on the BE is another one.
// We can unify the names later, because our goal is to refactor only the COS Alerts,
// without refactoring the Client/Vendor alerts, these are using the same names too.
const filtersMapping = {
  type: 'notificationName',
  status: 'createdAt',
  countryName: 'country',
}

const _createFilter = ({ page, limit, filters, state } = {}) => {
  limit = limit !== undefined ? limit : state.notifications.filters[filterName].params.limit
  page = page !== undefined ? page : state.notifications.filters[filterName].params.offset / limit
  filters = filters !== undefined ? filters : state.notifications.filters[filterName].params

  return createFilter({
    ...filters,
    offset: page * limit,
    limit,
    sort: [{ name: 'id', order: 'desc' }],
  }, filterName)
}

const invalidate = () => {
  return dispatch => {
    dispatch(invalidateParentCompaniesIfNeeded())
    dispatch(invalidatePayrollInstances())
  }
}

const fetch = () => {
  return dispatch => {
    dispatch(fetchParentCompaniesIfNeeded())
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  downloadDocument: url => dispatch(downloadDocumentByUrl(url)),
  onFilter: ({ page = 0, limit, filters }) => dispatch((dispatch, getState) => (
    dispatch(fetchNotifications({
      filter: _createFilter({
        page,
        limit,
        // Normalize query filter parameters (their names must match the BE API)
        ...filters && {
          filters: Object.keys(filters).reduce((normalized, filterName) => {
            // Set the correct filter name, expected by the BE API
            const name = filtersMapping[filterName] || filterName

            // Extract the filter's value
            normalized[name] = filters[filterName].value
            return normalized
          }, {})
        },
        state: getState()
      }),
      disableObsoleteFlow: true
    }))
  )),
  selectTenant: data => {
    dispatch(selectTenant(data))
    dispatch(fetch())
  },
  resetTenant: () => {
    dispatch(invalidate())
    dispatch(selectTenant({ url: null, schema: null }))
  },
  invalidate: () => dispatch(invalidate()),
  dispatch
})
const mapStateToProps = state => {
  const isFetchedAtLeastOnce = state.notifications.filters[filterName] &&
    state.notifications.filters[filterName].ids &&
    state.cotUsers.filters['/'] &&
    state.cotUsers.filters['/'].ids

  if (!isFetchedAtLeastOnce) return { isInitialFetching: true }

  const { totalCount, params: { offset, limit } } = state.notifications.filters[filterName]

  const parentCompaniesTenants = getParentCompaniesTenants(state)
  const vendorsTenants = getVendorsTenants(state)
  const hasVendorAccess = isVendorInvolved(state)

  let vendorUser
  let vendorId
  if (hasVendorAccess) {
    vendorUser = getVendorUser(state, { userId: state.auth.userId })
    vendorId = vendorUser.vendor
  }
  const notifications = getFilteredNotificationsDecorated(state, { filter: filterName })
  // There are some scenarios where the COT (user) does not have access to a particular tenant.
  // In this case we want to hide that particular notifications
  const filteredOutTenants = notifications.filter(({ extraData }) => parentCompaniesTenants.find(t => t.name === extraData.client))
  return {
    notifications: isCot(state) ? filteredOutTenants : notifications,
    countries: getCountries(state).sort(sortByName),
    tenants: [
      { name: 'Clients', disabled: true },
      ...parentCompaniesTenants,
      { name: 'Vendors', disabled: true },
      ...vendorsTenants
    ],
    isCotUser: isCot(state),
    isCotOrEngineerOrAdminUser: isCotOrEngineerOrAdmin(state),
    cotUserSpecialRights: isCotOrEngineerOrAdmin(state) && getCotUser(state, { userId: state.auth.userId }).cotSpecialRight,
    isVendor: hasVendorAccess,
    vendorUser: vendorUser || null,
    vendorId: vendorId || null,
    pagination: {
      totalPages: Math.ceil(totalCount / limit),
      currentPage: offset / limit,
      limit,
      totalCount: totalCount
    },
  }
}

// TODO - once Pay & Taxes refactoring is merged into develop/release, we can reuse ConditionalLoader component from it.
const ConditionalLoader = (Component, conditionalProp) => props => (
  props[conditionalProp] ? <Loader /> : <Component {...props} />
)

const Container = connect(mapStateToProps, mapDispatchToProps)(ConditionalLoader(
  NotificationView, 'isInitialFetching')
)

export default Fetcher(Container, [
  'parentCompanies',
  'vendors',
  'countries',
  {
    name: 'notifications',
    params: [{
      _computed: {
        filter: state => {
          const isFetchedAtLeastOnce = state.notifications.filters[filterName] &&
          state.notifications.filters[filterName].ids

          // If notifications are already fetched (for example in the case the notifications are invalidated),
          // we don't pass any filter params and we expect/delegate `_createFilter` to refetch the notifications
          // using the filter parameters already cached in the Store/state.
          if (isFetchedAtLeastOnce) return _createFilter({ state })

          // On initial loading (notifications are not fetched at least once), then we predefine the filter parameters
          return _createFilter({ page: 0, limit: 10, filters: {} })
        }
      },
      disableObsoleteFlow: true
    }]
  }], { renderWhileFetching: true, showLoader: true })
