import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/vendorCountryServicePivot'

class VendorCountryService extends CRUDModel {}
VendorCountryService.modelName = 'VendorCountryService'
VendorCountryService.actionTypes = actionTypes

VendorCountryService.fields = {
  id: attr(),
  countryService: fk('CountryService', 'vendorCountryServices'),
  vendor: fk('Vendor', 'vendorCountryServices')
}

export default VendorCountryService
