export const vendorAccessControlLabels = {
  title: 'Access control',
}

export const vendorCompanyDetailsLabels = {
  name: 'Name',
  vendorName: 'Vendor Name',
  status: 'Status',
  companyRegNumber: 'Company Registration Number',
  address: 'Address',
  registeredAddress: 'Registered Address',
  website: 'Website',
  hqCountry: 'HQ Country',
  holidaysCalendar: 'Holidays Calendar',
  payrollSoftware: 'Payroll Software',
  senderService: 'Sender Service',
  dataProtection: 'Data Protection',
  professionalIndemnity: 'Professional Indemnity',
}

export const vendorColumnLabels = {
  name: 'Name',
  status: 'Status',
  mobilePhone: 'Mobile Phone',
  workEmail: 'Email',
  position: 'Position',
  country: 'Country',
  roleType: 'Role',
  accessArea: 'Access area',
  action: 'Action',
}
