import Fetcher from './Fetcher'
import { connect } from 'react-redux'
import EmergencyPersonView from 'components/EmergencyPersonView'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'

/**
 * Get EmergencyPerson,
 * according to router params, as `companyId` and `employeeId`.
 *
 * If we're loading the component in the Company routes,
 * then we get the accounts for the current Company.
 * If the Company is HQ, then we return all EmergencyPerson.
 *
 * If we're loading the component in the Employee routes,
 * then we get the accounts for the current Employee.
 *
 * @param state
 * @param props
 * @returns {*}
 * @private
 */

// map to state
const mapStateToProps = (state, props) => {
  return {
    showText: props.match.params.employeeId
  }
}

export default Fetcher(connect(mapStateToProps)(EmergencyPersonView), [
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
