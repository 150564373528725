import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import SectionHeading from 'components/SectionHeading'
import { Link } from 'react-router-dom'
import PayslipTable from 'components/table/PayslipTable'
class PayrollUpdatesList extends React.Component {
  renderRows () {
    return this.props.payrollInstances.map((instance) => ({
      payrollName: instance.payrollName,
      fromDate: moment(instance.fromDate.date).format('DD/MM/YYYY'),
      toDate: moment(instance.toDate.date).format('DD/MM/YYYY'),
      payDate: moment(instance.payDate.date).format('DD/MM/YYYY'),
      file: (
        <div className='o-grid o-grid--middle o-grid--center'>
          <Link to={`/payruns/active/${instance.instanceId}/instance?employeeId=${instance.employeeId}`} className='icon--doc' data-testid='employee-payfile' />
        </div>
      ),
    }))
  }

  render () {
    const { isFetching } = this.props

    if (isFetching) return <div>Loading...</div>

    if (this.props.payrollInstances.length === 0) {
      return (
        <div className='u-text-center'>
          <p>No payroll updates found</p>
        </div>
      )
    }

    return (
      <div>
        <SectionHeading text='Payroll Updates' />

        <PayslipTable
          wrapperClassName='u-1/1'
          tableElementClassName='u-margin-bottom-large table--layout-auto'
          data-testid='employee-payroll-updates'
          headings={[
            {
              Header: 'Payroll',
              accessor: 'payrollName',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: 'From date',
              accessor: 'fromDate',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: 'To date',
              accessor: 'toDate',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: 'Pay date',
              accessor: 'payDate',
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: 'Payrun file',
              classNames: 'text-center',
              accessor: 'file',
              disableSortBy: true,
              disableFilters: true,
            },
          ]}
          data={this.renderRows()}
        />
      </div>
    )
  }
}

PayrollUpdatesList.propTypes = {
  payrollInstances: PropTypes.array,
}

export default PayrollUpdatesList
