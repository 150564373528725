import React from 'react'
import FixedPayElementsContainer from 'containers/FixedPayElementsContainer'
import VariablePayElementsContainer from 'containers/VariablePayElementsContainer'
import Expandable from './Expandable'

export default ({ companyId, disabled, disabledReason, fixedHeading, variableHeading,
  className = 'u-1/1 u-1/2@desktop', itemsClassName }) => {
  const props = { companyId, disabled, disabledReason, className: itemsClassName }

  return <div>
    <Expandable text={fixedHeading}>
      <div className='o-layout'>
        <div className={`o-layout__item ${className}`}>
          { companyId &&
            <FixedPayElementsContainer {...props} /> }
        </div>
      </div>
    </Expandable>
    <Expandable text={variableHeading} isExpanded={false}>
      <div className='o-layout'>
        <div className={`o-layout__item ${className}`}>
          { companyId &&
            <VariablePayElementsContainer {...props} /> }
        </div>
      </div>
    </Expandable>
  </div>
}
