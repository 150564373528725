import React, { useContext, useEffect } from 'react'
import Loader from 'components/Loader'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginByRHSSOTokens } from 'redux/actions/auth'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'
import { useHistoryProxy } from 'utils/history'

import { getStorageItem, removeItemFromLocalStorage, setStorageItem } from 'utils/storage'

const KeyCloakSignIn = ({ isLoggedIn }) => {
  const kc = useContext(KeycloakCTX)
  const dispatch = useDispatch()
  const history = useHistoryProxy(useHistory())
  const entryRoute = getStorageItem('entryRoute') || '/home'

  if (isLoggedIn) history.enhancedPush('/home')

  useEffect(() => {
    if (kc?.authenticated) {
      const tokens = {
        token: kc.token,
        refresh_token: kc.refreshToken,
        tokenParsed: kc.tokenParsed,
      }
      dispatch(loginByRHSSOTokens(tokens))
      removeItemFromLocalStorage('entryRoute')
      history.enhancedPush(entryRoute)
    }
  }, [kc])
  return <div />
}

const RouteViewContainer = () => {
  const configs = useSelector((state) => state.config)
  const isLoggedIn = useSelector((state) => state.auth.accessToken)
  const location = useLocation()
  const entryRoute = location?.state?.entryRoute

  useEffect(() => {
    if (entryRoute) setStorageItem('entryRoute', entryRoute)
  }, [])

  if (!configs.tenantType) return <Loader />
  return <KeyCloakSignIn configs={configs} isLoggedIn={isLoggedIn} />
}

export default RouteViewContainer
