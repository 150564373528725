import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/taxAgencies'

class TaxAgency extends CRUDModel {}
TaxAgency.modelName = 'TaxAgency'

TaxAgency.fields = {
  id: attr(),
  country: fk('Country', 'taxAgencies'),
}
TaxAgency.actionTypes = actionTypes

export default TaxAgency
