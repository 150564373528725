import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyProcessPivot'

class CompanyCountryProcess extends CRUDModel {}
CompanyCountryProcess.modelName = 'CompanyCountryProcess'
CompanyCountryProcess.actionTypes = actionTypes

CompanyCountryProcess.fields = {
  id: attr(),
  companyService: fk('CompanyCountryService', 'companyCountryProcesses'),
  countryProcess: fk('CountryProcess', 'companyCountryProcesses'),
  processOwner: fk('EmployeeSystemUser', 'companyCountryProcesses'),
}

export default CompanyCountryProcess
