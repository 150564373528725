import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/cotUsers'
import { getName } from './user'

class CotUser extends CRUDModel {
  getName () {
    return getName(this)
  }
}
CotUser.modelName = 'CotUser'

CotUser.fields = {
  id: attr(),
  country: fk('Country', 'cotUsers'),
}

CotUser.actionTypes = actionTypes

export default CotUser
