import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PROJECTS_FETCH = 'PROJECTS_FETCH'
export const PROJECTS_RECEIVE = 'PROJECTS_RECEIVE'
export const PROJECTS_CREATE = 'PROJECTS_CREATE'
export const PROJECTS_UPDATE = 'PROJECTS_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PROJECTS_FETCH,
  receive: PROJECTS_RECEIVE,
  create: PROJECTS_CREATE,
  update: PROJECTS_UPDATE
}
const actionUtility = new ActionUtility(actionTypes, 'projects', 'projects', 'Project')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchProjectsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchProjects = actionUtility.fetchEntities
export const createProject = actionUtility.createEntity
export const updateProjects = actionUtility.bulkUpdateEntity
