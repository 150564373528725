import { createFilter } from 'utils/redux/filter'

export const PAYROL_FILTER_NAME = 'FILTERED_PAYROLLS'
export const PAYRUN_FILTER_NAME = 'FILTERED_PAYRUNS'

export const BASE_PAYRUN_STATUSES = [
  'active',
  'sent-and-locked',
  'gross-to-net-calculations-and-review-done',
  'cot-final-payroll-reports-review-done',
  'completed',
  'completed-with-extra-tasks',
  'reopened',
  'reverted',
]

/**
 * Creates a payroll filter object with the specified parameters.
 *
 * @param {string} name - The name of the payroll filter.
 * @param {Object} options - The options for the payroll filter.
 * @param {string} options.fromDate - The start date for the payroll filter.
 * @param {string} options.toDate - The end date for the payroll filter.
 * @param {string} options.company - The company for the payroll filter.
 * @returns {Object} The created payroll filter object.
 */
export const createPayrollFilter = (name, { fromDate, toDate, company }) => {
  return createFilter(
    {
      reportFromDate: fromDate,
      reportToDate: toDate,
      company,
      sort: [{ name: 'id', order: 'desc' }],
    },
    name
  )
}

/**
 * Creates a payrun filter object with the specified parameters.
 *
 * @param {string} name - The name of the payrun filter.
 * @param {Object} options - The options for the payrun filter.
 * @param {string} options.fromDate - The start date for the payrun filter.
 * @param {string} options.toDate - The end date for the payrun filter.
 * @param {string} options.payroll - The payroll identifier for the payrun filter.
 * @param {string} options.status - The status of the payrun filter.
 * @returns {Object} The created payrun filter object.
 */
export const createPayrunFilter = (name, { fromDate, toDate, payroll, status }) => {
  return createFilter(
    {
      reportFromDate: fromDate,
      reportToDate: toDate,
      payroll,
      status: status ?? BASE_PAYRUN_STATUSES,
      sort: [{ name: 'id', order: 'desc' }],
    },
    name
  )
}
