import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'
import { fetchCompanies } from 'routes/Companies/modules/actions'

// ------------------------------------
// Constants
// ------------------------------------
export const DATA_OWNERS_FETCH = 'DATA_OWNERS_FETCH'
export const DATA_OWNERS_RECEIVE = 'DATA_OWNERS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: DATA_OWNERS_FETCH,
  receive: DATA_OWNERS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'dataOwners', 'dataowners', 'DataOwner')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchDataOwnersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchDataOwners = actionUtility.fetchEntities

/*
 * Update data protection policy data
 */
export const updateDataProtectionPolicy = (entity, companyId) => {
  const url = `companysensitivefields/${companyId}`

  return (dispatch, getState, { api }) => {
    return api.patch(url, { payload: entity })
      .then(errorsHandling.handleFormErrors)
      .then(response => {
        dispatch(fetchDataOwners())
        dispatch(fetchCompanies())
        return response
      })
  }
}
