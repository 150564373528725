import React from 'react'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import Employee from './Employee'

export const FieldEmployees = ({ slicedEmployeeSystemUsers }) => {
  return <div className='o-layout u-margin-top-large o-grid--strech'>
    { slicedEmployeeSystemUsers.map((employee, index) => (
      <Employee key={index} employee={employee}>
        <Field
          type='checkbox'
          labelClassName='u-hidden'
          component={CheckboxField}
          className='control__indicator__input'
          name={`id-${employee.id}`}
          indicatorClassName='control__indicator--center'
          data-testid={`employee-checkbox-${employee.id}`}
        />
      </Employee>
    )) }
  </div>
}

export default FieldEmployees
