import createSelector from 'utils/createSelector'
import _ from 'lodash'
import moment from 'moment'

const getCompanyId = (state, props) => parseInt(props.companyId)
// get company holiday id from props
const getCompanyHolidayId = (state, props) => parseInt(props.companyHolidayId)

export const getCompanyHolidayPivotByCompany = createSelector(getCompanyId,
  ({ CompanyHoliday }, companyId) => {
    const companyHolidays = CompanyHoliday.filter({ company: companyId }).toRefArray().map(ch => ({ ...ch }))

    // Order holidays by date
    return _.orderBy(companyHolidays, ['date["date"]'], ['asc'])
  }
)

export const getCompanyHolidayPivotByCompanyGroupByYear = createSelector(getCompanyId,
  ({ CompanyHoliday }, companyId) => {
    const companyHolidays = CompanyHoliday.filter({ company: companyId }).toRefArray().map(ch => ({ ...ch }))

    // Get list of years, sort desc
    const years = Array.from(new Set(companyHolidays.map(holiday => (new Date(holiday.date.date)).getFullYear())))
      .sort((a, b) => b - a)

    // Group holidays by years
    let groupedHolidays = []
    years.forEach(year =>
      groupedHolidays.push({
        year: year,
        data: companyHolidays.filter(
          (holiday) => new Date(holiday.date.date).getFullYear() === year
        ),
      })
    )

    // Sort asc arrays grouped by year
    groupedHolidays.forEach(hol => hol.data.sort((a, b) => new Date(a.date.date) - new Date(b.date.date)))

    return groupedHolidays
  }
)

// Here we get only the formatted holiday dates (without the rest properties)
export const getCompanyHolidayDatesByCompany = createSelector(getCompanyHolidayPivotByCompany,
  (session, holidays) => holidays
    .filter(h => !h.isWorkingDay)
    .map(holiday => moment(holiday.date.date).format('YYYY-MM-DD'))
)

// get holiday by id
export const getCompanyHolidayById = createSelector(getCompanyHolidayId,
  ({ CompanyHoliday }, companyHolidayId) => {
    const holiday = CompanyHoliday.withId(companyHolidayId)
    return {
      ...holiday.ref
    }
  }
)
