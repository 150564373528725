import createSelector from 'utils/createSelector'
import { employeeFieldsConfig } from 'redux/config/employeeFieldsConfig'

const getCountryId = (state, props) => parseInt(props.countryId)
const getCountryDataProtectionFieldId = (state, props) => parseInt(props.countryDataFieldId)

export const getCountryDataProtectionFields = createSelector(getCountryId,
  ({ CountryDataProtectionField }, countryId) => {
    let fields = []
    employeeFieldsConfig.map(cat => {
      fields.push({
        category: cat.category,
        categoryName: cat.categoryName,
        fields: CountryDataProtectionField.all()
          .toModelArray()
          .filter(field => {
            if (field.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
              return cat.fields.find(f => f.field === field.dataProtectionField.ref.entityField) &&
            field.country === countryId
            }
            if (field.dataProtectionField.ref.entityClass.includes('TermCategory')) {
              return cat.fields.find(f => f.field === field.dataProtectionField.ref.fieldValue) &&
            field.country === countryId
            }
          })
          .map(field => {
            let name
            if (field.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.ref.entityField).name
            }
            if (field.dataProtectionField.ref.entityClass.includes('TermCategory')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.ref.fieldValue).name
            }
            return {
              ...field.ref,
              entityField: field.dataProtectionField.ref.entityField,
              name
            }
          })
      })
    })

    return fields
  })

export const getSensitiveCountryDataProtectionFields = createSelector(getCountryId,
  ({ CountryDataProtectionField }, countryId) => {
    let fields = []
    employeeFieldsConfig.map(cat => {
      fields.push({
        category: cat.category,
        categoryName: cat.categoryName,
        fields: CountryDataProtectionField.all()
          .toModelArray()
          .filter(field => {
            if (field.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
              return cat.fields.find(f => f.field === field.dataProtectionField.ref.entityField) &&
            field.country === countryId && field.isSensitive
            }
            if (field.dataProtectionField.ref.entityClass.includes('TermCategory')) {
              return cat.fields.find(f => f.field === field.dataProtectionField.ref.fieldValue) &&
            field.country === countryId && field.isSensitive
            }
          })
          .map(field => {
            let name
            if (field.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.ref.entityField).name
            }
            if (field.dataProtectionField.ref.entityClass.includes('TermCategory')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.ref.fieldValue).name
            }
            return {
              ...field.ref,
              entityField: field.dataProtectionField.ref.entityField,
              name
            }
          })
      })
    })

    return fields
  })
export const getCountryDataProtectionFieldById = createSelector(getCountryDataProtectionFieldId,
  ({ CountryDataProtectionField }, countryDataFieldId) => {
    const dataField = CountryDataProtectionField.withId(countryDataFieldId)

    let employeeDataFieldCat
    let employeeDataFieldObj

    if (dataField.dataProtectionField.ref.entityClass.includes('EmployeeUser')) {
      employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === dataField.dataProtectionField.ref.entityField))
      employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === dataField.dataProtectionField.ref.entityField)
    }
    if (dataField.dataProtectionField.ref.entityClass.includes('TermCategory')) {
      employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === dataField.dataProtectionField.ref.fieldValue))
      employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === dataField.dataProtectionField.ref.fieldValue)
    }

    return {
      ...dataField.ref,
      name: employeeDataFieldObj.name,
      dataProtectionField: dataField.dataProtectionField.ref
    }
  }
)

export const getDataProtectionFieldsNotApprovedByCountry = createSelector(getCountryDataProtectionFields,
  (session, countryDataProtectionFields) => {
    let fields = []
    countryDataProtectionFields.forEach(countryCat => {
      // Find the matching category
      fields.push({
        category: countryCat.category,
        categoryName: countryCat.categoryName,
        fields: countryCat.fields.filter(field => {
          // Filter all fields that are marked as not sensitive
          return !field.isSensitive
        }).map(field => ({ ...field, id: field.dataProtectionField }))
      })
    })
    return fields
  })

export const getNotApprovedFieldsInitialValues = createSelector(getDataProtectionFieldsNotApprovedByCountry,
  (session, fullList) => {
    let initialValues = {}
    fullList.map(cat => {
      cat.fields.forEach(field => {
        initialValues[`id-${field.id}`] = false
      })
    })

    return initialValues
  })
