import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/document'

class Document extends CRUDModel {}
Document.modelName = 'Document'

Document.fields = {
  id: attr()
}
Document.actionTypes = actionTypes

export default Document
