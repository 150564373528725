import React, { useContext, useEffect, useState } from 'react'
import Flag from 'components/Flag'
import NumberFormat from 'react-number-format'
import { amountTransformer } from 'redux/transformers/amountsTransformer'
import { payrollSummaryDisclaimers, payrollSummaryItems } from 'utils/locales/payrollSummary.en'
import { buttonActions } from 'utils/locales/common.en'
import { PayrollSummaryContext, summaryViewToggleIds } from '../PayrollSummaryContext'

const Item = ({
  label,
  value,
  icon,
  onPayrunValueChange,
  onPayrunValueSave,
  onPayrunValueEdit,
  showInputAndSave,
  currentPayrunValue,
  isUpdatePayrunTotalsDisabled,
}) => {
  if (label === payrollSummaryItems.currentPayrun) {
    return (
      <div className='d-flex'>
        <span className='u-text--normal u-text--valhalla u-weight--regular u-min-width-200'>{label}</span>
        {showInputAndSave ? (
          <>
            <NumberFormat
              value={currentPayrunValue}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              onChange={(e) => onPayrunValueChange(e.currentTarget.value)}
              className='c-input c-inline-edit-input c-input--inline c-input--current-payrun rounded'
            />
            <button
              className='c-btn c-btn--tiniest c-btn--curious u-text--tiny u-margin-left-tiny u-align-self--center'
              title={buttonActions.save}
              onClick={onPayrunValueSave}
            >
              <span className='icon icon--save' data-testid='submit-button' />
            </button>
          </>
        ) : (
          <>
            <span className='u-text--normal u-text--valhalla u-weight--bold u-margin-right'>{value}</span>
            <button
              className='c-btn c-btn--tiniest c-btn--curious u-text--tiny u-margin-left u-align-self--center'
              disabled={isUpdatePayrunTotalsDisabled}
              title={isUpdatePayrunTotalsDisabled ? payrollSummaryDisclaimers.editDisabledMessage : buttonActions.edit}
              onClick={onPayrunValueEdit}
            >
              <span className='icon icon--edit' data-testid='edit-button' />
            </button>
          </>
        )}
      </div>
    )
  }
  return (
    <div className='d-flex'>
      <span className='u-text--normal u-text--valhalla u-weight--regular u-min-width-200'>{label}</span>
      {icon}
      <span className='u-text--normal u-text--valhalla u-weight--bold'>{value}</span>
    </div>
  )
}

const SummaryDisplayCard = ({ data, onUpdatePayrunTotals, isUpdatePayrunTotalsDisabled }) => {
  const [val, setVal] = useState(data.currentPayrun)
  const [showInputAndSave, setShowInputAndSave] = useState(false)
  const onSave = async () => {
    const newData = {
      userDefinedPayrunValue: parseFloat(amountTransformer(val)),
    }
    await onUpdatePayrunTotals(newData)
    setShowInputAndSave(false)
  }
  const onEditPress = () => {
    setShowInputAndSave(!showInputAndSave)
  }

  const { setGlobalUpdateMode } = useContext(PayrollSummaryContext)

  useEffect(() => {
    setGlobalUpdateMode(summaryViewToggleIds.overview, showInputAndSave)
  }, [showInputAndSave])

  const { country, companyCode, payroll, currency, headcount, payrollPeriod, payDate, currentPayrun, previousPayrun, payrunDifference } = data

  const firstColumn = [
    { label: payrollSummaryItems.country, value: country },
    { label: payrollSummaryItems.companyCode, value: companyCode },
    { label: payrollSummaryItems.payroll, value: payroll },
    { label: payrollSummaryItems.currency, value: currency },
    {
      label: payrollSummaryItems.headcount,
      value: headcount,
      icon: <span className='icon icon--users u-margin-right-small' />,
    },
  ]
  const secondColumn = [
    { label: payrollSummaryItems.payrollPeriod, value: payrollPeriod },
    { label: payrollSummaryItems.payDate, value: payDate },
    { label: payrollSummaryItems.currentPayrun, value: currentPayrun },
    { label: payrollSummaryItems.previousPayrun, value: previousPayrun },
    { label: payrollSummaryItems.difference, value: payrunDifference },
  ]

  return (
    <div className='c-display-cards shadow rounded border u-padding u-padding-bottom d-flex d-flex--wrap'>
      <Flag flag={data.countryAbbr} size='huge' classes='u-margin u-margin-right-large' />
      <div className='d-flex d-flex--col u-margin u-margin-right-large'>
        {firstColumn.map((item) => (
          <Item label={item.label} value={item.value} icon={item?.icon} key={item.label} />
        ))}
      </div>
      <div className='border-1-pale--left u-margin-right-large' />
      <div className='d-flex d-flex--col u-margin'>
        {secondColumn.map((item) => (
          <Item
            label={item.label}
            value={item.value}
            onPayrunValueChange={setVal}
            onPayrunValueSave={onSave}
            onPayrunValueEdit={onEditPress}
            showInputAndSave={showInputAndSave}
            currentPayrunValue={val}
            isUpdatePayrunTotalsDisabled={isUpdatePayrunTotalsDisabled}
            key={item.label}
          />
        ))}
      </div>
    </div>
  )
}

export default SummaryDisplayCard
