// We only need to import the modules necessary for initial render
import HomeRoute from './Home'
import DashboardRoute from './Dashboard'
import Pure from './Pure'
import ColorPicker from './ColorPicker'
import CountriesRoute from './Countries'
import TermsRoute from './Terms'
import TermSubcategoriesRoute from './TermSubcategories'
import ServicesRoute from './Services'
import ProcessesRoute from './Processes'
import StepsRoute from './Steps'
import TasksRoute from './Tasks'
import CompaniesRoute from './Companies'
import PayrunsRoute from './Payruns'
import EmployeesRoute from './Employees'
import ToolboxRoute from './Toolbox'
import VendorsRoute from './Vendors'
import VendorsRedirect from './VendorsRedirect'
import UsersRoute from './CotUsers'
import LoginRoute from './Login'
import SingleSignOn from './SingleSignOn'
import RHSSOLoginRoute from './RHSSO'
import ForgottenPasswordRoute from './ForgottenPassword'
import ResetPasswordRoute from './ResetPassword'
import ResetPasswordConfirmationRoute from './ResetPasswordConfirmation'
import UserActivationRoute from './UserActivation'
import AuthCodeRoute from './AuthCode'
import SettingsRoute from './Settings'
import PayrollsRoute from './Payrolls'
import PaygroupsRoute from './Paygroups'
import ClientsRoute from './Clients'
import ReportingRoute from './Reporting'
import ReportAnalyticsRoute from './ReportingAnalytics'
import ReportsRoute from './Reports'
import ImportRoute from './Import'
import GlobalDataProtection from './GlobalDataProtection'
import NotificationsRoute from './Notifications'
import ChangePasswordRoute from './ChangePassword'
import PrivacySecurityRoute from './PrivacySecurity'
import ResourcesRoute from './Resources'

import LoginContainer from './Login/containers/LoginContainer'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = (store) => ({
  path: '/',
  indexRoute: {
    component: LoginContainer,
  },
  childRoutes: [
    HomeRoute(store),
    ColorPicker(store),
    DashboardRoute(store),
    Pure(store),
    CountriesRoute(store),
    TermsRoute(store),
    TermSubcategoriesRoute(store),
    ServicesRoute(store),
    ProcessesRoute(store),
    StepsRoute(store),
    TasksRoute(store),
    CompaniesRoute(store),
    PayrunsRoute(store),
    EmployeesRoute(store),
    ToolboxRoute(store),
    VendorsRoute(store),
    VendorsRedirect(store),
    UsersRoute(store),
    LoginRoute(store),
    SingleSignOn(store),
    RHSSOLoginRoute(store),
    ForgottenPasswordRoute(store),
    ResetPasswordRoute(store),
    ResetPasswordConfirmationRoute(store),
    UserActivationRoute(store),
    AuthCodeRoute(store),
    SettingsRoute(store),
    PayrollsRoute(store),
    PaygroupsRoute(store),
    ClientsRoute(store),
    ReportingRoute(store),
    ReportAnalyticsRoute(store),
    ReportsRoute(store),
    ImportRoute(store),
    GlobalDataProtection(store),
    NotificationsRoute(store),
    ChangePasswordRoute(store),
    PrivacySecurityRoute(store),
    ResourcesRoute(store),
  ],
})

export default createRoutes
