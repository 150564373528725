import React from 'react'

import { CommentIcon } from 'components/icons/CommentIcon'
import { isEmpty } from 'lodash'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import { payrollSummaryTotalsTable } from 'utils/locales/payrollSummary.en'
import { buildSelectOpt } from 'utils/options'
import { conditionalBoldValue } from 'utils/tableDataFormatters'
import { tablePlaceholderLabels } from 'utils/locales/table.en'

const needsBoldValidation = (row) => isEmpty(row?.subcategory)

export const generateTableHeadings = ({ viewBy }) => {
  const extraHeadings = []
  if (viewBy?.value === 'subcategory') {
    extraHeadings.push(
      new TableHeading({
        accessor: 'subcategory',
        heading: payrollSummaryTotalsTable.subcategory,
        className: 'text-left',
        columnClassName: 'text-left',
        Cell: conditionalBoldValue(needsBoldValidation),
        disableSortBy: false,
      })
    )
  }

  return [
    new TableHeading({
      accessor: 'category',
      heading: payrollSummaryTotalsTable.category,
      className: 'text-left',
      columnClassName: 'text-left',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    ...extraHeadings,
    new TableHeading({
      accessor: 'currentTotal',
      heading: payrollSummaryTotalsTable.currentPayrun,
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'previousTotal',
      heading: payrollSummaryTotalsTable.previousPayrun,
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      accessor: 'variance',
      heading: payrollSummaryTotalsTable.variance,
      className: 'text-right',
      columnClassName: 'text-right',
      Cell: conditionalBoldValue(needsBoldValidation),
      disableSortBy: false,
    }),
    new TableHeading({
      id: 'body',
      expander: true,
      heading: payrollSummaryTotalsTable.comments,
      editable: true,
      inputType: 'textArea',
      charactersPerLine: 180,
      focusableIndex: 0,
      disableSortBy: false,
      placeholder: tablePlaceholderLabels.leaveAcomment,
      Cell: ({ row }) => {
        const hasComment = !isEmpty(row?.original?.body)
        return row.canExpand ? (
          <div className='d-flex jc--center ai-center'>
            <CommentIcon className='u-relative' stroke='var(--ps-color-alternative)'>
              {hasComment && <span className='c-notification__indicator u-bg--supernova' />}
            </CommentIcon>
          </div>
        ) : null
      },
    }),
  ]
}

export const getViewByOpts = () =>
  buildSelectOpt([
    {
      id: 'category',
      name: payrollSummaryTotalsTable.category,
    },
    {
      id: 'subcategory',
      name: payrollSummaryTotalsTable.subcategory,
    },
  ])
