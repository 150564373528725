import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import { Field } from 'redux-form'
import SwitchToggle from 'components/form/SwitchToggle'
import _ from 'lodash'
import PayslipTable from './table/PayslipTable'
const classNames = require('classnames')

class FormFieldsGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // Show/Hide fields
      // By default only first group of fields is visible
      expandFields: props.index === 0
    }
  }

  /*
   * Arrow classes by toggling filters
   */
  getArrowClasses () {
    return classNames({
      'icon--arrow u-float--left u-margin-left-tiny u-margin-right-tiny': true,
      'active': this.state.expandFields
    })
  }

  /*
   * Toggle fields
   */
  toggleFields () {
    this.setState({
      expandFields: !this.state.expandFields
    })
  }

  buildHeadings () {
    const { isApproveModal } = this.props

    let headings = []

    if (isApproveModal) {
      // If this component is rendered inside approve fields modal,
      // then render table with 3 columns, including country isSensitive value
      headings.push(
        {
          accessor: 'name',
          Header: 'Field name',
          isKey: true,
          disableSortBy: true,
          disableFilters: true
        },
        {
          accessor: 'isSensitive',
          Header: 'Country value',
          disableSortBy: true,
          disableFilters: true
        },
        {
          accessor: 'isApproved',
          Header: 'Your current approved list',
          disableSortBy: true,
          disableFilters: true
        }
      )
    } else {
      // If this component is not rendered inside approve fields modal,
      // then render table with just one column including field name and isApproved value
      headings.push({
        accessor: 'field',
        Header: 'Field name',
        isKey: true,
        disableSortBy: true,
        disableFilters: true
      })
    }

    return headings
  }

  buildData (data) {
    const { isApproveModal, disabled, disabledReason } = this.props

    if (isApproveModal) {
      return data.map((field) => ({
        name: <span className='u-text--normal'>{field.name}</span>,
        isSensitive: (
          <Field
            name={`isSensitive-${field.id}`}
            component={SwitchToggle}
            labelClassName='c-label u-text--small'
            disabled // Should always be disabled
            disabledReason='You cannot change country value'
          />
        ),
        isApproved: (
          <Field
            name={`id-${field.id}`}
            component={SwitchToggle}
            labelClassName='c-label u-text--small'
            disabled={disabled}
            disabledReason={disabledReason}
          />
        ),
      }))
    } else {
      return data.map((field) => ({
        field: (
          <div className='o-layout'>
            <div className='o-layout__item u-2/3'>
              <span className='u-text--normal'>{field.name}</span>
            </div>
            <div className='o-layout__item u-1/3'>
              <Field
                name={`id-${field.id}`}
                component={SwitchToggle}
                labelClassName='c-label u-text--small'
                disabled={disabled}
                disabledReason={disabledReason}
              />
            </div>
          </div>
        ),
      }))
    }
  }

  render () {
    const { categoryName, fields } = this.props
    return (
      <div className='page--form-fields-group'>
        <div className={`u-1/1 u-bg--white u-margin-bottom-small border-1-valhalla rounded ${this.state.expandFields ? 'u-bg--lilac' : ''}`}>
          <SectionHeading
            text={categoryName}
            className=' u-padding-tiny u-margin-bottom-none'
          >
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <span
                  onClick={() => this.toggleFields()}
                  className={this.getArrowClasses()}
                />
              </div>
            </div>
          </SectionHeading>
          {this.state.expandFields ? (
            !_.isEmpty(fields) ? (
              <div className='u-padding-small'>
                <PayslipTable
                  data={this.buildData(fields)}
                  headings={this.buildHeadings()}
                  wrapperClassName='u-1/1 u-margin-bottom'
                  modifierClasses='u-margin-top'
                  trClassName='react-bs-table__cell--whitespace--normal'
                />
              </div>
            ) : (
              <p className='u-margin-bottom u-padding-left-tiny'>
                No data protection fields from this category.
              </p>
            )
          ) : null}
        </div>
      </div>
    )
  }
}

FormFieldsGroup.propTypes = {
  categoryName: PropTypes.string,
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  isApproveModal: PropTypes.bool,
  index: PropTypes.number,
}

export default FormFieldsGroup
