import React from 'react'

import CheckboxWrapper from 'components/form/CheckboxWrapper'
import { CheckIconAlt } from 'components/icons/CheckIcons'
import { platformColors } from 'utils/colors'
import { sortStrings } from 'utils/fnkit/sort'
import { isEmpty } from 'lodash'
import { placeholders } from '../../../../../locales/common.en'

export const DualSelectorItem = ({ selected, onSelectItem, showCheckIcon, ...item }) => (
  <div
    className={`d-flex ai-center jc--space-between dual-selector-widget--item ${selected ? 'dual-selector-widget--item--selected' : ''}`}
    onClick={() => onSelectItem(item.value)}
  >
    {item.label}
    {showCheckIcon && <CheckIconAlt color={platformColors.midGreen} width='19' height='19' />}
  </div>
)

export const DualSelectorOption = ({ orderType, options = [], onChangeOption }) => {
  const sortedOptions = sortStrings(options, orderType)
  return (
    <>
      {isEmpty(options) && <div className='d-flex ai-center jc--center dual-selector-widget--full'>{placeholders.noFieldsAvailable}</div>}
      {sortedOptions.map((opt, index) => (
        <div key={`dual-right-box_${opt.value}-${index}`} className='schema-form--item'>
          <CheckboxWrapper label={opt.label}>
            <input name={opt.value} type='checkbox' value={opt.value} className='control__indicator__input' checked={opt?.selected} onChange={onChangeOption} />
          </CheckboxWrapper>
        </div>
      ))}
    </>
  )
}
