import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loginByTokens, logout } from 'redux/actions/auth'
import Cookies from 'js-cookie'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'

class RouteViewContainer extends React.Component {
  componentDidMount () {
    const { loginByCookie, cookie, userId } = this.props

    loginByCookie(cookie, userId)
  }

  render () {
    return <div />
  }
}

RouteViewContainer.propTypes = {
  cookie: PropTypes.object,
  userId: PropTypes.number,
  loginByCookie: PropTypes.func,
}

const mapDispatchToProps = (dispatch, props) => ({
  loginByCookie: (cookie, userId) => {
    // In case of missing cookie (the user opens `/sso` directly or the BE isn't set the cookie for some reason),
    // we short-circuit the login functionality, in order to prevent a misfunction
    if (!cookie) {
      props.history.push('/login')

      return false
    }

    // In the case there's a user data in the local Store, while the user is in a process of SSO,
    // we have to remove the user's data firstly (logout the current user) and then continue processing the SSO.
    // Here, I will explain why that's needed:
    // 1. Imagine the user has Accounting (read-only) role and is logged in the app. All's good!
    // 2. He decided to close the app, but without doing a logout. Therefore his data is still kept in the local Store.
    // 3. Later, the COS admin decides to change his role to Accounting.
    // 4. The Accounting user JWT will be blacklisted by the BE
    // and on his next app visit, he will be logged out automatically! All's good!
    // 5. But the Accounting user decides to login directly via SSO provider, without visiting the app firstly.
    // 5.1. In that case, he will get the new tokens correctly and will be logged in the app.
    // 5.2. But the problem is that - his permissions won't be refetched, because they are already cached in the local
    // store from the previous auth session.
    // They weren't cleared/reset, because the user didn't logged out or didn't opened the app
    // with the blacklisted / invalidated / expired tokens.
    // 6. In order to handle this corner case, we make sure to logout the user here firstly.
    if (userId) {
      dispatch(logout())
    }

    const { jwt, refreshToken, errorsCommon, errors, cookieDomain: domain, cookiePath: path } = cookie

    // Make sure to delete the Cookie, because of security reasons
    Cookies.remove('state', { domain, path })

    // If we have errors while logging in via SSO
    if (errorsCommon || errors) {
      props.history.push('/login')
      // `errorToString` expects specific errors format, so we need to normalized the errors we have
      const normalizedErrors = {
        errors: {
          ...(errorsCommon && { _error: errorsCommon }),
          ...errors,
        },
      }

      dispatch(
        showMessage({
          body: errorToString(normalizedErrors, false),
        })
      )

      return false
    }

    // Everything's fine. Keep the user data in state and redirect him to the app.
    dispatch(loginByTokens({ token: jwt, refresh_token: refreshToken }))
    props.history.push('/home')
  },
})

const mapStateToProps = (state) => {
  const cookie = Cookies.get('state')

  return {
    cookie: cookie && JSON.parse(cookie),
    userId: state.auth.userId,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteViewContainer)
