import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyCountryTermPivot'

class CompanyCountryTerm extends CRUDModel {
  isFixed () {
    return this.type === 'fixed'
  }
  isOvertime () {
    return this.type === 'overtime'
  }
  isAttendance () {
    return this.type === 'attendance'
  }
  isVariable () {
    return this.type === 'variable'
  }

  /**
   * Get the elements (fields), needed for building the full name of CCT.
   *
   * For example: a CCT can be build by global name + local name only.
   *
   * Logic for showing the names
   * We have names on 3 levels, global, countryLocal, and local(company)
   * If global = local only show global to prevent duplicates
   * If they are different show "global / local"
   *  We shouldn't be using the countryLocal in this logic at all
   * @returns {[*,*,*]}
   */
  getFullNameElements () {
    const global = this.countryTerm.term.name
    const local = this.localName || this.countryTerm.localName
    const elementCode = this.elementCode
    const shouldOnlyUseGlobalName = global === this.localName

    return [global, ...(!shouldOnlyUseGlobalName ? [local] : []), ...(elementCode ? [`[${elementCode}]`] : [])]
  }

  getFullName () {
    return this.getFullNameElements().join(' / ')
  }
}
CompanyCountryTerm.modelName = 'CompanyCountryTerm'

CompanyCountryTerm.fields = {
  id: attr(),
  company: fk('Company', 'companyCountryTerms'),
  countryTerm: fk('CountryTerm', 'companyCountryTerms'),
}
CompanyCountryTerm.actionTypes = actionTypes

export default CompanyCountryTerm
