import createSelector from 'utils/createSelector'

/**
 * Get employee id
 * @param state
 * @param props
 */
const getEmployeeId = (state, props) => parseInt(props.employeeId)

/**
 * Get attendance id
 * @param state
 * @param props
 */
const getAttendanceId = (state, props) => props.id

/**
 * Get attendance periods by employee id
 */
export const getAttendancesByEmployee = createSelector(getEmployeeId,
  ({ EmployeeAttendancePeriod }, employeeId) => {
    return EmployeeAttendancePeriod.filter({ employee: employeeId })
      .toModelArray().map(attendance => ({
        ...attendance.ref,
        localName: attendance.companyCountryTerm.countryTerm.localName,
        globalName: attendance.companyCountryTerm.countryTerm.term.name
      })).reverse()
  })

/**
 * Get attendance period by id
 */
export const getAttendancesPeriodById = createSelector(getAttendanceId,
  ({ EmployeeAttendancePeriod }, attendanceId) => {
    return { ...EmployeeAttendancePeriod.withId(attendanceId).ref }
  })
