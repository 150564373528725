import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { updateTermSubCategory } from 'redux/actions/termSubCategories'
import FormFields from '../components/FormFields'
import { getSubCategoryById } from '../selectors/termSubCategories'

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => dispatch(updateTermSubCategory(data, props.subCategoryId))
      .then(() => props.onEdit())
  }
}

const mapStateToProps = (state, props) => {
  const subCategory = getSubCategoryById(state, { subCategoryId: props.subCategoryId })

  return {
    initialValues: {
      name: subCategory.name,
      termCategory: subCategory.termCategory
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'subCategoriesEditForm'
})(FormFields))

export default Fetcher(Container, ['termSubCategories'])
