import { ActionUtility } from 'utils/redux'
import { amountsTransformer } from 'redux/transformers/amountsTransformer'
import { getFieldsKeyByPrefix } from 'redux/helpers/form'
import { fetchPaygroupTermPivot } from 'redux/actions/paygroupTermPivot'
import { fetchPaygroupEmployeePivot } from 'redux/actions/paygroupEmployeePivot'
import { fetchEmployeeSystemUsers } from 'redux/actions/employeeSystemUsers'
import { normalizeSyncData, normalizeSyncTermsData } from 'redux/transformers/manyToManyTransformer'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYGROUPS_FETCH = 'PAYGROUPS_FETCH'
export const PAYGROUPS_RECEIVE = 'PAYGROUPS_RECEIVE'
export const PAYGROUP_CREATE = 'PAYGROUP_CREATE'
export const PAYGROUP_UPDATE = 'PAYGROUP_UPDATE'
export const PAYGROUP_TERMS_SYNC = 'PAYGROUP_TERMS_SYNC'
export const PAYGROUP_INVALIDATE = 'PAYGROUP_INVALIDATE'
export const PAYGROUP_SET_ACTIVE_ID = 'PAYGROUP_SET_ACTIVE_ID'
export const PAYGROUP_EMPLOYEE_ATTACH = 'PAYGROUP_EMPLOYEE_ATTACH'
export const PAYGROUP_EMPLOYEE_DETACH = 'PAYGROUP_EMPLOYEE_DETACH'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYGROUPS_FETCH,
  receive: PAYGROUPS_RECEIVE,
  create: PAYGROUP_CREATE,
  update: PAYGROUP_UPDATE,
  attachEmployees: PAYGROUP_EMPLOYEE_ATTACH,
  syncTerms: PAYGROUP_TERMS_SYNC,
  invalidate: PAYGROUP_INVALIDATE,
  setActiveId: PAYGROUP_SET_ACTIVE_ID,
  detachPaygroupEmployees: PAYGROUP_EMPLOYEE_DETACH
}
const actionUtility = new ActionUtility(actionTypes, 'paygroups', 'paygroups', 'Paygroup', {}, { fetch: 'PAYGROUP_VIEW' })

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPaygroupsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPaygroups = actionUtility.fetchEntities
export const setActivePaygroup = actionUtility.setActive
export const invalidatePaygroups = actionUtility.invalidate

const syncTermsAction = entity => ({
  type: actionTypes.syncTerms,
  payload: entity
})

export const createPaygroup = (entity) => {
  // set valid amount
  const fixedTermsAmounts = getFieldsKeyByPrefix(entity, 'fixed-term-')
  const data = amountsTransformer(entity, fixedTermsAmounts)

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(data))
      .then(response => {
        dispatch(syncTerms(data, response))
        return response
      })
  }
}

export const updatePaygroup = (entity) => {
  // set valid amount
  const fixedTermsAmounts = getFieldsKeyByPrefix(entity, 'fixed-term-')
  const data = amountsTransformer(entity, fixedTermsAmounts)

  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.updateEntity(data, data.paygroupId))
      .then(response => {
        dispatch(syncTerms(data, response))
        return response
      })
  }
}

/**
 * Send an api request to sync terms to paygroup
 */
const syncTerms = (data, response) => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.syncEntities({
      entity: normalizeSyncTermsData(data),
      id: response.id,
      childUri: 'companycountryterms',
      actionFunc: syncTermsAction
    })).then(() => {
      dispatch(fetchPaygroupTermPivot())
    })
  }
}

const attachEmployeesAction = (employeeSystemUsersIds, paygroupId) => ({
  type: actionTypes.attachEmployees,
  payload: { paygroupId, ...employeeSystemUsersIds }
})

// attach employees to paygroup
export const attachEmployees = entity => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.attachEntities({
      entity: normalizeSyncData(entity, null, value => value),
      id: entity.paygroupId,
      childUri: 'employees',
      actionFunc: attachEmployeesAction
    })).then(() => {
      dispatch(fetchPaygroupEmployeePivot())
      dispatch(fetchEmployeeSystemUsers())
    })
  }
}

/**
 * Attach employee to paygroup
 *
 * Maybe you're asking yourself, why don't we use `attachEmployees`? Good question!
 * `attachEmployees` purpose is to attach multiple employees to a paygroup.
 * Our goal here is to attach exactly 1 employee to a paygroup.
 * So, in order to keep current `attachEmployees` API usage
 * and without needing to pass many-to-many data structure,
 * we created this method.
 *
 * I know it would be better for this task to have the opposite API call,
 * i.e. attach many paygroups to 1 employee, but it will be extra work for the BE team.
 *
 * Considering the fact, that the task is complicated and not so clear,
 * we decided to do it in a simple way, in order to refactor it easily later.
 *
 * @param {Object} entity
 * @param {Number} entity.paygroupId
 * @param {Number} entity.employeeId
 * @param {Object} params - Please refer to `actionUtility`. The parameters are passed down to the utility directly.
 * @returns {function(*, *, {api: *})}
 */
export const attachEmployeeToPaygroup = (entity, params) => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.attachEntities({
      ...params,
      entity: { data: { [entity.employeeId]: {} } },
      id: entity.paygroupId,
      childUri: 'employees',
      actionFunc: attachEmployeesAction
    }))
  }
}

const detachEmployeesAction = (employeeSystemUsersIds, paygroupId) => ({
  type: actionTypes.detachPaygroupEmployees,
  payload: { paygroupId, ...employeeSystemUsersIds }
})

// remove employees from payroll
export const detachEmployees = entity => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.detachEntities({
      entity: normalizeSyncData(entity, null, value => value),
      id: entity.paygroupId,
      childUri: 'employees',
      actionFunc: detachEmployeesAction
    })).then(() => {
      dispatch(fetchPaygroupEmployeePivot())
      dispatch(fetchEmployeeSystemUsers())
    })
  }
}
