import { createFilter } from 'utils/redux/filter'

export const createResourcesFilterName = (resourceType, accessibleBy, sort, offset, limit) => {
  const filterNamePrefix = resourceType.text.replace(' ', '_').toLowerCase()
  const [{ name, order }] = sort
  return `${filterNamePrefix}_${accessibleBy.toLowerCase()}_${name}_${order}_${offset}_${offset + limit}`
}

export const prepareResourcesFilters = (resourceType, filters, sort, offset, limit, filterName) => {
  const defaultFilters = { resourceTypes: [resourceType.dbName] }

  return {
    filter: createFilter(
      {
        ...defaultFilters,
        ...filters,
        sort,
        offset,
        limit,
      },
      filterName
    ),
    disableObsoleteFlow: true,
  }
}

export const prepareResourceLogsFilters = (resourceId, tenant) => {
  return {
    filter: createFilter(
      {
        resource: resourceId,
        userTenant: tenant,
      },
      'history'
    ),
  }
}
