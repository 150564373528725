export const getServiceTree = service => ({
  id: service.id,
  name: service.name,
  type: service.type,
  processes: service.processes.toModelArray().map(process => ({
    name: process.name,
    keyEvent: process.keyEvent,
    steps: process.steps.toModelArray().map(step => ({
      name:  step.name,
      tasks:  step.tasks.toModelArray().map(task => ({
        id: task.id,
        name: task.name,
        owner: task.owner,
        finishTaskLink: task.finishTaskLink,
        position: task.position
      }))
    }))
  }))
})
