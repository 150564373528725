import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payfiles'
import { baseReducerHandlers } from './payrun'

class Payfile extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      ...baseReducerHandlers(Model)
    }
  }
}
Payfile.modelName = 'Payfile'

Payfile.fields = {
  id: attr(),
}
Payfile.actionTypes = actionTypes

export default Payfile
