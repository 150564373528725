import React from 'react'
import { forOwn } from 'lodash'
import ServicesTreeDecorator from 'components/ServicesTreeDecorator'
import VendorServicesTree from './VendorServicesTree'

class SlAsView extends React.Component {
  renderTree () {
    let tree = []

    forOwn(this.props.vendorServiceTree, (country, countryId) => {
      tree.push(<ServicesTreeDecorator key={countryId} services={country.services}>
        <VendorServicesTree heading={country.countryName} vendorId={this.props.vendorId} onClick={this.props.onClick} />
      </ServicesTreeDecorator>)
    })

    return tree
  }

  render () {
    const { isFetching } = this.props

    if (isFetching) return <div>Loading...</div>

    return <div>
      {this.renderTree()}
    </div>
  }
}

export default SlAsView
