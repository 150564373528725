import React from 'react'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import GenerateTabbedRoute from 'components/TabbedRoutes'

/**
 * Method to handle the generation of all routes.
 * It works on recursive principle where it will
 * continue through each child and each of their children
 * till no children are left found
 * @param {object} route The first level route to generate
 * @param {string} path The first level path to start generation off o
 * @param {array} allRoutes A collection of routes that are to be returned
 * @param {object} history A History api object
 */
const generateAllRoutes = (route, path, allRoutes, history) => {
  if (route.indexRoute) {
    if (route.tabbedRoute) {
      let pathToUse = route.path.includes(path) ? `${route.path}` : `${path}/${route.path}`
      allRoutes.unshift(GenerateTabbedRoute(route, pathToUse, history))
    } else {
      allRoutes.unshift(<RouteWithSubRoutes key={path} {...route} />)
    }
  }
  if (route.tabbedLayout) return allRoutes

  if (route.childRoutes) {
    for (const child of route.childRoutes) {
      let newPath = child.path.includes(path) ? `${child.path}` : `${path}/${child.path}`
      child.path = newPath

      if (child.renderChildrenOutSideTab) {
        for (const grandChild of child.childRoutes) {
          let grandChildPath = grandChild.path.includes(newPath) ? `${grandChild.path}` : `${newPath}/${grandChild.path}`
          grandChild.path = grandChildPath
          generateAllRoutes(grandChild, grandChildPath, allRoutes, history)
        }
        continue
      }
      generateAllRoutes(child, newPath, allRoutes, history)
    }
  }

  return allRoutes
}

export default generateAllRoutes
