import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import FieldsGroup from 'components/FieldsGroup'
import { Authorization } from 'containers/Authorization'

class GlobalDataProtectionList extends React.Component {
  render () {
    const { title, groupedFields, showAddBtn, onCreate } = this.props
    return <div>
      <div className='o-layout o-layout--tiny u-margin-bottom-small'>
        <div className='o-layout__item u-1/1 u-1/1@tablet u-2/3@desktop'>
          <SectionHeading
            text={title} >
            { showAddBtn &&
              <Authorization permissions={['COUNTRYDATAPROTECTIONFIELD_EDIT']}>
                <div className='o-layout__item u-1/1 u-1/2@tablet'>
                  <div className='u-float--right'>
                    <button
                      title='Add country data protection fields'
                      onClick={() => onCreate()}
                      className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'>
                      <span className='icon icon--plus' />
                    </button>
                  </div>
                </div>
              </Authorization>
            }
          </SectionHeading>
        </div>
      </div>
      {
        groupedFields.map((group, i) => {
          return <FieldsGroup
            key={group.category}
            category={group.category}
            categoryName={group.categoryName}
            fields={group.fields}
            index={i}
            {...this.props}
          />
        })
      }
    </div>
  }
}

GlobalDataProtectionList.propTypes = {
  title: PropTypes.string,
  groupedFields: PropTypes.array,
  showAddBtn: PropTypes.bool,
  onCreate: PropTypes.func
}

export default GlobalDataProtectionList
