import { isArray, isEmpty } from 'utils/fnkit/typeChecks'
import { getPayrollCurrency, setReportingCurrency, setReportingRate } from '../selectors/currencies'
import {
  feReportIDsWithSingleCountryCompanyPayroll,
  reportsWithCombinedSubCategory,
  reportsWithDynamicSelectors,
  reportsWithOnlyOneCompanyToSelect,
  reportsWithOnlyOneCountryToSelect,
  reportsWithRemovedFields,
  reportsWithSinglePayroll,
} from 'utils/enums/reportGroupings'
import { getDatesInRange } from 'utils/fnkit/dates/ranges'
import { pick } from 'utils/fnkit/object'
import { fieldsToRegenerate, reportDesignerSchemaFields } from '../routes/ReportDesigner/components/ReportDesigner/fields'

const NONE_VALUES = ['None', 'none']

export const getRegenerateSavedReport = (history) => history?.location?.state?.report
const prepareDateData = (date) => (typeof date === 'string' ? new Date(date) : date)
const getArrayPropData = (value) => (isArray(value) && value.length === 1 ? value[0] : value)

const isSingleOption = ({ filters } = {}) => {
  const isLessThanMonth = getDatesInRange(filters?.fromDate, filters?.toDate)?.length <= 32
  const isLessThanYearWithShowOnlyTotalsYes = getDatesInRange(filters?.fromDate, filters?.toDate)?.length <= 366 && filters?.showOnlyTotals
  return !isLessThanMonth && !isLessThanYearWithShowOnlyTotalsYes
}

const getDynamicValues = (report = {}) => {
  const { category, subcategory, filters } = report
  const hasCombinedSubcategory = reportsWithCombinedSubCategory.includes(`${category}-${subcategory}`)
  const needsSingleOption =
    (isSingleOption(report) && reportsWithDynamicSelectors.includes(subcategory)) || feReportIDsWithSingleCountryCompanyPayroll.includes(report.feCategoryId)
  const needsSingleCountry = reportsWithOnlyOneCountryToSelect.includes(subcategory) || hasCombinedSubcategory
  const needsSingleCompany = reportsWithOnlyOneCompanyToSelect.includes(subcategory) || hasCombinedSubcategory
  const needsSinglePayroll = reportsWithSinglePayroll.includes(subcategory)

  return {
    country: needsSingleCountry || needsSingleOption ? getArrayPropData(filters?.country) : filters?.country,
    company: needsSingleCompany || needsSingleOption ? getArrayPropData(filters?.company) : filters?.company,
    payroll:
      needsSinglePayroll || feReportIDsWithSingleCountryCompanyPayroll.includes(report.feCategoryId) ? getArrayPropData(filters?.payroll) : filters?.payroll,
  }
}

const getFxRates = (state, report) => {
  if (state.fxRates.isFetching === false) {
    const payrollCurrency = getPayrollCurrency(state, report)
    const updatedReportingCurrency = setReportingCurrency(state, report, payrollCurrency)
    const reportingRate = setReportingRate(state, report)
    const reportingCurrency = isArray(updatedReportingCurrency) ? updatedReportingCurrency : [updatedReportingCurrency]
    const customRateObj = report?.params?.fxRateCustom?.reduce((acc, fx) => {
      acc[`customRate-${fx.fromCurrency}`] = fx.rate
      return acc
    }, {})
    return {
      reportingCurrency,
      reportingRate,
      payrollCurrency,
      ...customRateObj,
      toCurrency: reportingCurrency?.[0],
    }
  }
}

const getCombinedSubCategories = ({ subcategory, filters } = {}) => {
  if (subcategory === 'Terms') return filters?.termCategory.map((cat) => `Terms-${cat}`)
  if (['OrgUnitVarianceReport', 'OrgUnit'].includes(subcategory)) return filters?.orgUnits
  return [subcategory]
}

/**
 *
 * This function dynamically removes a list of fields from the `filters` object
 * based on the corresponding report category.
 *
 * @param {Object} filters - The filters object that may contain fields to be removed.
 * @param {string} category - The category that determines which fields should be removed.
 * @returns {Object} - A new object with the specified fields removed (if any).
 *
 * @example
 * Change Log reports used to have a payrollInstance filter, so the Saved Reports from that time would also have the payrollInstance filter
 * That filter has since been removed from the report library, so we have to take them out from the Saved Reports filters
 *
 * const reportsWithRemovedFields = {
 *   ChangeLogReport: ['payrollInstance', 'anotherField'],
 *   FinanceReport: ['budgetField'],
 * }
 *
 * const filters = {
 *   payrollInstance: [1, 2, 3],
 *   employee: ['John Doe'],
 *   anotherField: 'example',
 * }
 *
 * const category = 'ChangeLogReport';
 * const result = filterFieldsByCategory(filters, category);
 * // Result: { employee: ['John Doe'] }
 */
export const filterFieldsByCategory = (filters = {}, category) => {
  const fields = reportsWithRemovedFields[category] || []

  return fields.reduce((result, field) => {
    const { [field]: unused, ...rest } = result
    return rest
  }, filters)
}

const mapSavedReportData = (state, report = {}) => {
  const { id, category, subcategory, feCategoryId, type, cat, subCat, categoryName, filters } = report

  const filteredFilters = filterFieldsByCategory(filters, category)

  return {
    id,
    category,
    subcategory,
    feCategoryId,
    type,
    cat,
    subCat,
    categoryName,
    ...filteredFilters,
    ...getDynamicValues(report),
    employmentStatus: filters?.employmentStatus?.map((f) => (NONE_VALUES.includes(f) ? 0 : f)),
    combinedSubcategory: getCombinedSubCategories(report),
    fromDate: prepareDateData(filters?.fromDate),
    toDate: prepareDateData(filters?.toDate),
    process: getArrayPropData(filters?.process),
    modifiedBy: filters.modifiedByWithFilter?.map((v) => JSON.stringify(v)),
    ...getFxRates(state, report),
  }
}

export const getInitialReportData = ({ state, initValues, savedReport, reportType }) => {
  const feCategoryId = savedReport?.feCategoryId
  const hasSavedReport = !isEmpty(savedReport) && feCategoryId === reportType
  const values = hasSavedReport ? mapSavedReportData(state, savedReport) : initValues
  values.selectedReportType = reportType
  return values
}

export const getRegeneratedReportDesigner = (history) => {
  const regeneratedReport = getRegenerateSavedReport(history)
  const filters = regeneratedReport?.filters
  let data = {}

  if (filters) {
    const typeofEmploymentKey = reportDesignerSchemaFields.typeOfEmploymentEngagement
    data = pick(regeneratedReport.filters, [...fieldsToRegenerate, 'fields'])
    data[typeofEmploymentKey] = filters[typeofEmploymentKey]?.map((option) => (option === 'None' ? 0 : option)) // Only for the options with text
  }
  return data
}
