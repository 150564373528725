import createSelector from 'utils/createSelector'
import { getKeyPeopleRole } from 'redux/models/employeeSystemUser'
import { makeFilteredIdsByFilter } from './filters'

const getFilteredIds = makeFilteredIdsByFilter('businessUnits')

/*
 * Build table data for selecting multiple action owners view
 *
 * First column lists all unique Business Units of people in the payroll.
 * For each BU, there is a dropdown listing Key people.
 * Key people listed in the dropdown are such who have access to the according Business unit.
 * So if someone has access to multiple BUs, he may be listed in multiple dropdowns.
 *
 */
export const getFilteredBusinessUnitsWithKeyPeople = createSelector(getFilteredIds,
  ({ BusinessUnit, EmployeeSystemUser }, filteredIds) => filteredIds.map(id => {
    const bu = BusinessUnit.withId(id).ref

    return {
      id: bu.id,
      businessUnit: bu.name,
      actionOwners: getKeyPeopleByBusinessUnit(EmployeeSystemUser, bu, bu.company)
    }
  })
)

/*
 * Get key people who have access(by BU or Company) to the BU
 */
const getKeyPeopleByBusinessUnit = (EmployeeSystemUser, bu, companyId) => {
  return EmployeeSystemUser
    .filter(employee => getKeyPeopleRole(employee.roleType))
    .toModelArray()
    .filter(employee => {
      let accessAreas = employee.accessArea.all().toModelArray().map(a => ({ ...a.ref }))
      let filteredAccessAreasByBU = accessAreas.filter(area =>
        (area.accessableType === 'Payslip\\CoreBundle\\Entity\\BusinessUnit' && area.accessableId === bu.id) ||
        (area.accessableType === 'Payslip\\CoreBundle\\Entity\\Company' && area.accessableId === companyId)
      )
      return filteredAccessAreasByBU.length
    })
    .map(employee => ({
      id: employee.id,
      name: employee.getName()
    }))
}
