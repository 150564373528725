import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useFetch } from 'hooks/useFetch'
import { createCommentary, editCommentary, fetchCommentary } from 'redux/actions/commentary'
import { getCommentaryByPayrollId } from 'redux/selectors/commentary'
import CommentaryView from '../components/CommentaryView'
import Loader from 'components/Loader'
import { dummyRowForAddingComment, normalizeCommentaryEditPayload, normalizeCommentaryPayload, verifyData } from '../utils/commentary'
import { formatDateToUtc } from 'utils/date'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { createFilter } from 'utils/redux/filter'
import { generateCommentaryHeadings } from '../commentaryTableConfig'
import { PayrollSummaryContext, summaryViewToggleIds } from '../PayrollSummaryContext'

const CommentaryContainer = ({ payrollInstanceId }) => {
  const [state, setState] = useState({
    inAddMode: false,
    inEditMode: false,
    fieldErrors: [],
    data: [],
    tableChangedData: [],
  })
  const dispatch = useDispatch()
  const { setGlobalUpdateMode } = useContext(PayrollSummaryContext)

  useEffect(() => {
    setGlobalUpdateMode(summaryViewToggleIds.commentary, state.inAddMode || state.inEditMode)
  }, [state])

  const fetcherOptions = [
    {
      name: 'commentary',
      params: [
        {
          _computed: {
            filter: (state) => createFilter({ payrollInstance: payrollInstanceId }),
          },
          disableObsoleteFlow: true,
        },
      ],
      forceFetch: true,
    },
  ]
  const fetcherCommentaryData = useFetch(fetcherOptions)
  const isFetching = fetcherCommentaryData.isFetching

  const commentaryData = useSelector((state) => {
    if (isFetching) return []
    return getCommentaryByPayrollId(state, { payrollInstanceId })
  })

  const remappedTableData = commentaryData.map((c) => ({
    id: c.id,
    body: c.body,
    date: formatDateToUtc(c.lastModifiedAt.date, dateFormatEnums.DefaultFnsDateWithTime),
    authorName: c.authorName,
    disableEdit: c.disableEdit,
  }))

  useEffect(() => {
    if (remappedTableData.length) {
      setState({
        inAddMode: false,
        inEditMode: false,
        fieldErrors: [],
        data: remappedTableData,
        tableChangedData: [],
      })
    }
  }, [commentaryData])

  // Edit and add modes
  const toggleModes = (operation, shouldToggle) => {
    if (operation === 'edit') {
      setState({
        ...state,
        inEditMode: shouldToggle,
        inAddMode: false,
      })
    }
    if (operation === 'add') {
      const newData = [dummyRowForAddingComment, ...state.data]
      setState({
        ...state,
        data: newData,
        tableChangedData: [],
        inEditMode: false,
        inAddMode: shouldToggle,
      })
    }
  }

  const onSave = () => {
    const errors = verifyData(state.tableChangedData)
    if (!isEmpty(errors)) {
      setState({ ...state, fieldErrors: errors })
      return
    }
    // there is a difference in the payload if we're adding a new comment or editing an existing one
    // so we are basing the Save function on which "mode" is currently true
    if (state.inAddMode) onAddSave(state.tableChangedData)
    if (state.inEditMode) onEditSave(state.tableChangedData)
  }

  const onAddSave = async (data) => {
    const payload = data.find((d) => d.isNew)
    payload['payrollInstance'] = payrollInstanceId
    const res = await dispatch(
      createCommentary(normalizeCommentaryPayload(payload), {
        shouldFetch: true,
        shouldInvalidate: true,
      })
    )
    if (res && res.errors) {
      const returnedErrors = []
      returnedErrors.push({ id: 0, body: res.errors.body })
      setState({ ...state, fieldErrors: returnedErrors })
    }
  }

  const onEditSave = async (data) => {
    const payload = data.filter((d) => d.isDirty)
    const normalizedPayload = normalizeCommentaryEditPayload(payload)
    const res = await dispatch(editCommentary(normalizedPayload))
    if (res && res.errors) {
      const returnedErrors = []
      for (const key in res.errors) {
        if (Object.hasOwnProperty.call(res.errors, key)) {
          const element = res.errors[key]
          returnedErrors.push({ id: parseInt(key, 10), ...element })
        }
      }
      setState({ ...state, fieldErrors: returnedErrors })
    } else {
      dispatch(
        fetchCommentary({
          filter: createFilter({ payrollInstance: payrollInstanceId }),
        })
      )
    }
  }

  const onCancel = async () => {
    // Should be updated, to bring in new comments added by another
    setState({
      ...state,
      inAddMode: false,
      inEditMode: false,
      data: remappedTableData,
      tableChangedData: [],
    })

    dispatch(
      fetchCommentary({
        filter: createFilter({ payrollInstance: payrollInstanceId }),
      })
    )
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value) => {
    /* We should use different states instead of the original data state
    because, otherwise, when you try to edit more than one row at a time and
    scroll, it resets the rest of the rows to the original state, causing them
    to lose the changes */
    const notFoundItemdIdex = -1
    const payload = state.tableChangedData
    const originalComment = state.data[rowIndex]
    const savedCommentIndex = state.tableChangedData.findIndex(({ id }) => id === originalComment?.id)

    if (savedCommentIndex > notFoundItemdIdex) {
      payload[savedCommentIndex][columnId] = value
    } else {
      payload.push({
        ...state.data[rowIndex],
        [columnId]: value,
        isDirty: true,
      })
    }
    setState({
      ...state,
      tableChangedData: payload,
    })
  }

  if (isFetching) return <Loader />

  return (
    <CommentaryView
      isFetching={isFetching}
      data={state.data}
      tableHeadings={generateCommentaryHeadings()}
      toggleModes={toggleModes}
      inEditMode={state.inEditMode}
      inAddMode={state.inAddMode}
      onCancel={onCancel}
      onSave={onSave}
      fieldErrors={state.fieldErrors}
      onUpdateGlobalDataForSave={onUpdateGlobalDataForSave}
    />
  )
}

export default CommentaryContainer
