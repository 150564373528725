import { MODAL_SHOW_MESSAGE, MODAL_CLEAR_MESSAGE } from 'redux/actions/modal'

const initialState = { title: null, body: null }

const handlers = {
  [MODAL_SHOW_MESSAGE]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [MODAL_CLEAR_MESSAGE]: state => ({
    ...initialState
  })
}

export default (state = initialState, action) => {
  const handler = handlers[action.type]

  return handler ? handler(state, action) : state
}
