import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Fetcher from 'containers/Fetcher'
import Logo from '../Logo'
import MainNav from '../MainNav'
import { getVendorIdBySchema } from 'redux/selectors/vendors'
import { getContactedParentCompanies } from 'redux/selectors/tenants'
import { getTenantSubdomain } from 'utils/tenant'
import PropTypes from 'prop-types'
import { closeAllToastMessages } from 'redux/actions/toasts'
import { setShouldPoll, setStartPolling } from 'redux/actions/pollingJob'
import { useParams } from 'react-router'
import Loader from 'components/Loader'
import { asideTitles, asideVendorTitles } from 'utils/locales/aside.en'
import { isVendorAdmin } from 'redux/selectors/auth'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { asideGlobalRoutes } from './routes'

const getMenuItems = ({ vendorId, hasAccessToUsers, hasAccessToResources }) => {
  const userSection = hasAccessToUsers ? [{ title: asideTitles.users, name: 'home', path: `/vendors/${vendorId}/users` }] : []
  const resourcesSection = hasAccessToResources ? [asideGlobalRoutes.resources] : []
  return [
    { title: asideTitles.home, name: 'home', path: `/vendors/${vendorId}/company-details`, isHome: true },
    { title: asideTitles.clients, name: 'clients', path: `/vendors/${vendorId}/clients` },
    ...userSection,
    ...resourcesSection,
    {
      title: asideVendorTitles.toolbox,
      name: 'toolbox',
      path: '/toolbox',
      isLink: false,
      children: [{ title: asideVendorTitles.alerts, name: 'alerts', path: '/toolbox/alerts', sub: true }],
    },
  ]
}

const Aside = (props) => {
  const { vendorId, isFetching, asideMode, selectAside, hasAccessToUsers, hasAccessToResources } = props
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(() => {
    if (!params.tenantId) {
      dispatch(closeAllToastMessages())
      dispatch(setShouldPoll({ enablePolling: false }))
      dispatch(setStartPolling({ shouldStartPolling: false }))
    }
  }, [])
  return (
    <aside className='o-aside'>
      <Logo />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      {isFetching ? <Loader /> : <MainNav items={getMenuItems({ vendorId, hasAccessToUsers, hasAccessToResources })} {...props} />}
    </aside>
  )
}

// map to state
const mapStateToProps = (state, props) => {
  if (props.isFetching) return { isFetching: true }

  return {
    hasAccessToUsers: isVendorAdmin(state),
    vendorId: getVendorIdBySchema(state, { schema: getTenantSubdomain() }),
    parentCompanies: getContactedParentCompanies(state),
  }
}

const Container = connect(mapStateToProps)(Aside)

Aside.propTypes = {
  selectAside: PropTypes.func,
  isFetching: PropTypes.bool,
  asideMode: PropTypes.bool,
  isUser: PropTypes.bool,
  userId: PropTypes.number,
  hasAccess: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchAccessAreaPivotIfNeeded: PropTypes.func,
  fetchParentCompaniesIfNeeded: PropTypes.func,
  parentCompanies: PropTypes.array,
  vendorId: PropTypes.number,
  dispatch: PropTypes.func,
}

export default Fetcher(Container, ['vendors', 'parentCompanies'], { renderWhileFetching: true, showLoader: false })
