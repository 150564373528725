import { DateType } from 'utils/date'
import { graphTypesEnum, reportingGraphNamesEnum, totalsCalculations } from 'utils/enums/analyticsEnums'
import { ReportingAnalyticsService } from './ReportingAnalyticsService'

export class ReportingAnalyticsTileService extends ReportingAnalyticsService {
  /**
   * @param {array} rawData The full data set returned when getting the details from a the Reports API
   * @param {array} otherSheets The otherSheets data returned from the server reporting API
   * @param {object} mappingOptions A series of options that will be used throughout the file.  General contains logic from enums/reports.js
   * @param {object} charts The active charts for a given report.  Data is found from enums/reports.js
   * @param {object} chartLogic Chart logic that will be used throughout the Services.  Logic is currently obtained from the enums/reports.js
   */
  constructor (rawData = [], otherSheets = [], mappingOptions = {}, charts = [], chartLogic = {}) {
    super(rawData, otherSheets, mappingOptions, charts, chartLogic)
    this.filteredDownReportTypes = this.getFilteredChartTypes()
  }

  /**
   * Method to filter out the unrelated report types for a given report
   * The full details come enums/reports.js.  But this service only cares about Tile reports (graphTypesEnum.TILE_COUNT)
   * @returns {array} The Tile charts that are activated for this report
   */
  getFilteredChartTypes = () => {
    const filteredCharts = Object.entries(this.chartTypes).filter(([key, value]) => value === graphTypesEnum.TILE_COUNT)
    return Object.fromEntries(filteredCharts)
  }

  /**
   * Method to start process the data for this report.
   * It handles setting the data for each current report tile type
   * This includes the Totals count for Workforce, Active ESS, Inactive ESS, Joiners and Leavers
   * Will return a string message if no items are active
   * @returns null | string
   */
  processReportData = () => {
    const reportStartDate = new DateType(this.options.reportStartDate)
    const reportEndDate = new DateType(this.options.reportEndDate)

    if (Object.keys(this.filteredDownReportTypes).length === 0) return 'No Charts Specified'

    const data = this.options.CHART_LOGIC.OTHER_SHEETS_TOTALS ? this.otherSheetsData : this.chartData.data
    const currencies = new Set()

    if (this.options.CHART_LOGIC?.IS_WORKFLOW_TOTALS_COUNT) {
      const totals = this.chartData?.otherSheets?.find((item) => item.name === this.options.CHART_LOGIC.TOTALS_KEY_VALUE)
      this.setWorkFlowTotalsCount(this.options.CHART_LOGIC.TILE_CALC_KEYS, totals?.data)
      return
    }

    data.forEach((item) => {
      if (item.currency && !item.currency.includes(this.options.CHART_LOGIC.TOTALS_KEY_VALUE)) {
        currencies.add(item.currency)
      }
    })

    data.forEach((item) => {
      if (item.total && item[this.options.CHART_LOGIC.TOTALS_KEY]) {
        if (this.filteredDownReportTypes[reportingGraphNamesEnum.GLOBAL_WORKFORCE_HEADCOUNT]) {
          this.setTotalData(item)
        }
        if (this.options.CHART_LOGIC.TOTALS_CALCULATION === totalsCalculations.FLAT) {
          if (this.filteredDownReportTypes[reportingGraphNamesEnum.GLOBAL_JOINERS_HEADCOUNT]) {
            this.setTotalJoiners(item)
          }

          if (this.filteredDownReportTypes[reportingGraphNamesEnum.GLOBAL_LEAVERS_HEADCOUNT]) {
            this.setTotalLeavers(item)
          }
        }
      }

      const itemIncludesTotalsKeyValue = item[this.options.CHART_LOGIC.TOTALS_KEY].includes(this.options.CHART_LOGIC.TOTALS_KEY_VALUE)

      const setFinanceData = (reportKey, reportEnum, cb) => {
        const hasValue = item[reportKey] || item[reportKey] === ''
        if (hasValue && item[this.options.CHART_LOGIC.TOTALS_KEY] && itemIncludesTotalsKeyValue) {
          if (this.filteredDownReportTypes[reportEnum]) {
            if (currencies.size === 1) {
              const [currency] = currencies.values()
              this.setShowFinanceTilesWithCurrency(currency)
              cb(item)
            }
          }
        }
      }

      if (this.options.CHART_LOGIC.IS_FINANCE_TILE) {
        setFinanceData(this.options.CHART_LOGIC.TOTALS_REPORT_KEY, reportingGraphNamesEnum.TOTAL_EMPLOYER_COSTS, this.setTotalEmployerCosts)
        setFinanceData(
          this.options.CHART_LOGIC.TOTALS_ER_CONTRIBUTION_REPORT_KEY,
          reportingGraphNamesEnum.EMPLOYER_CONTRIBUTION_COSTS,
          this.setTotalErContributionCosts
        )
        setFinanceData(this.options.CHART_LOGIC.TOTALS_PAY_ELEMENT_REPORT_KEY, reportingGraphNamesEnum.TOTAL_PAY_ELEMENT_COSTS, this.setTotalPayElementCosts)
        setFinanceData(
          this.options.CHART_LOGIC.TOTALS_EE_DEDUCTIONS_REPORT_KEY,
          reportingGraphNamesEnum.TOTAL_EE_DEDUCTION_COSTS,
          this.setTotalEeDeductionCosts
        )
        setFinanceData(
          this.options.CHART_LOGIC.TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY,
          reportingGraphNamesEnum.TOTAL_EE_NET_DEDUCTION_COSTS,
          this.setTotalEeNetDeductionCosts
        )
        setFinanceData(
          this.options.CHART_LOGIC.TOTALS_PAYROLL_ELEMENT_REPORT_KEY,
          reportingGraphNamesEnum.TOTAL_PAYROLL_ELEMENT_COSTS,
          this.setTotalPayrollElementCosts
        )
      }

      if (item[this.options.CHART_LOGIC.TOTALS_KEY] && !itemIncludesTotalsKeyValue) {
        if (item[this.options.CHART_LOGIC.TOTALS_REPORT_KEY]) this.setFinancePayrollCounts('totalErCost')
        this.setFinancePayrollCounts('totalPayrollCount')
      }
    })

    if (this.options.CHART_LOGIC.TOTALS_CALCULATION === totalsCalculations.CALCULATED) {
      this.chartData.data.forEach((item) => {
        const { startDate, endDate } = item
        if (this.filteredDownReportTypes[reportingGraphNamesEnum.GLOBAL_JOINERS_HEADCOUNT] && startDate) {
          const dateParts = startDate.split('/')
          // month is 0-based, that's why we need dataParts[1] - 1
          let employeeStartDate = new DateType(+dateParts[2], dateParts[1] - 1, +dateParts[0])
          const isAfterStart = employeeStartDate >= reportStartDate
          const isBeforeEndDate = employeeStartDate <= reportEndDate
          if (isAfterStart && isBeforeEndDate) {
            this.setTotalJoinersByCalculation()
          }
        }

        if (this.filteredDownReportTypes[reportingGraphNamesEnum.GLOBAL_LEAVERS_HEADCOUNT] && endDate) {
          const dateParts = endDate.split('/')
          // month is 0-based, that's why we need dataParts[1] - 1
          let employeeEndDate = new DateType(+dateParts[2], dateParts[1] - 1, +dateParts[0])

          if (employeeEndDate) {
            employeeEndDate = this.adjustEndDateForNextMonth(employeeEndDate)
          }

          const isAfterStart = employeeEndDate >= reportStartDate
          const isBeforeEndDate = employeeEndDate <= reportEndDate

          if (isAfterStart && isBeforeEndDate) {
            this.setTotalLeaversByCalculation()
          }
        }
      })
    }
  }
}
