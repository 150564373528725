import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { fetchTermsIfNeeded, updateTerm } from '../modules/actions'
import { fetchTermCategoriesIfNeeded } from 'redux/actions/termCategories'
import { fetchTermSubCategoriesIfNeeded } from 'redux/actions/termSubCategories'
import TermsFormFields from '../components/TermsFormFields'
import { getTermById } from 'redux/selectors/terms'

const TermsEditContainer = (props) => {
  useEffect(() => {
    props.fetchTermsIfNeeded()
    props.fetchTermCategoriesIfNeeded()
    props.fetchTermSubCategoriesIfNeeded()
  }, [])

  return (
    <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <TermsFormFields {...props} />
      </div>
    </div>
  )
}

TermsEditContainer.propTypes = {
  termId: PropTypes.number,
  onEdit: PropTypes.func,
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchTermsIfNeeded: () => dispatch(fetchTermsIfNeeded()),
    fetchTermCategoriesIfNeeded: () => dispatch(fetchTermCategoriesIfNeeded()),
    fetchTermSubCategoriesIfNeeded: () => dispatch(fetchTermSubCategoriesIfNeeded()),
    onSubmit: (data) =>
      dispatch(updateTerm(data, props.termId)).then(() => {
        props.onEdit()
      }),
  }
}

const mapStateToProps = (state, props) => {
  const { terms, termCategories, termSubCategories } = state

  const entities = [terms, termCategories, termSubCategories]

  if (isFetching(entities)) return { isFetching: true }

  const term = getTermById(state, { termId: props.termId })

  return {
    initialValues: {
      name: term.name,
      definition: term.definition,
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'termEdit',
  })(TermsEditContainer)
)
