import { keycloakTokenExpiration } from 'utils/enums/keycloakRealms'
import { getStorageItem, setStorageItem } from 'utils/storage'

/**
 * Sets up a listener to refresh the Keycloak token before it expires.
 *
 * @param {Object} keycloak - The Keycloak instance.
 * @param {Function} renewToken - Function to renew the Keycloak token.
 */
export const useRhssoRefreshTokenListener = (keycloak, renewToken) => {
  const isAuthenticated = keycloak?.authenticated
  const tokenExpTime = keycloak?.idTokenParsed?.exp

  if (tokenExpTime && isAuthenticated) {
    const currentUnixTime = Math.floor(new Date().getTime() / 1000)
    const delayInSeconds = tokenExpTime - currentUnixTime - keycloakTokenExpiration.MIN_EXPIRED_VALIDITY
    const delayInMilliseconds = delayInSeconds * 1000

    if (delayInMilliseconds > 0) {
      setTimeout(() => {
        renewToken()
      }, delayInMilliseconds)
    }
  }
}

/**
 * Checks if the current login is the first login for the user and updates the local storage accordingly.
 *
 * @param {Object} kc - The Keycloak instance.
 * @param {Object} kc.tokenParsed - The parsed token object from Keycloak.
 * @param {string} kc.tokenParsed.sub - The user's unique identifier.
 * @param {number} kc.tokenParsed.auth_time - The authentication time of the user's current session.
 * @returns {boolean} - Returns true if this is the user's first login, otherwise false.
 */
export const storeUserFirstLogin = (kc) => {
  const userId = kc?.tokenParsed?.sub
  let isFirstLogin = false
  if (userId) {
    const authTime = kc?.tokenParsed?.auth_time
    const firstLoginKey = `firstLogin_${userId}`
    const lastAuthTime = getStorageItem(firstLoginKey)

    if (!lastAuthTime || parseInt(lastAuthTime, 10) !== authTime) {
      isFirstLogin = true
      setStorageItem(firstLoginKey, authTime)
    }
  }

  return isFirstLogin
}
