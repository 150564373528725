import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getEmployeesWhichFixedTermsDifferWithPaygroup } from '../selectors/employees'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import PayrollPaygroupEmployees from 'components/employees/PayrollPaygroupEmployees'
import { getFormFieldValue } from 'redux/selectors/form'
import { getFixedPaygroupTermByPaygroup } from 'redux/selectors/paygroupTerm'
import { areAllEmployeesSelected } from 'redux/helpers/areAllEmployeesSelected'

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state, props) => {
  const { paygroup, employees } = props

  // Here I'm getting the selected employees in a very hackish way.
  // That's because we generate employees checkbox names dynamically and here I can't get them
  // We have to consider array fields in redux-form
  let selectedEmployees = employees
    .filter((employee) => getFormFieldValue(state, 'paygroupAssignEmployees', `id-${employee.id}`))
    .map((employee) => employee.id)

  // Get these employees, which fixed company country terms
  // differs with the current paygroup company country terms
  const employeesWithDifferTerms = getEmployeesWhichFixedTermsDifferWithPaygroup(state, {
    paygroupId: paygroup.id,
    employeeIds: selectedEmployees,
  })

  // get fixed pay elements for paygroup
  let fixedPaygroupElements = getFixedPaygroupTermByPaygroup(state, {
    paygroupId: paygroup.id,
  })

  return {
    employeesWithDifferTerms,
    fixedPaygroupElements,
    employeeSystemUsers: employees,
    hasEmployees: () => hasEmployees(state, 'paygroupAssignEmployees', employees),
    areAllEmployeesSelected: areAllEmployeesSelected({ state, formName: 'paygroupAssignEmployees', employees: props.employees }),
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'paygroupAssignEmployees',
    destroyOnUnmount: false,
  })(PayrollPaygroupEmployees)
)

export default Container
