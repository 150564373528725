import newTypes from 'redux/config/newReportTypes'

class ReportItemDetailBuilder {
  constructor (type = null) {
    this.reportType = type
  }

  get lockedInItems () {
    let details = newTypes[this.reportType]
    return details
  }
}

export default ReportItemDetailBuilder
