import React from 'react'
import SectionHeading from 'components/SectionHeading'
import { tasksIdentifiers } from 'redux/config/tasksIdentifiers'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'

export const Tasks = ({ tasks, isFetching }) => {
  if (isFetching) return (<div>Loading...</div>)
  return <div>
    <SectionHeading text='Actions list' className='u-margin-top-large' />

    {isFetching === true ? 'Loading...' : null}

    <PayslipTable
      data={tasks.map(task => {
        let taskIdentifier = _.find(tasksIdentifiers, { 'code': task.identifier })
        return {
          id: task.id,
          name: task.name,
          identifier: taskIdentifier ? taskIdentifier.name : null,
          link: task.finishTaskLink,
          owner: task.owner,
          step: task.stepRef.name
        }
      })}
      headings={[
        { accessor: 'name', Header: 'Name', disableSortBy: true },
        { accessor: 'identifier', Header: 'Identifier', disableSortBy: true },
        { accessor: 'link', Header: 'Link', disableSortBy: true },
        { accessor: 'owner', Header: 'Owner', disableSortBy: true },
        { accessor: 'step', Header: 'Step', disableSortBy: true }
      ]}
      wrapperClassName='u-1/1'
      trClassName='react-bs-table__cell--whitespace--normal'
    />
  </div>
}

export default Tasks
