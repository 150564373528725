import createSelector from 'utils/createSelector'

export const getFilteredResourceLogs = createSelector(
  (state) => {
    const entity = 'resourceLogs'
    const filterName = 'history'
    return state[entity].filters[filterName]?.ids
  },
  ({ ResourceLog }, filteredIds = []) => {
    return filteredIds?.map((id) => ResourceLog.withId(id))
  }
)
