import createSelector from 'utils/createSelector'
import { getVendorServices, getVendorCountries } from 'redux/selectors/vendorCountryServices'
import { getCountries } from 'routes/Countries/selectors/countries'

// get the country id
export const getCountryId = (state, props) => parseInt(props.countryId)

export const getCountryServicesByCountry = createSelector(getCountryId,
  ({ CountryService }, countryId) => {
    return CountryService
      .filter(countryService => countryService.country === countryId)
      .toModelArray()
      .map(countryService => ({
        id: countryService.id,
        name: countryService.service.name,
        countryId: countryService.country.id
      }))
  }
)

export const getUnassignedCountryServicesByVendor = createSelector(getCountryServicesByCountry, getVendorServices,
  (session, countryServices, vendorServices) => {
    return countryServices.filter(countryService => {
      const hasVendorCountryServices = vendorServices.hasOwnProperty(countryService.countryId)

      return !hasVendorCountryServices || !vendorServices[countryService.countryId].services
        .find(service => service.id === countryService.id)
    })
  }
)

export const getUnassignedCountriesByVendor = createSelector(getCountries, getVendorCountries,
  (session, countries, vendorCountries) => {
    return countries.filter(country => !vendorCountries.includes(country.id))
  }
)
