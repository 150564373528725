import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import ConfirmationModal from 'components/ConfirmationModal'
import ReportItem from 'routes/Reporting/components/ReportItem'
import ReportResultsModalView from 'routes/Reporting/components/ReportResultsModalView'
import Modal from 'components/Modal'
import { reportCommonLabels } from 'utils/locales/report.en'

const SavedReportsList = (props) => {
  const { reports, deleteReport } = props
  const [reportId, setReportId] = useState(null)
  const modalViewReportModalRef = useRef(null)
  const deleteReportRef = useRef(null)

  const handleModalOpen = (modalRef, report) => {
    setReportId(report.id)
    modalRef.current.showModal()
  }

  return (
    <div>
      <SectionHeading text='Saved reports' />
      <Modal
        ref={modalViewReportModalRef}
        className='c-modal c-modal--full c-modal--overflow-y'
        topLevelClassnames='u-margin-top-small'
        modalHeading=''
        data-testid='view-report-modal'
      >
        <ReportResultsModalView reportId={reportId} />
      </Modal>
      <ConfirmationModal ref={deleteReportRef} className='c-modal' modalHeading='Confirmation' onConfirm={() => deleteReport(reportId)}>
        <p>{reportCommonLabels.areYouSureWantToDelete}</p>
      </ConfirmationModal>

      <div className='o-layout'>
        {reports.map((report) => (
          <ReportItem
            key={report.id}
            report={report}
            handleReportModalOpen={() => handleModalOpen(modalViewReportModalRef, report)}
            onDelete={() => handleModalOpen(deleteReportRef, report)}
            isSavedReportList
            {...props}
          />
        ))}
      </div>
    </div>
  )
}

SavedReportsList.propTypes = {
  reports: PropTypes.array,
  deleteReport: PropTypes.func,
}

export default SavedReportsList
