import { cotSubdomain } from './config'
import { getTenantSubdomain } from './tenant'
import { getParentCompanies } from 'redux/selectors/parentCompanies'
import { isCot } from 'redux/selectors/auth'
import { getParsedDomain } from './query'

/**
 * check the host string of the url
 * @param {String} url
 * @return {Boolean}
 */

export const isCotDomain = (url) => {
  const currentDomainName = getTenantSubdomain()
  return currentDomainName === cotSubdomain
}

export const buildControlCenterUrl = (state) => {
  const { domain, tld } = getParsedDomain()
  const isCotUser = isCot(state)

  const parentCompanies = getParentCompanies(state)

  const currentTenant = parentCompanies?.[0]

  if (isCotUser) {
    return `https://console.${domain}.${tld}`
  }

  if (currentTenant) {
    return `https://${currentTenant.schema}.console.${domain}.${tld}`
  }
  return ''
}

export const DEFAULT_TICKETING_SYSTEM_URL = 'https://ppayslip.atlassian.net/servicedesk/customer/portal/2'
