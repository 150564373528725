import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'routes/Countries/modules/actions'

class Country extends CRUDModel {}
Country.modelName = 'Country'
Country.actionTypes = actionTypes

Country.fields = {
  id: attr(),
  name: attr(),
  currency: fk('Currency', 'countries'),
  lang: fk('Language', 'countries'),
  services: many({
    to: 'Service',
    relatedName: 'countries',
    through: 'CountryService',
    throughFields: ['country', 'service']
  }),
  timezones: many({
    to: 'Timezone',
    relatedName: 'countries',
    through: 'CountryTimezone',
    throughFields: ['country', 'timezone']
  }),
}

export default Country
