import createSelector from 'utils/createSelector'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { makePayrollInstanceTasksInitials,
  makeDisabledPayrollInstanceTasks } from 'redux/selectors/payrollInstanceTasks'
import { sortASCByAlphabeticName } from 'redux/models/task'

const getFilteredIds = makeFilteredIdsByFilter('payrollInstanceTask')

export const getPayrollInstanceTasksByFilter = createSelector(getFilteredIds,
  ({ PayrollInstanceTask }, ids) => (
    ids.map(id => {
      const task = PayrollInstanceTask.withId(id).ref

      return {
        ...task,
        // Here, in Vendor mode, we're removing the link, because this link is entirely Tenant/Client related
        // and the Vendor users don't have access to the link's pages.
        // Later, if needed, we can do it the same way, we already handled it in the Tenant mode:
        // routes/Payruns/selectors/payrollInstanceTasks.js `buildLink`.
        finishTaskLink: null
      }
    })
      .sort(sortASCByAlphabeticName))
)

export const getPayrollInstanceTasksByFilterInitials = makePayrollInstanceTasksInitials(
  getPayrollInstanceTasksByFilter
)

export const getDisabledPayrollInstanceTasksByFilter = makeDisabledPayrollInstanceTasks(
  getPayrollInstanceTasksByFilter
)
