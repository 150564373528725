import React, { useCallback, useState } from 'react'

/* eslint-disable max-len */
export const MultiArrow = ({ className = '', props }) => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' className={`icon-multi-arrow ${className}`} {...props}>
    <circle cx='10.5' cy='10.5' r='10.5' fill='var(--ps-muilti-arrow-color)' />
    <path
      d='M9.9645 15C9.79586 14.9993 9.63426 14.9323 9.51453 14.8136L6.56398 11.863C6.20285 11.5014 6 11.0113 6 10.5002C6 9.98921 6.20285 9.49906 6.56398 9.13747L9.51453 6.18693C9.63497 6.0672 9.79789 6 9.96772 6C10.1375 6 10.3005 6.0672 10.4209 6.18693C10.4812 6.24669 10.529 6.31778 10.5616 6.39612C10.5942 6.47445 10.611 6.55847 10.611 6.64333C10.611 6.72819 10.5942 6.81221 10.5616 6.89054C10.529 6.96888 10.4812 7.03997 10.4209 7.09973L7.47036 10.0438C7.41011 10.1036 7.36229 10.1747 7.32965 10.253C7.29702 10.3314 7.28022 10.4154 7.28022 10.5002C7.28022 10.5851 7.29702 10.6691 7.32965 10.7475C7.36229 10.8258 7.41011 10.8969 7.47036 10.9567L10.4209 13.9008C10.4812 13.9605 10.529 14.0316 10.5616 14.11C10.5942 14.1883 10.611 14.2723 10.611 14.3572C10.611 14.442 10.5942 14.526 10.5616 14.6044C10.529 14.6827 10.4812 14.7538 10.4209 14.8136C10.3608 14.8731 10.2896 14.9203 10.2113 14.9523C10.133 14.9843 10.0491 15.0005 9.9645 15Z'
      fill='white'
    />
    <path
      d='M14.4638 15C14.2952 14.9993 14.1336 14.9323 14.0139 14.8136L10.1569 10.9567C10.0967 10.8969 10.0489 10.8258 10.0162 10.7475C9.9836 10.6691 9.9668 10.5851 9.9668 10.5002C9.9668 10.4154 9.9836 10.3314 10.0162 10.253C10.0489 10.1747 10.0967 10.1036 10.1569 10.0438L14.0139 6.18693C14.1343 6.0672 14.2972 6 14.4671 6C14.6369 6 14.7998 6.0672 14.9202 6.18693C14.9805 6.24669 15.0283 6.31778 15.0609 6.39612C15.0936 6.47445 15.1104 6.55847 15.1104 6.64333C15.1104 6.72819 15.0936 6.81221 15.0609 6.89054C15.0283 6.96888 14.9805 7.03997 14.9202 7.09973L11.5197 10.5002L14.9202 13.9008C14.9805 13.9605 15.0283 14.0316 15.0609 14.11C15.0936 14.1883 15.1104 14.2723 15.1104 14.3572C15.1104 14.442 15.0936 14.526 15.0609 14.6044C15.0283 14.6827 14.9805 14.7538 14.9202 14.8136C14.8602 14.8731 14.7889 14.9203 14.7106 14.9523C14.6323 14.9843 14.5484 15.0005 14.4638 15Z'
      fill='white'
    />
  </svg>
)

export const MultiArrowMoved = ({ className = '', props }) => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' className={`icon-multi-arrow ${className}`} {...props}>
    <circle cx='10.5' cy='10.5' r='10.5' fill='var(--ps-muilti-arrow-moved-color)' />
    <path
      d='M7.9645 15C7.79586 14.9993 7.63426 14.9323 7.51453 14.8136L4.56398 11.863C4.20285 11.5014 4 11.0113 4 10.5002C4 9.98921 4.20285 9.49906 4.56398 9.13747L7.51453 6.18693C7.63497 6.0672 7.79789 6 7.96772 6C8.13754 6 8.30046 6.0672 8.4209 6.18693C8.48115 6.24669 8.52898 6.31778 8.56161 6.39612C8.59425 6.47445 8.61105 6.55847 8.61105 6.64333C8.61105 6.72819 8.59425 6.81221 8.56161 6.89054C8.52898 6.96888 8.48115 7.03997 8.4209 7.09973L5.47036 10.0438C5.41011 10.1036 5.36229 10.1747 5.32965 10.253C5.29702 10.3314 5.28022 10.4154 5.28022 10.5002C5.28022 10.5851 5.29702 10.6691 5.32965 10.7475C5.36229 10.8258 5.41011 10.8969 5.47036 10.9567L8.4209 13.9008C8.48115 13.9605 8.52898 14.0316 8.56161 14.11C8.59425 14.1883 8.61105 14.2723 8.61105 14.3572C8.61105 14.442 8.59425 14.526 8.56161 14.6044C8.52898 14.6827 8.48115 14.7538 8.4209 14.8136C8.36084 14.8731 8.2896 14.9203 8.21129 14.9523C8.13297 14.9843 8.0491 15.0005 7.9645 15Z'
      fill='white'
    />
    <path
      d='M12.4643 15C12.2957 14.9993 12.1341 14.9323 12.0144 14.8136L8.15743 10.9567C8.09718 10.8969 8.04936 10.8258 8.01672 10.7475C7.98409 10.6691 7.96729 10.5851 7.96729 10.5002C7.96729 10.4154 7.98409 10.3314 8.01672 10.253C8.04936 10.1747 8.09718 10.1036 8.15743 10.0438L12.0144 6.18693C12.1348 6.0672 12.2977 6 12.4675 6C12.6374 6 12.8003 6.0672 12.9207 6.18693C12.981 6.24669 13.0288 6.31778 13.0614 6.39612C13.0941 6.47445 13.1109 6.55847 13.1109 6.64333C13.1109 6.72819 13.0941 6.81221 13.0614 6.89054C13.0288 6.96888 12.981 7.03997 12.9207 7.09973L9.52021 10.5002L12.9207 13.9008C12.981 13.9605 13.0288 14.0316 13.0614 14.11C13.0941 14.1883 13.1109 14.2723 13.1109 14.3572C13.1109 14.442 13.0941 14.526 13.0614 14.6044C13.0288 14.6827 12.981 14.7538 12.9207 14.8136C12.8607 14.8731 12.7894 14.9203 12.7111 14.9523C12.6328 14.9843 12.5489 15.0005 12.4643 15Z'
      fill='white'
    />
  </svg>
)

export const MultiArrowTransitioendIcon = ({ className = '', handleClick }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [handleClick])
  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [handleClick])

  return (
    <div className={className} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isHovered ? <MultiArrowMoved /> : <MultiArrow />}
    </div>
  )
}
