import React from 'react'
import { Field } from 'redux-form'
import AmountInputField from './form/AmountInputField'
import FieldDatePicker from './form/FieldDatePicker'
import PayslipTable from './table/PayslipTable'
import { amountTransformer } from 'redux/transformers/amountsTransformer'
import PropTypes from 'prop-types'

const getTermId = (str, formFieldName) => formFieldName.replace(str, '')
const hasAnAmount = (id, values) => !!values[`deduction-${id}-amount`]
const hasValidFromDate = (id, values) => !!values[`deduction-${id}-validFrom`]
const hasValidToDate = (id, values) => !!values[`deduction-${id}-validTo`]
const getValidFromDate = (id, values) => values[`deduction-${id}-validFrom`]

const valueRequired = (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-amount', termId)

  const hasValidFrom = hasValidFromDate(termId, values)
  const hasValidTo = hasValidToDate(termId, values)
  if ((hasValidFrom || hasValidTo) && !value) {
    return 'This value should not be blank'
  }

  return undefined
}
const validFromDateRequired = (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-validFrom', termId)
  const hasAmount = hasAnAmount(termId, values)
  if (hasAmount && !value) {
    return 'From Date field is required'
  }

  return undefined
}

const validToDateRequired = (value, values, props, formFieldName) => {
  let termId = getTermId('deduction-', formFieldName)
  termId = getTermId('-validTo', termId)
  const hasAmount = hasAnAmount(termId, values)
  const hasValidFrom = hasValidFromDate(termId, values)
  const fromDate = getValidFromDate(termId, values)
  if (hasAmount && hasValidFrom && value && fromDate > value) {
    return 'The To Date should be the same or after the From Date.'
  }

  return undefined
}

const RecurringDeductionsTable = ({ tableGrid, deductions, initialValues: { disabledFields = [] } = {} }) => {
  return <div className='o-layout '>
    <div className={`o-layout__item ${tableGrid}`}>
      <PayslipTable
        headings={[
          { accessor: 'companyCountryTermId', Header: '', isKey: true, isVisible: false, disableSortBy: true, disableFilters: true },
          { accessor: 'fullName', Header: 'Name', disableSortBy: true, disableFilters: true },
          { accessor: 'validFrom', Header: 'From date', disableSortBy: true, disableFilters: true, columnClassName: 'disabled-rounded-input-border' },
          { accessor: 'validTo', Header: 'To date', disableSortBy: true, disableFilters: true, columnClassName: 'disabled-rounded-input-border' },
          { accessor: 'amount', Header: 'Value', disableSortBy: true, disableFilters: true, columnClassName: 'disabled-rounded-input-border' }
        ]}
        data={deductions.map(item => ({
          ...item,
          validFrom: <Field
            className='c-input c-input--transparent u-border-bottom--minsk'
            name={`deduction-${item.companyCountryTermId}-validFrom`}
            component={FieldDatePicker}
            formGroupClassName='u-margin-none'
            disabled={disabledFields.includes(`deduction-${item.companyCountryTermId}`)}
            validate={[validFromDateRequired]}
          />,
          validTo: <Field
            className='c-input c-input--transparent u-border-bottom--minsk'
            name={`deduction-${item.companyCountryTermId}-validTo`}
            component={FieldDatePicker}
            formGroupClassName='u-margin-none'
            validate={[validToDateRequired]}
          />,
          amount: <Field
            name={`deduction-${item.companyCountryTermId}-amount`}
            component={AmountInputField}
            parse={amountTransformer}
            placeholder='Enter value'
            className='c-input c-input--transparent u-border-bottom--minsk'
            formGroupClassName='u-margin-none'
            validate={[valueRequired]}
          />
        }))}
        wrapperClassName='u-margin-bottom u-1/1'
        trClassName='u-cursor--pointer'
        modifierClasses='react-bs-container-body'
        tableElementClassName='table--layout-auto'
        options={{
          noDataMessage: 'No matching records found.'
        }}
      />
    </div>
  </div>
}

RecurringDeductionsTable.propTypes = {
  tableGrid: PropTypes.any,
  deductions: PropTypes.any,
  initialValues: PropTypes.any,
}

export default RecurringDeductionsTable
