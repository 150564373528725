import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { getTermDisplayName } from 'utils/sections/payAndTaxes/employee'
import { TableHeading } from 'utils/helperClasses/tableHeadings'
import EditableCell from 'components/table/cell/EditableCell'
import { format } from 'date-fns'
import { EditableCellDataEmployeeFieldsFormatter } from 'components/table/cell/EditableCellDataEmployeeFieldsFormatter'
import { isDate } from 'lodash'

const EmptyRow = (props) => {
  return (
    <div className='v-hidden'><span title='Toggle Row Expanded' className='icon--arrow' /></div>
  )
}

const ExpandedRow = ({ deductions, editableOptions, dataKey }) => {
  const valueEditOptions = { Cell: EditableCell, placeholder: 'Value', editable: true, inputType: 'amount' }
  const dateOptions = { Cell: EditableCell, inputType: 'date', editable: true }
  let rows = []

  deductions.forEach((d) => {
    rows.push({
      title: getTermDisplayName(d),
      validFrom: d.validFrom ? (isDate(d.validFrom) ? format(d.validFrom, 'dd/MM/yyyy') : d.validFrom) : null,
      validTo: d.validTo ? (isDate(d.validTo) ? format(d.validTo, 'dd/MM/yyyy') : d.validTo) : null,
      amount: d.amount,
      subRows: d.subRows,
      metaData: { subCategory: dataKey, mappedErrorFieldId: d.employeeTermId },
      emptyRow: <EmptyRow />
    })
  })

  return (
    <PayslipTable
      wrapperClassName='u-1/1'
      tableElementClassName='u-margin-top-none table--layout-auto '
      editableOptions={editableOptions}
      headings={[
        new TableHeading({ accessor: 'title', heading: 'Title', className: 'd-none', columnClassName: 'text-left u-padding-left-none' }),
        new TableHeading({
          accessor: 'validFrom',
          heading: 'From Date',
          className: 'd-none',
          columnClassName: 'text-right u-fixed-width--125',
          ...dateOptions,
          placeholder: 'From Date',
          mappedErrorField: 'validFrom',
        }),
        new TableHeading({
          accessor: 'validTo',
          heading: 'To Date',
          className: 'd-none',
          columnClassName: 'text-right u-fixed-width--125',
          ...dateOptions,
          placeholder: 'To Date',
          mappedErrorField: 'validTo',
        }),
        new TableHeading({
          accessor: 'amount',
          heading: 'Value',
          className: 'd-none ',
          columnClassName: 'text-right u-fixed-width--200',
          ...valueEditOptions,
          placeholder: 'Value',
        }),
        new TableHeading({ accessor: 'emptyRow', heading: 'emptyRow', className: 'd-none ', columnClassName: 'text-right u-fixed-width--50' }),
      ]}
      data={rows}
    />
  )
}

ExpandedRow.propTypes = {
  deductions: PropTypes.array,
  editableOptions: PropTypes.object,
  dataKey: PropTypes.string,
}

const Deductions = ({ deductions, futureDeductions, title, inAddMode, inEditMode, onUpdateGlobalDataForSave, dataKey, futureDataKey, fieldErrors }) => {
  const editableOptions = {
    inGlobalEditMode: inEditMode,
    inAddMode: inAddMode,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
    onCancelInlineAdd: () => null,
    onSaveInline: () => null,
    fieldErrors: fieldErrors,
    onValueChange: () => null,
  }
  const getRowExpandable = (futureDeductions, options, futureDataKey) => {
    return <ExpandedRow deductions={futureDeductions} editableOptions={options} dataKey={futureDataKey} />
  }

  const renderRows = (deductions, options) => {
    let rows = []
    deductions.forEach((term, idx) => {
      const futureItems = futureDeductions ? futureDeductions.filter((future) => term.id === future.id) : []
      let row = {
        title: getTermDisplayName(term),
        validFrom: term.validFrom ? (isDate(term.validFrom) ? format(term.validFrom, 'dd/MM/yyyy') : term.validFrom) : null,
        validTo: term.validTo ? (isDate(term.validTo) ? format(term.validTo, 'dd/MM/yyyy') : term.validTo) : null,
        amount: term.amount,
        subRows: [{ id: term.id, ExpandedComponent: (props) => getRowExpandable(futureItems, props, futureDataKey), editableOptions: editableOptions }],
        editDisabled: term.shouldDisableEditOnLoad || ((!term.isRecurring || (!term.validFrom && !term.validTo)) && !inEditMode),
        addDisabled: !term.isRecurring,
        metaData: { subCategory: dataKey, hasFutureItems: !!futureItems.length, mappedErrorFieldId: term.employeeTermId, mappedNewErrorId: term.addServerId },
      }
      if (term.isNew) {
        row['isNew'] = term.isNew
      }
      rows.push(row)
    })

    return rows
  }

  const data = renderRows(deductions, editableOptions)
  const commonSettings = { disableSortBy: false, className: 'd-none' }
  const dateOptions = {
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
    inputType: 'date',
    editable: true,
  }
  const valueEditOptions = {
    Cell: (props) => {
      return <EditableCellDataEmployeeFieldsFormatter {...props} />
    },
    placeholder: 'Value',
    editable: true,
  }

  return (
    <div className='page--pay-taxes-deductions'>
      <PayslipTable
        wrapperClassName='u-1/1 deductions-expanded-table'
        tableElementClassName='u-margin-bottom-large table--layout-auto'
        headingColSpan='2'
        dynamicValues
        autoResetExpanded={!inEditMode}
        useCustomExpandedDisplay
        editableOptions={editableOptions}
        headings={[
          {
            Header: title,
            classNames: 'text-center',
            title: title,
            columns: [
              new TableHeading({ accessor: 'title', heading: 'Title', ...commonSettings, columnClassName: 'text-left' }),
              new TableHeading({
                accessor: 'validFrom',
                heading: 'Value',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--125',
                ...dateOptions,
                placeholder: 'From Date',
                mappedErrorField: 'validFrom',
              }),
              new TableHeading({
                accessor: 'validTo',
                heading: 'Title',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--125',
                ...dateOptions,
                placeholder: 'To Date',
                mappedErrorField: 'validTo',
              }),
              new TableHeading({
                accessor: 'amount',
                heading: 'Value',
                ...commonSettings,
                columnClassName: 'text-right u-fixed-width--200',
                ...valueEditOptions,
                inputType: 'amount',
                placeholder: 'Value',
              }),
              {
                id: 'expander',
                expander: true,
                editable: false,
                columnClassName: 'text-right u-fixed-width--50',
                classNames: 'd-none',
                disableFilters: true,
                disableSortBy: true,
                // eslint-disable-next-line react/prop-types
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                  <span {...getToggleAllRowsExpandedProps()}>{' '}</span>
                ),
                Cell: ({ row }) => {
                  // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                  // to build the toggle for expanding a row
                  const View = row.canExpand ? (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                      className={row.isExpanded ? 'icon--arrow active' : 'icon--arrow'}
                    />
                  ) : null

                  return row.original.metaData.hasFutureItems ? View : null
                },
              },
            ],
          },
        ]}
        data={data}
      />
    </div>
  )
}

Deductions.propTypes = {
  deductions: PropTypes.array,
  title: PropTypes.string,
  row: PropTypes.object,
  inAddMode: PropTypes.bool,
  inEditMode: PropTypes.bool,
  futureDeductions: PropTypes.array,
  onUpdateGlobalDataForSave: PropTypes.func,
  dataKey: PropTypes.string,
  futureDataKey: PropTypes.string,
  fieldErrors: PropTypes.array,
}

export default Deductions
