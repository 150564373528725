import { createSelector as createORMSelector } from 'redux-orm'
import orm from '../redux/orm'

/**
 * Shorthand pattern for creating Redux ORM selectors
 *
 * @ref https://github.com/tommikaikkonen/redux-orm/pull/154
 * @param rest
 */
export default function createSelector (...rest) {
  return createORMSelector(orm, ...rest)
}
