import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryDataProtectionFieldsPivot'

class CountryDataProtectionField extends CRUDModel {}
CountryDataProtectionField.modelName = 'CountryDataProtectionField'
CountryDataProtectionField.actionTypes = actionTypes

CountryDataProtectionField.fields = {
  id: attr(),
  dataProtectionField: fk('DataProtectionField', 'countryDataProtectionFields')
}

export default CountryDataProtectionField
