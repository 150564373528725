import createSelector from 'utils/createSelector'

// get the company id
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

// get the route companies
export const getCompaniesWithCountryWithCompanyServices = createSelector(
  ({ Company }) => {
    // return all country services from the company country
    return Company
      .all()
      .toModelArray()
      .map(company => _getCompanyWithCountryWithCompanyServices(company))
  })

export const getCompanyWithCountryWithCompanyServices = createSelector(getCompanyId,
  ({ Company }, id) => {
    // find the route company
    let company = Company.withId(id)

    return _getCompanyWithCountryWithCompanyServices(company)
  })

const _getCompanyWithCountryWithCompanyServices = company => ({
  ...company.ref,
  isHQ: company.isHQ(),
  country: company.country.ref,
  companyServices: company
    .companyCountryServices
    .toModelArray()
    .map(pivot => ({
      ...pivot.ref,
      service: {
        ...pivot.countryService.service.ref
      },
      vendor: pivot.vendorCountryService
        ? {
          ...pivot.vendorCountryService.vendor?.ref
        }
        : null
    }))
    .reverse()
})
