import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/dataOwners'

class DataOwner extends CRUDModel {}
DataOwner.modelName = 'DataOwner'
DataOwner.actionTypes = actionTypes

DataOwner.fields = {
  id: attr(),
  company: fk('Company', 'dataOwner'),
  employee: fk('EmployeeSystemUser', 'dataOwner'),
}

export default DataOwner
