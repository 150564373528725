import React from 'react'
import { Field } from 'redux-form'
import { isEmpty } from 'lodash'
import CheckboxOption from 'components/form/select/CheckboxOption'
import CustomSelectField from 'components/form/select/CustomSelectField'
import MostRecentRunReportItem from './MostRecentRunReportItem'
import SubmitButtons from './SubmitButtons'

const FiltersAccessManagementReport = ({
  countries,
  companies,
  processes,
  isFieldDisabled,
  disabledReason,
  selected,
  readonlyFields,
  hideNavigationButtons,
  navigateToParentOnClick,
  mostRecentRunReport,
  ...props
}) => {
  const shouldShowMostRecentRunReportItem = !props.reportItem

  return (
    <div className='o-block--inline'>
      {!isEmpty(selected.subcategory) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='process'
              label='Services *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--left-padding'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              disabled={isFieldDisabled || readonlyFields.servicesLocked}
              disabledReason={disabledReason}
              options={processes}
              enableRemovedOptionFix
              data-testid='process-wrapper'
            />
          </div>
        </div>
      )}
      <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-modern-input'>
          <Field
            name='country'
            label='Country *'
            component={CustomSelectField}
            className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            optionComponent={CheckboxOption}
            placeholder='Select'
            disabled={isFieldDisabled}
            disabledReason={disabledReason}
            multi
            options={countries}
            enableRemovedOptionFix
            data-testid='country-wrapper'
          />
        </div>
      </div>
      {!isEmpty(selected.country) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='company'
              label='Company *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={companies}
              enableRemovedOptionFix
              data-testid='company-wrapper'
            />
          </div>
        </div>
      )}
      {!hideNavigationButtons && <SubmitButtons pristineCheck={!isEmpty(selected.company)} onBackClick={navigateToParentOnClick} {...props} />}
      {shouldShowMostRecentRunReportItem && (
        <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem
            mostRecentRunReport={mostRecentRunReport}
            onOpenReport={props.handleReportModalOpen}
            onDelete={props.deleteReport}
            {...props}
          />
        </div>
      )}
    </div>
  )
}

export default FiltersAccessManagementReport
