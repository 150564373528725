import createSelector from 'utils/createSelector'
import { makeFilteredIdsByFilter } from './filters'

const getDocumentId = (state, props) => parseInt(props.documentId)
const getDocumentTenant = (state, props) => props.documentTenant
const getUserId = (state, props) => parseInt(props.userId)
const getUserTenant = (state, props) => props.userTenant
const getUsers = (state, props) => props.users
const getUserType = (state, props) => props.userType
// get filtered ids, by passed in filter
export const getFilteredIdsByFilter = makeFilteredIdsByFilter('documentUsers')

// Get all document users for a document with passed documentId and documentTenant
export const getDocumentUsersByDocIdAndDocTenant = createSelector(getDocumentId, getDocumentTenant,
  ({ DocumentUser }, documentId, documentTenant) => {
    return DocumentUser
      .filter(docUser => docUser.documentId === documentId && docUser.documentTenant === documentTenant)
      .toRefArray()
      .map(docUser => ({ ...docUser }))
  })

// Check if a particular file is shared with a user
export const getIsSharedDocByUserByDocIdByDocTenant = createSelector(getDocumentUsersByDocIdAndDocTenant, getUserId, getUserTenant,
  (session, docUsers, userId, userTenant) => {
    const file = docUsers.find(docUser => docUser.userId === userId && docUser.userTenant === userTenant)

    return file !== undefined
  })

// Get initial values of checkboxes for file share in users list
export const getInitialValues = createSelector(getUsers, getUserType,
  (session, users, userType) => {
    let initialValues = {}
    switch (userType) {
    case 'vendorUser': users.forEach(user => {
      initialValues[`${user.vendor.schema}-${user.id}`] = user.share
    })
      break
    case 'externalEmployeeUser':
    case 'externalVendorUser':
    case 'onboardingVendorUser':
    case 'offboardingVendorUser': users.forEach(user => {
      initialValues[`${user.extraData.tenant}-${user.extraData.id}`] = user.share
    })
      break
    case 'employeeUser': users.forEach(user => {
      initialValues[`${user.schema}-${user.id}`] = user.share
    })
      break
    default: users.forEach(user => {
      initialValues[`id-${user.id}`] = user.share
    })
    }
    return initialValues
  })
