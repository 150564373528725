import { createFilter } from 'utils/redux/filter'

/**
 * This filter creates employee filter for entity in the Fetcher.
 *
 * In our employee containers there are multiple times where we use 'createFilter' for employee filtering.
 * Instead of multiple duplicate code declaration we decided to reuse our createEmployeeFilter here.
 * You can check out the implementation in Employees -> Employee subroutes.
 *
 * @param {String} name
 * the name of the Fetcher entity - default one is 'employeeSystemUsers' since this is createEmployeeFilter
 * @param {String} filterName
 * filterName is the name of the filter which is added to the entity name
 *
 * Filtering by employee on routes other than 'employeeSystemUsers' is by 'employee' filter, otherwise is by 'id'.
 * This is why here default filterName is 'employee', since it's most used with employee filtering.
 * @param {Function} getId
 * getId is a function which gets employeeId from passed props object and passes it as value to filterName.
 * By default we filter by employee and get it from props.match.params.employeeId.
 *
 *
 * Examples:
 * ```
 * 1. Filtering employeeSystemUsers by id (for specific employee)
 * createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
 *
 * filter created:
 * {
 *  'employeeSystemUsers',
 *  params: [{
 *    _computed: {
 *      filter: (state, props) => createFilter({ id: getId(props) })
 *    }
 *  }]
 * }
 *
 *
 * 2. Filtering employeeTermPivot by employeeId
 * createEmployeeFilter({ name: 'employeeTermPivot' })
 *
 * filter created:
 * {
 *  'employeeTermPivot',
 *  params: [{
 *    _computed: {
 *      filter: (state, props) => createFilter({ employee: getId(props) })
 *    }
 *  }]
 * }
 * ```
 *
 *
 * TODO: We will need to apply changes in the future:
 *
 * 1. For the case when we need to add more than one parameter to the Fetcher. For example:
 * createEmployeeFetcher({ params: [{ disableObsoleteFlow: true }] })
 * 2. We can have a dynamic function which creates filter for different entities. For example:
 * createPayrollInstanceFilter,
 * 3. Add isKeyPerson filtration to employeeSystemUsers with createEmployeeFilter and make sure it works
 *
 */
export default ({
  name = 'employeeSystemUsers',
  filterName = 'employee',
  getId = props => props.match.params.employeeId
}) => ({
  name,
  params: [{
    _computed: {
      filter: (state, props) => createFilter({ [filterName]: getId(props) })
    }
  }]
})
