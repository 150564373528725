import { formValueSelector } from 'redux-form'

/**
 * Get form field value
 *
 * @param {Object} state
 * @param {String} formName - Name of the redux-form instance
 * @param {String} fieldName - Name of the field, which value we want to get
 *
 * @returns {*}
 */
export const getFormFieldValue = (state, formName, fieldName) => {
  const selector = formValueSelector(formName)
  const field = selector(state, fieldName)

  return field
}

/**
 * Get form field value as Integer
 *
 * @inheritDoc getFormFieldValue()
 *
 * @param state
 * @param formName
 * @param fieldName
 * @returns {*}
 */
export const getFormFieldValueToInt = (state, formName, fieldName) => {
  const value = getFormFieldValue(state, formName, fieldName)

  return value ? parseInt(value) : value
}
