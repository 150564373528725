import React from 'react'
import { isNull } from 'lodash'
import PropTypes from 'prop-types'
import Submit from 'components/buttons/Submit'
import { propTypes } from 'redux-form'

/**
 * Component that will render both Reporting buttons (Save and Save & Run)
 * and will add loading/disabled state only to the clicked button.
 * Otherwise - clicking on a single button will add the loading/disabled state to both buttons.
 */
class SubmitButtons extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      clickedButton: null
    }
  }

  handleClick (clickedButton, shouldSave) {
    return this.props.handleSubmit(data => {
      this.setState({ clickedButton })

      const params = [
        data,
        ...shouldSave ? [true] : []
      ]

      return this.props.onSubmit(...params)
    })
  }

  renderButton (name, text, shouldSave, className) {
    const isDisabled = !isNull(this.props.pristineCheck) ? !this.props.pristineCheck : this.props.pristine || this.props.submitting
    return <Submit
      onClick={this.handleClick(name, shouldSave)}
      pristine={this.props.pristine}
      // Show loader only on the clicked button
      submitting={this.state.clickedButton === name && this.props.submitting}
      disabled={isDisabled || this.props.submitting}
      className={className}
    >
      {text}
    </Submit>
  }

  render () {
    return <div className='o-layout__item u-text--center u-1/1'>
      { this.renderButton('run', 'Run', false, 'rounded-md') }
    </div>
  }
}

SubmitButtons.propTypes = {
  pristineCheck: propTypes.bool
}

SubmitButtons.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default SubmitButtons
