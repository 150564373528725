import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const SERVICES_FETCH = 'SERVICES_FETCH'
export const SERVICES_RECEIVE = 'SERVICES_RECEIVE'
export const SERVICES_INVALIDATE = 'SERVICES_INVALIDATE'
export const SERVICE_CREATE = 'SERVICE_CREATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: SERVICES_FETCH,
  receive: SERVICES_RECEIVE,
  invalidate: SERVICES_INVALIDATE,
  create: SERVICE_CREATE,
}
const actionUtility = new ActionUtility(actionTypes, 'services', 'services', 'Service')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchServicesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchServices = actionUtility.fetchEntities
export const invalidateServices = actionUtility.invalidate
export const createService = actionUtility.createEntity
