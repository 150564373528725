import { ActionUtility } from 'utils/redux'
import { invalidatePayrollInstanceTask } from './payrollInstanceTask'
import { invalidatePayrollInstanceTaskOwner } from './payrollInstanceTaskOwner'
import { invalidatePayrollInstanceTaskOwnerScope } from './payrollInstanceTaskOwnerScope'
import _ from 'lodash'
import { isNumeric } from 'utils/number'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_TASK_FETCH = 'PAYROLL_TASK_FETCH'
export const PAYROLL_TASK_RECEIVE = 'PAYROLL_TASK_RECEIVE'
export const PAYROLL_TASK_CREATE = 'PAYROLL_TASK_CREATE'
export const PAYROLL_TASK_UPDATE = 'PAYROLL_TASK_UPDATE'
export const PAYROLL_TASK_INVALIDATE = 'PAYROLL_TASK_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_TASK_FETCH,
  receive: PAYROLL_TASK_RECEIVE,
  create: PAYROLL_TASK_CREATE,
  update: PAYROLL_TASK_UPDATE,
  invalidate: PAYROLL_TASK_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollTask', 'payrolltasks', 'PayrollTask')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollTaskIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollTask = actionUtility.fetchEntities
export const invalidatePayrollTask = actionUtility.invalidate

const invalidate = dispatch => {
  dispatch(invalidatePayrollInstanceTask())
  dispatch(invalidatePayrollInstanceTaskOwner())
  dispatch(invalidatePayrollInstanceTaskOwnerScope())
}

export const createPayrollTask = entity => dispatch => (
  dispatch(actionUtility.createEntity(buildData(entity)))
    .then(() => invalidate(dispatch))
)

const verifyOwnersChanges = (sanitized, initOwners, formOwners) => {
  const initOwnersLength = initOwners.length
  const hasFormOwnersLength = formOwners.length
  const hasInitOwners = initOwnersLength > 0
  const hasFormOwners = hasFormOwnersLength > 0

  // Both initial records and form values have empty []
  // Means that there has been no changes to the owner field
  if (!hasInitOwners && !hasFormOwners) {
    return false
  }

  // If the length between the two items is different
  // i.e form values have a length of 1, and initial is 2
  // This means there has been a change to the owners field
  if (hasFormOwnersLength !== initOwnersLength) {
    return true
  }
  // If the length between the two items is the same
  // Will have to verify if the data in it is the same (ie. the same id number)
  // ex. you already have 1 owner, and then you just change the owner
  // Additionally will have to verify if the difference
  if (hasFormOwnersLength === initOwnersLength) {
    const difference = _.xorWith(formOwners, initOwners, _.isEqual)
    return difference.length > 0
  }
  return true
}

export const updatePayrollTask = (entity, id, initValues = {}, buOwnersData = []) => {
  const data = buildData(entity)
  const callbackFn = entity.taskableType !== 'Company' ? buildMultiOwners : buildSingleOwners
  const initOwners = callbackFn(initValues)
  const entityOwner = callbackFn(entity)
  const sanitized = {}

  Object.keys(data).forEach(key => {
    const newValue = data[key]
    const initValue = initValues[key]

    if (newValue !== initValue) {
      sanitized[key] = newValue
    }
  })
  let hasOwnerChanges = verifyOwnersChanges(sanitized, initOwners, entityOwner)
  // We always want to send the owners when the taskable type is sent
  if (!('taskableType' in sanitized)) {
    if (!hasOwnerChanges) {
      delete sanitized['owners']
    }
  }
  // If Owners are being sent, the backend requires the taskableType to be added
  if ('owners' in sanitized) {
    sanitized['taskableType'] = entity.taskableType
    // When switching form single to multiple people
    // And not selecting any owners from the business units the form will think the owners
    // is [], the backend rejects this as a value.  So will loop through the total amount of filtered
    // out business units, and build up the expected payload of { owner: null, taskableId: Number}
    if (entity.taskableType !== 'Company') {
      if (sanitized.owners.length === 0) {
        sanitized.owners = buOwnersData.map(bu => {
          return {
            owner: null, // This should always be null as no value was selected
            taskableId: bu.id // You can see the actual built name in 'BusinessUnitsOwnersTable
          }
        })
      }
    }
  }

  return dispatch => {
    return dispatch(actionUtility.updateEntity(sanitized, id))
      .then(() => invalidate(dispatch))
  }
}

const buildData = entity => {
  const buildOwners = entity.taskableType === 'Company'
    ? buildSingleOwners(entity)
    : buildMultiOwners(entity)

  return {
    name: entity.name,
    position: entity.position,
    payrollStep: entity.payrollStep,
    owners: buildOwners,
    deadline: isNumeric(entity.deadline) ? parseInt(entity.deadline) : null,
    taskableType: entity.taskableType,
    // When we operate in "Multiple owners" Form mode, `task.active` should be always `true` (business requirement).
    // We set it here, because  for "Single owner" and "Multiple owners" we use only one Form.
    // Having only one Form, when we change the form mode from Single to Multiple mode,
    // then the Single form values are still kept in the Store. The opposite (from Multi to Single) is still valid.
    // Because of this known issue, it's possible to submit "Multiple owners", but the active value to be `false`,
    // because of already changed `active` value on the previous "Single" mode.
    // For now - this solution is fine. If the both modes business logic change drastically in the future,
    // then it would be better to separate the logic in two different forms.
    active: entity.taskableType === 'Company' ? entity.active : true,
    ...entity.globalOwner && { globalOwner: entity.globalOwner },
  }
}

/*
 * Build data to update single owner
 */
export const buildSingleOwners = (entity) => {
  return [{
    owner: entity.owner,
    taskableId: entity.companyId
  }]
}

/*
 * Build data to update multi owner
 */
export const buildMultiOwners = (entity) => {
  const multiOwners = []
  const picked = _.pickBy(entity, (value, key) => _.startsWith(key, 'actionOwnerBu-'))
  _.forOwn(picked, (value, key) => {
    multiOwners.push({
      owner: value,
      taskableId: parseInt(key.split('-')[1])
    })
  })

  return multiOwners
}
