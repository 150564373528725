import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { cotRoleTypes } from 'redux/config/auth'
import { getParentCompaniesTenants } from 'redux/selectors/tenants'
import { isCotAdmin } from 'redux/selectors/auth'

export default (WrappedComponent, formName) => {
  const mapDispatchToProps = dispatch => {
    return {
      // We reset parent company field on COT roleType change,
      // since we don't add access areas when selected roleType is ROLE_COT_ADMIN.
      // Otherwise, the already selected fields are still kept in the Store,
      // and later sent to the API.
      resetForm: () => {
        const fieldsToReset = ['accessAreaParentCompany']
        fieldsToReset.forEach(field => dispatch(change(formName, field, null)))
      },
      dispatch
    }
  }

  const mapStateToProps = state => {
    const selector = formValueSelector(formName)
    const values = selector(state, 'roleType', 'accessAreaParentCompany')

    return {
      isCotAdmin: isCotAdmin(state),
      parentCompaniesTenants: getParentCompaniesTenants(state),
      roleType: values.roleType,
      cotRoleTypes,
      accessAreaParentCompany: values.accessAreaParentCompany
    }
  }

  const Component = connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)

  return Fetcher(Component, ['parentCompanies'])
}

/**
 * Build `accessAreas` BE API field value
 *
 * Currently BE API field `accessAreas` supports accessAreas which are array of ids.
 *
 * @param accessAreas - form submitted Access Areas options
 * @param accessableType - passed type of area. Currently on COT we have only Parent
 * @return {[*,*]}
 */
export const buildAccessArea = (accessAreas = [], accessableType) => {
  if (!accessAreas || !accessAreas.length) return []

  return accessAreas
    .map(aa => ({ accessableId: aa, accessableType }))
}
