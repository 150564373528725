import createSelector from 'utils/createSelector'

/**
 * Get country task id
 * @param state
 * @param props
 */
const getCountryTaskId = (state, props) => parseInt(props.countryTaskId)

/**
 * Get vendor country service id
 * @param state
 * @param props
 */
const getVendorCountryServiceId = (state, props) => parseInt(props.vendorCountryServiceId)

/**
 * Get vendor country task selector
 */
export const getVendorCountryTask = createSelector(getCountryTaskId, getVendorCountryServiceId,
  ({ VendorCountryTask }, countryTaskId, vendorCountryServiceId) => {
    return getVendorCountryTaskFunc(VendorCountryTask, countryTaskId, vendorCountryServiceId)
  }
)

/**
 * Get vendor country task function
 */
export const getVendorCountryTaskFunc = (vendorCountryTask, countryTaskId, vendorCountryServiceId) => {
  const vendorTask = vendorCountryTask.filter({
    countryTask: countryTaskId,
    vendorCountryService: vendorCountryServiceId
  }).toRefArray()[0]

  return vendorTask
}
