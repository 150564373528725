import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { SelectColumnFilter } from 'components/table/filters'
import { country, icon, status } from 'utils/tableDataFormatters'
import NotificationHOC from 'components/NotificationsHOC'
import SectionHeading from 'components/SectionHeading'
import FilterButton from 'components/buttons/FilterButton'
import { notificationTypeLabels } from 'utils/locales/notifications.en'
let classNames = require('classnames')

const typesOptions = [
  { value: 'FinalReversionTaskCompletedNotification', label: notificationTypeLabels.FinalReversionTaskCompletedNotification },
  { value: 'GTNCalculationsFinishedNotification', label: notificationTypeLabels.GTNCalculationsFinishedNotification },
  { value: 'GTNValidationFailedNotification', label: notificationTypeLabels.GTNValidationFailedNotification },
  { value: 'GTNValidationErrorNotification', label: notificationTypeLabels.GTNValidationErrorNotification },
  { value: 'TaskFinishedNotification', label: notificationTypeLabels.TaskFinishedNotification },
  { value: 'AODeadlinePassedNotification', label: notificationTypeLabels.AODeadlinePassedNotification },
  { value: 'NODeadlinePassedNotification', label: notificationTypeLabels.NODeadlinePassedNotification },
  { value: 'PODeadlinePassedNotification', label: notificationTypeLabels.PODeadlinePassedNotification },
  { value: 'FiveDaysToDeadlineNotification', label: notificationTypeLabels.FiveDaysToDeadlineNotification },
  { value: 'DeadlineTodayNotification', label: notificationTypeLabels.DeadlineTodayNotification },
  { value: 'TaskNotFinishedNotification', label: notificationTypeLabels.TaskNotFinishedNotification },
  { value: 'PODeadlineTodayNotification', label: notificationTypeLabels.PODeadlineTodayNotification },
  { value: 'DeadlineTomorrowNotification', label: notificationTypeLabels.DeadlineTomorrowNotification },
  { value: 'ApproveAllUpdatesDone', label: notificationTypeLabels.ApproveAllUpdatesDone },
  { value: 'CNReversionCompletedNotification', label: notificationTypeLabels.CNReversionCompletedNotification },
  { value: 'FileUploadedNotification', label: notificationTypeLabels.FileUploadedNotification },
  { value: 'NoteCreatedNotification', label: notificationTypeLabels.NoteCreatedNotification },
  { value: 'SendForPaymentFinishedNotification', label: notificationTypeLabels.SendForPaymentFinishedNotification },
  { value: 'StepCreatedNotification', label: notificationTypeLabels.StepCreatedNotification },
  { value: 'TaskCreatedNotification', label: notificationTypeLabels.TaskCreatedNotification },
  { value: 'TaskRevertedNotification', label: notificationTypeLabels.TaskRevertedNotification },
  { value: 'DocumentDeleteNotification', label: notificationTypeLabels.DocumentDeleteNotification },
  { value: 'CompanyCountryTermBulkCreationNotification', label: notificationTypeLabels.CompanyCountryTermBulkCreationNotification },
  { value: 'CompanyCountryTermBulkUpdateNotification', label: notificationTypeLabels.CompanyCountryTermBulkUpdateNotification },
  { value: 'PayrollInstanceLockUnlockNotification', label: notificationTypeLabels.PayrollInstanceLockUnlockNotification },
  { value: 'TermDeleteNotification', label: notificationTypeLabels.TermDeleteNotification },
]

const columnFilter = { type: 'TextFilter', delay: 1000, placeholder: 'Filter' }

class NotificationList extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showFilters: false }
  }
  trClassFormat ({ original }) {
    // row is the current row data
    // bold the whole row if notification is unread
    return !original.isRead ? 'u-weight--medium' : '' // return class name.
  }

  filterCountryName = (cell) => {
    return cell.name
  }

  filterStatus = (cell) => {
    return cell.createdAt
  }
  onPageChange = ({ pageSize, pageIndex, filters }) => {
    if (!this.props.isFetching) {
      let normalizedFilters = {}
      filters.forEach((filter) => {
        normalizedFilters[filter.id] = filter
      })
      this.props.onFilter({
        page: pageIndex,
        limit: pageSize,
        filters: normalizedFilters,
      })
    }
  }
  render () {
    const { isFetching, pagination, onFilter, tenants, countries } = this.props

    const options = {
      noDataMessage: 'No matching records found.',
      pageSize: pagination && pagination.limit,
      sizePerPageList: [
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
      ],
      pageIndex: pagination && pagination.currentPage,
      pageCount: pagination && pagination.totalPages,
      showPageSizeSelector: true,
      onPageChange: this.onPageChange,
      onFilterChange: (filters) => onFilter({ filters }),
    }

    return (
      <div className='u-relative'>
        <SectionHeading text='Alerts' fullWidth>
          <FilterButton
            className='c-btn c-btn--small c-btn--curious u-padding-left float-right u-padding-right'
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          />
        </SectionHeading>
        <PayslipTable
          data={this.props.notifications}
          headings={[
            {
              accessor: 'type',
              Header: 'Type',
              disableSortBy: true,
              setWidth: '120px',
              Cell: icon,
              Filter: SelectColumnFilter,
              dropDownOptions: {
                name: 'type',
                options: typesOptions,
                multi: true,
              },
            },
            {
              accessor: 'client',
              Header: 'Client',
              setWidth: '120px',
              disableSortBy: true,
              dropDownOptions: {
                name: 'client',
                options: tenants.map((tenant) => ({
                  value: tenant.schema,
                  label: tenant.name,
                  disabled: tenant.disabled,
                  className: classNames({
                    'option--as-group-title': tenant.disabled,
                  }),
                })),
                multi: true,
              },
              Filter: SelectColumnFilter,
            },
            {
              accessor: 'countryName',
              Header: 'Country',
              disableSortBy: true,
              columnClassName: 'u-overflow-visible',
              Cell: country,
              Filter: SelectColumnFilter,
              dropDownOptions: {
                name: 'countryName',
                options: countries.map((c) => ({
                  value: c.id,
                  label: c.name,
                })),
                multi: true,
              },
            },
            {
              accessor: 'company',
              Header: 'Company',
              filterOptions: columnFilter,
              disableSortBy: true,
            },
            {
              accessor: 'payrollName',
              Header: 'Payroll Name',
              filterOptions: columnFilter,
              disableSortBy: true,
            },
            {
              accessor: 'period',
              Header: 'Pay Period',
              setWidth: '100px',
              filterOptions: columnFilter,
              disableSortBy: true,
            },
            {
              accessor: 'body',
              Header: 'Notification',
              filterOptions: columnFilter,
              columnClassName: 'u-word-break-word',
              disableSortBy: true,
            },
            {
              accessor: 'status',
              Header: 'Status',
              setWidth: '170px',
              Cell: status,
              filterOptions: columnFilter,
              disableSortBy: true,
            },
          ]}
          trBodyClassName={this.trClassFormat}
          options={options}
          modifierClasses='react-bs-container-body'
          tableElementClassName='table--layout-auto'
          wrapperClassName={classNames({
            'u-1/1 react-bs-table--overflow-auto': true,
            'u-half-opacity': isFetching,
          })}
          pagination
          remote
          loading={isFetching}
          showFilters={this.state.showFilters}
          totalCount={pagination && pagination.totalCount}
        />
      </div>
    )
  }
}
NotificationList.propTypes = {
  isFetching: PropTypes.bool,
  notifications: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      schema: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
}
export default NotificationHOC(NotificationList)
