import React from 'react'
import ReportTypeAvatar from 'routes/Reporting/routes/Report/components/ReportTypeAvatar'
import PropTypes from 'prop-types'

const Report = ({ icon, title, description, onDownloadClick, isHidden }) => {
  if (isHidden) return null
  return (
    <div className='border-1-valhalla rounded-md shadow-md u-padding-left-small u-padding-right-small u-margin-top d-flex ai-center'>
      <ReportTypeAvatar classList='rounded-full u-margin-right-small' icon={`${icon}-avatar-logo`} height='63px' width='63px' />
      <div className='d-flex u-flex-grow--1 jc--space-between ai-center'>
        <div>
          <h2 className='u-text--huge u-text--curious u-weight--bold u-margin-bottom-tiny'>{title}</h2>
          <div>{description}</div>
        </div>
        <button className='c-btn c-btn--curious u-margin' onClick={onDownloadClick} data-testid={`download-${title}`}>
          <span className='icon icon--download' />
        </button>
      </div>
    </div>
  )
}

Report.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  onDownloadClick: PropTypes.func,
  title: PropTypes.string,
  isHidden: PropTypes.bool,
}

export default Report
