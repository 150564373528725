const cleanObject = (obj) => {
  const copiedObject = { ...obj, isNew: true }
  copiedObject['id'] = (Math.floor(Math.random() * 100)) * ((Math.floor(Math.random() * 100)) * 7777)
  copiedObject['validFrom'] = null
  copiedObject['validTo'] = null
  copiedObject['amount'] = null
  copiedObject['addServerId'] = obj.id
  return copiedObject
}

export const getPayTaxesFieldsForAdd = (employeeDeductions, employeeNetDeductions, employerDeductions, fixedTerms) => {
  let newEmployeeDeductions = [...employeeDeductions]
  let newEmployeeNetDeductions = [...employeeNetDeductions]
  let newEmployerDeductions = [...employerDeductions]
  let newFixedTerms = [...fixedTerms]

  newEmployeeDeductions = newEmployeeDeductions.map(obj => cleanObject(obj))
  newEmployeeNetDeductions = newEmployeeNetDeductions.map(obj => cleanObject(obj))
  newEmployerDeductions = newEmployerDeductions.map(obj => cleanObject(obj))
  newFixedTerms = newFixedTerms.map(obj => cleanObject(obj))

  return {
    employeeDeductions: newEmployeeDeductions,
    employeeNetDeductions: newEmployeeNetDeductions,
    employerDeductions: newEmployerDeductions,
    fixedTerms: newFixedTerms
  }
}
/**
 * Method to format the Fixed term name; It's based on a bit of logic
 * There's a method on the model, which does one check if it should use only the global name
 * If that is true it also has to check if the global name is the same as the local name
 * With an or condition it additionally checks if the local name has no value
 *
 * @param {object} term Term to format for display
 *
 * @return string Returns global name if conditions are true, or returns the name in the following format
 * globalName / localName || term.name
 */
export const formatFixedTermName = (term) => {
  const localNameIsEmpty = !term.localName || term.localName === null || term.localName === ''
  const areGlobalNameAndLocalTheSame = term.globalName === term.localName

  if (areGlobalNameAndLocalTheSame || localNameIsEmpty) {
    return term.globalName
  }

  return `${term.globalName} / ${term.localName || term.name}`
}

/**
 * Method to determine if a given record is a historical record and can be ignored
 * For the purpose of displaying it to the end user
 *
 * @param {{ validTo, validFrom}} i The object containing the dates to
 * @param {*} piFromDate The Payroll Instance from date
 * @returns boolean | true | false
 */
export const findIfRecordIsHistorical = (i, piFromDate) => {
  if (!i.validTo) return false
  return ((new Date(i.validTo) < piFromDate) && (new Date(i.validFrom) < piFromDate))
}

/**
 * Method to determine if a given record is the next active record.
 * For the Pay & Taxes Page we want to show the next active record, by discounting all the historical records
 * @param {{ validTo, validRom}} i The object containing the dates to
 * @param {*} piFromDate The Payroll Instance from date
 * @param {*} matchingId
 * @returns boolean | true | false
 */
export const findMostNextActiveRecord = (i, piFromDate, matchingId) => {
  if (!i.validTo && ((matchingId === i.id) || matchingId === i.countryTermId)) return true
  return !((new Date(i.validTo) < piFromDate) && (new Date(i.validFrom) < piFromDate)) && ((matchingId === i.id) || matchingId === i.countryTermId)
}
