import React from 'react'
import SectionHeading from 'components/SectionHeading'

import ChangePhoneNumberStepOneContainer from '../containers/ChangePhoneNumberStepOneContainer'
import ChangePhoneNumberStepTwoContainer from '../containers/ChangePhoneNumberStepTwoContainer'

class ChangePhoneNumberView extends React.Component {
  render () {
    return <div>
      <SectionHeading text='Change phone number' />
      <ChangePhoneNumberStepOneContainer {...this.props} />
      <ChangePhoneNumberStepTwoContainer {...this.props} />
    </div>
  }
}

export default ChangePhoneNumberView
