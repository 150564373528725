import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import PayslipTable from 'components/table/PayslipTable'

class ClientsList extends React.Component {
  constructor (props) {
    super(props)
    this.formatNameColumn = this.formatNameColumn.bind(this)
  }

  renderData (clients, referrals, onEdit, onCreate) {
    return clients.map(client => {
      // Filter referrals array, so we can extract the appropriate referral name, according to the parentCompanyId
      const referral = referrals.length > 0 ? referrals.find(referral => referral.parentCompanies.includes(client.id)) : null

      return {
        id: client.id,
        name: client.name,
        url: client.url,
        shortUrl: client.shortUrl,
        referral: referral ? referral.name : '',

        // In case we need action column for create/edit referral, we have some of the code already

        // action: client.referral ?
        //   <button
        //     onClick={() => onEdit(client.id)}
        //     className='c-btn c-btn--tiny c-btn--curious'>
        //     <span className='icon icon--edit' />
        //   </button> :
        //    <button
        //    onClick={() => onCreate(client.id)}
        //    className='c-btn c-btn--tiny c-btn--curious'>
        //    <span className='icon icon--plus' />
        //  </button>
      }
    })
  }

  formatNameColumn ({ row: { values: { id, name, url } } }) {
    const { selectTenantSubmit } = this.props
    return <div title={name} onClick={() => selectTenantSubmit(url, 'company', id)}>{name}</div>
  }

  render () {
    const { clients, referrals, onEdit, onCreate } = this.props

    const options = {
      searchPosition: 'left',
      noDataText: 'No matching records found.',
    }
    return <div>
      <SectionHeading text='Clients list' />
      <PayslipTable
        data={this.renderData(clients, referrals, onEdit, onCreate)}
        headings={[
          { accessor: 'id', Header: 'ID', isKey: true, isVisible: false },
          { accessor: 'name',
            Header: 'Name',
            disableFilters: true,
            columnClassName: 'u-text--curious u-cursor--pointer',
            Cell: this.formatNameColumn },
          { accessor: 'url', Header: 'Url', isVisible: false, disableFilters: true },
          { accessor: 'shortUrl', Header: () => (<span>Url</span>), disableFilters: true },
          { accessor: 'referral', Header: 'Referral', disableFilters: true },

          // In case we need action column
          // { name: 'action', text: 'Action' }
        ]}
        wrapperClassName='u-1/1@tablet u-1/2@desktop'
        trClassName='react-bs-table__cell--overflow-ellipsis'
        options={options}
        search
      />
    </div>
  }
}

ClientsList.propTypes = {
  clients: PropTypes.array,
  selectTenantSubmit: PropTypes.func
}

export default ClientsList
