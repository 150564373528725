import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchVendorsIfNeeded } from 'redux/actions/vendors'
import PropTypes from 'prop-types'
import { fetchVendorCountryServicePivotIfNeeded } from 'redux/actions/vendorCountryServicePivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { reduxForm } from 'redux-form'
import { updateCompanyServicePivot } from 'redux/actions/companyServicePivot'
import { getVendorsWithCountryServices } from '../selectors/vendors'
import AddVendorToServiceForm from '../components/AddVendorToServiceForm'

class AddVendorToServiceContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchVendorsIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
  }

  render () {
    return <AddVendorToServiceForm {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    fetchVendorsIfNeeded: () => dispatch(fetchVendorsIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    dispatch
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { vendors, vendorCountryServicePivot, countryServicePivot } = state
  const entities = [vendors, vendorCountryServicePivot, countryServicePivot]

  if (isFetching(entities)) return { isFetching: true, runShowModal: false }

  // get the vendors
  let vendorData = getVendorsWithCountryServices(state, {
    countryServiceId: props.countryServiceId
  })

  return {
    runShowModal: true,
    vendors: vendorData,
    onSuccess: props.onSuccess,
    initialValues: {
      companyCountryServiceId: props.companyCountryServiceId
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { onSuccess } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: data => dispatch(updateCompanyServicePivot(data, data['companyCountryServiceId']))
      .then(() => onSuccess())
  }
}

AddVendorToServiceContainer.propTypes = {
  countryServiceId: PropTypes.number.isRequired,
  companyCountryServiceId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'addVendorToService',
})(AddVendorToServiceContainer))
