import { types } from 'redux/config/documents'

const getDocCategory = (docTypeClass) => {
  const type = Object.values(types).find((type) => type.value === docTypeClass)
  return type ? type.label : undefined
}

export const documentsMapper = ({ documents, showCategoryPayrollName }) =>
  documents?.map((doc) => ({
    ...doc,
    isOwner: doc.extraData.isOwner,
    type: doc.documentTypes,
    ...(showCategoryPayrollName
      ? {
        category: getDocCategory(doc.typeClass),
        payrollName: getDocCategory(doc.typeClass) === 'Payroll' ? doc.extraData.typeObject.name : 'None',
      }
      : {}),
  }))
