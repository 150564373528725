import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/generalLedgers'

class GeneralLedgers extends CRUDModel {}
GeneralLedgers.modelName = 'GeneralLedgers'
GeneralLedgers.actionTypes = actionTypes

GeneralLedgers.fields = {
  id: attr(),
}

export default GeneralLedgers
