import { cotSubdomain } from './config'
import { keycloakRealms } from './enums/keycloakRealms'
import { tenantTypes } from './enums/tenantTypes'

export const getTenantRealm = (tenantType) => {
  const tenants = [cotSubdomain, tenantTypes.COT]

  if (tenants.includes(tenantType)) return keycloakRealms.PAYSLIP_COT
  if (tenantType === tenantTypes.VENDOR) return keycloakRealms.PAYSLIP_ICP
  if (tenantType === tenantTypes.TENANT) return keycloakRealms.PAYSLIP_APPS
  throw new Error('Must provide a valid tenant type')
}
