import CoreLayout from 'layouts/PageLayout/PageLayout'
import loadable from '@loadable/component'
const RouteViewRouteLoadable = loadable(() => import('containers/PayAndTaxesElementsEditContainer'))

export default (store) => ({
  path: ':payrollInstanceId/remove-variable-elements',
  authProtected: true,
  exact: true,
  renderOutsideParent: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: RouteViewRouteLoadable },
})
