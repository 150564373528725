import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollStep'
import { numberToLetter } from 'utils/strings'

// Step position is counted from 0.
// `numberToLetter` starts counting from 1, so we just add `1` here
export const getAlphabeticName = position => numberToLetter(position + 1).toUpperCase()

class PayrollStep extends CRUDModel {
  getAlphabeticName () {
    return getAlphabeticName(this.position)
  }
}
PayrollStep.modelName = 'PayrollStep'
PayrollStep.actionTypes = actionTypes

PayrollStep.fields = {
  id: attr(),
  payrollProcess: fk('PayrollProcess', 'payrollSteps'),
  companyStep: fk('CompanyCountryStep', 'payrollSteps')
}

export default PayrollStep
