import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { HeaderIcon, HEADER_ICONS_TYPES } from 'components/header/HeaderIcons'
import { KeycloakCTX } from 'components/Keycloak/KeycloakProvider'

const NavBarProfileItem = (props) => {
  const keycloak = useContext(KeycloakCTX)

  const {
    item: { name },
  } = props

  const handleLogout = (clicked, props) => {
    clicked === 'logout' && props.logout(keycloak)
  }

  const renderLink = (props) => {
    const {
      item: { title, name, path },
    } = props
    const inner = (
      <>
        <span className={`o-block u-float--left u-padding-right-small`}>
          <HeaderIcon type={HEADER_ICONS_TYPES[name]} />
        </span>

        <span className='nav-label u-text--small'>{title}</span>
      </>
    )

    // Make sure to not change the current page, while doing logout
    // because some components can be mounted again and a fetch request to be triggered,
    // once the token is already deleted / invalidated
    if (name === 'logout') {
      return <a className='o-block o-block--left u-padding-none'>{inner}</a>
    }

    return (
      <NavLink to={path} className='o-block o-block--left u-padding-none'>
        {inner}
      </NavLink>
    )
  }

  return (
    <li
      className='o-list-bare'
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => handleLogout(name, props)}
    >
      {renderLink(props)}
    </li>
  )
}

NavBarProfileItem.propTypes = {
  item: PropTypes.object,
  logout: PropTypes.func,
}

export default NavBarProfileItem
