import React from 'react'
import { isFetching } from 'utils/redux/fetching'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import { fetchCountryProcessPivot } from 'redux/actions/countryProcessPivot'
import { fetchAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { getAccessibleCompaniesWithCountry, getCompanyByCompanyProcess } from 'redux/selectors/company'
import { getPayrolls, getPayrollById, getPayrollsByCompanies } from 'redux/selectors/payroll'
import { getFilteredDecoratedEmployees, getFilteredDecoratedEmployeesByCompany } from 'redux/selectors/employees'
import { isCot } from 'redux/selectors/auth'

import { getCompanyProcesses, getCompanyProcessesByCompany } from 'redux/selectors/companyProcesses'
import { createFilter } from '../utils/redux/filter'

/**
 * HOC component, that allows `WrappedComponent` rendering,
 * whit common filter logic and its own specific
 *
 * @param {component} - container with specific logic
 * @param {Object} props
 * @param {bool} props.isAsync - Should the component be rendered, while the data is being fetched.
 * If `true`, the component will be rendered and `isFetching` prop will be passed down.
 * If `false`, the component will be rendered only if the data is fetched.
 * @param {bool} props.initialValues - We can pass what props to be included in the `initialValues` props.
 *
 * @example - Usage:
 *
 * ```
 *   import Filter from 'containers/EventsAssignmentFilterDataHOC'
 *
 *   // It will render `CalendarFiltersContainer`,
 *   // wrapped by country, payroll, owner filters
 *   // and its own specifics
 *   const CalendarFilter = Filter(CalendarFiltersContainer)
 *   <CalendarFilter />
 * ```
 *
 * @link - Credits to https://medium.com/@ricardo_42589/this-is-awesome-470fe9bb6f56
 *
 * @returns {function}
 */
export default (WrappedComponent, { isAsync = true, initialValues = {} }) => {
  class Filter extends React.Component {
    componentDidMount () {
      const { dispatch } = this.props

      dispatch(fetchCountryProcessPivot())
      dispatch(fetchAccessAreaPivot())
    }

    render () {
      if (!isAsync && this.props.isFetching) return <div>Loading...</div>

      return <WrappedComponent {...this.props} />
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      setCompany: (selected) => {
        const parsed = JSON.parse(selected)
        return dispatch((dispatch, getState) => {
          const payrollProcessId = parsed ? parsed.id : null

          // If the user clears the value, then we have to remove
          if (!payrollProcessId) {
            dispatch(change('filterTasks', 'company', null))
            dispatch(change('filterTasks', 'payroll', null))
            dispatch(change('filterTasks', 'companyProcess', null))

            return false
          }

          const isPayroll = parsed.isPayroll
          const isProcess = parsed.isProcess

          if (isPayroll) {
            dispatch(change('filterTasks', 'payroll', payrollProcessId))
            dispatch(change('filterTasks', 'companyProcess', null))
            dispatch((dispatch, getState) => {
              // For the `clearable` cases.
              // If the user clears the Payroll value, we should reset the company.
              if (!payrollProcessId) return dispatch(change('filterTasks', 'company', null))

              const state = getState()

              const companyId = getPayrollById(state, { payrollId: payrollProcessId }).company.id
              dispatch(change('filterTasks', 'company', companyId))
            })
          }

          if (isProcess) {
            const company = getCompanyByCompanyProcess(getState(), { companyProcessId: payrollProcessId })

            dispatch(change('filterTasks', 'companyProcess', payrollProcessId))
            dispatch(change('filterTasks', 'company', company.id))

            dispatch(change('filterTasks', 'payroll', null))
          }
        })
      },
      resetFields: (fields) => fields.forEach((field) => dispatch(change('filterTasks', field, null))),
      // If we have selected value for the payroll, then we set the company's value automatically
      // setCompany: payrollId => dispatch( (dispatch, getState ) => {
      //   // For the `clearable` cases.
      //   // If the user clears the Payroll value, we should reset the company.
      //   if (!payrollId) return dispatch(change('filterTasks', 'company', null))
      //
      //   const state = getState()
      //
      //   const companyId = getPayrollById(state, { payrollId }).company.id
      //   dispatch(change('filterTasks', 'company', companyId))
      // })
    }
  }

  const mergePayrollsAndProcesses = (payrolls, processes) => {
    return [
      ...payrolls.map((payroll) => ({
        value: JSON.stringify({
          id: payroll.id,
          isPayroll: true,
        }),
        label: payroll.name,
      })),
      ...processes.map((process) => ({
        value: JSON.stringify({
          id: process.id,
          isProcess: true,
        }),
        label: process.name,
      })),
    ]
  }

  const mapStateToProps = (state, { filters }) => {
    const { accessAreaPivot, countryProcessPivot } = state

    const entities = [accessAreaPivot, countryProcessPivot]

    if (isFetching(entities)) return { isFetching: true }

    const isLoggedUserCot = isCot(state)

    // Get calendar filter form values
    const selector = formValueSelector('filterTasks')
    const values = selector(state, 'company', 'payroll', 'owner')

    const selectedPayroll = values.company && values.payroll

    const companyProcesses = values.company ? getCompanyProcessesByCompany(state, { companyId: values.company }) : getCompanyProcesses(state)
    const payrolls = values.company ? getPayrollsByCompanies(state, { companiesIds: [values.company] }) : getPayrolls(state)

    return {
      companies: getAccessibleCompaniesWithCountry(state),
      payrolls: payrolls,
      payrollsProcesses: mergePayrollsAndProcesses(payrolls, companyProcesses),
      owners: values.company
        ? getFilteredDecoratedEmployeesByCompany(state, { filter: filters.employeeSystemUsers.name, companyId: values.company })
        : getFilteredDecoratedEmployees(state, { filter: filters.employeeSystemUsers.name }),
      initialValues: {
        owner: !isLoggedUserCot ? state.auth.userId : null,
        ...initialValues,
      },
      selectedPayroll,
    }
  }

  const Container = connect(mapStateToProps, mapDispatchToProps)(Filter)

  return Fetcher(Container, [
    'companyProcessPivot',
    'processes',
    'services',
    'countries',
    'payrolls',
    'companies',
    'companyServicePivot',
    {
      name: 'employeeSystemUsers',
      params: [
        {
          filter: createFilter({ isKeyPerson: 1 }),
        },
      ],
    },
  ])
}
