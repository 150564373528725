import React from 'react'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import PropTypes from 'prop-types'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { asideGlobalRoutes } from './routes'
import { asideTitles } from 'utils/locales/aside.en'

const Aside = (props) => {
  const { history, selectAside, asideMode } = props
  return (
    <aside className='o-aside'>
      <Logo />
      <TenantSelectorContainer history={history} />

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav
        items={[
          { title: asideTitles.home, name: 'home', path: '/home', isHome: true },
          { title: asideTitles.countries, name: 'payroll', path: '/countries' },
          { title: asideTitles.globalTerms, name: 'payroll', path: '/terms' },
          { title: asideTitles.globalTermsSubcategories, name: 'payroll', path: '/term-subcategories' },
          { title: asideTitles.clients, name: 'payroll', path: '/clients' },
          {
            title: asideTitles.globalServices,
            name: 'payroll',
            path: '/services',
            children: [
              { title: asideTitles.processes, name: 'processes', path: '/processes', sub: true },

              { title: asideTitles.steps, name: 'steps', path: '/steps', sub: true },

              { title: asideTitles.actions, name: 'actions', path: '/actions', sub: true },
            ],
          },
          { title: asideTitles.vendors, name: 'payroll', path: '/vendors' },

          { title: asideTitles.cotUsers, name: 'payroll', path: '/users' },

          { title: asideTitles.reports, name: 'payroll', path: '/reports' },

          { title: asideTitles.globalDataProtection, name: 'payroll', path: '/global-data-protection' },
          asideGlobalRoutes.resources,
          { title: asideTitles.alerts, name: 'payroll', path: '/alerts' },
        ]}
        {...props}
      />
    </aside>
  )
}

Aside.propTypes = {
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isFetching: PropTypes.bool,
  parentCompanies: PropTypes.array,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

export default Aside
