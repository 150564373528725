import React from 'react'
import PropTypes from 'prop-types'

const Box = ({ color }) => <span className='c-box c-box--tiny' style={{ backgroundColor: color }} />

Box.propTypes = {
  color: PropTypes.string.isRequired
}

export default Box
