import { getCompanyWithCountry } from 'redux/selectors/company'
import { getPayrollById } from 'redux/selectors/payroll'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'

/**
 * Set Modal Heading when uploading a document
 *
 * @param {Object} state
 * @param {Number} payrollInstanceId
 * @param {String} typeClass
 * @param {Number} companyId
 *
 * @returns string
 */

const getUploadString = (...rest) => `You are uploading a document in ${rest.join(', ')}.`

export const getModalHeading = (state, payrollInstanceId, typeClass, companyId, payrollId = null) => {
  switch (typeClass) {
  case 'payrollInstance':
    const instance = payrollInstanceId ? getPayrollInstanceRef(state, { payrollInstanceId }) : null
    return instance
      ? getUploadString(instance.companyName, instance.countryName, instance.payrollName)
      : null
  case 'company':
    const company = getCompanyWithCountry(state, { companyId: companyId })
    return company
      ? getUploadString(company.name, company.country.name)
      : null
  case 'payroll':
    const payroll = payrollId ? getPayrollById(state, { payrollId }) : null
    const companyCountry = payroll ? getCompanyWithCountry(state, { companyId: payroll.company.id }) : null
    return payroll
      ? getUploadString(companyCountry.name, companyCountry.country.name, payroll.name)
      : null
  default: return null
  }
}
