import { ActionUtility } from 'utils/redux'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const COT_USER_FETCH = 'COT_USER_FETCH'
export const COT_USER_RECEIVE = 'COT_USER_RECEIVE'
export const COT_USER_CREATE = 'COT_USER_CREATE'
export const COT_USER_UPDATE = 'COT_USER_UPDATE'
export const COT_USER_DELETE = 'COT_USER_DELETE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COT_USER_FETCH,
  receive: COT_USER_RECEIVE,
  create: COT_USER_CREATE,
  update: COT_USER_UPDATE,
  delete: COT_USER_DELETE
}
const actionUtility = new ActionUtility(actionTypes, 'cotUsers', 'cotusers', 'CotUser', { forceDefaultAPI: true })

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCotUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCotUsers = actionUtility.fetchEntities
export const createCotUser = actionUtility.createEntity
export const updateCotUser = actionUtility.updateEntity
export const deleteCotUser = actionUtility.deleteEntity
export const downloadReport = () => actionUtility.downloadEntity({ childUri: 'export' })

export const updateCotUserPhone = entity => {
  let data = _.omit(entity, ['id'])

  return dispatch => {
    return dispatch(actionUtility.updateEntity(data, entity.id))
  }
}
