import LoginWrapper from 'layouts/LoginWrapper'
import React from 'react'
import AuthCodeContainer from '../containers/AuthCodeContainer'

export const RouteView = props => {
  return <LoginWrapper>
    <AuthCodeContainer {...props} />
  </LoginWrapper>
}

export default RouteView
