import { resetAction } from 'redux/actions/persist'

// ------------------------------------
// Constants
// ------------------------------------
export const TENANT_SELECT = 'TENANT_SELECT'
export const DISABLE_INVALIDATOR = 'DISABLE_INVALIDATOR'

// ------------------------------------
// Actions
// ------------------------------------
export const selectTenant = ({ url, type, id }) => {
  const select = (dispatch, getStore) => {
    const { config, auth } = getStore()

    dispatch(resetAction({ auth, config }))

    dispatch({
      type: TENANT_SELECT,
      payload: { url, type, id }
    })
  }

  select.disableJWT = true

  return select
}

export const disableInvalidator = () => ({
  type: DISABLE_INVALIDATOR
})
