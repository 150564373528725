import React from 'react'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'

const TasksTable = ({ tasks }) => (
  <PayslipTable
    dynamicValues
    headings={[
      {
        accessor: 'alphabeticName',
        Header: 'Current Actions:',
        columnClassName: 'text-left',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell: { value, row: { original } } }) => {
          return <div title={value} className={original.isModified ? 'u-weight--bold' : ''}>{value}</div>
        }
      }
    ]}
    data={tasks}
  />
)

TasksTable.propTypes = {
  tasks: PropTypes.array.isRequired,
}

export default TasksTable
