import React from 'react'
import LoginContainer from '../containers/LoginContainer'
import { useLocation } from 'react-router-dom'

export const RouteView = (props) => {
  const location = useLocation()
  return <LoginContainer {...props} entryRoute={location?.state?.entryRoute} />
}

export default RouteView
