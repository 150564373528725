import { ActionUtility } from 'utils/redux'
import { normalizeSyncData } from 'redux/transformers/manyToManyTransformer'
import { fetchCompanyServicePivot } from 'redux/actions/companyServicePivot'
import { filterApprovedCompanyDataProtectionFields } from 'redux/actions/approvedCompanyDataProtectionFieldsPivot'
import { invalidateCompanyCountryTermPivot } from 'redux/actions/companyCountryTermPivot'
import { isEmpty } from 'utils/fnkit/typeChecks'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANIES_FETCH = 'COMPANIES_FETCH'
export const COMPANIES_RECEIVE = 'COMPANIES_RECEIVE'
export const COMPANIES_CREATE = 'COMPANIES_CREATE'
export const COMPANIES_UPDATE = 'COMPANIES_UPDATE'
export const COMPANIES_INVALIDATE = 'COMPANIES_INVALIDATE'
export const COMPANIES_COUNTRY_SERVICE_ATTACH = 'COMPANIES_COUNTRY_SERVICE_ATTACH'
export const COMPANY_DATA_PROTECTION_FIELDS_ATTACH = 'COMPANY_DATA_PROTECTION_FIELDS_ATTACH'
export const COMPANY_EXTRA_FIELDS = 'COMPANY_EXTRA_FIELDS'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANIES_FETCH,
  receive: COMPANIES_RECEIVE,
  create: COMPANIES_CREATE,
  update: COMPANIES_UPDATE,
  invalidate: COMPANIES_INVALIDATE,
  attachCountryServices: COMPANIES_COUNTRY_SERVICE_ATTACH,
  attachDataProtectionFields: COMPANY_DATA_PROTECTION_FIELDS_ATTACH,
}
const actionUtility = new ActionUtility(actionTypes, 'companies', 'companies', 'Company')

export const fetchCompaniesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanies = actionUtility.fetchEntities
export const updateCompany = actionUtility.updateEntity
export const createCompany = actionUtility.createEntity
export const invalidateCompanies = actionUtility.invalidate

const getCompanyExtraFields = () => ({
  type: COMPANY_EXTRA_FIELDS,
  payload: {},
})

export const retrieveCompanyCustomFields = (companyId) => {
  return (dispatch, getState, { api }) => {
    if (!companyId) {
      return Promise.resolve('No Company ID')
    }
    return dispatch(
      actionUtility.retrieveEntities({
        id: companyId,
        childUri: 'extrafieldsvalidationschema',
        actionFunc: getCompanyExtraFields,
      })
    ).then((resp) => {
      return resp
    })
  }
}

export const retrieveCompaniesCustomFields = (companyIds) => {
  return (dispatch, getState, { api }) => {
    if (isEmpty(companyIds)) {
      return Promise.resolve('No Company IDs')
    }
    const params = new URLSearchParams()
    companyIds.forEach((id) => {
      params.append('companyId[]', id)
    })
    return api.fetch(`companies/extrafieldsvalidationschema?${params.toString()}`).then((res) => res)
  }
}

// define attach country services action
const attachCountryServicesAction = (countryServiceIds, companyId) => ({
  type: actionTypes.attachCountryServices,
  payload: { companyId, ...countryServiceIds },
})

// attach CountryService entity to Company
export const attachCountryServices = (entity) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: normalizeSyncData(entity),
        id: entity.companyId,
        childUri: 'countryservices',
        actionFunc: attachCountryServicesAction,
      })
    ).then(() => {
      dispatch(fetchCompanyServicePivot())
    })
  }
}

const attachCompanyDataProtectionFieldsAction = (entity, id) => ({
  type: actionTypes.attachDataProtectionFields,
  payload: {
    id,
    data: entity.data,
  },
})
/**
 * Send an api request to attach data protection fields to company
 */
export const attachCompanyDataProtectionFields = (companyId, data) => {
  return (dispatch, getState, { api }) => {
    return dispatch(
      actionUtility.attachEntities({
        entity: normalizeSyncData(data, 'isApproved'),
        id: companyId,
        childUri: 'countrydataprotectionfields',
        actionFunc: attachCompanyDataProtectionFieldsAction,
      })
    ).then(() => {
      dispatch(filterApprovedCompanyDataProtectionFields({ company: companyId }))
    })
  }
}
/**
 * Delete country terms in bulk
 * @param {payload} payload data to be deleted
 * {
    "data": {
        "skipValidations": false,
        "countryTerms": {
            "12": "",
            "13": "",
            "14": "",
            "15": ""
        }
    }
  }
 * @param {*} companyId
 * @returns {
    "success": [],
    "failure": [
        12,
        11
    ],
    "errors": {
        "11": [
            "This element can not be deleted due to its inclusion in a previous payrun.",
            "This element can not be deleted due to its current use for employees."
        ],
        "12": [
            "This element can not be deleted due to its inclusion in a previous payrun.",
            "This element can not be deleted due to its current use for employees."
        ]
    }
}
 */
export const deleteCountryTermsInBulk = (payload, companyId) => {
  const url = `companies/${companyId}/countryterms`

  return (dispatch, getState, { api }) => {
    return api.delete(url, { payload }).then((response) => {
      dispatch(invalidateCompanyCountryTermPivot())
      return response
    })
  }
}
