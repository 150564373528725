import React from 'react'
import BaseField from './BaseField'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

const AmountInputField = props => {
  const { input, type, disabled, className = 'c-input', placeholder, suffix } = props

  return <BaseField {...props}>
    <NumberFormat
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      type={type}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      suffix={suffix}
      {...input}
      data-testid={props['data-testid']}
    />
  </BaseField>
}

AmountInputField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  suffix: PropTypes.string
}

export default AmountInputField
