import React from 'react'
import { connect } from 'react-redux'
import DownloadTemplateView from '../components/DownloadTemplateView'
import { showMessage } from 'redux/actions/modal'
import { errorToString } from 'utils/apiErrors'

class DownloadTemplateContainer extends React.Component {
  render () {
    return <DownloadTemplateView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch,
  { downloadTemplateAction, payrollId, companyId, shouldUseCompanyAsFilter }) => {
  return {
    downloadTemplate: () => dispatch(downloadTemplateAction({ payrollId, companyId, shouldUseCompanyAsFilter }))
      .catch(error => dispatch(showMessage({
        body: errorToString(error, false)
      })))
  }
}

export default connect(null, mapDispatchToProps)(DownloadTemplateContainer)
