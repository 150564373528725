import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeAttendance'

class EmployeeAttendance extends CRUDModel {}
EmployeeAttendance.modelName = 'EmployeeAttendance'
EmployeeAttendance.actionTypes = actionTypes

EmployeeAttendance.fields = {
  id: attr(),
  employee: fk('EmployeeSystemUser', 'employeeAttendances'),
  companyCountryTerm: fk('CompanyCountryTerm', 'employeeAttendances'),
}

export default EmployeeAttendance
