import React from 'react'
import PropTypes from 'prop-types'

export default function EmptyListNotice ({ text, className = '' }) {
  return (
    <ul data-testid='dashboard-empty-list' className='o-list--striped list-unstyled text-center u-margin-none'>
      <li className={`u-margin u-text--normal u-text--valhalla ${className}`}>{text}</li>
    </ul>
  )
}

EmptyListNotice.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}
