import React from 'react'
import Form from 'components/form/Form'
import { createEmployeeFields, getFieldsBySection, renderFields } from 'utils/employee'
import { employeeFieldsConfigAlt } from 'redux/config/employeeFieldsConfigAlt'

export const EmergencyPersonFields = (props) => {
  const { form, employee } = props
  const isCreateForm = (form === 'employeeCreate' || form === 'EmergencyPersonCreate')

  const createEmergencyPersonFields = () => createEmployeeFields({
    fields: employeeFieldsConfigAlt({ isCreate: true }),
    section: 'personal',
    subSection: 'emergencyPersonDetails',
  }).map(field => renderFields(field))

  const getEmergencyPersonFields = () => getFieldsBySection({
    fields: employeeFieldsConfigAlt({ isEdit: true }),
    employee,
    section: 'personal',
    subSection: 'emergencyPersonDetails',
  }).map(field => renderFields(field))

  return <Form {...props}>
    <div className='o-layout o-grid--strech'>
      {isCreateForm
        ? createEmergencyPersonFields()
        : getEmergencyPersonFields()
      }
    </div>
  </Form>
}

export default EmergencyPersonFields
