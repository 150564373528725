import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyHolidayPivot'

class CompanyHoliday extends CRUDModel {}
CompanyHoliday.modelName = 'CompanyHoliday'
CompanyHoliday.actionTypes = actionTypes

CompanyHoliday.fields = {
  id: attr(),
  company: fk('Company', 'companyHolidays'),
  holiday: fk('Holiday', 'companyHolidays')
}

export default CompanyHoliday
