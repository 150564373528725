import React from 'react'
import { connect } from 'react-redux'
import { isCot } from 'redux/selectors/auth'
import { change } from 'redux-form'
import InnerWrapper from 'layouts/InnerWrapper'
import Filter from 'containers/EventsAssignmentFilterDataHOC'
import CalendarContainer from '../containers/CalendarContainer'
import CalendarFiltersContainer from '../containers/CalendarFiltersContainer'
import moment from 'moment'

const RouteView = props => {
  let initialValues = {
    // We're using `react-big-calendar` for showing the events.
    // It's default view is `month`.
    // Because the events are shown in a grid 5x7 boxes, then it's possible in the viewport to be shown
    // events from prev and next months.
    // Because of this we get a week before and a week after, according to the current month.
    // TODO - reuse the login here and `calculateRange` function.
    fromDate: moment(moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DD')).toDate(),
    toDate: moment(moment().endOf('month').add(7, 'days').format('YYYY-MM-DD')).toDate()
  }
  if (props.owner) {
    initialValues.owner = props.owner
    props.dispatch(change('filterTasks', 'owner', props.owner))
  }
  const CalendarFilter = Filter(CalendarFiltersContainer, { isAsync: false, initialValues })

  return <InnerWrapper>
    <div className='u-relative'>
      <CalendarFilter shouldFilterInitially shouldMergePayrollsAndProcesses shouldNotShowStatus />
      <CalendarContainer {...props} />
    </div>
  </InnerWrapper>
}

const mapDispatchToProps = (dispatch, state) => {
  return {
    dispatch
  }
}
function mapStateToProps (state) {
  return { owner: !isCot(state) ? state.auth.userId : null, }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteView)
