import { applyMiddleware, compose, createStore as createReduxStore } from 'redux'
import jwt from '../redux/middlewares/jwt'

import enhancedThunk from '../redux/middlewares/enhancedThunk'
import { createBrowserHistory } from 'history'

import { persistStore } from 'redux-persist'
import reduxReset from 'redux-reset'
import makeRootReducer from './reducers'
import { updateLocation } from './location'
import createRavenMiddleware from 'raven-for-redux'
import Raven from 'redux/middlewares/raven'
import _ from 'lodash'
const browserHistory = createBrowserHistory()

const createStore = (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================

  // It's important jwt middleware to be placed before thunk middleware!
  //  @inheritDoc redux/middlewares/jwt.js
  const middleware = [jwt, enhancedThunk]

  if (__PROD__) {
    // Enable Sentry reporting tool for Redux errors

    middleware.push(
      createRavenMiddleware(Raven.install(), {
        actionTransformer: (action) => {
          // Remove `data` payload from Sentry request, in order to keep it short
          if (action.payload && action.payload.data) {
            return {
              ...action,
              payload: _.omit(action.payload, 'data'),
            }
          }

          return action
        },
        // Send the most important Store data, because there's a Sentry payload limit of 100kb
        stateTransformer: (state) => {
          // The Stores' keys to be send to Sentry.
          // Here we list the most significant keys on one hand and on the other the static ones.
          // The static are these keys, these are small in size and most of the time act as a config.
          // If we list a dynamic key (let's say payrollInstanceEmployeeTerms),
          // then only this key can exceed the Sentry limit of 100kb.
          const whitelist = ['auth', 'config', 'tenants', 'vendorTenant']

          // Keep in mind Sentry.io has a bug and it trims the extra state payload for no reason.
          // This currently happens on Sentry.io (Sentry as a Service).
          // On Staging / Production, where we self-hosted it - everything's fine.
          // The bug is reported here:
          // @url: https://forum.sentry.io/t/additional-data-is-getting-agressively-truncated-trimmed/4940
          return Object.keys(state)
            .filter((key) => whitelist.includes(key))
            .reduce((store, key) => {
              store[key] = state[key]

              return store
            }, {})
        },
      })
    )
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  let composeEnhancers = compose

  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 10 })
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(
      reduxReset(), // Will use 'RESET' as default action.type to trigger reset
      applyMiddleware(...middleware),
      ...enhancers
    )
  )
  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  let persistor = persistStore(store)

  return { store, persistor }
}

export default createStore
