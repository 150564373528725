import React, { createContext, useEffect, useState } from 'react'

export const PayrollSummaryContext = createContext()

export const summaryViewToggleIds = {
  overview: 'overview',
  totalsTable: 'totalsTable',
  additionalItems: 'additionalItems',
  commentary: 'commentary',
}

export const PayrollSummaryProvider = ({ children }) => {
  const [viewToggleState, setViewToggleState] = useState({
    [summaryViewToggleIds.overview]: false,
    [summaryViewToggleIds.totalsTable]: false,
    [summaryViewToggleIds.additionalItems]: false,
    [summaryViewToggleIds.commentary]: false,
  })
  const [inGlobalUpdateMode, setGlobalUpdateState] = useState(false)

  const setGlobalUpdateMode = (id, value) => {
    if (viewToggleState[id] !== value) {
      setViewToggleState({
        ...viewToggleState,
        [id]: value,
      })
    }
  }
  useEffect(() => {
    const hasSomeItemTrue = Object.values(viewToggleState)?.some((v) => v === true)
    if (hasSomeItemTrue !== inGlobalUpdateMode) {
      setGlobalUpdateState(hasSomeItemTrue)
    }
  }, [viewToggleState])

  const value = {
    inGlobalUpdateMode,
    setGlobalUpdateMode,
  }

  return <PayrollSummaryContext.Provider value={value}>{children}</PayrollSummaryContext.Provider>
}
