import React from 'react'

export default function PasswordPolicyMessage () {
  return (
    <div>
      <p className='u-text--normal'>
        <b>Note</b>: <i>To maintain account security,
          you will be required to change your password every 3 months
          and it should be different than any previously used password.</i>
      </p>
      <p className='u-text--normal'>To gain access to your account, please change your password.</p>
    </div>
  )
}
