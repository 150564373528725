import Fetcher from 'containers/Fetcher'
import PayrollUpdatesList from '../components/PayrollUpdatesList'
import { connect } from 'react-redux'
import { getPayrollInstancesByEmployee } from '../../../selectors/payrollInstanceEmployee'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'

const mapStateToProps = (state, props) => ({
  payrollInstances: getPayrollInstancesByEmployee(state, { employeeId: props.match.params.employeeId })
})

const Container = connect(mapStateToProps)(PayrollUpdatesList)

export default Fetcher(Container, [
  'payrolls',
  createEmployeeFilter({ name: 'payrollInstances' }),
  createEmployeeFilter({ name: 'payrollInstanceEmployeePivot' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
