import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_STEPS_FETCH = 'COMPANY_STEPS_FETCH'
export const COMPANY_STEPS_RECEIVE = 'COMPANY_STEPS_RECEIVE'
export const COMPANY_STEPS_INVALIDATE = 'COMPANY_STEPS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_STEPS_FETCH,
  receive: COMPANY_STEPS_RECEIVE,
  invalidate: COMPANY_STEPS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'companyStepPivot', 'companysteps', 'CompanyCountryStep')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyStepPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyStepPivot = actionUtility.fetchEntities
export const invalidateCompanyStepPivot = actionUtility.invalidate
