import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const TERMS_FETCH = 'TERMS_FETCH'
export const TERMS_RECEIVE = 'TERMS_RECEIVE'
export const TERM_CREATE = 'TERM_CREATE'
export const TERM_UPDATE = 'TERM_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TERMS_FETCH,
  receive: TERMS_RECEIVE,
  create: TERM_CREATE,
  update: TERM_UPDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'terms', 'terms')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTermsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchTerms = actionUtility.fetchEntities
export const createTerm = actionUtility.createEntity
export const requestTerm = actionUtility.requestEntity
export const updateTerm = (entity, termId) => {
  const url = `terms/${termId}`

  return (dispatch, getState, { api }) => {
    return api
      .patch(url, { payload: entity })
      .then((response) => {
        errorsHandling.handleFormErrorsWhereFormKeyAndErrorKeyMismatch(response, [['name', 'termSubcategory']])
      })
      .then((response) => {
        dispatch(fetchTerms())
        return response
      })
  }
}

/*
 * Create new term entity
 */
export const createTermEntity = (entity) => {
  return (dispatch, getState, { api }) => {
    return api
      .post(`terms/create`, { payload: entity })
      .then(errorsHandling.handleFormErrors)
      .then((response) => {
        return response
      })
  }
}
