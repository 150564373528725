import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/vendorUsers'
import { getName } from './user'

class VendorUser extends CRUDModel {
  getName () {
    return getName(this)
  }
}
VendorUser.modelName = 'VendorUser'

VendorUser.fields = {
  id: attr(),
  country: fk('Country', 'vendorUsers'),
  vendor: fk('Vendor', 'vendorUsers')
}

VendorUser.actionTypes = actionTypes

export default VendorUser
