import React from 'react'
import { NavLink } from 'react-router-dom'
class ErrorView extends React.Component {
  render () {
    return <div>
      <div className='o-layout o-grid o-grid--center'>
        <div className='o-layout__item u-text--center u-1/1 u-1/2@tablet u-1/3@desktop'>
          <div className='u-text--enormous u-margin-top-huge'>
            404
          </div>
          <div className='u-text--huge u-uppercase'>
            Page not found
          </div>
          <NavLink to='/' className='c-btn c-btn--small c-btn--curious u-margin-top-large'>
            Home
          </NavLink>
        </div>
      </div>
    </div>
  }
}

export default ErrorView
