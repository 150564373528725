import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/parentCompanies'

class ParentCompany extends CRUDModel {}
ParentCompany.modelName = 'ParentCompany'
ParentCompany.actionTypes = actionTypes

ParentCompany.fields = {
  id: attr(),
  name: attr()
}

export default ParentCompany
