import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'

export default (store) => ({
  path : 'gl-accounts',
  exact: true,
  isOrphanedChild: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: RouteView },
})
