import { parse } from 'date-fns'

/**
 * Parses a date string into a Date object or returns the Date object directly.
 * If the input is a string, it will be parsed using the specified format.
 * If the input is already a Date object, it will be returned as is.
 *
 * @param {string | Date} date - The date to parse or return. Can be a string in the specified format or a Date object.
 * @param {string} [format='MM-dd-yyyy'] - The format to use for parsing if the input is a string. Defaults to 'MM-dd-yyyy'.
 * @returns {Date} - The parsed or provided Date object.
 */
export const parseDate = (date, format = 'MM-dd-yyyy') => (typeof date === 'string' ? parse(date, format, new Date()) : date)
