import createSelectorORM from 'utils/createSelector'
import { createSelector } from 'reselect'

export const getCompanyId = (state, props) => parseInt(props.companyId)
export const getUserId = (state, props) => parseInt(props.userId)

// get all subsidiaries
export const getAllSubsidiaries = createSelectorORM(
  ({ Subsidiary }) => Subsidiary.all().toRefArray())

// get subsidiaries by company id
// eslint-disable-next-line max-len
export const getSubsidiariesByCompany = createSelector(getAllSubsidiaries, getCompanyId, (subsidiaries, companyId) => subsidiaries.filter(s => s.company === companyId))

export const getSubsidiariesWithCompanyWithCountry = createSelectorORM(
  ({ Subsidiary }) => {
    return Subsidiary.all()
      .toModelArray()
      .map(unit => ({
        ...unit.ref,
        company: { ...unit.company.ref },
        country: { ...unit.company.country.ref }
      }))
  })

// get all subsidiaries initial values
export const getAllSubsidiariesInitialValues = createSelector(getAllSubsidiaries, subsidiaries => (
  subsidiaries.reduce((initialValues, subsidiary) => {
    const id = `id-${subsidiary.id}`
    initialValues[id] = subsidiary.name

    return initialValues
  }, {})
))

// get company subsidiaries initial values
export const getCompanySubsidiariesInitialValues = createSelector(getSubsidiariesByCompany, subsidiaries => (
  subsidiaries.reduce((initialValues, subsidiary) => {
    const id = `id-${subsidiary.id}`
    initialValues[id] = subsidiary.name

    return initialValues
  }, {})
))
