import React from 'react'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { createFilter } from 'utils/redux/filter'
import { toastEntityTypesEnum } from 'utils/enums/toastEntityTypesEnum'
import { useSelector } from 'react-redux'
import { getPayrollInstanceRef } from 'redux/selectors/payrollInstance'
import { isCot, isVendorInvolved } from 'redux/selectors/auth'
import { toasts } from 'utils/locales/toasts.en'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'

const PayfileExportView = ({ job }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  return (
    <div className='u-text--small' data-testid='toast-loading-payrunfile-export'>
      {toasts.preparingPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.payrunFile.fileName}
    </div>
  )
}

PayfileExportView.propTypes = {
  job: PropTypes.object,
}

const FetchedPayfileExportView = Fetcher(PayfileExportView, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, loading: 'true' }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const PayfileImportView = ({ job }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  return (
    <div className='u-text--small' data-testid='toast-loading-payrunfile-export'>
      {toasts.preparingPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.payrunFile.fileName}
    </div>
  )
}

PayfileImportView.propTypes = {
  job: PropTypes.object,
}

const FetchedPayfileImportView = Fetcher(PayfileImportView, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const PayrunVarianceView = ({ job }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  return (
    <div className='u-text--small' data-testid='toast-loading-payrunfile-export'>
      {toasts.preparingPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.reconciliation.fileName}
    </div>
  )
}

PayfileExportView.propTypes = {
  job: PropTypes.object,
}
const FetchedPayrunVarianceView = Fetcher(PayrunVarianceView, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, loading: 'true' }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const ElementVarianceView = ({ job }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  return (
    <div className='u-text--small' data-testid='toast-loading-element-variance-export'>
      {toasts.preparingPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.elementReconciliation.fileName}
    </div>
  )
}

ElementVarianceView.propTypes = {
  job: PropTypes.object,
}
const FetchedElementVarianceView = Fetcher(ElementVarianceView, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, loading: 'true' }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const EmployeeVarianceView = ({ job }) => {
  const payrollInstance = useSelector((state) => getPayrollInstanceRef(state, { payrollInstanceId: job.entityId }))
  return (
    <div className='u-text--small' data-testid='toast-loading-element-variance-export'>
      {toasts.preparingPrefix} <strong>{payrollInstance.shortName}</strong> {toasts.elementReconciliation.alternateFileName}
    </div>
  )
}

ElementVarianceView.propTypes = {
  job: PropTypes.object,
}
const FetchedEmployeeVarianceView = Fetcher(EmployeeVarianceView, [
  {
    name: 'payrollInstances',
    isForceFetching: true,
    params: [
      {
        _computed: {
          filter: (state, { job }) => createFilter({ id: job.entityId, loading: 'true' }),
          forceVendorTenantAPI: (state, props) => state.vendorTenant.url,
        },
      },
    ],
  },
])

const ReportView = ({ job }) => {
  const builder = new ReportItemBuilder()
  const feCategoryId = job.filters.feCategoryId
  const reportName = builder.getReportNameCategory(feCategoryId)
  return (
    <div className='u-text--small' data-testid='toast-loading-element-variance-export'>
      {toasts.yourPrefix} <strong>{reportName}</strong> {toasts.reports.inProgress}
    </div>
  )
}

const CotReportView = () => (
  <div className='u-text--small' data-testid='toast-loading-report'>
    {toasts.reports.reportInProgress}
  </div>
)

ReportView.propTypes = {
  job: PropTypes.object,
}

const EmployeeBulkImportView = () => (
  <div className='u-text--small' data-testid='toast-loading-element-variance-export'>
    {toasts.employees.createImportInProgress}
  </div>
)

const EmployeeBulkUpdateImportView = () => (
  <div className='u-text--small' data-testid='toast-loading-element-variance-export'>
    {toasts.employees.updateImportInProgress}
  </div>
)

const ToastLoadingViews = ({ job }) => {
  let View = null
  const state = useSelector((state) => state)
  const isCotUser = useSelector((state) => isCot(state))
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const vendorTenant = useSelector((state) => state.vendorTenant)
  if (isVendorUser && !vendorTenant.url) {
    return View
  }
  if (isCotUser && window.location.pathname.indexOf('vendors') !== -1 && !vendorTenant.url) {
    return View
  }
  if (window.location.pathname.indexOf('pure') !== -1) {
    return View
  }
  if (!state.tenants.url && isCotUser && job.type === toastEntityTypesEnum.REPORT_CREATE_JOB) {
    View = <CotReportView />
  }
  if (state.tenants.url || !isCotUser) {
    switch (job.type) {
    case toastEntityTypesEnum.PAYFILE_EXPORT_JOB:
    case toastEntityTypesEnum.PAYFILE_CHANGES_EXPORT_JOB:
    case toastEntityTypesEnum.PAYFILE_EXPORT_ARCHIVE_JOB:
    case toastEntityTypesEnum.EMPTY_PAYFILE_EXPORT_JOB:
      View = <FetchedPayfileExportView job={job} />
      break
    case toastEntityTypesEnum.PAYFILE_IMPORT_JOB:
      View = <FetchedPayfileImportView job={job} />
      break
    case toastEntityTypesEnum.VARIANCE_EXPORT_JOB:
      View = <FetchedPayrunVarianceView job={job} />
      break
    case toastEntityTypesEnum.ELEMENT_VARIANCE_EXPORT_JOB:
      View = <FetchedElementVarianceView job={job} />
      break
    case toastEntityTypesEnum.EMPLOYEE_VARIANCE_EXPORT_JOB:
      View = <FetchedEmployeeVarianceView job={job} />
      break
    case toastEntityTypesEnum.REPORT_CREATE_JOB:
      View = <ReportView job={job} />
      break
    case toastEntityTypesEnum.EMPLOYEE_BULK_CREATE_JOB:
      View = <EmployeeBulkImportView job={job} />
      break
    case toastEntityTypesEnum.EMPLOYEE_BULK_UPDATE_JOB:
      View = <EmployeeBulkUpdateImportView job={job} />
      break
    default:
      break
    }
  }
  return View
}

export default ToastLoadingViews
