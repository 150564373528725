import React from 'react'
import PayrollInstanceListContainer from '../containers/PayrollInstanceListContainer'
import Modal from 'components/Modal'
import PayrollInstanceTaskListContainer from '../containers/PayrollInstanceTaskListContainer'
import DocumentsViewContainer from '../../Documents/containers/DocumentsViewContainer'
import { includes } from 'lodash'
import PropTypes from 'prop-types'
import PayrollInstanceJoinersContainer from 'routes/Payruns/containers/PayrollInstanceJoinersContainer'
import { createFilter } from 'utils/redux/filter'

class RouteView extends React.Component {
  constructor (props) {
    super(props)

    this.filesRef = React.createRef()
    this.actionsRef = React.createRef()
    this.joinersRef = React.createRef()
    this.joinersExportFilter = createFilter({ vendorPortal: props.vendorSchema })
    this.state = {
      payrollInstance: null,
      payrollInstanceId: null,
      showNotesModal: false,
      notesSubHeading: '',
      payrollInstanceTaskId: null,
      notificationSubHeading: ''
    }
  }

  componentDidMount () {
    if (includes(this.props.location.search, 'openNotesModal')) this.onNotesModalOpen(this.props.location.query.id)
  }

  renderPayrollInstanceNameWithDates (payrollInstance) {
    if (payrollInstance) {
      const company = payrollInstance.companyName
      const country = payrollInstance.countryName
      const payrollNameAndPeriod = payrollInstance.payrollNameAndPeriod
      return `${company} / ${country} / ${payrollNameAndPeriod}`
    }
    return null
  }

  shouldOpenNotesView = (newState, payrollInstanceTaskId) => {
    if (newState) {
      this.actionsRef.current.hideModal()
      this.setState({
        showNotesModal: newState,
        notesSubHeading:
          'The Action owner who made the reversion will be notified about this note.',
        payrollInstanceTaskId,
        notificationSubHeading:
          'A notification was sent to the action owner, do you want to notify someone else?'
      })
    }

    this.setState({
      showNotesModal: newState
    })
  };

  resetPayrollInstanceTask = () => {
    this.setState({
      notesSubHeading: '',
      payrollInstanceTaskId: null
    })
  };

  resetNotificationSubHeading = () => {
    this.setState({ notificationSubHeading: '' })
  };

  onNotesModalOpen = (payrollInstanceId) => {
    this.setState({
      payrollInstanceId,
      showNotesModal: true
    })
  }

  render () {
    const {
      payrollInstance,
      payrollInstanceId
    } = this.state

    return (
      <div>
        <Modal
          ref={this.filesRef}
          className='c-modal c-modal--full c-modal--min-height--full c-modal--overflow-y c-modal-with-top-background'
          modalHeading={this.renderPayrollInstanceNameWithDates(
            payrollInstance
          )}
        >
          <DocumentsViewContainer
            typeClass='payrollInstance'
            typeId={payrollInstanceId}
            payrollInstanceId={payrollInstanceId}
            instance={payrollInstance}
            title=''
            {...this.props}
          />
        </Modal>
        <Modal
          ref={this.actionsRef}
          className='c-modal c-modal--half'
          modalHeading={`${this.renderPayrollInstanceNameWithDates(payrollInstance)}`}
        >
          <PayrollInstanceTaskListContainer
            shouldOpenNotesView={this.shouldOpenNotesView}
            payrollInstanceId={payrollInstanceId}
          />
        </Modal>
        <Modal
          ref={this.joinersRef}
          className='c-modal c-modal-with-top-background'
          payrollInstanceId={payrollInstanceId}
          modalHeading={`${this.renderPayrollInstanceNameWithDates(payrollInstance)}`}
        >
          {payrollInstanceId && (
            <PayrollInstanceJoinersContainer
              payrollInstanceId={payrollInstanceId}
              shouldUseFilter={this.props.isCotUser}
              filter={this.joinersExportFilter}
              isVendorUser
            />
          )}
          <div className='u-text--center u-margin-top-large'>
            <button
              onClick={() => this.joinersRef.current.hideModal()}
              className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
              data-testid={`modal-ok`}
            >
              OK
            </button>
          </div>
        </Modal>

        <PayrollInstanceListContainer
          onFilesButtonClick={payrollInstance => {
            this.setState({
              payrollInstance,
              payrollInstanceId: payrollInstance.id
            })
            this.filesRef.current.showModal()
          }}
          onActionsButtonClick={payrollInstance => {
            this.setState(
              { payrollInstance, payrollInstanceId: payrollInstance.id },
              () => this.actionsRef.current.showModal()
            )
          }}
          onNotesButtonClick={payrollInstance => {
            this.setState({
              payrollInstance,
              payrollInstanceId: payrollInstance.id,
              showNotesModal: true
            })
          }}
          onJoinersButtonClick={payrollInstance => {
            this.setState({
              payrollInstance,
              payrollInstanceId: payrollInstance.id
            })
            this.joinersRef.current.showModal()
          }}
          payrollInstanceId={payrollInstanceId}
          {...this.props}
        />
      </div>
    )
  }
}

RouteView.propTypes = {
  location: PropTypes.object
}

export default RouteView
