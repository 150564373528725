import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import ServicesCreateContainer from '../containers/ServicesCreateContainer'
import ServicesListContainer from '../containers/ServicesListContainer'

export default props => {
  return <InnerWrapper>
    <ServicesCreateContainer {...props} />
    <ServicesListContainer {...props} />
  </InnerWrapper>
}
