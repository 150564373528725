import { get } from 'lodash'

/**
 * Builds an array of objects suitable for populating select dropdown options.
 * @param {Array} items - The array of items to build options from.
 * @param {Object} [options] - Options object.
 * @param {string} [options.labelPath='name'] - The path to the property to be used as the label for each option.
 * @param {string} [options.valuePath='id'] - The path to the property to be used as the value for each option.
 * @returns {Array} An array of objects with `label` and `value` properties, derived from the provided items.
 */
export const buildSelectOpt = (items = [], options) => {
  const labelPath = options?.labelPath || 'name'
  const valuePath = options?.valuePath || 'id'
  return items.map((item) => {
    const value = get(item, valuePath)
    return {
      label: get(item, labelPath),
      value: value || get(item, labelPath),
    }
  })
}

/**
 * Builds an array of objects based on the provided object and options.
 *
 * @param {Object} obj - The object to be transformed.
 * @param {Object} [options={}] - Options to customize the transformation.
 * @param {string} [options.labelPath='label'] - The key for the labels in the resulting objects.
 * @param {string} [options.valuePath='value'] - The key for the values in the resulting objects.
 * @returns {Array<Object>} The transformed array of objects.
 */
export const buildOptBasedOnObject = (obj = {}, options) => {
  const labelPath = options?.labelPath || 'label'
  const valuePath = options?.valuePath || 'value'
  return Object.keys(obj).map((k) => ({
    [labelPath]: obj[k],
    [valuePath]: k,
  }))
}

/**
 * Converts an array of primitive values into an array of objects with `label` and `value` properties.
 *
 * @param {Array} [items=[]] - An array of primitive values to be transformed.
 * @returns {Array<Object>} An array of objects, each containing `label` and `value` properties,
 * both set to the respective primitive value from the input array.
 */
export const buildPrimitiveValueToOpts = (items = []) => items.map((item) => ({ label: item, value: item }))
