import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import CompanyRoute from './routes/Company'

export default (store) => ({
  path : '/companies',
  exact: true,
  indexRoute: { component: RouteView },
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ CompanyRoute(store) ]
})
