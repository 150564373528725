import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/employeeUserAddresses'

class EmployeeUserAddresses extends CRUDModel {}
EmployeeUserAddresses.modelName = 'EmployeeUserAddresses'
EmployeeUserAddresses.actionTypes = actionTypes

EmployeeUserAddresses.fields = {
  id: attr(),
  employeeUser: fk('EmployeeSystemUser', 'addresses')
}

export default EmployeeUserAddresses
