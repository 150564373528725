/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector, reduxForm } from 'redux-form'
import Loader from 'components/Loader'
import EmployeesSearch from 'components/employees/EmployeesSearch'
import { isFetching } from 'utils/redux/fetching'
import { sortBy, isEmpty } from 'lodash'
import { fetchEmployeeSystemUsers, resetEmployeeSystemUsersFilters } from 'redux/actions/employeeSystemUsers'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { fetchProjectsIfNeeded } from 'redux/actions/projects'
import { fetchPayrollsIfNeeded } from 'redux/actions/payrolls'
import { fetchPaygroupsIfNeeded } from 'redux/actions/paygroups'
import { fetchAccessAreaPivot } from 'redux/actions/accessAreaPivot'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import {
  getCountriesAndCompaniesIdsByBusinessUnits,
  getCountriesAndCompaniesIdsByCompanies,
  getCountriesAndCompaniesIdsByCostCenters,
  getCountriesAndCompaniesIdsByPaygroups,
  getCountriesAndCompaniesIdsByPayrollInstances,
  getCountriesAndCompaniesIdsByPayrolls,
  getCountriesAndCompaniesIdsByProjects,
  getCountriesByAuth,
  getCountriesByCompanies,
} from 'redux/selectors/country'
import { getAccessibleCompanies, getAccessibleCompaniesByCountries } from 'redux/selectors/company'
import {
  getBusinessUnitsByAccessibleCompanies,
  getBusinessUnitsByCompanies,
  getBusinessUnitsByCountriesThroughAccessibleCompany,
} from 'redux/selectors/businessUnits'
import { getCostCenters, getCostCentersByCompanies, getCostCentersByCountriesThroughCompany } from 'redux/selectors/costCenters'
import { getProjects, getProjectsByCompanies, getProjectsByCountriesThroughCompany } from 'redux/selectors/projects'
import { getPayrollsByCompanies, getPayrollsByCountriesThroughCompany, getPayrolls } from 'redux/selectors/payroll'
import { getPaygroups, getPaygroupsByCompanies, getPaygroupsByCountriesThroughCompany } from 'redux/selectors/paygroup'
import { isCot } from 'redux/selectors/auth'
import { getCurrentPayrollInstances, getCurrentPayrollInstancesByCompanies, getCurrentPayrollInstancesByCountries } from 'redux/selectors/payrollInstance'
import { createFilter } from 'utils/redux/filter'
import { sortByName } from 'utils/strings'
import PropTypes from 'prop-types'
import { pushBreadcrumbFilter } from 'redux/actions/breadcrumbs'
import { getBreadcrumbFiltersByKey } from 'redux/selectors/breadcrumbs'
import { getPayrollInstanceWithPayroll } from 'routes/Payruns/selectors/payrollInstances'
import { buildFilterName } from 'redux/filters/commonFilters'
import { triggerAttachDettachDispatch, triggerPayrollInstancePivotDispatch } from 'containers/employee/utils/helpers'

// Pagination: How many items per page to be filtered and visible
export const limit = 24

/**
 * Search bar, that filters `EmployeeSystemUsers`
 *
 * The key moment here is that all the form's fields (filters) here are interconnected and always in SYNC.
 *
 * Here're some examples, in order to understand the feature in deep:
 *
 * Example 1 (changing filters from left to right Country->Company->BusinessUnit->...):
 * If we change the Country field's value, then all the child entities as Company, BusinessUnit, CostCenter and so on,
 * will be filtered by Country.
 *
 * Example 2 (changing filters from left to right Country->Company->BusinessUnit->...):
 * If we change the Company field's value, the same logic will be applied as the above example.
 *
 * Example 3 (changing filters from right to left Country<-Company<-BusinessUnit):
 * If we change the BusinessUnit firstly, then we have to set values for Company and Country.
 *
 * Keep in mind that all the fields are multi selects (dropdowns),
 * which increases the complexity of the feature dramatically.
 * Quick example. If you select many BusinessUnits, then you have to select automatically
 * all the Companies and Countries, according to the BusinessUnits selected values.
 *
 * Please review `selectCountryAndCompanyByEntityValue` and `getFormFields` functions,
 * in order to understand how we do the fields sync.
 */
class EmployeesSearchBarContainer extends React.Component {
  UNSAFE_componentWillMount () {
    const { payrollInstance } = this.props
    this.props.fetchCompaniesIfNeeded()
    this.props.fetchCountriesIfNeeded()
    this.props.fetchBusinessUnitsIfNeeded()
    this.props.fetchCostCentersIfNeeded()
    this.props.fetchProjectsIfNeeded()
    this.props.fetchPayrollsIfNeeded()
    this.props.fetchPaygroupsIfNeeded()
    this.props.fetchAccessAreaPivot()
    payrollInstance && this.props.fetchPayrollInstancesIfNeeded({ filter: createFilter({ id: payrollInstance, name: 'withPayrunId' }) })
  }

  componentWillUnmount () {
    // Reset the filters, because we don't want to apply them
    // to any other page that relies on these filter criteria.
    // Example:
    // 1. Imagine we're on Employee's listing and we filter the Employees by Country.
    // 2. Later we decide to assign an Employee to Paygroup,
    // so we navigate to the page, that do the assignment.
    // 3. Here on that Assignment page, we don't want to have already applied Employee filters,
    // from Employee's listing page.
    this.props.resetEmployeeSystemUsersFilters()
  }

  render () {
    if (this.props.isFetching) return <Loader key={this.props.payrollInstance} />

    return <EmployeesSearch key={this.props.payrollInstance} {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, { enablePagination, location, ...rest }) => {
  // Prevent multiple API calls for filtering the employees,
  // when the user continuously input some text.
  // Please refer to `onSubmit`
  let delaySearch
  return {
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchBusinessUnitsIfNeeded: () => dispatch(fetchBusinessUnitsIfNeeded()),
    fetchCostCentersIfNeeded: () => dispatch(fetchCostCentersIfNeeded()),
    fetchProjectsIfNeeded: () => dispatch(fetchProjectsIfNeeded()),
    fetchPayrollsIfNeeded: () => dispatch(fetchPayrollsIfNeeded()),
    fetchPaygroupsIfNeeded: () => dispatch(fetchPaygroupsIfNeeded()),
    fetchAccessAreaPivot: () => dispatch(fetchAccessAreaPivot()),
    fetchPayrollInstancesIfNeeded: (params) => dispatch(fetchPayrollInstances(params)),
    resetEmployeeSystemUsersFilters: () => dispatch(resetEmployeeSystemUsersFilters()),
    /**
     * Set `country` and `company` form values,
     * when another child field is changed.
     *
     * Example: When we select a Company and the Country field is still unset,
     * then we have to auto set `country` value to match to the selected Company's country.
     *
     * Example2: There aren't any selected values for Country and Company.
     * Then we decide to select some BusinessUnits. Therefore this callback function is invoked,
     * and we will set Country and Company values automatically, according to the selected BusinessUnits.
     *
     * Keep in mind that all the fields are array like and have enabled `multi` select option,
     * so the passed in `value` here is an array.
     * Because of this, it's possible to select one-or-many Countries / Companies, according to
     * a child field value.
     *
     * Example3: We select all possible BusinessUnits. Let's say they are from different Companies / Countries.
     * Because of this, we will set Country and Company values automatically, with the unique
     * BusinessUnits's Countries and Companies.
     *
     * @param {String} entity - Which field is changed.
     * According this, we know from which selector / entity to get the `country` and `company` value.
     * @param {Array} value - Entity's selected value.
     */
    selectCountryAndCompanyByEntityValue: (entity, value) => {
      dispatch((dispatch, getState) => {
        const getCountryId = {
          company: () => getCountriesAndCompaniesIdsByCompanies(getState(), { companiesIds: value }),
          businessUnit: () => getCountriesAndCompaniesIdsByBusinessUnits(getState(), { businessUnitsIds: value }),
          costCenter: () => getCountriesAndCompaniesIdsByCostCenters(getState(), { costCentersIds: value }),
          project: () => getCountriesAndCompaniesIdsByProjects(getState(), { projectsIds: value }),
          payroll: () => getCountriesAndCompaniesIdsByPayrolls(getState(), { payrollsIds: value }),
          paygroup: () => getCountriesAndCompaniesIdsByPaygroups(getState(), { paygroupsIds: value }),
          payrollInstance: () => getCountriesAndCompaniesIdsByPayrollInstances(getState(), { payrollInstancesIds: value }),
        }

        const ids = getCountryId[entity](value)

        // Get employee search form values
        const selector = formValueSelector('employeeSearch')
        const values = selector(getState(), 'country', 'company')

        // Set values of `company` and `country` fields, only if they don't have a value yet,
        // because we want to prevent the following scenario:
        // 1. First we select all the Companies.
        // 2. After that we select 1 `businessUnit`.
        // 3. And if we don't have this check, then the `company`
        // will be selected automatically(by the businessUnit.company)
        // and will overwrite all previously selected companies.
        // If the both fields (company, country) don't have values,
        // then it's perfectly fine to set their values, according to the child entities.
        if (isEmpty(values.company)) {
          dispatch(change('employeeSearch', 'company', ids.companiesIds))
        }
        if (isEmpty(values.country)) {
          dispatch(change('employeeSearch', 'country', ids.countriesIds))
        }
      })
    },
    // We reset the form fields, on a `country` and `company` change.
    // Otherwise, the already selected fields's values, which are hidden later,
    // according to the new `country` and `company` filtration,
    // are still kept in the Store and later sent to the API.
    resetForm: (fields) => fields.forEach((field) => dispatch(change('employeeSearch', field, null))),
    onSubmit: (data) => {
      dispatch((dispatch, getState) => {
        clearTimeout(delaySearch)
        delaySearch = setTimeout(() => {
          const { pathname } = location
          const state = getState()
          const { payrollInstanceId, payrollId, paygroupId } = rest.match.params

          if (pathname.includes('/remove-employees')) {
            return triggerPayrollInstancePivotDispatch({
              dispatch,
              data,
              state,
              limit,
              payrollInstanceId,
              filterName: buildFilterName({ payrollInstanceId }).unassignEmployeesPayrollInstance,
            })
          }
          if (pathname.includes('/add-employees')) {
            return triggerAttachDettachDispatch({
              dispatch,
              data,
              state,
              limit,
              filterName: buildFilterName({ payrollInstanceId }).assignEmployeesPayrollInstance,
              additionalFilters: { attachableToPayrun: payrollInstanceId },
            })
          }
          if (pathname.includes('/assign') && pathname.includes('payrolls')) {
            return triggerAttachDettachDispatch({
              dispatch,
              data,
              state,
              limit,
              filterName: buildFilterName({ payrollId }).assignEmployeesPayroll,
              additionalFilters: { attachableToPayroll: payrollId },
            })
          }
          if (pathname.includes('/unassign') && !pathname.includes('paygroups')) {
            return triggerAttachDettachDispatch({
              dispatch,
              data,
              state,
              limit,
              filterName: buildFilterName({ payrollId }).unassignEmployeesPayroll,
              additionalFilters: { payroll: payrollId },
            })
          }
          if (pathname.includes('/assign') && pathname.includes('paygroups')) {
            return triggerAttachDettachDispatch({
              dispatch,
              data,
              state,
              limit,
              filterName: buildFilterName({ paygroupId }).assignEmployeesPaygroup,
              additionalFilters: { attachableToPayGroup: paygroupId },
            })
          }
          if (pathname.includes('/unassign') && pathname.includes('paygroups')) {
            return triggerAttachDettachDispatch({
              dispatch,
              data,
              state,
              limit,
              filterName: buildFilterName({ paygroupId }).unassignEmployeesPaygroup,
              additionalFilters: { paygroup: paygroupId },
            })
          }

          const filter = createFilter(
            {
              ...data,
              ...(enablePagination && { offset: 0, limit, isEmployee: true, isAccessable: true, payloadFormat: 'reduced' }),
            },
            'search'
          )
          dispatch(pushBreadcrumbFilter({ routeKey: `employees${location.search}`, filters: filter }))

          return dispatch(
            fetchEmployeeSystemUsers({
              filter: filter,
              disableObsoleteFlow: true,
            })
          )
        }, 600)
      })
    },
  }
}

/**
 * Group BUs by `company.id`
 * @param businessUnits
 */
const buildBUsOptions = (businessUnits) => {
  const sorted = sortBy(businessUnits, ['company.name', 'name'])

  return sorted.reduce((result, bu, i) => {
    const prev = sorted[i - 1]
    const isFirst = !prev

    if (isFirst || prev.company.id !== bu.company.id) {
      result.push({
        label: `Company ${bu.company.name}`,
        disabled: true,
        isBold: true,
        removeCheckbox: true,
      })
    }

    result.push({
      value: bu.id,
      label: bu.name,
    })

    return result
  }, [])
}

/**
 * Get form fields (dropdowns) option values
 *
 * Because all the fields' values depend on
 * `country` and `company` selected values,
 * here we determine which selector to invoke,
 * in order to filter the fields' values correctly.
 *
 * @param state
 * @param props
 * @param shouldIncludePayrollInstance
 * @return {{countries: *, companies: *, businessUnits: *, costCenters: *, projects: *, payrolls: *, paygroups: *}}
 */
const getFormFields = (state, props, shouldIncludePayrollInstance) => {
  // Get employee search form values
  const selector = formValueSelector('employeeSearch')
  const values = selector(state, 'country', 'company', 'businessUnit', 'costCenter', 'project', 'payroll', 'paygroup', 'payrollInstance')

  const { initial = {}, fields = {} } = state.form.employeeSearch || {}

  const countriesIds = fields.country && fields.country.visited ? values.country : initial.country
  const companiesIds = fields.company && fields.company.visited ? values.company : initial.company

  let countries, companies, businessUnits, costCenters, projects, payrolls, paygroups, payrollInstances

  // If the country is chosen, then we have to filter the companies by the selected country's value
  if (!isEmpty(countriesIds)) {
    let filters = { countriesIds }
    companies = getAccessibleCompaniesByCountries(state, filters)
  } else {
    // Otherwise just get all the companies, without a filter
    companies = getAccessibleCompanies(state)
  }

  // If the company is chosen, then filter all child entities by the selected company's value.
  // Here we check for `company` firstly, instead of `country`,
  // because all child entities have direct relation with company
  // and we'll filter one level deeper, because of the following relation:
  // 1 Country -> has_many -> Companies -> has_many BusinessUnits, CostCenters and all other child entities.
  if (!isEmpty(companiesIds)) {
    let filters = { companiesIds }

    countries = getCountriesByCompanies(state, filters).sort(sortByName)
    businessUnits = getBusinessUnitsByCompanies(state, filters).sort(sortByName)
    costCenters = getCostCentersByCompanies(state, filters).sort(sortByName)
    projects = getProjectsByCompanies(state, filters).sort(sortByName)
    payrolls = getPayrollsByCompanies(state, filters)
    paygroups = getPaygroupsByCompanies(state, filters)
    payrollInstances = shouldIncludePayrollInstance ? getCurrentPayrollInstancesByCompanies(state, filters) : null
  } else if (!isEmpty(countriesIds)) {
    // If the country is chosen, then filter all child entities by the selected country's value
    let filters = { countriesIds }

    countries = getCountriesByAuth(state).sort(sortByName)
    businessUnits = getBusinessUnitsByCountriesThroughAccessibleCompany(state, filters).sort(sortByName)
    costCenters = getCostCentersByCountriesThroughCompany(state, filters).sort(sortByName)
    projects = getProjectsByCountriesThroughCompany(state, filters).sort(sortByName)
    payrolls = getPayrollsByCountriesThroughCompany(state, filters)
    paygroups = getPaygroupsByCountriesThroughCompany(state, filters)
    payrollInstances = shouldIncludePayrollInstance ? getCurrentPayrollInstancesByCountries(state, filters) : null
  } else {
    // Otherwise, when nothing is selected, just render all the entities' values,
    // without applying filtration
    countries = getCountriesByAuth(state).sort(sortByName)
    businessUnits = getBusinessUnitsByAccessibleCompanies(state).sort(sortByName)
    costCenters = getCostCenters(state).sort(sortByName)
    projects = getProjects(state, props).sort(sortByName)
    payrolls = getPayrolls(state)
    paygroups = getPaygroups(state)
    payrollInstances = shouldIncludePayrollInstance ? getCurrentPayrollInstances(state) : null
  }

  businessUnits = buildBUsOptions(businessUnits)

  return {
    countries,
    companies,
    businessUnits,
    costCenters,
    projects,
    payrolls,
    paygroups,
    ...(shouldIncludePayrollInstance && { payrollInstances }),
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { companies, countries, businessUnits, costCenters, projects, payrolls, paygroups, accessAreaPivot, payrollInstances } = state

  const entities = [companies, countries, businessUnits, costCenters, projects, payrolls, paygroups, accessAreaPivot]

  // Here we support passing `payrollInstance` as a query parameter,
  // in order to filter the Employees by `payrollInstance` initially
  const payrollInstance = props.query.get('payrollInstance')

  if (payrollInstance) {
    const payrollInstanceFilter = createFilter({ id: payrollInstance, name: 'withPayrunId' })
    entities.push({ ref: payrollInstances, filter: payrollInstanceFilter })
  }

  if (isFetching(entities)) return { isFetching: true, payrollInstance }

  let countryAndCompany = null
  let currentPayroll = null
  let countryValue = null
  let companyValue = null

  if (payrollInstance) {
    countryAndCompany = getCountriesAndCompaniesIdsByPayrollInstances(state, { payrollInstancesIds: [payrollInstance] })
    const payrollForPayrun = getPayrollInstanceWithPayroll(state, { payrollInstanceId: payrollInstance })
    currentPayroll = [payrollForPayrun.payrollId]
    countryValue = countryAndCompany.countriesIds
    companyValue = countryAndCompany.companiesIds
  }

  let defaultInitials = {
    search: null,
    country: countryValue,
    company: companyValue,
    businessUnit: null,
    costCenter: null,
    project: null,
    payroll: currentPayroll,
    paygroup: null,
    payrollInstance: payrollInstance || null,
  }
  const currentBreadcrumbs = getBreadcrumbFiltersByKey(state, { breadcrumbKey: `employees${props.location.search}` })

  if (currentBreadcrumbs?.params) {
    defaultInitials = {
      search: currentBreadcrumbs.params?.search ?? null,
      country: currentBreadcrumbs.params?.country ?? null,
      company: currentBreadcrumbs.params?.company ?? null,
      businessUnit: currentBreadcrumbs.params?.businessUnit ?? null,
      costCenter: currentBreadcrumbs.params?.costCenter ?? null,
      project: currentBreadcrumbs.params?.project ?? null,
      payroll: currentBreadcrumbs.params?.payroll ?? currentPayroll,
      paygroup: currentBreadcrumbs.params?.paygroup ?? null,
      payrollInstance: currentBreadcrumbs.params?.payrollInstance ?? null,
    }
  }

  return {
    ...getFormFields(state, props, payrollInstance),
    isUserCot: isCot(state),
    disableCountryCompanyPayrollAndPayrun: !!payrollInstance,
    payrollInstance,
    initialValues: defaultInitials,
  }
}

EmployeesSearchBarContainer.propTypes = {
  payrollInstance: PropTypes.object,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchCountriesIfNeeded: PropTypes.func,
  fetchBusinessUnitsIfNeeded: PropTypes.func,
  fetchCostCentersIfNeeded: PropTypes.func,
  fetchProjectsIfNeeded: PropTypes.func,
  fetchPayrollsIfNeeded: PropTypes.func,
  fetchPaygroupsIfNeeded: PropTypes.func,
  fetchAccessAreaPivot: PropTypes.func,
  fetchPayrollInstancesIfNeeded: PropTypes.func,
  resetEmployeeSystemUsersFilters: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'employeeSearch',
    enableReinitialize: true,
  })(EmployeesSearchBarContainer)
)
