import { createReducer } from 'utils/redux/reducer'
import {
  LOGIN,
  UPDATE_PASSWORD,
  RECEIVE_PERMISSIONS_ACTION,
  REFRESH_TOKEN,
  RECEIVE_TOKEN,
  FETCH_MOBILE_PHONE,
  RECEIVE_MOBILE_PHONE,
  FETCH_EMAIL,
  FETCH_PERMISSIONS_ACTION,
  RECEIVE_EMAIL,
} from '../actions/auth'

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  roles: [],
  accessToken: null,
  refreshToken: null,
  /**
   * @inheritDoc redux/middlewares/jwt.js
   */
  refreshTokenPromise: null,
  userId: null,
  permissions: null,
  isFetchingPermissions: null,
  mobilePhone: {
    isFetching: null,
    phone: null,
  },
  email: {
    isFetching: null,
    email: null,
  },
  username: null,
  changePasswordToken: null,
  showLegacyPasswordReason: false,
}

export default createReducer(initialState, {
  [LOGIN]: (state, { payload: { roles, accessToken, refreshToken, userId, rhssoLogin } }) => ({
    ...state,
    roles,
    accessToken,
    refreshToken,
    userId,
    rhssoLogin,
  }),
  [UPDATE_PASSWORD]: (state, { payload: { username, changePasswordToken, showLegacyPasswordReason } }) => ({
    ...state,
    username,
    changePasswordToken,
    showLegacyPasswordReason,
  }),
  [FETCH_PERMISSIONS_ACTION]: (state, { payload }) => ({ ...state, permissions: payload, isFetchingPermissions: true }),
  [RECEIVE_PERMISSIONS_ACTION]: (state, { payload }) => ({ ...state, permissions: payload, isFetchingPermissions: false }),
  [FETCH_MOBILE_PHONE]: (state, { payload }) => ({ ...state, mobilePhone: { ...state.mobilePhone, isFetching: true } }),
  [RECEIVE_MOBILE_PHONE]: (state, { payload: { mobilePhone } }) => ({ ...state, mobilePhone: { ...state.mobilePhone, isFetching: false, phone: mobilePhone } }),
  [FETCH_EMAIL]: (state, { payload }) => ({ ...state, email: { ...state.email, isFetching: true } }),
  [RECEIVE_EMAIL]: (state, { payload: { email } }) => ({ ...state, email: { ...state.email, isFetching: false, email: email } }),
  [REFRESH_TOKEN]: (state, { payload: { refreshTokenPromise } }) => ({ ...state, refreshTokenPromise }),
  [RECEIVE_TOKEN]: (state, { payload: { accessToken, refreshToken } }) => ({ ...state, accessToken, refreshToken, refreshTokenPromise: null }),
})
