import _ from 'lodash'

/**
 * Convert a Number to its Alphabetic representation
 *
 * We add `9` to the number and use `36` as a base, because
 * base-36 positional numeral system uses
 * the Arabic digits 0 to 9 and Latin letters A to Z to represent any number.
 *
 * Because of this, the following is true `(10).toString(36) === 'a'`
 *
 * @link https://stackoverflow.com/a/36129812/4312466
 * @param {Number} number
 *
 * @returns String
 */
export const numberToLetter = (number) => (number + 9).toString(36)

/*
 * Truncate text
 *
 * @param {text} string
 * @param {length} number
 *
 * @returns String
 */
export const truncateText = (text, length) => _.truncate(text, { length: length })

/*
 * trim String method removes any special characters and
 * whitespaces from strings
 *
 * ex:
 * 'some - Name' => 'somename'
 * 'name_k' => 'namek'
 *
 * @param {text} string
 *
 * @returns String
 */
export const trimString = (string) =>
  string
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, ' ')
    .replace(/\s+/g, '')
    .trim()

/*
 * CheckValue method check if passed value contains at least * one number, at least one capital letter, at least one
 * special symbol and is at least 8 characters long
 *
 * @param {val} string | number | character
 *
 * @returns String
 */
export const checkValue = (val) => {
  const isThereAtLeastOneNumber = (passwordValue) => {
    const rgx = /.*[0-9].*/
    const passRgx = rgx.test(passwordValue)
    return passRgx
  }

  const isThereAtLeastOneCapitalLetter = (passwordValue) => {
    const rgx = /(?=.*[A-Z])/
    const passRgx = rgx.test(passwordValue)
    return passRgx
  }

  const isThereAtLeastOneSpecialSymbol = (passwordValue) => {
    const rgx = /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/
    const passRgx = rgx.test(passwordValue)
    return passRgx
  }

  const isMinimumTenCharacters = (passwordValue) => {
    return passwordValue.length >= 10
  }

  const isThereNoAdjacentRepeatingChars = (passwordValue) => {
    const rgx = /(.)\1{1}/
    const passRgx = !rgx.test(passwordValue)
    return passRgx
  }

  if (val == null || val === undefined) return

  return {
    hasNumber: isThereAtLeastOneNumber(val),
    hasCapitalLetter: isThereAtLeastOneCapitalLetter(val),
    hasSpecialSymbol: isThereAtLeastOneSpecialSymbol(val),
    hasMinimumLength: isMinimumTenCharacters(val),
    hasNoAdjacentRepeatingChars: isThereNoAdjacentRepeatingChars(val),
  }
}

/**
 * Locale Compare and Sort By 'name' attribute
 */
export const sortByName = (a, b) => a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())

/**
 * Locale Compare and Sort By 'surname' attribute then by 'firstName' attribute
 */
export const sortBySurnameThenFirstName = (a, b) => {
  const surnameComparison = a.surname.localeCompare(b.surname)
  if (surnameComparison === 0) {
    return a.firstName.localeCompare(b.firstName)
  }
  return surnameComparison
}

/**
 * Locale Compare and Sort by nested country 'label' attribute
 */
export const sortByLabel = (a, b) => a.label?.toLocaleLowerCase().localeCompare(b.label?.toLocaleLowerCase())

/**
 * Locale Compare and Sort by nested country 'name' attribute
 */
export const sortByCountryName = (a, b) => a.country?.name?.toLocaleLowerCase().localeCompare(b.country?.name?.toLocaleLowerCase())

export const boolToYNStringsWithNullAsEmptyString = (value) => {
  if (value === null) return ''
  return value ? 'Y' : 'N'
}

export const boolToYNStrings = (value) => (value ? 'Y' : 'N')
