// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_SHOW_MESSAGE = 'MODAL_SHOW_MESSAGE'
export const MODAL_CLEAR_MESSAGE = 'MODAL_CLEAR_MESSAGE'

// ------------------------------------
// Actions
// ------------------------------------

/**
 * Show message
 *
 * @param {Object} message - It has to match modal reducer structure.
 */
export const showMessage = message => ({
  type: MODAL_SHOW_MESSAGE,
  payload: message
})

export const clearMessage = () => ({
  type: MODAL_CLEAR_MESSAGE
})
