export const ACCEPTED_TYPE_VALUES = {
  STRING: 'string',
  NUMBER: 'number',
  INTEGER: 'integer',
  DATE: 'date',
  ENUM: 'enum',
  DECIMAL: 'decimal',
  EMAIL: 'email',
}

export const ACCEPTED_FORMAT_VALUES = {
  STRING: 'string',
  NUMBER: 'number',
  INTEGER: 'number',
  DATE: 'date',
  ENUM: 'enum',
  DECIMAL: 'decimal',
  EMAIL: 'email',
}

export const EMPLOYEE_EXTRA_FIELDS_DEFAULT_SCHEMA = {
  subCategory: '',
  component: 'input',
  countries: [],
  field: '',
  name: '',
  mappedServerType: 'string',
  type: 'input',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_NUMBER_SCHEMA = {
  subCategory: '',
  component: 'input',
  countries: [],
  field: '',
  min: null,
  name: '',
  mappedServerType: 'number',
  type: 'number',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_INTEGER_SCHEMA = {
  subCategory: '',
  component: 'input',
  countries: [],
  field: '',
  min: null,
  name: '',
  mappedServerType: 'integer',
  type: 'number',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_STRING_SCHEMA = {
  subCategory: '',
  component: 'input',
  countries: [],
  field: '',
  name: '',
  mappedServerType: 'string',
  type: 'text',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_EMAIL_SCHEMA = {
  subCategory: '',
  component: 'email',
  countries: [],
  field: '',
  name: '',
  mappedServerType: 'email',
  type: 'text',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_DROPDOWN_SCHEMA = {
  subCategory: '',
  component: 'dropdown',
  countries: [],
  field: '',
  options: [],
  mappedServerType: 'enum',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_DATE_SCHEMA = {
  subCategory: '',
  component: 'date',
  countries: [],
  field: '',
  name: '',
  type: '',
  mappedServerType: 'date',
  schemaEnabled: true,
}

export const EMPLOYEE_EXTRA_FIELDS_SCHEMAS = [
  { ...EMPLOYEE_EXTRA_FIELDS_DEFAULT_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_NUMBER_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_INTEGER_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_STRING_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_DROPDOWN_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_DATE_SCHEMA },
  { ...EMPLOYEE_EXTRA_FIELDS_EMAIL_SCHEMA },
]
