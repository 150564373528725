import createSelector from 'utils/createSelector'

// get employee id
const getEmployeeId = (state, props) => parseInt(props.employeeId)

// get employee paygroups
export const getPaygroupsByEmployee = createSelector(getEmployeeId,
  ({ EmployeeSystemUser }, id) => {
    return EmployeeSystemUser.withId(id).paygroupEmployees.toModelArray().map(paygroupEmployee => ({
      id: paygroupEmployee.paygroup.id,
      name: paygroupEmployee.paygroup.name,
      currency: paygroupEmployee.paygroup.currency
    }))
  }
)
