import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'

class ClientsList extends React.Component {
  renderData (clients) {
    return clients.map(client => {
      return {
        id: client.id,
        url: client.url,
        name: <span data-testid={`client-${client.id}`}>{client.name}</span>,
        schema: client.schema
      }
    })
  }

  render () {
    const { clients, selectTenant, resetTenant, vendorId, history } = this.props

    const formatNameColumn = ({ row: { values: { id, name, url, schema } } }) => {
      return <div onClick={() => {
        resetTenant()
        selectTenant({ url: url, schema: schema, shouldUse: true })
        history.push(`/vendors/${vendorId}/clients/${id}/companies`)
      }}>{name}</div>
    }

    const options = {
      searchPosition: 'left',
      noDataText: 'No matching records found.',
    }

    return <>
      <SectionHeading text='Clients list' />

      <PayslipTable
        data={this.renderData(clients)}
        headings={[
          { accessor: 'id', Header: 'ID', isKey: true, isVisible: false },
          { accessor: 'url', Header: 'Url', isVisible: false, disableFilters: true },
          { accessor: 'schema', Header: 'schema', isVisible: false, disableFilters: true },
          {
            accessor: 'name',
            Header: 'Name',
            columnClassName: 'u-text--curious',
            Cell: formatNameColumn,
            disableFilters: true
          },
        ]}
        wrapperClassName='u-1/1 u-1/2@tablet u-1/3@desktop'
        trClassName='react-bs-table__cell--overflow-ellipsis u-cursor--pointer'
        trBodyClassName='u-cursor--pointer'
        options={options}
        search
      />
    </>
  }
}

ClientsList.propTypes = {
  clients: PropTypes.array.isRequired,
  selectTenant: PropTypes.func.isRequired,
  resetTenant: PropTypes.func.isRequired,
  vendorId: PropTypes.number.isRequired,
  history: PropTypes.object
}

export default ClientsList
