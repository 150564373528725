import React, { useState, useRef, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import download from 'downloadjs'
import { toPng } from 'html-to-image'
import PropTypes from 'prop-types'
import Fetcher from 'containers/Fetcher'
import { getCompanyRef } from 'redux/selectors/company'
import { getCompanyHolidayPivotByCompanyGroupByYear } from 'redux/selectors/companyHolidayPivot'
import { getCountryNameById } from 'redux/selectors/country'
import { getPayrollById } from 'redux/selectors/payroll'
import { getCompanyIdByPayroll } from 'routes/Payruns/routes/ChangeDetails/selectors/companyORM'
import { getPayrollInstancesByPayrollId } from 'routes/Payruns/selectors/payrollInstancesORM'
import { getEventsByFilterName } from 'routes/Toolbox/routes/Calendar/selectors/events'
import { createFilter } from 'utils/redux/filter'
import { calendarYear, getCalendarEventFiltersForYear, getSelectedYearStartDate, shouldShowNextYear } from 'utils/calendarYearDates'
import CalendarYearView from '../components/CalendarYearView'
import Loader from 'components/Loader'

const CalendarYearPayrunContainer = props => {
  const { isFetching } = props
  const [isExporting, setIsExporting] = useState(false)
  const pngRef = useRef()

  const onDownloadCalendar = useCallback(() => {
    if (pngRef.current === null) {
      return
    }
    setIsExporting(true)
    setTimeout(() => {
      toPng(pngRef.current, {
        cacheBust: true,
        backgroundColor: 'white',
        style: { marginRight: '1rem' },
      })
        .then((dataUrl) => {
          download(dataUrl)
          setIsExporting(false)
        })
        .catch((err) => console.log(err))
    }
    , 10)
  }, [pngRef])

  if (isFetching) return <Loader />

  return <CalendarYearView
    events={props.events}
    pngRef={pngRef}
    downloadCalendar={onDownloadCalendar}
    isExporting={isExporting}
    onSelectedYear={props.onSelectedYear}
    shouldShowNextYear={shouldShowNextYear()}
    {...props} />
}

CalendarYearPayrunContainer.propTypes = {
  events: PropTypes.array,
  isFetching: PropTypes.bool
}

const mapStateToCalendarContainerProps = (state, props) => {
  return {
    events: getEventsByFilterName(state, { filter: props.eventFilters.name }),
    ...props
  }
}

const Container = connect(mapStateToCalendarContainerProps)(CalendarYearPayrunContainer)

const EventFetcher = Fetcher(Container, [
  {
    name: 'events',
    params: [{
      _computed: {
        filter: (state, props) => props.eventFilters,
        forceVendorTenantAPI: (state, props) => props.isVendor,
      },
    }]
  }
])

const WrapperComponent = (props) => {
  const [year, setYear] = useState(calendarYear)
  const onSelectedYear = (data) => {
    setYear(data.value)
  }
  const calendarFiltersForYear = getCalendarEventFiltersForYear(year)
  const selectedYearStartDate = getSelectedYearStartDate(year)

  const eventFilters = createFilter({
    fromDate: calendarFiltersForYear.fromDate,
    toDate: calendarFiltersForYear.toDate,
    company: props.companyId,
    payroll: props.match.params.payrun,
    notInactive: true
  })

  const holidays = useSelector((state) => getCompanyHolidayPivotByCompanyGroupByYear(state, { companyId: props.companyId }))
    .find(holidays => holidays.year === year)?.data
    .filter(h => !h.isWorkingDay)

  return (
    <EventFetcher
      eventFilters={eventFilters}
      onSelectedYear={onSelectedYear}
      year={year}
      selectedYearStartDate={selectedYearStartDate}
      holidays={holidays}
      {...props}
    />
  )
}

WrapperComponent.propTypes = {
  companyId: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      payrun: PropTypes.string
    })
  })
}

const mapStateToProps = (state, props) => {
  const companyId = getCompanyIdByPayroll(state, { payroll: props.match.params.payrun })
  const company = getCompanyRef(state, { companyId })
  const payrollName = getPayrollById(state, { payrollId: props.match.params.payrun }).name
  const countryName = getCountryNameById(state, { countryId: company.country })
  const instances = getPayrollInstancesByPayrollId(state, { payrollId: props.match.params.payrun })
  const filteredInstances = instances.filter(instance => instance.status !== 'payroll-ended')
  return {
    companyId,
    companyName: company.name,
    countryName,
    payrollName,
    payrollInstances: filteredInstances,
    stateEvents: state.events,
  }
}

const ContainerWrapper = connect(mapStateToProps)(WrapperComponent)

export default Fetcher(ContainerWrapper, [
  {
    name: 'companies',
    params: [{
      _computed: {
        forceVendorTenantAPI: (state, props) => props.isVendor,
      }
    }]
  },
  {
    name: 'countries',
    params: [{
      _computed: {
        forceVendorTenantAPI: (state, props) => props.isVendor,
      }
    }]
  },
  {
    name: 'payrolls',
    params: [{
      _computed: {
        forceVendorTenantAPI: (state, props) => props.isVendor,
      }
    }]
  },
  {
    name: 'companyHolidayPivot',
    params: [{
      _computed: {
        forceVendorTenantAPI: (state, props) => props.isVendor,
      }
    }]
  },
  {
    name: 'payrollInstances',
    params: [{
      _computed: {
        filter: (state, props) =>
          createFilter({ payroll: props.match.params.payrun }),
        forceVendorTenantAPI: (state, props) => props.isVendor
      },
      disableObsoleteFlow: true
    }]
  },
])
