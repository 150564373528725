import React from 'react'

import { isEmpty } from 'lodash'

export const addExpandableSubRow = (list = [], options = {}) => {
  let itemsWithSubRow = []
  try {
    const { idKey, expandedTextKey, ...opts } = options
    itemsWithSubRow = list?.map((item, rowIndex) => {
      const expandedText = item[expandedTextKey]
      let ExpandedComponent
      if (!isEmpty(expandedText)) {
        ExpandedComponent = (editableOptions) => <div className='row-expanded-text'>{expandedText}</div>
      }
      return {
        ...item,
        subRows: [
          {
            id: item[idKey],
            rowIndex,
            value: item[expandedTextKey],
            ExpandedComponent,
            isNew: item?.isNew,
            ...opts,
          },
        ],
      }
    })
  } catch (_) {
    itemsWithSubRow = list
  }
  return itemsWithSubRow
}
