import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AccordionContainer from 'containers/AccordionContainer'
import { Authorization } from 'containers/Authorization'
import SectionHeading from 'components/SectionHeading'
import AmountFormat from 'components/AmountFormat'
import Dropzone from 'react-dropzone'
import { trimString } from 'utils/strings'
import MobileDetailsView from 'components/MobileDetailsView'
import PayslipTable from 'components/table/PayslipTable'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { buttonActions } from 'utils/locales/common.en'
import { payslipMessages } from 'utils/locales/payruns.en'
const noPayslipsMessage = 'No files to display.'

class PayslipsList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      instanceId: null,
    }
  }

  onUpload (instanceId) {
    this.setState({
      instanceId,
    })

    this.dropzoneRef.open()
  }

  renderRows () {
    const { downloadFile, isCot, payslips } = this.props

    if (isEmpty(payslips)) return []

    return payslips.data.map((payslip) => ({
      payrollName: payslip.payrollName,
      fromDate: moment(payslip.fromDate.date).format('DD/MM/YYYY'),
      toDate: moment(payslip.toDate.date).format('DD/MM/YYYY'),
      grossPay: <AmountFormat amount={payslip.grossPay} />,
      netPay: <AmountFormat amount={payslip.netPay} />,
      payDate: moment(payslip.payDate.date).format('DD/MM/YYYY'),
      payrollpayslipsName: (
        <div>
          <button
            className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
            title={payslip.hasPayslipFile ? buttonActions.download : payslipMessages.notUploadedYet}
            disabled={!payslip.hasPayslipFile}
            onClick={() => downloadFile(payslip.id)}
            data-testid={`payslip-${payslip.id}-download`}
          >
            <span className='icon icon--download' />
          </button>
          <Authorization permissions={['PAYSLIP_IMPORT']}>
            {isCot && (
              <button type='button' onClick={() => this.onUpload(payslip.id)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title='Upload'>
                <span className='icon icon--upload' />
              </button>
            )}
          </Authorization>
        </div>
      ),
    }))
  }

  // THIS IS NEEDED FOR THE MOBILE VIEW
  renderPayslipDetails (payslip) {
    const { fromDate, toDate, grossPay, netPay } = payslip

    return [
      { 'From Date': moment(fromDate.date).format('DD/MM/YYYY') },
      { 'To Date': moment(toDate.date).format('DD/MM/YYYY') },
      { 'Gross Pay': <AmountFormat amount={grossPay} key={payslip.id} /> },
      { 'Net Pay': <AmountFormat amount={netPay} key={payslip.id} /> },
    ]
  }

  render () {
    const { isFetching, uploadFile, employmentStatus, downloadFile, isEmployeeOnly, payslipsFetched, payslips } = this.props

    let contractorChangeTitleStatuses = ['contractorcompany', 'contractoragency', 'contractorindividual']
    const isContractorChangeTitleStatus = employmentStatus && contractorChangeTitleStatuses.includes(trimString(employmentStatus))

    const sectionHeading = isContractorChangeTitleStatus ? 'Invoices' : 'Payslips'
    const headingPayslips = isContractorChangeTitleStatus ? 'Invoice' : 'Payroll'
    const headingDocuments = isContractorChangeTitleStatus ? 'My Invoices' : 'My Payslips'

    if (isFetching) return <div>Loading...</div>

    return (
      <div>
        <div className={isEmployeeOnly ? 'u-hide-on-mobile' : null}>
          <SectionHeading text={`${sectionHeading}`} />

          <Dropzone
            onDrop={(files) => uploadFile(this.state.instanceId, files[0])}
            ref={(node) => {
              this.dropzoneRef = node
            }}
            className='u-relative'
          />

          <PayslipTable
            proportionsClassName='u-1/1'
            tableClassName='o-table--tiny o-table--striped'
            headings={[
              {
                Header: `${headingPayslips}`,
                accessor: 'payrollName',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: 'From Date',
                accessor: 'fromDate',
                setWidth: '220px',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: 'To Date',
                accessor: 'toDate',
                setWidth: '220px',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: 'Pay Date',
                accessor: 'payDate',
                setWidth: '220px',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: 'Gross Pay',
                accessor: 'grossPay',
                setWidth: '150px',
                classNames: 'text-center',
                columnClassName: 'text-center',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: 'Net Pay',
                accessor: 'netPay',
                setWidth: '150px',
                classNames: 'text-center',
                columnClassName: 'text-center',
                disableSortBy: true,
                disableFilters: true,
              },
              {
                Header: `${headingDocuments}`,
                accessor: 'payrollpayslipsName',
                classNames: 'text-right',
                columnClassName: 'text-right',
                setWidth: '200px',
                disableSortBy: true,
                disableFilters: true,
              },
            ]}
            data={this.renderRows()}
          />
        </div>
        {isEmployeeOnly && (
          <div className='u-hide-on-desktop'>
            <h1 className='u-text--huge u-weight--bold'>{headingDocuments}</h1>
            {!isEmpty(payslips) &&
              payslips.data?.map((payslip) => (
                <AccordionContainer
                  key={payslip.id}
                  rounded
                  title={payslip.payrollName}
                  titleColor='minsk'
                  priority={3}
                  subtitleKey={'Pay Date:'}
                  subtitle={moment(payslip.payDate.date).format('DD/MM/YYYY')}
                  mobileView
                  fullWidth
                  actionButtons={
                    <button
                      className='c-btn c-btn--small c-btn--curious u-margin-right-tiny rounded'
                      disabled={!payslip.hasPayslipFile}
                      onClick={() => downloadFile(payslip.id)}
                      data-testid={`payslip-mobile-${payslip.id}-download`}
                    >
                      <span className='icon icon--download' />
                    </button>
                  }
                >
                  <MobileDetailsView rows={this.renderPayslipDetails(payslip)} />
                </AccordionContainer>
              ))}
            {isEmpty(payslips) && payslipsFetched && (
              <div className='mobile-no-data-message--centered'>
                <span>{noPayslipsMessage}</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

PayslipsList.propTypes = {
  isFetching: PropTypes.bool,
  downloadFile: PropTypes.func,
  uploadFile: PropTypes.func,
  isCot: PropTypes.bool,
  employmentStatus: PropTypes.string,
  isEmployeeOnly: PropTypes.bool,
}

export default PayslipsList
