import React from 'react'
import ChangePhoneNumberContainer from '../containers/ChangePhoneNumberContainer'
import ConfirmPhoneNumberContainer from '../containers/ConfirmPhoneNumberContainer'
import PropTypes from 'prop-types'

// Possible action to be done here
const actions = {
  // Confirming the phone number
  confirm: 'confirm',
  // Changing the phone number
  change: 'change'
}

class UserActivationView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      action: props.action
    }
  }

  changeAction (action) {
    this.setState({ action })
  }

  renderPhoneNumberConfirming () {
    return <>
      <ConfirmPhoneNumberContainer {...this.props} />
      <p className='u-text--small u-text--center'>If the provided phone number is not correct, please
        <span className='u-text--curious u-text--underline u-cursor--pointer'
          onClick={() => this.changeAction(actions.change)}>
          &nbsp;click here</span>, enter your current phone number and save.
      </p>
    </>
  }

  renderPhoneNumberChanging () {
    return <>
      <ChangePhoneNumberContainer
        onSuccess={() => this.changeAction(actions.confirm)}
        {...this.props} />

      <p className='u-text--small u-text--center'>
        <span className='u-text--curious u-text--underline u-cursor--pointer'
          onClick={() => this.changeAction(actions.confirm)}>Cancel</span>
      </p>
    </>
  }

  render () {
    const { action } = this.state

    return <div className='o-grid o-grid--center'>
      { action === actions.confirm ? this.renderPhoneNumberConfirming() : this.renderPhoneNumberChanging() }
    </div>
  }
}

UserActivationView.propTypes = {
  action: PropTypes.string
}

export default UserActivationView
