import createSelector from 'utils/createSelector'
import { hasAccess } from 'redux/selectors/auth'
import { makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { completionURIs } from 'redux/config/tasks'
import { getCompiledUri } from 'utils/templates'
import moment from 'moment'
import _ from 'lodash'

const getFilteredIds = (state) => state.events.filteredIds
const getCompanyId = (state, props) => parseInt(props.companyId)
export const getFilteredIdsByFilter = makeFilteredIdsByFilter('events')

export const getEvents = createSelector(hasAccess, ({ Event, Company }, hasAccess) => {
  return Event.filter((event) => filterInitialEvents(event))
    .toRefArray()
    .map((event) => getEvent(event, Company, hasAccess))
})

export const getEventsByFilterName = createSelector(hasAccess, getFilteredIdsByFilter, ({ Event, Company }, hasAccess, filteredIds) => {
  return filteredIds
    .map((id) => Event.withId(id).ref)
    .filter((event) => filterInitialEvents(event))
    .map((event) => getEvent(event, Company, hasAccess))
})

export const getFilteredEvents = createSelector(getFilteredIds, hasAccess, ({ Event, Company }, filteredIds, hasAccess) => {
  return Event.filter((event) => filterInitialEvents(event) && filteredIds.includes(event.id))
    .toRefArray()
    .map((event) => getEvent(event, Company, hasAccess))
})

const buildTaskLink = (event, hasAccess) => {
  const template = event.extraData.finishTaskLink
  const payrollInstanceId = event.extraData.payrollInstance
  const companyId = event.extraData.company

  if (!template || !payrollInstanceId) return null

  const uriMapping = _.find(completionURIs, { value: template })
  const hasAccessToUri = hasAccess(uriMapping.requiredPermissions)

  if (!hasAccessToUri) return null

  const taskLink = uriMapping
    ? _.includes(uriMapping.uri, 'companies')
      ? getCompiledUri(uriMapping.uri, companyId)
      : getCompiledUri(uriMapping.uri, payrollInstanceId)
    : null

  return taskLink
}

const getEvent = (event, Company, hasAccess) => {
  const {
    extraData: {
      owner,
      ownerInitials,
      company,
      status,
      hasReversions,
      isReversionCompleted,
      reversionType,
      reversionReason,
      payrollProcess,
      headlampColor,
      payrollInstance,
    },
  } = event

  const detailedTitle = payrollProcess ? `${event.name} - ${payrollProcess}` : event.name
  const start = moment(event.date.date).startOf('day').toDate()
  const end = moment(event.date.date).startOf('day').toDate()
  const flag = company ? Company.withId(company).country.abbreviature.toLowerCase() : null
  const companyName = company ? Company.withId(company).name : null

  return {
    id: event.id,
    title: event.name,
    finishTaskLink: buildTaskLink(event, hasAccess),
    headlampColor: `#${headlampColor}`,
    detailedTitle,
    start,
    end,
    owner,
    ownerInitials,
    flag,
    payrollProcess,
    payrollInstance,
    status,
    hasReversions,
    isReversionCompleted,
    reversionType,
    reversionReason,
    companyName,
  }
}

const filterInitialEvents = (event) => {
  const hasDate = event.date
  const isTask = ['PayrollInstanceTaskOwner', 'CompanyTask', 'PayrollInstanceTask'].includes(event.type)

  return hasDate && isTask
}

export const getHolidays = createSelector(({ Event }) => {
  return Event.filter((event) => event.type === 'CompanyHoliday')
    .toRefArray()
    .map((event) => getSingleHoliday(event))
})

export const getHolidaysByCompany = createSelector(getCompanyId, getHolidays, ({ Event }, companyId, holidays) => {
  return Event.filter((event) => event.type === 'CompanyHoliday' && event.extraData.company === companyId)
    .toRefArray()
    .map((event) => getSingleHoliday(event))
})

const getSingleHoliday = (event) => ({
  ...event,
  title: event.name,
  start: moment(event.date.date).startOf('day').toDate(),
  end: moment(event.date.date).startOf('day').toDate(),
})
