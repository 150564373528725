export const payrunTitles = {
  prePayrollSteps: 'Pre Payroll Steps',
  postPayrollSteps: 'Post Payroll Steps',
  closedPayruns: 'Closed Payruns',
  futurePayruns: 'Future Payruns',
}

export const payrunProgressStatus = {
  overdue: 'Overdue',
  pending: 'Pending',
  onTime: 'On Time',
  notStarted: 'Not Started',
  tooltip: ' Overdue Actions',
}

export const gtnValidationMessages = {
  override: {
    prefix: 'We have compared the ',
    item1: 'Primary IDs on the uploaded document ',
    concat: 'with the ',
    item2: 'Primary IDs in the payrun ',
    suffix: 'and there is not a sufficient match to pass validation.',
    message: 'Please review the file and ensure it is correct.',
    label: 'Override validation check',
    heading: 'Override validation',
    confirm1: 'We have detected that there are Primary IDs in the uploaded document that do not belong to the payrun.',
    confirm2: 'Please confirm you have uploaded the GTN to the correct company and you want to override the validation?',
  },
  pending: {
    message: 'File is pending validation and can’t be downloaded',
  },
  share: {
    confirm: 'Validation has been overridden. Do you want to share the file? Note: any previous sharing of the document has been removed.',
  },
  error: {
    message: 'The validation process has failed and this document can’t be downloaded',
  },
}

export const payrunMessages = {
  completed:
    'Payrun is complete. The next active payrun is being generated and will be displayed once finished.\n' +
    '\n' +
    'Note: If you make multiple changes to an employee record while the file is being generated, only the most recent update will be shown in the payrun file',
  completedWithExtraSteps:
    'Payrun has moved to the Post Payroll Steps tab. The next active payrun is being generated and will be displayed once finished.\n' +
    '\n' +
    'Note:  If you make multiple changes to an employee record while the file is being generated, only the most recent update will be shown in the payrun file',
  timeout: 'TIMEOUT: Issue Completing a Payrun Step (most probably a timeout error)',
  completedAfterExtraSteps: 'All actions for your payroll are completed.',
}

export const payslipMessages = {
  notUploadedYet: 'The payslip has not been uploaded yet',
}
