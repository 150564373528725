import { createReducer } from 'utils/redux'
import { SERVICES_FETCH, SERVICES_RECEIVE, SERVICES_INVALIDATE, SERVICE_CREATE } from '../actions/services'

const actionTypes = {
  fetch: SERVICES_FETCH,
  receive: SERVICES_RECEIVE,
  invalidate: SERVICES_INVALIDATE,
  create: SERVICE_CREATE
}

export default createReducer(actionTypes)
