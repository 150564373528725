
import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const REPORT_DATA_FETCH = 'REPORT_DATA_FETCH'
export const REPORT_DATA_RECEIVE = 'REPORT_DATA_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: REPORT_DATA_FETCH,
  receive: REPORT_DATA_RECEIVE
}

const actionUtility = new ActionUtility(actionTypes, 'reportData', 'report', 'ReportData')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchReportDataIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchReportData = actionUtility.fetchEntities
