import React from 'react'
import Modal from 'components/Modal'
import ApproveFieldsContainer from '../containers/ApproveFieldsContainer'
import { Authorization } from 'containers/Authorization'
import PropTypes from 'prop-types'
import PayslipTable from 'components/table/PayslipTable'
import { CheckIconAlt } from 'components/icons/CheckIcons'

class PersonalDataList extends React.Component {
  render () {
    const { dataFields } = this.props
    return (
      <div className='o-layout__item u-1/1 u-1/2@tablet u-1/3@desktop'>
        <Modal ref='approveFieldsModal' className='c-modal c-modal--half c-modal--overflow-y' modalHeading='Approve Fields'>
          <ApproveFieldsContainer isApproveModal {...this.props} />
        </Modal>
        <Authorization permissions={['COMPANYDATAPROTECTIONFIELDSTATE_CREATE']}>
          <div
            onClick={() => this.refs.approveFieldsModal.showModal()}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-bottom'
            title='Approve fields'
          >
            Approve fields
          </div>
        </Authorization>
        <PayslipTable
          data={dataFields.map((field) => ({
            field: (
              <div className='o-layout'>
                <div className='o-layout__item u-2/3'>
                  <span className='u-text--normal'>{field.name}</span>
                </div>
                <div className='o-layout__item u-1/3'>
                  {field.hasData ? <CheckIconAlt className='d-flex jc--center' width='23px' height='23px' /> : <span className='icon--ex icon--ex--bordered' />}
                </div>
              </div>
            ),
          }))}
          headings={[
            // eslint-disable-next-line max-len
            {
              accessor: 'field',
              Header: 'Personal Data Fields (Sensitive Data)',
              isKey: true,
              classNames: 'text-center',
              disableSortBy: true,
              disableFilters: true,
            },
          ]}
          wrapperClassName='u-margin-bottom u-1/1'
          options={{ noDataMessage: 'There is no sensitive data for the selected fields.' }}
        />
      </div>
    )
  }
}

PersonalDataList.propTypes = {
  dataFields: PropTypes.array,
}

export default PersonalDataList
