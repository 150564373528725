import { getUser } from 'redux/selectors/employees'
import { getPayrollById } from 'redux/selectors/payroll'
import { getCompanyByPayrollInstance } from 'routes/Payruns/routes/ChangeDetails/selectors/companyORM'

/**
 * Get company id by type class
 *
 * @param string typeClass
 * @param {Object} state
 * @param {Object} props
 * @return number
 */
export const getCompanyIdByTypeClass = (typeClass, state, { match: { params }, payrollInstanceId, payrollId }) => {
  const getCompanyId = {
    employee: () => getUser(state, { userId: params.employeeId }).company,
    company: () => parseInt(params.companyId),
    payroll: () => getPayrollById(state, { payrollId }).company.id,
    payrollInstance: () => getCompanyByPayrollInstance(state, { payrollInstanceId }).id
  }

  return getCompanyId[typeClass]()
}
