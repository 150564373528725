import React from 'react'
import { connect } from 'react-redux'
import SectionHeading from 'components/SectionHeading'
import { Authorization } from 'containers/Authorization'
import CostingContainer from '../containers/CostingContainer'
import AddServiceFormContainer from '../containers/AddServiceFormContainer'
import RemoveServiceFormContainer from '../containers/RemoveServiceFormContainer'
import AddServiceElementFormContainer from '../containers/AddServiceElementFormContainer'
import EditServiceElementFormContainer from '../containers/EditServiceElementFormContainer'
import Modal from 'components/Modal'
import BaseConfirmationModal from 'components/BaseConfirmationModal'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
    this.handleEditCreateClick = this.handleEditCreateClick.bind(this)
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this)
    this.state = {
      action: 'addService',
      countryId: null,
      serviceId: null,
      serviceName: '',
    }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(getWhiteLabeledBrandStyles())
  }
  /**
   * Handle modal opening
   * @param modalRef
   */
  handleModalOpen (modalRef) {
    modalRef.showModal()
  }

  hideModal () {
    this.refs.modalAddEdit.hideModal()
    this.setState({ action: 'addService' })
  }

  hideConfirmationModal () {
    this.refs.modalConfirmation.hideModal()
    this.setState({ action: 'addService' })
  }

  handleEditCreateClick (action, countryId, serviceId, serviceName) {
    this.setState({ action })
    this.setState({ countryId })
    this.setState({ serviceId })
    this.setState({ serviceName })
    this.handleModalOpen(action === 'removeService' ? this.refs.modalConfirmation : this.refs.modalAddEdit)
  }

  render () {
    const { action, countryId, serviceId, serviceName } = this.state

    return (
      <div>
        <SectionHeading>
          <Authorization permissions={['VENDORCOUNTRYSERVICE_CREATE']}>
            <div className='o-layout__item u-1/1 u-1/2@tablet'>
              <div className='u-float--right'>
                <button
                  title='Add Country'
                  onClick={() => {
                    this.setState({ countryId: null })
                    this.handleModalOpen(this.refs.modalAddEdit)
                  }}
                  className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                >
                  Add Country
                </button>
              </div>
            </div>
          </Authorization>
        </SectionHeading>

        <CostingContainer {...this.props} onClick={this.handleEditCreateClick} />

        <Modal ref='modalAddEdit' className='c-modal' modalHeading='Update Service List'>
          {action === 'addService' ? <AddServiceFormContainer onSubmit={this.hideModal} {...this.props} countryId={countryId} /> : null}
          {action === 'addServiceElement' ? <AddServiceElementFormContainer onSubmit={this.hideModal} {...this.props} /> : null}
          {action === 'editServiceElement' ? <EditServiceElementFormContainer onSubmit={this.hideModal} {...this.props} /> : null}
        </Modal>

        <BaseConfirmationModal ref='modalConfirmation' className='c-modal' modalHeading='Confirmation'>
          <RemoveServiceFormContainer
            {...this.props}
            countryId={countryId}
            serviceId={serviceId}
            serviceName={serviceName}
            onSubmit={this.hideConfirmationModal}
            onReject={this.hideConfirmationModal}
          />
        </BaseConfirmationModal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})
const Container = connect(null, mapDispatchToProps)(RouteView)
export default Container
