import { createReducer } from 'utils/redux/reducer'
import { DISABLE_INVALIDATOR } from '../actions/tenants'

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer({
  // Is `Invalidator` feature enabled?
  // In short, the `Invalidator` monitors the cases where we leave (unmounting) a Vendor->Tenant(Client) pages
  // and we navigate to other pages, these aren't related to the previously selected Client.
  // In this case we have to invalidate some resources.
  // But there's a use-case, the `Invalidator` should not be triggered.
  // For more details: `routes/Vendors/routes/Vendor/routes/Clients/routes/Client/components/Invalidator`
  isEnabled: true
}, {
  [DISABLE_INVALIDATOR]: state => ({ ...state, isEnabled: false })
})
