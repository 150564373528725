import React from 'react'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import AttachingToPaygroupContainer from '../containers/AttachingToPaygroupContainer'
import PaygroupHeadingContainer from '../../../../../containers/PaygroupHeadingContainer'
import InnerWrapper from 'layouts/InnerWrapper'
import useQuery from 'hooks/useQuery'

export default props => <InnerWrapper>
  <PaygroupHeadingContainer
    heading='Employees - Assigning to Paygroup'
    {...props} />
  <EmployeesSearchBarContainer query={useQuery()} {...props} />
  <AttachingToPaygroupContainer {...props} />
</InnerWrapper>
