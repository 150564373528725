import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import createDocumentFilter from 'redux/filters/createDocumentFilter'
import { isCotBot, isCotOrEngineerOrAdmin, isCot as isCotSelector, isVendorInvolved } from 'redux/selectors/auth'
import { updateDocument } from 'redux/actions/document'
import { showMessage } from 'redux/actions/modal'
import Fetcher from 'containers/Fetcher'
import DocumentClassificationFormEdit from 'components/documents/DocumentClassificationFormEdit'
import { getDocumentRef } from 'components/documents/selectors/documents'
import { setDocumentTypes } from 'utils/documentTypes'
import { docTypeIDs, docTypesFilesToExcludeWhenReadOnly } from 'utils/enums/documentTypes'
import { isNumber } from 'lodash'
import { excludeForFuturePayruns } from './utils/documents'
import { getDocumentTypes } from 'redux/selectors/documentTypes'
import { getCotUser } from 'redux/selectors/cotUsers'

const mapDispatchToProps = (dispatch, { id, onSubmitChanges, hasDocumentClassification }) => ({
  onSubmit: (data) => {
    // Editing a document outside of a payroll instance should send only the description
    const filteredData = hasDocumentClassification ? data : { description: data.description }

    return dispatch(updateDocument(filteredData, id, false, true)).then(({ messages }) => {
      onSubmitChanges()
      if (messages && Object.keys(messages).length) {
        // Join message by a dot and add a dot at the end of the messages.
        const messagesString = Object.values(messages).join('. ') + '.'

        dispatch(
          showMessage({
            body: messagesString,
          })
        )
      }
    })
  },
})

const mapStateToProps = (state, props) => {
  const document = getDocumentRef(state, { id: props.id })
  const isCotUser = isCotSelector(state)
  const isCotBotUser = isCotBot(state)
  const hasVendorAccess = isVendorInvolved(state)

  const cotUserSpecialRights = isCotUser && getCotUser(state, { userId: state.auth.userId }).cotSpecialRight

  const isPayrollInstance = isNumber(props.payrollInstanceId)

  const selector = formValueSelector('documentEdit')
  const values = selector(state, 'documentType', 'payrollId', 'documentTypes', 'version')
  const regex = new RegExp(`^[1-9]{1}[0-9]*$`)
  const showSubmitBtn = regex.test(Number(values.version))

  // Remove option/s from document types dropdown, when uploading file on payrun level in ER mode
  const documentTypesToExcludeForEmployer = [
    'gtnMappingReport',
    'payslipUploadReport',
    'taxDocumentUploadReport',
    'inputsValidationReport',
    'additionalInputs',
    'beneficiaryFile',
    'dataMigration',
    'dataMigrationValidationReport',
    ...docTypesFilesToExcludeWhenReadOnly.edit,
  ]

  // Remove option/s from document types dropdown, when uploading file on payrun level in Vendor mode
  const documentTypesToExcludeForVendor = [
    'inputs',
    'gtnMappingReport',
    'payslipUploadReport',
    'taxDocumentUploadReport',
    'glMapping',
    'mipiInputs',
    'inputsValidationReport',
    'additionalInputs',
    'beneficiaryFile',
    'dataMigration',
    'dataMigrationValidationReport',
    ...docTypesFilesToExcludeWhenReadOnly.edit,
  ]
  let shouldRunIsCotUserExcludes = isCotUser
  let documentTypesToExclude = hasVendorAccess ? documentTypesToExcludeForVendor : documentTypesToExcludeForEmployer
  const statusCheck = props.payrollInstance ? props.payrollInstance : props.instance

  // Only CotBot user should be able to upload Additional Inputs and Beneficiary file document type
  if (!isCotBotUser) documentTypesToExclude.push('additionalInputs', 'beneficiaryFile')

  const documentTypesToExcludeForFuturePayruns =
    isPayrollInstance &&
    excludeForFuturePayruns({
      docTypes: ['inputsAutoSaved', 'mipiInputs', 'gtn', 'gtnAutoSaved', 'payslips', 'tax', 'bankFile', 'glMapping', 'glReports'],
      payrunState: statusCheck.status,
      allowInputs: isCotUser,
    })

  if (documentTypesToExcludeForFuturePayruns) {
    shouldRunIsCotUserExcludes = false
    documentTypesToExclude = documentTypesToExclude.concat(documentTypesToExcludeForFuturePayruns)
  }

  // const documentTypes = setDocumentTypes(getDocumentTypes(state), documentTypesToExclude, shouldRunIsCotUserExcludes, isCotBotUser)
  const documentTypes = setDocumentTypes(
    getDocumentTypes(state),
    documentTypesToExclude,
    shouldRunIsCotUserExcludes,
    isCotBotUser,
    isCotOrEngineerOrAdmin,
    cotUserSpecialRights
  )

  const selectedTypesArray = document.documentTypes && [...document.documentTypes]
  const notAllowedTypes = [docTypeIDs.GTN, docTypeIDs.PAYSLIPS, docTypeIDs.TAX_DOCUMENTS]
  const documenTypeCannotBeChanged = selectedTypesArray?.length === 1 && notAllowedTypes.includes(selectedTypesArray.shift())

  return {
    isCot: isCotUser,
    documentTypes,
    showSubmitBtn,
    initialValues: {
      description: document.description,
      documentTypes: document.documentTypes,
      version: document.version,
      ...(isCotUser && { readyForIntegration: document.readyForIntegration }),
    },
    documenTypeCannotBeChanged,
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'documentEdit',
  })(DocumentClassificationFormEdit)
)

export default Fetcher(Container, ['documentTypes', createDocumentFilter({ filterNames: ['id'] })])
