import React from 'react'
import PropTypes from 'prop-types'
const classNames = require('classnames')

const AccordionExtender = ({ headerColor, toggleExpanded, dataTestId, expanded }) => {
  const getArrowClasses = () => {
    return classNames({
      'icon--arrow u-cursor--pointer u-float--left u-margin-left-tiny u-padding-top accordion-expander': true,
      'icon--arrow--white' : headerColor === 'minsk',
      'active': expanded
    })
  }
  return (
    <div className='accordion-extender u-text--small u-cursor--pointer u-float--right u-relative'>
      <span
        className={getArrowClasses()}
        onClick={toggleExpanded}
        data-testid={`arrow-${dataTestId || 'button'}`} />
    </div>
  )
}

AccordionExtender.propTypes = {
  dataTestId: PropTypes.string,
  expanded: PropTypes.bool,
  headerColor: PropTypes.string,
  toggleExpanded: PropTypes.func
}

export default AccordionExtender
