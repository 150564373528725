import createSelector from 'utils/createSelector'
import { employeeFieldsConfig } from 'redux/config/employeeFieldsConfig'
import moment from 'moment'

const getFilteredIds = (state, props) => state.companyDataProtectionFieldState.filteredIds

/**
 * Get all company DPF for a particular revision
 */
export const getCompanyDataProtectionFieldStates = createSelector(getFilteredIds,
  ({ CompanyDataProtectionFieldState }, filteredIds) => {
    return CompanyDataProtectionFieldState.filter(state => filteredIds.includes(state.id))
      .toModelArray()
      .map(state => {
        return {
          ...state.countryDataProtectionField.ref,
          dataProtectionField: state.countryDataProtectionField.dataProtectionField.ref,
          companyDataProtectionFieldRevision: {
            ...state.companyDataProtectionFieldRevision.ref,
            modifiedBy: state.companyDataProtectionFieldRevision.modifiedBy ? { ...state.companyDataProtectionFieldRevision.modifiedBy.ref } : null
          },
          isApproved: state.isApproved,
          isSensitive: state.isSensitive
        }
      })
  })

/**
 * Structure all company DPF for a particular revision into categories
 */
export const getCompanyDataProtectionFieldStatesWithCategories = createSelector(getCompanyDataProtectionFieldStates,
  (session, states) => {
    let fields = []
    employeeFieldsConfig.map(cat => {
      fields.push({
        category: cat.category,
        categoryName: cat.categoryName,
        fields: states.filter(field => {
          if (field.dataProtectionField.entityClass.includes('EmployeeUser')) {
            return cat.fields.find(f => f.field === field.dataProtectionField.entityField)
          }
          if (field.dataProtectionField.entityClass.includes('TermCategory')) {
            return cat.fields.find(f => f.field === field.dataProtectionField.fieldValue)
          }
        })
          .map(field => {
            let name
            if (field.dataProtectionField.entityClass.includes('EmployeeUser')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.entityField).name
            }
            if (field.dataProtectionField.entityClass.includes('TermCategory')) {
              name = cat.fields.find(f => f.field === field.dataProtectionField.fieldValue).name
            }
            return {
              ...field,
              entityField: field.dataProtectionField.entityField,
              name
            }
          })
      })
    })

    return fields
  }
)

/**
 * Get initialValues for company DPFs for a particular revision
 */
export const getFieldsStateInitialValues = createSelector(getCompanyDataProtectionFieldStates,
  (session, fields) => {
    let initialValues = {}

    fields.map(field => {
      initialValues[`id-${field.id}`] = field.isApproved
    })

    return initialValues
  })

/**
 * Get the date and author of a particular revision
 */
export const getApprovalUserDate = createSelector(getCompanyDataProtectionFieldStates,
  (session, states) => {
    // get only first element from approvedFields
    const [firstFieldsState] = states
    const hasApprovedFields = states.length
    return {
      hasApprovedFields,
      modifiedAt: hasApprovedFields && moment(firstFieldsState.companyDataProtectionFieldRevision.modifiedAt.date).format('DD/MM/YYYY'),
      // eslint-disable-next-line max-len
      modifiedBy: (hasApprovedFields && firstFieldsState.companyDataProtectionFieldRevision.modifiedBy) && firstFieldsState.companyDataProtectionFieldRevision.modifiedBy.fullname
    }
  })
