import createSelector from 'utils/createSelector'
import { getAlphabeticName } from 'redux/models/payrollStep'
import orderBy from 'lodash/orderBy'

const getPayrollStepId = (state, props) => parseInt(props.payrollStepId)
const getPayrollProcessId = (state, props) => parseInt(props.payrollProcessId)
const getStepName = (state, props) => props.name
const getStepPosition = (state, props) => props.position
const getShouldIncludeLastOption = (state, props) => props.shouldIncludeLastOption
const getShouldUseIdAsValue = (state, props) => props.shouldUseIdAsValue
const getShouldUseShortName = (state, props) => props.shouldUseShortName

export const getPayrollStepById = createSelector(getPayrollStepId,
  ({ PayrollStep }, id) => PayrollStep.withId(id).ref
)

export const getPayrollStepsByPayrollProcessId = createSelector(getPayrollProcessId,
  ({ PayrollStep }, payrollProcess) => PayrollStep
    .filter({ payrollProcess })
    .orderBy('position')
    .toModelArray()
    .map(step => ({
      ...step.ref,
      alphabeticName: `${step.getAlphabeticName()}. ${step.name}`,
      alphabeticShortName: step.getAlphabeticName(),
    }))
)

// Here we convert Steps as Dropdown position options and add 'Last' position option
export const getPayrollStepsOptionsByPayrollProcessId = createSelector(
  getPayrollStepsByPayrollProcessId, getShouldIncludeLastOption, getShouldUseIdAsValue, getShouldUseShortName,
  (session, steps, shouldIncludeLastOption, shouldUseIdAsValue, shouldUseShortName) => [
    ...steps.map(({ alphabeticShortName, alphabeticName, position, id }) => ({
      value: shouldUseIdAsValue ? id : position,
      label: shouldUseShortName ? alphabeticShortName : alphabeticName
    })),
    ...shouldIncludeLastOption ? [{
      // Here we send such a big number, not a real use-case, in order the newly created Step to be the Last one.
      // Later, after the new Step is created, the BE will update the position with the real index.
      value: 1000,
      label: 'Last'
    }] : []
  ]
)

/**
 * Insert a new Step, in an ordered list of Steps and return all the Steps ordered by position.
 *
 * Also if we pass an `id` param, it means we're changing the position of already created Step.
 */
export const getReorderedPayrollStepsByPayrollProcessId = createSelector(
  getPayrollStepsByPayrollProcessId, getPayrollStepId, getStepName, getStepPosition,
  (session, ...rest) => insertItemInOrderedList(...rest))

/**
 * Get alphabetic name from an object
 *
 * @param {Object} item
 * @param {Number} i
 * @returns {string}
 * @private
 */
const _getAlphabeticName = (item, i) => `${getAlphabeticName(i)}. ${item.name}`

/**
 * Insert a new Item, in an ordered list of Items and return all the Items ordered by position.
 *
 * Also if we pass an `id` param, it means we're changing the position of already created Item.
 *
 * @param {Array.<{ id: Number, position: Number }>} items
 * @param {Number} id - if we have an `id` param, it means we're changing the position of already created Item.
 * Otherwise, having only `name` and `position` we're inserting a new Item.
 * @param {String} name - the new Item name
 * @param {Number} position - the new Item position
 * @param {Func} setAlphabeticName - a function for setting `alphabeticName` field
 *
 * @return {Array.< id: Number, position: Number, isModified: true, alphabeticName: String >}
 */
export const insertItemInOrderedList = (items, id, name, position, setAlphabeticName = _getAlphabeticName) => {
  if (!name || position === null) return items

  // Having an `id`? Now, we have to change the position of already created Item.
  if (id) {
    // Find the Item current position
    const { position } = items.find(item => item.id === id)

    items = items
      .filter(item => item.id !== id) // Remove the Item (later we'll insert it with the new position)
      .map(item => {
        // As we removed the Item, we have to update the position to all the Items,
        // these are positioned after the removed Item
        if (item.position > position) {
          return { ...item, position: item.position - 1 }
        }

        return item
      })
  }

  // Order the Items by position, taking in the account the new Item (or the edited one).
  // We put the new Item (`{ name, position }`) before the `items` intentionally,
  // because in the case we have the same position twice, we want to prioritize the new Item.
  // Example: If we have already created Item on position 2, and we add a new Item on position 2,
  // then the first Item will have position 3, and the new Item will have position 2.
  let ordered = orderBy([ { name, position, isModified: true }, ...items ], 'position')

  // We have to recalculate `alphabeticName`, because of the newly inserted Item.
  return ordered.map((item, i) => ({ ...item, alphabeticName: setAlphabeticName(item, i) }))
}
