import createSelector from 'utils/createSelector'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

const getReportId = (state, props) => parseInt(props.reportId, 10)

const getReportingCurrency = (reportModel, Payroll, Currency) => {
  let reportingCurrency = []
  const report = reportModel.ref
  if (!isEmpty(report.namedParams) && report.namedParams.fxRate) {
    reportingCurrency = report.namedParams.fxRate
  } else if (isEmpty(report.namedParams) && !isEmpty(report.filters.payroll)) {
    const payrollIds = report.filters.payroll

    payrollIds.map((payrollId) => {
      const payroll = Payroll.filter((payroll) => payroll.id === payrollId).count() && Payroll.withId(payrollId)

      if (payroll) reportingCurrency.push(Currency.withId(payroll.currency.id).abbreviature)
    })
  }

  return [...new Set(reportingCurrency)]
}

export const getReportDetailsById = createSelector(getReportId, ({ ReportData }, reportId) => {
  const report = ReportData.withId(reportId)
  return {
    ...report.ref,
  }
})

export const getReportDetailsByIdWithFilters = createSelector(
  getReportId,
  ({ ReportData, Country, Company, Payroll, EmployeeSystemUser, Category, SubCategory, Term, Currency }, reportId) => {
    const report = ReportData.withId(reportId)
    return {
      ...report.ref,
      reportingCurrency: getReportingCurrency(report, Payroll, Currency),
      filters: {
        ...report.filters,
        countryNames: isArray(report.filters.country) ? report.filters.country.map((country) => Country.withId(country).name) : [],
        companyNames: isArray(report.filters.company) ? report.filters.company.map((company) => Company.withId(company).name) : [],
        payrollNames: report.namedFilters.payroll || [],
        payrollInstanceNames: report.namedFilters.payrollInstance || [],
        businessUnitNames: report.namedFilters.businessUnit || [],
        costCenterNames: report.namedFilters.costCenter || [],
        departmentNames: report.namedFilters.department || [],
        processOwnerNames: isArray(report.filters.processOwner) ? report.filters.processOwner.map((owner) => EmployeeSystemUser.withId(owner)?.getName()) : [],
        termCategoryNames: isArray(report.filters.termCategory) ? report.filters.termCategory.map((cat) => Category.withId(cat).name) : [],
        termSubCategoryNames: isArray(report.filters.termSubcategory) ? report.filters.termSubcategory.map((subCat) => SubCategory.withId(subCat).name) : [],
        termNames: isArray(report.filters.term) ? report.filters.term.map((term) => Term.withId(term).name) : [],
        employmentStatus: report.namedFilters.employmentStatus || [],
        modifiedBy: report.namedFilters.modifiedBy || [],
      },
    }
  }
)
