import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import VendorForm from '../../../../../components/VendorForm'
import { getVendor } from 'redux/selectors/vendor'
import { isFetching } from 'utils/redux/fetching'
import { getVendors } from 'redux/selectors/vendors'
import { updateVendor } from 'redux/actions/vendors'
import { getCountries } from 'redux/selectors/country'

class VendorFormEditContainer extends React.Component {
  render () {
    return <VendorForm {...this.props} />
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const checkValue = (val) => val !== '' ? val : false

  return {
    onSubmit: data =>
      dispatch(updateVendor(
        {
          ...data,
          dataProtection: checkValue(data.dataProtection),
          professionalIndemnity: checkValue(data.professionalIndemnity)
        },
        data.id
      )).then(props.onSubmit),
  }
}

const mapStateToProps = (state, props) => {
  if (isFetching([
    state.vendors
  ])) return { isFetching: true }

  const vendor = getVendor(state, { vendorId: props.match.params.vendorId })
  const statusOptions = [
    { value: 'Initial Approach', label: 'Initial Approach' },
    { value: 'Under Discussion', label: 'Under Discussion' },
    { value: 'Contract Signed', label: 'Contract Signed' },
    { value: 'Contract Denied', label: 'Contract Denied' },
    { value: 'Contract Rejected', label: 'Contract Rejected' },
  ]

  return {
    isEdit: true,
    countries: getCountries(state),
    vendors: getVendors(state),
    statusOptions,
    initialValues: {
      ...vendor,
      name: vendor.name ? vendor.name : null,
      status: vendor.status ? vendor.status : null,
      registrationNumber: vendor.registrationNumber ? vendor.registrationNumber : null,
      address: vendor.address ? vendor.address : null,
      registrationAddress: vendor.registrationAddress ? vendor.registrationAddress : null,
      website: vendor.website ? vendor.website : null,
      hqCountry: vendor.hqCountry.id ? vendor.hqCountry.id : null,
      holidaysCalendar: vendor.holidaysCalendar ? vendor.holidaysCalendar : null,
      payrollSoftware: vendor.payrollSoftware ? vendor.payrollSoftware : null,
      dataProtection: vendor.dataProtection,
      professionalIndemnity : vendor.professionalIndemnity
    }
  }
}

VendorFormEditContainer.propTypes = {
  onSubmit: PropTypes.func
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'vendorEdit',
})(VendorFormEditContainer))

export default Fetcher(Container, ['vendors', 'countries'])
