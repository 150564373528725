import React from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { fetchStepsIfNeeded, createStep } from 'redux/actions/steps'
import { fetchProcessesIfNeeded, invalidateProcesses } from 'redux/actions/processes'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { getServices } from 'redux/selectors/store/services'
import { getFilteredProcesses } from '../selectors/processes'
import { getSteps } from '../selectors/steps'
import Steps from '../components/Steps'
import StepCreate from '../components/StepCreate'

class StepsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { fetchProcessesIfNeeded, fetchStepsIfNeeded, fetchServicesIfNeeded } = this.props
    fetchProcessesIfNeeded()
    fetchStepsIfNeeded()
    fetchServicesIfNeeded()
  }

  render () {
    return <div>
      <StepCreate {...this.props} />
      <Steps {...this.props} />
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProcessesIfNeeded: () => dispatch(fetchProcessesIfNeeded()),
  fetchStepsIfNeeded: () => dispatch(fetchStepsIfNeeded()),
  fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
  onSubmit: data => dispatch(createStep(data)).then(() => {
    dispatch(invalidateProcesses())
    dispatch(reset('step'))
  })
})

const mapStateToProps = (state) => ({
  services: getServices(state),
  processes: getFilteredProcesses(state),
  steps: getSteps(state),
  isFetching : state.processes.isFetching || state.steps.isFetching || state.services.isFetching
})

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:
 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })
 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

export default connect(mapStateToProps, mapDispatchToProps)(StepsContainer)
