import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { doesStringContainValue } from 'utils/query'
import { ALL_ROUTES } from 'configs/routes'
import { payrollSummaryTitles } from 'utils/locales/payrollSummary.en'
import { reconciliationTitles } from 'utils/locales/reconciliation.en'
import { payrunTitles } from 'utils/locales/payruns.en'
import { fetchPayrollInstances } from 'redux/actions/payrollInstances'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import InnerWrapperTabs from 'layouts/InnerWrapperTabs'
import { createFilter } from 'utils/redux/filter'

export const InnerWrapperContainer = (props) => {
  const dispatch = useDispatch()
  const payrollInstanceState = useSelector((state) => state.payrollInstances.filters['previousWithExtraTasksLimitCheck'])
  const showReconciliation = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state))

  const showExtraPayrunsTab = payrollInstanceState?.ids?.length > 0

  useEffect(() => {
    dispatch(
      fetchPayrollInstances({
        filter: createFilter(
          {
            payrunState: 'previousWithExtraTasks',
            limit: 1,
            sort: [
              { name: 'country', order: 'asc' },
              { name: 'name', order: 'asc' },
              { name: 'fromDate', order: 'desc' },
            ],
          },
          'previousWithExtraTasksLimitCheck'
        ),
      })
    )
  }, [])

  const renderTabs = () => {
    if (
      [ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION, ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION, ALL_ROUTES.PAYRUNS.PAYROLL_SUMMARY].some((v) =>
        doesStringContainValue(props.location.pathname, v)
      )
    ) {
      return [
        { name: payrollSummaryTitles.payrollSummary, to: ALL_ROUTES.PAYRUNS.PAYROLL_SUMMARY },
        showReconciliation && {
          name: reconciliationTitles.payrollReconciliation,
          to: ALL_ROUTES.PAYRUNS.PAYROLL_RECONCILIATION,
        },
        showReconciliation && {
          name: reconciliationTitles.elementReconciliation,
          to: ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION,
        },
      ]
    }
    return [
      { name: payrunTitles.prePayrollSteps, to: `/payruns/active` },
      showExtraPayrunsTab && { name: payrunTitles.postPayrollSteps, to: `/payruns/extra` },
      { name: payrunTitles.closedPayruns, to: `/payruns/previous` },
      { name: payrunTitles.futurePayruns, to: `/payruns/inactive` },
    ]
  }
  return <InnerWrapperTabs tabs={renderTabs()} {...props} />
}

InnerWrapperContainer.propTypes = {
  showExtraPayrunsTab: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  props: PropTypes.object,
}

export default InnerWrapperContainer
