import React from 'react'
import EmployeeSearchListContainer from '../containers/EmployeeSearchListContainer'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import InnerWrapper from 'layouts/InnerWrapper'
import SectionHeading from 'components/SectionHeading'
import useQuery from 'hooks/useQuery'

export default props => {
  let query = useQuery()
  return <InnerWrapper>
    <SectionHeading text='Employees' />
    <EmployeesSearchBarContainer showAddBtn enablePagination {...props} query={query} />
    <EmployeeSearchListContainer enablePagination {...props} query={query} />
  </InnerWrapper>
}
