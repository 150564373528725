import AuthorizedRoute from 'containers/AuthorizedRoute'
import React, { useEffect } from 'react'
import { Route, useParams, useLocation, useHistory } from 'react-router-dom'
import LoadableErrorBoundary from './LoadableErrorBoundary'
import { useDispatch } from 'react-redux'
import { clearAllBreadcrumbFilterByKey, pushBreadcrumb } from 'redux/actions/breadcrumbs'

const componentRoute = (history, location, match, params, rest, route) => (
  <LoadableErrorBoundary><route.indexRoute.component {...rest}
    params={params}
    location={location}
    match={match}
    history={history}
    exact={route.exact ? route.exact : false}
    childRoutes={route.childRoutes} /></LoadableErrorBoundary>
)

const WithAuthWrapper = (route) => {
  let params = useParams()
  let location = useLocation()
  let history = useHistory()
  let dispatch = useDispatch()
  useEffect(() => {
    dispatch(pushBreadcrumb({ location, route }))
    // For right now, we are only saving this on employees
    // And product requirement is that if you navigate out of the '/employees' route (ie go to /companies)
    // The stashed filters need to be wiped
    if (location.pathname.split('/')[1] !== 'employees') {
      dispatch(clearAllBreadcrumbFilterByKey({ type: 'employees' }))
    }
  }, [])
  return (
    <Route
      path={route.path}
      exact={route.exact || false}
      children={({ match, rest }) => (
        <AuthorizedRoute history={history}>
          <route.wrappedComponent match={match} history={history} {...rest}>
            {route.indexRoute.wrappedComponent
              ? <route.indexRoute.wrappedComponent history={history} match={match} {...rest}>
                { componentRoute(history, location, match, params, rest, route) }
              </route.indexRoute.wrappedComponent>
              : componentRoute(history, location, match, params, rest, route)
            }
          </route.wrappedComponent>
        </AuthorizedRoute>
      )}
    />
  )
}

const WithNoAuthWrapper = (route) => {
  let params = useParams()
  let location = useLocation()
  let history = useHistory()
  return (
    <Route
      exact={route.exact || false}
      key={route.path}
      path={route.path}
      children={({ match, rest }) => (
        componentRoute(history, location, match, params, rest, route)
      )}
    />
  )
}
export default function RouteWithSubRoutes (route) {
  return (
    <React.Fragment>
      {route.authProtected ? WithAuthWrapper(route) : WithNoAuthWrapper(route)}
    </React.Fragment>
  )
}
