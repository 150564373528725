import createSelector from 'utils/createSelector'

const getCompanyId = (state, props) => props.companyId

export const getDataProtectionPolicy = createSelector(getCompanyId,
  ({ Company, DataOwner }, companyId) => {
    const company = Company.withId(companyId)
    return {
      retentionPeriod: company.ref.retentionPeriod,
      process: company.ref.process,
      trigger: company.ref.trigger,
      dataOwners: company.ref.dataOwners.map(dataOwner => {
        let owner = DataOwner.withId(dataOwner).employee
        return owner.position ? `${owner.fullname} (${owner.position})` : `${owner.fullname}`
      }),
      dataOwnersIds: company.ref.dataOwners.map(dataOwner => DataOwner.withId(dataOwner).employee.id)
    }
  }
)
