/**
 * Tasks identifiers,
 * these are referenced in the code, are listed here.
 *
 * In that way we prevent using magic numbers
 * and also we can change the values below easily.
 *
 * Please note that some of the identifiers listed here, were removed in the new Payruns, but we still kept them,
 * because for the old Payruns their related logic is still relevant and we should keep it for legacy reasons.
 */
export const tasksIdentifiers = {
  joinersLeavers: {
    code: 'joiners-leavers',
    name: 'Joiners & leavers'
  },
  permanentUpdates: {
    code: 'permanent-updates',
    name: 'Permanent updates'
  },
  taUpdates: {
    code: 'time-and-attendance-updates',
    name: 'T & A updates'
  },
  temporaryPayUpdates: {
    code: 'temporary-pay-updates',
    name: 'Temporary pay updates'
  },
  approveAllUpdatesSendForCalculations: {
    code: 'approve-all-updates-and-send-for-calculations',
    name: 'Approve all updates and send for calculations'
  },
  cotPrePayrollPartnerReview: {
    code: 'cot-pre-payroll-partner-review',
    name: 'Payslip pre-payroll partner review'
  },
  ppRun: {
    code: 'pp-run',
    name: 'GTN Version 1'
  },
  cotRunReview: {
    code: 'cot-run-review',
    name: 'GTN Version 1 Import'
  },
  companyRunReview: {
    code: 'company-run-review',
    name: 'GTN Version 1 Review'
  },
  grossToNetCalculations: {
    code: 'gross-to-net-calculations',
    name: 'Gross to net calculations'
  },
  cotGrossToNetReview: {
    code: 'cot-gross-to-net-review',
    name: 'Payslip gross to net review'
  },
  grossToNetReview: {
    code: 'gross-to-net-review',
    name: 'Gross to net review'
  },
  grossToNetAuthorization: {
    code: 'gross-to-net-authorization',
    name: 'Gross to net authorization'
  },
  finalPayrollReportsProvision: {
    code: 'final-payroll-reports-provision',
    name: 'Final Payroll Reports Provision'
  },
  cotFinalPayrollReportsReview: {
    code: 'cot-final-payroll-reports-review',
    name: 'Final Payroll Reports Import'
  },
  finalPayrollReportsReview: {
    code: 'final-payroll-reports-review',
    name: 'Final Payroll Reports Review'
  },
  finalPayrollReportsAuthorization: {
    code: 'final-payroll-reports-authorization',
    name: 'Final Payroll Reports Authorization'
  },
  arrangeForPayment: {
    code: 'arrange-for-payment',
    name: 'Arrange for Payment'
  },
  sendForPayment: {
    code: 'send-for-payment',
    name: 'Send for payment'
  },
  payslipProductionUpload: {
    code: 'payslip-production-or-upload',
    name: 'Payslip production or upload'
  }
}

// List tasks' identifiers, these are available for creating new tasks.
// For example: some identifiers aren't available more, because we were using them in old Payruns before,
// but for business reasons, they are no more available for the new Payruns.
export const creatableIdentifiers = ['joinersLeavers', 'permanentUpdates', 'taUpdates', 'temporaryPayUpdates',
  'approveAllUpdatesSendForCalculations', 'cotPrePayrollPartnerReview',
  'ppRun', 'cotRunReview', 'companyRunReview', 'finalPayrollReportsProvision', 'cotFinalPayrollReportsReview',
  'finalPayrollReportsReview', 'finalPayrollReportsAuthorization', 'arrangeForPayment',
  'sendForPayment', 'payslipProductionUpload'
]
