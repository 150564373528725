import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryProcessPivot'

class CountryProcess extends CRUDModel {}
CountryProcess.modelName = 'CountryProcess'
CountryProcess.actionTypes = actionTypes

CountryProcess.fields = {
  id: attr(),
  name: attr(),
  process: fk('Process', 'countryProcesses'),
  countryService: fk('CountryService', 'countryProcesses')
}

export default CountryProcess
