import { ActionUtility } from 'utils/redux'
import errorsHandling from '../../utils/redux/actions/errorsHandling'
import split from 'lodash/split'
import pickBy from 'lodash/pickBy'

// ------------------------------------
// Constants
// ------------------------------------
export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH'
export const NOTIFICATIONS_RECEIVE = 'NOTIFICATIONS_RECEIVE'
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE'
export const NOTIFICATIONS_INVALIDATE = 'NOTIFICATIONS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: NOTIFICATIONS_FETCH,
  receive: NOTIFICATIONS_RECEIVE,
  update: NOTIFICATIONS_UPDATE,
  invalidate: NOTIFICATIONS_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'notifications', 'notifications', 'Notification')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchNotificationsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchNotifications = actionUtility.fetchEntities
export const updateNotification = actionUtility.updateEntity

export const markAsRead = (data, shouldFetch = true, shouldInvalidate = false) => {
  const sanitized = { isRead : true }
  return dispatch => {
    return dispatch(updateNotification(sanitized, data.id, shouldFetch, shouldInvalidate))
  }
}

export const notifyUsers = (entity, objectId, objectType) => {
  // Get all checked fields
  const checkedFields = pickBy(entity, value => value === true)

  // Get their keys
  const checkedKeys = Object.keys(checkedFields)

  // Build data users
  const dataUsers = checkedKeys.map(key => {
    // Split the keys in two parts
    const [ userTenant, userId ] = split(key, '-', 2)

    return {
      userId: parseInt(userId, 10),
      userTenant,
    }
  })

  // Build the actual data to share this document to selected users
  const data = {
    data: dataUsers,
    params: { objectId, objectType }
  }

  return (dispatch, getState, { api }) => {
    return api.post('notification/notifyusers', { payload: data })
      .then(errorsHandling.handleFormErrors)
  }
}
