import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadTemplateUpdate, download } from 'redux/actions/employeeSystemUsers'
import SectionHeading from 'components/SectionHeading'
import employeeStatusEnum from 'utils/enums/employmentStatuses'
import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { commonLabels, commonDescriptions } from 'utils/locales/employeeBulkCommon.en'
import { dataValidationsLabels, dataValidationsDescriptions } from 'utils/locales/employeeBulkUpdate.en'
import { createNewEmployeesBulkImportJob } from 'redux/actions/pollingJob'

const TemplateClarifications = () => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading text='Update employees’ Personal and Job & Org Data in bulk' />
    <p className='u-margin-bottom-small'>
      With this template you can update employees’ Personal and Job & Org Data in bulk. The generated template will include only employees present in the active
      payrun.
    </p>
    <ul className='u-margin-bottom-small'>
      <li>Select company and payroll and download template</li>
      <li>Complete data</li>
      <li>
        Columns A-H <b>cannot be updated</b> - informational purposes only
      </li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
    </ul>
    <u>Error message</u>: This happens when there are errors in the upload file. You can view details on the import page. Fix the errors (you can work both with
    original or error file) and attempt the upload again.
  </div>
)

export default (props) => (
  <ImportFacade
    {...props}
    heading='Import employees in bulk'
    entityName='employees'
    downloadAction={download}
    TemplateClarifications={TemplateClarifications}
    downloadTemplateAction={({ payrollId, companyId, shouldUseCompanyAsFilter }) => {
      if (!shouldUseCompanyAsFilter) companyId = null

      return downloadTemplateUpdate(payrollId, companyId)
    }}
    importAction={({ companyId, file }) => {
      return createNewEmployeesBulkImportJob(
        {
          company: companyId,
          entityId: companyId,
        },
        file
      )
    }}
    shouldIncludeAllPayrollsOption
    isAsync
  >
    <div className='o-layout'>
      <div className='o-layout__item u-1/1 u-text--normal'>
        <SectionHeading text='Data validations (all are case-insensitive):' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.dateFormat}</span> {commonDescriptions.dateFormat}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.orgUnit}</span> {commonDescriptions.orgUnit}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.title}</span> - {titleTypes.map((t) => t.label).join(', ')}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.maritalStatus}</span> {commonDescriptions.maritalStatus}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.email}</span> {commonDescriptions.email}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.mobilePhone}</span> {commonDescriptions.mobilePhone}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.typeOfEmployment}</span> - {employeeStatusEnum.ALL_IMPORT_LABELS.join(', ')}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.visaWorkPermit}</span> {commonDescriptions.visaWorkPermit}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.employeeSelfService}</span> {dataValidationsDescriptions.employeeSelfService}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.mode2FA}</span> {dataValidationsDescriptions.mode2FA}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.paymentType}</span> {commonDescriptions.paymentType}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.fullTimePartTime}</span> {commonDescriptions.fullTimePartTime}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.nationalInsuranceLetter}</span> {commonDescriptions.nationalInsuranceLetter}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.otherAddressFields}</span> {commonDescriptions.otherAddressFields}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.typeOfAddress}</span> {commonDescriptions.typeOfAddress}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.countryOfBirth}</span> {commonDescriptions.countryOfBirth}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{commonLabels.endDate}</span> {commonDescriptions.endDate}
        </p>
      </div>
    </div>
  </ImportFacade>
)
