import { isEmpty } from './typeChecks'

/**
 * Calculates the average of a list of numbers or object values.
 *
 * @param {Array<number|Object>} list - The list of numbers or objects to average. If the list contains objects,
 * specify the key to use in the `predicate` parameter.
 * @param {string} [predicate] - An optional key to access a specific value within each object in the list.
 * If not provided, the list is expected to contain numbers.
 * @param {number} [fixed] - An optional number of decimal places to which the result should be fixed.
 * If not provided, the result will not be fixed.
 * @returns {number} The average of the list, or 0 if the list is empty.
 */
export const calcAverage = (list = [], predicate = undefined, fixed = undefined) => {
  if (isEmpty(list)) return 0
  const sum = list.reduce((acc, item) => {
    const value = predicate ? item[predicate] : item
    return acc + value
  }, 0)
  const result = sum / list.length
  return fixed ? result.toFixed(fixed) : result
}

/**
 * Calculates the average of a list of percentages.
 *
 * @param {Array<string|Object>} list - The list of percentages as strings (e.g., "20%", "5%") or objects containing
 * percentage values. If the list contains objects, specify the key to use in the `predicate` parameter.
 * @param {string} [predicate] - An optional key to access a specific percentage value within each object in the list.
 * If not provided, the list is expected to contain percentage strings.
 * @param {number} [fixed] - An optional number of decimal places to which the result should be fixed.
 * If not provided, the result will not be fixed.
 * @returns {string} The average percentage as a string (e.g., "12%"),
 * rounded to the nearest integer if `fixed` is not specified.
 */
export const calcPercentageAverage = (list = [], predicate = undefined, fixed = undefined) => {
  if (isEmpty(list)) return '0%'
  const values = list.map((item) => {
    const value = predicate ? item[predicate] : item
    return parseFloat(value) / 100
  })
  const average = calcAverage(values) * 100
  return `${fixed ? average.toFixed(fixed) : average}%`
}
