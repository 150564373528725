import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/emptyPayfiles'

class EmptyPayfile extends CRUDModel {}
EmptyPayfile.modelName = 'EmptyPayfile'

EmptyPayfile.fields = {
  id: attr(),
}
EmptyPayfile.actionTypes = actionTypes

export default EmptyPayfile
