export const requiredFieldsLabels = {
  fromCurrency: 'From Currency',
  toCurrency: 'To Currency',
  rate: 'Rate',
}

export const requiredFieldsDesc = {
  currency: '- Should be the ISO 4217 code, e.g. EUR, USD, GBP, etc.',
  rate: '- Should be decimal number, with a maximum of 6 digits after the decimal point',
}

export const dataValidationsLabels = {
  date: 'Date',
}

export const dataValidationsDesc = {
  date: "- DD/MM/YYYY. This should be the real value in the cell, doesn't matter if Excel shows it differently (for example DD.MM.YYYY)",
}
