import createSelector from 'utils/createSelector'

export const getCategories = createSelector(({ Category }) => Category.all().toRefArray())

export const getCategoriesInPayTaxes = createSelector(
  ({ Category }) => {
    return Category
      .filter(category => category.showOnPayTaxes)
      .toRefArray()
      .slice()
      .sort((a, b) => a.payTaxesOrder - b.payTaxesOrder)
  })
