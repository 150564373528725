import React from 'react'
import Modal from 'components/Modal'
import CompanyDetails from './CompanyDetails'
import VendorFormEditContainer from '../containers/VendorFormEditContainer'
class CompanyDetailsView extends React.Component {
  showModal (modalRef) {
    modalRef.showModal()
  }

  render () {
    return <div>
      <Modal
        ref='editModal'
        className='c-modal c-modal--full c-modal--overflow-y'
        modalHeading='Edit Vendor'
        data-testid='vendor-edit-modal'>

        <VendorFormEditContainer
          onSubmit={() => this.refs.editModal
            ? this.refs.editModal.hideModal()
            : null}
          {...this.props}
        />
      </Modal>

      <CompanyDetails
        onEdit={() => this.refs.editModal.showModal()}
        {...this.props}
      />
    </div>
  }
}

export default CompanyDetailsView
