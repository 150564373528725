import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getCurrencies } from 'redux/selectors/currencies'
import { getFxRates } from 'redux/selectors/fxRates'
import { isArray } from 'lodash'
import FiltersGlobalPayrunManagementReport from 'routes/Reporting/components/FiltersGlobalPayrunManagementReport'
import Fetcher from 'containers/Fetcher'
import { sortByName } from 'utils/strings'

const mapStateToProps = (state, props) => {
  const { countries, companies, currencies, fxRates, accessAreaPivot } = state
  if (isFetching([countries, companies, currencies, fxRates, accessAreaPivot])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'subcategory',
    'showOnlyTotals',
    'fromDate',
    'toDate',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'payrollCurrency',
    'reportingCurrency',
    'reportingRate',
    'employmentStatus'
  )

  const allCurrencies = getCurrencies(state)

  return {
    // All selected values
    selected: values,
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, {
        countriesIds: isArray(values.country) ? values.country : [values.country],
      }).sort(sortByName)
      : getCompaniesByAuth(state, props).sort(sortByName),
    // All currencies
    currencies: allCurrencies,
    fxRates: getFxRates(state),
  }
}

const Container = connect(mapStateToProps)(FiltersGlobalPayrunManagementReport)

export default Fetcher(Container, ['countries', 'companies', 'currencies', 'accessAreaPivot', 'fxRates'], { wrapperDivClassName: 'o-block--inline' })
