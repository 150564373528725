import { isEmpty } from 'utils/fnkit/typeChecks'

const reportingPermissionPrefix = {
  section: 'REPORTING_SECTION_',
  report: 'REPORTING_',
}

/**
 * Extracts and processes reporting section permissions from a given permissions array.
 *
 * This function filters the input array to include only those permissions that start
 * with a predefined reporting section prefix. After filtering, it removes the prefix
 * from each matching permission and returns the updated array.
 *
 * @function
 * @param {string[]} [permissions=[]] - An optional array of permission strings to process. Defaults to an empty array if not provided.
 * @returns {string[]} An array of permissions with the reporting section prefix removed.
 */
export const getAllowedReportingSections = (permissions = []) =>
  permissions
    .filter((permission) => permission?.startsWith(reportingPermissionPrefix.section))
    .map((permission) => permission?.replace(reportingPermissionPrefix.section, ''))

/**
 * Determines whether a user has the required permission to access the specific report value
 * within a given report section. If no specific permissions are defined for the report section,
 * it defaults to granting access.
 *
 * @param {string} reportSectionId - The identifier of the report section to check permissions for.
 * @param {string} reportValue - The specific report value to check permissions against.
 * @param {Array<string>} [permissions=[]] - An optional array of permission strings assigned to the user.
 * @returns {boolean} - Returns true if the user has permission for the report value; otherwise, false.
 */
export const hasReportPermissionValue = (reportSectionId, reportValue, permissions = []) => {
  const categoryReportPrefix = `${reportingPermissionPrefix.report}${reportSectionId}`
  const thereAreNotSpecificCategoryPermissions = !permissions.find((p) => p.startsWith(categoryReportPrefix))
  return thereAreNotSpecificCategoryPermissions ? true : permissions.includes(`${categoryReportPrefix}_${reportValue}`)
}

/**
 * Filters and returns the allowed report items based on given permissions.
 *
 * This function iterates through a list of section reports, filtering the reports within each section
 * to include only those that have the corresponding permission values as per the provided permissions array.
 * The filtering is applied specifically to the `reports` property within the `items` object of each section report.
 *
 * @param {Array} sectionReports - An array of section report objects. Each object contains a structure
 *                                 where `items.reports` represents a list of reports for the section.
 * @param {Array} permissions - An array of permission values to determine which reports are allowed.
 * @returns {Array} A new array of section report objects with filtered `reports` under `items` based on permissions.
 */
export const getAllowedReportItems = (sectionReports = [], permissions = []) =>
  sectionReports.map((reportSection) => {
    reportSection.items.reports = reportSection?.items?.reports?.filter((r) => hasReportPermissionValue(reportSection.id, r.VALUE, permissions)) || []
    return reportSection
  })

/**
 * Determines whether a specific report section should be hidden based on user permissions.
 *
 * @param {string} section - The name of the section to check visibility for.
 * @param {Array<string>} permissions - The list of permissions associated with the user.
 * @returns {boolean} - Returns true if the section should be hidden, false otherwise.
 *                      The section is hidden if the user has some allowed permissions
 *                      but the section is not included in the list of allowed reporting sections.
 */
export const hideReportSection = (section, permissions) => {
  const allowedPermissions = getAllowedReportingSections(permissions)
  const hasSectionPermissions = !isEmpty(allowedPermissions)
  return hasSectionPermissions && !allowedPermissions?.includes(section)
}
