import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import PayslipTable from 'components/table/PayslipTable'
import { getSummaryNotesByTotals } from 'redux/selectors/summaryNotes'
import { addExpandableSubRow } from '../../../../../components/table/cell/ExpandableCell'

export const PayrollTotalsContent = ({
  editableTableState,
  summaryNotes,
  isAllRowsExpanded,
  className,
  data,
  dataWithoutSubcategories,
  viewBy,
  tableHeadings,
  onUpdateGlobalData,
}) => {
  const onlyTotalsAreNeeded = viewBy.value === 'category'
  const [tableData, setTableData] = useState([])

  const editableOptions = {
    ...editableTableState,
    onUpdateGlobalData,
    needsValueUpdate: true,
  }

  useEffect(() => {
    const updatedTotalsData = onlyTotalsAreNeeded ? dataWithoutSubcategories : data
    const summaryNotesByTotals = getSummaryNotesByTotals(updatedTotalsData, summaryNotes)
    setTableData(
      addExpandableSubRow(summaryNotesByTotals, {
        idKey: 'itemKey',
        expandedTextKey: 'body',
        columnKey: 'body',
      })
    )
  }, [viewBy, data])

  return (
    <div className={`${className}`}>
      <PayslipTable
        editableOptions={editableOptions}
        expandableClass='row-expanded'
        hasAllRowsExpanded={isAllRowsExpanded}
        data={tableData}
        headings={tableHeadings}
        useCustomExpandedDisplay
        dynamicValues
      />
    </div>
  )
}

PayrollTotalsContent.propTypes = {
  isAllRowsExpanded: PropTypes.bool,
  className: PropTypes.string,
  summaryNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataWithoutSubcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewBy: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  editableTableState: PropTypes.shape({
    inGlobalEditMode: PropTypes.bool,
    fieldErrors: PropTypes.array,
    inAddMode: PropTypes.bool,
  }),
  tableHeadings: PropTypes.arrayOf(PropTypes.object).isRequired,
}
