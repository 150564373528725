import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { getUserPhone } from 'redux/actions/auth'
import UserActivationView from '../components/UserActivationView'
import PropTypes from 'prop-types'

/**
 * User Activation flow
 *
 * 1. First of all we have to make sure we always fetch the phone freshly,
 * independently of the fact do or don't have it already fetched.
 * That's required because the `auth` Store entity is being cached via `redux-persist`,
 * and if we don't fetch it freshly, and the user opens the activation link for second time,
 * then the COT changes to the `mobilePhone`  will not be refreshed here.
 *
 * 2. After we fetched the `mobilePhone`, we determine which action the user should perform.
 * - If the user has a `mobilePhone` value, then we show `ConfirmPhoneNumberContainer`
 * - If the user has not a `mobilePhone` value, then we show `ChangePhoneNumberContainer`
 *
 * 3. Once he confirms his `mobilePhone`, then he is redirected to enter his new password and
 * the activation code he received on his `mobilePhone` number.
 *
 * Important note. Please consider the fact that `getUserPhone` action,
 * doesn't reuse the `actionUtility` so its functionality are limited.
 * Because of this we can invoke the function only one time here,
 * otherwise (calling it in Change / Confirm) can result in a loop.
 * Therefore the three containers Change / Confirm / UserActivation,
 * are tightly coupled and should be used together, with starting point UserActivation.
 *
 * If we want to improve the reusability:
 * 1. we should add support to `getUserPhone` for `fetchIfNeeded()`
 * 2. we should stop caching `mobilePhone` field
 * 3. or add a flag in the other containers, to control should or should not we do a fetch
 */
class UserActivationContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch, match: { params: { token } } } = this.props

    dispatch(getUserPhone({ confirmationToken: token }))
  }

  render () {
    if (this.props.isFetching) return <div />

    return <UserActivationView {...this.props} />
  }
}

const mapStateToProps = ({ auth: { mobilePhone } }, props) => {
  if (isFetching([mobilePhone])) return { isFetching: true }

  return {
    action: mobilePhone.phone ? 'confirm' : 'change'
  }
}

UserActivationContainer.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps)(UserActivationContainer)
