import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const LANGUAGES_FETCH = 'LANGUAGES_FETCH'
export const LANGUAGES_RECEIVE = 'LANGUAGES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: LANGUAGES_FETCH,
  receive: LANGUAGES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'languages', 'languages', 'Language')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchLanguagesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchLanguages = actionUtility.fetchEntities
