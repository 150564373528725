import React from 'react'
import Flag from 'components/Flag'
import PropTypes from 'prop-types'

export const CountryHeader = ({ abbreviature, name }) => (
  <div className='o-media d-flex d-flex--no-wrap ai-center'>
    <div className='o-media__img o-media__img--circle u-margin-right-small line-height--17'>
      <Flag flag={abbreviature.toLowerCase()} size='medium' />
    </div>
    <div className='o-media__body'>
      <span className='u-weight--bold u-text--large u-word-break-all'>{name}</span>
    </div>
  </div>
)

CountryHeader.propTypes = {
  abbreviature: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default CountryHeader
