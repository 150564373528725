import { documentTypeLabels } from 'utils/locales/document.en'

/**
 * Document types
 */
export const docTypes = {
  INPUTS: 'Inputs',
  INPUTS_VALIDATION_REPORT: 'Inputs Validation Report',
  INPUTS_AUTO_SAVED: 'Inputs Auto-Saved',
  MIPI_INPUTS: 'MIPI Inputs',
  GTN: 'GTN',
  GTN_MAPPING_REPORT: 'GTN Mapping Report',
  GTN_AUTO_SAVED: 'GTN Auto-Saved',
  PAYSLIPS: 'Payslips',
  PAYSLIP_UPLOAD_REPORT: 'Payslips Upload Report',
  TAX_DOCUMENTS: 'Tax Documents',
  TAX_DOCUMENT_UPLOAD_REPORT: 'Tax Docs Upload Report',
  BANK_FILE: 'Bank File',
  BANK_FILE_VALIDATION_REPORT: 'Bank File Validation Report',
  GL_MAPPING: 'GL Mapping',
  GL_REPORTS: 'GL Reports',
  OTHER: 'Other',
  ADDITIONAL_INPUTS: 'Additional Inputs',
  BENEFICIARY_FILE: 'Beneficiary File',
  DATA_MIGRATION: 'Data Migration',
  DATA_MIGRATION_VALIDATION_REPORT: 'Data Migration Validation Report',
  PAYSLIPS_VALIDATION_REPORT: 'Payslips Validation Report',
  PAYSLIPS_HCM_REPORT: 'Payslips HCM Report',
  TAX_DOCS_VALIDATION_REPORT: 'Tax Docs Validation Report',
  TAX_DOCS_HCM_REPORT: 'Tax Docs HCM Report',
  NO_TYPE: '',
}

export const docTypeIDs = {
  GTN: 1,
  PAYSLIPS: 2,
  TAX_DOCUMENTS: 3,
  GL_REPORTS: 4,
  OTHER: 5,
  GTN_MAPPING_REPORT: 6,
  INPUTS: 7,
  PAYSLIP_UPLOAD_REPORT: 8,
  TAX_DOCUMENT_UPLOAD_REPORT: 9,
  BANK_FILE: 10,
  GL_MAPPING: 11,
  INPUTS_AUTO_SAVED: 12,
  INPUTS_VALIDATION_REPORT: 14,
  GTN_AUTO_SAVED: 13,
  MIPI_INPUTS: 15,
  ADDITIONAL_INPUTS: 16,
  BENEFICIARY_FILE: 17,
  BANK_FILE_VALIDATION_REPORT: 18,
  DATA_MIGRATION: 19,
  DATA_MIGRATION_VALIDATION_REPORT: 20,
  PAYSLIPS_VALIDATION_REPORT: 21,
  PAYSLIPS_HCM_REPORT: 22,
  TAX_DOCS_VALIDATION_REPORT: 23,
  TAX_DOCS_HCM_REPORT: 24,
  // this is not used for the moment
  NO_TYPE: 0,
}

export const docTypeValues = {
  INPUTS: 'Inputs',
  INPUTS_AUTO_SAVED: 'Inputs Auto-Saved',
  INPUTS_VALIDATION_REPORT: 'Inputs Validation Report',
  MIPI_INPUTS: 'MIPI Inputs',
  GTN: 'GTN',
  GTN_MAPPING_REPORT: 'GTN Mapping Report',
  GTN_AUTO_SAVED: 'GTN Auto-Saved',
  PAYSLIPS: 'Payslips',
  PAYSLIP_UPLOAD_REPORT: 'Payslips Upload Report',
  TAX_DOCUMENTS: 'Tax Documents',
  TAX_DOCUMENT_UPLOAD_REPORT: 'Tax Docs Upload Report',
  BANK_FILE: 'Bank File',
  BANK_FILE_VALIDATION_REPORT: 'Bank File Validation Report',
  GL_MAPPING: 'GL Mapping',
  GL_REPORTS: 'GL Reports',
  OTHER: 'Other',
  ADDITIONAL_INPUTS: 'Additional Inputs',
  BENEFICIARY_FILE: 'Beneficiary File',
  DATA_MIGRATION: 'Data Migration',
  DATA_MIGRATION_VALIDATION_REPORT: 'Data Migration Validation Report',
  PAYSLIPS_VALIDATION_REPORT: 'Payslips Validation Report',
  PAYSLIPS_HCM_REPORT: 'Payslips HCM Report',
  TAX_DOCS_VALIDATION_REPORT: 'Tax Docs Validation Report',
  TAX_DOCS_HCM_REPORT: 'Tax Docs HCM Report',
  NO_TYPE: 0,
}

// Indexed array to sort the documents types received from the BE into this particular order
export const indexedDocTypes = [
  'inputs',
  'mipiInputs',
  'inputsAutoSaved',
  'inputsValidationReport',
  'additionalInputs',
  'gtn',
  'gtnMappingReport',
  'gtnAutoSaved',
  'payslips',
  'payslipUploadReport',
  'tax',
  'taxDocumentUploadReport',
  'bankFile',
  'bankFileValidationReport',
  'beneficiaryFile',
  'glMapping',
  'glReports',
  'dataMigration',
  'dataMigrationValidationReport',
  'payslipsValidationReport',
  'payslipsHcmReport',
  'taxDocsValidationReport',
  'taxDocsHcmReport',
  'other',
]

export const documentTypeOptions = [
  { value: docTypeValues.INPUTS, label: docTypes.INPUTS },
  { value: docTypeValues.MIPI_INPUTS, label: docTypes.MIPI_INPUTS },
  { value: docTypeValues.INPUTS_AUTO_SAVED, label: docTypes.INPUTS_AUTO_SAVED },
  { value: docTypeValues.INPUTS_VALIDATION_REPORT, label: docTypes.INPUTS_VALIDATION_REPORT },
  { value: docTypeValues.GTN, label: docTypes.GTN },
  { value: docTypeValues.GTN_MAPPING_REPORT, label: docTypes.GTN_MAPPING_REPORT },
  { value: docTypeValues.GTN_AUTO_SAVED, label: docTypes.GTN_AUTO_SAVED },
  { value: docTypeValues.PAYSLIPS, label: docTypes.PAYSLIPS },
  { value: docTypeValues.PAYSLIP_UPLOAD_REPORT, label: docTypes.PAYSLIP_UPLOAD_REPORT },
  { value: docTypeValues.TAX_DOCUMENTS, label: docTypes.TAX_DOCUMENTS },
  { value: docTypeValues.TAX_DOCUMENT_UPLOAD_REPORT, label: docTypes.TAX_DOCUMENT_UPLOAD_REPORT },
  { value: docTypeValues.BANK_FILE, label: docTypes.BANK_FILE },
  { value: docTypeValues.BANK_FILE_VALIDATION_REPORT, label: docTypes.BANK_FILE_VALIDATION_REPORT },
  { value: docTypeValues.GL_MAPPING, label: docTypes.GL_MAPPING },
  { value: docTypeValues.GL_REPORTS, label: docTypes.GL_REPORTS },
  { value: docTypeValues.ADDITIONAL_INPUTS, label: docTypes.ADDITIONAL_INPUTS },
  { value: docTypeValues.BENEFICIARY_FILE, label: docTypes.BENEFICIARY_FILE },
  { value: docTypeValues.DATA_MIGRATION, label: docTypes.DATA_MIGRATION },
  { value: docTypeValues.DATA_MIGRATION_VALIDATION_REPORT, label: docTypes.DATA_MIGRATION_VALIDATION_REPORT },
  { value: docTypeValues.PAYSLIPS_VALIDATION_REPORT, label: docTypes.PAYSLIPS_VALIDATION_REPORT },
  { value: docTypeValues.PAYSLIPS_HCM_REPORT, label: docTypes.PAYSLIPS_HCM_REPORT },
  { value: docTypeValues.TAX_DOCS_VALIDATION_REPORT, label: docTypes.TAX_DOCS_VALIDATION_REPORT },
  { value: docTypeValues.TAX_DOCS_HCM_REPORT, label: docTypes.TAX_DOCS_HCM_REPORT },
  { value: docTypeValues.OTHER, label: docTypes.OTHER },
]
export const documentTypeOptionsWithIds = [
  { value: docTypeIDs.INPUTS, label: docTypes.INPUTS },
  { value: docTypeIDs.MIPI_INPUTS, label: docTypes.MIPI_INPUTS },
  { value: docTypeIDs.INPUTS_AUTO_SAVED, label: docTypes.INPUTS_AUTO_SAVED },
  { value: docTypeIDs.INPUTS_VALIDATION_REPORT, label: docTypes.INPUTS_VALIDATION_REPORT },
  { value: docTypeIDs.GTN, label: docTypes.GTN },
  { value: docTypeIDs.GTN_MAPPING_REPORT, label: docTypes.GTN_MAPPING_REPORT },
  { value: docTypeIDs.GTN_AUTO_SAVED, label: docTypes.GTN_AUTO_SAVED },
  { value: docTypeIDs.PAYSLIPS, label: docTypes.PAYSLIPS },
  { value: docTypeIDs.PAYSLIP_UPLOAD_REPORT, label: docTypes.PAYSLIP_UPLOAD_REPORT },
  { value: docTypeIDs.TAX_DOCUMENTS, label: docTypes.TAX_DOCUMENTS },
  { value: docTypeIDs.TAX_DOCUMENT_UPLOAD_REPORT, label: docTypes.TAX_DOCUMENT_UPLOAD_REPORT },
  { value: docTypeIDs.BANK_FILE, label: docTypes.BANK_FILE },
  { value: docTypeIDs.BANK_FILE_VALIDATION_REPORT, label: docTypes.BANK_FILE_VALIDATION_REPORT },
  { value: docTypeIDs.GL_MAPPING, label: docTypes.GL_MAPPING },
  { value: docTypeIDs.GL_REPORTS, label: docTypes.GL_REPORTS },
  { value: docTypeIDs.ADDITIONAL_INPUTS, label: docTypes.ADDITIONAL_INPUTS },
  { value: docTypeIDs.BENEFICIARY_FILE, label: docTypes.BENEFICIARY_FILE },
  { value: docTypeIDs.DATA_MIGRATION, label: docTypes.DATA_MIGRATION },
  { value: docTypeIDs.DATA_MIGRATION_VALIDATION_REPORT, label: docTypes.DATA_MIGRATION_VALIDATION_REPORT },
  { value: docTypeIDs.PAYSLIPS_VALIDATION_REPORT, label: docTypes.PAYSLIPS_VALIDATION_REPORT },
  { value: docTypeIDs.PAYSLIPS_HCM_REPORT, label: docTypes.PAYSLIPS_HCM_REPORT },
  { value: docTypeIDs.TAX_DOCS_VALIDATION_REPORT, label: docTypes.TAX_DOCS_VALIDATION_REPORT },
  { value: docTypeIDs.TAX_DOCS_HCM_REPORT, label: docTypes.TAX_DOCS_HCM_REPORT },
  { value: '0', label: docTypes.OTHER }, // Other doesn't exist as a type on the BE, 0 is the correct id to get the docs without type
]

export const payrollInputsDocTypes = [docTypeValues.INPUTS, docTypeValues.INPUTS_AUTO_SAVED, docTypeValues.INPUTS_VALIDATION_REPORT, docTypeValues.MIPI_INPUTS]

export const payrollGtnDocTypes = [docTypeValues.GTN, docTypeValues.GTN_MAPPING_REPORT, docTypeValues.GTN_AUTO_SAVED]

export const payrollPayslipsTaxDocTypes = [
  docTypeValues.PAYSLIPS,
  docTypeValues.PAYSLIP_UPLOAD_REPORT,
  docTypeValues.TAX_DOCUMENTS,
  docTypeValues.TAX_DOCUMENT_UPLOAD_REPORT,
  docTypeValues.PAYSLIPS_VALIDATION_REPORT,
  docTypeValues.PAYSLIPS_HCM_REPORT,
  docTypeValues.TAX_DOCS_VALIDATION_REPORT,
  docTypeValues.TAX_DOCS_HCM_REPORT,
]

export const payrollGlReportsDocTypes = [docTypeValues.GL_REPORTS, docTypeValues.GL_MAPPING]

export const payrollPaymentsDocTypes = [docTypeValues.BANK_FILE, docTypeValues.BENEFICIARY_FILE, docTypeValues.BANK_FILE_VALIDATION_REPORT]

export const payrollOtherDocTypes = [
  docTypeValues.OTHER,
  docTypeValues.ADDITIONAL_INPUTS,
  docTypeValues.DATA_MIGRATION,
  docTypeValues.DATA_MIGRATION_VALIDATION_REPORT,
  docTypeValues.NO_TYPE,
]

export const docTypeIDsRestrictedForCOTUsers = [docTypeIDs.ADDITIONAL_INPUTS]

export const docTypeIDsRestrictedForVendors = [docTypeIDs.BENEFICIARY_FILE]

export const docTypesOnlyCotBotCanEdit = [docTypes.ADDITIONAL_INPUTS]

export const docTypesFilesToExcludeWhenReadOnly = {
  upload: ['bankFileValidationReport'],
  edit: ['bankFileValidationReport'],
}

export const docTypesLabelling = {
  [documentTypeLabels.inputs]: [docTypeIDs.INPUTS, docTypeIDs.INPUTS_AUTO_SAVED, docTypeIDs.INPUTS_VALIDATION_REPORT, docTypeIDs.MIPI_INPUTS],
  [documentTypeLabels.gtn]: [docTypeIDs.GTN, docTypeIDs.GTN_MAPPING_REPORT, docTypeIDs.GTN_AUTO_SAVED],
  [documentTypeLabels.payAndTax]: [
    docTypeIDs.PAYSLIPS,
    docTypeIDs.PAYSLIP_UPLOAD_REPORT,
    docTypeIDs.TAX_DOCUMENTS,
    docTypeIDs.TAX_DOCUMENT_UPLOAD_REPORT,
    docTypeIDs.PAYSLIPS_VALIDATION_REPORT,
    docTypeIDs.PAYSLIPS_HCM_REPORT,
    docTypeIDs.TAX_DOCS_VALIDATION_REPORT,
    docTypeIDs.TAX_DOCS_HCM_REPORT,
  ],
  [documentTypeLabels.gl]: [docTypeIDs.GL_REPORTS, docTypeIDs.GL_MAPPING],
  [documentTypeLabels.payments]: [docTypeIDs.BANK_FILE, docTypeIDs.BENEFICIARY_FILE, docTypeIDs.BANK_FILE_VALIDATION_REPORT],
  [documentTypeLabels.other]: [
    docTypeIDs.OTHER,
    docTypeIDs.ADDITIONAL_INPUTS,
    docTypeIDs.DATA_MIGRATION,
    docTypeIDs.DATA_MIGRATION_VALIDATION_REPORT,
    docTypeIDs.NO_TYPE,
  ],
}

export const documentTypeClass = Object.freeze({
  payroll: 'payroll',
  payrollInstance: 'payrollInstance',
})

export const getDocTypeLabel = (type) => Object.keys(docTypesLabelling).find((k) => docTypesLabelling[k].includes(type))

export const getDocTypesLabellingOpts = () =>
  Object.keys(docTypesLabelling).map((docTypeLabel) => ({
    label: docTypeLabel,
    value: docTypeLabel,
  }))
