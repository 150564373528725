export const commonLabels = {
  countryOfBirth: 'Country of Birth',
  dateFormat: 'Date format on all date fields',
  employeeSelfService: 'Employee Self-service',
  endDate: 'End Date',
  fullTimePartTime: 'Full Time / Part Time',
  maritalStatus: 'Marital Status',
  mobilePhone: 'Mobile Phone',
  nationalInsuranceLetter: 'National Insurance Letter',
  orgUnit: 'Business Unit, Cost Center, Project',
  otherAddressFields: 'Other Address Fields',
  paymentType: 'Payment Type',
  title: 'Title',
  typeOfAddress: 'Type of Address',
  typeOfEmployment: 'Type of Employment / Engagement',
  visaWorkPermit: 'Visa / Work Permit',
  mode2FA: '2FA Mode',
}

export const commonDescriptions = {
  countryOfBirth: '- Should be only one country, e.g. Ireland',
  dateFormat: "- DD/MM/YYYY. This should be the real value in the cell, doesn't matter if Excel shows it differently (for example DD.MM.YYYY)",
  email: '- Should be email in valid format (xxx@yyy.zz). Work Email should be unique for the whole client',
  endDate: '- Should be a date greater than or equal to Start Date',
  fullTimePartTime: '- Full Time, Part Time, Variable',
  maritalStatus:
    '- Single, Married, Free Union, Domestic Partnership, Civil Partnership, ' +
    'Civil Partnership Dissolved, Civil Partner Deceased, Separated, Judicially Separated, Divorced, Widowed, Pacsed, Not Reported',
  mobilePhone:
    '- Should be unique per user. Should be at least 10 characters long. ' +
    "Doesn't matter if it is 353888123456, +353888123456, ++353888123456, 0353888123456, 00353888123456 - all those cases will be imported successfully",
  nationalInsuranceLetter: '- A, A1, B, C, E, J, H, M, X, Z',
  orgUnit: '- The value should match the name exactly of a one in the system',
  otherAddressFields:
    '- For Contractor - Company and Contractor - Agency this should be the company/agency address details. ' +
    'For Short Term Assignment employees this should be their host address details',
  paymentType: '- Salary, Hourly',
  typeOfAddress: '- Dependant, Home, Mailing, Payroll',
  visaWorkPermit: '- Applicable, Not applicable',
}
