import createSelector from 'utils/createSelector'

const categoryName = (state, props) => props.categoryName

export const getCompanyCountryTermsPivotByCategory = createSelector(categoryName,
  ({ CompanyCountryTerm }, categoryName) => {
    return CompanyCountryTerm.all()
      .toModelArray()
      .filter(entity => (
        entity.countryTerm.term.termSubcategory.termCategory.name === categoryName
      ))
      .map(pivot => pivot.id)
  }
)
