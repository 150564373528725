import React from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import DayWrapper from '../containers/DayWrapper'
import moment from 'moment'
import PropTypes from 'prop-types'
import { CustomToolbar, DailyHeader, Event, EventDaily, formats, WeeklyHeader } from 'components/calendar/CustomComponents'
import Year from 'components/calendar/Year'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
moment.locale('en-GB')
const localizer = momentLocalizer(moment) // or globalizeLocalizer
class Calendar extends React.Component {
  render () {
    const { tasks } = this.props
    return <div>
      <div className='o-layout u-margin-bottom-small'>
        <div className='c-calendar__holder o-layout__item u-1/1'>
          <BigCalendar
            // Here we always set the current Day as a default value,
            // because for some reason (I guess it's a bug) the Calendar keeps the last selected Date,
            // event if this component is destroyed and recreated.
            // My bet is `react-big-calendar` sets the current day globally and later mutates its value.
            defaultDate={new Date()}
            events={tasks}
            localizer={localizer}
            toolbar
            popup
            formats={formats}
            components={{
              event: Event,
              toolbar: toolbar => CustomToolbar(toolbar, { onRangeChange: this.props.onRangeChange }),
              dateCellWrapper: DayWrapper,
              week: {
                header: WeeklyHeader,
              },
              day: {
                header: DailyHeader,
                event: EventDaily
              },
            }}
            views={{
              day: true,
              week: true,
              month: true,
              year: Year
            }}
          />
        </div>
      </div>
    </div>
  }
}

Calendar.propTypes = {
  tasks: PropTypes.array.isRequired
}

export default Calendar
