import React from 'react'
import { connect } from 'react-redux'
import SLAsContainer from '../containers/SLAsContainer'
import TimelineFormCreateContainer from '../containers/TimelineFormCreateContainer'
import TimelineFormEditContainer from '../containers/TimelineFormEditContainer'
import Modal from 'components/Modal'
import { getWhiteLabeledBrandStyles } from 'redux/actions/brandings'

class RouteView extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
    this.handleEditCreateClick = this.handleEditCreateClick.bind(this)
    this.state = {
      vendorProps: {}
    }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(getWhiteLabeledBrandStyles())
  }

  /**
   * Handle modal opening
   * @param modalRef
  */
  handleModalOpen (modalRef) {
    modalRef.showModal()
  }

  hideModal () {
    this.refs.modalEditService.hideModal()
  }

  handleEditCreateClick (vendorProps) {
    this.setState({ vendorProps })
    this.handleModalOpen(this.refs.modalEditService)
  }

  render () {
    const { vendorProps } = this.state
    const isEdit = vendorProps.vendorCountryTaskId !== undefined
    const isCreate = !isEdit

    return <div>
      <SLAsContainer {...this.props} onClick={this.handleEditCreateClick} />
      <Modal
        ref='modalEditService'
        className='c-modal c-modal--half'
        modalHeading='Update timeline'
      >
        { isEdit ? <TimelineFormEditContainer onSubmit={this.hideModal} {...this.props} {...this.state.vendorProps} /> : null }
        { isCreate ? <TimelineFormCreateContainer onSubmit={this.hideModal} {...this.props} {...this.state.vendorProps} /> : null }
      </Modal>
    </div>
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})
const Container = connect(null, mapDispatchToProps)(RouteView)
export default Container
