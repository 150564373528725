import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { hasEmployees } from 'redux/helpers/hasEmployees'
import PayrollPaygroupEmployees from 'components/employees/PayrollPaygroupEmployees'
import { areAllEmployeesSelected } from 'redux/helpers/areAllEmployeesSelected'

const mapDispatchToProps = (dispatch) => ({ dispatch })

const mapStateToProps = (state, props) => {
  const { employees } = props

  return {
    employeeSystemUsers: employees,
    hasEmployees: () => hasEmployees(state, 'paygroupDetachEmployees', employees),
    areAllEmployeesSelected: areAllEmployeesSelected({ state, formName: 'paygroupDetachEmployees', employees: props.employees }),
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'paygroupDetachEmployees',
    destroyOnUnmount: false,
  })(PayrollPaygroupEmployees)
)

export default Container
