import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import PayslipTable from 'components/table/PayslipTable'
import { useDocument } from 'hooks/useDocument'
import { createDocumentFilterNameById, prepareFilters } from 'redux/filters/createDocumentFilter'
import { generateSupportingDocsHeadings } from '../suportingDocsTableConfig'
import { downloadDocument, updateDocument } from 'redux/actions/document'
import { CircleICon } from 'components/icons/CircleIcon'
import { createFilter } from 'utils/redux/filter'

const DocumentActions = ({ row, onClick }) => (
  <div className='mr-2 u-cursor--pointer' onClick={() => onClick(row.id)}>
    <CircleICon />
  </div>
)
export const SupportingDocsContent = ({ payrollInstanceId }) => {
  const dispatch = useDispatch()
  const { docFilterNames, documents, syncDocsData } = useDocument({
    filterName: createDocumentFilterNameById(payrollInstanceId),
  })
  const tableData = (documents || [])?.filter((doc) => doc.payrollSummary)
  const handleDownloadDocument = useCallback((id) => dispatch(downloadDocument(id)), [dispatch])
  const handleUpdateDoc = useCallback(
    async (id) => {
      await dispatch(
        updateDocument(
          {
            payrollSummary: false,
          },
          id,
          false
        )
      )
      const computedFilters = prepareFilters({
        filterNames: docFilterNames,
        props: {
          typeClass: 'payrollInstance',
          typeId: payrollInstanceId,
          payrollSummary: true,
        },
      })
      syncDocsData({
        filter: createFilter(computedFilters, createDocumentFilterNameById(payrollInstanceId)),
      })
    },
    [dispatch]
  )

  const tableHeadings = generateSupportingDocsHeadings({
    handleDownloadDocument,
    handleUpdateDoc,
    DocumentActions,
  })

  return (
    <div className='u-margin-top-tiny'>
      <PayslipTable data={tableData} headings={tableHeadings} dynamicValues />
    </div>
  )
}
