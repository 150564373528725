import { titleTypes } from 'redux/config/DropDownOptions/titleOptions'
import { mode2faOptions } from 'redux/config/DropDownOptions/mode2faOptions'

const statusesForActions = [
  { id: 'all', name: 'All' },
  { id: 'overdue', name: 'Overdue' },
]

const selectionOptionsConfig = {
  statusesForActions: statusesForActions,
  title: titleTypes,
  mode2fa: mode2faOptions
}

/**
 * A method that should be used to retrieve selection
 * options that are not currently coming back from the BE
 * This should help translations as we can change it in one place
 *
 * @param {string} type String containing the list you want
 * Current accepted values ['statusesForActions', 'title', 'mode2fa]
 * @returns An array of options for display in custom select box
 */
export const getSelectionOptions = (type) => selectionOptionsConfig[type] || []

// get label for value from list of options
export const getLabelFromOptions = (options, value) => {
  return options.find(o => o.value === value)?.label || ''
}
