import { isEqual } from './base'

/**
 * Recursively deletes properties from an object based on a set of property names.
 * @param {Object} obj - The object from which to delete properties.
 * @param {Set} omitSet - A set of property names to delete.
 */
const deepOmit = (obj, omitSet) => {
  Object.keys(obj).forEach((key) => {
    if (omitSet.has(key)) {
      delete obj[key]
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      deepOmit(obj[key], omitSet)
    }
  })
}

/**
 * Creates an object composed of the object properties not listed in the omitted paths.
 * @param {Object} object - The source object.
 * @param {string[]} paths - An array of property names or paths (nested paths) to be omitted.
 * @returns {Object} - Returns a new object with specified paths omitted.
 */
export const omitDeep = (object, paths) => {
  // Deep clone the object to avoid mutating the original
  const result = JSON.parse(JSON.stringify(object))
  const omitSet = new Set(paths)
  deepOmit(result, omitSet)
  return result
}

/**
 * Creates a new object with specified properties omitted.
 * This function is useful for creating a new object from an existing one minus a few keys.
 * @param {Object} obj - The source object.
 * @param {Array<string>} props - An array of properties to omit from the returned object.
 * @returns {Object} - New object with the specified properties omitted.
 */
export const omit = (obj, ...props) => {
  const result = { ...obj }
  props.forEach((prop) => delete result[prop])
  return result
}

/**
 * Retrieves a value from an object using a path, with optional chaining and nullish coalescing for defaults.
 * @param {Object} obj - The object to query.
 * @param {string} path - The path to the property to retrieve.
 * @param {*} defaultValue - The default value to return if the resolved value is undefined.
 * @returns {*} - The value at the specified path or the default value.
 */
export const get = (obj, path, defaultValue = undefined) => {
  const result = path.split('.').reduce((acc, part) => acc?.[part], obj)
  return result ?? defaultValue
}

/**
 * Creates an object composed of the object properties predicate returns truthy for.
 *
 * @param {Object} object - The object to query
 * @param {Func} predicate - Test function
 * @returns {Object} - New object made of only truthy properties from the input object
 */

export const pickBy = (object, predicate = (v) => v) => Object.fromEntries(Object.entries(object).filter(([, v]) => predicate(v)))

/**
 * Checks if an object has a direct property.
 *
 * @param {Object} object - The object to query.
 * @param {string} key - The key of the property to check.
 * @returns {boolean} - Returns true if the property exists, false otherwise.
 */
export const has = (object, key) => object != null && Object.prototype.hasOwnProperty.call(object, key)

/**
 * Computes the difference between the initial data and the form data.
 *
 * @param {Object} initialData - The initial state of the data.
 * @param {Object} data - The current state of the data.
 * @param {boolean} [deepEqual=false] - Whether to perform a deep comparison of the data.
 * @returns {Object} An object containing the changed values.
 */
export const diff = (initialData = {}, data = {}, deepEqual = false) => {
  const allKeys = new Set([...Object.keys(initialData), ...Object.keys(data)])

  return Array.from(allKeys).reduce((acc, key) => {
    const isDataChanged = deepEqual ? !isEqual(data[key], initialData[key]) : data[key] !== initialData[key]

    if (isDataChanged) {
      acc[key] = data[key]
    }

    return acc
  }, {})
}

/**
 * Picks specified properties from an object and returns a new object
 * containing only those properties.
 *
 * @param {Object} obj - The source object to pick properties from.
 * @param {Array<string>} keys - An array of keys representing the properties to pick.
 * @returns {Object} A new object containing only the picked properties.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * const picked = pick(obj, ['a', 'c']);
 * console.log(picked); // { a: 1, c: 3 }
 */
export const pick = (obj, keys) => {
  if (!obj) return {}
  return keys.reduce(
    (result, key) => ({
      ...result,
      ...(key in obj ? { [key]: obj[key] } : {}),
    }),
    {}
  )
}
