import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import PayslipTable from 'components/table/PayslipTable'
class ShareVendorClients extends React.Component {
  render () {
    const { clientsUsers, initialValues, isOwner, sharingActionText = 'Share' } = this.props
    const options = {
      searchPosition: 'left',
      noDataText: 'No matching records found.',
    }

    return <div data-testid='outsideCompany-tabBody' className='position-relative'>
      <PayslipTable
        data={clientsUsers.map(user => ({
          ...user,
          share: <Field
            type='checkbox'
            labelClassName='u-hidden'
            component={CheckboxField}
            className='control__indicator__input'
            indicatorClassName='control__indicator--center'
            formGroupClassName='u-margin-none'
            name={`${user.extraData.tenant}-${user.extraData.id}`}
            disabled={initialValues[`${user.extraData.tenant}-${user.extraData.id}`] && !isOwner}
          />
        }))}
        headings={[
          { accessor: 'fullname', Header: 'Name', disableSortBy: true },
          {
            accessor: 'share',
            Header: sharingActionText,
            setWidth: '100px',
            classNames: 'text-center',
            columnClassName: 'u-text--center u-padding-right-small',
            disableFilters: true,
            disableSortBy: true
          }
        ]}
        wrapperClassName='u-1/1 u-margin-bottom'
        modifierClasses='u-margin-top-small'
        options={options}
        search
        trClassName='react-bs-table__cell--whitespace--normal'
      />

    </div>
  }
}

ShareVendorClients.propTypes = {
  clientsUsers: PropTypes.array,
  initialValues: PropTypes.object,
  isOwner: PropTypes.bool,
  sharingActionText: PropTypes.string
}

export default ShareVendorClients
