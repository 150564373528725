import createSelector from 'utils/createSelector'
import { getDocumentUsersByDocIdAndDocTenant } from './documentUsers'
import { ROLE_ACCOUNTING, ROLE_ACCOUNTING_READ_ONLY } from 'redux/config/auth'

const getCompanyId = (state, props) => parseInt(props.companyId)
const getTenant = (state, props) => props.tenant
const getPayrollProcessId = (state, props) => props.payrollProcessId
const getPayrollInstanceProcessId = (state, props) => props.payrollInstanceProcessId
const getTasksOwners = (state, props) => props.tasksOwners
const getProcessOwnerId = (state, props) => props.processOwnerId
const getTypeClass = (state, props) => props.typeClass

/*
 * Get external employee users by access area for tab clients
 * Process owner and action owners have to be excluded from list.
 */
export const getExternalClients = createSelector(
  getCompanyId,
  getTenant,
  getDocumentUsersByDocIdAndDocTenant,
  getProcessOwnerId,
  getTasksOwners,
  getTypeClass,
  ({ ExternalEmployeeUser }, companyId, tenant, docUsers, processOwnerId, tasksOwners, typeClass) => {
    return (
      ExternalEmployeeUser.filter(
        (user) =>
          user.extraData.companyAccessArea?.includes(companyId) &&
          user.extraData.tenant === tenant &&
          user.extraData.id !== processOwnerId &&
          !tasksOwners?.includes(user.extraData.id)
      )
        // ROLE_ACCOUNTING users should be returned only for documents, with type `company` or `payrollInstance`.
        .filter((u) => ([ROLE_ACCOUNTING, ROLE_ACCOUNTING_READ_ONLY].includes(u.roleType) ? ['payrollInstance', 'company'].includes(typeClass) : true))
        .toRefArray()
        .map((employeeUser) => _getUserProps(employeeUser, docUsers))
    )
  }
)

export const getExternalClientsHavingCredentials = createSelector(getExternalClients, (session, users) => users.filter((u) => u.hasCredentials))

// Get external employee users that have been shared a particular file
export const getExternalClientsWithSharedDocument = createSelector(getExternalClients, (session, users) => {
  return users.filter((user) => user.share)
})

/*
 * Helper func to get external employee user which is process owner
 */
export const _getExternalProcessOwner = (ExternalEmployeeUser, tenant, processId, processes, docUsers) => {
  return ExternalEmployeeUser.filter((user) => user[processes]?.includes(processId) && user.extraData.tenant === tenant)
    .toRefArray()
    .map((employeeUser) => _getUserProps(employeeUser, docUsers))
}

/*
 * Get external employee user which is payroll process owner
 */
export const getExternalPayrollProcessOwner = createSelector(
  getTenant,
  getPayrollProcessId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ ExternalEmployeeUser }, tenant, payrollProcessId, docUsers) => {
    return _getExternalProcessOwner(ExternalEmployeeUser, tenant, payrollProcessId, 'payrollProcesses', docUsers)
  }
)

/*
 * Get external employee user which is payroll instance process owner
 */
export const getExternalPayrollInstanceProcessOwner = createSelector(
  getTenant,
  getPayrollInstanceProcessId,
  getDocumentUsersByDocIdAndDocTenant,
  ({ ExternalEmployeeUser }, tenant, payrollInstanceProcessId, docUsers) => {
    return _getExternalProcessOwner(ExternalEmployeeUser, tenant, payrollInstanceProcessId, 'payrollInstanceProcesses', docUsers)
  }
)

export const getExternalPayrollInstanceProcessOwnerHavingCredentials = createSelector(getExternalPayrollInstanceProcessOwner, (session, users) =>
  users.filter((u) => u.hasCredentials)
)

/*
 * Get external employee users which are action owners
 */
export const getExternalActionOwners = createSelector(
  getCompanyId,
  getTenant,
  getDocumentUsersByDocIdAndDocTenant,
  getTasksOwners,
  getProcessOwnerId,
  getTypeClass,
  ({ ExternalEmployeeUser }, companyId, tenant, docUsers, tasksOwners, processOwnerId, typeClass) => {
    return (
      ExternalEmployeeUser.filter(
        (user) => user.extraData.tenant === tenant && tasksOwners?.includes(user.extraData.id) && user.extraData.id !== processOwnerId
      )
        // ROLE_ACCOUNTING users should be returned only for documents, with type `company` or `payrollInstance`.
        .filter((u) => ([ROLE_ACCOUNTING, ROLE_ACCOUNTING_READ_ONLY].includes(u.roleType) ? ['payrollInstance', 'company'].includes(typeClass) : true))
        .toRefArray()
        .map((employeeUser) => _getUserProps(employeeUser, docUsers))
    )
  }
)

export const getExternalActionOwnersHavingCredentials = createSelector(getExternalActionOwners, (session, users) => users.filter((u) => u.hasCredentials))

/*
 * Helper func to get all user props and share permissions
 */
export const _getUserProps = (employeeUser, docUsers) => {
  const file = docUsers.find((docUser) => docUser.userId === employeeUser.extraData.id && docUser.userTenant === employeeUser.extraData.tenant)
  return {
    ...employeeUser,
    share: file !== undefined,
  }
}
