import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { isFetching } from 'utils/redux/fetching'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth, getCompanyRef } from 'redux/selectors/company'
import { getBusinessUnitsWithCompanyWithCountry, getBusinessUnitsByCompanies } from 'redux/selectors/businessUnits'
import FiltersChangeLogReport from 'routes/Reporting/components/FiltersChangeLogReport'
import Fetcher from 'containers/Fetcher'
import { sortByName } from 'utils/strings'
import { toArray } from 'utils/fnkit/array'

const mapStateToProps = (state, props) => {
  const { countries, companies, businessUnits, accessAreaPivot } = state

  if (isFetching([countries, companies, businessUnits, accessAreaPivot])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(state, 'subcategory', 'fromDate', 'toDate', 'country', 'company', 'payroll', 'businessUnit', 'employmentStatus', 'modifiedBy')
  // For some reports the values will be an array of ids, and for others they will be a single id, depending if the fields are multiselect
  // To facilitate checks we will convert the non arrays to arrays
  const countryIds = toArray(values.country)
  const companyIds = toArray(values.company)

  // In the case where we have multiselect, we are checking if one of the selected companies is HQ
  const isHQSelected = companyIds.map((companyId) => getCompanyRef(state, { companyId }).isHQ).includes(true)

  return {
    // All selected values
    selected: values,
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies:
      countryIds.length > 0
        ? getCompaniesByCountriesByAuth(state, { countriesIds: countryIds }).sort(sortByName)
        : getCompaniesByAuth(state, props).sort(sortByName),
    // Business Units by selected countries
    businessUnits: isHQSelected
      ? getBusinessUnitsWithCompanyWithCountry(state).sort(sortByName)
      : companyIds.length > 0
        ? getBusinessUnitsByCompanies(state, { companiesIds: companyIds }).sort(sortByName)
        : [],
  }
}

Fetcher.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

const Container = connect(mapStateToProps)(FiltersChangeLogReport)

export default Fetcher(Container, ['countries', 'companies', 'accessAreaPivot', 'businessUnits'], { wrapperDivClassName: 'o-block--inline' })
