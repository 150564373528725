import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchPaygroupsIfNeeded } from 'redux/actions/paygroups'
import { getPaygroupRef } from '../selectors/paygroup'
import SectionHeading from 'components/SectionHeading'

class PaygroupHeadingContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchPaygroupsIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <SectionHeading {...this.props} />
  }
}

const mapDispatchToProps = {
  fetchPaygroupsIfNeeded
}

// map to state
const mapStateToProps = (state, props) => {
  const entities = [ state.paygroups ]

  if (isFetching(entities)) return { isFetching: true }

  // get selected paygroup
  let paygroup = getPaygroupRef(state,
    { paygroupId: props.match.params.paygroupId }
  )

  return {
    text: `${props.heading} ${paygroup.name}`
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaygroupHeadingContainer)
