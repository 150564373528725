import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const VENDORS_FETCH = 'VENDORS_FETCH'
export const VENDORS_RECEIVE = 'VENDORS_RECEIVE'
export const VENDOR_UPDATE = 'VENDOR_UPDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: VENDORS_FETCH,
  receive: VENDORS_RECEIVE,
  update: VENDOR_UPDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'vendors', 'vendors', 'Vendor')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchVendorsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchVendors = actionUtility.fetchEntities
export const updateVendor = actionUtility.updateEntity
