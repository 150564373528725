import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import ReferralsFormCreateFields from '../components/ReferralsFormCreateFields'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { createReferral } from 'redux/actions/referrals'

class ReferralsCreateContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchParentCompaniesIfNeeded()
  }

  render () {
    return <div className='o-layout'>
      <div className='o-layout__item u-1/1'>
        <ReferralsFormCreateFields {...this.props} />
      </div>
    </div>
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
    onSubmit: data =>
      dispatch(createReferral(data, props.referralId))
        .then(() => {
          props.onCreate()
        })
  }
}

const mapStateToProps = (state, props) => {
  const { parentCompanies } = state
  const entities = [ parentCompanies ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    initialValues: {
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'referralCreate'
})(ReferralsCreateContainer))
