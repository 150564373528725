import React from 'react'
import SectionHeading from 'components/SectionHeading'
import PropTypes from 'prop-types'
import _ from 'lodash'
import PayslipTable from 'components/table/PayslipTable'

class CompanyServiceVendorList extends React.Component {
  /**
   * Render the table rows
   *
   * @return array
   */
  renderRows (company) {
    return company.companyServices.map(companyService => ({
      name: companyService.service.name,
      serviceType: _.capitalize(companyService.service.type),
      vendors: companyService.vendor
        ? companyService.vendor.name
        : (
          <button
            className='c-btn c-btn--tiny u-text--small c-btn--curious u-padding-left-small u-padding-right-small'
            onClick={this.props.onOpenAddVendor({
              companyCountryServiceId: companyService.id,
              countryServiceId: companyService.countryService
            })}
          >
            Add
          </button>
        ),
      serviceElements: ''
    }))
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <div className='o-layout'>

      {this.props.companies.map(company => {
        return <div className='o-layout__item u-1/1@tablet u-2/3@desktop u-margin-top u-margin-bottom' key={company.id}>
          <SectionHeading text={company.name}>
            <div className='o-layout__item u-1/1 u-1/2@tablet u-text--right'>
              <button
                className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
                onClick={this.props.onOpenAssign({ companyId: company.id })}
              >
                Add service
              </button>
            </div>
          </SectionHeading>
          <PayslipTable
            proportionsClassName='u-1/1'
            tableClassName='o-table--tiny o-table--striped'
            headings={[
              {
                Header: 'Services',
                accessor: 'name',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: 'Service type',
                accessor: 'serviceType',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: 'Vendors',
                accessor: 'vendors',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
              {
                Header: 'Service Elements',
                accessor: 'serviceElements',
                disableSortBy: true,
                disableFilters: true,
                columnClassName: 'u-word-break-all',
              },
            ]}
            data={this.renderRows(company)}
          />
        </div>
      })}
    </div>
  }
}

CompanyServiceVendorList.propTypes = {
  isFetching: PropTypes.bool,
  onOpenAddVendor: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    country: PropTypes.shape({
      name: PropTypes.string
    }),
    companyServices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      countryService: PropTypes.number,
      service: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
      }),
      vendor: PropTypes.object,
    }))
  })),
  onOpenAssign: PropTypes.func
}

export default CompanyServiceVendorList
