import React from 'react'

const SortDown = () => (
  <span className='icon-sort-arrow' />
)

const SortUp = () => (
  <span className='icon-sort-arrow up' />
)

const SortAll = () => (
  <span className='icon-sort-arrow-up-down' />
)

export const SortIcons = ({ cell: { isSorted, isSortedDesc } }) => {
  return (
    <span className='order d-inline-block u-margin-left-small u-padding-top-tiny'>
      {!isSorted ? (
        <SortAll />
      ) : isSortedDesc ? (
        <SortDown />
      ) : (
        <div className='u-padding-bottom-tiny'>
          <SortUp />
        </div>
      )}
    </span>
  )
}
