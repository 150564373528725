import { createReducer } from 'utils/redux/reducer'
import { ASIDE_SELECT } from '../actions/aside'

let windowWidth = window.innerWidth
const windowThreshold = 1367

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer({
  collapsed: windowWidth < windowThreshold,
}, {
  [ASIDE_SELECT]: (state, { payload: { collapsed } }) => ({ ...state, collapsed })
})
