import React from 'react'
import { formatNumberToCurrency } from 'utils/number'

const VarianceDisplayCard = ({
  subtitle = '',
  value = 0,
  currency = null,
  icon = '',
  shouldShowSuffix = false,
  suffix = false,
  classNames = ''
}) => {
  const valueSuffix = shouldShowSuffix && suffix
  return (
    <div className={`c-display-cards c-display-cards-variance shadow rounded border u-padding d-flex ai-center u-margin-top ${classNames}`}>
      <div className='rounded-full' style={{ height: '80px', width: '120px' }}>
        <span className={`icon--${icon} u-margin-left-neg-small`} style={{ width: '100px' }} />
      </div>
      <div className='c-display-cards-subtitle u-text--huge u-margin-right-large'>{subtitle}</div>
      <div className='d-flex ai-baseline'>
        <div className='u-margin-right-tiny'>{currency}</div>
        <div className='u-weight--bold u-text--26px'>{ currency ? formatNumberToCurrency(value) : value}{valueSuffix}</div>
      </div>
    </div>
  )
}

export default VarianceDisplayCard
