import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { getCompaniesRef } from 'redux/selectors/company'
import Logo from '../Logo'
import MainNav from '../MainNav'
import TenantSelectorContainer from '../../containers/TenantSelectorContainer'
import { isMultitenancyEnabled } from 'utils/config'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router'
import { setActiveMenuByRoute } from 'utils/router'
import { MultiArrowTransitioendIcon } from 'components/icons/multiArrowIcon'
import { asideGlobalRoutes } from './routes'

const Aside = (props) => {
  const {
    history,
    asideMode,
    selectAside,
    fetchCompaniesIfNeeded,
    fetchParentCompaniesIfNeeded,
    hasAccess,
    userHasAccessToPICC,
    controlCenterUrl,
    hasAccessToResources,
  } = props
  const match = useRouteMatch()

  const renderItems = () => {
    let items = [
      {
        title: 'Home',
        name: 'home',
        path: '/dashboard',
        isHome: true,
      },
      {
        title: 'Company',
        name: 'company',
        path: '/companies',
      },
      { title: 'Employees', name: 'people', path: '/employees' },
      { title: 'Payroll', name: 'payroll', path: '/payruns/active', isActive: () => setActiveMenuByRoute({ match, path: '/payruns' }) },
      {
        title: 'Toolbox',
        name: 'toolbox',
        path: '/toolbox',
        isLink: false,
        children: [
          { title: 'Calendar', name: 'calendar', path: '/toolbox/calendar', sub: true },
          { title: 'Actions', name: 'actions', path: '/toolbox/actions', sub: true },
        ],
      },
    ]

    if (hasAccess(['REPORTING_VIEW'])) {
      items.push({
        title: 'Reporting',
        name: 'reporting',
        path: '/reporting/customize-report',
      })
    }

    if (userHasAccessToPICC) {
      items.push({
        title: 'Control Center',
        name: 'control-center',
        path: controlCenterUrl,
        isExternal: true,
      })
    }

    if (hasAccessToResources) {
      items.push(asideGlobalRoutes.resources)
    }

    return items
  }

  useEffect(() => {
    fetchCompaniesIfNeeded()
    fetchParentCompaniesIfNeeded()
  }, [])

  return (
    <aside className='o-aside'>
      <Logo />

      {isMultitenancyEnabled && <TenantSelectorContainer history={history} />}

      <MultiArrowTransitioendIcon className='o-block' handleClick={() => selectAside(!asideMode)} />

      <MainNav items={renderItems()} {...props} />
    </aside>
  )
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => ({
  fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
  fetchParentCompaniesIfNeeded: () => dispatch(fetchParentCompaniesIfNeeded()),
})

// map to state
const mapStateToProps = (state, props) => {
  if (isFetching([state.companies, state.parentCompanies])) return { isFetching: true }

  return {
    companies: getCompaniesRef(state, props),
  }
}

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:
 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })
 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

Aside.propTypes = {
  isFetching: PropTypes.bool,
  selectAside: PropTypes.func,
  asideMode: PropTypes.bool,
  isUser: PropTypes.bool,
  userId: PropTypes.number,
  hasAccess: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchAccessAreaPivotIfNeeded: PropTypes.func,
  fetchParentCompaniesIfNeeded: PropTypes.func,
  history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
