import { gtnCutOffEventsSortedByPriority } from 'utils/enums/calendarEvents'

const higherPriorityEvents = (eventTitle) => {
  const eventPriority = gtnCutOffEventsSortedByPriority.findIndex(e => e === eventTitle)
  if (eventPriority === 0) return []
  return gtnCutOffEventsSortedByPriority.slice(0, eventPriority)
}

const hasNoHigherPriorityEventInInstance = (eventTitle, currentPayrollInstanceEvents) => !currentPayrollInstanceEvents.find(
  e => higherPriorityEvents(eventTitle).includes(e.title)
)
/**
 * Checks the current days events against all the events in the current payroll instance.
 * If there is an event that has a higher priority the current event isn't returned
 * @param {Array} eventsToday
 * @param {Array} currentPayrollInstanceEvents
 * @returns {Boolean}
 */
export const checkIfGtnCutOffDay = (eventsToday, currentPayrollInstanceEvents) => !!eventsToday.find(
  event => gtnCutOffEventsSortedByPriority.includes(event.title) && hasNoHigherPriorityEventInInstance(event.title, currentPayrollInstanceEvents)
)
