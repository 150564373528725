export const calculateCellHeight = (charactersPerLine, value = '') => {
  let styles = {}
  try {
    const needsResize = value.length >= charactersPerLine
    const lineHeight = 24
    const splitedValue = value.split(/\n/)
    const isMultiline = splitedValue?.length > 1

    if (needsResize) {
      const lineCount = Math.ceil(value.length / charactersPerLine)
      styles.height = `${lineCount * lineHeight}px`
    } else if (isMultiline) {
      styles.height = `${splitedValue?.length * lineHeight}px`
    }

    styles.width = '100%'
  } catch (error) {
    styles = { width: '100%' }
  }
  return styles
}
