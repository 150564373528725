import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryStepPivot'

class CountryStep extends CRUDModel {}
CountryStep.modelName = 'CountryStep'
CountryStep.actionTypes = actionTypes

CountryStep.fields = {
  id: attr(),
  name: attr(),
  step: fk('Step', 'countrySteps'),
  countryProcess: fk('CountryProcess', 'countrySteps')
}

export default CountryStep
