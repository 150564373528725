import React, { useState } from 'react'
import PropTypes from 'prop-types'

const FilterButton = ({ onClick, type = 'button', className, disabled = false, title = '', ...rest }) => {
  const [hasOpenedFilters, setHasOpenedFilters] = useState(false)
  const handleOnClick = () => {
    setHasOpenedFilters(!hasOpenedFilters)
    onClick()
  }

  return (
    <button
      onClick={handleOnClick}
      className={`${className}`}
      type={type}
      disabled={disabled}
      title={title}
      data-testid={rest['data-testid'] || 'filter-button'}
    >
      <span className={`icon icon--hamburger-filter-icon ${hasOpenedFilters ? 'filter-opened' : ''}`} />
    </button>
  )
}

FilterButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string
}

export default FilterButton
