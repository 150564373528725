import { createReducer } from 'utils/redux'
import { STEPS_FETCH, STEPS_RECEIVE, STEPS_INVALIDATE, STEP_CREATE } from 'redux/actions/steps'

const actionTypes = {
  fetch: STEPS_FETCH,
  receive: STEPS_RECEIVE,
  invalidate: STEPS_INVALIDATE,
  create: STEP_CREATE
}

export default createReducer(actionTypes)
