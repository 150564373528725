import createSelector from 'utils/createSelector'

const getPayrollProcessId = (state, props) => parseInt(props.payrollProcessId)

export const getPayrollProcess = createSelector(getPayrollProcessId,
  ({ PayrollProcess }, payrollProcessId) => {
    return { ...PayrollProcess.withId(payrollProcessId).ref }
  }
)

export const getPayrollProcessWithService = createSelector(getPayrollProcessId,
  ({ PayrollProcess }, payrollProcessId) => {
    const process = PayrollProcess.withId(payrollProcessId)

    return {
      ...process.ref,
      service: process.payrollService.ref
    }
  }
)
