import { createReducer } from 'utils/redux'
import { PARENTCOMPANIES_FETCH, PARENTCOMPANIES_RECEIVE } from 'redux/actions/parentCompanies'

const actionTypes = {
  fetch: PARENTCOMPANIES_FETCH,
  receive: PARENTCOMPANIES_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(actionTypes)
