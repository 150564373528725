import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const EXTERNAL_EMPLOYEE_USERS_FETCH = 'EXTERNAL_EMPLOYEE_USERS_FETCH'
export const EXTERNAL_EMPLOYEE_USERS_RECEIVE = 'EXTERNAL_EMPLOYEE_USERS_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EXTERNAL_EMPLOYEE_USERS_FETCH,
  receive: EXTERNAL_EMPLOYEE_USERS_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'externalEmployeeUsers', 'external_employeeusers', 'ExternalEmployeeUser')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchExternalEmployeeUsersIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchExternalEmployeeUsers = actionUtility.fetchEntities
