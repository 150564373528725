import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/companyServicePivot'

class CompanyCountryService extends CRUDModel {}
CompanyCountryService.modelName = 'CompanyCountryService'
CompanyCountryService.actionTypes = actionTypes

CompanyCountryService.fields = {
  id: attr(),
  company: fk('Company', 'companyCountryServices'),
  countryService: fk('CountryService', 'companyCountryServices'),
  vendorCountryService: fk('VendorCountryService', 'companyCountryServices'),
  payrolls: many({
    to: 'Payroll',
    relatedName: 'companyCountryServices',
    through: 'PayrollService',
    throughFields: ['companyService', 'payroll']
  })
}

export default CompanyCountryService
