import React from 'react'
import PropTypes from 'prop-types'

const AccordionActionButtons = ({ actionButtons }) => <span>{actionButtons}</span>

AccordionActionButtons.propTypes = {
  actionButtons: PropTypes.object
}

export default AccordionActionButtons
