import React, { useState, useEffect } from 'react'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import FieldDatePicker from 'components/form/FieldDatePicker'
import PayrollSelectContainer from '../containers/PayrollSelectContainer'
import MostRecentRunReportItem from './MostRecentRunReportItem'
import SubmitButtons from './SubmitButtons'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { sortByLabel } from 'utils/strings'
import CheckboxOption from 'components/form/select/CheckboxOption'
import { getMaxDate } from 'utils/fnkit/dates/ranges'
import { parseDate } from 'utils/fnkit/dates/transform'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { getDateBasedOnMaxDate } from 'utils/fnkit/dates/checks'
import PropTypes from 'prop-types'

const FiltersAuditTrailReport = (props) => {
  const {
    processes,
    selected,
    selectedType,
    countries,
    companies,
    owners,
    isFieldDisabled,
    disabledReason,
    setProcessOwner,
    mostRecentRunReport = {},
    readonlyFields,
    hideNavigationButtons,
    navigateToParentOnClick,
    isSinglePayroll,
    reportItem,
    handleReportModalOpen,
    deleteReport,
  } = props

  const startDate = selected?.fromDate
  const [maxDate, setMaxDate] = useState(getMaxDate(startDate))

  useEffect(() => {
    setMaxDate(getMaxDate(parseDate(startDate, dateFormatEnums.MonthDayYear)))
  }, [startDate])

  const isMultiAllowed = !isSinglePayroll
  const shouldShowMostRecentRunReportItem = !reportItem
  const optionComponent = isMultiAllowed ? CheckboxOption : undefined
  const isWorkflowCalendarReport = selectedType === 'WorkflowCalendarReport'

  return (
    <div className='o-block--inline'>
      {!isEmpty(selected.subcategory) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='process'
              label='Services *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--left-padding'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              placeholder='Select'
              disabled={isFieldDisabled || readonlyFields.servicesLocked}
              disabledReason={disabledReason}
              options={processes.map((process) => ({
                value: process.id,
                label: process.name,
              }))}
              enableRemovedOptionFix
              data-testid='process-wrapper'
            />
          </div>
        </div>
      )}

      {selected.process && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label={`From Date ${isWorkflowCalendarReport ? '*' : ''}`}
              labelClassName='c-label u-text--small u-text--curious'
              name='fromDate'
              component={FieldDatePicker}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              data-testid='fromDate'
              onChange={() => {
                setMaxDate(getMaxDate(parseDate(startDate, dateFormatEnums.MonthDayYear)))
              }}
            />
          </div>
        </div>
      )}

      {selected.process && (!isWorkflowCalendarReport || selected.fromDate) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              className='c-input c-input--transparent c-input__value-placeholder'
              label={`To Date ${isWorkflowCalendarReport ? '*' : ''}`}
              labelClassName='c-label u-text--small u-text--curious'
              name='toDate'
              component={FieldDatePicker}
              minDate={isWorkflowCalendarReport && startDate}
              maxDate={isWorkflowCalendarReport && maxDate}
              selected={isWorkflowCalendarReport && getDateBasedOnMaxDate(selected.toDate, maxDate)}
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              formGroupClassName='u-margin-none'
              isClearable={!isFieldDisabled}
              data-testid='toDate'
            />
          </div>
        </div>
      )}

      {selected.process && (!isWorkflowCalendarReport || selected.toDate) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='country'
              label='Country *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={optionComponent}
              multi={isMultiAllowed}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              options={countries
                .map((country) => ({
                  value: country.id,
                  label: country.name,
                  abbreviature: country.abbreviature,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='country-wrapper'
            />
          </div>
        </div>
      )}

      {!isEmpty(selected.country) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='company'
              label='Company *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={optionComponent}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi={isMultiAllowed}
              options={companies
                .map((company) => ({
                  value: company.id,
                  label: company.name,
                }))
                .sort(sortByLabel)}
              enableRemovedOptionFix
              data-testid='company-wrapper'
            />
          </div>
        </div>
      )}

      {!isEmpty(selected.company) && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <PayrollSelectContainer
              optionComponent={optionComponent}
              {...(!isFieldDisabled
                ? {
                  onChanged: (value) => (!isWorkflowCalendarReport ? setProcessOwner() : null),
                }
                : {})}
              multi={isMultiAllowed}
              {...props}
            />
          </div>
        </div>
      )}

      {!isEmpty(selected.payroll) && !isWorkflowCalendarReport && (
        <div className='o-layout__item u-padding-left-small u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
          <div className='c-modern-input'>
            <Field
              name='processOwner'
              label='Process Owner *'
              component={CustomSelectField}
              className='c-custom-select--transparent c-custom-select--overflowy'
              labelClassName='c-label u-text--small u-text--curious'
              formGroupClassName='u-margin-none'
              optionComponent={CheckboxOption}
              placeholder='Select'
              disabled={isFieldDisabled}
              disabledReason={disabledReason}
              multi
              options={owners.map((owner) => ({
                value: owner.id,
                label: owner.name,
              }))}
              enableRemovedOptionFix
              data-testid='processOwner-wrapper'
            />
          </div>
        </div>
      )}

      {/* Submit Buttons */}
      {!hideNavigationButtons && <SubmitButtons pristineCheck={!isEmpty(selected.payroll)} onBackClick={navigateToParentOnClick} {...props} />}

      {/* Most Recent Run Report Item */}
      {shouldShowMostRecentRunReportItem && (
        <div className='o-layout__item u-padding-left-small u-text--center u-1/1 u-margin-top-small u-padding-top-small'>
          <MostRecentRunReportItem mostRecentRunReport={mostRecentRunReport} onOpenReport={handleReportModalOpen} onDelete={deleteReport} {...props} />
        </div>
      )}
    </div>
  )
}

FiltersAuditTrailReport.propTypes = {
  companies: PropTypes.array,
  countries: PropTypes.array,
  deleteReport: PropTypes.func,
  disabledReason: PropTypes.string,
  handleReportModalOpen: PropTypes.func,
  hideNavigationButtons: PropTypes.bool,
  isFieldDisabled: PropTypes.bool,
  isSinglePayroll: PropTypes.bool,
  mostRecentRunReport: PropTypes.object,
  navigateToParentOnClick: PropTypes.func,
  owners: PropTypes.array,
  payrolls: PropTypes.array,
  processes: PropTypes.array,
  readonlyFields: PropTypes.object,
  reportItem: PropTypes.object,
  resetFormFields: PropTypes.func,
  selected: PropTypes.object,
  selectedType: PropTypes.string,
  setProcessOwner: PropTypes.func,
  showRunReportBtn: PropTypes.bool,
}
export default FiltersAuditTrailReport
