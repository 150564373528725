import React from 'react'
import PropTypes from 'prop-types'
import ServicesTreeDecorator from 'components/ServicesTreeDecorator'
import ProcessTree from 'components/ProcessTree'

const PayrollInstanceProcessTree = ({
  tree,
  isFetching,
  onProcessEdit,
  onTaskEdit,
  onMultiView,
  isCot,
  isEditDisabled,
  isShare,
  hideProcessOwner,
  initialValues,
  isOwner,
  isReadOnlyMode,
  hideProcessDetailsAsVendor = false,
  sharingActionText = 'Share',
}) => {
  if (isFetching) return <div>Loading...</div>
  return (
    <ServicesTreeDecorator steps={tree.steps} isDeadlineDate>
      <ProcessTree
        processId={tree.id}
        name={tree.name}
        owner={tree.owner}
        hideProcessOwner={hideProcessOwner}
        keyEventDate={tree.keyEventDate}
        keyEvent={tree.keyEvent}
        onProcessEdit={onProcessEdit}
        onTaskEdit={onTaskEdit}
        isCot={isCot}
        isEditDisabled={isEditDisabled}
        isShare={isShare}
        onMultiView={onMultiView}
        initialValues={initialValues}
        isOwner={isOwner}
        isReadOnlyMode={isReadOnlyMode}
        hideProcessDetailsAsVendor={hideProcessDetailsAsVendor}
        sharingActionText={sharingActionText}
      />
    </ServicesTreeDecorator>
  )
}

PayrollInstanceProcessTree.propTypes = {
  tree: PropTypes.object,
  isFetching: PropTypes.bool,
  onProcessEdit: PropTypes.func,
  onTaskEdit: PropTypes.func,
  isCot: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  isShare: PropTypes.bool,
  isOwner: PropTypes.bool,
  hideProcessOwner: PropTypes.bool,
  initialValues: PropTypes.object,
  isReadOnlyMode: PropTypes.bool,
  sharingActionText: PropTypes.string,
  onMultiView: PropTypes.func,
  hideProcessDetailsAsVendor: PropTypes.bool,
}

export default PayrollInstanceProcessTree
