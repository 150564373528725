/**
 * Capitalizes the first letter of a string and makes the rest of the string lowercase.
 * @param {string} str - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
export const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`

/**
 * Returns a new string with some or all matches of a pattern replaced by a replacement.
 * @param {string} str - The string to search and replace within.
 * @param {(string|{[Symbol.replace]: Function})} pattern - pattern - The pattern to search for, either a string or an object with a Symbol.replace method.
 * @param {string} replacement - The string to replace the matches with.
 * @returns {string} - The modified string with replacements.
 */
export const replace = (str, pattern, replacement) => str.replace(pattern, replacement)
/**
 * Create a template function that takes a data object to compile.
 *
 * @param {*} templateString
 * @returns template function
 */
export const template = (templateString) => {
  // Return a function that takes a data object
  return (data) => {
    // Replace the placeholders with corresponding values from the data object
    return templateString.replace(/\$\{\s*(\w+)\s*\}/g, (match, p1) => (data[p1] !== undefined ? data[p1] : match))
  }
}
