import { ActionUtility } from 'utils/redux'
import errorsHandling from 'utils/redux/actions/errorsHandling'

// ------------------------------------
// Constants
// ------------------------------------
export const EMPLOYEEUSERADDRESSES_FETCH = 'EMPLOYEEUSERADDRESSES_FETCH'
export const EMPLOYEEUSERADDRESSES_RECEIVE = 'EMPLOYEEUSERADDRESSES_RECEIVE'
export const EMPLOYEEUSERADDRESSES_CREATE = 'EMPLOYEEUSERADDRESSES_CREATE'
export const EMPLOYEEUSERADDRESSES_DELETE = 'EMPLOYEEUSERADDRESSES_DELETE'
export const EMPLOYEEUSERADDRESSES_UPDATE = 'EMPLOYEEUSERADDRESSES_UPDATE'
export const EMPLOYEEUSERADDRESSES_INVALIDATE = 'EMPLOYEEUSERADDRESSES_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: EMPLOYEEUSERADDRESSES_FETCH,
  receive: EMPLOYEEUSERADDRESSES_RECEIVE,
  create: EMPLOYEEUSERADDRESSES_CREATE,
  delete: EMPLOYEEUSERADDRESSES_DELETE,
  update: EMPLOYEEUSERADDRESSES_UPDATE,
  invalidate: EMPLOYEEUSERADDRESSES_INVALIDATE,
}

const actionUtility = new ActionUtility(actionTypes, 'employeeUserAddresses', 'employeeuseraddresses', 'EmployeeUserAddresses')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchEmployeeUserAddressesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchEmployeeUserAddresses = actionUtility.fetchEntities
export const creatEmployeeUserAddresses = actionUtility.createEntity
export const deleteEmployeeUserAddresses = actionUtility.deleteEntity
export const updateEmployeeUserAddresses = actionUtility.updateEntity
export const invalidateEmployeeUserAddresses = actionUtility.invalidate

export const createNewEmployeeUserAddress = (entity, shouldFetch = true) => {
  return (dispatch, getState, { api }) => {
    return dispatch(actionUtility.createEntity(entity, { shouldFetch }))
      .then(errorsHandling.handleFormErrors)
      .then(response => {
        return response
      })
  }
}

export const updateInlineAddresses = (data = []) => {
  return dispatch => {
    const promises = data.map(d => dispatch(updateEmployeeUserAddresses(...d)).then(res => {
      return res
    }))
    return Promise.all(promises)
  }
}
