import createSelector from 'utils/createSelector'
import moment from 'moment'
import { getCompanyHolidayDatesByCompany } from './companyHolidayPivot'
import { getMappedOvertimeTermsToCCTerms } from './companyCountryTermPivot'
import _ from 'lodash'
import { getCountriesIds, getCompaniesIds, makeFilteredIdsByFilter } from 'redux/selectors/filters'
import { payrunStatusesEnum } from 'utils/enums/payrunStatuses'

// get payroll instance id
const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)
// get payroll Id
const getPayrollId = (state, props) => parseInt(props.payroll)
// get payroll Instance status
const status = (state, props) => props.status
// get filtered ids, by passed in filter
export const getFilteredIdsByFilter = makeFilteredIdsByFilter('payrollInstances')

export const getCurrentPayrollInstances = createSelector(({ PayrollInstance }) =>
  PayrollInstance.filter(
    (instance) =>
      instance.isAccessable &&
      ['active', 'sent-and-locked', 'gross-to-net-calculations-and-review-done', 'cot-final-payroll-reports-review-done', 'reverted'].includes(instance.status)
  )
    .toModelArray()
    .map((instance) => ({
      ...instance.ref,
      country: instance.payroll.company.ref.country,
    }))
)

export const getCurrentPayrollInstancesByCountries = createSelector(getCurrentPayrollInstances, getCountriesIds, (session, instances, countriesIds) =>
  instances.filter((i) => countriesIds.includes(i.country))
)

export const getCurrentPayrollInstancesByCompanies = createSelector(getCurrentPayrollInstances, getCompaniesIds, (session, instances, companiesIds) =>
  instances.filter((i) => companiesIds.includes(i.company))
)

/**
 * Get payroll instance reference by id
 */
export const getPayrollInstanceRef = createSelector(getPayrollInstanceId, ({ PayrollInstance }, payrollInstanceId) => ({
  ...PayrollInstance.withId(payrollInstanceId)?.ref,
}))

export const getPayrollInstanceByIdWithCurrentStep = createSelector(getPayrollInstanceId, ({ PayrollInstance, PayrollInstanceStep }, id) => {
  let payrollInstance = PayrollInstance.withId(id)

  const currentStep = PayrollInstanceStep.withId(payrollInstance.currentStepId)

  return {
    ...payrollInstance.ref,
    isLocked: _.includes(['C', 'D', 'E'], currentStep.getAlphabeticName()),
    currentStep,
  }
})

// get the payroll instance by id
// alogn with dates in instance period, day by day
// with overtime terms mapped to each day
export const getPayrollInstanceWithOvertimeDates = createSelector(
  getPayrollInstanceId,
  getMappedOvertimeTermsToCCTerms,
  getCompanyHolidayDatesByCompany,
  ({ PayrollInstance }, getPayrollInstanceId, mappedOvertimes, holidayDates) => {
    // get payroll instance
    let payrollInstance = PayrollInstance.withId(getPayrollInstanceId)

    // init empty vars
    let dates = []
    let overtimeTerms = {}

    // cycle through payroll instance from-to dates
    for (let addDay = 0, date = moment(payrollInstance.fromDate.date); date.diff(payrollInstance.toDate.date, 'days') <= 0; addDay++, date.add(1, 'days')) {
      // set date
      let finalDate = moment(payrollInstance.fromDate.date).add(addDay, 'days')

      // add correct date to array
      dates.push(finalDate)

      if (_.includes(holidayDates, date.format('YYYY-MM-DD'))) {
        overtimeTerms[`cct_${finalDate.format('DD/MM/YYYY')}`] = mappedOvertimes.holiday
      } else if (finalDate.locale('en').weekday() % 6 === 0) {
        overtimeTerms[`cct_${finalDate.format('DD/MM/YYYY')}`] = mappedOvertimes.weekend
      } else {
        overtimeTerms[`cct_${finalDate.format('DD/MM/YYYY')}`] = mappedOvertimes.normal
      }
    }

    return {
      ...payrollInstance.ref,
      payroll: { ...payrollInstance.payroll.ref },
      dates,
      overtimeTerms,
    }
  }
)

export const getPayrollInstancesByPayrollByStatus = createSelector(status, getPayrollId, ({ PayrollInstance }, statuses, payroll) => {
  return PayrollInstance.filter((instance) => instance.isAccessable && instance.payroll === payroll && statuses.includes(instance.status))
    .toRefArray()
    .map((payrollInstance) => ({ ...payrollInstance }))
})

export const getFilteredPayrollInstances = createSelector(getFilteredIdsByFilter, ({ PayrollInstance }, filteredIds) => {
  return filteredIds?.map((id) => PayrollInstance.withId(id).ref)
})

const getCompanyId = (state, props) => parseInt(props.companyId)

// All locked payruns
export const getLockedPayrollInstancesByCompanyId = createSelector(getCompanyId, ({ PayrollInstance }, companyId) =>
  PayrollInstance.filter((instance) => instance.companyId === companyId)
    .filter(
      (instance) =>
        [
          payrunStatusesEnum.SENT_AND_LOCKED,
          payrunStatusesEnum.GROSS_TO_NET_CALCULATIONS_AND_REVIEW_DONE,
          payrunStatusesEnum.COT_FINAL_PAYROLL_REPORTS_REVIEW_DONE,
        ].includes(instance.status) && instance.companyId === companyId
    )
    .toModelArray()
    .map((instance) => ({
      ...instance.ref,
    }))
)

export const getPayrollInstanceCompanyCountryTermsByPayrollInstance = createSelector(getPayrollInstanceId, ({ PayrollInstance }, payrollInstanceId) => {
  let initial = {}

  const terms = PayrollInstance.withId(payrollInstanceId).payrollInstanceCompanyCountryTerms.toRefArray()

  terms.forEach((term) => {
    initial[`term-${term.companyCountryTerm}`] = 'checked'
  })

  return initial
})
