import { DateType } from 'utils/date'
import { calcAverage, calcPercentageAverage } from '../utils/fnkit/calc'

/**
 * ReportingAnalytics Service
 * Responsible for housing the generics methods and settings required for each of the Reporting Analytics Reports
 */
export class ReportingAnalyticsService {
  /**
   * @param {array} rawData The full data set returned when getting the details from a the Reports API
   * @param {array} otherSheets The otherSheets data returned from the server reporting API
   * @param {object} mappingOptions A series of options that will be used throughout the file.  General contains logic from enums/reports.js
   * @param {object} charts The active charts for a given report.  Data is found from enums/reports.js
   * @param {object} chartLogic Chart logic that will be used throughout the Services.  Logic is currently obtained from the enums/reports.js
   */
  constructor (rawData = [], otherSheets = [], mappingOptions = {}, charts = {}, chartLogic = {}) {
    this.reportChartData = rawData
    this.options = mappingOptions
    this.otherSheetsData = otherSheets
    this.reportChartTypes = charts
    this.chartDisplayLogic = chartLogic
    this.mappedLineChart = []
    this.mappedLineChartLegend = []
    this.mappedSideBarData = []
    this.joinCount = 0
    this.leavesCount = 0
    this.essPieDataActive = 0
    this.essPieDataInactive = 0
    this.totalWorkCount = 0
    this.totalEmployerCosts = 0
    this.totalErContributionCosts = 0
    this.totalPayElementCosts = 0
    this.totalEeDeductionCosts = 0
    this.totalEeNetDeductionCosts = 0
    this.totalPayrollElementCosts = 0
    this.currency = null
    this.showFinanceTiles = false
    this.financePayrollCounts = {
      totalErCost: 0,
      totalPayrollCount: 0,
    }
    this.workflowTotalsCount = {
      avgOnTimeCompletionCompany: '0%',
      avgOnTimeCompletionPayrollProvider: '0%',
      avgOnTimeCompletionGtnVersions: 0,
    }
  }

  /**
   * Method to set the value of the total workforce count
   * @param {object} item The current report record item that will be assigned the total workforce count
   */
  setTotalData = (item) => {
    if (item[this.options.CHART_LOGIC.TOTALS_KEY] === this.options.CHART_LOGIC.TOTALS_KEY_VALUE) {
      this.totalWorkCount = item.total
    }
  }

  /**
   * Method to set the value of the total joiners workforce count
   * @param {object} item The current report record item that will be assigned the total joiners count
   */
  setTotalJoiners = (item) => {
    if (item[this.options.CHART_LOGIC.TOTALS_KEY] === this.options.CHART_LOGIC.TOTALS_KEY_VALUE) {
      this.joinCount = item.total
    }
  }

  /**
   * Method to set the value of the total joiners workforce count
   */
  setTotalJoinersByCalculation = () => {
    this.joinCount++
  }

  /**
   * Method to set the value of the total leavers workforce count
   * @param {object} item The current report record item that will be assigned the total leavers count
   */
  setTotalLeavers = (item) => {
    if (item[this.options.CHART_LOGIC.TOTALS_KEY] === this.options.CHART_LOGIC.TOTALS_KEY_VALUE) {
      this.leavesCount = item.total
    }
  }

  /**
   * Method to set the value of the total joiners workforce count
   */
  setTotalLeaversByCalculation = () => {
    this.leavesCount++
  }

  /**
   * Method to set the visibility of tile values with currency
   */

  setShowFinanceTilesWithCurrency = (currency) => {
    this.currency = currency
    this.showFinanceTiles = true
  }

  /**
   * Methods to set the total cost
   * @param {object} item The report item that will be assigned as the total costs values based on a report key from the chart logic
   */

  setTotalEmployerCosts = (item) => {
    this.totalEmployerCosts = item[this.options.CHART_LOGIC.TOTALS_REPORT_KEY]
  }

  setTotalErContributionCosts = (item) => {
    this.totalErContributionCosts = item[this.options.CHART_LOGIC.TOTALS_ER_CONTRIBUTION_REPORT_KEY]
  }

  setTotalPayElementCosts = (item) => {
    this.totalPayElementCosts = item[this.options.CHART_LOGIC.TOTALS_PAY_ELEMENT_REPORT_KEY]
  }
  setTotalPayrollElementCosts = (item) => {
    this.totalPayrollElementCosts = item[this.options.CHART_LOGIC.TOTALS_PAYROLL_ELEMENT_REPORT_KEY]
  }

  setTotalEeDeductionCosts = (item) => {
    this.totalEeDeductionCosts = item[this.options.CHART_LOGIC.TOTALS_EE_DEDUCTIONS_REPORT_KEY]
  }

  setTotalEeNetDeductionCosts = (item) => {
    this.totalEeNetDeductionCosts = item[this.options.CHART_LOGIC.TOTALS_EE_NET_DEDUCTIONS_REPORT_KEY]
  }

  setFinancePayrollCounts = (element) => {
    this.financePayrollCounts[element]++
  }

  setWorkFlowTotalsCount = (tileCalKeys, data) => {
    this.workflowTotalsCount = {
      avgOnTimeCompletionCompany: calcPercentageAverage(data, tileCalKeys.ON_TIME_COMPANY_KEY, 2),
      avgOnTimeCompletionPayrollProvider: calcPercentageAverage(data, tileCalKeys.ON_TIME_PAYROLL_PARTNER_KEY, 2),
      avgOnTimeCompletionGtnVersions: calcAverage(data, tileCalKeys.ON_TIME_GTN_VERSIONS_KEY, 2),
    }
  }

  /**
   * Method to set the value of the total employees that have an ESS activated
   * @param {object} item The current report record item that will be assigned the ESS activated count
   */
  setEssPieDataActive = (item) => {
    if (item[this.options.CHART_LOGIC.TOTALS_KEY] === this.options.CHART_LOGIC.ESS_ACTIVE_KEY) {
      this.essPieDataActive = item.total
    }
  }

  /**
   * Method to set the value of the total employees that have an ESS inactived
   * @param {object} item The current report record item that will be assigned the ESS inactived count
   */
  setEssPieDataInActive = (item) => {
    if (item[this.options.CHART_LOGIC.TOTALS_KEY] === this.options.CHART_LOGIC.ESS_INACTIVE_KEY) {
      this.essPieDataInactive = item.total
    }
  }

  /**
   * Method to filter out unused items from the legend for the line chart graph
   * @param {array} data Array of data
   */
  setLineChartDataLegend = (data) => {
    let usedList = new Set()
    data.forEach((item) => {
      Object.keys(item).forEach((i) => usedList.add(i))
    })
    this.lineChartDataLegend = Object.keys(this.options.CHART_LOGIC.LINE_CHART_DATAKEYS).filter((value) => usedList.has(value))
  }

  /**
   * Method to start the processing of a the reports other sheet data
   * This method will use the otherSteets data based, and is based on the chart logic having the totals key option set
   * It will Assign the values for the total workforce count, active ess as well as inactive ess employees
   */
  processOtherSheetsReportData = async () => {
    this.otherSheetsData.forEach((item) => {
      if (item.total && item[this.options.CHART_LOGIC.TOTALS_KEY]) {
        this.setTotalData(item)
        this.setEssPieDataActive(item)
        this.setEssPieDataInActive(item)
      }
    })
  }

  /**
   * Set the persons end date to the 1st day of the next month
   * @param {date} date The end date for the entity
   */
  adjustEndDateForNextMonth = (date) => {
    const dateToFirstOfMonth = new DateType(date.setDate(1))
    const dateNextMonth = new DateType(dateToFirstOfMonth.setMonth(dateToFirstOfMonth.getMonth() + 1))
    return dateNextMonth
  }

  /** GETTERS */

  /**
   * Method to retrieve the data for the ess activty count.
   *
   * @returns {array} The formatted data needed for the current pie chart layout.
   *  [{ name: 'Active', value: this.essPieDataActive }, { name: 'Inactive', value: this.essPieDataInactive }]
   */
  get essActivityPieData () {
    return [
      { name: 'Active', value: this.essPieDataActive, cssName: 'active' },
      {
        name: 'Inactive',
        value: this.essPieDataInactive,
        cssName: 'inactive',
      },
    ]
  }

  /**
   * @returns The Data required for the bar chart that is flipped
   */
  get sideBarChartData () {
    return this.sideBarData
  }

  /**
   * @returns {int} The total ess active count
   */
  get essPieDataActiveInActive () {
    return this.essPieData
  }

  /**
   * @returns {array} The Data required for the bar chart that is flipped
   */
  get sideBarDataMapped () {
    return this.mappedSideBarData
  }

  /**
   * @returns {array} The Data required for the line chart
   */
  get lineChartDataMapped () {
    return this.mappedLineChart
  }

  /**
   * @returns {array} The Data required for the line chart legend items
   */
  get lineChartDataLegend () {
    return this.mappedLineChartLegend
  }

  /**
   * @returns {int} The total joiners count
   */
  get joinersCount () {
    return this.joinCount
  }

  /**
   * @returns {int} The total workforce count
   */
  get globalWorkCount () {
    return this.totalWorkCount
  }

  /**
   * @returns {int} The total leavers count
   */
  get leaversCount () {
    return this.leavesCount
  }

  /**
   * @returns {array} The actual report data that has been processed
   */
  get chartData () {
    return this.reportChartData
  }

  /**
   * @returns The Data of the mapping options passed in
   */
  get mappingOptions () {
    return this.options
  }

  /**
   * @returns The Othersheets data returned from the server
   */
  get otherSheets () {
    return this.otherSheetsData
  }

  /**
   * @returns The data that contains the chart types that are activated for a given report
   */
  get chartTypes () {
    return this.reportChartTypes
  }

  /**
   * @returns The Data that contains chart logic, which is used to decorate the data
   */
  get chartLogic () {
    return this.chartDisplayLogic
  }

  /** SETTERS */
  set chartData (data) {
    this.reportChartData = data
  }

  set mappingOptions (options) {
    this.options = options
  }

  set otherSheets (otherShees) {
    this.otherSheetsData = otherShees
  }

  set chartTypes (charts) {
    this.reportChartTypes = charts
  }

  set chartLogic (logic) {
    this.chartDisplayLogic = logic
  }

  set sideBarChartData (data) {
    this.sideBarData = data
  }

  set lineChartDataMapped (data) {
    this.mappedLineChart = data
  }

  set lineChartDataLegend (data) {
    this.mappedLineChartLegend = data
  }
}
