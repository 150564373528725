import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { markAsRead } from 'redux/actions/notifications'
import NotificationForm from '../components/NotificationForm'

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => {
    return dispatch(markAsRead(data, false, true))
  },
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'notificationEditForm',
  })(NotificationForm)
)
