import React from 'react'

import CheckboxWrapper from './CheckboxWrapper'
import BaseField from './BaseField'

const CheckboxField = ({ input, label = '', type, className, indicatorClassName = '', ...rest }) => {
  return (
    <BaseField {...rest}>
      <CheckboxWrapper label={label} indicatorClassName={indicatorClassName} disabled={rest.disabled} data-testid={rest['data-testid']}>
        &nbsp;
        <input type={type} className={className} {...input} disabled={rest.disabled} />
      </CheckboxWrapper>
    </BaseField>
  )
}

export default CheckboxField
