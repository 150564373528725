import createSelector from 'utils/createSelector'
import { getVendorCountryTaskFunc } from './vendorCountryTask'
import { sortASCByPosition } from 'redux/models/task'

/**
 * Get vendor id
 * @param state
 * @param props
 */
const getVendorId = (state, props) => parseInt(props.vendorId)

/**
 * Get vendor service tree
 */
export const getVendorServiceTree = createSelector(getVendorId,
  ({ Vendor, VendorCountryTask }, vendorId) => {
    const vendor = Vendor.withId(vendorId)
    const tree = {}

    vendor.vendorCountryServices.toModelArray().map(vendorCountryService => {
      const countryService = vendorCountryService.countryService

      let countryId = countryService.ref.country
      let service = {}

      if (!tree.hasOwnProperty(countryId)) {
        tree[countryId] = {
          countryName: countryService.country.name,
          services: [],
        }
      }

      service.name = countryService.service.name
      service.processes = countryService.countryProcesses.toModelArray().map(countryProcess => {
        return {
          name: countryProcess.process.name,
          keyEvent: countryProcess.process.keyEvent,
          steps: countryProcess.countrySteps.toModelArray().map(countryStep => {
            return {
              name: countryStep.name || countryStep.step.name,
              tasks: countryStep.countryTasks.toModelArray().map(countryTask => {
                const vendorTask = getVendorCountryTaskFunc(VendorCountryTask, countryTask.id, vendorCountryService.id)

                return {
                  name: countryTask.name || countryTask.task.name,
                  position: countryTask.position !== undefined ? countryTask.position : countryTask.task.position,
                  owner: countryTask.task.owner,
                  finishTaskLink: countryTask.finishTaskLink,
                  deadlineMonthly: vendorTask ? vendorTask.deadlineMonthly : null,
                  deadlineFortnightly: vendorTask ? vendorTask.deadlineFortnightly : null,
                  deadlineWeekly: vendorTask ? vendorTask.deadlineWeekly : null,
                  countryTaskId: countryTask.id,
                  vendorCountryServiceId: vendorCountryService.id,
                  vendorCountryTaskId: vendorTask ? vendorTask.id : null,
                }
              }).sort(sortASCByPosition)
            }
          })
        }
      })
      tree[countryId].services.push(service)
    })

    return tree
  }
)
