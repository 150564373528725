import createSelector from 'utils/createSelector'
import { getSensitiveCountryDataProtectionFields } from 'redux/selectors/countryDataProtectionFields'
import { getCompanyDataProtectionFields } from 'redux/selectors/companyDataProtectionFields'
import { employeeFieldsConfig, extraEmployeeFieldsConfig } from 'redux/config/employeeFieldsConfig'
import moment from 'moment'

const getFilteredIds = (state, props) => state.approvedCompanyDataProtectionFieldsPivot.filteredIds
let employeeFieldsConfigForData = []
employeeFieldsConfig.forEach(fi => {
  const section = extraEmployeeFieldsConfig.find(eefc => eefc.category === fi.category)
  if (section) {
    fi.fields = [...fi.fields, ...section.fields]
  }
  employeeFieldsConfigForData.push(fi)
})

/**
 * Get all company DPF that are approved for a company
 */
export const getApprovedCompanyDataProtectionFields = createSelector(getFilteredIds,
  ({ ApprovedCompanyDataProtectionField, DataProtectionField }, filteredIds) => {
    // Structure all fields in categories
    return ApprovedCompanyDataProtectionField
      .filter(acdpf => filteredIds.includes(acdpf.id)).toModelArray()
      .map(acdpf => {
        const globalField = DataProtectionField.withId(acdpf.countryDataProtectionField.ref.dataProtectionField).ref
        let employeeDataFieldCat
        let employeeDataFieldObj

        if (globalField.entityClass.includes('EmployeeUser')) {
          employeeDataFieldCat = employeeFieldsConfigForData.find(cat => cat.fields.find(field => field.field === globalField.entityField))
          employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === globalField.entityField)
        }

        if (globalField.entityClass.includes('TermCategory')) {
          employeeDataFieldCat = employeeFieldsConfigForData.find(cat => cat.fields.find(field => field.field === globalField.fieldValue))
          employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === globalField.fieldValue)
        }

        return {
          ...acdpf.ref,
          companyDataProtectionFieldRevision: {
            ...acdpf.companyDataProtectionFieldRevision.ref,
            ...acdpf.companyDataProtectionFieldRevision.modifiedBy ? { modifiedBy: acdpf.companyDataProtectionFieldRevision.modifiedBy.ref } : {}
          },
          name: employeeDataFieldObj.name,
          dataProtectionField: { ...globalField }
        }
      })
  })

/**
 * Get sensitive country data protection fields list and map it with all approved company dpf list,
 * since we don't have country dpf -> company dpf inheritance
 */
export const getFullCompanyDataProtectionFieldsForApprovalList = createSelector(getSensitiveCountryDataProtectionFields, getApprovedCompanyDataProtectionFields,
  (session, countryDataProtectionFields, approvedCompanyDataProtectionFields) => {
    const fullList = []
    countryDataProtectionFields.map(cat => {
      fullList.push({
        category: cat.category,
        categoryName: cat.categoryName,
        fields: cat.fields.map(field => {
          const approvedField = approvedCompanyDataProtectionFields.find(approvedField => field.id === approvedField.countryDataProtectionField)
          const isFieldApproved = approvedField ? approvedField.isApproved : true
          return {
            ...field,
            isApproved: isFieldApproved
          }
        })
      })
    })
    return fullList
  })

/**
 * Get latest approval `modifiedBy` and `modifiedAt` info
 */
export const getLatestApproval = createSelector(getApprovedCompanyDataProtectionFields,
  ({ EmployeeSystemUser }, approvedFields) => {
    // get only first element from approvedFields since all of the fields
    // have the same `modifiedBy` and `modifiedAt` props
    const [approvedField] = approvedFields
    const hasApprovedFields = approvedFields.length > 0
    return {
      hasApprovedFields,
      modifiedAt: hasApprovedFields ? moment(approvedField.companyDataProtectionFieldRevision.modifiedAt.date).format('DD/MM/YYYY') : null,
      // eslint-disable-next-line max-len
      modifiedBy: hasApprovedFields && approvedField.companyDataProtectionFieldRevision.modifiedBy ? approvedField.companyDataProtectionFieldRevision.modifiedBy.fullname : null
    }
  })

/**
 * Get initialValues for approve fields form
 * Set currently approved fields to `true`
 */
export const getApprovedFieldsInitialValues = createSelector(getFullCompanyDataProtectionFieldsForApprovalList,
  (session, fullList) => {
    let initialValues = {}
    fullList.map(cat => {
      cat.fields.forEach(field => {
        initialValues[`id-${field.id}`] = field.isApproved
        // Get country isSensitive values in case we need to show
        // 'Country value' column when rendering approve fields form
        initialValues[`isSensitive-${field.id}`] = field.isSensitive
      })
    })

    return initialValues
  })

/**
 * Get all approved company dpf list and map it with country dpf with data list
 */
export const getApprovedCompanyDataProtectionFieldsWithData = createSelector(getApprovedCompanyDataProtectionFields, getCompanyDataProtectionFields,
  (session, approvedFields, fieldsWithData) => {
    return approvedFields.filter(field => field.isApproved)
      .map(field => ({
        ...field,
        hasData: fieldsWithData.find(fieldWithData => fieldWithData.dataProtectionField.id === field.dataProtectionField.id) !== undefined
      }))
  })
