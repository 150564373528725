import React, { forwardRef, useCallback } from 'react'

import DatePicker from 'react-datepicker'
import { formatDate } from 'utils/date'
import { isValid } from 'date-fns'
import { isEmpty } from 'utils/fnkit/typeChecks'

const CustomInput = forwardRef((props, ref) => <input ref={ref} {...props} />)

export const DateWidget = ({ id, value, onChange, options }) => {
  const selected = typeof value === 'string' ? new Date(value) : value
  const minDate = options?.minDate || new Date(1974, 0, 1)
  const maxDate = options?.maxDate

  const handleChange = useCallback(
    (newDate) => {
      const filterDate = newDate ? formatDate(newDate, options?.valueFormat) : undefined
      const isValidDate = isValid(new Date(filterDate))
      if (isValidDate || isEmpty(filterDate)) {
        onChange(filterDate)
      }
    },
    [onChange]
  )
  return (
    <DatePicker
      id={id}
      className='c-input normal-font-weight'
      autoComplete='off'
      minDate={minDate}
      maxDate={maxDate}
      yearDropdownItemNumber={50}
      {...options}
      selected={selected}
      onChange={handleChange}
      placeholderText={options?.placeholder ?? null}
      customInput={<CustomInput />}
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
    />
  )
}
