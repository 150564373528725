import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const ASSIGNMENTS_FETCH = 'ASSIGNMENTS_FETCH'
export const ASSIGNMENTS_RECEIVE = 'ASSIGNMENTS_RECEIVE'
export const ASSIGNMENTS_FILTER = 'ASSIGNMENTS_FILTER'
export const ASSIGNMENTS_FILTERED = 'ASSIGNMENTS_FILTERED'
export const ASSIGNMENTS_UPDATE = 'ASSIGNMENTS_UPDATE'
export const ASSIGNMENTS_RESET = 'ASSIGNMENTS_RESET'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: ASSIGNMENTS_FETCH,
  receive: ASSIGNMENTS_RECEIVE,
  filter: ASSIGNMENTS_FILTER,
  filtered: ASSIGNMENTS_FILTERED,
  reset: ASSIGNMENTS_RESET
}
const actionUtility = new ActionUtility(actionTypes, 'assignments', 'assignments', 'Assignment')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchAssignmentsIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchAssignments = actionUtility.fetchEntities
export const resetAssignments = actionUtility.reset

/*
 * Updating assignments is related to the so called `trello` board.
 * Here we can update single assignment as well as multiple assignments in bulk.
 *
 * Right data format for both is as follows:
 *
 * PATCH: /assignments
 * Request body:
 * [
 *   {
 *      type: string,
 *      company: string,
 *      status: string,
 *      id: number
 *   }
 * ]
 *
 * @param {Array} entity - chosen assignments
 * @param {String} status - target status lane
 *
 */
export const updateAssignment = (entity, status) => {
  return (dispatch, getState, { api }) => {
    // Define data format for edit
    const data = entity.map(e => ({
      type: e.type,
      company: e.company,
      id: e.extraData.id,
      status: status
    }))

    // Define uri for single and
    const uri = 'assignments'

    dispatch({
      type: ASSIGNMENTS_UPDATE,
      uri
    })

    return api.patch(uri, { payload: data })
      .then((response) => {
        return response
      })
  }
}
