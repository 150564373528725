import React, { useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { LeaveConfirmationModal, LeaveRouteConfirmationModal } from 'components/ReusableModals/LeaveConfirmationModal'

import { buttonActions } from 'utils/locales/common.en'
import { additionalItemsMode } from '../utils/additionalItemsStateReducer'
import { createAdditionalItem, saveAdditionalItems } from 'redux/actions/additionalItems'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import { validateCommonPreRequestErrors } from 'routes/Employees/routes/Employee/selectors/formErrors'
import { isInPrePayrollOrIsReopened } from 'utils/payrollInstanceStatuses'
import { showMessage } from 'redux/actions/modal'
import { errorMessageLabels } from 'utils/locales/errors.en'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

export const AdditionalItemsActions = ({ mode, payrollInstance, hasItems, isDirty, tableChangedData, toggleMode, notifyErrors, refreshState }) => {
  const navigationLockedModalRef = useRef()
  const payrollInstanceId = payrollInstance.id

  const dispatch = useDispatch()

  const inAddMode = mode === additionalItemsMode.add
  const inEditMode = mode === additionalItemsMode.edit
  const isDisabledEdit = inAddMode || !hasItems
  const itemsTosave = tableChangedData?.filter((item) => item.isDirty)
  const hasTheProperlyPayrollInstanceState = isInPrePayrollOrIsReopened(payrollInstance)
  const canDefineContent = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state)) && hasTheProperlyPayrollInstanceState

  const toggleAdd = () => toggleMode(additionalItemsMode.add)
  const toggleEdit = () => toggleMode(additionalItemsMode.edit)
  const handleIfInecessaryLeaveModal = (cb) => {
    if (isDirty) {
      navigationLockedModalRef?.current?.showModal()
      return
    }
    cb()
  }

  const saveEditChanges = async () => dispatch(saveAdditionalItems(itemsTosave))
  const saveAdditionalItem = async () => {
    const entityToSave = itemsTosave.find((entity) => entity?.isNew)
    return dispatch(createAdditionalItem(payrollInstanceId, entityToSave, { shouldFetch: false }))
  }

  const storeData = async () => {
    const saveCallback = inEditMode ? saveEditChanges : saveAdditionalItem
    const response = await saveCallback()

    if (response?.hasErrors) {
      notifyErrors(response.errors)
      return
    } else if (response?.hasCommonErrors) {
      dispatch(showMessage({ body: errorMessageLabels.somethingWentWrong }))
    }

    refreshState()
  }

  const handleSave = async () => {
    const canSave = isDirty || inAddMode
    if (canSave) {
      const { isValid, errors } = validateCommonPreRequestErrors(itemsTosave, 'item')

      if (!isValid) {
        notifyErrors(errors)
        return
      }
      storeData()
    }

    if (!isDirty && !inAddMode) {
      refreshState()
    }
  }

  return (
    <div className='d-flex u-float--right'>
      {mode && (
        <>
          <LeaveConfirmationModal modalRef={navigationLockedModalRef} onConfirmation={refreshState} />
          <LeaveRouteConfirmationModal isDirty={isDirty} />
          <button
            onClick={() => {
              handleIfInecessaryLeaveModal(refreshState)
            }}
            className={btnClasses}
            title={buttonActions.cancel}
            data-testid='additional-items-cancel'
          >
            {buttonActions.cancel}
          </button>
          <button onClick={handleSave} className={btnClasses} title={buttonActions.save} data-testid='additional-items-save'>
            {buttonActions.save}
          </button>
        </>
      )}
      {canDefineContent && (
        <button
          onClick={toggleEdit}
          className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
          title={buttonActions.edit}
          disabled={isDisabledEdit}
          data-testid='additional-items-edit'
        >
          <span className='icon icon--edit' />
        </button>
      )}
      {canDefineContent && (
        <button
          onClick={toggleAdd}
          className={`${btnClasses} ${mode ? 'disabled' : ''}`}
          title={buttonActions.add}
          disabled={mode}
          data-testid='additional-items-create'
        >
          <span className='icon icon--plus' />
        </button>
      )}
    </div>
  )
}
