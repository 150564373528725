import moment from 'moment'

/**
 * Filter the collection by period
 *
 * @param {} collection
 * @param string dateProp - collection's date property
 * @param string fromDate
 * @param string toDate
 * @return {} collection
 */
export const filterByPeriod = (collection, dateProp, fromDate, toDate) => {
  return collection
    .filter(el => {
      // collection date property
      let elDate = moment(el[dateProp].date)

      // get booleans
      let isAfter = elDate.isSameOrAfter(moment(fromDate))
      let isBefore = elDate.isSameOrBefore(moment(toDate))

      // return result
      return (isAfter && isBefore)
    })
}
