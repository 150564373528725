import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/costCenters'

class CostCenter extends CRUDModel {}
CostCenter.modelName = 'CostCenter'
CostCenter.actionTypes = actionTypes

CostCenter.fields = {
  id: attr(),
  name: attr(),
  company: fk('Company', 'costCenters'),
}

export default CostCenter
