import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { fetchFxRatesIfNeeded } from 'redux/actions/fxRates'
import { getFxRatesByFromCurrenciesAndToCurrency } from 'redux/selectors/fxRates'
import PredefinedRateForm from '../components/PredefinedRateForm'

class ReportingContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props

    dispatch(fetchFxRatesIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <PredefinedRateForm {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const { fxRates } = state

  const entities = [ fxRates ]

  if (isFetching(entities)) return { isFetching: true }

  return {
    fxRates: getFxRatesByFromCurrenciesAndToCurrency(state, { fromCurrencies: [props.selected.payrollCurrency], toCurrency: props.selected.toCurrency })
  }
}

export default connect(mapStateToProps)(ReportingContainer)
