import isEmpty from 'lodash/isEmpty'

export const verifyData = (data) => {
  let normalize = [...data]
  let errors = []
  normalize.forEach(d => {
    let error = {}
    let hasErrors = false
    if (isEmpty(d.accountType.trim())) {
      hasErrors = true
      error['accountType'] = ['Field is Required']
    }
    if (isEmpty(d.accountNumber.trim())) {
      hasErrors = true
      error['accountNumber'] = ['Field is Required']
    }
    if (hasErrors) {
      error['id'] = d.id
      errors.push(error)
    }
  })
  return errors
}
/**
 * Formats the data into a structure needed for the backend to make a PUT
 * for multiple objects.
 * Converts [{id: 1..., id: 2...}] to
 * {1: {...}, 2: {...}}
 * @param {[]} data Data that will be sent as a payload
 * @returns obj
 */
export const formatDataForGlobalSave = (data) => {
  return data.filter(d => d.isDirty)
    .reduce((result, item) => {
      const { id, isDirty, ...rest } = item

      return {
        ...result,
        [id]: {
          ...rest
        },
      }
    }, {})
}
