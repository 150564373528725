import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const TERM_CATEGORIES_FETCH = 'TERM_CATEGORIES_FETCH'
export const TERM_CATEGORIES_RECEIVE = 'TERM_CATEGORIES_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: TERM_CATEGORIES_FETCH,
  receive: TERM_CATEGORIES_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'termCategories', 'termcategories')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchTermCategoriesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchTermCategories = actionUtility.fetchEntities
