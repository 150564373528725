import React from 'react'
import PropTypes from 'prop-types'
import InputField from 'components/form/InputField'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { Field } from 'redux-form'
import { sortByLabel } from 'utils/strings'

const CompanyBankAccountForm = (props) => {
  const {
    countries,
    companies,
    currencies,
    bankAccountTypes,
    country,
    company,
    bankCountry,
    disabled,
    customFields
  } = props

  return <Form {...props}>
    <div className='o-layout'>
      <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
        <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
          <Field
            name='country'
            label='Payroll country *'
            component={CustomSelectField}
            className='c-custom-select--transparent'
            labelClassName='c-label u-text--small u-text--curious'
            formGroupClassName='u-margin-none'
            placeholder='Select'
            disabled={disabled}
            options={countries.map(country => ({
              value: country.id,
              label: country.name
            }))}
            data-testid='country-wrapper'
          />
        </div>
      </div>
      {
        country
          ? <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='company'
                label='Company *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                disabled={disabled}
                options={companies.map(company => ({
                  value: company.id,
                  label: company.name
                })).sort(sortByLabel)}
                data-testid='company-wrapper'
              />
            </div>
          </div>
          : null
      }
      {
        country && company
          ? <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='bankCountry'
                label='Bank country *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={bankAccountTypes.map(type => ({
                  value: type['country_abbr'],
                  label: type.country.name
                }))}
                data-testid='bankCountry-wrapper'
              />
            </div>
          </div>
          : null
      }
      {
        country && company && bankCountry
          ? <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
            <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
              <Field
                name='currency'
                label='Currency *'
                component={CustomSelectField}
                className='c-custom-select--transparent'
                labelClassName='c-label u-text--small u-text--curious'
                formGroupClassName='u-margin-none'
                placeholder='Select'
                options={currencies.map(currency => ({
                  value: currency.id,
                  label: currency.abbreviature
                }))}
                data-testid='currency-wrapper'
              />
            </div>
          </div>
          : null
      }
      {
        customFields
          ? <div>
            <div className='o-layout__item  u-margin-bottom u-1/1
                u-1/2@tablet u-1/4@desktop' data-testid='bankName-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='bankName'
                  label='Bank name'
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bankName'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom u-1/1
              u-1/2@tablet u-1/4@desktop' data-testid='bankAddress-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='bankAddress'
                  label='Bank address'
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='bankAddress'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='typeOfAccount'
                  label='Type of account'
                  component={CustomSelectField}
                  className='c-custom-select--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  placeholder='Select'
                  options={[
                    { value: 'Saving', label: 'Saving' },
                    { value: 'Checking', label: 'Checking' },
                    { value: 'Current', label: 'Current' },
                    { value: 'Other', label: 'Other' }
                  ].map(opt => ({
                    value: opt.value,
                    label: opt.label
                  }))
                  }
                  data-testid='type-of-account-wrapper'
                />
              </div>
            </div>
            <div className='o-layout__item  u-margin-bottom
              u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='accountHolder-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='accountHolder'
                  label='Account holder name'
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='accountHolder'
                />
              </div>
            </div>
            {
              customFields.map(field => {
                return <div
                  key={field.name}
                  className='o-layout__item  u-margin-bottom
                  u-1/1 u-1/2@tablet u-1/4@desktop' data-testid={`${field.name}-wrapper`}>
                  <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                    <Field
                      name={field.name}
                      label={field['printable_name']}
                      component={InputField}
                      type='text'
                      className='c-input c-input--transparent'
                      labelClassName='c-label u-text--small u-text--curious'
                      formGroupClassName='u-margin-none'
                      data-testid={`${field.name}`}
                    />
                  </div>
                </div>
              })
            }
            <div className='o-layout__item  u-margin-bottom
            u-1/1 u-1/2@tablet u-1/4@desktop' data-testid='description-wrapper'>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name='description'
                  label='Description'
                  component={InputField}
                  type='text'
                  className='c-input c-input--transparent'
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid='description'
                />
              </div>
            </div>
          </div>
          : null
      }
      <div className='o-layout__item u-text--center u-1/1'>
        <button
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
          data-testid='submit'
        >
          Save
        </button>
      </div>
    </div>
  </Form>
}

CompanyBankAccountForm.propTypes = {
  countries: PropTypes.array,
  companies: PropTypes.array,
  currencies: PropTypes.array,
  bankAccountTypes: PropTypes.array,
  customFields: PropTypes.array,
  country: PropTypes.number,
  company: PropTypes.number,
  bankCountry: PropTypes.string,
  disabled: PropTypes.bool
}

export default CompanyBankAccountForm
