import { graphTypesEnum } from 'utils/enums/analyticsEnums'
import { ReportingAnalyticsService } from './ReportingAnalyticsService'
import { consoleTextErrors } from '../utils/locales/errors.en'

export class ReportingAnalyticsSideBarService extends ReportingAnalyticsService {
  /**
   * @param {array} rawData The full data set returned when getting the details from a the Reports API
   * @param {array} otherSheets The otherSheets data returned from the server reporting API
   * @param {object} mappingOptions A series of options that will be used throughout the file.  General contains logic from enums/reports.js
   * @param {object} charts The active charts for a given report.  Data is found from enums/reports.js
   * @param {object} chartLogic Chart logic that will be used throughout the Services.  Logic is currently obtained from the enums/reports.js
   */
  constructor (rawData = [], otherSheets = [], mappingOptions = {}, charts = [], chartLogic = {}) {
    super(rawData, otherSheets, mappingOptions, charts, chartLogic)
    this.filteredDownReportTypes = this.getFilteredChartTypes()
    this.filteredDownRecords = []
    this.recordsByType = new Map()
  }

  /**
   * Method to filter out the unrelated report types for a given report
   * The full details come enums/reports.js.  But this service only cares about Side bar charts reports (graphTypesEnum.SIDE_BARCHART)
   * @returns {array} The Pie charts that are activated for this report
   */
  getFilteredChartTypes = () => {
    const filteredCharts = Object.entries(this.chartTypes).filter(([key, value]) => value === graphTypesEnum.SIDE_BARCHART)
    return Object.fromEntries(filteredCharts)
  }

  /**
   * Private method to handle remapping a field given the settings provided
   * @param {object} record The report item records
   * @param {object} filter The current filter settings set in enum/report.js
   * @returns {object}
   */
  _adjustedTitleForChart = (record, filter) => {
    let item = record[filter.individualRecords.field]
    if (filter.individualRecords && record[filter.individualRecords.field]) {
      const { fieldFormatter } = filter.individualRecords
      if (fieldFormatter.exclude) {
        item = record[filter.individualRecords.field].replace(fieldFormatter.exclude, fieldFormatter.replace)
      }
    }
    return item
  }

  /**
   * Method to start the processing for the side bar charts
   * This method handles filter out values based on the logic passed in, as well as decorated the data for the x column
   * The keys can be set in the enum/reports.js for values to look for and replace
   * ex. setting a blackList will remove the records, whiteList to keep records, field: the key in the data to look for
   * and remapField to remap the field to a given name (i.e field: country, remapField: 'Country') on an object {country: 'Ireland'}
   * will look for that country field, take it's value and map it to a new key { [remapField]: 'Ireland' }.  This is to maintain
   * the original structure and provide the option to customize what field should be called afterwars
   * @returns null | string
   */
  processReportData = () => {
    if (Object.keys(this.filteredDownReportTypes).length === 0) return 'No Charts Specified'
    if (!this.options.CHART_LOGIC.SIDE_BARCHART_EXCLUSIONS) return 'No Filters Provided'

    const filters = this.options.CHART_LOGIC.SIDE_BARCHART_EXCLUSIONS
    const data = this.options.CHART_LOGIC.OTHER_SHEETS_TOTALS ? [...this.otherSheetsData] : [...this.chartData.data]

    data.forEach((record) => {
      let isFieldWhitelisted = true
      if (filters.individualRecords && record[filters.individualRecords.field]) {
        const { field, whiteList, blackList } = filters.individualRecords
        isFieldWhitelisted = record[field] ? record[field].toLowerCase().includes(whiteList) : false

        if (blackList && isFieldWhitelisted) {
          isFieldWhitelisted = !blackList.find((list) => record[field].toLowerCase().includes([list]))
        }
      }

      if (isFieldWhitelisted) {
        record[filters.individualRecords.remapField] = this._adjustedTitleForChart(record, filters)
        this.sideBarDataMapped.push(record)
      }
    })
  }

  getTotalsData = (totalsKey) => {
    let data
    try {
      data = this.chartData.otherSheets.find((r) => r.name === totalsKey).data || []
    } catch (e) {
      console.error(consoleTextErrors.onTransformData)
      data = []
    }
    return data
  }

  getCountsByQueryType = (type) => {
    const data = [...this.chartData.data]
    const xAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.xAxis
    const yAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.yAxis

    if (!type) return []
    let dataCount = []
    const storedRecordType = {}

    if (!this.recordsByType.has(type)) {
      this.recordsByType.set(type, storedRecordType)
    } else {
      return this.recordsByType.get(type)
    }

    data.forEach((record) => {
      const typesValue = record[type] || 'Not Assigned'
      if (!storedRecordType[typesValue]) {
        storedRecordType[typesValue] = 1
      } else {
        storedRecordType[typesValue] += 1
      }
    })

    Object.keys(storedRecordType).forEach((k) => {
      dataCount.push({
        [xAxis]: k,
        [yAxis]: storedRecordType[k],
        options: { dontUseFlags: true },
      })
    })
    this.recordsByType.set(type, dataCount)

    return this.recordsByType.get(type)
  }

  calculateTotalsByQueryType = (type) => {
    const data = [...this.chartData.data]
    const xAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.xAxis
    const yAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.yAxis

    if (!type) return []
    let dataCount = []
    const storedRecordType = {}

    if (!this.recordsByType.has(type)) {
      this.recordsByType.set(type, storedRecordType)
    } else {
      return this.recordsByType.get(type)
    }

    data.forEach((record) => {
      const typesValue = record[type] || null
      if (typesValue) {
        const value = parseFloat(record[yAxis]) || 0
        if (!storedRecordType[`${typesValue}-${record.currency}`]) {
          storedRecordType[`${typesValue}-${record.currency}`] = value
        } else {
          storedRecordType[`${typesValue}-${record.currency}`] += value
        }
      }
    })

    Object.keys(storedRecordType).forEach((k) => {
      const [, x, currency] = k.match(/(.+)-(\w+)/)
      dataCount.push({
        [xAxis]: x,
        [yAxis]: storedRecordType[k],
        options: { dontUseFlags: true },
        currency: currency,
      })
    })
    this.recordsByType.set(type, dataCount)

    return this.recordsByType.get(type)
  }
  calculateTotalsByQueryElementAndType = (first, type) => {
    const data = [...this.chartData.data]

    const xAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.xAxis
    const yAxis = this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.yAxis

    if (!type) return []
    let dataCount = []
    const storedRecordType = {}

    data.forEach((record) => {
      const typesValue = record[type] || null

      if (typesValue) {
        const value = parseFloat(record[first]) || 0
        if (!storedRecordType[`${typesValue}-${record.currency}`]) {
          storedRecordType[`${typesValue}-${record.currency}`] = value
        } else {
          storedRecordType[`${typesValue}-${record.currency}`] += value
        }
      }
    })

    Object.keys(storedRecordType).forEach((k) => {
      const [, x, currency] = k.match(/(.+)-(\w+)/)
      dataCount.push({
        [xAxis]: x,
        [yAxis]: storedRecordType[k],
        options: { dontUseFlags: true },
        currency: currency,
      })
    })

    this.recordsByType.set(type, dataCount)

    return this.recordsByType.get(type)
  }

  calculateDataBasedOnYAxis = ({ type, withTotals, disabledRecordByType, axis }) => {
    const data = withTotals ? this.getTotalsData(this.options.CHART_LOGIC.TOTALS_KEY_VALUE) : [...this.chartData.data]
    const xAxis = axis?.xAxis || this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.xAxis
    const yAxis = axis?.yAxis || this.options.CHART_LOGIC.SIDE_BARCHART_AXIS.yAxis

    if (!type) return []

    if (!disabledRecordByType && this.recordsByType.has(type)) {
      return this.recordsByType.get(type)
    }

    const dataCount = data.reduce((chartCount, record) => {
      const typesValue = record[type] || this.options.CHART_LOGIC.NOT_ASSIGNED_TEXT
      let chartRow = chartCount.find((item) => item[xAxis] === typesValue)
      if (!chartRow) {
        return [
          ...chartCount,
          {
            [xAxis]: typesValue,
            [yAxis]: record[yAxis],
            options: { dontUseFlags: true },
          },
        ]
      }
      chartRow[yAxis] = chartRow[yAxis] + record[yAxis]
      return chartCount
    }, [])

    if (!disabledRecordByType) {
      this.recordsByType.set(type, dataCount)
    }
    return dataCount
  }
}
