import CoreLayout from 'layouts/PageLayout/PageLayout'
import InnerWrapper from 'layouts/InnerWrapper'
import RouteView from './components/RouteView'

export default (store) => ({
  path : 'icp-costing',
  wrappedComponent: CoreLayout,
  authProtected: true,
  indexRoute: { component: RouteView, wrappedComponent: InnerWrapper }
})
