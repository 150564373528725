import React from 'react'
import BankAccountFormEdit from '../components/BankAccountFormEdit'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchBankAccountTypesIfNeeded } from 'redux/actions/bankAccountTypes'
import { fetchBankAccounts, updateBankAccount } from 'redux/actions/bankAccounts'
import { getCountries } from 'routes/Countries/selectors/countries'
import { getCompanyRef, getCompaniesByCountry } from 'redux/selectors/company'
import {
  getBankAccountTypes,
  getCurrenciesByBankAccountCountryAbbr,
  getFieldsByBankAccountCountryAbbrAndCurrency
} from 'redux/selectors/bankAccountTypes'
import { getBankAccountById, getBankAccountCustomFieldsInitials } from 'redux/selectors/bankAccounts'
import { getUser } from 'redux/selectors/employees'
import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { sortByCountryName, sortByName } from 'utils/strings'

class BankAccountFormEditContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCountriesIfNeeded()
    this.props.fetchCurrenciesIfNeeded()
    this.props.fetchBankAccountTypesIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <BankAccountFormEdit {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchCurrenciesIfNeeded: () => dispatch(fetchCurrenciesIfNeeded()),
    fetchBankAccountTypesIfNeeded: () => dispatch(fetchBankAccountTypesIfNeeded()),
    onSubmit: (data) => dispatch(updateBankAccount(data, data.bankAccountId))
      .then(() => {
        props.onSubmit()
        dispatch(fetchBankAccounts())
      })
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { countries, currencies, bankAccountTypes } = state

  if (isFetching([countries, currencies, bankAccountTypes])) return { isFetching: true }

  const companyId = props.match.params.companyId
  const employeeId = props.match.params.employeeId

  const employee = employeeId ? getUser(state, { userId: employeeId }) : null
  const currentCompany = companyId
    ? getCompanyRef(state, { companyId })
    : getCompanyRef(state, { companyId: employee.company })
  const isHQ = companyId ? currentCompany.isHQ : null

  const selector = formValueSelector('bankAccountEdit')
  const values = selector(state, 'country', 'company', 'bankCountry', 'currency')
  const bankAccount = getBankAccountById(state, { bankAccountId: props.bankAccountId })

  const initialValues = getBankAccountCustomFieldsInitials(state, { bankAccountId: bankAccount.id })

  const customFields = values.country && values.company && values.bankCountry && values.currency
    ? getFieldsByBankAccountCountryAbbrAndCurrency(state, {
      bankCountry: values.bankCountry, currency: values.currency
    }) : null

  return {
    countries: getCountries(state).sort(sortByName),
    companies: values.country ? getCompaniesByCountry(state, { countryId: values.country }) : [],
    currencies: values.bankCountry
      ? getCurrenciesByBankAccountCountryAbbr(state, { bankCountry: values.bankCountry }) : [],
    bankAccountTypes: uniqBy(getBankAccountTypes(state), 'country_abbr').sort(sortByCountryName),
    bankAccount,
    customFields,
    country: values.country,
    company: values.company,
    bankCountry: values.bankCountry,
    disabled: !isHQ,
    employee,
    initialValues: {
      ...initialValues,
      country: bankAccount.country,
      company: bankAccount.company,
      bankCountry: bankAccount.bankCountry,
      currency: bankAccount.currency,
      bankAccountId: bankAccount.id,
      accountHolder: bankAccount.accountHolder,
      bankName: bankAccount.bankName,
      bankAddress: bankAccount.bankAddress,
      description: bankAccount.description,
      typeOfAccount: bankAccount.typeOfAccount,
      payrollCountry: bankAccount.country,
    }
  }
}

BankAccountFormEditContainer.propTypes = {
  fetchCountriesIfNeeded: PropTypes.func,
  fetchCurrenciesIfNeeded: PropTypes.func,
  fetchBankAccountTypesIfNeeded: PropTypes.func,
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bankAccountEdit'
})(BankAccountFormEditContainer))
