const TextEnums = {
  CHECK_UPDATED_TIME_LINES: 'Check the updated timelines',
  CHECK_UPDATED_WORKFLOW: 'Check the updated workflow',
  REVIEW_IMPORTED: 'Imported GTN data in the platform.',
  GTN_MAPPING_REPORT: 'GTN Mapping report',
  REVIEW_MAPPING_REPORT: 'Review Mapping report'

}
/**
 * General Class for generating the additional information needed for notifications
 * Can be used to general
 */
class NotificationExtraLinkBuilder {
  constructor ({
    notification = null,
    notificationType = null,
    isVendor = false,
    isCot = false,
    isGTNFileSuccessfullyImported = false
  }) {
    this.notification = notification
    this.notificationType = notificationType
    this.isVendor = isVendor
    this.isCot = isCot
    this.isGTNFileSuccessfullyImported = isGTNFileSuccessfullyImported
  }
  /**
   * Method to handle the fetching of the addition text for notifications
   */
  get linkAdditionalText () {
    let text = null
    switch (this.notificationType) {
    case 'PaydateChangedOnPayrunNotification':
      text = TextEnums.CHECK_UPDATED_TIME_LINES
      break
    case 'ActionTimelineChangedOnPayrunNotification':
      text = TextEnums.CHECK_UPDATED_TIME_LINES
      break
    case 'ActionTimelineChangedOnPayrollNotification':
      if (!this.isVendor) text = TextEnums.CHECK_UPDATED_WORKFLOW
      break
    case 'DocumentGTNReportSharedNotificationCOT':
      text = this.isGTNFileSuccessfullyImported
        ? TextEnums.REVIEW_IMPORTED
        : `${TextEnums.GTN_MAPPING_REPORT} ${this.notification.fileName} was generated.`
      break
    case 'DocumentGTNReportSharedNotificationER':
      text = this.isGTNFileSuccessfullyImported
        ? TextEnums.REVIEW_IMPORTED
        : `${TextEnums.REVIEW_MAPPING_REPORT} ${this.notification.fileName} and resolve issues.`
      break
    default:
      break
    }
    return text
  }
}

export default NotificationExtraLinkBuilder
