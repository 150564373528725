import React from 'react'

/* eslint-disable max-len */
export const NotStartedIcon = ({ className, children, title, ...props }) => (
  <svg className={className} width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M8.5 8.5L16 16' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 8.5L8.5 16' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <circle cx='12.25' cy='12.25' r='11.25' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export const InProgressIcon = ({ className, children, title, ...props }) => (
  <svg className={className} width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.864 23C19.8762 23 24.75 18.0751 24.75 12C24.75 5.92487 19.8762 1 13.864 1C10.64 1 7.74329 2.41625 5.75 4.66667'
      stroke='var(--ps-trello-icon-color)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M13.75 5V12.3333L18.75 15' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.8665 22.8677C9.29769 22.4474 7.83848 21.6923 6.58919 20.6544C5.33991 19.6165 4.33016 18.3204 3.62936 16.8552C2.92856 15.39 2.55333 13.7905 2.52945 12.1665C2.50557 10.5425 2.83361 8.93255 3.49103 7.44738'
      stroke='var(--ps-trello-icon-color)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='5 5'
    />
  </svg>
)

export const CompletedIcon = ({ className, children, title, ...props }) => (
  <svg className={className} width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M6 13.5L9.75 17.25L18.5 8.5' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <circle cx='12.25' cy='12.25' r='11.25' stroke='var(--ps-trello-icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
