import React from 'react'
import PayslipTable from 'components/table/PayslipTable'
import { buttonActions } from 'utils/locales/common.en'
const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-left-tiny'

const CommentaryView = ({ tableHeadings, data, fieldErrors, toggleModes, inEditMode, inAddMode, onCancel, onSave, onUpdateGlobalDataForSave }) => {
  const editableOptions = {
    inGlobalEditMode: inEditMode,
    inAddMode: inAddMode,
    fieldErrors: fieldErrors,
    onUpdateGlobalData: onUpdateGlobalDataForSave,
  }
  const isDisabledEdit = inAddMode || data?.length === 0
  return (
    <div className='rounded border u-padding-small'>
      <div className='u-float--right'>
        {(inAddMode || inEditMode) && (
          <>
            <button onClick={onCancel} className={btnClasses} title={buttonActions.cancel} data-testid='general-ledger-account-cancel'>
              {buttonActions.cancel}
            </button>
            <button onClick={onSave} className={btnClasses} title={buttonActions.save} data-testid='general-ledger-account-save'>
              {buttonActions.save}
            </button>
          </>
        )}
        <button
          onClick={() => toggleModes('edit', !inEditMode)}
          className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
          title={buttonActions.edit}
          disabled={isDisabledEdit}
          data-testid='general-ledger-account-edit'
        >
          <span className='icon icon--edit' />
        </button>
        <button
          onClick={() => toggleModes('add', !inAddMode)}
          className={`${btnClasses} ${inEditMode || inAddMode ? 'disabled' : ''}`}
          title={buttonActions.add}
          disabled={inEditMode || inAddMode}
          data-testid='general-ledger-account-create'
        >
          <span className='icon icon--plus' />
        </button>
      </div>
      <PayslipTable data={data} headings={tableHeadings} dynamicValues editableOptions={editableOptions} wrapperClassName='u-margin-top-large' />
    </div>
  )
}

export default CommentaryView
