import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYFILES_FETCH = 'PAYFILES_FETCH'
export const PAYFILES_RECEIVE = 'PAYFILES_RECEIVE'
export const PAYFILES_UPDATE = 'PAYFILES_UPDATE'
export const PAYFILES_FILTER = 'PAYFILES_FILTER'
export const PAYFILES_FILTERED = 'PAYFILES_FILTERED'
export const PAYFILES_IMPORT = 'PAYFILES_IMPORT'
export const PAYFILES_IMPORTED = 'PAYFILES_IMPORTED'
export const PAYFILES_INITIAL_VALUES = 'PAYFILES_INITIAL_VALUES'
export const PAYFILES_RESET_INITIAL_VALUES = 'PAYFILES_RESET_INITIAL_VALUES'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYFILES_FETCH,
  receive: PAYFILES_RECEIVE,
  update: PAYFILES_UPDATE,
  filter: PAYFILES_FILTER,
  filtered: PAYFILES_FILTERED,
  import: PAYFILES_IMPORT,
  imported: PAYFILES_IMPORTED,
  initials: PAYFILES_INITIAL_VALUES,
  resetInitials: PAYFILES_RESET_INITIAL_VALUES
}
const actionUtility = new ActionUtility(actionTypes, 'payfiles', 'payfiles', 'Payfile')

export const resetPayfilesInitials = () => ({
  type: actionTypes.resetInitials
})

// ------------------------------------
// Thunk
// ------------------------------------
export const downloadArchive = (id, params) => actionUtility.downloadEntity({ id, childUri: 'exportarchive', params })
export const uploadArchive = (id, file, params) => {
  return dispatch => {
    dispatch({ type: actionTypes.import })

    return dispatch(actionUtility.uploadEntity({ id, file, childUri: 'import', params })).then(() => {
      dispatch({ type: actionTypes.imported })
    })
  }
}

export const downloadPayFile = (id, { filter, ...params } = {}) => (
  actionUtility.downloadEntity({ id, childUri: 'export', filter, params })
)

export const filterPayfiles = ({ filters }) => {
  return dispatch => {
    return dispatch(actionUtility.filterEntity({ filters }))
      .then(response => dispatch({ type: actionTypes.initials, payload: { data: response, filters } }))
  }
}

export const updatePayfile = actionUtility.updateEntity

export const fetchVariance = ({ id, filter: { uri } }) => {
  return (dispatch, getState, { api }) => {
    return api.fetch(`payfiles/${id}/variance?${uri ?? ''}`)
      .then(res => res)
  }
}

/**
  * Makes a call to get the term variance data, used for the element reconciliation page
 * Needs 2 versions and the term element id along with the payrollInstanceId
 */

export const fetchTermVariance = ({ id, filter: { uri } }) => {
  return (dispatch, getState, { api }) => {
    return api.fetch(`payfiles/${id}/varianceterm?${uri ?? ''}`)
      .then(res => res)
  }
}
