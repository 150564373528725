import { attr, fk } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/countryTermPivot'

class Deadline extends CRUDModel {}
Deadline.modelName = 'Deadline'

Deadline.fields = {
  id: attr(),
  country: fk('Country', 'deadlines'),
}
Deadline.actionTypes = actionTypes

export default Deadline
