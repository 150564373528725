import { getLoggedInUserId } from './auth'
import createSelector from 'utils/createSelector'
import { getTenantSubdomain } from 'utils/tenant'

const getPayrollInstanceId = (state, props) => parseInt(props.payrollInstanceId)

export const getCommentaryByPayrollId = createSelector(
  getPayrollInstanceId,
  getTenantSubdomain,
  getLoggedInUserId,
  ({ Commentary }, payrollInstanceId, tenant, loggedUserId) =>
    Commentary.all()
      .toRefArray()
      .filter((v) => v.payrollInstance === payrollInstanceId)
      .map((c) => ({
        disableEdit: c.userEm !== tenant || c.userId !== loggedUserId,
        ...c,
      }))
      .sort((a, b) => new Date(b.lastModifiedAt.date) - new Date(a.lastModifiedAt.date))
)
