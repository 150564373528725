import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { fetchDataProtectionFieldsIfNeeded, updateDataProtectionField } from 'redux/actions/dataProtectionFields'
import { getDataProtectionFieldById } from 'redux/selectors/dataProtectionFields'
import DataFieldForm from 'components/DataFieldForm'
import _ from 'lodash'

class GlobalDataProtectionEditContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props
    dispatch(fetchDataProtectionFieldsIfNeeded())
  }

  render () {
    return <DataFieldForm {...this.props} />
  }
}

GlobalDataProtectionEditContainer.propTypes = {
  dispatch: PropTypes.func
}

// map to state
const mapStateToProps = (state, props) => {
  const { dataProtectionFields } = state

  const entities = [dataProtectionFields]

  if (isFetching(entities)) return { isFetching: true }

  let dataProtectionField = getDataProtectionFieldById(state, {
    dataFieldId: props.dataFieldId
  })

  return {
    initialValues: {
      ...dataProtectionField
    }
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSubmit: data => {
      const sanitized = _.pick(data, ['isSensitive'])
      dispatch(updateDataProtectionField(sanitized, data.id)).then(props.onEdit)
    },
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'globalDataProtectionFieldEditForm',
})(GlobalDataProtectionEditContainer))
