import React from 'react'
import PropTypes from 'prop-types'

const ReportAccordionHeader = ({ title, textSize = '', textColor = '', weight = 'normal', childPaths = [], seperator = '/', onClick = null }) => {
  const path = childPaths.map(child => <span key={child} className=''> {seperator} {child}</span>)
  const hoverCursorCss = childPaths.length ? 'u-cursor--pointer' : ' '
  return (
    <div className={`u-text--${textSize} u-text--${textColor}`}>
      <span className={`u-weight--${weight} ${hoverCursorCss}`} onClick={onClick}>{title}</span>
      {path}
    </div>
  )
}

ReportAccordionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  onClick: PropTypes.func
}

export default ReportAccordionHeader
