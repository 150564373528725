import _ from 'lodash'
import errorsHandling from './actions/errorsHandling'
import ActionCreator from './actions/actionCreator'
import ApiThunks from './actions/apiThunks'
import { hasAccess } from 'redux/selectors/auth'

/**
 * Common Action creators and fetching functionality
 *
 * Most of the Entities have `fetch`, `receive` action creators
 * and fetching helper methods as `shouldFetch` and `fetchEnitiesIfNeeded`.
 *
 * So this module gives the basic action structure for the most of the Entities.
 *
 * @param {Object} actionTypes - Action types mapping. We support the bellow action creators,
 * therefore we need to provide the real Entity action types values, mapped to the supported ones:
 *
 * @example {
 *  fetch: COUNTRIES_FETCH,
 *  receive: COUNTRIES_RECEIVE,
 *  create: COUNTRIES_CREATE,
 *  update: COUNTRIES_UPDATE,
 *  delete: COUNTRIES_DELETE,
 *  invalidate: COUNTRIES_INVALIDATE,
 * }
 *
 * @param {String} reducerName - Reducer the Entity uses. After we integrated `redux-orm`, we use this reducer as meta.
 * In the project begining we use the Reducer as meta and data holder.
 * Please refer to `utils/redux/reducer-enitites.js` and `utils/redux/reducer-meta.js`,
 * for more details about why we integrate `redux-orm`.
 * @param {String} uri - REST API uri of Entity
 * @param {String} ormReducerName - ORM Reducer name. We added it later, because we integrated `redux-orm` library.
 *
 */
export default function (actionTypes, reducerName, uri, ormReducerName, params = {}, permissions = {}) {
  const actionCreator = new ActionCreator(actionTypes)
  const apiThunks = new ApiThunks(uri, params, actionCreator, errorsHandling, reducerName)

  this.fetchEntities = apiThunks.fetch
  this.createEntityWithoutForm = apiThunks.createWithoutForm
  this.createEntity = apiThunks.create
  this.createWithPolling = apiThunks.createWithPolling
  this.updateEntity = apiThunks.update
  this.updateEntityWithChildUri = apiThunks.updateWithChildUri
  this.bulkUpdateEntity = apiThunks.bulkUpdate
  this.deleteEntity = apiThunks.delete
  this.filterEntity = apiThunks.filter
  this.retrieveEntities = apiThunks.retrieve
  this.attachEntities = apiThunks.attach
  this.detachEntities = apiThunks.detach
  this.syncEntities = apiThunks.sync
  this.mergeEntities = apiThunks.merge
  this.downloadEntity = apiThunks.download
  this.uploadEntity = apiThunks.upload
  this.requestEntity = apiThunks.request

  this.setActive = id => {
    return dispatch => {
      dispatch(actionCreator.setActiveId(id))
    }
  }

  this.setLastCreatedId = id => {
    return dispatch => {
      dispatch(actionCreator.setLastCreatedId(id))
    }
  }

  this.setLastRunReportType = obj => {
    return dispatch => {
      dispatch(actionCreator.setLastRunReportType(obj))
    }
  }

  /**
   * Method to save the state if the application should considering polling
   * This and @setStartPolling are required to be true if polling API requests should take place
   * @param {{ enablePolling: boolean }} obj
   * @returns promise
   */
  this.setShouldPoll = obj => {
    return dispatch => {
      dispatch(actionCreator.setShouldPoll(obj))
    }
  }

  /**
   * Method to set if the polling should start. Must be used in conjunction with
   * @setShouldPoll to start the polling
   * @param {{ shouldStartPolling: boolean }} obj
   * @returns promise
   */
  this.setStartPolling = obj => {
    return dispatch => {
      dispatch(actionCreator.setStartPolling(obj))
    }
  }

  /**
   * Method to set the delay interval (in ms) the polling should use
   * Can send null as a way to cancel the interval and polling
   * @param {{ pollingDelay: number }} obj
   * @returns promise
   */
  this.setPollingDelay = obj => {
    return dispatch => {
      dispatch(actionCreator.setPollingDelay(obj))
    }
  }

  this.receiveWithErrors = obj => {
    return dispatch => {
      dispatch(actionCreator.receiveWithErrors(obj))
    }
  }

  this.clearErrors = () => {
    return dispatch => {
      dispatch(actionCreator.clearErrors())
    }
  }

  this.invalidate = entity => {
    return dispatch => {
      dispatch(actionCreator.invalidate())
    }
  }

  this.invalidateSpecificFilter = filter => {
    return dispatch => {
      dispatch(actionCreator.invalidateSpecificFilter(filter))
    }
  }

  this.reset = () => {
    return dispatch => {
      dispatch(actionCreator.reset())
    }
  }

  this.shouldFetch = (entity, filter = { name: '/' }) => {
    if (entity.filters && _.isEmpty(entity.filters[filter.name])) return true
    const { isFetching, didInvalidated } = entity.filters ? entity.filters[filter.name] : entity

    // We should fetch the entity only if:
    // 1. It isn' fetched
    // 2. OR the entity is invalidated
    // and it's not being fetched in the moment (we don't want to trigger multiple API calls)
    // * Please refer to utils/redux/fetching.js documentation,
    // for better understanding of how `isFetching` works.
    return (isFetching === null) || (didInvalidated && !isFetching)
  }

  this.hasPermission = (state, type) => {
    // No explicit permission is required for this action type
    if (!permissions[type]) return true

    return hasAccess(state)([permissions[type]])
  }

  this.fetchEnitiesIfNeeded = (params = {}) => {
    return (dispatch, getState, { api }) => {
      const state = getState()

      if (!this.hasPermission(state, 'fetch') || !this.shouldFetch(state[reducerName], params.filter)) return

      return dispatch(this.fetchEntities(params))
    }
  }

  this.resetEntityFilters = () => {
    return (dispatch, getState, { api }) => {
      dispatch(actionCreator.resetFilters())
    }
  }
}
