import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { statuses } from 'redux/config/payrollInstanceTasks'
import Form from 'components/form/Form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import PayslipTable from 'components/table/PayslipTable'

const renderHeading = (instance, task, isReadOnly) => {
  return <div>
    { instance.name }  -&nbsp;
    { moment(instance.fromDate.date).format('DD/MM/YYYY') } -&nbsp;
    { moment(instance.toDate.date).format('DD/MM/YYYY') }
    { !isReadOnly && <span>, action <span className='u-weight--bold'>{task.name}</span> status</span>}
  </div>
}

const renderTable = (scopes, isReadOnly, isPayrollInstanceFrozen) => {
  const data = scopes.map(scope => {
    const showTooltip = !scope.isEditable && isPayrollInstanceFrozen
    return {
      businessUnit: scope.businessUnit,
      owner: scope.owner,
      ...!isReadOnly ? {
        status: <Field
          name={scope.key}
          component={CustomSelectField}
          placeholder='Select'
          options={Object.keys(statuses).filter(status => status !== 'reverted').map(key => ({ ...statuses[key] }))}
          clearable={false}
          className={`c-custom-select--transparent ${showTooltip ? 'is-frozen' : ''}`}
          formGroupClassName='u-margin-none'
          disabled={!scope.isEditable}
          data-testid={`scopeId-${scope.id}`}
          aria-label={`${showTooltip
            ? 'Payrun file is currently unlocked for changes. Please lock it to complete any actions.'
            : ''}`}
        />
      } : {}
    }
  })

  return <PayslipTable
    data={data}
    headings={[
      { accessor: 'businessUnit', Header: 'Business unit name', isKey: true, disableFilters: true, disableSortBy: true },
      { accessor: 'owner', Header: 'Action owner', disableFilters: true, disableSortBy: true },
      ...!isReadOnly ? [{ accessor: 'status', Header: 'Status', disableFilters: true, disableSortBy: true }] : []
    ]}
    wrapperClassName='u-1/1 u-margin-top u-margin-bottom'
    trClassName='react-bs-table__cell--whitespace--normal'
  />
}

export const PayrollInstanceTaskOwnerEditView = props => {
  const { instance, task, scopes, isReadOnly, isPayrollInstanceFrozen } = props

  return <Form {...props} data-testid='scope-form'>
    <div className='o-layout'>
      <div className='o-layout__item u-text--center u-1/1 u-padding-bottom'>{ renderHeading(instance, task, isReadOnly) }</div>
      <div className='o-layout__item u-text--center u-1/1'>{renderTable(scopes, isReadOnly, isPayrollInstanceFrozen) }</div>
      { !isReadOnly && <div className='o-layout__item u-text--center u-1/1'>
        <button type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small'
          data-testid='submit-button'
        >Save</button>
      </div> }
    </div>
  </Form>
}

PayrollInstanceTaskOwnerEditView.propTypes = {
  instance: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fromDate: PropTypes.shape({
      date: PropTypes.string.isRequired
    }).isRequired,
    toDate: PropTypes.shape({
      date: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  task: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isPayrollInstanceFrozen: PropTypes.bool,
  scopes: PropTypes.array
}

export default PayrollInstanceTaskOwnerEditView
