import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { required } from 'utils/validations'

const TermCategoriesAndSub = ({
  categories,
  selectedCategory,
  subCategories,
  fetchTermSubCategoriesIfNeeded,
  onChangeTermSubCategories,
  showIsRequired,
  categoryPlaceholder,
  subcategoryPlaceholder,
}) => {
  return (
    <div>
      <Field
        name='category'
        label={`Category ${showIsRequired ? '*' : ''}`}
        labelClassName='c-label'
        component={CustomSelectField}
        onChange={(value) => fetchTermSubCategoriesIfNeeded(value)}
        clearable={false}
        validate={required}
        placeholder={categories.length ? categoryPlaceholder || 'Select Category' : 'Loading...'}
        options={categories.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        data-testid='category'
      />

      {selectedCategory ? (
        <Field
          name='termSubcategory'
          label={`Subcategory ${showIsRequired ? '*' : ''}`}
          labelClassName='c-label'
          component={CustomSelectField}
          onChange={onChangeTermSubCategories}
          clearable={false}
          validate={required}
          placeholder={subCategories.length ? subcategoryPlaceholder || 'Select Subcategory' : 'Loading...'}
          options={subCategories.map((category) => ({
            value: category.id,
            label: category.name,
          }))}
          data-testid='termSubcategory'
        />
      ) : null}
    </div>
  )
}

TermCategoriesAndSub.propTypes = {
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array,
  selectedCategory: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  fetchTermSubCategoriesIfNeeded: PropTypes.func.isRequired,
  onChangeTermSubCategories: PropTypes.func,
  showIsRequired: PropTypes.bool,
  categoryPlaceholder: PropTypes.string,
  subcategoryPlaceholder: PropTypes.string,
}

export default TermCategoriesAndSub
