import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { resetPasswordSendEmail } from 'redux/actions/auth'
import ForgottenPasswordView from '../components/ForgottenPasswordView'
import { showMessage } from 'redux/actions/modal'

class ForgottenPasswordContainer extends React.Component {
  render () {
    return <ForgottenPasswordView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(resetPasswordSendEmail(data))
      .then(() => {
        dispatch(reset('forgottenPassword'))
        dispatch(showMessage({
          body: <div>
            <p>We are checking our records for the user: {data.email}</p>
            <p>
              If this email address is found, you'll get an email with further instructions.
              Please always check your junk folder.
              If you do not receive an email, please check that you entered the email address used when you first set up your account.
            </p>
          </div>,
        }))
      })
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      email: null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'forgottenPassword'
})(ForgottenPasswordContainer))
