import { createReducerMeta } from 'utils/redux'
import { actionTypes } from 'redux/actions/pollingJob'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  jobs: [],
  shouldPoll: false,
  startPolling: false,
  delay: 2500
}
export default createReducerMeta(actionTypes, initialState)
