import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Fetcher from '../../../containers/Fetcher'
import { createFilter } from '../../../utils/redux/filter'
import CustomSelectField from 'components/form/select/CustomSelectField'
import CheckboxOption from 'components/form/select/CheckboxOption'
import { getFilteredEditors } from 'redux/selectors/editors'
import moment from 'moment'

class ChangedBySelectContainer extends React.Component {
  render () {
    const { isFieldDisabled, disabledReason, editors, isFetching } = this.props

    return (
      <Field
        name='modifiedBy'
        label='Changed by'
        component={CustomSelectField}
        optionComponent={CheckboxOption}
        className='c-custom-select--transparent c-custom-select--overflowy'
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        placeholder={isFetching ? 'Loading...' : 'Any'}
        disabled={isFieldDisabled}
        disabledReason={disabledReason}
        multi
        options={editors.map((user) => ({
          value: JSON.stringify({ id: user.userId, tenant: user.tenant }),
          label: user.userName,
        }))}
        data-testid='changed-by-wrapper'
        enableRemovedOptionFix={!this.props.isFetching}
      />
    )
  }
}

ChangedBySelectContainer.propTypes = {
  editors: PropTypes.array.isRequired,
  isFieldDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  disabledReason: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const editors = !props.isFetching ? getFilteredEditors(state, { filter: props.filters.editors.name }) : []

  return {
    isFetching: props.isFetching,
    editors,
  }
}

const Container = connect(mapStateToProps)(ChangedBySelectContainer)

export const normalizeDate = (date) => {
  // In the case a `date` is not selected/passed, we don't do anything.
  // Returning `undefined` is pretty enough, because `createFilter` function won't pass it as filter parameters
  // in that case.
  if (!date) return

  // In the case it's a string, it's already normalized for the BE request filters.
  // It's passed as a string, in the case a report is already created and the date is returned by the BE.
  if (typeof date === 'string') return date

  if (date instanceof Date) return moment(date).format('YYYY-MM-DD')

  // Here we have a moment object and we should normalize it to a string.
  // moment object is passed in the case we are in a process of creating a report.
  return date.format('YYYY-MM-DD')
}

export default Fetcher(
  Container,
  [
    {
      name: 'editors',
      params: [
        {
          _computed: {
            filter: (state, props) =>
              createFilter({
                reportFromDate: normalizeDate(props.selected.fromDate),
                reportToDate: normalizeDate(props.selected.toDate),
                country: props.selected.country,
                company: props.selected.company,
                businessUnit: props.selected.businessUnit,
              }),
          },
        },
      ],
    },
  ],
  { renderWhileFetching: true, showLoader: false }
)
