import React from 'react'
import PropTypes from 'prop-types'
import ServicesTreeDecorator from 'components/ServicesTreeDecorator'
import ProcessTree from 'components/ProcessTree'
import Modal from 'components/Modal'

class PayrollProcessTree extends React.Component {
  showCreateModal = () => {
    this.refs.createModal.showModal()
  }

  onStepCreate = () => {
    this.refs.createModal.hideModal()
    this.props.onStepCreate(this.props.payrollProcessId)
  }

  onTaskCreate = () => {
    this.refs.createModal.hideModal()
    this.props.onTaskCreate(this.props.payrollProcessId)
  }

  render () {
    const {
      tree,
      payrollProcessId,
      isFetching,
      onProcessEdit,
      onTaskEdit,
      onStepEdit,
      onMultiView,
      isCot,
      isShare,
      addBtnTitle,
      isEditDisabled,
      initialValues,
      isOwner,
    } = this.props

    if (isFetching) return <div>Loading...</div>

    return (
      <div>
        <Modal ref='createModal' className='c-modal c-modal--small c-modal--overflow-y' modalHeading='Do you want to create a:'>
          <div className='u-text--center u-1/1'>
            <button
              onClick={this.onStepCreate}
              className='c-btn c-btn--small c-btn--curious
             u-padding-left u-padding-right u-margin-right-small'
            >
              Step
            </button>
            <button onClick={this.onTaskCreate} className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'>
              Action
            </button>
          </div>
        </Modal>
        <ServicesTreeDecorator steps={tree.steps}>
          <ProcessTree
            processId={payrollProcessId}
            isPayrollProcess={tree.isPayrollProcess}
            name={tree.name}
            owner={tree.owner}
            onProcessEdit={onProcessEdit}
            onTaskEdit={onTaskEdit}
            onStepEdit={onStepEdit}
            onCreate={this.showCreateModal}
            payDate={tree.payDate}
            keyEvent={tree.keyEvent}
            isCot={isCot}
            isShare={isShare}
            onMultiView={onMultiView}
            addBtnTitle={addBtnTitle}
            isEditDisabled={isEditDisabled}
            initialValues={initialValues}
            isOwner={isOwner}
          />
        </ServicesTreeDecorator>
      </div>
    )
  }
}

PayrollProcessTree.propTypes = {
  tree: PropTypes.object,
  payrollProcessId: PropTypes.number,
  isFetching: PropTypes.bool,
  onProcessEdit: PropTypes.func,
  onTaskEdit: PropTypes.func,
  onMultiView: PropTypes.func,
  isCot: PropTypes.bool,
  isShare: PropTypes.bool,
  isOwner: PropTypes.bool,
}

export default PayrollProcessTree
