import { shift, flip, offset } from '@floating-ui/react'

/**
 * Returns an array of middleware functions for floating UI components.
 *
 * @param {Object} options - The options for configuring the middleware.
 * @param {number} [options.offsetValue=20] - The offset value to use for the offset middleware.
 * @returns {Array} An array of middleware functions.
 */
export const getBaseFloaterMiddleWare = ({ offsetValue = 20 }) => [offset(offsetValue), flip(), shift()]
