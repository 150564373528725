import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TicketingIcon } from 'components/icons/TicketingIcon'
import { isEmployeeOnly, isVendorInvolved } from 'redux/selectors/auth'
import { textLabels } from 'utils/locales/common.en'
import { getParsedDomain } from 'utils/query'
import { getSubdomainBrandConfig } from 'components/BrandConfig'
import { DEFAULT_TICKETING_SYSTEM_URL } from 'utils/url'

const TicketingContainer = () => {
  const isEmployeeOnlyUser = useSelector((state) => isEmployeeOnly(state))
  const isVendorUser = useSelector((state) => isVendorInvolved(state))
  const tenants = useSelector((state) => state.tenants)
  const brandsConfig = useSelector((state) => state.config.brands)
  const subdomain = getParsedDomain(tenants.url)?.subdomain?.split('.')?.[0]
  const brandData = getSubdomainBrandConfig(brandsConfig, subdomain)
  const pathname = brandData?.subdomain ? brandData?.ticketingSystem : DEFAULT_TICKETING_SYSTEM_URL
  const hideTicketingButton = isEmployeeOnlyUser || isVendorUser || !pathname

  if (hideTicketingButton) return
  return (
    <Link to={{ pathname }} target='_blank' title={textLabels.submitTicket} className='u-padding-top-tiny u-margin-right'>
      <TicketingIcon />
    </Link>
  )
}

export default TicketingContainer
