import { createFilter } from 'utils/redux/filter'
import { buildFilterName } from './commonFilters'

export const prepareEEPivotFilters = ({ page = 0, limit, sort, filters, state, filterName } = {}) => {
  limit = limit !== undefined ? limit : state.payrollInstanceEmployeePivot.filters[filterName].params.limit
  page = page !== undefined ? page : state.payrollInstanceEmployeePivot.filters[filterName].params.offset / limit
  filters =
    filters !== undefined
      ? filters
      : state.payrollInstanceEmployeePivot.filters[filterName]
        ? state.payrollInstanceEmployeePivot.filters[filterName].params
        : null

  return createFilter(
    {
      ...filters,
      sort,
      offset: page * limit,
      limit,
      payloadFormat: 'reduced',
    },
    filterName
  )
}

export default ({ sort = [], limit = 20, filterKey }) => ({
  name: 'payrollInstanceEmployeePivot',
  params: [
    {
      _computed: {
        filter: (state, { match: { params } }) => {
          const filterName = buildFilterName(params)[filterKey]
          const isFetchedAtLeastOnce = state.payrollInstanceEmployeePivot.filters[filterName] && !!state.payrollInstanceEmployeePivot.filters[filterName].ids

          if (isFetchedAtLeastOnce) return prepareEEPivotFilters({ state, filterName })

          return prepareEEPivotFilters({
            page: 0,
            limit,
            filters: {
              payrollInstance: params.payrollInstanceId,
            },
            sort,
            state,
            filterName,
          })
        },
      },
      disableObsoleteFlow: true,
    },
  ],
})
