import { fk, many, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrolls'
import { getOrdinalDay } from 'utils/date'
import { isNumeric } from 'utils/number'
import moment from 'moment'

/**
 *
 * @param {String} frequency
 * @param {String} date
 * @return {*}
 */
export const formatDate = (frequency, date) => {
  // Return formatted date
  if (frequency === 'Once off') return moment(date).format('DD/MM/YYYY')

  // Return the name of the week day
  if (['Weekly', 'Fortnightly'].includes(frequency)) return moment.weekdays(parseInt(date))

  // Return ordinal date ( i.e. 5th)
  if (isNumeric(date)) return getOrdinalDay(date)

  // For all other cases, just return the plain date value
  return date
}

class Payroll extends CRUDModel {
  static handlers (Model) {
    return {
      ...CRUDModel.baseHandlers(Model),
      [Model.actionTypes.detachPayrollEmployees]: (Model, { payload: { payrollId, data } }) => {
        Model.withId(payrollId)
          .payrollEmployees.filter((entity) => {
            return data.hasOwnProperty(entity.employee)
          })
          .delete()
      },
      [Model.actionTypes.detachPayrollPaygroups]: (Model, { payload: { payrollId, data } }) => {
        Model.withId(payrollId)
          .payrollPaygroups.filter((entity) => {
            return data.hasOwnProperty(entity.paygroup)
          })
          .delete()
      },
    }
  }

  getFormattedPayDate () {
    return formatDate(this.frequency, this.payDate)
  }
}
Payroll.modelName = 'Payroll'

Payroll.fields = {
  id: attr(),
  processOwner: fk('EmployeeSystemUser'),
  currency: fk('Currency', 'payrolls'),
  paymentAccount: fk('BankAccount', 'payrolls'),
  company: fk('Company', 'payrolls'),
  workingWeek: attr(),
  paygroups: many({
    to: 'Paygroup',
    relatedName: 'payrolls',
    through: 'PayrollPaygroup',
    throughFields: ['payroll', 'paygroup'],
  }),
  employeeSystemUsers: many({
    to: 'EmployeeSystemUser',
    relatedName: 'payrolls',
    through: 'PayrollEmployee',
    throughFields: ['payroll', 'employee'],
  }),
}
Payroll.actionTypes = {
  ...actionTypes,
}

export default Payroll
