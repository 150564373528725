export const calendarEventsStepEnum = {
  INPUTS_CUTOFF: 'Approve all updates and send for calculations',
  GTN_CUTOFF: 'Final Payroll Reports Provision',
  GTN_CUTOFF_ALT: 'GTN Version 1',
  GTN_CUTOFF_TERTIARY: 'Final Payroll Reports Authorization'
}

export const calendarEventsTitlesEnum = {
  INPUTS_CUTOFF: 'Inputs CutOff Date',
  GTN_CUTOFF: 'GTN CutOff Date',
  PAY_DATE: 'Pay Date'
}

export const gtnCutOffEventsSortedByPriority = [
  calendarEventsStepEnum.GTN_CUTOFF,
  calendarEventsStepEnum.GTN_CUTOFF_ALT,
  calendarEventsStepEnum.GTN_CUTOFF_TERTIARY
]
