import React from 'react'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import Loader from 'components/Loader'
import DashboardTileHeader from './DashboardTileHeader'
import EmptyListNotice from './EmptyListNotice'
import NotificationsHOC from 'components/NotificationsHOC'

const NewestAlertsListItem = ({ notification }) => {
  return (
    <li className='u-padding-top-small u-padding-bottom-tiny u-weight--medium u-text--normal
    u-text--valhalla u-border-bottom--mischka'>
      <div style={{ display: 'grid', gridTemplateColumns: '10% 30% auto', alignItems: 'center' }}>
        <div className='u-padding-right-tiny u-padding-left-tiny'>
          <Flag flag={notification.countryName.abbr} size='tiny' classes='u-margin-right-tiny line-height--27' />
        </div>
        <span className='u-padding-right-small u-text--overflow-wrap'>
          {notification.extraData.payrollShortName || notification.extraData.payrollName}
        </span>
        <div className='text-left'>
          <span className='u-weight--bold'>Action: </span>
          <span className='u-weight--regular'>{notification.body}</span>
        </div>
      </div>
    </li>
  )
}

const NewestAlertsList = ({ notifications }) => {
  if (notifications.length < 1) return <EmptyListNotice text='No New Alerts' />
  return (
    <ul className='list-unstyled u-margin-none'>
      {notifications.map(item => <NewestAlertsListItem key={item.id} notification={item} />)}
    </ul>
  )
}

function NewestAlertsDashboardView (props) {
  const hasItems = !props.isFetching && props.notifications.length > 0 ? 'has-items' : 'no-items'
  return (
    <div data-testid='dashboard-newest-alerts'>
      <DashboardTileHeader title='Newest Alerts' onClick={props.onNavigatingToAlerts} buttonText='View' />
      <div className={`dashboard-tile-container ${hasItems}`}>
        {!props.isFetching ? <NewestAlertsList notifications={props.notifications} {...props} /> : <Loader />}
      </div>
    </div>
  )
}

NewestAlertsDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigatingToAlerts: PropTypes.func,
  notifications: PropTypes.array,
  notification: PropTypes.object
}

NewestAlertsListItem.propTypes = {
  notification: PropTypes.object
}

NewestAlertsList.propTypes = {
  notifications: PropTypes.array
}

export default NotificationsHOC(NewestAlertsDashboardView)
