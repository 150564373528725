/**
 * termCategories items,
 * these are referenced in the code, are listed here.
 *
 * In that way we prevent using magic numbers
 * and also we can change the values below easily.
 */
export default {
  payElements: 'Pay elements',
  employerDeductions: 'Employer Contributions',
  employeeDeductions: 'Employee Deductions',
  employeeNetDeductions: 'Employee Net Deductions',
  timeAttendance: 'Time and Attendance',
  overtime: 'Overtime',
}
