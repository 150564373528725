import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COUNTRY_STEP_FETCH = 'COUNTRY_STEP_FETCH'
export const COUNTRY_STEP_RECEIVE = 'COUNTRY_STEP_RECEIVE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COUNTRY_STEP_FETCH,
  receive: COUNTRY_STEP_RECEIVE
}
const actionUtility = new ActionUtility(actionTypes, 'countryStepPivot', 'countrysteps', 'CountryStep')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCountryStepPivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCountryStepPivot = actionUtility.fetchEntities
