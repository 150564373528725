import createSelector from 'utils/createSelector'
import { payrollName } from 'redux/config/services'
import _ from 'lodash'

const getType = (state, props) => props.type
const getCompanyId = (state, props) => parseInt(props.companyId)

/**
 * Get CompanyServices with processes by type
 *
 * @param {SessionBoundModel} company
 * @param {String} type
 * @returns {Array}
 */
export const getCompanyServicesWithProcessesByType = createSelector(getType,
  ({ CompanyCountryService }, type) => {
    return CompanyCountryService.all().toModelArray()
      .filter(pivot => pivot.countryService.service.type === type)
      .map(pivot => ({
        // Service name
        name: pivot.countryService.service?.name,
        company: pivot.company.id,
        // Service processes
        ...getProcesses(pivot)
      }))
  })

/**
 * @inheritDoc getCompanyServicesWithProcessesByType()
 */
export const getCompanyServicesWithProcessesByTypeByCompany = createSelector(getCompanyServicesWithProcessesByType,
  getCompanyId, (session, services, companyId) => {
    return services.filter(service => service.company === companyId)
  })

/**
 * Here we group all the processes by a service, across all the companies
 *
 * By default a company can have a specific service only one time.
 * And in the most cases, we list the services by a company, so the grouping isn't necessary.
 *
 * But the grouping is needed, because here we get the services/processes of all the companies.
 * And because there are many companies included, the services are repeated and we do additional grouping.
 *
 * Example. Without grouping, we'll have:
 * ```
 * [
 *   { name: 'Payroll Calculation', company: 1, processesCount: 1, processes: [...]},
 *   { name: 'Payroll Calculation', company: 2, processesCount: 2, processes: [...]}
 * ]
 * ```
 *
 * And after the grouping, that's our goal, we'll have:
 *
 * ```
 * [
 *   // All the processes will be kept in the `processes` and the `processesCount` will be updated too.
 *   { name: 'Payroll Calculation', processesCount: 3, processes: [...]},
 * ]
 * ```
 *
 * @inheritDoc getCompanyServicesWithProcessesByType()
 */
export const getCompanyServicesWithProcessesByTypeGroupedByService = createSelector(getCompanyServicesWithProcessesByType,
  getCompanyId, (session, services) => {
    // Group services by name
    const grouped = _.groupBy(services, 'name')
    const result = []

    // Combine all services' processes in one merged array
    Object.keys(grouped).forEach(serviceName => {
      let merged = { name: serviceName, processes: [], processesCount: 0 }

      grouped[serviceName].forEach(service => {
        merged.processesCount += service.processesCount
        merged.processes = merged.processes.concat(service.processes)
      })

      result.push(merged)
    })

    return result
  })

/**
 * Get Company Service Processes, according to the Service type
 *
 * Currently we have specifics for Payroll service only.
 * For all others, they use the global Processes.
 *
 * @param {CompanyCountryService} companyCountryService
 */
const getProcesses = companyCountryService => {
  // If the service is Payroll
  if (companyCountryService.countryService.service.name === payrollName) {
    return getPayrollsProcesses(companyCountryService.payrollServices)
  }

  return getServiceProcesses(companyCountryService)
}

const getPayrollsProcesses = payrollServices => {
  let processes = []

  payrollServices.toModelArray().forEach(service => {
    service.payrollProcesses.toRefArray().forEach(process => {
      processes.push({
        id: process.id,
        name: service.payroll.name,
        uri: 'payroll',
        company: { ...service.companyService.company.ref },
        country: { ...service.companyService.company.country.ref }
      })
    })
  })

  return {
    processesCount: processes.length,
    processes
  }
}

const getServiceProcesses = service => ({
  processesCount: service.companyCountryProcesses.count(),
  processes: service.companyCountryProcesses.toModelArray().map(process => ({
    id: process.id,
    name: process.countryProcess.process.name,
    uri: 'process',
    company: { ...service.company.ref },
    country: { ...service.company.country.ref }
  }))
})
