import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NewestAlertsDashboardView from '../components/NewestAlertsDashboardView'
import Fetcher from 'containers/Fetcher'
import { purify } from 'utils/router'
import { selectTenant } from 'redux/actions/tenants'
import { fetchCotUsersIfNeeded } from 'redux/actions/cotUsers'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { getFilteredNotificationsDecorated } from 'redux/selectors/notifications'
import { createFilter } from 'utils/redux/filter'
import { downloadDocumentByUrl } from 'redux/actions/document'
import { getParentCompaniesTenants, getVendorsTenants } from 'redux/selectors/tenants'
import { fetchParentCompaniesIfNeeded, invalidateParentCompaniesIfNeeded } from 'redux/actions/parentCompanies'
import { invalidatePayrollInstances } from 'redux/actions/payrollInstances'
import { isCotOrEngineerOrAdmin } from 'redux/selectors/auth'
import { getCotUser } from 'redux/selectors/cotUsers'

const invalidate = () => {
  return dispatch => {
    dispatch(invalidateParentCompaniesIfNeeded())
    dispatch(invalidatePayrollInstances())
  }
}

const fetch = () => {
  return dispatch => {
    dispatch(fetchParentCompaniesIfNeeded())
  }
}

const mapDispatchToProps = (dispatch, { isCot, history }) => ({
  downloadDocument: url => dispatch(downloadDocumentByUrl(url)),
  // Before navigating (routing) to Alerts route, we have to check if the current logged in user is COT.
  // If so, then we have to perform tenant switching firstly,
  // because COT Alerts are located in the COT mode and have to switch between the current tenant mode to the COT mode.
  onNavigatingToAlerts: () => {
    if (!isCot) return history.push('/toolbox/alerts')

    // In the case we re COT
    // Before switching the COT api url, we go to the `/pure` neutral route.
    purify(history).then(() => {
      dispatch(selectTenant({ url: null, type: null, id: null }))

      history.push(`/alerts`)

      // After setting the tenant to COT we should fetch the COT users again, so the NavBarProfile loads correctly.
      dispatch(fetchCotUsersIfNeeded())
      // Also we should fetch the countries again, so the CountryHeader loads correctly.
      // Otherwise it says 'Loading...'
      dispatch(fetchCountriesIfNeeded())
    })
  },
  selectTenant: data => {
    dispatch(selectTenant(data))
    dispatch(fetch())
  },
  resetTenant: () => {
    dispatch(invalidate())
    dispatch(selectTenant({ url: null, schema: null }))
  },
  invalidate: () => dispatch(invalidate())
})

const mapStateToProps = (state, { filters, onItemFetched }) => {
  const recentNotifications = getFilteredNotificationsDecorated(state, { filter: filters.notifications.name })

  /**
   * Check if there are any unread messages from the recent notifications
   * Let's assume that there aren't any unread notifications
  */
  let hasUnread = false

  /**
   * check for each recent notification if it's read
   */
  recentNotifications.forEach(notification => {
    if (!notification.isRead) {
      hasUnread = true
    }
  })
  if (!state.isFetching) {
    onItemFetched('alerts', recentNotifications.length)
  }

  const parentCompaniesTenants = getParentCompaniesTenants(state)
  const vendorsTenants = getVendorsTenants(state)

  return {
    isFetching: state.isFetching,
    notifications: recentNotifications,
    hasUnread,
    isCotOrEngineerOrAdminUser: isCotOrEngineerOrAdmin(state),
    cotUserSpecialRights: isCotOrEngineerOrAdmin(state) && getCotUser(state, { userId: state.auth.userId }).cotSpecialRight,
    tenants: [
      { name: 'Clients', disabled: true },
      ...parentCompaniesTenants,
      { name: 'Vendors', disabled: true },
      ...vendorsTenants
    ],
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(NewestAlertsDashboardView)

Container.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool
}

export default Fetcher(Container, [
  'parentCompanies',
  'vendors',
  { name: 'notifications',
    params: [{
      filter: createFilter({ offset: 0, limit: 3, sort:  [ { name: 'createdAt', order: 'desc' } ] })
    }]
  }
])
