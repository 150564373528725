import createSelector from 'utils/createSelector'
import { employeeFieldsConfig } from 'redux/config/employeeFieldsConfig'

const getDataProtectionFieldId = (state, props) => parseInt(props.dataFieldId)

export const getDataProtectionFieldsGroupedByCategory = createSelector(
  ({ DataProtectionField }) => {
    let fields = []
    employeeFieldsConfig.map(cat => {
      fields.push({
        category: cat.category,
        categoryName: cat.categoryName,
        fields: DataProtectionField.all()
          .toRefArray()
          .filter(field => cat.fields.find(f => {
            if (field.entityClass.includes('EmployeeUser')) {
              return f.field === field.entityField
            }
            if (field.entityClass.includes('TermCategory')) {
              return f.field === field.fieldValue
            }
          }
          ))
          .map(field => {
            let name
            if (field.entityClass.includes('EmployeeUser')) {
              name = cat.fields.find(f => f.field === field.entityField).name
            }
            if (field.entityClass.includes('TermCategory')) {
              name = cat.fields.find(f => f.field === field.fieldValue).name
            }
            return {
              ...field,
              name
            }
          }
          )
      })
    })
    return fields
  })

export const getDataProtectionFieldById = createSelector(getDataProtectionFieldId,
  ({ DataProtectionField }, dataFieldId) => {
    const dataField = DataProtectionField.withId(dataFieldId)
    let employeeDataFieldCat
    let employeeDataFieldObj
    if (dataField.entityClass.includes('EmployeeUser')) {
      employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === dataField.entityField))
      employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === dataField.entityField)
    }
    if (dataField.entityClass.includes('TermCategory')) {
      employeeDataFieldCat = employeeFieldsConfig.find(cat => cat.fields.find(field => field.field === dataField.fieldValue))
      employeeDataFieldObj = employeeDataFieldCat.fields.find(field => field.field === dataField.fieldValue)
    }

    return {
      ...dataField.ref,
      name: employeeDataFieldObj.name
    }
  }
)
