import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_EMPLOYEE_FETCH = 'PAYROLL_EMPLOYEE_FETCH'
export const PAYROLL_EMPLOYEE_RECEIVE = 'PAYROLL_EMPLOYEE_RECEIVE'
export const PAYROLL_EMPLOYEE_INVALIDATE = 'PAYROLL_EMPLOYEE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_EMPLOYEE_FETCH,
  receive: PAYROLL_EMPLOYEE_RECEIVE,
  invalidate: PAYROLL_EMPLOYEE_INVALIDATE
}
const actionUtility = new ActionUtility(
  actionTypes, 'payrollEmployeePivot', 'payrollemployees', 'PayrollEmployee', {}, { fetch: 'PAYROLLEMPLOYEE_VIEW' }
)

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollEmployeePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollEmployeePivot = actionUtility.fetchEntities
export const invalidatePayrollEmployeePivot = actionUtility.invalidate
