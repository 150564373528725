import React from 'react'

import { payrunStatesEnum } from 'utils/enums/payrunStates'
import { buttonActions } from 'utils/locales/common.en'
import { canLoggedInUserEditDocumentByType } from 'containers/documents/utils/documents'
import { reusableModalTexts } from 'utils/locales/modals.en'

export const generateDescriptionDocLink = (doc, { onDownload }) => {
  const [, label, file] = doc.description?.match(/(.+)\s'([^']+)'$/) || []

  if (!label || !file) {
    return null
  }

  return (
    <>
      <span>{label} </span>

      <span
        className='u-text--curious u-cursor--pointer u-word-break-all'
        onClick={() => {
          onDownload(doc.gtnFileId)
        }}
      >
        {file}
      </span>
    </>
  )
}

export const prepareGTNDocument = (doc) => ({
  id: doc.id,
  documentId: doc.id,
  documentName: doc.name,
  documentTenant: doc.documentTenant,
  documentVersion: doc.version,
})

export const getGTNImportTemplate = (
  doc,
  {
    isPayrollInstanceLocked,
    openReimportGTNModal,
    hasRightsToLockUnlock,
    openReimportGTNWhenPayrunIsUnlockedModal,
    openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal,
  }
) => (
  <button
    id={`gtn-reimport-${doc.id}`}
    onClick={() => {
      isPayrollInstanceLocked
        ? openReimportGTNModal(prepareGTNDocument(doc))
        : hasRightsToLockUnlock && payrunStatesEnum.ACTIVE
          ? openReimportGTNWhenPayrunIsUnlockedModal(prepareGTNDocument(doc))
          : openReimportGTNUserNotAuthorizedToLockUnlockPayrunModal()
    }}
    className='c-btn c-btn--small c-btn--curious u-relative u-padding-left-small  u-padding-right-small u-padding-top-none u-padding-bottom-none'
  >
    <span className='icon icon--reimport-arrows' />
  </button>
)

export const DocumentActionsLayout = ({ children }) => <div className='d-flex jc--flex-end'>{children}</div>

export const DocumentsShareAction = ({ doc, handleClick }) => (
  <button
    onClick={() => handleClick(doc)}
    className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
    title={buttonActions.share}
    data-testid={`share-button-${doc.id}`}
  >
    <span className='icon icon--share' />
  </button>
)

export const DocumentsHistoryAction = ({ doc, handleHistoryClick }) => (
  <button
    onClick={() => handleHistoryClick(doc)}
    className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny'
    title={buttonActions.history}
    data-testid={`history-button-${doc.id}`}
  >
    <span className='icon icon--history' />
  </button>
)

export const DocumentsEditAction = ({ doc, handleEditClick }) => (
  <button onClick={() => handleEditClick(doc)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny u-margin-right-tiny' title={buttonActions.edit}>
    <span className='icon icon--edit' />
  </button>
)

export const DocumentsDeleteAction = ({ doc, handleTrashAction }) => (
  <button onClick={() => handleTrashAction(doc)} className='c-btn c-btn--tiny c-btn--curious u-text--tiny' title='Delete'>
    <span className='icon icon--trash' />
  </button>
)

export const PayrollDocOwnerAction = ({ doc, canLoggedInUserEditDocumentByType, handleHistoryClick, handleEditClick }) => (
  <>
    <DocumentsHistoryAction doc={doc} handleHistoryClick={handleHistoryClick} />
    {canLoggedInUserEditDocumentByType(doc, doc.extraData.isOwner) && <DocumentsEditAction doc={doc} handleEditClick={handleEditClick} />}
  </>
)

export const PayrollDocumentActionsRow = ({
  doc,
  isCot,
  userSpecialRightAllPayrunDocuments,
  canCreate,
  shouldShowShareButton,
  handleShareButtonClick,
  handleHistoryClick,
  handleEditClick,
  handleTrashAction,
}) => {
  return (
    <DocumentActionsLayout>
      {canCreate && shouldShowShareButton && <DocumentsShareAction doc={doc} handleClick={() => handleShareButtonClick(doc)} />}
      {(doc.extraData.isOwner || isCot || userSpecialRightAllPayrunDocuments) && (
        <PayrollDocOwnerAction
          doc={doc}
          canLoggedInUserEditDocumentByType={canLoggedInUserEditDocumentByType}
          handleHistoryClick={() => handleHistoryClick(doc)}
          handleEditClick={() => handleEditClick(doc)}
        />
      )}
      {doc?.extraData?.isDeletable && <DocumentsDeleteAction doc={doc} handleTrashAction={() => handleTrashAction(doc)} />}
    </DocumentActionsLayout>
  )
}

export const DocumentHeaderActions = ({ canCreate, handleClick }) => (
  <>
    {canCreate && (
      <button
        title={buttonActions.uploadFile}
        onClick={handleClick}
        className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-relative'
        data-testid='upload-button'
      >
        <span className='icon icon--upload' />
      </button>
    )}
  </>
)

export const DocumentActionsRow = ({ doc, canCreate, hasHistoryAction, handleShareButtonClick, handleHistoryClick, handleEditClick, handleTrashAction }) => (
  <DocumentActionsLayout>
    {canCreate && <DocumentsShareAction doc={doc} handleClick={() => handleShareButtonClick(doc)} />}
    {hasHistoryAction && (
      <>
        <DocumentsHistoryAction doc={doc} handleHistoryClick={handleHistoryClick} />
        <DocumentsEditAction doc={doc} handleEditClick={handleEditClick} />
        {doc?.extraData?.isDeletable && <DocumentsDeleteAction doc={doc} handleTrashAction={() => handleTrashAction(doc)} />}
      </>
    )}
  </DocumentActionsLayout>
)

export const DocumentsDeleteModalText = ({ fileName }) => (
  <p>
    {reusableModalTexts.areYouSureWantToDelete}
    <b>{fileName}</b>?
  </p>
)
