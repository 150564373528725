import React from 'react'
import { Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'

const TimelineForm = ({ ...props }) => (
  <Form {...props}>
    <div className='o-layout u-text--center'>
      <div className='o-layout__item u-1/1 u-1/3@tablet'>
        <Field
          name='deadlineMonthly'
          label='Monthly deadline'
          labelClassName='c-label'
          component={InputField}
          type='number'
          placeholder='Enter value'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/3@tablet'>
        <Field
          name='deadlineFortnightly'
          label='Fortnightly deadline'
          labelClassName='c-label'
          component={InputField}
          type='number'
          placeholder='Enter value'
        />
      </div>
      <div className='o-layout__item u-1/1 u-1/3@tablet'>
        <Field
          name='deadlineWeekly'
          label='Weekly deadline'
          labelClassName='c-label'
          component={InputField}
          type='number'
          placeholder='Enter value'
        />
      </div>
      <div className='o-layout__item u-1/1'>
        <button
          type='submit'
          className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-top-small' >
          Save
        </button>
      </div>
    </div>
  </Form>
)

export default TimelineForm
