import React from 'react'
import Form from 'components/form/Form'
import addressFields from 'redux/config/employeeAddressFieldsConfig'
import { Field } from 'redux-form'
import InputField from 'components/form/InputField'
import fieldComponentsEnum from 'utils/enums/fieldComponentsEnum'
import CustomSelectField from 'components/form/select/CustomSelectField'

const getRenderComponenet = (type) => {
  switch (type) {
  case fieldComponentsEnum.DROPDOWN:
    return CustomSelectField
  default:
    return InputField
  }
}

export const AddNewEmployeeAddressForm = (props) => {
  return (
    <Form {...props}>
      <div className='o-layout o-grid--strech'>
        {addressFields?.map((address) => {
          const cssName = address.component === fieldComponentsEnum.DROPDOWN ? 'c-custom-select--transparent' : 'c-input c-input--transparent'
          return (
            <div key={address.field} className='o-layout__item  u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' data-testid={`${address.field}-wrapper`}>
              <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                <Field
                  name={address.field}
                  label={address.name}
                  component={getRenderComponenet(address.component)}
                  {...address.component === fieldComponentsEnum.INPUT ? { type: 'text' } : null}
                  className={cssName}
                  labelClassName='c-label u-text--small u-text--curious'
                  formGroupClassName='u-margin-none'
                  data-testid={`${address.field}`}
                  {...address.component === fieldComponentsEnum.DROPDOWN ? {
                    options: address.options,
                    placeholder: 'Select'
                  } : null}
                />
              </div>
            </div>
          )
        })}
        <div className='o-layout__item u-text--center u-1/1'>
          <button className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right' data-testid='submit'>
            Save
          </button>
        </div>
      </div>
    </Form>
  )
}
