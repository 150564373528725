import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import { ALL_ROUTES } from 'configs/routes'

export default (store) => ({
  path : `:id/${ALL_ROUTES.PAYRUNS.ELEMENT_RECONCILIATION}`,
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  renderChildrenOutSideTab: true,
  wrappedComponent: CoreLayout,
  childRoutes: []
})
