import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import PayrollReconciliationView from '../components/PayrollReconciliationView'
import { createFilter } from 'utils/redux/filter'
import { useSelector, useDispatch, batch } from 'react-redux'
import Loader from 'components/Loader'
import { fetchVariance } from 'redux/actions/payfiles'
import { pushBreadcrumbFilter } from 'redux/actions/breadcrumbs'
import { getBreadcrumbFiltersByKey } from 'redux/selectors/breadcrumbs'
import { createNewVarianceExportJob } from 'redux/actions/pollingJob'
import { startToastFileGeneration } from 'redux/actions/toasts'
import { toast } from 'react-toastify'
import ToastLoadingViews from 'components/ToastLoadingViews'
import { getTermName, getTitleAndVersionData } from 'routes/Payruns/utils/versioning'
import { getNonCompletedPayrunVarianceJobs } from 'redux/selectors/pollingJobs'
import { elementSortingOrder } from '../utils/elements'
import { dateFormatEnums } from 'utils/enums/dateFormatEnums'
import { normalizeFiltersForBE } from 'utils/filters'
import { payrollReconciliationFilterMapping } from 'utils/enums/filterMappingEnums'
import { generatePayrollReconciliationTableHeading, getMainOptions } from './payrollReconciliationTableConfig'
import { addExpandableSubRow } from 'components/table/cell/ExpandableCell'
import { updateVarianceNotes } from 'redux/actions/varianceNotes'

const limit = 20

const PayrollReconciliationContainer = (props) => {
  const [varianceFilterState, setVarianceFilterState] = useState(true)
  const [excludeZeroesFilterState, setExcludeZeroesFilterState] = useState(true)

  const [state, setState] = useState({
    varianceData: {},
    offset: 0,
    tableFilters: {},
    mainOptions: {},
  })
  const [tableState, setTableState] = useState({
    tiles: {},
    data: [],
    changedData: [],
    isLoading: false,
  })
  const [initialLoading, setInitialLoading] = useState(false)

  const inProgressPollingJobs = useSelector((state) => getNonCompletedPayrunVarianceJobs(state))

  const varianceData = state?.varianceData || {}
  const offset = state?.offset || 0
  const tableFilters = state?.tableFilters || {}

  const mountedRef = useRef(true)
  const dispatch = useDispatch()

  const pagination = !isEmpty(varianceData) && {
    totalPages: Math.ceil(varianceData.totalCount / limit),
    currentPage: offset / limit,
    totalCount: varianceData.totalCount || 0,
    limit,
  }

  let defaultOptionLeft
  let defaultOptionRight

  const payrollInstanceId = parseInt(props.match.params.id, 10)

  const notesToSave = tableState.changedData?.filter((note) => note.isDirty)

  const isLoadingJob = useSelector((state) => {
    const filterLoading = createFilter({ id: payrollInstanceId, loading: 'true' })
    const filterCompleted = createFilter({ id: payrollInstanceId, completed: 'true' })
    return !!(state.payrollInstances.filters[filterLoading.name] && !state.payrollInstances.filters[filterCompleted.name])
  })

  const fetchVarianceWithFilter = ({ versionOne, versionTwo, onlyVariance, excludeZeroes, filters = {}, limit, offset: newOffset }) =>
    fetchVariance({
      id: payrollInstanceId,
      filter: createFilter({
        versionOne: versionOne,
        versionTwo: versionTwo,
        onlyVariance: onlyVariance,
        excludeZeroes,
        limit,
        offset: newOffset,
        ...normalizeFiltersForBE(filters, payrollReconciliationFilterMapping),
      }),
    })

  // VERSION DROPDOWNS
  /**
   * This par takes care that there are versions to show, at least two are needed to populate the dropdowns.
   * We are remapping the version names into options, and selecting the default value.
   * We are also saving the selected versions into redux -> see state.breadcrumbs
   * This way if a user navigates away from the page and then back, we show the previously selected versions
   */
  const { versionData, title, isFetching } = getTitleAndVersionData(payrollInstanceId)

  const preselectedFilter = useSelector((state) => {
    return getBreadcrumbFiltersByKey(state, { breadcrumbKey: location.pathname })
  })

  // remap versions for dropdown options
  const versionOptions = versionData.map((version) => ({
    value: version.id,
    label: `${version.type} v${version.version}, ${moment(version.createdAt.date).format(dateFormatEnums.DefaultDateFormatWithTime)} ${
      version.createdAt.timezone
    }`,
  }))

  // set default values for version dropdown selection
  if (versionOptions.length > 1) {
    ;[defaultOptionRight, defaultOptionLeft] = versionOptions
    if (preselectedFilter) {
      defaultOptionLeft = versionOptions.find((v) => v.value === preselectedFilter.params.versionOne)
      defaultOptionRight = versionOptions.find((v) => v.value === preselectedFilter.params.versionTwo)
    }
  }

  const onVersionSubmit = async (versionOne, versionTwo) => {
    const breadcrumbFilter = createFilter({
      versionOne: versionOne?.value,
      versionTwo: versionTwo?.value,
    })
    await dispatch(
      fetchVarianceWithFilter({
        versionOne: versionOne?.value,
        versionTwo: versionTwo?.value,
        onlyVariance: varianceFilterState,
        excludeZeroes: excludeZeroesFilterState,
        limit,
        offset,
        filters: tableFilters,
      })
    ).then((res) => {
      batch(() => {
        setTableState({ ...tableState, isLoading: true })
        setState({ ...state, varianceData: res, mainOptions: getMainOptions(res) })
      })
    })
    // saving the selected filters
    dispatch(pushBreadcrumbFilter({ routeKey: location.pathname, filters: breadcrumbFilter }))
  }

  // DOWNLOAD
  /**
   * Download is async with toast generation
   * We need to pass the same filters applied to the table, and the toggle switch to the BE to download the report
   * Because the downloaded report has to be filtered the same way the table is   *
   */
  const downloadReport = ({ versionOne, versionTwo, entityId, onlyVariance, excludeZeroes }) => {
    const payload = {
      versionOne,
      versionTwo,
      entityId,
      filters: {
        onlyVariance,
        excludeZeroes,
        ...normalizeFiltersForBE(tableFilters, payrollReconciliationFilterMapping),
      },
    }
    return dispatch((dispatch, getState) => {
      return dispatch(createNewVarianceExportJob(payload, {}, { holdTillComplete: true })).then((job) => {
        dispatch(startToastFileGeneration(job.id))
        toast.loading(<ToastLoadingViews job={job} />, { toastId: job.id, autoClose: false, closeOnClick: false })
      })
    })
  }

  const rehidrateData = ({
    page = 0,
    filters,
    varianceFilter = varianceFilterState,
    excludeZeroesFilter = excludeZeroesFilterState,
    selectedOptionLeft,
    selectedOptionRight,
  }) => {
    const filterOffset = page * limit
    // making a call with the filters object to the BE
    dispatch(
      fetchVarianceWithFilter({
        versionOne: selectedOptionLeft.value,
        versionTwo: selectedOptionRight.value,
        onlyVariance: varianceFilter,
        excludeZeroes: excludeZeroesFilter,
        limit,
        offset: filterOffset,
        filters: filters,
      })
    ).then((res) => {
      batch(() => {
        setState({ ...state, tableFilters: filters, offset: filterOffset, varianceData: res, mainOptions: getMainOptions(res) })
        setTableState({ ...tableState, data: [], changedData: [] })
      })
    })
  }

  const onFilterChange = ({ filters, selectedOptionLeft, selectedOptionRight }) => {
    rehidrateData({ page: 0, filters, selectedOptionLeft, selectedOptionRight })
  }

  // When we click on the toggle switch it needs to do 2 things:
  // change the local state where we keep track of the switch state,
  // and call the onFilter because we want to trigger a call to the API and this should behave like a table filter
  const onToggleVariance = (value, selectedOptionLeft, selectedOptionRight) => {
    batch(() => {
      setVarianceFilterState(value)
      if (value) setExcludeZeroesFilterState(value)
      rehidrateData({
        filters: tableFilters,
        varianceFilter: value,
        excludeZeroesFilter: value || excludeZeroesFilterState,
        selectedOptionLeft,
        selectedOptionRight,
      })
    })
  }

  const onToggleExcludeZeroes = (value, selectedOptionLeft, selectedOptionRight) => {
    batch(() => {
      setExcludeZeroesFilterState(value)
      if (!value) setVarianceFilterState(value)
      rehidrateData({
        filters: tableFilters,
        excludeZeroesFilter: value,
        varianceFilter: value && varianceFilterState,
        selectedOptionLeft,
        selectedOptionRight,
      })
    })
  }

  // TABLE DATA
  /**
   * Remapping table data and preparing the headings, along with creating the pagination object for the table
   */
  const noData = versionData.length < 2 && isEmpty(varianceData)

  const prepareDataForView = () => {
    let remappedTableData = []
    let remappedTiles = {}

    // varianceData will contain all the data we need to show in the tables
    // Every element in the body object comes with a property "belongsTo" that tells us which dictionary to use for element names and data formats
    // varianceData contains those dictionary objects as well
    const buildElementNameFromDictionary = (element, dictionary) => {
      const termName = getTermName({
        companyLocalName: varianceData[dictionary][element]?.companyLocalName,
        countryLocalName: varianceData[dictionary][element]?.countryLocalName,
        globalName: varianceData[dictionary][element]?.globalName,
        showElementCode: true,
        elementCode: varianceData[dictionary][element]?.elementCode,
      })

      return termName || varianceData[dictionary][element].name
    }
    const getElementFormatFromDictionary = (element, dictionary) => varianceData[dictionary][element].format
    const getElementCategoryFromDictionary = (element, dictionary) => varianceData[dictionary][element].category
    const getElementSubcategoryFromDictionary = (element, dictionary) => varianceData[dictionary][element].subcategory

    // remapping data for table
    for (const element in varianceData.body) {
      remappedTableData.push({
        elementName: buildElementNameFromDictionary(element, varianceData.body[element].belongsTo),
        payrollInstanceId,
        elementId: varianceData.termData[element]?.id || element,
        format: getElementFormatFromDictionary(element, varianceData.body[element].belongsTo),
        category: getElementCategoryFromDictionary(element, varianceData.body[element].belongsTo),
        subcategory: getElementSubcategoryFromDictionary(element, varianceData.body[element].belongsTo),
        subRows: [
          {
            id: buildElementNameFromDictionary(element, varianceData.body[element].belongsTo),
            ExpandedComponent: <span className=''>{varianceData.body[element].note}</span>,
          },
        ],
        ...varianceData.body[element],
      })
      if (varianceFilterState) remappedTableData = remappedTableData.filter((v) => v.result === 'Variance')
    }

    // Elements have to be sorted by a specific order saved to elementSortingOrder
    remappedTableData.sort((a, b) => {
      if (a.category !== b.category) return elementSortingOrder.indexOf(a.category) - elementSortingOrder.indexOf(b.category)

      // secondary sorting by subcategory alphabetically so elements with same subcategory are grouped together
      if (a.subcategory !== b.subcategory) return a.subcategory.localeCompare(b.subcategory)

      // inside subcategory we are sorting by element name alphabetically to keep roughly the same order in different versions
      return a.elementName.localeCompare(b.elementName)
    })

    // currency and total element count are a part of the tiles object
    // variance and variance_p depend on the switch filter state
    remappedTiles = {
      currency: varianceData.tiles.currency,
      totalElementsWithVariance: varianceData.tiles.totalElementsWithVariance,
      ...varianceData.tiles,
    }
    batch(() => {
      setTableState({
        ...tableState,
        tiles: remappedTiles,
        data: addExpandableSubRow(remappedTableData, {
          idKey: 'elementId',
          expandedTextKey: 'note',
          columnKey: 'note',
        }),
        isLoading: false,
      })
    })
  }

  const tableHeadings = generatePayrollReconciliationTableHeading({
    varianceData,
    versionData,
    defaultOptionRight,
    defaultOptionLeft,
    mainOptions: state.mainOptions,
  })

  // EFFECTS
  /**
   * After the first call to see if the versions exist, we have to check if the 2 versions are loaded
   * After they are loaded, we make the call to teh variance endpoint providing the 2 versions
   * Whenever we get fresh data from the variance endpoint, we need to run the remap again to prepare table data
   */

  // sets flag that shows if the default options have already loaded for the dropdowns
  useEffect(() => {
    setInitialLoading(!!(defaultOptionLeft && defaultOptionRight))
  }, [defaultOptionLeft, defaultOptionRight])

  // makes initial fetch to populate variance data, needs default options to be loaded
  useEffect(() => {
    const fetchVarianceData = async () => {
      await dispatch(
        fetchVarianceWithFilter({
          versionOne: defaultOptionLeft.value,
          versionTwo: defaultOptionRight.value,
          onlyVariance: varianceFilterState,
          excludeZeroes: excludeZeroesFilterState,
          limit,
          offset,
        })
      ).then((res) => {
        if (mountedRef) setState({ ...state, varianceData: res, mainOptions: getMainOptions(res) })
      })
      return () => {
        mountedRef.current = false
      }
    }

    if (initialLoading && !isEmpty(defaultOptionLeft) && !isEmpty(defaultOptionRight) && isEmpty(varianceData)) fetchVarianceData()
  }, [initialLoading])

  // handles remapping the data for the tables and tiles when there is fresh data or the variance filter is updated
  useEffect(() => {
    if (!noData && varianceData.body) {
      prepareDataForView()
    }
  }, [varianceData, varianceFilterState])

  const onSave = (versionOne, versionTwo, page) => {
    if (!isEmpty(notesToSave)) {
      dispatch(updateVarianceNotes({ versionOne: versionOne.value, versionTwo: versionTwo.value, data: notesToSave })).then((res) => {
        rehidrateData({ page, filters: tableFilters, selectedOptionLeft: versionOne, selectedOptionRight: versionTwo })
      })
    }
  }

  const onUpdateGlobalDataForSave = (rowIndex, columnId, value) => {
    const notFoundItemdIndex = -1
    const payload = tableState.changedData
    const originalComment = tableState.data[rowIndex]
    const savedCommentIndex = tableState.changedData.findIndex(({ elementId }) => elementId === originalComment?.elementId)
    const isDirty = originalComment?.[columnId] !== value

    if (savedCommentIndex > notFoundItemdIndex) {
      payload[savedCommentIndex][columnId] = value
      payload[savedCommentIndex].isDirty = isDirty
    } else {
      payload.push({
        ...tableState.data[rowIndex],
        [columnId]: value,
        isDirty: isDirty,
      })
    }
    setTableState({
      ...tableState,
      changedData: payload,
    })
  }
  const resetChangedData = () => {
    setTableState({
      ...tableState,
      changedData: [],
    })
  }

  if (isFetching || tableState.isLoading) return <Loader />

  return (
    <PayrollReconciliationView
      mainOptions={state.mainOptions}
      versionOptions={versionOptions}
      defaultOptionLeft={defaultOptionLeft}
      defaultOptionRight={defaultOptionRight}
      onVersionSubmit={onVersionSubmit}
      onToggleVariance={onToggleVariance}
      toggleValue={varianceFilterState}
      onToggleExcludeZeroes={onToggleExcludeZeroes}
      excludeZeroesToggleValue={excludeZeroesFilterState}
      title={title}
      tilesData={tableState.tiles}
      tableData={tableState.data}
      tableHeadings={tableHeadings}
      downloadReport={downloadReport}
      payrollInstanceId={payrollInstanceId}
      inProgressPollingJobs={inProgressPollingJobs}
      isLoadingJob={isLoadingJob}
      noData={noData}
      pagination={pagination}
      rehidrateData={rehidrateData}
      onFilterChange={onFilterChange}
      onUpdateGlobalData={onUpdateGlobalDataForSave}
      onSave={onSave}
      hasChanges={!isEmpty(notesToSave)}
      resetChangedData={resetChangedData}
      isLoading={tableState.isLoading}
    />
  )
}

export default PayrollReconciliationContainer
