/*
 * Provides an object of options for conditional rendering of individual buttons for the Options component
 *
 * example:
 * How to render only the "Pay date" button
 *
 * import { payDateButtonItem } from 'utils/buttonOptions'
 * <Options availableOptions={payDateButtonItem} />
 *
 * if no availableOptions is passed, the component defaults to all buttons
 */
export const PayrollTabModalOptionButtons = {
  payDateString: 'Pay date',
  actionOwnersString: 'Action owners',
  addMorePeopleString: 'Add more people',
  removePeopleString: 'Remove people',
  addVariableElementsString: 'Add more variable elements',
  removeVariableElementsString: 'Remove Variable Elements',
  addPayAndTaxesElementsString: 'Add P&T Elements',

  get allButtonItems () {
    return {
      payDateButton: this.payDateString,
      actionOwnersButton: this.actionOwnersString,
      addMorePeopleButton: this.addMorePeopleString,
      removePeopleButton: this.removePeopleString,
      addVariableElementsButton: this.addVariableElementsString,
      removeVariableElementsButton: this.removeVariableElementsString,
      addPayAndTaxesElementsButton: this.addPayAndTaxesElementsString,
    }
  },
  get payDateButtonItem () {
    return { payDateButton: this.payDateString }
  },
  get actionOwnersButtonItem () {
    return { actionOwnersButton: this.actionOwnersString }
  },
  get addMorePeopleButtonItem () {
    return { addMorePeopleButton: this.addMorePeopleString }
  },
  get removePeopleButtonItem () {
    return { removePeopleButton: this.removePeopleString }
  },
  get addVariableElementsButtonItem () {
    return { addVariableElementsButton: this.addVariableElementsString }
  },
  get removeVariableElementsButton () {
    return { removeVariableElementsButton: this.removeVariableElementsString }
  },
  get addPayAndTaxesElementsButton () {
    return { addPayAndTaxesElementsButton: this.addPayAndTaxesElementsString }
  },
}
