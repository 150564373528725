import RouteView from './components/RouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'
import YearRoute from './routes/Year'

export default (store) => ({
  path : 'calendar',
  indexRoute: { component: RouteView },
  exact: true,
  isOrphanedChild: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
  childRoutes: [ YearRoute(store) ]
})
