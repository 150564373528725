import React from 'react'
import { connect } from 'react-redux'
import ResetPasswordContainer from './ResetPasswordContainer'
import ResetPasswordConfirmationContainer from 'routes/ResetPasswordConfirmation/containers/ResetPasswordConfirmationContainer'
import LoginWrapper from 'layouts/LoginWrapper'
import ResetPasswordWrapper from 'layouts/ResetPasswordWrapper'
import PropTypes from 'prop-types'

export const RouteView = ({ is2FAenabled, ...props }) => {
  return (
    <>
      {is2FAenabled ? (
        <LoginWrapper>
          <ResetPasswordContainer {...props} />
        </LoginWrapper>
      ) : (
        <ResetPasswordWrapper>
          <ResetPasswordConfirmationContainer {...props} />
        </ResetPasswordWrapper>
      )}
    </>
  )
}

RouteView.propTypes = {
  is2FAenabled: PropTypes.bool
}

const mapStateToProps = (state) => ({
  is2FAenabled: state.config.is2FAenabled,
})

export default connect(mapStateToProps)(RouteView)
