/**
 * We have such file, extending `employeeTermPivot.js` actions + thunks,
 * in order to prevent circular dependency issue between `actions/employeeSystemUsers` and `employeeTermPivot.js`
 */

import { importEmployeeTerms as importTerms, invalidateEmployeeTermPivot } from './employeeTermPivot'
import { invalidateEmployeeSystemUsers } from './employeeSystemUsers'
import { invalidatePayrollInstanceCountryTermsPivot } from './payrollInstanceCountryTermsPivot'
import { invalidateAnnualSalaries } from './annualSalaries'

export const importEmployeeTerms = (payrollId, file) => (dispatch) =>
  dispatch(importTerms(payrollId, file)).then((response) => {
    dispatch(invalidateEmployeeSystemUsers())
    dispatch(invalidateEmployeeTermPivot())
    dispatch(invalidatePayrollInstanceCountryTermsPivot())
    dispatch(invalidateAnnualSalaries())

    return response
  })
