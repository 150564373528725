import React from 'react'
import ReportFiltersContainer from '../containers/ReportFiltersContainer'
import ReportDetailContainer from '../containers/ReportDetailContainer'

export default props => {
  return <>
    <ReportFiltersContainer {...props} />
    <ReportDetailContainer {...props} />
  </>
}
