import CoreLayout from 'layouts/PageLayout/PageLayout'
import CookiesRoute from './routes/Cookie'
import PrivacyRoute from './routes/Privacy'
import InnerWrapper from './components/InnerWrapper'

export default (store) => ({
  path: '/privacysecurity',
  indexRoute: { component: InnerWrapper },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayout: true,
  childRoutes: [CookiesRoute(store), PrivacyRoute(store)],
})
