import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'redux/actions'
import { isFetching } from 'utils/redux/fetching'
import { computeEntities, normalizeEntities, validateEntities } from 'utils/fetching'
import httpStatuses from 'utils/enums/restStatus'

export const useFetch = (entities = [], dependency = [], props) => {
  const normalizedEntities = normalizeEntities(entities)
  validateEntities(normalizedEntities, actions)

  const reduxState = useSelector((state) => state)
  const computedEntities = computeEntities(normalizedEntities, reduxState, props)
  const filters = {}
  const entitiesState = useSelector((state) => {
    return computedEntities.map(entity => {
      if (entity.params[0] && entity.params[0].filter) {
        filters[entity.name] = entity.params[0].filter

        return {
          ref: state[entity.name],
          filter: entity.params[0].filter
        }
      }

      return state[entity.name]
    })
  })
  const [dependantData, setDependantData] = useState({})
  const [errors, setErrors] = useState([])
  const [areEntitiesLoaded, setAreEntitiesLoaded] = useState(false)
  const dispatch = useDispatch()

  const fetchDependantCall = async (dependants = [], serverResponse) => {
    if (!serverResponse) return
    const promises = dependants.map(dep => {
      if (!dep.reduxEnabled && dep.responseField) {
        const { payload } = serverResponse
        const { filter } = payload
        if (!filter.params.id) {
          throw new Error('An id field must provided on the filter params')
        }
        const record = payload?.response.data.find(record => parseInt(record.id, 10) === parseInt(filter.params.id, 10))
        let externalUri = dep.url ?? null
        if (!dep.url) {
          externalUri = record[dep.responseField] ?? null
        }
        if (!externalUri) return

        return fetch(externalUri)
          .then((resp) => resp.json())
          .then(resp => {
            if (resp.statusCode && (resp.statusCode !== httpStatuses.OK)) {
              let currentErrors = errors
              currentErrors.push(resp.errors)
              setErrors(currentErrors)
            }
            return {
              parent: dep.parent,
              data: resp
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
    const responses = await Promise.all(promises)
    // If the action does trigger (as it's already fetch and cached)
    // It returns as [undefined] there's no harm with it being there, but just removing
    // As it isnt really needed
    return responses.filter(res => res !== undefined)
  }

  useEffect(() => {
    const fetchData = async () => {
      for (const entity of computedEntities) {
        const isForceFetching = entity.forceFetch ?? false
        const action = isForceFetching ? actions[entity.name].fetch : actions[entity.name].fetchIfNeeded

        const response = await dispatch(action(...entity.params))
        if (entity.dependants) {
          setAreEntitiesLoaded(false)
          const externalCall = await fetchDependantCall(entity.dependants, response)
          setDependantData({ [entity.name]: externalCall })
          setAreEntitiesLoaded(true)
        }
      }
    }
    fetchData()
  }, dependency)

  return {
    errors,
    dependantData,
    areDependentsLoaded: areEntitiesLoaded,
    isFetching: isFetching(entitiesState)
  }
}
