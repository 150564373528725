import RouteView from './components/RouteView'
import CoreLayout from 'layouts/PageLayout/PageLayout'

export default (store) => ({
  path : ':reportId/details',
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  wrappedComponent: CoreLayout,
})
