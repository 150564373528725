import createSelector from 'utils/createSelector'
import _ from 'lodash'
import { getCompanyCountryTermsPivotByCompanyAndCategory } from 'redux/selectors/companyCountryTermPivot'
import { findIfRecordIsHistorical } from '../utility'

const getEmployeeId = (state, props) => parseInt(props.employeeId)
const getCompanyId = (state, props) => parseInt(props.companyId)
const getEmployeePaygroups = (state, props) => props.employeePaygroups
const getFixedTermsProps = (state, props) => props.activeFixedTerms
const getActiveTerms = (state, props) => props.activeTerms
const getActivePayrollInstanceFromDate = (state, props) => props.activePayrollInstanceFromDate

/**
 * Get fixed terms by employee
 *
 * Fixed with and without amount
 *
 * @returns {Array}
 */
export const getFixedTerms = createSelector(getEmployeeId, getCompanyId,
  ({ CompanyCountryTerm }, employeeId, companyId) => {
    return CompanyCountryTerm.filter(
      pivot => pivot.company === companyId && pivot.type === 'fixed'
    ).toModelArray().map(term => {
      let employeeTerms = term.employeeCompanyCountryTerms.filter(
        employeeTerm => employeeTerm.employee === employeeId
      ).toRefArray(employeeTerm => ({
        ...employeeTerm
      }))

      let amount = employeeTerms[0] ? employeeTerms[0].amount : null

      return {
        ...term.ref,
        name: term.countryTerm.localName,
        globalName: term.countryTerm.term.name,
        shouldOnlyUseGlobalName: term.countryTerm.shouldOnlyUseGlobalName(),
        amount
      }
    })
  }
)

export const getFixedTermsWithDateFormatting = createSelector(getEmployeeId, getCompanyId,
  ({ CompanyCountryTerm }, employeeId, companyId) => {
    return CompanyCountryTerm.filter(
      pivot => pivot.company === companyId && pivot.type === 'fixed'
    ).toModelArray().map(term => {
      let employeeTerms = term.employeeCompanyCountryTerms.filter(
        employeeTerm => employeeTerm.employee === employeeId
      ).toRefArray(employeeTerm => ({
        ...employeeTerm
      }))

      let amount = employeeTerms[0] ? employeeTerms[0].amount : null

      return {
        ...term.ref,
        name: term.countryTerm.localName,
        localName: term.localName,
        globalName: term.countryTerm.term.name,
        validFrom: employeeTerms[0]?.validFrom ? new Date(employeeTerms[0].validFrom.date) : null,
        validTo: employeeTerms[0]?.validTo ? new Date(employeeTerms[0].validTo.date) : null,
        employeeTermId: employeeTerms[0] ? employeeTerms[0].id : null,
        shouldOnlyUseGlobalName: term.countryTerm.shouldOnlyUseGlobalName(),
        shouldDisableEditOnLoad: !employeeTerms[0]?.validFrom,
        amount
      }
    })
  }
)

/**
 * Get variable terms by employee
 *
 * @returns {Array}
 */
export const getEmployeeVariableTerms = createSelector(getEmployeeId,
  ({ EmployeeCompanyCountryTerm }, employeeId) => {
    return EmployeeCompanyCountryTerm.filter(pivot => pivot.employee === employeeId)
      .toModelArray()
      .filter(pivot => pivot.term.type === 'variable')
      .map(getTerms)
  }
)

/**
 * Get variable terms by paygroup
 *
 * @returns {Array}
 */
export const getPaygroupVariableTerms = createSelector(getEmployeePaygroups,
  ({ Paygroup }, paygroups) => {
    let terms = paygroups.map(paygroup => {
      return Paygroup.withId(paygroup.id)
        .paygroupTerms
        .all()
        .toModelArray()
        .filter(pivot => pivot.companyCountryTerm.type === 'variable')
        .map(paygroupTerm => ({
          id: paygroupTerm.companyCountryTerm.countryTerm.id,
          localName: paygroupTerm.companyCountryTerm.countryTerm.localName,
          globalName: paygroupTerm.companyCountryTerm.countryTerm.term.name,
        }))
    })

    return _.flatten(terms)
  }
)

/**
 * Get variable terms by employee with paygroup variable terms
 *
 * @returns {Array}
 */
export const getEmployeeVariableTermsWithPaygroupVariableTerms = createSelector(
  getEmployeeVariableTerms, getPaygroupVariableTerms,
  (session, employeeVariableTerms, paygroupVariableTerms) => {
    let variableTerms = _.concat(employeeVariableTerms, paygroupVariableTerms)
    variableTerms = _.uniqBy(variableTerms, 'id')
    return variableTerms
  }
)

/**
 * Push terms to correct format
 * @param {} employeeTerm
 * @return {}
 */
const getTerms = employeeTerm => {
  return {
    id: employeeTerm.term.countryTerm.id,
    amount: employeeTerm.amount,
    localName: employeeTerm.term.countryTerm.localName,
    globalName: employeeTerm.term.countryTerm.term.name,
    shouldOnlyUseGlobalName: employeeTerm.term.countryTerm.shouldOnlyUseGlobalName(),
    companyCountryTermLocalName: employeeTerm.term.localName
  }
}

/**
 * Get Employee Terms
 *
 * @returns {Array}
 */
const getEmployeeTerms = createSelector(getEmployeeId,
  ({ EmployeeCompanyCountryTerm }, employeeId) => {
    return EmployeeCompanyCountryTerm.filter(pivot => pivot.employee === employeeId)
      .toRefArray()
      .map(entity => ({ ...entity }))
  }
)

export const getActiveCCTsWithEmployeeValues = createSelector(
  getEmployeeTerms,
  getCompanyCountryTermsPivotByCompanyAndCategory,
  (session, employeeTerms, ccts) => {
    return ccts.map((cct) => {
      const employeeTerm = employeeTerms.find((et) => et.term === cct.id)
      return {
        ...cct,
        shouldDisableEditOnLoad: !employeeTerm,
        ...(employeeTerm
          ? {
            employeeTermId: employeeTerm.id,
            amount: employeeTerm.amount,
            validFrom: employeeTerm.validFrom ? new Date(employeeTerm.validFrom?.date) : null,
            validTo: employeeTerm.validTo ? new Date(employeeTerm.validTo?.date) : null,
            shouldDisableEditOnLoad: !employeeTerm.validFrom
          }
          : {}),
      }
    })
  }
)

export const getFutureCCTsWithEmployeeValues = createSelector(
  getEmployeeTerms,
  getCompanyCountryTermsPivotByCompanyAndCategory,
  getActiveTerms,
  getActivePayrollInstanceFromDate,
  (session, employeeTerms, ccts, activeTerms, activePayrollInstanceFromDate) => {
    let futureItems = []
    activeTerms.forEach(at => {
      let futureTerms = employeeTerms.filter(et => et.term === at.id)
      let filteredFutureTerms = futureTerms.filter(ft => ft.id !== at.employeeTermId)

      filteredFutureTerms.forEach(fft => {
        let cct = ccts.find(cct => cct.id === fft.term)

        const dateFormattedTerm = {
          ...cct,
          employeeTermId: fft.id,
          amount: fft.amount ?? '',
          validFrom: fft.validFrom ? new Date(fft.validFrom?.date) : null,
          validTo: fft.validTo ? new Date(fft.validTo?.date) : null,
        }
        const isRecordHistorical = findIfRecordIsHistorical(dateFormattedTerm, activePayrollInstanceFromDate)

        if (!isRecordHistorical) {
          futureItems.push(dateFormattedTerm)
        }
      })
    })
    return futureItems
  }
)

export const getFutureFixedTermsWithDateFormatting = createSelector(
  getEmployeeTerms,
  getCompanyId,
  getFixedTermsProps,
  getActivePayrollInstanceFromDate,
  ({ CompanyCountryTerm }, employeeTerms, companyId, activeFixedTerms, activePayrollInstanceFromDate) => {
    let futureItems = []

    const ccts = CompanyCountryTerm.filter(pivot => pivot.company === companyId && pivot.type === 'fixed').toModelArray()

    activeFixedTerms.forEach(aft => {
      let futureTerms = employeeTerms.filter(et => (et.term === aft.id))
      let filteredFutureTerms = futureTerms.filter(item => item.id !== aft.employeeTermId)

      filteredFutureTerms.forEach(futureTerm => {
        let cct = ccts.find(cct => cct.id === futureTerm.term)

        const dateFormattedTerm = {
          ...futureTerm.ref,
          countryTermId: cct.id,
          name: cct.countryTerm.localName,
          localName: cct.localName,
          globalName: cct.countryTerm.term.name,
          validFrom: futureTerm?.validFrom ? new Date(futureTerm.validFrom.date) : null,
          validTo: futureTerm?.validTo ? new Date(futureTerm.validTo.date) : null,
          employeeTermId: futureTerm.id,
          amount: futureTerm.amount
        }

        const isRecordHistorical = findIfRecordIsHistorical(dateFormattedTerm, activePayrollInstanceFromDate)

        if (!isRecordHistorical) {
          futureItems.push(dateFormattedTerm)
        }
      })
    })
    return futureItems
  }
)
