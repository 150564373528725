import { createTransform, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import locationReducer from './location'
import { reducer as reduxFormReducer } from 'redux-form'
import countries from '../redux/reducers/countries'
import countryServicePivot from '../redux/reducers/countryServicePivot'
import countryProcessPivot from '../redux/reducers/countryProcessPivot'
import countryStepPivot from '../redux/reducers/countryStepPivot'
import countryTermPivot from '../redux/reducers/countryTermPivot'
import countryTimezonePivot from '../redux/reducers/countryTimezonePivot'
import currencies from '../redux/reducers/currencies'
import languages from '../redux/reducers/languages'
import timezones from '../redux/reducers/timezones'
import holidays from '../redux/reducers/holidays'
import taxAgencies from '../redux/reducers/taxAgencies'
import deadlines from '../redux/reducers/deadlines'
import processes from '../redux/reducers/processes'
import services from '../redux/reducers/services'
import steps from '../redux/reducers/steps'
import tasks from '../redux/reducers/tasks'
import terms from '../redux/reducers/terms'
import termCategories from '../redux/reducers/termCategories'
import termSubCategories from '../redux/reducers/termSubCategories'
import companies from '../redux/reducers/companies'
import companyServicePivot from '../redux/reducers/companyServicePivot'
import companyProcessPivot from '../redux/reducers/companyProcessPivot'
import companyStepPivot from '../redux/reducers/companyStepPivot'
import companyTaskPivot from '../redux/reducers/companyTaskPivot'
import companyCountryTermPivot from '../redux/reducers/companyCountryTermPivot'
import companyHolidayPivot from '../redux/reducers/companyHolidayPivot'
import parentCompanies from '../redux/reducers/parentCompanies'
import employeeSystemUsers from '../redux/reducers/employeeSystemUsers'
import cotUsers from '../redux/reducers/cotUsers'
import vendorUsers from '../redux/reducers/vendorUsers'
import payrolls from '../redux/reducers/payrolls'
import paygroups from '../redux/reducers/paygroups'
import payrollEmployeePivot from '../redux/reducers/payrollEmployeePivot'
import payrollPaygroupPivot from '../redux/reducers/payrollPaygroupPivot'
import paygroupEmployeePivot from '../redux/reducers/paygroupEmployeePivot'
import paygroupTermPivot from '../redux/reducers/paygroupTermPivot'
import employeeTermPivot from '../redux/reducers/employeeTermPivot'
import payfiles from '../redux/reducers/payfiles'
import emptyPayfiles from '../redux/reducers/emptyPayfiles'
import payfileChanges from '../redux/reducers/payfileChanges'
import payrollInstances from '../redux/reducers/payrollInstances'
import payrollInstanceService from '../redux/reducers/payrollInstanceService'
import payrollInstanceProcess from '../redux/reducers/payrollInstanceProcess'
import payrollInstanceStep from '../redux/reducers/payrollInstanceStep'
import payrollInstanceTask from '../redux/reducers/payrollInstanceTask'
import payrollInstanceTaskOwner from '../redux/reducers/payrollInstanceTaskOwner'
import payrollInstanceTaskOwnerScope from '../redux/reducers/payrollInstanceTaskOwnerScope'
import payrollInstanceCountryTermsPivot from '../redux/reducers/payrollInstanceCountryTermsPivot'
import payrollInstanceEmployeePivot from '../redux/reducers/payrollInstanceEmployeePivot'
import employeeAttendance from '../redux/reducers/employeeAttendance'
import employeeOvertime from '../redux/reducers/employeeOvertime'
import employeeAttendancePeriods from '../redux/reducers/employeeAttendancePeriods'
import vendors from '../redux/reducers/vendors'
import vendorCountryServicePivot from '../redux/reducers/vendorCountryServicePivot'
import countryTaskPivot from '../redux/reducers/countryTaskPivot'
import vendorCountryTaskPivot from '../redux/reducers/vendorCountryTaskPivot'
import businessUnits from '../redux/reducers/businessUnits'
import costCenters from '../redux/reducers/costCenters'
import departments from '../redux/reducers/departments'
import officeLocations from '../redux/reducers/officeLocations'
import subsidiary from '../redux/reducers/subsidiaries'
import projects from '../redux/reducers/projects'
import payrollService from '../redux/reducers/payrollService'
import payrollProcess from '../redux/reducers/payrollProcess'
import payrollStep from '../redux/reducers/payrollStep'
import payrollTask from '../redux/reducers/payrollTask'
import orm from '../redux/reducers/orm'
import modal from '../redux/reducers/modal'
import tenants from '../redux/reducers/tenants'
import invalidator from '../redux/reducers/invalidator'
import vendorTenant from '../redux/reducers/vendorTenant'
import accessAreaPivot from '../redux/reducers/accessAreaPivot'
import auth from '../redux/reducers/auth'
import brandings from '../redux/reducers/brandings'
import config from '../redux/reducers/config'
import aside from '../redux/reducers/aside'
import events from '../redux/reducers/events'
import assignments from '../redux/reducers/assignments'
import bankAccounts from '../redux/reducers/bankAccounts'
import bankAccountTypes from '../redux/reducers/bankAccountTypes'
import employeeCountryPivot from '../redux/reducers/employeeCountryPivot'
import editors from '../redux/reducers/editors'
import notes from '../redux/reducers/notes'
import noteUsers from '../redux/reducers/noteUsers'
import reports from '../redux/reducers/reports'
import notifications from '../redux/reducers/notifications'
import fxRates from '../redux/reducers/fxRates'
import dataProtectionFields from '../redux/reducers/dataProtectionFields'
import countryDataProtectionFieldsPivot from '../redux/reducers/countryDataProtectionFieldsPivot'
import dataOwners from '../redux/reducers/dataOwners'
import companyDataProtectionFieldsPivot from '../redux/reducers/companyDataProtectionFieldsPivot'
import approvedCompanyDataProtectionFieldsPivot from '../redux/reducers/approvedCompanyDataProtectionFieldsPivot'
import document from '../redux/reducers/document'
import documentUsers from '../redux/reducers/documentUsers'
import externalVendorUsers from '../redux/reducers/externalVendorUsers'
import externalEmployeeUsers from '../redux/reducers/externalEmployeeUsers'
import companyDataProtectionFieldsRevision from '../redux/reducers/companyDataProtectionFieldsRevision'
import documentLogs from '../redux/reducers/documentLogs'
import documentTypes from '../redux/reducers/documentTypes'
import companyDataProtectionFieldState from '../redux/reducers/companyDataProtectionFieldState'
import payrollTaskOwners from '../redux/reducers/payrollTaskOwners'
import payrollTaskOwnerScopes from '../redux/reducers/payrollTaskOwnerScopes'
import annualSalaries from '../redux/reducers/annualSalaries'
import noteShares from '../redux/reducers/noteShares'
import referrals from '../redux/reducers/referrals'
import generalLedgers from '../redux/reducers/generalLedgers'
import reportData from '../redux/reducers/reportData'
import dashboards from '../redux/reducers/dashboards'
import generalLedgerGlobalData from '../redux/reducers/generalLedgerGlobalData'
import vendorSwitch from 'redux/reducers/vendorSwitch'
import employeeUserAddresses from 'redux/reducers/employeeUserAddresses'
import pollingJob from '../redux/reducers/pollingJob'
import toast from '../redux/reducers/toast'
import breadcrumbs from '../redux/reducers/breadcrumbs'
import payrollInstanceVersion from 'redux/reducers/payrollInstanceVersion'
import externalCompanies from '../redux/reducers/externalCompanies'
import commentary from 'redux/reducers/commentary'
import additionalItems from 'redux/reducers/additionalItems'
import resources from 'redux/reducers/resources'
import resourceLogs from 'redux/reducers/resourceLogs'

const authBlackListTransform = createTransform(
  // Transform state on its way to being serialized and persisted.
  (inboundState) => {
    const authReducerState = { ...inboundState }
    if (authReducerState?.rhssoLogin) {
      // Define the properties you want to ignore
      const blacklist = ['accessToken', 'refreshToken']
      // Remove the blacklisted properties from the state object
      blacklist.forEach((prop) => {
        delete authReducerState[prop]
      })
    }
    return authReducerState
  },
  // No transformation on rehydration is needed
  (outboundState) => outboundState,
  { whitelist: ['auth'] }
)

const localConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'tenants', 'aside', 'vendorTenant', 'pollingJob', 'breadcrumbs'],
  transforms: [authBlackListTransform],
}

export const makeRootReducer = (asyncReducers) => {
  return persistCombineReducers(localConfig, {
    orm,
    config,
    aside,
    auth,
    brandings,
    countries,
    countryServicePivot,
    countryProcessPivot,
    countryStepPivot,
    countryTermPivot,
    countryTimezonePivot,
    currencies,
    languages,
    timezones,
    holidays,
    taxAgencies,
    deadlines,
    processes,
    services,
    steps,
    tasks,
    terms,
    termCategories,
    termSubCategories,
    companies,
    companyServicePivot,
    companyProcessPivot,
    companyStepPivot,
    companyTaskPivot,
    companyCountryTermPivot,
    companyHolidayPivot,
    parentCompanies,
    employeeSystemUsers,
    cotUsers,
    vendorUsers,
    payrolls,
    paygroups,
    payrollEmployeePivot,
    payrollPaygroupPivot,
    paygroupEmployeePivot,
    paygroupTermPivot,
    employeeTermPivot,
    payfiles,
    emptyPayfiles,
    payfileChanges,
    payrollInstances,
    payrollInstanceService,
    payrollInstanceProcess,
    payrollInstanceStep,
    payrollInstanceTask,
    payrollInstanceTaskOwner,
    payrollInstanceTaskOwnerScope,
    payrollInstanceCountryTermsPivot,
    payrollInstanceEmployeePivot,
    payrollInstanceVersion,
    employeeAttendance,
    employeeOvertime,
    employeeAttendancePeriods,
    vendors,
    vendorCountryServicePivot,
    countryTaskPivot,
    vendorCountryTaskPivot,
    payrollService,
    payrollProcess,
    payrollStep,
    payrollTask,
    businessUnits,
    costCenters,
    departments,
    officeLocations,
    subsidiary,
    projects,
    location: locationReducer,
    form: reduxFormReducer, // mounted under "form"
    modal,
    tenants,
    invalidator,
    accessAreaPivot,
    vendorTenant,
    events,
    assignments,
    bankAccounts,
    bankAccountTypes,
    employeeCountryPivot,
    editors,
    notes,
    noteUsers,
    reports,
    notifications,
    fxRates,
    dataProtectionFields,
    countryDataProtectionFieldsPivot,
    dataOwners,
    companyDataProtectionFieldsPivot,
    approvedCompanyDataProtectionFieldsPivot,
    document,
    documentUsers,
    externalVendorUsers,
    externalEmployeeUsers,
    externalCompanies,
    companyDataProtectionFieldsRevision,
    documentLogs,
    documentTypes,
    companyDataProtectionFieldState,
    payrollTaskOwners,
    payrollTaskOwnerScopes,
    annualSalaries,
    noteShares,
    referrals,
    dashboards,
    generalLedgers,
    reportData,
    generalLedgerGlobalData,
    vendorSwitch,
    employeeUserAddresses,
    pollingJob,
    toast,
    breadcrumbs,
    commentary,
    additionalItems,
    resources,
    resourceLogs,
    ...asyncReducers,
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
