import React from 'react'

const PayrollsPinButton = ({ pinned, onClick, id, canPinPayroll = true }) => {
  const pinnedCss = pinned ? 'pinned' : 'not-pinned'
  const canPinCss = canPinPayroll ? 'can-pin' : 'pinning-not-allowed'
  return (
    <div
      className={`pin-icon hover-box-cell ${pinnedCss} ${canPinCss}`}
      onClick={onClick}
      data-testid={`pin-button-${id}`}
    >
      <span className='icon' />
      <div className='hover-box hover-box--pinned-payruns'>
        <div className='text-center u-text-u-weight--regular'>{pinned ? 'Unpin' : 'Pin'}</div>
      </div>
    </div>
  )
}

export default PayrollsPinButton
