/* eslint-disable jsx-quotes */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const COMMON_ICONS_TYPES = {
  arrow: 'arrow',
  view: 'view'
}

export const IconArrow = (props) => {
  const { width = 24, height = 24, fill = '#291547', className = '' } = props
  return <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5397 11.2895L9.87974 5.63955C9.78677 5.54582 9.67617 5.47143 9.55431 5.42066C9.43246 5.36989 9.30175 5.34375 9.16974 5.34375C9.03773 5.34375 8.90702 5.36989 8.78516 5.42066C8.6633 5.47143 8.5527 5.54582 8.45974 5.63955C8.27349 5.82691 8.16895 6.08036 8.16895 6.34455C8.16895 6.60873 8.27349 6.86219 8.45974 7.04955L13.4097 12.0495L8.45974 16.9995C8.27349 17.1869 8.16895 17.4404 8.16895 17.7045C8.16895 17.9687 8.27349 18.2222 8.45974 18.4095C8.55235 18.504 8.6628 18.5792 8.78467 18.6307C8.90655 18.6822 9.03743 18.709 9.16974 18.7095C9.30204 18.709 9.43293 18.6822 9.5548 18.6307C9.67668 18.5792 9.78712 18.504 9.87974 18.4095L15.5397 12.7595C15.6412 12.6659 15.7223 12.5523 15.7777 12.4258C15.8331 12.2993 15.8617 12.1627 15.8617 12.0245C15.8617 11.8864 15.8331 11.7498 15.7777 11.6233C15.7223 11.4968 15.6412 11.3832 15.5397 11.2895Z" />
  </svg>
}

export const IconView = (props) => {
  const { width = 21, height = 16, fill = '#5292dd', className = '' } = props
  return <svg className={`icon-view ${className}`} width={width} height={height} viewBox="0 0 21 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9235 7.6C17.9035 2.91 14.1035 0 10.0035 0C5.90348 0 2.10348 2.91 0.0834848 7.6C0.0284215 7.72617 0 7.86234 0 8C0 8.13766 0.0284215 8.27383 0.0834848 8.4C2.10348 13.09 5.90348 16 10.0035 16C14.1035 16 17.9035 13.09 19.9235 8.4C19.9785 8.27383 20.007 8.13766 20.007 8C20.007 7.86234 19.9785 7.72617 19.9235 7.6ZM10.0035 14C6.82348 14 3.83348 11.71 2.10348 8C3.83348 4.29 6.82348 2 10.0035 2C13.1835 2 16.1735 4.29 17.9035 8C16.1735 11.71 13.1835 14 10.0035 14ZM10.0035 4C9.21236 4 8.439 4.2346 7.7812 4.67412C7.12341 5.11365 6.61072 5.73836 6.30797 6.46927C6.00522 7.20017 5.926 8.00444 6.08034 8.78036C6.23468 9.55628 6.61565 10.269 7.17506 10.8284C7.73447 11.3878 8.4472 11.7688 9.22312 11.9231C9.99905 12.0775 10.8033 11.9983 11.5342 11.6955C12.2651 11.3928 12.8898 10.8801 13.3294 10.2223C13.7689 9.56448 14.0035 8.79113 14.0035 8C14.0035 6.93913 13.5821 5.92172 12.8319 5.17157C12.0818 4.42143 11.0644 4 10.0035 4ZM10.0035 10C9.60792 10 9.22124 9.8827 8.89234 9.66294C8.56345 9.44318 8.3071 9.13082 8.15573 8.76537C8.00435 8.39991 7.96474 7.99778 8.04191 7.60982C8.11908 7.22186 8.30957 6.86549 8.58927 6.58579C8.86898 6.30608 9.22534 6.1156 9.6133 6.03843C10.0013 5.96126 10.4034 6.00087 10.7689 6.15224C11.1343 6.30362 11.4467 6.55996 11.6664 6.88886C11.8862 7.21776 12.0035 7.60444 12.0035 8C12.0035 8.53043 11.7928 9.03914 11.4177 9.41421C11.0426 9.78929 10.5339 10 10.0035 10Z" />
  </svg>
}

export const CommonIcon = ({ type, ...rest }) => {
  switch (type) {
  case COMMON_ICONS_TYPES.arrow:
    return <IconArrow {...rest} />
  case COMMON_ICONS_TYPES.view:
    return <IconView {...rest} />
  default:
    return null
  }
}

const commonProps = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string
}

CommonIcon.propTypes = {
  type: PropTypes.string,
}

IconArrow.propTypes = commonProps
IconView.propTypes = commonProps
