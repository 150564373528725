import { toast } from 'react-toastify'

export const TOAST_FILE_GENERATION_START = 'TOAST_FILE_GENERATION_START'
export const TOAST_FILE_GENERATION_DONE = 'TOAST_FILE_GENERATION_DONE'
export const CLEAR_ALL_TOAST_MESSAGES = 'CLEAR_ALL_TOAST_MESSAGES'

export function closeAllToastMessages () {
  return function (dispatch) {
    dispatch({
      type: CLEAR_ALL_TOAST_MESSAGES
    })
    toast.dismiss()
  }
}

export function startToastFileGeneration (toastId, job) {
  return function (dispatch) {
    dispatch({
      type: TOAST_FILE_GENERATION_START
    })
  }
}

export function endToastFileGeneration (toastId, cb, job) {
  return function (dispatch) {
    dispatch({
      type: TOAST_FILE_GENERATION_DONE
    })
  }
}
