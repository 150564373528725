import React from 'react'
import Fetcher from 'containers/Fetcher'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getEmployeeFieldsByCountry,
  getEmployeePayTaxesFields,
  getEmployeeWithCurrencyAndTerms
} from '../../../selectors/employeesORM'
import {
  getActiveCCTsWithEmployeeValues,
  getFutureCCTsWithEmployeeValues,
  getEmployeeVariableTermsWithPaygroupVariableTerms,
  getFixedTermsWithDateFormatting,
  getFutureFixedTermsWithDateFormatting
} from '../selectors/employeeTermPivotORM'
import termCategories from 'redux/config/termCategories'
import { getAllPayrollsByEmployee } from '../selectors/employeePayrolls'
import { getPaygroupsByEmployee } from '../selectors/employeePaygroups'
import { isEmployeeOnly } from 'redux/selectors/auth'
import { getAnnualSalariesByEmployee } from 'redux/selectors/annualSalaries'
import { getRecurringDeductionsByCompany } from 'redux/selectors/companyCountryTermPivot'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import PayTaxesViewContainer from '../containers/PayTaxesViewContainer'
import { retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'
import Loader from 'components/Loader'
import useEmployeeCustomFields from 'hooks/useEmployeeCustomFields'
import { getPayrollRef } from 'redux/selectors/payroll'
import { first } from 'lodash'
import { createFilter } from 'utils/redux/filter'
import { getPayrollInstancesByPayrollId } from 'routes/Payruns/selectors/payrollInstancesORM'
import { activeTabStatuses, postPayrollTabStatuses } from 'utils/payrollInstanceStatuses'
import { findIfRecordIsHistorical, findMostNextActiveRecord } from '../utility'

const validFromAsc = (a, b) => new Date(a.validFrom) - new Date(b.validFrom)

const PayTaxesContainer = (props) => {
  const { fields, isFetchingExtraFields } = useEmployeeCustomFields(retrieveCompanyCustomFields, props.employee.companyRef?.id, [
    props.employee.companyRef?.id,
  ])

  if (isFetchingExtraFields) return <Loader />
  return <PayTaxesViewContainer extraFields={fields} {...props} />
}

PayTaxesContainer.propTypes = {
  employee: PropTypes.object,
}

const filterOutHistoricInstances = (instances, piFromDate, futureItems = []) => {
  let hasFutureItems = {}
  let adjustFutureItems = futureItems

  instances.forEach(instance => {
    let item = instance.countryTermId
      ? futureItems.find(item => item.countryTermId === instance.countryTermId)
      : futureItems.find(item => item.countryTermId === instance.id)
    hasFutureItems[instance.id] = !!item
  })

  if (!piFromDate) return instances
  // Go through instances, remove items that are historical and replace with the next active one
  // If there are no next active ones, just return the historical one
  instances.map(record => {
    const isRecordHistorical = findIfRecordIsHistorical(record, piFromDate)
    // if the record has no future items, and is historical should have edit disabled and empty fields on create
    if (!hasFutureItems[record.id]) {
      if (isRecordHistorical) {
        record['shouldDisableEditOnLoad'] = true
        record['validFrom'] = ''
        record['validTo'] = ''
        record['amount'] = ''
        record['employeeTermId'] = ''
      }
      return record
    }

    if (isRecordHistorical) {
      const mostActiveRecord = futureItems.find(fi => findMostNextActiveRecord(fi, piFromDate, record.id))

      record['validFrom'] = mostActiveRecord.validFrom
      record['validTo'] = mostActiveRecord.validTo
      record['amount'] = mostActiveRecord.amount
      record['employeeTermId'] = mostActiveRecord.employeeTermId

      adjustFutureItems = adjustFutureItems.filter(future => future.id !== mostActiveRecord.id)

      return record
    }
    return record
  })
  return instances
}

const mapStateToProps = (state, props) => {
  const employee = getEmployeeWithCurrencyAndTerms(state, { employeeId: props.match.params.employeeId })
  const employeeCustomFieldsObj = getEmployeeFieldsByCountry(state, { 'country': employee.companyCountry.name })
  const employeeCustomFields = employeeCustomFieldsObj ? getEmployeePayTaxesFields(state, {
    'country': employee.companyCountry.name }) : []

  const recurringDeductions = getRecurringDeductionsByCompany(state, { companyId: employee.company })

  let employeePaygroups = getPaygroupsByEmployee(state, { employeeId: props.match.params.employeeId })
  let employeePayrolls = getAllPayrollsByEmployee(state, { employeeId: props.match.params.employeeId })

  const employeeAnnualSalary = getAnnualSalariesByEmployee(state, { employeeId: props.match.params.employeeId })
  const EmployeeStatus = new EmploymentStatusHelper(employee.employmentStatus)

  const payroll = first(employeePayrolls)

  const hasPayrollActiveInstances = payroll ? getPayrollRef(state, { payrollId: payroll.id }).hasActivePayrollInstances : false

  const activePayrollInstance = payroll ? getPayrollInstancesByPayrollId(state, { payrollId: payroll.id })
    .find(pi => activeTabStatuses.includes(pi.status) || postPayrollTabStatuses.includes(pi.status)) : false
  const activePayrollInstanceFromDate = activePayrollInstance ? new Date(activePayrollInstance.fromDate?.date) : null

  const hasEmployeeActiveInstances = hasPayrollActiveInstances || employee.hasActivePayrollInstances
  const hasEmployeeLockedInstances = employee.hasLockedPayrollInstances

  // Fixed Terms
  const fixedTerms = getFixedTermsWithDateFormatting(state, {
    employeeId: props.match.params.employeeId,
    companyId: employee.company
  })

  let futureFixedTerms = getFutureFixedTermsWithDateFormatting(state, {
    employeeId: props.match.params.employeeId,
    companyId: employee.company,
    activeFixedTerms: fixedTerms,
    activePayrollInstanceFromDate
  })

  const filteredFixedTerms = filterOutHistoricInstances(fixedTerms, activePayrollInstanceFromDate, futureFixedTerms)

  filteredFixedTerms.forEach(term => {
    futureFixedTerms = futureFixedTerms.filter(futureTerm => futureTerm.employeeTermId !== term.employeeTermId)
  })

  // Variable Terms
  const variableTerms = getEmployeeVariableTermsWithPaygroupVariableTerms(state, {
    employeeId: props.match.params.employeeId,
    employeePaygroups
  })

  // Employer Deductions
  const employerDeductions = getActiveCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company ? employee.company : null,
    categoryName: termCategories.employerDeductions,
  })

  let futureEmployerDeductions = getFutureCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company,
    categoryName: termCategories.employerDeductions,
    activeTerms: employerDeductions,
    activePayrollInstanceFromDate
  })

  const filteredEmployerDeductions = filterOutHistoricInstances(employerDeductions, activePayrollInstanceFromDate, futureEmployerDeductions)

  filteredEmployerDeductions.forEach(deduct => {
    futureEmployerDeductions = futureEmployerDeductions.filter(futureDeduct => futureDeduct.employeeTermId !== deduct.employeeTermId)
  })

  // Employee Deductions
  const employeeDeductions = getActiveCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company,
    categoryName: termCategories.employeeDeductions,
  })

  let futureEmployeeDeductions = getFutureCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company,
    categoryName: termCategories.employeeDeductions,
    activeTerms: employeeDeductions,
    activePayrollInstanceFromDate
  })
  const filteredEmployeeDeductions = filterOutHistoricInstances(employeeDeductions, activePayrollInstanceFromDate, futureEmployeeDeductions)

  filteredEmployeeDeductions.forEach(deduct => {
    futureEmployeeDeductions = futureEmployeeDeductions.filter(futureDeduct => futureDeduct.employeeTermId !== deduct.employeeTermId)
  })

  // Employee Net Deductions
  const employeeNetDeductions = getActiveCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company,
    categoryName: termCategories.employeeNetDeductions,
  })

  let futureEmployeeNetDeductions = getFutureCCTsWithEmployeeValues(state, {
    employeeId: employee.id,
    companyId: employee.company,
    categoryName: termCategories.employeeNetDeductions,
    activeTerms: employeeNetDeductions,
    activePayrollInstanceFromDate
  })
  const filteredEmployeeNetDeductions = filterOutHistoricInstances(employeeNetDeductions, activePayrollInstanceFromDate, futureEmployeeNetDeductions)

  filteredEmployeeNetDeductions.forEach(deduct => {
    futureEmployeeNetDeductions = futureEmployeeNetDeductions.filter(futureDeduct => futureDeduct.employeeTermId !== deduct.employeeTermId)
  })

  return {
    employee,
    employeeCustomFields,
    employeeAnnualSalary: employeeAnnualSalary.length ? employeeAnnualSalary[0].value : null,
    fixedTerms: filteredFixedTerms,
    futureFixedTerms: futureFixedTerms.sort(validFromAsc),
    variableTerms,
    effectiveDate: employeeAnnualSalary.length ? employeeAnnualSalary[0].effectiveDate : null,
    employerDeductions: filteredEmployerDeductions,
    employeeDeductions: filteredEmployeeDeductions,
    employeeNetDeductions: filteredEmployeeNetDeductions,
    futureEmployeeDeductions: futureEmployeeDeductions.sort(validFromAsc),
    futureEmployerDeductions: futureEmployerDeductions.sort(validFromAsc),
    futureEmployeeNetDeductions: futureEmployeeNetDeductions.sort(validFromAsc),
    EmployeeStatus,
    employeePayrolls,
    employeePaygroups,
    isEmployeeOnly: isEmployeeOnly(state),
    // Show the modal, only if the employee is part of active or locked payruns
    // and the annual salary value is changed (that will be shown on the AnnualSalaryTableView)
    showConfirmation: (hasEmployeeActiveInstances || hasEmployeeLockedInstances),
    showFixedTermConfirmation: hasEmployeeLockedInstances,
    hasEmployeeActiveInstances: hasEmployeeActiveInstances,
    hasEmployeeLockedInstances: hasEmployeeLockedInstances,
    isEditDeductionsButtonVisible: recurringDeductions.length > 0
  }
}

const Container = connect(mapStateToProps)(PayTaxesContainer)

export default Fetcher(Container, [
  'countries',
  'countryTermPivot',
  'currencies',
  'terms',
  'payrolls',
  'paygroups',
  'paygroupTermPivot',
  'payrollPaygroupPivot',
  'termCategories',
  'termSubCategories',
  'companyCountryTermPivot',
  {
    name: 'employeeTermPivot',
    params: [{
      _computed: {
        filter: (state, props) => createFilter({
          employee: props.match.params.employeeId,
          sort: [{ name: 'validFrom', order: 'asc' }]
        })
      }
    }]
  },
  createEmployeeFilter({ name: 'payrollInstances' }),
  createEmployeeFilter({ name: 'employeeCountryPivot' }),
  createEmployeeFilter({ name: 'payrollEmployeePivot' }),
  createEmployeeFilter({ name: 'paygroupEmployeePivot' }),
  createEmployeeFilter({ name: 'annualSalaries' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' })
])
