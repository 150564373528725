import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_SERVICE_FETCH = 'PAYROLL_SERVICE_FETCH'
export const PAYROLL_SERVICE_RECEIVE = 'PAYROLL_SERVICE_RECEIVE'
export const PAYROLL_SERVICE_INVALIDATE = 'PAYROLL_SERVICE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_SERVICE_FETCH,
  receive: PAYROLL_SERVICE_RECEIVE,
  invalidate: PAYROLL_SERVICE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollService', 'payrollservices', 'PayrollService')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollServiceIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollService = actionUtility.fetchEntities
export const invalidatePayrollService = actionUtility.invalidate
