import loadable from '@loadable/component'
import BasicRoute from './routes/Basic'
import DeadlinesRoute from './routes/Deadlines'
import ServicesRoute from './routes/Services'
import TermsRoute from './routes/Terms'
import DataProtectionRoute from './routes/DataProtection'
import CoreLayout from 'layouts/PageLayout/PageLayout'

const BasicRouteLoadable = loadable(() => import('./components/InnerWrapper'))

export default (store) => ({
  path : ':countryId',
  indexRoute: { component: BasicRouteLoadable },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayout: true,
  childRoutes: [ BasicRoute(store), DeadlinesRoute(store), ServicesRoute(store), TermsRoute(store), DataProtectionRoute(store) ]
})
