import React, { useEffect, useState } from 'react'
import KeyPeopleTable from '../components/KeyPeopleTable'
import { useFetch } from 'hooks/useFetch'
import { useDispatch, useSelector } from 'react-redux'
import { getKeyPeopleWithCountryWithBusinessUnit } from '../selectors/employees'
import { useParams } from 'react-router-dom'
import { hasAccess, isCot } from 'redux/selectors/auth'
import { fetchEmployeeSystemUsers, getEmployeeSystemUsersLocation } from 'redux/actions/employeeSystemUsers'
import { isEmpty } from 'utils/fnkit/typeChecks'
import { normalizeFiltersForBE, normalizePartialFilteringForBE } from 'utils/filters'
import { getRolesAndRightsTableOptions } from '../utils/options'
import employeeSystemUsersFilter, { prepareEESystemUsersFilters } from 'redux/filters/employeeSystemUsersFilter'
import { buildFilterName } from 'redux/filters/commonFilters'
import { getTableHeadings } from '../utils/headings'
import { rolesAndRightsFilterMapping, rolesAndRightsPartialSearchFieldEnabled } from 'utils/enums/filterMappingEnums'
import { formatTableData } from '../utils/tableData'
import { getCompanyRef } from 'redux/selectors/company'

const KeyPeopleContainer = (props) => {
  const [baseOptions, setBaseOptions] = useState([])
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const params = useParams()

  const isCotUser = isCot(state)

  const companyId = parseInt(params.companyId)
  const isHQ = getCompanyRef(state, { companyId }).isHQ

  const filterName = buildFilterName({ companyId })['rolesAndRights']

  let totalCount
  let limit = 25
  let offset = 0
  const initialSort = [
    {
      name: 'firstname',
      order: 'asc',
    },
    {
      name: 'surname',
      order: 'asc',
    },
  ]
  props.setFilters({ sort: initialSort, filterName })
  // fetch data
  const fetcherOptions = [
    'vendors',
    'countries',
    'accessAreaPivot',
    'businessUnits',
    employeeSystemUsersFilter({ sort: initialSort, companyId, filterName, isHQ }),
  ]
  const fetchedData = useFetch(fetcherOptions)
  const isFetching = fetchedData.isFetching

  // set edit permissions
  const showActions = useSelector((state) => hasAccess(state)(['EMPLOYEEUSER_EDIT', 'EMPLOYEEUSER_NORMAL_EDIT']))

  // get data from redux state
  const keyPeople = isFetching ? [] : getKeyPeopleWithCountryWithBusinessUnit(state, { companyId, filter: filterName })

  const data = formatTableData({ data: keyPeople, showActions, onEdit: props.onEdit })

  const options = getRolesAndRightsTableOptions({ state, isFetching })

  // get headings
  const headings = getTableHeadings({ options, baseOptions, showActions, isCotUser })

  // table actions
  const onFilter = ({ page = 0, limit = 25, filters, companyId, sort }) => {
    const normalizedFilters = normalizeFiltersForBE(filters, rolesAndRightsFilterMapping, true)
    const partialSearchFilters = normalizePartialFilteringForBE({
      filters,
      partialSearchFields: rolesAndRightsPartialSearchFieldEnabled,
    })

    return dispatch(
      fetchEmployeeSystemUsers({
        filter: prepareEESystemUsersFilters({
          page,
          limit,
          // Normalize query filter parameters (their names must match the BE API)
          filters: { ...normalizedFilters, partialSearchFields: partialSearchFilters },
          state,
          props: {
            companyId,
            isHQ,
          },
          sort: sort.reduce(
            (acc, e) => [
              ...acc,
              ...(e.id === 'name'
                ? [
                  { name: 'firstname', order: e.desc ? 'desc' : 'asc' },
                  { name: 'surname', order: e.desc ? 'desc' : 'asc' },
                ]
                : [{ name: rolesAndRightsFilterMapping[e.id], order: e.desc ? 'desc' : 'asc' }]),
            ],
            []
          ),
        }),
        disableObsoleteFlow: true,
      })
    )
  }

  // pagination
  const isFetchedAtLeastOnce = state.employeeSystemUsers.filters[filterName]?.ids
  if (isFetchedAtLeastOnce) {
    totalCount = state.employeeSystemUsers.filters[filterName].totalCount
    limit = state.employeeSystemUsers.filters[filterName].params.limit
    offset = state.employeeSystemUsers.filters[filterName].params.offset
  }

  const pagination = isFetchedAtLeastOnce && {
    totalPages: Math.ceil(totalCount / limit),
    currentPage: offset / limit,
    limit,
    totalCount: totalCount,
  }

  // get list of employee locations
  useEffect(() => {
    const populateLocations = async () => {
      const res = await dispatch(getEmployeeSystemUsersLocation())
      if (res?.data) {
        const remapData = Object.keys(res.data).map((e) => ({
          value: res.data[e],
          label: res.data[e],
        }))
        setBaseOptions(remapData)
      }
    }
    if (isEmpty(baseOptions)) populateLocations()
  }, [])
  return (
    <KeyPeopleTable data={data} headings={headings} pagination={pagination} onFilter={onFilter} isFetching={isFetching} initialSort={initialSort} {...props} />
  )
}

export default KeyPeopleContainer
