import React from 'react'
import { connect } from 'react-redux'
import { change, formValueSelector } from 'redux-form'
import { getCompaniesByCountries, getCompaniesRef } from 'redux/selectors/company'
import { getCountriesByAuth } from 'redux/selectors/country'
import keyPeopleRoleTypes from 'redux/config/keyPeopleRoleTypes'
import {
  getBusinessUnitsByAccessibleCompanies,
  getBusinessUnitsByCompanies,
  getBusinessUnitsByCompany,
  getBusinessUnitsWithCompanyWithCountry,
} from 'redux/selectors/businessUnits'
import { getCurrencies } from 'redux/selectors/currencies'
import { isCot } from 'redux/selectors/auth'
import Fetcher from 'containers/Fetcher'
import sortBy from 'lodash/sortBy'
import { trimString } from 'utils/strings'

export default (WrappedComponent, formName) => {
  class KeyPeopleFormHOC extends React.Component {
    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  const rolesWithAccessToChangeLogReport = ['rolepayrolladmin', 'rolepayrollanalyst', 'rolecontrolling']

  const mapDispatchToProps = (dispatch) => {
    return {
      // reset checkboxes on role type change
      onRoleTypeChange: (role) => {
        const selectedRole = role && trimString(role)

        dispatch(change(formName, 'readOnly', false))

        // Set globalOwner, lock/unlock and GTN re-import fields to false if selected role has no rights to be global owner
        if (!rolesWithAccessToChangeLogReport.includes(selectedRole)) {
          dispatch(change(formName, 'globalOwner', false))
          dispatch(change(formName, 'unlockLockPayrunFile', false))
          dispatch(change(formName, 'gtnReImport', false))
          dispatch(change(formName, 'payrollIntegrationsControlCenter', false))
          dispatch(change(formName, 'allPayrunDocuments', false))
          dispatch(change(formName, 'accessChangeLogReport', false))
          dispatch(change(formName, 'reportDesigner', false))
        }
      },

      // uncheck 'Specials Rights' checkboxes and set globalOwner field to false on readOnly=true
      onReadOnlyChange: (props) => {
        if (props.target.checked) {
          dispatch(change(formName, 'unlockLockPayrunFile', false))
          dispatch(change(formName, 'accessChangeLogReport', false))
          dispatch(change(formName, 'gtnReImport', false))
          dispatch(change(formName, 'globalOwner', false))
          dispatch(change(formName, 'payrollIntegrationsControlCenter', false))
          dispatch(change(formName, 'allPayrunDocuments', false))
          dispatch(change(formName, 'reportDesigner', false))
        }
      },

      onGlobalOwnerChange: (isGlobalOwner) => {
        if (isGlobalOwner) {
          dispatch(change(formName, 'readOnly', false))
          dispatch(change(formName, 'gtnReImport', true))
          dispatch(change(formName, 'unlockLockPayrunFile', true))
          dispatch(change(formName, 'payrollIntegrationsControlCenter', true))
          dispatch(change(formName, 'accessChangeLogReport', true))
          return
        }
        dispatch(change(formName, 'gtnReImport', false))
        dispatch(change(formName, 'unlockLockPayrunFile', false))
        dispatch(change(formName, 'payrollIntegrationsControlCenter', false))
        dispatch(change(formName, 'allPayrunDocuments', false))
        dispatch(change(formName, 'accessChangeLogReport', false))
      },

      onAccessAreaBUsChange: (businessUnits) => {
        const parsedBUs = businessUnits.map((bu) => JSON.parse(bu))
        const companiesIds = selectedCompaniesThroughBUs(parsedBUs)

        // If "Select all" Business Units option is selected (i.e. `bu.isCompany = true`),
        // then we want to remove the already selected Business Units for the selected Company
        // and just to leave "Select all" option selected.
        // Just to recall: "Select all" option (i.e. `bu.isCompany = true`) means that you
        // provide access to all already created and future Business Units of the Company,
        // i.e. you are providing access to the whole Company (not to a specific Business Unit option only).
        // So in that case - choosing "Select all", we should unselect the already selected Business Units
        // for that Company, because we can choose either "Select all" or manually selecting some of the Business Units.
        const buOptions = parsedBUs.filter((bu) => bu.isCompany || !companiesIds.includes(bu.companyId)).map((bu) => JSON.stringify(bu))

        return dispatch(change(formName, 'accessAreaBUs', buOptions))
      },
      onAccessAreaCompaniesChange: (companiesIds) =>
        dispatch((dispatch, getState) => {
          const selector = formValueSelector(formName)
          const businessUnits = selector(getState(), 'accessAreaBUs') || []

          // If there aren't selected BUs, don't do anything,
          // because the bellow lines of codes do BUs filtration
          if (!businessUnits) return

          const parsedBUs = businessUnits.map((bu) => JSON.parse(bu))
          // On changing Companies values, we have to leave only the BusinessUnits from the selected Companies.
          // So here we removed the rest BusinessUnits.
          const filteredBUs = parsedBUs.filter((bu) => companiesIds.includes(bu.companyId)).map((bu) => JSON.stringify(bu))

          dispatch(change(formName, 'accessAreaBUs', filteredBUs))
        }),
      // We reset the form fields, on a country or company change.
      // Otherwise, the already selected fields are still kept in the Store,
      // and later sent to the API.
      resetForm: () => {
        const fieldsToReset = ['accessAreaCompanies', 'accessAreaBUs', 'roleType', 'readOnly']
        fieldsToReset.forEach((field) => dispatch(change(formName, field, null)))
      },
      resetBusinessUnitField: () => {
        dispatch(change(formName, 'businessUnit', null))
      },
      dispatch,
    }
  }

  const mapStateToProps = (state) => {
    const selector = formValueSelector(formName)
    const values = selector(
      state,
      'countries',
      'company',
      'accessAreaCompanies',
      'accessAreaBUs',
      'isEmployee',
      'roleType',
      'readOnly',
      'receivesNotifications'
    )

    const accessAreaCompanies =
      values.countries && values.countries.length ? getCompaniesByCountries(state, { countriesIds: values.countries }) : getCompaniesRef(state)

    /**
     * For the BU selection, only show BUs belonging to companies that are included in the selected access area
     */
    const filteredAccessAreasForBUs = accessAreaCompanies.filter((company) => values.accessAreaCompanies?.includes(company.id))

    const accessAreaBUs =
      values.accessAreaCompanies && values.accessAreaCompanies.length
        ? getBusinessUnitsByCompanies(state, { companiesIds: values.accessAreaCompanies })
        : getBusinessUnitsWithCompanyWithCountry(state)

    const businessUnitData = values.company ? getBusinessUnitsByCompany(state, { companyId: values.company }) : getBusinessUnitsByAccessibleCompanies(state)

    return {
      isCot: isCot(state),
      accessAreaCompanies,
      keyPeopleRoleTypes,
      companies: getCompaniesRef(state),
      accessAreaBUsOptions: buildAccessAreasBUOptions(filteredAccessAreasForBUs, accessAreaBUs, values.accessAreaBUs),
      globalCountries: getCountriesByAuth(state),
      businessUnits: businessUnitData,
      allCurrencies: getCurrencies(state),
      showPaymentCurrency: values.isEmployee,
      showStartDate: values.isEmployee,
      receivesNotifications: values.receivesNotifications,
    }
  }

  const Component = connect(mapStateToProps, mapDispatchToProps)(KeyPeopleFormHOC)

  return Fetcher(Component, ['companies', 'countries', 'businessUnits', 'employeeCountryPivot', 'currencies', 'accessAreaPivot'])
}

// It will get the Companies, through the selected Business Units -> "Select all" option.
// "Select all" option in the Business Units dropdown is equal to providing access to the whole Company.
// In other words: Provide access to all the Business Units in the selected Company, i.e. to the whole Company.
const selectedCompaniesThroughBUs = (businessUnits) => {
  return businessUnits.filter((bu) => bu.isCompany).map((bu) => bu.companyId)
}

/**
 * Build `accessAreas` BE API field value
 *
 * BE API field `accessAreas` supports two types of area values- `BusinessUnit` and `Company`.
 * Because of this - we have to transform the submitted form options to the required format.
 *
 * The submitted `businessUnits` options may include a company options too (select-all).
 * Please refer to: `buildAccessAreasBUOptions()`.
 * Because of this here we have to build the both types of Access Areas.
 *
 * @param businessUnits - form submitted Business Unit options
 * @return {[*,*]}
 */
export const buildAccessArea = (businessUnits = []) => {
  const parsedBUs = businessUnits.map((bu) => JSON.parse(bu))

  const sanitizedBUs = parsedBUs
    .filter((bu) => bu.id)
    .map((bu) => ({
      accessableId: bu.id,
      accessableType: 'BusinessUnit',
    }))

  const sanitizedCompanies = parsedBUs
    .filter((bu) => !bu.id)
    .map((bu) => ({
      accessableId: bu.companyId,
      accessableType: 'Company',
    }))

  return [...sanitizedBUs, ...sanitizedCompanies]
}

/**
 * Build Access Areas Business Units dropdown options
 *
 * Having `companies` and `businessUnits` we build the access area business units dropdown options,
 * where each company is represented by "Select all business units from this Company" option,
 * followed by its business units.
 *
 * @example: Let's say we have the following passed in data:
 * ```
 * companies = [
 *  { id: 1, name: 'Company-1 },
 *  { id: 2, name: 'Company-2 },
 *  { id: 3, name: 'Company-3 },
 * ]
 *
 * businessUnits = [
 *   { id: 1, name: 'Managers', company: { id: 1, name: 'Company-1' } },
 *   { id: 2, name: 'Developers', company: { id: 2, name: 'Company-2' } },
 * ]
 * ```
 *
 * We have to build the following structure:
 *
 * ```
 * [
 *   // This is the company "Select all" option
 *   {
 *    value: JSON.stringify({
 *     companyId: 1,
 *     isCompany: true
 *    }),
 *    label: `Select all Company-1 BUs`,
 *  },
 *  // Here are the business units, from the above company
 *  {
 *    value: JSON.stringify({
 *      id: 1,
 *      companyId: 1
 *    }),
 *    label: Managers,
 *  },
 *  // This is the company "Select all" option
 *   {
 *    value: JSON.stringify({
 *     companyId: 2,
 *     isCompany: true
 *    }),
 *    label: `Select all Company-2 BUs`,
 *  },
 *  // Here are the business units, from the above company
 *  {
 *    value: JSON.stringify({
 *      id: 2,
 *      companyId: 2
 *    }),
 *    label: Developers,
 *  },
 *   // This is the company "Select all" option
 *   // Here we don't have busuiness units for this company, but that's a possible use case to have a company,
 *   // without created business units. In this case, we just add the "Select all" option.
 *   // If the user selects that option, then he will have access to all future created business units.
 *   {
 *    value: JSON.stringify({
 *     companyId: 3,
 *     isCompany: true
 *    }),
 *    label: `Select all Company-3 BUs`,
 *  }
 * ]
 * ```
 *
 * @param {Array.<{ id: Number, name: String }>} companies - All available companies
 * @param {Array.<{ id: Number, name: String, company: { id: Number } }>} businessUnits - All available business units
 * @param {Array.<{ isCompany: Boolean, companyId: Number  }>} selectedBUs - Business units form values,
 * already choosen by the user.
 * @returns {*}
 */
const buildAccessAreasBUOptions = (companies, businessUnits, selectedBUs) => {
  let BUs = selectedBUs || []

  const sortedCompanies = sortBy(companies, ['company.id', 'id'])
  const sortedBUs = sortBy(businessUnits, ['company.id', 'id'])
  // Which companies are selected through the business units `Select all ${company.name} BUs` option
  const companiesIds = selectedCompaniesThroughBUs(BUs.map((bu) => JSON.parse(bu)))

  return sortedCompanies.reduce((result, company) => {
    result.push({
      value: JSON.stringify({
        companyId: company.id,
        isCompany: true,
      }),
      label: `Select all ${company.name} BUs`,
      isBold: true,
    })

    sortedBUs
      .filter((bu) => bu.company.id === company.id)
      .forEach((bu) => {
        result.push({
          value: JSON.stringify({
            id: bu.id,
            companyId: bu.company.id,
          }),
          label: bu.name,
          // Disable the BU field, if the BU's company is already selected,
          // because if the BU's company is selected, then it means that all the company's business units
          // are selected too behind the scenes (not directly selected in the form)
          disabled: companiesIds.includes(bu.company.id),
          disabledReason: 'Access to all and future company BUs selected.',
        })
      })

    return result
  }, [])
}
