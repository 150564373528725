import React from 'react'
import PropTypes from 'prop-types'
import Primary from './Primary'

class Download extends React.Component {
  constructor (props) {
    super(props)
    this.state = ({
      isDownloading: false
    })
  }

  render () {
    const { onClick, classes } = this.props
    const { isDownloading } = this.state

    return <Primary
      {...this.props}
      classes={`${classes} ${isDownloading ? 'c-btn--downloading' : ''}`}
      disabled={isDownloading}
      onClick={() => this.setState({ isDownloading: true },
        () => onClick().then(() => this.setState({ isDownloading: false })))}
    />
  }
}

Download.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.string,
}

export default Download
