import React from 'react'
import PropTypes from 'prop-types'
import { change, Field } from 'redux-form'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import CustomSelectField from 'components/form/select/CustomSelectField'

const SingleEditForm = (
  { payrollTaskName, showOwnerField, owners, onOwnerChange, disableDeadline, dispatch, isMAOEnabled, ...rest }
) => (
  <div>
    <div className='o-layout'>
      <div className='o-layout__item u-text--center u-1/1'>
        <h2 className='u-text--center u-text--huge'>
          <span className='u-weight--bold'>Update action: {payrollTaskName}</span>
        </h2>
      </div>
    </div>

    <Form showSubmitBtn {...rest}>
      <div className='o-layout u-text--center'>
        {
          showOwnerField &&
            <div className='o-layout__item u-1/2'>
              <Field
                name='owner'
                label='Owner'
                component={CustomSelectField}
                labelClassName='c-label'
                className='u-text--left'
                placeholder='Select'
                options={owners}
                onChanged={onOwnerChange}
              />
            </div>
        }

        <div className={`o-layout__item ${showOwnerField ? 'u-1/2' : 'u-1/1'}`}>
          <Field
            name='deadline'
            label='Timeline'
            labelClassName='c-label'
            component={InputField}
            type='number'
            disabled={disableDeadline}
          />
        </div>

        {
          isMAOEnabled && showOwnerField &&
          <div className='o-layout__item u-text--center u-1/1'>
            <span
              onClick={() => dispatch(change('payrollTaskEdit', 'taskableType', 'BusinessUnit'))}
              className='u-text--small u-text--curious u-cursor--pointer'>
              Switch to multiple owners
            </span>
          </div>
        }
      </div>
    </Form>
  </div>
)

SingleEditForm.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  payrollTaskName: PropTypes.string,
  showOwnerField: PropTypes.bool,
  disableDeadline: PropTypes.bool,
  onOwnerChange: PropTypes.func,
  isMAOEnabled: PropTypes.bool,
  dispatch: PropTypes.func
}

export default SingleEditForm
