import { twoFAResponseMessageWithAuth, twoFAResponseMessage } from 'utils/enums/twoFA'

const strings = {
  authentication: {
    twoFAWithAuth: twoFAResponseMessageWithAuth,
    twoFAWithoutAuth: twoFAResponseMessage
  },
  successfulChangeUpdate: 'Your changes have been successfully saved'
}

export default strings
