import React from 'react'
import ReportItemBuilder from 'utils/helperClasses/ReportsItemBuilder'
import { reportGroupingsEnum, reportIdsEnum } from 'utils/enums/reportGroupings'
import ReportsAccordionContainer from '../containers/ReportsAccordionContainer'

export default (props) => {
  const hrReports = new ReportItemBuilder(reportGroupingsEnum.HR)
  const financeReports = new ReportItemBuilder(reportGroupingsEnum.FINANCE)
  const payrollReports = new ReportItemBuilder(reportGroupingsEnum.PAYROLL)
  const controlAndGovernanceReports = new ReportItemBuilder(reportGroupingsEnum.CONTROL_AND_GOVERNANCE)
  const builder = new ReportItemBuilder()
  const feCategoryId = props?.history?.location?.state?.feCategoryId
  const defaultReport = feCategoryId ? builder.getReportMetadata(feCategoryId) : null
  const reportCategoryType = feCategoryId ? builder.getReportTypeCategory(feCategoryId) : null

  const reports = [
    {
      id: reportIdsEnum.HR,
      categoryType: reportGroupingsEnum.HR,
      items: hrReports,
      icon: { name: 'hr-report', bgColor: 'pink' },
    },
    {
      id: reportIdsEnum.FINANCE,
      categoryType: reportGroupingsEnum.FINANCE,
      items: financeReports,
      icon: { name: 'finance-report', bgColor: 'light-green' },
    },
    {
      id: reportIdsEnum.PAYROLL,
      categoryType: reportGroupingsEnum.PAYROLL,
      items: payrollReports,
      icon: { name: 'payroll-report', bgColor: 'blue' },
    },
    {
      id: reportIdsEnum.CONTROL_AND_GOVERNANCE,
      categoryType: reportGroupingsEnum.CONTROL_AND_GOVERNANCE,
      items: controlAndGovernanceReports,
      icon: { name: 'control-governance-report', bgColor: 'orange' },
    },
  ].map((r) => ({
    ...r,
    isExpanded: r.categoryType === reportCategoryType,
    isDefaultSection: r.categoryType === reportCategoryType,
  }))

  return <ReportsAccordionContainer defaultReport={defaultReport} reports={reports} {...props} />
}
