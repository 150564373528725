const httpStatuses = {
  OK: 200,
  CREATED: 201,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
}

export default httpStatuses
