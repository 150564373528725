export const PUSH_BREADCRUMB = 'PUSH_BREADCRUMB'
export const PUSH_BREADCRUMB_FILTER = 'PUSH_BREADCRUMB_FILTER'
export const CLEAR_BREADCRUMB_FILTER = 'CLEAR_BREADCRUMB_FILTER'
export const CLEAR_ALL_BREADCRUMB_FILTER_OF_TYPE = 'CLEAR_ALL_BREADCRUMB_FILTER_OF_TYPE'
export const RESET_BREADCRUMB = 'RESET_BREADCRUMB'

export const pushBreadcrumb = (payload) => ({
  type: PUSH_BREADCRUMB,
  payload
})

export const pushBreadcrumbFilter = (payload) => ({
  type: PUSH_BREADCRUMB_FILTER,
  payload
})

export const clearBreadcrumbFilter = (payload) => ({
  type: CLEAR_BREADCRUMB_FILTER,
  payload
})

export const clearAllBreadcrumbFilterByKey = (payload) => ({
  type: CLEAR_ALL_BREADCRUMB_FILTER_OF_TYPE,
  payload
})

export const resetBreadcrumb = () => ({ type: RESET_BREADCRUMB })
