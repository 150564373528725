import { fk, attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/payrollInstanceService'

class PayrollInstanceService extends CRUDModel {}
PayrollInstanceService.modelName = 'PayrollInstanceService'
PayrollInstanceService.actionTypes = actionTypes

PayrollInstanceService.fields = {
  id: attr(),
  payrollInstance: fk('PayrollInstance', 'payrollInstanceServices'),
  payrollService: fk('PayrollService', 'payrollInstanceServices')
}

export default PayrollInstanceService
