import React from 'react'
import Select from 'react-select/lib/Select'
import SideBarChartWithFlags from '../../../../../components/charts/SideBarChartWithFlags'
import PropTypes from 'prop-types'

export const SideBarBlockTemplate = ({
  sideBarService,
  sideBarQuery,
  chartLogic,
  onSideBarFilterChange,
  isExporting,
  sortByTotal,
  setHeadCountSort,
  hasHeadCountSortOpened,
  withSelect = true,
}) => (
  <div className='u-padding-top'>
    <div className='c-display-cards headcount-by-period shadow rounded border u-padding u-padding-bottom-large overflow-y'>
      <div className='d-flex jc--space-between'>
        <div className='d-flex'>
          <h2 className='u-margin-none u-text--xtra-huge u-weight--bold'>{chartLogic.TITLE}</h2>
          {withSelect && (
            <div className='u-margin-left-small u-margin-bottom u-min-width-200'>
              <Select
                className='c-custom-select c-inline-select-dropdown'
                value={sideBarQuery}
                labelClassName='c-label'
                placeholder='Select'
                options={chartLogic.SIDE_BAR_FILTER_OPTIONS}
                clearable={false}
                onChange={onSideBarFilterChange}
              />
            </div>
          )}
        </div>
        <div className='headcount-sort' onClick={() => setHeadCountSort(hasHeadCountSortOpened === 'desc' ? 'asc' : 'desc')}>
          <span className={`icon icon--chart-sort-icon ${hasHeadCountSortOpened === 'desc' ? 'sort-opened' : ''}`} />
        </div>
      </div>
      <SideBarChartWithFlags
        data={sideBarService.calculateDataBasedOnYAxis({ type: sideBarQuery, withTotals: true }).sort(sortByTotal)}
        xKey={chartLogic.SIDE_BARCHART_AXIS.xAxis}
        yKey={chartLogic.SIDE_BARCHART_AXIS.yAxis}
        queryKey={sideBarQuery}
        isExporting={isExporting}
      />
    </div>
  </div>
)

SideBarBlockTemplate.propTypes = {
  sideBarService: PropTypes.object,
  chartLogic: PropTypes.object,
  setHeadCountSort: PropTypes.func,
  hasHeadCountSortOpened: PropTypes.string,
  isExporting: PropTypes.bool,
  onSideBarFilterChange: PropTypes.func,
  sideBarQuery: PropTypes.any,
  sortByTotal: PropTypes.func,
  withSelect: PropTypes.bool,
}
