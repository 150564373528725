import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const NOTES_FETCH = 'NOTES_FETCH'
export const NOTES_RECEIVE = 'NOTES_RECEIVE'
export const NOTE_CREATE = 'NOTE_CREATE'
export const NOTE_INVALIDATE = 'NOTE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: NOTES_FETCH,
  receive: NOTES_RECEIVE,
  create: NOTE_CREATE,
  invalidate: NOTE_INVALIDATE,
}
const actionUtility = new ActionUtility(actionTypes, 'notes', 'notes', 'Note')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchNotesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchNotes = actionUtility.fetchEntities
export const createNote = actionUtility.createEntity
export const invalidateNote = actionUtility.invalidate
