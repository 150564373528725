import React from 'react'
import PropTypes from 'prop-types'
import Flag from 'components/Flag'
import Option from './Option'

const FlagOption = props => {
  const { children, option: { abbreviature } } = props

  return <Option {...props} >
    { abbreviature && <Flag flag={abbreviature.toLowerCase()} size='miniature' classes='u-margin-right-tiny' /> }
    {children}
  </Option>
}

FlagOption.propTypes = {
  children: PropTypes.node,
  option: PropTypes.object.isRequired,
}

export default FlagOption
