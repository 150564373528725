import React from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import EditableCell from './EditableCell'
import moment from 'moment'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'

const whitelistFieldsForCustomDropDowns = ['hasCredentials', 'isSSOEnabled']
const whiteListForCustomDropDownView = ['gender', 'isPrimary', 'primaryBankAccount', 'paymentCurrency', 'invoicePaymentCurrency', 'payroll', 'paygroup']
// For some fields we don't exactly have all the details
// at the start to use the table API to add the appropriate options
// i.e for the custom field tables, the layout is such that we
// don't know if it's a text or dropdown
// This class is a wrapper to format
export const EditableCellDataEmployeeFieldsFormatter = (props) => {
  const { row: { original }, inGlobalEditMode, inAddMode } = props
  const { metaData } = original
  const formattedData = {}

  switch (metaData.component) {
  case 'input':
    formattedData['inputType'] = 'input'
    break
  case 'email':
    formattedData['inputType'] = 'email'
    break
  case 'phone':
    formattedData['inputType'] = 'phone'
    break
  case 'date':
    formattedData['inputType'] = 'date'
    // TODO: If needed set this to read in from the metadata field
    formattedData['dateOptions'] = {
      calendarClassName: '',
      yearDropdownItemNumber:  50,
      calendarStartDay: 1,
      minDate: moment('01/01/1900').toDate(),
      showMonthDropdown: true,
      showYearDropdown: true,
      scrollableYearDropdown: true,
      autoComplete: 'off',
      highlightDates: [],
      todayButton: false,
      ...metaData
    }
    break
  case 'dropdown':
    formattedData['inputType'] = 'dropDownSelection'
    formattedData['clearable'] = true
    if (whitelistFieldsForCustomDropDowns.includes(metaData.field)) {
      formattedData['customMapping'] = 'useLabel'
    }
    if (metaData.enum && metaData.component === 'dropdown' && metaData?.metaData?.enumDisplayValues) {
      const displayValue = metaData.options.find(opt => opt.value === original.value)
      formattedData['customMapping'] = 'useEnumDisplayValues'
      formattedData['customDisplay'] = displayValue?.label || ''
    }
    if (whiteListForCustomDropDownView.includes(metaData.field)) {
      const value = metaData.options.find(opt => opt.value === original.value)
      formattedData['customDisplay'] = value?.label || ''
    }
    formattedData['dropDownOptionsForInput'] = metaData.options ? metaData.options.flat().map(option => {
      if (isObject(option)) {
        if (!option.label && option.name) return { value: option.name, label: option.name }

        if (formattedData['customMapping'] && formattedData['customMapping'] === 'useLabel') {
          return { value: option.label, label: option.label, apiValue: option.value }
        }

        if (formattedData['customMapping'] && formattedData['customMapping'] === 'useEnumDisplayValues') {
          return { value: option.value, label: option.label, apiValue: option.value }
        }
        return option
      }

      return { value: option, label: option }
    }) : []
    break
  }
  if (inGlobalEditMode) {
    const { canModifyFields } = metaData
    if (canModifyFields && !canModifyFields[pageUIStatesEnum.EDIT] && !isObject(canModifyFields[pageUIStatesEnum.EDIT])) {
      formattedData['inputType'] = 'readonly'
    }

    if (canModifyFields && isObject(canModifyFields[pageUIStatesEnum.EDIT])) {
      const { permissionLayers } = canModifyFields[pageUIStatesEnum.EDIT]
      let hasAccess = permissionLayers.every(layer => props.permissions.includes(layer))

      if (!hasAccess) { formattedData['inputType'] = 'readonly' }
    }
    if (original.editDisabled) { formattedData['inputType'] = 'readonly' }
  }

  if (inAddMode && original.addDisabled) { formattedData['inputType'] = 'readonly' }

  return (
    <EditableCell formattedData={formattedData} {...props} />
  )
}

EditableCellDataEmployeeFieldsFormatter.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  inGlobalEditMode: PropTypes.bool,
  inAddMode: PropTypes.bool,
  permissions: PropTypes.array,
}
