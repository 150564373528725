import { createSelector } from 'reselect'
import { services } from './store/services'
import { processes } from './store/processes'
import { loadRef } from 'redux/helpers/loadRef'

export const getProcesses = createSelector([ processes, services ],
  (processes, services) => processes.allIds.map(id => {
    return loadRef(processes.byIds[id], [
      {
        ref: 'service',
        entity: services
      }
    ])
  })
    .reverse()
)
