import { ActionUtility, createReducer } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PROCESSES_FETCH = 'PROCESSES_FETCH'
export const PROCESSES_RECEIVE = 'PROCESSES_RECEIVE'
export const PROCESSES_INVALIDATE = 'PROCESSES_INVALIDATE'
export const PROCESS_CREATE = 'PROCESS_CREATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PROCESSES_FETCH,
  receive: PROCESSES_RECEIVE,
  invalidate: PROCESSES_INVALIDATE,
  create: PROCESS_CREATE,
}
const actionUtility = new ActionUtility(actionTypes, 'processes', 'processes', 'Process')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchProcessesIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchProcesses = actionUtility.fetchEntities
export const invalidateProcesses = actionUtility.invalidate
export const createProcess = actionUtility.createEntity

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(actionTypes)
