import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReportsList from '../components/ReportsList'
import { downloadVendorsAndKeyUsersReport } from 'redux/actions/parentCompanies'
import { isCotAdmin } from 'redux/selectors/auth'
import { cotLevelReportLabels } from 'utils/locales/report.en'
import { createCotReportJob } from 'redux/actions/pollingJob'

const ReportsContainer = () => {
  const dispatch = useDispatch()

  const isCotAdminUser = useSelector((state) => isCotAdmin(state))

  const reports = [
    {
      title: cotLevelReportLabels.vendorsAndKeyUsersReport.title,
      description: cotLevelReportLabels.vendorsAndKeyUsersReport.description,
      icon: 'internal-report',
      reportName: 'vendorsAndKeyUsersReport',
      isHidden: !isCotAdminUser,
    },
    {
      title: cotLevelReportLabels.tenantSummaryReport.title,
      description: cotLevelReportLabels.tenantSummaryReport.description,
      icon: 'internal-report',
      reportName: 'tenantSummaryReport',
    },
  ]

  const handleDownloadReport = (reportName) => {
    const downloadFunctions = {
      vendorsAndKeyUsersReport: () => dispatch(downloadVendorsAndKeyUsersReport()),
      tenantSummaryReport: () =>
        dispatch(
          createCotReportJob({
            type: 'TenantReport',
            category: 'TenantSummaryReport',
            subcategory: 'TenantSummaryReport',
          })
        ),
    }

    return downloadFunctions[reportName] || null
  }

  return <ReportsList reports={reports} onDownloadClick={handleDownloadReport} />
}

export default ReportsContainer
