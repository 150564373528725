const pageUIStatesEnum = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  CREATE: 'CREATE',
  EDIT:'EDIT',
  VIEW: 'VIEW',
  CONFIRM: 'CONFIRM',
  SUCCESS: 'SUCCESS',
  COMPLETED: 'COMPLETED'
}

export default pageUIStatesEnum
