import loadable from '@loadable/component'
import CorporateRoute from './routes/Corporate'
import OrganizationRoute from './routes/Organization'
import PayrollRoute from './routes/Payroll'
import PayTaxesRoute from './routes/PayTaxes'
import DatesProcessesRoute from './routes/DatesProcesses'
import RolesRightsRoute from './routes/RolesRights'
import HolidaysRoute from './routes/Holidays'
import GeneralLedgersRoute from './routes/GeneralLedgers'
import ServiceVendorsRoute from './routes/ServiceVendors'
import BankRoute from './routes/Bank'
import DataProtectionRoute from './routes/DataProtection'
import FxRatesRoute from './routes/FxRates'
import DocumentsRoute from './routes/Documents'
import CoreLayout from 'layouts/PageLayout/PageLayout'

const CompanyRouteLoadable = loadable(() => import('./containers/InnerWrapperContainer'))

export default (store) => ({
  path : ':companyId',
  skipChildren: true,
  indexRoute: { component: CompanyRouteLoadable },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayout: true,
  childRoutes: [
    CorporateRoute(store),
    OrganizationRoute(store),
    PayrollRoute(store),
    PayTaxesRoute(store),
    DatesProcessesRoute(store),
    RolesRightsRoute(store),
    HolidaysRoute(store),
    GeneralLedgersRoute(store),
    ServiceVendorsRoute(store),
    BankRoute(store),
    DataProtectionRoute(store),
    FxRatesRoute(store),
    DocumentsRoute(store)
  ]
})
