import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'
import CustomSelectField from 'components/form/select/CustomSelectField'
import InputField from 'components/form/InputField'
import { makeScaleValidation } from 'utils/validations'

const scaleValidation = makeScaleValidation(6)

const CustomRateForm = (props) => {
  const {
    selected,
    currencies,
    areAllCustomRatesFilled,
    isCustomRateValueValid,
    customRatesFieldsNames,
    customRatesFieldsValues,
    areCustomRatesValuesValid,
    setReportingCurrencyDetails,
    resetFormFields,
    isFieldDisabled,
    disabledReason,
    onHide
  } = props

  return <div className='o-layout__item u-1/1' data-testid='customRate-form'>
    <SectionHeading text='Custom Rate' />
    <div className='c-panel u-margin-top u-margin-bottom u-bg--white border-1-valhalla rounded u-padding-small'>
      <Field
        name='toCurrency'
        label='Choose Currency'
        component={CustomSelectField}
        className='c-custom-select--transparent'
        labelClassName='c-label u-text--small u-text--curious'
        formGroupClassName='u-margin-none'
        placeholder='Select'
        disabled={isFieldDisabled}
        disabledReason={disabledReason}
        options={currencies.map(currency => ({
          value: currency.id,
          label: currency.abbreviature
        }))}
        onChanged={value => {
          const fieldsToReset = ['fxRate', 'rate', ...customRatesFieldsNames]
          resetFormFields(value, fieldsToReset)
        }}
        data-testid='toCurrency'
      />
    </div>
    {
      selected.toCurrency
        ? (
          selected.payrollCurrency.length === 1
            ? <div className='c-panel u-margin-top u-margin-bottom u-bg--white
            border-1-valhalla rounded u-padding-small'>
              <Field
                name='rate'
                label='Rate'
                labelClassName='c-label u-text--small u-text--curious'
                className='c-input c-input--transparent'
                component={InputField}
                type='number'
                min={0}
                step={0.000001}
                validate={scaleValidation}
                formGroupClassName='u-margin-none'
                data-testid={`customRate-${selected.payrollCurrency}`}
              />
            </div>
            : <div className='o-layout'>
              {
                selected.payrollCurrency.map((currency, i) => currency !== selected.toCurrency &&
                  <div className='o-layout__item u-margin-bottom u-1/1 u-1/2@tablet u-1/4@desktop' key={i}>
                    <div className='c-panel u-bg--white border-1-valhalla rounded u-padding-small'>
                      <Field
                        name={`customRate-${currency}`}
                        label={`Rate from ${currencies.find(c => c.id === currency).abbreviature}`}
                        labelClassName='c-label u-text--small u-text--curious'
                        className='c-input c-input--transparent'
                        component={InputField}
                        type='number'
                        min={0}
                        step={0.000001}
                        validate={scaleValidation}
                        formGroupClassName='u-margin-none'
                        data-testid={`customRate-${currency}`}
                      />
                    </div>
                  </div>
                )
              }
            </div>
        )
        : null
    }
    {
      // Show "Save" button if
      // - there is selected and valid single rate
      // - all custom rates are filled and valid
      ((selected.toCurrency && selected.rate && isCustomRateValueValid) ||
        (areAllCustomRatesFilled && areCustomRatesValuesValid)) &&
        <div className='u-text--center'>
          <div
            onClick={(e) => {
              setReportingCurrencyDetails(null, customRatesFieldsValues)
              onHide()
            }}
            className='c-btn c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid='save-button'
          >
            Save
          </div>
        </div>
    }
  </div>
}

CustomRateForm.propTypes = {
  selected: PropTypes.object,
  currencies: PropTypes.array,
  areAllCustomRatesFilled: PropTypes.bool,
  areCustomRatesValuesValid: PropTypes.bool,
  isCustomRateValueValid: PropTypes.bool,
  customRatesFieldsNames: PropTypes.array,
  customRatesFieldsValues: PropTypes.array,
  setReportingCurrencyDetails: PropTypes.func,
  resetFormFields: PropTypes.func,
  isFieldDisabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  onHide: PropTypes.func
}

export default CustomRateForm
