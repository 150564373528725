import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'

export default (store) => ({
  path : ':id/payroll-reconciliation',
  indexRoute: { component: RouteView },
  tabbedRoute: true,
  authProtected: true,
  renderChildrenOutSideTab: true,
  wrappedComponent: CoreLayout,
  childRoutes: []
})
