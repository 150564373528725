import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_PROCESS_FETCH = 'PAYROLL_INSTANCE_PROCESS_FETCH'
export const PAYROLL_INSTANCE_PROCESS_RECEIVE = 'PAYROLL_INSTANCE_PROCESS_RECEIVE'
export const PAYROLL_INSTANCE_PROCESS_UPDATE = 'PAYROLL_INSTANCE_PROCESS_UPDATE'
export const PAYROLL_INSTANCE_PROCESS_INVALIDATE = 'PAYROLL_INSTANCE_PROCESS_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_PROCESS_FETCH,
  receive: PAYROLL_INSTANCE_PROCESS_RECEIVE,
  update: PAYROLL_INSTANCE_PROCESS_UPDATE,
  invalidate: PAYROLL_INSTANCE_PROCESS_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceProcess', 'payrollinstanceprocesses', 'PayrollInstanceProcess')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceProcessIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceProcess = actionUtility.fetchEntities
export const updatePayrollInstanceProcess = actionUtility.updateEntity
export const invalidatePayrollInstanceProcess = actionUtility.invalidate
