import Loader from 'components/Loader'
import React from 'react'
import PropTypes from 'prop-types'
import DashboardTileHeader from './DashboardTileHeader'
import EmptyListNotice from './EmptyListNotice'
import AssignmentCard from 'routes/Toolbox/routes/Actions/components/AssignmentCard'

// eslint-disable-next-line react/prop-types
const OverdueActionsListItem = ({ item }) => {
  return (
    <article>
      <AssignmentCard key={item.id} bulkArr={[]} {...item} />
    </article>
  )
}

// eslint-disable-next-line react/prop-types
const OverdueActionsList = ({ items }) => {
  let newItems = [...items]
  if (newItems.length < 1) return <EmptyListNotice text='No Overdue Actions' />
  if (newItems.length > 3) newItems.pop()
  return (
    <ul className='o-list--striped list-unstyled u-margin-none'>
      {newItems.map((item) => (
        <OverdueActionsListItem key={item.id} item={item} />
      ))}
    </ul>
  )
}

const OverDueTitleInfo = ({ count = 0 }) => {
  return (
    <>
      <span
        data-testid='section-header-title u-text--huge'
        title='Lists your oldest Overdue Actions'>
        Overdue Actions
      </span>
      {count > 0 && (
        <span className='u-text--mandy u-margin-left-small u-margin-right-small'>
          {
            count > 3
              ? <span title='Plus sign indicates additional Overdue Actions'>3+</span>
              : count
          }
        </span>
      )}
    </>
  )
}
function OverdueActionsDashboardView (props) {
  const itemCount = !props.isFetching ? props.overdueAssignments.length : 0
  const hasItems = itemCount ? 'has-items' : 'no-items'
  return (
    <div data-testid='dashboard-overdue-actions'>
      <DashboardTileHeader
        title={<OverDueTitleInfo count={itemCount} />}
        onClick={props.onNavigateToActions}
        buttonText='View'
      />
      <div className={`c-draggableboard c-draggableboard-background--white dashboard-tile-container ${hasItems}
      `}>
        {!props.isFetching
          ? (
            <OverdueActionsList
              items={props.overdueAssignments || []}
              {...props}
            />
          )
          : (
            <Loader />
          )}
      </div>
    </div>
  )
}

OverdueActionsDashboardView.propTypes = {
  isFetching: PropTypes.bool,
  onNavigateToActions: PropTypes.func,
  overdueAssignments: PropTypes.array,
}

OverDueTitleInfo.propTypes = {
  count: PropTypes.number
}

OverdueActionsListItem.propTypes = {
  item: PropTypes.object
}

export default OverdueActionsDashboardView
