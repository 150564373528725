/**
 * Define reusable Payrun derived reducers.
 *
 * For example, we have the same reducer handlers for `models/payfile`, `models/payfileChange`
 *
 * @param {ReduxOrm} Model
 */
export const baseReducerHandlers = Model => ({
  [Model.actionTypes.resetInitials]: Model => {
    Model.all().delete()
  }
})
