import React from 'react'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadTemplate, importFxRatesCreate, download } from 'redux/actions/fxRates'
import SectionHeading from 'components/SectionHeading'
import { requiredFieldsLabels, requiredFieldsDesc, dataValidationsLabels, dataValidationsDesc } from 'utils/locales/fxRatesBulk.en'

const TemplateClarifications = () => (
  <div className='u-margin-bottom-small u-text--normal'>
    <SectionHeading text='Create multiple FX rates' />
    <p className='u-margin-bottom-small'>This template allows you to create multiple FX rates in bulk. Follow the simple process described below:</p>

    <ul className='margin-bottom-small'>
      <li>Download template</li>
      <li>Complete data</li>
      <li>Import the completed template using Import button</li>
      <li>Wait for confirmation message</li>
    </ul>
  </div>
)

export default (props) => (
  <ImportFacade
    {...props}
    heading='Import FX rates'
    entityName='FX rates'
    downloadAction={download}
    downloadTemplateAction={() => downloadTemplate()}
    importAction={({ file }) => importFxRatesCreate(file)}
    TemplateClarifications={TemplateClarifications}
    hasFilters={false}
  >
    <div className='o-layout'>
      <div className='o-layout__item u-1/2 u-text--normal'>
        <SectionHeading text='Required fields:' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.fromCurrency}</span> {requiredFieldsDesc.currency}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.toCurrency}</span> {requiredFieldsDesc.currency}
        </p>
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{requiredFieldsLabels.rate}</span> {requiredFieldsDesc.rate}
        </p>
      </div>

      <div className='o-layout__item u-1/2 u-text--normal'>
        <SectionHeading fullWidth text='Other data validations (all are case-insensitive):' />
        <p className='u-margin-bottom-tiny'>
          <span className='u-weight--bold'>{dataValidationsLabels.date}</span> {dataValidationsDesc.date}
        </p>
      </div>
    </div>
  </ImportFacade>
)
