import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from 'components/SectionHeading'

function DashboardTileHeader ({ title, onClick, buttonText = 'View' }) {
  return (
    <div
      data-testid='dashboard-tile-header'
      className='u-padding-right-small u-margin-top-small u-margin-bottom-tiny page--dashboard-tile-header'
      style={{ display: 'grid', gridTemplateColumns: '60% 40%', alignItems: 'center' }}
    >
      <SectionHeading
        text={title}
        tagTestId='section-header-title'
        customGrid='u-padding-none'
        className='text-left u-margin-left u-margin-bottom-none u-text--valhalla'
      />

      <div className='u-float--right u-margin-left text-right'>
        {onClick && (
          <div
            onClick={onClick}
            className='c-btn c-btn--curious c-btn--tiny u-margin-right-tiny'
            title={buttonText || ''}
          >
            <span className='icon icon--preview' />
          </div>
        )}
      </div>
    </div>
  )
}

DashboardTileHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonText: PropTypes.string,
  onClick: PropTypes.func
}

export default DashboardTileHeader
