import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYROLL_INSTANCE_SERVICE_FETCH = 'PAYROLL_INSTANCE_SERVICE_FETCH'
export const PAYROLL_INSTANCE_SERVICE_RECEIVE = 'PAYROLL_INSTANCE_SERVICE_RECEIVE'
export const PAYROLL_INSTANCE_SERVICE_INVALIDATE = 'PAYROLL_INSTANCE_SERVICE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYROLL_INSTANCE_SERVICE_FETCH,
  receive: PAYROLL_INSTANCE_SERVICE_RECEIVE,
  invalidate: PAYROLL_INSTANCE_SERVICE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'payrollInstanceService', 'payrollinstanceservices', 'PayrollInstanceService')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchPayrollInstanceServiceIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchPayrollInstanceService = actionUtility.fetchEntities
export const invalidatePayrollInstanceService = actionUtility.invalidate
