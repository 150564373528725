import React from 'react'
import PropTypes from 'prop-types'
import { Authorization } from 'containers/Authorization'
import AddBtnMessage from './AddBtnMessage'
import PayslipTable from 'components/table/PayslipTable'

const titleWrapperCss = 'u-text--center u-text--normal u-border-bottom--mischka u-bg--white u-margin-bottom u-padding-bottom-small'

const EmployeeFieldsWithAdd = (props) => {
  const { permissions } = props
  const lastIndex = props.items?.length - 1
  const hasItems = props.items?.length > 0

  return (
    <>
      {!hasItems && (
        <Authorization permissions={permissions.create}>
          <div className={titleWrapperCss}>
            <h6 className='u-text-generic u-text--large line-height--49 u-weight--bold u-bg--sauve rounded-t u-margin-bottom-small '>{props.header}</h6>
            <div className='u-weight--regular u-bg--white'>
              <AddBtnMessage text={props.addText} btnClasses='c-btn--tiny' isDisabled={props.inEditMode} onClick={props.onAddClick} testId={props.identifier} />
            </div>
          </div>
        </Authorization>
      )}

      {props.items.map((item, i) => {
        const actionsButtons = (
          <Authorization permissions={permissions.delete}>
            <div className='o-layout__item'>
              <div className='u-float--right'>
                <div
                  onClick={() => {
                    props.onDeleteClick(item.id)
                  }}
                  className={`c-btn c-btn--tiny c-btn--curious u-padding-left-small u-padding-right-small u-margin-left-tiny
                  ${props.inEditMode ? 'disabled' : null}`}
                  title='Delete'
                  disabled={props.inEditMode}
                  data-testid={`${props.identifier}-delete`}
                >
                  <span className='icon icon--trash' />
                </div>
              </div>
            </div>
          </Authorization>
        )

        return (
          <div key={item.id} data-testid={`${props.identifier}-items-${item.id}`}>
            <PayslipTable
              dynamicValues
              editableOptions={props.editableOptions}
              data={props.data[item.id] || []}
              trBodyActionBar={{
                options: { colSpan: 2 },
                Component: (
                  <div className='o-layout'>
                    <div className='o-layout__item u-2/3 field-name u-padding-right-small u-text--overflow-wrap u-text--normal'>
                      {item[props.headerKey] ?? null}
                    </div>
                    <div className='o-layout__item u-1/3 field-value u-weight--regular u-padding-left-none u-text--overflow-wrap'>{actionsButtons}</div>
                  </div>
                ),
              }}
              headings={props.headings}
              wrapperClassName={`u-margin-bottom u-1/1 ${props.identifier}-table`}
            />
            {lastIndex === i && (
              <Authorization permissions={permissions.create}>
                <div className='u-weight--regular u-text--normal text-right u-margin-bottom'>
                  <AddBtnMessage
                    onClick={props.onAddClick}
                    isDisabled={props.inEditMode}
                    text={props.addText}
                    testId={props.identifier}
                    btnClasses='c-btn--small u-padding-left u-padding-right u-margin-left-small'
                  />
                </div>
              </Authorization>
            )}
          </div>
        )
      })}
    </>
  )
}

EmployeeFieldsWithAdd.propTypes = {
  header: PropTypes.string,
  identifier: PropTypes.string,
  addText: PropTypes.string,
  permissions: PropTypes.shape({ create: PropTypes.array, delete: PropTypes.array, edit: PropTypes.array }),
  inEditMode: PropTypes.bool,
  data: PropTypes.object,
  items: PropTypes.array,
  editableOptions: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onAddClick: PropTypes.func,
  headings: PropTypes.array,
  headerKey: PropTypes.string,
}

export default EmployeeFieldsWithAdd
