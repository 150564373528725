import React from 'react'
import Company from 'components/companies/Company'
import PropTypes from 'prop-types'

export const CompanyList = ({ companies, ...props }) => {
  return <div className='o-layout o-grid--strech'>
    {companies.map(company =>
      <Company key={`${company.id}-${company.name}`} company={company} {...props} />
    )}
  </div>
}

CompanyList.propTypes = {
  companies: PropTypes.array
}

export default CompanyList
