import React from 'react'
import PropTypes from 'prop-types'
import Option from './Option'
import Box from '../../Box'

const ColorOption = props => {
  const { children, option: { color } } = props

  return <Option {...props} >
    { color && <Box color={color} /> }
    {children}
  </Option>
}

ColorOption.propTypes = {
  children: PropTypes.node,
  option: PropTypes.object.isRequired,
}

export default ColorOption
