import CoreLayout from 'layouts/PageLayout/PageLayout'
import ActiveRoute from './routes/Active'
import ExtraRoute from './routes/Extra'
import PreviousRoute from './routes/Previous'
import InactiveRoute from './routes/Inactive'
import ReopenedRoute from './routes/Reopened'
import InnerWrapperContainer from 'routes/Payruns/containers/InnerWrapperContainer'
import PayrollReconciliationRoute from './routes/PayrollReconciliation'
import ElementReconciliationRoute from './routes/ElementReconciliation'
import PayrollSummaryRoute from './routes/PayrollSummary'

export default (store) => ({
  path: '/payruns',
  indexRoute: { component: InnerWrapperContainer },
  authProtected: true,
  wrappedComponent: CoreLayout,
  tabbedLayoutWithOrphanedRoutes: true,
  childRoutes: [
    ActiveRoute(store),
    ExtraRoute(store),
    PreviousRoute(store),
    InactiveRoute(store),
    ReopenedRoute(store),
    PayrollReconciliationRoute(store),
    ElementReconciliationRoute(store),
    PayrollSummaryRoute(store),
  ],
})
