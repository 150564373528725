import createSelector from 'utils/createSelector'

const getCompanyId = (state, props) => parseInt(props.companyId)

export const getCompanyProcesses = createSelector(({ CompanyCountryProcess }) =>
  CompanyCountryProcess.all()
    .toModelArray()
    .filter((p) => p.countryProcess.process.service.type === 'setup')
    .map(getProcess)
)

export const getCompanyProcessesByCompany = createSelector(getCompanyId, ({ CompanyCountryProcess }, companyId) =>
  CompanyCountryProcess.all()
    .toModelArray()
    .filter((p) => p.companyService.company.id === companyId && p.countryProcess.process.service.type === 'setup')

    .map(getProcess)
)

// TODO - check why don't we have `color`
const getProcess = (process) => ({
  ...process.ref,
  name: `${process.companyService.company.name} - ${process.countryProcess.process.name}`,
})
