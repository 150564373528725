import { createReducer } from 'utils/redux'
import { PROCESSES_FETCH, PROCESSES_RECEIVE, PROCESSES_INVALIDATE, PROCESS_CREATE } from '../actions/processes'

const actionTypes = {
  fetch: PROCESSES_FETCH,
  receive: PROCESSES_RECEIVE,
  invalidate: PROCESSES_INVALIDATE,
  create: PROCESS_CREATE,
}

export default createReducer(actionTypes)
