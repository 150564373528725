import React, { useCallback, useRef } from 'react'

import { ExportFormatType, getDomSections, generatePdfBySections } from './util'

export const ExportActionButton = ({ iconClass = 'icon--download', ...props }) => (
  <button className='c-btn c-btn--small c-btn--curious u-margin-right-small' title='Download' {...props}>
    <span className={`'icon ${iconClass}`} />
  </button>
)

export const exportImgAsPdf = async ({ sectionsToPrint, getFileName, containerElement, pdfOptions = {}, imgOptions = {} }) => {
  const sections = getDomSections({
    sectionsToPrint,
    containerElement,
  })
  await generatePdfBySections({
    sections,
    getFileName,
    containerElement,
    pdfOptions,
    imgOptions: {
      format: ExportFormatType.Canvas,
      quality: 0.9,
      ...imgOptions,
    },
  })
}

export const Export = ({ sectionsToPrint, getFileName, children, format = ExportFormatType.Pdf, Actions }) => {
  const containerRef = useRef()

  const handleExport = useCallback(() => {
    if (format === ExportFormatType.Pdf) {
      return exportImgAsPdf({
        getFileName,
        containerElement: containerRef.current,
        sectionsToPrint,
      })
    }
  }, [format])

  return (
    <div>
      <Actions onClick={handleExport} />
      <div ref={containerRef}>{children}</div>
    </div>
  )
}
