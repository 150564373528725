import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'
import ClientRoute from './routes/Client'

export default (store) => ({
  path : 'clients',
  indexRoute: { component: RouteView },
  wrappedComponent: CoreLayout,
  authProtected: true,
  childRoutes: [ ClientRoute(store) ]
})
