/* eslint-disable camelcase */
import React from 'react'
import EmployeeCreate from '../components/EmployeeCreate'
import { reduxForm, reset, formValueSelector, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import { getAccessibleCompanies, getCompaniesByCountry } from 'redux/selectors/company'
import { createEmployeeSystemUser } from 'redux/actions/employeeSystemUsers'
import { fetchEmployeeCountryPivot } from 'redux/actions/employeeCountryPivot'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded, retrieveCompanyCustomFields } from 'routes/Companies/modules/actions'
import { fetchTimezonesIfNeeded } from 'redux/actions/timezones'
import { invalidateAnnualSalaries } from 'redux/actions/annualSalaries'
import nationalities from 'redux/config/nationalities'
import allCountries from 'redux/config/allCountries'
import { fetchAccessAreaPivotIfNeeded } from 'redux/actions/accessAreaPivot'
import { fetchBusinessUnitsIfNeeded } from 'redux/actions/businessUnits'
import { fetchOfficeLocationsIfNeeded } from 'redux/actions/officeLocations'
import { fetchSubsidiariesIfNeeded } from 'redux/actions/subsidiaries'
import { getBusinessUnitsByCompany } from 'redux/selectors/businessUnits'
import { fetchCostCentersIfNeeded } from 'redux/actions/costCenters'
import { getCostCentersByCompany } from 'redux/selectors/costCenters'
import { fetchProjectsIfNeeded } from 'redux/actions/projects'
import { getProjectsByCompany } from 'redux/selectors/projects'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { getCurrencies } from 'redux/selectors/currencies'
import { getFormFieldValueToInt } from 'redux/selectors/form'
import { ROLE_EMPLOYEE } from 'redux/config/auth'
import { employeeCustomFieldsConfig } from 'redux/config/employeeFieldsConfig'
import { getCountryRef, getCountriesByAuth } from 'redux/selectors/country'
import { getEmployeeFieldsByCountry, getEmployeeJobOrgFields, getEmployeePayTaxesFields, getEmployeePersonalFields } from '../selectors/employeesORM'
import { showMessage, clearMessage } from 'redux/actions/modal'
import { flatten, isNull } from 'lodash'
import { getDepartmentsByCompany } from 'redux/selectors/departments'
import { fetchDepartmentsIfNeeded } from 'redux/actions/departments'
import { getOfficeLocationsByCompany } from 'redux/selectors/officeLocations'
import { getSubsidiariesByCompany } from 'redux/selectors/subsidiary'
import EmploymentStatusHelper from 'utils/helperClasses/EmploymentStatuses'
import { sortByName } from 'utils/strings'
import PropTypes from 'prop-types'
import { isCot } from '../../../../../redux/selectors/auth'

// Flatten all Employee custom country fields
const customFields = flatten(employeeCustomFieldsConfig.map((country) => country.customFields))

// Get fields by `tabName`
const getCustomFields = (tabName) => customFields.filter((field) => field.tab === tabName)

// Convert fields from Array to Object and add `tabIndex` key to each one field
const convertFieldsToObjectAndAddIndex = (fields, tabIndex) =>
  fields.reduce((acc, field) => {
    acc[field.name] = {
      name: field.name,
      tabIndex: tabIndex,
      ...(field.title && { title: field.title }),
    }
    return acc
  }, {})

// Please refer to the invoked functions documentation
const getAndConvertFields = (tabName, tabIndex) => convertFieldsToObjectAndAddIndex(getCustomFields(tabName), tabIndex)

// Here we have a list of Employee form fields, mapped to `tabIndex` value.
// That's needed, because of the tab error handling logic, implemented in `EmployeeFormCreateContainer.changeTab()`.
// In short: If we have an error field on Employee form submission, we have to switch to the form tab, that contains
// the error field. Because of this we have to know the `tabIndex` value for each one field.
// !!! Important !!!: when we render a `<Field>` in the form, for `name` prop we pass these `formFields` values,
// instead of hardcoded string value.
// I.e.: `<Field name={formFields.firstname.name} />`, instead of `<Field name='firstname' />`.
// We do this, in order to ensure that, all the rendered `<Field`> are already registered and mapped to `tabIndex` here.
// Otherwise, if we pass a hardcoded string name value,
// then it's very likely to forget to register the Field here and the error handling logic for this field won't work.
// !!! Important 2 !!!: Here we don't handle and register dynamic fields, such as deductions and pay elements.
// Currently these fields are rendered on the last tab and their error handling process works correctly,
// because the process is triggered only if all the `formFields` are valid. Therefore, no tab changes should be done.
// But if we move the dynamic fields to the first or second tab, then it won't work.
// Because it would be a little bit more work to be implemented and it's not a priority just right now,
// then we won't handle this use-case.
export const formFields = {
  // TabIndex 0 fields
  firstname: { name: 'firstname', tabIndex: 0 },
  surname: { name: 'surname', tabIndex: 0 },
  otherSurname: { name: 'otherSurname', tabIndex: 0 },
  dateOfBirth: { name: 'dateOfBirth', tabIndex: 0 },
  nationality: { name: 'nationality', tabIndex: 0 },
  title: { name: 'title', tabIndex: 0 },
  gender: { name: 'gender', tabIndex: 0 },
  maritalStatus: { name: 'maritalStatus', tabIndex: 0 },
  personalEmail: { name: 'personalEmail', tabIndex: 0 },
  workEmail: { name: 'workEmail', tabIndex: 0 },
  taxNumber: { name: 'taxNumber', tabIndex: 0 },
  mobilePhone: { name: 'mobilePhone', tabIndex: 0 },
  officePhone: { name: 'officePhone', tabIndex: 0 },
  skype: { name: 'skype', tabIndex: 0 },
  emergencyFirstname: { name: 'emergencyFirstname', tabIndex: 0 },
  emergencySurname: { name: 'emergencySurname', tabIndex: 0 },
  emergencyMobilePhone: { name: 'emergencyMobilePhone', tabIndex: 0 },
  emergencyRelationship: { name: 'emergencyRelationship', tabIndex: 0 },
  birthName: { name: 'birthName', tabIndex: 0 },
  suffix: { name: 'suffix', tabIndex: 0 },
  initials: { name: 'initials', tabIndex: 0 },
  employmentCondition: { name: 'employmentCondition', tabIndex: 0 },
  rgNumber: { name: 'rgNumber', tabIndex: 0 },
  nci: { name: 'nci', tabIndex: 0 },
  crnm: { name: 'crnm', tabIndex: 0 },
  voterRegistrationNumber: { name: 'voterRegistrationNumber', tabIndex: 0 },
  otherLocalSurname: { name: 'otherLocalSurname', tabIndex: 0 },
  otherLocalFirstName: { name: 'otherLocalFirstName', tabIndex: 0 },
  ...getAndConvertFields('Personal', 0),
  // TabIndex 1 fields
  employeeId: { name: 'employeeId', tabIndex: 1 },
  username: { name: 'username', tabIndex: 1 },
  position: { name: 'position', tabIndex: 1 },
  countries: { name: 'countries', tabIndex: 1 },
  company: { name: 'company', tabIndex: 1 },
  businessUnit: { name: 'businessUnit', tabIndex: 1 },
  department: { name: 'department', tabIndex: 1 },
  costCenter: { name: 'costCenter', tabIndex: 1 },
  officeLocation: { name: 'officeLocation', tabIndex: 1 },
  subsidiary: { name: 'subsidiary', tabIndex: 1 },
  project: { name: 'project', tabIndex: 1 },
  hasCredentials: { name: 'hasCredentials', tabIndex: 1 },
  isSSOEnabled: { name: 'isSSOEnabled', tabIndex: 1 },
  companyLink: { name: 'companyLink', tabIndex: 1 },
  employmentStatus: { name: 'employmentStatus', tabIndex: 1 },
  uniqueContractorId: { name: 'uniqueContractorId', tabIndex: 1 },
  tradeUnion: { name: 'tradeUnion', tabIndex: 1 },
  visaWorkPermit: { name: 'visaWorkPermit', tabIndex: 1 },
  contractorTempAgency: { name: 'contractorTempAgency', tabIndex: 1 },
  typeOfContract: { name: 'typeOfContract', tabIndex: 1 },
  startDate: { name: 'startDate', tabIndex: 1 },
  originalHireDate: { name: 'originalHireDate', tabIndex: 1 },
  hireReason: { name: 'hireReason', tabIndex: 1 },
  endDate: { name: 'endDate', tabIndex: 1 },
  terminationReason: { name: 'terminationReason', tabIndex: 1 },
  weeklyHours: { name: 'weeklyHours', tabIndex: 1 },
  monthlyHours: { name: 'monthlyHours', tabIndex: 1 },
  paymentType: { name: 'paymentType', tabIndex: 1 },
  hourlyRate: { name: 'hourlyRate', tabIndex: 1 },
  workType: { name: 'workType', tabIndex: 1 },
  occupationalStatusCode: { name: 'occupationalStatusCode', tabIndex: 1 },
  managementPandL: { name: 'managementPandL', tabIndex: 1 },
  managerId: { name: 'managerId', tabIndex: 1 },
  workLevel: { name: 'workLevel', tabIndex: 1 },
  rehire: { name: 'rehire', tabIndex: 1 },
  otherEmployeeId: { name: 'otherEmployeeId', tabIndex: 1 },
  payScaleGroup: { name: 'payScaleGroup', tabIndex: 1 },
  payScaleLevel: { name: 'payScaleLevel', tabIndex: 1 },
  ctpsNumber: { name: 'ctpsNumber', tabIndex: 1 },
  pisNumber: { name: 'pisNumber', tabIndex: 1 },
  employmentInsuranceNumber: { name: 'employmentInsuranceNumber', tabIndex: 1 },
  residentCardNumber: { name: 'residentCardNumber', tabIndex: 1 },
  welfarePensionNumber: { name: 'welfarePensionNumber', tabIndex: 1 },
  ...getAndConvertFields('Job & Org', 1),
  // TabIndex 2 fields
  paymentCurrency: { name: 'paymentCurrency', tabIndex: 2 },
  payroll: { name: 'payroll', tabIndex: 2 },
  paygroup: { name: 'paygroup', tabIndex: 2 },
  annualSalaryValue: { name: 'annualSalaryValue', tabIndex: 2 },
  effectiveDate: { name: 'effectiveDate', tabIndex: 2 },
  reason: { name: 'reason', tabIndex: 2 },
  ...getAndConvertFields('Pay & Taxes', 2),
}

class EmployeeFormCreateContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
  }

  /**
   * Change to the tab, that has an error
   *
   * @param {Array} errorFields - Names of form fields, these have errors
   */
  changeTab (errorFields) {
    const formFieldsKeys = Object.keys(formFields)

    const tabOne = formFieldsKeys.filter((fieldKey) => formFields[fieldKey].tabIndex === 0).map((fieldKey) => formFields[fieldKey])

    const tabTwo = formFieldsKeys.filter((fieldKey) => formFields[fieldKey].tabIndex === 1).map((fieldKey) => formFields[fieldKey])

    const tabThree = formFieldsKeys.filter((fieldKey) => formFields[fieldKey].tabIndex === 2).map((fieldKey) => formFields[fieldKey])

    const fields = tabOne.concat(tabTwo, tabThree)

    // We iterate over the fields (not the error fields),
    // because we want to respect the order, on which the error occurs.
    // Otherwise wrong tab can be changed, depending on `errorFields`
    // fields order.
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i]
      let hasError = errorFields ? errorFields.includes(field.name) : false

      if (!hasError) continue

      this.setState({ tabIndex: field.tabIndex })
      break
    }
  }

  hidePopup () {
    this.props.refs.modalCreateEmployee.hideModal()
  }

  UNSAFE_componentWillMount () {
    this.props.fetchCountriesIfNeeded()
    this.props.fetchCompaniesIfNeeded()
    this.props.fetchTimezonesIfNeeded()
    this.props.fetchBusinessUnitsIfNeeded()
    this.props.fetchCostCentersIfNeeded()
    this.props.fetchDepartmentsIfNeeded()
    this.props.fetchOfficeLocationsIfNeeded()
    this.props.fetchSubsidiariesIfNeeded()
    this.props.fetchProjectsIfNeeded()
    this.props.fetchCurrenciesIfNeeded()
    this.props.fetchEmployeeCountryPivot()
    this.props.fetchEmployeeCountryPivot()
    this.props.fetchAccessAreaPivotIfNeeded()
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return (
      <EmployeeCreate
        fields={formFields}
        onSubmit={(data) =>
          this.props.createEmployeeSystemUser(data, this.props.onCreate, this.props).catch((error) => {
            this.changeTab(Object.getOwnPropertyNames(error.errors))
            throw new SubmissionError(error.errors)
          })
        }
        onChangeTab={(tabIndex) => this.setState({ tabIndex })}
        tabIndex={this.state.tabIndex}
        {...this.props}
      />
    )
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    fetchTimezonesIfNeeded: () => dispatch(fetchTimezonesIfNeeded()),
    fetchBusinessUnitsIfNeeded: () => dispatch(fetchBusinessUnitsIfNeeded()),
    fetchCostCentersIfNeeded: () => dispatch(fetchCostCentersIfNeeded()),
    fetchDepartmentsIfNeeded: () => dispatch(fetchDepartmentsIfNeeded()),
    fetchOfficeLocationsIfNeeded: () => dispatch(fetchOfficeLocationsIfNeeded()),
    fetchSubsidiariesIfNeeded: () => dispatch(fetchSubsidiariesIfNeeded()),
    fetchProjectsIfNeeded: () => dispatch(fetchProjectsIfNeeded()),
    fetchCurrenciesIfNeeded: () => dispatch(fetchCurrenciesIfNeeded()),
    fetchEmployeeCountryPivot: () => dispatch(fetchEmployeeCountryPivot()),
    fetchAccessAreaPivotIfNeeded: () => dispatch(fetchAccessAreaPivotIfNeeded()),
    createEmployeeSystemUser: async (data, onSuccess) => {
      const resp = await dispatch(retrieveCompanyCustomFields(data.company))
      return dispatch(createEmployeeSystemUser(data, resp)).then((response) => {
        onSuccess()
        dispatch(
          showMessage({
            body: (
              <span data-testid='employee-created-message'>
                Employee is successfully created. You can assign them to payroll from
                <span
                  className='u-text--curious u-text--underline u-cursor--pointer'
                  onClick={() => {
                    // 1. Navigate to Employee Pay & Taxes tab
                    ownProps.history.push(`/employees/${response.id}/job-and-org`)
                    // 2. Close the modal
                    dispatch(clearMessage())
                  }}
                >
                  {' '}
                  here
                </span>
                .
              </span>
            ),
          })
        )
        dispatch(reset('employeeCreate'))
        dispatch(fetchEmployeeCountryPivot())
        dispatch(invalidateAnnualSalaries())
      })
    },
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { countries, companies, timezones, businessUnits, costCenters, departments, officeLocations, subsidiary, projects, currencies, accessAreaPivot } = state

  if (
    isFetching([countries, companies, timezones, businessUnits, costCenters, departments, officeLocations, subsidiary, projects, currencies, accessAreaPivot])
  ) {
    return { isFetching: true }
  }

  const selector = formValueSelector('employeeCreate')
  const selectedCountry = selector(state, 'countries')
  const formSalary = selector(state, 'annualSalaryValue')
  const formAnnualSalary = formSalary ? parseInt(formSalary, 10) : null
  const paymentType = selector(state, 'paymentType')
  const typeOfEmploymentSelectedValue = selector(state, 'employmentStatus')
  const selectedCountryName = selectedCountry ? getCountryRef(state, { countryId: selectedCountry }).name : null

  const employeeCustomFieldsObj = getEmployeeFieldsByCountry(state, { country: selectedCountryName })
  const employeeCustomFieldsPersonal = (employeeCustomFieldsObj ? getEmployeePersonalFields(state, { country: selectedCountryName }) : []).filter(
    (field) => !field.hideOnCreate
  )
  const employeeCustomFieldsJobAndOrg = employeeCustomFieldsObj ? getEmployeeJobOrgFields(state, { country: selectedCountryName }) : []
  const employeeCustomFieldsPayAndTaxes = employeeCustomFieldsObj ? getEmployeePayTaxesFields(state, { country: selectedCountryName }) : []

  const selectedCompany = getFormFieldValueToInt(state, 'employeeCreate', 'company')

  const businessUnitsByCompany = getBusinessUnitsByCompany(state, { companyId: selectedCompany }).sort(sortByName)
  const costCentersByCompany = getCostCentersByCompany(state, { companyId: selectedCompany }).sort(sortByName)
  const departmentsByCompany = getDepartmentsByCompany(state, { companyId: selectedCompany }).sort(sortByName)
  const officeLocationsByCompany = getOfficeLocationsByCompany(state, { companyId: selectedCompany })
  const subsidiariesByCompany = getSubsidiariesByCompany(state, { companyId: selectedCompany })
  const projectsByCompany = getProjectsByCompany(state, { companyId: selectedCompany }).sort(sortByName)
  const allCurrencies = getCurrencies(state)
  const currenciesOptions = allCurrencies.map((currency) => ({
    value: currency.id,
    label: currency.abbreviature,
  }))

  return {
    nationalities,
    allCountries,
    businessUnits: businessUnitsByCompany,
    costCenters: costCentersByCompany,
    departments: departmentsByCompany,
    officeLocations: officeLocationsByCompany,
    subsidiaries: subsidiariesByCompany,
    projects: projectsByCompany,
    allCurrencies,
    currencies: currenciesOptions,
    selectedCompany,
    selectedCountry,
    employeeCustomFieldsPersonal,
    employeeCustomFieldsJobAndOrg,
    employeeCustomFieldsPayAndTaxes,
    includeCompany: true,
    includePayroll: false,
    includePaygroup: false,
    hadNotAnnualSalaryAndHasChanged: !isNull(formAnnualSalary),
    isPaymentTypeHourly: paymentType === 'Hourly',
    isCotUser: isCot(state),
    countries: getCountriesByAuth(state),
    companies: selectedCountry ? getCompaniesByCountry(state, { countryId: selectedCountry }).sort(sortByName) : getAccessibleCompanies(state).sort(sortByName),
    timezones: state.timezones.allIds.map((id) => state.timezones.byIds[id]),
    initialValues: {
      roleType: ROLE_EMPLOYEE,
      paymentType: 'Salary',
    },
    isExpandable: true,
    typeOfEmploymentSelectedValue,
    EmployeeStatus: new EmploymentStatusHelper(typeOfEmploymentSelectedValue),
    selectedCountryName,
  }
}

EmployeeFormCreateContainer.propTypes = {
  fetchCountriesIfNeeded: PropTypes.func,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchTimezonesIfNeeded: PropTypes.func,
  fetchBusinessUnitsIfNeeded: PropTypes.func,
  fetchCostCentersIfNeeded: PropTypes.func,
  fetchDepartmentsIfNeeded: PropTypes.func,
  fetchOfficeLocationsIfNeeded: PropTypes.func,
  fetchSubsidiariesIfNeeded: PropTypes.func,
  fetchProjectsIfNeeded: PropTypes.func,
  fetchCurrenciesIfNeeded: PropTypes.func,
  fetchEmployeeCountryPivot: PropTypes.func,
  fetchAccessAreaPivotIfNeeded: PropTypes.func,
  createEmployeeSystemUser: PropTypes.func,
  onCreate: PropTypes.func,
  isFetching: PropTypes.bool,
  refs: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'employeeCreate',
    destroyOnUnmount: false,
    touchOnChange: true,
    touchOnBlur: true,
  })(EmployeeFormCreateContainer)
)
