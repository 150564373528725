import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import AccordionContainer from 'containers/AccordionContainer'
import Flag from 'components/Flag'
import { getCompanyWithCountry } from 'redux/selectors/company'
import PayrollInstanceView from 'routes/Payruns/components/PayrollInstanceView'
import FilterButton from 'components/buttons/FilterButton'
import { payrunStatesEnum } from 'utils/enums/payrunStates'
// eslint-disable-next-line max-len
import PayrollInstanceVendorView from 'routes/Vendors/routes/Vendor/routes/Clients/routes/Client/routes/VendorCompanies/routes/VendorCompany/routes/CompanyPayruns/routes/Payruns/components/RouteView'
import PayrollsPinButton from 'routes/Payruns/components/PayrollsPinButton'

const PayrollInstanceAccordionContainer = ({ payroll, payrunState, company, isVendor = false, isExpanded = false, onPinPayrollClick, ...rest }) => {
  const [buttonVisibility, setButtonVisibility] = useState(isExpanded)

  const query = useQuery()
  const [showFilters, setShowFilters] = useState(false)

  const ActionButtons = () => {
    return buttonVisibility && <>
      {payrunState === payrunStatesEnum.INACTIVE && <Link
        to={`/toolbox/calendar/year/payruns/${payroll.id}`}
        className='c-btn c-btn--curious c-btn--tiny u-margin-right-small u-margin-bottom'
        data-testid={`calendar-button-${payroll.id}`}
        title='Calendar'
      >
        <span className='icon icon--calendar-year' />
      </Link>}
      <FilterButton
        className='c-btn c-btn--curious c-btn--tiny u-margin-right-small u-margin-bottom'
        onClick={() => setShowFilters(!showFilters)} />
    </>
  }

  const onPinButtonClick = () => {
    if (!payroll.pinned) return
    onPinPayrollClick(payroll.id)
  }

  return <AccordionContainer
    title={isVendor ? payroll.name : payroll.shortName}
    isExpanded={isExpanded}
    icon={
      <>
        {!isVendor ? <PayrollsPinButton id={payroll.id} pinned={payroll.pinned} onClick={onPinButtonClick} /> : null}
        <Flag
          flag={company.country.abbreviature?.toLowerCase()}
          title={`${company.country.name} - ${company.name}`}
          size='tiny'
          classes='u-margin-right-small u-margin-left-small'
        />
      </>
    }
    headerDisplayModifiers='ai-center payroll-instance-accordion-header'
    actionButtons={<ActionButtons />}
    onExpandedCallback={() => setButtonVisibility(!buttonVisibility)}
    fullWidth
    rounded
  >
    {buttonVisibility && isVendor && <PayrollInstanceVendorView
      payrunState={payrunState}
      payrollId={payroll.id}
      showFilters={showFilters}
      {...rest}
    />
    }
    {buttonVisibility && !isVendor && <PayrollInstanceView
      payrunState={payrunState}
      payrollId={payroll.id}
      query={query}
      showFilters={showFilters}
      {...rest}
    />
    }
  </AccordionContainer>
}

PayrollInstanceAccordionContainer.propTypes = {
  company: PropTypes.object,
  payroll: PropTypes.object,
  payrunState: PropTypes.string,
  isVendor: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onPinPayrollClick: PropTypes.func
}

const mapStateToProps = (state, props) => {
  return {
    company: getCompanyWithCountry(state, { companyId: props.payroll.company })
  }
}

export default connect(mapStateToProps)(PayrollInstanceAccordionContainer)
