import CoreLayout from 'layouts/PageLayout/PageLayout'
import RouteView from './components/RouteView'

export default (store) => ({
  path : 'customize-report',
  authProtected: true,
  wrappedComponent: CoreLayout,
  indexRoute: { component: RouteView },
  tabbedRoute: true,
})
