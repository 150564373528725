import { useState } from 'react'
import pageUIStatesEnum from 'utils/enums/pageUIStatesEnums'

const usePageUIState = ({ uiState = pageUIStatesEnum.VIEW }) => {
  const [pageUIState, setPageUIState] = useState(uiState)
  const inEditMode = pageUIState === pageUIStatesEnum.EDIT
  const inAddMode = pageUIState === pageUIStatesEnum.ADD
  return { pageUIState, setPageUIState, inEditMode, inAddMode }
}

export default usePageUIState
