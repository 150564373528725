import { uniq } from 'lodash'

import createSelector from 'utils/createSelector'
import { getLoggedInUserId, isCot } from './auth'
import { getDocumentUsersByDocIdAndDocTenant } from './documentUsers'
import { _getCompanyProps, extractAccessibleCompanies } from './externalCompanies'

const getUserId = (state, props) => parseInt(props.userId, 10)
const isCotUser = (state, props) => isCot(state)
const getTenantName = (state, props) => props.tenant
const getCompanyId = (state, props) => parseInt(props.companyId, 10)

export const getVendorUser = createSelector(getUserId, ({ VendorUser }, userId) => {
  const user = VendorUser.withId(userId)
  return {
    ...user.ref,
    ...(user.country ? { country: { ...user.country.ref } } : {}),
  }
})

/**
 *
 * Get vendor users for a vendor tenant
 *
 * @return VendorUser
 */
export const getVendorUsers = createSelector(({ VendorUser }) => {
  return VendorUser.all()
    .toModelArray()
    .map((user) => ({
      ...user.ref,
      ...(user.country ? { country: { ...user.country.ref } } : {}),
      vendor: { ...user.vendor.ref },
      name: user.getName(),
    }))
    .reverse()
})

/**
 *
 * Get vendor users for a vendor tenant excluding the logged in user
 *
 * @return VendorUser
 */
export const getVendorUsersWithoutLoggedVendorUser = createSelector(getVendorUsers, isCotUser, getLoggedInUserId, (session, vendorUsers, isCot, userId) => {
  if (isCot) return vendorUsers
  return vendorUsers.filter((user) => user.id !== userId)
})

/**
 *
 * Get vendor users for a vendor tenant excluding the logged in user
 * and check if the document is shared with them
 *
 * @return VendorUser
 */
export const getVendorUsersWithSharedDocumentsWithoutLoggedVendorUser = createSelector(
  getVendorUsersWithoutLoggedVendorUser,
  getDocumentUsersByDocIdAndDocTenant,
  (session, vendorUsers, docUsers) => {
    return vendorUsers.map((user) => {
      const file = docUsers.find((docUser) => docUser.userId === user.id && docUser.userTenant === user.vendor.schema)
      return {
        ...user,
        share: file !== undefined,
      }
    })
  }
)

export const getVendorUsersHavingCredentialsWithAccessToCompanyByCompanyId = createSelector(
  getVendorUsersWithSharedDocumentsWithoutLoggedVendorUser,
  getCompanyId,
  getTenantName,
  (session, users, companyId, tenantName) => users.filter((u) => u.hasCredentials).filter((u) => u.accessableCompanies[tenantName]?.includes(companyId))
)

export const getVendorUsersWithSharedDocument = createSelector(getVendorUsers, getDocumentUsersByDocIdAndDocTenant, (session, vendorUsers, docUsers) => {
  const users = vendorUsers.map((user) => {
    const file = docUsers.find((docUser) => docUser.userId === user.id && docUser.userTenant === user.vendor.schema)
    return {
      ...user,
      share: file !== undefined,
    }
  })
  return users.filter((user) => user.share)
})

export const getVendorUsersInitialAccessAreas = createSelector(
  (state, props) => [state, props],
  ({ ExternalCompany }, [state, props]) => {
    const { vendorUser } = props
    const accessableCompanies = vendorUser?.accessableCompanies || []
    const selectedAccessAreas = ExternalCompany.all()
      .toRefArray()
      .map((allowedExternalCompany) => ({
        accessable: allowedExternalCompany.extraData.accessable,
        companyId: allowedExternalCompany.extraData.id,
        country: allowedExternalCompany.extraData.country,
        tenant: allowedExternalCompany.extraData.tenant,
        name: allowedExternalCompany.name,
        id: allowedExternalCompany.id,
      }))
      .filter((allowedExternalCompany) => {
        const accesableCompaniesByTenant = accessableCompanies[allowedExternalCompany.tenant]
        return accesableCompaniesByTenant?.includes(allowedExternalCompany.companyId) && allowedExternalCompany?.accessable
      })
    const selectedTenants = uniq(Object.keys(accessableCompanies))
    return {
      tenants: selectedTenants,
      accessAreas: selectedAccessAreas?.map((area) => JSON.stringify(area)),
    }
  }
)

/**
 * get vendor companies based on logged User own access
 * @function
 * @returns {Object.<string, Array>} An object where keys are vendor user IDs and values are arrays of accessible external vendor companies.
 * @param {Object} options - The options object.
 * @param {Object} options.VendorUser - The VendorUser model.
 * @param {Object} options.ExternalCompany - The ExternalCompany model.
 * @returns {Object.<string, Array>} An object mapping vendor user IDs to arrays of accessible external vendor companies.
 */
export const getVendorAAsByLoggedUserPermissions = createSelector(({ VendorUser, ExternalCompany }) => {
  const ownAccessibleCompanies = ExternalCompany.all()
    .toRefArray()
    .map((company) => _getCompanyProps(company))
  return VendorUser.all()
    .toRefArray()
    ?.reduce((acc, vendor) => {
      acc[vendor.id] = extractAccessibleCompanies(ownAccessibleCompanies, vendor)?.filter((v) => v)
      return acc
    }, {})
})
