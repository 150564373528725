import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { isFetching } from 'utils/redux/fetching'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchCurrenciesIfNeeded } from 'redux/actions/currencies'
import { fetchFxRatesIfNeeded } from 'redux/actions/fxRates'
import { fetchAccessAreaPivotIfNeeded } from 'redux/actions/accessAreaPivot'
import FiltersPayrunReport from '../../../components/FiltersPayrunReport'
import { getCountriesByAuth } from 'redux/selectors/country'
import { getCompaniesByAuth, getCompaniesByCountriesByAuth } from 'redux/selectors/company'
import { getCurrencies } from 'redux/selectors/currencies'
import { getFxRates } from 'redux/selectors/fxRates'
import { isArray } from 'lodash'
import { sortByName } from 'utils/strings'

class FiltersPayrunReportContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { dispatch } = this.props

    dispatch(fetchCountriesIfNeeded())
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchCurrenciesIfNeeded())
    dispatch(fetchAccessAreaPivotIfNeeded())
    dispatch(fetchFxRatesIfNeeded())
  }

  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <FiltersPayrunReport {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const { countries, companies, currencies, fxRates, accessAreaPivot } = state
  if (isFetching([countries, companies, currencies, fxRates, accessAreaPivot])) return { isFetching: true }

  const selector = formValueSelector(props.form)
  const values = selector(
    state,
    'subcategory',
    'showOnlyTotals',
    'fromDate',
    'toDate',
    'country',
    'company',
    'payroll',
    'payrollInstance',
    'payrollCurrency',
    'reportingCurrency',
    'reportingRate'
  )

  const allCurrencies = getCurrencies(state)

  return {
    // All selected values
    selected: values,
    // All countries in the system
    countries: getCountriesByAuth(state).sort(sortByName),
    // Companies by selected countries
    companies: values.country
      ? getCompaniesByCountriesByAuth(state, { countriesIds: isArray(values.country) ? values.country : [values.country] }).sort(sortByName)
      : getCompaniesByAuth(state, props).sort(sortByName),
    // All currencies
    currencies: allCurrencies,
    fxRates: getFxRates(state),
  }
}

FiltersPayrunReportContainer.propTypes = {
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
}

export default connect(mapStateToProps)(FiltersPayrunReportContainer)
