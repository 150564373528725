import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { createFilter } from 'utils/redux/filter'
import { getFilteredPayrollInstances } from 'redux/selectors/payrollInstance'
import CustomSelectField from 'components/form/select/CustomSelectField'
import CheckboxOption from 'components/form/select/CheckboxOption'
import Fetcher from '../../../containers/Fetcher'
import { normalizeDate } from './PayrollSelectContainer'

const PayrollSelectContainer = ({ payrollInstances, isFieldDisabled, disabledReason, isFetching, onChanged, payrunRequired = true }) => {
  return (
    <Field
      name='payrollInstance'
      label={payrunRequired ? 'Payrun *' : 'Payrun'}
      component={CustomSelectField}
      className='c-custom-select--transparent c-custom-select--overflowy c-custom-select--left-padding '
      labelClassName='c-label u-text--small u-text--curious'
      formGroupClassName='u-margin-none'
      optionComponent={CheckboxOption}
      placeholder={isFetching ? 'Loading...' : 'Select'}
      disabled={isFieldDisabled}
      disabledReason={disabledReason}
      multi
      options={payrollInstances.map((instance) => ({
        value: instance.id,
        label: instance.payrollNameAndPeriod,
      }))}
      onChanged={onChanged}
      // While fetching, the payrollInstance are empty array. If we enable the flag, it will reset the selected values.
      // That's the reasons we are disabling it in that case only.
      enableRemovedOptionFix={!isFetching}
      data-testid='payrollInstance-wrapper'
    />
  )
}

PayrollSelectContainer.propTypes = {
  payrollInstances: PropTypes.array.isRequired,
  isFieldDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  payrunRequired: PropTypes.bool,
  disabledReason: PropTypes.string,
  onChanged: PropTypes.func,
}

const mapStateToProps = (state, props) => {
  return {
    isFetching: props.isFetching,
    payrollInstances: !props.isFetching ? getFilteredPayrollInstances(state, { filter: props.filters.payrollInstances.name }) : [],
  }
}

const Container = connect(mapStateToProps)(PayrollSelectContainer)

export default Fetcher(
  Container,
  [
    {
      name: 'payrollInstances',
      params: [
        {
          _computed: {
            filter: (state, props) =>
              createFilter({
                reportFromDate: normalizeDate(props.selected.fromDate),
                reportToDate: normalizeDate(props.selected.toDate),
                payroll: props.selected.payroll,
                status: props.status,
                isAccessable: true,
                sort: [{ name: 'id', order: 'desc' }],
              }),
          },
        },
      ],
      isForceFetching: true,
    },
  ],
  { renderWhileFetching: true, showLoader: false }
)
