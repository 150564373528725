import React from 'react'
import PropTypes from 'prop-types'

class SSO extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isClicked: false
    }

    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    // disable subsequent clicks
    if (this.state.isClicked) return false

    this.setState({ isClicked: true }, () => {
      window.location = this.props.url
    })
  }

  render () {
    return <span
      onClick={this.onClick}
      className='c-btn c-btn--full c-btn--rounded u-text--large c-btn--curious u-padding-left--small u-padding-right--small'>
      Single Sign On
    </span>
  }
}

SSO.propTypes = {
  url: PropTypes.string.isRequired
}

export default SSO
