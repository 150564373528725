/* eslint-disable curly */
import React from 'react'
import { connect } from 'react-redux'
import {
  isUser, isPayrollAdmin, isPayrollAnalyst, isPayrollDataEntry, isFinanceAdmin, isAccounting, isControlling,
  isCot, isEmployeeOnly, isVendorInvolved } from 'redux/selectors/auth'
import { isParentCompanyTenant } from 'redux/selectors/tenants'

import EmployeeHeaderContainer from './EmployeeHeaderContainer'
import CompanyHeaderContainer from './CompanyHeaderContainer'
import CountryHeaderContainer from './CountryHeaderContainer'
import VendorHeaderContainer from './VendorHeaderContainer'
import PayrunHeaderContainer from './PayrunHeaderContainer'

import NavBarProfileContainerEmployer from '../navbar/NavBarProfileContainer_Employer'
import NavBarProfileContainerCos from '../navbar/NavBarProfileContainer_Cos'
import NavBarProfileContainerVendor from '../navbar/NavBarProfileContainer_Vendor'

import HeaderLayout from 'components/header/HeaderLayout'
import PropTypes from 'prop-types'

const renderRightColumn = ({ isEmployer, isVendor, isCot }) => {
  if (isEmployer) return <NavBarProfileContainerEmployer />
  if (isVendor) return <NavBarProfileContainerVendor />
  if (isCot) return <NavBarProfileContainerCos />
}

const renderHeaderChildren = ({
  employeeId,
  companyId,
  countryId,
  vendorId,
  isCot,
  vendorTenantUrl,
  isEmployeeOnly,
  payrunInstanceId,
  showPayrunHeader
}) => {
  if (employeeId) return <EmployeeHeaderContainer
    employeeId={employeeId}
    isCot={isCot}
    isEmployeeOnly={isEmployeeOnly}
  />

  if (showPayrunHeader) return <PayrunHeaderContainer
    payrunInstanceId={payrunInstanceId}
    vendorTenantUrl={vendorTenantUrl}
  />

  if (companyId) return <CompanyHeaderContainer
    companyId={companyId}
    vendorTenantUrl={vendorTenantUrl}
    isCot={isCot}
  />

  if (countryId) return <CountryHeaderContainer countryId={countryId} />

  if (vendorId) return <VendorHeaderContainer vendorId={vendorId} />
}

const HeaderContainer = props => {
  return <HeaderLayout isCot={props.isCot} renderRightColumn={() => renderRightColumn(props)}>
    { renderHeaderChildren(props) }
  </HeaderLayout>
}

const mapStateToProps = (state, { match: { params }, ...rest }) => {
  // TODO - had to demystify where we pass `employeeId=`,
  //  because the standard flow is to use `props.match.params`, for such tasks.
  const employeeIdFilter = parseInt(rest.query.get('employeeId'), 10)
  const employeeId = params.employeeId || employeeIdFilter
  return {
    employeeId,
    companyId: params.companyId,
    countryId: params.countryId,
    vendorId: params.vendorId,
    payrunInstanceId: params.payrollInstanceId,
    showPayrunHeader : params.payrollInstanceId && location.pathname.includes('notes'),
    isEmployer: isUser(state) || isPayrollAdmin(state) || isPayrollAnalyst(state) ||
      isPayrollDataEntry(state) || isFinanceAdmin(state) || isAccounting(state) || isControlling(state),
    isCot: isCot(state),
    isEmployeeOnly: isEmployeeOnly(state),
    isVendor: isVendorInvolved(state),
    isParentCompanyTenant: isParentCompanyTenant(state),
    vendorTenantUrl: state.vendorTenant.url
  }
}

HeaderContainer.propTypes = {
  isCot: PropTypes.bool,
}

export default connect(mapStateToProps)(HeaderContainer)
