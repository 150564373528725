import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const COMPANY_SERVICE_FETCH = 'COMPANY_SERVICE_FETCH'
export const COMPANY_SERVICE_RECEIVE = 'COMPANY_SERVICE_RECEIVE'
export const COMPANY_SERVICE_UPDATE = 'COMPANY_SERVICE_UPDATE'
export const COMPANY_SERVICE_INVALIDATE = 'COMPANY_SERVICE_INVALIDATE'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: COMPANY_SERVICE_FETCH,
  receive: COMPANY_SERVICE_RECEIVE,
  update: COMPANY_SERVICE_UPDATE,
  invalidate: COMPANY_SERVICE_INVALIDATE
}
const actionUtility = new ActionUtility(actionTypes, 'companyServicePivot', 'companyservices', 'CompanyCountryService')

// ------------------------------------
// Thunk
// ------------------------------------
export const fetchCompanyServicePivotIfNeeded = actionUtility.fetchEnitiesIfNeeded
export const fetchCompanyServicePivot = actionUtility.fetchEntities
export const updateCompanyServicePivot = actionUtility.updateEntity
export const invalidateCompanyServicePivot = actionUtility.invalidate
