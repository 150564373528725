import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CheckboxField from 'components/form/CheckboxField'
import Flag from 'components/Flag'
import Option from './Option'
import Box from '../../Box'

const CheckboxOption = props => {
  const { isSelected, children, className, option: { abbreviature, value, label, disabled, color, isBold, removeCheckbox } } = props

  const dropdownClassName = `o-block o-block--left u-padding-tiny ${className}`
  const indicatorClassName = isSelected ? 'control__indicator--small control__indicator--in-select'
    : 'control__indicator--small'
  const formGroupClassName = 'o-form-group--in-select u-margin-none u-margin-top-tiny-half u-float--left'

  return <Option {...props} className={dropdownClassName}>
    {
      value !== '*' && !removeCheckbox && <Field
        type='checkbox' component={CheckboxField} name={label} disabled={disabled}
        indicatorClassName={indicatorClassName}
        formGroupClassName={formGroupClassName}
      />
    }
    { abbreviature && <Flag flag={abbreviature.toLowerCase()} size='miniature' classes='u-margin-right-tiny' /> }
    { color && <Box color={color} /> }
    { isBold ? <span className='u-weight--bold'>{ children }</span> : children}
  </Option>
}

CheckboxOption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  isBold: PropTypes.bool,
  removeCheckbox: PropTypes.bool,
  option: PropTypes.object.isRequired,
}

export default CheckboxOption
