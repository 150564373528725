import { toastEntityTypesEnum } from 'utils/enums/toastEntityTypesEnum'

export const DEFAULT_AUTO_CLOSE_TIME = 20000

const DEFAULT_CLOSE_BEHAVIOUR = { autoClose: false, closeOnClick: false }
export const DEFAULT_LOADING_OPTIONS = { ...DEFAULT_CLOSE_BEHAVIOUR }
export const DEFAULT_SUCCESS_OPTIONS = { ...DEFAULT_CLOSE_BEHAVIOUR, isLoading: false }
export const DEFAULT_SUCCESS_TIMED_OPTIONS = { ...DEFAULT_CLOSE_BEHAVIOUR, isLoading: false, autoClose: DEFAULT_AUTO_CLOSE_TIME, pauseOnFocusLoss: false }
export const DEFAULT_FAILED_OPTIONS = { ...DEFAULT_CLOSE_BEHAVIOUR, isLoading: false, autoClose: DEFAULT_AUTO_CLOSE_TIME, pauseOnFocusLoss: false }
export const TOAST_MESSAGES_FOR_AUTOCLOSE = [
  toastEntityTypesEnum.PAYFILE_IMPORT_JOB,
  toastEntityTypesEnum.REPORT_CREATE_JOB,
  toastEntityTypesEnum.EMPLOYEE_BULK_CREATE_JOB,
  toastEntityTypesEnum.EMPLOYEE_BULK_UPDATE_JOB,
]
