import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getCodeByToken, getUserEmail, getUserPhone, logout } from 'redux/actions/auth'
import { showMessage } from 'redux/actions/modal'
import PropTypes from 'prop-types'
import strings from 'strings'
import ResetPasswordPhoneView from '../components/ResetPasswordPhoneView'
import ResetPasswordEmailView from '../components/ResetPasswordEmailView'
import { isCotDomain } from 'utils/url'
import { tenantTypes } from 'utils/enums/tenantTypes'

class ResetPasswordContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    let { isSenderServiceSMS } = this.props

    if (isSenderServiceSMS) {
      return this.props.dispatch(getUserPhone({ confirmationToken: this.props.token }))
    }
    return this.props.dispatch(getUserEmail({ confirmationToken: this.props.token }))
  }
  render () {
    let { isSenderServiceSMS } = this.props

    if (isSenderServiceSMS) return <ResetPasswordPhoneView {...this.props} />
    return <ResetPasswordEmailView {...this.props} />
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  dispatch
})

const mapStateToProps = (state, props) => {
  const token = props.match.params.token
  const hostUrlCot = isCotDomain(state.config.apiUrl)
  const isSenderServiceSMS = hostUrlCot ? state.config.senderServiceCot === 'sms' : state.config.senderService === 'sms'
  const senderService = hostUrlCot ? state.config.senderServiceCot : state.config.senderService
  let { tenantType } = state.config
  tenantType = hostUrlCot ? tenantTypes.COT : tenantType

  return {
    tenantType,
    isSenderServiceSMS,
    senderService,
    mobilePhone: state.auth.mobilePhone.phone,
    email: state.auth.email.email,
    token,
    config: state.config,
    initialValues: {
      confirmationToken: token
    }
  }
}

const getModalMessage = (senderService) => {
  return strings.authentication.twoFAWithAuth[senderService] ?? ''
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { senderService } = stateProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: data => dispatch(getCodeByToken(data))
      .then(() => {
        ownProps.history.push(`/reset-password-confirmation/${ownProps.match.params.token}`)
      })
      .then((res) => {
        dispatch(showMessage({
          body: getModalMessage(senderService)
        }))
      })
  }
}

ResetPasswordContainer.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  config: PropTypes.object,
  isSenderServiceSMS: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'resetPassword'
})(ResetPasswordContainer))
