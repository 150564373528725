import ChangeDetailsRoute from './routes/ChangeDetails'
import TimeAttendanceView from './components/TimeAttendanceView'

export default (store) => ({
  path : 'time-attendance',
  indexRoute: { component: TimeAttendanceView },
  skipChildren: true,
  tabbedRoute: true,
  childRoutes: [ ChangeDetailsRoute(store) ],
})
