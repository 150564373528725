import React from 'react'
import ConfirmationForm from 'components/form/ConfirmationForm'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { detachTerms, mergeTerms } from 'redux/actions/employeeSystemUsers'
import { getEmployeeWithCurrencyAndTerms } from '../../../selectors/employeesORM'
import _ from 'lodash'
import Fetcher from 'containers/Fetcher'
import { message, confirmMessage } from 'components/payruns/Messages'
import { invalidateEmployeeTermPivot } from 'redux/actions/employeeTermPivot'
import createEmployeeFilter from 'redux/filters/createEmployeeFilter'
import VariableEditPayElementsContainer from 'components/VariableEditPayElementsContainer'

class TermsFormEditContainer extends React.Component {
  render () {
    const { companyId, arePayelementsDisabled, paygroupEmployeeName, hasEmployeeLockedPayrollInstances } = this.props
    return <ConfirmationForm
      message={confirmMessage}
      {...this.props}
      showConfirmation={hasEmployeeLockedPayrollInstances} >

      { hasEmployeeLockedPayrollInstances && message }
      <VariableEditPayElementsContainer
        companyId={companyId}
        disabled={arePayelementsDisabled}
        disabledReason={`The value of these elements comes from ${paygroupEmployeeName},
         as this employee is part of it. To change them, please change the values of the Paygroup elements.`}
      />
      <div className='o-layout'>
        <div className='o-layout__item u-text--center u-1/1'>
          <button
            type='submit'
            className='c-btn c-btn--submit c-btn--small c-btn--curious u-padding-left u-padding-right'
            data-testid='submit'
          >
            Save
          </button>
        </div>
      </div>
    </ConfirmationForm>
  }
}

export const getChangedFields = (state, formName, submittedData) => {
  const initialValues = state.form[formName].initial

  // Filter and return the changed fields only
  return Object.keys(submittedData)
    .filter(field => !_.isEqual(initialValues[field], submittedData[field]))
    .reduce((accumulator, field) => {
      accumulator[field] = submittedData[field]

      return accumulator
    }, {})
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, { onSubmit, match: { params: { employeeId } } }) => {
  return {
    onSubmit: data => dispatch((dispatch, getState) => {
      // Here we will keep only the changed fields
      const changedFields = getChangedFields(getState(), 'employeeTermsEdit', data)

      const deletedVariableFields = Object.keys(changedFields)
        .filter(field => _.startsWith(field, 'variable-term-') && !changedFields[field])
        .reduce((accumulator, field) => {
          accumulator[field] = data[field]

          return accumulator
        }, {})

      // TODO - if there's a rejected promise, then callback is still invoked.
      return Promise.all([
        dispatch(mergeTerms(employeeId, changedFields, { shouldFetch: false, shouldInvalidate: true })),
        ...!_.isEmpty(deletedVariableFields) ? [dispatch(detachTerms(employeeId, deletedVariableFields, {
          shouldFetch: false, shouldInvalidate: true }))] : []
      ]).then(() => {
        dispatch(invalidateEmployeeTermPivot())
        onSubmit()
      })
    }),
    dispatch
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const employee = getEmployeeWithCurrencyAndTerms(state, { employeeId: props.match.params.employeeId })

  // ready terms for preselect
  let initEmployeeTerms = {}

  // Build CCT initial values
  // TODO - have to be reused
  employee.employeeCompanyCountryTerms.map(pivot => {
    if (pivot.cct.type === 'fixed') {
      initEmployeeTerms['fixed-term-' + pivot.cct.id] = pivot.amount
    } else if (pivot.cct.type === 'variable') {
      initEmployeeTerms['variable-term-' + pivot.cct.id] = 'checked'
    }
  })

  return {
    companyId: employee.company,
    hasEmployeeLockedPayrollInstances: employee.hasLockedPayrollInstances,
    initialValues: {
      id: employee.id,
      ...initEmployeeTerms
    }
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'employeeTermsEdit'
})(TermsFormEditContainer))

export default Fetcher(Container, [
  'companies', 'countries', 'payrollPaygroupPivot', 'currencies', 'companyCountryTermPivot',
  createEmployeeFilter({ name: 'employeeTermPivot' }),
  createEmployeeFilter({ name: 'payrollEmployeePivot' }),
  createEmployeeFilter({ name: 'paygroupEmployeePivot' }),
  createEmployeeFilter({ name: 'annualSalaries' }),
  createEmployeeFilter({ name: 'employeeSystemUsers', filterName: 'id' }),
])
