import { placeholders } from 'utils/locales/common.en'
import { sortByName } from 'utils/strings'

/**
 * Generates a schema with oneOf for enum items.
 *
 * @param {Array} items - The array of items to map.
 * @param {string} nameKey - The key to use for the title in the schema.
 * @param {string} valueKey - The key to use for the const value in the schema.
 * @param options
 * @returns {Object} The schema with the mapped oneOf array with none option depending on the options argument or an empty object if items are empty.
 */
export const getMappedOneOfEnumSchema = (items = [], nameKey, valueKey, options = {}) => {
  const sortFunc = options.sortFunc || sortByName

  const sortedItems = items.sort(sortFunc)

  // Normally we would want to use OneOf property, but in the case where there is too many options, it breaks the validator
  // See https://github.com/rjsf-team/react-jsonschema-form/issues/3829
  // As a workaround we are using the elements as enums and enumNames which is not advisable because it will be deprecated in a future version
  // But for now, until the validator library is fixed, this fixes our issue
  const enumList = sortedItems.map((item) => item[valueKey])
  const enumNames = sortedItems.map((item) => item[nameKey])

  // Add a "None" option if specified
  if (options.withNone) {
    enumList.push(0)
    enumNames.push(placeholders.none)
  }

  return { enum: enumList, enumNames }
}
