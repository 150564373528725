import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { filterCompanyDataProtectionFields } from 'redux/actions/companyDataProtectionFieldsPivot'
import { filterCompanyDataProtectionFieldsRevision } from 'redux/actions/companyDataProtectionFieldsRevision'
import { getApprovedFieldsInitialValues } from 'redux/selectors/approvedCompanyDataProtectionFields'
import ApproveFieldsForm from '../components/ApproveFieldsForm'
import { getCompanyRef } from 'redux/selectors/company'
import { attachCompanyDataProtectionFields } from 'routes/Companies/modules/actions'
import { invalidateApprovedCompanyDataProtectionFieldsPivot } from 'redux/actions/approvedCompanyDataProtectionFieldsPivot'
import { invalidateCountryDataProtectionFieldsPivot } from 'redux/actions/countryDataProtectionFieldsPivot'
import { hasAccess } from 'redux/selectors/auth'

class ApproveFieldsContainer extends React.Component {
  render () {
    return <ApproveFieldsForm {...this.props} />
  }
}

const mapStateToProps = (state, props) => {
  const company = getCompanyRef(state, { companyId: props.match.params.companyId })
  const selector = formValueSelector('approveFieldsForm')

  return {
    companyId: props.match.params.companyId,
    initialValues: getApprovedFieldsInitialValues(state, { countryId: company.country }),
    isApproved: selector(state, 'approve'),
    showApproveMsgBtn: hasAccess(state)(['COMPANYDATAPROTECTIONFIELDSTATE_CREATE'])
  }
}
const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: data => {
    dispatch(invalidateApprovedCompanyDataProtectionFieldsPivot())
    dispatch(invalidateCountryDataProtectionFieldsPivot())
    return dispatch(attachCompanyDataProtectionFields(props.match.params.companyId, data)).then(() => {
      dispatch(filterCompanyDataProtectionFieldsRevision({ company: props.match.params.companyId }))
      dispatch(filterCompanyDataProtectionFields({ company: props.match.params.companyId, isApproved: true }))
    })
  },
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'approveFieldsForm',
})(ApproveFieldsContainer))
