import createSelector from 'utils/createSelector'
import { sortByLastModifiedAt } from 'utils/sort'
import { getTenantSubdomain } from 'utils/tenant'

export const getLoggedInUserId = (state) => state.auth.userId
export const getAdditionalItems = createSelector(
  (state, filterName) => {
    const entity = 'additionalItems'
    return state[entity].filters[filterName]?.ids
  },
  getTenantSubdomain,
  getLoggedInUserId,
  ({ AdditionalItem }, filteredIds = [], tenant, loggedUserId) => {
    return AdditionalItem.all()
      .toRefArray()
      .filter((item) => filteredIds.includes(item.id))
      .map((item) => ({
        ...item,
        currentPayrunValue: parseFloat(item.currentPayrunValue),
        previousPayrunValue: parseFloat(item.previousPayrunValue),
        isTheLoggedUserTheOwner: item.createdByUserTenant === tenant && item.createdByUserId === loggedUserId,
      }))
      .sort(sortByLastModifiedAt)
  }
)
