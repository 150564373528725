import React from 'react'
import PropTypes from 'prop-types'
import DocumentsView from 'components/documents/DocumentsView'

export const RouteView = props => <DocumentsView
  {...props}
  typeClass='employee'
  typeId={props.match.params.employeeId}
  title='Documents'
/>

RouteView.propTypes = {
  params: PropTypes.shape({
    employeeId: PropTypes.string
  })
}

export default RouteView
