import { attr } from 'redux-orm'
import { CRUDModel } from './CRUDModel'
import { actionTypes } from 'redux/actions/externalCompanies'

class ExternalCompany extends CRUDModel {}
ExternalCompany.modelName = 'ExternalCompany'
ExternalCompany.actionTypes = actionTypes

ExternalCompany.fields = {
  id: attr(),
  name: attr(),
  extraData: attr()
}

export default ExternalCompany
