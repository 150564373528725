import { CommentIcon } from 'components/icons/CommentIcon'
import React from 'react'
import { buttonActions } from 'utils/locales/common.en'
import { isAccounting, isFinanceAdmin } from 'redux/selectors/auth'
import { useSelector } from 'react-redux'

const btnClasses = 'c-btn c-btn--small c-btn--curious u-padding-left u-padding-right u-margin-right-small'

const CommentaryControlBtns = ({ onToggleViewComments, hasAllRowsExpanded, onCancel, onSave, onEdit, isEdit }) => {
  const hasAccess = useSelector((state) => !isFinanceAdmin(state) && !isAccounting(state))
  const showEditButton = hasAllRowsExpanded && hasAccess
  return (
    <>
      {/* TOGGLE COMMENTS BTN */}
      <button className={`${btnClasses} d-flex u-margin-right-small`} onClick={onToggleViewComments}>
        <CommentIcon className='u-margin-right-small' />
        {hasAllRowsExpanded ? 'Close Comments' : 'View Comments'}
      </button>
      {/* EDIT BTN */}
      {showEditButton && (
        <button
          onClick={onEdit}
          className={`${btnClasses} u-margin-right-small`}
          title={buttonActions.edit}
          data-testid='versioning-comments-edit'
          disabled={isEdit}
        >
          <span className='icon icon--edit' />
        </button>
      )}
      {isEdit && (
        <>
          {/* CANCEL BTN */}
          <button onClick={onCancel} className={btnClasses} title={buttonActions.cancel} data-testid='versioning-cancel'>
            {buttonActions.cancel}
          </button>
          {/* SAVE BTN */}
          <button onClick={onSave} className={btnClasses} title={buttonActions.save} data-testid='versioning-save'>
            {buttonActions.save}
          </button>
        </>
      )}
    </>
  )
}

export default CommentaryControlBtns
