import React from 'react'
import { isFetching } from 'utils/redux/fetching'
import AssignmentsList from '../components/AssignmentsList'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchAssignments, updateAssignment } from 'redux/actions/assignments'
import { getAssignmentsGroupedByStatus, getOverdueAssignmentsGroupedByStatus } from '../selectors/assignments'
import { showMessage } from 'redux/actions/modal'
import { createFilter } from 'utils/redux/filter'
import { buildFilters } from 'utils/filters'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

class AssignmentsListContainer extends React.Component {
  render () {
    if (this.props.isFetching) return <div>Loading...</div>

    return <AssignmentsList {...this.props} />
  }
}

/*
 * Get assignment by id
 *
 * @param {Object} groupedAssignments
 * @param {String} id
 *
 * return Object
 */
const getAssignmentById = (groupedAssignments, id) => {
  const matchingLane = _.find(groupedAssignments.lanes, { cards: [ { id } ] })
  const assignment = _.find(matchingLane.cards, { id })
  return assignment
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, props) => {
  const { query } = props.location
  return {
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    onUpdate: (data, status) => dispatch((dispatch, getState) => {
      return dispatch(updateAssignment(data, status))
        .then((response) => {
          if (response.errorsCommon) {
            return dispatch(showMessage({
              body: response.errorsCommon[0]
            }))
          }

          if (response.errors) {
            let responseSummary = `${response.data.length} successfully updated, ${Object.keys(response.errors).length} errors`
            let errorsArr = []

            const state = getState()

            const groupedAssignments = getAssignmentsGroupedByStatus(state)

            Object.keys(response.errors).forEach(e => {
              const assignment = getAssignmentById(groupedAssignments, e)

              errorsArr.push(<span>{`${assignment.name} of ${assignment.processName}: ${response.errors[e]}.`}<br /></span>)
            })

            return dispatch(showMessage({
              body: <span>{responseSummary}<br /><br />{errorsArr}</span>
            }))
          }

          return dispatch(showMessage({
            body: 'Status successfully changed!'
          }))
        })
        .then(() => {
          const state = getState()
          const selector = formValueSelector('filterTasks')
          let values = selector(state, 'fromDate', 'view', 'toDate',
            'owner', 'payroll', 'companyProcess', 'company')
          if (!_.isEmpty(query) && (query.status && query.status === 'overdue') && values.view === 'year') {
            values.view = 'month'
            values.fromDate = moment().startOf('month')
          }
          const filters = buildFilters(values)
          let defaultFilters = {
            ...filters,
            notInactive: true
          }
          return dispatch(fetchAssignments({
            filter: createFilter(defaultFilters, 'assignments'),
            disableObsoleteFlow: true
          }))
        })
    }),
    dispatch
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { assignments, companies } = state

  const entities = [ { ref: assignments, filter: { name: 'assignments' } }, companies ]

  if (isFetching(entities)) return { isFetching: true }
  const selector = formValueSelector('filterTasks')
  const values = selector(state, 'actionStatus')
  const isOverdueStatus = values && (props.initialFilterValues.actionStatus === 'overdue' || values === 'overdue')

  const groupedAssignments =
    isOverdueStatus && values !== 'all'
      ? getOverdueAssignmentsGroupedByStatus(state, { filter: 'assignments' })
      : getAssignmentsGroupedByStatus(state, { filter: 'assignments' })

  return {
    groupedAssignments,
    draggingEnabled: !props.initialFilterValues.actionStatus,
    assignmentById: id => getAssignmentById(groupedAssignments, id),
  }
}

AssignmentsListContainer.propTypes = {
  isFetching: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsListContainer)
