import createSelector from 'utils/createSelector'
import { getIds } from 'redux/selectors/filters'
import { uniq } from 'lodash'

export const getPayrollProcessOwners = createSelector(getIds,
  ({ Payroll }, payrollIds) => {
    const processOwners = Payroll.filter(({ id }) => payrollIds.includes(id)).toRefArray()
      .map(payroll => payroll.processOwner)

    return uniq(processOwners)
  }
)
