import { createReducerMeta } from 'utils/redux'
import { COT_USER_FETCH, COT_USER_RECEIVE } from 'redux/actions/cotUsers'

const actionTypes = {
  fetch: COT_USER_FETCH,
  receive: COT_USER_RECEIVE
}

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducerMeta(actionTypes)
