import { ActionUtility } from 'utils/redux'

// ------------------------------------
// Constants
// ------------------------------------
export const PAYFILE_CHANGES_FETCH = 'PAYFILE_CHANGES_FETCH'
export const PAYFILE_CHANGES_RECEIVE = 'PAYFILE_CHANGES_RECEIVE'
export const PAYFILE_CHANGES_UPDATE = 'PAYFILE_CHANGES_UPDATE'
export const PAYFILE_CHANGES_FILTER = 'PAYFILE_CHANGES_FILTER'
export const PAYFILE_CHANGES_FILTERED = 'PAYFILE_CHANGES_FILTERED'
export const PAYFILE_CHANGES_IMPORT = 'PAYFILE_CHANGES_IMPORT'
export const PAYFILE_CHANGES_IMPORTED = 'PAYFILE_CHANGES_IMPORTED'
export const PAYFILE_CHANGES_INITIAL_VALUES = 'PAYFILE_CHANGES_INITIAL_VALUES'
export const PAYFILE_CHANGES_RESET_INITIAL_VALUES = 'PAYFILE_CHANGES_RESET_INITIAL_VALUES'

// ------------------------------------
// Actions
// ------------------------------------
export const actionTypes = {
  fetch: PAYFILE_CHANGES_FETCH,
  receive: PAYFILE_CHANGES_RECEIVE,
  update: PAYFILE_CHANGES_UPDATE,
  filter: PAYFILE_CHANGES_FILTER,
  filtered: PAYFILE_CHANGES_FILTERED,
  import: PAYFILE_CHANGES_IMPORT,
  imported: PAYFILE_CHANGES_IMPORTED,
  initials: PAYFILE_CHANGES_INITIAL_VALUES,
  resetInitials: PAYFILE_CHANGES_RESET_INITIAL_VALUES,
}
const actionUtility = new ActionUtility(actionTypes, 'payfileChanges', 'payfilechanges', 'PayfileChange')

export const resetPayfileChangesInitials = () => ({
  type: actionTypes.resetInitials
})

// ------------------------------------
// Thunk
// ------------------------------------
export const downloadPayFile = (id, params) => actionUtility.downloadEntity({ id, childUri: 'export', params })

export const filterPayfiles = ({ filters }) => {
  return dispatch => {
    return dispatch(actionUtility.filterEntity({ filters }))
      .then(response => dispatch({ type: actionTypes.initials, payload: { data: response, filters } }))
  }
}
