import React from 'react'
import { connect } from 'react-redux'
import { isFetching } from 'utils/redux/fetching'
import PropTypes from 'prop-types'
import CompanyServiceVendorList from '../components/CompanyServiceVendorList'
import { fetchCompaniesIfNeeded } from 'routes/Companies/modules/actions'
import { fetchCountriesIfNeeded } from 'routes/Countries/modules/actions'
import { fetchCompanyServicePivotIfNeeded } from 'redux/actions/companyServicePivot'
import { fetchCountryServicePivotIfNeeded } from 'redux/actions/countryServicePivot'
import { fetchServicesIfNeeded } from 'redux/actions/services'
import { fetchVendorCountryServicePivotIfNeeded } from 'redux/actions/vendorCountryServicePivot'
import { getCompaniesWithCountryWithCompanyServices,
  getCompanyWithCountryWithCompanyServices } from '../../../selectors/companies'
import { getCompanyRef } from 'redux/selectors/company'

class ServiceVendorsContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    this.props.fetchCompaniesIfNeeded()
    this.props.fetchCountriesIfNeeded()
    this.props.fetchCompanyServicePivotIfNeeded()
    this.props.fetchCountryServicePivotIfNeeded()
    this.props.fetchServicesIfNeeded()
    this.props.fetchVendorCountryServicePivotIfNeeded()
  }

  render () {
    return <CompanyServiceVendorList {...this.props} />
  }
}

// say what you're dispatching on init
const mapDispatchToProps = (dispatch, state) => {
  return {
    fetchCompaniesIfNeeded: () => dispatch(fetchCompaniesIfNeeded()),
    fetchCountriesIfNeeded: () => dispatch(fetchCountriesIfNeeded()),
    fetchCompanyServicePivotIfNeeded: () => dispatch(fetchCompanyServicePivotIfNeeded()),
    fetchCountryServicePivotIfNeeded: () => dispatch(fetchCountryServicePivotIfNeeded()),
    fetchServicesIfNeeded: () => dispatch(fetchServicesIfNeeded()),
    fetchVendorCountryServicePivotIfNeeded: () => dispatch(fetchVendorCountryServicePivotIfNeeded()),
  }
}

// map to state
const mapStateToProps = (state, props) => {
  const { companies, countries, companyServicePivot, countryServicePivot, services, vendorCountryServicePivot
  } = state
  const entities = [companies, countries, companyServicePivot, countryServicePivot, services, vendorCountryServicePivot]

  if (isFetching(entities)) return { isFetching: true }

  let routeCompany = getCompanyRef(state, {
    companyId: props.match.params.companyId
  })

  // get all companies
  let companyData = routeCompany.isHQ
    ? getCompaniesWithCountryWithCompanyServices(state)
    : [getCompanyWithCountryWithCompanyServices(state, {
      companyId: props.match.params.companyId
    })]

  return {
    companies: companyData
  }
}

ServiceVendorsContainer.propTypes = {
  onOpenAssign: PropTypes.func.isRequired,
  onOpenAddVendor: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetchCompaniesIfNeeded: PropTypes.func,
  fetchCountriesIfNeeded: PropTypes.func,
  fetchCompanyServicePivotIfNeeded: PropTypes.func,
  fetchCountryServicePivotIfNeeded: PropTypes.func,
  fetchServicesIfNeeded: PropTypes.func,
  fetchVendorCountryServicePivotIfNeeded: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceVendorsContainer)
