import React from 'react'
import PropTypes from 'prop-types'
import FieldErrors from './FieldErrors'

const BaseField = ({ label, labelClassName, formGroupClassName = '', disabled, disabledReason, meta, children, title,
  input = {}, useTestProp, showDisabledFieldModalMessage, ...rest }) => {
  const { onMouseEnter } = input

  return <div className={'o-form-group u-relative ' + formGroupClassName}
    title={title}
    {... disabled ? { title: disabledReason } : {}}
    {... onMouseEnter ? { onMouseEnter } : {}}
    {... useTestProp ? { 'data-testid' : rest['data-testid'] } : {}}
    onClick={disabled ? showDisabledFieldModalMessage : null}
  >
    <label className={labelClassName}>{label}</label>
    {children}
    <FieldErrors meta={meta} />
  </div>
}

BaseField.propTypes = {
  // Should we set `data-testid` prop or not.
  // Basically, in most of the cases, this prop should be passed down to the children,
  // i.e. InputField, TextAreaField and etc.
  // But in the case the child Component API doesn't allow adding custom data attributes,
  // then we are adding it here to the BaseField wrapper, as a workaround.
  // Such case we have with `react-select` component. Please refer to `CustomSelectField` for more details.
  useTestProp: PropTypes.bool,
  showDisabledFieldModalMessage: PropTypes.func
}

export default BaseField
