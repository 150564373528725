import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CustomSelectField from 'components/form/select/CustomSelectField'
import { onChangeSubmit } from 'utils/form'
let classNames = require('classnames')

const renderOptions = (tenants, isFetching) => {
  if (isFetching) return []

  return tenants.map(tenant => ({
    value: tenant.url,
    label: tenant.name,
    disabled: tenant.disabled,
    // Here we treat the `disabled` items as a group title, for example:
    // Clients (its disabled)
    //   -> Tenant1
    //   -> Tenant2
    // Vendors (its disabled)
    //   -> Vendor1
    //   -> Vendor2
    className: classNames({ 'option--as-group-title': tenant.disabled })
  }))
}

const TenantSelector = ({ tenants, isFetching, handleSubmit }) => {
  return <form onSubmit={handleSubmit}>
    <Field
      name='url'
      label=''
      labelClassName='c-label'
      component={CustomSelectField}
      className='c-custom-select--small'
      formGroupClassName='u-margin-tiny'
      clearable={false}
      placeholder={isFetching ? 'Loading...' : 'Client/Vendor'}
      options={renderOptions(tenants, isFetching)}
      onChanged={onChangeSubmit(handleSubmit)}
      data-testid='tenantSelector-wrapper'
    />
  </form>
}

TenantSelector.propTypes = {
  tenants: PropTypes.array
}

export default TenantSelector
