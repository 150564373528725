import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import EmployeesSearchBarContainer from 'containers/employee/EmployeesSearchBarContainer'
import DetachingFromPayrollContainer from '../containers/DetachingFromPayrollContainer'
import PayrollHeadingContainer from '../../../../../containers/PayrollHeadingContainer'
import useQuery from 'hooks/useQuery'

export default props => <InnerWrapper>
  <PayrollHeadingContainer heading='Employees - Unassigning from Payroll' {...props} />
  <EmployeesSearchBarContainer query={useQuery()} {...props} />
  <DetachingFromPayrollContainer enablePagination {...props} />
</InnerWrapper>
