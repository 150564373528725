import React from 'react'

export const CheckboxWrapper = (props) => {
  const className = `control__indicator ${props.indicatorClassName}`
  return (
    <label className={'c-label o-block o-block--left control control--checkbox ' + (props.disabled ? 'disabled-greyed' : 'u-cursor--pointer')}>
      {props.label}
      {props.children}
      <div className={className} data-testid={props['data-testid']} />
    </label>
  )
}

export default CheckboxWrapper
