import PropTypes from 'prop-types'
import React from 'react'
import Year from 'components/calendar/Year'
import Legend from './Legend'
import CalendarYearHeader from './CalendarYearHeader'
import CalendarHolidayList from './CalendarHolidayList'
import CalendarYearButtonsHeader from './CalendarYearButtonsHeader'

const CalendarYearView = props => {
  return <div className={props.isExporting ? 'calendar-yearly-exporting' : ''}>
    <CalendarYearButtonsHeader {...props} />
    <div ref={props.pngRef}>
      <CalendarYearHeader {...props} />
      <Legend />
      <hr className='calendar-yearly-divider' />
      <div className='calendar-yearly-scrollable'>
        <Year date={props.selectedYearStartDate} {...props} />
        <hr className='calendar-yearly-divider' />
        <CalendarHolidayList {...props} />
      </div>
    </div>
  </div>
}

CalendarYearView.propTypes = {
  pngRef: PropTypes.object,
  isExporting: PropTypes.bool
}

export default CalendarYearView
