import React from 'react'
import InnerWrapper from 'layouts/InnerWrapper'
import Modal from 'components/Modal'
import CotUserListContainer from '../containers/CotUserListContainer'
import CotUserFormEditContainer from '../containers/CotUserFormEditContainer'
import CotUserFormCreateContainer from '../containers/CotUserFormCreateContainer'

class RouteView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      cotUserId: null
    }
  }

  render () {
    return <InnerWrapper>
      <Modal
        ref='createModal'
        className='c-modal c-modal--full c-modal--overflow-y'
        modalHeading='Add COT User'
      >
        <CotUserFormCreateContainer
          onCreate={() => this.refs.createModal.hideModal()}
          {...this.props}
        />
      </Modal>

      <Modal
        ref='editModal'
        className='c-modal c-modal--full c-modal--overflow-y'
        modalHeading='Edit COT User Info'
      >
        <CotUserFormEditContainer
          cotUserId={this.state.cotUserId}
          onEdit={() => this.refs.editModal.hideModal()}
        />
      </Modal>

      <CotUserListContainer
        onCreate={() => this.refs.createModal.showModal()}
        onEdit={(cotUserId) => {
          this.setState({
            cotUserId
          })
          this.refs.editModal.showModal()
        }}
        {...this.props} />
    </InnerWrapper>
  }
}

export default RouteView
