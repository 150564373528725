import React from 'react'
import SectionHeading from 'components/SectionHeading'
import ImportFacade from '../../../containers/ImportFacade'
import { downloadTemplateUpdate, importAccountsUpdate, download } from 'redux/actions/generalLedgers'

const TemplateClarifications = () => <div className='u-margin-bottom-small u-text--normal'>
  <SectionHeading text='Update accounts in bulk' />
  <p className='u-margin-bottom-small'>This template allows you to update multiple accounts in bulk.
    Follow the simple process described below:</p>

  <ul className='u-margin-bottom-small'>
    <li>Download template</li>
    <li>Complete data</li>
    <li>Import the completed template using Import button</li>
    <li>Wait for confirmation message</li>
  </ul>
</div>

export default props => <ImportFacade
  {...props}
  heading='Update accounts in bulk'
  entityName='generalLedgers'
  downloadAction={download}
  downloadTemplateAction={() => downloadTemplateUpdate()}
  importAction={({ file }) => importAccountsUpdate(file)}
  TemplateClarifications={TemplateClarifications}
  hasFilters={false}
>
  <div className='o-layout'>
    <div className='o-layout__item u-1/2 u-text--normal'>
      <SectionHeading text='Data validations:' />
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Status: </span>
        Active, Inactive
      </p>
      <p className='u-margin-bottom-tiny'>
        <span className='u-weight--bold'>Account Type: </span> - Expense, Liability, Expense/Liability
      </p>
    </div>
  </div>
</ImportFacade>
